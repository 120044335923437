import React from 'react';
import styles from './MenuColorButton.module.scss';
import cls from 'classnames';

export const MenuColorButton = ({ id, colorClass, isActive, onClick }) => {
  const reference = React.useRef(null);
  return (
    <div
      ref={reference}
      onClick={(e) => {
        e.stopPropagation();
        onClick(e, reference, id);
      }}
      className={cls(styles.Item, isActive ? styles.Active : null, colorClass)}
    >
      <button />
    </div>
  );
};
