import AppSignals from 'pixi-project/signals/AppSignals';
import { PR_EVENT_FUNNEL_CHANGED, PR_STEP_TEXTURE_UPDATED } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';

export default class CommandContentIcon {
  constructor(object, pathUrl, isCustom) {
    this.object = object;

    this.previousThumbnail = object.thumbnail;
    this.previousUseThumbnail = object.useThumbnail;
    this.previousThumbnailURL = object.thumbnailURL;
    this.previousIsCustom = object.isCustom || false;
    this.previousTexturePath = object.texturePath;
    this.previousContentTexture = object.content.texture;

    this.pathUrl = pathUrl;
    this.isCustom = isCustom || false;
    this.isExecuted = false;
  }

  execute() {
    if (!this.isExecuted) {
      this.object.removeThumbnail();
      this.object.texturePath = this.pathUrl;
      this.object.isCustom = this.isCustom;

      if (this.isCustom) {
        this.object.isCustomIconFetched = false;
        AppSignals.fetchCustomIcons.dispatch(this.object);
      } else {
        this.object.content.setTexture(this.pathUrl);
      }

      commonSendEventFunction(PR_STEP_TEXTURE_UPDATED, this.object);
      window.app.needsRendering();

      this.isExecuted = true;
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
    }
  }

  revert() {
    if (this.isExecuted) {
      this.object.useThumbnail = this.previousUseThumbnail;
      this.object.thumbnailURL = this.previousThumbnailURL;
      this.object.texturePath = this.previousTexturePath;
      this.object.isCustom = this.previousIsCustom;

      if (this.previousIsCustom) {
        this.object.isCustomIconFetched = false;
        AppSignals.fetchCustomIcons.dispatch(this.object);
      } else {
        if (this.previousUseThumbnail) {
          this.object.setThumbnailSprite(this.previousThumbnail);
        } else {
          this.object.content.removeChildren();
          this.object.content.texture = this.previousContentTexture;
        }
      }

      window.app.needsRendering();
      this.isExecuted = false;
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
    }
  }
}
