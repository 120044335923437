import React, { useEffect, useRef } from 'react';
import styles from 'react-project/components/popUp/UpsellPopup/content/Tutorial.module.scss';
import { setNewIndicies } from 'react-project/Header/tutorialSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Footer } from './TaskNavigation/Footer';
import { Header } from './TaskNavigation/Header';
import { List } from './TaskNavigation/List';

const selected = 'Actions';

export const TaskNavigation = () => {
  const listRef = useRef();
  const dispatch = useDispatch();
  const tasks = useSelector((state) => state.tutorial.tasks);
  const completedPercent = useSelector((state) => Math.ceil(state.tutorial.completedPercent));
  const currentIndex = useSelector((state) => state.tutorial.currentIndex);
  const selectedName = useSelector((state) => {
    const currentIndex = state.tutorial.currentIndex;
    return state.tutorial.tasks[currentIndex].name;
  });
  const isLoading = useSelector((state) => state.tutorial.isLoading);

  useEffect(() => {
    if (listRef.current) {
      const listBoundingRect = listRef.current.getBoundingClientRect();
      const liElement = document.querySelectorAll(`[data-key="tut-${currentIndex}"]`);
      const target = liElement[0];
      if (target && target.getBoundingClientRect().bottom > listBoundingRect.bottom) {
        target.scrollIntoView(false);
      }
      if (target && target.getBoundingClientRect().top < listBoundingRect.top) {
        target.scrollIntoView();
      }
    }
  }, [currentIndex]);

  const setNewIndex = (index) => {
    dispatch(setNewIndicies(index));
  };

  return (
    <div className={styles.upsellTaskNavigation}>
      <div className={styles.action}>
        <Header completedPercent={completedPercent} />
      </div>
      <div className={styles.list}>
        <div>
          <List
            isLoading={isLoading}
            listRef={listRef}
            tasks={tasks}
            taskName={selectedName}
            onClick={setNewIndex}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  );
};
