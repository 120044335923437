export default class PermissionHelper {
  static isDataOriginBlocked = (dataOrigin, canvasPermissions) => {
    if (!canvasPermissions.gohighlevelIntegrationAllowed && dataOrigin === 'gohighlevel') {
      return true;
    } else if (!canvasPermissions.hubspotIntegrationAllowed && dataOrigin === 'hubspot') {
      return true;
    } else if (!canvasPermissions.webhookIntegrationAllowed && dataOrigin === 'webhook') {
      return true;
    } else if (!canvasPermissions.zapierIntegrationAllowed && dataOrigin === 'zapier') {
      return true;
    }

    return false;
  };

  static filterByDataOrigin = (objectsToReturn, canvasPermissions) => {
    return objectsToReturn.filter((obj) => {
      if (obj.filterData && obj.filterData.length) {
        return !obj.filterData.find((filter) =>
          PermissionHelper.isDataOriginBlocked(filter.value, canvasPermissions),
        );
      }
      return true;
    });
  };
}
