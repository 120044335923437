import { produce } from 'immer';
import { handleActions } from 'redux-actions';

import { setReportSending } from './actions';

const initialState = {
  isReportSending: false,
};

export default handleActions(
  {
    [setReportSending.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.isReportSending = action.payload;
      }),
  },
  initialState,
);
