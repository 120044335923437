import { formatDistanceToNowStrict } from 'date-fns';
import React from 'react';
import { Loader } from 'react-project/components/loader/Loader';
import { TEXT_LOAD_MORE } from 'react-project/Constants/texts';
import styles from 'react-project/Toolbar/step-toolbar/PeopleTracking/PeopleTracking.module.scss';
import { useSelector } from 'react-redux';
import { CountriesListArr } from 'shared/CSharedConstants';
import { isEmailValid } from 'shared/ValidateHelpers';

export function PeopleTable({ data, hasLoadMore, onLoadMore, pagIsLoading, onClick }) {
  const styling = pagIsLoading ? { cursor: 'not-allowed' } : null;
  const peopleData = useSelector((state) => state.peopleTracking.peopleTrackingPopups);

  return (
    <>
      <table className="w-full">
        <thead>
          <tr className="flex justify-between font-semibold pl-5 py-3 border-b-[1px] border-slate-300 text-slate-500">
            <th className="w-[20%] flex justify-left">Country</th>
            <th className="w-[40%] flex justify-left">Person</th>
            <th className="w-[40%] flex justify-left">First Touch</th>
          </tr>
        </thead>
        <tbody>
          {data.map((session, i) => {
            let username = {
              full: null,
            };
            if (session.email && isEmailValid(session.email)) {
              username.full = session.email;
            } else if (session.fullName) {
              username.full = session.fullName;
            } else {
              username.full = `Person ${session.id}`;
            }
            const dataAgo = `${formatDistanceToNowStrict(new Date(session.createdAt))} ago`;
            const countryAttr = CountriesListArr.find(
              (attrs) => attrs.name === session.country.name,
            );
            const countryCode = countryAttr ? countryAttr.iso2.toLowerCase() : '';
            const countryClass = `flag flag-${countryAttr ? countryCode : ''}`;
            const table = (
              <tr
                className={'flex flex-row pl-5 items-center even:bg-gray-100 bg-white w-full'}
                key={`pplCtn-${i}`}
                onClick={(e) => {
                  onClick(e.currentTarget, session);
                }}
                style={{ display: 'flex' }}
              >
                <td className={'w-[20%]'}>
                  <div className="flex justify-left">
                    <div className={countryClass} />
                  </div>
                </td>
                <td
                  className={'w-[40%]'}
                  title={username.full}
                  style={{ textOverflow: 'ellipsis' }}
                >
                  <div className="flex justify-left">
                    <div className="max-w-[100%] whitespace-nowrap text-ellipsis overflow-hidden">
                      {username.full || session.id}
                    </div>
                  </div>
                </td>
                <td className={'w-[40%]'}>
                  <div className="flex justify-left">{dataAgo}</div>
                </td>
              </tr>
            );

            if (peopleData.some((p) => p.session.id === session.id)) {
              table.props.style.backgroundColor = 'lightblue';
            }

            return table;
          })}
        </tbody>
      </table>
      {hasLoadMore ? (
        <div className={styles.loadMoreButton} onClick={onLoadMore} style={styling}>
          {pagIsLoading ? <Loader variant="grey-font-1" /> : TEXT_LOAD_MORE}
        </div>
      ) : null}
    </>
  );
}

export default PeopleTable;
