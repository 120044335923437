import React from 'react';
import { PopUpWrapper } from 'react-project/components/popUp/PopUpWrapper';

export const PopupContainer = ({
  children,
  wrapperOptions = {
    position: { x: 0, y: 0 },
    icon: '',
    title: '',
    showCloseIcon: true,
    menuActions: [],
    onClosePopup: () => {},
    isDraggable: true,
    width: 400,
    minWidth: 400,
    onIconClick: () => {},
    rndRef: undefined,
    className: '',
    cancel,
  },
  x = 50,
  y = 50,
}) => {
  return (
    <>
      <PopUpWrapper {...wrapperOptions}>{children}</PopUpWrapper>
    </>
  );
};
