import React, { useEffect } from 'react';
import { iconAnalyticsError, iconCheckmark, iconRefreshBtn } from 'react-project/assets/Icons';
import { Tooltip } from 'react-project/components/tooltip/Tooltip';
import { LOCK_NO_ANALYTICS, UILock } from 'react-project/components/uilock/UILock';
import styles from 'react-project/Header/Header.module.scss';
import Expire from 'react-project/Helpers/Expire/Expire';
import { useHover } from 'react-project/hooks';
import { selectAnalyticsStatus } from 'react-project/redux/analytics/selectors';
import { selectIsFiltersLoaded } from 'react-project/redux/filters/selectors';
import { When } from 'react-project/Util/When';
import { useSelector } from 'react-redux';
import {
  ANALYTICS_STATUS_CANCELLED,
  ANALYTICS_STATUS_LOADING,
  ANALYTICS_STATUS_STALE,
  ANALYTICS_STATUS_SUCCESS,
} from 'shared/CSharedConstants';

const CancelRefresh = ({ onClick }) => {
  return (
    <span
      style={{ pointerEvents: 'none' }} // to prevent changing the state on hover ( annoying blinking )
      className={`${styles.AnalyticsStatusIcon}`}
      onClick={onClick}
    >
      {iconAnalyticsError}
    </span>
  );
};

export const RefreshAnalyticsBtn = ({
  analyticsIsLoading,
  onClick,
  explorerIsLoading,
  refreshButtonStatusIcon,
  canCancel = false,
  expireDelay = 10000,
  hoveredOnClick,
}) => {
  const isFiltersLoaded = useSelector(selectIsFiltersLoaded);
  const [hoverRef, isHover] = useHover();
  const isRotatedClass = !isHover && analyticsIsLoading ? styles.Rotated : '';
  const analyticsStatus = useSelector(selectAnalyticsStatus);
  const [tooltipLabel, setTooltipLabel] = React.useState('');
  const timeoutIDRef = React.useRef(null);

  useEffect(() => {
    if (analyticsStatus === ANALYTICS_STATUS_SUCCESS) {
      timeoutIDRef.current = setTimeout(() => {
        setTooltipLabel(getAnalyticsStatusText());
      }, expireDelay);
    }

    setTooltipLabel(getAnalyticsStatusText(analyticsStatus));

    return () => {
      clearTimeout(timeoutIDRef.current);
    };
  }, [analyticsStatus]);

  const onApply = (e) => {
    onClick();
  };

  const onCancel = (e) => {
    hoveredOnClick();
  };

  const getAnalyticsStatusText = (analyticsStatus) => {
    switch (analyticsStatus) {
      case ANALYTICS_STATUS_LOADING:
        return `Cancel Refresh`;
      case ANALYTICS_STATUS_STALE:
        return `Analytics data is stale. Refresh to get the latest data.`;
      case ANALYTICS_STATUS_SUCCESS:
        return `Data successfully loaded`;
      case ANALYTICS_STATUS_CANCELLED:
        return `Data request was cancelled. Click to refresh`;
      default:
        return 'Refresh Analytics';
    }
  };

  const refreshIsCheckMark = () => {
    return refreshButtonStatusIcon === iconCheckmark ? (
      <Expire delay={expireDelay}>{refreshButtonStatusIcon}</Expire>
    ) : (
      refreshButtonStatusIcon
    );
  };
  const changeIconStatus = () => {
    return (
      refreshButtonStatusIcon &&
      !explorerIsLoading && (
        <span className={`${styles.AnalyticsStatusIcon}`}>{refreshIsCheckMark()}</span>
      )
    );
  };

  return (
    <div className="h-full" ref={hoverRef}>
      <Tooltip label={tooltipLabel}>
        <UILock lock={LOCK_NO_ANALYTICS}>
          <div
            className={`${styles.RefreshBtn}`}
            onClick={canCancel && analyticsIsLoading && isHover ? onCancel : onApply}
          >
            {canCancel && isHover && analyticsIsLoading && <CancelRefresh onClick={onCancel} />}
            <span className={`${isRotatedClass}`}>{iconRefreshBtn}</span>
            <When condition={isFiltersLoaded}>{changeIconStatus() || <></>}</When>
          </div>
        </UILock>
      </Tooltip>
    </div>
  );
};

export default RefreshAnalyticsBtn;
