import React from 'react';
import styles from 'react-project/components/trackingTab/TrackingTabAction.module.scss';
import { TEXT_COPY_VIEW_SETUP_INSTRUCTIONS } from 'react-project/Constants/texts';
import { iconExternalLink } from 'react-project/assets/Icons';

export const SetupDescription = ({
  setupDescription,
  setupLinkDescription,
  setupInstructionsLink,
}) => {
  return (
    <div className={styles.SetupDescription}>
      <p>{setupDescription}</p>
      <p className={styles.SetupInstructions}>{setupLinkDescription}</p>
      <a
        className={styles.SetupInstructionsLink}
        href={setupInstructionsLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        {TEXT_COPY_VIEW_SETUP_INSTRUCTIONS}
        <span className={styles.SetupInstructionsLinkIcon}>{iconExternalLink}</span>
      </a>
    </div>
  );
};
