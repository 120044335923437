import { PR_EVENT_COMMAND_REMOVE_EXECUTED, PR_EVENT_FUNNEL_CHANGED } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import SharedElementHelpers from 'shared/SharedElementHelpers';
import { CommandStatus } from '../CommandManager';

export default class CommandDeleteStep {
  constructor(object, parent, objectsArray, focusSelectionManager) {
    this.object = object;
    this.parent = parent;
    this.objects = objectsArray;
    this.focusSelectionManager = focusSelectionManager;
    this.isExecuted = false;
    this.focusFilterTypes = object.isFocused ? object.focusFilterTypes.slice() : [];
  }

  execute() {
    if (!this.isExecuted) {
      this.isExecuted = true;
      window.app.needsRendering();

      this.object.removeFromParent();
      this.objects.removeElement(this.object);

      this.object.isHovered = false;

      if (this.focusFilterTypes.length) {
        for (let i = 0; i < this.focusFilterTypes.length; i++) {
          const focusFilterType = this.focusFilterTypes[i];
          this.focusSelectionManager.blurSingleElement(this.object, focusFilterType);
        }
        this.focusSelectionManager.drawAll();
      }

      commonSendEventFunction(PR_EVENT_COMMAND_REMOVE_EXECUTED, {
        isExecuted: true,
        status: CommandStatus.DELETE,
        objects: [this.object],
      });
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
    }
  }

  revert() {
    if (this.isExecuted) {
      this.isExecuted = false;
      window.app.needsRendering();

      if (SharedElementHelpers.IsReport(this.object)) {
        SharedElementHelpers.InsertReport(this.object, this.objects, this.parent);
      } else {
        this.parent.addChild(this.object);
        this.objects.push(this.object);
      }

      if (this.focusFilterTypes.length) {
        for (let i = 0; i < this.focusFilterTypes.length; i++) {
          const focusFilterType = this.focusFilterTypes[i];
          this.focusSelectionManager.focusSingleElement(this.object, focusFilterType);
        }
        this.focusSelectionManager.drawAll();
      }

      commonSendEventFunction(PR_EVENT_COMMAND_REMOVE_EXECUTED, {
        isExecuted: false,
        status: CommandStatus.ADD,
        objects: [this.object],
      });
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
    }
  }
}
