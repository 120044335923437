import {
  PR_EVENT_FUNNEL_CHANGED,
  PR_EVENT_ANALYTICS_NEEDS_REFRESH,
  PR_EVENT_CONNECTION_RELOCATED,
} from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';

export default class CommandRelocateConnection {
  constructor(connection, element, isEndPoint, pointType) {
    this.connection = connection;
    this.element = element;
    this.isEndPoint = isEndPoint;
    this.pointType = pointType;
    this.isExecuted = false;

    this.prevIconA = connection.iconA;
    this.prevIconB = connection.iconB;

    this.prevPointTypeA = connection.attachmentPointsLocation.pointA;
    this.prevPointTypeB = connection.attachmentPointsLocation.pointB;
  }

  execute() {
    if (!this.isExecuted) {
      this.connection.detach();
      if (this.isEndPoint) {
        this.updateAnalytics(this.connection.iconB, this.element);
        this.connection.iconB = this.element;
        this.connection.attachmentPointsLocation.pointB = this.pointType;
      } else {
        this.updateAnalytics(this.connection.iconA, this.element);
        this.connection.iconA = this.element;
        this.connection.attachmentPointsLocation.pointA = this.pointType;
      }
      this.connection.detachedData = null;
      this.connection.attach();
      this.connection.update();
      this.connection.updateFlowPoints();
      this.isExecuted = true;
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
      commonSendEventFunction(PR_EVENT_CONNECTION_RELOCATED, this.connection);
    }
  }

  revert() {
    if (this.isExecuted) {
      this.connection.detach();
      if (this.isEndPoint) {
        this.updateAnalytics(this.connection.iconB, this.prevIconB);
        this.connection.iconB = this.prevIconB;
        this.connection.attachmentPointsLocation.pointB = this.prevPointTypeB;
      } else {
        this.updateAnalytics(this.connection.iconA, this.prevIconA);
        this.connection.iconA = this.prevIconA;
        this.connection.attachmentPointsLocation.pointA = this.prevPointTypeA;
      }
      this.connection.detachedData = null;
      this.connection.attach();
      this.connection.update();
      this.connection.updateFlowPoints();
      this.isExecuted = false;
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
      commonSendEventFunction(PR_EVENT_CONNECTION_RELOCATED, this.connection);
    }
  }

  updateAnalytics(previousElement, nextElement) {
    if (previousElement.id !== nextElement.id) {
      this.connection.analyticsManager.setData(null);
      commonSendEventFunction(PR_EVENT_ANALYTICS_NEEDS_REFRESH);
    }
  }
}
