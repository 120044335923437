import { produce } from 'immer';
import { handleActions } from 'redux-actions';
import { setWidgetStatus, setWidgetPosition } from './actions';

const initialState = {
  widget: {
    opened: false,
    position: {
      x: 0,
      y: 0,
    },
  },
};

export default handleActions(
  {
    [setWidgetStatus.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.widget.opened = action.payload;
      }),
    [setWidgetPosition.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.widget.position = action.payload;
      }),
  },
  initialState,
);
