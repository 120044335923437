import * as PIXI from 'pixi.js';
import Button from 'pixi-project/base/elements/Button';
import Styles, {
  COLOR_WIDGET_BACKGROUND,
  COLOR_WIDGET_OUTLINE,
  COLOR_WIDGET_HEADER,
  COLOR_WIDGET_MESSAGE,
} from 'pixi-project/view/Styles';
import BaseContainer from 'pixi-project/base/containers/BaseContainer';
import TableViewStatic from './TableView/TableViewStatic';
import Utils from 'pixi-project/utils/Utils';
import { EElementCategories } from 'shared/CSharedCategories';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { PR_EVENT_WIDGET_PASTED } from 'shared/CSharedEvents';
import MultiStyleText from '../objects/Text/MultiStyleText';
import { NO_DATA_LINK_SUPPORT } from 'shared/CSharedConstants';

export const WIDGET_PADDING = 20; // padding from the left/right sides
export const HEADER_SPACING = 18; // Spacing between items in the widget header

export default class BaseWidget extends BaseContainer {
  constructor(eventHandlers, id, delegate, data) {
    super(eventHandlers, id);

    this.category = EElementCategories.WIDGET;

    this.delegate = delegate;
    this.widgetData = data.widgetData;

    this.isResizable = false;
    this.isShowTool = false;
    this.isSelectable = true;

    this.content = new PIXI.Container();
    this.addChild(this.content);

    this.background = new PIXI.Graphics();
    this.content.addChild(this.background);

    this.dataMessage = new PIXI.BitmapText('Refresh analytics to show data', Styles.WIDGET_MESSAGE);
    this.dataMessage.tint = COLOR_WIDGET_MESSAGE;
    this.dataMessage.visible = false;
    this.dataMessage.anchor.set(0.5, 0.5);
    this.addChild(this.dataMessage);

    this.loadingMessage = new PIXI.BitmapText('Loading data, please wait!', Styles.WIDGET_MESSAGE);
    this.loadingMessage.tint = COLOR_WIDGET_MESSAGE;
    this.loadingMessage.visible = false;
    this.loadingMessage.anchor.set(0.5, 0.5);
    this.addChild(this.loadingMessage);

    this.tableView = null;
    this.containerWidth = 700; // The width of the entire widget , it can be dynamic in future
    this.containerHeight = 0; // it will be caluclated based on the row height
    this.headerHeight = 49; // The total height of the widget header
    this.numberOfRows = 5; // Number of rows to be displayed in the table
    this.rowHeight = 30; // The height of each row
    this.tableHeaderHeight = 32; // The height of the table header

    this.isAnalyticsVisible = false;
    this.isAnalyticsLoading = false;
    this.compareRange = { from: 0, to: 0 };

    this.styles = {
      icon: '',
      title: '',
    };
  }

  init() {
    this.footer.visible = false;
    this.createHeaderTitle(); // let prioritize this so that we know the size of the icon
    this.calculateContainerHeight(); // the container size will be caluclated based on the row height

    this.drawBackground();
    this.createHeader();
    this.createSubHeader();
    this.createTable();
    this.createNoDataMessage();
    this.createContent();

    this.onCreationFinished();

    this.positionHeader();
    this.positionSubHeader();
    this.positionTable();
    this.positionNoDataMessage();

    this.interactive = true;
    this.buttonMode = true;
    this.content.interactive = true;
    this.cursor = 'move';
    this.highlightElement(false);

    this.addEvents();
    this.showMessage(); // init with hidden data

    this.onInitFinished();
  }

  onInitFinished() {
    // abstract
  }

  onCreationFinished() {
    // abstract
  }

  calculateContainerHeight() {
    this.containerHeight =
      this.getHeaderHeight() +
      this.getSubHeaderHeight() +
      this.tableHeaderHeight +
      this.numberOfRows * this.rowHeight;
  }

  acceptsWheelEvents() {
    return false;
  }

  onWheel(e) {
    // abstract method
  }

  createTable() {
    this.tableView = new TableViewStatic();
    this.content.addChild(this.tableView);
  }

  createNoDataMessage() {
    const text =
      'No data available.\n\nPlease review your filter selections or visit this <a> support article</a> to troubleshoot other causes.';
    this.noDataMessage = new MultiStyleText(text, Styles.WIDGET_NODATA_LABEL);
    this.noDataMessage.anchor.set(0.5, 0.5);
    this.content.addChild(this.noDataMessage);
    this.noDataMessage.visible = false;
    this.noDataMessage.interactive = true;
    this.noDataMessage.buttonMode = true;
    this.noDataMessage.on('pointerdown', (e) => {
      e.stopPropagation();
    });
    this.noDataMessage.on('pointerup', (e) => {
      window.open(NO_DATA_LINK_SUPPORT, '_blank');
    });
  }

  createHeader() {
    this.createPagination();
    this.drawHeaderLine();
  }

  positionHeader() {
    this.positionHeaderTitle();
    this.positionPagination();
  }

  drawHeaderLine() {
    const y = this.getHeaderHeight();
    this.background.moveTo(0, y);
    this.background.lineTo(this.containerWidth, y);
  }

  createHeaderTitle() {
    this.headerIcon = new PIXI.Sprite();
    this.headerIcon.setTexture(this.styles.icon);
    this.headerIcon.anchor.set(0.5, 0.5);
    this.headerIcon.scale.set(0.5);
    this.content.addChild(this.headerIcon);

    this.headerLabel = new PIXI.BitmapText(this.styles.title, Styles.WIDGET_TITLE_LABEL);
    this.headerLabel.tint = COLOR_WIDGET_HEADER;
    this.headerLabel.anchor.set(0, 0.5);
    this.content.addChild(this.headerLabel);
  }

  createPagination() {
    this.paginationLabel = new PIXI.BitmapText('no data', Styles.WIDGET_PAGINATION_LABEL);
    this.paginationLabel.tint = COLOR_WIDGET_HEADER;
    this.paginationLabel.anchor.set(1, 0.5);
    this.content.addChild(this.paginationLabel);

    // btn-arrow-left
    const leftImageName = 'btn-arrow-left.png';
    const rightImageName = 'btn-arrow-right.png';

    this.paginationBtnLeft = new Button(
      leftImageName,
      leftImageName,
      this.onPaginationLeft.bind(this),
    );
    this.paginationBtnLeft.scale.set(1);
    this.content.addChild(this.paginationBtnLeft);
    this.paginationBtnRight = new Button(
      rightImageName,
      rightImageName,
      this.onPaginationRight.bind(this),
    );
    this.paginationBtnRight.scale.set(1);
    this.content.addChild(this.paginationBtnRight);
  }

  drawBackground() {
    this.background.clear();

    this.background.lineStyle(1, COLOR_WIDGET_OUTLINE, 1);
    this.background.beginFill(COLOR_WIDGET_BACKGROUND);
    this.background.drawRoundedRect(0, 0, this.containerWidth, this.containerHeight, 16);
    this.background.endFill();
  }

  positionHeaderTitle() {
    const y = this.getHeaderY();

    this.headerIcon.x = this.headerIcon.width / 2 + WIDGET_PADDING;
    this.headerIcon.y = y;

    this.headerLabel.x = this.headerIcon.x + this.headerIcon.width / 2 + HEADER_SPACING;
    this.headerLabel.y = y;
  }

  positionPagination() {
    const y = this.getHeaderY();

    this.paginationBtnRight.x =
      this.containerWidth - WIDGET_PADDING - this.paginationBtnRight.width / 2;
    this.paginationBtnRight.y = y - this.paginationBtnRight.height / 2;

    this.paginationBtnLeft.x =
      this.paginationBtnRight.x -
      this.paginationBtnLeft.width / 2 -
      this.paginationBtnRight.width / 2 -
      HEADER_SPACING;
    this.paginationBtnLeft.y = y - this.paginationBtnLeft.height / 2;

    this.paginationLabel.x =
      this.paginationBtnLeft.x - this.paginationBtnLeft.width / 2 - HEADER_SPACING * 2;
    this.paginationLabel.y = y;
  }

  positionNoDataMessage() {
    this.noDataMessage.x = this.containerWidth / 2;
    this.noDataMessage.y = this.containerHeight / 2 + this.getHeaderHeight() / 2;
  }

  positionTable() {
    const y = this.getHeaderHeight() + this.getSubHeaderHeight();
    this.tableView.y = y;
  }

  createSubHeader() {
    // abstract
  }

  createContent() {
    //abstract method
  }

  positionSubHeader() {
    // abstract
  }

  onWidgetDropped() {
    // abstract
  }

  onWidgetLoaded() {
    this.hideMessage();
    this.showLoadingMessage();
    this.noDataMessage.visible = false;
  }

  onAnalyticsStartedRefresh() {
    this.isAnalyticsLoading = true;
    this.showLoadingMessage();
    this.hideContent();
  }

  showLoadingMessage() {
    this.loadingMessage.x = this.containerWidth / 2;
    this.loadingMessage.y = this.containerHeight / 2 + this.getHeaderHeight() / 2;
    this.loadingMessage.visible = true;
  }

  setWidgetData(widgetData) {
    this.isAnalyticsLoading = false;
    this.widgetData = widgetData;
    this.showContent();
    this.loadingMessage.visible = false;
  }

  getSubHeaderHeight() {
    return 0;
  }

  getHeaderY() {
    // TODO determine if the icon or the title is higher
    return 49 / 2; // this.headerIcon.height / 2 + HEADER_PADDING;
  }

  getHeaderHeight() {
    return this.getHeaderY() * 2;
  }

  onPaginationLeft(e) {
    if (this.hasPreviousPage()) {
      this.tableView.currentPage--;
      this.tableView.renderRows();
      this.updatePagination();
    }
  }

  onPaginationRight(e) {
    if (this.hasNextPage()) {
      this.tableView.currentPage++;
      this.tableView.renderRows();
      this.updatePagination();
    }
  }

  updatePagination() {
    const len = this.tableView.rowsData.length;
    const startItem = this.tableView.currentPage * this.tableView.pageSize + 1;
    const endItem = Utils.clamp(startItem + this.tableView.pageSize - 1, 0, len);

    this.paginationLabel.text = `${startItem}-${endItem} of ${len} items`;

    window.app.needsRendering();
  }

  hasNextPage() {
    const len = this.tableView.rowsData.length;
    const pageSize = this.tableView.pageSize;
    return this.tableView.currentPage * pageSize + pageSize < len;
  }

  hasPreviousPage() {
    return this.tableView.currentPage > 0;
  }

  getState() {
    super.getState();
    return this.stateData;
  }

  onPaste() {
    commonSendEventFunction(PR_EVENT_WIDGET_PASTED, {
      category: this.category,
      type: this.type,
      id: this.id,
    });
  }

  onAnalyticsSwitched(status) {
    this.isAnalyticsVisible = status;
    this.handleAnalayticsVisibility();
  }

  handleAnalayticsVisibility() {
    if (this.isAnalyticsVisible && !this.widgetData) {
      this.dataMessage.visible = true;
      this.dataMessage.x = this.containerWidth / 2;
      this.dataMessage.y = this.containerHeight / 2 + this.getSubHeaderHeight() / 2;
    } else {
      this.dataMessage.visible = false;
    }

    if (this.isAnalyticsLoading && this.isAnalyticsVisible) {
      this.showLoadingMessage();
      this.hideContent();
    }

    if (this.isAnalyticsLoading) {
      this.dataMessage.visible = false;
    }
  }

  hideContent() {
    console.warn('TODO wiget needs to implement hideContent');
  }

  showContent() {
    console.warn('TODO wiget needs to implement showContent');
  }

  hideMessage() {
    this.dataMessage.visible = false;
    this.loadingMessage.visible = false;
    this.noDataMessage.visible = false;
    this.setElementsVisibility(false);
    window.app.needsRendering();
  }

  showMessage() {
    this.loadingMessage.visible = false;
    this.dataMessage.visible = false;
    this.noDataMessage.visible = false;

    this.setElementsVisibility(true);

    window.app.needsRendering();
  }

  setElementsVisibility(status) {
    if (this.tableView) {
      this.tableView.visible = !status;
    }

    if (this.paginationLabel) {
      this.paginationLabel.visible = !status;
    }

    if (this.paginationBtnLeft) {
      this.paginationBtnLeft.visible = !status;
    }

    if (this.paginationBtnRight) {
      this.paginationBtnRight.visible = !status;
    }
  }

  setCompareRange(compareRange) {
    this.compareRange = compareRange;
  }
}
