import cls from 'classnames';
import React, { useEffect, useState } from 'react';
import { iconDolar, trophyIcon } from 'react-project/assets/Icons';
import { PopUpWrapper } from 'react-project/components/popUp/PopUpWrapper';
import { Switch } from 'react-project/components/switch/Switch';
import { HEAP_EVENTS } from 'react-project/Constants/heapEvents';
import { setStepGoal } from 'react-project/redux/current-step/actions';
import { selectCurrentStep } from 'react-project/redux/current-step/selectors';
import { selectCurrentFunnelId } from 'react-project/redux/funnels/selectors';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import styles from 'react-project/Toolbar/Toolbar.module.scss';
import { sendHeapTracking } from 'react-project/Util/HEAP.utilities';
import { track as trackCohesive } from 'react-project/Util/CohesiveTracking';
import { useDispatch, useSelector } from 'react-redux';
import { validateInput } from 'shared/NumberHelpers';

const TITLE = 'Goals';

export const StepGoals = (props) => {
  const { onClose } = props;

  const currentStep = useSelector(selectCurrentStep);
  const initialGoal = currentStep.object.goal || { goalValue: '', goalName: '', hasGoal: false };

  const [hasGoal, setHasGoal] = useState(initialGoal.hasGoal);
  const [goalValue, setGoalValue] = useState(initialGoal.goalValue);
  const [goalName, setGoalName] = useState(initialGoal.goalName);
  const dispatch = useDispatch();

  const canvasPermissions = useSelector(selectCanvasPermissions);
  const funnelId = useSelector(selectCurrentFunnelId);
  const projectId = useSelector((state) => state.funnels[funnelId]?.projectId);

  useEffect(() => {
    const goal = currentStep.object.goal || { goalValue: '', goalName: '', hasGoal: false };
    dispatch(setStepGoal(currentStep.stepId, goal));
    setHasGoal(goal.hasGoal);
    setGoalValue(goal.goalValue);
    setGoalName(goal.goalName);
  }, [currentStep]);

  useEffect(() => {
    const goal = { hasGoal, goalValue, goalName };
    dispatch(setStepGoal(currentStep.stepId, goal));
  }, [hasGoal, goalValue, goalName]);

  const onGoalChange = (e) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }

    if (e.target.value === '') {
      setGoalValue('');
      return;
    }

    const isValid = validateInput(e.target);
    if (isValid) {
      setGoalValue(e.target.value);
    }
  };

  const onGoalNameChange = (e) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }
    setGoalName(e.target.value);
  };

  const onSwitchToggle = (newState) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }
    setHasGoal(newState);

    if (newState) {
      // Whenever the toggle is turned on, we send a heap event
      sendHeapTracking({
        projectId: projectId,
        eventName: HEAP_EVENTS.GOALS_ENABLED,
      });
      trackCohesive(HEAP_EVENTS.GOALS_ENABLED, {
        projectId: projectId,
      });
    }
  };

  return (
    <>
      <PopUpWrapper
        icon={trophyIcon}
        title={TITLE}
        showCloseIcon={true}
        onClosePopup={() => onClose()}
        cancel={'rndCancel'}
        minWidth="400px"
      >
        <div className="rndCancel cursor-auto h-full">
          <div className={cls(styles.Label, styles.LabelLight)}>
            Use these settings to highlight a step in the customer journey as a Conversion Goal
          </div>

          <div className={cls(styles.Label, styles.LabelGoals)}>
            <div className={styles.LabelLeft}>
              <label className={styles.SideBarLabel}>Set Element as a Goal</label>
            </div>
            <div className={styles.LabelWrapper}>
              <Switch isActive={hasGoal} onToggle={onSwitchToggle} />
            </div>
          </div>

          <div className={cls(styles.Label, styles.LabelGoals)}>
            <div className={styles.LabelLeft}>
              <label className={styles.SideBarLabel}>Goal Value</label>
              <span>
                optional - assign a monetary value to the goal - use negative values for expenses
              </span>
            </div>
            <div className={styles.LabelWrapper}>
              {iconDolar}
              <input
                placeholder="0"
                disabled={!hasGoal || canvasPermissions.isReadonlyAccess}
                autoFocus={true}
                className={styles.RsInput}
                type="text"
                value={goalValue}
                onChange={onGoalChange}
              />
            </div>
          </div>

          <div className={cls(styles.Label, styles.LabelGoals)}>
            <div className={styles.LabelLeft}>
              <label className={styles.SideBarLabel}>Goal Name</label>
              <span>optional</span>
            </div>
            <div className={styles.LabelWrapper}>
              <input
                placeholder="My Goal"
                disabled={!hasGoal || canvasPermissions.isReadonlyAccess}
                className={styles.RsInput}
                type="text"
                value={goalName}
                onChange={onGoalNameChange}
              />
            </div>
          </div>
        </div>
      </PopUpWrapper>
    </>
  );
};
