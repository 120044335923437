import {
  icon3Dots,
  iconCheck,
  analyticsIcon,
  iconHorizontalArrows,
  iconImg,
  iconNotesMenu,
  iconPeople,
  iconPlanning,
  iconSettings,
  trophyIcon,
  iconForecasting,
} from 'react-project/assets/Icons';
import { FF_CHECKLIST, FF_FORECASTING } from 'shared/FeatureFlags';

export const MENU_BTN_STEP_STYLE = 'MENU_BTN_STEP_STYLE';
export const MENU_BTN_STEP_ANALYTICS = 'MENU_BTN_STEP_ANALYTICS';
export const MENU_BTN_STEP_PLANNING = 'MENU_BTN_STEP_PLANNING';
export const MENU_BTN_STEP_MORE = 'MENU_BTN_STEP_MORE';
export const MENU_BTN_ANALYTICS_TRACKING = 'MENU_BTN_ANALYTICS_TRACKING';
export const MENU_BTN_ANALYTICS_PEOPLE = 'MENU_BTN_ANALYTICS_PEOPLE';
export const MENU_BTN_ANALYTICS_GOALS = 'MENU_BTN_ANALYTICS_GOALS';
export const MENU_BTN_PLANNING_NOTES = 'MENU_BTN_PLANNING_NOTES';
export const MENU_BTN_PLANNING_CHECKLIST = 'MENU_BTN_PLANNING_CHECKLIST';
export const MENU_BTN_PHOTO_UPLOAD = 'MENU_BTN_PHOTO_UPLOAD';
export const MENU_BTN_SHAPE_STROKE = 'MENU_BTN_SHAPE_STROKE';
export const MENU_BTN_SHAPE_FILL = 'MENU_BTN_SHAPE_FILL';
export const MENU_BTN_CONNECTION_SETTINGS = 'MENU_BTN_CONNECTION_SETTINGS';
export const MENU_BTN_CONNECTION_ANALYTICS = 'MENU_BTN_CONNECTION_ANALYTICS';
export const MENU_BTN_CONNECTION_PEOPLE = 'MENU_BTN_CONNECTION_PEOPLE';
export const MENU_BTN_CONNECTION_GOALS = 'MENU_BTN_CONNECTION_GOALS';
export const MENU_BTN_CONNECTION_STYLE_STRAIGHT = 'MENU_BTN_CONNECTION_STYLE_STRAIGHT';
export const MENU_BTN_CONNECTION_STYLE_BEZIER = 'MENU_BTN_CONNECTION_STYLE_BEZIER';
export const MENU_BTN_CONNECTION_TYPE_DIRECT = 'MENU_BTN_CONNECTION_TYPE_DIRECT';
export const MENU_BTN_CONNECTION_TYPE_SKIP = 'MENU_BTN_CONNECTION_TYPE_SKIP';
export const MENU_BTN_CONNECTION_TYPE_NODATA = 'MENU_BTN_CONNECTION_TYPE_NODATA';
export const MENU_BTN_CONNECTION_PLANNING = 'MENU_BTN_CONNECTION_PLANNING';
export const MENU_BTN_ARRANGE_HORIZONTAL = 'MENU_BTN_ARRANGE_HORIZONTAL';
export const MENU_BTN_ARRANGE_VERTICAL = 'MENU_BTN_ARRANGE_VERTICAL';
export const MENU_BTN_ALIGN_HORIZONTAL = 'MENU_BTN_ALIGN_HORIZONTAL';
export const MENU_BTN_ALIGN_VERTICAL = 'MENU_BTN_ALIGN_VERTICAL';
export const MENU_BTN_FORECASTING = 'MENU_BTN_FORECASTING';

export const menuStepButtons = [
  {
    id: MENU_BTN_STEP_STYLE,
    image: iconSettings,
    title: 'Style',
  },
  {
    id: MENU_BTN_STEP_PLANNING,
    image: iconPlanning,
    title: 'Plan',
  },
  {
    id: MENU_BTN_STEP_ANALYTICS,
    image: analyticsIcon,
    title: 'Analytics',
  },
  {
    id: MENU_BTN_STEP_MORE,
    image: icon3Dots,
    title: null,
  },
];

export const menuMiscStepButtons = [
  {
    id: MENU_BTN_STEP_STYLE,
    image: iconSettings,
    title: 'Style',
  },
  {
    id: MENU_BTN_STEP_PLANNING,
    image: iconPlanning,
    title: 'Plan',
  },
  {
    id: MENU_BTN_STEP_MORE,
    image: icon3Dots,
    title: null,
  },
];

export const menuPhotoButtons = [
  {
    id: MENU_BTN_PHOTO_UPLOAD,
    image: iconImg,
    title: 'Upload',
  },
  {
    id: MENU_BTN_STEP_MORE,
    image: icon3Dots,
    title: null,
  },
];

export const menuConnectionButtons = [
  {
    id: MENU_BTN_CONNECTION_SETTINGS,
    image: iconSettings,
    title: 'Line type',
  },
  FF_FORECASTING && {
    id: MENU_BTN_CONNECTION_PLANNING,
    image: iconPlanning,
    title: 'Plan',
  },
  {
    id: MENU_BTN_CONNECTION_ANALYTICS,
    image: analyticsIcon,
    title: 'Analytics',
  },
];

menuConnectionButtons.push({
  id: MENU_BTN_STEP_MORE,
  image: icon3Dots,
  title: null,
});

export const subMenuAnalyticsButtons = [
  {
    id: MENU_BTN_ANALYTICS_TRACKING,
    image: iconHorizontalArrows,
    title: 'Tracking',
  },
  {
    id: MENU_BTN_ANALYTICS_PEOPLE,
    image: iconPeople(15, 15),
    title: 'People',
  },
  {
    id: MENU_BTN_ANALYTICS_GOALS,
    image: trophyIcon,
    title: 'Goals',
  },
];

export const subMenuPlanningButtons = [
  {
    id: MENU_BTN_PLANNING_NOTES,
    image: iconNotesMenu(15, 15),
    title: 'Notes',
  },
  FF_CHECKLIST && {
    id: MENU_BTN_PLANNING_CHECKLIST,
    image: iconCheck,
    title: 'Checklist',
  },
  FF_FORECASTING && {
    id: MENU_BTN_FORECASTING,
    image: iconForecasting,
    title: 'Forecast',
  },
];

export const subMenuMiscPlanningButtons = [
  {
    id: MENU_BTN_PLANNING_NOTES,
    image: iconNotesMenu(15, 15),
    title: 'Notes',
  },
  FF_CHECKLIST && {
    id: MENU_BTN_PLANNING_CHECKLIST,
    image: iconCheck,
    title: 'Checklist',
  },
];

export const subMenuConnectionAnalyticsButtons = [
  {
    id: MENU_BTN_CONNECTION_PEOPLE,
    image: iconPeople(15, 15),
    title: 'People',
  },
  {
    id: MENU_BTN_CONNECTION_GOALS,
    image: trophyIcon,
    title: 'Goals',
  },
];

export const subMenuConnectionPlanningButtons = [
  {
    id: MENU_BTN_FORECASTING,
    image: iconForecasting,
    title: 'Forecast',
  },
];
