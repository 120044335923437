import {
  getConnections,
  getExplorerFilterProperties,
  getFilters,
  getOptionsProperties,
  getPeopleFilterCsvProperties,
  getPeopleFilterProperties,
  getRangeProperty,
  getTransformedDictionary,
  transformSingleElement,
} from 'react-project/Helpers/FunnelDataTransform';
import SharedElementHelpers from 'shared/SharedElementHelpers';
import { AnalyticsHelper } from 'shared/AnalyticsHelper';
import { DATA_MODE_FUNNEL } from 'shared/CSharedConstants';
import LoopDetector from 'pixi-project/utils/LoopDetector';

export const getAnalyticsRequestBody = ({
  dictionary,
  connections,
  focusedSteps,
  funnelConfiguration,
  compareMode,
}) => {
  return {
    filter: getFilterProperties({
      dictionary,
      connections,
      focusedSteps,
      funnelConfiguration,
      compareMode,
    }),
    options: getOptionsProperties(),
  };
};

export const getTrendsRequestBody = ({
  dictionary,
  connections,
  focusedSteps,
  funnelConfiguration,
  compareMode,
}) => {
  const data = {
    filter: getFilterProperties({
      dictionary,
      connections,
      focusedSteps,
      funnelConfiguration,
      compareMode,
    }),
    options: getOptionsProperties(),
  };

  data.filter.widgets = {};

  for (let i = 0; i < dictionary.length; i++) {
    const object = dictionary[i];
    if (SharedElementHelpers.IsWidget(object)) {
      if (!object.configuration || !object.configuration.steps) {
        continue;
      }

      const range = AnalyticsHelper.divideToDateRanges(
        funnelConfiguration,
        compareMode,
        object.configuration.viewDataMode,
      );

      data.filter['widget-ranges'] = range;

      const d = {
        elements: [],
        connections: [], // no connections for widgets
        range: range,
      };

      for (let j = 0; j < object.configuration.steps.length; j++) {
        const s = object.configuration.steps[j];
        if (s.ID !== 0) {
          // Remove the All People Tracked record
          d.elements.push(s.ID);
        }
      }

      data.filter.widgets[object.ID] = d;
    }
  }

  return data;
};

export const getExplorerRequestBody = ({
  funnelConfiguration,
  focusedSteps,
  search,
  pageNumber,
  exploredStep,
  limit,
  nameRequired = true,
  attributeKey,
}) => {
  return {
    filter: getExplorerFilterProperties({
      funnelConfiguration,
      focusedSteps,
      search,
      pageNumber,
      exploredStep,
      limit,
      nameRequired,
      attributeKey,
    }),
    options: getOptionsProperties(),
  };
};

export const getFilterProperties = ({
  dictionary,
  connections,
  funnelConfiguration,
  compareMode,
  focusedSteps,
}) => {
  const filterProperties = compareMode
    ? getFilters(funnelConfiguration.compareFilter, focusedSteps)
    : getFilters(funnelConfiguration.filter, focusedSteps);

  const dateProperty = compareMode
    ? getRangeProperty(funnelConfiguration.compareDateRange)
    : getRangeProperty(funnelConfiguration.dateRange);

  const transformedDictionary = getTransformedDictionary(dictionary, true);
  const transformedConnections = getConnections({ connections, dictionary });

  removeCyclicConnections(funnelConfiguration, transformedDictionary, transformedConnections);

  const focusProperty =
    focusedSteps && focusedSteps.length
      ? { focus: focusedSteps.map((itm) => transformSingleElement(itm, true)) }
      : {};

  const result = {
    ...filterProperties,
    ...focusProperty,
    range: dateProperty,
    canvas: {
      dictionary: transformedDictionary,
      connections: transformedConnections,
      isDisabled: 'false',
    },
    funnelMode: funnelConfiguration.dataMode === DATA_MODE_FUNNEL ? 'true' : 'false',
  };
  return result;
};

export const removeCyclicConnections = (
  funnelConfiguration,
  transformedDictionary,
  transformedConnections,
) => {
  if (funnelConfiguration.dataMode === DATA_MODE_FUNNEL) {
    // remove cyclic connections from the request
    try {
      let loops = null;
      if (window.app.planeContainer.sceneManager.graphData === null) {
        const detector = new LoopDetector();
        detector.loadDataFromRequest(transformedDictionary, transformedConnections);
        loops = detector.findCycles();
      } else {
        // optization,
        // if loops are being detected from the canvas
        // lets use them as they are always up to date
        loops = window.app.planeContainer.sceneManager.graphData.loops;
      }

      if (loops.length > 0) {
        loops.forEach((loop) => {
          // use only the last connection in the loop
          const connectionId = loop.connections[loop.connections.length - 1];

          // remove that connection from the request
          for (let j = transformedConnections.length - 1; j >= 0; j--) {
            const connection = transformedConnections[j];
            if (connection.id === connectionId) {
              transformedConnections.splice(j, 1);
            }
          }
        });
      }
    } catch (error) {
      console.log('Loop detection error', error);
    }
  }
};

export const getPeopleRequestBody = ({
  compareMode,
  connections,
  currentStep,
  focusedSteps,
  funnelConfiguration,
  limit,
  pageNumber,
  dataConnections,
  dataObjs,
}) => {
  return getPeopleFilterProperties({
    compareMode,
    connections,
    currentStep,
    focusedSteps,
    funnelConfiguration,
    limit,
    pageNumber,
    dataConnections,
    dataObjs,
  });
};

export const getPeopleCSVRequestBody = ({
  compareMode,
  connections,
  currentStep,
  focusedSteps,
  funnelConfiguration,
  dataConnections,
  dataObjs,
}) => {
  return getPeopleFilterCsvProperties({
    compareMode,
    connections,
    currentStep,
    focusedSteps,
    funnelConfiguration,
    dataConnections,
    dataObjs,
  });
};
