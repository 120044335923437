import React from 'react';
import { iconVersionHistory } from 'react-project/assets/Icons';
import { DropdownBlock } from 'react-project/components/dropdown/DropdownBlock';
import { DropdownItem } from 'react-project/components/dropdown/DropdownItem';
import { TEXT_VERSION_HISTORY } from 'react-project/Constants/texts';

export const VersionHistoryDropdown = ({ onClick }) => {
  return (
    <DropdownBlock key="block-1">
      <DropdownItem
        icon={iconVersionHistory}
        onClick={onClick}
        label={TEXT_VERSION_HISTORY}
        key={TEXT_VERSION_HISTORY}
      />
    </DropdownBlock>
  );
};
