import cls from 'classnames';
import produce from 'immer';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import {
  iconDesktopDevice,
  iconMobileDevice,
  iconRefreshBtn,
  iconUserInfo,
} from 'react-project/assets/Icons';
import styles from 'react-project/components/userProfile/UserProfile.module.scss';
import { incrementPopupZIndex } from 'react-project/Toolbar/step-toolbar/PeopleTracking/peopleTrackingSlice';
import { When } from 'react-project/Util/When';
import { useDispatch, useSelector } from 'react-redux';
import { IconsConfig } from 'shared/CSharedConstants';
import { roundTo } from 'shared/CSharedMethods';
import { PopUpWrapper } from '../popUp/PopUpWrapper';
import { TablePopup } from '../popUp/tablePopup/TablePopup';
import {
  POSITIONING_RIGHT_TO_LEFT,
  ViewportConstrainer,
} from '../viewportConstrainer/ViewportConstrainer';

const TITLE = 'User information';
export const UserProfile = (
  {
    userID,
    name,
    email,
    countryCode,
    countryName,
    city,
    isMobile,
    source,
    createdAt,
    journey,
    isLoading,
    transactions,
    spend,
    onUserProfileClose,
    position = { x: 0, y: 0 },
    widgetBounds = { x: 0, y: 0, width: 0, height: 0 },
  },
  ref,
) => {
  let lastSeen = null;

  const journeyData = journey.map((item, index) => {
    let srcPath = '';
    let name = '';
    let details = null;

    if (item.type === 'step') {
      srcPath = IconsConfig.PAGE[0].src;
      name = IconsConfig.PAGE[0].name;
      details = item.params;
    } else {
      srcPath = IconsConfig.EVENT[1].src;
      name = IconsConfig.EVENT[1].name;
      details = item.attrs;
    }

    let src = srcPath + name.replace(/ /g, '').toLocaleLowerCase() + '.svg';

    return {
      index,
      title: item.name,
      type: item.type,
      icon: src,
      createdAt: item.created_at,
      amount: '',
      stepTitle: '',
      stepURL: item.page,
      details,
      isSelected: false,
    };
  });

  if (journeyData && journeyData.length) {
    lastSeen = journeyData[0].create_at;
  }
  const dispatch = useDispatch();
  const refPopupWrapper = useRef();
  const [profileDetails, setProfileDetails] = useState([]);
  const [journeyStateData, setJourneyStateData] = useState(journeyData);
  const zIndex = useSelector((state) => state.peopleTracking.popupZIndex);

  useEffect(() => {
    setJourneyStateData(journeyData);
  }, [journey]);

  const toLocalDate = (dateTimeString) => {
    return moment(new Date(dateTimeString)).format('MMM Do, YYYY');
  };

  const toLocalDateTime = (dateTimeString) => {
    return moment(new Date(dateTimeString)).format('MMM Do YYYY, h:mm a');
  };

  const toLocalTimeDifference = (dateTimeString) => {
    return moment(new Date(dateTimeString)).fromNow();
  };

  const timeDifference = (startTimeString, endTimeString) => {
    const start = moment(new Date(startTimeString));
    const end = moment(new Date(endTimeString));

    const duration = moment.duration(end.diff(start));

    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    const milliseconds = duration.milliseconds();

    let timeString = '0 sec';
    if (hours > 0) {
      timeString = `${hours} hours`;
    } else if (minutes > 0) {
      timeString = `${minutes} min`;
    } else if (seconds > 0) {
      timeString = `${seconds} sec`;
    } else if (milliseconds > 0) {
      timeString = `${roundTo(milliseconds / 1000, 1)} sec`;
    }

    return timeString;
  };

  const onJourneyClicked = (index) => {
    const newProfileDetails = produce(profileDetails, (draft) => {
      const existingItem = draft.find((item) => item && item.index === index);
      if (existingItem) {
        const itemPosition = draft.indexOf(existingItem);
        draft.splice(itemPosition, 1);
      } else {
        draft.push(journeyData[index]);
      }
    });
    setProfileDetails(newProfileDetails);

    const newJourneyData = produce(journeyStateData, (draft) => {
      draft[index].isSelected = !draft[index].isSelected;
    });

    setJourneyStateData(newJourneyData);
  };

  const closeTableItem = (index) => {
    let itemIndex = -1;
    const newProfileDetails = produce(profileDetails, (draft) => {
      if (draft[index]) {
        itemIndex = draft[index].index;
      }
      delete draft[index];
    });
    setProfileDetails(newProfileDetails);

    const newJourneyData = produce(journeyStateData, (draft) => {
      draft[itemIndex].isSelected = false;
    });

    setJourneyStateData(newJourneyData);
  };

  const createTableDetails = (item, index) => {
    const itemsData = [];
    if (item.details) {
      item.details.forEach((detail) => {
        itemsData.push({
          key1: <div> {detail.key} </div>,
          key2: (
            <div style={{ textAlign: 'right' }}>{detail.value ? detail.value.toString() : ''}</div>
          ),
        });
      });
    }

    return {
      id: 'details-table-popup-' + index,
      rows: {
        columnWidths: ['auto', 'auto'],
        columnsNumber: 2,
        rowHeaders: {
          keys: [
            <div style={{ textAlign: 'left' }}>Key</div>,
            <div style={{ textAlign: 'right' }}>Value</div>,
          ],
        },
        items: itemsData,
      },
      title: 'Step Properties',
      popupWidth: 405,
      popupHeight: 220,
      position: { x: 0, y: 0 },
      onPointerDown: (e) => {},
    };
  };

  const createContent = () => {
    return (
      <div
        ref={refPopupWrapper}
        onPointerDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className={styles.Content}
      >
        <div className={styles.Row}>
          <div className={cls(styles.Column, styles.UserName)}>
            <When condition={!(name && email)}>
              <label>{userID.toString().substring(0, 8)}</label>
            </When>
            <When condition={name || email}>
              <When condition={name}>
                <label>{name}</label>
              </When>
              <When condition={!name}>
                <label>{userID.toString().substring(0, 8)}</label>
              </When>
              <When condition={email}>
                <span>{email}</span>
              </When>
            </When>
          </div>
          <div className={styles.VSeparator}></div>
          <div className={cls(styles.Column, styles.UserCountry)}>
            <When condition={countryCode}>
              <span className={cls(`flag flag-${countryCode.toString().toLowerCase()}`)}></span>
            </When>
            <div>
              <label>{countryName}</label>
              <span>{city}</span>
            </div>
          </div>
        </div>

        <div className={styles.HSeparator}></div>

        <div className={styles.Row}>
          <div className={cls(styles.Column, styles.UserPlatform)}>
            {isMobile ? iconMobileDevice : iconDesktopDevice}
            <span>{isMobile ? 'Mobile' : 'Desktop'}</span>
          </div>
          <div className={styles.VSeparator}></div>
          <div className={cls(styles.Column, styles.UserVisits)}>
            <p>
              {/* First touch via <b>{source}</b>  */}
              First touch on <b>{toLocalDate(createdAt)}</b>
            </p>
            {/* <When condition={lastSeen}>
              <p>
                Last seen <b>{toLocalTimeDifference(lastSeen)}</b>
              </p>
            </When> */}
          </div>
        </div>

        {/* <div className={styles.HSeparator}></div>

        <div className={styles.Row}>
          <div className={cls(styles.Column)}>
            <span className={styles.SubTitle}>Transactions</span>
            <span className={styles.Value}>{transactions}</span>
          </div>
          <div className={styles.VSeparator}></div>
          <div className={cls(styles.Column)}>
            <span className={styles.SubTitle}>Spend</span>
            <span className={styles.Value}>${spend}</span>
          </div>
        </div> */}

        <div className={styles.HSeparator}></div>

        <div className={styles.Row}>
          <div className={cls(styles.Column)}>
            <span className={styles.SubTitle}>Journey</span>
          </div>
        </div>

        <When condition={isLoading}>
          <div className={styles.Row}>
            <div className={cls(styles.Column)}>
              <div className={styles.Loader}>{iconRefreshBtn}</div>
            </div>
          </div>
        </When>

        {journeyStateData.map((item, index, itemsList) => {
          return (
            <div key={index} className={cls(styles.Row)}>
              <div
                onClick={(e) => {
                  onJourneyClicked(index);
                }}
                className={cls(
                  styles.Column,
                  styles.JourneyRow,
                  item.isSelected ? styles.JourneyRow_Active : null,
                )}
              >
                <img className={styles.JourneyIcon} src={item.icon} />
                <div className={styles.JourneyContent}>
                  <div className={styles.JourneyTitleSection}>
                    <span className={styles.JourneyTitle}>{item.title}</span>
                    <span className={styles.JourneyDate}>{toLocalDateTime(item.createdAt)}</span>
                  </div>
                  <div className={styles.JourneyDetailSection}>
                    <div>
                      <span className={styles.JourneyURLTitle}>{item.stepTitle}</span>
                      <span className={styles.JourneyURL}>{item.stepURL}</span>
                    </div>
                    <span className={styles.JourneyAmount}>{item.amount}</span>
                  </div>
                </div>
                <When condition={index > 0 && index < itemsList.length}>
                  <div className={styles.JourneyRelativeConnection}>
                    <span>
                      {itemsList[index - 1]
                        ? timeDifference(item.createdAt, itemsList[index - 1].createdAt)
                        : '-'}
                    </span>
                  </div>
                </When>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const onClosePopup = () => {
    if (onUserProfileClose) {
      onUserProfileClose(userID);
    }
  };

  let ind = 0;

  return (
    <>
      <ViewportConstrainer relativeRectangle={widgetBounds}>
        <PopUpWrapper
          onClosePopup={onClosePopup}
          icon={iconUserInfo}
          children={createContent()}
          title={TITLE}
          showCloseIcon={true}
          position={{ x: 0, y: 0 }}
          isDraggable={true}
          minWidth="400px"
          onDragStart={(e, node) => {
            node.node.parentNode.style.zIndex = zIndex;
            dispatch(incrementPopupZIndex());
          }}
        ></PopUpWrapper>
      </ViewportConstrainer>
      {profileDetails.map((item, index) => {
        return (
          <ViewportConstrainer
            positioning={POSITIONING_RIGHT_TO_LEFT}
            relativeElement={refPopupWrapper.current}
            key={index}
          >
            <TablePopup
              title="Title"
              {...createTableDetails(item, index)}
              onDeleteBtn={() => {}}
              onClose={(e) => {
                closeTableItem(index);
              }}
              onTableClosed={() => {}}
            />
          </ViewportConstrainer>
        );
      })}
    </>
  );
};

UserProfile.defaultProps = {
  userID: '',
  name: null,
  email: null,
  countryCode: '',
  countryName: 'Unknown',
  city: 'Unknown',
  isMobile: false,
  source: 'Facebook',
  createdAt: 'Jan 01,2020',
  lastSeen: '3 hours ago',
  transactions: 0,
  spend: 0,
  isLoading: true,
  position: { x: 0, y: 0 },
  widgetBounds: { x: 0, y: 0, width: 0, height: 0 },
};
