import cls from 'classnames';
import React from 'react';
import styles from './NotAvailable.module.scss';

export const NotAvailable = ({ onClose }) => {
  const onBtnClose = () => {
    onClose();
  };

  const learnMore = () => {
    window.open('https://funnelytics.io');
  };

  return (
    <div id="popup1" className={cls(styles.Overlay, styles.OverlayActive)}>
      <div className={styles.popup}>
        <h2>This feature is not available for this canvas</h2>
        <div className={styles.close} onClick={onBtnClose}>
          &times;
        </div>
        <div className={styles.content}>
          Please visit funnelytics.io for the full list of features available.
        </div>

        <div onClick={learnMore} className={styles.LearnMore}>
          Visit funnelytics.io
        </div>
        <div onClick={onBtnClose} className={styles.BackToCanvas}>
          Back to canvas
        </div>
      </div>
    </div>
  );
};

NotAvailable.propTypes = {};
