import cls from 'classnames';
import React from 'react';
import { TooltipWithHotkey } from '../components/tooltip/TooltipWithHotkey';
import { ExtendableItem } from './ExtendableItem';
import styles from './ToolsBox.module.scss';
import { UILock } from 'react-project/components/uilock/UILock';

const ToolsItem = React.forwardRef(
  (
    {
      id,
      icon,
      extended,
      open,
      openExtended,
      onSelectItem,
      type,
      createObject,
      notifyDragStarted,
      event,
      active,
      isSelected,
      tooltipLabel,
      selectedItem,
      actionName,
      isFillActive,
      isLocked = false,
    },
    ref,
  ) => {
    const onSelect = (el) => {
      onSelectItem(el);
    };

    const onDragEnd = (el) => {
      onSelectItem(el);
      createObject && createObject(el.type);
    };

    const startDraggingText = (elType, e) => {
      notifyDragStarted && notifyDragStarted(elType, e);
    };

    let ActiveClass = active ? styles.Active : '';
    let ExtendedClass = !!extended ? styles.Extended : '';
    let ActiveIconClass;
    if (active && !extended) {
      ActiveIconClass = styles[icon.props.id];
    } else if (active && !!extended) {
      ActiveIconClass = styles[icon.props.id];
    } else {
      ActiveIconClass = '';
    }

    const className = cls(`${styles.Item} ${ActiveClass} ${ExtendedClass} ${ActiveIconClass}`, {
      [styles.Selected]: isSelected,
    });

    return (
      <>
        {!extended && (
          <TooltipWithHotkey label={tooltipLabel || ''} actionName={actionName}>
            <UILock isLocked={isLocked} style={{ width: '47px' }}>
              <div ref={ref} id={id} className={className} onClick={() => event && event()}>
                <div className={styles.IconWithTrigger}>
                  <div
                    draggable={true}
                    className={styles.Icon}
                    onDragStart={(e) => {
                      e.preventDefault();
                      startDraggingText(type, e);
                    }}
                  >
                    {icon}
                  </div>
                </div>
              </div>
            </UILock>
          </TooltipWithHotkey>
        )}
        {extended && (
          <ExtendableItem
            onOpen={openExtended ? () => openExtended(type) : event && (() => event())}
            onDragStart={(e) => {
              e.preventDefault();
              startDraggingText(type, e);
            }}
            icon={icon}
            open={open}
            dropdownItems={extended}
            onDragEnd={(el) => onDragEnd(el)}
            onSelectItem={(el) => onSelect(el)}
            selectedItem={selectedItem}
            tooltipLabel={tooltipLabel}
            classes={{ ExtendedClass, ActiveClass, ActiveIconClass }}
            onItemClick={() => event && event()}
            type={type}
            isFillActive={isFillActive}
          />
        )}
      </>
    );
  },
);

export default ToolsItem;
