import cls from 'classnames';
import React from 'react';
import styles from './Button.module.scss';

export const Button = ({ className, onClick, disabled, children, style, ...props }) => {
  return (
    <button
      className={cls(styles.BrandBlue, className)}
      onClick={onClick}
      disabled={disabled}
      style={style}
      {...props}
    >
      {children}
    </button>
  );
};
