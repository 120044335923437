import React from 'react';
import { iconHelp } from 'react-project/assets/Icons';
import { useSelector } from 'react-redux';

export const HelpLogo = () => {
  const tutorialLoading = useSelector((state) => state.tutorial.isLoading);
  const tasksToComplete = useSelector((state) => state.tutorial.toComplete);
  const isSingleDigit = tasksToComplete > 9;
  return (
    <div>
      <div>{iconHelp}</div>
      {tutorialLoading || tasksToComplete === 0 || tasksToComplete === null ? null : (
        <>
          <div
            style={{
              position: 'fixed',
              transform: 'translate(11px, -7px)',
              fontSize: '12px',
              border: '1px solid red',
              borderRadius: '50%',
              width: '19px',
              height: '19px',
              background: 'red',
            }}
          />
          <div
            style={{
              position: 'fixed',
              transform: isSingleDigit ? 'translate(14px, -7px)' : 'translate(16.5px, -7px)',
              fontSize: '12px',
              color: 'white',
            }}
          >
            {tasksToComplete}
          </div>
        </>
      )}
    </div>
  );
};
