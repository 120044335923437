import {
  EConnectionDrawLineType,
  EConnectionLineType,
} from 'pixi-project/base/joint/CConnectionConstants';
import React, { forwardRef } from 'react';
import {
  iconBeizerLine,
  iconDirectLineType,
  iconDottedLineType,
  iconNoDataLineType,
  iconStraightLine,
} from 'react-project/assets/Icons';
import {
  VERTICAL_ALIGN_LEFT,
  ViewportConstrainer,
} from 'react-project/components/viewportConstrainer/ViewportConstrainer';
import { MenuImageButton } from '../Components/MenuItems/MenuImageButton';
import { MenuSectionButton } from '../Components/MenuItems/MenuSectionButton';
import {
  MENU_BTN_CONNECTION_STYLE_BEZIER,
  MENU_BTN_CONNECTION_STYLE_STRAIGHT,
  MENU_BTN_CONNECTION_TYPE_DIRECT,
  MENU_BTN_CONNECTION_TYPE_NODATA,
  MENU_BTN_CONNECTION_TYPE_SKIP,
} from '../MenusConfiguration';
import styles from './LineSettingsSubMenu.module.scss';

export const LineSettingsSubMenu = forwardRef(
  ({ toolbarData, sectionStyle, onButtonClicked }, ref) => {
    const onClick = (e, reference, id) => {
      onButtonClicked(e, reference, id);
    };

    const isLineSupported = (lineType) => {
      return toolbarData.supportedLineTypes.includes(lineType);
    };

    return (
      <ViewportConstrainer verticalAlign={VERTICAL_ALIGN_LEFT} relativeRectangle={sectionStyle}>
        <div ref={ref} className={styles.Menu}>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={styles.MenuWrapper}
          >
            <MenuSectionButton title="Style" />

            <MenuImageButton
              id={MENU_BTN_CONNECTION_STYLE_STRAIGHT}
              image={iconStraightLine}
              title="Straight line"
              isActive={toolbarData.commonDrawLineType === EConnectionDrawLineType.STRAIGHT}
              onClick={onClick}
            />

            <MenuImageButton
              id={MENU_BTN_CONNECTION_STYLE_BEZIER}
              image={iconBeizerLine}
              title="Curved line"
              isActive={toolbarData.commonDrawLineType === EConnectionDrawLineType.BEZIER}
              onClick={onClick}
            />

            <MenuSectionButton title="Connection" />

            {isLineSupported(EConnectionLineType.SOLID) ? (
              <MenuImageButton
                id={MENU_BTN_CONNECTION_TYPE_DIRECT}
                image={iconDirectLineType}
                title="Direct connection"
                isActive={toolbarData.commonLineType === EConnectionLineType.SOLID}
                onClick={onClick}
              />
            ) : null}

            {isLineSupported(EConnectionLineType.DOTTED) ? (
              <MenuImageButton
                id={MENU_BTN_CONNECTION_TYPE_SKIP}
                image={iconDottedLineType}
                title="Skip steps"
                isActive={toolbarData.commonLineType === EConnectionLineType.DOTTED}
                onClick={onClick}
              />
            ) : null}

            {isLineSupported(EConnectionLineType.NODATA) ? (
              <MenuImageButton
                id={MENU_BTN_CONNECTION_TYPE_NODATA}
                image={iconNoDataLineType}
                title="No data"
                isActive={toolbarData.commonLineType === EConnectionLineType.NODATA}
                onClick={onClick}
              />
            ) : null}
          </div>
        </div>
      </ViewportConstrainer>
    );
  },
);
