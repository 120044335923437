import { produce } from 'immer';
import { handleActions } from 'redux-actions';
import {
  setIsModalOpened,
  setIsStepModalOpened,
  setIsStepModalPinned,
  setStepModalData,
  resetStepModalData,
} from './actions';

const STEP_MODAL_DEFAULT_STATE = {
  activeStepsModalTab: false,
  canExplore: false,
  coords: {
    x: 5,
    y: 5,
  },
  createObjectPositions: {
    x: 5,
    y: 5,
  },
  funnel: undefined,
  hasWorkspaceActions: false,
  isParentHeader: false,
  onCloseModal: () => {},
  onStepsModalTabChanged: false,
  port: '',
};

const initialState = {
  opened: false,
  stepModalOpened: false,
  stepModalPinned: false,
  stepModalData: STEP_MODAL_DEFAULT_STATE,
};

export default handleActions(
  {
    [setIsModalOpened.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.opened = action.payload;
      }),
    [setIsStepModalOpened.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.stepModalOpened = action.payload;
      }),
    [setIsStepModalPinned.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.stepModalPinned = action.payload;
      }),
    [setStepModalData.toString()]: (state, action) =>
      produce(state, (draft) => {
        const stepModalData = {
          ...draft.stepModalData,
          ...action.payload.data,
        };
        draft.stepModalData = stepModalData;
      }),
    [resetStepModalData.toString()]: (state) =>
      produce(state, (draft) => {
        draft.stepModalData = {
          ...draft.stepModalData,
          ...STEP_MODAL_DEFAULT_STATE,
        };
      }),
  },
  initialState,
);
