import React from 'react';

import styles from './Main.module.scss';

export const NotFound = ({ message = 'Not Found' }) => {
  return (
    <div className={styles.NotFound}>
      <h1>{message}</h1>
    </div>
  );
};

export default NotFound;
