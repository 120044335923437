import cls from 'classnames';
import React, { forwardRef } from 'react';
import { iconArrow } from 'react-project/assets/Icons';
import { Dropdown } from 'react-project/components/dropdown/Dropdown';
import versionHistoryStyle from 'react-project/components/versionHistory/VersionHistory.module.scss';
import styles from 'react-project/Header/Header.module.scss';
import { ClickOutsideCustomComponent } from '../Util/ClickOutsideCustom';
import { useSelector } from 'react-redux';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import { When } from 'react-project/Util/When';

const DropdownTrigger = forwardRef(({ canvasName, isOpen, value, isDisabled, ...rest }, ref) => {
  return (
    <div ref={ref} className={styles.HeaderActionsDropdownTrigger} {...rest}>
      <div className={styles.CanvasName}>{canvasName}</div>
      <When condition={!isDisabled}>
        <div
          className={cls(styles.TriggerIcon, {
            [styles.TriggerIconOpened]: isOpen,
          })}
        >
          {iconArrow}
        </div>
      </When>
    </div>
  );
});

export const HeaderActionsDropdown = ({
  setDropdownToggle,
  dropdownOpened,
  canvasName,
  triggerSlot,
  dropdownBlock,
}) => {
  const canvasPermissions = useSelector(selectCanvasPermissions);

  return (
    <Dropdown
      contentClassName={cls(
        styles.HeaderActionsDropdownContent,
        versionHistoryStyle.VersionHistoryDropDownItem,
      )}
      triggerSlot={
        triggerSlot || (
          <DropdownTrigger
            canvasName={canvasName}
            isDisabled={canvasPermissions.isReadonlyAccess}
          />
        )
      }
      className={styles.HeaderActionsDropdown}
      onToggle={(opened) => !canvasPermissions.isReadonlyAccess && setDropdownToggle(opened)}
      isOpen={!canvasPermissions.isReadonlyAccess && dropdownOpened}
    >
      <ClickOutsideCustomComponent
        onClickOutside={() => {
          setDropdownToggle(false);
        }}
      >
        {dropdownBlock}
      </ClickOutsideCustomComponent>
    </Dropdown>
  );
};
