import React, { useEffect, useRef, useState } from 'react';
import styles from './NotesBlock.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectCanvasNote, selectNotes } from 'react-project/redux/notes/selectors';
import { RichTextEditor, isEmptyDraftJs } from 'react-project/RichTextEditor/RichTextEditor';
import { setCanvasNote, setNote } from 'react-project/redux/notes/actions';
import { cloneData, commonSendEventFunction } from 'shared/CSharedMethods';
import {
  RP_EVENT_NOTE_EDITING_FINISHED,
  RP_EVENT_ON_NOTE_EDITED,
  PR_EVENT_OBJECT_SELECTED,
  PR_NOTES_ADDED,
} from 'shared/CSharedEvents';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';

export const CANVAS_NOTE_ID = 'canvas-note';

export const NotesBlock = ({ isCanvas, note, onNoteRemoved, step, onNoteEdited }) => {
  const canvasNote = useSelector(selectCanvasNote);
  const objectNotes = useSelector(selectNotes);

  const [noteBeforeEdit, setNoteBeforeEdit] = useState(null);
  const canvasPermissions = useSelector(selectCanvasPermissions);

  const canvasNoteRef = useRef(canvasNote);
  const objectNotesRef = useRef(objectNotes);

  const dispatch = useDispatch();

  const _selectedNote = isCanvas
    ? {
        id: CANVAS_NOTE_ID,
        noteData: canvasNote,
      }
    : {
        id: step.stepId,
        noteData: step.object.notes
          ? step.object.notes
          : {
              title: step.object.label,
              subTitle: '',
              data: null,
              timestamp: new Date().toISOString(),
              type: step.object.type,
              category: step.object.category,
            },
      };

  // Its important as selected note needs to be defined only once and not on every render
  const [selectedNote, setSelectedNote] = useState(_selectedNote);

  // const isEmpty = isEmptyDraftJs(selectedNote.noteData.data);

  useEffect(() => {
    document.addEventListener(PR_NOTES_ADDED, onNotesAdded, false);
    return () => {
      document.removeEventListener(PR_NOTES_ADDED, onNotesAdded);
    };
  }, []);

  useEffect(() => {
    if (isCanvas) {
      return;
    }

    canvasNoteRef.current = canvasNote;
    objectNotesRef.current = objectNotes;
  }, [objectNotes, canvasNote]);

  const onEditorContentChanged = (newRaw, content) => {
    const plainText = content.getPlainText() || '';
    const subTitle = plainText.split('\n')[0].trim();
    if (isCanvas) {
      const data = {
        ...selectedNote.noteData,
        data: newRaw,
        timestamp: new Date().toISOString(),
        subTitle,
      };
      dispatch(setCanvasNote(data));
      commonSendEventFunction(RP_EVENT_ON_NOTE_EDITED, { id: CANVAS_NOTE_ID, noteData: data });
    } else if (selectedNote && selectedNote.id) {
      const data = {
        id: selectedNote.id,
        noteData: {
          ...selectedNote.noteData,
          data: newRaw,
          timestamp: new Date().toISOString(),
          subTitle,
        },
        isEmpty: isEmptyDraftJs(newRaw),
      };
      dispatch(setNote(data));
      commonSendEventFunction(RP_EVENT_ON_NOTE_EDITED, data);
      onNoteEdited(selectedNote.id, data.noteData);
    }
  };

  const onImageSrcUpdated = (dataId, randomKey, imageURL) => {
    if (isCanvas) {
      // update canvas note
      const data = cloneData(canvasNoteRef.current);
      updateImageByKey(data, randomKey, imageURL);
      dispatch(setCanvasNote(data));
      commonSendEventFunction(RP_EVENT_ON_NOTE_EDITED, { id: CANVAS_NOTE_ID, noteData: data });
    } else {
      // update object note
      const note = objectNotesRef.current.find((itm) => itm.id === dataId);
      const noteData = cloneData(note.noteData);
      updateImageByKey(noteData, randomKey, imageURL);
      const data = {
        id: note.id,
        noteData: noteData,
      };
      dispatch(setNote(data));
      commonSendEventFunction(RP_EVENT_ON_NOTE_EDITED, data);
      onNoteEdited(note.id, data);
    }
  };

  const updateImageByKey = (data, randomKey, imageURL) => {
    const objectKeys = Object.keys(data.data.entityMap);
    for (let i = 0; i < objectKeys.length; i++) {
      const oKey = objectKeys[i];
      const entity = data.data.entityMap[oKey];
      if (entity.data.randomKey === randomKey) {
        entity.data.src = imageURL;
      }
    }
    data.timestamp = new Date().toISOString();
  };

  const onTextAreaFocus = () => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }
    setNoteBeforeEdit(cloneData(selectedNote));
    // hide element menu
    commonSendEventFunction(PR_EVENT_OBJECT_SELECTED, {
      show: false,
    });
  };

  const onTextAreaBlur = () => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }
    commonSendEventFunction(RP_EVENT_NOTE_EDITING_FINISHED, {
      oldData: noteBeforeEdit,
    });
  };

  const onNoteDeleteClicked = (e) => {
    onNoteRemoved(selectedNote.id);
  };

  const onNotesAdded = (e) => {
    if (e.detail.id === selectedNote.id) {
      setSelectedNote({
        id: e.detail.id,
        noteData: e.detail.noteData,
      });
    }
  };

  return (
    <div className={styles.NotesWrapper}>
      <div className={styles.NotesTextEditorBlock}>
        <RichTextEditor
          onFocus={onTextAreaFocus}
          onBlur={onTextAreaBlur}
          placeholder={'Write a note...'}
          dataId={selectedNote.id}
          rawContent={selectedNote.noteData.data}
          onEditorContentChanged={onEditorContentChanged}
          onImageSrcUpdated={onImageSrcUpdated}
          showDeleteButton={selectedNote.id !== CANVAS_NOTE_ID}
          onDeleteButtonClicked={onNoteDeleteClicked}
        />
      </div>
    </div>
  );
};
