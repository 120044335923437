import { TrackerSessionType, TrackerStepParamType, TrackerStepType } from './types';

export default (serializer) => {
  serializer.register(TrackerStepType, {
    relationships: {
      params: {
        type: TrackerStepParamType,
      },
      sessions: {
        type: TrackerSessionType,
      },
    },
  });

  serializer.register(TrackerStepParamType);
};
