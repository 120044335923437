import React from 'react';
export const ExplorerFreeTrial = ({ onBtnClicked }) => {
  return (
    <>
      <div>
        <p>
          Start Your <b>Free Trial</b> Today and Unleash the Power of the <b>Step Explorer!</b>
        </p>
        <p>
          Start your free trial now to gain access to the <b>Step Explorer</b>, the ultimate tool
          for analyzing your traffic sources, campaigns, and pages.
        </p>
        <p>
          Dive deep into your data and uncover valuable insights that will revolutionize your custom
          journey optimization.
        </p>
        <p>
          It's your chance to experience the complete Funnelytics package and maximize your
          marketing success.
        </p>
        <p>
          Don't miss out on this exclusive opportunity. Start your free trial today and discover the
          true power of Funnelytics!
        </p>

        <button onClick={onBtnClicked}>Start Your Free Trial</button>
      </div>
    </>
  );
};
