import React, { useEffect, useState } from 'react';
import styles from './CreateAccountBanner.module.scss';
import { iconClose } from 'react-project/assets/Icons';
import cls from 'classnames';

export const CreateAccountBanner = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isClosedByUser, setIsClosedByUser] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const onCloseBtnClicked = () => {
    setIsClosedByUser(true);
  };

  const onGetStartedBtnClicked = () => {
    window.open(
      'https://funnelytics.io/pricing-table/?utm_source=app&utm_medium=canvas&utm_campaign=tutorial',
      '_self',
    );
  };

  return !isClosedByUser && isOpen ? (
    <div className={styles.Wrapper}>
      <div className={styles.Content}>
        This is a DEMO tutorial. Get started with your own Funnelytics account!
        <div onClick={onGetStartedBtnClicked} className={styles.Button}>
          Get Started Now
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
