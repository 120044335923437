import { produce } from 'immer';
import { handleActions } from 'redux-actions';

import { setUIStateAction } from './actions';

const initialState = {
  lastMappingTab: null,
  lastLeftSideTab: null,
};

export default handleActions(
  {
    [setUIStateAction.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft[action.payload.key] = action.payload.value;
      }),
  },
  initialState,
);
