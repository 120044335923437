import React, { useEffect, useState } from 'react';
import { AttributeExplorerList } from './AttributeExplorerList';
import styles from 'react-project/Toolbar/step-toolbar/AttributeExplorer/AttributeExplorerList.module.scss';
import { iconRefreshBtn, iconCheckmark, iconAnalyticsWarning } from 'react-project/assets/Icons.js';
import { useSelector } from 'react-redux';
import { selectCurrentStep } from 'react-project/redux/current-step/selectors';
import { selectFunnelConfiguration } from 'react-project/redux/funnel-configuration/selectors';
import { selectFocusedSteps } from 'react-project/redux/focused-step/selectors';
import { getExplorerRequestBody } from 'react-project/Helpers/requestConstruction';
import RequestService from 'react-project/Helpers/RequestService';
import { When } from 'react-project/Util/When';
import axios from 'axios';
import { extractSelectedItems, transformToFilterData } from './AttributeHelper';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { RP_EVENT_ELEMENT_SPREAD } from 'shared/CSharedEvents';

let source = axios.CancelToken.source();
const ITEMS_ON_PAGE = 20;
const ATTRIBUTE_TYPE = { common: 'common', custom: 'custom' };

const DEFAULT_PAGINATION_STATE = {
  [ATTRIBUTE_TYPE.common]: {},
  [ATTRIBUTE_TYPE.custom]: {},
};

export const AttributeExplorer = ({
  selectedData,
  onAttributesChanged,
  projectId,
  trackingURL,
}) => {
  const funnelConfiguration = useSelector(selectFunnelConfiguration);
  const currentStep = useSelector(selectCurrentStep);
  const focusedSteps = useSelector(selectFocusedSteps);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [commonParametersExpanded, setCommonParametersExpanded] = useState({});
  const [customParametersExpanded, setCustomParametersExpanded] = useState({});
  const [pageParameters, setPageParameters] = useState({
    common_parameters: [],
    custom_parameters: [],
    hits: null,
  });
  const [hasChange, setHasChange] = useState(false);
  const [lastRequest, setLastRequest] = useState(null);
  const [canLoadMoreConfig, setCanLoadMoreConfig] = useState({
    [ATTRIBUTE_TYPE.common]: {},
    [ATTRIBUTE_TYPE.custom]: {},
  });
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION_STATE);
  const [isMoreLoading, setIsMoreLoading] = useState(false);

  useEffect(() => {
    getAttributeExplorerData();
    return () => {
      source.cancel('Attribute explorer window was closed');
    };
  }, []);

  useEffect(() => {
    checkForChanges();
  }, [selectedData, trackingURL]);

  const getAttributeExplorerData = async () => {
    setHasChange(false);
    setIsRefreshing(true);
    setPageParameters({
      common_parameters: [],
      custom_parameters: [],
      hits: null,
    });

    const reqBody = prepareRequestBody();
    setLastRequest(reqBody);

    source = axios.CancelToken.source();
    const requestService = new RequestService();
    const responseData = await requestService.getAttributeExplorerPageParams(
      projectId,
      reqBody,
      source,
    );

    if (axios.isCancel(responseData)) {
      // The request was canceled, do nothing
    } else if (responseData) {
      // When we get the response, we will collapse all expanded rows
      setCommonParametersExpanded({});
      setCustomParametersExpanded({});
      setPageParameters(responseData);
      createLoadMoreConfig(responseData);
      setIsRefreshing(false);
      // Set data loaded with success
    } else {
      // TODO set data did not load
      setIsRefreshing(false);
    }
  };

  const prepareRequestBody = (attributeKey = '', pageNumber = 1) => {
    const exploredStep = currentStep ? { ...currentStep.object, ID: currentStep.stepId } : null;
    const reqBody = getExplorerRequestBody({
      funnelConfiguration,
      exploredStep,
      focusedSteps,
      limit: ITEMS_ON_PAGE,
      pageNumber,
      attributeKey,
    });

    const hasParameters = exploredStep.filterData.find((itm) => itm.value !== '');
    // do this only if the url is empty
    if (reqBody.filter.explored_step.url === '' && !hasParameters) {
      // In order to get all possible parameters, we need to send a request with no parameters
      // remove explored_step from request body , and remove canvas.dictionary
      delete reqBody.filter.explored_step;
      delete reqBody.filter.canvas.dictionary;
    }

    return reqBody;
  };

  // This is where the data flows in ,
  // We divide the selected data to selected common and custom parameters
  // as the data will be sent into two different sub-components

  const commonParametersData = extractSelectedItems(selectedData, pageParameters.common_parameters);
  const customParametersData = extractSelectedItems(selectedData, pageParameters.custom_parameters);

  const onCommonParametersChanged = (commonParameters) => {
    // merge back the data so that we report the changes
    const joinedParameters = { ...commonParameters, ...customParametersData };
    // This is where the data flows out
    const newFilterData = transformToFilterData(joinedParameters, selectedData, inPageParameters);
    onAttributesChanged(newFilterData);
  };

  const onCustomParametersChanged = (customParameters) => {
    // merge back the data so that we report the changes
    const joinedParameters = { ...commonParametersData, ...customParameters };
    // This is where the data flows out
    const newFilterData = transformToFilterData(joinedParameters, selectedData, inPageParameters);
    onAttributesChanged(newFilterData);
  };

  const inPageParameters = (key) => {
    return (
      pageParameters.common_parameters.find((itm) => itm.key === key) ||
      pageParameters.custom_parameters.find((itm) => itm.key === key)
    );
  };

  const checkForChanges = () => {
    const rBody = prepareRequestBody();
    const a = JSON.stringify(rBody);
    const b = JSON.stringify(lastRequest);
    if (a === b || lastRequest === null) {
      setHasChange(false);
    } else {
      setHasChange(true);
    }
  };

  const onRefreshIconClicked = () => {
    if (!isRefreshing) {
      setPagination(DEFAULT_PAGINATION_STATE);
      getAttributeExplorerData();
    }
  };

  const onSpread = (itemData) => {
    commonSendEventFunction(RP_EVENT_ELEMENT_SPREAD, itemData);
  };

  const loadPaginatedPageParameters = async (attributeKey, pageNumber) => {
    setIsMoreLoading(true);
    const reqBody = prepareRequestBody(attributeKey, pageNumber);
    setLastRequest(reqBody);

    source = axios.CancelToken.source();
    const requestService = new RequestService();
    const responseData = await requestService.getAttributeExplorerPageParams(
      projectId,
      reqBody,
      source,
    );

    if (axios.isCancel(responseData) || !responseData) {
      setIsMoreLoading(false);
      return;
    }

    // disable pagination for key if response is empty
    if (
      !responseData ||
      (!responseData.common_parameters.length && !responseData.custom_parameters.length)
    ) {
      const emptyItem = { key: attributeKey, values: [] };
      updateLoadMoreConfig([emptyItem], ATTRIBUTE_TYPE.common);
      updateLoadMoreConfig([emptyItem], ATTRIBUTE_TYPE.custom);
    } else {
      pageParameters.common_parameters.forEach((parameter) => {
        if (parameter.key === attributeKey) {
          const itemWithNewValues = responseData.common_parameters.find(
            (i) => i.key === attributeKey,
          );

          if (itemWithNewValues && itemWithNewValues.values && itemWithNewValues.values.length) {
            updateLoadMoreConfig([itemWithNewValues], ATTRIBUTE_TYPE.common);
            parameter.values = parameter.values.concat(itemWithNewValues.values);
          }
        }
      });

      pageParameters.custom_parameters.forEach((parameter) => {
        if (parameter.key === attributeKey) {
          const itemWithNewValues = responseData.custom_parameters.find(
            (i) => i.key === attributeKey,
          );

          if (itemWithNewValues && itemWithNewValues.values && itemWithNewValues.values.length) {
            updateLoadMoreConfig([itemWithNewValues], ATTRIBUTE_TYPE.custom);
            parameter.values = parameter.values.concat(itemWithNewValues.values);
          }
        }
      });

      setPageParameters(pageParameters);
    }

    setIsMoreLoading(false);
  };

  const loadMoreItems = (attributeType) => async (key) => {
    if (isMoreLoading) {
      return;
    }

    const { page } = pagination[attributeType][key] || { page: 1 };
    const nextPage = page + 1;

    await loadPaginatedPageParameters(key, nextPage);
    setPagination({
      ...pagination,
      [attributeType]: { ...pagination[attributeType], [key]: { page: nextPage } },
    });
  };

  const createLoadMoreConfig = (attributes) => {
    const common = attributes.common_parameters.reduce((result, item) => {
      result[item.key] = item.values.length >= ITEMS_ON_PAGE;

      return result;
    }, {});
    const custom = attributes.custom_parameters.reduce((result, item) => {
      result[item.key] = item.values.length >= ITEMS_ON_PAGE;

      return result;
    }, {});

    setCanLoadMoreConfig({ common, custom });
  };

  const updateLoadMoreConfig = (attributes, attributeType) => {
    attributes.forEach((item) => {
      canLoadMoreConfig[attributeType][item.key] = item.values.length >= ITEMS_ON_PAGE;
    });

    setCanLoadMoreConfig(canLoadMoreConfig);
  };

  return (
    <div className={styles.AttributeExplorer}>
      <div className={styles.AttributeExplorerHeader}>
        <div className={styles.SectionTitle}>Attribute Explorer</div>
        <div className={styles.RefreshBtn} onClick={onRefreshIconClicked}>
          <span className={isRefreshing ? styles.Rotate : null}>{iconRefreshBtn}</span>
          <When condition={!isRefreshing}>
            <div className={styles.StatusIcon}>
              {hasChange ? iconAnalyticsWarning : iconCheckmark}
            </div>
          </When>
        </div>
      </div>

      <When condition={isRefreshing}>
        <div className={styles.Loading}>
          <span>Loading data please wait</span>
        </div>
      </When>
      <When condition={!isRefreshing}>
        {pageParameters.common_parameters.length === 0 &&
        pageParameters.custom_parameters.length === 0 ? (
          <div className={styles.Loading}>
            <span>No data available for the selected parameters</span>
          </div>
        ) : (
          <div className={styles.AttributeExplorerContainer}>
            <AttributeExplorerList
              onSelectionChanged={onCommonParametersChanged}
              title="Common Parameters"
              list={pageParameters.common_parameters}
              selectedData={commonParametersData}
              expandedRows={commonParametersExpanded}
              setExpandedRows={setCommonParametersExpanded}
              canSpread={true}
              onSpread={onSpread}
              canLoadMoreConfig={canLoadMoreConfig[ATTRIBUTE_TYPE.common]}
              onLoadMore={loadMoreItems(ATTRIBUTE_TYPE.common)}
              isMoreLoading={isMoreLoading}
            />
            <AttributeExplorerList
              onSelectionChanged={onCustomParametersChanged}
              title="Custom Parameters"
              list={pageParameters.custom_parameters}
              selectedData={customParametersData}
              expandedRows={customParametersExpanded}
              setExpandedRows={setCustomParametersExpanded}
              canSpread={true}
              onSpread={onSpread}
              canLoadMoreConfig={canLoadMoreConfig[ATTRIBUTE_TYPE.custom]}
              onLoadMore={loadMoreItems(ATTRIBUTE_TYPE.custom)}
              isMoreLoading={isMoreLoading}
            />
          </div>
        )}
      </When>
    </div>
  );
};
