import { createAction } from 'redux-actions';
import {
  FILTER_TYPE_COMPARE,
  FILTER_TYPE_COMPARE_STEP,
  FILTER_TYPE_DEFAULT_STEP,
} from 'shared/CSharedConstants';
import { ActionNames } from 'react-project/redux/actionNamesConstant';

export const addDefaultFocusedStep = createAction(ActionNames.addDefaultFocusedStep);
export const addCompareFocusedStep = createAction(ActionNames.addCompareFocusedStep);

export const removeDefaultFocusedStep = createAction(ActionNames.removeDefaultFocusedStep);
export const removeCompareFocusedStep = createAction(ActionNames.removeCompareFocusedStep);

export const removeAllDefaultFocusedStep = createAction(ActionNames.removeAllDefaultFocusedStep);
export const removeAllCompareFocusedStep = createAction(ActionNames.removeAllCompareFocusedStep);

export const openDefaultFocusedStep = createAction(ActionNames.openDefaultFocusedStep);
export const openCompareFocusedStep = createAction(ActionNames.openCompareFocusedStep);

export const setDefaultFocusedSteps = createAction(ActionNames.setDefaultFocusedSteps);
export const setCompareFocusedSteps = createAction(ActionNames.setCompareFocusedSteps);

export const addFocusedStep = ({ step, filterTypes }) => {
  return (dispatch) => {
    if (filterTypes.includes(FILTER_TYPE_COMPARE_STEP)) {
      dispatch(addCompareFocusedStep(step));
    } else if (filterTypes.includes(FILTER_TYPE_DEFAULT_STEP)) {
      dispatch(addDefaultFocusedStep(step));
    }
  };
};

export const removeFocusedStep = ({ step, filterTypes }) => {
  return (dispatch) => {
    if (step) {
      if (filterTypes.includes(FILTER_TYPE_DEFAULT_STEP)) {
        dispatch(removeDefaultFocusedStep(step));
      }

      if (filterTypes.includes(FILTER_TYPE_COMPARE_STEP)) {
        dispatch(removeCompareFocusedStep(step));
      }
    } else {
      if (filterTypes.includes(FILTER_TYPE_DEFAULT_STEP)) {
        dispatch(removeAllDefaultFocusedStep());
      }

      if (filterTypes.includes(FILTER_TYPE_COMPARE_STEP)) {
        dispatch(removeAllCompareFocusedStep());
      }
    }
  };
};

export const setStepFilterOpened = ({ id, value }) => {
  return (dispatch) => {
    if (id === FILTER_TYPE_COMPARE) {
      dispatch(openCompareFocusedStep(value));
    } else {
      dispatch(openDefaultFocusedStep(value));
    }
  };
};
