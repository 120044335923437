// define base colors first

const COLOR_WHITE = 0xffffff;
const COLOR_BLACK = 0x000000;
const COLOR_PURPLE = 0x540566;

const COLOR_LIGHT_GRAY_BLUE = 0xf0f2f7; // light gray color that its hue its shifted towards blue just a little bit
const COLOR_LIGHT_GRAY_BLUE_2 = 0xe4e6ea; // slightly darker then the first one
const COLOR_LIGHT_GRAY_BLUE_3 = 0xe2e2e5; // even more dark
const COLOR_LIGHT_GRAY_BLUE_4 = 0xc6cbd5; //
const COLOR_LIGHT_GRAY_BLUE_5 = 0x959ba8; // darkest gray blue , so far
const COLOR_DESATURATED_BLUE = 0x636e84; // Almost gray
const COLOR_GRAY = 0x4a5468; //
const COLOR_DARK_BLUE_GRAY = 0x1e2531;
const COLOR_DARK_GRAY_BLUE = 0x26272e; // hue shifted towards blue just a little bit
const COLOR_G1 = 0x636e84; // some gray color
const COLOR_G2 = 0x4a5468; // some gray color
const COLOR_G3 = 0x959ba8; // some gray color
const COLOR_G4 = 0xe9e9e9; // some gray color
const COLOR_G5 = 0xfbfbfb; // some very very light gray
const COLOR_G6 = 0xbcbcbc;
const COLOR_G7 = 0xeaf6fe;

const COLOR_LIGHT_BLUE = 0xf0e3ff;
const COLOR_TIRKIZ = 0x007acc;
const COLOR_MAGENTA = 0xd64e82;
const COLOR_BLUE = 0x934cff;
const COLOR_BLUE_TURQUOISE = 0x009bf3;
const COLOR_GREEN = 0x60d773;
const COLOR_ORANGE = 0xfeb663;
const COLOR_BABY_BLUE = 0x048fea;
const COLOR_RED = 0xf43b46;
const COLOR_AMBER = 0xffb763;
const COLOR_DARK_ORANGE = 0xff4d01;

// define font size
const FONT_SIZE_NORMAL = 17;
const FONT_SIZE_HEADING = 26;

// define font family
const FONT_ROBOTO = 'Roboto';
const FONT_ROBOTO_MEDIUM = 'Roboto Medium';
const FONT_ROBOTO_BOLD = 'Roboto Bold';

// export specific element coloring
export const COLOR_LABEL_DEFAULT = COLOR_G1;
export const COLOR_LABEL_DEFAULT_LIGHT = COLOR_G3;
export const COLOR_LABEL_HIGHLIGHTED = COLOR_DARK_GRAY_BLUE;
export const COLOR_SELECTION = COLOR_MAGENTA;
export const COLOR_MULTIPLE_SELECTION_RECTANGLE = COLOR_LIGHT_GRAY_BLUE_3;
export const COLOR_SHAPE_LINE = COLOR_DARK_BLUE_GRAY;
export const COLOR_SHAPE_FILL = COLOR_WHITE;
export const COLOR_CONNECTION_LINE = COLOR_TIRKIZ;
export const COLOR_BACKGROUND = COLOR_LIGHT_GRAY_BLUE;
export const COLOR_MESH_BACKGROUND = COLOR_LIGHT_GRAY_BLUE;
export const COLOR_MESH_LINE = COLOR_LIGHT_GRAY_BLUE_2;
export const COLOR_SHAPE_INVALID = COLOR_RED;
export const COLOR_COMPARE_HITS = COLOR_BLUE;
export const COLOR_COMPARE_PERCENT_POSITIVE = COLOR_GREEN;
export const COLOR_COMPARE_PERCENT_NEGATIVE = COLOR_RED;
export const COLOR_COMPARE_PERCENT_NEUTRAL = COLOR_AMBER;
export const COLOR_COMPARE_LABEL = COLOR_GRAY;
export const COLOR_COMPARE_VALUE = COLOR_BLACK;
export const COLOR_STEP_FOCUSED = COLOR_BLUE_TURQUOISE;
export const COLOR_ELEMENT_HIGHLIGHT_FRAME = COLOR_ORANGE;
export const COLOR_ALIGNMENT_GUIDE = COLOR_BLUE_TURQUOISE;
export const COLOR_REPORT_VIEW_BACKGROUNG = COLOR_WHITE;
export const COLOR_REPORT_TITLE = COLOR_GRAY;
export const COLOR_REPORT_SLIDE_TITLE = COLOR_GRAY;
export const COLOR_REPORT_DASHED_LINE = COLOR_DESATURATED_BLUE;
export const COLOR_WIDGET_BACKGROUND = COLOR_WHITE;
export const COLOR_WIDGET_HEADER = COLOR_G1;
export const COLOR_WIDGET_TEXT = COLOR_G2;
export const COLOR_WIDGET_SUB_TITLE = COLOR_BLUE;
export const COLOR_WIDGET_SUBTEXT = COLOR_G3;
export const COLOR_WIDGET_OUTLINE = COLOR_LIGHT_GRAY_BLUE_4;
export const COLOR_WIDGET_ZEBRA = COLOR_LIGHT_GRAY_BLUE;
export const COLOR_WIDGET_SELECTED_ROW = COLOR_BLUE_TURQUOISE;
export const COLOR_WIDGET_HOVER_ROW = COLOR_G7;
export const COLOR_WIDGET_TEXT_SELECTED = COLOR_WHITE;
export const COLOR_TABLE_LOADER = COLOR_BABY_BLUE;
export const COLOR_TOOLTIP_TEXT = COLOR_WHITE;
export const COLOR_TOOLTIP_BACKGROUND = COLOR_G1;
export const COLOR_DISPALY_DEFAULT_TEXT = COLOR_LIGHT_GRAY_BLUE_4;
export const COLOR_DISPLAY_BORDER = COLOR_LIGHT_GRAY_BLUE_4;
export const COLOR_FRAME_ATTACHMENT = COLOR_BLUE_TURQUOISE;
export const COLOR_COMPARE_BACKGROUND = COLOR_LIGHT_BLUE;
export const COLOR_DISPLAY_SEPARATOR = COLOR_LIGHT_GRAY_BLUE;
export const COLOR_WIDGET_MESSAGE = COLOR_GRAY;
export const COLOR_FORECASTING_NEUTRAL = COLOR_LIGHT_GRAY_BLUE_4;
export const COLOR_FORECASTING_POSITIVE = COLOR_GREEN;
export const COLOR_FORECASTING_NEGATIVE = COLOR_RED;

export const OPACITY_INVALID_SHAPE = 0.4;

export const SELECTION_ROUND_CORNER = 5;
export const SELECTION_BOUNDARY_GAP = 10;
export const SELECTION_PADDING = 15;

export const FOOTER_MARGIN = 15;
export const TITLE_MARGIN = 30;
export const TITLE_PROGRESS_BAR_MARGIN = 10;

export const CONNECTION_BOX_PADDING = 5;
export const CONNECTION_BOX_PADDING_HORIZONTAL = 10;
export const BOX_SEPARATOR_THICKHNESS = 4;
export const BOX_SEPARATOR_TRIANGLE = 7;

export default class Styles {
  static TOOLTIP_LABEL = {
    fontName: FONT_ROBOTO_MEDIUM,
    fontSize: 13,
    align: 'center',
    wordWrap: true,
    wordWrapWidth: 320,
    breakWords: true,
    miterLimit: 0,
    whiteSpace: 'normal',
    fill: COLOR_WHITE,
  };

  static FOOTER_LABEL = {
    fontName: FONT_ROBOTO,
    fontSize: FONT_SIZE_NORMAL,
    align: 'center',
    wordWrap: true,
    wordWrapWidth: 320,
    breakWords: true,
    miterLimit: 0,
    whiteSpace: 'normal',
  };

  static TITLE_LABEL = {
    fontName: FONT_ROBOTO_BOLD,
    fontSize: FONT_SIZE_NORMAL,
    align: 'center',
    wordWrap: true,
    wordWrapWidth: 200,
    breakWords: true,
    miterLimit: 0,
    whiteSpace: 'normal',
    fill: COLOR_LABEL_DEFAULT,
  };

  static REPORT_TITLE = {
    fontName: FONT_ROBOTO_BOLD,
    fontSize: 18,
    align: 'left',
    miterLimit: 0,
    whiteSpace: 'normal',
    input: {
      fontFamily: FONT_ROBOTO,
      fontSize: 18,
      fontWeight: 700,
      color: '#4A5468',
      width: '1000px',
    },
    box: {
      default: { stroke: { color: 0xcbcee0, width: 1, alpha: 0.1 } },
      focused: { stroke: { color: 0xcbcee0, width: 1, alpha: 0.1 } },
    },
  };

  static REPORT_SLIDE_TITLE = {
    fontName: FONT_ROBOTO,
    fontSize: 40,
    align: 'left',
    miterLimit: 0,
    whiteSpace: 'normal',
  };

  static TEXT_OBJECT = {
    input: {
      fontSize: `${FONT_SIZE_HEADING}px`,
      fontFamily: FONT_ROBOTO,
      color: COLOR_LABEL_HIGHLIGHTED,
      multiline: true,
      wordWrap: false,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  };

  static WIDGET_TITLE_LABEL = {
    fontName: FONT_ROBOTO_MEDIUM,
    fontSize: '13',
    miterLimit: 0,
    whiteSpace: 'normal',
  };

  static WIDGET_SUB_TITLE_LABEL = {
    fontName: FONT_ROBOTO_MEDIUM,
    fontSize: '11',
    miterLimit: 0,
    whiteSpace: 'normal',
  };

  static WIDGET_TABLE_TITLE_LABEL = {
    fontName: FONT_ROBOTO_MEDIUM,
    fontSize: '13',
    miterLimit: 0,
    whiteSpace: 'normal',
  };

  static WIDGET_PAGINATION_LABEL = {
    fontName: FONT_ROBOTO,
    fontSize: '13',
    miterLimit: 0,
    whiteSpace: 'normal',
  };

  static WIDGET_NODATA_LABEL = {
    default: {
      fontFamily: FONT_ROBOTO,
      fontSize: '12px',
      fill: '#4a5468',
      align: 'center',
      breakWords: true,
      wordWrap: true,
      wordWrapWidth: 320,
    },
    b: {
      fontWeight: 'bold',
    },
    i: {
      fontStyle: 'italic',
    },
    a: {
      fill: '#009BF3',
      textDecoration: 'underline',
    },
  };

  static WIDGET_BOLD_TITLE = {
    fontName: FONT_ROBOTO_BOLD,
    fontSize: '16',
    miterLimit: 0,
    whiteSpace: 'normal',
  };

  static WIDGET_COMPARE = {
    fontName: FONT_ROBOTO_BOLD,
    fontSize: '15',
    miterLimit: 0,
    whiteSpace: 'normal',
  };

  static WIDGET_LABEL = {
    fontName: FONT_ROBOTO,
    fontSize: '13',
    miterLimit: 0,
    whiteSpace: 'normal',
    wordWrap: true,
    wordWrapWidth: 130,
    breakWords: true,
  };

  static WIDGET_BOLD_LABEL = {
    fontName: FONT_ROBOTO_BOLD,
    fontSize: '13',
    miterLimit: 0,
    whiteSpace: 'normal',
  };

  static MULTY_STYLE_LABEL = {
    default: {
      fontFamily: FONT_ROBOTO,
      fontSize: '24px',
      fill: '#000000',
      align: 'left',
      breakWords: true,
    },
    b: {
      fontWeight: 'bold',
    },
    i: {
      fontStyle: 'italic',
    },
    placeholder: {
      fill: '#cccccc',
    },
    span: {},
    a: {
      fill: '#009BF3',
      textDecoration: 'underline',
    },
    p: {},
    div: {},
    font: {},
  };

  static FOOTER_DEFAULT = {
    fontName: FONT_ROBOTO_MEDIUM,
    fontSize: 13,
    align: 'center',
    wordWrap: true,
    wordWrapWidth: 320,
    breakWords: true,
    miterLimit: 0,
    whiteSpace: 'normal',
  };

  static FOOTER_TITLE = {
    fontName: FONT_ROBOTO,
    fontSize: '12',
    fill: COLOR_DESATURATED_BLUE,
    spacing: 0,
  };

  static FOOTER_NUMBER = {
    fontName: FONT_ROBOTO_BOLD,
    fontSize: '19',
    fill: COLOR_G1,
    spacing: 3,
  };

  static FOOTER_PERCENT = {
    fontName: FONT_ROBOTO_BOLD,
    fontSize: '12',
    fill: COLOR_WHITE,
    spacing: 5,
  };

  static FOOTER_COMPARE = {
    fontName: FONT_ROBOTO,
    fontSize: '12',
    fill: COLOR_COMPARE_HITS,
    background: COLOR_LIGHT_BLUE,
    spacing: 5,
  };

  static WIDGET_MESSAGE = {
    fontName: FONT_ROBOTO,
    fontSize: '28',
    fill: COLOR_DESATURATED_BLUE,
    spacing: 0,
    align: 'center',
  };

  static DROPDOWN_SELECTED_LABEL = {
    fontName: FONT_ROBOTO,
    fontSize: '12',
    fill: COLOR_DESATURATED_BLUE,
    spacing: 0,
  };

  static DROPDOWN_ITEM_LABEL = {
    fontName: FONT_ROBOTO,
    fontSize: '14',
    fill: COLOR_DESATURATED_BLUE,
    spacing: 0,
  };

  static FORECASTING_TITLE = {
    fontName: FONT_ROBOTO,
    fontSize: '12',
    fill: COLOR_DARK_ORANGE,
    spacing: 0,
  };

  static FORECASTING_NUMBER = {
    fontName: FONT_ROBOTO_BOLD,
    fontSize: '19',
    fill: COLOR_DARK_ORANGE,
    spacing: 3,
  };

  static TEXT_DEFAULT = {
    fontName: FONT_ROBOTO,
    fontSize: 16,
    align: 'center',
    miterLimit: 0,
    whiteSpace: 'normal',
  };

  static TEXT_MEDIUM = {
    fontName: FONT_ROBOTO_MEDIUM,
    fontSize: 16,
    align: 'center',
    miterLimit: 0,
    whiteSpace: 'normal',
  };

  static TEXT_STRONG = {
    fontName: FONT_ROBOTO_BOLD,
    fontSize: 18,
    align: 'center',
    miterLimit: 0,
    whiteSpace: 'normal',
  };
}
