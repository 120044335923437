import * as PIXI from 'pixi.js';
import BezierCurve from 'pixi-project/utils/BezierCurve';

export default class BezierSegment {
  constructor() {
    this.pointA = new PIXI.Point();
    this.controlA = new PIXI.Point();
    this.controlB = new PIXI.Point();
    this.pointB = new PIXI.Point();
    this.curve = new BezierCurve(this.pointA, this.controlA, this.controlB, this.pointB);
  }

  setPoints(pointA, controlA, controlB, pointB) {
    this.pointA.copyFrom(pointA);
    this.controlA.copyFrom(controlA);
    this.controlB.copyFrom(controlB);
    this.pointB.copyFrom(pointB);

    this.curve.calculateArcs();
  }

  calculateLength() {
    this.curve.calculateArcs();
  }

  get length() {
    return this.curve.totalLength;
  }
}
