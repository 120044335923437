import Cookies from 'js-cookie';
import { getFutureDate } from './Dates';

const COOKIE_KEY = 'flx_auth';
const COOKIE_PATH = '/';
const COOKIE_VIEW_ONLY_PREFIX = 'view-only-auth-';

const getCookieDomain = () => {
  return process.env.NODE_ENV === 'development' ? '' : '.funnelytics.io';
};

export const parseAuthCookie = (key = COOKIE_KEY) => {
  try {
    const flxAuthCookie = Cookies.get(key);
    const parsedCookie = JSON.parse(flxAuthCookie);
    return {
      id: parsedCookie.id,
      accessToken: parsedCookie.access_token,
      refreshToken: parsedCookie.refresh_token,
    };
  } catch {
    return null;
  }
};

export const getViewOnlyAuth = () => {
  const path = window.location.pathname || '';

  if (path.startsWith('/view/')) {
    const canvasId = path.split('/')[2];

    // grab auth from storage
    try {
      return JSON.parse(localStorage.getItem(`view-only-auth-${canvasId}`));
    } catch (error) {
      // no authorization was found
    }
  }
  return null;
};

export const getTutorialAuth = () => {
  const path = window.location.pathname || '';

  if (path.startsWith('/tutorial/')) {
    const canvasId = path.split('/')[2];

    // grab auth from storage
    try {
      return JSON.parse(localStorage.getItem(`tutorial-auth-${canvasId}`));
    } catch (error) {
      // no authorization was found
    }
  }
  return null;
};

const removeTutorialAuth = () => {
  const path = window.location.pathname || '';

  if (path.startsWith('/tutorial/')) {
    const canvasId = path.split('/')[2];

    // grab auth from storage
    try {
      localStorage.removeItem(`tutorial-auth-${canvasId}`);
    } catch (error) {
      // no authorization was found
    }
  }
};

export const getAuthToken = () => {
  const viewOnlyAuth = getViewOnlyAuth();
  if (viewOnlyAuth) {
    return viewOnlyAuth.access_token;
  }

  const tutorialAuth = getTutorialAuth();
  if (tutorialAuth) {
    return tutorialAuth.access_token;
  }

  const parsedCookie = parseAuthCookie();
  return parsedCookie ? parsedCookie.accessToken : null;
};

export const getRefreshToken = () => {
  const parsedCookie = parseAuthCookie();
  return parsedCookie ? parsedCookie.refreshToken : null;
};

export const getUserId = () => {
  const parsedCookie = parseAuthCookie();
  return parsedCookie ? parsedCookie.id : null;
};

export const removeAuthCookie = () => {
  const domain = getCookieDomain();

  Cookies.remove(COOKIE_KEY, { domain, path: COOKIE_PATH });

  /**
   * Check if cookies was removed, if not try to remove it without domain property.
   */
  if (Cookies.get(COOKIE_KEY)) {
    Cookies.remove(COOKIE_KEY, { path: COOKIE_PATH });
  }

  /**
   * If, for some reason cookie was not still removed, then remove it with native API
   */
  if (Cookies.get(COOKIE_KEY)) {
    document.cookie = `${COOKIE_KEY}=''; Domain=${domain}; Path=${COOKIE_PATH}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    document.cookie = `${COOKIE_KEY}=''; Path=${COOKIE_PATH}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
};

export const setAuthCookie = (data) => {
  try {
    const { id, access_token, refresh_token } = data;

    Cookies.set(
      COOKIE_KEY,
      JSON.stringify(
        { id, access_token, refresh_token },
        {
          domain: getCookieDomain(),
          // this should be set in order to exchange cookies with Ember-Dashboard
          expires: getFutureDate(),
          path: COOKIE_PATH,
        },
      ),
    );
  } catch (err) {
    console.error(`Auth cookie refresh problem: `, err);
  }
};

export const setViewAuthCookie = (data, key) => {
  try {
    const { id, access_token, refresh_token } = data;

    Cookies.set(
      `${COOKIE_VIEW_ONLY_PREFIX}${key}`,
      JSON.stringify(
        { id, access_token, refresh_token },
        {
          domain: getCookieDomain(),
          // this should be set in order to exchange cookies with Ember-Dashboard
          expires: getFutureDate(),
          path: COOKIE_PATH,
        },
      ),
    );
  } catch (err) {
    console.error(`Auth cookie refresh problem: `, err);
  }
};

const removeViewOnlyAuth = () => {
  const path = window.location.pathname || '';

  if (path.startsWith('/view/')) {
    const canvasId = path.split('/')[2];

    // grab auth from storage
    try {
      localStorage.removeItem(`view-only-auth-${canvasId}`);
    } catch (error) {
      // no authorization was found
    }
  }
};

export const removeAuthCookieAndReload = () => {
  removeAuthCookie();
  removeTutorialAuth();
  removeViewOnlyAuth();
  window.location.reload();
};
