import _ from 'lodash';
import { adType } from 'react-project/FacebookIntegration/helpers';
import { DEFAULT_OPERATOR } from 'react-project/Toolbar/step-toolbar/LogicalOperators';

export const filterIds = (el, source) => {
  if (el.type === adType.CAMPAIGN) {
    return el;
  }

  if (el.type === adType.ADSET) {
    if (source.some((s) => s.id === el.campaignId)) {
      return;
    } else {
      return el;
    }
  }

  if (el.type === adType.AD) {
    if (source.some((s) => s.id === el.campaignId || s.id === el.adsetId)) {
      return;
    } else {
      return el;
    }
  }
};

const fn = (obj, key) => {
  if (_.has(obj, key)) return [obj];
  return _.flatten(
    _.map(obj, function (v) {
      return typeof v === 'object' ? fn(v, key) : [];
    }),
    true,
  );
};

export const getUtmParameters = (el) => {
  const result = fn(el, 'creative');

  // get queries from tracking tab
  const urlTags =
    result && result[0] && result[0].creative && result[0].creative.url_tags
      ? result[0].creative.url_tags.split('&')
      : [];
  // get queries from destination tab
  let searchQuerys = [];
  if (Array.isArray(result) && result[0]) {
    let destinationURLobj;
    if (result[0]?.creative?.object_story_spec?.link_data?.link) {
      destinationURLobj = new URL(result[0]?.creative?.object_story_spec?.link_data?.link);
    } else if (result[0]?.creative?.asset_feed_spec?.link_urls) {
      destinationURLobj = new URL(result[0]?.creative?.asset_feed_spec?.link_urls[0]?.website_url);
    }

    if (destinationURLobj && destinationURLobj.search) {
      let destinationURLsearch = destinationURLobj.search.replace('?', '');
      searchQuerys = destinationURLsearch.split('&');
    }
  }

  // check utm-parameters from destination-url-querys and tracking-querys
  // if both exists, tracking-querys will take precedence
  let utmParameters = [];
  if (Array.isArray(searchQuerys) && searchQuerys.length > 0) {
    utmParameters = searchQuerys;
  }
  if (Array.isArray(urlTags) && urlTags.length > 0) {
    utmParameters = [...utmParameters, ...urlTags];
  }

  const mappedUtms = utmParameters.map((parameter) => {
    const splittedValue = parameter.split('=');
    let value;
    switch (splittedValue[1]) {
      case '{{campaign.name}}':
        value = el.campaign?.name || el.name;
        break;
      case '{{adset.name}}':
        value = el.adset?.name || el.name;
        break;
      case '{{ad.name}}':
        value = el.name;
        break;
      case '{{campaign.id}}':
        value = el.campaign?.id || el.id;
        break;
      case '{{adset.id}}':
        value = el.adset?.id || el.id;
        break;
      case '{{ad.id}}':
        value = el.id;
        break;
      default:
        value = splittedValue[1];
        break;
    }

    return { key: splittedValue[0], value: value, operator: DEFAULT_OPERATOR };
  });
  //TODO- (Praveen R) We need to display all parameter even if it does not contains utm.
  // Keeping commented code for reference.
  // const availableUtms = {
  //   campaign: ['utm_source', 'utm_medium', 'utm_campaign'],
  //   adset: ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content'],
  //   ad: ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'],
  // };
  // return mappedUtms.filter((utm) => availableUtms[el.type].includes(utm.key));

  return mappedUtms;
};

// possible values: undefined, "$123", "123", 123
export const currencyFormatter = (input) => {
  if (input === undefined || input === null || input === '') input = '';
  input = input.toString();
  if (input.startsWith('$')) input = input.substr(1);
  if (isNaN(input)) input = 0;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(Number(input));
};
