import React, { useState } from 'react';
import styles from 'react-project/Toolbar/Toolbar.module.scss';
import { When } from 'react-project/Util/When';
import { DropDown } from 'react-project/components/dropdowncomponent/dropdown';
import { isExcludedOperator, isMathOperator, transformToSupportedNumber } from './LogicalOperators';
import { useSelector } from 'react-redux';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';

export const StepURLParamsTableRow = ({
  index,
  itemKey = '',
  itemValue = '',
  onChange,
  operator,
  itemList,
}) => {
  const [isEditingKey, setKeyEdit] = useState(false);
  const [isEditingValue, setValueEdit] = useState(false);
  const canvasPermissions = useSelector(selectCanvasPermissions);

  const selectedItem = itemList.find((itm) => itm.value === operator);

  const onKeyCellClicked = (e) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();
    if (!isEditingKey) {
      setKeyEdit(true);
    }
  };

  const onEdited = (index, key, itemValue, operator) => {
    if (isExcludedOperator(operator)) {
      // In the case of isExluded operator
      // we will always have the * value
      // isExcludedOperator overwrite
      // search for the isExcludedOperator to find the other overwrites
      onChange(index, key, '*', operator);
      return;
    }
    onChange(index, key, itemValue, operator);
  };

  const onValueCellClicked = (e) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }
    if (!isEditingValue && selectedItem && !isExcludedOperator(selectedItem.value)) {
      setValueEdit(true);
    }
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      setKeyEdit(false);
    }
  };

  const onValueKeyDown = (e) => {
    if (e.key === 'Enter') {
      setValueEdit(false);
      cleanUpFilterValue();
    }
  };

  const onKeyChange = (e) => {
    onEdited(index, e.target.value, itemValue, operator);
  };

  const onValueChange = (e) => {
    let val = e.target.value;
    let multipleValues = val.split(',');

    if (multipleValues.length > 1) {
      val = multipleValues;
    }

    if (isMathOperator(operator)) {
      val = transformToSupportedNumber(val);
    }

    onEdited(index, itemKey, val, operator);
  };

  const onKeyBlur = (e) => {
    setKeyEdit(false);
  };

  const onValueBlur = (e) => {
    setValueEdit(false);
    cleanUpFilterValue();
  };

  const cleanUpFilterValue = () => {
    if (Array.isArray(itemValue)) {
      let newVal = itemValue.filter((itm) => itm !== '');
      newVal = newVal.map((itm) => {
        return itm.trim();
      });
      onEdited(index, itemKey.trim(), newVal, operator);
    } else {
      onEdited(index, itemKey.trim(), itemValue.trim(), operator);
    }
  };

  const onDropDownChanged = (item) => {
    const val = isMathOperator(item.value) ? transformToSupportedNumber(itemValue) : itemValue;
    onEdited(index, itemKey, val, item.value);
  };

  return (
    <>
      <div
        className={styles.KeyValueCell}
        onClick={onKeyCellClicked}
        style={{
          height: '100%',
          minWidth: '50px',
        }}
      >
        <When condition={isEditingKey}>
          <input
            onMouseDown={(e) => {
              e.stopPropagation();
            }}
            autoFocus
            onKeyDown={onKeyDown}
            onBlur={onKeyBlur}
            type="text"
            className={styles.addItemKey}
            value={itemKey}
            onChange={onKeyChange}
            disabled={canvasPermissions.isReadonlyAccess}
          />
        </When>
        <When condition={!isEditingKey}>{itemKey}</When>
      </div>

      <DropDown
        disabled={canvasPermissions.isReadonlyAccess}
        selectedItem={selectedItem}
        itemList={itemList}
        onSelectionChanged={onDropDownChanged}
      ></DropDown>

      <div
        className={styles.KeyValueCell}
        onClick={onValueCellClicked}
        style={{
          textAlign: 'right',
          whiteSpace: Array.isArray(itemValue) && !isEditingValue ? 'pre-wrap' : 'normal',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          height: '100%',
          minWidth: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        }}
      >
        <When condition={isEditingValue}>
          <input
            onMouseDown={(e) => {
              e.stopPropagation();
            }}
            autoFocus
            onKeyDown={onValueKeyDown}
            onBlur={onValueBlur}
            type="text"
            className={styles.addItemValue}
            value={Array.isArray(itemValue) ? itemValue.join(',') : itemValue}
            onChange={onValueChange}
            disabled={canvasPermissions.isReadonlyAccess}
          />
        </When>
        <When condition={!isEditingValue}>
          <When condition={selectedItem && isExcludedOperator(selectedItem.value)}>{'*'}</When>
          <When condition={selectedItem && !isExcludedOperator(selectedItem.value)}>
            {Array.isArray(itemValue) ? itemValue.join('\n') : itemValue}
          </When>
        </When>
      </div>
    </>
  );
};

StepURLParamsTableRow.defaultProps = {
  isChecked: false,
};
