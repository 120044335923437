import React, { useEffect, useRef } from 'react';
import styles from 'react-project/LeftSideBar/LeftSideBar.module.scss';
import buttonStyles from 'react-project/components/secondaryButton/SecondaryButtons.module.scss';
import { iconClose, iconStep, iconWarrning } from 'react-project/assets/Icons';
import { When } from 'react-project/Util/When';
import cls from 'classnames';
import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';
import { RP_ANALYTICS_PWP_TOOL_ACTIVATED, RP_EVENT_STEP_FOCUS_CLEARED } from 'shared/CSharedEvents';
import PropTypes from 'prop-types';
import {
  TEXTS_TOOLTIP,
  TEXT_CLEAR_PEOPLE_PERFORMED,
  TEXT_INFO_PEOPLE_PERFORMED,
} from 'react-project/Constants/texts';
import { useDispatch, useSelector } from 'react-redux';
import { setStepFilterOpened } from 'react-project/redux/focused-step/actions';
import { getDirtyStyle, getFilterAddClass } from 'react-project/Util/FilterStyleHelper';
import {
  FILTER_ITEM_PADDING_WIDTH,
  FILTER_TYPE_COMPARE,
  FILTER_TYPE_COMPARE_STEP,
  FILTER_TYPE_DEFAULT,
  FILTER_TYPE_DEFAULT_STEP,
  HEADER_HEIGHT,
  LEFT_SIDEBAR_WIDTH,
} from 'shared/CSharedConstants';
import { hasScrollBar } from 'react-project/Util/hasScrollBar';
import { getRefPosition } from 'react-project/Util/getPosition';
import { TOOLTIP_POSITIONS } from 'react-project/Constants/tooltip';
import { Tooltip } from 'react-project/components/tooltip/Tooltip';
import { selectStepOpened } from 'react-project/redux/focused-step/selectors';
import { SecondaryButton } from 'react-project/components/secondaryButton/SecondaryButton';
import { commonSendEventFunction } from 'shared/CSharedMethods';

export const DEFAULT_STEP_LABEL = 'Select a step';

const CONVERT_TYPE = {
  [FILTER_TYPE_COMPARE]: FILTER_TYPE_COMPARE_STEP,
  [FILTER_TYPE_DEFAULT]: FILTER_TYPE_DEFAULT_STEP,
};

export const StepFilterBlock = ({
  type,
  onClearSteps,
  onRemoveFocusedStep,
  hasFilterChange,
  isApplyButtonEnabled,
  focusedSteps,
  focusLogicalOperator,
  onFilterChanged,
}) => {
  const dispatch = useDispatch();
  const isOpened = useSelector((state) => selectStepOpened(state, type));

  const toggleOpenedFilter = (e, value) => {
    dispatch(
      setStepFilterOpened({
        id: type,
        value: value !== undefined ? value : !isOpened,
      }),
    );
  };

  const onFocusLogicalOperatorChanged = (e) => {
    onFilterChanged({
      focusLogicalOperator: e.target.value,
    });
  };

  useEffect(() => {
    commonSendEventFunction(RP_ANALYTICS_PWP_TOOL_ACTIVATED, {
      opened: isOpened,
      type: CONVERT_TYPE[type],
    });
  }, [isOpened]);

  const removeStep = (e, step) => {
    commonSendEventFunction(RP_EVENT_STEP_FOCUS_CLEARED, { types: [CONVERT_TYPE[type]], step });
    onRemoveFocusedStep({ step, filterTypes: [CONVERT_TYPE[type]] });
  };

  const onClearButtonClicked = (e) => {
    onFilterChanged({
      focusLogicalOperator: undefined,
    });
    onClearSteps();
  };

  const onApplyButtonClicked = (e) => {
    toggleOpenedFilter(e, false);
  };

  const ref = useRef();
  const position = getRefPosition(ref);
  const sidebar = document.getElementById('left-sidebar-wrapper');

  return (
    <ClickOutsideCustomComponent
      ignoreClickOutside={!isOpened}
      onClickOutside={(e) => {
        toggleOpenedFilter(e, false);
      }}
    >
      <div
        className={cls(styles.FiltersItem, getFilterAddClass(type), {
          [styles.ActiveFilter]: isOpened,
          [getDirtyStyle(type)]: focusedSteps.length > 0,
        })}
        ref={ref}
      >
        <div className={styles.ItemsForSelection} onClick={toggleOpenedFilter}>
          <span className={cls(styles.FilterTitleBlock, styles.PWPBlock)}>
            Step:
            <span className={styles.CountriesTitle} style={{ width: '170px' }}>
              {focusedSteps.length
                ? focusedSteps.map((itm) => itm.label).join(',')
                : DEFAULT_STEP_LABEL}
            </span>
          </span>
          <div className={styles.filterIconArrangement}>
            <When condition={hasFilterChange && isApplyButtonEnabled}>
              <Tooltip label={TEXTS_TOOLTIP.UNAPPLIED_CHANGES} position={TOOLTIP_POSITIONS.BOTTOM}>
                <div className={styles.FilterWarning}>{iconWarrning}</div>
              </Tooltip>
            </When>
            {iconStep()}
          </div>
        </div>
        <When condition={isOpened}>
          <div
            className={styles.StepFilterWrapper}
            style={{
              position: 'absolute',
              top: 0, // position.y - HEADER_HEIGHT,
              // left: LEFT_SIDEBAR_WIDTH + (hasScrollBar(sidebar) ? 0 : FILTER_ITEM_PADDING_WIDTH),
              zIndex: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div className={styles.PWPFocusedControls}>
              <div className={styles.PWPTitleSection}>
                {focusedSteps.length ? TEXT_CLEAR_PEOPLE_PERFORMED : TEXT_INFO_PEOPLE_PERFORMED}
              </div>
            </div>
            <When condition={!!focusedSteps.length}>
              <div className={styles.PWPSelectionLogic}>
                <div className={styles.PWPSelectionLogicTitle}>
                  <span>Selection Logic</span>
                  <select
                    value={focusLogicalOperator || 'AND'}
                    onChange={onFocusLogicalOperatorChanged}
                  >
                    <option value="OR">OR</option>
                    <option value="AND">AND</option>
                  </select>
                </div>
                <div className={styles.PWPSelectionLogicDesc}>
                  How Funnelytics should treat the various elements you select
                  <ul>
                    <li>OR - User did either X or Y</li>
                    <li>AND - User must have done X and Y</li>
                  </ul>
                </div>
              </div>
              <div className={styles.PWPSubSection}>Selected Elements</div>
              <div className={styles.PWPFocusedContainer}>
                {focusedSteps.map((step, i) => (
                  <div key={`pwp-focus-${type}-${i}`} className={styles.PWPFocusedItem}>
                    <div className={styles.PWPItemText}>{step.label}</div>
                    <div className={styles.PWPRemoveStep} onClick={(e) => removeStep(e, step)}>
                      {iconClose}
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles.PWPFooterSection}>
                <SecondaryButton title="Clear All" onClick={onClearButtonClicked} />
                <SecondaryButton
                  title="Apply"
                  onClick={onApplyButtonClicked}
                  className={cls(buttonStyles.ApplyFiltersButton, styles.PWPApplyButton)}
                />
              </div>
            </When>
          </div>
        </When>
      </div>
    </ClickOutsideCustomComponent>
  );
};

StepFilterBlock.propType = {
  onSelectStep: PropTypes.func.isRequired,
};
