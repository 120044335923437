import * as PIXI from 'pixi.js';
import Styles, {
  BOX_SEPARATOR_THICKHNESS,
  BOX_SEPARATOR_TRIANGLE,
  COLOR_DISPLAY_SEPARATOR,
  CONNECTION_BOX_PADDING,
  CONNECTION_BOX_PADDING_HORIZONTAL,
} from 'pixi-project/view/Styles';
import TextDisplay from './TextDisplay';
import { ANALYTICS_DATA_DEFAULT_PLACEHOLDER, GOAL_DEFAULT_NAME } from 'shared/CSharedConstants';
import { numSeparator } from 'shared/NumberHelpers';
import { isGoalValid } from 'shared/ValidateHelpers';
import { roundTo } from 'shared/CSharedMethods';
import { shortenText } from 'shared/SharedHelpers';

export default class ConnectionCommonSegment extends PIXI.Container {
  constructor() {
    super();
    this.goal = null;
    this.currency = 'D';
    this.graphics = new PIXI.Graphics();
    this.addChild(this.graphics);

    this.rateDisplay = new TextDisplay();
    this.addChild(this.rateDisplay);

    this.peopleDisplay = new TextDisplay();
    this.addChild(this.peopleDisplay);

    this.goalDisplay = new TextDisplay();
    this.addChild(this.goalDisplay);
  }

  setData(value1, value2, label1, label2, goal) {
    /**
     * @param {string} value1
     * @param {string} value2
     * @param {string} label1
     * @param {string} label2
     */

    this.goal = goal;

    this.rateDisplay.setData([
      { value: label1, ...Styles.FOOTER_TITLE },
      { value: value1, ...Styles.FOOTER_NUMBER, spacing: 0 },
    ]);

    this.peopleDisplay.setData([
      { value: label2, ...Styles.FOOTER_TITLE },
      { value: value2, ...Styles.FOOTER_NUMBER, spacing: 0 },
    ]);

    if (isGoalValid(goal)) {
      this.goalDisplay.setData([
        { value: shortenText(goal.goalName, 20) || GOAL_DEFAULT_NAME, ...Styles.FOOTER_TITLE },
        {
          value: `${this.currency}${numSeparator(
            roundTo(goal.goalValue * parseFloat(value2.replace(/,/g, '')), 2),
          )}`,
          ...Styles.FOOTER_NUMBER,
          spacing: 0,
        },
      ]);
    }

    if (isGoalValid(goal)) {
      this.layoutGoalSegments();
    } else {
      this.layoutSegments();
    }
  }

  layoutGoalSegments() {
    this.goalDisplay.visible = true;

    const rateBounds = this.rateDisplay.areaSize;
    const hitBounds = this.peopleDisplay.areaSize;
    const goalBounds = this.goalDisplay.areaSize;

    const midY = 0;
    const totalWidth =
      rateBounds.width +
      hitBounds.width +
      goalBounds.width +
      CONNECTION_BOX_PADDING_HORIZONTAL * 4 +
      BOX_SEPARATOR_THICKHNESS * 2;

    const totalHeight = Math.max(
      this.rateDisplay.areaSize.height,
      this.peopleDisplay.areaSize.height,
      this.goalDisplay.areaSize.height,
    );

    const rateMidX = -totalWidth / 2 + rateBounds.width / 2;

    const peopleMidX =
      rateMidX +
      rateBounds.width / 2 +
      CONNECTION_BOX_PADDING_HORIZONTAL * 2 +
      BOX_SEPARATOR_THICKHNESS +
      hitBounds.width / 2;

    const goalMidX =
      peopleMidX +
      hitBounds.width / 2 +
      CONNECTION_BOX_PADDING_HORIZONTAL * 2 +
      BOX_SEPARATOR_THICKHNESS +
      goalBounds.width / 2;

    const separatorX =
      -totalWidth / 2 +
      rateBounds.width +
      CONNECTION_BOX_PADDING_HORIZONTAL +
      BOX_SEPARATOR_THICKHNESS / 2;

    const separatorX2 =
      peopleMidX +
      hitBounds.width / 2 +
      CONNECTION_BOX_PADDING_HORIZONTAL +
      BOX_SEPARATOR_THICKHNESS / 2;

    this.rateDisplay.x = rateMidX;
    this.rateDisplay.y = -rateBounds.height / 2;

    this.peopleDisplay.x = peopleMidX;
    this.peopleDisplay.y = -hitBounds.height / 2;

    this.goalDisplay.x = goalMidX;
    this.goalDisplay.y = -goalBounds.height / 2;

    this.graphics.clear();
    this.graphics.lineStyle(BOX_SEPARATOR_THICKHNESS, COLOR_DISPLAY_SEPARATOR, 1);
    this.graphics.moveTo(separatorX, -totalHeight / 2 - CONNECTION_BOX_PADDING);
    this.graphics.lineTo(separatorX, totalHeight / 2 + CONNECTION_BOX_PADDING - 1);
    this.graphics.moveTo(separatorX2, -totalHeight / 2 - CONNECTION_BOX_PADDING);
    this.graphics.lineTo(separatorX2, totalHeight / 2 + CONNECTION_BOX_PADDING - 1);
    this.graphics.closePath();

    this.drawTriangle(separatorX, midY, COLOR_DISPLAY_SEPARATOR, BOX_SEPARATOR_TRIANGLE);
    this.drawTriangle(separatorX2, midY, COLOR_DISPLAY_SEPARATOR, BOX_SEPARATOR_TRIANGLE);
  }

  layoutSegments() {
    this.goalDisplay.visible = false;
    const rateBounds = this.rateDisplay.areaSize;
    const hitBounds = this.peopleDisplay.areaSize;

    const midY = 0;
    const totalWidth =
      rateBounds.width +
      hitBounds.width +
      CONNECTION_BOX_PADDING_HORIZONTAL * 2 +
      BOX_SEPARATOR_THICKHNESS;
    const totalHeight = Math.max(
      this.rateDisplay.areaSize.height,
      this.peopleDisplay.areaSize.height,
    );
    const rateMidX = -totalWidth / 2 + rateBounds.width / 2;
    const peopleMidX =
      rateMidX +
      rateBounds.width / 2 +
      CONNECTION_BOX_PADDING_HORIZONTAL * 2 +
      BOX_SEPARATOR_THICKHNESS +
      hitBounds.width / 2;
    const separatorX =
      -totalWidth / 2 +
      rateBounds.width +
      CONNECTION_BOX_PADDING_HORIZONTAL +
      BOX_SEPARATOR_THICKHNESS / 2;

    this.rateDisplay.x = rateMidX;
    this.rateDisplay.y = -rateBounds.height / 2;

    this.peopleDisplay.x = peopleMidX;
    this.peopleDisplay.y = -hitBounds.height / 2;

    this.graphics.clear();
    this.graphics.lineStyle(BOX_SEPARATOR_THICKHNESS, COLOR_DISPLAY_SEPARATOR, 1);
    this.graphics.moveTo(separatorX, -totalHeight / 2 - CONNECTION_BOX_PADDING);
    this.graphics.lineTo(separatorX, totalHeight / 2 + CONNECTION_BOX_PADDING - 1);
    this.graphics.closePath();

    this.drawTriangle(separatorX, midY, COLOR_DISPLAY_SEPARATOR, BOX_SEPARATOR_TRIANGLE);
  }

  drawTriangle(x, midY, color, triangleSize) {
    this.graphics.lineStyle(0);
    this.graphics.beginFill(color, 1);
    this.graphics.moveTo(x, midY - triangleSize);
    this.graphics.lineTo(x + triangleSize, midY);
    this.graphics.lineTo(x, midY + triangleSize);
    this.graphics.closePath();
    this.graphics.endFill();
  }

  setNoData() {
    this.rateDisplay.setData(['n/a']);
    this.peopleDisplay.setData(['n/a']);
    this.goalDisplay.setData(['n/a']);

    if (isGoalValid(this.goal)) {
      this.layoutGoalSegments();
    } else {
      this.layoutSegments();
    }
  }

  resetData() {
    this.rateDisplay.setData([ANALYTICS_DATA_DEFAULT_PLACEHOLDER]);
    this.peopleDisplay.setData([ANALYTICS_DATA_DEFAULT_PLACEHOLDER]);
    this.goalDisplay.setData([ANALYTICS_DATA_DEFAULT_PLACEHOLDER]);

    if (isGoalValid(this.goal)) {
      this.layoutGoalSegments();
    } else {
      this.layoutSegments();
    }
  }

  getArea() {
    if (isGoalValid(this.goal)) {
      return {
        width:
          this.rateDisplay.areaSize.width +
          this.peopleDisplay.areaSize.width +
          this.goalDisplay.areaSize.width +
          BOX_SEPARATOR_THICKHNESS * 2 +
          CONNECTION_BOX_PADDING_HORIZONTAL * 4,
        height: Math.max(
          this.rateDisplay.areaSize.height,
          this.peopleDisplay.areaSize.height,
          this.goalDisplay.areaSize.height,
        ),
      };
    } else {
      return {
        width:
          this.rateDisplay.areaSize.width +
          this.peopleDisplay.areaSize.width +
          BOX_SEPARATOR_THICKHNESS +
          CONNECTION_BOX_PADDING_HORIZONTAL * 2,
        height: Math.max(this.rateDisplay.areaSize.height, this.peopleDisplay.areaSize.height),
      };
    }
  }
}
