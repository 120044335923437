export const NOTIFICATION_ALERTS = {
  PAN_GUIDE: 'panGuide',
  PERFECT_SHAPE_GUIDE: 'perfectShapeGuide',
  UPDATE_CANVAS_DATA: 'updateCanvasData',
};

export const TEXT_DONT_SHOW_AGAIN = 'Don’t show again';
export const TEXT_REMIND_ME_LATER = 'Remind me later';
export const TEXT_DISMISS = 'Dismiss';
export const TEXT_UPDATE_DATA = 'Update data';
