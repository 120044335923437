import * as PIXI from 'pixi.js';
import Styles, { COLOR_DISPALY_DEFAULT_TEXT } from 'pixi-project/view/Styles';

export const LABEL_OFFSET_Y = 0;

export const LABEL_ALIGN_LEFT = 'left';
export const LABEL_ALIGN_RIGHT = 'right';
export const LABEL_ALIGN_CENTER = 'center';

const SPACING_Y = 2;

export default class TextDisplay extends PIXI.Container {
  constructor() {
    super();

    this.data = null;
    this.displayObjects = [];
    this.areaSize = {
      width: 0,
      height: 0,
    };
    this.rowsData = [];
    this.hasBox = false;
    this.hasLine = false;
    this.align = LABEL_ALIGN_CENTER;

    this.graphics = new PIXI.Graphics();
    this.addChild(this.graphics);
  }
  /**
   * It will display all the strings that are given in the dataArray
   * @param {Array} dataArray
   */
  setData(dataArray) {
    this.data = [...dataArray];
    this._displayData();
  }

  /**
   * Creates all the labels and assigns the text to be displayed
   */
  _displayData() {
    for (let i = 0; i < this.data.length; i++) {
      const objectData = this.data[i];
      let oData = objectData;

      if (objectData.children) {
        oData = [];
        for (let j = 0; j < objectData.children.length; j++) {
          const tData = objectData.children[j];
          if (tData.visible === false) {
            continue;
          }
          if (tData.texture) {
            oData.push(tData.texture);
          } else {
            oData.push(tData.value);
          }
        }
      } else if (typeof objectData === 'object') {
        oData = objectData.value;
      }

      // check if cached data is different from what we need to display
      if (this.displayObjects[i]) {
        if (this._isLabelDifferent(this.displayObjects[i], objectData)) {
          this._removeLabels(i);
          this._createDisplayObject(objectData);
        }
      }

      // If the label does not exist , create it on the fly.
      let displayObjects = this.displayObjects[i] || this._createDisplayObject(objectData);

      if (objectData.children) {
        for (let j = 0; j < displayObjects._children.length; j++) {
          const object = displayObjects._children[j];
          j = this.getNextVisible(objectData.children, j);
          if (objectData.children[j]) {
            object.text = objectData.children[j].value;
          }
        }
      } else {
        if (oData && oData.isSeparator) {
          // update separator size
        } else {
          displayObjects.text = oData;
        }
      }
    }

    this._removeUnusedLabels();
    this.repositionDisplayObjects();
  }

  getNextVisible(array, index) {
    if (!array[index]) {
      return index;
    }
    return array[index].visible === false ? this.getNextVisible(array, index + 1) : index;
  }

  _isLabelDifferent(label, labelData) {
    if (label._children && !labelData.children) {
      return true;
    } else if (!label._children && labelData.children) {
      return true;
    } else if (label._children && labelData.children) {
      if (labelData.length !== label._children.length) {
        return true;
      } else {
        for (let i = 0; i < label._children.length; i++) {
          const l = label._children[i];
          const d = labelData.children[i];
          if (this._isLabelDataDifferent(l, d)) {
            return true;
          }
        }
      }
    } else {
      if (this._isLabelDataDifferent(label, labelData)) {
        return true;
      }
    }

    return false;
  }

  _isLabelDataDifferent(label, labelData) {
    if (label.tint !== labelData.fill) {
      return true;
    }

    return false;
  }

  /**
   * Create a label object
   * @returns PIXI.Text
   */
  _createDisplayObject(displayData) {
    if (displayData.children) {
      const listOfDisplayObjects = [];

      for (let j = 0; j < displayData.children.length; j++) {
        const tData = displayData.children[j];
        if (tData.visible === false) {
          continue;
        }
        if (tData.texture) {
          this.createImage(tData, listOfDisplayObjects);
        } else {
          this.createLabel(tData, listOfDisplayObjects);
        }
      }

      const objects = {
        _children: listOfDisplayObjects,
      };

      this.displayObjects.push(objects);
      return objects;
    } else if (typeof displayData === 'object') {
      if (displayData.visible === false) {
        return false;
      }
      if (displayData.isSeparator) {
        return this.createSeparator(displayData, this.displayObjects);
      } else {
        return this.createLabel(displayData, this.displayObjects);
      }
    } else {
      const label = this.createLabel({}, this.displayObjects);
      label.tint = COLOR_DISPALY_DEFAULT_TEXT;
      return label;
    }
  }

  createLabel(styleData, labels) {
    const label = new PIXI.BitmapText('', Styles.FOOTER_DEFAULT);
    this.applyStyle(label, styleData);
    label.anchor.set(0.5, 0.5);
    this.addChild(label);
    labels.push(label);
    return label;
  }

  createSeparator(styleData, objects) {
    const separator = new PIXI.Graphics();
    separator.styleData = styleData;
    this.updateSeparator(separator, styleData);
    this.addChild(separator);
    objects.push(separator);
    return separator;
  }

  updateSeparator(separator, styleData) {
    separator.clear();
    separator.lineStyle(styleData.thickness || 1, styleData.color || 0x000000, 1);
    separator.moveTo(-styleData.width / 2, 0);
    separator.lineTo(styleData.width / 2, 0);
    separator.closePath();
  }

  createImage(textureData, list) {
    const image = PIXI.Sprite.from(textureData.texture);
    image.scale.set(textureData.scale || 1);
    image.anchor.set(0.5, 0.5);
    this.addChild(image);
    list.push(image);
    return image;
  }

  applyStyle(label, style) {
    label.tint = style.fill === undefined ? COLOR_DISPALY_DEFAULT_TEXT : style.fill;
    label.fontSize = style.fontSize === undefined ? label.fontSize : style.fontSize;
    label.fontName = style.fontName === undefined ? label.fontName : style.fontName;
    if (style.wordWrap) {
      label.maxWidth = style.wordWrap;
    }
  }

  /**
   * It will remove all the labels for which we do not have data
   */
  _removeUnusedLabels() {
    // remove any labels that are not being used
    this._removeLabels(this.data.length);
  }

  /**
   * It will remove all the labels starting at a given index
   */
  _removeLabels(startIndex) {
    // remove any labels that are not being used
    const removedLabels = this.displayObjects.splice(startIndex);

    for (let i = removedLabels.length - 1; i >= 0; i--) {
      if (removedLabels[i]._children) {
        const rl = removedLabels[i]._children;
        for (let j = 0; j < rl.length; j++) {
          const label = rl[j];
          label.removeFromParent();
        }
      } else {
        const label = removedLabels[i];
        label.removeFromParent();
      }
    }
  }

  repositionDisplayObjects() {
    let y = LABEL_OFFSET_Y;
    const PADDING_X = 5;
    let biggestWidth = 0;

    const rowsData = [];

    this.graphics.clear();

    for (let i = 0; i < this.displayObjects.length; i++) {
      if (this.displayObjects[i]._children) {
        const labels = this.displayObjects[i]._children;

        const data = this.data[i];
        const spacing = data && data.spacing !== undefined ? data.spacing : SPACING_Y;
        let highestLabel = 0;
        let width = 0;

        for (let j = 0; j < labels.length; j++) {
          const label = labels[j];
          if (label.height > highestLabel) {
            highestLabel = label.height;
          }
          width += label.width;
        }

        width += PADDING_X * (labels.length - 1);

        if (width > biggestWidth) {
          biggestWidth = width;
        }

        let startX = -width / 2;

        for (let j = 0; j < labels.length; j++) {
          const label = labels[j];
          label.x = startX + label.width / 2;
          startX += label.width + PADDING_X;
          label.y = y + highestLabel / 2;
        }

        rowsData.push({
          y: y,
          width: width,
          height: highestLabel + spacing,
          spacing: spacing,
          data: data,
        });

        if (data.background !== undefined) {
          const bWidth = data.width ? data.width : biggestWidth;
          const extraPadding = data.backgroundHorizontalPadding || 0;
          const sharpen = data.sharpen || null;

          if (data.round) {
            const hh = highestLabel + spacing;
            const ww = bWidth + extraPadding * 2;
            const xx = -bWidth / 2 - extraPadding;
            const yy = y - spacing / 2;
            this.graphics.beginFill(data.background, 1);
            this.graphics.drawRoundedRect(xx, yy, ww, hh, data.round);
            this.graphics.endFill();

            // Shapen top edges
            this.graphics.beginFill(data.background, 1);
            this.graphics.drawRect(xx, yy, ww, hh / 2);
            this.graphics.endFill();

            if (sharpen === 'left') {
              this.graphics.beginFill(data.background, 1);
              this.graphics.drawRect(xx, yy + hh / 2, ww / 2, hh / 2);
              this.graphics.endFill();
            } else if (sharpen === 'right') {
              this.graphics.beginFill(data.background, 1);
              this.graphics.drawRect(xx + ww / 2, yy + hh / 2, ww / 2, hh / 2);
              this.graphics.endFill();
            } else if (sharpen === 'both') {
              this.graphics.beginFill(data.background, 1);
              this.graphics.drawRect(xx, yy + hh / 2, ww, hh / 2);
              this.graphics.endFill();
            }
          } else {
            this.graphics.beginFill(data.background, 1);
            this.graphics.drawRect(-bWidth / 2, y - spacing / 2, bWidth, highestLabel + spacing);
            this.graphics.endFill();
          }
        }

        y += highestLabel + spacing;
      } else {
        const displayObject = this.displayObjects[i];
        const data = this.data[i];
        const spacing = data && data.spacing !== undefined ? data.spacing : SPACING_Y;
        const offsetY = data && data.offsetY !== undefined ? data.offsetY : 0;

        y += offsetY;

        rowsData.push({
          y: y,
          width: displayObject.width,
          height: displayObject.height + spacing,
          spacing: spacing,
          data: data,
        });

        displayObject.y = y + displayObject.height / 2;
        y += displayObject.height + spacing;
        if (displayObject.width > biggestWidth) {
          biggestWidth = displayObject.width;
        }
      }
    }

    if (this.align !== LABEL_ALIGN_CENTER) {
      for (let i = 0; i < this.displayObjects.length; i++) {
        if (this.displayObjects[i]._children) {
          const labels = this.displayObjects[i]._children;
          if (this.align === LABEL_ALIGN_LEFT) {
            let startX = -biggestWidth / 2;
            for (let j = 0; j < labels.length; j++) {
              const label = labels[j];
              label.x = startX + label.width / 2;
              startX += label.width + PADDING_X * j + PADDING_X;
            }
          } else if (this.align === LABEL_ALIGN_RIGHT) {
            let startX = biggestWidth / 2;
            let counter = 0;
            for (let j = labels.length - 1; j >= 0; j--) {
              const label = labels[j];
              label.x = startX - label.width / 2;
              startX = startX - label.width - PADDING_X * counter++ - PADDING_X;
            }
          }
        } else {
          const label = this.displayObjects[i];
          if (this.align === LABEL_ALIGN_LEFT) {
            label.x = -biggestWidth / 2 + label.width / 2;
          } else if (this.align === LABEL_ALIGN_RIGHT) {
            label.x = biggestWidth / 2 - label.width / 2;
          }
        }
      }
    }

    this.areaSize.width = biggestWidth;
    this.areaSize.height = y - LABEL_OFFSET_Y;
    this.graphics.endFill();

    this.rowsData = rowsData;
  }
}
