import React from 'react';
import cx from 'classnames';

const statusDict = {
  active: 'active',
  inactive: 'inactive',
};

const toTitleCase = (txt) => {
  try {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  } catch (e) {
    return '';
  }
};

function Circle({ bgClassName, popupText }) {
  return <div className={cx('h-2 w-2 rounded-full', bgClassName)} title={popupText || ''} />;
}

export function StatusIndicator({ status }) {
  if (status?.toLowerCase() === statusDict.active) {
    return <Circle bgClassName="bg-bright-green" popupText={toTitleCase(status)} />;
  }

  return <Circle bgClassName="bg-slate-400" popupText={toTitleCase(status)} />;
}

export default StatusIndicator;
