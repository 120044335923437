export default class LocalStorage {
  static mainKey = 'funnelytics-';

  static get(key, defaultValue = null) {
    let itm = null;
    if (window['localStorage']) {
      itm = JSON.parse(localStorage.getItem(LocalStorage.mainKey + key));
    }
    if (itm === null) {
      return defaultValue;
    }
    return itm;
  }

  static set(key, value) {
    if (window['localStorage']) {
      localStorage.setItem(LocalStorage.mainKey + key, JSON.stringify(value));
    }
  }

  static remove(key) {
    if (window['localStorage']) {
      localStorage.removeItem(LocalStorage.mainKey + key);
    }
  }
}
