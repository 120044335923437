import { EExplorerConfigTypes } from './CSharedCategories';
import { IntegrationTypes } from './CSharedConstants';
import SharedElementHelpers from './SharedElementHelpers';

export default class TrafficData {
  static data = null;

  static findBy(detail) {
    if (TrafficData.data) {
      // The key will have the next_ prefics
      // as we are searching for the plain data the element will show
      // that is not dependent on next_ or previous_
      // The generaly analytics data for the elements is stored under the next_ key
      // next_ previous_ are important for connections
      let key = 'next_';

      if (detail.explorerType === EExplorerConfigTypes.PAGE) {
        key += 'pages';
      } else if (detail.explorerType === EExplorerConfigTypes.PAGE_ALL) {
        key += 'pages_all';
      } else if (detail.explorerType === EExplorerConfigTypes.EVENT) {
        key += 'actions';
      } else if (detail.explorerType === EExplorerConfigTypes.EVENT_ALL) {
        key += 'actions_all';
      } else if (detail.explorerType === EExplorerConfigTypes.SOURCE) {
        key = 'page_parameters';
      } else if (detail.explorerType === EExplorerConfigTypes.SOURCE_ALL) {
        key = 'page_parameters_all';
      }

      var data = TrafficData.data[key];

      // Direct traffic object
      if (detail.object.integrationType === IntegrationTypes.DIRECT_TRAFFIC) {
        return detail.object.value;
      }

      if (data && data.list) {
        const list = data.list;
        return this.findInList(detail.object, list);
      }

      if (data && data.common_parameters && data.custom_parameters) {
        let commonData = this.findInList(detail.object, data.common_parameters);
        if (commonData !== null) {
          return commonData;
        }
        return this.findInList(detail.object, data.custom_parameters);
      }
    }

    return null;
  }

  static findInList(object, list) {
    if (SharedElementHelpers.IsPage(object)) {
      //For pages search for a matching url
      for (let i = 0; i < list.length; i++) {
        const analyticsData = list[i];
        if (analyticsData.url === object.url) {
          return analyticsData.hits;
        }
      }
    } else if (SharedElementHelpers.IsAction(object)) {
      // For events search for a matching name of the action
      for (let i = 0; i < list.length; i++) {
        const analyticsData = list[i];
        if (analyticsData.name === object.actionName) {
          return analyticsData.hits;
        }
      }
    } else if (SharedElementHelpers.IsSource(object) && object.filterData.length > 0) {
      // For sources search for matching key and value
      let parameterKey = object.filterData[0].key;
      let parameterValue = object.filterData[0].value;

      for (let i = 0; i < list.length; i++) {
        const analyticsData = list[i];
        if (analyticsData.key === parameterKey) {
          if (parameterValue === '*') {
            return analyticsData.hits;
          } else {
            for (let j = 0; j < analyticsData.values.length; j++) {
              const subValue = analyticsData.values[j];
              if (subValue.value === parameterValue) {
                return subValue.hits;
              }
            }
          }
        }
      }
    }
    return null;
  }

  static setData(data) {
    TrafficData.data = data;
  }
}
