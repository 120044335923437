import React, { useEffect } from 'react';
import styles from './RangePicker.module.scss';
import cls from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { PR_EVENT_COMPARE_RANGE_CHANGED, PR_EVENT_FUNNEL_CHANGED } from 'shared/CSharedEvents';
import {
  setCompareRangeFrom,
  setCompareRangeTo,
} from 'react-project/redux/funnel-configuration/actions';

export const RangePicker = () => {
  const dispatch = useDispatch();
  const compareRange = useSelector((state) => {
    return state.funnelConfiguration.compareFilter.range;
  });

  useEffect(() => {
    commonSendEventFunction(PR_EVENT_COMPARE_RANGE_CHANGED, compareRange);
  }, []);

  const onChangeRangeFrom = (e) => {
    dispatch(setCompareRangeFrom(e.target.value));
    commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
  };

  const onChangeRangeTo = (e) => {
    dispatch(setCompareRangeTo(e.target.value));
    commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
  };

  const onBlurRangeFrom = (e) => {
    dispatch(setCompareRangeFrom(Number(e.target.value) || 0));
    commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
  };

  const onBlurRangeTo = (e) => {
    dispatch(setCompareRangeTo(Number(e.target.value) || 0));
    commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
  };

  return (
    <div className={styles.RangeComponentWrapper}>
      <h3 style={{ marginBottom: '10px' }}>Signal indicators</h3>
      <p>Set your mid range (yellow) indicators. Green and red will automatically adjust.</p>
      <div className={styles.RangeContainer}>
        <input
          className={styles.RangeInput}
          placeholder="0%"
          onChange={onChangeRangeFrom}
          onBlur={onBlurRangeFrom}
          value={compareRange.from}
          maxLength="4"
          type="text"
        />
        <span style={{ margin: '0 10px' }}>to</span>
        <input
          className={styles.RangeInput}
          placeholder="10%"
          onChange={onChangeRangeTo}
          onBlur={onBlurRangeTo}
          value={compareRange.to}
          maxLength="4"
          type="text"
        />
      </div>
      <div className={styles.RangeContainer}>
        <div className={cls(styles.RangeIndicator, styles.RangeBelow)}>
          &lt; {compareRange.from}%
        </div>
        <div className={cls(styles.RangeIndicator, styles.RangeBetween)}>
          {compareRange.from}% &lt;&gt; {compareRange.to}%
        </div>
        <div className={cls(styles.RangeIndicator, styles.RangeAbove)}>&gt; {compareRange.to}%</div>
      </div>
    </div>
  );
};
