import _ from 'lodash';
import moment from 'moment';
import { CACHE_TYPE } from 'react-project/Constants/requestCache';
import { COMMERCE_ACTION_NAME } from 'react-project/Constants/specialActions';
import { parseURL } from 'react-project/Helpers/URLHelpers';
import {
  backwardCompatibleOperator,
  isExcludedOperator,
} from 'react-project/Toolbar/step-toolbar/LogicalOperators';
import { EElementCategories } from 'shared/CSharedCategories';
import {
  ActionTypes,
  DATA_MODE_FUNNEL,
  DATA_MODE_TRAFFIC,
  IntegrationTypes,
} from 'shared/CSharedConstants';
import { cloneData } from 'shared/CSharedMethods';
import SharedElementHelpers from 'shared/SharedElementHelpers';
import { removeCyclicConnections } from './requestConstruction';
import { EConnectionLineType } from 'pixi-project/base/joint/CConnectionConstants';

const DEFAULT_DEVICE_FILTER = 'all devices';
const DEFAULT_COUNTRIES_FILTER = 'all countries';
const VALID_PEOPLE_CSV_OPTIONS = ['withEmails'];

export const getPeopleFilterProperties = ({
  compareMode,
  currentStep,
  dataConnections,
  dataObjs,
  focusedSteps,
  funnelConfiguration,
  limit = 15,
  pageNumber = 1,
}) => {
  const filterProperties = compareMode
    ? getFilters(funnelConfiguration.compareFilter, focusedSteps)
    : getFilters(funnelConfiguration.filter, focusedSteps);
  const dateProperty = compareMode
    ? getRangeProperty(funnelConfiguration.compareDateRange)
    : getRangeProperty(funnelConfiguration.dateRange);
  const pagination = limit ? { page: pageNumber, limit } : { page: pageNumber };
  const dictionary = getTransformedDictionary(dataObjs);
  const connections = getTransformedConnections(dataConnections);
  const list = [...Object.values(dictionary), ...connections];
  const selectedElement = list.find((el) => {
    return el.ID === currentStep.stepId || el.id === currentStep.stepId;
  });
  const data = {
    filter: {
      ..._.omit(filterProperties, 'filter.session'),
      range: dateProperty,
      pagination,
      canvas: {
        dictionary,
        connections, // joints
        isDisabled: 'false',
      },
      selected_element: selectedElement,
      funnelMode: funnelConfiguration.dataMode === DATA_MODE_FUNNEL ? 'true' : 'false',
    },
    options: getOptionsProperties(),
    cacheType: CACHE_TYPE.PEOPLE_IN_STEPS_ANALYTICS,
  };
  if (focusedSteps.length > 0) {
    const focus = focusedSteps.map((step) => dictionary[step.ID]);
    data.filter['focus'] = focus;
  }
  return data;
};

export const getPeopleFilterCsvProperties = ({
  compareMode,
  currentStep,
  dataConnections,
  dataObjs,
  focusedSteps,
  funnelConfiguration,
  filterOptions = {},
}) => {
  const data = getPeopleFilterProperties({
    compareMode,
    currentStep,
    dataConnections,
    dataObjs,
    focusedSteps,
    funnelConfiguration,
  });
  const validOptionsList = Object.keys(filterOptions).filter((x) =>
    VALID_PEOPLE_CSV_OPTIONS.includes(x),
  );
  if (validOptionsList.length > 1) {
    data.filter['export'] = {};
    validOptionsList.forEach((el) => (data.filter.export[el] = filterOptions[el]));
  }
  return data;
};

export const getExplorerFilterProperties = ({
  funnelConfiguration,
  compareMode,
  focusedSteps,
  search,
  pageNumber = 1,
  exploredStep,
  limit,
  nameRequired = true,
  attributeKey,
}) => {
  const dateProperty = compareMode
    ? getRangeProperty(funnelConfiguration.compareDateRange)
    : getRangeProperty(funnelConfiguration.dateRange);

  const filterProperties = compareMode
    ? getFilters(funnelConfiguration.compareFilter, focusedSteps)
    : getFilters(funnelConfiguration.filter, focusedSteps);

  const { focusProperty, canvas } = createCanvasProperties({
    focusedSteps,
    exploredStep,
    isDisabled: false,
    nameRequired: true,
    funnelConfiguration,
  });

  const searchProperty = search ? { search } : {};
  const attributeKeyProperty = attributeKey ? { attribute_key: attributeKey } : {};
  // Need to pass nameRequired to true if we have exploredStep, so that we get custom action list associate with selected action.
  // Changed it always be true , so that when creating a cache key , we differ elements by that
  const exploredStepProperty = exploredStep
    ? { explored_step: transformSingleElement(exploredStep, true) }
    : {};

  const paginationProperty = limit
    ? { pagination: { page: pageNumber, limit } }
    : { pagination: { page: pageNumber } };

  const result = {
    ..._.omit(filterProperties, 'filter.session'),
    ...paginationProperty,
    ...focusProperty,
    canvas,
    explorer: {
      explored_step_is_dependent_action: 'false',
      ...searchProperty,
      ...attributeKeyProperty,
    },
    ...exploredStepProperty,
    range: dateProperty,
  };

  if (funnelConfiguration.dataMode === DATA_MODE_FUNNEL) {
    result.funnelMode = 'true';
  }

  return result;
};

export const getOptionsProperties = () => {
  return {
    peopleVsTotal: 'individual-people',
  };
};

export const getConnections = ({ connections, dictionary }) => {
  const transformedConnections = [];
  if (connections && connections.length > 0) {
    const objectKeys = Object.keys(dictionary);
    // Do not use in the request joints between elements that are not in the dictionary
    const filteredConnections = connections.filter((conn) => {
      if (objectKeys.includes(conn.iconA_ID) && objectKeys.includes(conn.iconB_ID)) {
        return conn;
      }
    });

    filteredConnections.forEach((item) => {
      const connectionItem = {
        id: item.ID,
        source: item.iconA_ID,
        target: item.iconB_ID,
        category: EElementCategories.CONNECTION.toLowerCase(),
        ignore_in_between: 'false',
      };
      if (item.ignoreInBetween) {
        connectionItem['ignore_in_between'] = item.ignoreInBetween.toString();
      }

      if (item.lineType === EConnectionLineType.NODATA) {
        connectionItem.is_no_data_line = 'true';
      }

      transformedConnections.push(connectionItem);
    });
  }

  return transformedConnections;
};

export const transformSingleElement = (objectData, nameRequired = true) => {
  const stringId = objectData.ID.toString();
  let transformedObject = {};
  if (SharedElementHelpers.IsStep(objectData) && !SharedElementHelpers.IsMisc(objectData)) {
    transformedObject = {
      id: objectData.ID.toString(),
      category: objectData.category?.toLowerCase(),
      type: objectData.type?.toLowerCase(),
      url: parseURL(objectData.url),
      is_commerce: (objectData.actionType === ActionTypes.COMMERCE).toString(),
    };

    if (objectData.actionName && nameRequired) {
      transformedObject.name = objectData.actionName;
    }

    if (objectData.actionType === ActionTypes.COMMERCE) {
      transformedObject.name = COMMERCE_ACTION_NAME;
    }

    if (objectData.filterData && objectData.filterData.length > 0) {
      // Ignore paramters with empty value and replace "*" with ""
      // Because for the backend the "" is wildcard
      const filters = cloneData(objectData.filterData);

      // But in the case of the excluded operator
      // we should always have * value
      // isExcludedOperator overwrite
      // search for the isExcludedOperator to find the other overwrites
      filters.map((itm) => {
        if (isExcludedOperator(itm.operator)) {
          itm.value = '*';
        }
        return itm;
      });

      const attributes = filters.filter((itm) => {
        return itm.value !== '';
      });
      attributes.forEach((attributeItem) => {
        attributeItem.value = attributeItem.value === '*' ? '*' : attributeItem.value;
        if (Array.isArray(attributeItem.value)) {
          attributeItem.value = attributeItem.value.filter((val) => {
            return val !== '';
          });
        }

        // contains is still required
        // this code will need to be removed if we drop contains support in the API
        if (
          SharedElementHelpers.IsSource(objectData) ||
          SharedElementHelpers.IsAction(objectData)
        ) {
          if (!attributeItem.contains) {
            attributeItem.contains = 'true';
          } else {
            attributeItem.operator = backwardCompatibleOperator(attributeItem);
            attributeItem.contains = 'true';
          }

          // Exception for exclude
          // Exclude is treated as a Operator in the UI
          // but it is sent differently in the API request
          if (isExcludedOperator(attributeItem.operator)) {
            attributeItem.operator = '=';
            attributeItem.contains = 'false';
          }
        } else if (SharedElementHelpers.IsPage(objectData)) {
          // by defult we need to have the contains paramter set to true
          if (!attributeItem.contains) {
            attributeItem.contains = 'true';
          }
        }

        // Replace space with + singn in utm parameter values as facebook is replacing while creating ads URL
        if (objectData.integrationType === IntegrationTypes.FACEBOOK_AD) {
          if (Array.isArray(attributeItem)) {
            attributeItem.forEach((el) => {
              if (el.key.indexOf('utm_') !== -1) {
                el.value = el.value.replaceAll(' ', '+');
              }
            });
          } else {
            if (attributeItem.key.indexOf('utm_') !== -1) {
              attributeItem.value = attributeItem.value.replaceAll(' ', '+');
            }
          }
        }
      });
      transformedObject['attributes'] = attributes;
    }
  }

  if (SharedElementHelpers.IsSource(objectData) && _.isEmpty(objectData.integrationAttributes)) {
    const isURLParameters = objectData.integrationType === IntegrationTypes.URL_PARAMETERS;
    transformedObject['url'] = isURLParameters ? '' : parseURL(objectData.trackingURL);
  }

  if (
    SharedElementHelpers.IsSource(objectData) &&
    objectData.integrationType === IntegrationTypes.DIRECT_TRAFFIC
  ) {
    transformedObject.is_direct_traffic = 'true';
    if (objectData.trackingURL) {
      transformedObject['url'] = parseURL(objectData.trackingURL);
    } else {
      transformedObject['url'] = '*';
    }
  }

  if (SharedElementHelpers.IsConnection(objectData)) {
    const transformedObject = {};
    transformedObject['id'] = stringId;
    transformedObject['source'] = objectData.iconA_ID;
    transformedObject['target'] = objectData.iconB_ID;
    transformedObject['category'] = EElementCategories.CONNECTION.toLowerCase();
    transformedObject['ignore_in_between'] = objectData.ignoreInBetween?.toString() || 'false';
    return transformedObject;
  }

  return transformedObject;
};

export const createCanvasProperties = ({
  focusedSteps,
  exploredStep,
  isDisabled = false,
  nameRequired = true,
  funnelConfiguration,
}) => {
  const result = {};
  const focusProperty = {};

  if (focusedSteps && focusedSteps.length) {
    for (let i = 0; i < focusedSteps.length; i++) {
      const step = focusedSteps[i];
      const focusedStepData = transformSingleElement(step, nameRequired);
      if (!focusProperty.focus) {
        focusProperty.focus = [];
      }
      focusProperty.focus.push(focusedStepData);
    }
  }

  result.focusProperty = focusProperty;

  if (funnelConfiguration.dataMode === DATA_MODE_TRAFFIC) {
    const dictionary = {};

    if (!_.isEmpty(focusProperty)) {
      for (let i = 0; i < focusedSteps.length; i++) {
        const step = focusedSteps[i];
        dictionary[step.ID] = step;
      }
    }

    if (exploredStep) {
      dictionary[exploredStep.ID] = exploredStep;
    }

    // Need to pass nameRequired to false so that custom action list is not  associate with selected action.
    const transformedDictionary = !_.isEmpty(dictionary)
      ? { dictionary: getTransformedDictionary(dictionary, nameRequired) }
      : {};

    result.canvas = {
      ...transformedDictionary,
      isDisabled: isDisabled.toString(),
    };
  } else {
    // Funnel mode
    const sceneData = window.app.planeContainer.sceneManager.getSceneData(false);
    const data = JSON.parse(sceneData.data);

    const transformedDictionary = getTransformedDictionary(data.objects, nameRequired);
    const transformedConnections = getTransformedConnections(data.joints);

    removeCyclicConnections(funnelConfiguration, transformedDictionary, transformedConnections);

    result.canvas = {
      dictionary: transformedDictionary,
      connections: transformedConnections,
      isDisabled: isDisabled.toString(),
    };
  }

  return result;
};

export const getTransformedDictionary = (dictionary, nameRequired = true) => {
  const transformedDictionary = {};
  for (let key in dictionary) {
    try {
      const item = dictionary[key];
      if (!item.ID) continue;
      const stringId = item.ID.toString();
      transformedDictionary[stringId] = transformSingleElement(item, nameRequired);
    } catch (e) {
      console.log('GTD: ', e);
    }
  }

  return transformedDictionary;
};

export const getTransformedConnections = (connections) => {
  const output = connections.map((connection) => transformSingleElement(connection));
  return output;
};

export const getFilters = (filters, focusedSteps) => {
  const properties = {};

  if (filters.device && filters.device !== DEFAULT_DEVICE_FILTER) {
    properties.device = filters.device;
  }

  if (focusedSteps && focusedSteps.length && filters.contributionWindow) {
    properties.contributionWindow = filters.contributionWindow;
  }

  if (focusedSteps && focusedSteps.length && filters.focusLogicalOperator) {
    properties.focusLogicalOperator = filters.focusLogicalOperator;
  }

  if (
    filters.countries &&
    filters.countries.length > 0 &&
    filters.countries[0] !== DEFAULT_COUNTRIES_FILTER
  ) {
    properties.profiles = {
      countries: {
        allow: filters.countries,
      },
    };
  }

  if (filters.session) {
    properties.session = filters.session;
  }

  return properties;
};

export const getRangeProperty = (dateRange) => {
  const min = moment(dateRange.min).startOf('day');
  const max = moment(dateRange.max).endOf('day');

  return {
    min: min.toISOString(),
    max: max.toISOString(),
    min_local: min.format('YYYYMMDD'),
    max_local: max.format('YYYYMMDD'),
  };
};
