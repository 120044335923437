import React, { useEffect, useRef, useState } from 'react';
import {
  iconActions,
  iconMisk,
  iconPage,
  iconSearch,
  iconTraffic,
} from 'react-project/assets/Icons';
import { IconList } from 'react-project/components/iconList/IconList';
import { selectCustomIcons } from 'react-project/redux/custom-icons/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { ActionTypes, IntegrationTypes, MAP_TABS } from 'shared/CSharedConstants';
import styles from './MappingBlock.module.scss';
import cls from 'classnames';
import produce from 'immer';
import { setCustomIconsAction } from 'react-project/redux/custom-icons/actions';
import { FileUploader } from 'react-project/components/fileUploader/FileUploader';
import { When } from 'react-project/Util/When';
import RequestService from 'react-project/Helpers/RequestService';
import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';
import { sendHeapTracking } from 'react-project/Util/HEAP.utilities';
import { track as trackCohesive } from 'react-project/Util/CohesiveTracking';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { PR_EVENT_MIDDLE_MOUSE_DOWN, RP_EVENT_CREATE_OBJECT } from 'shared/CSharedEvents';
import { setNewCurrentStepLabel } from 'react-project/redux/current-step/actions';
import { EElementCategories } from 'shared/CSharedCategories';
import { selectCurrentFunnelId, selectProjectId } from 'react-project/redux/funnels/selectors';
import MainStorage from 'pixi-project/core/MainStorage';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';

// Define Tabs
const tabs = [
  { icon: iconTraffic, title: 'Sources', type: MAP_TABS.SOURCE },
  { icon: iconPage, title: 'Pages', type: MAP_TABS.PAGE },
  { icon: iconActions, title: 'Actions', type: MAP_TABS.EVENT },
  { icon: iconMisk, title: 'Offline', type: MAP_TABS.MISC },
];

export const MappingBlock = () => {
  const dispatch = useDispatch();
  const textSearchInputRef = useRef(null);
  const elementCreationShiftRef = useRef(0);
  const customIcons = useSelector(selectCustomIcons);
  const [isDisabledMapTab, setIsDisabledMapTab] = useState(false);
  const [textSearch, setTextSearch] = useState('');
  const [isUploaderOpened, setIsUploaderOpened] = useState(false);
  const [uploaderData, setUploaderData] = useState(null);
  const [activeTab, setActiveTab] = useState(MAP_TABS.PAGE);
  const funnelId = useSelector(selectCurrentFunnelId);
  const projectId = useSelector((state) => selectProjectId(state, funnelId));
  const canvasPermissions = useSelector(selectCanvasPermissions);

  useEffect(() => {
    document.addEventListener(PR_EVENT_MIDDLE_MOUSE_DOWN, onMiddleMouseDown, false);

    return () => {
      document.removeEventListener(PR_EVENT_MIDDLE_MOUSE_DOWN, onMiddleMouseDown, false);
    };
  }, []);

  const onMiddleMouseDown = (e) => {
    textSearchInputRef.current.blur();
  };

  // Switching tabs
  const activateTab = (type) => {
    // MAP subtab changed
    setActiveTab(type);
    setIsDisabledMapTab(false);
    focusTextSearch();
  };

  // Searching
  const focusTextSearch = () => {
    textSearchInputRef.current.focus();
  };

  const onSearchChange = (e) => {
    onSetTextSearch(e.target.value);
  };

  const onSetTextSearch = (value) => {
    setIsDisabledMapTab(!!value);
    setTextSearch(value);
  };

  // Click outside of uploader
  const clickOutsideUploader = (e) => {
    setIsUploaderOpened(false);
    setUploaderData(null);
  };

  // Adding new custom icon
  const onAddIconClick = (data) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }
    setIsUploaderOpened(true);
    setUploaderData(data);
  };

  const onFileUploaderClose = (e) => {
    setIsUploaderOpened(false);
    setUploaderData(null);
  };

  const onFileUploaded = (fileID, fileName, imageURL, uploadType) => {
    if (imageURL) {
      const imageData = {
        id: fileID,
        name: fileName,
        path: imageURL,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        type: uploadType.toString().toLowerCase(),
      };

      const newItems = produce(customIcons, (draft) => {
        draft.push(imageData);
      });
      dispatch(setCustomIconsAction(newItems));
    }

    setIsUploaderOpened(false);
    setUploaderData(null);
  };

  // Deleting custom icon
  const onIconCloseClick = async (e) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }

    const iconId = e.detail.id;
    const requestService = new RequestService();
    await requestService.deleteIcon(iconId);

    const newItems = produce(customIcons, (draft) => {
      const index = draft.findIndex((item) => item.id === iconId);
      if (index !== -1) draft.splice(index, 1);
    });

    dispatch(setCustomIconsAction(newItems));
  };

  const getCustomIcons = (type) => {
    const iconType = type.toLowerCase();
    return customIcons
      .filter((item) => item.type === iconType)
      .sort((a, b) => {
        if (a.created_at === b.created_at) {
          return 0;
        }
        return a.created_at > b.created_at ? 1 : -1;
      });
  };

  const onIconClick = (step) => {
    const panelSize = MainStorage.getLeftPanelSize();
    const spacing = 10;
    const shiftIndex = elementCreationShiftRef.current;

    const x = panelSize.width + (window.innerWidth - panelSize.width) / 2 + shiftIndex * spacing;
    const y = panelSize.height / 2 + shiftIndex * spacing;

    createElementOnCanvas(step, x, y);
    elementCreationShiftRef.current += 1;

    if (elementCreationShiftRef.current > 20) {
      elementCreationShiftRef.current = 0;
    }
  };

  const onItemDroppedToCanvas = (clientX, clientY, step) => {
    createElementOnCanvas(step, clientX, clientY);
  };

  const createElementOnCanvas = (step, clientX, clientY) => {
    // check canvas permissions
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }

    const {
      src,
      title,
      type,
      url,
      filterData,
      actionType,
      actionName,
      integrationType,
      isCustom = false,
    } = step;

    dispatch(setNewCurrentStepLabel(title));

    const objectData = {
      type,
      src,
      label: title,
      category: EElementCategories.STEP,
      actionType: actionType || ActionTypes.NONE,
      integrationType: integrationType || IntegrationTypes.NONE,
      isCustom,
    };

    // Fix the type for events
    if (type.toString().endsWith('_ALL')) {
      objectData.type = type.substring(0, type.length - 4);
    }

    if (url) {
      objectData.url = url;
    }

    if (actionName) {
      objectData.actionName = actionName;
    }

    if (filterData) {
      objectData.filterData = filterData;
    }

    const explorerType = type;
    const stepData = {
      mappingDirection: 'New Step',
      stepMapped: objectData.type,
    };
    const event = `MAP_${objectData.category}`;

    sendHeapTracking({
      projectId: projectId,
      eventName: event.toLowerCase(),
      eventData: stepData,
    });

    trackCohesive(event.toLowerCase(), {
      ...stepData,
      projectId: projectId,
    });

    commonSendEventFunction(RP_EVENT_CREATE_OBJECT, {
      position: { x: clientX, y: clientY },
      object: objectData,
      explorerType,
      isStepModalPinned: false,
    });
  };

  return (
    <>
      {/* Search Input Section */}
      <div className={styles.SearchSection}>
        {iconSearch}
        <input
          placeholder="Search"
          autoFocus={true}
          ref={textSearchInputRef}
          maxLength="512"
          className={styles.Search}
          value={textSearch}
          onChange={onSearchChange}
          type="text"
        />
      </div>

      {/* Tabs Section */}
      <div className={styles.TopSectionIcon}>
        {tabs.map((tab) => (
          <MappingTabButton
            key={tab.type}
            icon={tab.icon}
            title={tab.title}
            type={tab.type}
            isActive={!isDisabledMapTab}
            onClick={activateTab}
            activeMapTab={activeTab}
          />
        ))}
      </div>

      {/* Icons Section */}
      <IconList
        parentComponent="StepsModal"
        textSearch={textSearch}
        type={activeTab}
        colls={7}
        isDisabledMapTab={isDisabledMapTab}
        otherIconsTitle={'Custom Actions'}
        onIconClick={onIconClick}
        onDragEnd={onItemDroppedToCanvas}
        onAddIconClick={onAddIconClick}
        onCloseClick={onIconCloseClick}
        customUploadedIcons={getCustomIcons(activeTab)}
      />

      <When condition={isUploaderOpened}>
        <ClickOutsideCustomComponent onClickOutside={clickOutsideUploader}>
          <FileUploader
            uploadType={uploaderData?.type}
            onFileUploaderClose={onFileUploaderClose}
            onFileUploaded={onFileUploaded}
          ></FileUploader>
        </ClickOutsideCustomComponent>
      </When>
    </>
  );
};

const MappingTabButton = ({ type, title, icon, isActive, onClick, activeMapTab }) => {
  return (
    <button
      className={cls(styles.Chip, {
        [styles.Active]: isActive && activeMapTab === type,
      })}
      onClick={() => onClick(type)}
    >
      <span className={styles.ChipIcon}>{icon}</span>
      <span className={styles.ChipText}>{title}</span>
    </button>
  );
};
