import Utils from 'pixi-project/utils/Utils';
import {
  COLOR_COMPARE_PERCENT_NEGATIVE,
  COLOR_COMPARE_PERCENT_NEUTRAL,
  COLOR_COMPARE_PERCENT_POSITIVE,
} from 'pixi-project/view/Styles';
import { roundTo } from './CSharedMethods';
import moment from 'moment';
import {
  DIVISION_MODE_3DAY,
  DIVISION_MODE_DAILY,
  DIVISION_MODE_WEEKLY,
} from 'pixi-project/view/widgets/TrendsWidget';

export class AnalyticsHelper {
  static getColor(n, range) {
    if (Utils.inRange(n, range.from, range.to)) {
      return COLOR_COMPARE_PERCENT_NEUTRAL;
    }

    return n > range.to ? COLOR_COMPARE_PERCENT_POSITIVE : COLOR_COMPARE_PERCENT_NEGATIVE;
  }

  static getColorForPercent(n) {
    if (n === 0) {
      return COLOR_COMPARE_PERCENT_NEUTRAL;
    }

    return n > 0 ? COLOR_COMPARE_PERCENT_POSITIVE : COLOR_COMPARE_PERCENT_NEGATIVE;
  }

  static setIndicator(sprite, n, range = null) {
    if (range) {
      if ((n) => range.from && n <= range.to) {
        sprite.setTexture('compare-in-range');
      } else if (n > range.to) {
        sprite.setTexture('compare-up');
      } else {
        sprite.setTexture('compare-down');
      }
    } else {
      if (n === 0) {
        sprite.setTexture('compare-in-range');
      } else if (n > 0) {
        sprite.setTexture('compare-up');
      } else {
        sprite.setTexture('compare-down');
      }
    }
  }

  static parsePercentChange(startValue, endValue) {
    const change = endValue / startValue;

    if (isNaN(change)) {
      return 0;
    } else if (change === Infinity) {
      // If the change is infitly large ,
      // lets just say in a human way that its 100% increase
      return 100;
    }

    if (change === 1) {
      // if the change is 1 , it means there is no change at all.
      return 0;
    } else if (change > 1) {
      // that is a change in the positive scale
      return Math.round((roundTo(change, 2) - 1) * 100);
    } else {
      // below 1 is a negative change
      return Math.round((1 - roundTo(change, 2)) * 100) * -1;
    }
  }

  static divideToDateRanges(funnelConfiguration, compareMode, mode = DIVISION_MODE_DAILY) {
    // define date format used for keys
    const keyFormat = 'Do MMM';
    // get appropriate date range based on compare mode
    const { startDate, endDate } = AnalyticsHelper.getDateRange(funnelConfiguration, compareMode);

    const daysBetween = endDate.diff(startDate, 'days');
    const dateRanges = [];

    if (mode === DIVISION_MODE_DAILY) {
      for (let i = 0; i <= daysBetween; i++) {
        const start = startDate.clone().add(i, 'days');
        dateRanges.push({
          min: start.startOf('day').toISOString(),
          max: start.endOf('day').toISOString(),
          key: i,
        });
      }
    } else if (mode === DIVISION_MODE_3DAY) {
      // 3 weeks
      // divide them in batches of 3 days
      for (let i = 0; i < daysBetween; i += 3) {
        const range = AnalyticsHelper.calculatedRange(
          startDate,
          endDate,
          'days',
          3,
          i,
          daysBetween,
        );
        dateRanges.push(range);
      }
    } else if (mode === DIVISION_MODE_WEEKLY) {
      // 7 weeks
      // divide them by weeks
      for (let i = 0; i < daysBetween; i += 7) {
        const range = AnalyticsHelper.calculatedRange(
          startDate,
          endDate,
          'days',
          7,
          i,
          daysBetween,
        );
        dateRanges.push(range);
      }
    } else {
      // display by months
      const monthsBetween = endDate.diff(startDate, 'months');
      for (let i = 0; i <= monthsBetween; i++) {
        const start = startDate.clone().add(i, 'months');

        if (i === monthsBetween) {
          // the last month, might not be full month
          const end = start.clone().endOf('month');
          const range = {
            min: start.startOf('month').toISOString(),
            max: end.isAfter(endDate) ? endDate.toISOString() : end.toISOString(),
            key: i,
          };
          dateRanges.push(range);
        } else {
          dateRanges.push({
            min: start.startOf('month').toISOString(),
            max: start.endOf('month').toISOString(),
            key: i,
          });
        }
      }
    }

    // add formated label
    for (let i = 0; i < dateRanges.length; i++) {
      const range = dateRanges[i];
      if (moment(range.min).startOf('day').isSame(moment(range.max).startOf('day'))) {
        const key = moment(range.min).format(keyFormat);
        range.label = key;
      } else {
        const key = `${moment(range.min).format(keyFormat)} - ${moment(range.max).format(
          keyFormat,
        )}`;
        range.label = key;
      }
    }

    return dateRanges;
  }

  static getDateRange(funnelConfiguration, compareMode) {
    let range = compareMode ? funnelConfiguration.compareDateRange : funnelConfiguration.dateRange;
    const startDate = moment(range.min).startOf('day');
    const endDate = moment(range.max).endOf('day');
    return { startDate, endDate };
  }

  static calculatedRange(startDate, endDate, periodName = 'days', amount = 1, i, daysBetween) {
    const start =
      i === 0
        ? startDate.clone().add(i, periodName)
        : startDate.clone().add(1, 'seconds').add(i, periodName);
    const end = startDate.clone().add(i + amount, periodName);
    const max = end.isAfter(endDate) ? endDate : end;

    return {
      min: start.toISOString(),
      max:
        i === daysBetween - 1
          ? max.toISOString()
          : max.clone().subtract(1, 'seconds').toISOString(),
      key: i,
    };
  }

  static calculateCost(value, type, days) {
    if (!type) {
      // either 0 or undefined
      // per day
      return value * days;
    } else if (type === 1) {
      // per week
      return (value / 7) * days;
    } else if (type === 2) {
      // per month
      return (value / 30) * days;
    } else if (type === 3) {
      // fixed
      return value;
    }
  }

  static calculatePerDay(value, period, days) {
    if (!period) {
      // either 0 or undefined
      // per day
      return value;
    } else if (period === 1) {
      // per week
      return value / 7;
    } else if (period === 2) {
      // per month
      return value / 30;
    } else if (period === 3) {
      // fixed
      return value / days;
    }
  }
}
