import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { HEAP_EVENTS } from 'react-project/Constants/heapEvents';
import { AcknowledgementConstants, TUTORIAL_TASKS } from 'react-project/Constants/tutorial';
import RequestService from 'react-project/Helpers/RequestService';
import { sendHeapTracking } from 'react-project/Util/HEAP.utilities';
import { track, track as trackCohesive } from 'react-project/Util/CohesiveTracking';

const requestService = new RequestService();

const initialState = {
  tasks: TUTORIAL_TASKS,
  completedPercent: null,
  previousIndex: null,
  currentIndex: 0, //int
  nextIndex: null,
  toComplete: null,
  isLoading: true,
};

export const tutorialSlice = createSlice({
  name: 'tutorial',
  initialState,
  reducers: {
    setInitialState: (prevState, action) => {
      const {
        tasks,
        completedPercent,
        isLoading,
        toComplete,
        currentIndex,
        nextIndex,
        previousIndex,
      } = action.payload;
      return {
        ...prevState,
        tasks,
        completedPercent,
        currentIndex,
        nextIndex,
        previousIndex,
        toComplete,
        isLoading,
      };
    },
    setNewIndicies: (prevState, action) => {
      const currentIndex = action.payload;
      const { previousIndex, nextIndex } = createDirectionIndices(
        currentIndex,
        prevState.tasks.length,
      );
      return {
        ...prevState,
        currentIndex,
        nextIndex,
        previousIndex,
      };
    },
    updateAcknowledged: (prevState, action) => {
      const tasks = prevState.tasks.map((a) => {
        return { ...a };
      });
      const task = _.find(tasks, (task) => task.name === action.payload);
      task.acknowledged = true;
      return {
        ...prevState,
        tasks,
      };
    },
    updateCompleted: (prevState, action) => {
      const { completedPercent, toComplete } = action.payload;
      return {
        ...prevState,
        completedPercent,
        toComplete,
      };
    },
  },
});

export const {
  setInitialState,
  setNewIndicies,
  updateAcknowledged,
  updateCompleted,
  toggleShowing,
} = tutorialSlice.actions;

export const tutorialReducer = tutorialSlice.reducer;

/**
 * Redux Thunk actions below
 */

export const asyncGetTutorialCompleted = () => async (dispatch, getState) => {
  //GET stored tutorials completed
  const tutorialNames = Object.values(AcknowledgementConstants);
  const response = await requestService.getAllAcknowledgements(tutorialNames);
  if (!response.success) {
    return;
  }

  //Assign acknowledged to matching tasks
  // Matched Stored tasks count, exclude tasks that do not match
  const tasks = response.data;
  const updatedTasks = getState().tutorial.tasks.map((a) => {
    return { ...a };
  });
  let acknowledgedCount = 0; //Records can have 'acknowledged' as 'false'
  Object.entries(tasks).forEach(([key, val]) => {
    if (!val) return;
    const task = _.find(updatedTasks, (t) => t.name === key);
    if (!task) return;
    task['acknowledged'] = val;
    acknowledgedCount++;
  });

  //Set complete percent
  const completedPercent = (acknowledgedCount / updatedTasks.length) * 100;
  const toComplete = updatedTasks.length - acknowledgedCount;

  //find current index (one that isn't completed) and the next and previous index
  const startingTask = _.find(updatedTasks, (t) => t.acknowledged === false);
  const currentIndex = startingTask ? startingTask.index : 0;
  const { previousIndex, nextIndex } = createDirectionIndices(currentIndex, updatedTasks.length);

  //Set isLoading false
  const newState = {
    ...getState().tutorial,
    tasks: updatedTasks,
    completedPercent,
    currentIndex,
    nextIndex,
    previousIndex,
    isLoading: false,
    toComplete,
  };
  dispatch(setInitialState(newState));
};

export const asyncMarkCompleted = (reqObj) => async (dispatch, getState) => {
  const { taskName, hubspotReporting, email } = reqObj;
  const validAknowledgements = Object.values(AcknowledgementConstants).includes(taskName);
  if (!validAknowledgements) return;
  const resp = await requestService.setAcknowledgement(taskName, email, hubspotReporting);
  if (resp.status !== 200) {
    return;
  }
  const currentFunnel = getState().funnels.currentFunnelId;
  const projectId = getState().funnels[currentFunnel].projectId;
  const eventName = `${HEAP_EVENTS.CANVAS_TOUR_COMPLETED}_${taskName}`;
  sendHeapTracking({
    projectId: projectId,
    eventName,
  });
  trackCohesive(eventName, {
    projectId: projectId,
  });
  dispatch(updateAcknowledged(taskName, email, hubspotReporting));
  const { completedPercent, toComplete } = _updateCompletionStats(getState().tutorial.tasks);
  dispatch(updateCompleted({ completedPercent, toComplete }));
};

const createDirectionIndices = (currentIndex, tasksLength) => {
  const previousIndex = currentIndex - 1 === -1 ? tasksLength - 1 : currentIndex - 1;
  const nextIndex = currentIndex + 1 > tasksLength - 1 ? 0 : currentIndex + 1;

  return { previousIndex, nextIndex };
};

//**Internal Helper functons below */
const _updateCompletionStats = (tasks) => {
  let acknowledgedCount = 0; //Records can have 'acknowledged' as 'false'
  tasks.forEach((task) => {
    if (task.acknowledged === true) {
      acknowledgedCount++;
    }
  });
  //Set complete percent
  const completedPercent = (acknowledgedCount / tasks.length) * 100;
  const toComplete = tasks.length - acknowledgedCount;
  return { completedPercent, toComplete };
};
