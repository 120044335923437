import React from 'react';
import cx from 'classnames';

function CheckmarkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="1 0 11 12"
      fill="#0EA5E9"
    >
      <path
        d="M10.8692 0H1.46589C0.7237 0 0.122559 0.596667 0.122559 1.33333V10.6667C0.122559 11.4033 0.7237 12 1.46589 12H10.8692C11.6114 12 12.2126 11.4033 12.2126 10.6667V1.33333C12.2126 0.596667 11.6114 0 10.8692 0ZM4.82423 9.60933L1.66269 6.47133L2.61243 5.52867L4.82423 7.724L9.72269 2.862L10.6724 3.80467L4.82423 9.60933Z"
        fill="#0EA5E9"
      />
    </svg>
  );
}

function IndeterminateIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="1 0 11 12"
      fill="#0EA5E9"
    >
      <path
        d="M10.8692 0H1.46589C0.7237 0 0.122559 0.596667 0.122559 1.33333V10.6667C0.122559 11.4033 0.7237 12 1.46589 12H10.8692C11.6114 12 12.2126 11.4033 12.2126 10.6667V1.33333C12.2126 0.596667 11.6114 0 10.8692 0ZM9.52589 6.66667H2.80923V5.33333H9.52589V6.66667Z"
        fill="#0EA5E9"
      />
    </svg>
  );
}

const checkboxStyles = ({ customStyles }) => ({
  initial: cx(
    customStyles?.default,
    'bg-white border-2 border-solid border-slate-300 hover:border-slate-500 hover:bg-slate-100',
  ),
  checked: cx(
    customStyles?.checked,
    'bg-white border-sky-500 border-2 border-solid hover:opacity-90',
  ),
  indeterminate: cx(
    customStyles?.indeterminate,
    'bg-white border-sky-500 border-2 border-solid hover:opacity-90',
  ),
  base: cx(
    'flex',
    'items-center',
    'justify-center',
    'rounded',
    'h-4',
    'w-4',
    'transition',
    'duration-250',
    'ease-in-out',
  ),
});

export function Checkbox({ onChange, selection, customStyles, disabled }) {
  const shapeCustomStyles = { ...customStyles };
  const altClassNames = checkboxStyles({ customStyles: shapeCustomStyles });
  const checked = !!selection;
  const icon = selection === 1 ? <CheckmarkIcon /> : <IndeterminateIcon />;
  return (
    <button
      type="button"
      className={cx('p-1', disabled ? 'hover:cursor-not-allowed' : '')}
      onClick={onChange}
    >
      <div
        className={cx(
          checked ? altClassNames.checked : altClassNames.initial,
          altClassNames.base,
          disabled ? 'hover:cursor-not-allowed' : '',
        )}
      >
        {checked ? icon : null}
      </div>
    </button>
  );
}
Checkbox.defaultProps = {
  customStyles: {},
};

export default Checkbox;
