import Utils from 'pixi-project/utils/Utils';
import FlowPoint from './FlowPoint';
import Color from 'pixi-project/utils/Color';

const DOTS_SPACING = 13; // 12px is without gaps
const MAX_CLUSTERS = 11;
const DOTS_SPEED = 2;
const GOOD_CLUSTER_TOLERANCE = 0.2; // the lower the number the more it tries to divide into more clusters

export default class FlowController {
  constructor(connections) {
    this.connections = connections;
    this.isAnimating = false;
    this.requestID = null;
    this.isFlowToggleOn = false;

    this.heigestHits = 0;
    this.lowestHits = 0;

    this.minSpace = 30;
    this.maxSpace = 200;

    this.timePassed = 0;
    this.renderAfterMS = 30; // 30ms

    // used to calculate absolute density
    this.upperThreshold = 10000;
    this.numberOfClusters = 0; // to be calculated
    this.clustersTempData = {};

    // generate all colors
    this.clusterColors = [];

    // recycle flow points
    this.flowPoints = [];
  }

  generateColors() {
    // generate all colors
    this.clusterColors = [];

    const startColor = '#DC0505'; // (lowest hits cluster)
    const middleColor = '#E0C900';
    const endColor = '#10DD09'; //  (highest hits cluster)

    for (let i = 0; i < this.numberOfClusters; i++) {
      const absolutePercent = i / (this.numberOfClusters - 1);
      let color = 0x007acc;
      if (absolutePercent <= 0.5) {
        const percent = Utils.map(absolutePercent, 0, 0.5, 0, 1);
        color = Color.getGradientColor(startColor, middleColor, percent);
      } else {
        const percent = Utils.map(absolutePercent, 0.5, 1, 0, 1);
        color = Color.getGradientColor(middleColor, endColor, percent);
      }

      // const h = Utils.map(i, 1, this.numberOfClusters, 240, 0);
      // const s = Utils.map(i, 1, this.numberOfClusters, 35, 100);
      // const l = Utils.map(i, 1, this.numberOfClusters, 65, 45);
      // const color = Color.hslToHex(h, s, l);

      // DEBUG: visualize the color palettee

      // const g = new PIXI.Graphics();
      // g.beginFill(color);
      // g.drawRect(0, 0, 50, 50);
      // g.x = 100 + i * 55;
      // g.y = 500;
      // window.app.stage.addChild(g);

      this.clusterColors.push(color);
    }
  }

  median(arr) {
    const mid = Math.floor(arr.length / 2);
    const sortedArr = arr.sort((a, b) => a - b);

    if (arr.length % 2 === 0) {
      return (sortedArr[mid - 1] + sortedArr[mid]) / 2;
    } else {
      return sortedArr[mid];
    }
  }

  makeClusters(numberOfClusters) {
    const allHits = [];
    for (let i = 0; i < this.connections.length; i++) {
      const connection = this.connections[i];
      if (connection.analyticsManager.data) {
        const hits = connection.analyticsManager.data.hits;
        allHits.push(hits);
      }
    }

    const clusters = Utils.clusterNumbersByPercent(allHits, numberOfClusters - 1);

    this.clustersTempData[numberOfClusters] = {};
    this.clustersTempData[numberOfClusters + '_distances'] = {};

    for (let i = 0; i < this.connections.length; i++) {
      const connection = this.connections[i];
      if (connection.analyticsManager.data) {
        const hits = connection.analyticsManager.data.hits;
        connection.flowData.cluster = Utils.getClusterIndex(hits, clusters);

        // caluclate average of hits per cluster
        if (!this.clustersTempData[numberOfClusters][connection.flowData.cluster]) {
          this.clustersTempData[numberOfClusters][connection.flowData.cluster] = {
            sum: 0,
            count: 0,
            avg: 0, // average hits
            numbers: [],
          };
          this.clustersTempData[numberOfClusters + '_distances'][connection.flowData.cluster] = {
            sum: 0,
            count: 0,
            avg: 0, // average standard diviation
            numbers: [],
          };
        }

        // only unique hits
        const numbers =
          this.clustersTempData[numberOfClusters][connection.flowData.cluster].numbers;
        if (numbers.indexOf(hits) === -1) {
          this.clustersTempData[numberOfClusters][connection.flowData.cluster].sum += hits;
          this.clustersTempData[numberOfClusters][connection.flowData.cluster].count++;
          numbers.push(hits);
        }
      }
    }

    // calculate average or hits
    for (const cluster in this.clustersTempData[numberOfClusters]) {
      const data = this.clustersTempData[numberOfClusters][cluster];
      data.avg = data.sum / data.count;
    }

    // calculate standard diviation
    for (let i = 0; i < this.connections.length; i++) {
      const connection = this.connections[i];
      if (connection.analyticsManager.data) {
        const hits = connection.analyticsManager.data.hits;
        const cluster = connection.flowData.cluster;
        const data = this.clustersTempData[numberOfClusters][cluster];

        const distanceData = this.clustersTempData[numberOfClusters + '_distances'][cluster];
        const numbers = distanceData.numbers;
        if (numbers.indexOf(hits) === -1) {
          // only unique hits
          distanceData.sum += Math.abs(hits - data.avg);
          distanceData.count++;
          numbers.push(hits);
        }
      }
    }

    // calculate avg diviation
    for (const cluster in this.clustersTempData[numberOfClusters + '_distances']) {
      const data = this.clustersTempData[numberOfClusters + '_distances'][cluster];
      data.avg = data.sum / data.count;

      data.percent = data.avg / this.clustersTempData[numberOfClusters][cluster].avg || 0;
      if (data.count === 1) {
        // for single numbers in group
        data.percent = 0; // no diviation
      }

      if (cluster == 0 && data.avg < 10) {
        // for small numbers in the first group
        data.percent = 0; // no diviation in the first group
      }
    }

    for (const cluster in this.clustersTempData[numberOfClusters + '_distances']) {
      const data = this.clustersTempData[numberOfClusters + '_distances'][cluster];
      data.avg = data.sum / data.count;
      if (data.percent > GOOD_CLUSTER_TOLERANCE) {
        // Detected a bad cluster with a lot of diviation
        return false;
      }
    }

    // if no cluster is above 1.0
    return true;
  }

  updateClustering() {
    // reset cluster TempData
    this.clustersTempData = {};

    // It calculates from 3 to MAX_CLUSTERS
    // and stops when it finds a good clustering group
    for (let i = 3; i <= MAX_CLUSTERS; i++) {
      this.numberOfClusters = i;
      if (this.makeClusters(this.numberOfClusters)) {
        // if no bad group was detected , then it is the best cluster
        break;
      }
    }

    // Once we know the number of clusters
    // we can match the number of colors
    this.generateColors();

    // SET spacing and colors
    for (let i = 0; i < this.connections.length; i++) {
      const connection = this.connections[i];
      if (connection.analyticsManager.data) {
        connection.flowData.spacing =
          DOTS_SPACING * this.numberOfClusters - DOTS_SPACING * connection.flowData.cluster;

        connection.flowData.speed = DOTS_SPEED;

        connection.flowData.color = this.clusterColors[connection.flowData.cluster];

        if (connection.analyticsManager.data.hits === 0) {
          connection.flowData.color = 0xb6b6b6;
        }
      }
    }
  }

  calculateClusterPercent(index) {
    const clusterIndex = `_distances${index}`;
    const cluster = this.clustersTempData[clusterIndex];
    let sum = 0;
    let count = 0;
    let sum_percent = 0;
    for (let ci in cluster) {
      const data = cluster[ci];
      sum += data.avg;
      sum_percent += data.F;
      count++;
    }
    const avg = sum / count;
    const avg_percent = sum_percent / count;
    this.clustersTempData[clusterIndex + '_avg'] = avg;
    this.clustersTempData[clusterIndex + '_avg_percent'] = avg_percent;
  }

  /**
   * Given an array of numbers, return an array of clusters, where each cluster is an array of numbers
   * cluster numbers into n groups based on their proximity to each other
   */
  clusterNumbers(numbers, n) {
    // sort numbers by value
    numbers.sort((a, b) => a > b);
  }

  getFlowPoint() {
    if (this.flowPoints.length === 0) {
      return new FlowPoint();
    } else {
      return this.flowPoints.pop();
    }
  }

  recycleFlowPoint(flowPoint) {
    flowPoint.removeFromParent();
    this.flowPoints.push(flowPoint);
  }

  start() {
    if (this.isAnimating) {
      return;
    }

    this.isAnimating = true;
    this.onUpdate();
  }

  stop() {
    this.isAnimating = false;
    this.clearAllFlowPoints();
  }

  onUpdate() {
    this.timePassed += PIXI.Ticker.shared.deltaMS;

    if (!this.isAnimating) {
      cancelAnimationFrame(this.requestID);
      return;
    }
    this.requestID = requestAnimationFrame(this.onUpdate.bind(this));

    if (this.timePassed > this.renderAfterMS) {
      this.timePassed = Utils.clamp(this.timePassed, 0, 60);
      this.drawDots(this.timePassed);
      window.app.needsRendering();
      this.timePassed = 0;
    }
  }

  drawDots(deltaTime) {
    for (let i = 0; i < this.connections.length; i++) {
      const connection = this.connections[i];

      if (!connection.analyticsManager.data) {
        continue;
      }

      // 0 hits means no flow for that connection
      if (connection.analyticsManager.data.hits === 0) {
        continue;
      }

      for (let i = connection.flowData.points.length - 1; i >= 0; i--) {
        const flowPoint = connection.flowData.points[i];
        flowPoint.update(deltaTime / 16.66);

        if (flowPoint.needsRecycle) {
          connection.flowData.points.splice(i, 1);
          this.recycleFlowPoint(flowPoint);
          continue;
        }

        flowPoint.draw(connection);
      }

      connection.flowData.currentTravelDistance += (connection.flowData.speed * deltaTime) / 16.66;

      const nearestSteps = Math.floor(connection.length / connection.flowData.spacing);
      const maxLength = connection.flowData.spacing * (nearestSteps + 1);
      if (connection.flowData.currentTravelDistance > maxLength) {
        connection.flowData.currentTravelDistance -= maxLength;

        if (maxLength === connection.flowData.spacing) {
          connection.flowData.lastPointIndex = -1;
        }
      }

      const pointIndex = Math.floor(
        connection.flowData.currentTravelDistance / connection.flowData.spacing,
      );

      if (pointIndex !== connection.flowData.lastPointIndex) {
        const flowPoint = this.getFlowPoint();
        flowPoint.reset();
        flowPoint.speed = connection.flowData.speed;
        flowPoint.currentDistance =
          connection.flowData.currentTravelDistance - pointIndex * connection.flowData.spacing;
        connection.flowData.points.push(flowPoint);
        connection.addChild(flowPoint);
        flowPoint.pushToBack();

        flowPoint.setColor(connection.flowData.color);
        flowPoint.setDistanceToTravel(connection);
        connection.flowData.lastPointIndex = pointIndex;
        flowPoint.draw(connection); // set the initial position
      }
    }
  }

  setConnectionsFlowMode(isFlowModeOn) {
    for (let i = 0; i < this.connections.length; i++) {
      const connection = this.connections[i];
      connection.setFlowMode(isFlowModeOn);
    }
  }

  clearAllFlowPoints() {
    for (let i = 0; i < this.connections.length; i++) {
      const connection = this.connections[i];

      for (let i = connection.flowData.points.length - 1; i >= 0; i--) {
        const flowPoint = connection.flowData.points[i];
        this.recycleFlowPoint(flowPoint);
      }

      connection.flowData.points = [];
    }
  }

  onAnalyticsDataReceived(data) {
    this.hasAnalyticsData = true;
    this.updateClustering();
    this.setConnectionsFlowMode(this.isFlowToggleOn);
    if (!this.isFlowToggleOn) {
      return;
    }
    this.start();
  }

  onAnalyticsCleared() {
    this.hasAnalyticsData = false;
    this.stop();
  }

  onFlowToggle(isVisible) {
    if (isVisible) {
      this.isFlowToggleOn = true;
      if (this.hasAnalyticsData) {
        this.updateClustering();
        this.start();
      }
    } else {
      this.isFlowToggleOn = false;
      this.stop();
    }
    this.setConnectionsFlowMode(isVisible);
  }
}
