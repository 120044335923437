import { PR_EVENT_FUNNEL_CHANGED, PR_NOTES_ADDED, PR_NOTES_REMOVED } from 'shared/CSharedEvents';
import { cloneData, commonSendEventFunction } from 'shared/CSharedMethods';

export default class CommandEditNote {
  constructor(object, oldNotes, notes) {
    this.object = object;
    this.oldNotes = cloneData(oldNotes);
    this.notes = cloneData(notes);
    this.isExecuted = false;
  }

  execute() {
    if (!this.isExecuted) {
      this.isExecuted = true;

      this.object.notes = cloneData(this.notes);
      this.object.onNoteChanged();
      window.app.needsRendering();

      commonSendEventFunction(PR_NOTES_ADDED, {
        id: this.object.id,
        noteData: cloneData(this.notes),
        checklistData: cloneData(this.object.checklistData),
      });
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
    }
  }

  revert() {
    if (this.isExecuted) {
      this.isExecuted = false;

      this.object.notes = cloneData(this.oldNotes);
      this.object.onNoteChanged();
      window.app.needsRendering();

      commonSendEventFunction(PR_NOTES_ADDED, {
        id: this.object.id,
        noteData: cloneData(this.oldNotes),
        checklistData: cloneData(this.object.checklistData),
      });
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
    }
  }
}
