import JSONAPISerializer from 'json-api-serializer';

import registerTrackerSession from './tracker-session';
import registerTrackerProfile from './tracker-profile';
import registerTrackerProfileAttribute from './tracker-profile-attribute';
import registerUser from './user';
import registerTrackerStep from './tracker-step';
import registerTrackerEvent from './tracker-event';

const serializer = new JSONAPISerializer();

registerTrackerSession(serializer);
registerTrackerProfile(serializer);
registerTrackerProfileAttribute(serializer);
registerUser(serializer);
registerTrackerStep(serializer);
registerTrackerEvent(serializer);

export default serializer;
