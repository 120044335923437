import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';
import { Dropdown } from 'react-project/components/dropdown/Dropdown';
import styles from './TextToolbar.module.scss';
import cls from 'classnames';
import commonDropdownStyles from 'react-project/components/dropdown/Dropdown.module.scss';
import { DropdownBlock } from 'react-project/components/dropdown/DropdownBlock';
import { iconLink, iconExternalLink } from 'react-project/assets/Icons';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { When } from 'react-project/Util/When';
import { commonSendEventFunction, goToURL } from 'shared/CSharedMethods';
import { cleanUrl, parseURL } from 'react-project/Helpers/URLHelpers';
import { RP_EVENT_TEXT_HYPERLINK_STARTED_EDITING } from 'shared/CSharedEvents';

const TextLinkDropdownTrigger = forwardRef(({ isOpen, isActive, ...rest }, ref) => {
  const tabClassName = cls(styles.WithBorder, styles.TextToolbarTab);
  return (
    <div className={tabClassName} {...rest}>
      <div
        className={cls(styles.IconLink, styles.TriggerWrapper, {
          [styles.ActiveStyle]: isActive,
        })}
      >
        {iconLink}
      </div>
    </div>
  );
});

export const TextLinkDropdown = ({ selectedValue, onSelect, onHyperLinkChanged }) => {
  const [urlValue, setUrlValue] = useState(selectedValue);
  const [dropdownOpened, setDropdownOpened] = useState(urlValue === '' ? false : true);
  const [editModeState, setEditModeState] = useState(urlValue === '' ? true : false);
  const newTextRef = useRef(selectedValue);

  useEffect(() => {
    newTextRef.current = urlValue;
    if (urlValue == '') {
      setEditModeState(true);
    }
  }, [urlValue, editModeState]);

  useEffect(() => {
    if (editModeState) {
      commonSendEventFunction(RP_EVENT_TEXT_HYPERLINK_STARTED_EDITING);
    }
  }, [editModeState]);

  useEffect(() => {
    return () => {
      if (selectedValue !== newTextRef.current) {
        onHyperLinkChanged(selectedValue, newTextRef.current);
      }
    };
  }, []);

  const onInputBlur = (e) => {
    onLabelChange(e);
    if (selectedValue !== urlValue) {
      onHyperLinkChanged(selectedValue, urlValue);
    }
  };

  const onLabelChange = (e) => {
    const currentTargetValue = e.target.value;
    setUrlValue(currentTargetValue);
    onSelect(currentTargetValue);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      setEditModeState(false);
    }
  };

  return (
    <ClickOutsideCustomComponent
      onClickOutside={() => {
        setDropdownOpened(false);
        setEditModeState(false);
      }}
    >
      <Dropdown
        contentClassName={cls(styles.TextDropdown, styles.LinkDropdown)}
        triggerSlot={<TextLinkDropdownTrigger isActive={editModeState && dropdownOpened} />}
        onToggle={(opened) => {
          setEditModeState(!editModeState);
          if (urlValue === '') {
            setDropdownOpened(opened);
          }
        }}
        isOpen={dropdownOpened}
      >
        <div className={cls(commonDropdownStyles.CommonDropdownItems, styles.TextLinkBlock)}>
          <DropdownBlock>
            <div>
              <When condition={editModeState}>
                <input
                  autoFocus={true}
                  type="text"
                  value={urlValue}
                  onChange={onLabelChange}
                  placeholder={'Enter URL'}
                  onFocus={onLabelChange}
                  onBlur={onInputBlur}
                  onKeyDown={onKeyDown}
                />
              </When>
              <When condition={!editModeState}>
                <div className={styles.TextLinkLabelBlock}>
                  <div onClick={() => goToURL(cleanUrl(selectedValue))}>{selectedValue}</div>
                  <div
                    className={styles.TextLinkIcon}
                    onClick={() => goToURL(cleanUrl(selectedValue))}
                  >
                    {iconExternalLink}
                  </div>
                </div>
              </When>
            </div>
          </DropdownBlock>
        </div>
      </Dropdown>
    </ClickOutsideCustomComponent>
  );
};
