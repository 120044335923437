import { iconActions, iconPage, iconTraffic } from 'react-project/assets/Icons';
import { EXPLORER_TABS } from 'shared/CSharedConstants';

export const SUB_TAB = {
  ALL_PAGES: 0,
  FIRST_PAGES: 1,
  LAST_PAGES: 2,
  ENTRY_PAGES: 3,
  EXIT_PAGES: 4,
  ALL_REFERING_URLS: 5,
  FIRST_URL: 6,
  LAST_URL: 7,
  UTMS: 8,
  CUSTOM_PARAMS: 9,
  CONVERSION_ACTIONS: 10,
  ENGAGEMENT_ACTIONS: 11,
  EXTERNAL_ACTIONS: 12,
  CUSTOM_ACTIONS: 13,
};

export const explorerTabs = [
  { icon: iconTraffic, title: 'Sources', type: EXPLORER_TABS.SOURCE },
  { icon: iconPage, title: 'Pages', type: EXPLORER_TABS.PAGE },
  { icon: iconActions, title: 'Actions', type: EXPLORER_TABS.EVENT },
];

export const explorerSubTabs = {
  [EXPLORER_TABS.SOURCE]: [
    {
      id: SUB_TAB.CUSTOM_PARAMS,
      title: 'URL Parameters',
    },
    {
      id: SUB_TAB.ALL_REFERING_URLS,
      title: 'Referring URLs',
    },
    // {
    //   id: SUB_TAB.FIRST_URL,
    //   title: 'First URL',
    // },
    // {
    //   id: SUB_TAB.LAST_URL,
    //   title: 'Last URL',
    // },
    // {
    //   id: SUB_TAB.UTMS,
    //   title: 'UTMs',
    // },
  ],
  [EXPLORER_TABS.PAGE]: [
    {
      id: SUB_TAB.ALL_PAGES,
      title: 'All Pages',
    },
    // {
    //   id: SUB_TAB.FIRST_PAGES,
    //   title: 'First Pages',
    // },
    // {
    //   id: SUB_TAB.LAST_PAGES,
    //   title: 'Last Pages',
    // },
    // {
    //   id: SUB_TAB.ENTRY_PAGES,
    //   title: 'Entry Pages',
    // },
    // {
    //   id: SUB_TAB.EXIT_PAGES,
    //   title: 'Exit Pages',
    // },
  ],
  [EXPLORER_TABS.EVENT]: [
    // {
    //   id: SUB_TAB.CONVERSION_ACTIONS,
    //   title: 'Conversion Actions',
    // },
    // {
    //   id: SUB_TAB.ENGAGEMENT_ACTIONS,
    //   title: 'Engagement Actions',
    // },
    // {
    //   id: SUB_TAB.EXTERNAL_ACTIONS,
    //   title: 'External Actions',
    // },
    {
      id: SUB_TAB.CUSTOM_ACTIONS,
      title: 'Custom Actions',
    },
  ],
};

export const subTabsTitles = {
  [SUB_TAB.ALL_REFERING_URLS]: ['Referring URL', 'People'],
  [SUB_TAB.ALL_PAGES]: ['Page URL', 'People', 'Actions'],
  [SUB_TAB.CONVERSION_ACTIONS]: ['Action Name', 'People'],
  [SUB_TAB.CUSTOM_ACTIONS]: ['Action Name', 'People'],
  [SUB_TAB.CUSTOM_PARAMS]: ['Parameters', 'People'],
};
