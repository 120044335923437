export default new (class Utils {
  // Converts from degrees to radians.
  deg2rad(degrees) {
    return (degrees * Math.PI) / 180;
  }

  // Converts from radians to degrees.
  rad2deg(radians) {
    return (radians * 180) / Math.PI;
  }

  distanceAB(a, b) {
    let xs = b.x - a.x;
    let ys = b.y - a.y;
    return Math.sqrt(xs * xs + ys * ys);
  }

  getBoundingRect(points) {
    if (!points || !points.length) {
      throw Error(`Utils.getBoundingRect: empty or not an array input ${points}`);
    }
    let minX = points[0],
      minY = points[1],
      maxX = points[0],
      maxY = points[1];

    for (let i = 2; i < points.length; i += 2) {
      minX = Math.min(minX, points[i]);
      maxX = Math.max(maxX, points[i]);
      minY = Math.min(minY, points[i + 1]);
      maxY = Math.max(maxY, points[i + 1]);
    }

    return {
      left: minX,
      top: minY,
      width: maxX - minX,
      height: maxY - minY,
      get centerX() {
        return this.left + this.width / 2;
      },
      get centerY() {
        return this.top + this.height / 2;
      },
      get right() {
        return this.left + this.width;
      },
      get bottom() {
        return this.top + this.height;
      },
      get x() {
        return this.left;
      },
      get y() {
        return this.top;
      },
    };
  }

  findGroupBounds(objects) {
    const selection = [];
    for (let i = 0; i < objects.length; i++) {
      let el = objects[i];
      this._addGlobalCoordinates(selection, el.x, el.y);
      this._addGlobalCoordinates(
        selection,
        el.x + el.content.width * el.scale.x,
        el.y + el.content.height * el.scale.y,
      );
      this._addGlobalCoordinates(selection, el.x + el.content.width * el.scale.x, el.y);
      this._addGlobalCoordinates(selection, el.x, el.y + el.content.height * el.scale.y);
    }
    return this.getBoundingRect(selection);
  }

  _addGlobalCoordinates(target, x, y) {
    const coords = window.app.viewport.toGlobal({
      x: x,
      y: y,
    });
    target.push(...[coords.x, coords.y]);
  }

  angleAB(pointA, pointB) {
    return Math.atan2(pointB.y - pointA.y, pointB.x - pointA.x);
  }

  /**
   * Finds which point is higher and lower by Y axis between the two provided
   * @param point1
   * @param point2
   * @returns {{lower: *, higher: *}} The point that is higher (Y axis). Null in case of same Y
   */
  getHigherLowerPoint(point1, point2) {
    let higher = null;
    let lower = null;
    if (point1.y !== point2.y) {
      higher = point1;
      lower = point2;
      if (point1.y > point2.y) {
        higher = point2;
        lower = point1;
      }
    }
    return { higher, lower };
  }

  /**
   * Clamps a value between defined limit
   * @param {Number} value
   * @param {Number} min
   * @param {Number} max
   * @returns
   */
  clamp(value, min, max) {
    return Math.min(Math.max(value, Math.min(min, max)), Math.max(min, max));
  }

  /**
   * Normalization
   * It returns a number between 0 and 1 in the given range.
   * it can be above 1 and below 0 if the value is out of range.
   * Normalization is the oposite process of Linear Interpolation
   * @param {Number} value
   * @param {Number} min
   * @param {Number} max
   * @returns
   */
  normalize(value, min, max) {
    return (value - min) / (max - min);
  }

  /**
   * Linear Interpolation
   * It takes a normalized value between 0 and 1
   * and returns a value on the given range
   * https://en.wikipedia.org/wiki/Linear_interpolation
   * Linear Interpolation is the oposite process of Normalization
   * @param {*} norm
   * @param {*} min
   * @param {*} max
   * @returns
   */
  lerp(norm, min, max) {
    return (max - min) * norm + min;
  }

  /**
   * It takes a value , from a defined source range
   * and it translates it to a destination range
   * @param {Number} value
   * @param {Number} sourceMin
   * @param {Number} sourceMax
   * @param {Number} destMin
   * @param {Number} destMax
   * @returns
   */
  map(value, sourceMin, sourceMax, destMin, destMax) {
    return this.lerp(this.normalize(value, sourceMin, sourceMax), destMin, destMax);
  }

  /**
   * Checks if a given values is withing a given range ( inclusively )
   * @param {Number} value
   * @param {Number} min
   * @param {Number} max
   * @returns
   */
  inRange(value, min, max) {
    return value >= Math.min(min, max) && value <= Math.max(min, max);
  }

  getDistanceFromLine(p, a, b) {
    const normalPoint = this.getNormalFromLine(p, a, b);
    return this.distanceAB(p, normalPoint);
  }

  getNormalFromLine(p, a, b) {
    const atob = { x: b.x - a.x, y: b.y - a.y };
    const atop = { x: p.x - a.x, y: p.y - a.y };
    const len = atob.x * atob.x + atob.y * atob.y;
    let dot = atop.x * atob.x + atop.y * atob.y;
    const t = Math.min(1, Math.max(0, dot / len));

    dot = (b.x - a.x) * (p.y - a.y) - (b.y - a.y) * (p.x - a.x);

    return {
      x: a.x + atob.x * t,
      y: a.y + atob.y * t,
    };
  }

  findAngleBetween3Points(A, B, C) {
    // Set v = A - B and w = C - B.
    // Then angle = atan2( wy*vx-wx*vy , wx*vx+wy*vy)
    let vx = A.x - B.x;
    let vy = A.y - B.y;
    let wx = C.x - B.x;
    let wy = C.y - B.y;

    return Math.atan2(wy * vx - wx * vy, wx * vx + wy * vy);
  }

  lineSegmentsIntersection(
    p0x,
    p0y,
    p1x,
    p1y,
    p2x,
    p2y,
    p3x,
    p3y,
    isLine1Segment = true,
    isLine2Segment = true,
  ) {
    const s10_x = p1x - p0x,
      s10_y = p1y - p0y,
      s32_x = p3x - p2x,
      s32_y = p3y - p2y;

    const denom = s10_x * s32_y - s32_x * s10_y;
    if (denom == 0) return undefined; // collinear ,lines are paralel

    const s02_x = p0x - p2x,
      s02_y = p0y - p2y;

    const s_numer = s10_x * s02_y - s10_y * s02_x;
    const t_numer = s32_x * s02_y - s32_y * s02_x;

    if (isLine1Segment) {
      if (t_numer < 0 == denom > 0 || t_numer > denom == denom > 0) {
        return undefined;
      }
    }

    if (isLine2Segment) {
      if (s_numer < 0 == denom > 0 || s_numer > denom == denom > 0) {
        return undefined;
      }
    }

    // collision detected
    const t = t_numer / denom;
    return new PIXI.Point(p0x + t * s10_x, p0y + t * s10_y);
  }

  lineSegmentRectangleIntersection(p0, p1, r) {
    const intersectionPoints = [];
    let p = null;

    // check top line
    p = this.lineSegmentsIntersection(
      p0.x,
      p0.y,
      p1.x,
      p1.y,
      r.x,
      r.y,
      r.x + r.width,
      r.y,
      false,
      true,
    );
    if (p) {
      intersectionPoints.push(p);
    }

    // check left line
    p = this.lineSegmentsIntersection(
      p0.x,
      p0.y,
      p1.x,
      p1.y,
      r.x,
      r.y,
      r.x,
      r.y + r.height,
      false,
      true,
    );
    if (p) {
      intersectionPoints.push(p);
    }

    // check right line
    p = this.lineSegmentsIntersection(
      p0.x,
      p0.y,
      p1.x,
      p1.y,
      r.x + r.width,
      r.y,
      r.x + r.width,
      r.y + r.height,
      false,
      true,
    );
    if (p) {
      intersectionPoints.push(p);
    }

    // check bottom line
    p = this.lineSegmentsIntersection(
      p0.x,
      p0.y,
      p1.x,
      p1.y,
      r.x,
      r.y + r.height,
      r.x + r.width,
      r.y + r.height,
      false,
      true,
    );
    if (p) {
      intersectionPoints.push(p);
    }

    let closestDistance = Number.MAX_SAFE_INTEGER;
    let closestPoint = null;
    for (let i = 0; i < intersectionPoints.length; i++) {
      const ip = intersectionPoints[i];
      const dist = this.distToSegment(ip, p0, p1);
      if (dist < closestDistance) {
        closestDistance = dist;
        closestPoint = ip;
      }
    }
    return closestPoint;

    // return p;
  }

  sqr(x) {
    return x * x;
  }

  dist2(v, w) {
    return this.sqr(v.x - w.x) + this.sqr(v.y - w.y);
  }

  distToSegmentSquared(p, v, w) {
    var l2 = this.dist2(v, w);
    if (l2 == 0) return this.dist2(p, v);
    var t = ((p.x - v.x) * (w.x - v.x) + (p.y - v.y) * (w.y - v.y)) / l2;
    t = Math.max(0, Math.min(1, t));
    return this.dist2(p, {
      x: v.x + t * (w.x - v.x),
      y: v.y + t * (w.y - v.y),
    });
  }

  distToSegment(point, segmentP0, segmentP1) {
    return Math.sqrt(this.distToSegmentSquared(point, segmentP0, segmentP1));
  }

  randomFloat(min, max) {
    return min + Math.random() * (max - min);
  }

  randomInt(min, max) {
    return Math.floor(min + Math.random() * (max - min + 1));
  }

  clusterNumbersByPercent(numbers, n) {
    const nums = numbers.slice();
    nums.push(0); // to make sure they start from 0
    function onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
    }
    const uniqueNumbers = nums.filter(onlyUnique);
    const sorted = uniqueNumbers.slice().sort((a, b) => a - b);
    const gaps = [];
    for (let i = 0; i < sorted.length - 1; i++) {
      let a = sorted[i];
      let b = sorted[i + 1];
      let percent = a / b;
      if (percent === 0) {
        percent = 1; // 0 is the same bucket as 1
      }
      gaps.push({
        from: a,
        to: b,
        value: percent,
        gapIndex: i,
      });
    }
    gaps.sort((a, b) => a.value - b.value);
    return gaps.slice(0, n).sort((a, b) => a.from - b.from);
  }

  // It takes an array of numbers and clusters it into n clusters
  // based on the proximity of the numbers to each other
  // If you plot the numbers , the clasters will tend to create groups
  // we can identify the clusters by looking for the widest gaps between the numbers
  clusterNumbers(numbers, n) {
    const nums = numbers.slice();
    nums.push(0); // to make sure they start from 0
    function onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
    }
    const uniqueNumbers = nums.filter(onlyUnique);
    const sorted = uniqueNumbers.slice().sort((a, b) => a - b);
    const gaps = [];
    for (let i = 0; i < sorted.length - 1; i++) {
      let a = sorted[i];
      let b = sorted[i + 1];
      gaps.push({
        from: a,
        to: b,
        value: a - b,
        gapIndex: i,
      });
    }
    gaps.sort((a, b) => b.value - a.value);
    return gaps.slice(0, n).sort((a, b) => a.from - b.from);
  }

  // for a given number, find the cluster it belongs to
  getClusterIndex(value, clusters) {
    for (let i = 0; i < clusters.length; i++) {
      const cluster = clusters[i];

      if (value <= cluster.from) {
        return i;
      }
    }
    return clusters.length;
  }
})();
