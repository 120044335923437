import DropShadowGenerator from 'pixi-project/utils/DropShadowGenerator';
import Utils from 'pixi-project/utils/Utils';
import {
  COLOR_DISPLAY_BORDER,
  COLOR_SELECTION,
  CONNECTION_BOX_PADDING,
  CONNECTION_BOX_PADDING_HORIZONTAL,
  SELECTION_ROUND_CORNER,
} from 'pixi-project/view/Styles';
import * as PIXI from 'pixi.js';
import ConnectionCommonSegment from './ConnectionCommonSegment';
import ConnectionCompareSegment from './ConnectionCompareSegment';
import MainStorage from 'pixi-project/core/MainStorage';
import ConnectionForecastingCommonSegment from './ConnectionForecastingCommonSegment';
import ConnectionForecastingCompareSegment from './ConnectionForecastingCompareSegment';

// DELEGATE
// - onConnectionDisplayStartedMoving(event,connectionDisplay)
// - onConnectionDisplayMoving(event,connectionDisplay)
// - onConnectionDisplayFinishedMoving(event,connectionDisplay)
// - onConnectionDisplayDoubleClicked(event,connectionDisplay)
// - onConnectionDisplayPointerDown(event,connectionDisplay)
// - onConnectionDisplayPointerUp(event,connectionDisplay)
// - onConnectionDisplayPointerMove(event,connectionDisplay)

export default class ConnectionDisplay extends PIXI.Container {
  constructor(delegate) {
    super();

    this.canHaveValue = true;
    this.breakPoint = null;
    this.isCompare = false;
    this.isConnectionSelected = false;

    this.selectionBox = new PIXI.Graphics();
    this.addChild(this.selectionBox);

    this.backgroundBox = new PIXI.Graphics();
    this.addChild(this.backgroundBox);

    this.activeDisplay = null;

    this.commonSegment = new ConnectionCommonSegment();
    this.addChild(this.commonSegment);

    this.compareSegment = new ConnectionCompareSegment();
    this.addChild(this.compareSegment);

    this.forecastCommonSegment = new ConnectionForecastingCommonSegment();
    this.addChild(this.forecastCommonSegment);

    this.forecastCompareSegment = new ConnectionForecastingCompareSegment();
    this.addChild(this.forecastCompareSegment);

    this.delegate = delegate;
    this.lastPosition = new PIXI.Point();
    this.startDragPosition = new PIXI.Point();
    this.startPosition = new PIXI.Point();

    this.isPointerDown = false;
    this.didMove = false;
    this.doubleClickTime = 0;
    this.doubleClickPoint = new PIXI.Point();

    this.boxStyle = {
      borderThickness: 1,
      borderColor: COLOR_DISPLAY_BORDER,
      borderRadius: SELECTION_ROUND_CORNER,
      backgroundColor: 0xffffff,
      padding: 7,
    };

    this.interactive = true;
    this.buttonMode = true;
    this.cursor = 'move';
    this.pointerdown = this.onPointerDown.bind(this);
    this.pointermove = this.onPointerMove.bind(this);
    this.pointerup = this.onPointerUp.bind(this);
    this.pointerupoutside = this.onPointerUpOutside.bind(this);

    this.compareRange = { from: 0, to: 0 };

    this.resetData();

    this.shouldCache = true;
    this.setDropShadow(true);
  }

  setCurrency(currency) {
    this.commonSegment.currency = currency;
    this.compareSegment.currency = currency;
    this.forecastCommonSegment.currency = currency;
    this.forecastCompareSegment.currency = currency;
  }

  onPointerDown(e) {
    this.isPointerDown = true;
    this.didMove = false;
    let localP = this.parent.toLocal(e.data.global);
    this.startDragPosition.copyFrom(localP);
    this.startPosition.copyFrom(this.position);
    e.stopPropagation();

    if (this.delegate && this.delegate.onConnectionDisplayPointerDown) {
      this.delegate.onConnectionDisplayPointerDown(e, this);
    }
  }

  onPointerMove(e) {
    if (this.isPointerDown && !MainStorage.getCanvasPermissions().isReadonlyAccess) {
      if (this.delegate.onConnectionDisplayPointerMove) {
        this.delegate.onConnectionDisplayPointerMove(e, this);
      }
    }
  }

  moveDisplay(e) {
    if (!this.didMove) {
      this.didMove = true;
      if (this.delegate && this.delegate.onConnectionDisplayStartedMoving) {
        this.delegate.onConnectionDisplayStartedMoving(e, this);
      }
    }

    if (this.breakPoint) {
      let localP = this.parent.toLocal(e.data.global);
      let dx = localP.x - this.startDragPosition.x;
      let dy = localP.y - this.startDragPosition.y;
      this.x = this.startPosition.x + dx;
      this.y = this.startPosition.y + dy;
    }

    if (this.delegate && this.delegate.onConnectionDisplayMoving) {
      this.delegate.onConnectionDisplayMoving(e, this);
    }
  }

  onPointerUp(e) {
    this.isPointerDown = false;
    // measure time
    let dt = app.ticker.lastTime - this.doubleClickTime;
    this.doubleClickTime = app.ticker.lastTime;

    // measure location
    let distance = Utils.distanceAB(this.doubleClickPoint, e.data.global);
    this.doubleClickPoint.copyFrom(e.data.global);

    if (dt < 300 && distance < 10) {
      this.onDoubleClick(e);
      this.doubleClickTime = 0; // reset the time to prevent consecutive dobule clicking
    }

    if (this.didMove) {
      if (this.delegate && this.delegate.onConnectionDisplayFinishedMoving) {
        this.delegate.onConnectionDisplayFinishedMoving(e, this);
      }
    }

    e.stopPropagation();

    if (this.delegate && this.delegate.onConnectionDisplayPointerUp) {
      this.delegate.onConnectionDisplayPointerUp(e, this);
    }
  }

  onPointerUpOutside(e) {
    if (this.isPointerDown) {
      this.isPointerDown = false;
      e.stopPropagation();
      if (this.delegate && this.delegate.onConnectionDisplayPointerUp) {
        this.delegate.onConnectionDisplayPointerUp(e, this);
      }
    }
  }

  onDoubleClick(e) {
    if (this.delegate && this.delegate.onConnectionDisplayDoubleClicked) {
      this.delegate.onConnectionDisplayDoubleClicked(e, this);
    }
  }

  setCommonData(value1, value2, label1, label2, goal) {
    this.activateDisplay(this.commonSegment);
    this.commonSegment.setData(value1, value2, label1, label2, goal);
    this.drawBox(this.commonSegment);
    this.updateSelectionBox();
  }

  setCommonForecastingData(value1, value2, label1, label2, goal) {
    this.activateDisplay(this.forecastCommonSegment);
    this.forecastCommonSegment.setData(value1, value2, label1, label2, goal);
    this.drawBox(this.forecastCommonSegment);
    this.updateSelectionBox();
  }

  setCompareForecastingData(data, goal) {
    this.activateDisplay(this.forecastCompareSegment);
    this.isCompare = true;
    this.forecastCompareSegment.setData(data, goal);
    this.drawBox(this.forecastCompareSegment);
    this.updateSelectionBox();
  }

  setCompareData(data, goal) {
    this.activateDisplay(this.compareSegment);
    this.isCompare = true;
    this.compareSegment.setData(data, goal);
    this.drawBox(this.compareSegment);
    this.updateSelectionBox();
  }

  hideAllDisplays() {
    this.isCompare = false;
    // Keep adding displays here
    this.commonSegment.visible = false;
    this.compareSegment.visible = false;
    this.forecastCommonSegment.visible = false;
    this.forecastCompareSegment.visible = false;
  }

  activateDisplay(display) {
    this.hideAllDisplays();
    this.activeDisplay = display;
    this.activeDisplay.compareRange = this.compareRange;
    display.visible = true;
    this.setDropShadow(true);
  }

  resetData() {
    this.commonSegment.goal = this.delegate.goal;
    this.activateDisplay(this.commonSegment);
    this.commonSegment.resetData();
    this.drawBox(this.commonSegment);
    this.updateSelectionBox();
  }

  setNoData() {
    this.commonSegment.goal = this.delegate.goal;
    this.activateDisplay(this.commonSegment);
    this.commonSegment.setNoData();
    this.drawBox(this.commonSegment);
    this.updateSelectionBox();
  }

  getFooterHeight() {
    return this.activeDisplay ? this.activeDisplay.getArea().height : 0;
  }

  drawBox(segment) {
    const contentSize = segment.getArea();
    const left = -contentSize.width / 2 - CONNECTION_BOX_PADDING_HORIZONTAL;

    this.backgroundBox.cacheAsBitmap = false;
    this.backgroundBox.clear();

    if (this.delegate.goal && this.delegate.goal.hasGoal) {
      this.backgroundBox.lineStyle(2, 0x0ed073, 1, 1);
    } else {
      this.backgroundBox.lineStyle(0);
    }

    this.backgroundBox.beginFill(this.boxStyle.backgroundColor, 1);
    this.backgroundBox.drawRoundedRect(
      left,
      -contentSize.height / 2 - CONNECTION_BOX_PADDING,
      contentSize.width + CONNECTION_BOX_PADDING_HORIZONTAL * 2,
      contentSize.height +
        (this.isCompare ? CONNECTION_BOX_PADDING : CONNECTION_BOX_PADDING * 2) -
        1,
      this.boxStyle.borderRadius,
    );
    this.backgroundBox.endFill();

    if (this.shouldCache) {
      this.backgroundBox.cacheAsBitmapResolution = 2;
      this.backgroundBox.cacheAsBitmap = true;
    }
  }

  setCaching(shouldCache) {
    this.shouldCache = shouldCache;

    if (this.shouldCache) {
      this.backgroundBox.cacheAsBitmapResolution = 2;
      this.backgroundBox.cacheAsBitmap = true;
    } else {
      this.backgroundBox.cacheAsBitmap = false;
    }
  }

  updateVisibility(visibitily) {
    this.visible = visibitily;

    if (this.breakPoint && !this.delegate.isNoDataLine()) {
      this.breakPoint.visible = !visibitily;
      if (!this.delegate.isConnectionSelected()) {
        this.breakPoint.visible = false;
      }
    }

    this.updateSelectionBox();
  }

  updateBlocks(isNoDataLine, visibitily) {
    if (this.activeDisplay) {
      this.updateBlock(this.activeDisplay, isNoDataLine, visibitily);
    }
  }

  updateBlock(block, isNoDataLine, visibitily) {
    if (isNoDataLine) {
      block.canHaveValue = false;
      block.visible = false;
    } else {
      block.canHaveValue = true;
      block.visible = visibitily;
    }
  }

  setDropShadow(visible) {
    this.backgroundBox.cacheAsBitmap = false;
    if (visible) {
      this.backgroundBox.filters = [DropShadowGenerator.getFilter()];
    } else {
      this.backgroundBox.filters = [];
    }

    if (this.shouldCache) {
      this.backgroundBox.cacheAsBitmap = true;
    }
  }

  updateView(range) {
    this.compareRange = range;
    if (this.activeDisplay) {
      if (this.activeDisplay.onRangeUpdated) {
        this.activeDisplay.compareRange = this.compareRange;
        this.activeDisplay.onRangeUpdated();
      }
    }
  }

  updateSelectionBox() {
    if (this.visible && this.isConnectionSelected) {
      this.selectionBox.visible = true;
      this.drawSelectionBox();
    } else {
      this.selectionBox.visible = false;
    }
  }

  drawSelectionBox() {
    const scale = app.viewport.scale.x;
    const padding = 5 * (1 / scale);
    const lineThickness = 2 * (1 / scale);
    const bounds = this.backgroundBox.getLocalBounds();
    this.selectionBox.clear();
    this.selectionBox.lineStyle(lineThickness, COLOR_SELECTION, 1);
    this.selectionBox.beginFill(0, 0);
    this.selectionBox.drawRect(
      bounds.x - padding,
      bounds.y - padding,
      bounds.width + padding * 2,
      bounds.height + padding * 2,
    );
    this.selectionBox.endFill();
  }

  onConnectionSelected() {
    this.isConnectionSelected = true;
    this.updateSelectionBox();
  }

  onConnectionDeselected() {
    this.isConnectionSelected = false;
    this.updateSelectionBox();
  }
}
