import React from 'react';
import {
  VERTICAL_ALIGN_CENTER,
  ViewportConstrainer,
} from 'react-project/components/viewportConstrainer/ViewportConstrainer';
import {
  ItemsWithLabelCustomization,
  STEP_GOAL,
  STEP_PEOPLE_TAB_NAME,
  STEP_SETTINGS_TAB_NAME,
  STEP_TRACKING_TAB_NAME,
} from 'react-project/Constants/step-settings';
import { ContextMenu } from 'react-project/Toolbar/ContextMenu';
import TopToolbar from 'react-project/Toolbar/TopToolbar';
import { When } from 'react-project/Util/When';
import { RR_NOTES_OPEN, RR_CHECKLIST_OPEN, RP_EVENT_INFO_MESSAGE } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import SharedElementHelpers from 'shared/SharedElementHelpers';
import { VerticalSubMenu } from './Components/VerticalSubMenu/VerticalSubMenu';
import dropdownStyles from 'react-project/components/dropdown/Dropdown.module.scss';
import { useSelector } from 'react-redux';
import { selectCurrentStep } from 'react-project/redux/current-step/selectors';
import { notifyIfValueChanged } from 'react-project/Util/InputHelpers';
import { HorizontalMenu } from './Components/HorizontalMenu/HorizontalMenu';
import {
  subMenuAnalyticsButtons,
  subMenuConnectionAnalyticsButtons,
  menuConnectionButtons,
  MENU_BTN_CONNECTION_ANALYTICS,
  MENU_BTN_CONNECTION_GOALS,
  MENU_BTN_CONNECTION_PEOPLE,
  MENU_BTN_CONNECTION_SETTINGS,
  MENU_BTN_CONNECTION_STYLE_BEZIER,
  MENU_BTN_CONNECTION_STYLE_STRAIGHT,
  MENU_BTN_CONNECTION_TYPE_DIRECT,
  MENU_BTN_CONNECTION_TYPE_NODATA,
  MENU_BTN_CONNECTION_TYPE_SKIP,
  menuPhotoButtons,
  subMenuPlanningButtons,
  menuStepButtons,
  menuMiscStepButtons,
  MENU_BTN_PLANNING_CHECKLIST,
  MENU_BTN_FORECASTING as MENU_BTN_FORECASTING,
  MENU_BTN_CONNECTION_PLANNING,
  subMenuConnectionPlanningButtons,
  subMenuMiscPlanningButtons,
} from './MenusConfiguration';
import {
  MENU_BTN_ANALYTICS_GOALS,
  MENU_BTN_ANALYTICS_PEOPLE,
  MENU_BTN_ANALYTICS_TRACKING,
  MENU_BTN_PHOTO_UPLOAD,
  MENU_BTN_PLANNING_NOTES,
  MENU_BTN_STEP_ANALYTICS,
  MENU_BTN_STEP_MORE,
  MENU_BTN_STEP_PLANNING,
  MENU_BTN_STEP_STYLE,
} from './MenusConfiguration';
import { ShapeMenu } from './ShapeMenu/ShapeMenu';
import MainStorage from 'pixi-project/core/MainStorage';
import CommonToolbar from 'react-project/Toolbar/CommonToolbar';
import { StepGoals } from 'react-project/Toolbar/step-toolbar/StepGoals';
import { PeopleTracking } from 'react-project/Toolbar/step-toolbar/PeopleTracking';
import { LineSettingsSubMenu } from './LineSettingsSubMenu/LineSettingsSubMenu';
import {
  EConnectionDrawLineType,
  EConnectionLineType,
} from 'pixi-project/base/joint/CConnectionConstants';
import { onPhotoUploadClicked } from 'react-project/Util/PhotoHelper';
import { EElementCategories } from 'shared/CSharedCategories';
import { AlignArrangeMenu } from './AlignArrangeMenu/AlignArrangeMenu';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import { TEXT_TUTORIAL_UPLOAD_NOT_SUPPORTED } from 'react-project/Constants/texts';
import { ForecastingComponent } from 'react-project/components/forecasting/ForecastingComponent';
import {
  LOCK_NO_ANALYTICS,
  LOCK_NO_PURCHASE_TRACKING,
  UILock,
} from 'react-project/components/uilock/UILock';
import PermissionHelper from 'shared/PermissionHelper';

export const Menus = ({
  elementData,
  sectionStyle,
  funnel,
  position,
  stepId,
  iconType,
  isNumbersActive,
  toolbarData,
  onConnectionDrawLineTypeChanged,
  onConnectionLineTypeChanged,
  isRightMouse,
  isMultiSelect,
}) => {
  // the reference of the top level menu that appears when an element is selected
  const activeMenuRef = React.useRef(null);
  const [activeSubmenuIdentifier, setActiveSubmenuIdentifier] = React.useState(null);
  const [activeMenuBtnReference, setActiveMenuBtnReference] = React.useState(null);
  const [activeSubmenuId, setActiveSubmenuId] = React.useState(null);
  const currentStep = useSelector(selectCurrentStep);
  const canvasPermissions = useSelector(selectCanvasPermissions);

  const stepButtons = SharedElementHelpers.IsMisc(elementData)
    ? menuMiscStepButtons
    : menuStepButtons;

  const lockedButtons = [];

  if (SharedElementHelpers.IsAction(elementData) && !canvasPermissions.isActionsAllowed) {
    lockedButtons.push(MENU_BTN_STEP_ANALYTICS);
  }

  if (!canvasPermissions.isAnalyticsAllowed) {
    lockedButtons.push(MENU_BTN_STEP_ANALYTICS);
    lockedButtons.push(MENU_BTN_CONNECTION_ANALYTICS);
  }

  if (!canvasPermissions.checklistAllowed) {
    lockedButtons.push(MENU_BTN_PLANNING_CHECKLIST);
  }

  if (!canvasPermissions.notesAllowed) {
    lockedButtons.push(MENU_BTN_PLANNING_NOTES);
  }

  if (!canvasPermissions.iconsAllowed) {
    lockedButtons.push(MENU_BTN_PHOTO_UPLOAD);
  }

  // lock planning btn if all 3 are locked
  if (
    !canvasPermissions.checklistAllowed &&
    !canvasPermissions.notesAllowed &&
    !canvasPermissions.iconsAllowed
  ) {
    lockedButtons.push(MENU_BTN_STEP_PLANNING);
    lockedButtons.push(MENU_BTN_CONNECTION_PLANNING);
  }

  if (
    !canvasPermissions.purchaseTrackingAllowed &&
    SharedElementHelpers.IsPurchaseAction(currentStep.object)
  ) {
    lockedButtons.push(MENU_BTN_STEP_ANALYTICS);
  } else if (
    !canvasPermissions.calendarTrackingAllowed &&
    SharedElementHelpers.IsMeetingAction(currentStep.object)
  ) {
    lockedButtons.push(MENU_BTN_STEP_ANALYTICS);
  } else if (
    !canvasPermissions.formTrackingAllowed &&
    SharedElementHelpers.IsFormAction(currentStep.object)
  ) {
    lockedButtons.push(MENU_BTN_STEP_ANALYTICS);
  } else if (
    !canvasPermissions.dealTrackingAllowed &&
    SharedElementHelpers.IsDealAction(currentStep.object)
  ) {
    lockedButtons.push(MENU_BTN_STEP_ANALYTICS);
  }

  const filterData = currentStep.object.filterData || [];
  const dataOrigin = filterData.find((item) => item.key === '__dataOrigin__')?.value;
  if (PermissionHelper.isDataOriginBlocked(dataOrigin, canvasPermissions)) {
    lockedButtons.push(MENU_BTN_STEP_ANALYTICS);
  }

  // Lock analytics for other actions

  const onMenuBtnClicked = (e, reference, id) => {
    setActiveSubmenuIdentifier(id);
    setActiveMenuBtnReference(reference);

    // Single action button , that triggers a modal window
    // pushing the event down the line through the submenu logic
    // As if a sub menu button was clcked
    if (id === MENU_BTN_STEP_STYLE) {
      setActiveSubmenuId(MENU_BTN_STEP_STYLE);
    }

    if (id === MENU_BTN_PHOTO_UPLOAD && !canvasPermissions.isReadonlyAccess) {
      if (canvasPermissions.isTutorial) {
        // Click upload button for image object
        commonSendEventFunction(RP_EVENT_INFO_MESSAGE, {
          message: TEXT_TUTORIAL_UPLOAD_NOT_SUPPORTED,
        });
      } else if (canvasPermissions.iconsAllowed) {
        onPhotoUploadClicked(currentStep);
      }
    }
  };

  const onSubButtonClicked = (e, reference, id) => {
    setActiveSubmenuId(id);
    setActiveSubmenuIdentifier(null);

    // Some buttons will trigger certain events
    if (id === MENU_BTN_PLANNING_NOTES) {
      commonSendEventFunction(RR_NOTES_OPEN, {
        topMenuRect: activeMenuRef.current.getBoundingClientRect(),
      });
    } else if (id === MENU_BTN_PLANNING_CHECKLIST) {
      commonSendEventFunction(RR_CHECKLIST_OPEN, {
        topMenuRect: activeMenuRef.current.getBoundingClientRect(),
      });
    }
  };

  const isConnectionMenu = () => {
    return SharedElementHelpers.IsConnection(elementData) || isCommonConnections();
  };

  const isCommonConnections = () => {
    return (
      toolbarData &&
      toolbarData.categories &&
      toolbarData.categories.length === 1 &&
      toolbarData.categories[0] === EElementCategories.CONNECTION
    );
  };

  // Adapter methods , to bind the old logic to the new menu structure
  const onConnectionSettingsButtonClicked = (e, reference, id) => {
    switch (id) {
      case MENU_BTN_CONNECTION_STYLE_STRAIGHT:
        onConnectionDrawLineTypeChanged(EConnectionDrawLineType.STRAIGHT);
        break;
      case MENU_BTN_CONNECTION_STYLE_BEZIER:
        onConnectionDrawLineTypeChanged(EConnectionDrawLineType.BEZIER);
        break;
      case MENU_BTN_CONNECTION_TYPE_DIRECT:
        onConnectionLineTypeChanged(EConnectionLineType.SOLID);
        break;
      case MENU_BTN_CONNECTION_TYPE_SKIP:
        onConnectionLineTypeChanged(EConnectionLineType.DOTTED);
        break;
      case MENU_BTN_CONNECTION_TYPE_NODATA:
        onConnectionLineTypeChanged(EConnectionLineType.NODATA);
        break;
      default:
        break;
    }
  };

  // Adapter methods , to bind the old logic to the new menu structure
  const getSelectedTab = (id) => {
    switch (id) {
      case MENU_BTN_ANALYTICS_TRACKING:
        return STEP_TRACKING_TAB_NAME;
      case MENU_BTN_ANALYTICS_PEOPLE:
        return STEP_PEOPLE_TAB_NAME;
      case MENU_BTN_ANALYTICS_GOALS:
        return STEP_GOAL;
      case MENU_BTN_STEP_STYLE:
        return STEP_SETTINGS_TAB_NAME;
      default:
        return null;
    }
  };

  // The old menus trigger this method , to change the selected tab
  // but now its been triggered when we click outside the menu
  // that is why it results with setting it to null all the time
  const setSelectedTab = (tabName) => {
    setActiveSubmenuId(null);
  };

  if (canvasPermissions.isReadonlyAccess) {
    // Hide all menus in readonly mode
    return <></>;
  }

  return (
    <>
      {/* Top Level MENUS */}
      {SharedElementHelpers.IsStep(elementData) ? (
        <HorizontalMenu
          ref={activeMenuRef}
          sectionStyle={sectionStyle}
          buttons={stepButtons}
          lockedButtons={lockedButtons}
          onButtonClicked={onMenuBtnClicked}
        />
      ) : null}

      {isConnectionMenu() && !isRightMouse ? (
        <HorizontalMenu
          relativeMouseBox={{ width: 10, height: 10 }}
          avoidRectangles={toolbarData.avoidRectangles}
          ref={activeMenuRef}
          sectionStyle={sectionStyle}
          buttons={menuConnectionButtons}
          onButtonClicked={onMenuBtnClicked}
          lockedButtons={lockedButtons}
        />
      ) : null}

      {SharedElementHelpers.IsPhoto(elementData) && !canvasPermissions.isReadonlyAccess ? (
        <HorizontalMenu
          ref={activeMenuRef}
          sectionStyle={sectionStyle}
          buttons={menuPhotoButtons}
          onButtonClicked={onMenuBtnClicked}
          lockedButtons={lockedButtons}
        />
      ) : null}

      {SharedElementHelpers.IsShape(elementData) && !canvasPermissions.isReadonlyAccess ? (
        <ShapeMenu
          ref={activeMenuRef}
          sectionStyle={sectionStyle}
          onButtonClicked={onMenuBtnClicked}
          shapeStyle={currentStep.object.shapeStyle}
          currentStep={currentStep}
        />
      ) : null}

      {/* Sub MENUS */}

      <When condition={activeSubmenuIdentifier === MENU_BTN_STEP_ANALYTICS}>
        <VerticalSubMenu
          buttons={subMenuAnalyticsButtons}
          sectionStyle={activeMenuBtnReference?.current?.getBoundingClientRect()}
          onButtonClicked={onSubButtonClicked}
          lockedButtons={lockedButtons}
        />
      </When>

      <When condition={activeSubmenuIdentifier === MENU_BTN_STEP_PLANNING}>
        <VerticalSubMenu
          buttons={
            SharedElementHelpers.IsMisc(currentStep.object)
              ? subMenuMiscPlanningButtons
              : subMenuPlanningButtons
          }
          sectionStyle={activeMenuBtnReference?.current?.getBoundingClientRect()}
          onButtonClicked={onSubButtonClicked}
          lockedButtons={lockedButtons}
        />
      </When>

      <When
        condition={isConnectionMenu() && activeSubmenuIdentifier === MENU_BTN_CONNECTION_SETTINGS}
      >
        <LineSettingsSubMenu
          toolbarData={toolbarData}
          sectionStyle={activeMenuBtnReference?.current?.getBoundingClientRect()}
          onButtonClicked={onConnectionSettingsButtonClicked}
        />
      </When>

      <When condition={activeSubmenuIdentifier === MENU_BTN_CONNECTION_ANALYTICS}>
        <VerticalSubMenu
          buttons={subMenuConnectionAnalyticsButtons}
          sectionStyle={activeMenuBtnReference?.current?.getBoundingClientRect()}
          onButtonClicked={onSubButtonClicked}
          lockedButtons={lockedButtons}
        />
      </When>

      <When condition={activeSubmenuIdentifier === MENU_BTN_CONNECTION_PLANNING}>
        <VerticalSubMenu
          buttons={subMenuConnectionPlanningButtons}
          sectionStyle={activeMenuBtnReference?.current?.getBoundingClientRect()}
          onButtonClicked={onSubButtonClicked}
          lockedButtons={lockedButtons}
        />
      </When>

      {/* MENU Panels/Popus that are trigered with selecting a menu button  */}

      <When condition={activeSubmenuId === MENU_BTN_CONNECTION_PEOPLE}>
        <ViewportConstrainer
          verticalAlign={VERTICAL_ALIGN_CENTER}
          relativeElement={activeMenuBtnReference?.current}
        >
          <PeopleTracking
            onClose={() => {
              setActiveSubmenuId(null);
            }}
          />
        </ViewportConstrainer>
      </When>

      <When condition={activeSubmenuId === MENU_BTN_CONNECTION_GOALS}>
        <ViewportConstrainer
          verticalAlign={VERTICAL_ALIGN_CENTER}
          relativeElement={activeMenuBtnReference?.current}
        >
          <StepGoals
            onClose={() => {
              setActiveSubmenuId(null);
            }}
          />
        </ViewportConstrainer>
      </When>

      <When condition={activeSubmenuId === MENU_BTN_FORECASTING}>
        <ViewportConstrainer
          verticalAlign={VERTICAL_ALIGN_CENTER}
          relativeElement={activeMenuBtnReference?.current}
        >
          <ForecastingComponent
            onClose={() => {
              setActiveSubmenuId(null);
            }}
          />
        </ViewportConstrainer>
      </When>

      <When condition={activeSubmenuIdentifier === MENU_BTN_STEP_MORE}>
        <ViewportConstrainer relativeElement={activeMenuBtnReference?.current}>
          <div className={dropdownStyles.DropdownContent}>
            <ContextMenu
              dropdownTrigger={false}
              showLabel={ItemsWithLabelCustomization.includes(currentStep.object.type)}
              onItemSelect={() => {}}
              notifyIfValueChanged={notifyIfValueChanged}
              projectId={funnel.projectId}
              showLayerHierarchy={!isConnectionMenu()}
              showCopy={!isConnectionMenu()}
              showDuplicate={!isConnectionMenu()}
            />
          </div>
        </ViewportConstrainer>
      </When>

      <When condition={isMultiSelect && !isCommonConnections()}>
        <AlignArrangeMenu sectionStyle={sectionStyle} onButtonClicked={onMenuBtnClicked} />
      </When>

      {/* Old MENUS they still need to be included in the new menu system */}

      <When
        condition={isConnectionMenu() || SharedElementHelpers.IsText(elementData) || isMultiSelect}
      >
        <CommonToolbar
          sectionStyle={sectionStyle}
          stepId={stepId}
          multiSelect={isMultiSelect}
          toolbarData={toolbarData}
          isRightMouse={isRightMouse}
        />
      </When>

      <When condition={!isConnectionMenu()}>
        <TopToolbar
          funnel={funnel}
          position={position}
          sectionStyle={sectionStyle}
          stepId={stepId}
          iconType={iconType}
          isNumbersActive={isNumbersActive}
          selectedTab={getSelectedTab(activeSubmenuId)}
          setSelectedTab={setSelectedTab}
          activeMenuRef={activeMenuRef}
        />
      </When>
    </>
  );
};
