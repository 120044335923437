import { CanvasTransformator } from 'pixi-project/utils/CanvasTransformator';
import { HEAP_EVENTS } from 'react-project/Constants/heapEvents';
import RequestService from 'react-project/Helpers/RequestService';
import { ActionNames } from 'react-project/redux/actionNamesConstant';
import {
  setFunnelDataFilterAndFocus,
  setNewFunnelConfiguration,
  setPreviousFunnelData,
} from 'react-project/redux/funnel-configuration/actions';
import { sendHeapTracking } from 'react-project/Util/HEAP.utilities';
import { track as trackCohesive } from 'react-project/Util/CohesiveTracking';
import { createAction } from 'redux-actions';
import { RP_EVENT_LOAD_REQUEST } from 'shared/CSharedEvents';
import { cloneData, commonSendEventFunction } from 'shared/CSharedMethods';
import { logout } from '../auth/actions';
import { getDateRange, getTutorialDateRange } from 'react-project/Util/Dates';
import { FUNNEL_CONFIGURATION } from 'shared/CSharedConstants';

export const updateFunnel = createAction(ActionNames.updateFunnel);
export const updateIsFunnelLoading = createAction(ActionNames.updateIsFunnelLoading);
export const updateIsMigratedC1toC2 = createAction(ActionNames.updateIsMigratedC1toC2);

const requestService = new RequestService();

export const setIsMigrationC1toC2 = (isMigratedC1toC2) => {
  return async (dispatch) => {
    dispatch(updateIsMigratedC1toC2(isMigratedC1toC2));
  };
};

export const fetchFunnelAsync = (funnelId) => {
  return async (dispatch) => {
    const result = await requestService.fetchFunnelRequest(funnelId);
    if (result.success) {
      dispatch(updateFunnel({ ...result.data, isInUnorganizedFolder: !result.data.projectId }));
    }

    if (!result.success && result.errorStatus === 401) {
      dispatch(logout());
    }

    return result;
  };
};

export const loadFunnelAsync = ({
  projectId,
  funnelId,
  params = {},
  refreshRevisions,
  refreshAnalytics,
  canvasPermissions,
}) => {
  return async (dispatch, getState) => {
    dispatch(updateIsFunnelLoading(true));

    const response = await requestService.loadFunnelRequest(funnelId, params);

    if (!response) {
      dispatch(updateIsFunnelLoading(false));

      commonSendEventFunction(RP_EVENT_LOAD_REQUEST, {
        data: {
          objects: [],
          joints: [],
          funnelConfiguration: cloneData(FUNNEL_CONFIGURATION),
          projectId,
          funnelId,
        },
      });
      return;
    }

    let data = null;
    const transformator = new CanvasTransformator();

    if (!response.data.objects && response.data.cells) {
      // covert from 1.0 to 2.0
      data = transformator.transform(response.data);
      dispatch(setIsMigrationC1toC2(true));
    } else {
      data = response.data;
    }

    data.projectId = projectId;
    data.funnelId = funnelId;

    dispatch(setPreviousFunnelData({ data }));
    commonSendEventFunction(RP_EVENT_LOAD_REQUEST, { data: data });

    const hasAnalyticsData = getState().analytics.hasAnalyticsData;
    if (hasAnalyticsData) {
      dispatch(setFunnelDataFilterAndFocus({ data, canvasPermissions }));
      //send heap data
      const currentFunnel = getState().funnels.currentFunnelId;
      const projectId = getState().funnels[currentFunnel].projectId;
      sendHeapTracking({
        projectId: projectId,
        eventName: HEAP_EVENTS.ANALYTICS_ENABLED,
      });
      trackCohesive(HEAP_EVENTS.ANALYTICS_ENABLED, {
        projectId: projectId,
      });
    } else {
      // update the funnel configuration

      const dateRange = getDateRange(data.funnelConfiguration.dateRange);
      const compareDateRange = getDateRange(data.funnelConfiguration.compareDateRange);
      const funnelConfig = {
        ...data.funnelConfiguration,
        dateRange: dateRange,
        compareDateRange: compareDateRange,
      };

      if (canvasPermissions.isTutorial) {
        funnelConfig.dateRange = getTutorialDateRange();
        funnelConfig.compareDateRange = getTutorialDateRange();
      }

      dispatch(setNewFunnelConfiguration(funnelConfig));
    }

    // Update 'previousData' with current/new data
    dispatch(updateIsFunnelLoading(false));
    refreshAnalytics(data.objects, data.joints);
    refreshRevisions(funnelId);
  };
};
