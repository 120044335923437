import React from 'react';
import cx from 'classnames';

function InfoIconSVG({ className }) {
  return (
    <svg
      viewBox="0 0 13 13"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('h-[13px] w-[13px]', className)}
      fill="none"
    >
      <path
        d="M6.2251 0.5C2.9131 0.5 0.225098 3.188 0.225098 6.5C0.225098 9.812 2.9131 12.5 6.2251 12.5C9.5371 12.5 12.2251 9.812 12.2251 6.5C12.2251 3.188 9.5371 0.5 6.2251 0.5ZM6.8251 9.5H5.6251V5.9H6.8251V9.5ZM6.8251 4.7H5.6251V3.5H6.8251V4.7Z"
        fill="#636E84"
      />
    </svg>
  );
}
InfoIconSVG.defaultProps = {
  className: undefined,
};

export function InfoIcon({ visible, onClick, className, title }) {
  if (visible) {
    return (
      <div title={title}>
        <button type="button" onClick={onClick}>
          <InfoIconSVG className={className} />
        </button>
      </div>
    );
  }

  return null;
}
InfoIcon.defaultProps = {
  visible: false,
};

export default InfoIcon;
