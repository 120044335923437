export default class Migration6 {
  constructor() {
    this.version = 6;
  }

  migrate(data) {
    // go through all objects and change costType to period
    for (let i = 0; i < data.objects.length; i++) {
      const object = data.objects[i];
      if (object.forecastingData) {
        if (object.forecastingData.costType !== undefined) {
          object.forecastingData.period = object.forecastingData.costType;
          delete object.forecastingData.costType;
        }

        if (object.forecastingData.period === undefined) {
          object.forecastingData.period = 0; // default value is daily
        }

        // change peoplePerDay to numberOfPeople
        if (object.forecastingData.peoplePerDay !== undefined) {
          object.forecastingData.numberOfPeople = object.forecastingData.peoplePerDay;
          delete object.forecastingData.peoplePerDay;
        }
      }
    }

    return data;
  }
}
