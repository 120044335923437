import * as PIXI from 'pixi.js';
import Facade from 'pixi-project/Facade';
import { PR_EVENT_OBJECT_SELECTED } from 'shared/CSharedEvents';
import AppSignals from 'pixi-project/signals/AppSignals';
import ScalePoint from 'pixi-project/base/elements/ScalePoint';
import { EStepConnectionPort } from 'shared/CSharedCategories';
import { cloneData, commonSendEventFunction } from 'shared/CSharedMethods';
import SharedElementHelpers from 'shared/SharedElementHelpers';
import {
  COLOR_ELEMENT_HIGHLIGHT_FRAME,
  COLOR_SELECTION,
  SELECTION_ROUND_CORNER,
} from 'pixi-project/view/Styles';
import { SELECTION_BOUNDARY_GAP } from '../Styles';
import MainStorage from 'pixi-project/core/MainStorage';
import {
  SELECTION_UPDATE_SOURCE_ANALYTICS,
  SELECTION_UPDATE_SOURCE_GOAL_CHANGED,
  SELECTION_UPDATE_SOURCE_PANNING,
} from 'shared/CSharedConstants';

export const SINGLE_SELECTION_BOUNDS_WIDTH = 2;

export const MIN_ALLOWED_SCALE = 0.25;
export const MAX_ALLOWED_SCALE = 9;
const SCALE_POINT_LEFT_BOTTOM = 'leftBottomScalePoint';
const SCALE_POINT_RIGHT_BOTTOM = 'rightBottomScalePoint';
const SCALE_POINT_RIGHT_TOP = 'rightTopScalePoint';
const SCALE_POINT_LEFT_TOP = 'leftTopScalePoint';
const SCALE_POINT_RIGHT = 'rightScalePoint';
const SCALE_POINT_LEFT = 'leftScalePoint';
const PADDING_TOP_LEFT = 8.5;
const PADDING_BOTTOM_RIGHT = 11;
const HEADS_OFFSET = 10;

// Delegate
// - onShapeResizeHandleDown(event , currentStep)
// - onShapeResizeHandleMove(event , currentStep)
// - onShapeResizeHandleUp(event , currentStep)
// - onTextResizeHandleDown(event , currentStep)
// - onTextResizeHandleMove(event , currentStep)
// - onTextResizeHandleUp(event , currentStep)

export default class SelectionTool extends PIXI.Container {
  constructor(onHeadsDown, delegate) {
    super();

    this.isDraggingStarted = false;
    this.dragData = {};
    this.headIn = null;
    this.headOut = null;
    this.onHeadsDown = onHeadsDown;
    this.startHandlePosition = new PIXI.Point();
    this.delegate = delegate;
    this.sectionStyle = { x: 0, y: 0, width: 0, height: 0 };
    this.isRightMouse = false;
    this.lastObjectData = null; // used to track what data was sent to React for the menu to appear
    this.init();
  }

  init() {
    AppSignals.setTextEditMode.add(this.onTextEditModeChanged, this);

    this.frame = new PIXI.Graphics();
    this.addChild(this.frame);
    this.drawHeads();
    this.drawScalePoints();
    this.isDraggingStarted = false;
    this.bottomScalePoints = [SCALE_POINT_LEFT_BOTTOM, SCALE_POINT_RIGHT_BOTTOM];

    this.opositeHandles = {};
    this.opositeHandles[SCALE_POINT_LEFT_BOTTOM] = SCALE_POINT_RIGHT_TOP;
    this.opositeHandles[SCALE_POINT_RIGHT_BOTTOM] = SCALE_POINT_LEFT_TOP;
    this.opositeHandles[SCALE_POINT_RIGHT_TOP] = SCALE_POINT_LEFT_BOTTOM;
    this.opositeHandles[SCALE_POINT_LEFT_TOP] = SCALE_POINT_RIGHT_BOTTOM;
    this.opositeHandles[SCALE_POINT_LEFT] = SCALE_POINT_RIGHT;
    this.opositeHandles[SCALE_POINT_RIGHT] = SCALE_POINT_LEFT;

    this.cornerHandles = [
      SCALE_POINT_LEFT_BOTTOM,
      SCALE_POINT_RIGHT_BOTTOM,
      SCALE_POINT_RIGHT_TOP,
      SCALE_POINT_LEFT_TOP,
    ];

    this.horizontalHandles = [SCALE_POINT_LEFT, SCALE_POINT_RIGHT];

    this.visible = false;
  }

  isBottomScalePoint(pointName) {
    return this.bottomScalePoints.indexOf(pointName) > -1;
  }

  drawFrame() {
    this.frame.visible = true;
    this.frame.clear();
    if (this.currentStep.isInsideReport) {
      this.frame.lineStyle(SINGLE_SELECTION_BOUNDS_WIDTH, COLOR_ELEMENT_HIGHLIGHT_FRAME, 1);
    } else {
      this.frame.lineStyle(SINGLE_SELECTION_BOUNDS_WIDTH, COLOR_SELECTION, 1);
    }

    const offsetPoint = this.currentStep.getFrameOffset();
    const endPoint = this.currentStep.getEndPoint();
    const scale = window.app.viewport.scaled;
    const padding = SELECTION_BOUNDARY_GAP * scale;

    this.frame.drawRoundedRect(
      -padding + offsetPoint.x,
      -padding + offsetPoint.y,
      endPoint.width + 2 * padding,
      endPoint.height + 2 * padding,
      SELECTION_ROUND_CORNER,
    );
  }

  drawScalePoints() {
    this.scalePoints = {};
    let points = [
      SCALE_POINT_LEFT_TOP,
      SCALE_POINT_RIGHT_TOP,
      SCALE_POINT_RIGHT_BOTTOM,
      SCALE_POINT_LEFT_BOTTOM,
      SCALE_POINT_LEFT,
      SCALE_POINT_RIGHT,
    ];
    const cursors = [
      'nwse-resize',
      'nesw-resize',
      'nwse-resize',
      'nesw-resize',
      'ew-resize',
      'ew-resize',
    ];

    for (let i = 0; i < points.length; i++) {
      const pointName = points[i];
      const cursor = cursors[i];

      let scalePoint = new ScalePoint(pointName);
      scalePoint.index = i;
      scalePoint.interactive = true;
      scalePoint.buttonMode = true;
      scalePoint.on('pointerdown', this.onScalePointDown.bind(this));
      scalePoint.cursor = cursor;
      this.addChild(scalePoint);
      this.scalePoints[pointName] = scalePoint;
    }

    Facade.viewport.on('pointermove', this.onScalePointMove.bind(this));
    Facade.viewport.on('pointerup', this.onScalePointUp.bind(this));
  }

  positionScalePoints() {
    const scale = window.app.viewport.scaled;

    this.scalePoints[SCALE_POINT_LEFT_TOP].x = -PADDING_TOP_LEFT * scale;
    this.scalePoints[SCALE_POINT_LEFT_TOP].y = -PADDING_TOP_LEFT * scale;

    this.scalePoints[SCALE_POINT_RIGHT_TOP].x = this.frame.width - PADDING_BOTTOM_RIGHT * scale;
    this.scalePoints[SCALE_POINT_RIGHT_TOP].y = -PADDING_TOP_LEFT * scale;

    this.scalePoints[SCALE_POINT_LEFT_BOTTOM].x = -PADDING_TOP_LEFT * scale;
    this.scalePoints[SCALE_POINT_LEFT_BOTTOM].y = this.frame.height - PADDING_BOTTOM_RIGHT * scale;

    this.scalePoints[SCALE_POINT_RIGHT_BOTTOM].x = this.frame.width - PADDING_BOTTOM_RIGHT * scale;
    this.scalePoints[SCALE_POINT_RIGHT_BOTTOM].y = this.frame.height - PADDING_BOTTOM_RIGHT * scale;

    this.scalePoints[SCALE_POINT_RIGHT].x = this.frame.width - PADDING_BOTTOM_RIGHT * scale;
    this.scalePoints[SCALE_POINT_RIGHT].y = this.frame.height / 2 - PADDING_BOTTOM_RIGHT * scale;

    this.scalePoints[SCALE_POINT_LEFT].x = -PADDING_TOP_LEFT * scale;
    this.scalePoints[SCALE_POINT_LEFT].y = this.frame.height / 2 - PADDING_BOTTOM_RIGHT * scale;
  }

  showScalePoints(visible, showCornerHandles, showHorizontalEdgeHandles) {
    for (const scalePoint of Object.values(this.scalePoints)) {
      scalePoint.visible = visible;

      // hide unwanted handles
      if (!showCornerHandles && this.cornerHandles.indexOf(scalePoint.name) !== -1) {
        scalePoint.visible = false;
      }

      if (!showHorizontalEdgeHandles && this.horizontalHandles.indexOf(scalePoint.name) !== -1) {
        scalePoint.visible = false;
      }
    }
  }

  drawHeads() {
    // todo Why are we using SVGs here. Are we using them at all
    const leftArrowHead = PIXI.Texture.from(`${process.env.PUBLIC_URL}/asset/leftArrowHead.svg`);
    const rightArrowHead = PIXI.Texture.from(`${process.env.PUBLIC_URL}/asset/rightArrowHead.svg`);

    this.headsContainer = new PIXI.Container();
    this.addChild(this.headsContainer);

    this.headIn = new PIXI.Sprite(leftArrowHead);
    this.headIn.name = EStepConnectionPort.IN;
    this.headIn.anchor.set(0.5);
    this.headIn.interactive = true;
    this.headIn.buttonMode = true;
    this.headIn.cursor = 'grab';
    this.headsContainer.addChild(this.headIn);

    this.headOut = new PIXI.Sprite(rightArrowHead);
    this.headOut.name = EStepConnectionPort.OUT;
    this.headOut.anchor.set(0.5);
    this.headOut.interactive = true;
    this.headOut.buttonMode = true;
    this.headOut.cursor = 'grab';
    this.headsContainer.addChild(this.headOut);

    this.headsContainer.interactive = true;

    this.headIn.on('pointerdown', this.onHeadsDown);

    this.headOut.on('pointerdown', this.onHeadsDown);
  }

  positionHeads() {
    const scale = window.app.viewport.scaled;
    const offset = HEADS_OFFSET * scale;

    this.headIn.x = -offset;
    this.headIn.y = this.frame.height / 2 - offset;

    this.headOut.x = this.frame.width - offset;
    this.headOut.y = this.frame.height / 2 - offset;
  }

  setStep(step) {
    if (this.currentStep !== step) {
      this.currentStep = step;
      this.updateFrame();
    }
  }

  updateFrame(showToolbar = true, showScalePoints = true, showHeads = true) {
    this.currentRatio = this.currentStep.height / this.currentStep.width;
    this.show(showToolbar, showScalePoints, showHeads);
  }

  updateFramePosition() {
    let p = null;
    if (SharedElementHelpers.IsConnection(this.currentStep)) {
      p = this.currentStep.getBounds();
    } else {
      p = Facade.viewport.toGlobal(this.currentStep);
    }
    this.x = p.x;
    this.y = p.y;
  }

  /**
   * Decides the visibitily of the ports (in, out, attr exp)
   * @param step
   */
  setPortVisibility(step, visible = true) {
    this.headsContainer.interactive = visible && SharedElementHelpers.IsStep(step);
    this.headsContainer.visible = visible && SharedElementHelpers.IsStep(step);

    this.headIn.visible = visible && !SharedElementHelpers.IsSource(step);
    this.headIn.interactive = visible && !SharedElementHelpers.IsSource(step);
  }

  show(showToolbar = true, showScalePoints = true, showHeads = true) {
    if (!this.currentStep || this.currentStep.isEditMode) {
      return;
    }

    this.currentRatio = this.currentStep.height / this.currentStep.width;

    this.updateFramePosition();
    this.showScalePoints(
      showScalePoints && this.currentStep.isResizable,
      this.currentStep.showCornerHandles,
      this.currentStep.showHorizontalEdgeHandles,
    );

    if (this.currentStep.hasSelectionFrame) {
      this.drawFrame();
    } else {
      this.frame.visible = false;
    }

    this.positionScalePoints();
    this.positionHeads();

    const isShow = this.currentStep.isShowTool;
    this.setToolbarPositionPoint();
    this.notifyObjectSelected(showToolbar && isShow);
    this.visible = true;

    this.setPortVisibility(this.currentStep, showHeads);
    if (this.currentStep) {
      this.currentStep.onSingleSelectionVisibilityChanged(true);
    }

    if (MainStorage.getCanvasPermissions().isReadonlyAccess) {
      this.headIn.visible = false;
      this.headOut.visible = false;
      this.showScalePoints(false);
    }
  }

  hide() {
    if (this.visible) {
      this.notifyObjectSelected(false);
      this.visible = false;
      this.positionPoint = null;
      if (this.currentStep) {
        this.currentStep.onSingleSelectionVisibilityChanged(false);
      }
    }
  }

  setToolbarPositionPoint() {
    this.positionPoint = this.currentStep.getPositionPoint();
    const rect = this.currentStep.getAttachmentFrame();
    this.sectionStyle = cloneData(rect);
  }

  onTextEditModeChanged(status) {
    if (this.currentStep) {
      this.visible = !status;
      if (!status) {
        this.notifyObjectSelected(false);
      }
    }

    if (!status) {
      this.updateSelectedElementFrame();
    }
  }

  updateSelectedElementFrame() {
    this.currentStep.updateAttachPoints();
    this.updateFrame();
    this.notifyObjectSelected(true);
  }

  /**
   * Sets a point from which we position the toolbar for an element
   */
  notifyObjectSelected(show) {
    if (!this.positionPoint) {
      commonSendEventFunction(PR_EVENT_OBJECT_SELECTED, {
        show: false,
      });
      return;
    }

    if (show === false) {
      commonSendEventFunction(PR_EVENT_OBJECT_SELECTED, {
        show: false,
      });
      return;
    }

    let data = this.getSelectedElementData();
    data.show = show;
    this.lastObjectData = data;
    commonSendEventFunction(PR_EVENT_OBJECT_SELECTED, data);
  }

  getSelectedElementData() {
    if (!this.positionPoint) {
      return;
    }

    const position = this.positionPoint.clone();
    position.x *= window.app.scaleManager.aspectRatio;
    position.y *= window.app.scaleManager.aspectRatio;
    const data = this.currentStep.getState();
    // todo Update API. Change stepId and ID to id
    data.stepId = data.ID;
    data.position = { x: position.x, y: position.y };
    data.sectionStyle = {
      x: this.sectionStyle.x,
      y: this.sectionStyle.y,
      width: this.sectionStyle.width * window.app.scaleManager.aspectRatio,
      height: this.sectionStyle.height * window.app.scaleManager.aspectRatio,
    };
    data.isRightMouse = this.isRightMouse;

    if (SharedElementHelpers.IsConnection(this.currentStep)) {
      data.supportedLineTypes = this.currentStep.supportedLineTypes;
      data.commonDrawLineType = this.currentStep.drawLineType;

      data.sectionStyle = null; // show at mouse click position
      // if the click is inside the data box
      // then show the menu around the data box
      if (
        (MainStorage.isNumbersVisible() || MainStorage.isForecastingVisible()) &&
        !this.currentStep.isNoDataLine()
      ) {
        const viewBounds = this.currentStep.analyticsManager.view.getBounds();
        const p = window.app.renderer.plugins.interaction.mouse.global;
        if (viewBounds.contains(p.x, p.y)) {
          data.sectionStyle = viewBounds;
        }
      }

      // In the case of refreshing the menu from non interaction sources i.e analytics data loaded
      // Then refresh the menu position based on previous position
      // If it was clicked on connection line then keep the same position
      // but if was clicked on the analytics box , then show around the analytics box
      if (
        ((this.delegate.updateSource === SELECTION_UPDATE_SOURCE_ANALYTICS ||
          this.delegate.updateSource === SELECTION_UPDATE_SOURCE_GOAL_CHANGED) &&
          this.lastObjectData &&
          this.lastObjectData.sectionStyle) ||
        this.delegate.updateSource === SELECTION_UPDATE_SOURCE_PANNING
      ) {
        const viewBounds = this.currentStep.analyticsManager.view.getBounds();
        data.sectionStyle = viewBounds;
      }

      const r1 = this.currentStep.selectionPointA.getBounds();
      const r2 = this.currentStep.selectionPointB.getBounds();
      const padding = 10;
      r1.pad(padding);
      r2.pad(padding);
      data.avoidRectangles = [r1, r2];
    }

    return data;
  }

  onViewportClicked() {
    AppSignals.viewportClicked.dispatch();
    if (this.currentStep && !this.currentStep.isText) {
      this.hide();
    } else if (this.currentStep && this.currentStep.isEditMode) {
      this.currentStep.isEditMode = false;
      this.setStep(this.currentStep);
    } else {
      this.hide();
    }
  }

  onScalePointDown(e) {
    this.hide();
    e.stopPropagation();
    this.setDraggingData(e);

    if (this.currentStep) {
      if (SharedElementHelpers.IsShape(this.currentStep)) {
        if (this.delegate && this.delegate.onShapeResizeHandleDown) {
          this.delegate.onShapeResizeHandleDown(e, this.currentStep);
        }
      } else if (SharedElementHelpers.IsText(this.currentStep)) {
        if (this.delegate && this.delegate.onTextResizeHandleDown) {
          this.delegate.onTextResizeHandleDown(e, this.currentStep);
        }
      } else {
        AppSignals.resizePointPressed.dispatch(e);
      }
    }
  }

  onScalePointMove(e) {
    if (this.isDraggingStarted) {
      if (SharedElementHelpers.IsShape(this.currentStep)) {
        if (this.delegate && this.delegate.onShapeResizeHandleMove) {
          this.delegate.onShapeResizeHandleMove(e, this.currentStep);
          e.stopPropagation();
        }
      } else if (SharedElementHelpers.IsText(this.currentStep)) {
        if (this.delegate && this.delegate.onTextResizeHandleMove) {
          this.delegate.onTextResizeHandleMove(e, this.currentStep);
          e.stopPropagation();
        }
        this.updateFrame(false, false, false);
      } else {
        const newScale = this._getNewScale(e.data.global);

        // We limit scale values
        if (newScale > MIN_ALLOWED_SCALE && newScale < MAX_ALLOWED_SCALE) {
          let position;
          const changeScale = newScale / this.dragData.scale;

          switch (this.dragData.scalePointName) {
            case SCALE_POINT_RIGHT_BOTTOM:
              // Do nothing
              break;
            case SCALE_POINT_LEFT_BOTTOM:
              position = {
                x:
                  this.dragData.position.x +
                  this.dragData.size.width -
                  this.dragData.size.width * changeScale,
                y: this.dragData.position.y,
              };
              this._setLocalStepPosition(position);
              break;
            case SCALE_POINT_LEFT_TOP:
              position = {
                x:
                  this.dragData.position.x +
                  this.dragData.size.width -
                  this.dragData.size.width * changeScale,
                y:
                  this.dragData.position.y +
                  this.dragData.size.height -
                  this.dragData.size.height * changeScale,
              };
              this._setLocalStepPosition(position);
              break;
            case SCALE_POINT_RIGHT_TOP:
              position = {
                x: this.dragData.position.x,
                y:
                  this.dragData.position.y +
                  this.dragData.size.height -
                  this.dragData.size.height * changeScale,
              };
              this._setLocalStepPosition(position);
              break;
            default:
              throw Error(
                `[SelectionTool.onScalePointMove] Wrong value ${this.dragData.scalePointName}`,
              );
          }

          this.currentStep.scale.set(newScale);
          this.currentStep.move();
          AppSignals.elementScaleChanged.dispatch(this.currentStep);
        }
      }

      window.app.needsRendering();
    }
  }

  onScalePointUp(e) {
    if (this.isDraggingStarted) {
      if (this.currentStep) {
        if (SharedElementHelpers.IsShape(this.currentStep)) {
          if (this.delegate && this.delegate.onShapeResizeHandleUp) {
            this.delegate.onShapeResizeHandleUp(e, this.currentStep);
            e.stopPropagation();
          }
        } else if (SharedElementHelpers.IsText(this.currentStep)) {
          this.updateSelectedElementFrame();
          if (this.delegate && this.delegate.onTextResizeHandleUp) {
            this.delegate.onTextResizeHandleUp(e, this.currentStep);
            e.stopPropagation();
          }
        } else {
          AppSignals.resizePointReleased.dispatch();
        }
      }

      this.clearDraggingData();
      this.setStep(this.currentStep);
      this.currentStep.move();
      window.app.needsRendering();
    }
  }

  /**
   * Calculate change in size of the element
   * @param position
   * @returns {number}
   * @private
   */
  _getNewScale(position) {
    // Important! this.y is actually the start of the step selected.
    // Selection bounds are drawn from -10, -10
    let height = 0;
    let scale = window.app.viewport.scaled;
    // In case we're using the bottom scale point, use top value
    if (this.isBottomScalePoint(this.dragData.scalePointName)) {
      height = position.y - SELECTION_BOUNDARY_GAP * scale - this.y;
    } else {
      height = this.y + this.dragData.size.height - position.y - SELECTION_BOUNDARY_GAP * scale;
    }

    return (height * this.dragData.scale) / this.dragData.size.height;
  }

  _getGlobalCoordinates(x, y) {
    // todo Extract to utils method that can create a copy in local/global scope
    const coords = Facade.viewport.toGlobal({
      x: x,
      y: y,
    });
    return coords;
  }

  _setLocalStepPosition(position) {
    const local = Facade.viewport.toLocal(position);
    this.currentStep.x = local.x;
    this.currentStep.y = local.y;
  }

  setDraggingData(e) {
    this.isDraggingStarted = true;
    this.dragData = {
      scalePointName: e.currentTarget.name,
      scale: this.currentStep.scale.x,
      position: this._getGlobalCoordinates(this.currentStep.x, this.currentStep.y),
      size: this.currentStep.getEndPoint(),
    };
  }

  clearDraggingData() {
    this.isDraggingStarted = false;
    this.dragData = {};
  }

  getOpositeHandle(handle) {
    let ohn = this.opositeHandles[handle.name];
    return this.scalePoints[ohn];
  }
}
