import * as PIXI from 'pixi.js';

export default class SelectionPoint extends PIXI.Container {
  constructor() {
    super();

    this.content = PIXI.Sprite.from('selection-point');
    this.content.anchor.set(0.5, 0.5);
    this.addChild(this.content);
    this.hitArea = PIXI.Circle(0, 0, 20);

    this.interactive = true;
    this.buttonMode = true;
    this.cursor = 'pointer';
    this.pointerover = this.onHoverIn.bind(this);
    this.pointerout = this.onHoverOut.bind(this);

    this.hide();
  }

  show() {
    this.content.visible = true;
    this.interactive = true;
  }

  hide() {
    this.content.visible = false;
    this.interactive = false;
  }

  onHoverIn() {
    this.content.texture = PIXI.utils.TextureCache['selection-point-hover'];
  }

  onHoverOut() {
    this.content.texture = PIXI.utils.TextureCache['selection-point'];
  }
}
