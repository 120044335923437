import MainStorage from 'pixi-project/core/MainStorage';
import React, { Fragment } from 'react';
import { MappingUpgrade } from './content/MappingUpgrade';
import { NotAvailable } from './content/NotAvailable';
import { PerformanceLiteUpgrade } from './content/PerformanceLiteUpgrade';
import { Tutorial } from './content/Tutorial';
import { VideoUpsell } from './content/VideoUpsell';

export const UpsellPopup = ({ onClose, context }) => {
  let modalContent = null;
  const canvasPermissions = MainStorage.getCanvasPermissions();
  const isEligibleForFreeTrial = canvasPermissions.isEligibleForFreeTrial;
  console.log({ context });
  switch (context) {
    case 'upsellVideo':
      modalContent = (
        <VideoUpsell onClose={onClose} isEligibleForFreeTrial={isEligibleForFreeTrial} />
      );
      break;
    case 'tutorial':
      modalContent = <Tutorial onClose={onClose} />;
      break;
    default:
      const isReadOnly = canvasPermissions.isReadonlyAccess;
      if (isReadOnly) {
        modalContent = <NotAvailable onClose={onClose} />;
      } else {
        modalContent = <PerformanceLiteUpgrade onClose={onClose} />;
      }

    // Keep this code for future reference ,until the popups are all implemented

    // else if (!isAnalyticsAllowed) {
    //   // !isAnalyticsAllowed
    //   modalContent = (
    //     <MappingUpgrade onClose={onClose} isEligibleForFreeTrial={isEligibleForFreeTrial} />
    //   );
    // } else if (!isActionsAllowed) {
    //   // !isActionsAllowed
    //   modalContent = <PerformanceLiteUpgrade onClose={onClose} />;
    // }
  }
  return <Fragment>{modalContent ? modalContent : <div />}</Fragment>;
};

UpsellPopup.propTypes = {};
