import React from 'react';
import styles from 'react-project/components/sendReportPopup/SendReportPopup.module.scss';
import { iconClose } from '../../../assets/Icons';

const EmailItemComponent = ({ deleteEmail, email }) => {
  return (
    <div className={styles.Email}>
      <span>{email}</span>
      <span onClick={() => deleteEmail()}>{iconClose}</span>
    </div>
  );
};

export const EmailItem = EmailItemComponent;
