import { ActionNames } from 'react-project/redux/actionNamesConstant';
import { createAction } from 'redux-actions';

export const setCanvasChecklistAction = createAction(ActionNames.setCanvasChecklistAction);

export const setCanvasChecklist = (checklist) => {
  return (dispatch) => {
    dispatch(setCanvasChecklistAction(checklist));
  };
};
