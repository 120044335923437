import cls from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { copyToClipboard } from 'react-project/Helpers/CopyToClipboard';
import { iconCheck, iconCopy, iconTrashcan } from 'react-project/assets/Icons';
import { CopyToClipboardNotification } from 'react-project/components/copyToClipboardNotification/copyToClipboardNotification';
import { Switch } from 'react-project/components/switch/Switch';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ShareLink.module.scss';
import {
  asyncSetIsCanvasViewOnly,
  asyncSetIsLinkPrivate,
  asyncUpdateCanvasViewOnly,
  setIsPasswordProtected,
} from './shareCanvasSlice';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import { setIsCanvasShareModalOpen } from './shareCanvasSlice';
import { When } from 'react-project/Util/When';
import { LOCK_NO_VIEW_ONLY, UILock } from 'react-project/components/uilock/UILock';

export const ShareLink = () => {
  const axiosControllerShareLink = useRef();
  const dispatch = useDispatch();
  const isCanvasSharePublic = useSelector((state) => state.canvasSharing.isCanvasSharePublic);
  const isCanvasViewOnly = useSelector((state) => state.canvasSharing.isCanvasViewOnly);
  const isPasswordProtected = useSelector((state) => state.canvasSharing.isPasswordProtected);
  const passwordPlaceHolder = isPasswordProtected ? '********' : 'Password';
  const funnelId = useSelector((state) => state.funnels.currentFunnelId);
  const funnelShareLink = `${process.env.REACT_APP_CURRENT_APP_URL}/share/${funnelId}`;
  const funnelViewOnlyLink = `${window.location.origin}/view/${funnelId}`;
  const [previousPassword, setPreviousPassword] = useState(null);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const disabled = useRef();
  const isPasswordFocusedRef = useRef(false);
  const passwordInputRef = useRef(null);
  const canvasPermissions = useSelector(selectCanvasPermissions);

  useEffect(() => {
    disabled.current = isCanvasSharePublic === null;
  }, [isCanvasSharePublic]);

  useEffect(() => {
    return () => {
      if (isPasswordFocusedRef.current) {
        setPassword(passwordInputRef.current.value);
      }
    };
  }, []);

  const onCanvasSharingToggle = (status, e) => {
    if (canvasPermissions.isTutorial) {
      setIsErrorShown(true);
      return;
    }

    if (axiosControllerShareLink.current) {
      axiosControllerShareLink.current.abort();
    }
    const abortController = new AbortController();
    axiosControllerShareLink.current = abortController;
    dispatch(asyncSetIsLinkPrivate({ isCanvasSharePublic: status, abortController }));
  };

  const onViewOnlyToggle = (status, e) => {
    if (canvasPermissions.isTutorial) {
      setIsErrorShown(true);
      return;
    }

    dispatch(asyncSetIsCanvasViewOnly({ isCanvasViewOnly: status }));
    setPreviousPassword('');
    dispatch(setIsPasswordProtected(false));
  };

  const onPasswordFocus = (e) => {
    setPreviousPassword(e.target.value);
    isPasswordFocusedRef.current = true;
  };

  const onPasswordBlur = (e) => {
    setPassword(e.target.value);
    isPasswordFocusedRef.current = false;
  };

  const onPasswordKeyDown = (e) => {
    if (canvasPermissions.isTutorial) {
      setIsErrorShown(true);
      return;
    }

    if (e.key === 'Enter') {
      setPassword(e.target.value);
    }
  };

  const onPasswordRemoved = (e) => {
    if (canvasPermissions.isTutorial) {
      setIsErrorShown(true);
      return;
    }

    setPassword('', true);
    dispatch(setIsPasswordProtected(false));
    passwordInputRef.current.value = '';
  };

  const setPassword = (password, force = false) => {
    if (canvasPermissions.isTutorial) {
      console.warn('TODO cant change password in tutorial mode');
      return;
    }

    if (previousPassword !== password || force) {
      // if they are different, then update password
      dispatch(asyncUpdateCanvasViewOnly({ password: password })).then((e) => {
        dispatch(setIsPasswordProtected(!!e.data.password));
      });
      setPreviousPassword(password);
    }
  };

  return (
    <div className={cls(styles.ShareCanvasContainer)}>
      <div className="flex flex-column">
        <div>
          <Switch
            onToggle={onCanvasSharingToggle}
            isActive={isCanvasSharePublic}
            disabled={disabled.current}
          />
        </div>
        <div>
          <div className={cls(styles.ShareCanvasLabel, styles.ShareCanvasToggleLabel)}>
            Enable Canvas Sharing Link
          </div>
          <div>
            <div className={cls(styles.ShareCanvasCopyWarning, 'pb-[16px] italic')}>
              Enabling this toggle will allow anyone with the link to view the canvas and make a
              copy of it in their account.
            </div>
          </div>
        </div>
      </div>
      {isCanvasSharePublic ? <CopyLink link={funnelShareLink} /> : null}
      <hr className={styles.Divider} />
      <UILock lock={LOCK_NO_VIEW_ONLY} onClick={() => dispatch(setIsCanvasShareModalOpen(false))}>
        <div className="flex flex-column">
          <div>
            <Switch
              onToggle={onViewOnlyToggle}
              isActive={canvasPermissions.viewOnlyAllowed && isCanvasViewOnly}
            />
          </div>
          <div>
            <div className={cls(styles.ShareCanvasLabel, styles.ShareCanvasToggleLabel)}>
              Enable Canvas View Only Link
            </div>
            <div>
              <div className={cls(styles.ShareCanvasCopyWarning, 'pb-[16px] italic')}>
                Enabling this toggle will allow anyone with the link to access the canvas in View
                Only mode.
              </div>
            </div>
          </div>
        </div>
      </UILock>
      {canvasPermissions.viewOnlyAllowed && isCanvasViewOnly ? (
        <>
          <CopyLink link={funnelViewOnlyLink} />

          <div className={styles.FormControl}>
            <p className={styles.PasswordTitle}>
              {isPasswordProtected
                ? 'Your canvas is password protected'
                : 'Set a password to protect your canvas (optional)'}
              {isPasswordProtected ? iconCheck : null}
            </p>
            <div className={styles.PasswordWrapper}>
              <input
                key="canvasPasswordProtected"
                name="canvasPasswordProtected"
                type="password"
                onBlur={onPasswordBlur}
                onFocus={onPasswordFocus}
                placeholder={passwordPlaceHolder}
                onKeyDown={onPasswordKeyDown}
                ref={passwordInputRef}
                disabled={canvasPermissions.isTutorial}
              />
              <div onClick={onPasswordRemoved} className={styles.RemovePassword}>
                {iconTrashcan}
              </div>
            </div>
          </div>
        </>
      ) : null}

      <When condition={isErrorShown}>
        <div
          className="flex flex-column"
          style={{
            color: 'red',
            marginTop: '20px',
          }}
        >
          You can't edit sharing settings in tutorial mode
        </div>
      </When>
    </div>
  );
};

const CopyLink = ({ link }) => {
  const [count, setCount] = useState(0);
  return (
    <div
      className={cls(
        styles.ShareCanvasInput,
        'flex',
        'flex-justify',
        'justify-between',
        'whitespace-nowrap',
      )}
      onClick={() => {
        copyToClipboard(link);
        setCount(count + 1);
      }}
      readOnly
    >
      <div className={styles.InputText}>{link}</div>
      <div className={styles.Icon}>{iconCopy}</div>
      <CopyToClipboardNotification count={count} />
    </div>
  );
};
