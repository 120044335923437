import axios from 'axios';
import React, { useEffect, useState } from 'react';
import RequestService from 'react-project/Helpers/RequestService';
import { getPeopleRequestBody } from 'react-project/Helpers/requestConstruction';
import { iconPeople } from 'react-project/assets/Icons';
import { NoDataBlock } from 'react-project/components/noDataBlock/NoDataBlock';
import { PopUpWrapper } from 'react-project/components/popUp/PopUpWrapper';
import {
  selectAnalyticsStatus,
  selectDataConnections,
  selectDataObjs,
} from 'react-project/redux/analytics/selectors';
import { selectCurrentStep } from 'react-project/redux/current-step/selectors';
import { selectFocusedSteps } from 'react-project/redux/focused-step/selectors';
import {
  selectFunnelConfiguration,
  selectIsNumbersActive,
} from 'react-project/redux/funnel-configuration/selectors';
import { selectFunnel, selectIsFunnelLoading } from 'react-project/redux/funnels/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { ANALYTICS_STATUS_LOADING } from 'shared/CSharedConstants';
import PeopleContainer from './components/PeopleContainer';
import PeopleTable from './components/PeopleTable';
import StyledCsvDownload from './components/StyledCsvDownload';
import { setUniqueSessions } from './helpers';
import { setPeopleTrackingPopup } from './peopleTrackingSlice';
import { LayerType } from 'react-project/LayersMenu/LayerType';

export function PeopleTracking({ onClose }) {
  const resultPerQuery = 15;
  const [peopleData, setPeopleData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasLoadMore, setHasLoadMore] = useState(false);
  const [metaCount, setMetaCount] = useState(0);
  const [pagination, setPagination] = useState(1);
  const [pagIsLoading, setPagIsLoading] = useState(false);
  const [csvIsLoading, setCsvIsLoading] = useState(false);

  const funnelConfiguration = useSelector((state) => selectFunnelConfiguration(state));
  const dataObjs = useSelector((state) => selectDataObjs(state));
  const dataConnections = useSelector((state) => selectDataConnections(state));
  const projectId = useSelector((state) => selectFunnel(state)['projectId']);
  const focusedSteps = useSelector((state) => selectFocusedSteps(state));
  const currentStep = useSelector((state) => selectCurrentStep(state));
  const analyticsStatus = useSelector(selectAnalyticsStatus);
  const isFunnelLoading = useSelector(selectIsFunnelLoading);
  const zIndex = useSelector((state) => state.peopleTracking.popupZindex);
  const isNumbersActive = funnelConfiguration.visibleDataLayers[LayerType.NUMBERS];
  const requestService = new RequestService();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isNumbersActive) return;
    if (analyticsStatus === ANALYTICS_STATUS_LOADING) return;
    if (isFunnelLoading) return;
    // Delay building query until explorer is loaded
    setIsLoading(true);
    asyncGetPeopleData()
      .catch((e) => {
        setHasLoadMore(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [isNumbersActive, analyticsStatus, isFunnelLoading]);

  useEffect(() => {
    const hasMore = metaCount - peopleData.length > 0;
    setHasLoadMore(hasMore);
  }, [metaCount, pagination]);

  const asyncGetPeopleData = async () => {
    const data = getPeopleRequestBody({
      dataObjs,
      dataConnections,
      compareMode: false,
      funnelConfiguration,
      focusedSteps,
      currentStep,
    });
    const source = axios.CancelToken.source();
    const resp = await requestService.getPeopleAnalytics(projectId, data, source);

    if (axios.isCancel(resp) || !resp) {
      return;
    }

    //Handle case where page response is below resultPerQuery limit;
    const requiredCount = Math.min(resultPerQuery, resp.data.meta.count);
    let currentCount = resp.data.sessions.length;
    let prevCurrentCount = currentCount;
    let pageNumber = 1;
    let noBueno = 0;
    while (requiredCount > currentCount) {
      pageNumber = pageNumber + 1;

      const data1 = getPeopleRequestBody({
        dataObjs,
        dataConnections,
        compareMode: false,
        pageNumber,
        funnelConfiguration,
        focusedSteps,
        currentStep,
      });
      const secReq = await requestService.getPeopleAnalytics(projectId, data1);
      setUniqueSessions(resp.data.sessions, secReq.data.sessions);
      prevCurrentCount = currentCount;
      currentCount = resp.data.sessions.length;
      if (prevCurrentCount === currentCount && noBueno === 2) {
        noBueno++;
        currentCount === requiredCount;
      }
    }
    setPagination(pageNumber);
    setPeopleData(resp.data.sessions);
    setMetaCount(resp.data.meta.count);
  };

  const onLoadMore = async () => {
    let internalCounter = 1;
    if (pagIsLoading) return;
    setPagIsLoading(true);
    const data = getPeopleRequestBody({
      dataObjs,
      dataConnections,
      compareMode: false,
      pageNumber: pagination + internalCounter,
      funnelConfiguration,
      focusedSteps,
      currentStep,
    });
    const resp = await requestService.getPeopleAnalytics(projectId, data);
    const newPeopleData = [...peopleData];
    setUniqueSessions(newPeopleData, resp.data.sessions);
    setPeopleData(newPeopleData);
    setPagination(pagination + internalCounter);
    setPagIsLoading(false);
  };

  const StyledStarterMessage = () => {
    return (
      <div className="defaultRndCancel w-full cursor-auto h-full flex justify-center items-center ">
        Turn on analytics to see People data
      </div>
    );
  };

  const loading = (
    <div className="defaultRndCancel w-full cursor-auto h-full flex justify-center items-center ">
      <div>Loading Data ...</div>
    </div>
  );

  const noDataBlock = (
    <div className="defaultRndCancel h-full cursor-auto">
      <NoDataBlock />
    </div>
  );

  const title = metaCount ? `${metaCount} people` : '';
  const resetStates = () => {
    setPeopleData(undefined);
    setIsLoading(false);
    setHasLoadMore(false);
    setMetaCount(0);
    setPagination(1);
    setPagIsLoading(false);
    setCsvIsLoading(false);
  };
  const close = () => {
    resetStates();
    onClose();
  };

  const requestObj = {
    dataObjs,
    dataConnections,
    compareMode: false,
    funnelConfiguration,
    focusedSteps,
    currentStep,
  };

  const wrapperOptions = {
    position: { x: window.innerWidth / 2 - 400 / 2, y: 0 },
    icon: iconPeople(),
    showCloseIcon: true,
    menuActions: [
      <StyledCsvDownload
        key="scd"
        isLoading={csvIsLoading}
        isEnabled={isNumbersActive}
        sessions={peopleData}
        setCsvIsLoading={setCsvIsLoading}
        csvIsLoading={csvIsLoading}
        requestObj={requestObj}
        projectId={projectId}
        metaCount={metaCount}
      />,
    ],
    onClosePopup: close,
    isDraggable: true,
    width: 400,
    minWidth: 400,
    onIconClick: () => {},
    rndRef: undefined,
    className: 'stop-event',
    cancel: 'defaultRndCancel',
    title: title,
  };

  const onClick = (eventTarget, session) => {
    eventTarget.style.backgroundColor = 'lightblue';
    dispatch(setPeopleTrackingPopup({ session, target: eventTarget }));
  };
  return (
    <>
      <PopUpWrapper {...wrapperOptions}>
        {isNumbersActive ? (
          isLoading ? (
            loading
          ) : peopleData?.length > 0 ? (
            <PeopleContainer>
              <PeopleTable
                data={peopleData}
                onClick={onClick}
                isLoading={isLoading}
                hasLoadMore={hasLoadMore}
                onLoadMore={onLoadMore}
                pagIsLoading={pagIsLoading}
              />
            </PeopleContainer>
          ) : (
            noDataBlock
          )
        ) : (
          <StyledStarterMessage />
        )}
      </PopUpWrapper>
    </>
  );
}

export default PeopleTracking;
