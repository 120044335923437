import * as PIXI from 'pixi.js';

const RADIUS = 6;

// DELEGATE
// - onGhostBreakPointDown(event , breakPoint)

export default class GhostBreakPoint extends PIXI.Container {
  constructor(delegate) {
    super();

    this.delegate = delegate;
    this.lastPosition = new PIXI.Point();
    this.startDragPosition = new PIXI.Point();
    this.startPosition = new PIXI.Point();

    this.isPointerDown = false;
    this.didMove = false;
    this.doubleClickTime = 0;
    this.doubleClickPoint = new PIXI.Point();

    this.interactive = true;
    this.buttonMode = true;
    this.cursor = 'move';
    this.pointerdown = this.onPointerDown.bind(this);
    this.interactionBounds = new PIXI.Circle(0, 0, RADIUS * 2);

    this.content = PIXI.Sprite.from(PIXI.utils.TextureCache['selection-ghost-point']);
    this.content.anchor.set(0.5, 0.5);
    this.addChild(this.content);
  }

  savePosition() {
    this.lastPosition.copyFrom(this.position);
    this.startPosition.copyFrom(this.position);
  }

  onPointerDown(e) {
    e.stopPropagation();
    if (this.delegate && this.delegate.onGhostBreakPointDown) {
      this.delegate.onGhostBreakPointDown(e, this);
    }
  }

  // PIXI overwrite
  containsPoint(point) {
    let local = this.toLocal(point);
    return this.interactionBounds.contains(local.x, local.y);
  }
}
