import { jsPDF } from 'jspdf';
// read html template
import titlePageTemplate from './titlePage.txt';
import filterPage from './filterPage.txt';
import filterRowsGeneral from './filterRowsGeneral.txt';
import filterRowsWithCompareDate from './filterRowsWithCompareDate.txt';
import filterRowsWithComparePeople from './filterRowsWithComparePeople.txt';
// read row
import filterRowPeople from './filterRowPeople.txt';
import filterRowPeopleWhoPerformed from './filterRowPeopleWhoPerformed.txt';
// read img
import backgroundImg from './background.png';
import logoImg from './logo.jpg';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { RP_EVENT_PDF_CREATED } from 'shared/CSharedEvents';

const attachTextPage = (text, backgroundBlob, doc, width, height) => {
  return new Promise((resolve) => {
    var reader = new FileReader();
    reader.onload = () => {
      // convert png image to binary
      const imgData = reader.result.split(';')[1].split(',')[1];
      // add png image to jspdf
      doc.addImage(imgData, 'png', 0, 0, width, height, '', 'FAST');
      // append text
      var element = document.createElement('div');
      element.style.width = '1910px';
      element.style.height = '980px';
      element.innerHTML = text;
      doc.html(element, {
        callback: () => {
          resolve();
        },
      });
    };
    reader.readAsDataURL(backgroundBlob);
  });
};

const shorthSessionName = (session) => {
  return session.toString().split('-')[0];
};

const blobsForPdf = async (name, funnelConfiguration, sessions) => {
  // STEP 1: gather blobs
  // background-img blob
  let backgroundResponse = await fetch(backgroundImg);
  let backgroundBlob = await backgroundResponse.blob();
  // title-page text
  let titlePageResponse = await fetch(titlePageTemplate);
  let titlePageText = await titlePageResponse.text();
  // filter-page text
  let filterPageResponse = await fetch(filterPage);
  let filterPageText = await filterPageResponse.text();

  let filterRowsGeneralResponse = await fetch(filterRowsGeneral);
  let filterRowsGeneralText = await filterRowsGeneralResponse.text();
  let filterRowsWithCompareDateResponse = await fetch(filterRowsWithCompareDate);
  let filterRowsWithCompareDateText = await filterRowsWithCompareDateResponse.text();
  let filterRowsWithComparePeopleResponse = await fetch(filterRowsWithComparePeople);
  let filterRowsWithComparePeopleText = await filterRowsWithComparePeopleResponse.text();

  let filterRowPeopleResponse = await fetch(filterRowPeople);
  let filterRowPeopleTextRaw = await filterRowPeopleResponse.text();
  let filterRowPeopleWhoPerformedResponse = await fetch(filterRowPeopleWhoPerformed);
  let filterRowPeopleWhoPerformedTextRaw = await filterRowPeopleWhoPerformedResponse.text();

  // STEP 2: post-processing
  // titlePageText transformation
  titlePageText = titlePageText.replace('{template.reportName}', name);

  // STEP 3: post-processing
  // STEP 3.1: filterRowsGeneralText transformation
  filterRowsGeneralText = filterRowsGeneralText.replace(
    '{filterRowsGeneral.people}',
    sessions && sessions.meta.count,
  );
  filterRowsGeneralText = filterRowsGeneralText.replace(
    '{filterRowsGeneral.device}',
    funnelConfiguration.filter.device,
  );
  filterRowsGeneralText = filterRowsGeneralText.replace(
    '{filterRowsGeneral.countries}',
    funnelConfiguration.filter.countries?.length > 0
      ? funnelConfiguration.filter.countries
      : 'all countries',
  );
  filterRowsGeneralText = filterRowsGeneralText.replace(
    '{filterRowsGeneral.timeRangeMin}',
    new Date(funnelConfiguration.dateRange.min).toLocaleString(),
  );
  filterRowsGeneralText = filterRowsGeneralText.replace(
    '{filterRowsGeneral.timeRangeMax}',
    new Date(funnelConfiguration.dateRange.max).toLocaleString(),
  );

  // The information for the PWP tool will curently not be included in the report
  // There should be a pending task to include this in future

  // // if 'people who performed' applied
  // if (funnelConfiguration.stepFocusingId !== 'none' && funnelConfiguration.stepFocusingId !== '') {
  //   let filterRowPeopleWhoPerformedText = '' + filterRowPeopleWhoPerformedTextRaw;
  //   filterRowPeopleWhoPerformedText = filterRowPeopleWhoPerformedText.replace(
  //     '{peopleWhoPerformedPrefix}',
  //     `People who performed `
  //   );
  //   filterRowPeopleWhoPerformedText = filterRowPeopleWhoPerformedText.replace(
  //     '{peopleWhoPerformed}',
  //     funnelConfiguration.stepFocusingLabel
  //   );
  //   filterRowsGeneralText = filterRowsGeneralText.replace(
  //     '{filterRowsGeneral.peopleWhoPerformed}',
  //     filterRowPeopleWhoPerformedText
  //   );
  // } else {
  //   let filterRowPeopleWhoPerformedText = '' + filterRowPeopleWhoPerformedTextRaw;
  //   filterRowsGeneralText = filterRowsGeneralText.replace(
  //     '{filterRowsGeneral.peopleWhoPerformed}',
  //     ''
  //   );
  // }

  // if 'people' applied
  if (funnelConfiguration.filter.session) {
    let filterRowPeopleText = '' + filterRowPeopleTextRaw;
    filterRowPeopleText = filterRowPeopleText.replace('{peopleRowPrefix}', `People `);
    filterRowPeopleText = filterRowPeopleText.replace(
      '{peopleRow}',
      shorthSessionName(funnelConfiguration.filter.session),
    );
    filterRowsGeneralText = filterRowsGeneralText.replace(
      '{filterRowsGeneral.peopleRow}',
      filterRowPeopleText,
    );
  } else {
    let filterRowPeopleText = '' + filterRowPeopleTextRaw;
    filterRowsGeneralText = filterRowsGeneralText.replace('{filterRowsGeneral.peopleRow}', '');
  }

  // STEP 3.2: filterRowsWithCompareDateText transformation
  filterRowsWithCompareDateText = filterRowsWithCompareDateText.replace(
    '{filterRowsWithCompareDate.people}',
    sessions && sessions.compareMeta.count,
  );
  filterRowsWithCompareDateText = filterRowsWithCompareDateText.replace(
    '{filterRowsWithCompareDate.timeRangeMin}',
    new Date(funnelConfiguration.compareDateRange.min).toLocaleString(),
  );
  filterRowsWithCompareDateText = filterRowsWithCompareDateText.replace(
    '{filterRowsWithCompareDate.timeRangeMax}',
    new Date(funnelConfiguration.compareDateRange.max).toLocaleString(),
  );

  // STEP 3.3: filterRowsWithComparePeopleText transformation
  filterRowsWithComparePeopleText = filterRowsWithComparePeopleText.replace(
    '{filterRowsWithCompareDate.people}',
    sessions && sessions.compareMeta.count,
  );
  filterRowsWithComparePeopleText = filterRowsWithComparePeopleText.replace(
    '{filterRowsWithCompareDate.device}',
    funnelConfiguration.compareFilter.device,
  );
  filterRowsWithComparePeopleText = filterRowsWithComparePeopleText.replace(
    '{filterRowsWithCompareDate.countries}',
    funnelConfiguration.compareFilter.countries?.length > 0
      ? funnelConfiguration.compareFilter.countries
      : 'all countries',
  );

  // TODO this will be addressed in future ticket
  // if 'people who performed' applied
  // if (
  //   funnelConfiguration.stepCompareFocusingId !== 'none' &&
  //   funnelConfiguration.stepCompareFocusingId !== ''
  // ) {
  //   let filterRowPeopleWhoPerformedText = '' + filterRowPeopleWhoPerformedTextRaw;
  //   filterRowPeopleWhoPerformedText = filterRowPeopleWhoPerformedText.replace(
  //     '{peopleWhoPerformedPrefix}',
  //     `People who performed `
  //   );
  //   filterRowPeopleWhoPerformedText = filterRowPeopleWhoPerformedText.replace(
  //     '{peopleWhoPerformed}',
  //     funnelConfiguration.stepCompareFocusingLabel
  //   );
  //   filterRowsWithComparePeopleText = filterRowsWithComparePeopleText.replace(
  //     '{filterRowsWithCompareDate.peopleWhoPerformed}',
  //     filterRowPeopleWhoPerformedText
  //   );
  // } else {
  //   let filterRowPeopleWhoPerformedText = '' + filterRowPeopleWhoPerformedTextRaw;
  //   filterRowsWithComparePeopleText = filterRowsWithComparePeopleText.replace(
  //     '{filterRowsWithCompareDate.peopleWhoPerformed}',
  //     ''
  //   );
  // }

  // if 'people' applied
  if (funnelConfiguration.compareFilter.session) {
    let filterRowPeopleText = '' + filterRowPeopleTextRaw;
    filterRowPeopleText = filterRowPeopleText.replace('{peopleRowPrefix}', `People `);
    filterRowPeopleText = filterRowPeopleText.replace(
      '{peopleRow}',
      shorthSessionName(funnelConfiguration.compareFilter.session),
    );
    filterRowsWithComparePeopleText = filterRowsWithComparePeopleText.replace(
      '{filterRowsWithCompareDate.peopleRow}',
      filterRowPeopleText,
    );
  } else {
    let filterRowPeopleText = '' + filterRowPeopleTextRaw;
    filterRowsWithComparePeopleText = filterRowsWithComparePeopleText.replace(
      '{filterRowsWithCompareDate.peopleRow}',
      '',
    );
  }

  // replace text in filter page, with / without compare filter
  filterPageText = filterPageText.replace('{filterRowsGeneral}', filterRowsGeneralText);
  if (funnelConfiguration.selectedCompareFilter.label === 'Date') {
    filterPageText = filterPageText.replace('{filterRowsMarginTop}', '31px');
    filterPageText = filterPageText.replace('{filterRowsMarginBottom}', '31px');
    filterPageText = filterPageText.replace(
      '{filterRowsWithCompare}',
      filterRowsWithCompareDateText,
    );
  } else if (funnelConfiguration.selectedCompareFilter.label === 'People') {
    filterPageText = filterPageText.replace('{filterRowsMarginTop}', '21px');
    filterPageText = filterPageText.replace('{filterRowsMarginBottom}', '21px');
    filterPageText = filterPageText.replace(
      '{filterRowsWithCompare}',
      filterRowsWithComparePeopleText,
    );
  } else {
    filterPageText = filterPageText.replace('{filterRowsMarginTop}', '31px');
    filterPageText = filterPageText.replace('{filterRowsMarginBottom}', '31px');
    filterPageText = filterPageText.replace('{filterRowsWithCompare}', '');
  }

  return { backgroundBlob, titlePageText, filterPageText };
};

const generatePdfFile = async (reportMetadata, isDownload) => {
  const { name, funnelConfiguration, sessions, images, isNumbersActive } = reportMetadata;
  const { backgroundBlob, titlePageText, filterPageText } = await blobsForPdf(
    name,
    funnelConfiguration,
    sessions,
  );

  const doc = new jsPDF('l', 'px', [1920, 1080]);
  const width = doc.internal.pageSize.getWidth();
  const height = doc.internal.pageSize.getHeight();

  // STEP 1: attach title
  await attachTextPage(titlePageText, backgroundBlob, doc, width, height);
  doc.addPage('l');

  // STEP 1.1: attach filter page
  if (isNumbersActive) {
    await attachTextPage(filterPageText, backgroundBlob, doc, width, height);
    doc.addPage('l');
  }

  // STEP 2: attach canvas page
  for (let i = 0; i < images.length; i++) {
    doc.addImage(images[i], 'png', 0, 0, width, height, '', 'FAST');
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(35);
    doc.text(`Page ${i + 1}`, 43, 1043);
    if (i !== images.length - 1) {
      doc.addPage('l');
    }
  }

  if (isDownload) {
    doc.save('report.pdf');
  }

  commonSendEventFunction(RP_EVENT_PDF_CREATED);

  return doc.output('blob');
};

export default generatePdfFile;
