import React, { useEffect, useRef, useState } from 'react';
import {
  POSITIONING_BOTTOM,
  VERTICAL_ALIGN_LEFT,
  ViewportConstrainer,
} from 'react-project/components/viewportConstrainer/ViewportConstrainer';
import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';
import styles from './FunnelDataMode.module.scss';
import cls from 'classnames';
import {
  iconFunnelMode,
  iconFunnelModeGear,
  iconTrafficMode,
  iconTrafficModeGear,
} from 'react-project/assets/Icons';

import { selectFunnelConfiguration } from 'react-project/redux/funnel-configuration/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { When } from 'react-project/Util/When';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import {
  PR_EVENT_FUNNEL_CHANGED,
  PR_EVENT_LOOP_DETECTION,
  RP_EVENT_REFRESH_REQUEST,
} from 'shared/CSharedEvents';
import { setDataMode } from 'react-project/redux/funnel-configuration/actions';
import { setAnalyticsStale } from 'react-project/redux/analytics/actions';
import {
  ANALYTICS_STATUS_SUCCESS,
  DATA_MODE_FUNNEL,
  DATA_MODE_TRAFFIC,
} from 'shared/CSharedConstants';
import { LoopWarningComponent } from 'react-project/Toolbar/step-toolbar/LoopWarrning/LoopWarningComponent';
import { useFirstRender } from 'react-project/hooks/useFirstRender';
import { selectAnalyticsStatus } from 'react-project/redux/analytics/selectors';
import { LayerType } from 'react-project/LayersMenu/LayerType';
import { Tooltip } from 'react-project/components/tooltip/Tooltip';
import { TEXTS_TOOLTIP } from 'react-project/Constants/texts';
import { LOCK_NO_ANALYTICS, LOCK_NO_TRAFFIC_MODE, UILock } from '../uilock/UILock';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';

export const FunnelDataMode = () => {
  const dispatch = useDispatch();
  const [isDataModeOpened, setIsDataModeOpened] = useState(false);
  const [isLoopWarningOpened, setIsLoopWarningOpened] = useState(false);
  const btnRef = useRef(null);
  const funnelConfiguration = useSelector(selectFunnelConfiguration);
  const canvasPermissions = useSelector(selectCanvasPermissions);
  const activeMode = canvasPermissions.trafficModeAllowed
    ? funnelConfiguration.dataMode
    : DATA_MODE_FUNNEL;
  const isFirstRender = useFirstRender();
  const analyticsStatus = useSelector(selectAnalyticsStatus);

  const onDataModeBtnClicked = () => {
    setIsDataModeOpened(!isDataModeOpened);
  };

  useEffect(() => {
    document.addEventListener(PR_EVENT_LOOP_DETECTION, onLoopDetection, false);

    if (
      funnelConfiguration.visibleDataLayers[LayerType.NUMBERS] &&
      analyticsStatus === ANALYTICS_STATUS_SUCCESS &&
      !isFirstRender
    ) {
      dispatch(setAnalyticsStale());
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
      commonSendEventFunction(RP_EVENT_REFRESH_REQUEST, { isCancel: true });
    }

    return () => {
      document.removeEventListener(PR_EVENT_LOOP_DETECTION, onLoopDetection, false);
    };
  }, [activeMode]);

  const onModeClicked = (mode) => {
    // check the mode here
    if (mode === DATA_MODE_FUNNEL && activeMode !== DATA_MODE_FUNNEL) {
      // ask if there is a cyclic funnel
      const loops = window.app.planeContainer.sceneManager.graphData.loops;
      setIsLoopWarningOpened(loops.length > 0);
    }

    onModeChanged(mode);
  };

  const onClose = (e) => {
    const htmlElement = e.target;
    // if htmlElement or its parents id is FUNNEL_MODE then return
    if (htmlElement.id === 'FUNNEL_MODE' || htmlElement.closest('#FUNNEL_MODE')) {
      return;
    }

    setIsDataModeOpened(false);
  };

  const onModeChanged = (mode) => {
    if (activeMode === mode) {
      return;
    }

    dispatch(setDataMode(mode));

    setIsDataModeOpened(false);
  };

  const getDataModeIcon = (mode) => {
    switch (mode) {
      case DATA_MODE_TRAFFIC:
        return iconTrafficModeGear;
      case DATA_MODE_FUNNEL:
        return iconFunnelModeGear;
      default:
        return iconTrafficModeGear;
    }
  };

  const onLoopWarrningClose = () => {
    setIsLoopWarningOpened(false);
  };

  const onLoopDetection = (e) => {
    if (activeMode === DATA_MODE_FUNNEL) {
      const cyclicData = e.detail;
      setIsLoopWarningOpened(cyclicData.length > 0);
    }
  };

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <Tooltip label={TEXTS_TOOLTIP.DATA_VIEW}>
        <div
          id="FUNNEL_MODE"
          ref={btnRef}
          onClick={() => {
            onDataModeBtnClicked();
          }}
          className={cls(styles.ToobarButton, isDataModeOpened && styles.ActiveToolbarBtn)}
        >
          {getDataModeIcon(activeMode)}
        </div>
      </Tooltip>
      {isLoopWarningOpened && <LoopWarningComponent onClose={onLoopWarrningClose} />}
      <When condition={isDataModeOpened}>
        <ViewportConstrainer
          positioning={POSITIONING_BOTTOM}
          relativeElement={btnRef.current}
          verticalAlign={VERTICAL_ALIGN_LEFT}
        >
          <ClickOutsideCustomComponent onClickOutside={onClose}>
            <div className={styles.Wrapper}>
              <div className={styles.WrapperBox}>
                <div className={cls(styles.Container, styles.Title)}>Data View</div>
                <div className={styles.Separator}></div>

                <div className={cls(styles.Container)}>
                  <UILock lock={LOCK_NO_TRAFFIC_MODE} style={{ flexGrow: 1 }}>
                    <div
                      onClick={() => onModeClicked(DATA_MODE_TRAFFIC)}
                      className={cls(
                        styles.Option,
                        activeMode === DATA_MODE_TRAFFIC ? styles.Active : null,
                      )}
                    >
                      <div>{iconTrafficMode}</div>
                      <div> Traffic Mode </div>
                    </div>
                  </UILock>

                  <div
                    onClick={() => onModeClicked(DATA_MODE_FUNNEL)}
                    className={cls(
                      styles.Option,
                      activeMode === DATA_MODE_FUNNEL ? styles.Active : null,
                    )}
                  >
                    <div> {iconFunnelMode} </div>
                    <div> Funnel Mode </div>
                  </div>
                </div>
              </div>
            </div>
          </ClickOutsideCustomComponent>
        </ViewportConstrainer>
      </When>
    </div>
  );
};
