import { formatDistanceToNowStrict } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { When } from 'react-project/Util/When';
import { CountriesListArr } from 'shared/CSharedConstants';
import styles from './BottomBar.module.scss';

const SessionListItemComponent = (props) => {
  const countryAttr = props.country.name
    ? CountriesListArr.find((attrs) => attrs.name === props.country.name)
    : null;
  const countryClass = `flag flag-${countryAttr ? countryAttr.iso2.toLowerCase() : ''}`;

  const rowClicked = (event) => {
    props.toggleSelected(event);
  };

  const onInputChanged = (e) => {
    // The event is captured by rowClicked method
  };

  const person = props.email || `User ${props.id.split('-')[0]}`;

  return (
    <div onClick={rowClicked} className={styles.ListItem}>
      <div className={styles.ListItemNameWrapper}>
        <When condition={props.selectable}>
          <input type="checkbox" checked={props.isSelected} onChange={onInputChanged} />
        </When>
        <span className={countryClass}></span>
        <span className={styles.ColumnPadding}>{person}</span>
      </div>
      <div>{formatDistanceToNowStrict(props.createdAt)} ago</div>
    </div>
  );
};

SessionListItemComponent.propTypes = {
  selectable: PropTypes.bool,
  toggleSelected: PropTypes.func,
  isSelected: PropTypes.bool,
  country: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
  createdAt: PropTypes.instanceOf(Date).isRequired,
};

export const SessionListItem = SessionListItemComponent;
