export default class CommandRemoveStepFromTrends {
  constructor(widget, data) {
    this.widget = widget;
    this.data = data;

    // formatting fix
    this.data.ID = data.id;

    this.isExecuted = false;
  }

  execute() {
    if (!this.isExecuted) {
      this.isExecuted = true;

      this.widget.removeStep(this.data);
      this.widget.onStepsChanged();

      window.app.needsRendering();
    }
  }

  revert() {
    if (this.isExecuted) {
      window.app.needsRendering();

      if (this.widget.addStep(this.data)) {
        this.widget.onStepsChanged();
        this.widget.hasNewSteps = true;
      }
    }
  }
}
