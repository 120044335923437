import * as PIXI from 'pixi.js';
import BaseContainer from 'pixi-project/base/containers/BaseContainer';
import MultiStyleText from 'pixi-project/view/objects/Text/MultiStyleText';
import { DEFAULT_TEXT_VALUE } from 'shared/CSharedConstants';
import { EElementCategories } from 'shared/CSharedCategories';
import Styles from '../../Styles';
import BaseSignals from 'pixi-project/base/signals/BaseSignals';
import { DOUBLE_CLICK_TIMEOUT } from 'pixi-project/stage/InputEventController';
import TextEditable from './TextEditable';
import AppSignals from 'pixi-project/signals/AppSignals';
import { PR_EVENT_EDIT_MODE } from 'shared/CSharedEvents';
import CommandMove from 'pixi-project/base/command-system/commands/CommandMove';
import CommandTextWrap from 'pixi-project/base/command-system/commands/CommandTextWrap';
import MainStorage from 'pixi-project/core/MainStorage';

// DELEGATE
// -onBeforeTextLabelFinishedEditing(this,oldText)
// -onTextLabelFinishedEditing(this,oldText,newText)
// -onTextLabelEdited(this)
// -onTextLineHeightChanged(this)

export default class TextLabel extends BaseContainer {
  constructor(texture, eventHandlers, id) {
    super(eventHandlers, id);

    this.delegate = null;
    this.isClicked = false;
    this.text = texture || DEFAULT_TEXT_VALUE;
    this.isText = true;
    this.category = EElementCategories.TEXT;
    this.removeChild(this.footer);
    this.editable = new TextEditable(this);
    this.hasPlaceholder = false;
    this.previousValue = '';
    this.lastKnownHeight = -1;
    this.wordWrapWidth = 0;

    // Information about how handles affect the width/height when dragged
    this._handlesSizeInfo = [
      { x: -1, y: -1 }, // left-top
      { x: 1, y: -1 }, // right-top
      { x: 1, y: 1 }, // right-bottom
      { x: -1, y: 1 }, // left-bottom
      { x: 1, y: 0 }, // left
      { x: -1, y: 0 }, // right
    ];

    // Information about how handles affect the relative x/y position when dragged
    this._handlesMoveInfo = [
      { x: 1, y: 1 }, // left-top
      { x: 0, y: 1 }, // right-top
      { x: 0, y: 0 }, // right-bottom
      { x: 1, y: 0 }, // left-bottom
      { x: 1, y: 0 }, // left
      { x: 0, y: 0 }, // right
    ];
  }

  init() {
    const resolution = window.app.renderer.resolution;
    this.isEditMode = false;
    this.isShowTool = true;
    this.isResizable = true;
    this.showCornerHandles = false; // Resize handles
    this.showHorizontalEdgeHandles = true; // Resize handles
    this.lastClickPoint = new PIXI.Point();
    this.lastClickTimestamp = 0;
    this.handleIndex = 0;
    this.startHandlePosition = new PIXI.Point();
    this.textStartPosition = new PIXI.Point();
    this.textStartWidth = 0;

    this.titleDisplay.visible = false;

    this.content = new PIXI.Graphics();
    this.content.alpha = 0; // make it transparent so that it remains interactive
    this.content.cursor = 'move';
    this.content.interactive = true;

    this.textLabel = new MultiStyleText('', Styles.MULTY_STYLE_LABEL);
    this.textLabel.resolution = resolution * 2;
    this.addChild(this.textLabel);
    this.draw(this.text);

    // Add the content above the text , so that it catches the pointer first
    this.addChild(this.content);

    AppSignals.setOutEditMode.add(this.outEditMode, this);
    AppSignals.viewportClicked.add(this.outEditMode, this);

    this.footer.removeFromParent();
    this.footer = null;
  }

  setText(text) {
    this.textLabel.text = text || '';
    this.onTextUpdated();
  }

  getText() {
    return this.textLabel.text;
  }

  getState() {
    super.getState();
    this.stateData.text = this.textLabel.text;
    this.stateData.hyperlink = this.getHyperlink();
    this.stateData.textStyle = this.getStyle();
    this.stateData.wordWrapWidth = this.wordWrapWidth;
    this.stateData.resolution = this.textLabel.resolution;
    return this.stateData;
  }

  move() {
    BaseSignals.moveObject.dispatch(this);
  }

  addEvents() {
    super.addEvents();
    this.content.on('pointerup', this.onClick.bind(this));
  }

  onClick(e) {
    let timeNow = new Date().getTime();
    let timeSinceLastClick = timeNow - this.lastClickTimestamp;
    this.lastClickTimestamp = timeNow;

    let currentPoint = e.data.global.clone();
    let hasMoved =
      currentPoint.x !== this.lastClickPoint.x || currentPoint.y !== this.lastClickPoint.y;
    this.lastClickPoint = currentPoint;

    if (timeSinceLastClick < DOUBLE_CLICK_TIMEOUT && !hasMoved) {
      this.onDoubleClick(currentPoint);
    }
  }

  onDoubleClick(e) {
    if (MainStorage.getCanvasPermissions().isReadonlyAccess) {
      return;
    }
    this.enterEditMode();
  }

  highlightElement(highlight = true) {
    if (highlight) {
      this.updateAttachPoints();
    }
    super.highlightElement(highlight);
    //TODO highlighting will not work with a text that can change color
    // this.content.setInputStyle('color', highlight ? COLOR_SELECTION : COLOR_LABEL_DEFAULT);
  }

  outEditMode() {
    if (this.isEditMode) {
      const oldText = this.getText();
      const newText = this.getFinalText();
      if (this.delegate && this.delegate.onBeforeTextLabelFinishedEditing) {
        this.delegate.onBeforeTextLabelFinishedEditing(this, oldText, newText);
      }

      this.isEditMode = false;
      this.editable.hide();

      this.setTransferText(newText);
      this.updateBounds();
      this.updateContentRectangle();

      AppSignals.setTextEditMode.dispatch(false);
      window.app.needsRendering();

      if (this.delegate && this.delegate.onTextLabelFinishedEditing) {
        this.delegate.onTextLabelFinishedEditing(this, oldText, newText);
      }
    }
  }

  enterEditMode() {
    if (!this.isLocked) {
      AppSignals.setTextEditMode.dispatch(true);
      this.isEditMode = true;

      this.editable.show(this);
      if (this.textLabel.text === DEFAULT_TEXT_VALUE) {
        this.editable.setText('');
        this.handlePlaceholder();
      }
      window.app.needsRendering();
    }
  }

  transferText(useEndingNewLine = false) {
    // When an empty new line is added
    const finalText = this.getFinalText(useEndingNewLine);
    this.setTransferText(finalText);
  }

  setTransferText(finalText) {
    this.textLabel.text = finalText;

    if (this.textLabel.height !== this.lastKnownHeight) {
      this.lastKnownHeight = this.textLabel.height;
      if (this.delegate.onTextLineHeightChanged) {
        this.delegate.onTextLineHeightChanged(this);
      }
    }
  }

  getFinalText(useEndingNewLine = false) {
    let text = this.editable.getText();
    if (text === '') {
      text = DEFAULT_TEXT_VALUE;
    }

    // When an empty new line is added
    return useEndingNewLine
      ? this.editable.toPixiTextWithNewLine(text)
      : this.editable.toPixiText(text);
  }

  set isEditMode(value) {
    this._isEditMode = value;
    if (value) {
      this.highlightElement(false);
    }
    setTimeout(() => {
      const event = new CustomEvent(PR_EVENT_EDIT_MODE, { detail: { value } });
      document.dispatchEvent(event);
      window.app.needsRendering();
    }, 0);
  }

  get isEditMode() {
    return this._isEditMode;
  }

  // DELEGATES Handlers
  onTEsc() {
    this.outEditMode();
  }

  onCaretChange() {
    this.handlePlaceholder();
  }

  onTextChange(isLineCountChanged) {
    if (this.delegate && this.delegate.onTextLabelEdited) {
      this.delegate.onTextLabelEdited(this, isLineCountChanged);
    }
  }

  handlePlaceholder() {
    const text = this.editable.getText();
    if (!text) {
      this.hasPlaceholder = true;
    } else {
      this.hasPlaceholder = false;
    }

    if (this.hasPlaceholder) {
      this.textLabel.text = `${DEFAULT_TEXT_VALUE}`;
      this.visible = true;
      this.updateMinWidth();
    } else {
      this.visible = false;
      this.editable.element.style.minWidth = '';
    }

    window.app.needsRendering();
  }

  updateMinWidth() {
    let bounds = this.textLabel.getBounds();
    let scale = window.app.viewport.scaled;
    let minWidth = (bounds.width * window.app.scaleManager.aspectRatio * 1) / scale + 8.5;
    this.editable.element.style.minWidth = minWidth + 'px';
  }

  onDestroy() {
    super.onDestroy();
    AppSignals.setOutEditMode.remove(this.outEditMode, this);
    AppSignals.viewportClicked.remove(this.outEditMode, this);
  }

  addHyperlink(url) {
    if (!this.isEditMode) {
      this.editable.setText(this.textLabel.text);
    }

    if (url) {
      this.editable.setAnchor(url);
    } else {
      this.editable.removeAnchor();
    }

    if (!this.isEditMode) {
      this.transferText();
    }
  }

  getHyperlink() {
    if (!this.isEditMode) {
      this.editable.setText(this.textLabel.text);
    }
    const anchor = this.editable.getAnchor();
    return anchor ? anchor.getAttribute('href') : null;
  }

  updateStyle(key, value, callUpdate = true) {
    window.app.needsRendering();

    if (key === 'fontSize') {
      const fontProperties = PIXI.TextMetrics.measureFont(
        value + ' ' + this.editable.getStyle('fontFamily'),
      );
      const lineHeight = fontProperties.fontSize;
      this.editable.setStyle('lineHeight', lineHeight + 'px');
    } else if (key === 'color') {
      this.editable.element.style[key] = value;
      this.setTextStyle('fill', value);
      return;
    } else if (key === 'align') {
      this.editable.element.style.textAlign = value;
      this.setTextStyle(key, value);
      return;
    }

    this.editable.element.style[key] = value;
    this.setTextStyle(key, value);

    if (callUpdate) {
      this.onTextUpdated();
    }
  }

  setTextStyle(key, value) {
    this.textLabel.textStyles.default[key] = value;
    this.textLabel.style[key] = value;
    this.textLabel.dirty = true;
  }

  setStyles(styles) {
    Object.keys(styles).forEach((styleKey) => {
      const styleValue = styles[styleKey];
      this.updateStyle(styleKey, styleValue, false);
    });
    this.onTextUpdated();
  }

  getStyle() {
    return {
      fontFamily: this.textLabel.textStyles.default.fontFamily,
      color: this.textLabel.textStyles.default.fill,
      fontSize: this.textLabel.textStyles.default.fontSize,
      align: this.textLabel.textStyles.default.align,
      fontStyle: this.textLabel.textStyles.default.fontStyle,
      fontWeight: this.textLabel.textStyles.default.fontWeight,
    };
  }

  onTextUpdated() {
    // When a text is being updated
    // That means his bounds are shifted
    this.updateBounds();
    this.updateContentRectangle();
  }

  updateBounds() {
    this.textLabel.updateText();
    // Update the location of the attach points on the bounds
    this.updateAttachPoints();
  }

  processAnalyticsData() {
    // This is an overwrite , no data is needed to be processed for the text label
  }

  onResizeHandleDown(e) {
    this.setResizeHandleDown(e.currentTarget);
  }

  onResizeHandleMove(e, isShift = false) {
    this.setResizeHandleMove(e, isShift);
  }

  onResizeHandleUp(e) {
    const wordWrapCommand = new CommandTextWrap(this, this.textStartWidth, this.wordWrapWidth);
    const moveCommand = new CommandMove(this, this.textStartPosition);

    AppSignals.commandCreated.dispatch([wordWrapCommand, moveCommand], true, true);
  }

  setResizeHandleDown(handle) {
    this.handleIndex = handle.index;
    const scale = 1 / window.app.viewport.scaled;
    const gp = handle.getGlobalPosition();
    gp.x *= scale;
    gp.y *= scale;

    this.startHandlePosition.copyFrom(gp);
    this.textStartPosition.copyFrom(this.position);
    this.textStartWidth = this.wordWrapWidth ? this.wordWrapWidth : this.textLabel.width;
  }

  setResizeHandleMove(p, isShift = false) {
    const scale = 1 / window.app.viewport.scaled;
    const currentPoint = new PIXI.Point().copyFrom(p);
    currentPoint.x *= scale;
    currentPoint.y *= scale;

    this.handleMoved(this.startHandlePosition, currentPoint, this.handleIndex, isShift);
  }

  handleMoved(startHandlePosition, point, index, isShift) {
    const moveMultiplicator = this._handlesMoveInfo[index];
    const sizeMultiplicator = this._handlesSizeInfo[index];

    const dispositionX = point.x - startHandlePosition.x;
    const dispositionY = point.y - startHandlePosition.y;

    const width = dispositionX * sizeMultiplicator.x;
    const height = dispositionY * sizeMultiplicator.y;
    const x = dispositionX * moveMultiplicator.x;
    const y = dispositionY * moveMultiplicator.y;

    this.position.x = this.textStartPosition.x + x;
    this.wordWrapWidth = this.textStartWidth - width;

    // this will also reset the wordWrap settings
    if (this.wordWrapWidth < 0) {
      this.wordWrapWidth = 0;
    }

    this.updateContentRectangle();
  }

  updateContentRectangle() {
    // Set the word wrap width
    const wordWrap = this.wordWrapWidth ? true : false;
    this.textLabel.setTagStyle('default', { wordWrap, wordWrapWidth: this.wordWrapWidth });

    this.content.clear();
    this.content.beginFill(0x650a5a);
    this.content.drawRect(
      0,
      0,
      this.wordWrapWidth ? this.wordWrapWidth : this.textLabel.width,
      this.textLabel.height,
    );
    this.content.endFill();
  }

  updateUsableResolution(width, height) {
    const resolution = this.getUsableResolution(width, height);
    this.textLabel.resolution = resolution;

    //TODO find a better way to update the resolution
    // force the internal text canvas to be updated
  }

  getUsableResolution(width, height) {
    const maxResolution = window.app.renderer.resolution * 2;
    const resolutionX = this._getUsableResolution(width, maxResolution);
    const resolutionY = this._getUsableResolution(height, maxResolution);
    return Math.min(resolutionX, resolutionY);
  }

  _getUsableResolution(length, maxResolution) {
    if (length < 2048) {
      return maxResolution;
    } else if (length < 4096) {
      return maxResolution / 2;
    }
    return 1;
  }
}
