import * as PIXI from 'pixi.js';
// DELEGATE

export const TABLE_SORTING_ASC = 'asc';
export const TABLE_SORTING_DESC = 'desc';
export const TABLE_SORTING_NONE = 'none';

// DELEGATE
// onSortLabelClick(label: TableViewSortLabel , sorting)

export default class TableViewSortLabel extends PIXI.Container {
  constructor(labelTitle, labelStyle, labelTintColor = 0x000000, delegate = null) {
    super();

    this.delegate = delegate;
    this.sortingState = null;
    this.sortingIndex = 0;

    this.label = new PIXI.BitmapText(labelTitle, labelStyle);
    this.label.tint = labelTintColor;
    this.addChild(this.label);

    this.sortIcon = PIXI.Sprite.from(PIXI.utils.TextureCache['compare-down']);
    this.sortIcon.anchor.set(0.5);
    this.sortIcon.position.set(this.label.width + 10, this.label.height / 2);
    this.sortIcon.tint = 0x999999;
    this.addChild(this.sortIcon);

    const padding = 10;

    this.interactive = true;
    this.buttonMode = true;
    this.hitArea = new PIXI.Rectangle(
      -padding,
      -padding,
      this.label.width + 10 + this.sortIcon.width + padding * 2,
      this.label.height + padding * 2,
    );
    this.on('pointerdown', this.onLabelClick.bind(this));

    this.setSorting(TABLE_SORTING_NONE);
  }

  setSorting(state) {
    this.sortingState = state;
    switch (state) {
      case TABLE_SORTING_ASC:
        this.sortIcon.texture = PIXI.utils.TextureCache['compare-up'];
        break;
      case TABLE_SORTING_DESC:
        this.sortIcon.texture = PIXI.utils.TextureCache['compare-down'];
        break;
      case TABLE_SORTING_NONE:
        this.sortIcon.texture = PIXI.utils.TextureCache['compare-in-range'];
        break;
    }
  }

  reset() {
    this.setSorting(TABLE_SORTING_NONE);
  }

  onLabelClick(e) {
    if (this.sortingState !== TABLE_SORTING_ASC) {
      this.setSorting(TABLE_SORTING_ASC);
    } else {
      this.setSorting(TABLE_SORTING_DESC);
    }

    e.stopPropagation();
    if (this.delegate && this.delegate.onSortLabelClick) {
      this.delegate.onSortLabelClick(this, this.sortingState);
    }
  }
}
