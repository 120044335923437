import React from 'react';
import { VerticalMenu } from 'react-project/Menus/Components/VerticalMenu/VerticalMenu';
import { MenuImageButton } from 'react-project/Menus/Components/MenuItems/MenuImageButton';
import { UILock } from 'react-project/components/uilock/UILock';

export const VerticalSubMenu = ({ sectionStyle, onButtonClicked, buttons, lockedButtons }) => {
  const [activeItem, setActiveItem] = React.useState(null);

  const onClick = (e, reference, id) => {
    setActiveItem(id);
    onButtonClicked(e, reference, id);
  };

  return (
    <VerticalMenu sectionStyle={sectionStyle}>
      {buttons.map((btnData) => {
        if (!btnData) return null;

        const isLocked = (lockedButtons && lockedButtons.includes(btnData.id)) || false;

        return (
          <UILock key={btnData.id} isLocked={isLocked}>
            <MenuImageButton
              key={btnData.id}
              id={btnData.id}
              image={btnData.image}
              title={btnData.title}
              isActive={activeItem === btnData.id}
              onClick={onClick}
            />
          </UILock>
        );
      })}
    </VerticalMenu>
  );
};
