import { EElementTypes } from 'shared/CSharedCategories';

export const STEP_BORDER_COLOR_TAB_NAME = 'borderColor';
export const STEP_FILL_COLOR_TAB_NAME = 'fillColor';

export const STEP_SETTINGS_TAB_NAME = 'style'; // This needs to match the label text , for more info search for "In order to toggle the menu buttton"

export const STEP_TRACKING_TAB_NAME = 'tracking'; // This needs to match the label text , for more info search for "In order to toggle the menu buttton"

export const STEP_PEOPLE_TAB_NAME = 'people';

export const STEP_ADDITIONAL_TAB_NAME = 'additional';

export const STEP_GOAL = 'goals'; // This needs to match the label text , for more info search for "In order to toggle the menu buttton"

export const STEP_NOTES = 'notes';

export const STEP_CHECKLIST = 'checklist';

export const DEFAULT_STEP_STATE = 'none';
export const DEFAULT_DEVICES_STATE = 'all devices';

export const STEP_PHOTO = 'photo';

export const ItemsWithLabelCustomization = [
  EElementTypes.PAGE,
  EElementTypes.SOURCE,
  EElementTypes.EVENT,
];
