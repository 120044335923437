import cx from 'classnames';
import React from 'react';

export function LoadingOverlay({ active, children }) {
  return (
    <div
      className={cx(
        active
          ? 'absolute cursor-wait h-[calc(100%-49px)] w-full bg-gray-300 opacity-50 z-20'
          : 'hidden',
      )}
    >
      {children}
    </div>
  );
}

export default LoadingOverlay;
