export default class Migration2 {
  constructor() {
    this.version = 2;
  }

  migrate(data) {
    // Do nothing , as the transfromation 1.0 to 2.0 is done in a different place
    // TODO investigate if we can bring that transformation here
    return data;
  }
}
