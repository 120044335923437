import Signals from 'pixi-project/signals/AppSignals';
import { PR_EVENT_FUNNEL_CHANGED } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import SharedElementHelpers from 'shared/SharedElementHelpers';

export default class CommandLock {
  constructor(object, lockedStatus) {
    this.object = object;
    this.oldLockStatus = this.object.isLocked;
    this.newLockStatus = lockedStatus;

    this.isExecuted = false;
  }

  execute() {
    if (!this.isExecuted) {
      this.object.setLockedStatus(this.newLockStatus);
      if (SharedElementHelpers.IsReport(this.object)) {
        this.object.changeButtonsState(this.newLockStatus);
      }

      Signals.elementChanged.dispatch();
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);

      this.isExecuted = true;
    }
  }

  revert() {
    if (this.isExecuted) {
      this.object.setLockedStatus(this.oldLockStatus);
      if (SharedElementHelpers.IsReport(this.object)) {
        this.object.changeButtonsState(this.oldLockStatus);
      }
      Signals.elementChanged.dispatch();
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);

      this.isExecuted = false;
    }
  }
}
