import * as PIXI from 'pixi.js';
import Facade from 'pixi-project/Facade';
import {
  PR_EVENT_ANALYTICS_NEEDS_REFRESH,
  PR_EVENT_FUNNEL_CHANGED,
  RP_EVENT_CURSOR_TYPE,
  RP_EVENT_EDIT_OBJECT,
  RP_EVENT_GET_DATA,
  RP_EVENT_LOAD_REQUEST,
  RP_EVENT_ANALYTICS_UPDATED,
  RP_EVENT_OBJECT_DRAG_START,
  PR_EVENT_QUICK_PANNING_MODE_CHANGED,
  RP_ANALYTICS_PWP_TOOL_ACTIVATED,
  PR_EVENT_STEP_FOCUS_CHANGED,
  PR_EVENT_REPORT_BLOCKED_WARNING_POPUP_OPENED,
  PR_NOTES_LOADED,
  RP_STEP_PICKER_ACTIVATED,
  RP_EVENT_TRENDS_STEP_PICKED,
  PR_EVENT_FUNNEL_LOADED,
} from 'shared/CSharedEvents';
import {
  ANALYTICS_STATUS_LOADING,
  ACTIVE_STATE_DRAW,
  CANVAS_VERSION,
  SELECTION_UPDATE_SOURCE_ANALYTICS,
} from 'shared/CSharedConstants';
import { EElementCategories, EElementTypes } from 'shared/CSharedCategories';
import MainStorage from 'pixi-project/core/MainStorage';
import Mesh from 'pixi-project/view/Mesh';
import SharedElementHelpers from 'shared/SharedElementHelpers';
import { commonSendEventFunction, isMouseWheelButton, isRightButton } from 'shared/CSharedMethods';
import Signals from 'pixi-project/signals/AppSignals';
import SelectionManager from './selection/SelectionManager';
import CommandManager from 'pixi-project/base/command-system/CommandManager';
import SceneManager from 'pixi-project/stage/SceneManager';
import CopyPasteUtility from 'pixi-project/stage/CopyPasteUtility';
import ZoomUtility from 'pixi-project/stage/ZoomUtility';
import CommandBatchHighlighted from 'pixi-project/base/command-system/commands/CommandBatchHighlighted';
import CommandScale from 'pixi-project/base/command-system/commands/CommandScale';
import ViewportAutoPan from 'pixi-project/stage/ViewportAutoPan';
import AlignmentGuides from './guides/AlignmentGuides';
import InputEventController from 'pixi-project/stage/InputEventController';
import AppEventController from 'pixi-project/stage/AppEventController';
import AppSignals from 'pixi-project/signals/AppSignals';
import { CControlPointTypes } from 'pixi-project/base/containers/CContainerConstants';
import CommandAddToReportView from 'pixi-project/base/command-system/commands/CommandAddToReportView';
import CommandRemoveFromReportView from 'pixi-project/base/command-system/commands/CommandRemoveFromReportView';
import CommandBatchMove from 'pixi-project/base/command-system/commands/CommandBatchMove';
import { isEmpty, uniqBy } from 'lodash';
import { getAuthToken } from 'react-project/Util/AuthCookie';
import { getUpdatedPhotoPath } from 'react-project/Util/urlPath';
import VersionMigration from 'pixi-project/base/migrations/VersionMigration';
import ConnectionDisplay from './objects/anaytics-display/ConnectionDisplay';
const CURSOR_NO_DROP = 'no-drop';

export default class PlaneContainer extends PIXI.Container {
  constructor() {
    super();
    this.dataReceivedPromise = new Promise((resolve) => {
      this.dataReceivedPromiseResolve = resolve;
    });
    this.initializationPromise = new Promise((resolve) => {
      this.initializationPromiseResolve = resolve;
    });
    this.funnelDrawnPromise = new Promise((resolve) => {
      this.funnelDrawnPromiseResolve = resolve;
    });
    Signals.assetsLoadingComplete.add(this.onAssetsLoadingComplete.bind(this), this);

    this.migration = new VersionMigration(CANVAS_VERSION);

    this.init();

    this.inputEventController.attachListeners();

    this.setCursorMode(EElementCategories.CLICKING);

    AppSignals.resizePointPressed.add(this.onResizePointPressed, this);
    AppSignals.resizePointReleased.add(this.onResizePointReleased, this);
    AppSignals.elementChanged.add(this.onElementChanged, this);

    document.addEventListener(RP_EVENT_CURSOR_TYPE, this.onCursorTypeChanged.bind(this), false);
    document.addEventListener(RP_EVENT_GET_DATA, this.getIDSData.bind(this), true);
    document.addEventListener(RP_EVENT_LOAD_REQUEST, this.onDataReceived.bind(this), false);
    document.addEventListener(RP_EVENT_EDIT_OBJECT, this.onEditObject.bind(this), false);
    document.addEventListener(RP_EVENT_ANALYTICS_UPDATED, this.onUpdateAnalytics.bind(this), false);
    document.addEventListener(
      RP_EVENT_OBJECT_DRAG_START,
      this.onToolbarDragStarted.bind(this),
      false,
    );
    document.addEventListener(
      RP_ANALYTICS_PWP_TOOL_ACTIVATED,
      this.onAnalyticsPWPActivated.bind(this),
      false,
    );
    document.addEventListener(RP_STEP_PICKER_ACTIVATED, this.onStepPickerActivated.bind(this));
  }

  init() {
    this.interactive = false;

    this.isToolbarDragging = false;
    this.toolbarDragData = null;
    this.cursorTimerID = null;

    this.hasElementChanged = false;
    this.isPWPActive = false;
    this.isTWPActive = false;
    this.trendsWidgetId = null;
    this.PWPFilterType = null;
    this.isResizePointPressed = false;

    this.meshContainer = new PIXI.Container();
    this.iconsContainer = new PIXI.Container();
    this.selectionToolContainer = new PIXI.Container();
    this.multipleSelectionContainer = new PIXI.Container();
    this.focusSelectionContainer = new PIXI.Container();
    this.attachmentSelectionContainer = new PIXI.Container();
    this.jointsContainer = new PIXI.Container();

    this.meshContainer.interactive = true;
    this.iconsContainer.interactive = true;
    this.selectionToolContainer.interactive = true;
    this.multipleSelectionContainer.interactive = true;
    this.focusSelectionContainer.interactive = false;
    this.attachmentSelectionContainer.interactive = false;
    this.jointsContainer.interactive = true;

    this.objectStartingScale = new PIXI.Point();
    this.objectStartingPosition = new PIXI.Point();

    window.app.stage.addChildAt(this.meshContainer, 0);
    this.addChild(this.iconsContainer);
    this.addChild(this.jointsContainer);

    this.mesh = new Mesh();
    this.mesh.interactive = true;
    this.meshContainer.addChild(this.mesh);

    window.app.stage.addChild(this.selectionToolContainer);
    window.app.stage.addChild(this.multipleSelectionContainer);
    window.app.stage.addChild(this.focusSelectionContainer);
    window.app.stage.addChild(this.attachmentSelectionContainer);

    //TODO consider the scene manager and selection manager to talk through a common interface
    this.sceneManager = new SceneManager(this);
    this.selectionManager = new SelectionManager(this, this.sceneManager);
    this.sceneManager.selectionManager = this.selectionManager;
    this.alignmentGuides = new AlignmentGuides(window.app.viewport);

    this.selectionToolContainer.addChild(this.selectionManager.single);
    this.multipleSelectionContainer.addChild(this.selectionManager.multi);
    this.focusSelectionContainer.addChild(this.selectionManager.focusSelection);
    this.attachmentSelectionContainer.addChild(this.selectionManager.attachmentSelection);
    window.app.stage.addChild(this.alignmentGuides);

    this.commandManager = new CommandManager();
    this.sceneManager.commandManager = this.commandManager;

    this.copyPasteUtility = new CopyPasteUtility(this.sceneManager, this.selectionManager);

    this.zoomUtility = new ZoomUtility(this.sceneManager.iconsContainer, this);

    this.viewportAutoPan = new ViewportAutoPan(this);

    this.inputEventController = new InputEventController(
      this.sceneManager,
      this.selectionManager,
      this.commandManager,
      this.copyPasteUtility,
      this.zoomUtility,
      this,
    );

    this.appEventController = new AppEventController(
      this.sceneManager,
      this.selectionManager,
      this.commandManager,
      this.copyPasteUtility,
      this,
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////// TOUCH EVENT HANDLERS

  onElementPointerDown(e) {
    const element = this.selectionManager.getClickedElement(e);

    // Ignore clicking on scene elements when the scene is locked
    // but allow it if we are in PWP mode (PWP mode lockes part of the scene)
    // also allow it in the case of clicking on report view ,
    // as that means we are trying to create an element inside of it
    if (
      this.sceneManager.isSceneLocked &&
      !this.isPWPActive &&
      !this.isTWPActive && // Trends Widget Picker
      !SharedElementHelpers.IsReport(element)
    ) {
      e.data.originalEvent.stopPropagation();
      return;
    }

    // Ignore clicking on non step elements when PWP is active
    if (this.isPWPActive && !SharedElementHelpers.IsStep(element)) {
      e.stopPropagation();
      return;
    }

    // Ignore clicking on non step elements when Trends Widget Picker is active
    if (this.isTWPActive && !SharedElementHelpers.IsStep(element)) {
      e.stopPropagation();
      return;
    }

    // Ignore locked elements
    if (element.isLocked) {
      e.stopPropagation();
      return;
    }

    // Ignore clicking with the wheel button
    if (isRightButton(e) && !isMouseWheelButton(e)) {
      this.selectionManager.onElementRightMouseDown(e);
      return;
    }

    if (!isMouseWheelButton(e)) {
      // Reset CTRL & SHIFT state when clicking
      if (e.data && e.data.originalEvent && e.data.originalEvent.ctrlKey === false) {
        this.inputEventController.isCtrlDown = false;
      }

      if (e.data && e.data.originalEvent && e.data.originalEvent.shiftKey === false) {
        this.inputEventController.isShiftDown = false;
      }

      const isMultiSelect =
        this.inputEventController.isShiftDown || this.inputEventController.isCtrlDown;
      this.selectionManager.onElementPointerDown(e, isMultiSelect);

      if (this.alignmentGuides.isActive) {
        this.setGuidesObjects();
      }
    }

    if (this.isPWPActive) {
      // Immediately deselect the element , it will cause the selection frame to
      // display in blue , signaling that the element is focused
      // and avoid the yellow frame that shows when an element is hovered.
      element.isHovered = false;
      element.isPointerDown = false;

      let selectionFrame = this.selectionManager.focusSelection.findFrameByElementId(element.id);
      let hasFocus = selectionFrame && selectionFrame.hasFilterType(this.PWPFilterType);

      // Note , the order is important here , please don't change it.
      let data = this.selectionManager.single.getSelectedElementData();
      this.selectionManager.clearSelection();
      this.selectionManager.hide();

      commonSendEventFunction(PR_EVENT_STEP_FOCUS_CHANGED, {
        step: data,
        filterType: this.PWPFilterType,
        hasFocus: hasFocus,
      });

      this.setStepFocused(element.id, !hasFocus);
      e.stopPropagation();
      // Call prevent default so that we can stop the click outside
      // component in React to close the PWP Tool
      e.data.originalEvent.preventDefault();
    }

    if (this.isTWPActive) {
      // Immediately deselect the element , it will cause the selection frame to
      // display in blue , signaling that the element is focused
      // and avoid the yellow frame that shows when an element is hovered.
      element.isHovered = false;
      element.isPointerDown = false;

      ///// insert code here

      let data = this.selectionManager.single.getSelectedElementData();
      this.selectionManager.clearSelection();
      this.selectionManager.hide();

      commonSendEventFunction(RP_EVENT_TRENDS_STEP_PICKED, {
        step: data,
        widgetId: this.trendsWidgetId,
      });

      // TODO add feedback to the user to know that the element was added to the widget
      console.log('Step added to widget , needs feedback to the user');

      e.stopPropagation();
      // Call prevent default so that we can stop the click outside
      // component in React to close the Twp Tool
      e.data.originalEvent.preventDefault();
    }

    const selectedElement = this.selectionManager.selectedElement;

    if (selectedElement) {
      AppSignals.setOutEditMode.dispatch();
    }

    element.onPointerDown(e);
  }

  onElementPointerMove(e) {
    if (this.isTextEditing()) {
      return;
    }

    this.selectionManager.onElementPointerMove(e);

    if (MainStorage.getCanvasPermissions().isReadonlyAccess) {
      return;
    }

    const element = this.selectionManager.getClickedElement(e);

    if (element.isPointerDown) {
      this.viewportAutoPan.isActive = true;

      const isSlideOverlaps = this.sceneManager.reportView
        ? this.sceneManager.reportView.isInsideSlide(element)
        : false;
      if (
        this.sceneManager.reportView &&
        isSlideOverlaps &&
        this.sceneManager.reportView.isLocked
      ) {
        if (element.cursor !== CURSOR_NO_DROP) {
          element.cursor = CURSOR_NO_DROP;
        }
      } else if (element.cursor === CURSOR_NO_DROP) {
        element.cursor = 'move';
      }
    }

    if (
      element.isPointerDown &&
      element.isSelectable &&
      this.selectionManager.hasSelectedElements()
    ) {
      this.showAlignmentGuides();
    }
  }

  onElementPointerUp(e) {
    if (this.cursorMode === EElementCategories.PANNING) {
      Facade.viewport.plugins.resume('drag');
    }

    this.viewportAutoPan.isActive = false;
    this.alignmentGuides.clear();

    // If we are clicking on an element whith a connetion line
    if (this.sceneManager.pointerJoint && !this.selectionManager.isShapeSelected) {
      this.onConnectionDroppedToElement(e);
      return;
    }

    if (this.sceneManager.movingConnection && !this.selectionManager.isShapeSelected) {
      this.sceneManager.onConnectionHeadDroppedToElement(e, this.sceneManager.movingConnection);
      return;
    }

    if (isRightButton(e) && !isMouseWheelButton(e)) {
      this.selectionManager.onElementRightMouseUp(e);
      return;
    }

    this.selectionManager.onElementPointerUp(e);
  }

  onElementPointerUpOutside(e) {
    this.viewportAutoPan.isActive = false;
    this.selectionManager.onElementPointerUpOutside(e);
    this.alignmentGuides.clear();
  }

  onElementPointerOver(e) {
    this.selectionManager.onElementPointerOver(
      e,
      this.isPWPActive,
      this.sceneManager.pointerJoint || this.sceneManager.movingConnection,
    );
  }

  onElementPointerOut(e) {
    this.selectionManager.onElementPointerOut(
      e,
      this.isPWPActive,
      this.sceneManager.pointerJoint || this.sceneManager.movingConnection,
    );
  }

  // DELEGATE handler AttachmentSelectionLayer
  onAttachmentPointUp(event, attachmentPoint) {
    this.setConnectionToElement(attachmentPoint.element, attachmentPoint);
  }

  onConnectionDroppedToElement(e) {
    // This is the handler when we drop a connection
    // to the blank area of an element
    const element = this.selectionManager.getClickedElement(e);

    this.selectionManager.attachmentSelection.removeFrameByElementId(element.id);
    this.selectionManager.attachmentSelection.drawAll();

    if (!SharedElementHelpers.IsWidget(element) && !SharedElementHelpers.IsPhoto(element)) {
      this.setConnectionToElement(element, { type: CControlPointTypes.FLOW });
    } else {
      this.sceneManager.removeCoordinatesJoint();
    }
    e.stopPropagation();
  }

  setConnectionToElement(element, attachmentPoint) {
    // If we are clicking on an element with a connection line
    if (this.sceneManager.pointerJoint && !this.selectionManager.isShapeSelected) {
      if (!SharedElementHelpers.IsWidget(element) && !SharedElementHelpers.IsPhoto(element)) {
        this.sceneManager.createConnectionsToElement(element, attachmentPoint);
      } else {
        this.sceneManager.removeCoordinatesJoint();
      }
    }

    if (this.sceneManager.movingConnection && !this.selectionManager.isShapeSelected) {
      this.sceneManager.onConnectionHeadRelocatedTo(element, attachmentPoint);
    }
  }

  // Delegate handler SelectionManager
  onSelectionHeadsDown(e) {
    this.sceneManager.onSelectionHeadsDown(e);
  }

  // Delegate handler SelectionManager
  onSelectionDrop(e, selectedObjects, inReport) {
    this.selectionManager.stopElementsMovement();
    let batch = new CommandBatchMove(this.selectionManager);
    const { report } = inReport;

    if (inReport && this.selectionManager.selectedObjects.includes(inReport.report)) {
      // This will make sure that we can include new objects if the report view is
      // dropped over some new items
      const allObjectsInReportArea = this.sceneManager.markReportObjects();
      const objectsOriginalyInside = this.sceneManager.getReport().getAllObjects();

      // mark new objects that need to be added
      // All objects that need to be added , if they where not there previously
      const newReportObjects = allObjectsInReportArea.filter(function (el) {
        return !objectsOriginalyInside.includes(el);
      });

      // Add to report view batch command
      if (newReportObjects.length) {
        const addToReportCommand = new CommandAddToReportView(newReportObjects, report);
        batch.add(addToReportCommand);
      }

      // move command for all the objects
      // it important to be executed after adding the objects to the report view
      if (this.selectionManager.startingPositions.length > 0 && selectedObjects.length > 0) {
        const batch2 = this.selectionManager.createMovementCommands();
        for (let i = 0; i < batch2.commands.length; i++) {
          const bc = batch2.commands[i];
          batch.add(bc);
        }
        e.stopPropagation();
      }

      if (!batch.isEmpty()) {
        AppSignals.commandCreated.dispatch(batch);
      }
      return;
    }

    // Create movement commands
    if (this.selectionManager.startingPositions.length > 0 && selectedObjects.length > 0) {
      batch = this.selectionManager.createMovementCommands();
      e.stopPropagation();
    }

    if (inReport && inReport.report) {
      if (inReport.objects.length > 0) {
        if (!inReport.report.isLocked) {
          // There can be a selection of objects that are moved
          // and some objects might partialy be inside the report view
          // and some be outside

          const objectsOriginalyInside = this.sceneManager.getReport().getAllObjects();
          const allObjectsInReportArea = this.sceneManager.markReportObjects();

          // All objects that need to be added , if they where not there previously
          const newReportObjects = allObjectsInReportArea.filter(function (el) {
            return !objectsOriginalyInside.includes(el);
          });

          if (newReportObjects.length) {
            const addToReportCommand = new CommandAddToReportView(newReportObjects, report);
            batch.add(addToReportCommand);
          }

          // All objects that are now outside the report view
          const objectsToRemove = this.selectionManager.selectedObjects.filter(
            (o) => !allObjectsInReportArea.some((i) => i.id === o.id),
          );

          if (objectsToRemove.length) {
            const removeCommand = new CommandRemoveFromReportView(objectsToRemove, report);
            batch.add(removeCommand);
          }

          // All objects that remain in the report view
          // Re add them again , as they might be changing to a different slide
          const existingReportObjects = this.selectionManager.selectedObjects.filter(function (el) {
            return objectsOriginalyInside.includes(el);
          });

          if (existingReportObjects.length) {
            const addToReportCommand = new CommandAddToReportView(existingReportObjects, report);
            batch.add(addToReportCommand);
          }
        } else {
          // Return objects to their previous place if they were tried to place in a locked report
          this.commandManager.undo();
          commonSendEventFunction(PR_EVENT_REPORT_BLOCKED_WARNING_POPUP_OPENED);
          this.selectionManager.hide();
        }
      } else {
        const removeCommand = new CommandRemoveFromReportView(
          this.selectionManager.selectedObjects,
          inReport.report,
        );
        batch.add(removeCommand);
      }
    }

    if (!batch.isEmpty()) {
      AppSignals.commandCreated.dispatch(batch);
    }
  }

  // Delegate handler - ViewportAutoPan
  onViewportAutoPan() {
    if (!this.isResizePointPressed) {
      this.selectionManager.moveElements();
      this.sceneManager.culling.updateObjects(this.selectionManager.selectedObjects);
    }
    this.selectionManager.updateFocused();

    // update the alignment guides
    if (this.alignmentGuides.isActive) {
      this.setGuidesObjects();
      this.showAlignmentGuides();
    }

    this.handleMeshPosition();
  }

  onConnectionDisplayDragged(e, connectionDisplay) {
    this.setGuidesObjects();
    this.showAlignmentGuides([connectionDisplay]);
  }

  showAlignmentGuides(objects = null) {
    if (this.selectionManager.hasSelectedElements()) {
      const bounds = this.selectionManager.getSelectionBoundingRect();
      objects = objects || this.selectionManager.getSelectedObjects();
      this.alignmentGuides.show(bounds, objects);

      if (this.alignmentGuides.didSnap) {
        // Repeat the process ( redo calculations ) to remove execess lines
        const bounds = this.selectionManager.getSelectionBoundingRect();
        this.alignmentGuides.show(bounds, objects);
        this.selectionManager.updateSelectionAfterElementMovement();

        // This is the case when a connection display have snapped to an object
        // we need to update it new values to where it have snapped
        if (objects.length === 1 && objects[0] instanceof ConnectionDisplay) {
          const connectionDisplay = objects[0];
          connectionDisplay.delegate.onConnectionDisplayMoving(null, connectionDisplay);
        }
      }
    }
  }

  setGuidesObjects() {
    const visibleObjects = this.getVisibleObjects();
    const objects = this.selectionManager.getSelectedObjects();
    for (let i = 0; i < objects.length; i++) {
      const element = objects[i];
      visibleObjects.removeElement(element);
    }
    this.alignmentGuides.setRelativeObjects(visibleObjects);
  }

  /**
   * Trigged when a resize point is pressed in the selection tool
   * @param {Event} e
   */
  onResizePointPressed(e) {
    this.sceneManager.setObjectsInteraction(false);
    this.isResizePointPressed = true;
    if (this.selectionManager.selectedElement) {
      let object = this.selectionManager.selectedElement;
      this.objectStartingScale.copyFrom(object.scale);
      this.objectStartingPosition.copyFrom(object.position);
    }
  }

  /**
   * Trigged when a resize point is released in the selection tool
   */
  onResizePointReleased() {
    this.sceneManager.revertObjectsInteraction();
    this.isResizePointPressed = false;
    if (this.selectionManager.selectedElement) {
      if (!SharedElementHelpers.IsShape(this.selectionManager.selectedElement)) {
        let object = this.selectionManager.selectedElement;
        let batch = new CommandBatchHighlighted(this.selectionManager);
        let command = new CommandScale(
          object,
          this.objectStartingScale,
          this.objectStartingPosition,
        );
        batch.add(command);
        this.commandManager.execute(batch);
      }
    }
  }

  // Delegate handler - SelectionManager
  onShapeResizeHandleDown(e, shape) {
    shape.opositeHandle = this.selectionManager.single.getOpositeHandle(e.currentTarget);
    shape.onResizeHandleDown(e);
  }

  // Delegate handler - SelectionManager
  onShapeResizeHandleMove(e, shape) {
    shape.onResizeHandleMove(e.data.global, this.inputEventController.isShiftDown);
    window.app.needsRendering();
  }

  // Delegate handler - SelectionManager
  onShapeResizeHandleUp(e, shape) {
    shape.onResizeHandleUp(e);
  }

  // Delegate handler - SelectionManager
  onTextResizeHandleDown(e, textLabel) {
    textLabel.opositeHandle = this.selectionManager.single.getOpositeHandle(e.currentTarget);
    textLabel.onResizeHandleDown(e);
  }

  // Delegate handler - SelectionManager
  onTextResizeHandleMove(e, textLabel) {
    textLabel.onResizeHandleMove(e.data.global, this.inputEventController.isShiftDown);
    window.app.needsRendering();
  }

  // Delegate handler - SelectionManager
  onTextResizeHandleUp(e, textLabel) {
    textLabel.onResizeHandleUp(e);
  }

  /////////////////////////////////////////////////////////////////////////////////////
  ////////////////// OTHER EVENT HANDLERS

  /**
   * Handler of the scene loading event. Needed to resolve async race between
   * mesh creation and loading of the funnel scene
   * @param e
   */
  onDataReceived(e) {
    this.dataReceivedPromiseResolve(e);

    // Do any migrations first
    const data = this.migration.migrate(e.detail.data);

    // There was a bug that was casuing objects to duplicate
    this.removeDuplicates(data);

    // if no version is detected , it will become 1.0
    // Do iterative support of versions , if an older version is detected
    // transform only to the next one , keep doing that until you reach
    // the final version

    const { objects, funnelConfiguration } = data;
    const { joints: connections } = data;

    this.sceneManager.onMetaDataLoaded(
      data.projectId,
      data.funnelId,
      data.source,
      data.version,
      data.notes,
      data.canvasChecklistData,
    );

    // It extracts the notes from the data
    // It updates the image url tokens with the latest token
    // and feeds the notes to redux
    this.handleNotesData(data);

    Promise.all([this.initializationPromise, this.dataReceivedPromise]).then(() => {
      this.sceneManager.onFunnelLoaded(objects, connections, funnelConfiguration);
      this.funnelDrawnPromiseResolve();
      this.appEventController.onFunnelLoaded();
      window.app.needsRendering();
      commonSendEventFunction(PR_EVENT_FUNNEL_LOADED);
    });
  }

  /**
   * Is triggered when all assets for PIXI side are loaded
   */
  onAssetsLoadingComplete() {
    this.mesh.onAssetsLoaded();
    this.initializationPromiseResolve();
  }

  /**
   * Handler for the case when we request analytics for a list of nodes
   * @param event
   */
  onUpdateAnalytics(event) {
    Promise.all([
      this.initializationPromise,
      this.meshReadyPromise,
      this.dataReceivedPromise,
      this.funnelDrawnPromise,
    ]).then(() => {
      if (!isEmpty(event.detail)) {
        this.setAnalyticsData(event.detail);
        this.processAnalyticsData();

        if (this.selectionManager.hasSelectedElements()) {
          // execute code to tell the selected connection that this is analytics update
          this.selectionManager.updateSelection(
            true,
            false,
            true,
            true,
            SELECTION_UPDATE_SOURCE_ANALYTICS,
          );
        }
        window.app.needsRendering();
      }
    });
  }

  /**
   * Handler for editing icon values
   * @param data
   */
  onEditObject(data) {
    try {
      const step = this.sceneManager.getElementById(data.detail.stepId);
      step.updateObject(data.detail);
      // push the title section to the back
      step.titleDisplay && step.titleDisplay.pushToBack();

      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
      window.app.needsRendering();
    } catch (e) {
      // todo Temp. Remove when we will fix the problem with async loading (elements not showing)
      console.log(`[onEditObject] There is no element with ID ${data.detail.stepId}`);
    }
  }

  onElementChanged() {
    // keep track if an element was updated
    this.hasElementChanged = true;

    // if the analytics data is not loading ,
    // the status can be set to ANALYTICS_NEEDS_REFRESH immediately.
    // But there is an edge case when we change funnel during the process of analytics update.
    // In this case we send status update to React and after loading finishes show the correct marker
    // on the 'Refresh analytics' button
    // In this case check onAnalyticsStatusChanged function.
    if (this.appEventController.analyticsLoadingStatus !== ANALYTICS_STATUS_LOADING) {
      commonSendEventFunction(PR_EVENT_ANALYTICS_NEEDS_REFRESH);
      this.hasElementChanged = false;
    }
  }

  onToolbarDragStarted(e) {
    this.isToolbarDragging = true;
    this.toolbarDragData = e.detail;
    //TODO add a custom icon for dragging objects into the canvas
    this.setViewportCursor('grabbing');
  }

  /**
   * Event handler when the cursor is changed
   * @param {Data} event
   */

  onCursorTypeChanged(event) {
    this.setCursorMode(event.detail.category);
  }

  onZoomLevelChanged(zoomLevel) {
    const shouldCache = zoomLevel < 1.2 ? true : false;
    this.sceneManager.setCachingForElements(this.sceneManager.joints, shouldCache);
    this.sceneManager.setCachingForElements(this.sceneManager.objects, shouldCache);

    const visibility = zoomLevel > 0.3 ? true : false;
    this.sceneManager.setDropShadows(this.sceneManager.joints, visibility);
    this.sceneManager.setDropShadows(this.sceneManager.objects, visibility);
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////// ACTION METHODS - methods that perform some action

  setAnalyticsData(data) {
    if (data) {
      this.setAnalyticsDataToObjects(data, this.sceneManager.objects);
      this.setAnalyticsDataToObjects(data, this.sceneManager.joints);

      // tell the goal widgets about it
      for (let i = 0; i < this.sceneManager.objects.length; i++) {
        const object = this.sceneManager.objects[i];
        if (
          (SharedElementHelpers.IsWidget(object) && object.type === EElementTypes.WIDGET_GOALS) ||
          object.type === EElementTypes.WIDGET_FORECASTING
        ) {
          object.setWidgetData(data);
        }
      }

      this.sceneManager.onAnalyticsDataReceived(data);
    } else {
      console.warn('Invalid analytics data');
    }
  }

  setAnalyticsDataToObjects(data, objects) {
    for (let i = 0; i < objects.length; i++) {
      const object = objects[i];
      const objectData = data[object.id];
      object.onAnalyticsDataReceived(objectData);
    }
  }

  processAnalyticsData() {
    this.processAnalyticsDataToObjects(this.sceneManager.objects);
    this.processAnalyticsDataToObjects(this.sceneManager.joints);
    this.selectionManager.updateFocused();
  }

  processAnalyticsDataToObjects(objects) {
    for (let i = 0; i < objects.length; i++) {
      objects[i].processAnalyticsData(this.sceneManager);
    }
  }

  setCursorMode(type) {
    this.cursorMode = type;
    switch (type) {
      case EElementCategories.PANNING:
        Facade.viewport.plugins.resume('drag');
        Facade.viewport.plugins.resume('pinch');
        this.iconsContainer.interactiveChildren = false;
        this.jointsContainer.interactiveChildren = false;
        this.setViewportCursor('grab');
        break;
      case EElementCategories.CLICKING:
        Facade.viewport.plugins.pause('drag');
        Facade.viewport.plugins.pause('decelerate');
        Facade.viewport.plugins.pause('pinch');
        this.iconsContainer.interactiveChildren = true;
        this.jointsContainer.interactiveChildren = true;
        this.setViewportCursor('default');
        break;
      default:
        console.log('[setCursorMode] Unknown cursor type');
        break;
    }
  }

  setViewportCursor(cursor) {
    clearTimeout(this.cursorTimerID);
    this.cursorTimerID = setTimeout(function () {
      Facade.viewport.cursor = cursor;
    }, 0);
  }

  enterQuickPanning() {
    if (
      this.cursorMode !== EElementCategories.PANNING &&
      !this.inputEventController.isInputActive()
    ) {
      this.setCursorMode(EElementCategories.PANNING);
      commonSendEventFunction(PR_EVENT_QUICK_PANNING_MODE_CHANGED, { isPanning: true });
      this.inputEventController.isQuickPanning = true;
    }
  }

  cancelQuickPanning() {
    if (!this.inputEventController.isInputActive() && this.inputEventController.isQuickPanning) {
      this.setCursorMode(EElementCategories.CLICKING);
      commonSendEventFunction(PR_EVENT_QUICK_PANNING_MODE_CHANGED, { isPanning: false });
      this.inputEventController.isQuickPanning = false;
    }
  }

  endDrawing() {
    if (this.sceneManager.objectCreated && this.sceneManager.objectCreated.onStopDraw) {
      const isValid = this.sceneManager.objectCreated.onStopDraw();
      if (isValid) {
        // Prepare for adding
        this.sceneManager.objects.removeElement(this.sceneManager.objectCreated);
        this.sceneManager.objectCreated.removeFromParent();

        // Add it to the stage via the command system so it can have undo/redo
        this.sceneManager.addObjectToStage(this.sceneManager.objectCreated, false);
      }
    }

    this.sceneManager.objectCreated = null;
    if (this.inputEventController.toolbarActiveState === ACTIVE_STATE_DRAW) {
      document.body.style.cursor = 'crosshair';
    }
  }

  handleMeshPosition() {
    if (this.mesh) {
      this.mesh.recalculate();
    }

    window.app.needsRendering();
  }

  setStepFocused(id, isFocused) {
    let element = this.sceneManager.getElementById(id);
    if (isFocused) {
      this.selectionManager.focusSelection.focus(element, this.PWPFilterType);
    } else {
      this.selectionManager.focusSelection.blur(element, this.PWPFilterType);
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////
  /////////////// GETTERS

  getVisibleSteps() {
    const visibleObjects = this.sceneManager.culling.getObjectsInViewport();
    for (let i = visibleObjects.length - 1; i >= 0; i--) {
      let object = visibleObjects[i];
      if (SharedElementHelpers.IsConnection(object)) {
        visibleObjects.splice(i, 1);
      }
    }
    return visibleObjects;
  }

  getVisibleObjects() {
    return this.sceneManager.culling.getObjectsInViewport();
  }

  /**
   * Returns the analytics element data
   * @param data
   */
  getIDSData(data) {
    // todo Plan is to move RP_EVENT_SAVE_REQUEST and RP_EVENT_REFRESH_REQUEST to this functionality
    const eventName = data.detail.value;
    const objects = this.sceneManager.objects;
    const joints = this.sceneManager.joints;

    this.minimalData = { objects: [], joints: [] };
    for (let i = 0; i < objects.length; i++) {
      const obj = objects[i].getState();
      this.minimalData.objects.push(obj);
    }

    for (let i = 0; i < joints.length; i++) {
      const joint = joints[i].getState();
      // Don't request analytics for connections that connect to Text or Shape elements
      if (
        SharedElementHelpers.IsStep(joints[i].iconA) &&
        !SharedElementHelpers.IsMisc(joints[i].iconA) &&
        SharedElementHelpers.IsStep(joints[i].iconB) &&
        !SharedElementHelpers.IsMisc(joints[i].iconB)
      ) {
        this.minimalData.joints.push(joint);
      }
    }

    const event = new CustomEvent(eventName, {
      detail: { data: JSON.stringify(this.minimalData) },
    });
    document.dispatchEvent(event);
  }

  /**
   * People Who Perfomed
   * @param {Bool} isActive
   */
  onAnalyticsPWPActivated(e) {
    const isActive = e.detail.opened;
    this.PWPFilterType = e.detail.type;

    // People Who Performed
    this.activatePWP(isActive);
  }

  onStepPickerActivated(e) {
    const isActive = e.detail.active;
    this.isTWPActive = isActive;
    this.trendsWidgetId = isActive ? e.detail.widgetId : null;

    this.activateSelectionOfSteps(isActive);
  }

  /**
   * Activate People Who Perfomed
   * @param {Bool} isActive
   */
  activatePWP(isActive) {
    this.isPWPActive = isActive;
    this.activateSelectionOfSteps(isActive);
  }

  activateSelectionOfSteps(isActive) {
    const objects = this.sceneManager.objects;

    if (isActive) {
      this.selectionManager.clearSelection();
      this.selectionManager.hide();

      document.body.style.cursor = 'crosshair';
      this.setViewportCursor('crosshair');

      for (let i = 0; i < objects.length; i++) {
        let object = objects[i];
        object.cursor = 'pointer';
      }

      this.sceneManager.lockInteractionsForNonStepOjects();
    } else {
      document.body.style.cursor = 'default';
      this.setViewportCursor('default');

      for (let i = 0; i < objects.length; i++) {
        let object = objects[i];
        object.cursor = 'move';
      }

      this.sceneManager.revertObjectsInteraction();
    }
  }

  clearStage() {
    // clear the main reference of the imported objects
    this.sceneManager.destroyAllObjects();
    this.sceneManager.objects.splice(0, this.sceneManager.objects.length);
    this.sceneManager.joints.splice(0, this.sceneManager.joints.length);
    this.sceneManager.stateData = { objects: [], joints: [] };

    // remove them from the stage
    this.jointsContainer.removeChildren();
    this.iconsContainer.removeChildren();

    this.selectionManager.clearSelection();
    this.selectionManager.hide();
    this.selectionManager.hideToolbar();

    this.commandManager.reset();

    if (!this.copyPasteUtility.hasValidClipboard()) {
      this.copyPasteUtility.reset();
    }

    this.sceneManager.culling.clear();
  }

  removeDuplicates(data) {
    if (data.objects) {
      data.objects = uniqBy(data.objects, 'ID');
    }

    if (data.joints) {
      data.joints = uniqBy(data.joints, 'ID');
    }

    if (data.funnelConfiguration && data.funnelConfiguration.previousFunnelData) {
      if (data.funnelConfiguration.previousFunnelData.objects) {
        data.funnelConfiguration.previousFunnelData.objects = uniqBy(
          data.funnelConfiguration.previousFunnelData.objects,
          'ID',
        );
      }

      if (data.funnelConfiguration.previousFunnelData.joints) {
        data.funnelConfiguration.previousFunnelData.joints = uniqBy(
          data.funnelConfiguration.previousFunnelData.joints,
          'ID',
        );
      }
    }
  }

  isTextEditing() {
    return this.getEditingText() ? true : false;
  }

  getEditingText() {
    if (this.selectionManager.hasSelectedElements()) {
      const selectedObjects = this.selectionManager.selectedObjects;
      for (let i = 0; i < selectedObjects.length; i++) {
        const selectedElement = selectedObjects[i];

        if (selectedElement) {
          const isTextElement =
            SharedElementHelpers.IsText(selectedElement) ||
            SharedElementHelpers.IsReport(selectedElement);

          if (isTextElement && selectedElement.isEditMode) {
            return selectedElement;
          }
        }
      }
    }
    return null;
  }

  handleNotesData(data) {
    const notesData = this.extractNotes(data);
    this.updateNotesImages(notesData);
    commonSendEventFunction(PR_NOTES_LOADED, notesData);
  }

  extractNotes(data) {
    const canvasNote = data.notes || null;
    const objectNotes = [];

    for (let i = 0; i < data.objects.length; i++) {
      const object = data.objects[i];
      if (object.notes) {
        objectNotes.push({
          id: object.ID,
          noteData: object.notes,
        });
      }
    }

    return {
      canvasNote,
      objectNotes,
    };
  }

  updateNotesImages(notesData) {
    const canvasNote = notesData.canvasNote;
    const objectNotes = notesData.objectNotes;

    if (canvasNote) {
      this.updateNotesImage(canvasNote);
    }

    for (let i = 0; i < objectNotes.length; i++) {
      const objectNote = objectNotes[i];
      this.updateNotesImage(objectNote.noteData);
    }
  }

  updateNotesImage(noteData) {
    if (noteData && noteData.data && noteData.data.entityMap) {
      const entityMap = noteData.data.entityMap;
      for (const key in entityMap) {
        if (entityMap.hasOwnProperty(key)) {
          const element = entityMap[key];
          if (element.type === 'IMAGE') {
            const src = element.data.src;
            if (src.startsWith(process.env.REACT_APP_API_URL)) {
              const tokenKey = 'token';
              const accessToken = getAuthToken();
              if (!accessToken) {
                return;
              }
              const url = new URL(src);
              url.searchParams.delete(tokenKey);
              url.searchParams.set(tokenKey, accessToken);
              url.pathname = getUpdatedPhotoPath(url.pathname);

              element.data.src = url.toString();
            }
          }
        }
      }
    }
  }

  checkEditingText(e) {
    const editingText = this.getEditingText();

    if (editingText) {
      // check if the touch was inside the editing text

      const rect = editingText.editable.element.getBoundingClientRect();
      const x = e.data.global.x;
      const y = e.data.global.y;

      return rect.x <= x && x <= rect.x + rect.width && rect.y <= y && y <= rect.y + rect.height;
    }

    return false;
  }
}
