export const getRefPosition = (ref) => {
  if (ref && ref.current) {
    return ref.current.getBoundingClientRect();
  }

  return { x: 0, y: 0, width: 0, height: 0 };
};

export const getElementPosition = (el) => {
  if (el && el.getBoundingClientRect()) {
    return el.getBoundingClientRect;
  } else {
    return { x: 0, y: 0, width: 0, height: 0 };
  }
};
