import { PR_EVENT_FUNNEL_CHANGED } from 'shared/CSharedEvents';
import { cloneData, commonSendEventFunction } from 'shared/CSharedMethods';

export default class CommandTextStyle {
  constructor(textObject, newStyle, selectionManager) {
    this.textObject = textObject;
    this.selectionManager = selectionManager;
    this.oldTextStyle = cloneData(textObject.getStyle());
    this.newTextStyle = cloneData(newStyle);

    this.isExecuted = false;
  }

  execute() {
    if (!this.isExecuted) {
      this.textObject.setStyles(this.newTextStyle);
      window.app.needsRendering();
      this.isExecuted = true;
      if (
        this.selectionManager.selectedElement &&
        this.selectionManager.selectedElement.id === this.textObject.id
      ) {
        this.selectionManager.single.updateSelectedElementFrame();
      }
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
    }
  }

  revert() {
    if (this.isExecuted) {
      this.textObject.setStyles(this.oldTextStyle);
      window.app.needsRendering();
      this.isExecuted = false;
      if (
        this.selectionManager.selectedElement &&
        this.selectionManager.selectedElement.id === this.textObject.id
      ) {
        this.selectionManager.single.updateSelectedElementFrame();
      }
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
    }
  }
}
