import React, { forwardRef } from 'react';
import {
  VERTICAL_ALIGN_CENTER,
  ViewportConstrainer,
} from 'react-project/components/viewportConstrainer/ViewportConstrainer';
import { MenuImageButton } from '../MenuItems/MenuImageButton';
import styles from './HorizontalMenu.module.scss';
import { UILock } from 'react-project/components/uilock/UILock';

export const HorizontalMenu = forwardRef(
  (
    { buttons, sectionStyle, onButtonClicked, avoidRectangles, relativeMouseBox, lockedButtons },
    ref,
  ) => {
    const [activeItem, setActiveItem] = React.useState(null);

    const onClick = (e, reference, id) => {
      setActiveItem(id);
      onButtonClicked(e, reference, id);
    };

    return (
      <ViewportConstrainer
        relativeMouseBox={relativeMouseBox}
        avoidRectangles={avoidRectangles}
        verticalAlign={VERTICAL_ALIGN_CENTER}
        relativeRectangle={sectionStyle}
        stickToOuterEdges={false}
      >
        <div ref={ref} className={styles.Menu}>
          <div className={styles.MenuWrapper}>
            {buttons.map((btnData) => {
              const isLocked = (lockedButtons && lockedButtons.includes(btnData.id)) || false;
              return (
                <UILock key={btnData.id} isLocked={isLocked}>
                  <MenuImageButton
                    key={btnData.id}
                    id={btnData.id}
                    image={btnData.image}
                    title={btnData.title}
                    isActive={activeItem === btnData.id}
                    onClick={onClick}
                  />
                </UILock>
              );
            })}
          </div>
        </div>
      </ViewportConstrainer>
    );
  },
);
