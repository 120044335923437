import Signals from 'pixi-project/signals/AppSignals';
import { PR_EVENT_FUNNEL_CHANGED } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';

export default class CommandElementURL {
  constructor(object, previousURL, nextURL) {
    this.object = object;
    this.previousURL = previousURL;
    this.nextURL = nextURL;

    this.isExecuted = false;
  }

  execute() {
    if (!this.isExecuted) {
      this.object.url = this.nextURL;
      window.app.needsRendering();
      Signals.elementChanged.dispatch();
      this.isExecuted = true;
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
    }
  }

  revert() {
    if (this.isExecuted) {
      this.object.url = this.previousURL;
      Signals.elementChanged.dispatch();
      window.app.needsRendering();
      this.isExecuted = false;
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
    }
  }
}
