import React from 'react';
import { PopupContainerTable } from 'react-project/components/v2/tables';

export function PeopleContainer({ children }) {
  return (
    <>
      <PopupContainerTable className="defaultRndCancel">{children}</PopupContainerTable>
    </>
  );
}

export default PeopleContainer;
