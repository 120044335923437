import { EConnectionLineType } from 'pixi-project/base/joint/CConnectionConstants';
import { EShapeTypes } from 'shared/CSharedConstants';

export const TEXT_LOAD_MORE_USERS = 'Load More Users';
export const TEXT_LOAD_MORE = 'Load More';

export const TEXT_INFO_PEOPLE_PERFORMED = 'Click an element to add it to the list';
export const TEXT_CLEAR_PEOPLE_PERFORMED = 'Click on the canvas or Apply to end the selection';

export const TEXT_ADD_STEP = 'Add Step';
export const TEXT_PASTE_OBJECT = 'Paste';
export const TEXT_UNDO_ACTION = 'Undo';
export const TEXT_REDO_ACTION = 'Redo';
export const TEXT_FIT_TO_SCREEN = 'Fit to screen';
export const TEXT_ZOOM_IN = 'Zoom in';
export const TEXT_ZOOM_OUT = 'Zoom out';
export const TEXT_ZOOM_DEFAULT = 'Zoom to 100%';
export const TEXT_VERSION_HISTORY = 'Version history';
export const TEXT_ALL_COUNTRIES = 'all countries';

export const TEXT_SEND = 'Send';
export const TEXT_SEND_REPORT = 'Send report';
export const TEXT_REPORT_NAME = 'Report name';
export const TEXT_PLACEHOLDER_REPORT = 'ex: CEO Report (required)';
export const TEXT_PLACEHOLDER_EMAILS = 'Emails, comma separated';
export const TEXT_RECIPIENTS = 'Recipients';
export const TEXT_CUSTOMIZE_SLIDE = 'Customize Slide';

export const TEXT_REVENUE_TRACKING_TITLE = 'Revenue Tracking';
export const TEXT_REVENUE_TRACKING_SETUP_TITLE = 'Setup Revenue Tracking';
export const TEXT_REVENUE_TRACKING_DESCRIPTION = 'This step represents people who completed a ';
export const TEXT_PURCHASE = 'purchase';
export const TEXT_REVENUE_TRACKING_SETUP_DESCRIPTION =
  'Setup revenue tracking to better understand conversions, track KPIs and more in real time.';
export const TEXT_REVENUE_TRACKING_SETUP_LINK_DESCRIPTION =
  'Follow the instructions to send revenue data.';

export const TEXT_CUSTOM_TRACKING_TITLE = 'Custom Action Tracking';
export const TEXT_CUSTOM_TRACKING_SETUP_TITLE = 'Setup Custom Action Tracking';
export const TEXT_CUSTOM_TRACKING_DESCRIPTION =
  'This step represents people who completed the following';
export const TEXT_CUSTOM_TRACKING_SETUP_DESCRIPTION =
  'For advanced tracking such as form submissions, follow our instructions';
export const TEXT_CUSTOM_TRACKING_SETUP_LINK_DESCRIPTION =
  'Follow the instructions to send custom action data.';

export const TEXT_SETTINGS_LABEL = 'Label';
export const TEXT_SETTINGS_THUMBNAIL_URL = 'Create a thumbnail from a URL';

export const TEXT_COPY_TO_CLIPBOARD = 'Copy to clipboard';
export const TEXT_COPY_API_KEY = 'API Key';
export const TEXT_COPY_VIEW_SETUP_INSTRUCTIONS = 'View setup instructions';

export const TEXT_ICON_LIST_CUSTOM_ACTIONS = 'Custom Actions';
export const TEXT_ICON_LIST_CUSTOM_SOURCES = 'Custom Sources';

export const TEXT_TUTORIAL_UPLOAD_NOT_SUPPORTED =
  "We're sorry but uploading custom images is not currently supported in the demo canvas.\r\nCreate a canvas in your account to upload infinite custom images or icons.";

export const TEXTS_TOOLTIP = {
  HIDE_FILTERS: 'Hide sidemenu',
  SHOW_FILTERS: 'Show sidemenu',
  HELP: 'Help',
  ZOOM_VIEW_OPTIONS: 'Zoom/View options',
  REFRESH_ANALYTICS: 'Refresh Analytics',
  ACTIVATE_ANALYTICS: 'Activate analytics',
  SELECT: 'Select',
  PAN: 'Pan',
  ADD_STEP: 'Add a step',
  ADD_TEXT: 'Add Text',
  ADD_PHOTO: 'Add Photo',
  EXPORT_PNG: 'Export PNG',
  EXPORT_CANVAS: 'Export Canvas',
  DRAW_RECTANGLE: 'Draw a rectangle',
  DRAW_TRIANGLE: 'Draw a triangle',
  DRAW_CIRCLE: 'Draw a ellipse',
  REPORT_TOOL: 'Report tool',
  MORE: 'More',
  BORDER_COLOR: 'Border color',
  FILL_COLOR: 'Fill color',
  ADVANCED_TRACKING_SETUP_UNAVAILABLE:
    'Advanced tracking setup is not yet available in beta canvas',
  WIDGET_TOOL: 'Widget tool',
  UNAPPLIED_CHANGES: 'Unapplied changes',
  DISABLE_EXPLORER: 'This option is unavailable when multiple steps are selected',
  SPREAD_ITEM: 'Clone this element with additional attributes applied',
  SHARE_CANVAS: 'Share Canvas',
  DATA_VIEW: 'Switch between Funnel Mode and Traffic Mode',
};

export const TEXTS_STRAIGHT_LINE_TYPES = 'Straight Line';
export const TEXTS_BEZIER_LINE_TYPES = 'Bezier Line';

export const TEXTS_SHAPES = {
  [EShapeTypes.ELLIPSE]: 'Ellipse',
  [EShapeTypes.RECTANGLE]: 'Rectangle',
  [EShapeTypes.TRIANGLE]: 'Triangle',
};

export const TEXTS_CONNECTION_LINE_TYPES = {
  [EConnectionLineType.SOLID]: 'Direct connection',
  [EConnectionLineType.DOTTED]: 'Skip steps',
  [EConnectionLineType.NODATA]: 'No data',
};
