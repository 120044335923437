import React, { useRef, useState } from 'react';
import styles from 'react-project/LeftSideBar/LeftSideBar.module.scss';
import { iconDevices, iconWarrning } from 'react-project/assets/Icons';
import { When } from 'react-project/Util/When';
import cls from 'classnames';
import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';
import DevicesFilter from 'react-project/FilterData/DevicesFilter';
import PropTypes from 'prop-types';
import { getDirtyStyle, getFilterAddClass } from 'react-project/Util/FilterStyleHelper';
import { TEXTS_TOOLTIP } from 'react-project/Constants/texts';
import { TOOLTIP_POSITIONS } from 'react-project/Constants/tooltip';
import { Tooltip } from 'react-project/components/tooltip/Tooltip';

export const DEVICES_LIST = {
  ALL_DEVICES: 'all devices',
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
};

export const DevicesFilterBlock = ({
  selectedDevice,
  funnel,
  onSelectDevice,
  type,
  isApplyButtonEnabled,
  hasFilterChange,
}) => {
  const [devicesOpened, setDevicesOpened] = useState(false);

  const onSelect = (selectedDevice) => {
    onSelectDevice({ device: selectedDevice });
  };

  const ref = useRef();

  return (
    <ClickOutsideCustomComponent
      ignoreClickOutside={!devicesOpened}
      onClickOutside={() => setDevicesOpened(false)}
    >
      <div
        className={cls(styles.FiltersItem, getFilterAddClass(type), {
          [styles.ActiveFilter]: devicesOpened,
          [getDirtyStyle(type)]: selectedDevice !== DEVICES_LIST.ALL_DEVICES,
        })}
        ref={ref}
      >
        <div className={styles.ItemsForSelection} onClick={() => setDevicesOpened(!devicesOpened)}>
          <span>
            Device:
            <span className={cls(styles.CapitalizeTitle, styles.CountriesTitle)}>
              {selectedDevice}
            </span>
          </span>
          <div className={styles.filterIconArrangement}>
            <When condition={hasFilterChange && isApplyButtonEnabled}>
              <Tooltip label={TEXTS_TOOLTIP.UNAPPLIED_CHANGES} position={TOOLTIP_POSITIONS.BOTTOM}>
                <div className={styles.FilterWarning}>{iconWarrning}</div>
              </Tooltip>
            </When>
            {iconDevices}
          </div>
        </div>
        <When condition={devicesOpened && funnel}>
          <DevicesFilter
            funnel={funnel}
            selectedDevice={selectedDevice}
            setSelectedDevice={onSelect}
            innerRef={ref}
          />
        </When>
      </div>
    </ClickOutsideCustomComponent>
  );
};

DevicesFilterBlock.propType = {
  selectedDevice: PropTypes.string.isRequired,
  funnel: PropTypes.shape({
    projectId: PropTypes.string.isRequired,
  }),
  onSelectDevice: PropTypes.func.isRequired,
};
