import React from 'react';
export const ExplorerUpgrade = ({ onBtnClicked }) => {
  return (
    <>
      <div>
        <p>
          <b>Upgrade</b> your account today and Unleash the Power of the <b>Step Explorer!</b>
        </p>
        <p>
          Upgrade now to gain access to <b>Step Explorer</b>, the ultimate tool for analyzing your
          traffic sources, campaigns, and segments.
        </p>
        <p>
          Dive deep into your data and uncover valuable insights that will revolutionize your custom
          journey optimization.
        </p>
        <p>
          It's your chance to experience the complete Funnelytics package and maximize your
          marketing success.
        </p>
        <p>
          Don't miss out on this exclusive opportunity. Upgrade your account today and discover the
          true power of Funnelytics!
        </p>
        <button onClick={onBtnClicked}>Upgrade your account</button>
      </div>
    </>
  );
};
