import { curryN, length, reduce, filter, propEq, prop, map, pipe, find } from 'ramda';

export const CheckboxState = {
  UNCHECKED: 0,
  CHECKED: 1,
  INDETERMINATE: 2,
};

// helpers

const stateIs = curryN(2, (value, state) => state === CheckboxState[value]);

const getItemState = (newState) => (id) => {
  const result = newState.find((i) => i.state && i.id === id);

  if (!result) {
    return CheckboxState.UNCHECKED;
  }

  return result.state;
};

export const findCheckedStateByIds = (childIds) => {
  const childIdsLength = length(childIds);

  if (childIdsLength === length(filter(stateIs('CHECKED'))(childIds))) {
    return CheckboxState.CHECKED;
  }

  if (childIdsLength === length(filter(stateIs('UNCHECKED'))(childIds))) {
    return CheckboxState.UNCHECKED;
  }

  return CheckboxState.INDETERMINATE;
};

export const determineParentStateBasedOnChildren = curryN(3, (prevState, items, selectedId) => {
  let parent;
  items.forEach((i) => {
    if (i.id === selectedId && selectedId === item?.parentId) {
      parent = i;
    }
  });
  const childIds = items.filter((i) => i.parentId === parent?.id).map((i) => i.id);

  const itemWithNewState = getItemState(prevState);
  const childStates = childIds.map(itemWithNewState);
  return findCheckedStateByIds(childStates);
});

const updateStateWithStatus = (checkboxStatus) => (acc, curr) =>
  curr.state !== checkboxStatus ? [...acc, { ...curr, state: checkboxStatus }] : [...acc, curr];

// reducers
const updateStateReducer = (checkboxStatus) => (acc, curr) => {
  return updateStateWithStatus(checkboxStatus)(acc, curr);
};

// featured functions
export const updateParent = ({ state, items, selectedId, initialState = [] }) => {
  const checkboxStatus = determineParentStateBasedOnChildren(state, items, selectedId);
  const reducer = updateStateReducer(checkboxStatus);
  return reduce(reducer, initialState, state);
};

// Set / Unset
const setItemById = (selectedId, prevState, checkboxStatus) =>
  prevState.map((item) => (item.id === selectedId ? { ...item, state: checkboxStatus } : item));

export function setCheckedById(selectedId, prevState) {
  return setItemById(selectedId, prevState, CheckboxState.CHECKED);
}

export function setUncheckedById(selectedId, prevState) {
  return setItemById(selectedId, prevState, CheckboxState.UNCHECKED);
}

const filterToIdsOfChildren = (id) => {
  const matchingParentId = propEq('parentId', id);
  const idOnly = prop('id');
  const filterByParentId = filter(matchingParentId);
  const mapToIds = map(idOnly);
  return pipe(filterByParentId, mapToIds);
};

const reducer =
  ({ selectedParentId, childIds, checkboxStatus }) =>
  (acc, curr) => {
    const isParent = curr.id === selectedParentId;
    const childrenMatchParent = childIds.find((id) => id === curr.id);

    if (isParent || childrenMatchParent) {
      return [...acc, { ...curr, state: checkboxStatus }];
    }

    return [...acc, curr];
  };

const isString = (x) => typeof x === 'string';
const onlyStrings = filter(isString);

const findParentId = ({ parentId }) => parentId;

const findAncestorId = ({ id, items }) => {
  const findAncestor = find(({ id: parentId }) => parentId === id);
  const ancestor = findAncestor(items);
  return ancestor ? findParentId(ancestor) : null;
};

const updateStateIfMatch = (item, nextCheckboxState, matchId) =>
  matchId === item.id ? nextCheckboxState : item.state;

export const setAllCheckboxes = (nextCheckboxState) => (selectedId, prevState, prevItems) => {
  const idEq = propEq('id');
  const itemFromSelected = idEq(selectedId);
  const getItemFromSelected = find(itemFromSelected);
  const item = getItemFromSelected(prevItems);

  const childIdsFromSelectedId = filterToIdsOfChildren(selectedId);
  const childIds = childIdsFromSelectedId(prevItems);
  const parentId = item ? findParentId(item) : null;
  const ancestorId = parentId ? findAncestorId({ id: parentId, items: prevItems }) : null;

  // ids to update
  const matchingIds = onlyStrings([...childIds, parentId, ancestorId]);

  // dispatch state change by id

  // const matchesCurrentId = idEq(id)
  // const findCurrent = find<ItemState>(matchesCurrentId)
  // const stateItem = findCurrent(prevState)

  const transformStateWithChecks = (itemState) => {
    const curriedUpdateStateIfMatch = curryN(3, updateStateIfMatch);
    const provideMatcherState = curriedUpdateStateIfMatch(itemState, nextCheckboxState);
    const determineChangeOnEachMatch = map(provideMatcherState);
    const [checkedState] = determineChangeOnEachMatch(matchingIds);

    return {
      ...itemState,
      state: checkedState,
    };
  };
  const updateWithNewChecks = map(transformStateWithChecks);

  return updateWithNewChecks(prevState);
};

export const handleCheckOrchestrator = (prevState, prevItems, selectedId) => {
  const idMatchesSelectedId = propEq('id', selectedId);
  const findMatchingItem = find(idMatchesSelectedId);
  const selectedItem = findMatchingItem(prevItems);

  // Should add test case to cover early bail
  if (!selectedItem) return prevState;

  const getItemStateById = getItemState(prevState);
  const selectedItemId = prop('id', selectedItem);
  const found = getItemStateById(selectedItemId);

  const nextCheckboxState =
    found === CheckboxState.CHECKED ? CheckboxState.UNCHECKED : CheckboxState.CHECKED;

  // case 1, toggle all relevant checkboxes
  // predicate logic to see if this needs to run or another func is suited
  const mutator = setAllCheckboxes(nextCheckboxState);

  // case 2, if parent

  const refreshedState = mutator(selectedId, prevState, prevItems);

  return refreshedState;
};
