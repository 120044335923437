import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHover } from '../../hooks';
import { TierTreeRow } from '../../components/v2';
import {
  setCheckboxes,
  toggleAdSetById,
  insertAdPopup,
  incrementPopContainerOffset,
} from '../../features/facebookIntegration';
import { adType } from '../helpers';
import { FB_AD_MODAL_MODE_SEARCH } from 'shared/CSharedConstants';

export const AdItem = ({ data, options, stepId }) => {
  const dispatch = useDispatch();
  const position = useSelector((state) => state.facebookIntegration.popUpBounds);
  const popUpOffset = useSelector((state) => state.facebookIntegration.popUpOffsetCount);
  const collectionDisplayMode = useSelector(
    ({ facebookIntegration }) => facebookIntegration.stepIds[stepId].collectionDisplayMode,
  );
  const doNotDisplay = data.doNotDisplay && collectionDisplayMode === FB_AD_MODAL_MODE_SEARCH;

  const isAd = data.type === adType.AD;
  const isWarning = isAd && data.utmParameters && Object.keys(data.utmParameters).length === 0;
  const showInfo = isAd && !isWarning;
  const selectedView = options?.selectedView;
  const uncheckedInDisplayView = selectedView && !data.checked;
  const [hoverRef, isHover] = useHover();
  const utmInfoModal = (data) => {
    dispatch(
      insertAdPopup({
        title: data.title,
        id: data.id,
        position,
        popUpOffset,
        utmParameters: data.utmParameters,
        stepId,
      }),
    );
    dispatch(incrementPopContainerOffset());
  };

  if (selectedView && data.excludeFromCheckbox && isAd) return null;

  const ad = uncheckedInDisplayView ? null : (
    <TierTreeRow
      key={`ad1-${data.id}`}
      data={data}
      handleCheck={() => dispatch(setCheckboxes({ id: data.id, stepId }))}
      options={{
        showInfo,
        isWarning,
        isAd,
        selectedView,
        ref: hoverRef,
        isHover,
        onInfoClick: utmInfoModal,
        doNotDisplay,
      }}
    />
  );
  return <> {ad} </>;
};

export const AdList = ({ data, options, stepId }) => {
  const ads = useSelector(({ facebookIntegration }) => facebookIntegration.stepIds[stepId].ads);
  const filteredAds = ads.filter((ad) => ad.adSetId === data.id);
  return (
    <div>
      {filteredAds?.length > 0 ? (
        filteredAds.map((ad) => (
          <AdItem key={`ad2-${ad.id}`} data={ad} options={options} stepId={stepId} />
        ))
      ) : (
        <div className="mt-2 rounded border border-slate-100 px-3 py-2">
          <p className="text-sm text-slate-400">No results matched this selection.</p>
        </div>
      )}
    </div>
  );
};

export function AdSetItem({ data, options, stepId }) {
  const dispatch = useDispatch();
  const { expanded } = data;
  const selectedView = options?.selectedView;
  const uncheckedInDisplayView = selectedView && !data.checked;
  const [hoverRef, isHover] = useHover();
  const selectionAdsetValue = useSelector(
    ({ facebookIntegration }) => facebookIntegration.stepIds[stepId].selectedAdPrices[data.id],
  );
  const collectionDisplayMode = useSelector(
    ({ facebookIntegration }) => facebookIntegration.stepIds[stepId].collectionDisplayMode,
  );
  const doNotDisplay = data.doNotDisplay && collectionDisplayMode === FB_AD_MODAL_MODE_SEARCH;

  const handleCheck = () => {
    dispatch(setCheckboxes({ id: data.id, stepId }));
  };
  const handleToggle = () => {
    dispatch(toggleAdSetById({ id: data.id, stepId }));
  };

  const displayAdset = uncheckedInDisplayView ? null : (
    <TierTreeRow
      data={data}
      options={{
        ...options,
        ref: hoverRef,
        isHover,
        selectedView,
        altPrice: selectionAdsetValue?.totalSpend?.formattedPrice,
        doNotDisplay,
      }}
      handleCheck={handleCheck}
      handleToggle={handleToggle}
    >
      {expanded ? <AdList data={data} options={options} stepId={stepId} /> : null}
    </TierTreeRow>
  );
  return <> {displayAdset} </>;
}

export function AdSetList({ data: { childResultsById }, options }) {
  const stepId = useSelector(({ currentStep }) => currentStep.stepId);
  const adSetData = useSelector(
    ({ facebookIntegration }) => facebookIntegration.stepIds[stepId].adSets,
  );
  const adSetIds = childResultsById;
  const selectedAdSets = adSetData?.filter((ad) => adSetIds?.includes(ad.id)) || [];
  return (
    <div>
      {selectedAdSets?.length > 0 ? (
        selectedAdSets.map((adSet) => (
          <AdSetItem key={`ad3-${adSet.id}`} data={adSet} options={options} stepId={stepId} />
        ))
      ) : (
        <div className="mt-2 px-3 py-2 w-full">
          <p className="text-sm text-slate-400 flex justify-center">Loading...</p>
        </div>
      )}
    </div>
  );
}

export default AdSetList;
