import React from 'react';
import { XCircleIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';

export function ErrorMessage({ message, type }) {
  const noResults = type === 'NoResults';
  if (noResults) {
    return (
      <div className="rounded-md p-4 text-center">
        <h3 className="mb-3 text-slate-500">{message}</h3>
      </div>
    );
  }
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{message}</h3>
        </div>
      </div>
    </div>
  );
}
ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default ErrorMessage;
