import { AnalyticsHelper } from 'shared/AnalyticsHelper';
import { EElementTypes } from 'shared/CSharedCategories';
import { RP_EVENT_REFRESH_REQUEST, RP_SCROLL_ELEMENT_INTO_VIEW } from 'shared/CSharedEvents';
import { commonSendEventFunction, roundTo } from 'shared/CSharedMethods';
import { numSeparator } from 'shared/NumberHelpers';
import SharedElementHelpers from 'shared/SharedElementHelpers';
import Styles, { COLOR_COMPARE_HITS, COLOR_WIDGET_MESSAGE } from '../Styles';
import BaseWidget, { WIDGET_PADDING } from './BaseWidget';
import TableRowGoals from './TableRowGoals';
import TableRowGoalsCompare from './TableRowGoalsCompare';
import TableViewScroll from './TableView/TableViewScroll';
import { TABLE_SORTING_ASC, TABLE_SORTING_DESC } from './TableView/TableViewSortLabel';
import MainStorage from 'pixi-project/core/MainStorage';

export default class GoalsWidget extends BaseWidget {
  constructor(eventHandlers, id, delegate, data) {
    super(eventHandlers, id, delegate, data);

    this.type = EElementTypes.WIDGET_GOALS;
    this.styles = {
      icon: 'icon-goals.png',
      title: 'Goals',
    };

    this.totalRows = 0; // number of rows loaded
    this.dataKeys = {}; // keep track of the data loaded to avoid duplicates
    this.isCompare = false;

    this.containerWidth = 480;
    this.rowHeight = 40;

    this.emptyMessage = new PIXI.BitmapText(
      'To see goals please select a step on the canvas\nand add a goal to it in the analytics menu.',
      { ...Styles.WIDGET_MESSAGE, fontSize: 18 },
    );
    this.emptyMessage.tint = COLOR_WIDGET_MESSAGE;
    this.emptyMessage.visible = false;
    this.emptyMessage.anchor.set(0.5, 0.5);
    this.addChild(this.emptyMessage);
  }

  init() {
    super.init();

    this.sortIndex = -1;
    this.sortDirection = TABLE_SORTING_ASC;

    // Create subheader
    const colorTitle = 0x4a5468;

    this.totalContainer = new PIXI.Container();
    this.totalContainer.visible = false;
    this.addChild(this.totalContainer);

    this.totalTitle = new PIXI.BitmapText('Total', Styles.WIDGET_BOLD_TITLE);
    this.totalTitle.tint = colorTitle;
    this.totalTitle.anchor.y = 0.5;
    this.totalContainer.addChild(this.totalTitle);

    this.totalGoals = new PIXI.BitmapText('/', Styles.WIDGET_BOLD_TITLE);
    this.totalGoals.tint = colorTitle;
    this.totalGoals.anchor.y = 0.5;
    this.totalContainer.addChild(this.totalGoals);

    this.totalCompareGoals = new PIXI.BitmapText('/', Styles.WIDGET_COMPARE);
    this.totalCompareGoals.tint = COLOR_COMPARE_HITS;
    this.totalCompareGoals.anchor.y = 0.5;
    this.totalContainer.addChild(this.totalCompareGoals);

    this.totalCompareCircle = PIXI.Sprite.from(PIXI.utils.TextureCache['compare_circle']);
    this.totalCompareCircle.tint = 0x00ff00;
    this.totalCompareCircle.anchor.y = 0.5;
    this.totalContainer.addChild(this.totalCompareCircle);
    this.totalCompareIndicator = PIXI.Sprite.from(PIXI.utils.TextureCache['compare-up']);
    this.totalCompareIndicator.x = this.totalCompareCircle.width / 2;
    this.totalCompareIndicator.anchor.set(0.5, 0.5);
    this.totalCompareCircle.addChild(this.totalCompareIndicator);

    this.emptyMessage.x = this.containerWidth / 2;
    this.emptyMessage.y = this.containerHeight / 2 + this.getHeaderHeight() / 2;

    this.handleAnalayticsVisibility();
  }

  getSubHeaderHeight() {
    return 0;
  }

  positionPagination() {
    this.refreshButton.x = this.containerWidth - WIDGET_PADDING - 10;
    const y = this.getHeaderY();
    this.refreshButton.y = y;
  }

  createPagination() {
    this.refreshButton = PIXI.Sprite.from(PIXI.utils.TextureCache['loader.png']);
    this.refreshButton.tint = 0x4a5468;
    this.refreshButton.scale.set(0.5);
    this.refreshButton.anchor.set(0.5, 0.5);
    this.refreshButton.buttonMode = true;
    this.refreshButton.interactive = true;
    this.refreshButton.pointerdown = (e) => {
      this.refreshButton.tint = 0x292929;
      e.stopPropagation();
      app.needsRendering();
    };
    this.refreshButton.pointerup = (e) => {
      this.refreshButton.tint = 0x4a5468;
      this.onRefresh();
      app.needsRendering();
    };
    this.content.addChild(this.refreshButton);
  }

  updatePagination() {
    // overwrite to hide the pagination

    const y = 25;
    const x = this.tableView.columnSpacing;
    const compareY = 18;
    const compareShift = this.isCompare ? -8 : 0;
    const headerData = this.tableView.headerData;

    const hitsX = x + headerData[0].calculatedWidth + this.tableView.columnSpacing;

    this.totalTitle.x = hitsX;
    this.totalTitle.y = y;

    const goalsX =
      x +
      headerData[0].calculatedWidth +
      headerData[1].calculatedWidth +
      this.tableView.columnSpacing * 2;

    this.totalGoals.x = goalsX;
    this.totalGoals.y = y + compareShift;

    this.totalCompareGoals.x = goalsX;
    this.totalCompareGoals.y = y + compareY + compareShift;

    const circleX =
      x +
      headerData[0].calculatedWidth +
      headerData[1].calculatedWidth +
      headerData[2].calculatedWidth +
      this.tableView.columnSpacing * 3;
    this.totalCompareCircle.x = circleX;
    this.totalCompareCircle.y = y;

    this.totalContainer.visible = this.isAnalyticsVisible;
    this.totalCompareGoals.visible = this.isCompare;
    this.totalCompareCircle.visible = this.isCompare;
  }

  createTable() {
    this.tableView = new TableViewScroll();
    this.tableView.delegate = this;
    this.content.addChild(this.tableView);
  }

  addWidgetData(widgetData) {}

  createGoalRowData(objects, result) {
    for (let i = 0; i < objects.length; i++) {
      const object = objects[i];
      const goal = object.goal;

      if (goal && goal.hasGoal) {
        const analyticsData = this.widgetData[object.id];
        if (analyticsData) {
          const compare = analyticsData.compare_to_data;
          const goalValue = goal.goalValue;
          const rowData = {
            stepId: object.id,
            name: this.composeTitle(object, goal),
            hits: analyticsData.hits,
            goal: (parseFloat(goalValue) || 0) * analyticsData.hits,
          };

          if (compare) {
            this.isCompare = true;
            rowData.compare_hits = compare.hits;
            rowData.compare_goal = (parseFloat(goalValue) || 0) * compare.hits;
          }

          result.push(rowData);
        }
      }
    }
  }

  composeTitle(object, goal) {
    let title = '';
    if (SharedElementHelpers.IsConnection(object)) {
      title = goal.goalName || `${object.iconA.titleText} -> ${object.iconB.titleText}`;
    } else {
      title = goal.goalName || object.titleText;
    }
    return title;
  }

  refreshDisplay() {
    if (this.widgetData) {
      this.setWidgetData(this.widgetData);
    }
  }

  setWidgetData(widgetData) {
    super.setWidgetData(widgetData);

    this.isCompare = false; // reset compare mode
    this.dataKeys = {}; // reset the dataKeys
    this.widgetData = widgetData;

    this.refreshButton.alpha = 1;
    this.refreshButton.interactive = true;

    const rowsData = [];
    this.createGoalRowData(this.delegate.objects, rowsData);
    this.createGoalRowData(this.delegate.joints, rowsData);

    // compare mode flag will be set after this operation 'createGoalRowData' and be avalilable

    this.updateHeaderData();

    this.totalRows = rowsData.length;

    const tableHeight = this.containerHeight - this.getHeaderHeight();

    this.tableView.resetToDefaults();
    this.tableView.hasMoreDataToLoad = false;
    this.tableView.bottomSpace = 0;
    this.tableView.isLoading = false;

    this.tableView.rowClass = this.isCompare ? TableRowGoalsCompare : TableRowGoals;
    this.tableView.pageSize = this.numberOfRows;
    this.tableView.columnSpacing = WIDGET_PADDING;
    this.tableView.tableSize.width = this.containerWidth;
    this.tableView.tableSize.height = tableHeight;
    this.tableView.rowSize.width = this.containerWidth;
    this.tableView.rowSize.height = this.rowHeight;
    this.tableView.barStyle.alpha = 0.5;
    this.tableView.barStyle.padding = 5;
    this.tableView.barStyle.width = 5;
    this.tableView.barStyle.radius = 5;
    this.tableView.barStyle.endPadding = 5; // the padding from top and bottom
    this.tableView.headerHeight = this.tableHeaderHeight;
    const headerData = [
      {
        title: 'Goals Breakdown',
        width: 160,
        align: 'left',
        sorting: true,
      },
      {
        title: 'People',
        width: 0.5,
        align: 'left',
        sorting: true,
      },
      {
        title: 'Value',
        width: 0.5,
        align: 'left',
        sorting: true,
      },
      {
        title: 'Locate',
        width: 20,
        align: 'right',
      },
    ];

    if (this.isCompare) {
      headerData.splice(3, 0, {
        title: 'Trend',
        width: 28,
        align: 'left',
      });
    }

    this.tableView.setHeaderData(headerData);
    this.tableView.setRowsData(rowsData);
    this.tableView.renderTable();
    this.updateRowsWithCompareRange();

    this.hideMessage();

    this.updatePagination();

    if (!rowsData.length) {
      this.tableView.visible = false;
      this.totalContainer.visible = false;
    }

    this.sortByIndex(this.sortIndex === -1 ? 0 : this.sortIndex, this.sortDirection);
    this.updateHeaderData();

    window.app.needsRendering();
  }

  updateHeaderData() {
    if (this.tableView) {
      let hitsSum = 0;
      let goalsSum = 0;
      let compareSumHits = 0;
      let compareSumGoals = 0;

      for (let i = 0; i < this.tableView.rowsData.length; i++) {
        const rd = this.tableView.rowsData[i];
        hitsSum += rd.hits;
        goalsSum += rd.goal;

        compareSumGoals += rd.compare_goal;
        compareSumHits += rd.compare_hits;
      }

      this.totalGoals.text = `$${numSeparator(roundTo(goalsSum, 2))}`;
      this.totalCompareGoals.text = `$${numSeparator(roundTo(compareSumGoals, 2))}`;

      const totalPercent = AnalyticsHelper.parsePercentChange(compareSumHits, hitsSum);
      this.totalCompareCircle.tint = AnalyticsHelper.getColor(totalPercent, this.compareRange);
      AnalyticsHelper.setIndicator(this.totalCompareIndicator, totalPercent, this.compareRange);
    }
  }

  onWheel(event) {
    this.tableView.onWheel(event);
  }

  acceptsWheelEvents() {
    return true;
  }

  onSortingClick(label, sorting) {
    const index = label.sortingIndex;
    this.sortByIndex(index, sorting);
  }

  sortByIndex(index, sorting) {
    if (index === 0) {
      this.sortRowsData(this.tableView.rowsData, 'name', sorting);
    } else if (index === 1) {
      this.sortRowsData(this.tableView.rowsData, 'hits', sorting);
    } else if (index === 2) {
      this.sortRowsData(this.tableView.rowsData, 'goal', sorting);
    }

    this.tableView.setRowsData(this.tableView.rowsData);
    this.tableView.rebindRows();

    this.sortIndex = index;
    this.sortDirection = sorting;

    this.tableView.titles[index].setSorting(sorting);
  }

  sortRowsData(data, propertyName, sorting) {
    data.sort((a, b) => {
      if (a[propertyName] < b[propertyName]) {
        return -1;
      } else if (a[propertyName] > b[propertyName]) {
        return 1;
      }
      return 0;
    });

    if (sorting === TABLE_SORTING_DESC) {
      data.reverse();
    }
  }

  onLocateClicked(e, cell) {
    commonSendEventFunction(RP_SCROLL_ELEMENT_INTO_VIEW, { id: cell.data.stepId });
  }

  setCompareRange(compareRange) {
    super.setCompareRange(compareRange);
    this.updateRowsWithCompareRange();
    this.updateHeaderData();
  }

  updateRowsWithCompareRange() {
    if (this.tableView) {
      for (let i = 0; i < this.tableView.rows.length; i++) {
        const row = this.tableView.rows[i];
        row.compareRange = this.compareRange;
        if (row.data && row.visible) {
          row.bindData(row.data);
        }
      }
    }
  }

  onAnalyticsStartedRefresh() {
    super.onAnalyticsStartedRefresh();

    this.hideContent();
    this.headerLabel.text = 'Goals';
    this.refreshButton.alpha = 0.5;
    this.refreshButton.interactive = false;
  }

  onRefresh() {
    commonSendEventFunction(RP_EVENT_REFRESH_REQUEST, { isCancel: false });
  }

  hideContent() {
    this.tableView.visible = false;
    this.refreshButton.visible = false;
    if (this.totalContainer) {
      this.totalContainer.visible = false;
    }
    this.emptyMessage.visible = false;
  }

  showContent() {
    this.tableView.visible = this.tableView.rowsData.length ? true : false;
    this.refreshButton.visible = true;
    if (this.totalContainer) {
      this.totalContainer.visible = this.tableView.visible;
    }

    this.emptyMessage.visible = !this.tableView.visible;
  }

  hideMessage() {
    super.hideMessage();
    this.headerLabel.text = `${this.totalRows} goals`;
    this.showContent();
  }

  showMessage() {
    super.showMessage();
    this.headerLabel.text = 'Goals';
    this.hideContent();
  }

  onWidgetDropped() {
    this.refreshDisplay();
  }

  onPaste() {
    super.onPaste();
    // TODO find if analytics layer is visible
    this.refreshDisplay();
  }
}
