import axios from 'axios';
import cls from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { chevronRightIcon, iconPlusSmall, iconSmallLogo } from 'react-project/assets/Icons';
import styles from 'react-project/AttributeExplorer/AttributeExplorer.module.scss';
import { getExplorerRequestBody } from 'react-project/Helpers/requestConstruction';
import RequestService from 'react-project/Helpers/RequestService';
import { selectCurrentStep } from 'react-project/redux/current-step/selectors';
import { setLoadMoreSourceItemsConfig } from 'react-project/redux/explorer/actions';
import { selectFocusedSteps } from 'react-project/redux/focused-step/selectors';
import { selectFunnelConfiguration } from 'react-project/redux/funnel-configuration/selectors';
import { selectCurrentFunnelId, selectProjectId } from 'react-project/redux/funnels/selectors';
import { When } from 'react-project/Util/When';
import { useDispatch, useSelector } from 'react-redux';
import { PAGINATION_LIMIT } from 'shared/CSharedConstants';
import { numSeparator } from 'shared/NumberHelpers';
import { Button } from '../components/button/Button';
import { commerceAction } from '../Constants/specialActions';
import { TEXT_LOAD_MORE } from '../Constants/texts';

const requestService = new RequestService();
const LIMIT_VALUES = PAGINATION_LIMIT;
let source = axios.CancelToken.source();

const AttributesListComponent = ({
  attributes,
  onSelect,
  isLoadingPageParameters,
  stepsModalSetState,
  isInUnorganizedFolder,
}) => {
  const setupCanLoadMore = {};
  attributes.forEach((attr) => {
    setupCanLoadMore[attr.key] = true;
  });

  const [expandedAttributeKeys, setExpandedAttributeKeys] = useState([]);
  const [limitValues, setLimitValues] = useState(LIMIT_VALUES);
  const [canLoadMore, setCanLoadMore] = useState(setupCanLoadMore);
  const funnelId = useSelector(selectCurrentFunnelId);
  const currentStep = useSelector(selectCurrentStep);
  const funnelConfiguration = useSelector(selectFunnelConfiguration);
  const focusedSteps = useSelector(selectFocusedSteps);
  const projectId = useSelector((state) => selectProjectId(state, funnelId));
  const dispatch = useDispatch();

  const onToggleExpanded = (value) => {
    const isAlreadyExpanded = expandedAttributeKeys.some((k) => k === value);
    const newExpandedAttributesKeys = isAlreadyExpanded
      ? expandedAttributeKeys.filter((k) => k !== value)
      : expandedAttributeKeys.concat(value);

    setExpandedAttributeKeys(newExpandedAttributesKeys);
  };

  const prepareRequestBody = (attributeKey = '', pageNumber = 1) => {
    const exploredStep = currentStep ? { ...currentStep.object, ID: currentStep.stepId } : null;
    const reqBody = getExplorerRequestBody({
      funnelConfiguration,
      focusedSteps,
      exploredStep,
      pageNumber,
      limit: limitValues,
      attributeKey,
    });
    return reqBody;
  };

  const getAttributeExplorerPageParameters = async (attributeKey, pageNumber) => {
    // setIsMoreLoading(true); //update loading  isLoadingPageParameters
    const reqBody = prepareRequestBody(attributeKey, pageNumber);
    // setLastRequest(reqBody);
    source = axios.CancelToken.source();
    const responseData = await requestService.getAttributeExplorerPageParams(
      projectId,
      reqBody,
      source,
    );
    if (axios.isCancel(responseData) || !responseData) {
      // setIsMoreLoading(false); //isLoadingPageParameters
      return;
    }
    return responseData;
  };

  // Dispatch action with the specific attributeKey

  const setAttrCanLoadMore = (attr) => {
    //Set CanLoadMore with updated value
    const revised = { ...canLoadMore };
    revised[attr.key] = attr.values.length >= LIMIT_VALUES;
    setCanLoadMore(revised);
  };

  const attributeList = attributes.map((attribute) => {
    const onLoadMore = async () => {
      try {
        if (isInUnorganizedFolder) return;
        stepsModalSetState({ isLoadingPageParameters: true });
        const paginationCount = Math.floor(attribute.values.length / 20) + 1;
        const respData = await getAttributeExplorerPageParameters(attribute.key, paginationCount);
        respData.common_parameters.forEach(setAttrCanLoadMore);
        respData.custom_parameters.forEach(setAttrCanLoadMore);
        dispatch(setLoadMoreSourceItemsConfig(respData));
        stepsModalSetState({ isLoadingPageParameters: false });
      } catch (e) {
        console.log('## E', e);
      }
    };
    return (
      <div key={attribute.key}>
        <div
          className={cls(styles.ItemBlock, styles.SpaceBetween, {
            [styles.Expanded]: expandedAttributeKeys.some((k) => k === attribute.key),
          })}
        >
          <div className={styles.LeftSideBlock}>
            <When condition={attribute.values.length > 0}>
              <div className={styles.IndentBtn} onClick={() => onToggleExpanded(attribute.key)}>
                {chevronRightIcon}
              </div>
            </When>
            <div className={`${styles.Strong} ${styles.AttributeKey}`}>
              {commerceAction[attribute.key] && iconSmallLogo}
              {`${commerceAction[attribute.key] || attribute.key}`}
            </div>
            ({numSeparator(attribute.hits)})
          </div>
          <div
            className={styles.PlusBtn}
            onClick={() => onSelect(attribute.key, '*', attribute.hits)}
          >
            {iconPlusSmall}
          </div>
        </div>
        <When
          condition={
            attribute.values.length > 0 && expandedAttributeKeys.some((k) => k === attribute.key)
          }
        >
          {attribute.values.map((itemValue) => (
            <div
              key={itemValue.value}
              className={cls(styles.ItemBlock, styles.SpaceBetween, styles.ExpandedBlock)}
            >
              <div className={styles.LeftSideBlock}>
                <span className={styles.Strong}>{itemValue.value}</span>(
                {numSeparator(itemValue.hits)})
              </div>
              <div
                className={styles.PlusBtn}
                onClick={() => onSelect(attribute.key, itemValue.value, itemValue.hits)}
              >
                {iconPlusSmall}
              </div>
            </div>
          ))}
          {attribute.has_more && !isLoadingPageParameters && canLoadMore[attribute.key] ? (
            <div className={styles.LoadMorePageParameters}>
              <Button
                disabled={isLoadingPageParameters}
                className={styles.LoadMore}
                onClick={onLoadMore}
                style={{}}
              >
                {TEXT_LOAD_MORE}
              </Button>
            </div>
          ) : null}
          {isLoadingPageParameters && canLoadMore[attribute.key] && (
            <div className={styles.LoadMorePageParameters}>
              <Button disabled={true} className={styles.LoadMore}>
                Loading...
              </Button>
            </div>
          )}
        </When>
      </div>
    );
  });

  return <div>{attributeList}</div>;
};

AttributesListComponent.propTypes = {
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          hits: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        }),
      ),
      hits: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export const AttributesList = AttributesListComponent;
