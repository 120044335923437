import { createAction } from 'redux-actions';
import RequestService from 'react-project/Helpers/RequestService';

export const setReportSending = createAction('REPORT_SENDING');

const requestService = new RequestService();

export const sendReportClicked = () => {
  return async (dispatch) => {
    dispatch(setReportSending(true));
  };
};

export const sendReportRequestAction = ({ reportData }) => {
  return async (dispatch) => {
    await requestService.sendReport(reportData);
    dispatch(setReportSending(false));
  };
};

export const downloadReportRequestAction = ({ reportData }) => {
  return async () => {
    await requestService.downloadReport(reportData);
  };
};
