import React, { forwardRef, useEffect } from 'react';
import styles from './UILock.module.scss';
import { lockIcon } from 'react-project/assets/Icons';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import { useSelector } from 'react-redux';
import { RR_SHOW_UPSELL_POPUP } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';

export const LOCK_NO_ACTIONS = 'LOCK_NO_ACTIONS'; // lock for no actions
export const LOCK_NO_ANALYTICS = 'LOCK_NO_ANALYTICS'; // lock for no analytics
export const LOCK_NO_PLANNING = 'LOCK_NO_PLANNING'; // lock for no planning

export const LOCK_NO_COMPARE_PEOPLE = 'LOCK_NO_COMPARE_PEOPLE'; // lock for no compare by people
export const LOCK_NO_COMPARE_DATE = 'LOCK_NO_COMPARE_DATE'; // lock for no compare by date
export const LOCK_NO_PEOPLE_FILTER = 'LOCK_NO_PEOPLE_FILTER'; // lock for no people filter
export const LOCK_NO_TRAFFIC_MODE = 'LOCK_NO_TRAFFIC_MODE'; // lock for no traffic mode
export const LOCK_NO_CSV_EXPORT = 'LOCK_NO_CSV_EXPORT'; // lock for no csv export
export const LOCK_NO_PEOPLE_DATA_EXPORT = 'LOCK_NO_PEOPLE_DATA_EXPORT'; // lock for no people data export
export const LOCK_NO_PURCHASE_TRACKING = 'LOCK_NO_PURCHASE_TRACKING';
export const LOCK_NO_NOTES = 'LOCK_NO_NOTES'; // lock for no notes
export const LOCK_NO_CHECKLIST = 'LOCK_NO_CHECKLIST'; // lock for no checklist
export const LOCK_NO_FORECASTING = 'LOCK_NO_FORECASTING'; // lock for no forecasting
export const LOCK_NO_VIEW_ONLY = 'LOCK_NO_VIEW_ONLY'; // lock for no view only
export const LOCK_NO_PNG_EXPORT = 'LOCK_NO_PNG_EXPORT'; // lock for no png export
export const LOCK_NO_ICONS = 'LOCK_NO_ICONS'; // lock for no icons

export const UILock = forwardRef((props, ref) => {
  const canvasPermissions = useSelector(selectCanvasPermissions);
  const [isContentLocked, setIsContentLocked] = React.useState(false);

  const { children, lock = null, isLocked, onClick, contentStyle, ...rest } = props;

  useEffect(() => {
    if (isLocked !== undefined) {
      setIsContentLocked(isLocked);
      return;
    }

    if (lock === null) {
      setIsContentLocked(true);
    } else if (lock === LOCK_NO_ACTIONS) {
      setIsContentLocked(!canvasPermissions.isActionsAllowed);
    } else if (lock === LOCK_NO_ANALYTICS) {
      setIsContentLocked(!canvasPermissions.isAnalyticsAllowed);
    } else if (lock === LOCK_NO_PLANNING) {
      setIsContentLocked(!canvasPermissions.isPlanningAllowed);
    } else if (lock === LOCK_NO_COMPARE_PEOPLE) {
      setIsContentLocked(!canvasPermissions.peopleCompareFilterAllowed);
    } else if (lock === LOCK_NO_COMPARE_DATE) {
      setIsContentLocked(!canvasPermissions.dateCompareFilterAllowed);
    } else if (lock === LOCK_NO_PEOPLE_FILTER) {
      setIsContentLocked(!canvasPermissions.peopleFilterAllowed);
    } else if (lock === LOCK_NO_TRAFFIC_MODE) {
      setIsContentLocked(!canvasPermissions.trafficModeAllowed);
    } else if (lock === LOCK_NO_CSV_EXPORT) {
      setIsContentLocked(!canvasPermissions.peopleCsvExportAllowed);
    } else if (lock === LOCK_NO_PEOPLE_DATA_EXPORT) {
      setIsContentLocked(!canvasPermissions.peopleDataExportAllowed);
    } else if (lock === LOCK_NO_PURCHASE_TRACKING) {
      setIsContentLocked(!canvasPermissions.purchaseTrackingAllowed);
    } else if (lock === LOCK_NO_NOTES) {
      setIsContentLocked(!canvasPermissions.notesAllowed);
    } else if (lock === LOCK_NO_CHECKLIST) {
      setIsContentLocked(!canvasPermissions.checklistAllowed);
    } else if (lock === LOCK_NO_FORECASTING) {
      setIsContentLocked(!canvasPermissions.isForecastAllowed);
    } else if (lock === LOCK_NO_VIEW_ONLY) {
      setIsContentLocked(!canvasPermissions.viewOnlyAllowed);
    } else if (lock === LOCK_NO_PNG_EXPORT) {
      setIsContentLocked(!canvasPermissions.pngExportAllowed);
    } else if (lock === LOCK_NO_ICONS) {
      setIsContentLocked(!canvasPermissions.iconsAllowed);
    } else {
      setIsContentLocked(false);
    }
  }, [canvasPermissions, isLocked]);

  const onLockedItemClicked = (e) => {
    commonSendEventFunction(RR_SHOW_UPSELL_POPUP);
    // execute onClick
    onClick && onClick(e);
  };

  return isContentLocked ? (
    <div {...rest} ref={ref} className={styles.LockingWrapper} onClick={onLockedItemClicked}>
      <div className={styles.LockingContent} style={contentStyle}>
        {children}
      </div>
      <div className={styles.LockIcon}>{lockIcon}</div>
    </div>
  ) : (
    React.cloneElement(children, { ref, ...rest })
  );
});
