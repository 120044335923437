import { length } from 'ramda';
import { determineDepth } from 'react-project/features/facebookIntegration/helpers';

export const getDepthClassNames = (depths) => (depth) => {
  if (depth < 0 || depth > 2) {
    return null;
  }
  return depths[depth];
};

export const formatToTierFromCategoryOfAd = (data) => ({
  ...data,
  depth: determineDepth(data.type),
  childCount: length(data.childResultsById),
  checked: false,
  expanded: false,
});

export const filterForExclusion = (item) => {
  const filterType = item.type === adType.AD;
  const emptyUtmParams = item.utmParameters?.length === 0;
  return filterType && emptyUtmParams;
};

export const adType = {
  AD: 'ad',
  ADSET: 'adset',
  CAMPAIGN: 'campaign',
};
