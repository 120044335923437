import * as PIXI from 'pixi.js';
import { CountriesListArr } from 'shared/CSharedConstants';
import { toPercent } from 'shared/CSharedMethods';
import { numSeparator } from 'shared/NumberHelpers';
import Styles, { COLOR_WIDGET_SUBTEXT, COLOR_WIDGET_TEXT, COLOR_WIDGET_ZEBRA } from '../Styles';
import TableRow from './TableView/TableRow';

export default class TableRowTopCountries extends TableRow {
  initRow() {
    super.initRow();

    this.cellContentSpacing = 10;

    this.countryIcon = new PIXI.Sprite();
    this.countryIcon.anchor.y = 0.5;
    this.addChild(this.countryIcon);

    this.countryName = new PIXI.BitmapText('', Styles.WIDGET_LABEL);
    this.countryName.tint = COLOR_WIDGET_TEXT;
    this.countryName.anchor.y = 0.5;
    this.addChild(this.countryName);

    this.peoplePercent = new PIXI.BitmapText('', Styles.WIDGET_BOLD_LABEL);
    this.peoplePercent.tint = COLOR_WIDGET_TEXT;
    this.peoplePercent.anchor.y = 0.5;
    this.addChild(this.peoplePercent);

    this.peopleTotal = new PIXI.BitmapText('', Styles.WIDGET_LABEL);
    this.peopleTotal.tint = COLOR_WIDGET_SUBTEXT;
    this.peopleTotal.anchor.y = 0.5;
    this.addChild(this.peopleTotal);
  }

  bindData() {
    const countryAttr = CountriesListArr.find((attrs) => attrs.name === this.data.name);
    const countryCode = countryAttr ? countryAttr.iso2.toLowerCase() : '';

    this.countryIcon.setTexture(`flag/${countryCode}`);
    this.countryIcon.fitTo(25, 25);
    this.countryName.text = this.data.name;
    this.peoplePercent.text = toPercent(this.data.percent, 2);
    this.peopleTotal.text = numSeparator(this.data.hits);
  }

  updatePositions() {
    const rh = this.rowSize.height;

    let cellX = this.columnSpacing;
    this.alignElementsInCell(
      [this.countryIcon, this.countryName],
      cellX,
      this.headerData[0].calculatedWidth,
      rh,
      this.cellContentSpacing,
      this.headerData[0].align,
    );

    cellX += this.headerData[0].calculatedWidth + this.columnSpacing;
    this.alignElementsInCell(
      [this.peoplePercent, this.peopleTotal],
      cellX,
      this.headerData[1].calculatedWidth,
      rh,
      this.cellContentSpacing,
      this.headerData[1].align,
    );
  }
}
