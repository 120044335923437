export default function addValue(map, values) {
  values.map((element) => {
    map[element] = element;
    return element;
  });
}

export function debounce(method, latencyTime) {
  let timer = null;
  let args = null;
  let context = null;

  const debouncedFn = function () {
    context = this;
    args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      method.apply(context, args);
      timer = null;
    }, latencyTime);
  };

  // Cancel the current debounced function if it exists
  const cancel = function () {
    clearTimeout(timer);
    timer = null;
  };

  // Execute immediately if the function is not debounced
  const flush = function () {
    clearTimeout(timer);
    method.apply(context, args);
    timer = null;
  };

  // Check if the function is pending execution
  const isPending = function () {
    return timer !== null;
  };

  debouncedFn.cancel = cancel;
  debouncedFn.isPending = isPending;
  debouncedFn.flush = flush;

  return debouncedFn;
}

export const shortenText = (str, len = 30) => {
  if (str.length > len) {
    return str.toString().slice(0, len) + '...';
  } else {
    return str;
  }
};
