import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './TrackingTabAction.module.scss';
import { TEXT_COPY_API_KEY, TEXT_COPY_TO_CLIPBOARD } from 'react-project/Constants/texts';
import { SetupDescription } from './SetupDescription';

export const TrackingSetup = ({
  apiKey,
  setupTitle,
  setupDescription,
  setupInstructionsLink,
  setupLinkDescription,
}) => {
  const textAreaRef = useRef(null);
  const copyToClipBoard = () => {
    textAreaRef.current.select();
    document.execCommand('copy');
  };

  return (
    <div className={styles.Setup}>
      <h6 className={styles.Title}>{setupTitle}</h6>
      <div className={styles.SetupContent}>
        <SetupDescription
          setupDescription={setupDescription}
          setupInstructionsLink={setupInstructionsLink}
          setupLinkDescription={setupLinkDescription}
        />
        {apiKey && (
          <div className={styles.ApiKey}>
            <div className={styles.ApiKeyHeader}>
              <span>{TEXT_COPY_API_KEY}</span>
              <button onClick={copyToClipBoard} className={styles.CopyApiKeyToClipboardButton}>
                {TEXT_COPY_TO_CLIPBOARD}
              </button>
            </div>
            <textarea
              ref={textAreaRef}
              className={styles.ApiKeyTextarea}
              rows="4"
              readOnly
              value={apiKey}
            />
          </div>
        )}
      </div>
    </div>
  );
};

TrackingSetup.propTypes = {
  apiKey: PropTypes.string,
  setupInstructionsLink: PropTypes.string.isRequired,
  setupLinkDescription: PropTypes.string.isRequired,
  setupTitle: PropTypes.string.isRequired,
  setupDescription: PropTypes.string.isRequired,
};
