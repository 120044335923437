import React from 'react';
import { TEXT_PLACEHOLDER_EMAILS } from '../../../Constants/texts';
import { ClickOutsideCustomComponent } from '../../../Util/ClickOutsideCustom';

const LIMIT_EMAIL_LIST = 5;

const EmailInputComponent = ({
  emails,
  textEmail,
  onKeyDown,
  onChangeEmailText,
  onClickOutside,
}) => {
  const totalEmails = emails.length;

  return (
    <ClickOutsideCustomComponent
      onClickOutside={() => {
        onClickOutside(textEmail);
      }}
      ignoreClickOutside={textEmail.length < 1}
    >
      {totalEmails < 2 && (
        <input
          placeholder={!totalEmails ? TEXT_PLACEHOLDER_EMAILS : null}
          onKeyDown={onKeyDown}
          onChange={onChangeEmailText}
          value={textEmail}
        />
      )}
      {totalEmails < LIMIT_EMAIL_LIST && totalEmails > 1 && (
        <textarea
          autoFocus={true}
          onKeyDown={onKeyDown}
          onChange={onChangeEmailText}
          value={textEmail}
        />
      )}
    </ClickOutsideCustomComponent>
  );
};

export const EmailInput = EmailInputComponent;
