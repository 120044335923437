import Migration2 from './Migration2';
import Migration3 from './Migration3';
import Migration4 from './Migration4';
import Migration5 from './Migration5';
import Migration6 from './Migration6';

export default class VersionMigration {
  constructor(latestVersion) {
    this.latestVersion = latestVersion;
    // Any new data structure changes should be added here as a new migration
    // all migrations need to be added in order
    this._migrations = [Migration2, Migration3, Migration4, Migration5, Migration6];
    if (latestVersion <= this._migrations.length) {
      console.error(
        `MIGRATION ERROR:
        latestVersion should be greater than the number of migrations
        Please update the CANVAS_VERSION variable
        latestVersion is ${latestVersion} and needs to be set to ${this._migrations.length + 1}
        `,
      );

      // Or this might be the case when we are downgrading the version in case of a rollback
      // TODO Handle rollbacks here if needed
    }
  }

  migrate(data) {
    // If the data is at the latest version, return it
    // Otherwise, run the migrations

    let version = data.version || 1;
    if (version >= this.latestVersion) {
      return data;
    }

    // Don't instantiate the migrations until they're needed
    // This is a performance optimization
    for (let i = 0; i < this._migrations.length; i++) {
      const migrationClass = this._migrations[i];
      const migration = new migrationClass();
      if (migration.version > version) {
        data = migration.migrate(data);
        data.version = migration.version;
      }
    }

    return data;
  }
}
