import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  selectIsStepModalOpened,
  selectIsStepModalPinned,
  selectStepModalData,
} from 'react-project/redux/modal/selectors';
import {
  setIsStepModalOpened,
  setIsStepModalPinned,
  setStepModalData,
} from 'react-project/redux/modal/actions';
import { StepsModal } from 'react-project/StepsModal/StepsModal';
import { EXPLORER_ITEMS, EXPLORER_TAB_TO_ITEMS } from 'shared/CSharedConstants';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';

const DEFAULT_POSITION = { x: 5, y: 5 };

const Modal = ({
  selectIsStepModalOpened = false,
  selectIsStepModalPinned,
  selectStepModalData,
  canvasPermissions,
}) => {
  const {
    isParentHeader,
    coords = DEFAULT_POSITION,
    createObjectPositions = DEFAULT_POSITION,
    funnel,
    onCloseModal,
    canExplore,
    activeStepsModalTab,
    onStepsModalTabChanged,
    hasWorkspaceActions,
    port,
    modalSource,
  } = selectStepModalData;

  const defaultItem = EXPLORER_ITEMS.NEXT_PAGES_ITEMS[0];

  const [item, setItem] = useState(port ? EXPLORER_TAB_TO_ITEMS.PAGE.PAGE[port] : defaultItem);

  const setExplorerDropdownActiveItem = (newItem) => {
    setItem(newItem);
  };

  return (
    <>
      {(selectIsStepModalOpened || (selectIsStepModalPinned && funnel)) &&
      !canvasPermissions.isReadonlyAccess ? (
        <StepsModal
          isParentHeader={isParentHeader}
          coords={coords}
          createObjectP={createObjectPositions}
          funnel={funnel}
          onCloseModal={onCloseModal}
          canExplore={canExplore}
          activeStepsModalTab={activeStepsModalTab}
          onStepsModalTabChanged={onStepsModalTabChanged}
          hasWorkspaceActions={hasWorkspaceActions}
          port={port}
          modalSource={modalSource}
          explorerDropdownActiveItem={item || defaultItem}
          setExplorerDropdownActiveItem={setExplorerDropdownActiveItem}
        />
      ) : null}
    </>
  );
};

function mapStateToProps(state) {
  return {
    selectIsStepModalOpened: selectIsStepModalOpened(state),
    selectIsStepModalPinned: selectIsStepModalPinned(state),
    selectStepModalData: selectStepModalData(state),
    canvasPermissions: selectCanvasPermissions(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setIsStepModalOpened: (status) => dispatch(setIsStepModalOpened(status)),
    setIsStepModalPinned: (status) => dispatch(setIsStepModalPinned(status)),
    setStepModalData: (data) => dispatch(setStepModalData(data)),
  };
}

Modal.propTypes = {
  selectIsStepModalOpened: PropTypes.bool,
  selectIsStepModalPinned: PropTypes.bool,
  selectStepModalData: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
