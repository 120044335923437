import md5 from 'md5';

export const createCustomActionKey = (requestData) => {
  const keyData = [];
  let customActionKey = '';

  // This method goes and picks important parts from the data
  // that matter to make a unique request.
  // This is becuase the same step ( equal configuration ) will have
  // different id or label , but the analytics data will actually be the same
  // so in that case , only the element configuration matters.
  // The key is then created based on relevant data.

  if (
    requestData &&
    requestData.filter &&
    requestData.filter.canvas &&
    requestData.filter.canvas.dictionary
  ) {
    const dictKeys = Object.keys(requestData.filter.canvas.dictionary);
    if (requestData.filter.focus) {
      for (let i = 0; i < dictKeys.length; i++) {
        extractDataForElement(requestData.filter.canvas.dictionary[dictKeys[i]], keyData);
      }
      keyData.push(requestData.filter.focus);
    }

    if (dictKeys.length === 1) {
      extractDataForElement(requestData.filter.canvas.dictionary[dictKeys[0]], keyData);
    }
  }

  // look for explored step
  if (requestData && requestData.filter && requestData.filter.explored_step) {
    // keyData.push(requestData.filter.explored_step.id);
    extractDataForElement(requestData.filter.explored_step, keyData);
  }

  // Consider search text for caching
  if (requestData && requestData.filter?.explorer?.search) {
    keyData.push(requestData.filter.explorer.search);
  }

  // Consider page no for caching
  if (requestData && requestData.filter?.pagination?.page) {
    keyData.push(requestData.filter.pagination.page);
  }

  // Consider limit for caching
  if (requestData && requestData.filter?.pagination?.limit) {
    keyData.push(requestData.filter.pagination.limit);
  }

  // Add Filter Date Range for caching
  if (requestData && requestData.filter?.range) {
    keyData.push(requestData.filter.range);
  }

  // Facebook campaign caching ONLY
  if (
    requestData?.projectId &&
    requestData?.timeRange &&
    !requestData?.searchText &&
    !requestData?.campaignId
  ) {
    keyData.push(requestData.projectId, requestData.timeRange);
  }

  // Facebook ad search caching
  if (requestData && requestData.searchText && requestData.timeRange) {
    keyData.push(requestData.searchText, requestData.timeRange);
  }

  // Facebook ad sets caching
  if (requestData && requestData.campaignId && requestData.timeRange) {
    keyData.push(requestData.campaignId, requestData.timeRange);
  }

  if (requestData && requestData.filter?.selected_element) {
    keyData.push(requestData.filter?.selected_element);
  }

  if (requestData && requestData.cacheType) {
    keyData.push(requestData.cacheType);
  }

  if (keyData.length) {
    customActionKey = md5(JSON.stringify(keyData));
  }

  return customActionKey;
};

const extractDataForElement = (object, keyData) => {
  if (object.type === 'event') {
    extractKeyDataFromEvent(object, keyData);
  } else if (object.type === 'page') {
    extractKeyDataFromPage(object, keyData);
  } else if (object.type === 'source') {
    extractKeyDataFromSource(object, keyData);
  }
};

// EVENT
const extractKeyDataFromEvent = (object, keyData) => {
  if (object.name) {
    keyData.push(object.name);
  }

  object.attributes?.forEach((a) => {
    keyData.push(a.key);
    keyData.push(a.contains);
    keyData.push(a.operator);
    if (a.value !== '') {
      keyData.push(a.value);
    }
  });
};

// PAGE
const extractKeyDataFromPage = (object, keyData) => {
  keyData.push(object.type);
  keyData.push(object.url);
  keyData.push(object.is_commerce);
};

// SOURCE
const extractKeyDataFromSource = (object, keyData) => {
  keyData.push(object.type);
  keyData.push(object.url);
  keyData.push(object.is_commerce);
  object.attributes?.forEach((a) => {
    keyData.push(a.key);
    keyData.push(a.contains);
    keyData.push(a.operator);
    if (a.value !== '') {
      keyData.push(a.value);
    }
  });
};
