export const addObjectToFormData = (obj, rootName, formData, ignoreList) => {
  const appendFormData = (data, root) => {
    if (!ignore(root)) {
      root = root || '';
      if (Array.isArray(data)) {
        appendFormData(data.join(', '), root);
      } else if (typeof data === 'object' && data) {
        for (let key in data) {
          if (data.hasOwnProperty(key)) {
            if (root === '') {
              appendFormData(data[key], key);
            } else {
              appendFormData(data[key], root + '.' + key);
            }
          }
        }
      } else {
        if (data !== null && typeof data !== 'undefined') {
          formData.append(root, data);
        }
      }
    }
  };

  const ignore = (root) => {
    return (
      Array.isArray(ignoreList) &&
      ignoreList.some(function (x) {
        return x === root;
      })
    );
  };

  appendFormData(obj, rootName);

  return formData;
};
