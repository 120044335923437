import React, { forwardRef } from 'react';
import {
  VERTICAL_ALIGN_CENTER,
  ViewportConstrainer,
} from 'react-project/components/viewportConstrainer/ViewportConstrainer';
import styles from './VerticalMenu.module.scss';

export const VerticalMenu = forwardRef(({ sectionStyle, children }, ref) => {
  return (
    <ViewportConstrainer verticalAlign={VERTICAL_ALIGN_CENTER} relativeRectangle={sectionStyle}>
      <div ref={ref} className={styles.Menu}>
        <div className={styles.MenuWrapper}>{children}</div>
      </div>
    </ViewportConstrainer>
  );
});
