import React from 'react';
import styles from './MoreActions.module.scss';
import { ViewportConstrainer } from 'react-project/components/viewportConstrainer/ViewportConstrainer';
import { CANVAS_ACTION_NAMES } from 'shared/canvasActionNames';
import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';
import { getHotkeyForAction } from 'react-project/Util/HotkeysUtil';
import { HotKey } from 'react-project/components/dropdown/HotKey';
import {
  iconAddChild,
  iconAddSibling,
  iconCopyAction,
  iconIndent,
  iconMoveDown,
  iconMoveUp,
  iconOutdent,
  iconTrashcan,
} from 'react-project/assets/Icons';

const LABELS = {
  ADD_SIBLING: 'Add Sibling',
  ADD_CHILD: 'Add Child',
  COPY: 'Copy',
  DELETE: 'Delete',
  INDENT: 'Indent',
  OUTDENT: 'Outdent',
  MOVE_UP: 'Move Up',
  MOVE_DOWN: 'Move Down',
};

export const MoreActions = ({
  itemData,
  relativeElement,
  onAddSibling,
  onAddChild,
  onIndent,
  onOutdent,
  onMoveUp,
  onMoveDown,
  onClickOutside,
  onDelete,
  onCopy,
}) => {
  const canOutdent = itemData.depth > 0;

  const items = [
    {
      label: LABELS.ADD_SIBLING,
      icon: iconAddSibling,
      shortcut: CANVAS_ACTION_NAMES.CHECKLIST_ADD_SIBLING,
      onClick: onAddSibling,
    },
    {
      label: LABELS.ADD_CHILD,
      icon: iconAddChild,
      shortcut: CANVAS_ACTION_NAMES.CHECKLIST_ADD_CHILD,
      onClick: onAddChild,
    },
    { isSeparator: true },
    {
      label: LABELS.COPY,
      icon: iconCopyAction,
      shortcut: CANVAS_ACTION_NAMES.CHECKLIST_COPY,
      onClick: onCopy,
    },
    {
      label: LABELS.DELETE,
      icon: iconTrashcan,
      shortcut: CANVAS_ACTION_NAMES.CHECKLIST_DELETE,
      onClick: onDelete,
    },
    { isSeparator: true },
    {
      label: LABELS.INDENT,
      icon: iconIndent,
      shortcut: CANVAS_ACTION_NAMES.CHECKLIST_INDENT,
      onClick: onIndent,
    },
    canOutdent && {
      label: LABELS.OUTDENT,
      icon: iconOutdent,
      shortcut: CANVAS_ACTION_NAMES.CHECKLIST_OUTDENT,
      onClick: onOutdent,
    },
    { isSeparator: true },
    {
      label: LABELS.MOVE_UP,
      icon: iconMoveUp,
      shortcut: CANVAS_ACTION_NAMES.CHECKLIST_MOVE_UP,
      onClick: onMoveUp,
    },
    {
      label: LABELS.MOVE_DOWN,
      icon: iconMoveDown,
      shortcut: CANVAS_ACTION_NAMES.CHECKLIST_MOVE_DOWN,
      onClick: onMoveDown,
    },
  ];

  return (
    <ViewportConstrainer relativeElement={relativeElement}>
      <ClickOutsideCustomComponent onClickOutside={onClickOutside}>
        <div className={styles.Menu}>
          {items.map((item, index) => {
            if (item) {
              const hotkey = getHotkeyForAction(item.shortcut);
              return item.isSeparator ? (
                <div key={index} className={styles.Separator} />
              ) : (
                <div key={index} onClick={item.onClick} className={styles.Item}>
                  <div className={styles.Icon}>{item.icon}</div>
                  <div className={styles.Label}>{item.label}</div>
                  <div className={styles.Shortcut}>{hotkey && <HotKey {...hotkey} />}</div>
                </div>
              );
            }
          })}
        </div>
      </ClickOutsideCustomComponent>
    </ViewportConstrainer>
  );
};
