import * as PIXI from 'pixi.js';

export const SINGLE_SELECTION_BOUNDS_WIDTH = 2;

export default class BaseSelectionLayer extends PIXI.Container {
  constructor(delegate) {
    super();
    this.frames = []; // it will hold frame data
    this.delegate = delegate;
  }

  hoverIn(event, element) {
    // Abstract method
  }

  hoverOut(event, element) {
    // Abstract method
  }

  updatePositions() {
    this.drawAll();
  }

  getLineColor() {
    return 0;
  }

  drawAll() {
    for (let i = 0; i < this.frames.length; i++) {
      const selectionFrame = this.frames[i];
      let lineColor = this.getLineColor(selectionFrame);
      const p = window.app.viewport.toGlobal(selectionFrame.element);
      selectionFrame.position.set(p.x, p.y);
      selectionFrame.draw(lineColor, SINGLE_SELECTION_BOUNDS_WIDTH);
    }
    window.app.needsRendering();
  }

  findFrameByElementId(id) {
    for (let i = 0; i < this.frames.length; i++) {
      const selectionFrame = this.frames[i];
      if (selectionFrame.element.id === id) {
        return selectionFrame;
      }
    }
    return null;
  }

  hideFrameByElementId(id) {
    this.showFrameByElementId(id, false);
  }

  showFrameByElementId(id, visibitily = true) {
    for (let i = 0; i < this.frames.length; i++) {
      const selectionFrame = this.frames[i];
      if (selectionFrame.element.id === id) {
        selectionFrame.visibleFrame = visibitily;
        break;
      }
    }
    this.drawAll();
  }

  removeFrameByElementId(id) {
    for (let i = this.frames.length - 1; i >= 0; i--) {
      const selectionFrame = this.frames[i];
      if (selectionFrame.element.id === id) {
        this.frames.splice(i, 1);
        selectionFrame.removeFromParent();
        selectionFrame.element.onFrameHide();
        break;
      }
    }
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  clear() {
    for (let i = this.frames.length - 1; i >= 0; i--) {
      const selectionFrame = this.frames[i];
      selectionFrame.removeFromParent();
      selectionFrame.element.onFrameHide();
    }
    this.frames = [];
  }

  hasFrames() {
    return this.frames.length ? true : false;
  }
}
