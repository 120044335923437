import * as PIXI from 'pixi.js';
import { EElementCategories, EElementTypes } from 'shared/CSharedCategories';
import BaseContainer, {
  CONTENT_HIT_AREA_PADDING,
} from 'pixi-project/base/containers/BaseContainer';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { PR_STEP_TEXTURE_UPDATED } from 'shared/CSharedEvents';
import AppSignals from 'pixi-project/signals/AppSignals';
import SteppedSpinner from '../SteppedSpinner';
import { getAuthToken } from 'react-project/Util/AuthCookie';
import { getUpdatedPhotoPath } from 'react-project/Util/urlPath';

const THUMBNAIL_POSITION = {
  x: 5,
  y: 55,
};

export default class PhotoContainer extends BaseContainer {
  constructor(photoData, texture, eventHandlers, id) {
    super(eventHandlers, id);
    this.texture = texture;
    this.texturePath = '';
    this.category = EElementCategories.PHOTO;
    this.type = EElementTypes.PHOTO;
    this.photoData = photoData;
    this.spinner = null;
  }

  init() {
    this.draw(this.texture);
    this.cursor = 'move';

    if (this.isCustom) {
      // on Every load we need to featch the latest image path
      // from the icon list , because url will expire after X amount of time.
      // We do that by using the id that is contained in the image path
      AppSignals.fetchCustomIcons.dispatch(this);
    }

    this.titleDisplay.visible = false;

    this.removeChild(this.footer);
    // if its set to visible , it gets taken
    // into account when calculating the selection frame size
    // despite the fact its not there anymore
    this.footer.visible = false;

    if (this.photoData) {
      this._updateThumbnailWithValidToken();

      const texture = PIXI.utils.TextureCache['photo-temp'];
      this.content.texture = texture;
      window.app.needsRendering();
      this.setThumbnail(this.photoData.imageURL);
    }

    this.updateFrameSize();
  }

  createImage(data) {
    this.content = new PIXI.Sprite(data);
    this.addChild(this.content);
    const scale = 0.5;
    this.content.scale.set(scale);
    super.createImage(data);
    this.updateHitArea();
  }

  updateHitArea() {
    const scale = (1 / this.content.scale.x) * (1 / this.scale.x);
    const bounds = this.content.getLocalBounds();
    const padding = CONTENT_HIT_AREA_PADDING * scale;
    let footerHeight = 0;
    if (this.footer && this.footer.visible) {
      footerHeight = this.footer.getFooterHeight() * scale;
    }
    this.content.hitArea = new PIXI.Rectangle(
      -padding,
      -padding,
      bounds.width + padding * 2,
      bounds.height + padding * 2 + footerHeight,
    );
  }

  getState() {
    super.getState();
    this.stateData.photoData = this.photoData;
    return this.stateData;
  }

  /**
   * Create a texture of a needed size. Texture must take all available space by width.
   * Bottom part should be trimmed if it takes more space then needed.
   * @param texture
   * @returns {{innerScale: PIXI.Point, adjustedTexture: PIXI.Texture}}
   * @private
   */
  _generateThumbnailTexture(texture) {
    const innerScale = new PIXI.Point(1, 1);
    let adjustedTexture = texture;
    // no adjustments will be done to the photos
    return { adjustedTexture, innerScale };
  }

  onPhotoLoading() {
    const texture = PIXI.utils.TextureCache['photo-temp'];
    this.content.texture = texture;

    const spinner = new SteppedSpinner();
    spinner.tint = 0x545454;
    spinner.scale.set(4);
    spinner.x = this.content.width;
    spinner.y = this.content.height;
    this.spinner = spinner;
    spinner.start();
    this.content.addChild(spinner);

    window.app.needsRendering();
  }

  onPhotoDataChanged(data) {
    this.photoData = data;
    this.setThumbnail(data.imageURL);
  }

  stopSpinner() {
    if (this.spinner) {
      this.spinner.stop();
      this.spinner.removeFromParent();
      this.spinner.destroy();
      this.spinner = null;
      window.app.needsRendering();
    }
  }

  /**
   * Instead of standard texture apply a black page one with a thumbnail of the content
   * @param thumbnailURL - URL to the content thumbnail
   */
  setThumbnail(thumbnailURL) {
    this.loadThumbnail(thumbnailURL, (texture) => {
      this.thumbnailURL = thumbnailURL;
      const thumbnail = this.generateThumbnail(texture);
      this.setThumbnailSprite(thumbnail);

      if (this.photoData.legacySize) {
        let x = this.photoData.legacySize.width / thumbnail.texture.width;
        delete this.photoData.legacySize;
        // x2 to compensate for the default inner scale of 0.5
        // x1.4 is determined by trial and error to match the original
        this.scale.set(x * 2 * 1.4);
      }

      this.stopSpinner();
    });
  }

  loadThumbnail(thumbnailURL, callback) {
    PIXI.Texture.fromURL(thumbnailURL)
      .then(callback)
      .catch((result) => {
        console.log(`[PhotoContainer.setTexture] Error loading`, thumbnailURL);
        const texture = PIXI.utils.TextureCache['photo-missing'];
        this.content.texture = texture;
        this.stopSpinner();
        window.app.needsRendering();
      });
  }

  setThumbnailSprite(thumbnail) {
    this.thumbnail = thumbnail;

    this.content.removeChildren();
    this.content.texture = thumbnail.texture;
    this.updateHitArea();

    commonSendEventFunction(PR_STEP_TEXTURE_UPDATED, this);
    window.app.needsRendering();
  }

  generateThumbnail(texture) {
    const { adjustedTexture, innerScale } = this._generateThumbnailTexture(texture);

    // Apply attributes to the inner thumbnail
    const thumbnailSprite = new PIXI.Sprite(adjustedTexture);
    thumbnailSprite.scale = innerScale;
    thumbnailSprite.position = new PIXI.Point(
      this.content.position.x + THUMBNAIL_POSITION.x,
      this.content.position.y + THUMBNAIL_POSITION.y,
    );
    return thumbnailSprite;
  }

  setTexturePath(texturePath) {
    this.removeThumbnail();

    this.texturePath = texturePath;
    this.content.setTexture(texturePath);
    commonSendEventFunction(PR_STEP_TEXTURE_UPDATED, this);
    window.app.needsRendering();
  }
  /**
   * Update values for an element
   * @param data
   */
  updateObject(data) {
    super.updateObject(data);
  }

  addEvents() {
    super.addEvents();
  }

  /**
   * It is possible, that for some images imageURL was stored
   * with accessToken from previous session.
   * This will bring an error, when token was expired and
   * GET image endpoint will return 401 error.
   * To fix that error, this method will be used in order to update
   * token parameter with actual accessToken value for current photoData object.
   */
  _updateThumbnailWithValidToken() {
    const tokenKey = 'token';
    const accessToken = getAuthToken();

    if (!accessToken) {
      return;
    }

    const url = new URL(this.photoData.imageURL);
    url.searchParams.delete(tokenKey);
    url.searchParams.set(tokenKey, accessToken);
    url.pathname = getUpdatedPhotoPath(url.pathname);

    this.photoData.imageURL = url.toString();
  }
}
