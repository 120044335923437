import React, { useEffect } from 'react';
import styles from 'react-project/components/sendReportPopup/SendReportPopup.module.scss';
import { iconVector } from '../../../assets/Icons';
import cls from 'classnames';
import { TEXT_SEND } from '../../../Constants/texts';
import { Loader } from '../../loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsReportSending } from 'react-project/redux/report/selectors';
import { setReportSending } from 'react-project/redux/report/actions';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { RP_EVENT_SEND_REPORT_CLICKED } from 'shared/CSharedEvents';

const ButtonSendComponent = ({ emails, reportName }) => {
  const totalEmails = emails.length;
  const isOneLine = totalEmails < 2;
  const isValid = totalEmails > 0 && reportName.length > 0;
  const isReportSending = useSelector(selectIsReportSending);
  const dispatch = useDispatch();

  const clickButton = () => {
    if (isValid && !isReportSending) {
      dispatch(setReportSending(true));
    }
  };

  useEffect(() => {
    if (isReportSending) {
      // workaround to make the loader seem less stuck.
      setTimeout(() => {
        commonSendEventFunction(RP_EVENT_SEND_REPORT_CLICKED);
      }, 500);
    }
  }, [isReportSending]);

  const buttonClassName = cls(styles.ButtonSend, {
    [styles.ButtonDisable]: !isValid || isReportSending,
  });

  return (
    <div
      className={cls(styles.ButtonSendContainer, {
        [styles.ButtonCenter]: isOneLine,
      })}
    >
      <div onClick={clickButton} className={buttonClassName}>
        {isReportSending && <Loader variant="grey-font-1" />}
        {!isReportSending && (
          <>
            <span className={styles.Label}>{TEXT_SEND}</span> <span>{iconVector}</span>
          </>
        )}
      </div>
    </div>
  );
};

export const ButtonSend = ButtonSendComponent;
