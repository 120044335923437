import styles from './FilterBtn.module.scss';
import {
  iconAnalyticsWarning,
  iconCheckmark,
  iconFilter,
  iconFilterCompare,
  lockIcon,
} from 'react-project/assets/Icons';
import { Tooltip } from 'react-project/components/tooltip/Tooltip';
import React, { useEffect } from 'react';
import cls from 'classnames';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { RP_EVENT_LAYER_TOGGLED, RR_SHOW_TUTORIAL_POPUP } from 'shared/CSharedEvents';
import { useDispatch, useSelector } from 'react-redux';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import { defaultFilters } from 'react-project/redux/filters/actions';
import {
  selectApplyCompareButtonEnabled,
  selectFilters,
  selectIsFiltersLoaded,
} from 'react-project/redux/filters/selectors';
import { When } from 'react-project/Util/When';
import { selectFunnelConfiguration } from 'react-project/redux/funnel-configuration/selectors';
import { cleanAndCompare } from 'react-project/LeftSideBar/Filters/FilterHelpers';
import { selectHasAnalyticsData } from 'react-project/redux/analytics/selectors';
import { setActiveDataLayer } from 'react-project/redux/funnel-configuration/actions';
import { LayerType } from 'react-project/LayersMenu/LayerType';
import {
  LOCK_NO_ANALYTICS,
  LOCK_NO_COMPARE_PEOPLE,
  LOCK_NO_PEOPLE_FILTER,
  UILock,
} from '../uilock/UILock';

export const FilterBtn = ({ onClick, isActive, isDisabled, isCompare, id }) => {
  const canvasPermissions = useSelector(selectCanvasPermissions);
  const allFilters = useSelector(selectFilters);
  const funnelConfiguration = useSelector(selectFunnelConfiguration);
  const isFiltersLoaded = useSelector(selectIsFiltersLoaded);
  const hasAnalyticsData = useSelector(selectHasAnalyticsData);
  const applyButtonForceEnabled = useSelector(selectApplyCompareButtonEnabled);
  const { currentFilters, previousFilters } = allFilters;
  const selectedCompareFilter = funnelConfiguration.selectedCompareFilter;
  const [lastToggleData, setLastToggleData] = React.useState(null);

  const dispatch = useDispatch();

  // This code is related only to the forecasting data layer
  useEffect(() => {
    // only under the compare filter btn
    if (isCompare) {
      // forecasting layer can't be active when compare filter is selected
      if (funnelConfiguration.selectedCompareFilter) {
        // turn off forecasting layer

        dispatch(setActiveDataLayer({ type: LayerType.FORECASTING, isActive: false }));
        const data = {
          type: LayerType.FORECASTING,
          isActive: false,
        };
        setLastToggleData(data);
      }
    }
  }, [funnelConfiguration.selectedCompareFilter]);

  // This code is related only to the forecasting data layer
  useEffect(() => {
    // The event for toggling the layer need to be sent once the state is updated
    // it is especially important because of MainStorage.setTogglePanelStatus(visibleDataLayers);

    if (lastToggleData) {
      commonSendEventFunction(RP_EVENT_LAYER_TOGGLED, lastToggleData);
    }
  }, [lastToggleData]);

  const onBtnClicked = () => {
    const isAnalyticsAllowed = canvasPermissions.isAnalyticsAllowed;
    if (isAnalyticsAllowed === null) {
      console.warn('Waiting to load data from server');
      return;
    } else if (!hasAnalyticsData) {
      commonSendEventFunction(RR_SHOW_TUTORIAL_POPUP);
      return;
    }

    onClick();
  };

  const tabsClassName = cls(styles.ToolButton, {
    [styles.Active]: isActive,
    [styles.Disabled]: isDisabled,
  });

  // In the case of compare filter when there is no filter selected ,
  // we want to show the default filter as applied ( meaning that no status icon will be displayed )
  // for all the other cases a comparison is needed
  let isDefaultFilter =
    isCompare && selectedCompareFilter === false
      ? true
      : cleanAndCompare(previousFilters, defaultFilters, isCompare, selectedCompareFilter);

  if (isCompare && selectedCompareFilter && selectedCompareFilter.label === 'Date') {
    isDefaultFilter = false; // in compare by date , we never mark as default ,
    // so that we will be able to show the warning icon
  }

  const hasAppliedFilters = cleanAndCompare(
    currentFilters,
    previousFilters,
    isCompare,
    selectedCompareFilter,
  );

  const getStatusIcon = () => {
    if (isCompare) {
      return getStatusIconForCompare();
    } else {
      return getStatusIconForFilters();
    }
  };

  const getStatusIconForFilters = () => {
    if (hasAppliedFilters && !isDefaultFilter) {
      return statusOk();
    } else if (!hasAppliedFilters) {
      return statusWarning();
    }
    return null;
  };

  const getStatusIconForCompare = () => {
    if (applyButtonForceEnabled) {
      return statusWarning();
    }

    if (!hasAppliedFilters) {
      return statusWarning();
    } else if (selectedCompareFilter) {
      return statusOk();
    }

    return null;
  };

  const statusOk = () => {
    return <div className={cls(styles.StatusIcon, styles.StatusIconComplete)}>{iconCheckmark}</div>;
  };

  const statusWarning = () => {
    return (
      <div className={cls(styles.StatusIcon, styles.StatusIconWarrning)}>
        {iconAnalyticsWarning}
      </div>
    );
  };

  return (
    <Tooltip label={isCompare ? 'Compare Filters' : 'Filters'}>
      <UILock lock={isCompare ? false : LOCK_NO_PEOPLE_FILTER}>
        <div id={id} className={tabsClassName} onClick={onBtnClicked}>
          {isCompare ? iconFilterCompare : iconFilter}
          <When condition={isFiltersLoaded}>{getStatusIcon()}</When>
        </div>
      </UILock>
    </Tooltip>
  );
};
