import React from 'react';
import styles from 'react-project/Toolbar/Toolbar.module.scss';
import { When } from 'react-project/Util/When';
import { Loader } from 'react-project/components/loader/Loader';
import { TrackingSettings } from './TrackingSettings';
import { TrackingSetup } from './TrackingSetup';

export const TrackingContent = ({
  trackingTabTitle,
  description,
  apiKey,
  hasWorkspaceActions,
  hasWorkspaceActionsLoading,
  children,
  setupDescription,
  setupTitle,
  setupInstructionsLink,
  setupLinkDescription,
  onClose,
  projectId,
}) => {
  return (
    <>
      <When condition={!hasWorkspaceActionsLoading}>
        <When condition={hasWorkspaceActions}>
          <TrackingSettings
            title={trackingTabTitle}
            description={description}
            onClose={onClose}
            projectId={projectId}
          >
            {children}
          </TrackingSettings>
        </When>
        <When condition={!hasWorkspaceActions}>
          <TrackingSetup
            apiKey={apiKey}
            setupTitle={setupTitle}
            setupDescription={setupDescription}
            setupInstructionsLink={setupInstructionsLink}
            setupLinkDescription={setupLinkDescription}
            onClose={onClose}
          />
        </When>
      </When>
      <When condition={hasWorkspaceActionsLoading}>
        <div className={styles.LoaderWrapper}>
          <Loader variant="grey-font-1" />
        </div>
      </When>
    </>
  );
};
