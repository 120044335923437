import cx from 'classnames';
import React from 'react';
import { iconHorizontalArrows } from 'react-project/assets/Icons';
import { PopUpWrapper } from 'react-project/components/popUp/PopUpWrapper';
import { setNewUrl, updateAnalyticsWhenUrlChanged } from 'react-project/redux/current-step/actions';
import { selectCurrentStep } from 'react-project/redux/current-step/selectors';
import { selectFunnelConfiguration } from 'react-project/redux/funnel-configuration/selectors';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import styles from 'react-project/Toolbar/Toolbar.module.scss';
import { When } from 'react-project/Util/When';
import { useDispatch, useSelector } from 'react-redux';

export const PageTracking = (props) => {
  const {
    isShowUrlParams,
    onAddCustomParameter,
    urlParamsRows,
    handleFilterPageByUrlChange,
    projectId,
    onClose,
    onFilterParametersPasted,
  } = props;

  const dispatch = useDispatch();
  const currentStep = useSelector(selectCurrentStep);
  const funnelConfiguration = useSelector(selectFunnelConfiguration);
  const canvasPermissions = useSelector(selectCanvasPermissions);

  const onUrlFocusOut = (e) => {
    dispatch(
      updateAnalyticsWhenUrlChanged({
        url: e.target.value,
        currentStep,
        funnelConfiguration,
        projectId,
      }),
    );
  };

  const onUrlChanged = (e) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }
    dispatch(setNewUrl({ url: e.target.value, currentStep }));
  };

  const onURLPasted = (e) => {
    if (canvasPermissions.isReadonlyAccess) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    const target = e.target;
    setTimeout(() => {
      setPageFiltersFromURL(target.value || '');
    }, 0);
  };

  const setPageFiltersFromURL = (urlString) => {
    const [base, queryParamtersString] = urlString.split('?');
    const urlParams = new URLSearchParams(queryParamtersString);
    const queryStringEntries = urlParams.entries();

    if (Array.from(urlParams).length) {
      const qParams = [];
      for (const entry of queryStringEntries) {
        const [key, value] = entry;
        qParams.push({
          key,
          operator: '=',
          isFocusKey: false,
          contans: 'true',
          value,
        });
      }
      onFilterParametersPasted(qParams);
    }
  };

  const TITLE = 'Page Tracking Settings';
  const urlValue = currentStep.object.url;

  return (
    <PopUpWrapper
      icon={iconHorizontalArrows}
      title={TITLE}
      showCloseIcon={true}
      onClosePopup={() => onClose()}
      cancel={'rndCancel'}
      minWidth="400px"
    >
      <div className={cx('rndCancel cursor-auto', styles.TrackingTab)}>
        <div className={styles.Label}>
          <div>
            <label className={styles.SideBarLabel}>URL</label>
            <div className={styles.LabelWrapper}>
              <input
                autoFocus={true}
                value={urlValue}
                onChange={onUrlChanged}
                type="text"
                className={`${styles.RsInput}`}
                onBlur={onUrlFocusOut}
                onPaste={onURLPasted}
                disabled={canvasPermissions.isReadonlyAccess}
              />
            </div>
          </div>
        </div>
        <div
          className={cx(
            styles.Label,
            'mb-[42px] h-[calc(100% + 147px)] p-[15px] overflow-y-auto overflow-x-hidden',
          )}
        >
          <label className={styles.FilterLabel}>
            <input
              className={styles.Checkbox}
              type="checkbox"
              name="isShowUrlParams"
              checked={isShowUrlParams}
              onChange={handleFilterPageByUrlChange}
              disabled={canvasPermissions.isReadonlyAccess}
            />
            <span className={styles.CheckboxSpan}></span>
            <div className={styles.CheckboxText}>Filter Page by URL Parameters</div>
          </label>
          {urlParamsRows}
          <When condition={isShowUrlParams}>
            <button
              className={styles.AddFilterBtn}
              onClick={(e) => {
                if (canvasPermissions.isReadonlyAccess) {
                  e.preventDefault();
                  e.stopPropagation();
                  return;
                }
                onAddCustomParameter(e);
              }}
            >
              + Add Custom Parameter
            </button>
          </When>
        </div>
      </div>
    </PopUpWrapper>
  );
};
