import Facade from 'pixi-project/Facade';
import { EElementCategories } from 'shared/CSharedCategories';
import {
  PR_EVENT_TEXT_EDITING_FINISH,
  PR_EVENT_ZOOM_LEVEL_CHANGED,
  RP_EVENT_EMPTY_CANVAS_DOUBLE_CLICK,
  RP_EVENT_EMPTY_CANVAS_POINTER_UP,
  RP_EVENT_ESC_PRESSED,
  RP_EVENT_FIT_TO_SCREEN,
  RP_EVENT_ZOOM_IN,
  RP_EVENT_ZOOM_OUT,
  RP_EVENT_ZOOM_RESET,
  RP_EVENT_ZOOM_VALUE_CHANGED,
  PR_EVENT_SHAPE_DRAWING_STARTED,
  PR_EVENT_REPORT_WARNING_POP_OVERLAPPED,
  PR_MOVING_CURSOR_WITH_ELEMENT,
  PR_EVENT_BRING_TO_FRONT,
  PR_EVENT_BRING_FORWARD,
  PR_EVENT_SEND_TO_BACK,
  PR_EVENT_SEND_BACKWARD,
  PR_EVENT_SHORTCUT_PRESSED,
  PR_EVENT_TOOLBOX_STATE_CHANGED,
  PR_EVENT_MIDDLE_MOUSE_DOWN,
  PR_EVENT_THUMBNAIL_REQUESTED,
} from 'shared/CSharedEvents';
import {
  commonSendEventFunction,
  getMousePosition,
  isCtrlKey,
  isDelete,
  isEscapeKey,
  isKey1,
  isKeyC,
  isKeyD,
  isKeyLeftSBracket,
  isKeyMinus,
  isKeyO,
  isKeyP,
  isKeyPlus,
  isKeyR,
  isKeyRightSBracket,
  isKeyS,
  isKeyT,
  isKeyV,
  isKeyY,
  isKeyZ,
  isKeyZero,
  isMouseWheelButton,
  isRightButton,
  isShiftKey,
  isSpaceKey,
} from 'shared/CSharedMethods';
import AppSignals from 'pixi-project/signals/AppSignals';
import {
  ACTIVE_STATE_DRAW,
  ACTIVE_STATE_PHOTO,
  ACTIVE_STATE_REPORT,
  ACTIVE_STATE_TEXT,
  SELECTION_UPDATE_SOURCE_PANNING,
} from 'shared/CSharedConstants';
import CommandMoveViewport from 'pixi-project/base/command-system/commands/CommandMoveViewport';
import SharedElementHelpers from 'shared/SharedElementHelpers';
import { CANVAS_ACTION_NAMES } from 'shared/canvasActionNames';
import MainStorage from 'pixi-project/core/MainStorage';
import { getURLData, parseSingleURL } from 'react-project/Helpers/URLHelpers';

export const DOUBLE_CLICK_TIMEOUT = 300; // 300 ms

export default class InputEventController {
  constructor(
    sceneManager,
    selectionManager,
    commandManager,
    copyPasteUtility,
    zoomUtility,
    planeContainer,
  ) {
    this.sceneManager = sceneManager;
    this.selectionManager = selectionManager;
    this.commandManager = commandManager;
    this.copyPasteUtility = copyPasteUtility;
    this.zoomUtility = zoomUtility;
    this.planeContainer = planeContainer;

    this.lastClickTimestamp = 0;
    this.lastEmptyCanvasClickPoint = new PIXI.Point();
    this.viewportDragStartPoint = new PIXI.Point();

    this.isPointerDown = false;
    this.isCtrlDown = false;
    this.isShiftDown = false;

    this.isQuickPanning = false;

    this.isHubspotLoaded = false;
    this.toolbarActiveState = null;
    this.lockedStates = [
      ACTIVE_STATE_DRAW,
      ACTIVE_STATE_REPORT,
      ACTIVE_STATE_TEXT,
      ACTIVE_STATE_PHOTO,
    ];
  }

  attachListeners() {
    document.addEventListener('paste', this.onPaste.bind(this), false);

    window.app.stage.on('pointerout', this.onStagePointerOut, this);
    Facade.viewport.on('pointerdown', this.onViewportDown, this);
    Facade.viewport.on('pointerup', this.onViewportUp, this);
    Facade.viewport.on('pointerupoutside', this.onViewportOut, this);
    Facade.viewport.on('pointercancel', this.onViewportOut, this);
    Facade.viewport.on('pointermove', this.onViewportMove, this);
    Facade.viewport.addListener('moved', this.onViewportDrag, this);
    Facade.viewport.addListener('wheel', this.onScroll, this);
    Facade.viewport.addListener('zoomed-end', this.onScrollEnd, this);
    Facade.viewport.addListener('drag-start', this.onViewportDragStart, this);
    Facade.viewport.addListener('drag-end', this.onViewportDragEnd, this);

    // Attempt to prevent other elements on the page to be selected when double clicking on the canvas.
    app.renderer.view.addEventListener('dblclick', this.onDoubleClickEvent.bind(this));

    document.addEventListener(RP_EVENT_FIT_TO_SCREEN, this.onZoomFitToScreen.bind(this), false);
    document.addEventListener(RP_EVENT_ZOOM_IN, this.onZoomIn.bind(this), false);
    document.addEventListener(RP_EVENT_ZOOM_OUT, this.onZoomOut.bind(this), false);
    document.addEventListener(RP_EVENT_ZOOM_RESET, this.onZoomReset.bind(this), false);
    document.addEventListener(RP_EVENT_ZOOM_VALUE_CHANGED, this.onZoomSetLevel.bind(this), false);
    document.addEventListener(
      PR_MOVING_CURSOR_WITH_ELEMENT,
      this.onCheckingReportPopupOverlapped.bind(this),
      false,
    );
    document.addEventListener(
      PR_EVENT_TOOLBOX_STATE_CHANGED,
      this.onToolbarActiveStateChanged.bind(this),
      false,
    );

    document.addEventListener('keydown', this.onKeyDown.bind(this), false);
    document.addEventListener('keyup', this.onKeyUp.bind(this));
    window.addEventListener('blur', this.onWindowLostFocus.bind(this));
    window.addEventListener('pointerdown', this.onWindowPointerDown.bind(this));
    window.addEventListener('pointerup', this.onWindowPointerUp.bind(this));

    let observerChat = new MutationObserver(() => {
      if (!this.isHubspotLoaded) {
        const hubsportChat = document.getElementById('hubspot-messages-iframe-container');
        if (hubsportChat) {
          this.isHubspotLoaded = true;
        }
      }
    });

    observerChat.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
      characterData: false,
    });

    app.renderer.view.addEventListener('pointerdown', this.onCanvasPointerDown.bind(this));
  }

  onDoubleClickEvent(e) {
    e.preventDefault();
    e.stopPropagation();
    app.renderer.view.focus();
  }

  onPaste(evt) {
    if (MainStorage.getCanvasPermissions().isReadonlyAccess) {
      return;
    }

    // Get the data of clipboard
    const clipboardItems = evt.clipboardData.items;
    const items = [].slice.call(clipboardItems).filter(function (item) {
      // Filter the image items only
      return item.type.indexOf('image') !== -1;
    });

    // if we are in an editor , do not trigger our copy-paste mechanizm
    if (this.isInputActive()) {
      return;
    }

    // If not images are detected
    if (items.length === 0) {
      evt.clipboardData.items[0].getAsString((str) => {
        // if the string is a URL , create a new step from it
        const urlData = getURLData(str);
        if (urlData) {
          // iconsAllowed
          console.log(
            'permission check ',
            MainStorage.getCanvasPermissions(),
            MainStorage.getCanvasPermissions().iconsAllowed,
          );
          const step = this.sceneManager.createStepFromURL(urlData);
          // trigger thumbnail creation
          const currentStep = {
            stepId: step.id,
            object: {
              url: step.url,
              stepId: step.id,
            },
          };
          commonSendEventFunction(PR_EVENT_THUMBNAIL_REQUESTED, { currentStep });
          // clear the internal clipboard
          this.copyPasteUtility.reset();
        } else {
          // else try to paste an object/step being copied into the clipboard
          this.copyPasteUtility.pasteClipboard();
        }
      });
      return;
    }

    const item = items[0];
    const blob = item.getAsFile();
    this.onImagePastedToCanvas(blob);
  }

  onImagePastedToCanvas(blob) {
    if (MainStorage.getCanvasPermissions().iconsAllowed) {
      this.sceneManager.createPhotoFromFile(blob);
    } else {
      console.warn("you don't have permission to paste images");
    }
  }

  onCanvasPointerDown(e) {
    if (this.isHubspotLoaded) {
      document
        .getElementById('hubspot-messages-iframe-container')
        .querySelector('iframe').style.pointerEvents = 'none';
    }
  }

  onViewportDown(e) {
    window.app.needsRendering();
    this.isPointerDown = true;

    if (e.data && e.data.originalEvent && e.data.originalEvent.ctrlKey === false) {
      this.isCtrlDown = false;
    }

    if (e.data && e.data.originalEvent && e.data.originalEvent.shiftKey === false) {
      this.isShiftDown = false;
    }

    // on wheel click , enter quick panning
    if (isMouseWheelButton(e)) {
      // tell that middle mouse was pressed
      commonSendEventFunction(PR_EVENT_MIDDLE_MOUSE_DOWN, true);
      e.data.originalEvent.preventDefault();
      this.planeContainer.setViewportCursor('grabbing');
      this.planeContainer.enterQuickPanning(e);
      //  resend the event to activate the viewport ,
      //  because dragging was disabled at the time of the event
      let event = new PIXI.InteractionEvent();
      event.data = e.data;
      event.data.originalEvent = null; // this is a hack to prevent recursion of this event
      window.app.renderer.plugins.interaction.dispatchEvent(Facade.viewport, 'pointerdown', event);

      return false;
    }

    this.selectionManager.hideToolbar();

    // handle cursor
    if (this.planeContainer.isToolbarDragging) {
      this.planeContainer.isToolbarDragging = false;
      this.planeContainer.toolbarDragData = null;
      this.planeContainer.setViewportCursor('default');
    }

    if (this.planeContainer.cursorMode === EElementCategories.PANNING) {
      this.planeContainer.setViewportCursor('grabbing');
    }

    // prevent selection when dropping a text object
    if (
      this.toolbarActiveState === ACTIVE_STATE_TEXT ||
      this.toolbarActiveState === ACTIVE_STATE_PHOTO
    ) {
      e.stopPropagation();
      return false;
    }

    if (this.planeContainer.isTextEditing()) {
      AppSignals.setOutEditMode.dispatch();
      commonSendEventFunction(PR_EVENT_TEXT_EDITING_FINISH, false);
    }

    // handle selection tool
    if (this.planeContainer.cursorMode === EElementCategories.CLICKING) {
      this.selectionManager.onViewportDown(e, this.isShiftDown);
    }
  }

  onViewportMove(e) {
    // When mouse move event is fired , but we don't have a button clicked
    // then its liekly that the "pointer up" event was not emitted properly
    // in this case we are going to set the "pointer down" to false
    const originalEvent = e.data.originalEvent;
    if (originalEvent.buttons === 0 && originalEvent.pressure === 0) {
      this.isPointerDown = false;
    }

    this.sceneManager.onViewportMove(e);

    if (this.isPointerDown) {
      if (this.toolbarActiveState === ACTIVE_STATE_DRAW) {
        this.onDrawObjectMove(e);
        return false;
      }

      if (this.planeContainer.cursorMode === EElementCategories.CLICKING) {
        this.selectionManager.onViewportMove(e);
      }

      if (this.planeContainer.cursorMode === EElementCategories.PANNING) {
        this.selectionManager.updateSelection(false, true, false, false);
        this.selectionManager.updateFocused();
      }
    }
  }

  onViewportDrag() {
    this.planeContainer.handleMeshPosition();
  }

  onViewportUp(e) {
    this.isPointerDown = false;
    window.app.needsRendering();

    if (this.planeContainer.isToolbarDragging) {
      if (SharedElementHelpers.IsText(this.planeContainer.toolbarDragData.object)) {
        this.sceneManager.createText(
          {
            detail: {
              position: { x: e.data.global.x, y: e.data.global.y },
              object: this.planeContainer.toolbarDragData.object,
            },
          },
          false,
        );
      } else if (SharedElementHelpers.IsPhoto(this.planeContainer.toolbarDragData.object)) {
        this.sceneManager.createPhoto(
          {
            detail: {
              position: { x: e.data.global.x, y: e.data.global.y },
              object: this.planeContainer.toolbarDragData.object,
            },
          },
          false,
        );
      } else {
        console.warn(
          `Object category ${this.planeContainer.toolbarDragData.object.category} is not Defined!`,
        );
      }

      this.planeContainer.isToolbarDragging = false;
      this.planeContainer.toolbarDragData = null;
      this.planeContainer.setViewportCursor('default');

      return;
    }

    if (this.toolbarActiveState === ACTIVE_STATE_DRAW) {
      this.onDrawObjectEnd(e);
      return;
    }

    if (this.planeContainer.cursorMode === EElementCategories.CLICKING) {
      this.selectionManager.onViewportUp(e);
    }

    if (this.planeContainer.cursorMode === EElementCategories.PANNING) {
      this.planeContainer.setViewportCursor('grab');
      this.selectionManager.updateSelection(
        true,
        true,
        true,
        true,
        SELECTION_UPDATE_SOURCE_PANNING,
      );
    }

    // on wheel click , exit quick panning
    if (isMouseWheelButton(e)) {
      this.planeContainer.cancelQuickPanning();
    }

    this.sceneManager.onViewportUp(e);

    if (!this.selectionManager.hasSelectedElements()) {
      commonSendEventFunction(RP_EVENT_EMPTY_CANVAS_POINTER_UP, {
        originalEvent: e.data.originalEvent,
        isRight: isRightButton(e),
        canRedo: this.commandManager.canRedo(),
        canUndo: this.commandManager.canUndo(),
        canPaste: this.copyPasteUtility.canPaste(),
      });

      let timeNow = new Date().getTime();
      let timeSinceLastClick = timeNow - this.lastClickTimestamp;
      this.lastClickTimestamp = timeNow;

      let currentPoint = e.data.global.clone();
      let hasMoved =
        currentPoint.x !== this.lastEmptyCanvasClickPoint.x ||
        currentPoint.y !== this.lastEmptyCanvasClickPoint.y;
      this.lastEmptyCanvasClickPoint = currentPoint;

      if (timeSinceLastClick < DOUBLE_CLICK_TIMEOUT && !hasMoved) {
        this.onDoubleClick(currentPoint);
      }
    }
  }

  onViewportOut(e) {
    this.isPointerDown = false;
    if (this.planeContainer.cursorMode === EElementCategories.PANNING) {
      // Handle an edge case when panning and mouse is released outside the canvas
      this.onViewportUp(e);
    } else if (this.planeContainer.isToolbarDragging) {
      // Handle an edge case when the dragging tool is pressed and released outside the canvas
      this.planeContainer.setViewportCursor('default');
    } else if (this.toolbarActiveState === ACTIVE_STATE_DRAW) {
      this.onDrawObjectEnd(e);
    } else {
      this.selectionManager.onViewportOut(e);
    }
  }

  onDoubleClick(point) {
    point.x *= window.app.scaleManager.aspectRatio;
    point.y *= window.app.scaleManager.aspectRatio;
    commonSendEventFunction(RP_EVENT_EMPTY_CANVAS_DOUBLE_CLICK, point);
  }

  onStagePointerOut(e) {
    AppSignals.setOutEditMode.dispatch();
    this.selectionManager.hide();
  }

  onWheel(e) {
    const widgets = this.sceneManager.getAllWidgets();
    window.app.viewport.plugins.resume('wheel');

    let p = getMousePosition();
    for (let i = 0; i < widgets.length; i++) {
      const widget = widgets[i];
      let bounds = widget.getBounds();
      if (widget.acceptsWheelEvents() && bounds.contains(p.x, p.y)) {
        window.app.viewport.plugins.pause('wheel');
        widget.onWheel(e);
      }
    }
  }

  onScroll(e) {
    AppSignals.setOutEditMode.dispatch();
    this.selectionManager.hide();
    this.onZoomLevelChanged(this.zoomUtility.getZoomLevel());
  }

  onScrollEnd(e) {
    this.selectionManager.show();
    commonSendEventFunction(PR_EVENT_ZOOM_LEVEL_CHANGED, {
      value: Facade.viewport.scaled,
    });
    this.onZoomLevelChanged(this.zoomUtility.getZoomLevel());
  }

  onZoomLevelChanged(zoomLevel) {
    this.selectionManager.updateFocused();
    this.planeContainer.handleMeshPosition();
    const report = this.sceneManager.getReport();
    if (report) {
      report.onZoomChanged();
      // Update the culling of the objects in the case of report view zooming
      // as the slides tend to shift and that shifts the objects to practically become
      // members of different culling segments
      // so the culling bounds need to be updated , so the elements will become visible
      const objects = report.getAllObjects();
      this.sceneManager.culling.updateObjects(objects);
    }
  }

  onViewportDragStart(e) {
    this.viewportDragStartPoint.copyFrom(Facade.viewport.center);
  }

  onViewportDragEnd(e) {
    const from = this.viewportDragStartPoint;
    const moveCommand = new CommandMoveViewport(
      Facade.viewport,
      from,
      this.planeContainer.mesh,
      this.selectionManager,
    );
    this.commandManager.execute(moveCommand);
  }

  onDrawObjectStart(e) {
    commonSendEventFunction(PR_EVENT_SHAPE_DRAWING_STARTED);
    this.sceneManager.setObjectsInteraction(false);

    e.detail.position = getMousePosition();
    this.sceneManager.objectCreated = this.sceneManager.createShape(e, false, null);

    // we are simulating this in order to update the top left point in the selection
    // FIX , calculate that point directly
    this.selectionManager.addToSelection(this.sceneManager.objectCreated);
    this.selectionManager.updateSelection(true);
    this.selectionManager.clearSelection();
    this.selectionManager.multi.isSelecting = false;
    this.selectionManager.hide();

    this.sceneManager.objectCreated.setResizeHandleDown(
      this.selectionManager.single.scalePoints.leftTopScalePoint,
      this.isShiftDown,
    );

    document.body.style.cursor = 'default';
  }

  onDrawObjectMove(e) {
    if (this.sceneManager.objectCreated) {
      this.sceneManager.objectCreated.onResizeHandleMove(e.data.global, this.isShiftDown);
      window.app.needsRendering();
    }
  }

  onDrawObjectEnd(e) {
    if (
      this.sceneManager.objectCreated &&
      SharedElementHelpers.IsShape(this.sceneManager.objectCreated)
    ) {
      this.sceneManager.objectCreated.onResizeHandleUp(e);
      const objectCreated = this.sceneManager.objectCreated;
      this.planeContainer.endDrawing();

      if (objectCreated.isShapeValid()) {
        this.selectionManager.selectElement(objectCreated);
        this.selectionManager.updateSelection();
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////
  /////////////////// KEYBORAD EVENTS & SYSTEM EVENTS

  onKeyDown(e) {
    // handle key modifiers first
    if (isShiftKey(e)) {
      this.isShiftDown = true;
    }

    if (isCtrlKey(e)) {
      this.isCtrlDown = true;
    }

    const isReadonly = MainStorage.getCanvasPermissions().isReadonlyAccess;

    // It is important to check if we are editing a text
    // as we should not activate our shortcuts then.
    // Imagine trying to delete a letter in some text
    // that will trigger deleting the selection
    const isInputActive = this.isInputActive();

    if (isSpaceKey(e) && !isInputActive) {
      this.onSpaceDown(e);
    } else if (isEscapeKey(e)) {
      if (this.planeContainer.isTextEditing()) {
        AppSignals.setOutEditMode.dispatch();
        commonSendEventFunction(PR_EVENT_TEXT_EDITING_FINISH, false);
        this.selectionManager.show();
      } else {
        // deselect all
        this.selectionManager.clearSelection();
        this.selectionManager.hide();
        window.app.needsRendering();
      }
      commonSendEventFunction(RP_EVENT_ESC_PRESSED);
    } else if (this.isCtrlDown && isKeyC(e) && !isInputActive) {
      this.copyPasteUtility.copySelection();
    } else if (this.isCtrlDown && isKeyD(e) && !isInputActive) {
      if (isReadonly) return;
      this.copyPasteUtility.duplicateSelection();
      e.preventDefault();
    } else if (this.isCtrlDown && isKeyV(e) && !isInputActive) {
      // This code was moved to be handled in the paste event
    } else if (isDelete(e) && !isInputActive) {
      if (isReadonly) return;
      this.sceneManager.onDeleteSelection();
    } else if (this.isCtrlDown && isKeyZ(e) && !this.isShiftDown && !isInputActive) {
      if (isReadonly) return;
      this.commandManager.undo();
      window.app.needsRendering();
      e.stopPropagation();
      e.preventDefault();
    } else if (
      ((this.isCtrlDown && isKeyY(e)) || (this.isCtrlDown && this.isShiftDown && isKeyZ(e))) &&
      !isInputActive
    ) {
      if (isReadonly) return;
      this.commandManager.redo();
      window.app.needsRendering();
    } else if (this.isCtrlDown && isKeyPlus(e) && !isInputActive) {
      this.zoomUtility.zoomIn();
      e.preventDefault();
    } else if (this.isCtrlDown && isKeyMinus(e) && !isInputActive) {
      this.zoomUtility.zoomOut();
      e.preventDefault();
    } else if (this.isCtrlDown && isKeyZero(e) && !isInputActive) {
      this.zoomUtility.zoomReset();
      e.preventDefault();
    } else if (this.isCtrlDown && isKey1(e) && !isInputActive) {
      this.zoomUtility.fitToScreen();
      e.preventDefault();
    } else if (this.isCtrlDown && this.isShiftDown && isKeyLeftSBracket(e)) {
      if (isReadonly) return;
      commonSendEventFunction(PR_EVENT_SEND_TO_BACK);
      e.preventDefault();
    } else if (this.isCtrlDown && isKeyLeftSBracket(e)) {
      if (isReadonly) return;
      commonSendEventFunction(PR_EVENT_SEND_BACKWARD);
      e.preventDefault();
    } else if (this.isCtrlDown && this.isShiftDown && isKeyRightSBracket(e)) {
      if (isReadonly) return;
      commonSendEventFunction(PR_EVENT_BRING_TO_FRONT);
      e.preventDefault();
    } else if (this.isCtrlDown && isKeyRightSBracket(e)) {
      if (isReadonly) return;
      commonSendEventFunction(PR_EVENT_BRING_FORWARD);
      e.preventDefault();
    } else if (!isInputActive && isKeyS(e)) {
      commonSendEventFunction(PR_EVENT_SHORTCUT_PRESSED, { action: CANVAS_ACTION_NAMES.SELECT });
      e.preventDefault();
    } else if (!isInputActive && isKeyT(e)) {
      if (isReadonly) return;
      commonSendEventFunction(PR_EVENT_SHORTCUT_PRESSED, { action: CANVAS_ACTION_NAMES.ADD_TEXT });
      e.preventDefault();
    } else if (!isInputActive && isKeyO(e)) {
      if (isReadonly) return;
      commonSendEventFunction(PR_EVENT_SHORTCUT_PRESSED, {
        action: CANVAS_ACTION_NAMES.DRAW_CIRCLE,
      });
      e.preventDefault();
    } else if (!isInputActive && isKeyR(e)) {
      if (isReadonly) return;
      commonSendEventFunction(PR_EVENT_SHORTCUT_PRESSED, {
        action: CANVAS_ACTION_NAMES.DRAW_RECTANGLE,
      });
      e.preventDefault();
    } else if (!isInputActive && isKeyP(e)) {
      if (isReadonly) return;
      commonSendEventFunction(PR_EVENT_SHORTCUT_PRESSED, {
        action: CANVAS_ACTION_NAMES.DRAW_TRIANGLE,
      });
      e.preventDefault();
    }
  }

  onKeyUp(e) {
    // handle key modifiers first
    this.isShiftDown = false;
    if (isShiftKey(e)) {
      this.isShiftDown = true;
    }

    if (isCtrlKey(e)) {
      this.isCtrlDown = false;
    }

    if (isSpaceKey(e)) {
      this.onSpaceUp(e);
    }
  }

  onSpaceDown(e) {
    this.planeContainer.enterQuickPanning();
  }

  onSpaceUp(e) {
    this.planeContainer.cancelQuickPanning();
  }

  onWindowLostFocus(e) {
    this.planeContainer.cancelQuickPanning();
    this.planeContainer.endDrawing();
  }

  onWindowPointerDown(e) {
    this.sceneManager.onWindowPointerDown(e);
  }

  onWindowPointerUp(e) {
    // This is the final destination of a click event ,
    // it occures even if the pointer is released outside the browser window
    // If something get stucked for some reason
    // This is the place to ultimately clear it from the canvas.

    if (this.sceneManager.pointerJoint && !this.sceneManager._addStepOnConnectionEnd) {
      console.warn(
        'Clicking outside the canvas while trying to create a new connection. Starting FORCE clear now !',
      );
      this.sceneManager.removeCoordinatesJoint();
      this.selectionManager.hideToolbar();
    } else if (this.selectionManager.multi.isSelecting) {
      console.warn(
        'A click outside the canvas was detected while trying to multiselect. Ending multiselection now !',
      );
      this.selectionManager.stopAndResolveMultiSelection();
    } else if (this.sceneManager.movingConnection) {
      console.warn(
        'Clicking outside the canvas while trying to reattach a connection. Starting FORCE clear now !',
      );
      this.sceneManager.clearMovingConnection();
      window.app.needsRendering();
    } else if (this.selectionManager.attachmentSelection.hasFrames()) {
      console.warn(
        'Clearing connection attachment frames, please inspect why there where attachment frames left over',
      );
      this.selectionManager.attachmentSelection.clear();
    }

    if (
      this.selectionManager.isShapeResizing &&
      SharedElementHelpers.IsShape(this.selectionManager.selectedElement)
    ) {
      this.selectionManager.single.onScalePointUp(e);
    }

    if (this.isHubspotLoaded) {
      document
        .getElementById('hubspot-messages-iframe-container')
        .querySelector('iframe').style.pointerEvents = '';
    }

    this.sceneManager.onWindowPointerUp(e);
  }

  onResize(frame) {
    Facade.viewport.resize(frame.width, frame.height);
    if (this.planeContainer.mesh) {
      this.planeContainer.mesh.recalculate();
    }
    window.app.needsRendering();
  }

  onZoomFitToScreen(e) {
    const oldPosition = Facade.viewport.center.clone();
    this.sceneManager.culling.showAllObjects();
    this.zoomUtility.fitToScreen();
    const command = new CommandMoveViewport(
      Facade.viewport,
      oldPosition,
      this.planeContainer.mesh,
      this.selectionManager,
    );
    this.commandManager.execute(command);
  }

  onZoomIn(e) {
    this.zoomUtility.zoomIn();
  }

  onZoomOut(e) {
    this.zoomUtility.zoomOut();
  }

  onZoomReset(e) {
    this.zoomUtility.zoomReset();
  }

  onZoomSetLevel(e) {
    const zoomLevel = Number(e.detail.value) ? e.detail.value / 100 : 0.01;
    this.zoomUtility.setZoomLevel(zoomLevel);
  }

  onCheckingReportPopupOverlapped(e) {
    if (this.sceneManager.reportView) {
      const scaleFactor = 1 / window.app.scaleManager.aspectRatio;
      const pointX = e.detail.coords.x * scaleFactor;
      const pointY = e.detail.coords.y * scaleFactor;
      const bounds = this.sceneManager.reportView.getBounds();
      const typeOfEl = e.detail.type;

      if (
        this.sceneManager.reportView &&
        bounds.contains(pointX, pointY) &&
        this.sceneManager.reportView.isLocked
      ) {
        commonSendEventFunction(PR_EVENT_REPORT_WARNING_POP_OVERLAPPED, {
          isOverlapped: true,
          type: typeOfEl,
        });
      } else {
        commonSendEventFunction(PR_EVENT_REPORT_WARNING_POP_OVERLAPPED, {
          isOverlapped: false,
          type: typeOfEl,
        });
      }
    }
  }

  onToolbarActiveStateChanged(e) {
    const state = e.detail.state;

    if (
      this.lockedStates.indexOf(this.toolbarActiveState) !== -1 &&
      this.lockedStates.indexOf(state) === -1
    ) {
      this.sceneManager.revertObjectsInteraction();
    } else if (this.lockedStates.indexOf(state) !== -1) {
      this.sceneManager.lockAllObjectsForInteraction();
    }

    this.toolbarActiveState = state;

    switch (state) {
      case ACTIVE_STATE_DRAW: {
        document.body.style.cursor = 'crosshair';
        this.selectionManager.clearReset();
        break;
      }
      case ACTIVE_STATE_REPORT: {
        document.body.style.cursor = 'crosshair';
        this.selectionManager.clearReset();
        break;
      }
      case ACTIVE_STATE_TEXT: {
        document.body.style.cursor = 'text';
        this.selectionManager.clearReset();
        break;
      }
      case ACTIVE_STATE_PHOTO: {
        document.body.style.cursor = 'crosshair';
        this.selectionManager.clearReset();
        break;
      }
      default: {
        document.body.style.cursor = 'default';
      }
    }
  }
  /**
   * It tells you if an input element is currently in focus (if someone is typing)
   * @returns Boolean If an input element is focused
   */
  isInputActive() {
    const obj = document.activeElement;
    const isInputFocused =
      obj instanceof HTMLInputElement &&
      (obj.type === 'text' ||
        obj.type === 'search' ||
        obj.type === 'password' ||
        obj.type === 'number');
    const isAreaFocused = obj instanceof HTMLTextAreaElement;
    const isContentEditable = obj.getAttribute('contenteditable') === 'true';

    return isInputFocused || isAreaFocused || isContentEditable;
  }
}
