import React from 'react';
import styles from './TextToolbar.module.scss';
import cls from 'classnames';

export const TextElement = ({
  label,
  classNames,
  onClick,
  withBorder = true,
  isActive = false,
}) => {
  return (
    <div
      className={cls(styles.TextToolbarTab, classNames, { [styles.WithBorder]: withBorder })}
      onClick={onClick}
    >
      <div className={cls(styles.TriggerWrapper, { [styles.ActiveStyle]: isActive })}>{label}</div>
    </div>
  );
};
