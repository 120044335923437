import cx from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { LoadingOverlay } from 'react-project/components/v2/loadingOverlay';
import { setIntegrationAttributes } from 'react-project/redux/current-step/actions';
import { selectFunnelConfiguration } from 'react-project/redux/funnel-configuration/selectors';
import {
  FACEBOOK_INTEGRATION,
  isIntegrationInstalled,
} from 'react-project/Util/IntegrationsDetector';
import { useDispatch, useSelector } from 'react-redux';
import {
  FB_AD_MODAL_MODE_DEFAULT,
  FB_AD_MODAL_MODE_SEARCH,
  IntegrationTypes,
} from 'shared/CSharedConstants';
import { RP_EVENT_EDIT_OBJECT } from 'shared/CSharedEvents';
import { commonSendEventFunction, isEnterKey } from 'shared/CSharedMethods';
import { AdFilterBar, ErrorMessage, SmallText, TierTreeRow } from '../components/v2';
import {
  fetchAllFacebookAdsCampaigns,
  fetchFacebookAdsAdsets,
  resetAllAdData,
  resetFilteredCollection,
  searchFacebookAds,
  setBlockInteraction,
  setCheckboxes,
  setCollectionDisplayMode,
  setCurrentDateRange,
  setIsLoading,
  toggleCampaignById,
  updatePopupContainerBounds,
} from '../features/facebookIntegration';
import { useHover } from '../hooks';
import { AdSetList } from './AdSet';
import { adType } from './helpers';

function CampaignItem({ data, projectId, stepId }) {
  const [firstToggle, setFirstToggle] = useState(true);
  const [preventUse, setPreventUse] = useState(false);
  const dispatch = useDispatch();
  const campaignAdsetLoaded = useSelector(
    ({ facebookIntegration }) => facebookIntegration.stepIds[stepId].selectedAdPrices,
  );
  const collectionDisplayMode = useSelector(
    ({ facebookIntegration }) => facebookIntegration.stepIds[stepId].collectionDisplayMode,
  );
  const doNotDisplay = data.doNotDisplay && collectionDisplayMode === FB_AD_MODAL_MODE_SEARCH;
  const { expanded } = data;
  useEffect(() => {
    if (campaignAdsetLoaded[data.id]) {
      setFirstToggle(false);
    }
  }, [campaignAdsetLoaded]);
  return (
    <TierTreeRow
      data={data}
      handleCheck={() => {
        if (preventUse) return;
        dispatch(setCheckboxes({ stepId, id: data.id }));
        if (firstToggle) {
          dispatch(setIsLoading({ stepId, isLoading: true }));
          dispatch(
            fetchFacebookAdsAdsets({
              stepId,
              projectId,
              campaignId: data.id,
            }),
          );
          dispatch(setIsLoading({ stepId, isLoading: false }));
        }

        setFirstToggle(false);
      }}
      handleToggle={async () => {
        await dispatch(toggleCampaignById({ id: data.id, stepId }));
        if (firstToggle) {
          setPreventUse(true);
          await dispatch(setIsLoading({ stepId, isLoading: true }));
          await dispatch(
            fetchFacebookAdsAdsets({
              stepId,
              projectId,
              campaignId: data.id,
            }),
          );
          await dispatch(setIsLoading({ stepId, isLoading: false }));
          setFirstToggle(false);
          setPreventUse(false);
        }
      }}
      options={{ preventUse, doNotDisplay }}
    >
      {expanded ? <AdSetList data={data} /> : null}
    </TierTreeRow>
  );
}

function HeaderRow() {
  return (
    <div className="mb-2 flex border-b border-slate-200 px-2 py-2">
      <div className="w-2/3">
        <SmallText className="font-medium text-slate-500">Name</SmallText>
      </div>
      <div className="w-1/3">
        <SmallText className="font-medium text-slate-500">Total Spend</SmallText>
      </div>
    </div>
  );
}

function CampaignList({ campaigns, projectId, stepId }) {
  return (
    <div className="p-2">
      <HeaderRow />
      {campaigns?.length > 0 ? (
        campaigns.map((campaign) => (
          <CampaignItem
            key={`c1-${campaign.id}`}
            data={campaign}
            projectId={projectId}
            stepId={stepId}
          />
        ))
      ) : (
        <ErrorMessage type="NoResults" message="No results matched this selection." />
      )}
    </div>
  );
}

function SelectionCampaignItem({ campaign, stepId }) {
  const dispatch = useDispatch();
  const { expanded } = campaign;
  const [hoverRef, isHover] = useHover();
  const selectedView = true;

  const handleCheck = () => {
    dispatch(setCheckboxes({ stepId, id: campaign.id }));
  };
  const selectionCampaignValue = useSelector(
    ({ facebookIntegration }) => facebookIntegration.stepIds[stepId].selectedAdPrices[campaign.id],
  );
  const handleToggle = () => {
    dispatch(toggleCampaignById({ id: campaign.id, stepId }));
  };
  return (
    <div>
      <TierTreeRow
        data={campaign}
        options={{
          selectedView,
          altPrice: selectionCampaignValue?.totalSpend?.formattedPrice,
          isHover,
          ref: hoverRef,
        }}
        handleCheck={handleCheck}
        handleToggle={handleToggle}
      >
        {expanded ? <AdSetList data={campaign} options={{ selectedView }} /> : null}
      </TierTreeRow>
    </div>
  );
}

function SelectionList({ campaigns, stepId }) {
  return (
    <div className="p-2">
      {campaigns?.length > 0 ? (
        <>
          <HeaderRow />
          {campaigns.map((campaign) => (
            <SelectionCampaignItem key={`c2-${campaign.id}`} campaign={campaign} stepId={stepId} />
          ))}
        </>
      ) : (
        <ErrorMessage
          type="NoResults"
          message="Add at least one ad, ad set or campaign to start tracking!"
        />
      )}
    </div>
  );
}

function CampaignListDual({ projectId, stepId }) {
  const dimensionRef = useRef();
  const dispatch = useDispatch();
  const campaigns = useSelector(
    ({ facebookIntegration }) => facebookIntegration.stepIds[stepId].campaigns,
  );
  const isBlockingInteraction = useSelector(
    ({ facebookIntegration }) => facebookIntegration.stepIds[stepId].isBlockingInteraction,
  );

  useEffect(() => {
    const dimensions = dimensionRef.current.getBoundingClientRect() || null;
    dispatch(updatePopupContainerBounds({ dimensions, stepId }));
  }, [dimensionRef]);

  const selectedCampaigns = campaigns.filter((campaign) => campaign.checked);
  return (
    <>
      <LoadingOverlay active={isBlockingInteraction}>
        <div className="items-center align-middle absolute h-full w-full top-[40%] left-[40%] text-[36px]">
          Loading...
        </div>
      </LoadingOverlay>
      <div
        className={cx(
          'rndCancel cursor-default flex justify-left rounded border h-full border-slate-200 overflow-x-hidden',
          isBlockingInteraction ? 'cursor-wait' : '',
        )}
        ref={dimensionRef}
      >
        <div className="flex-1 border-r border-inherit">
          <SelectionList campaigns={selectedCampaigns} stepId={stepId} />
        </div>
        {/* When infilter/search mode is active, display filtered collection instead of <CampaignList/>. <FilterSearchList/> should reference/display the same adsets and ads in memory so when a checkbox is applied, this change can propagate to <CampaignList/> */}
        <div className="flex-1">
          <CampaignList campaigns={campaigns} projectId={projectId} stepId={stepId} />
        </div>
      </div>
    </>
  );
}

const getFilteredIds = (arrIds) => {
  const filteredArr = arrIds.filter((el) => el.type === adType.AD);
  return filteredArr;
};

function FacebookIntegrationAlert() {
  return (
    <div className="text-center">
      Add your facebook ad manager API key in your workspace API settings to enable this feature.
    </div>
  );
}

export function FacebookIntegration({ projectId, stepId, searchRef }) {
  const [facebookIntegrationFlag, setFacebookIntegrationFlag] = useState(false);
  const dispatch = useDispatch();
  const funnelConfiguration = useSelector(selectFunnelConfiguration);
  const fbDateRange = useSelector(
    ({ facebookIntegration }) => facebookIntegration.currentDateRange,
  );

  if (fbDateRange !== funnelConfiguration.dateRange) {
    dispatch(setCurrentDateRange(funnelConfiguration.dateRange));
    dispatch(resetAllAdData(stepId));
  }
  const campaigns = useSelector(
    ({ facebookIntegration }) => facebookIntegration.stepIds[stepId].campaigns,
  );
  const shouldLoad = campaigns.length === 0;
  const integrationAttributes = useSelector(
    ({ currentStep }) => currentStep.object.integrationAttributes,
  );
  const ads = useSelector(({ facebookIntegration }) => facebookIntegration.stepIds[stepId].ads);
  const canSetIntegrationAttributes = useSelector(
    ({ facebookIntegration }) => facebookIntegration.canSetIntegrationAttributes,
  );

  const isValidUtm = (utm) => {
    if (utm.value.startsWith('[') && utm.value.endsWith(']')) {
      return false;
    }
    return true;
  };

  const getSelectedAds = (ads, stepId) => {
    if (ads?.length) {
      return ads
        ?.filter((ad) => ad.checked)
        .map((ad) => {
          return {
            stepId,
            id: ad.id,
            utm_tags: ad.utmParameters?.filter((utm) => {
              return isValidUtm(utm);
            }),
            type: ad.type,
            adSetId: ad.adSetId,
            campaignId: ad.campaignId,
          };
        });
    }
    return [];
  };

  const memoizedSelectedAds = useMemo(() => getSelectedAds(ads, stepId), [ads]);
  useEffect(() => {
    isIntegrationInstalled(projectId, FACEBOOK_INTEGRATION).then(
      async (isIntegrationConfigured) => {
        if (isIntegrationConfigured) {
          setFacebookIntegrationFlag(true);
          await dispatch(setBlockInteraction({ stepId, isBlockingInteraction: true }));
          await dispatch(setIsLoading({ stepId, isLoading: true }));
          await dispatch(
            fetchAllFacebookAdsCampaigns({
              projectId,
              timeRange: funnelConfiguration.dateRange,
              integrationAttributes,
              stepId,
            }),
          );
          await dispatch(setBlockInteraction({ stepId, isBlockingInteraction: false }));
          await dispatch(setIsLoading({ stepId, isLoading: false }));
        } else {
          setFacebookIntegrationFlag(false);
        }
      },
    );
  }, [shouldLoad]);

  useEffect(() => {
    if (!canSetIntegrationAttributes) return;
    const filterData = getFilteredIds(memoizedSelectedAds); // filter ad IDs only
    const mappedFilterData = filterData.map((el) => el.utm_tags);
    dispatch(setIntegrationAttributes(memoizedSelectedAds));
    commonSendEventFunction(RP_EVENT_EDIT_OBJECT, {
      stepId,
      integrationAttributes: memoizedSelectedAds,
      filterData: mappedFilterData,
      integrationType: IntegrationTypes.FACEBOOK_AD,
    });

    // dispatch(emptyStepAttributes());
  }, [memoizedSelectedAds]);

  const isBlockingInteraction = useSelector(
    ({ facebookIntegration }) => facebookIntegration.stepIds[stepId].isBlockingInteraction,
  );
  const triggerSearchFacebookAds = (value) => {
    const data = {
      searchText: value,
      projectId,
      stepId,
    };
    dispatch(resetFilteredCollection({ stepId }));
    dispatch(setCollectionDisplayMode({ stepId, collectionDisplayMode: FB_AD_MODAL_MODE_SEARCH }));
    dispatch(searchFacebookAds(data));
  };

  const handleKeyDown = (e) => {
    if (isEnterKey(e.key)) {
      triggerSearchFacebookAds(e.target.value);
    }
  };

  const emptyInput = (e) => {
    if (e.target.value.length === 0) {
      dispatch(resetFilteredCollection({ stepId }));
    }
  };

  const cancelClick = (e) => {
    searchRef.current.value = '';
    dispatch(resetFilteredCollection({ stepId }));
    dispatch(setCollectionDisplayMode({ stepId, collectionDisplayMode: FB_AD_MODAL_MODE_DEFAULT }));
  };

  const submitClick = () => {
    if (searchRef.current.value === '') return;
    triggerSearchFacebookAds(searchRef.current.value);
  };

  return (
    <>
      {/* Disabled per FA-2327 */}
      <div className="mb-3">
        <AdFilterBar
          content={{ searchField: { label: 'Search', placeholder: 'Search' } }}
          submitClick={submitClick}
          cancelClick={cancelClick}
          handleKeyDown={handleKeyDown}
          searchRef={searchRef}
          disabled={isBlockingInteraction}
          onChange={emptyInput}
        />
      </div>
      {facebookIntegrationFlag ? (
        <CampaignListDual projectId={projectId} stepId={stepId} />
      ) : (
        <FacebookIntegrationAlert stepId={stepId} />
      )}
    </>
  );
}

export default FacebookIntegration;
