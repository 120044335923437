import * as PIXI from 'pixi.js';
import Styles, { COLOR_DISPLAY_SEPARATOR } from 'pixi-project/view/Styles';
import TextDisplay from './TextDisplay';
import { ANALYTICS_DATA_DEFAULT_PLACEHOLDER } from 'shared/CSharedConstants';
import { SEPARATOR_THICKNESS } from 'pixi-project/core/BaseAnalyticsManager';

export default class CommonForecastingSegment extends PIXI.Container {
  constructor() {
    super();
    this.currency = '';
    this.textDisplay = new TextDisplay();
    this.addChild(this.textDisplay);
  }

  setData({ title, value, cost, revenue, width = 100 }) {
    const displayData = [
      { value: title, ...Styles.FOOTER_TITLE },
      { value: value, ...Styles.FORECASTING_NUMBER },
    ];

    if (cost !== undefined) {
      displayData.push({
        isSeparator: true,
        color: COLOR_DISPLAY_SEPARATOR,
        thickness: SEPARATOR_THICKNESS,
        width: width,
        offsetY: -2,
      });
      displayData.push({ value: 'Spend', ...Styles.FOOTER_TITLE });
      displayData.push({ value: cost, ...Styles.FORECASTING_NUMBER });
    }

    if (revenue !== undefined) {
      displayData.push({
        isSeparator: true,
        color: COLOR_DISPLAY_SEPARATOR,
        thickness: SEPARATOR_THICKNESS,
        width: width,
        offsetY: -2,
      });
      displayData.push({ value: 'Revenue', ...Styles.FOOTER_TITLE });
      displayData.push({ value: revenue, ...Styles.FORECASTING_NUMBER });
    }

    this.textDisplay.setData(displayData);
  }

  setNoData() {
    this.textDisplay.setData(['n/a']);
  }

  resetData() {
    this.textDisplay.setData([ANALYTICS_DATA_DEFAULT_PLACEHOLDER]);
  }

  getArea() {
    return this.textDisplay.areaSize;
  }
}
