export const TrackerProfileType = 'tracker-profile';
export const TrackerProfileAttributeType = 'tracker-profile-attribute';
export const TrackerSessionType = 'tracker-session';

export const UserType = 'user';
export const UserMetaPropertyType = 'user-meta-property';
export const RoleType = 'role';
export const MembershipType = 'membership';

export const TrackerEventType = 'tracker-event';
export const TrackerEventAttributeType = 'tracker-event-attribute';
export const TrackerStepType = 'tracker-step';
export const TrackerStepParamType = 'tracker-step-param';
