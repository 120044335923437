import React, { useEffect, useState } from 'react';
import { StepPicker } from '../stepPicker/StepPicker';
import {
  PR_ALL_STEPS,
  RP_EVENT_TRENDS_PICKER_CLOSED,
  RP_EVENT_TRENDS_STEP_PICKED,
} from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';

export const TrendsStepPicker = () => {
  const [objects, setObjects] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const widgetIdRef = React.useRef(null);

  useEffect(() => {
    document.addEventListener(PR_ALL_STEPS, (event) => {
      setObjects(event.detail.objects);
      widgetIdRef.current = event.detail.widgetId;
      setIsVisible(true);
    });
  }, []);

  const onCloseBtnClicked = () => {
    setIsVisible(false);
    commonSendEventFunction(RP_EVENT_TRENDS_PICKER_CLOSED, { widgetId: widgetIdRef.current });
  };

  const onStepPicked = (step) => {
    commonSendEventFunction(RP_EVENT_TRENDS_STEP_PICKED, {
      step,
      widgetId: widgetIdRef.current,
    });
  };

  return isVisible ? (
    <StepPicker onClose={onCloseBtnClicked} steps={objects} onStepPicked={onStepPicked} />
  ) : null;
};
