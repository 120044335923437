import React from 'react';
import { Route } from 'react-router-dom';

import { authContext } from './AuthProvider';
import ExternalRedirect from './ExternalRedirect';

import { getLoginUrl } from 'react-project/Util/ExternalUrlHelper';

const LOGIN_URL = getLoginUrl();

export const AuthRoute = ({ render, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          props.match.path === '/view/:funnelId' ||
          props.match.path === '/tutorial/:funnelId' ||
          props.match.path === '/tutorial'
        ) {
          // view only route , no need to check for auth
          return render(props);
        }

        return (
          <authContext.Consumer>
            {({ auth: { isAuthenticated } }) => {
              return isAuthenticated ? render(props) : <ExternalRedirect to={`${LOGIN_URL}`} />;
            }}
          </authContext.Consumer>
        );
      }}
    />
  );
};
