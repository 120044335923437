import React, { useEffect, useState } from 'react';
import NotFound from 'react-project/Main/NotFound';
import { Funnel } from './Funnel';
import { CanvasPassword } from 'react-project/components/popUp/CanvasPassword/CanvasPassword';
import RequestService from 'react-project/Helpers/RequestService';
import {
  getAuthToken,
  getRefreshToken,
  getUserId,
  getViewOnlyAuth,
} from 'react-project/Util/AuthCookie';
import NoAccess from 'react-project/Main/NoAccess';

const requestService = new RequestService();

export const ViewOnly = (props) => {
  const [isChecking, setIsChecking] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isShared, setIsShared] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [authMessage, setAuthMessage] = useState(null);
  const [isPasswordChecking, setIsPasswordChecking] = useState(false);
  const [isLinkValid, setIsLinkValid] = useState(true);

  useEffect(() => {
    const userId = getUserId();

    if (userId) {
      const accessToken = getAuthToken();
      // but check if this canvas can be accessed

      requestService.validateReadOnlyLink(props.match.params.funnelId).then((result) => {
        if (result.success) {
          setIsShared(true);
          setAccessToken(accessToken);
          setIsAuthorized(true);
          setIsChecking(false);
        } else {
          setIsChecking(false);
          setAccessToken(false);
          setIsAuthorized(false);
        }
      });

      return;
    } else {
      requestService.validateReadOnlyLink(props.match.params.funnelId).then(onCheckFinished);
    }
  }, []);

  const onCheckFinished = (result) => {
    setIsChecking(false);

    if (result.success) {
      setIsShared(true);

      // check if its already authorized
      const viewOnlyAuth = getViewOnlyAuth();
      if (viewOnlyAuth && viewOnlyAuth.access_token) {
        setAccessToken(viewOnlyAuth.access_token);
        setIsAuthorized(true);
        return;
      }

      // If it needs to be authorized
      if (result.data && result.data.password) {
        // there is a password
      } else {
        // No need to be authorized , only to obtain a valid token
        // We can do that by sending the same request as the one that is sent when the password is entered
        // but the password is empty this time
        setIsChecking(true);
        checkPassword();
      }
    } else {
      if (
        result &&
        result.err &&
        result.err.response &&
        result.err.response.data &&
        result.err.response.data.errors &&
        result.err.response.data.errors.length &&
        result.err.response.data.errors[0].error.message === 'Viewonly link was not found'
      ) {
        setIsLinkValid(false);
        return;
      }

      // If the link validation failed
      // but there is a token in the local storage
      // probably that token is expired
      // so lets try to refresh it
      const viewOnlyAuth = getViewOnlyAuth();
      if (viewOnlyAuth && viewOnlyAuth.access_token) {
        // clear a pre existing token
        localStorage.setItem(`view-only-auth-${props.match.params.funnelId}`, undefined);
        // then check again
        requestService.validateReadOnlyLink(props.match.params.funnelId).then(onCheckFinished);
      } else {
        setAccessToken(false);
      }
    }
  };

  const checkPassword = (password) => {
    setIsPasswordChecking(true);
    setAuthMessage(null);
    requestService.authViewOnly(props.match.params.funnelId, password).then((result) => {
      setIsPasswordChecking(false);
      setIsChecking(false);
      if (result.success) {
        if (result.data && result.data.access_token) {
          setAccessToken(result.data.access_token);

          const refresh_token = getRefreshToken();
          const cookieData = { ...result.data, refresh_token };

          const canvasId = props.match.params.funnelId;

          localStorage.setItem(`view-only-auth-${canvasId}`, JSON.stringify(cookieData));
          setIsAuthorized(true);
          setAuthMessage(null);
        }
      } else {
        setIsAuthorized(false);
        setAuthMessage('Incorrect password');
      }
    });
  };

  if (!isLinkValid) {
    return <NotFound message="Invalid link" />;
  } else if (isChecking) {
    return null;
  } else if (accessToken === false) {
    return <NoAccess />;
  } else if (isShared) {
    if (isAuthorized) {
      return <Funnel {...props} accessToken={accessToken} isViewOnlyLink={true} />;
    } else {
      return (
        <CanvasPassword
          checkPassword={checkPassword}
          authMessage={authMessage}
          isChecking={isPasswordChecking}
        />
      );
    }
  } else {
    return <NotFound />;
  }
};
