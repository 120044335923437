import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ActionAttributes } from 'react-project/components/trackingTab/ActionAttributes';
import { TrackingContent } from 'react-project/components/trackingTab/TrackingContent';
import { RevenueActionMetricsDropdown } from 'react-project/components/trackingTab/revenue-action/RevenueActionMetricsDropdown';
import { COMMERCE_ACTION_NAME } from 'react-project/Constants/specialActions';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { RP_EVENT_EDIT_OBJECT } from 'shared/CSharedEvents';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentStep } from 'react-project/redux/current-step/selectors';
import {
  TEXT_PURCHASE,
  TEXT_REVENUE_TRACKING_DESCRIPTION,
  TEXT_REVENUE_TRACKING_SETUP_DESCRIPTION,
  TEXT_REVENUE_TRACKING_SETUP_LINK_DESCRIPTION,
  TEXT_REVENUE_TRACKING_SETUP_TITLE,
  TEXT_REVENUE_TRACKING_TITLE,
} from 'react-project/Constants/texts';
import { isAnalyticsInstalled } from 'react-project/Util/ActionsDetector';
import LocalStorage from 'react-project/Helpers/LocalStorage';
import styles from 'react-project/components/trackingTab/TrackingTabAction.module.scss';
import cls from 'classnames';
import { EditableKeyValueTable } from './EditableKeyValueTable';
import { listViewIcon, tableViewIcon } from 'react-project/assets/Icons';
import { When } from 'react-project/Util/When';
import { setNewCurrentStepFilterParams } from 'react-project/redux/current-step/actions';
import { EventOperators } from './LogicalOperators';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';

const INSTRUCTIONS_LINK =
  'https://hub.funnelytics.io/c/tracking-setup/webhook';

export const RevenueActionTracking = ({ projectId, projectApiKey, onClose, onContentChanged }) => {
  const [hasWorkspaceActions, setHasWorkspaceActions] = useState(false);
  const [hasWorkspaceActionsLoading, setHasWorkspaceActionsLoading] = useState(true);
  const [isTableView, setIsTableView] = useState(LocalStorage.get('isTableView', true));
  const dispatch = useDispatch();
  const canvasPermissions = useSelector(selectCanvasPermissions);

  useEffect(() => {
    isAnalyticsInstalled(projectId, COMMERCE_ACTION_NAME).then((exists) => {
      setHasWorkspaceActionsLoading(false);
      setHasWorkspaceActions(exists);
    });
  }, [projectId]);

  useEffect(() => {
    if (onContentChanged) {
      onContentChanged();
    }
  }, [hasWorkspaceActions, hasWorkspaceActionsLoading]);

  const currentStep = useSelector(selectCurrentStep);

  const displayFilter = currentStep.object.analyticsFilterData?.displayFilter || {};

  const [isTotalRevenueSelected, setIsTotalRevenueSelected] = useState(
    displayFilter.totalRevenew === false ? false : true,
  );
  const [isTotalCustomersSelected, setIsTotalCustomersSelected] = useState(
    displayFilter.totalCustomers === false ? false : true,
  );
  const [isAvgPerCustomerSelected, setIsAvgPerCustomerSelected] = useState(
    displayFilter.avgValuePerCustomer === false ? false : true,
  );

  const handleDeleteAttribute = (newAttributes) => {
    updateStepFilterData(newAttributes);
  };

  const updateStepFilterData = (newFilterData) => {
    dispatch(setNewCurrentStepFilterParams(newFilterData));
    commonSendEventFunction(RP_EVENT_EDIT_OBJECT, {
      stepId: currentStep.stepId,
      label: currentStep.object.label,
      filterData: newFilterData,
    });
  };

  const onParametersChanged = (newFilterData) => {
    updateStepFilterData(newFilterData);
  };

  const activateView = (isTableView) => {
    LocalStorage.set('isTableView', isTableView);
    setIsTableView(isTableView);
  };

  const description = (
    <>
      {TEXT_REVENUE_TRACKING_DESCRIPTION} <b>{TEXT_PURCHASE}</b>
    </>
  );

  return (
    <TrackingContent
      projectId={projectId}
      trackingTabTitle={TEXT_REVENUE_TRACKING_TITLE}
      setupTitle={TEXT_REVENUE_TRACKING_SETUP_TITLE}
      setupInstructionsLink={INSTRUCTIONS_LINK}
      setupLinkDescription={TEXT_REVENUE_TRACKING_SETUP_LINK_DESCRIPTION}
      description={description}
      setupDescription={TEXT_REVENUE_TRACKING_SETUP_DESCRIPTION}
      apiKey={projectApiKey}
      hasWorkspaceActions={hasWorkspaceActions}
      hasWorkspaceActionsLoading={hasWorkspaceActionsLoading}
      onClose={onClose}
    >
      <RevenueActionMetricsDropdown
        isAvgPerCustomerSelected={isAvgPerCustomerSelected}
        isTotalCustomersSelected={isTotalCustomersSelected}
        isTotalRevenueSelected={isTotalRevenueSelected}
        setIsAvgPerCustomerSelected={setIsAvgPerCustomerSelected}
        setIsTotalCustomersSelected={setIsTotalCustomersSelected}
        setIsTotalRevenueSelected={setIsTotalRevenueSelected}
        disabled={canvasPermissions.isReadonlyAccess}
      />

      <div className={styles.CustomActionAttributesHeader}>
        <span>switch view</span>
        <div
          onClick={() => activateView(true)}
          className={cls(styles.CustomActionAttributesViewIcon, isTableView ? styles.Active : null)}
        >
          {tableViewIcon}
        </div>
        <div
          onClick={() => activateView(false)}
          className={cls(
            styles.CustomActionAttributesViewIcon,
            !isTableView ? styles.Active : null,
          )}
        >
          {listViewIcon}
        </div>
      </div>

      <When condition={isTableView}>
        <EditableKeyValueTable
          pairsData={currentStep.object.filterData || []}
          onKeyValueChanged={onParametersChanged}
          isExternalPointerDown={false}
          tableMinHeight={'319px'}
          hasAutoFocus={false}
          availableOperators={EventOperators}
        />
      </When>

      <When condition={!isTableView}>
        <ActionAttributes
          attributes={currentStep.object.filterData || []}
          setAttributes={handleDeleteAttribute}
        />
      </When>
    </TrackingContent>
  );
};

RevenueActionTracking.propTypes = {
  projectId: PropTypes.string.isRequired,
  projectApiKey: PropTypes.string,
};
