import * as PIXI from 'pixi.js';

export default class TableViewSubTitled extends PIXI.Container {
  constructor(title, subTitle, titleStyle, subTitleStyle, titleColor, subTitleColor) {
    super();

    this.titleLabel = new PIXI.BitmapText(title, titleStyle);
    this.titleLabel.tint = titleColor;
    this.addChild(this.titleLabel);

    this.subTitleLabel = new PIXI.BitmapText(subTitle, subTitleStyle);
    this.subTitleLabel.tint = subTitleColor;
    this.addChild(this.subTitleLabel);

    this.subTitleLabel.y = 12;
  }
}
