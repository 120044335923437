import { DATA_MODE_TRAFFIC } from 'shared/CSharedConstants';

export default class Migration4 {
  constructor() {
    this.version = 4;
  }

  migrate(data) {
    data.funnelConfiguration.dataMode = DATA_MODE_TRAFFIC; // set a default data mode to traffic mode
    return data;
  }
}
