import React, { useState } from 'react';
import styles from './StepPicker.module.scss';
import { iconClose, iconSearch } from 'react-project/assets/Icons';
import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';

export const StepPicker = ({ onStepPicked, steps, onClose }) => {
  const [search, setSearch] = useState('');

  const getTexturePath = (object) => {
    if (object.thumbnailURL) {
      return object.thumbnailURL;
    } else if (
      object.texturePath.startsWith('http') ||
      object.texturePath.startsWith('data:image')
    ) {
      return object.texturePath;
    } else if (object.texturePath.startsWith('/asset/')) {
      return object.texturePath;
    }

    return '/asset/' + object.texturePath;
  };

  const onInputChanged = (e) => {
    const value = e.target.value.toLowerCase();
    setSearch(value);
  };

  const onCloseBtnClicked = (e) => {
    onClose(e);
  };

  const onClickOutside = (e) => {
    onClose(e);
  };

  const objectsToRender = search
    ? steps.filter((object) => {
        return object.label.toLowerCase().includes(search);
      })
    : steps;

  return (
    <ClickOutsideCustomComponent onClickOutside={onClickOutside}>
      <div className={styles.Wrapper}>
        <div className={styles.Title}>
          Step List
          <div onClick={onCloseBtnClicked} className={styles.CloseBtn}>
            {iconClose}
          </div>
        </div>
        <div className={styles.SubTitle}>
          The following list reports all the elements on the canvas
        </div>
        <div className={styles.SearchBlock}>
          {iconSearch}
          <input onChange={onInputChanged} type="text" placeholder="Search step..." />
        </div>
        <div className={styles.List}>
          {objectsToRender.map((object) => {
            return (
              <div key={object.ID} className={styles.Item} onClick={() => onStepPicked(object)}>
                <img src={getTexturePath(object)} alt={object.label} />
                <div className={styles.ItemLabel}>{object.label}</div>
              </div>
            );
          })}
        </div>
      </div>
    </ClickOutsideCustomComponent>
  );
};
