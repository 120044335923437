import { PR_EVENT_FUNNEL_CHANGED } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import CommandsBatch from '../CommandsBatch';

export default class CommandBatchMove extends CommandsBatch {
  constructor(selectionManager) {
    super();
    this.selectionManager = selectionManager;
  }

  execute() {
    this.handleFreezeData();
    super.execute();
    this.selectionManager.show();
    commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
  }

  revert() {
    this.handleFreezeData();
    super.revert();
    this.selectionManager.show();
    commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
  }

  handleFreezeData() {
    // This method freezes the move data before the move signal is sent (see BaseContainer move method)
    // so that we have it as a referent point to know how to move the joints that have break points

    const dummyPositon = new PIXI.Point(); // becauase it is not really needed in this case
    const objects = [];

    for (let i = 0; i < this.commands.length; i++) {
      const command = this.commands[i];
      if (command.object) {
        // because the batch might contain other commands not just move commands
        objects.push(command.object);
        command.object.freezeStartMoveData(dummyPositon, null);
      }
    }

    const joints = this.selectionManager.sceneManager.findInclusiveJoints(objects);
    for (let i = 0; i < joints.length; i++) {
      const joint = joints[i];
      joint.saveReferentPoint();
      joint.isShifting = true;
    }
  }
}
