export const selectCanvasNote = (state) => state.notes.canvasNote;
export const selectNotes = (state) => state.notes.objectNotes;
export const selectHasEdits = (state) => state.notes.hasEdits;
export const selectNote = (state, id) => {
  for (let i = 0; i < state.notes.objectNotes.length; i++) {
    const noteData = state.notes.objectNotes[i];
    if (noteData.id === id) {
      return noteData.data;
    }
  }
  return null;
};
