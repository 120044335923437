import * as PIXI from 'pixi.js';
import ControlPoint from './ControlPoint';
import { PR_EVENT_OBJECT_SELECTED } from 'shared/CSharedEvents';
import { EElementCategories, EElementTypes } from 'shared/CSharedCategories';
import MainStorage from 'pixi-project/core/MainStorage';
import generateId from 'pixi-project/utils/IDGenerator';
import BaseSignals from 'pixi-project/base/signals/BaseSignals';
import Styles, {
  COLOR_LABEL_DEFAULT,
  SELECTION_BOUNDARY_GAP,
  TITLE_MARGIN,
} from 'pixi-project/view/Styles';
import { CControlPointTypes } from 'pixi-project/base/containers/CContainerConstants';
import SharedElementHelpers from 'shared/SharedElementHelpers';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import StepDisplay, { BOX_PADDING } from 'pixi-project/view/objects/anaytics-display/StepDisplay';
import { CANVAS_TRANSFORM_SCALE_FACTOR } from 'pixi-project/utils/CanvasTransformator';
import TitleDisplay from 'pixi-project/view/objects/title-display/TitleDisplay';

export const CONTENT_HIT_AREA_PADDING = 20;

export default class BaseContainer extends PIXI.Container {
  constructor(eventHandlers, id = generateId()) {
    super();
    this.titleText = '';
    this._highlighted = false;
    this.valueSecondary = '0';
    this.url = '';
    this.isText = false;
    this.stateData = {};
    this.id = id;
    this._id = PIXI.utils.uid(); // internal integer ID , for performance
    this.segmentIds = []; // Used to optimize culling
    this.cullingBounds = new PIXI.Rectangle();
    this.category = EElementCategories.NONE;
    this.type = EElementTypes.NONE;
    this._previousInteractiveChildrenState = null;
    this.isHovered = false;
    this.isSelected = false;
    this.isFrameShowing = false; // Focus frame or highlight frame
    this.isTitleShifted = false;
    this.isInsideReport = false;
    this.points = [];
    this.goal = null; // Steps and Connections can have goals
    this.badgePosition = new PIXI.Point(-2, -2);
    this.isExcluded = false;
    this.widgetIds = []; // a list of widgets where this element belongs to
    this.forecastingData = null;
    this.forecastingCalculatedData = null;

    this.notesBadge = PIXI.Sprite.from(PIXI.utils.TextureCache['notes-badge']);
    this.titleDisplay = new TitleDisplay();

    this.footer = new StepDisplay(this);

    this.isDebug = false;
    this.isPointerDown = false;

    if (eventHandlers) {
      this.onElementPointerDown = eventHandlers.onElementPointerDown;
      this.onElementPointerMove = eventHandlers.onElementPointerMove;
      this.onElementPointerUp = eventHandlers.onElementPointerUp;
      this.onElementPointerUpOutside = eventHandlers.onElementPointerUpOutside;
      this.onElementPointerOver = eventHandlers.onElementPointerOver;
      this.onElementPointerOut = eventHandlers.onElementPointerOut;
    }

    this.originWidth = 0;
    this.originHeight = 0;
    this.edgePointsAmount = 1;

    this.isShowTool = true;
    this.isResizable = true;
    this.isSelectable = true;
    this.showCornerHandles = true; // Resize handles
    this.showHorizontalEdgeHandles = false; // Resize handles
    this.hasSelectionFrame = true;

    this.frozedData = {
      eventData: null,
      position: {
        x: 0,
        y: 0,
      },
    };

    this.analyticsManager = null;
    this.isLocked = false;
  }

  onNoteChanged() {
    if (!MainStorage.isNotesVisible()) {
      return;
    }

    if (this.notes) {
      this.showNotesBadge();
    } else {
      this.hideNotesBadge();
    }
  }

  showNotesBadge() {
    this.notesBadge.visible = true;
    window.app.needsRendering();
  }

  hideNotesBadge() {
    this.notesBadge.visible = false;
    window.app.needsRendering();
  }

  createNotesBadge() {
    this.notesBadge.scale.set(0.5);
    this.notesBadge.position = this.badgePosition;
    this.addChild(this.notesBadge);
    this.hideNotesBadge();
  }

  // noinspection JSUnusedLocalSymbols
  createImage(data) {
    // todo I don't like the weird check "if this.label". Refactor if necessary
    this.drawTitleLabel(this.titleText);
    this.footer.x = this.content.width / 2;
    this.footer.updateVisibility(
      MainStorage.isNumbersVisible() || MainStorage.isForecastingVisible(),
    );
    this.addChild(this.footer);
    this.highlightFrame = new PIXI.Graphics();
    this.addChild(this.highlightFrame);

    this.footer.resetData();
    this.footer.pushToBack();

    this.createNotesBadge();
  }

  draw(data) {
    this.createImage(data);

    this.interactive = true;
    this.buttonMode = true;
    this.content.interactive = true;
    this.highlightElement(false);

    this.edgePointsAmount = 1;
    this.createAttachPoints();
    this.addEvents();
  }

  addEvents() {
    this.content
      .on('pointerdown', this.onElementPointerDown)
      .on('pointermove', this.onElementPointerMove)
      .on('pointerup', this.onElementPointerUp)
      .on('pointerupoutside', this.onElementPointerUpOutside)
      .on('pointerover', this.onElementPointerOver)
      .on('pointerout', this.onElementPointerOut);
  }

  createAttachPoints() {
    this.topPoint = this.createAttachPoint(CControlPointTypes.TOP);
    this.bottomPoint = this.createAttachPoint(CControlPointTypes.BOTTOM);
    this.rightPoint = this.createAttachPoint(CControlPointTypes.RIGHT);
    this.leftPoint = this.createAttachPoint(CControlPointTypes.LEFT);

    this.updateAttachPoints();
  }

  createAttachPoint(type) {
    const point = new ControlPoint(type);
    point.beginFill(0x000000);
    point.drawCircle(0, 0, 3);
    point.visible = this.isDebug;
    this.points.push(point);
    this.addChildAt(point, 0);
    return point;
  }

  getAttachPoint(type) {
    for (let i = 0; i < this.points.length; i++) {
      const attachPoint = this.points[i];
      if (attachPoint.type === type) {
        return attachPoint;
      }
    }
    return null;
  }

  _setCommonLabelProps(text, element) {
    element.text = text;
    this.addChild(element);
  }

  drawTitleLabel(titleText) {
    if (typeof this.titleText !== 'undefined') {
      this.titleText = titleText;
      this.titleDisplay.setText(titleText);
      this.titleDisplay.x = this.content.width / 2;
      this.titleDisplay.setProgressbarWidth(this.content.width + BOX_PADDING * 2);
      this.titleDisplay.updatePositions();
      if (this.isTitleShifted) {
        this.shiftDisplayLabels();
      } else {
        this.normalizeDisplayLabels();
      }
      this.addChild(this.titleDisplay);
    }
  }

  setURL(url) {
    this.url = url || '';
  }

  /**
   * Update values for an element. Should extend for element-specific values
   * @param data
   */
  updateObject(data) {
    if (typeof data.label !== 'undefined') {
      // noinspection JSUnresolvedFunction
      this.drawTitleLabel(data.label);
    }

    if (data.url !== undefined && this.url !== data.url) {
      this.setURL(data.url);
    }
  }

  updateGoal(goal) {
    this.goal = goal;
    this.analyticsManager.process();
  }

  getPositionPoint() {
    return this.bottomPoint ? this.bottomPoint.getGlobalPosition() : null;
  }

  /**
   * Recalculates the edge control points of the bounds
   * The points are used to snap the connection lines in place.
   */
  updateAttachPoints() {
    if (SharedElementHelpers.IsWidget(this) || SharedElementHelpers.IsReport(this)) {
      return;
    }

    const padding = this.getBoundsPadding();

    const rightOffset = SharedElementHelpers.IsPage(this) ? 60 : 30;

    const centerX = this.content.width / this.edgePointsAmount / 2; // center on the X axis
    const rightX = this.content.width - rightOffset; // get the right point position
    const centerY = this.content.height / this.edgePointsAmount / 2; // center on the Y axis
    let bottomY = this.content.height; // the bottom point position

    if (this.footer && this.footer.visible) {
      bottomY += this.footer.getFooterHeight();
    }

    this.topPoint.x = centerX;
    this.topPoint.y = -padding.top;
    this.bottomPoint.x = centerX;
    this.bottomPoint.y = bottomY + padding.bottom;
    this.rightPoint.x = rightX + padding.right;
    this.rightPoint.y = centerY;
    this.leftPoint.x = -padding.left;
    this.leftPoint.y = centerY;
  }

  getBoundsPadding() {
    let padding = {
      top: 30,
      right: 40,
      bottom: 10,
      left: 14,
    };

    if (SharedElementHelpers.IsPage(this)) {
      padding.right = 70;
    } else if (SharedElementHelpers.IsText(this)) {
      padding.top = 14;
      padding.right = 20;
    } else if (SharedElementHelpers.IsShape(this)) {
      padding.right = 14;
    }

    return padding;
  }

  /**
   * Returns the data that should be send to react when showing a toolbar
   * @returns {{show: *, stepId: *, position: {x: number, y: number}, category: string}}
   * @private
   */
  _getToolbarData(show) {
    let position = this.getPositionPoint();

    if (!position) {
      position = new PIXI.Point();
      console.warn(
        `No position point was found for element: ${this.id} of type:${this.type} please investigate this issue`,
      );
    }

    position.x *= window.app.scaleManager.aspectRatio;
    position.y *= window.app.scaleManager.aspectRatio;
    return {
      position: { x: position.x, y: position.y },
      show,
      stepId: this.id,
      category: this.category,
      supportedLineTypes: this.supportedLineTypes,
    };
  }

  sendPositionForToolbar(show) {
    const data = this._getToolbarData(show);
    commonSendEventFunction(PR_EVENT_OBJECT_SELECTED, data);
  }

  onDestroy() {
    if (!SharedElementHelpers.IsWidget(this)) {
      this.sendPositionForToolbar(false);
    }

    this.content.removeAllListeners();

    this.onElementPointerDown = null;
    this.onElementPointerMove = null;
    this.onElementPointerUp = null;
    this.onElementPointerUpOutside = null;
    this.onElementPointerOver = null;
    this.onElementPointerOut = null;

    for (let i = this.points.length - 1; i >= 0; i--) {
      this.removeChild(this.points[i]);
    }
    this.points = null;
  }

  /**
   * Save current position of the element for future use
   * @param localPosition
   * @param data
   */
  freezeStartMoveData(localPosition, data) {
    this.frozedData = {
      eventData: data,
      position: {
        x: localPosition.x * this.scale.x,
        y: localPosition.y * this.scale.y,
      },
      x: this.x,
      y: this.y,
    };
  }
  /**
   * Get frozen data
   * @returns {null}
   */
  getFrozenData() {
    return this.frozedData;
  }

  move() {
    BaseSignals.moveObject.dispatch(this);
  }

  getState() {
    this.stateData.ID = this.id;
    this.stateData.x = this.x;
    this.stateData.y = this.y;
    this.stateData.scaleX = this.scale.x;
    this.stateData.scaleY = this.scale.y;
    this.stateData.label = this.titleText;
    this.stateData.url = this.url;
    this.stateData.isText = this.isText;
    this.stateData.isShowTool = this.isShowTool;
    this.stateData.isResizable = this.isResizable;
    this.stateData.category = this.category;
    this.stateData.type = this.type;
    this.stateData.isLocked = this.isLocked;
    this.stateData.goal = this.goal;
    this.stateData.forecastingData = this.forecastingData;
    this.stateData.forecastingCalculatedData = this.forecastingCalculatedData;
  }

  updateForecastingData(data) {
    this.forecastingData = { ...this.forecastingData, ...data };

    // check if the data is empty
    let isForecastingEmpty = true;
    for (const key in this.forecastingData) {
      if (this.forecastingData.hasOwnProperty(key) && this.forecastingData[key] !== null) {
        isForecastingEmpty = false;
      } else if (this.forecastingData.hasOwnProperty(key) && this.forecastingData[key] === null) {
        delete this.forecastingData[key];
      }
    }

    if (isForecastingEmpty) {
      this.forecastingData = null;
    }
  }

  getStateWithAnalyticsData() {
    this.getState();
    let analyticsData = null;

    if (this.analyticsManager) {
      analyticsData = this.analyticsManager.data;
    }

    return {
      ...this.stateData,
      analyticsData,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  onSingleSelectionVisibilityChanged(visibitily) {
    // abstract method
  }

  // eslint-disable-next-line class-methods-use-this
  onPointerDown(e) {
    // Abstract method
  }

  onWindowPointerDown(e) {
    // Abstract method
  }

  onWindowPointerUp(e) {
    // Abstract method
  }

  highlightElement(highlight = true) {
    this._highlighted = highlight;
  }

  setInteractiveChildren(interactive) {
    if (this._previousInteractiveChildrenState === null) {
      this._previousInteractiveChildrenState = this.interactiveChildren;
      this.interactiveChildren = interactive;
    }
  }

  revertInteractiveChildren() {
    if (this._previousInteractiveChildrenState !== null) {
      this.interactiveChildren = this._previousInteractiveChildrenState;
      this._previousInteractiveChildrenState = null;
    }
  }

  onAnalyticsDataReceived(data) {
    if (this.analyticsManager) {
      this.analyticsManager.setData(data);
    }
  }

  processAnalyticsData() {
    if (this.analyticsManager) {
      this.analyticsManager.process();
    }
  }

  getFrameOffset() {
    return { x: 0, y: 0 };
  }

  getEndPoint() {
    const scale = window.app.viewport.scaled;
    let footerHeight = 0;
    if (this.footer && this.footer.visible) {
      footerHeight = this.footer.getFooterHeight();
    }
    return {
      width: this.content.width * this.scale.x * scale,
      height: (this.content.height + footerHeight) * this.scale.y * scale,
    };
  }

  get value() {
    return this.analyticsManager ? this.analyticsManager.data.hits : 0;
  }

  onSelected(numberOfObjectsInSelection) {
    this.isSelected = true;
    if (!this.isFrameShowing) {
      this.shiftDisplayLabels();
    }
  }

  onDeselected(numberOfObjectsInSelection) {
    this.isSelected = false;
    if (!this.isFrameShowing) {
      this.normalizeDisplayLabels();
    }
  }

  onSelectionTypeChanged(type) {
    // Abstract method
  }

  /**
   * Event handler fired when a foucs frame or highlight ( yellow ) frame is showing around the element
   * Focus frame is shown when People Who Performed tool is used to "focus" and element
   */
  onFrameShow() {
    this.isFrameShowing = true;
    if (!this.isSelected) {
      this.shiftDisplayLabels();
    }
  }

  /**
   * Event handler fired when a foucs frame or highlight ( yellow ) frame is removed from the element
   * Focus frame is shown when People Who Performed tool is used to "focus" and element
   */
  onFrameHide() {
    this.isFrameShowing = false;
    if (!this.isSelected) {
      this.normalizeDisplayLabels();
    }
  }

  shiftDisplayLabels() {
    this.isTitleShifted = true;
  }

  normalizeDisplayLabels() {
    this.isTitleShifted = false;
  }

  getCullingBounds() {
    this.getLocalBounds(this.cullingBounds);
    this.cullingBounds.x += this.x;
    this.cullingBounds.y += this.y;
    return this.cullingBounds;
  }

  setLockedStatus(status) {
    this.isLocked = status;
  }

  updateFrameSize() {
    this.titleDisplay.x = this.content.width / 2;
    this.footer.x = this.content.width / 2;
    this.updateAttachPoints();
    BaseSignals.moveObject.dispatch(this);
    this.updateHitArea();
  }

  updateHitArea() {
    // abstract method
  }

  onPaste(sceneManager) {
    // Abstract Event Handler
  }

  onSelectionRedraw() {
    // Abstract Event Handler
  }

  isConnectedTo() {
    // Abstract Method
    return false;
  }

  getContentBounds() {
    return this.content.getBounds();
  }

  onLoaded(data, sceneManager) {
    if (
      (SharedElementHelpers.IsStep(this) || SharedElementHelpers.IsShape(this)) &&
      this.isMigratedFromLegacy &&
      !this.isCustom &&
      this.legacySize
    ) {
      const f = this.footer;
      const footerHeight = f && f.visible ? f.getFooterHeight() : 0;

      const localBounds = this.getLocalBounds();
      const height = localBounds.height + footerHeight - this.titleDisplay.getHeight();
      const width = this.content.width;

      const halfScaleFactor = 1 + (CANVAS_TRANSFORM_SCALE_FACTOR - 1) / 2;

      const shiftX = (this.legacySize.width - width) / 2;
      const shiftY = (this.legacySize.height - height * halfScaleFactor) / 2;

      this.x += shiftX;
      this.y += shiftY;

      this.isMigratedFromLegacy = false;
    }
  }

  getAttachmentFrame() {
    const endPoint = this.getEndPoint();
    const viewportScale = window.app.viewport.scaled;
    const padding = SELECTION_BOUNDARY_GAP * viewportScale;
    const topPadding = 25 * viewportScale;

    const width = endPoint.width + 2 * padding;
    const height = endPoint.height + 2 * padding;
    const p = this.getGlobalPosition();
    return new PIXI.Rectangle(
      p.x - padding,
      p.y - padding - topPadding,
      width,
      height + topPadding,
    );
  }
}
