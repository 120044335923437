import React from 'react';

import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';
import styles from './LoopWarningComponent.module.scss';
import cls from 'classnames';
import { iconClose, iconWarrning } from 'react-project/assets/Icons';

export const LoopWarningComponent = ({ onClose }) => {
  return (
    <ClickOutsideCustomComponent
      onClickOutside={(e) => {
        onClose();
      }}
    >
      <div className={styles.Wrapper}>
        <div className={styles.WrapperBox}>
          <div className={cls(styles.Container, styles.Title)}>
            <div className={styles.WarningTitle}>{iconWarrning} Warrning</div>
            <div className={styles.CloseBtn} onClick={() => onClose()}>
              {iconClose}
            </div>
          </div>
          <div className={styles.Separator}></div>

          <div className={cls(styles.Warrning)}>
            This canvas contains a loop!
            <br />
            Please remove the loop to view the data in funnel mode.
            <br />
            Learn more about loops here
            <a href="#">Loops and Funnels</a>
          </div>
        </div>
      </div>
    </ClickOutsideCustomComponent>
  );
};
