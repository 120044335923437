import _ from 'lodash';
export const initUserProfile = (data, position, widgetBounds) => ({
  id: data.id,
  isLoading: true,
  profile: {},
  data,
  journey: [],
  position,
  widgetBounds,
});

export const createProfile = (data) => {
  const profile = {
    country_name: '',
    country_code: '',
    region_code: '',
    region_name: '',
    city: '',
    zip_code: '',
    created_at: '',
    updated_at: '',
  };

  const profileData = data.profile[0];
  if (profileData) {
    profile.country_name = findDataByKey('country_name', profileData.attrs);
    profile.country_code = findDataByKey('country_code', profileData.attrs);
    profile.region_code = findDataByKey('region_code', profileData.attrs);
    profile.region_name = findDataByKey('region_name', profileData.attrs);
    profile.city = findDataByKey('city', profileData.attrs);
    profile.zip_code = findDataByKey('zip_code', profileData.attrs);
    profile.created_at = profileData.created_at;
    profile.updated_at = profileData.updated_at;
  }
  return profile;
};

export const createJourney = (data) => {
  data.events.forEach((event) => {
    event.type = event.name;
  });

  data.steps.forEach((step) => {
    step.type = 'step';
  });

  const journey = _.sortBy(_.flatten(_.concat(data.events, data.steps)), (item) => {
    return item.created_at;
  }).reverse();

  return journey;
};

const findDataByKey = (key, data, defaultValue = '') => {
  let result = defaultValue;
  data.forEach((item) => {
    if (item.key === key) {
      result = item.value;
    }
  });
  return result;
};
