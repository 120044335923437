import React, { useEffect, useRef, useState } from 'react';
import styles from './ParametersBlock.module.scss';
import plainTableStyles from './PlainTable.module.scss';
import { When } from 'react-project/Util/When';
import {
  iconAnalyticsWarning,
  iconCheckmark,
  iconClose,
  iconRefreshBtn,
} from 'react-project/assets/Icons';
import {
  EventOperators,
  OPERATOR_CONTAINS,
  OPERATOR_EQUAL,
  PageOperators,
  SourceOperators,
} from 'react-project/Toolbar/step-toolbar/LogicalOperators';
import { AttributeExplorerList } from 'react-project/Toolbar/step-toolbar/AttributeExplorer/AttributeExplorerList';
import {
  extractSelectedItems,
  transformToFilterData,
} from 'react-project/Toolbar/step-toolbar/AttributeExplorer/AttributeHelper';
import { selectFunnelConfiguration } from 'react-project/redux/funnel-configuration/selectors';
import { selectFocusedSteps } from 'react-project/redux/focused-step/selectors';
import { useSelector } from 'react-redux';
import { EXPLORER_TABS } from 'shared/CSharedConstants';
import { EditableKeyValueTable } from 'react-project/Toolbar/step-toolbar/EditableKeyValueTable';
import { TOOLTIP_POSITIONS } from 'react-project/Constants/tooltip';
import { Tooltip } from 'react-project/components/tooltip/Tooltip';
import ExplorerDataHelper, { ITEMS_ON_PAGE } from '../Helpers/ExplorerDataHelper';
import { SUB_TAB } from '../ExplorerBlock/ExplorerBlockConfig';
import RequestService from 'react-project/Helpers/RequestService';
import axios from 'axios';

let source = axios.CancelToken.source();

const attributesTitles = {
  [EXPLORER_TABS.PAGE]: 'Page Parameters',
  [EXPLORER_TABS.EVENT]: 'Action Parameters',
  [EXPLORER_TABS.SOURCE]: 'Source Parameters',
};

const explorerDataHelper = new ExplorerDataHelper();

export const ParametersBlock = ({
  funnel,
  activeTab,
  activeSubTab,
  selectedItem,
  onCloseClicked,
  onConfirmAndMapClicked,
}) => {
  const funnelConfiguration = useSelector(selectFunnelConfiguration);
  const focusedSteps = useSelector(selectFocusedSteps);
  const [commonParametersExpanded, setCommonParametersExpanded] = useState({});
  const refreshBtnRef = useRef(null);
  const [isMoreLoading, setIsMoreLoading] = useState(false);
  const [canLoadMoreConfig, setCanLoadMoreConfig] = useState({});
  const [hasChange, setHasChange] = useState(false);
  const [lastRequest, setLastRequest] = useState(null);
  const [pagination, setPagination] = useState({});
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [pointerDownValue, setPointerDownValue] = useState(-1);
  const [stepParameters, setStepParameters] = useState({
    attributes: [],
    hits: null,
  });

  const commonParametersData = extractSelectedItems(selectedData, stepParameters.attributes);

  useEffect(() => {
    return () => {
      explorerDataHelper.cancelRequest();
    };
  }, []);

  // keep the helper updated
  useEffect(() => {
    explorerDataHelper.update(
      funnelConfiguration,
      focusedSteps,
      activeTab,
      selectedData,
      selectedItem,
      funnel,
      activeSubTab,
    );
  }, [
    funnelConfiguration,
    focusedSteps,
    activeTab,
    selectedData,
    selectedItem,
    funnel,
    activeSubTab,
  ]);

  useEffect(() => {
    if (refreshBtnRef.current) {
      if (isRefreshing) {
        refreshBtnRef.current.classList.add(styles.SpinAnimation);
      } else {
        refreshBtnRef.current.classList.remove(styles.SpinAnimation);
      }
    }
  }, [isRefreshing]);

  useEffect(() => {
    checkForChanges();
  }, [selectedData]);

  useEffect(() => {
    let preSelectedData = null;

    if (activeTab === EXPLORER_TABS.SOURCE && activeSubTab.id === SUB_TAB.CUSTOM_PARAMS) {
      preSelectedData = [
        {
          key: selectedItem.key,
          value: selectedItem.value !== undefined ? selectedItem.value : '*',
          operator: '=',
        },
      ];
      setSelectedData(preSelectedData);
    }
    explorerDataHelper.selectedItem = selectedItem;
    getAttributeExplorerData(preSelectedData);
  }, [selectedItem]);

  const onConfirmClicked = () => {
    onConfirmAndMapClicked(selectedItem, selectedData, stepParameters);
  };

  const checkForChanges = () => {
    const rBody = explorerDataHelper.prepareRequestBody();
    const a = JSON.stringify(rBody);
    const b = JSON.stringify(lastRequest);
    if (a === b || lastRequest === null) {
      setHasChange(false);
    } else {
      setHasChange(true);
    }
  };

  const onRefreshDataClicked = () => {
    if (!isRefreshing) {
      setPagination({});
      getAttributeExplorerData();
    }
  };

  const onTrackingParametersChanged = (data) => {
    setSelectedData(data);
  };

  const onCommonParametersChanged = (commonParameters) => {
    const joinedParameters = { ...commonParameters }; // , ...commonParametersData

    const defaultOperator = activeTab === EXPLORER_TABS.PAGE ? OPERATOR_CONTAINS : OPERATOR_EQUAL;

    const newFilterData = transformToFilterData(
      joinedParameters,
      selectedData,
      inPageParameters,
      defaultOperator,
    );
    setSelectedData(newFilterData);
  };

  const inPageParameters = (key) => {
    return stepParameters.attributes.find((itm) => itm.key === key);
  };

  const loadMoreItems = async (key) => {
    if (isMoreLoading) {
      return;
    }

    const { page } = pagination[key] || { page: 1 };
    const nextPage = page + 1;

    await loadPaginatedParameters(key, nextPage);

    setPagination({ ...pagination, [key]: { page: nextPage } });
  };

  const loadPaginatedParameters = async (attributeKey, pageNumber) => {
    setIsMoreLoading(true);
    const reqBody = explorerDataHelper.prepareRequestBody(null, attributeKey, pageNumber);
    setLastRequest(reqBody);

    source = axios.CancelToken.source();
    const requestService = new RequestService();
    let responseData =
      activeTab === EXPLORER_TABS.EVENT
        ? await requestService.getAttributeExplorerAttributes(funnel.projectId, reqBody, source)
        : await requestService.getAttributeExplorerPageParams(funnel.projectId, reqBody, source);

    if (axios.isCancel(responseData) || !responseData) {
      setIsMoreLoading(false);
      return;
    }

    // Check for empty data
    if (activeTab === EXPLORER_TABS.EVENT) {
      if (!responseData || !responseData.attributes.length) {
        const emptyItem = { key: attributeKey, values: [] };
        updateLoadMoreConfig([emptyItem]);

        setIsMoreLoading(false);
        return false;
      }
    } else {
      if (
        !responseData ||
        (!responseData.common_parameters.length && !responseData.custom_parameters.length)
      ) {
        const emptyItem = { key: attributeKey, values: [] };
        updateLoadMoreConfig([emptyItem]);

        setIsMoreLoading(false);
        return false;
      }
    }

    stepParameters.attributes.forEach((parameter) => {
      if (parameter.key === attributeKey) {
        let itemWithNewValues = null;
        if (activeTab === EXPLORER_TABS.EVENT) {
          itemWithNewValues = responseData.attributes.find((i) => i.key === attributeKey);
        } else {
          itemWithNewValues = findItem(attributeKey, responseData);
        }

        if (itemWithNewValues && itemWithNewValues.values && itemWithNewValues.values.length) {
          updateLoadMoreConfig([itemWithNewValues]);
          parameter.values = parameter.values.concat(itemWithNewValues.values);

          // not sure about this one
          setStepParameters(stepParameters);
        }
      }
    });

    setIsMoreLoading(false);
  };

  const findItem = (attributeKey, responseData) => {
    const itemWithNewValues = responseData.common_parameters.find((i) => i.key === attributeKey);

    if (itemWithNewValues) {
      return itemWithNewValues;
    }

    const itemWithNewValues2 = responseData.custom_parameters.find((i) => i.key === attributeKey);
    return itemWithNewValues2;
  };

  const updateLoadMoreConfig = (attributes) => {
    attributes.forEach((item) => {
      canLoadMoreConfig[item.key] = item.values.length >= ITEMS_ON_PAGE;
    });

    setCanLoadMoreConfig(canLoadMoreConfig);
  };

  const createLoadMoreConfig = (attributes) => {
    if (attributes.attributes) {
      const common = attributes.attributes.reduce((result, item) => {
        result[item.key] = item.values.length >= ITEMS_ON_PAGE;
        return result;
      }, {});
      setCanLoadMoreConfig({ ...common });
    } else if (attributes.common_parameters && attributes.custom_parameters) {
      const common = attributes.common_parameters.reduce((result, item) => {
        result[item.key] = item.values.length >= ITEMS_ON_PAGE;
        return result;
      }, {});
      const custom = attributes.custom_parameters.reduce((result, item) => {
        result[item.key] = item.values.length >= ITEMS_ON_PAGE;
        return result;
      }, {});

      setCanLoadMoreConfig({ ...common, ...custom });
    }
  };

  const getAttributeExplorerData = async (preSelectedData = null) => {
    setHasChange(false);
    setIsRefreshing(true);
    setStepParameters({
      attributes: [],
      hits: null,
    });

    explorerDataHelper.getAttributeExplorerData({
      preSelectedData,
      callback: (responseData, reqBody) => {
        if (!responseData) {
          // event canceled , do nothing
        } else if (responseData && !responseData.error) {
          // When we get the response, we will collapse all expanded rows
          setCommonParametersExpanded({});

          if (activeTab === EXPLORER_TABS.PAGE) {
            setStepParameters({
              attributes: []
                .concat(responseData.common_parameters)
                .concat(responseData.custom_parameters),
              hits: responseData.hits,
            });
          } else if (activeTab === EXPLORER_TABS.EVENT) {
            setStepParameters(responseData);
          } else if (activeTab === EXPLORER_TABS.SOURCE) {
            setStepParameters({
              attributes: []
                .concat(responseData.common_parameters)
                .concat(responseData.custom_parameters),
              hits: responseData.hits,
            });
          }
          createLoadMoreConfig(responseData);
          // Set data loaded with success
          setIsRefreshing(false);
        } else {
          // error occured
          setIsRefreshing(false);
        }

        setLastRequest(reqBody);
      },
    });
  };

  const getOperators = (activeTab) => {
    switch (activeTab) {
      case EXPLORER_TABS.PAGE:
        return PageOperators;
      case EXPLORER_TABS.EVENT:
        return EventOperators;
      case EXPLORER_TABS.SOURCE:
        return SourceOperators;
      default:
        return [];
    }
  };

  const availableOperators = getOperators(activeTab);

  return (
    <div className={styles.BlockWrapper}>
      <div className={styles.HeaderBlock}>
        <div className={styles.ParametersTitle}>
          {selectedItem.key || selectedItem.name || selectedItem.url}
        </div>
        <div ref={refreshBtnRef} onClick={onRefreshDataClicked} className={styles.Refresh}>
          <Tooltip label="Refresh" position={TOOLTIP_POSITIONS.TOP}>
            {iconRefreshBtn}
          </Tooltip>
          <When condition={!isRefreshing}>
            <div className={styles.StatusIcon}>
              {hasChange ? iconAnalyticsWarning : iconCheckmark}
            </div>
          </When>
        </div>

        <div onClick={onCloseClicked} className={styles.CloseBtn}>
          {iconClose}
        </div>
      </div>

      <EditableKeyValueTable
        pairsData={selectedData}
        onKeyValueChanged={onTrackingParametersChanged}
        isExternalPointerDown={pointerDownValue}
        availableOperators={availableOperators}
        plainTableStyles={plainTableStyles}
      />

      <div className={styles.ConfirmBlock}>
        <div onClick={onConfirmClicked} className={styles.ConfirmBtn}>
          Confirm and map +
        </div>
      </div>

      <When condition={isRefreshing}>
        <div className={styles.MessageBlock}>Loading...</div>
      </When>
      <When condition={!isRefreshing && stepParameters.attributes.length > 0}>
        <AttributeExplorerList
          onSelectionChanged={onCommonParametersChanged}
          title={attributesTitles[activeTab]}
          list={stepParameters.attributes}
          selectedData={commonParametersData}
          expandedRows={commonParametersExpanded}
          setExpandedRows={setCommonParametersExpanded}
          canLoadMoreConfig={canLoadMoreConfig}
          onLoadMore={loadMoreItems}
          isMoreLoading={isMoreLoading}
        />
      </When>
      <When condition={!isRefreshing && stepParameters.attributes.length === 0}>
        <div className={styles.MessageBlock}>No data</div>
      </When>
    </div>
  );
};
