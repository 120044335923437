import cls from 'classnames';
import React from 'react';
import { v4 } from 'uuid';
import styles from './Select-Options.module.scss';

export const Options = ({ optData, ...props }) => {
  /**
   * See https://jsdoc.app/tags-param.html for more info.
   * @param {Array} optData - An array of objects with the following properties:
   * value
   * className (optional) defaults to '', and is used to add custom classes;
   * disabled (optional) defaults to false, and is used to disable the option;
   * @param {Array} disabled, optional - An array of values to be disabled values.
   * @returns {JSX.Element} - A list of options
   * @example   <Options optData={[{ value: 'Healthcare' }, { value: 'Retail' }]} />
   */

  return (
    <>
      {optData.map((option) => {
        return (
          <option
            key={v4()}
            className={cls(styles.Options, option.className)}
            value={option.value}
            disabled={option.disabled || false}
            {...props}
          >
            {option.value}
          </option>
        );
      })}
    </>
  );
};

export const Select = ({ className, disabled, hasError, children, style, ...props }) => {
  /**
   * Use JSdoc to document props.
   * @param {string} className - A string of classes to be added to the select element.
   * @param {JSX.Element} children - A list of options.
   * @param {object} props - Any other props to be added to the select element.
   * @returns {JSX.Element} - A select element.
   * @example <Select className={cls(styles.SomeClass, "w-6/12 pr-[12px]")}>
   *           <Options optData={[{ value: 'Healthcare' }, { value: 'Retail' }]} />
   *        </Select>
   * See https://react.dev/reference/react-dom/components/select for all valid
   * props to be used.
   */
  return (
    <>
      <select
        className={cls(
          'focus:border-4 focus:border-solid focus:border-l-transparent',
          styles.Select,
          disabled && styles.disabled,
          hasError && styles.error,
          className,
        )}
        disabled={disabled}
        style={style}
        {...props}
      >
        {children}
      </select>
    </>
  );
};
