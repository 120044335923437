import cls from 'classnames';
import produce from 'immer';
import React from 'react';
import {
  iconActions,
  iconMisk,
  iconPage,
  iconSearch,
  iconTraffic,
} from 'react-project/assets/Icons';
import { IconList } from 'react-project/components/iconList/IconList';
import { SearchInput } from 'react-project/components/popUp/search/SearchInput';
import searchInputStyles from 'react-project/components/popUp/search/SearchInput.module.scss';
import { LOCK_NO_ACTIONS, UILock } from 'react-project/components/uilock/UILock';
import {
  TEXT_ICON_LIST_CUSTOM_ACTIONS,
  TEXT_ICON_LIST_CUSTOM_SOURCES,
} from 'react-project/Constants/texts';
import RequestService from 'react-project/Helpers/RequestService';
import { selectAnalyticsStatus } from 'react-project/redux/analytics/selectors';
import { selectCustomIcons } from 'react-project/redux/custom-icons/selectors';
import {
  selectExplorerIsLoading,
  selectExplorerItemsConfig,
} from 'react-project/redux/explorer/selectors';
import ExplorerTab from 'react-project/StepsModal/components/ExplorerTab';
import HeaderTitle from 'react-project/StepsModal/components/HeaderTitle';
import styles from 'react-project/StepsModal/StepsModal.module.scss';
import { When } from 'react-project/Util/When';
import { useSelector } from 'react-redux';
import { EExplorerConfigTypes } from 'shared/CSharedCategories';
import {
  ANALYTICS_STATUS_LOADING,
  EXPLORER_TABS,
  MAIN_TABS,
  MAP_TABS,
} from 'shared/CSharedConstants';

export const MainTab = ({
  type,
  getCustomIcons,
  textSearchInputRef,
  textSearch,
  onSearchChange,
  isDisabledMapTab,
  activeMapTab,
  activeTab,
  onDragend,
  activeExplorerTab,
  setActiveExplorerTab,
  propertySearchQuery,
  port,
  setCustomIcons,
  isExploreTrafficEnabled,
  sourceDropdown,
  isLoadingPageParameters,
  explorerDropdownActiveItem,
  position,
  trafficAttributeClick,
  directTrafficClicked,
  onLoadMore,
  onIconClick,
  onSelectDropdownExplorerItem,
  onSelectDropdownTrafficItem,
  stepsModalSetState,
  isInUnorganizedFolder,
  onPropertySearchSend,
  onPropertySearchQuery,
  onAddIconClick,
}) => {
  const MAP_TYPE_TO_CUSTOM_TITLE = new Map([
    [MAP_TABS.EVENT, TEXT_ICON_LIST_CUSTOM_ACTIONS],
    [MAP_TABS.SOURCE, TEXT_ICON_LIST_CUSTOM_SOURCES],
  ]);
  const customUploadedIcons = getCustomIcons(activeMapTab);
  const explorerIsLoading = useSelector(selectExplorerIsLoading);
  const customIcons = useSelector(selectCustomIcons);
  const explorerItemsConfig = useSelector(selectExplorerItemsConfig);
  const analyticsStatus = useSelector(selectAnalyticsStatus);

  const onIconCloseClick = async (e) => {
    const iconId = e.detail.id;
    const requestService = new RequestService();
    await requestService.deleteIcon(iconId);

    const newItems = produce(customIcons, (draft) => {
      const index = draft.findIndex((item) => item.id === iconId);
      if (index !== -1) draft.splice(index, 1);
    });

    setCustomIcons(newItems);
  };

  if (type === MAIN_TABS.MAP) {
    return (
      <div id="main_modal_tabs" style={{ height: 'inherit' }}>
        <div className={styles.SearchSection}>
          {iconSearch}
          <input
            placeholder="Search"
            autoFocus={true}
            ref={textSearchInputRef}
            maxLength="512"
            className={styles.Search}
            value={textSearch}
            onChange={onSearchChange}
            type="text"
          />
        </div>

        <div className={styles.TopSectionIcon}>
          <button
            className={cls(styles.Chip, {
              [styles.Active]: !isDisabledMapTab && activeMapTab === MAP_TABS.SOURCE,
            })}
            onClick={() => activeTab(MAP_TABS.SOURCE)}
          >
            <span className={styles.ChipIcon}>{iconTraffic}</span>
            <span className={styles.ChipText}>Sources</span>
          </button>

          <button
            className={cls(styles.Chip, {
              [styles.Active]: !isDisabledMapTab && activeMapTab === MAP_TABS.PAGE,
            })}
            onClick={() => activeTab(MAP_TABS.PAGE)}
          >
            <span className={styles.ChipIcon}>{iconPage}</span>
            <span className={styles.ChipText}>Pages</span>
          </button>

          <button
            className={cls(styles.Chip, {
              [styles.Active]: !isDisabledMapTab && activeMapTab === MAP_TABS.EVENT,
            })}
            onClick={() => activeTab(MAP_TABS.EVENT)}
          >
            <span className={styles.ChipIcon}>{iconActions}</span>
            <span className={styles.ChipText}>Actions</span>
          </button>

          <button
            className={cls(styles.Chip, {
              [styles.Active]: !isDisabledMapTab && activeMapTab === MAP_TABS.MISC,
            })}
            onClick={() => activeTab(MAP_TABS.MISC)}
          >
            <span className={styles.ChipIcon}>{iconMisk}</span>
            <span className={styles.ChipText}>Offline</span>
          </button>
        </div>

        <IconList
          parentComponent="StepsModal"
          textSearch={textSearch}
          type={activeMapTab}
          colls={5}
          onIconClick={onIconClick}
          onDragEnd={onDragend}
          isDisabledMapTab={isDisabledMapTab}
          otherIconsTitle={MAP_TYPE_TO_CUSTOM_TITLE.get(activeMapTab)}
          // withSpecialItems
          onAddIconClick={onAddIconClick}
          onCloseClick={onIconCloseClick}
          customUploadedIcons={customUploadedIcons}
        />

        <button className={styles.CustomIconsButton}>Manage Custom Icons</button>
      </div>
    );
  } else if (type === 'EXPLORER') {
    const isPageTab =
      activeExplorerTab === EXPLORER_TABS.PAGE ||
      activeExplorerTab === EExplorerConfigTypes.PAGE_ALL;
    const isSourceTab =
      activeExplorerTab === EXPLORER_TABS.SOURCE ||
      activeExplorerTab === EExplorerConfigTypes.SOURCE_ALL;
    const isEventTab =
      activeExplorerTab === EXPLORER_TABS.EVENT ||
      activeExplorerTab === EExplorerConfigTypes.EVENT_ALL;
    const isTrafficTabEnabled = isExploreTrafficEnabled();

    return (
      <div className={styles.ExplorerSection}>
        <div className={styles.TopSectionIcon}>
          <When condition={isTrafficTabEnabled}>
            <button
              className={cls(styles.Chip, { [styles.Active]: isSourceTab })}
              onClick={() => setActiveExplorerTab(EXPLORER_TABS.SOURCE)}
            >
              <span className={styles.ChipIcon}>{iconTraffic}</span>
              <span className={styles.ChipText}>Traffic</span>
            </button>
          </When>

          <button
            className={cls(styles.Chip, { [styles.Active]: isPageTab })}
            onClick={() => setActiveExplorerTab(EXPLORER_TABS.PAGE)}
          >
            <span className={styles.ChipIcon}>{iconPage}</span>
            <span className={styles.ChipText}>Pages</span>
          </button>
          <UILock
            lock={LOCK_NO_ACTIONS}
            style={{
              flexGrow: 1,
              flexBasis: 0,
              display: 'Flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            contentStyle={{ display: 'flex', justifyContent: 'stretch', width: '100%' }}
          >
            <button
              className={cls(styles.Chip, { [styles.Active]: isEventTab })}
              onClick={() => setActiveExplorerTab(EXPLORER_TABS.EVENT)}
            >
              <span className={styles.ChipIcon}>{iconActions}</span>
              <span className={styles.ChipText}>Actions</span>
            </button>
          </UILock>
        </div>
        <div className={styles.SectionSearch}>
          <SearchInput
            className={searchInputStyles.ExplorerSearch}
            value={propertySearchQuery}
            onChange={(value) => {
              onPropertySearchQuery(value);
            }}
            onEnterClicked={(value) => {
              onPropertySearchSend(value);
            }}
            placeholder="Search"
          />
        </div>

        <div className={styles.ExplorerSectionHeader}>
          <div className={styles.StepsTitle}>
            {!port ? (
              <span>{isSourceTab ? 'Traffic sources' : 'All Steps'}</span>
            ) : (
              <HeaderTitle
                param={activeExplorerTab}
                onSelectDropdownExplorerItem={onSelectDropdownExplorerItem}
                explorerDropdownActiveItem={explorerDropdownActiveItem}
                port={port}
                sourceDropdown={sourceDropdown}
                onSelectDropdownTrafficItem={onSelectDropdownTrafficItem}
              />
            )}
          </div>
        </div>
        {/* <When condition={!(explorerIsLoading || analyticsStatus === ANALYTICS_STATUS_LOADING)}> */}
        <ExplorerTab
          type={activeExplorerTab}
          activeExplorerTab={activeExplorerTab}
          port={port}
          sourceDropdown={sourceDropdown}
          isLoadingPageParameters={isLoadingPageParameters}
          explorerDropdownActiveItem={explorerDropdownActiveItem}
          position={position}
          explorerItemsConfig={explorerItemsConfig}
          analyticsStatus={analyticsStatus}
          trafficAttributeClick={trafficAttributeClick}
          directTrafficClicked={directTrafficClicked}
          onDragEnd={onDragend}
          onLoadMore={onLoadMore}
          onIconClick={onIconClick}
          stepsModalSetState={stepsModalSetState}
          isInUnorganizedFolder={isInUnorganizedFolder}
        />

        <When condition={explorerIsLoading || analyticsStatus === ANALYTICS_STATUS_LOADING}>
          <div className={styles.LoadingData}>
            <span>Loading Data ...</span>
          </div>
        </When>
      </div>
    );
  }
};

export default MainTab;
