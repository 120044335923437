import { CommandStatus } from '../CommandManager';

export default class CommandTextWrap {
  constructor(object, startWrapWidth, endWrapWidth = null) {
    this.object = object;
    this.startWrapWidth = startWrapWidth;
    this.endWrapWidth = endWrapWidth;

    this.isExecuted = false;
  }

  execute() {
    if (!this.isExecuted) {
      this.object.wordWrapWidth = this.endWrapWidth;
      this.object.updateContentRectangle();
      this.isExecuted = true;
    }
  }

  revert() {
    if (this.isExecuted) {
      this.object.wordWrapWidth = this.startWrapWidth;
      this.object.updateContentRectangle();
      this.isExecuted = false;
    }
  }
}
