import { produce } from 'immer';
import { handleActions } from 'redux-actions';
import { setEmail, setPermission, setShowDeveloperContent, setCanvasPermissions } from './actions';

const initialState = {
  permissions: [],
  email: null,
  canvasPermissions: {
    isActionsAllowed: null,
    isAnalyticsAllowed: null,
    isForecastAllowed: true,
    notesAllowed: null,
    checklistAllowed: null,
    viewOnlyAllowed: null,
    pngExportAllowed: null,
    iconsAllowed: null,
    peopleCompareFilterAllowed: null,
    pathExploreAllowed: null,
    peopleFilterAllowed: null,
    trafficModeAllowed: null,
    widgetsAllowed: null,
    peopleDataExportAllowed: null,
    peopleCsvExportAllowed: null,
    gohighlevelIntegrationAllowed: null,
    hubspotIntegrationAllowed: null,
    webhookIntegrationAllowed: null,
    zapierIntegrationAllowed: null,
    calendarTrackingAllowed: null,
    dateCompareFilterAllowed: null,
    dealTrackingAllowed: null,
    formTrackingAllowed: null,
    purchaseTrackingAllowed: null,
    isEligibleForFreeTrial: null,
    isFunnelytics: null,
    isInTrial: null,
    unlockCanvasAllowance: {
      unlocked_canvases_allowed: -1,
      unlocked_canvases: -1,
    },
    dataRetention: 24,
    isCanvasLocked: null,
    isReadonlyAccess: null,
    isCollaborator: null,
    hasStripePayment: null,
    isTutorial: null,
    isAdminImpersonation: null,
  },
  showDeveloperContent: false,
};

export default handleActions(
  {
    [setPermission.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.permissions = [action.payload];
      }),
    [setEmail.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.email = action.payload;
      }),
    [setShowDeveloperContent.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.showDeveloperContent = action.payload;
      }),
    [setCanvasPermissions.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.canvasPermissions = action.payload;
      }),
  },
  initialState,
);
