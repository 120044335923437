import { LayerType } from 'react-project/LayersMenu/LayerType';

export default class Migration5 {
  constructor() {
    this.version = 5;
  }

  migrate(data) {
    data.funnelConfiguration.visibleDataLayers[LayerType.FORECASTING] = false;

    return data;
  }
}
