import React from 'react';
import { NO_DATA_LINK_SUPPORT } from 'shared/CSharedConstants';
import styles from './NoDataBlock.module.scss';

export const NoDataBlock = () => {
  return (
    <div className={styles.ContainerGrey}>
      <div className={styles.ChildText}>
        No data available.
        <p>
          Please review your filter selections or visit
          <a target="_blank" href={NO_DATA_LINK_SUPPORT}>
            this support article
          </a>
          to troubleshoot other causes.
        </p>
      </div>
    </div>
  );
};
