import React from 'react';
import { Tab } from 'react-project/components/tabsHorizontal/Tab';
import { TabContainer } from 'react-project/components/tabsHorizontal/TabContainer';
import { ShareTabs } from 'react-project/Sharing/constants';
export const Head = ({ currentTab, onClick }) => {
  return (
    <TabContainer>
      <Tab currentTab={currentTab} onClick={onClick} tabName={ShareTabs.SHARING_LINK}>
        Sharing Link
      </Tab>
      <Tab
        currentTab={currentTab}
        tabName={ShareTabs.CANVAS_LIBRARY}
        disabled={false}
        onClick={onClick}
        style={{ cursor: 'default' }}
      >
        Template Library
      </Tab>

      {/* <Tab
        currentTab={currentTab}
        tabName={ShareTabs.COLLABORATORS}
        disabled={true}
        onClick={onClick}
        style={{ cursor: 'default' }}
      >
        Collaborators
      </Tab> */}
    </TabContainer>
  );
};
