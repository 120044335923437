import * as PIXI from 'pixi.js';
import Styles, {
  COLOR_WIDGET_HEADER,
  COLOR_WIDGET_OUTLINE,
  COLOR_WIDGET_TEXT,
  COLOR_WIDGET_ZEBRA,
} from '../../Styles';

// DELEGATE

export default class TableViewStatic extends PIXI.Container {
  constructor(rowsClass = null) {
    super();
    this.delegate = null;

    this.background = new PIXI.Graphics();
    this.addChild(this.background);

    this.isPaginated = true;
    this.currentPage = 0;
    this.pageSize = 5;
    this.zebraColor = COLOR_WIDGET_ZEBRA;

    this.tableSize = {
      width: 0,
      height: 0,
    };

    this.rowSize = {
      width: 0,
      height: 0,
    };

    this.dynamicWidthAvailable = 0;
    this.headerHeight = 0;
    this.columnSpacing = 0;

    this.rowClass = rowsClass;

    this.headerData = [];
    this.rowsData = [];

    this.titles = [];
    this.rows = [];
  }

  initRows() {
    this.clearRows();

    for (let i = 0; i < this.pageSize; i++) {
      const row = new this.rowClass(this.headerData, this.rowSize, this.columnSpacing);
      this.addChild(row);
      this.rows.push(row);
    }
  }

  clearRows() {
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      row.removeFromParent();
    }
    this.rows = [];
  }

  renderTable() {
    if (!this.rows.length) {
      this.initRows();
    }

    this.renderHeader();
    this.renderRows();
  }

  clearTitles() {
    for (let i = 0; i < this.titles.length; i++) {
      const titleLabel = this.titles[i];
      titleLabel.removeFromParent();
    }
    this.titles = [];
  }

  renderHeader() {
    let x = this.columnSpacing;

    this.clearTitles();

    for (let i = 0; i < this.headerData.length; i++) {
      const headerData = this.headerData[i];
      const titleLabel = new PIXI.BitmapText(headerData.title, Styles.WIDGET_TITLE_LABEL);
      titleLabel.tint = COLOR_WIDGET_HEADER;
      this.titles.push(titleLabel);
      this.addChild(titleLabel);

      let segmentWidth = headerData.calculatedWidth;

      if (headerData.align === 'left') {
        titleLabel.anchor.set(0, 0.5);
        titleLabel.x = x;
      } else if (headerData.align === 'right') {
        titleLabel.anchor.set(1, 0.5);
        titleLabel.x = x + segmentWidth;
      } else {
        // center
        titleLabel.anchor.set(0.5, 0.5);
        titleLabel.x = x + segmentWidth / 2;
      }
      titleLabel.y = this.headerHeight / 2;
      x += segmentWidth + this.columnSpacing;
    }

    this.background.clear();
    this.background.lineStyle(1, COLOR_WIDGET_OUTLINE, 1);
    this.background.moveTo(0, this.headerHeight);
    this.background.lineTo(this.tableSize.width, this.headerHeight);
  }

  renderRows() {
    if (this.isPaginated) {
      this.renderPaginatedRows();
    } else {
      this.renderScrollable();
    }
  }

  renderPaginatedRows() {
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      row.visible = false;
    }

    let start = this.currentPage * this.pageSize;

    for (let i = start; i < start + this.pageSize && i < this.rowsData.length; i++) {
      let index = i - start;
      const data = this.rowsData[i];
      const row = this.rows[index];
      row.setData(data);
      const isEven = index % 2 == 0;
      row.displayData(isEven);
      row.y = this.headerHeight + index * this.rowSize.height;
      row.visible = true;
    }
  }

  renderScrollable() {
    console.warn('//TODO scrollable table needs to be implemented in future');
  }

  setHeaderData(headerData) {
    this.headerData = headerData;

    if (!this.headerData) {
      return;
    }

    // Calculate the available space
    this.dynamicWidthAvailable = this.tableSize.width;
    for (let i = 0; i < this.headerData.length; i++) {
      const data = this.headerData[i];
      if (data.width > 1) {
        this.dynamicWidthAvailable -= data.width + this.columnSpacing;
      }
    }
    this.dynamicWidthAvailable -= this.columnSpacing; // subtract the end padding

    // Calculate the absolute widts of the columns
    for (let i = 0; i < this.headerData.length; i++) {
      const data = this.headerData[i];
      if (data.width > 1) {
        data.calculatedWidth = data.width;
      } else {
        data.calculatedWidth = data.width * this.dynamicWidthAvailable - this.columnSpacing;
      }
    }
  }

  setRowsData(rowsData) {
    this.rowsData = rowsData;
  }
}
