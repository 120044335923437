import React from 'react';
import { PeopleJourney } from 'react-project/Toolbar/step-toolbar/PeopleTracking/PeopleJourney';
import { useSelector } from 'react-redux';

export default function PeopleTrackingPopup() {
  const peopleData = useSelector((state) => state.peopleTracking.peopleTrackingPopups) || [];
  return peopleData.map((personData) => (
    <PeopleJourney
      key={`ses-${personData.session.id}`}
      session={personData.session}
      eventTarget={personData.target}
    />
  ));
}
