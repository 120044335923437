import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';
import { Dropdown } from 'react-project/components/dropdown/Dropdown';
import styles from './TextToolbar.module.scss';
import colorPickerStyles from 'react-project/components/colorPicker/ColorPicker.module.scss';
import cls from 'classnames';
import commonDropdownStyles from 'react-project/components/dropdown/Dropdown.module.scss';
import { DropdownBlock } from 'react-project/components/dropdown/DropdownBlock';
import React, { forwardRef, useState } from 'react';

const WHITE_COLOR = '#ffffff';

const TextColorDropdownTrigger = forwardRef(({ isOpen, selectedColor, ...rest }, ref) => {
  const tabClassName = cls(styles.WithBorder, styles.TextToolbarTab);
  const isWhite = selectedColor === '#ffffff' ? true : false;
  return (
    <div className={tabClassName} {...rest}>
      <div
        className={cls(colorPickerStyles.Wrapper, styles.TriggerWrapper, {
          [styles.ActiveStyle]: isOpen,
        })}
      >
        <button
          style={{ background: selectedColor }}
          className={cls(styles.ColorPickerTrigger, { [styles.ColorPickerWhiteItem]: isWhite })}
        ></button>
      </div>
    </div>
  );
});

export const TextColorDropdown = ({ items, selectedValue, onSelect }) => {
  const [dropdownOpened, setDropdownOpened] = useState(false);

  return (
    <ClickOutsideCustomComponent
      onClickOutside={() => {
        setDropdownOpened(false);
      }}
    >
      <Dropdown
        contentClassName={styles.TextDropdown}
        triggerSlot={<TextColorDropdownTrigger selectedColor={selectedValue} />}
        onToggle={(opened) => setDropdownOpened(opened)}
        isOpen={dropdownOpened}
      >
        <div className={cls(commonDropdownStyles.CommonDropdownItems)}>
          <DropdownBlock>
            <div className={colorPickerStyles.Wrapper}>
              {items.map((item) => (
                <button
                  key={item}
                  onClick={() => {
                    setDropdownOpened(false);
                    onSelect(item);
                  }}
                  style={{
                    background: item,
                    border: item === WHITE_COLOR ? '1px solid #ccc' : 'none',
                  }}
                  className={{ [colorPickerStyles.ActiveColor]: selectedValue === item }}
                ></button>
              ))}
            </div>
          </DropdownBlock>
        </div>
      </Dropdown>
    </ClickOutsideCustomComponent>
  );
};
