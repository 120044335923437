import MainStorage from 'pixi-project/core/MainStorage';
import Utils from 'pixi-project/utils/Utils';
import * as PIXI from 'pixi.js';
import Styles, { COLOR_TOOLTIP_BACKGROUND, COLOR_TOOLTIP_TEXT } from './Styles';

export const TOOLTIP_LOCATION = {
  top: 'top',
  right: 'right',
  left: 'left',
  bottom: 'bottom',
};

export default class ToolTipBox extends PIXI.Container {
  constructor(element, content, options) {
    super();

    this.element = element;
    this.content = content;
    this.options = {
      padding: 15,
      fontSize: 13,
      cornerRadius: 4,
      textColor: COLOR_TOOLTIP_TEXT,
      backgroundColor: COLOR_TOOLTIP_BACKGROUND,
      margin: 10,
      location: TOOLTIP_LOCATION.top, // localtion can be TOOLTIP_LOCATION or an array containing TOOLTIP_LOCATION
      onBeforeShow: null, // method invoked before showing the tooltip
      removeTarget: null, // an element to listen when being removed from the stage ,
      // If the element we are hovering was removed from the stage
      // we will not get pointerout event fired , so we better listed for removal
      // Remove target must be the parent element removed from the stage.
      ...(options || {}),
    };

    this.background = new PIXI.Graphics();
    this.addChild(this.background);

    this.pointer = new PIXI.Graphics();
    this.pointer.beginFill(this.options.backgroundColor);
    this.pointer.lineStyle(0, 0xffffff);
    this.pointer.moveTo(0, -this.options.margin / 2);
    this.pointer.lineTo(this.options.margin / 2, this.options.margin / 2);
    this.pointer.lineTo(-this.options.margin / 2, this.options.margin / 2);
    this.pointer.lineTo(0, -this.options.margin / 2);
    this.pointer.closePath();
    this.pointer.endFill();
    this.addChild(this.pointer);

    this.label = new PIXI.Text('', {
      ...Styles.TOOLTIP_LABEL,
      fill: this.options.textColor,
      fontSize: this.options.fontSize,
    });
    this.label.position.set(this.options.padding, this.options.padding / 2);
    this.addChild(this.label);

    this.setContent(content);

    // In order to understand what the config values mean , check out the fitTo method
    // Hint: they are multiplication factors mostly saying how much of the width/height , padding and other lenghts should be taken into account
    // So multipying with 0 means they will be ignored , -1 will make them subtruct , 0.5 will caluculate only half etc ...
    // the very last values are the angle , and an array of directions to check if the tooltip can fit.
    this.checkMethodsConfigs = {
      [TOOLTIP_LOCATION.top]: [
        1,
        0.5,
        0,
        1,
        0,
        -1,
        -0.5,
        -1,
        0.5,
        0,
        1,
        0.5,
        180,
        ['top', 'left', 'right'],
      ],
      [TOOLTIP_LOCATION.right]: [
        1,
        1,
        1,
        1,
        0.5,
        0,
        0,
        -0.5,
        0,
        -0.5,
        0.5,
        0,
        -90,
        ['top', 'bottom', 'right'],
      ],
      [TOOLTIP_LOCATION.bottom]: [
        1,
        0.5,
        0,
        1,
        1,
        1,
        -0.5,
        0,
        0.5,
        0,
        0,
        -0.5,
        0,
        ['left', 'bottom', 'right'],
      ],
      [TOOLTIP_LOCATION.left]: [
        1,
        0,
        -1,
        1,
        0.5,
        0,
        -1,
        -0.5,
        1,
        0.5,
        0.5,
        0,
        90,
        ['left', 'bottom', 'top'],
      ],
    };

    // Attache event listeners to the element, so that we can show and hide the tooltip automagicaly
    this.attachEventListeners();
  }

  attachEventListeners() {
    this.element.interactive = true;
    this.element.on('pointerover', () => {
      if (this.options.onBeforeShow) {
        this.options.onBeforeShow(this);
      }
      this.show();
      window.app.needsRendering();
    });

    this.element.on('pointerout', () => {
      this.hide();
      window.app.needsRendering();
    });

    if (this.options.removeTarget) {
      this.options.removeTarget.on('removed', () => {
        this.hide();
        window.app.needsRendering();
      });
    }
  }

  setContent(content) {
    // This makes it easy to adjust to content
    // it might be something else , not just a string
    this.content = content;
    this.label.text = content;

    const width = this.label.width + this.options.padding * 2;
    const height = this.label.height + this.options.padding;

    this.background.clear();
    this.background.lineStyle(0, 0xffffff, 0);
    this.background.beginFill(this.options.backgroundColor, 1);
    this.background.drawRoundedRect(0, 0, width, height, this.options.cornerRadius);
    this.background.endFill();
  }

  show(content = null) {
    if (content !== null) {
      this.setContent(content);
    }

    window.app.viewport.addChild(this);
    this.visible = true;
    this.scale.set(1 / window.app.viewport.scaled);

    this.calculatePosition();
  }

  hide() {
    this.removeFromParent();
  }

  calculatePosition() {
    const viewport = window.app.viewport;

    // Lets first find the bounds of the visible viewport
    const menusOffset = MainStorage.getViewportSideOffset();
    const x = menusOffset.left;
    const y = menusOffset.top;
    const width = viewport.screenWidth - menusOffset.right - menusOffset.left;
    const height = viewport.screenHeight - menusOffset.bottom - menusOffset.top;

    const viewportBounds = new PIXI.Rectangle(x, y, width, height);
    const elementBounds = this.element.getBounds();

    const configs = Object.values(this.checkMethodsConfigs);

    // Check the prioritized localtions first
    // and we do that by adding them at the beginning of the config array
    if (Array.isArray(this.options.location)) {
      for (let i = this.options.location.length - 1; i >= 0; i--) {
        const loc = this.options.location[i];
        configs.unshift(this.checkMethodsConfigs[loc]);
      }
    } else {
      configs.unshift(this.checkMethodsConfigs[this.options.location]);
    }

    // Check in which orientation the tooltip can fit in
    for (let i = 0; i < configs.length; i++) {
      const checkLocaltionConf = configs[i];
      if (this.fitTo(elementBounds, viewportBounds, checkLocaltionConf)) {
        // once it fits , leave it there ,that is why the order is important
        break;
      }
    }
  }

  fitTo(elementBounds, viewportBounds, conf) {
    // In order to understand this method please check checkMethodsConfigs property
    const viewport = window.app.viewport;
    const fitPosition = new PIXI.Point();

    // The values are multipied with a factor , in order to determine if they are taken into account
    // For example if you want to ignore the margin , set that conf value to be 0
    // the same way you can make them negative or take any percetnage out of the ,like 0.5 will be half of it

    // first determine the nearest point at the given direction ( based on the conf)
    // this gives top/left/right/bottom points with the margin offset
    fitPosition.set(
      elementBounds.x * conf[0] + elementBounds.width * conf[1] + this.options.margin * conf[2],
      elementBounds.y * conf[3] + elementBounds.height * conf[4] + this.options.margin * conf[5],
    );
    const lfp = viewport.toLocal(fitPosition);
    this.position = lfp;

    // then move the tooltip so that it is correctly positioned at that point
    // as the tooltip box is drawn at anchor point 0,0
    this.x += this.width * conf[6];
    this.y += this.height * conf[7];

    this.pointer.visible = false;
    const bounds = this.getBounds();

    // perform a check if the tooltip box fits in
    const canFit = this.fitTest(bounds, viewportBounds);

    // caluclate the little arrow position and orientation
    this.pointer.position.set(
      bounds.width * conf[8] + this.options.margin * conf[9],
      bounds.height * conf[10] + this.options.margin * conf[11],
    );
    this.pointer.rotation = Utils.deg2rad(conf[12]);
    this.pointer.visible = true;

    // check if the the box actually fits in
    if (canFit[conf[13][0]] && canFit[conf[13][1]] && canFit[conf[13][2]]) {
      return true;
    }

    return false;
  }

  fitTest(bounds, viewportBounds) {
    // Check which sides are fitted in on a rectangle inside another rectangle
    const canFit = {
      top: false,
      right: false,
      bottom: false,
      left: false,
    };

    if (bounds.y > viewportBounds.y) {
      canFit.top = true;
    }

    if (bounds.x > viewportBounds.x) {
      canFit.left = true;
    }

    if (bounds.x + bounds.width < viewportBounds.x + viewportBounds.width) {
      canFit.right = true;
    }

    if (bounds.y + bounds.height < viewportBounds.y + viewportBounds.height) {
      canFit.bottom = true;
    }

    return canFit;
  }
}
