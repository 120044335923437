import { LayerType } from 'react-project/LayersMenu/LayerType';

/**
 * Class for storing all data for pixi side of the project (for now)
 */
class MainStorage {
  togglePanelStatus = {};

  canvasPermissions = {
    isActionsAllowed: null,
    isAnalyticsAllowed: null,
    isForecastAllowed: null,
    notesAllowed: null,
    checklistAllowed: null,
    viewOnlyAllowed: null,
    pngExportAllowed: null,
    iconsAllowed: null,
    peopleCompareFilterAllowed: null,
    pathExploreAllowed: null,
    peopleFilterAllowed: null,
    trafficModeAllowed: null,
    widgetsAllowed: null,
    peopleDataExportAllowed: null,
    peopleCsvExportAllowed: null,
    gohighlevelIntegrationAllowed: null,
    hubspotIntegrationAllowed: null,
    webhookIntegrationAllowed: null,
    zapierIntegrationAllowed: null,
    calendarTrackingAllowed: null,
    dateCompareFilterAllowed: null,
    dealTrackingAllowed: null,
    formTrackingAllowed: null,
    purchaseTrackingAllowed: null,
    isEligibleForFreeTrial: null,
    isFunnelytics: null,
    isInTrial: null,
    unlockCanvasAllowance: {
      unlocked_canvases_allowed: -1,
      unlocked_canvases: -1,
    },
    dataRetention: 24,
    isCanvasLocked: null,
    isReadonlyAccess: null,
    isCollaborator: null,
    hasStripePayment: null,
    isTutorial: null,
    isAdminImpersonation: null,
  };

  analyticsDataInfo = {};

  calendarData = {
    startDate: null,
    endDate: null,
    days: 0,
  };

  setCalendarData(data) {
    this.calendarData.startDate = data.startDate;
    this.calendarData.endDate = data.endDate;
    this.calendarData.days = data.days;
  }

  setTogglePanelStatus(data) {
    this.togglePanelStatus[LayerType.NUMBERS] = data[LayerType.NUMBERS];
    this.togglePanelStatus[LayerType.FLOW] = data[LayerType.FLOW];
    this.togglePanelStatus[LayerType.LEAD_TIME] = data[LayerType.LEAD_TIME];
    this.togglePanelStatus[LayerType.NOTES] = data[LayerType.NOTES];
    this.togglePanelStatus[LayerType.CHECKLIST] = data[LayerType.CHECKLIST];
    this.togglePanelStatus[LayerType.FORECASTING] = data[LayerType.FORECASTING];
  }

  isForecastingVisible() {
    return this.togglePanelStatus[LayerType.FORECASTING];
  }

  isNumbersVisible() {
    return this.togglePanelStatus[LayerType.NUMBERS];
  }

  isFlowVisible() {
    return this.togglePanelStatus[LayerType.FLOW];
  }

  isLeadTimeVisible() {
    return this.togglePanelStatus[LayerType.LEAD_TIME];
  }

  isNotesVisible() {
    return this.togglePanelStatus[LayerType.NOTES];
  }

  isChecklistVisible() {
    return this.togglePanelStatus[LayerType.CHECKLIST];
  }

  getTogglePanelStatus() {
    return this.togglePanelStatus;
  }

  getBottomPanelSize() {
    return this.getBoundsByElementId('bottom-bar');
  }

  getLeftPanelSize() {
    return this.getBoundsByElementId('left-sidebar');
  }

  getHeaderPanelSize() {
    return this.getBoundsByElementId('header');
  }

  getBoundsByElementId(elementID) {
    const element = document.getElementById(elementID);
    return element ? element.getBoundingClientRect() : new DOMRect();
  }

  getViewportSideOffset() {
    // used to transfer the dom size of the side menus into canvas internal size
    const scaleFactor = window.app.scaleManager.aspectRatio;

    // get the panel sizes
    const leftWidth = this.getLeftPanelSize().width / scaleFactor;
    const bottomHeight = this.getBottomPanelSize().height / scaleFactor;
    const topHeight = this.getHeaderPanelSize().height / scaleFactor;

    return { left: leftWidth, top: topHeight, bottom: bottomHeight, right: 0 };
  }

  getCanvasPermissions() {
    return this.canvasPermissions;
  }

  setCanvasPermissions(data) {
    this.canvasPermissions = data;
    // We can set the data only once and then freeze the object
    Object.freeze(this.canvasPermissions);
    Object.freeze(this);
  }

  setHasAnalyticsData(data) {
    this.analyticsDataInfo.hasAnalyticsData = data;
    Object.freeze(this.analyticsDataInfo);
  }

  getHasAnalyticsData() {
    return this.analyticsDataInfo.hasAnalyticsData;
  }
}

export default new MainStorage();
