import cls from 'classnames';
import React from 'react';
import Styles from './TabsHorizontal.module.scss';

export const Tab = ({ currentTab, tabName, onClick, disabled, className, style, children }) => {
  //Horizontal tabs that highlight the current tab and
  //display the content of the current tab

  const isSelected = currentTab === tabName;
  return (
    <div
      onClick={() => !disabled && onClick(tabName)}
      className={cls(
        Styles.tab,
        className,
        isSelected && !disabled && Styles.selected,
        disabled && Styles.disabled,
      )}
      style={style}
    >
      {children}
    </div>
  );
};
