import * as PIXI from 'pixi.js';
import { AnalyticsHelper } from 'shared/AnalyticsHelper';
import { roundTo } from 'shared/CSharedMethods';
import { numSeparator } from 'shared/NumberHelpers';
import { shortenText } from 'shared/SharedHelpers';
import Styles, {
  COLOR_COMPARE_HITS,
  COLOR_WIDGET_TEXT,
  COLOR_WIDGET_TEXT_SELECTED,
} from '../Styles';
import TableRow from './TableView/TableRow';

export default class TableRowGoalsCompare extends TableRow {
  constructor(headerData, rowSize, columnSpacing = 0) {
    super(headerData, rowSize, columnSpacing);
  }

  initRow() {
    super.initRow();

    this.delegate = null;
    this.compareRange = { from: 0, to: 0 };

    this._id = null;
    this.index = 0;
    this.isSelected = false;

    this.nameLabel = new PIXI.BitmapText('', Styles.WIDGET_LABEL);
    this.nameLabel.tint = COLOR_WIDGET_TEXT;
    this.nameLabel.anchor.y = 0.5;
    this.addChild(this.nameLabel);

    this.hitsLabel = new PIXI.BitmapText('', Styles.WIDGET_LABEL);
    this.hitsLabel.tint = COLOR_WIDGET_TEXT;
    this.hitsLabel.anchor.y = 0.5;
    this.addChild(this.hitsLabel);

    this.hitsCompareLabel = new PIXI.BitmapText('', Styles.WIDGET_LABEL);
    this.hitsCompareLabel.tint = COLOR_COMPARE_HITS;
    this.hitsCompareLabel.anchor.y = 0.5;
    this.addChild(this.hitsCompareLabel);

    this.hitsCompareCircle = PIXI.Sprite.from(PIXI.utils.TextureCache['compare_circle']);
    this.hitsCompareCircle.tint = 0x00ff00;
    this.hitsCompareCircle.anchor.y = 0.5;
    this.addChild(this.hitsCompareCircle);
    this.hitsCompareIndicator = PIXI.Sprite.from(PIXI.utils.TextureCache['compare-up']);
    this.hitsCompareIndicator.x = this.hitsCompareCircle.width / 2;
    this.hitsCompareIndicator.anchor.set(0.5, 0.5);
    this.hitsCompareCircle.addChild(this.hitsCompareIndicator);

    this.goalValueLabel = new PIXI.BitmapText('', Styles.WIDGET_LABEL);
    this.goalValueLabel.tint = COLOR_WIDGET_TEXT;
    this.goalValueLabel.anchor.y = 0.5;
    this.addChild(this.goalValueLabel);

    this.goalCompareValueLabel = new PIXI.BitmapText('', Styles.WIDGET_LABEL);
    this.goalCompareValueLabel.tint = COLOR_COMPARE_HITS;
    this.goalCompareValueLabel.anchor.y = 0.5;
    this.addChild(this.goalCompareValueLabel);

    this.locateIcon = new PIXI.Sprite(PIXI.utils.TextureCache['locate']);
    this.locateIcon.anchor.y = 0.5;
    this.locateIcon.buttonMode = true;
    this.locateIcon.interactive = true;
    this.locateIcon.pointerdown = (e) => {
      e.stopPropagation();
    };
    this.locateIcon.pointerup = (e) => {
      if (this.delegate && this.delegate.delegate) {
        this.delegate.delegate.onLocateClicked(e, this);
      }
    };
    this.addChild(this.locateIcon);
  }

  bindData(data) {
    this.data = data;

    if (!data) {
      this.visible = true;
      this.nameLabel.text = '';
      this.hitsLabel.text = '';
      this.goalValueLabel.text = '';
      this.hitsCompareLabel.text = '';
      this.goalCompareValueLabel.text = '';
      this.hitsCompareIndicator.visible = false;
      return;
    }

    this.visible = true;
    this.nameLabel.text = shortenText(data.name);
    this.hitsLabel.text = numSeparator(data.hits);
    this.goalValueLabel.text = `$${numSeparator(roundTo(data.goal, 2))}`;

    const percent = AnalyticsHelper.parsePercentChange(data.compare_hits, data.hits);

    this.hitsCompareLabel.text = numSeparator(data.compare_hits);
    this.goalCompareValueLabel.text = `$${numSeparator(roundTo(data.compare_goal, 2))}`;

    this.hitsCompareCircle.tint = AnalyticsHelper.getColor(percent, this.compareRange);

    AnalyticsHelper.setIndicator(this.hitsCompareIndicator, percent, this.compareRange);

    this.updatePositions();
  }

  updatePositions() {
    const rh = this.rowSize.height;
    const verticalSpacing = 7;

    let cellX = this.columnSpacing;
    this.alignElementsInCell(
      [this.nameLabel],
      cellX,
      this.headerData[0].calculatedWidth,
      rh,
      0,
      this.headerData[0].align,
    );

    cellX += this.headerData[0].calculatedWidth + this.columnSpacing;

    this.hitsLabel.x = cellX;
    this.hitsLabel.y = rh / 2 - verticalSpacing;
    this.hitsCompareLabel.x = cellX;
    this.hitsCompareLabel.y = rh / 2 + verticalSpacing;

    cellX += this.headerData[1].calculatedWidth + this.columnSpacing;

    this.goalValueLabel.x = cellX;
    this.goalValueLabel.y = rh / 2 - verticalSpacing;
    this.goalCompareValueLabel.x = cellX;
    this.goalCompareValueLabel.y = rh / 2 + verticalSpacing;

    // this.hitsCompareCircle.x = cellX;
    // this.hitsCompareCircle.y = rh / 2;

    cellX += this.headerData[2].calculatedWidth + this.columnSpacing;
    this.alignElementsInCell(
      [this.hitsCompareCircle],
      cellX,
      this.headerData[2].calculatedWidth,
      rh,
      0,
      this.headerData[2].align,
    );

    cellX += this.headerData[3].calculatedWidth + this.columnSpacing;
    this.alignElementsInCell(
      [this.locateIcon],
      cellX,
      this.headerData[3].calculatedWidth,
      rh,
      0,
      this.headerData[3].align,
    );
  }

  onSelectChanged(isSelected) {
    super.onSelectChanged(isSelected);

    this.hitsLabel.tint = isSelected ? COLOR_WIDGET_TEXT_SELECTED : COLOR_WIDGET_TEXT;
    this.goalValueLabel.tint = isSelected ? COLOR_WIDGET_TEXT_SELECTED : COLOR_WIDGET_TEXT;

    window.app.needsRendering();
  }
}
