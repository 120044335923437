import { isArray } from 'lodash';
import { DEFAULT_OPERATOR } from '../LogicalOperators';

export const transformToFilterData = (
  attributes,
  filterData,
  isContained,
  defaultOperator = DEFAULT_OPERATOR,
) => {
  const newFilters = [];

  // Find the attriubutes that are not in the list
  // We will not delete the custom parameters
  for (let i = 0; i < filterData.length; i++) {
    const filter = filterData[i];
    if (!isContained(filter.key)) {
      newFilters.push(filter);
    }
  }

  // Add new keys
  const attributeKeys = Object.keys(attributes);
  for (let i = 0; i < attributeKeys.length; i++) {
    const key = attributeKeys[i];
    const find = filterData.find((item) => item.key === key);
    if (!find) {
      const value = keysToValue(attributes[key]);
      newFilters.push({
        key,
        value,
        operator: defaultOperator,
      });
    }
  }

  // Update existing keys
  for (let i = 0; i < filterData.length; i++) {
    const filter = filterData[i];
    const key = filter.key;

    if (!attributes[key]) {
      continue;
    }

    const value = keysToValue(attributes[key]);
    const operator = filter.operator;

    const newFilter = {
      key,
      value,
      operator,
    };

    newFilters.push(newFilter);
  }

  return newFilters;
};

export const keysToValue = (attributes) => {
  const keys = Object.keys(attributes);
  // Multiple values will be treated as an array
  // a single value is always a string
  return keys.length > 1 ? keys : keys[0];
};

export const transformSelectedData = (selectedData) => {
  const selectedDataTransformed = {};

  selectedData.forEach((object) => {
    const key = object.value;
    const selectedKeys = {};

    if (isArray(key)) {
      key.forEach((value) => {
        selectedKeys[value] = true;
      });
    } else if (object.value === '*') {
      selectedKeys['*'] = true;
    } else if (key !== '' && key !== undefined) {
      key.split(',').forEach((value) => {
        selectedKeys[value] = true;
      });
    }

    selectedDataTransformed[object.key] = selectedKeys;
  });

  return selectedDataTransformed;
};

// Look into the all parameters and find the selected ones
export const extractSelectedItems = (selectedData, allData) => {
  const results = [];
  for (let i = 0; i < selectedData.length; i++) {
    const sd = selectedData[i];
    for (let j = 0; j < allData.length; j++) {
      const ad = allData[j];
      if (sd.key === ad.key) {
        results.push(sd);
      }
    }
  }
  // Then transform the data to the format that the list component expects
  return transformSelectedData(results);
};
