import { DEFAULT_OPERATOR } from 'react-project/Toolbar/step-toolbar/LogicalOperators';
import { EElementTypes } from 'shared/CSharedCategories';

const GENERIC_TRAFFIC_ICON = 'StepsModal/Sources/trafficicon.png';

export const buildTrafficStepWithAttributes = (attributeKey, attributeValue, hits) => {
  const label = `${attributeKey}=${attributeValue || '*'}`;

  const filterData = [{ key: attributeKey, value: attributeValue, operator: DEFAULT_OPERATOR }];

  return {
    src: GENERIC_TRAFFIC_ICON,
    title: label,
    type: EElementTypes.SOURCE,
    filterData,
    hits: hits,
  };
};

export const buildGenericTrafficStep = (label, integrationType, hits) => {
  return {
    src: GENERIC_TRAFFIC_ICON,
    title: label,
    type: EElementTypes.SOURCE,
    filterData: [],
    hits: hits,
    integrationType,
  };
};
