import { tutorialMaxDate } from 'react-project/LeftSideBar/Filters/filter-components/DateFilterBlock';
import { ActionNames } from 'react-project/redux/actionNamesConstant';
import { setCompareMode } from 'react-project/redux/analytics/actions';
import { setCurrentFilter } from 'react-project/redux/filters/actions';
import {
  setCompareFocusedSteps,
  setDefaultFocusedSteps,
} from 'react-project/redux/focused-step/actions';
import { getDateRange, getTutorialDateRange } from 'react-project/Util/Dates';
import { createAction } from 'redux-actions';
import { PR_EVENT_COMPARE_RANGE_CHANGED } from 'shared/CSharedEvents';
import { cloneData, commonSendEventFunction } from 'shared/CSharedMethods';

export const updateDataRange = createAction(ActionNames.updateDataRange);
export const updateCompareDataRange = createAction(ActionNames.updateCompareDataRange);
export const updateFilterData = createAction(ActionNames.updateFilterData);
export const updateCompareFilterData = createAction(ActionNames.updateCompareFilterData);
export const setCompareFilterStatus = createAction(ActionNames.setCompareFilterStatus);
export const setPanToolActive = createAction(ActionNames.setPanToolActive);
export const setNewFunnelConfiguration = createAction(ActionNames.setNewFunnelConfiguration);
export const setPreviousFunnelData = createAction(ActionNames.setPreviousFunnelData);
export const updateFocusedSteps = createAction(ActionNames.updateFocusedSteps);
export const updateFocusedStepsCompare = createAction(ActionNames.updateFocusedStepsCompare);
export const setFunnelCompareRangeFrom = createAction(ActionNames.setFunnelCompareRangeFrom);
export const setFunnelCompareRangeTo = createAction(ActionNames.setFunnelCompareRangeTo);
export const setActiveDataLayer = createAction(ActionNames.setActiveDataLayer);
export const setDataMode = createAction(ActionNames.setDataMode);

export const setFunnelDataFilterAndFocus = ({ data, canvasPermissions }) => {
  return (dispatch) => {
    if (Object.keys(data.funnelConfiguration).length !== 0) {
      transformBackwardCompatibleSavedFilters(data);

      const dateRange = getDateRange(data.funnelConfiguration.dateRange);
      const compareDateRange = getDateRange(data.funnelConfiguration.compareDateRange);
      const selectedCompareFilter = data.funnelConfiguration.selectedCompareFilter;
      const funnelConfig = {
        ...data.funnelConfiguration,
        dateRange: dateRange,
        compareDateRange: compareDateRange,
      };

      if (canvasPermissions.isTutorial) {
        funnelConfig.dateRange = getTutorialDateRange();
        funnelConfig.compareDateRange = getTutorialDateRange();
      }

      dispatch(setNewFunnelConfiguration(funnelConfig));
      dispatch(setCurrentFilter(funnelConfig));

      if (data.funnelConfiguration.filter) {
        if (data.funnelConfiguration.focusedSteps && data.funnelConfiguration.focusedSteps.length) {
          dispatch(setDefaultFocusedSteps(data.funnelConfiguration.focusedSteps));
        }

        if (
          data.funnelConfiguration.focusedStepsCompare &&
          data.funnelConfiguration.focusedStepsCompare.length
        ) {
          dispatch(setCompareFocusedSteps(data.funnelConfiguration.focusedStepsCompare));
        }
      }

      if (selectedCompareFilter) {
        dispatch(setCompareMode(true));
      }
    }
  };
};

const transformBackwardCompatibleSavedFilters = (data) => {
  const stepFocusingId = data.funnelConfiguration.stepFocusingId;
  const stepCompareFocusingId = data.funnelConfiguration.stepCompareFocusingId;

  if (data.funnelConfiguration) {
    if (stepFocusingId) {
      const stepData = data.objects.find((o) => o.ID === stepFocusingId);
      if (stepData) {
        data.funnelConfiguration.focusedSteps = [stepData];
        delete data.funnelConfiguration.stepFocusingId;
        delete data.funnelConfiguration.stepFocusingLabel;
      }
    }

    if (stepCompareFocusingId) {
      const stepData = data.objects.find((o) => o.ID === stepCompareFocusingId);
      if (stepData) {
        data.funnelConfiguration.focusedStepsCompare = [stepData];
        delete data.funnelConfiguration.stepCompareFocusingId;
        delete data.funnelConfiguration.stepCompareFocusingLabel;
      }
    }
  }
};

export const setCompareFilter = ({ status }) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(setCompareFilterStatus({ status }));

      resolve();
    });
  };
};

export function setFocusedSteps(newValue, compare = false) {
  if (compare) {
    return async (dispatch) => {
      dispatch(updateFocusedStepsCompare(newValue));
    };
  }
  return async (dispatch) => {
    dispatch(updateFocusedSteps(newValue));
  };
}

export function setDataRange(newValue, compare = false) {
  if (compare) {
    return async (dispatch) => {
      dispatch(updateCompareDataRange(newValue));
    };
  } else {
    return async (dispatch) => {
      dispatch(updateDataRange(newValue));
    };
  }
}

export function setFilterData(newValue, compare = false) {
  return async (dispatch) => {
    return new Promise((resolve) => {
      dispatch(updateFilterData(newValue, compare));
      resolve();
    });
  };
}

export function setCompareFilterData(newValue, compare = false) {
  return async (dispatch) => {
    return new Promise((resolve) => {
      dispatch(updateCompareFilterData(newValue, compare));
      resolve();
    });
  };
}

export function setCompareRangeFrom(payload) {
  return async (dispatch, getState) => {
    dispatch(setFunnelCompareRangeFrom(payload));
    commonSendEventFunction(
      PR_EVENT_COMPARE_RANGE_CHANGED,
      getState().funnelConfiguration.compareFilter.range,
    );
  };
}

export function setCompareRangeTo(payload) {
  return async (dispatch, getState) => {
    dispatch(setFunnelCompareRangeTo(payload));
    commonSendEventFunction(
      PR_EVENT_COMPARE_RANGE_CHANGED,
      getState().funnelConfiguration.compareFilter.range,
    );
  };
}
