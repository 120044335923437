import { createAction } from 'redux-actions';
import RequestService from 'react-project/Helpers/RequestService';
import { ActionNames } from 'react-project/redux/actionNamesConstant';

export const setCustomIconsAction = createAction(ActionNames.setCustomIcons);

const requestService = new RequestService();

export const loadCustomIconsAsync = () => {
  return async (dispatch) => {
    const response = await requestService.getIconsList();
    const icons = [];
    if (response && response.data && response.data.data) {
      for (let i = 0; i < response.data.data.length; i++) {
        const o = response.data.data[i];
        icons.push({ id: o.id, ...o.attributes });
      }
    }

    dispatch(setCustomIconsAction(icons));
  };
};
