export const CANVAS_ACTION_NAMES = {
  COPY: 'COPY',
  DUPLICATE: 'DUPLICATE',
  DELETE: 'DELETE',
  BRING_FORWARD: 'BRING_FORWARD',
  BRING_TO_FRONT: 'BRING_TO_FRONT',
  SEND_BACKWARD: 'SEND_BACKWARD',
  SEND_TO_BACK: 'SEND_TO_BACK',
  ZOOM_IN: 'ZOOM_IN',
  ZOOM_OUT: 'ZOOM_OUT',
  RESET_ZOOM: 'RESET_ZOOM',
  FIT_TO_SCREEN: 'FIT_TO_SCREEN',
  ADD_STEP: 'ADD_STEP',
  PASTE: 'PASTE',
  UNDO: 'UNDO',
  REDO: 'REDO',
  SELECT: 'SELECT',
  ADD_TEXT: 'ADD_TEXT',
  ADD_PHOTO: 'ADD_PHOTO',
  PAN: 'PAN',
  EXPORT_PNG: 'EXPORT_PNG',
  EXPORT_CANVAS: 'EXPORT_CANVAS',
  DRAW_RECTANGLE: 'DRAW_RECTANGLE',
  DRAW_CIRCLE: 'DRAW_CIRCLE',
  DRAW_TRIANGLE: 'DRAW_TRIANGLE',
  CHECKLIST_ADD_SIBLING: 'CHECKLIST_ADD_SIBLING',
  CHECKLIST_ADD_CHILD: 'CHECKLIST_ADD_CHILD',
  CHECKLIST_INDENT: 'CHECKLIST_INDENT',
  CHECKLIST_OUTDENT: 'CHECKLIST_OUTDENT',
  CHECKLIST_MOVE_UP: 'CHECKLIST_MOVE_UP',
  CHECKLIST_MOVE_DOWN: 'CHECKLIST_MOVE_DOWN',
  CHECKLIST_DELETE: 'CHECKLIST_DELETE',
  CHECKLIST_COPY: 'CHECKLIST_COPY',
};
