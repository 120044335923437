import { LayerType } from 'react-project/LayersMenu/LayerType';

export default class Migration3 {
  constructor() {
    this.version = 3;
  }

  migrate(data) {
    data.funnelConfiguration.visibleDataLayers = {
      [LayerType.NUMBERS]: !!data.funnelConfiguration.isNumbersActive,
      [LayerType.FLOW]: false,
      [LayerType.LEAD_TIME]: false,
      [LayerType.NOTES]: !!data.funnelConfiguration.isPlanningActive,
      [LayerType.CHECKLIST]: !!data.funnelConfiguration.isPlanningActive,
    };

    return data;
  }
}
