import React, { useEffect, useRef, useState } from 'react';
import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';
import { iconPhoto, iconUploadGeneric } from 'react-project/assets/Icons';
import { StyleButton } from '../StyleButton/StyleButton';
import styles from './ImageAdd.module.scss';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import { useSelector } from 'react-redux';
import MainStorage from 'pixi-project/core/MainStorage';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { RP_EVENT_INFO_MESSAGE } from 'shared/CSharedEvents';
import { TEXT_TUTORIAL_UPLOAD_NOT_SUPPORTED } from 'react-project/Constants/texts';

export const ImageAdd = ({ editorState, onChange, onFileUpload, modifier }) => {
  const [url, setURL] = useState('');
  const [open, setOpen] = useState(false);
  const inputRef = useRef();
  const canvasPermissions = useSelector(selectCanvasPermissions);

  useEffect(() => {
    if (open) {
      setURL('');
      inputRef.current.focus();
    }
  }, [open]);

  const openPopover = () => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }
    setOpen(true);
  };

  const addImage = () => {
    onChange(modifier(editorState, url));
    setOpen(false);
  };

  const changeUrl = (e) => {
    setURL(e.target.value);
  };

  const onPointerOut = (e) => {
    setOpen(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setURL(inputRef.current.value);
      addImage();
    }
  };

  const onUploadClicked = () => {
    if (MainStorage.getCanvasPermissions().isTutorial) {
      // Click upload image in notes
      commonSendEventFunction(RP_EVENT_INFO_MESSAGE, {
        message: TEXT_TUTORIAL_UPLOAD_NOT_SUPPORTED,
      });
      return;
    }

    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.onchange = () => {
      onFileUpload(fileInput.files);
    };
    fileInput.click();
    setOpen(false);
  };

  const popoverClassName = open ? styles.addImagePopover : styles.addImageClosedPopover;
  return (
    <ClickOutsideCustomComponent ignoreClickOutside={false} onClickOutside={(e) => onPointerOut(e)}>
      <div className={styles.addImage}>
        <StyleButton
          key="add-image-button"
          active={false}
          label={iconPhoto}
          onToggle={openPopover}
        />
        <div className={popoverClassName}>
          <div className={styles.Wrap}>
            <div onClick={onUploadClicked} className={styles.uploadBtn}>
              {iconUploadGeneric} Upload image
            </div>
          </div>
          <div className={styles.Separator}>
            <div className={styles.Line}></div>
            <div className={styles.Text}>or</div>
            <div className={styles.Line}></div>
          </div>
          <div className={styles.Wrap}>
            <input
              ref={inputRef}
              type="text"
              placeholder="Paste the image url …"
              className={styles.addImageInput}
              onChange={changeUrl}
              value={url}
              onKeyDown={handleKeyDown}
            />
            <button className={styles.addImageConfirmButton} type="button" onClick={addImage}>
              Add
            </button>
          </div>
        </div>
      </div>
    </ClickOutsideCustomComponent>
  );
};
