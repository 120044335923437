// This file was taken from Ember.js frontend, only changed some names of parameters
import _ from 'lodash';
import { EElementCategories, EElementTypes } from 'shared/CSharedCategories';

// This is the key constant for this file. Determines the size of the request.
const MAX_COST = process.env.REACT_APP_MAX_CHUNK_WEIGHT || 750;

const CONNECTION_COST = 4;
const PAGE_COST = 6;
const EVENT_COST = 7;
const SOURCE_COST = 6;
const ATTRIBUTE_COST = 2;
const UNKNOWN_COST = 4;
const STEP_NOT_FOUND = 'step not found';
const UNKNOWN_ITEM_ID = 'unknownId';

const CHUNK_DICTIONARY = 'dictionary';
const CHUNK_CONNECTIONS = 'connections';

function newSetOfChunks() {
  return [];
}

function determineAttributeCost(step) {
  const numberOfAttributes = _.get(step, ['attributes', 'length'], 0);
  return numberOfAttributes * ATTRIBUTE_COST;
}

/*
  events, along with links, should account for the source and target within the cost calculation.
  They both require the full step to be present in the dictionary, meaning they contribute to the network
  request size. This logic does not currently exist.
*/

function determineStepCost(step /*, dictionary*/) {
  switch (step.type) {
    case EElementTypes.PAGE:
      return PAGE_COST + determineAttributeCost(step);
    case EElementTypes.EVENT:
      return EVENT_COST + determineAttributeCost(step);
    case EElementTypes.SOURCE:
      return SOURCE_COST + determineAttributeCost(step);
    default:
      return UNKNOWN_COST;
  }
}

function determineCostOfCell_internal(cell /*, dictionary*/) {
  if (cell.category === EElementCategories.STEP) {
    return determineStepCost(cell /*, dictionary*/);
  }

  if (cell.iconA_ID && cell.iconB_ID) {
    return CONNECTION_COST;
  }

  return UNKNOWN_COST;
}

function determineCostOfCells(cellOrCellArray /*, dictionary*/) {
  if (_.isArray(cellOrCellArray)) {
    return _.reduce(
      cellOrCellArray,
      (totalCost, currentCell) => {
        totalCost += determineCostOfCell_internal(currentCell /*, dictionary*/);
        return totalCost;
      },
      0,
    );
  } else if (_.isObject(cellOrCellArray)) {
    return determineCostOfCell_internal(cellOrCellArray /*, dictionary*/);
  } else {
    return 0;
  }
}

function notTooExpensive(currentCost, additionalCost, isFunnelMode = false) {
  // do not chunk in funnel mode
  if (isFunnelMode) {
    return true;
  }

  return currentCost + additionalCost <= MAX_COST;
}

function costNotMaxed(currentCost, isFunnelMode = false) {
  // do not chunk in funnel mode
  if (isFunnelMode) {
    return true;
  }

  return currentCost < MAX_COST;
}

function createEmptyChunk() {
  return {
    [CHUNK_DICTIONARY]: {},
    [CHUNK_CONNECTIONS]: [],
  };
}

function itemHasId(item) {
  return _.has(item, 'ID');
}

function getItemId(item) {
  const itemId = _.get(item, 'ID', UNKNOWN_ITEM_ID);

  return itemId;
}

function chunkConnections(chunk) {
  return _.get(chunk, CHUNK_CONNECTIONS);
}

function chunkDictionary(chunk) {
  return _.get(chunk, CHUNK_DICTIONARY);
}

function chunkIsEmpty(chunk) {
  return _.isEmpty(chunkConnections(chunk)) && _.isEmpty(chunkDictionary(chunk));
}

export default class RequestChunker {
  constructor(dictionary, connections, isFunnelMode = false) {
    this.dictionary = dictionary;
    this.connections = connections;
    this._isFunnelMode = isFunnelMode;
  }

  requestHasMoreConnections() {
    return this.getChunkingConnections().length > 0;
  }

  requestHasMoreNormalSteps() {
    return _.keys(this.getChunkingNormalSteps()).length > 0;
  }

  getDictionary() {
    return this.dictionary;
  }

  getNormalSteps() {
    return _.filter(this.getDictionary(), (step) => {
      return step;
    }).reduce((hash, step) => {
      _.set(hash, step.ID, step);
      return hash;
    }, {});
  }

  getConnections() {
    return this.connections;
  }

  setChunkingDictionaryAndConnections() {
    this._chunkingNormalSteps = _.cloneDeep(this.getNormalSteps());
    this.chunkingConnections = _.cloneDeep(this.getConnections());
  }

  getChunkingNormalSteps() {
    return this._chunkingNormalSteps;
  }

  getChunkingConnections() {
    return this.chunkingConnections;
  }

  peekNextConnection() {
    return this.getChunkingConnections()[0];
  }

  getNextConnection() {
    return this.getChunkingConnections().shift();
  }

  getRemainingNormalStepIds() {
    return _.keys(this.getChunkingNormalSteps());
  }

  peekNextNormalStep(stepIds) {
    return this.getChunkingNormalSteps()[stepIds[0]];
  }

  getNextNormalStep(stepIds) {
    return this.getChunkingNormalSteps()[stepIds.shift()];
  }

  itemInChunkDictionary(chunk, item) {
    return _.has(chunk, [CHUNK_DICTIONARY, item.ID]);
  }

  addToChunk(chunk, items) {
    _.forEach(items, (item) => {
      const keysToGrab = [];

      if (item.iconA_ID && item.iconA_ID) {
        chunk[CHUNK_CONNECTIONS].push(item);
      } else if (item.category === EElementCategories.STEP) {
        const itemId = getItemId(item);
        if (itemHasId(item)) {
          _.set(chunk, [CHUNK_DICTIONARY, itemId], item);
          if (item.iconA_ID) {
            _.set(chunk, [CHUNK_DICTIONARY, item.iconA_ID], this.getDictionary()[item.iconA_ID]);
          }
          if (item.iconB_ID) {
            _.set(chunk, [CHUNK_DICTIONARY, item.iconB_ID], this.getDictionary()[item.iconB_ID]);
          }
        }

        keysToGrab.push('ID', 'category');
      }
    });
  }

  removeNormalStepFromDictionary(stepId) {
    _.unset(this.getChunkingNormalSteps(), stepId);
  }

  getCostOfAddition(nextElement) {
    const { iconA_ID: sourceId, iconB_ID: targetId } = nextElement;
    const source = _.get(this.getChunkingNormalSteps(), sourceId, STEP_NOT_FOUND);
    const target = _.get(this.getChunkingNormalSteps(), targetId, STEP_NOT_FOUND);
    const sourceAndTarget = [source, target];
    const costOfAddition = determineCostOfCells([nextElement, ...sourceAndTarget]);
    return { costOfAddition, sourceAndTarget };
  }

  chunkRequests() {
    const chunks = newSetOfChunks();
    this.setChunkingDictionaryAndConnections();

    while (this.requestHasMoreConnections() || this.requestHasMoreNormalSteps()) {
      let currentCost = 10; // Start at 10 to reflect filters that will need to be sent.
      const currentChunk = createEmptyChunk();

      // Add this.getChunkingConnections() and related steps first:
      while (costNotMaxed(currentCost, this._isFunnelMode) && this.requestHasMoreConnections()) {
        const nextConnection = this.peekNextConnection();
        const { costOfAddition, sourceAndTarget } = this.getCostOfAddition(nextConnection);

        if (notTooExpensive(currentCost, costOfAddition, this._isFunnelMode)) {
          const connectionToAdd = this.getNextConnection(); // remove first connection from connection list.
          currentCost += costOfAddition;
          this.addToChunk(currentChunk, [connectionToAdd, ...sourceAndTarget]);
        } else if (chunkIsEmpty(currentChunk)) {
          return newSetOfChunks();
        } else {
          // too expensive to add connection, move out of this loop.
          break;
        }
      }

      if (!this.requestHasMoreConnections()) {
        // remove the steps we have tackled in connections from the dictionary for the next phase.
        chunks.forEach((chunk) => {
          _.keys(_.get(chunk, CHUNK_DICTIONARY)).forEach((trackerId) => {
            this.removeNormalStepFromDictionary(trackerId);
          });
        });
      }

      // Add standalone steps not part of any this.getChunkingConnections()
      const remainingSteps = this.getRemainingNormalStepIds();
      // TODO: This actually isn't good... should just wait until all connections are done before doing this:
      while (
        !this.requestHasMoreConnections() &&
        costNotMaxed(currentCost, this._isFunnelMode) &&
        remainingSteps.length > 0
      ) {
        const nextStep = this.peekNextNormalStep(remainingSteps);
        const costOfAddition = determineCostOfCells(nextStep);
        if (notTooExpensive(currentCost, costOfAddition, this._isFunnelMode)) {
          const stepToAdd = this.getNextNormalStep(remainingSteps); // remove first step from remaining steps.
          currentCost += costOfAddition;
          this.addToChunk(currentChunk, [stepToAdd]);
          this.removeNormalStepFromDictionary(getItemId(stepToAdd));
        } else if (chunkIsEmpty(currentChunk)) {
          return newSetOfChunks();
        } else {
          // too expensive to add connection, move out of this loop.
          break;
        }
      }
      if (!chunkIsEmpty(currentChunk)) {
        chunks.push(currentChunk);
      }
    }

    // Avoid duplicate connections:
    chunks.forEach((chunk) => {
      chunk[CHUNK_CONNECTIONS] = _.uniqBy(chunk[CHUNK_CONNECTIONS], (item) => item.ID);
    });

    return chunks;
  }
}

export const getDividedRequest = (elements, connections, isFunnelMode = false) => {
  const stepElements = elements.filter((element) => element.category === EElementCategories.STEP);
  const dictionary = getDictionaryFromElements(stepElements);

  const regularConnections = connections.filter((connection) => {
    return _.get(connection, 'iconA_ID') && _.get(connection, 'iconB_ID');
  });

  const requestChunker = new RequestChunker(dictionary, regularConnections, isFunnelMode);
  return requestChunker.chunkRequests();
};

const getDictionaryFromElements = (elements) => {
  const uniqueElementsById = _.uniqBy(elements, (element) => element.ID);

  return _.reduce(
    uniqueElementsById,
    (elements, model) => {
      const id = model.ID;
      if (id) {
        elements[id] = model;
      }
      return elements;
    },
    {},
  );
};
