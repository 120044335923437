import MainStorage from 'pixi-project/core/MainStorage';
import LoopDetector from 'pixi-project/utils/LoopDetector';
import { AnalyticsHelper } from 'shared/AnalyticsHelper';
import SharedElementHelpers from 'shared/SharedElementHelpers';

const DEBUG = false;

export default class ForecastingController {
  constructor(objects, connections) {
    this.objects = objects;
    this.connections = connections;
    this.loopDetector = new LoopDetector();
  }

  mapFromAnalytics() {
    const days = MainStorage.calendarData.days;
    this.objects.forEach((object) => {
      if (object.analyticsManager && object.analyticsManager.data) {
        const data = object.analyticsManager.data;
        object.forecastingData = {
          numberOfPeople: Math.round(data.hits / days),
          period: 0, // daily
        };
      }
    });

    this.connections.forEach((connection) => {
      if (connection.isNoDataLine()) {
        return;
      }

      if (connection.analyticsManager) {
        connection.forecastingData = {
          percent: connection.analyticsManager.percent,
          isAdvanced: false,
        };
      }
    });
  }

  calculateForecasting() {
    const days = MainStorage.calendarData.days;

    this.loopDetector.loadDataFromCanvas(this.objects, this.connections);

    const listOfOjbects = {};
    this.objects.forEach((obj) => {
      listOfOjbects[obj.id] = obj;
      obj.forecastingCalculatedData = null;
    });

    const listOfConnections = {};
    this.connections.forEach((connection) => {
      if (connection.isNoDataLine()) {
        return;
      }
      // TODO there might be more then a single connection between two objects
      // Those cases should be handled

      connection.forecastingCalculatedData = null;
      const from = this.loopDetector.getFrom(connection);
      const to = this.loopDetector.getTo(connection);
      if (!listOfConnections[from]) {
        listOfConnections[from] = {};
      }
      listOfConnections[from][to] = connection;
    });

    const rootNodes = this.loopDetector.findRootNodes();
    const loops = this.loopDetector.findCycles();
    const connections = [];
    for (let i = 0; i < loops.length; i++) {
      const loop = loops[i];
      const lc = loop.connections[loop.connections.length - 1];
      const c = this.connections.find((connection) => {
        return connection.id === lc;
      });
      connections.push(c);
    }

    DEBUG && console.log('=============================');
    DEBUG && console.log('loop connections', connections);

    const prevValues = {};

    for (let i = 0; i < rootNodes.length; i++) {
      const rootNodeId = rootNodes[i];
      // DEBUG && console.log('ROOT', rootNodeId);

      this.loopDetector.traverseFromRootNodeAvoidLoops(
        rootNodeId,
        connections,
        (nodeId, parentId) => {
          // DEBUG && console.log('nodeId', nodeId);
          // DEBUG && console.log('previousNode', parentId);

          if (nodeId === rootNodeId) {
            // calculate node
            const root = listOfOjbects[nodeId];
            if (root.forecastingData) {
              let numberOfPeople = 0;

              if (!root.forecastingData.calculationMethod && SharedElementHelpers.IsSource(root)) {
                // we need to calculate the number of people
                if (
                  root.forecastingData.cost !== undefined &&
                  root.forecastingData.costPerPerson !== undefined
                ) {
                  numberOfPeople = Math.round(
                    root.forecastingData.cost / root.forecastingData.costPerPerson,
                  );
                }
              } else {
                numberOfPeople = Math.round(root.forecastingData.numberOfPeople);
              }

              const peoplePerDay = AnalyticsHelper.calculatePerDay(
                numberOfPeople,
                root.forecastingData.period,
                days,
              );

              const newValue = peoplePerDay * days;

              root.forecastingCalculatedData = {
                people: Math.round(newValue),
              };

              // either 0 or undefined
              if (
                !root.forecastingData.calculationMethod &&
                root.forecastingData.cost !== undefined
              ) {
                // calculate people
                root.forecastingCalculatedData.people = newValue;
                const cost = AnalyticsHelper.calculateCost(
                  root.forecastingData.cost,
                  root.forecastingData.period,
                  days,
                );
                root.forecastingCalculatedData.cost = Math.round(cost);
              } else if (
                root.forecastingData.calculationMethod === 1 &&
                root.forecastingData.costPerPerson !== undefined
              ) {
                const cost = AnalyticsHelper.calculateCost(
                  root.forecastingData.costPerPerson * numberOfPeople,
                  root.forecastingData.period,
                  days,
                );
                root.forecastingCalculatedData.cost = Math.round(cost);
              } else if (
                root.forecastingData.calculationMethod === 2 &&
                root.forecastingData.cost !== undefined
              ) {
                const cost = AnalyticsHelper.calculateCost(
                  root.forecastingData.cost,
                  root.forecastingData.period,
                  days,
                );
                root.forecastingCalculatedData.cost = Math.round(cost);
              }

              if (root.forecastingData.avgRevenue !== undefined) {
                const revenue = root.forecastingData.avgRevenue * newValue;
                root.forecastingCalculatedData.revenue = Math.round(revenue);
              }

              if (isNaN(root.forecastingCalculatedData.people)) {
                prevValues[nodeId] = 0;
                root.forecastingCalculatedData = { people: '/' };
                return false;
              }

              prevValues[nodeId] = Math.round(newValue);
              DEBUG && console.log('root value', newValue);
              DEBUG && console.log('----------------------');
            } else {
              prevValues[nodeId] = 0;
              return false;
            }
          } else {
            // non root nodes

            const incommingConnection = listOfConnections[parentId][nodeId];

            if (!incommingConnection) {
              // safeguard in case the algorythm fails in some way
              return false;
            }

            const currentNode = listOfOjbects[nodeId];

            if (incommingConnection.forecastingData) {
              const percent = this.getConnectionPercent(incommingConnection, rootNodeId);

              if (!incommingConnection.forecastingCalculatedData) {
                incommingConnection.forecastingCalculatedData = {
                  people: 0,
                  percent,
                };
              }
              const pv = prevValues[parentId] || 0;
              const newValue = pv * (percent / 100);

              incommingConnection.forecastingCalculatedData.people += Math.round(newValue);

              // calculate percent based on the number of incomming people
              const incommingNodePeople = listOfOjbects[parentId].forecastingCalculatedData.people;
              if (incommingNodePeople !== 0 && incommingConnection.forecastingData.isAdvanced) {
                incommingConnection.forecastingCalculatedData.percent =
                  (incommingConnection.forecastingCalculatedData.people / incommingNodePeople) *
                  100;
              }

              if (!currentNode.forecastingCalculatedData) {
                currentNode.forecastingCalculatedData = {
                  people: 0,
                };
              }
              currentNode.forecastingCalculatedData.people += Math.round(newValue);
              DEBUG &&
                console.log(
                  'NODE: percent:',
                  percent,
                  '-> +',
                  newValue,
                  '=>',
                  currentNode.forecastingCalculatedData.people,
                );
              DEBUG && console.log('----------------------');

              if (
                currentNode.forecastingData &&
                currentNode.forecastingData.avgRevenue !== undefined &&
                currentNode.forecastingCalculatedData.people
              ) {
                const revenue =
                  currentNode.forecastingData.avgRevenue *
                  currentNode.forecastingCalculatedData.people;
                currentNode.forecastingCalculatedData.revenue = Math.round(revenue);
              }

              prevValues[nodeId] = Math.round(newValue);
            } else {
              prevValues[nodeId] = 0;
              return false;
            }
          }

          return true;
        },
      );
    }
  }

  getConnectionPercent(connection, id) {
    if (connection.forecastingData) {
      const forecastingData = connection.forecastingData;
      if (forecastingData.isAdvanced) {
        if (forecastingData.streams && forecastingData.streams[id] !== undefined) {
          return forecastingData.streams[id];
        }
      } else {
        return forecastingData.percent;
      }
    }

    return 100;
  }

  clearForecastingCalculations() {
    this.objects.forEach((object) => {
      object.forecastingCalculatedData = null;
    });

    this.connections.forEach((connection) => {
      connection.forecastingCalculatedData = null;
    });
  }
}
