import Facade from 'pixi-project/Facade';
import Utils from 'pixi-project/utils/Utils';
import {
  PR_EVENT_SELECTION_REMOVED,
  PR_EVENT_ELEMENT_POINTER_DOWN,
  PR_EVENT_ELEMENT_POINTER_UP,
  PR_EVENT_ELEMENT_SELECTED,
  PR_EVENT_OBJECT_SELECTED,
  RP_EVENT_ELEMENT_RIGHT_CLICK,
  PR_EVENT_SELECTION_ADDED,
} from 'shared/CSharedEvents';
import { commonSendEventFunction, getMousePosition, isRightButton } from 'shared/CSharedMethods';
import {
  COLOR_ELEMENT_HIGHLIGHT_FRAME,
  COLOR_SELECTION,
  SELECTION_BOUNDARY_GAP,
  SELECTION_PADDING,
  SELECTION_ROUND_CORNER,
} from '../Styles';
import {
  PR_EVENT_BRING_FORWARD,
  PR_EVENT_BRING_TO_FRONT,
  PR_EVENT_SEND_BACKWARD,
  PR_EVENT_SEND_TO_BACK,
} from 'shared/CSharedEvents';
import MultipleSelection, { MULTIPLE_SELECTION_BOUNDS_WIDTH } from './MultipleSelection';
import SelectionTool, { MAX_ALLOWED_SCALE, MIN_ALLOWED_SCALE } from './SelectionTool';
import CommandMove from 'pixi-project/base/command-system/commands/CommandMove';
import AppSignals from 'pixi-project/signals/AppSignals';
import SharedElementHelpers from 'shared/SharedElementHelpers';
import FocusSelectionLayer from './FocusSelectionLayer';
import CommandBatchMove from 'pixi-project/base/command-system/commands/CommandBatchMove';
import { EElementCategories, EElementTypes } from 'shared/CSharedCategories';
import intersection from 'lodash/intersection';
import AttachmentSelectionLayer from './AttachmentSelectionLayer';
import CommandScaleMove from 'pixi-project/base/command-system/commands/CommandScaleMove';
import MainStorage from 'pixi-project/core/MainStorage';
import { SELECTION_UPDATE_SOURCE_ANALYTICS } from 'shared/CSharedConstants';

export const SELECTION_TYPE_NONE = 'NONE';
export const SELECTION_TYPE_SINGLE = 'SINGLE';
export const SELECTION_TYPE_MULTI = 'MULTI';

const ORDER_ASC = 'ASC';
const ORDER_DESC = 'DESC';

const NOT_ALLOWED_TO_CREATE_MULTI_CONNECTIONS = [
  EElementCategories.WIDGET,
  EElementCategories.TEXT,
  EElementCategories.SHAPE,
  EElementCategories.PHOTO,
];

// DELEGATES
// - onSelectionDrop(e, this.selectedObjects, inReport)
// - onSelectionHeadsDown(e)
// - onShapeResizeHandleDown(e, shape)
// - onShapeResizeHandleMove(e, shape)
// - onShapeResizeHandleUp(e, shape)
// - onTextResizeHandleMove(e, textLabel)

export default class SelectionManager {
  constructor(delegate, sceneManager) {
    this.selectedObjects = [];
    this.delegate = delegate;
    this.sceneManager = sceneManager;
    this.single = new SelectionTool(this.onHeadsDown.bind(this), this);
    this.multi = new MultipleSelection(this.onHeadsDown.bind(this), this);
    this.focusSelection = new FocusSelectionLayer(this);
    this.attachmentSelection = new AttachmentSelectionLayer(this);

    this.isRightMouse = false;
    this.updateSource = null; // It can be from an event , or a callback or different place

    this.selectedElementLocalPos = null;
    this.startingPositions = [];
    this.selectionHasMoved = false;
    this.wasMultiSelect = false;

    // For multiscale
    this.initialMultiScaleData = [];
    this.minMaxRectScales = {
      minScale: 1,
      maxScale: 1,
    };
    this.multiScaleObjects = [];

    document.addEventListener(PR_EVENT_BRING_FORWARD, this.onBringForward.bind(this), false);
    document.addEventListener(PR_EVENT_BRING_TO_FRONT, this.onBringToFront.bind(this), false);
    document.addEventListener(PR_EVENT_SEND_BACKWARD, this.onSendBackward.bind(this), false);
    document.addEventListener(PR_EVENT_SEND_TO_BACK, this.onSendToBack.bind(this), false);
  }

  get selectedElement() {
    if (this.selectedObjects.length === 1) {
      return this.selectedObjects[0];
    } else {
      return null;
    }
  }

  onViewportDown(e) {
    this.isRightMouse = isRightButton(e);
    //if element was not created after connection in empty space
    if (!this.sceneManager.iconA) {
      this.clearSelection();
      this.multi.isSelecting = true;
      this.multi.onViewportDown(e);

      this.updateSelection();
    }
  }

  onViewportMove(e) {
    if (this.multi.isSelecting) {
      this.multi.drawFrame = false;
      this.multi.drawSelection = true;
      this.multi.onViewportMove(e);

      this.resolveMultiSelection();
      this.updateSelection(false, false, false, false);
    }
  }

  onViewportUp(e) {
    this.isRightMouse = isRightButton(e);
    if (this.multi.isSelecting) {
      this.multi.onViewportUp(e);
      this.resolveMultiSelection();
    }

    this.updateSelection(true, true);

    if (this.selectedElement) {
      this.sendElementToReact(this.selectedElement);
    }
  }

  onViewportOut(e) {
    // If we are selecting a single element with the multiselection tool
    // and the user releases the mouse outside the viewport
    if (this.multi.isSelecting && this.selectedElement) {
      this.sendElementToReact(this.selectedElement);
    }
  }

  stopAndResolveMultiSelection() {
    if (this.multi.isSelecting) {
      this.multi.clearSelectionFrame();
      this.resolveMultiSelection();
    }

    this.updateSelection(true, true);
  }

  onElementPointerDown(e, isMultiSelect) {
    this.selectionHasMoved = false;
    this.wasMultiSelect = isMultiSelect;
    // The event is attached at the content layer of the elements
    // so that is why we are looking at the parent element
    const element = this.getClickedElement(e);
    const data = element.getState();
    data.stepId = data.ID;
    commonSendEventFunction(PR_EVENT_ELEMENT_POINTER_DOWN, data);

    // Handle element selection
    const canSelect = this.applySelectionRules(element, isMultiSelect);

    element.isPointerDown = canSelect;

    // in the case of clicking on a report view while sceneManager.isSceneLocked
    // while we are drawing a shape or createing a text ,
    // the event should not be stopped to propagate so that the element can be created

    if (!(this.sceneManager.isSceneLocked && SharedElementHelpers.IsReport(element))) {
      e.stopPropagation();
    }

    // Prepare objects for dragging
    if (this.selectedObjects.length === 1) {
      if (!SharedElementHelpers.IsConnection(this.selectedElement)) {
        this.selectedElementLocalPos = e.data.getLocalPosition(this.selectedElement);
        this.selectedElementLocalPos.x *= this.selectedElement.scale.x;
        this.selectedElementLocalPos.y *= this.selectedElement.scale.y;
        this.selectedElement.data = e.data;
        this.selectedElement.startPos = {
          x: e.data.global.x,
          y: e.data.global.y,
        };

        Facade.viewport.plugins.pause('drag');
      }
    } else if (this.selectedObjects.length > 1) {
      const reportView = this.findReportViewInSelection();
      if (reportView) {
        reportView.previousDragPoint.copyFrom(reportView.position);
        reportView.prepBreakPointsForMove(e.data, this.sceneManager, true);
      }

      this.selectedObjects.forEach((element) => {
        if (!SharedElementHelpers.IsConnection(element)) {
          let position = e.data.getLocalPosition(element);
          element.freezeStartMoveData(position, e.data);
        }
      });
      Facade.viewport.plugins.pause('drag');

      const joints = this.sceneManager.findInclusiveJoints(this.selectedObjects);
      for (let i = 0; i < joints.length; i++) {
        const joint = joints[i];
        joint.saveReferentPoint();
      }
    }

    this.startingPositions = [];
    this.selectedObjects.forEach((element) => {
      if (!SharedElementHelpers.IsConnection(element)) {
        this.startingPositions.push({ element, position: element.position.clone() });
      }
    });
  }

  onElementPointerMove(e) {
    this.selectionHasMoved = true;

    if (MainStorage.getCanvasPermissions().isReadonlyAccess) {
      return;
    }

    // The event is attached at the content layer of the elements
    // so that is why we are looking at the parent element
    const element = this.getClickedElement(e);
    if (element.isPointerDown) {
      this.moveElements();
    }
  }

  onElementPointerUp(e) {
    const element = this.getClickedElement(e);

    // check if there is editing text
    if (this.delegate && this.delegate.checkEditingText) {
      if (this.delegate.checkEditingText(e)) {
        element.isPointerDown = false;
        return;
      }
    }

    if (this.selectedObjects.length && !this.wasMultiSelect) {
      if (this.selectionHasMoved) {
        if (this.delegate && this.delegate.onSelectionDrop) {
          let inReport = this.markReportObjects();
          this.delegate.onSelectionDrop(e, this.selectedObjects, inReport);
        }

        const joints = this.sceneManager.findInclusiveJoints(this.selectedObjects);

        for (let i = 0; i < joints.length; i++) {
          const joint = joints[i];
          joint.saveReferentPoint();
        }
      } else {
        // if there is no dragging , then make the element a single selection
        this.clearSelection();
        this.addToSelection(element);
        this.show();
      }

      element.isPointerDown = false;
    }
    element.isPointerDown = false;
  }

  moveElements() {
    if (this.selectedObjects.length === 1) {
      // DRAG Single Element
      if (!SharedElementHelpers.IsConnection(this.selectedElement)) {
        const newPosition = this.selectedElement.data.getLocalPosition(Facade.viewport);
        this.selectedElement.x = newPosition.x - this.selectedElementLocalPos.x;
        this.selectedElement.y = newPosition.y - this.selectedElementLocalPos.y;
        this.selectedElement.move();
      }
    } else if (this.selectedObjects.length > 1) {
      // DRAG Multiselection
      let newPosition, frozenData;
      this.selectedObjects.forEach((element) => {
        if (!SharedElementHelpers.IsConnection(element)) {
          frozenData = element.getFrozenData();
          newPosition = frozenData.eventData.getLocalPosition(Facade.viewport);
          element.x = newPosition.x - frozenData.position.x;
          element.y = newPosition.y - frozenData.position.y;
          // todo [optimize] since currently each connection is re-drawed twice
          element.move();
        }
      });
      const joints = this.sceneManager.findInclusiveJoints(this.selectedObjects);
      for (let i = 0; i < joints.length; i++) {
        const joint = joints[i];
        joint.isShifting = true;
      }
    }

    this.markReportObjects();
    this.updateSelectionAfterElementMovement();
  }

  markReportObjects() {
    this.selectedObjects.forEach((object) => (object.isInsideReport = false));
    const inReport = this.getSelectedObjectsInsideReport();
    inReport.objects.forEach((object) => {
      object.isInsideReport = true;
    });
    return inReport;
  }

  getSelectedObjectsInsideReport() {
    const reportView = this.sceneManager.getReport();

    const inReport = {
      report: null,
      objects: [],
    };

    if (reportView) {
      inReport.report = reportView;

      for (let i = 0; i < reportView.slides.length; i++) {
        const slide = reportView.slides[i];
        const slideRectangle = slide.content.getBounds();

        for (let j = 0; j < this.selectedObjects.length; j++) {
          const element = this.selectedObjects[j];
          const elementBounds = element.content.getBounds();

          if (SharedElementHelpers.IsConnection(element)) {
            // Connections need to be ignored and not included inside report
            // As they will automaticaly be managed by the elements they are connected to
            continue;
          } else if (
            slideRectangle.containsRectangle(elementBounds) &&
            !SharedElementHelpers.IsReport(element)
          ) {
            inReport.objects.push(element);
            element.isInsideReport = true;
          }
        }
      }
    }

    return inReport;
  }

  updateSelectionAfterElementMovement() {
    this.updateSelection(false, true, false, false);
    this.updateFocused();
  }

  stopElementsMovement() {
    for (let i = 0; i < this.selectedObjects.length; i++) {
      const element = this.selectedObjects[i];
      if (element.isPointerDown) {
        const data = element.getState();
        data.stepId = data.ID;
        commonSendEventFunction(PR_EVENT_ELEMENT_POINTER_UP, data);

        // The mouse was released on the same element that was clicked
        element.isPointerDown = false;
        this.updateSelection(true);
        break; // since we drag only by trouching a single element
      }
    }
  }

  createMovementCommands() {
    const batch = new CommandBatchMove(this);
    for (let i = 0; i < this.startingPositions.length; i++) {
      const data = this.startingPositions[i];
      if (data.element.x === data.position.x && data.element.y === data.position.y) {
        break;
      } else if (!SharedElementHelpers.IsConnection(data.element)) {
        let moveCommand = new CommandMove(data.element, data.position);
        batch.add(moveCommand);
      }
    }
    this.startingPositions = [];

    return batch;
  }

  onElementPointerUpOutside(e) {
    const element = this.getClickedElement(e);
    element.isPointerDown = false;
  }

  onElementPointerOver(e, isPWPActive, pointerJoint) {
    const element = this.getClickedElement(e);

    if (element.isLocked) {
      e.stopPropagation();
      return;
    }

    element.isHovered = true;

    if (isPWPActive) {
      if (SharedElementHelpers.IsStep(element)) {
        this.focusSelection.hoverIn(e, element);
      }
    }

    if (
      pointerJoint &&
      !SharedElementHelpers.IsWidget(element) &&
      !SharedElementHelpers.IsPhoto(element)
    ) {
      this.isShapeSelected = SharedElementHelpers.IsShape(element);
      if (!this.isSelected(element) && !SharedElementHelpers.IsShape(element)) {
        this.attachmentSelection.hoverIn(e, element);
      }
    }
  }

  onElementPointerOut(e, isPWPActive, pointerJoint) {
    let element = this.getClickedElement(e);

    if (element.isLocked) {
      e.stopPropagation();
      return;
    }

    element.isHovered = false;

    if (isPWPActive) {
      if (SharedElementHelpers.IsStep(element)) {
        this.focusSelection.hoverOut(e, element);
      }
    }

    if (
      pointerJoint &&
      !SharedElementHelpers.IsWidget(element) &&
      !SharedElementHelpers.IsPhoto(element)
    ) {
      if (!this.isSelected(element)) {
        this.attachmentSelection.hoverOut(e, element);
      }
    }
  }

  onElementRightMouseDown(e) {
    e.stopPropagation();
    let element = this.getClickedElement(e);
    this.applySelectionRules(element);
  }

  onElementRightMouseUp(e) {
    e.stopPropagation();

    if (this.selectedObjects.length > 1 && MainStorage.getCanvasPermissions().isReadonlyAccess) {
      return;
    }

    let element = this.getClickedElement(e);

    if (!SharedElementHelpers.IsReport(element) && !element.isLocked) {
      const data = element.getState();
      data.stepId = data.ID;
      data.position = getMousePosition();
      data.position.x *= window.app.scaleManager.aspectRatio;
      data.position.y *= window.app.scaleManager.aspectRatio;
      commonSendEventFunction(RP_EVENT_ELEMENT_RIGHT_CLICK, data);
    }
  }

  applySelectionRules(element, isMultiSelect) {
    let canSelect = true;
    if (isMultiSelect && SharedElementHelpers.IsReport(element)) {
      // If the report view contains elements that are selected
      // then prevent the it from being selected
      if (this.hasReporViewSelectedElements(element)) {
        canSelect = false;
      } else {
        // If no child elements are selected its okay for the report view
        // to be selected as well
        if (isMultiSelect && this.isSelected(element)) {
          // shift clicking on a selected element
          this.removeFromSelection(element);
        } else {
          this.addToSelection(element);
        }
      }
    } else if (isMultiSelect && this.isSelected(element)) {
      // shift clicking on a selected element
      this.removeFromSelection(element);
    } else if (isMultiSelect && !this.isSelected(element)) {
      // shift clicking on a non selected element
      this.addToSelection(element);
    } else if (!isMultiSelect && !this.isSelected(element)) {
      // clicking on an element that is not in the selection without shift
      // this is the case of straight forward selecting an element by clicking it
      this.clearSelection();
      this.addToSelection(element);
    }

    // If a report view is being selected previously and now
    // we are selecting an element that is inside the report view
    // the report view will be deselected
    const reportView = this.findReportViewInSelection();
    if (reportView && this.hasReporViewSelectedElements(reportView)) {
      this.removeFromSelection(reportView);
    }

    // handle how the selection is displayed , if it is displayed
    this.updateSelection(false, true, false, false);

    return canSelect;
  }

  findReportViewInSelection() {
    for (let i = 0; i < this.selectedObjects.length; i++) {
      if (SharedElementHelpers.IsReport(this.selectedObjects[i])) {
        return this.selectedObjects[i];
      }
    }

    return false;
  }

  hasReporViewSelectedElements(reportView) {
    const allReportViewElements = reportView.getAllObjects();
    for (let i = 0; i < allReportViewElements.length; i++) {
      const elementInsideReport = allReportViewElements[i];
      if (elementInsideReport.isSelected) {
        return true;
      }
    }
    return false;
  }

  isMovingElements() {
    return this.startingPositions.length > 0;
  }

  getClickedElement(e) {
    return e.currentTarget.parent;
  }

  addObjectsToSelection(objects) {
    for (let i = 0; i < objects.length; i++) {
      const object = objects[i];
      this.addToSelection(object);
    }
  }

  addToSelection(object) {
    let index = this.selectedObjects.indexOf(object);
    if (index === -1 && !object.isLocked) {
      object.highlightElement(true);
      this.selectedObjects.push(object);
      this.onElementSelected(object);
      if (this.selectedObjects.length === 1) {
        this.propageteSelectionType(SELECTION_TYPE_SINGLE);
      } else if (this.selectedObjects.length === 2) {
        this.propageteSelectionType(SELECTION_TYPE_MULTI);
      } else {
        object.onSelectionTypeChanged(SELECTION_TYPE_MULTI);
      }
    }
  }

  removeFromSelection(object) {
    let index = this.selectedObjects.indexOf(object);
    if (index > -1) {
      object.highlightElement(false);
      this.selectedObjects.splice(index, 1);
      this.onElementDeselected(object);

      if (this.selectedObjects.length === 1) {
        this.propageteSelectionType(SELECTION_TYPE_SINGLE);
      }
    }
  }

  clearSelection() {
    for (let i = this.selectedObjects.length - 1; i >= 0; i--) {
      const object = this.selectedObjects[i];
      this.selectedObjects.splice(i, 1);
      object.highlightElement(false);
      this.onElementDeselected(object);
    }
    this.selectedObjects = [];
    this.multi.clearSelectionFrame();
  }

  propageteSelectionType(type) {
    for (let i = 0; i < this.selectedObjects.length; i++) {
      const selectedObject = this.selectedObjects[i];
      selectedObject.onSelectionTypeChanged(type);
    }
  }

  isSelected(element) {
    return this.selectedObjects.indexOf(element) > -1;
  }

  hasSelectedElements() {
    return this.selectedObjects.length > 0;
  }

  resolveMultiSelection() {
    let bounds = this.multi.getSelectionBounds();
    this.checkMultiSelection(bounds);
  }

  checkMultiSelection(bounds) {
    let newlySelectedObjects = [];

    if (bounds) {
      const objects = this.sceneManager.objects;
      objects.forEach((element) => {
        const isSelectedWholeReport =
          SharedElementHelpers.IsReport(element) && bounds.containsRectangle(element.getBounds());
        if ((element.isSelectable || isSelectedWholeReport) && element.overlaps(bounds)) {
          newlySelectedObjects.push(element);
        }
      });

      // because the polygon vertices are in a local coordinate system
      const rectangleVertices = bounds.localizeVertices(window.app.viewport);
      this.sceneManager.joints.forEach((element) => {
        if (element.content.hitArea.overlapsPolygon(rectangleVertices)) {
          newlySelectedObjects.push(element);
        }
      });
    }

    let reportView = null;

    // Note! It is important to only make the necessary change ,
    // add or remove only the elments that are in/out of the selection
    // so that we can emmit the right events

    // Clear deselected elements first
    for (let i = 0; i < this.selectedObjects.length; i++) {
      const selectedObject = this.selectedObjects[i];

      if (SharedElementHelpers.IsReport(selectedObject)) {
        // Check if a report view is being selected
        reportView = selectedObject;
      }

      if (newlySelectedObjects.indexOf(selectedObject) === -1) {
        this.removeFromSelection(selectedObject);
      }
    }

    // Add newly selected elements
    for (let i = 0; i < newlySelectedObjects.length; i++) {
      const newObject = newlySelectedObjects[i];
      if (this.selectedObjects.indexOf(newObject) === -1) {
        this.addToSelection(newObject);
      }
    }

    // If the whole report was selected , deselect its internal elements
    if (reportView) {
      const allReportViewElements = reportView.getAllObjects();
      for (let i = 0; i < allReportViewElements.length; i++) {
        const elementInsideReport = allReportViewElements[i];
        if (elementInsideReport.isSelected) {
          this.removeFromSelection(elementInsideReport);
        }
      }
    }
  }

  selectElement(element) {
    this.clearSelection();
    this.addToSelection(element);
    this.updateSelection(false, true);

    // Notify that the element was selected
    this.sendElementToReact(element);
  }

  sendElementToReact(element) {
    // Notify that the element was selected
    const stepData = element.getState();
    stepData.stepId = stepData.ID;
    commonSendEventFunction(PR_EVENT_ELEMENT_SELECTED, stepData);
  }

  updateSelection(
    showToolbar = false,
    preventDrawSelection = false,
    showScalePoints = true,
    showHeads = true,
    source = null, // This is used to determine the event source ,it can be click or callback ( for example when selection is updated after analytics data is loaded)
  ) {
    this.updateSource = source;

    if (this.selectedObjects.length === 1) {
      // Update how the selection frames are drawn in the case of single selection
      // It handles cases when a single element is :
      //  - being pressed down directly
      //  - mouse released and selected , it shows the menu
      //  - while a selection tool is dragging , the menu will be hidden
      this.multi.drawSelection = preventDrawSelection ? false : !showToolbar;
      this.multi.drawFrame = false;
      this.multi.draw();

      const element = this.selectedObjects[0];

      this.single.isRightMouse = this.isRightMouse;
      this.single.setStep(element);
      this.single.updateFrame(showToolbar, showScalePoints, showHeads);
    } else if (this.selectedObjects.length > 1) {
      // For multiselection we hide the single selection tool
      this.single.hide();

      let bounds = this.getGroupBounds();
      const scale = window.app.viewport.scaled;
      const padding = SELECTION_PADDING * scale;
      // lets add padding
      bounds.left -= padding;
      bounds.top -= padding;
      bounds.width += padding * 2;
      bounds.height += padding * 2;

      // show the multiselection

      const [homogeneous, isHeadsVisible] = this.getHeadsVisibility();

      this.multi.isRightMouse = this.isRightMouse;
      this.multi.drawSelection = preventDrawSelection ? false : !showToolbar;
      this.multi.drawFrame = true;
      this.multi.updateArrowHeads(
        !MainStorage.getCanvasPermissions().isReadonlyAccess && isHeadsVisible,
      );
      this.multi.setSelectionBounds(bounds);
      this.multi.setToolbarPositionPoint();
      this.multi.notifyObjectSelected(
        !MainStorage.getCanvasPermissions().isReadonlyAccess && showToolbar,
        homogeneous,
      );
      this.multi.draw();
      this.multi.updateFrame(showToolbar);
    } else if (showToolbar) {
      // Trying to make a selection , but no objects are selected
      // so we just need to hide the selection tool
      this.hide();
    } else {
      // this is the case when a selection is dragging
      // and it is trying to activly select an object
      // but no object are being selected yet
      // also this is the case when panning the canvas
      this.single.hide();
      this.multi.drawFrame = false;
      this.multi.isRightMouse = this.isRightMouse;
      this.multi.drawSelection = preventDrawSelection ? false : true;
      this.multi.draw();
    }

    this.emmitSelectionRedraw();
    this.focusSelection.updatePositions();
    this.focusSelection.visible = true;
    window.app.needsRendering();
  }

  emmitSelectionRedraw(visible) {
    for (let i = 0; i < this.selectedObjects.length; i++) {
      const element = this.selectedObjects[i];
      element.onSelectionRedraw(visible);
    }
  }

  onMultiResizeStarted(startFrame) {
    this.multi.updateArrowHeads(false);
    commonSendEventFunction(PR_EVENT_OBJECT_SELECTED, {
      show: false,
    });
    window.app.needsRendering();

    this.initialMultiScaleData = [];

    let maxScaleDiff = Number.MAX_SAFE_INTEGER;
    let minScaleDiff = Number.MAX_SAFE_INTEGER;

    let minScale = 1;
    let maxScale = 1;

    this.multiScaleObjects = [];

    for (let i = 0; i < this.selectedObjects.length; i++) {
      const object = this.selectedObjects[i];
      if (object.isResizable) {
        this.multiScaleObjects.push(object);
      }
    }

    // Get initial data
    for (let i = 0; i < this.multiScaleObjects.length; i++) {
      const object = this.multiScaleObjects[i];

      const b = object.getBounds();
      const ap = object.getGlobalPosition();
      const scale = new PIXI.Point().copyFrom(object.scale);
      this.initialMultiScaleData.push({
        position: object.position.clone(),
        bounds: b,
        scale: scale,
        globalPosition: ap,
      });

      const minX = scale.x - MIN_ALLOWED_SCALE;
      if (minX < minScaleDiff) {
        minScaleDiff = minX;
        minScale = MIN_ALLOWED_SCALE / scale.x;
      }

      const maxX = MAX_ALLOWED_SCALE - scale.x;
      if (maxX < maxScaleDiff) {
        maxScaleDiff = maxX;
        maxScale = MAX_ALLOWED_SCALE / scale.x;
      }
    }

    this.minMaxRectScales.minScale = minScale;
    this.minMaxRectScales.maxScale = maxScale;
  }

  onMultiResizeChanged(startFrame, endFrame) {
    let scaleX = endFrame.width / startFrame.width;
    let scaleY = endFrame.height / startFrame.height;

    for (let i = 0; i < this.multiScaleObjects.length; i++) {
      const object = this.multiScaleObjects[i];
      const data = this.initialMultiScaleData[i];

      const gx = Utils.map(
        data.globalPosition.x,
        startFrame.x,
        startFrame.x + startFrame.width,
        endFrame.x,
        endFrame.x + endFrame.width,
      );
      const gy = Utils.map(
        data.globalPosition.y,
        startFrame.y,
        startFrame.y + startFrame.height,
        endFrame.y,
        endFrame.y + endFrame.height,
      );

      const local = object.parent.toLocal(new PIXI.Point(gx, gy));

      object.position.set(local.x, local.y);
      object.scale.set(data.scale.x * scaleX, data.scale.y * scaleY);
      object.move();
      AppSignals.elementScaleChanged.dispatch(object);
      object.updateHitArea();
    }

    this.updateSelection(false, true, false, false);

    window.app.needsRendering();
  }

  onMultiResizeEnded(startFrame, endFrame) {
    const commandBatch = new CommandBatchMove(this);
    for (let i = 0; i < this.multiScaleObjects.length; i++) {
      const object = this.multiScaleObjects[i];
      const data = this.initialMultiScaleData[i];
      const command = new CommandScaleMove(object, data.position, data.scale);
      commandBatch.add(command);
    }

    if (!commandBatch.isEmpty()) {
      AppSignals.commandCreated.dispatch(commandBatch);
    }

    this.multiScaleObjects = [];

    let homogeneous = this.homogeneousCheck();
    const isConnections = this.isHomogeneousConnections(homogeneous);
    const canCreateMultiConnections = this.canCreateMultiConnection(homogeneous);
    // The "&& !isConnections;" will cause to
    // hide the connection creation heads in the case of exclusive connection selection
    // Also connections are ignored when trying to create a multi-connection out of them
    const isHeadsVisible = canCreateMultiConnections && !isConnections;
    this.multi.updateArrowHeads(isHeadsVisible);
    window.app.needsRendering();
  }

  getScaleLimit() {
    return this.minMaxRectScales;
  }

  hideToolbar() {
    // todo Make hiding toolbar work through one pipe
    // todo [optimize] Do no redraw the toolbar if you'se selecting the same element
    commonSendEventFunction(PR_EVENT_OBJECT_SELECTED, {
      show: false,
    });
  }

  getSelectionBoundingRect() {
    if (this.selectedElement) {
      return this.selectedElement.getContentBounds();
    }

    return this.getGroupBounds();
  }

  getGroupBounds() {
    return this.findGroupBounds(this.selectedObjects);
  }

  findGroupBounds(objects) {
    const selection = [];
    for (let i = 0; i < objects.length; i++) {
      let el = objects[i];

      if (SharedElementHelpers.IsConnection(el)) {
        const coords = el.getLocalBounds();
        this._addGlobalCoordinates(selection, coords.x, coords.y);
        this._addGlobalCoordinates(
          selection,
          coords.x + el.content.width * el.scale.x,
          coords.y + el.content.height * el.scale.y,
        );
        this._addGlobalCoordinates(selection, coords.x + el.content.width * el.scale.x, coords.y);
        this._addGlobalCoordinates(selection, coords.x, coords.y + el.content.height * el.scale.y);
      } else {
        let bottomY = 0;
        if (el.footer && el.footer.visible) {
          bottomY += el.footer.getFooterHeight();
        }

        this._addGlobalCoordinates(selection, el.x, el.y);
        this._addGlobalCoordinates(
          selection,
          el.x + el.content.width * el.scale.x,
          el.y + el.content.height * el.scale.y,
        );
        this._addGlobalCoordinates(selection, el.x + el.content.width * el.scale.x, el.y);
        this._addGlobalCoordinates(
          selection,
          el.x,
          el.y + (el.content.height + bottomY) * el.scale.y,
        );
      }
    }
    return Utils.getBoundingRect(selection);
  }

  getSelectedSteps() {
    return this.selectedObjects.filter((object) => !SharedElementHelpers.IsConnection(object));
  }

  getSelectedObjects() {
    return this.selectedObjects;
  }

  _addGlobalCoordinates(target, x, y) {
    const coords = Facade.viewport.toGlobal({
      x: x,
      y: y,
    });
    target.push(...[coords.x, coords.y]);
  }

  onHeadsDown(e) {
    if (this.delegate && this.delegate.onSelectionHeadsDown) {
      this.delegate.onSelectionHeadsDown(e);
    }
  }

  show() {
    this.updateSelection(true, true);
  }

  hide() {
    this.single.hide();
    this.multi.hideBounds();
    this.multi.hideToolbar();
    this.focusSelection.hide();
    window.app.needsRendering();
  }

  getHeadsVisibility() {
    let homogeneous = this.homogeneousCheck();
    const isConnections = this.isHomogeneousConnections(homogeneous);
    if (isConnections) {
      this.addSpecificHomogeneousData(homogeneous);
    }

    const canCreateMultiConnections = this.canCreateMultiConnection(homogeneous);
    // The "&& !isConnections;" will cause to
    // hide the connection creation heads in the case of exclusive connection selection
    // Also connections are ignored when trying to create a multi-connection out of them
    const isHeadsVisible = canCreateMultiConnections && !isConnections;
    return [homogeneous, isHeadsVisible];
  }

  homogeneousCheck() {
    let categories = {};
    let types = {};
    for (let i = 0; i < this.selectedObjects.length; i++) {
      const object = this.selectedObjects[i];
      categories[object.category] = object.category;
      types[object.type] = object.type;
    }
    categories = Object.keys(categories);
    types = Object.keys(types);

    return {
      isHomogeneous: types.length === 1 && categories.length === 1,
      types,
      categories,
    };
  }

  canCreateMultiConnection(homogeneous) {
    for (let i = 0; i < NOT_ALLOWED_TO_CREATE_MULTI_CONNECTIONS.length; i++) {
      const category = NOT_ALLOWED_TO_CREATE_MULTI_CONNECTIONS[i];
      if (homogeneous.categories.indexOf(category) !== -1) {
        return false;
      }
    }

    return true;
  }

  isHomogeneousConnections(data) {
    return data.isHomogeneous && data.categories[0] === EElementCategories.CONNECTION
      ? true
      : false;
  }

  addSpecificHomogeneousData(homogeneous) {
    if (this.isHomogeneousConnections(homogeneous)) {
      // Set the supported line types that are only in common
      let toCheck = [];
      let commonLineType = this.selectedObjects[0].lineType;
      let commonDrawLineType = this.selectedObjects[0].drawLineType;
      for (let i = 0; i < this.selectedObjects.length; i++) {
        const object = this.selectedObjects[i];
        toCheck.push(object.supportedLineTypes);
        commonLineType = commonLineType !== object.lineType ? null : commonLineType;
        commonDrawLineType = commonDrawLineType !== object.drawLineType ? null : commonDrawLineType;
      }
      const supportedLineTypes = intersection.apply(null, toCheck);
      homogeneous.supportedLineTypes = supportedLineTypes;
      homogeneous.commonLineType = commonLineType;
      homogeneous.commonDrawLineType = commonDrawLineType;
    }
  }

  // Handle delegation
  onMultiSelectionFrameDraw(graphics) {
    // lets draw individual frames
    const scale = window.app.viewport.scaled;
    const padding = scale * SELECTION_BOUNDARY_GAP;

    for (let i = 0; i < this.selectedObjects.length; i++) {
      const object = this.selectedObjects[i];
      if (!object.hasSelectionFrame) {
        continue;
      }
      let coords = null;
      if (SharedElementHelpers.IsConnection(object)) {
        coords = object.getBounds();
      } else {
        coords = Facade.viewport.toGlobal(object);
      }

      let bottomY = 0;
      if (object.footer && object.footer.visible) {
        bottomY += object.footer.getFooterHeight();
      }

      const width = object.content.width * object.scale.x * scale;
      const height = (object.content.height + bottomY) * object.scale.y * scale;

      if (object.isInsideReport) {
        graphics.lineStyle(MULTIPLE_SELECTION_BOUNDS_WIDTH, COLOR_ELEMENT_HIGHLIGHT_FRAME);
      } else {
        graphics.lineStyle(MULTIPLE_SELECTION_BOUNDS_WIDTH, COLOR_SELECTION);
      }

      graphics.drawRoundedRect(
        coords.x - padding - this.multi.x,
        coords.y - padding - this.multi.y,
        width + 2 * padding,
        height + 2 * padding,
        SELECTION_ROUND_CORNER,
      );
    }
  }

  onBringForward() {
    // Lets take all the selected elements in descending order
    // and since we are ordering them in descending order it means that the first
    // element in the array is going to be the one that is the first on the z axis (in front of all the other elements)
    const elementsToMove = this.getElementsToMove(ORDER_DESC);
    let lastElementMoved = true;

    // we will now try to move all elemnts up one level , starting with the one at the top
    // there is only one rule when not to move , and that is:
    // If the element infront can't be moved furter ( last moved element ) and we are right
    // behind that element , then we also can't move.
    // For additional information see https://funnelytics.atlassian.net/wiki/spaces/EN/pages/1246724097/Move+elements+on+Z+axis+One+above+other

    for (let i = 0; i < elementsToMove.length; i++) {
      const element = elementsToMove[i];
      const lastElement = elementsToMove[i - 1];

      // Don't move up if the element right infront of you have not moved up.
      if (lastElement) {
        if (lastElement.index + 1 === element.index && !lastElementMoved) {
          continue;
        }
      }
      lastElementMoved = element.object.bringForward();
    }

    this.hideToolbar();
    this.show();
  }

  onBringToFront() {
    // Lets take all the selected elements in ascending order
    // and since we are ordering them in ascending order it means that the first
    // element in the array is going to be the one that is the last on the z axis (behind all the other elements)
    // and if we bring the last one at front , and then continue 2nd last again to front
    // this will result with moving all the object to the front and also keeping their relative position to each other
    // For additional information see https://funnelytics.atlassian.net/wiki/spaces/EN/pages/1246724097/Move+elements+on+Z+axis+One+above+other
    const elementsToMove = this.getElementsToMove(ORDER_ASC);

    for (let i = 0; i < elementsToMove.length; i++) {
      const element = elementsToMove[i];
      element.object.bringToFront();
    }

    this.hideToolbar();
    this.show();
  }

  onSendBackward() {
    // For more details please see onBringForward() , as it is the same method ,just in reverse
    // For additional information see https://funnelytics.atlassian.net/wiki/spaces/EN/pages/1246724097/Move+elements+on+Z+axis+One+above+other
    const elementsToMove = this.getElementsToMove(ORDER_ASC);
    let lastElementMoved = true;
    const reportView = this.sceneManager.getReport();

    for (let i = 0; i < elementsToMove.length; i++) {
      const element = elementsToMove[i];
      const lastElement = elementsToMove[i - 1];

      // Don't move down if the element right infront of you have not moved down.
      if (lastElement) {
        if (lastElement.index + 1 === element.index && !lastElementMoved) {
          continue;
        }
      }

      if (!reportView || (reportView && !reportView.containtsObject(element.object))) {
        lastElementMoved = element.object.sendBackward();
      } else {
        // in case we are skiping a movement because of the report view , lets pretend that the movement can continue
        lastElementMoved = true;
      }
    }

    this.hideToolbar();
    this.show();
  }

  onSendToBack() {
    // Lets take all the selected elements in descending order
    // and since we are ordering them in descending order it means that the first
    // element in the array is going to be the one that is the first on the z axis (in front of all the other elements)
    // and if we push first element to the back of all elements , and then continue with 2nd element
    // this will result with moving all the object at the back and also keeping their relative position to each other
    // For additional information see https://funnelytics.atlassian.net/wiki/spaces/EN/pages/1246724097/Move+elements+on+Z+axis+One+above+other
    const elementsToMove = this.getElementsToMove(ORDER_DESC);
    const reportView = this.sceneManager.getReport();

    for (let i = 0; i < elementsToMove.length; i++) {
      const element = elementsToMove[i];
      if (!reportView || (reportView && !reportView.containtsObject(element.object))) {
        element.object.pushToBack();
      }
    }

    this.hideToolbar();
    this.show();
  }

  /**
   * It takes all the elements in the selection , orders them by their position in the canvas
   * and returns them
   * @param {String} order Either ASC or DESC
   * @returns an array that contains wrapped objects with their index position information
   */
  getElementsToMove(order = ORDER_DESC) {
    // Lets take all the selected elements and create a new array
    // that will hold the objects along with their index position in their parent container
    const elementsToMove = [];
    for (let i = 0; i < this.selectedObjects.length; i++) {
      const selectedObject = this.selectedObjects[i];
      elementsToMove.push({
        object: selectedObject,
        index: selectedObject.parent.getChildIndex(selectedObject),
      });
    }

    // lets order them by thier index
    let compare = null;

    if (order === ORDER_DESC) {
      compare = (a, b) => {
        if (a.index < b.index) {
          return 1;
        } else if (a.index > b.index) {
          return -1;
        }
        return 0;
      };
    } else if (order === ORDER_ASC) {
      compare = (a, b) => {
        if (a.index < b.index) {
          return -1;
        } else if (a.index > b.index) {
          return 1;
        }
        return 0;
      };
    }
    elementsToMove.sort(compare);

    return elementsToMove;
  }

  onShapeResizeHandleDown(e, shape) {
    this.isShapeResizing = true;
    this.delegate.onShapeResizeHandleDown(e, shape);
  }

  onShapeResizeHandleMove(e, shape) {
    this.delegate.onShapeResizeHandleMove(e, shape);
  }

  onShapeResizeHandleUp(e, shape) {
    this.delegate.onShapeResizeHandleUp(e, shape);
    this.isShapeResizing = false;
  }

  onTextResizeHandleDown(e, textLabel) {
    this.delegate.onTextResizeHandleDown(e, textLabel);
  }

  onTextResizeHandleMove(e, textLabel) {
    this.delegate.onTextResizeHandleMove(e, textLabel);
  }

  onTextResizeHandleUp(e, textLabel) {
    this.delegate.onTextResizeHandleUp(e, textLabel);
  }

  onElementSelected(element) {
    element.onSelected(this.selectedObjects.length);
    if (element.isFocused) {
      this.focusSelection.hideFrameByElementId(element.id);
    }
    commonSendEventFunction(PR_EVENT_SELECTION_ADDED, {
      element,
      selection: this.selectedObjects,
    });
  }

  onElementDeselected(element) {
    element.onDeselected(this.selectedObjects.length);
    if (element.isFocused) {
      this.focusSelection.showFrameByElementId(element.id);
    }
    element.onSelectionTypeChanged(SELECTION_TYPE_NONE);
    // notify if an element is deselected
    commonSendEventFunction(PR_EVENT_SELECTION_REMOVED, {
      element,
      selection: this.selectedObjects,
    });
  }

  updateFocused() {
    this.focusSelection.updatePositions();
  }

  // DELEGATE handler
  onAttachmentPointUp(event, attachmentPoint) {
    if (this.delegate && this.delegate.onAttachmentPointUp) {
      this.delegate.onAttachmentPointUp(event, attachmentPoint);
    }
    event.stopPropagation();
  }

  clearReset() {
    if (this.hasSelectedElements()) {
      this.clearSelection();
      this.hide();
      this.hideToolbar();
    }
  }

  onToggleAnalytics() {
    if (this.hasSelectedElements()) {
      this.updateSelection(true, false, true, true, SELECTION_UPDATE_SOURCE_ANALYTICS);
    }
  }

  onToggleForecasting() {
    if (this.hasSelectedElements()) {
      this.updateSelection(true, false, true, true, SELECTION_UPDATE_SOURCE_ANALYTICS);
    }
  }
}
