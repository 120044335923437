export const OPERATOR_EQUAL = '=';
export const OPERATOR_NOT_EQUAL = '<>';
export const OPERATOR_CONTAINS = 'like';
export const OPERATOR_DOES_NOT_CONTAINS = 'not like';

export const OPERATOR_IS_GRATER_THEN = '>';
export const OPERATOR_IS_GRATER_THEN_OR_EQUAL = '>=';
export const OPERATOR_IS_LESS_THEN = '<';
export const OPERATOR_IS_LESS_THEN_OR_EQUAL = '<=';

export const OPERATOR_IS_EXCLUDED = '!=';

const basicOperators = [
  {
    label: 'contains',
    value: OPERATOR_CONTAINS,
  },
  {
    label: 'does not contain',
    value: OPERATOR_DOES_NOT_CONTAINS,
  },
];

const CommonOperators = [
  {
    label: 'is equal to',
    value: OPERATOR_EQUAL,
  },
  {
    label: 'is not equal to',
    value: OPERATOR_NOT_EQUAL,
  },
  {
    label: 'contains',
    value: OPERATOR_CONTAINS,
  },
  {
    label: 'does not contain',
    value: OPERATOR_DOES_NOT_CONTAINS,
  },
];

const ExtraOperators = [
  {
    label: 'is excluded',
    value: OPERATOR_IS_EXCLUDED,
  },
];

const MathOperators = [
  {
    label: 'is greater than',
    value: OPERATOR_IS_GRATER_THEN,
  },
  {
    label: 'is greater than or equal to',
    value: OPERATOR_IS_GRATER_THEN_OR_EQUAL,
  },
  {
    label: 'is less than',
    value: OPERATOR_IS_LESS_THEN,
  },
  {
    label: 'is less than or equal to',
    value: OPERATOR_IS_LESS_THEN_OR_EQUAL,
  },
];

const mathematicalOperators = [
  OPERATOR_IS_GRATER_THEN,
  OPERATOR_IS_GRATER_THEN_OR_EQUAL,
  OPERATOR_IS_LESS_THEN,
  OPERATOR_IS_LESS_THEN_OR_EQUAL,
];

const numberValidationMapping = {
  '-0.': '-0.',
  '-00': '-0',
  '.': '0.',
  '0.': '0.',
  '0-': '-0',
  '-0': '-0',
  '-': '-',
};

export const DEFAULT_OPERATOR = OPERATOR_EQUAL;
export const LogicalOperators = CommonOperators.concat(MathOperators);
export const PageOperators = basicOperators.slice();
export const EventOperators = LogicalOperators.slice();
export const SourceOperators = CommonOperators.slice().concat(ExtraOperators);

export const getLogicalOperator = (operator, defaultOperator = OPERATOR_EQUAL) => {
  const o = LogicalOperators.find((itm) => itm.value === operator);
  return o ? o : LogicalOperators.find((itm) => itm.value === defaultOperator);
};

export const isMathOperator = (operator) => {
  return mathematicalOperators.indexOf(operator) !== -1;
};

export const isExcludedOperator = (operator) => {
  return operator === OPERATOR_IS_EXCLUDED;
};

export const backwardCompatibleOperator = (item) => {
  if (item.operator) {
    return item.operator;
  } else if (item.contains) {
    return item.contains === 'true' ? OPERATOR_EQUAL : OPERATOR_NOT_EQUAL;
  }
  return OPERATOR_EQUAL;
};

export const transformToSupportedNumber = (value) => {
  const validMappedValue = numberValidationMapping[value];
  if (validMappedValue) {
    return validMappedValue;
  }
  return isNumeric(value) ? value : '0';
};

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
