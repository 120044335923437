import React, { useEffect, useRef, useState } from 'react';
import {
  PR_EVENT_OBJECT_SELECTED,
  PR_NOTES_ADDED,
  RP_EVENT_ON_NOTE_EDITED,
  RR_SET_ACTIVE_CHECKLIST,
  RR_SET_ACTIVE_NOTE,
} from 'shared/CSharedEvents';
import { FLOAT_POPUP_TAB_CHECKLIST, FLOAT_POPUP_TAB_NOTE, FloatingPopup } from './FloatingPopup';
import { commonSendEventFunction } from 'shared/CSharedMethods';

export const FloatingPopups = () => {
  const openPopupsRef = useRef([]);
  const [openFoatingPopups, setOpenFloatingPopups] = useState([]);
  const allChecklistsData = useRef({});

  useEffect(() => {
    document.addEventListener(RR_SET_ACTIVE_NOTE, onActiveNoteSet, false);
    document.addEventListener(PR_NOTES_ADDED, onNotesAdded, false);
    document.addEventListener(RR_SET_ACTIVE_CHECKLIST, onChecklistOpened, false);

    return () => {
      document.removeEventListener(RR_SET_ACTIVE_NOTE, onActiveNoteSet);
      document.removeEventListener(PR_NOTES_ADDED, onNotesAdded);
      document.removeEventListener(RR_SET_ACTIVE_CHECKLIST, onChecklistOpened);
    };
  }, []);

  const onChecklistOpened = (e) => {
    openPopup(e.detail, FLOAT_POPUP_TAB_CHECKLIST);
  };

  const onActiveNoteSet = (e) => {
    openPopup(e.detail, FLOAT_POPUP_TAB_NOTE);
  };

  const onNotesAdded = (e) => {
    updatePopup(e.detail, FLOAT_POPUP_TAB_NOTE);
  };

  const openPopup = (detail, activeTab = null) => {
    const { id, checklistData, object, topMenuRect } = detail;
    allChecklistsData.current[id] = checklistData;

    const existingPop = openPopupsRef.current.find((pop) => pop.id === id);
    if (existingPop) {
      // if the window is already open, just focus on it
      existingPop.activeTab = activeTab;
      existingPop.isOpen = true;
      setOpenFloatingPopups([...openPopupsRef.current]);
      return;
    }

    openPopupsRef.current.push({
      id,
      activeTab,
      step: object,
      isOpen: true,
      topMenuRect,
    });
    setOpenFloatingPopups([...openPopupsRef.current]);
  };

  const updatePopup = (detail, activeTab = null) => {
    const { id, checklistData, object, topMenuRect } = detail;
    allChecklistsData.current[id] = checklistData;

    const existingPopup = openPopupsRef.current.find((pop) => pop.id === id);
    if (existingPopup) {
      // if the pop is already open, just focus on it

      existingPopup.activeTab = activeTab;
      setOpenFloatingPopups([...openPopupsRef.current]);
      return;
    }

    openPopupsRef.current.push({
      id,
      activeTab,
      step: object,
      isOpen: false,
      topMenuRect,
    });
    setOpenFloatingPopups([...openPopupsRef.current]);
  };

  const removePopup = (id) => {
    const popInfo = openPopupsRef.current.find((pop) => pop.id === id);
    popInfo.isOpen = false;
    setOpenFloatingPopups([...openPopupsRef.current]);
  };

  const movePopupToBeLast = (id) => {
    const popInfo = openPopupsRef.current.find((pop) => pop.id === id);
    openPopupsRef.current = openPopupsRef.current.filter((pop) => pop.id !== id);
    openPopupsRef.current.push(popInfo);
    setOpenFloatingPopups([...openPopupsRef.current]);
  };

  const onNoteRemoved = (id) => {
    commonSendEventFunction(RP_EVENT_ON_NOTE_EDITED, { id, noteData: null, isEmpty: true });
  };

  const onPopupDrag = (id) => {
    // when an object is selected , its menu appears and it covers the notes.
    // This is a solution to just hide the menu when you click on the note panel

    commonSendEventFunction(PR_EVENT_OBJECT_SELECTED, {
      show: false,
    });
    movePopupToBeLast(id);
  };

  const onTabSwitch = (tab, id) => {
    const popInfo = openPopupsRef.current.find((pop) => pop.id === id);
    popInfo.activeTab = tab;
    setOpenFloatingPopups([...openPopupsRef.current]);
  };

  const onNoteEdited = (id, noteData) => {
    const popInfo = openPopupsRef.current.find((pop) => pop.id === id);

    if (popInfo) {
      const step = {
        ...popInfo.step,
      };
      step.object = {
        ...popInfo.step.object,
        notes: noteData,
      };
      popInfo.step = step;
      setOpenFloatingPopups([...openPopupsRef.current]);
    }
    popInfo.step.notes = noteData;

    setOpenFloatingPopups([...openPopupsRef.current]);
  };

  const onChecklistEdited = (id, checklistData) => {
    allChecklistsData.current[id] = checklistData;
  };

  return (
    <>
      {openFoatingPopups.map((popInfo) => {
        return (
          <FloatingPopup
            popInfo={popInfo}
            onRemovePopup={removePopup}
            key={popInfo.id}
            elementID={popInfo.id}
            onNoteRemoved={onNoteRemoved}
            onPopupDrag={onPopupDrag}
            checklistData={allChecklistsData.current[popInfo.id]}
            onTabSwitch={onTabSwitch}
            step={popInfo.step}
            onNoteEdited={onNoteEdited}
            onChecklistEdited={onChecklistEdited}
            isOpen={popInfo.isOpen}
          />
        );
      })}
    </>
  );
};
