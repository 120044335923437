import RequestService from 'react-project/Helpers/RequestService';
import { removeAuthCookie } from 'react-project/Util/AuthCookie';
import { ActionNames } from 'react-project/redux/actionNamesConstant';
import { createAction } from 'redux-actions';

const requestService = new RequestService();

export const clean = createAction(ActionNames.clean);
export const setAccessToFunnel = createAction(ActionNames.setAccessToFunnel);

export const logout = () => (dispatch) => {
  removeAuthCookie();
  dispatch(clean());
};

export const asyncHasAccessToFunnel = (funnelId) => {
  let counter = 0;
  let data;
  return async (dispatch) => {
    data = await requestService.getCanvasPermissions(funnelId);
    while (!data && counter < 3) {
      counter += 1;
      data = await requestService.getCanvasPermissions(funnelId);
    }
    dispatch(setAccessToFunnel(data && !data.isReadOnlyAccess));
  };
};
