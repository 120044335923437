import React from 'react';
import { iconWidget } from 'react-project/assets/Icons';
import { TEXTS_TOOLTIP } from 'react-project/Constants/texts';
import { Tooltip } from 'react-project/components/tooltip/Tooltip';
import styles from './tools.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setWidgetPosition, setWidgetStatus } from 'react-project/redux/popups/actions';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { RR_EVENT_ON_WIDGET_CLOSE, RR_EVENT_ON_WIDGET_PRESSED } from 'shared/CSharedEvents';
import cls from 'classnames';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';

export const WidgetTool = ({ onOpen, isActive, onClose }) => {
  const canvasPermissions = useSelector(selectCanvasPermissions);
  const dispatch = useDispatch();

  const onWidgetClicked = (e) => {
    if (isActive) {
      commonSendEventFunction(RR_EVENT_ON_WIDGET_CLOSE);
      return;
    }

    onOpen();
    const isAnalyticsAllowed = canvasPermissions.isAnalyticsAllowed;
    if (canvasPermissions.widgetsAllowed) {
    } else if (isAnalyticsAllowed === null) {
      console.warn('Waiting to load data from server');
      return;
    }

    dispatch(setWidgetStatus(true));
    const rect = e.currentTarget.getBoundingClientRect();
    const position = { x: rect.x, y: rect.y };
    dispatch(setWidgetPosition(position));

    commonSendEventFunction(RR_EVENT_ON_WIDGET_PRESSED);
  };

  return (
    <Tooltip label={TEXTS_TOOLTIP.WIDGET_TOOL}>
      <div
        id="widget-tool-btn"
        className={cls(styles.ToolButton, isActive ? styles.Active : null)}
        onClick={onWidgetClicked}
      >
        {iconWidget}
      </div>
    </Tooltip>
  );
};
