export default class HTMLExtractor {
  constructor(createEmptyItem) {
    this.createEmptyItem = createEmptyItem;
  }

  getChecklistExtractor = (clipboardData) => {
    const types = clipboardData.types;
    let hasHTML = false;

    for (let i = 0; i < types.length; i++) {
      const type = types[i];
      if (type.match(/google-docs\-document/)) {
        // google docs html extractor
        return this.extractFromHTML;
      } else if (type === 'text/html') {
        hasHTML = true;
      }
    }

    if (hasHTML) {
      const html = clipboardData.getData('text/html');
      // check html contains sub string "data-aria-level"
      if (html.indexOf('data-aria-level') > -1) {
        // Microsoft office 365 html extractor
        return this.extractFromOffice365HTML;
      }

      return this.extractFromHTML;
    }

    return null;
  };

  extractFromOffice365HTML = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const liElements = doc.querySelectorAll('li');
    const items = this.constructItemsListFromLiElements(liElements);
    this.removeParentReferences(items);
    return items;
  };

  removeParentReferences = (items) => {
    items.forEach((item) => {
      delete item.parent;
      if (item.items) {
        this.removeParentReferences(item.items);
      }
    });
  };

  // create a list of items from the list of li elements
  // the li elements contain the data-aria-level attribute
  // which is used to determine the hierarchy of the items
  // The list needs to be recursively constructed , it will hold
  // the children in the items property

  constructItemsListFromLiElements = (liElements, items, index = 0, parent = null, data = []) => {
    if (!items) {
      items = data;
    }

    const element = liElements[index];
    const item = this.createEmptyItem();
    item.isEditing = false;
    item.text = element.textContent;
    item.parent = parent;
    items.push(item);
    const nextElement = liElements[index + 1];

    if (nextElement) {
      if (nextElement.dataset.ariaLevel === element.dataset.ariaLevel) {
        // same level
        this.constructItemsListFromLiElements(liElements, items, index + 1, parent, data);
      } else if (nextElement.dataset.ariaLevel > element.dataset.ariaLevel) {
        // go to deeper level
        this.constructItemsListFromLiElements(liElements, item.items, index + 1, item, data);
      } else if (nextElement.dataset.ariaLevel < element.dataset.ariaLevel) {
        // go to upper level , but it can be more then one level jump
        // we need to find the the corresponding item and list at the target level
        const lci = this.findLastCorespondingItem(data, nextElement.dataset.ariaLevel);
        this.constructItemsListFromLiElements(liElements, lci[0], index + 1, lci[1], data);
      }
    }

    return items;
  };

  // Itereate through the items list and find the last item at the target level
  findLastCorespondingItem = (items, targetLevel, currentLevel = 1, parent) => {
    if (currentLevel == targetLevel) {
      return [items, parent];
    }

    const lastItem = items[items.length - 1];
    if (lastItem.items.length > 0) {
      return this.findLastCorespondingItem(lastItem.items, targetLevel, currentLevel + 1, lastItem);
    }

    return [];
  };

  extractFromHTML = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const ul = this.getTopListElement(doc);
    const items = [];

    if (ul) {
      const children = ul.children;
      for (let i = 0; i < children.length; i++) {
        const li = children[i];
        const item = this.createEmptyItem();
        item.isEditing = false;
        item.text = li.textContent;

        if (li.children.length > 1 && !(li instanceof HTMLLIElement)) {
          const theItems = this.extractFromHTML(li.outerHTML);
          items[items.length - 1].items = theItems;
          continue;
        }

        items.push(item);
      }
    }
    return items;
  };

  getTopListElement = (doc) => {
    return doc.querySelector('ul') || doc.querySelector('ol');
  };
}
