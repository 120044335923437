import moment from 'moment';
import { tutorialMaxDate } from 'react-project/LeftSideBar/Filters/filter-components/DateFilterBlock';

import { formatDate } from 'react-project/Util/dateFormatter';

export const getFutureDate = (yearsToAdd = 10) => {
  const date = new Date();

  date.setFullYear(date.getFullYear + yearsToAdd);

  return date;
};

export const getDiffInDays = (startDate, endDate, dateFormate = 'YYYY-MM-DD') => {
  return moment(endDate, dateFormate).diff(moment(startDate, dateFormate), 'days');
};

export const dateFormatter = (rawDate) => {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(new Date(rawDate));
};

const preDefinedDates = {
  Today: [moment(), moment()],
  Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'Last 7 Days': [moment().subtract(6, 'days'), moment()],
  'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Last Month': [
    moment().subtract(1, 'month').startOf('month'),
    moment().subtract(1, 'month').endOf('month'),
  ],
};

export const getDateRange = (dateRange) => {
  const selectedDateRange = dateRange.selectedLabel ? preDefinedDates[dateRange.selectedLabel] : '';
  if (selectedDateRange && dateRange.selectedLabel !== 'Custom Range') {
    return {
      min: new Date(dateFormatter(selectedDateRange[0].format())).toISOString(),
      max: new Date(dateFormatter(selectedDateRange[1].format())).toISOString(),
      selectedLabel: dateRange.selectedLabel,
    };
  } else {
    return dateRange;
  }
};

export const getTutorialDateRange = () => {
  const inputStart = tutorialMaxDate.clone().subtract(14, 'days').toISOString();
  const inputFinish = tutorialMaxDate.clone().toISOString();
  const range = {
    min: inputStart,
    max: inputFinish,
    selectedLabel: 'Custom Range',
  };
  return range;
};
