import cls from 'classnames';
import React from 'react';
import styles from 'react-project/components/popUp/UpsellPopup/content/Tutorial.module.scss';

export const ListItem = ({ task, taskName, onClick, dataKey }) => {
  const setSelected = () => {
    onClick(task.index);
  };
  return (
    <li
      className={cls(
        styles.listItem,
        task.acknowledged ? styles.completed : '',
        taskName === task.name ? styles.selected : '',
      )}
      onClick={setSelected}
      data-key={dataKey}
    >
      <div className={styles.name}>{task.listTitle}</div>
    </li>
  );
};

export const List = ({ isLoading, tasks, taskName, onClick, listRef }) => {
  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '368px',
          }}
        >
          <div style={{ display: 'flex', fontSize: '48px', justifyContent: 'center' }}>
            <div> Loading ... </div>
          </div>
        </div>
      ) : (
        <ul ref={listRef} style={{ overflowY: 'overlay', height: '368px' }}>
          <div style={{ padding: '8px 0 0 0' }} />
          {tasks.map((task, i) => (
            <ListItem
              task={task}
              key={`tut-${i}`}
              dataKey={`tut-${i}`}
              taskName={taskName}
              onClick={onClick}
            />
          ))}
        </ul>
      )}
    </>
  );
};
