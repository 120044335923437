import * as PIXI from 'pixi.js';

// DELEGATES
// - onAttachmentPointHoverIn(event , attachmentPoint)
// - onAttachmentPointHoverOut(event , attachmentPoint)
// - onAttachmentPointPointerUp(event , attachmentPoint)

export default class AttachmentPoint extends PIXI.Container {
  constructor(type, delegate, element) {
    super();

    this.type = type;
    this.delegate = delegate;
    this.element = element;

    this.content = PIXI.Sprite.from(PIXI.utils.TextureCache['selection-point']);
    this.content.anchor.set(0.5, 0.5);
    this.content.interactive = true;
    this.content.buttonMode = true;
    this.content.pointerover = this.onPointerOver.bind(this);
    this.content.pointerout = this.onPointerOut.bind(this);
    this.content.pointerdown = this.onPointerDown.bind(this);
    this.content.pointerup = this.onPointerUp.bind(this);
    this.addChild(this.content);

    this.content.hitArea = new PIXI.Circle(0, 0, 16);
  }

  onPointerOver(e) {
    this.content.texture = PIXI.utils.TextureCache['selection-point-hover'];
    if (this.delegate && this.delegate.onAttachmentPointHoverIn) {
      this.delegate.onAttachmentPointHoverIn(e, this);
    }
    e.stopPropagation();
  }

  onPointerOut(e) {
    this.content.texture = PIXI.utils.TextureCache['selection-point'];
    if (this.delegate && this.delegate.onAttachmentPointHoverOut) {
      this.delegate.onAttachmentPointHoverOut(e, this);
    }
    e.stopPropagation();
  }

  onPointerDown(e) {}

  onPointerUp(e) {
    if (this.delegate && this.delegate.onAttachmentPointPointerUp) {
      this.delegate.onAttachmentPointPointerUp(e, this);
    }
  }
}
