import React, { Component } from 'react';
import styles from 'react-project/Toolbar/Toolbar.module.scss';
import { iconDelete } from 'react-project/assets/Icons';
import produce from 'immer';
import { selectCurrentStep } from '../redux/current-step/selectors';
import { selectApiKey } from 'react-project/redux/analytics/selectors';
import {
  setNewCurrentStepFilterParams,
  setNewCurrentStepTrackingUrl,
} from 'react-project/redux/current-step/actions';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';
import { connect } from 'react-redux';
import { EElementTypes } from 'shared/CSharedCategories';
import { RP_EVENT_EDIT_OBJECT } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { selectFunnelConfiguration } from '../redux/funnel-configuration/selectors';
import { DEFAULT_OPERATOR } from './step-toolbar/LogicalOperators';
import {
  VERTICAL_ALIGN_CENTER,
  ViewportConstrainer,
} from 'react-project/components/viewportConstrainer/ViewportConstrainer';
import { StepToolbar } from './StepToolbar';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';

const typeSource = EElementTypes.SOURCE;
const typeActions = EElementTypes.EVENT;

class TopToolbar extends Component {
  constructor(props) {
    super(props);
    let activeStateForUrlParamsCheckbox;

    const filterParamsInitArray = this.props.currentStep.object.filterData;
    filterParamsInitArray.length > 0
      ? (activeStateForUrlParamsCheckbox = true)
      : (activeStateForUrlParamsCheckbox = false);
    this.state = {
      loadingThumbnailImg: false,
      notesBlock: true,
      categoryType: this.props.currentStep.object.category,
      isShowUrlParams: activeStateForUrlParamsCheckbox,
      urlValue: this.props.currentStep.object.url,
      labelValue: this.props.currentStep.object.label,
      filterParamsArray: filterParamsInitArray,
    };

    this.modules = {
      toolbar: [
        [{ font: [] }],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ['clean'],
      ],
    };

    this.handleInputChangeUrlParams = this.handleInputChangeUrlParams.bind(this);
    this.handleFilterPageByUrlChange = this.handleFilterPageByUrlChange.bind(this);
  }

  handleInputChangeUrlParams(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleFilterPageByUrlChange(event) {
    const target = event.target;
    const value = target.checked;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    if (!value) {
      this.setState({ filterParamsArray: [] });
    }

    commonSendEventFunction(RP_EVENT_EDIT_OBJECT, {
      stepId: this.props.currentStep.stepId,
      filterData: value ? this.state.filterParamsArray : [],
    });
  }

  onParamsInputChange = (e, rowIndex) => {
    const nextState = produce(this.state.filterParamsArray, (draft) => {
      draft[rowIndex][e.target.name] = e.target.value;
      if (draft[rowIndex].isFocusKey) {
        draft[rowIndex].isFocusKey = false;
      }
    });

    this.setState({
      filterParamsArray: nextState,
    });
    this.props.onSetNewCurrentStepFilterParams(nextState);
    commonSendEventFunction(RP_EVENT_EDIT_OBJECT, {
      stepId: this.props.currentStep.stepId,
      label: this.props.currentStep.object.label,
      filterData: nextState,
    });
  };

  onFilterParametersPasted = (newFilters) => {
    this.setState({ filterParamsArray: newFilters });
    this.props.onSetNewCurrentStepFilterParams(newFilters);
    commonSendEventFunction(RP_EVENT_EDIT_OBJECT, {
      stepId: this.props.currentStep.stepId,
      label: this.props.currentStep.object.label,
      filterData: newFilters,
    });
  };

  deleteParamRow = (rowIndex) => {
    const newState = this.state.filterParamsArray;
    const filtered = newState.filter((el, i) => {
      return i !== rowIndex;
    });
    this.setState(
      {
        filterParamsArray: filtered,
      },
      () => {
        const event = new CustomEvent(RP_EVENT_EDIT_OBJECT, {
          detail: {
            stepId: this.props.currentStep.stepId,
            label: this.props.currentStep.object.label,
            filterData: this.state.filterParamsArray,
          },
        });
        document.dispatchEvent(event);
        this.props.onSetNewCurrentStepFilterParams(this.state.filterParamsArray);
      },
    );
  };

  addNewCustomParameter = () => {
    const newFilterParamsArray = this.state.filterParamsArray.concat({
      operator: DEFAULT_OPERATOR,
      key: '',
      value: '',
      isFocusKey: true,
      contains: 'true', // pages default value
    });

    this.setState({
      filterParamsArray: newFilterParamsArray,
    });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.currentStep.object.filterData !== this.props.currentStep.object.filterData) {
      const filterData = this.props.currentStep.object.filterData;
      this.setState({ filterParamsArray: filterData, isShowUrlParams: filterData.length > 0 });
    }
  };

  render() {
    const { sectionStyle: { width: upperElWidth, height: upperElHeight } = {} } = this.props;
    const addParamsSelect = (contains, i) => {
      if (this.props.iconType !== typeActions) {
        return (
          <div className={styles.FilterItemParameter}>
            <label>Type</label>
            <select
              defaultValue={contains}
              name="contains"
              onChange={(e) => this.onParamsInputChange(e, i)}
              disabled={this.props.canvasPermissions.isReadonlyAccess}
            >
              <option value="true">Contains</option>
              <option value="false">Does Not Contain</option>
            </select>
          </div>
        );
      }
    };

    // eslint-disable-next-line
    const urlParamsRows = this.state.filterParamsArray.map((filterItem, i) => {
      if (this.state.isShowUrlParams || this.props.iconType === typeSource) {
        return (
          <div key={i} className={styles.FilterRow}>
            {addParamsSelect(filterItem.contains, i)}
            <div className={styles.FilterItemParameter}>
              <label>Key</label>
              <input
                autoFocus={filterItem.isFocusKey}
                name="key"
                type="text"
                value={filterItem.key}
                onChange={(e) => this.onParamsInputChange(e, i)}
                disabled={this.props.canvasPermissions.isReadonlyAccess}
              />
            </div>
            <div className={styles.FilterItemParameter}>
              <label>Value</label>
              <input
                name="value"
                type="text"
                value={filterItem.value}
                onChange={(e) => this.onParamsInputChange(e, i)}
                disabled={this.props.canvasPermissions.isReadonlyAccess}
              />
            </div>
            <span
              className={styles.FilterItemParameterDelete}
              onClick={() => {
                if (this.props.canvasPermissions.isReadonlyAccess) {
                  return;
                }
                this.deleteParamRow(i);
              }}
            >
              {iconDelete}
            </span>
          </div>
        );
      }
    });

    return (
      <ViewportConstrainer
        relativeRectangle={this.props.sectionStyle}
        verticalAlign={VERTICAL_ALIGN_CENTER}
      >
        <StepToolbar
          iconType={this.props.iconType}
          isShowUrlParams={this.state.isShowUrlParams}
          handleInputChangeUrlParams={this.handleInputChangeUrlParams}
          onAddCustomParameter={this.addNewCustomParameter}
          urlParamsRows={urlParamsRows}
          handleFilterPageByUrlChange={this.handleFilterPageByUrlChange}
          currentStep={this.props.currentStep}
          projectApiKey={this.props.projectApiKey}
          projectId={this.props.funnel.projectId}
          funnelConfiguration={this.props.funnelConfiguration}
          isNumbersActive={this.props.isNumbersActive}
          onFilterParametersPasted={this.onFilterParametersPasted}
          selectedTab={this.props.selectedTab}
          setSelectedTab={this.props.setSelectedTab}
          activeMenuRef={this.props.activeMenuRef}
        />
      </ViewportConstrainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentStep: selectCurrentStep(state),
    funnelConfiguration: selectFunnelConfiguration(state),
    projectApiKey: selectApiKey(state),
    canvasPermissions: selectCanvasPermissions(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSetNewCurrentStepFilterParams: (newValue) =>
      dispatch(setNewCurrentStepFilterParams(newValue)),
    onSetNewCurrentStepTrackingUrl: (newValue) => dispatch(setNewCurrentStepTrackingUrl(newValue)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopToolbar);
