import React from 'react';
import styles from './InfoPopup.module.scss';
import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';
import { iconInfo, iconPopupClose } from 'react-project/assets/Icons';
import { getUserId } from 'react-project/Util/AuthCookie';

export const InfoPopup = ({ message, onClose }) => {
  const userId = getUserId();
  const isLoggedIn = !!userId;

  const closePopup = () => {
    onClose();
  };

  const onBtnClicked = () => {
    onClose();

    window.location.href = isLoggedIn
      ? process.env.REACT_APP_CURRENT_APP_URL
      : `${process.env.REACT_APP_CURRENT_APP_URL}/register?next=performance-business-trial`;
  };

  const btnText = isLoggedIn ? 'Get back to your account' : 'Start your Funnelytics Trial';

  return (
    <ClickOutsideCustomComponent
      onClickOutside={() => {
        closePopup();
      }}
    >
      <div className={styles.InfoPopup}>
        <h1 className={styles.Header}>
          <div className={styles.LeftWrapper}>
            <div className={styles.Icon}>{iconInfo}</div>
            <span className={styles.Title}>Info</span>
          </div>
          <div onClick={closePopup} className={styles.closeBtn}>
            {iconPopupClose}
          </div>
        </h1>
        <div className={styles.Content}>
          <div className={styles.TextContainer}>
            {message.split('\n').map((str, i) => (
              <p key={i} className={styles.Description}>
                {str}
              </p>
            ))}
          </div>

          <div onClick={onBtnClicked} className={styles.Btn}>
            {btnText}
          </div>
        </div>
      </div>
    </ClickOutsideCustomComponent>
  );
};
