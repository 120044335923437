import { PR_EVENT_FUNNEL_CHANGED } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';

export default class CommandAddBreakPoint {
  constructor(object, parent, objectsArray, index, connectionDisplay) {
    this.object = object;
    this.parent = parent;
    this.objects = objectsArray;
    this.index = index;
    this.isExecuted = false;
    this.connectionDisplay = connectionDisplay;
  }

  execute() {
    if (!this.isExecuted) {
      this.isExecuted = true;
      window.app.needsRendering();

      this.parent.addChild(this.object);
      this.objects.splice(this.index, 0, this.object);
      this.parent.onBreakPointsUpdated();
      this.parent.update();

      this.object.connectionDisplay = this.connectionDisplay;
      if (this.connectionDisplay) {
        this.connectionDisplay.breakPoint = this.object;
      }

      this.parent.onBreakPointsPostUpdate();
      this.parent.updateFlowPoints();

      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
    }
  }

  revert() {
    if (this.isExecuted) {
      this.isExecuted = false;
      window.app.needsRendering();

      this.object.connectionDisplay = null;
      if (this.connectionDisplay) {
        this.connectionDisplay.breakPoint = null;
      }

      this.object.removeFromParent();
      this.objects.removeElement(this.object);
      this.parent.onBreakPointsUpdated();
      this.parent.update();

      this.parent.onBreakPointsPostUpdate();
      this.parent.updateFlowPoints();

      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
    }
  }
}
