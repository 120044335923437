import { PR_EVENT_FUNNEL_CHANGED } from 'shared/CSharedEvents';
import { cloneData, commonSendEventFunction } from 'shared/CSharedMethods';

export default class CommandStyleReportSlide {
  constructor(slide, style) {
    this.oldStyle = cloneData(slide.style);
    this.slide = slide;
    this.style = style;
    this.isExecuted = false;
  }

  execute() {
    if (!this.isExecuted) {
      this.isExecuted = true;
      this.slide.setStyle(this.style);

      app.needsRendering();
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
    }
  }

  revert() {
    if (this.isExecuted) {
      this.isExecuted = false;
      this.slide.setStyle(this.oldStyle);

      app.needsRendering();
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
    }
  }
}
