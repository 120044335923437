import cls from 'classnames';
import produce from 'immer';
import MainStorage from 'pixi-project/core/MainStorage';
import React, { useEffect, useState } from 'react';
import { FileUploader } from 'react-project/components/fileUploader/FileUploader';
import { Pinned } from 'react-project/components/pinned/Pinned';
import { ModalWrapper } from 'react-project/components/popUp/ModalWrapper';
import { RefreshButton } from 'react-project/components/refreshButton/RefreshButton';
import { Tooltip } from 'react-project/components/tooltip/Tooltip';
import { ViewportConstrainer } from 'react-project/components/viewportConstrainer/ViewportConstrainer';
import { HEAP_EVENTS } from 'react-project/Constants/heapEvents';
import { TEXTS_TOOLTIP } from 'react-project/Constants/texts';
import {
  selectAnalyticsStatus,
  selectHasAnalyticsData,
} from 'react-project/redux/analytics/selectors';
import { selectCurrentStep } from 'react-project/redux/current-step/selectors';
import { setCustomIconsAction } from 'react-project/redux/custom-icons/actions';
import { selectCustomIcons } from 'react-project/redux/custom-icons/selectors';
import {
  onSetExplorerItemsConfig,
  onSetExplorerItemsConfigLoadMore,
  onSetExplorerPageNumber,
  setExplorerItemsConfig,
  setExplorerLoadingStatus,
} from 'react-project/redux/explorer/actions';
import {
  selectExplorerIsLoading,
  selectExplorerItemsConfig,
  selectExplorerPageNumber,
} from 'react-project/redux/explorer/selectors';
import { selectFocusedSteps } from 'react-project/redux/focused-step/selectors';
import { setIsStepModalPinned } from 'react-project/redux/modal/actions';
import { selectIsStepModalPinned, selectStepModalData } from 'react-project/redux/modal/selectors';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import MainTab from 'react-project/StepsModal/components/MainTab';
import styles from 'react-project/StepsModal/StepsModal.module.scss';
import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';
import { sendHeapTracking } from 'react-project/Util/HEAP.utilities';
import { track as trackCohesive } from 'react-project/Util/CohesiveTracking';
import {
  buildGenericTrafficStep,
  buildTrafficStepWithAttributes,
} from 'react-project/Util/StepBuilder';
import { When } from 'react-project/Util/When';
import { useDispatch, useSelector } from 'react-redux';
import {
  EElementCategories,
  EElementTypes,
  EExplorerConfigTypes,
  EStepConnectionPort,
} from 'shared/CSharedCategories';
import {
  ActionTypes,
  ANALYTICS_STATUS_LOADING,
  DEFAULT_ITEMS_CONFIG,
  EXPLORER_ITEMS,
  EXPLORER_TABS,
  EXPLORER_TAB_TO_ITEMS,
  IntegrationTypes,
  MAIN_TABS,
  MAP_TABS,
  MODAL_SOURCE_TOOLBAR,
} from 'shared/CSharedConstants';
import {
  PR_EVENT_CONNECTION_IN_EMPTY_SPACE,
  PR_EVENT_REMOVE_POINTER_JOINT,
  RP_EVENT_CREATE_OBJECT,
} from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import SharedElementHelpers from 'shared/SharedElementHelpers';
import { setNewCurrentStepLabel } from '../redux/current-step/actions';
import { selectFunnelConfiguration } from '../redux/funnel-configuration/selectors';
import axios from 'axios';
import { LOCK_NO_ANALYTICS, UILock } from 'react-project/components/uilock/UILock';

const MODAL_SIZE = {
  width: 400,
  height: 452,
};

const OFFSET_X = 8;
const CONNECTION_OFFSET = 165;
const UNDER_HEADER_OFFSET = 30;
const EXPIRATION = 1000 * 60 * 10;

let axiosSource = null;

export const StepsModal = ({
  modalSource,
  hasWorkspaceActions,
  funnel,
  onStepsModalTabChanged,
  onCloseModal,
  isParentHeader,
  createObjectP,
  coords,
  port,
  activeStepsModalTab,
  canExplore,
  explorerDropdownActiveItem,
  setExplorerDropdownActiveItem,
}) => {
  const dispatch = useDispatch();

  coords = coords ? { x: coords.x, y: coords.y } : { x: 0, y: 0 };
  port = port || '';

  const [portState, setPortState] = useState(port);
  const currentStep = useSelector(selectCurrentStep);

  const isExploreTrafficEnabled = () => {
    const port = portState;
    if (!port || !currentStep || SharedElementHelpers.IsMisc(currentStep.object)) {
      return true;
    }

    return port !== EStepConnectionPort.OUT;
  };

  // If there never was an interaction with the modal (i.e the user never clicked on the tabs)
  // then the default tab will always be on MAP
  // Otherwise, it will be on the tab that was last clicked
  let activeTab = activeStepsModalTab || MAIN_TABS.MAP;

  // If the modal is loaded in explorer mode , but can't explore anything,
  // (i.e user is multiselecting elements)
  // the set modal to be on MAP tab
  activeTab = !canExplore ? MAIN_TABS.MAP : activeTab;

  // Check if sources are aviailable
  let initialActiveExplorerTab = explorerDropdownActiveItem.type || EXPLORER_TABS.PAGE;
  const isTrafficEnabled = isExploreTrafficEnabled();

  if (explorerDropdownActiveItem.type === EXPLORER_TABS.SOURCE && !isTrafficEnabled) {
    initialActiveExplorerTab = EXPLORER_TABS.PAGE;
    explorerDropdownActiveItem.type = EXPLORER_TABS.PAGE;
  }

  const [activeExplorerTab, setActiveExplorerTab] = useState(initialActiveExplorerTab);
  const [activeMainTab, setActiveMainTab] = useState(activeTab);
  const [activeMapTab, setActiveMapTab] = useState(MAP_TABS.PAGE);
  const [containersCreated, setContainersCreated] = useState(0);
  const [createObjectPositions, setCreateObjectPositions] = useState(createObjectP);
  const [isDisabledMapTab, setIsDisabledMapTab] = useState(false);

  const [propertySearchQuery, setPropertySearchQuery] = useState('');
  const [sourceDropdown, setSourceDropdown] = useState(EXPLORER_ITEMS.TRAFFIC_ITEMS[0]);
  const [textSearch, setTextSearch] = useState('');
  const [isUploaderOpened, setIsUploaderOpened] = useState(false);
  const [uploaderData, setUploaderData] = useState(null);

  const funnelConfiguration = useSelector(selectFunnelConfiguration);
  const explorerIsLoading = useSelector(selectExplorerIsLoading);
  const explorerItemsConfig = useSelector(selectExplorerItemsConfig);
  const explorerPageNumber = useSelector(selectExplorerPageNumber);
  const analyticsStatus = useSelector(selectAnalyticsStatus);
  const focusedSteps = useSelector(selectFocusedSteps);
  const canvasPermissions = useSelector(selectCanvasPermissions);
  const isStepModalPinned = useSelector(selectIsStepModalPinned);
  const stepModalData = useSelector(selectStepModalData);
  const customIcons = useSelector(selectCustomIcons);
  const hasAnalyticsData = useSelector(selectHasAnalyticsData);

  const textSearchInput = React.createRef();
  const category = EElementCategories.STEP;

  const isFirstTimeOpening = activeStepsModalTab === null;
  const isLoadingPageParameters = false;
  const position = coords;

  const getCustomIcons = (type) => {
    const iconType = type.toLowerCase();
    return customIcons
      .filter((item) => item.type === iconType)
      .sort((a, b) => {
        if (a.created_at === b.created_at) {
          return 0;
        }
        return a.created_at > b.created_at ? 1 : -1;
      });
  };

  const onDragend = (clientX, clientY, step) => {
    const {
      src,
      title,
      type,
      url,
      filterData,
      hits,
      actionType,
      actionName,
      integrationType,
      isCustom = false,
      trackingURL,
    } = step;

    dispatch(setNewCurrentStepLabel(title));

    const objectData = {
      type,
      src,
      label: title,
      category: category,
      actionType: actionType || ActionTypes.NONE,
      integrationType: integrationType || IntegrationTypes.NONE,
      isCustom,
    };

    // Fix the type for events
    if (type.toString().endsWith('_ALL')) {
      objectData.type = type.substring(0, type.length - 4);
    }

    if (url) {
      objectData.url = url;
    }

    if (actionName) {
      objectData.actionName = actionName;
    }

    if (filterData) {
      objectData.filterData = filterData;
    }

    if (trackingURL) {
      objectData.trackingURL = trackingURL;
    }

    if (typeof hits !== 'undefined') {
      objectData.value = hits;
    }

    if (
      activeExplorerTab === EXPLORER_TABS.SOURCE &&
      sourceDropdown.type === EExplorerConfigTypes.SOURCE_ALL
    ) {
      objectData.lineType = EExplorerConfigTypes.SOURCE_ALL;
      objectData.trackingURL = ''; // no tracking url in this case
    }

    const explorerType = explorerDropdownActiveItem ? explorerDropdownActiveItem.type : type;
    const stepData = {
      mappingDirection: explorerDropdownActiveItem?.name || 'New Step',
      stepMapped: objectData.type,
    };
    const event = `${activeMainTab}_${objectData.category}`;

    sendHeapTracking({
      projectId: funnel.projectId,
      eventName: event.toLowerCase(),
      eventData: stepData,
    });

    trackCohesive(event.toLowerCase(), {
      projectId: funnel.projectId,
      ...stepData,
    });

    if (!portState) {
      const iterClientX = clientX + containersCreated * 5;
      const iterClientY = clientY + containersCreated * 5;
      commonSendEventFunction(RP_EVENT_CREATE_OBJECT, {
        position: { x: iterClientX, y: iterClientY },
        object: objectData,
        explorerType,
        isPinned: isStepModalPinned,
      });
    } else {
      const iterClientX = createObjectPositions.x + containersCreated * 5;
      const iterClientY = createObjectPositions.y + containersCreated * 5;
      commonSendEventFunction(RP_EVENT_CREATE_OBJECT, {
        position: { x: iterClientX, y: iterClientY },
        sourceId: currentStep.stepId,
        port: portState,
        object: objectData,
        explorerType,
        isPinned: isStepModalPinned,
      });
    }

    setContainersCreated(containersCreated + 1);

    !isStepModalPinned && onCloseModal();
  };

  const focusTextSearch = () => {
    textSearchInput.current.focus();
  };

  const onPropertySearchQuery = (value) => {
    setPropertySearchQuery(value);
  };

  const onPropertySearchSend = (value) => {
    if (value.length < 3 && value.length > 0) {
      return;
    } else {
      const props = {
        funnelConfiguration: funnelConfiguration,
        currentStep: currentStep,
        port: portState,
        mounted: true,
        onLoadMore: false,
        explorerItemsConfig: explorerItemsConfig,
        funnel: funnel,
        pageNumber: 1,
        focusedSteps: focusedSteps,
        search: value,
      };
      dispatch(onSetExplorerItemsConfig(props));
    }
  };

  const activateTab = (type) => {
    // MAP subtab changed
    setActiveMapTab(type);
    setIsDisabledMapTab(false);
    focusTextSearch();
  };

  const hasNoAnalytics = () => {
    return !canvasPermissions.isAnalyticsAllowed;
  };

  const onSetActiveExplorerTab = (type) => {
    let explorerDropdownActiveItem;
    const explorerTabItem = EXPLORER_TAB_TO_ITEMS[currentStep.object.type];

    if (portState && explorerTabItem && explorerTabItem[type]) {
      explorerDropdownActiveItem = explorerTabItem[type][portState];
    } else {
      explorerDropdownActiveItem = explorerDropdownActiveItem;
    }

    setActiveExplorerTab(type);
    setExplorerDropdownActiveItem(explorerDropdownActiveItem);
  };

  const activateMainTab = (type) => {
    if (activeMainTab === MAIN_TABS.MAP && type === MAIN_TABS.EXPLORER) {
      // Switching to the explorer tab
      if (hasAnalyticsData && !hasNoAnalytics() && !MainStorage.isNumbersVisible()) {
        const eventData = {
          screen_size: window.innerWidth + 'x' + window.innerHeight,
          sceen_px_count: window.innerWidth * window.innerHeight,
        };
        //Heap data send
        sendHeapTracking({
          projectId: funnel.projectId,
          eventName: HEAP_EVENTS.OPEN_CANVAS,
          eventData,
        });
        trackCohesive(HEAP_EVENTS.OPEN_CANVAS, {
          projectId: funnel.projectId,
          ...eventData,
        });
      } else {
        //TODO the case when we try to open traffic explorer without analytics data
      }
    }

    setActiveMainTab(type);
    onStepsModalTabChanged(type);
  };

  const onRefreshButtonClicked = () => {
    getExplorerData(true);
  };

  const getExplorerData = (disableCache = false) => {
    if (
      funnel.isInUnorganizedFolder ||
      !canExplore ||
      canvasPermissions.isAnalyticsAllowed === false
    ) {
      return;
    }

    // set the axios source to be able to cancel the request
    axiosSource = axios.CancelToken.source();

    const props = {
      funnelConfiguration: funnelConfiguration,
      currentStep: currentStep,
      port: portState,
      mounted: true,
      onLoadMore: false,
      explorerItemsConfig: explorerItemsConfig,
      funnel: funnel,
      pageNumber: explorerPageNumber || 1,
      focusedSteps: focusedSteps,
      traffic: sourceDropdown,
      axiosSource: axiosSource,
    };

    if (propertySearchQuery.length > 3) {
      props.search = propertySearchQuery;
    }

    dispatch(onSetExplorerItemsConfig(props, disableCache));
    dispatch(setExplorerLoadingStatus(true));
  };

  const onLoadMore = (type) => {
    if (type === EXPLORER_TABS.SOURCE || explorerIsLoading || funnel.isInUnorganizedFolder) {
      return;
    }

    // TODO: Rework this, should depend on the current explorer tab and the selected item in the dropdown
    if (
      explorerItemsConfig.has_more_actions ||
      explorerItemsConfig.has_more_pages ||
      explorerItemsConfig.has_more_actions_all ||
      explorerItemsConfig.has_more_pages_all
    ) {
      //TODO a refactor is needed to make analyticsStatus
      // and explorerIsLoading clear
      // right now they seem to be a little tangled
      // Its one thing to have the analytics fetching data
      // and another one to have the traffic explorer fetching data
      dispatch(setExplorerLoadingStatus(true));
      let pageNumber = explorerPageNumber || 1;
      dispatch(onSetExplorerPageNumber(++pageNumber));
    }
  };

  const onAddIconClick = (data) => {
    setIsUploaderOpened(true);
    setUploaderData(data);
  };

  const onSetTextSearch = (value) => {
    setIsDisabledMapTab(!!value);
    setTextSearch(value);
  };

  const onSearchChange = (e) => {
    onSetTextSearch(e.target.value);
  };

  const onFileUploaderClose = (e) => {
    setIsUploaderOpened(false);
    setUploaderData(null);
  };

  const onFileUploaded = (fileID, fileName, imageURL, uploadType) => {
    if (imageURL) {
      const imageData = {
        id: fileID,
        name: fileName,
        path: imageURL,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        type: uploadType.toString().toLowerCase(),
      };

      const newItems = produce(customIcons, (draft) => {
        draft.push(imageData);
      });
      setCustomIcons(newItems);
    }

    setIsUploaderOpened(false);
    setUploaderData(null);
  };

  const setCustomIcons = (icons) => {
    dispatch(setCustomIconsAction(icons));
  };

  const eventConnectionHandler = (e) => {
    e.preventDefault();

    setCreateObjectPositions({
      x: e.detail.position.x,
      y: e.detail.position.y,
    });

    if (e.detail.port) {
      setPortState(e.detail.port);
    }

    onSetActiveExplorerTab(EXPLORER_TABS.PAGE);
  };

  const onRemovePointerJoint = (e) => {
    // Do nothing , do not reset the portState
  };

  const checkForParentWithId = (element, id) => {
    if (!element) {
      return false;
    }

    if (element.id === id) {
      return true;
    }

    return checkForParentWithId(element.parentNode, id);
  };

  const handleClickOutside = (e) => {
    if (
      stepModalData.modalSource === MODAL_SOURCE_TOOLBAR &&
      checkForParentWithId(e.target, 'toolbar-add-step-button')
    ) {
      return false;
    }

    if (e.target && e.target.dataset && e.target.dataset.ignoreClickOutside) {
      return;
    }

    if (isUploaderOpened) {
      return;
    }

    if (!isStepModalPinned) {
      onCloseModal();
    }
  };

  useEffect(() => {
    document.addEventListener(PR_EVENT_CONNECTION_IN_EMPTY_SPACE, eventConnectionHandler, false);
    document.addEventListener(PR_EVENT_REMOVE_POINTER_JOINT, onRemovePointerJoint, false);

    dispatch(setExplorerItemsConfig(DEFAULT_ITEMS_CONFIG));

    if (isFirstTimeOpening && MainStorage.isNumbersVisible() && !hasNoAnalytics()) {
      if (hasWorkspaceActions && canExplore) {
        activateMainTab(MAIN_TABS.EXPLORER);
      }
    }

    // it helps to set the initial state of the drop down regarding
    // previous/next pages
    onSetActiveExplorerTab(EXPLORER_TABS.PAGE);

    return () => {
      if (axiosSource) {
        axiosSource.cancel();
      }

      document.removeEventListener(PR_EVENT_CONNECTION_IN_EMPTY_SPACE, eventConnectionHandler);
      document.removeEventListener(PR_EVENT_REMOVE_POINTER_JOINT, onRemovePointerJoint);

      if (funnel.isInUnorganizedFolder) {
        return;
      }
      dispatch(onSetExplorerPageNumber(1));
      dispatch(onSetExplorerItemsConfigLoadMore(DEFAULT_ITEMS_CONFIG));
    };
  }, []);

  useEffect(() => {
    setPortState(port);
  }, [port]);

  useEffect(() => {
    getExplorerData();
  }, [explorerPageNumber, sourceDropdown, explorerDropdownActiveItem, portState, currentStep]);

  const isInUnorganizedFolder = funnel.isInUnorganizedFolder;
  const onIconClick = (step) => {
    if (!!portState) {
      onDragend(position.x + OFFSET_X, position.y + CONNECTION_OFFSET, step);
    } else {
      // Clicking on an item from the list
      const isToolbarAndAnalytics =
        MainStorage.isNumbersVisible() && modalSource === MODAL_SOURCE_TOOLBAR;

      const panelXOfset = isToolbarAndAnalytics ? 120 : 0;
      const panelYOff = isToolbarAndAnalytics ? 20 : 0;
      const positionYOffset = position.y + UNDER_HEADER_OFFSET;

      const positionY = isParentHeader ? positionYOffset : position.y;
      onDragend(position.x + panelXOfset, positionY + panelYOff, step);
    }
  };

  const trafficAttributeClick = (attributeKey, attributeValue, hits) => {
    const trafficStep = buildTrafficStepWithAttributes(attributeKey, attributeValue, hits);
    onIconClick(trafficStep);
  };

  const directTrafficClicked = (hits) => {
    const step = buildGenericTrafficStep('Direct Traffic', IntegrationTypes.DIRECT_TRAFFIC, hits);
    step.trackingURL = currentStep.object.url;
    onIconClick(step);
  };

  const onSelectDropdownExplorerItem = (item) => {
    setExplorerDropdownActiveItem(item);
  };

  const onSelectDropdownTrafficItem = (item) => {
    setSourceDropdown(item);
  };

  const onSetIsStepModalPinned = (isPinned) => {
    dispatch(setIsStepModalPinned(isPinned));
  };

  return (
    <ClickOutsideCustomComponent onClickOutside={handleClickOutside}>
      <ViewportConstrainer>
        <ModalWrapper
          minWidth={MODAL_SIZE.width}
          minHeight={MODAL_SIZE.height}
          position={position}
          dragHandleClassName={styles.PageIconActions}
        >
          <div className={cls(styles.Modal, styles.OverrideForPopupWrapper)}>
            <div className={cls(styles.OverrideWrapperTab, styles.PageIconsTabs)}>
              <button
                className={cls(styles.PageIconTab, {
                  [styles.Active]: activeMainTab === MAIN_TABS.MAP,
                })}
                onClick={() => activateMainTab(MAIN_TABS.MAP)}
              >
                Map Icons
              </button>

              <When condition={canExplore}>
                <UILock lock={LOCK_NO_ANALYTICS}>
                  <button
                    className={cls(styles.PageIconTab, {
                      [styles.Active]: activeMainTab === MAIN_TABS.EXPLORER,
                    })}
                    onClick={() => {
                      activateMainTab(MAIN_TABS.EXPLORER);
                    }}
                  >
                    Traffic Explorer
                  </button>
                </UILock>
              </When>

              <When condition={!canExplore}>
                <Tooltip label={TEXTS_TOOLTIP.DISABLE_EXPLORER}>
                  <button className={cls(styles.PageIconTab, styles.Disabled)}>Explorer</button>
                </Tooltip>
              </When>
              <div className={styles.PageIconActions}>
                <div>
                  <When condition={activeMainTab === MAIN_TABS.EXPLORER}>
                    <RefreshButton
                      analyticsStatus={null}
                      loading={analyticsStatus === ANALYTICS_STATUS_LOADING || explorerIsLoading}
                      onClick={onRefreshButtonClicked}
                      expire={EXPIRATION}
                    />
                  </When>
                </div>
                <div className={styles.PinnedPadding}>
                  <Pinned onClick={onSetIsStepModalPinned} isPinned={isStepModalPinned} />
                </div>
              </div>
            </div>
            <MainTab
              trafficAttributeClick={trafficAttributeClick}
              directTrafficClicked={directTrafficClicked}
              onIconClick={onIconClick}
              onSelectDropdownExplorerItem={onSelectDropdownExplorerItem}
              onSelectDropdownTrafficItem={onSelectDropdownTrafficItem}
              textSearchInputRef={textSearchInput}
              getCustomIcons={getCustomIcons}
              onSearchChange={onSearchChange}
              activeTab={activateTab}
              onDragend={onDragend}
              setActiveExplorerTab={onSetActiveExplorerTab}
              isExploreTrafficEnabled={isExploreTrafficEnabled}
              onPropertySearchQuery={onPropertySearchQuery}
              onPropertySearchSend={onPropertySearchSend}
              onLoadMore={onLoadMore}
              type={activeMainTab}
              textSearch={textSearch}
              isDisabledMapTab={isDisabledMapTab}
              activeMapTab={activeMapTab}
              activeExplorerTab={activeExplorerTab}
              propertySearchQuery={propertySearchQuery}
              port={portState}
              sourceDropdown={sourceDropdown}
              isParentHeader={isParentHeader}
              position={position}
              isLoadingPageParameters={isLoadingPageParameters}
              explorerDropdownActiveItem={explorerDropdownActiveItem}
              setCustomIcons={setCustomIcons}
              stepsModalSetState={(e) => {
                console.warn('//TODO if you see this in the console , state needs to be set here');
              }}
              isInUnorganizedFolder={isInUnorganizedFolder}
              onAddIconClick={onAddIconClick}
            />
          </div>
        </ModalWrapper>
        <When condition={isUploaderOpened}>
          <FileUploader
            uploadType={uploaderData?.type}
            onFileUploaderClose={onFileUploaderClose}
            onFileUploaded={onFileUploaded}
          ></FileUploader>
        </When>
      </ViewportConstrainer>
    </ClickOutsideCustomComponent>
  );
};
