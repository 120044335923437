import * as PIXI from 'pixi.js';
import decomp from 'poly-decomp';

export default class HitAreaShapes {
  constructor(shapes = []) {
    this.shapes = shapes;
  }

  static breakPolygon(polygon) {
    // Transform into array of subarrays that have x/y pairs [x1,y1,x2,y2] => [[x1,y1],[x2,y2]]
    const transformed = [];
    for (let i = 0; i < polygon.points.length - 1; i += 2) {
      transformed.push([polygon.points[i], polygon.points[i + 1]]);
    }

    // Reorder the coordinates to go in Counter Clockwise order
    decomp.makeCCW(transformed);
    decomp.removeDuplicatePoints(transformed, 0.01);
    // Break the polygon into convex polygons , it might end up
    // being the same polygon if it was already convex
    const convexPolygons = decomp.quickDecomp(transformed);
    // const convexPolygons = decomp.decomp(transformed);

    // Transform into PIXI.Polygons
    const finalPolygons = [];
    for (let i = 0; i < convexPolygons.length; i++) {
      const cp = convexPolygons[i];
      finalPolygons.push(new PIXI.Polygon(cp.flat()));
    }

    return new HitAreaShapes(finalPolygons);
  }

  /**
   * Called by hitArea
   * @param {number} x
   * @param {number} y
   */
  contains(x = 0, y = 0) {
    return !this.shapes || this.shapes.length === 0
      ? false
      : this.shapes.some((shape) => shape.contains(x, y));
  }

  overlapsPolygon(rectangleVertices, polygonVertices = null) {
    return !this.shapes || this.shapes.length === 0
      ? false
      : this.shapes.some((shape) => shape.overlapsPolygon(rectangleVertices, polygonVertices));
  }
}
