import * as PIXI from 'pixi.js';
import Styles, { COLOR_REPORT_SLIDE_TITLE, COLOR_REPORT_VIEW_BACKGROUNG } from '../Styles';
import Button from 'pixi-project/base/elements/Button';
import ImageSnapshot from 'pixi-project/utils/ImageSnapshot';
import { SCALE_THRESHOLD } from './ReportView';
import ToolTipBox, { TOOLTIP_LOCATION } from '../ToolTipBox';
import { cloneData, commonSendEventFunction } from 'shared/CSharedMethods';
import AppSignals from 'pixi-project/signals/AppSignals';
import {
  IMAGE_MODE_FILL,
  IMAGE_MODE_FIT_TO_VIEW,
  IMAGE_MODE_STRETCH,
} from 'shared/CSharedConstants';
import MainStorage from 'pixi-project/core/MainStorage';

export const viewSize = { WIDTH: 1920, HEIGHT: 1080 };

const TEXTURE_NAME_REMOVE = 'remove.png';
const TEXTURE_NAME_REMOVE_HOVER = 'remove-hover.png';
const TEXTURE_NAME_ADD = 'add.png';
const TEXTURE_NAME_ADD_HOVER = 'add-hover.png';
const TEXTURE_NAME_STYLE = 'brush-icon';
const TEXTURE_NAME_STYLE_HOVER = 'brush-icon-hover';

const REMOVE_BUTTON_OFFSET_X = -5;
const REPORT_SLIDE_TITLE_OFFSET_Y = -6;

export default class ReportSlide extends PIXI.Container {
  constructor(data, reportView) {
    super();

    this.objects = [];
    this.objectsOffsets = {};
    this.hasRemoveButton = (data && data.hasRemoveButton) || false;
    this.hasAddButton = true;
    this.reportView = reportView;
    this.isCustomIconFetched = false;

    this.titlePointerDown = false;

    this.title = new PIXI.BitmapText(
      data && data.label ? data.label : '',
      Styles.REPORT_SLIDE_TITLE,
    );
    this.title.tint = COLOR_REPORT_SLIDE_TITLE;
    this.title.anchor.set(0, 1);
    this.title.y = REPORT_SLIDE_TITLE_OFFSET_Y;
    this.title.interactive = true;
    this.title.pointerdown = (e) => {
      this.titlePointerDown = true;
    };
    this.title.pointerup = (e) => {
      this.titlePointerDown = false;
    };
    this.title.pointerupoutside = (e) => {
      this.titlePointerDown = false;
    };
    this.addChild(this.title);

    this.content = new PIXI.Graphics();
    this.addChild(this.content);

    this.backgroundImage = new PIXI.Sprite();
    this.backgroundImage.anchor.set(0.5, 0.5);
    this.backgroundImage.position.set(viewSize.WIDTH / 2, viewSize.HEIGHT / 2);
    this.content.addChild(this.backgroundImage);

    this.backgroundMask = new PIXI.Graphics();
    this.backgroundMask.beginFill(0xffffff);
    this.backgroundMask.drawRect(0, 0, viewSize.WIDTH, viewSize.HEIGHT);
    this.backgroundMask.endFill();

    this.backgroundImage.mask = this.backgroundMask;
    this.addChild(this.backgroundMask);

    this.addSlideButton = null;
    this.removeSlideButton = null;

    this.style = {
      color: 0xffffff,
      image: null,
      mode: 0,
    };

    this.applyStyle(this.style);
    this.createButtons();
    this.rescaleButtons();

    this.interactive = true;
    this.on('pointerdown', this.onSliderPointerDown.bind(this));
    this.on('pointerup', this.onSlideraPointerUp.bind(this));
    this.on('pointerupoutside', this.onSlideraPointerUpOutside.bind(this));
  }

  onSliderPointerDown(e) {
    if (!this.titlePointerDown) {
      this.reportView.onSliderPointerDown(e);
    }
  }

  onSlideraPointerUp(e) {
    this.reportView.onSliderPointerUp(e);
  }

  onSlideraPointerUpOutside(e) {
    this.reportView.onSliderPointerUp(e);
  }

  setTitle(title) {
    this.title.text = title;
  }

  setIsLockedButtons(status) {
    this.addSlideButton && this.addSlideButton.setDisabled(status);
    this.removeSlideButton && this.removeSlideButton.setDisabled(status);

    // Set the same kind of buttons for the default and hover states so that the user knows that the button will not work
    if (status) {
      this.addSlideButton &&
        this.addSlideButton.setTextures(TEXTURE_NAME_ADD_HOVER, TEXTURE_NAME_ADD_HOVER);
      this.removeSlideButton &&
        this.removeSlideButton.setTextures(TEXTURE_NAME_REMOVE_HOVER, TEXTURE_NAME_REMOVE_HOVER);
    } else {
      this.addSlideButton &&
        this.addSlideButton.setTextures(TEXTURE_NAME_ADD, TEXTURE_NAME_ADD_HOVER);
      this.removeSlideButton &&
        this.removeSlideButton.setTextures(TEXTURE_NAME_REMOVE, TEXTURE_NAME_REMOVE_HOVER);
    }
  }

  addObjects(objects) {
    for (let i = 0; i < objects.length; i++) {
      this.addObject(objects[i]);
    }
  }

  addObject(object) {
    object.isInsideReport = true;
    this.objects.push(object);
    const data = {
      position: object.position.clone(),
      offset: new PIXI.Point(),
      slideStartPosition: this.position.clone(),
    };
    this.objectsOffsets[object.id] = data;
  }

  getSlideObjects() {
    return this.objects;
  }

  isEmpty() {
    return !this.objects.length;
  }

  removeObjects(objects) {
    for (let i = objects.length - 1; i >= 0; i--) {
      let object = objects[i];
      this.removeObject(object);
    }
  }

  removeObject(object) {
    this.objects.removeElement(object);
    object.isInsideReport = false;
    delete this.objectsOffsets[object.id];
  }

  initializeFromData(data) {
    // in case we have data
  }

  getData() {
    let ids = [];

    for (let i = 0; i < this.objects.length; i++) {
      const o = this.objects[i];
      ids.push(o.id);
    }

    return ids;
  }

  showAddButton() {
    this.addSlideButton.visible = true;
  }

  hideAddButton() {
    this.addSlideButton.visible = false;
  }

  showRemoveButton() {
    this.removeSlideButton.visible = true;
  }

  hideRemoveButton() {
    this.removeSlideButton.visible = false;
  }

  createButtons() {
    if (this.hasAddButton) {
      this.addSlideButton = new Button(TEXTURE_NAME_ADD, TEXTURE_NAME_ADD_HOVER, () =>
        this.reportView.onAddSlideButtonClicked(this),
      );
      this.addSlideButton.content.anchor.set(0, 1);
      this.addChild(this.addSlideButton);

      new ToolTipBox(this.addSlideButton, 'Add slide', {
        location: [TOOLTIP_LOCATION.bottom, TOOLTIP_LOCATION.right],
        removeTarget: this,
      });

      if (MainStorage.getCanvasPermissions().isReadonlyAccess) {
        this.addSlideButton.removeFromParent();
      }
    }

    if (this.hasRemoveButton) {
      this.removeSlideButton = new Button(TEXTURE_NAME_REMOVE, TEXTURE_NAME_REMOVE_HOVER, () =>
        this.reportView.onRemoveSlideButtonClicked(this),
      );
      this.removeSlideButton.content.anchor.set(0, 1);
      this.addChild(this.removeSlideButton);
      new ToolTipBox(this.removeSlideButton, 'Remove slide', {
        location: [TOOLTIP_LOCATION.bottom, TOOLTIP_LOCATION.left],
        removeTarget: this,
      });

      if (MainStorage.getCanvasPermissions().isReadonlyAccess) {
        this.removeSlideButton.removeFromParent();
      }
    }

    this.styleButton = new Button(TEXTURE_NAME_STYLE, TEXTURE_NAME_STYLE_HOVER, () =>
      this.reportView.onStyleSlideButtonClicked(this),
    );
    this.styleButton.content.anchor.set(0, 1);
    this.addChild(this.styleButton);
    new ToolTipBox(this.styleButton, 'Style slide', {
      location: [TOOLTIP_LOCATION.bottom, TOOLTIP_LOCATION.left],
      removeTarget: this,
    });

    if (MainStorage.getCanvasPermissions().isReadonlyAccess) {
      this.styleButton.removeFromParent();
    }

    this.rescaleButtons();
    this.positionButtons();
  }

  getButtonPositions() {
    const positions = {};

    if (this.addSlideButton.visible && this.removeSlideButton && this.removeSlideButton.visible) {
      positions.add = { x: viewSize.WIDTH, y: viewSize.HEIGHT };
      positions.remove = {
        x: viewSize.WIDTH,
        y: viewSize.HEIGHT - this.addSlideButton.height + REMOVE_BUTTON_OFFSET_X,
      };
      positions.style = {
        x: viewSize.WIDTH,
        y:
          viewSize.HEIGHT -
          this.addSlideButton.height -
          this.removeSlideButton.height +
          REMOVE_BUTTON_OFFSET_X * 2,
      };
    } else if (this.addSlideButton.visible) {
      positions.add = { x: viewSize.WIDTH, y: viewSize.HEIGHT };
      positions.style = {
        x: viewSize.WIDTH,
        y: viewSize.HEIGHT - this.addSlideButton.height + REMOVE_BUTTON_OFFSET_X,
      };
    } else if (this.removeSlideButton) {
      positions.remove = { x: viewSize.WIDTH, y: viewSize.HEIGHT };
      positions.style = {
        x: viewSize.WIDTH,
        y: viewSize.HEIGHT - this.removeSlideButton.height + REMOVE_BUTTON_OFFSET_X,
      };
    }

    return positions;
  }

  positionButtons() {
    const positions = this.getButtonPositions();

    if (positions.add) {
      this.addSlideButton.position = positions.add;
    }

    if (positions.remove) {
      this.removeSlideButton.position = positions.remove;
    }

    this.styleButton.position = positions.style;
  }

  onZoomChanged(scale) {
    scale = scale === undefined ? window.app.viewport.scaled : scale;
    this.rescaleButtons(scale);
    this.positionButtons();
  }

  rescaleButtons(scale) {
    scale = scale === undefined ? window.app.viewport.scaled : scale;
    const toScale = scale > SCALE_THRESHOLD ? 1 / scale : 1 / SCALE_THRESHOLD;

    this.addSlideButton.scale.set(toScale);
    this.removeSlideButton.scale.set(toScale);
    this.styleButton.scale.set(toScale);
  }

  getCanvasPromise(viewport) {
    // Prepare the viewport by focusing the slide to be in 0,0 cooridnates
    // so that we can take a perfect shot of it
    const scale = viewport.scaled;
    viewport.scaled = 1;
    const bounds = this.content.getBounds();
    viewport.x -= bounds.x;
    viewport.y -= bounds.y;

    // Create
    const snapshot = new ImageSnapshot(window.app.renderer, bounds.width, bounds.height);
    const canvasPromise = snapshot.getCanvas(viewport);

    // Restore the viewport to its previous state
    viewport.x += bounds.x;
    viewport.y += bounds.y;
    viewport.scaled = scale;

    return canvasPromise;
  }

  shiftContent() {
    for (let i = 0; i < this.objects.length; i++) {
      const object = this.objects[i];
      let data = this.objectsOffsets[object.id];
      let sdy = data.slideStartPosition.y - this.y;
      object.y = data.position.y - sdy;
      object.move();
    }
  }

  containtsObject(object) {
    for (let i = 0; i < this.objects.length; i++) {
      if (object === this.objects[i]) {
        return true;
      }
    }

    return false;
  }

  setStyle(style) {
    if (this.style.image !== style.image && style.image) {
      this.isCustomIconFetched = false;
    }
    this.style = cloneData(style);
    this.applyStyle(this.style);
  }

  applyStyle(style) {
    if (style.image) {
      AppSignals.fetchCustomIcons.dispatch(this);
      this.applyImageMode();
    } else {
      this.backgroundImage.visible = false;
    }

    this.content.clear();
    this.content.beginFill(style.color);
    this.content.drawRect(0, 0, viewSize.WIDTH, viewSize.HEIGHT);
    this.content.endFill();
  }

  onCustomIconsLoaded(customIcons) {
    // extract the id of the image
    let id = null;
    let iconData = null;
    try {
      const id = this.style.image.split('?')[0].split('/').pop();
      iconData = customIcons.find((item) => item.id === id);
    } catch (error) {
      iconData = { path: this.style.image };
    }

    if (iconData && iconData.path) {
      if (!this.isCustomIconFetched) {
        this.style.image = iconData.path;
        this.isCustomIconFetched = true;

        // load the latest image
        PIXI.Texture.fromURL(this.style.image)
          .then(this.onBackgroundTextureLoaded.bind(this))
          .catch((result) => {
            console.error(`Error loading with message ${result}`);
          });
      }
    } else {
      console.warn('Image is not found', iconData);
    }
  }

  onBackgroundTextureLoaded(texture) {
    this.backgroundImage.visible = true;
    this.backgroundImage.texture = texture;
    app.needsRendering();
    this.applyImageMode();
  }

  applyImageMode() {
    if (this.style.mode === IMAGE_MODE_FIT_TO_VIEW) {
      this.backgroundImage.fitTo(viewSize.WIDTH, viewSize.HEIGHT);
    } else if (this.style.mode === IMAGE_MODE_FILL) {
      this.backgroundImage.fillOut(viewSize.WIDTH, viewSize.HEIGHT);
    } else if (this.style.mode === IMAGE_MODE_STRETCH) {
      this.backgroundImage.width = viewSize.WIDTH;
      this.backgroundImage.height = viewSize.HEIGHT;
    } else {
      this.backgroundImage.scale.set(1);
    }
  }
}
