import cls from 'classnames';
import MainStorage from 'pixi-project/core/MainStorage';
import React, { useState } from 'react';
import { Button } from 'react-project/components/button/Button';
import { CopyToClipboardNotification } from 'react-project/components/copyToClipboardNotification/copyToClipboardNotification';
import styles from 'react-project/components/popUp/UpsellPopup/content/Tutorial.module.scss';
import { AcknowledgementConstants } from 'react-project/Constants/tutorial';
import { UPSELL_URLS } from 'react-project/Constants/upsells';
import { asyncMarkCompleted, setNewIndicies } from 'react-project/Header/tutorialSlice';
import { copyToClipboard } from 'react-project/Helpers/CopyToClipboard';
import { selectCurrentFunnelId, selectProjectId } from 'react-project/redux/funnels/selectors';
import { useDispatch, useSelector } from 'react-redux';

const BUTTON_TEXT = {
  UPSELL: 'start your free trial',
  COMPLETED: 'Completed',
  MARK_AS_COMPLETED: 'Mark as Complete',
  UPGRADE: 'Upgrade',
};

export const TutorialContent = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.tutorial.isLoading);
  const nextIndex = useSelector((state) => state.tutorial.nextIndex);
  const prevIndex = useSelector((state) => state.tutorial.previousIndex);
  const currentTask = useSelector((state) => {
    const currentIndex = state.tutorial.currentIndex;
    return state.tutorial.tasks[currentIndex];
  });
  const taskConfig = useSelector((state) => {
    // if acknowledged: show disabled and 'completed'
    // else:
    // if hasAnalytics :: [false, 'complete]
    // if !hasAnalytics && requiresAnalytics:: [false, 'Book a demo']
    const { isAnalyticsAllowed } = MainStorage.getCanvasPermissions();

    const hasAnalyticsAccess = isAnalyticsAllowed;
    const acknowledged = currentTask?.acknowledged;
    const requiresAnalytics = currentTask?.requiresAnalytics;
    if (typeof acknowledged === 'undefined' || typeof requiresAnalytics === 'undefined') {
      return [];
    }

    if (acknowledged) {
      return [true, BUTTON_TEXT.COMPLETED];
    } else {
      if (!hasAnalyticsAccess && requiresAnalytics) {
        return [
          false,
          MainStorage.getCanvasPermissions().isEligibleForFreeTrial
            ? BUTTON_TEXT.UPSELL
            : BUTTON_TEXT.UPGRADE,
        ];
      } else {
        return [false, BUTTON_TEXT.MARK_AS_COMPLETED];
      }
    }
  });
  const email = useSelector((state) => state.user.email);
  const hasScriptWarning = useSelector((state) => !state.analytics.hasAnalyticsData);

  const [disabled, buttonText] = taskConfig;

  const modifiedDisabled = (disabled, currentTask) => {
    const { isAnalyticsAllowed } = MainStorage.getCanvasPermissions();
    if (currentTask.name === AcknowledgementConstants.TRACKING_SCRIPT) {
      return isAnalyticsAllowed ? (hasScriptWarning ? true : disabled) : disabled;
    } else {
      return disabled;
    }
  };

  const setNewIndex = (index) => {
    dispatch(setNewIndicies(index));
  };

  const upsell = () => {
    if (MainStorage.getCanvasPermissions().isEligibleForFreeTrial) {
      window.open(UPSELL_URLS.start_free_trial);
    } else {
      window.open(UPSELL_URLS.upgrade);
    }
  };

  return (
    <div className={styles.upsellContent}>
      <div className={cls(styles.rowCenter, styles.title)}>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {currentTask?.title}
        </div>
      </div>

      <ContentContainer currentTask={currentTask} isLoading={isLoading} />
      <div className={styles.rowCenter} style={{ width: '100%', height: '24%' }}>
        <div
          style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}
          dangerouslySetInnerHTML={{ __html: currentTask?.bodyInnerHTML }}
        ></div>
      </div>
      <div className={styles.rowCenter}>
        <Footer
          onClick={() => {
            buttonText === BUTTON_TEXT.UPSELL || buttonText === BUTTON_TEXT.UPGRADE
              ? upsell()
              : dispatch(
                  asyncMarkCompleted({
                    taskName: currentTask?.name,
                    hubspotReporting: currentTask?.hubspotReporting,
                    email,
                  }),
                );
          }}
          onClickPrevious={() => (isLoading ? null : setNewIndex(prevIndex))}
          onClickNext={() => (isLoading ? null : setNewIndex(nextIndex))}
          acknowledged={currentTask?.acknowledged}
          buttonText={buttonText}
          disabled={modifiedDisabled(disabled, currentTask)}
        />
      </div>
    </div>
  );
};

const ContentContainer = ({ currentTask, isLoading }) => {
  const content = (task, isLoading) => {
    if (task.contentType === 'video') {
      return <VideoContent currentTask={currentTask} isLoading={isLoading} />;
    } else if (task.contentType === 'setupScript') {
      return <TrackingScriptContent currentTask={currentTask} isLoading={isLoading} />;
    } else {
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <div style={{ display: 'flex', fontSize: '48px' }}>
          <div> Loading ... </div>
        </div>
      </div>;
    }
  };
  return <>{content(currentTask, isLoading)}</>;
};

const VideoContent = ({ currentTask, isLoading }) => {
  return (
    <div className={styles.rowCenter} style={{ width: '100%', height: '70%' }}>
      {isLoading ? (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <div style={{ display: 'flex', fontSize: '48px' }}>
            <div> Loading ... </div>
          </div>
        </div>
      ) : (
        <div style={{ width: '100%', marginTop: '24px' }}>
          <iframe
            src={currentTask?.video}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
            style={{ width: '100%', height: '100%' }}
          ></iframe>
        </div>
      )}
    </div>
  );
};

const TrackingScriptContent = () => {
  const { isAnalyticsAllowed } = MainStorage.getCanvasPermissions();
  const hasScriptWarning = useSelector((state) => !state.analytics.hasAnalyticsData);
  const funnelId = useSelector(selectCurrentFunnelId);
  const projectId = useSelector((state) => selectProjectId(state, funnelId));
  const scriptText = !isAnalyticsAllowed
    ? ''
    : `<script type="text/javascript"> (function(funnel) {var deferredEvents = [];window.funnelytics = {events: {trigger: function (name, attributes, callback, opts) {deferredEvents.push({name: name, attributes: attributes, callback: callback, opts: opts});}}};var insert = document.getElementsByTagName('script')[0],script = document.createElement('script');script.addEventListener('load', function() {window.funnelytics.init(funnel, false, deferredEvents);});script.src = 'https://cdn.funnelytics.io/track-v3.js';script.type = 'text/javascript';script.async = true;insert.parentNode.insertBefore(script, insert);})('${projectId}'); </script>`;
  const starterWarningText =
    'Your account doesn’t have access to the Analytics features, please Upgrade to start collecting data.';
  const nonStarterWarningText =
    'It seems that you have no data in your workspace. Please install the script to display your Analytics data and mark this task as complete.';
  const warningText = !isAnalyticsAllowed ? (
    <div className={styles.scriptWarning}> {starterWarningText}</div>
  ) : hasScriptWarning ? (
    <div className={styles.scriptWarning}>{nonStarterWarningText}</div>
  ) : (
    <div style={{ height: '20%' }}></div>
  );
  const [count, setCount] = useState(0);
  return (
    <div className={styles.rowCenter} style={{ width: '100%' }}>
      <div style={{ width: '90%' }}>
        {isAnalyticsAllowed && (
          <>
            <div style={{ color: '#636e84', marginTop: '24px', fontSize: '12px' }}>
              To start collecting data into your Funnelytics account, click on your tracking script
              below to copy it to your clipboard.
            </div>
            <textarea
              className={styles.scriptArea}
              onClick={() => {
                if (isAnalyticsAllowed) {
                  copyToClipboard(scriptText);
                  setCount(count + 1);
                }
              }}
              style={hasScriptWarning ? { height: '150px' } : {}}
              readOnly
              defaultValue={scriptText}
            ></textarea>
            <div className={styles.copyNotifications}>
              <CopyToClipboardNotification count={count} />
            </div>
          </>
        )}
        <div className={cls(!isAnalyticsAllowed && 'pt-[128px]')}>{warningText}</div>
      </div>
    </div>
  );
};

const Footer = ({
  acknowledged,
  nextDisabled,
  prevDisabled,
  onClickPrevious,
  onClick,
  onClickNext,
  disabled,
  buttonText,
}) => {
  return (
    <div
      className={cls(styles.rowCenter)}
      style={{ padding: '28px 50px', justifyContent: 'space-between' }}
    >
      <div
        onClick={onClickPrevious}
        className={styles.change}
        style={{ textDecoration: 'underline', cursor: prevDisabled ? 'not-allowed' : 'pointer' }}
      >
        {'<< Previous'}
      </div>
      <Button
        className={cls(styles.demoButton, acknowledged ? styles.completed : '')}
        disabled={disabled}
        onClick={onClick}
      >
        {buttonText}
      </Button>
      <div
        onClick={onClickNext}
        className={styles.change}
        style={{ textDecoration: 'underline', cursor: nextDisabled ? 'not-allowed' : 'pointer' }}
      >
        {'Next >>'}
      </div>
    </div>
  );
};
