import Utils from 'pixi-project/utils/Utils';
import * as PIXI from 'pixi.js';

export default class SteppedSpinner extends PIXI.Sprite {
  constructor() {
    super(PIXI.utils.TextureCache['stepped-spinner']);

    const steps = 10;
    this.angle = Utils.deg2rad(360 / steps);
    this.delay = 200;
    this.interval = null;
    this.anchor.set(0.5, 0.5);
  }

  start() {
    this.interval = setInterval(this.steppedUpdate.bind(this), this.delay);
  }

  stop() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  steppedUpdate() {
    this.rotation = (this.rotation + this.angle) % (Math.PI * 2);
    window.app.needsRendering();
  }
}
