import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CControlPointTypes } from 'pixi-project/base/containers/CContainerConstants';
import { PopupContainer } from 'react-project/components/v2/popupContainer';
import { PopupContainerTable } from 'react-project/components/v2/tables';
import {
  removeAdPopup,
  resetPopContainerOffset,
  setPopupModalPosition,
} from 'react-project/features';

const MENU_HEIGHT = 200;
const MENU_WIDTH = 400;

const InfoIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      className={className}
    >
      <path
        d="M6 0.611328C2.688 0.611328 0 3.29933 0 6.61133C0 9.92333 2.688 12.6113 6 12.6113C9.312 12.6113 12 9.92333 12 6.61133C12 3.29933 9.312 0.611328 6 0.611328ZM6.6 9.61133H5.4V6.01133H6.6V9.61133ZM6.6 4.81133H5.4V3.61133H6.6V4.81133Z"
        fill="#009BF3"
      />
    </svg>
  );
};

export default function UtmInfoPopup() {
  const dispatch = useDispatch();
  const stepId = useSelector(({ currentStep }) => currentStep.stepId);
  const popupModals = useSelector(({ facebookIntegration }) => facebookIntegration.adsPopup) || [];
  const dimensionRef =
    useSelector(({ facebookIntegration }) => facebookIntegration.popUpBounds) || null;

  useEffect(() => {
    if (stepId && popupModals.length === 0) {
      dispatch(resetPopContainerOffset());
    }
  }, [popupModals]);

  return (
    <div>
      {popupModals.map((modalEl, index) => {
        const onClose = async () => {
          await dispatch(removeAdPopup(modalEl.id));
        };
        const vpa = {
          startX: modalEl.draggablePosition?.x || modalEl.offset * 36,
          startY: modalEl.draggablePosition?.y || modalEl.offset * 36,
          position: CControlPointTypes.BOTTOM,
          type: 'groundZero',
          className: 'ignore-react-onclickoutside',
          widthEl: MENU_WIDTH,
          heightEl: MENU_HEIGHT,
          relativeElement: dimensionRef,
          key: `pcva-${modalEl.id}`,
        };
        const wo = {
          position: {
            x: 0,
            y: 0,
          },
          icon: <InfoIcon />,
          title: modalEl.title,
          showCloseIcon: true,
          menuActions: [],
          onClosePopup: onClose,
          isDraggable: true,
          height: MENU_HEIGHT,
          width: MENU_WIDTH,
          minWidth: 200,
          rndRef: undefined,
          onDragStop: (e, data) => {
            dispatch(
              setPopupModalPosition({
                position: {
                  x: data.x,
                  y: data.y,
                },
                id: modalEl.id,
                stepId,
              }),
            );
          },
        };
        return (
          <PopupContainer viewPortAllowerOptions={vpa} wrapperOptions={wo} key={`PC-${modalEl.id}`}>
            <PopupContainerTable>
              {modalEl && modalEl.utmParameters ? (
                modalEl.utmParameters.map((item, index) => {
                  return (
                    <div
                      className="flex justify-between items-center px-5 py-2 bg-white even:bg-gray-100 text-slate-600"
                      key={`row-${index}`}
                    >
                      <div>{item.key}</div>
                      <div>{item.value}</div>
                    </div>
                  );
                })
              ) : (
                <div className="h-full flex justify-center items-center">
                  No parameters available.
                </div>
              )}
            </PopupContainerTable>
          </PopupContainer>
        );
      })}
    </div>
  );
}
