import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from 'react-project/components/sendReportPopup/SendReportPopup.module.scss';
import { iconReportTool, iconPopupClose } from '../../assets/Icons';
import { cloneData, commonSendEventFunction, isEnterKey } from 'shared/CSharedMethods';
import { EmailItem } from './common/EmailItem';
import { EmailInput } from './common/EmailInput';
import { ButtonSend } from './common/ButtonSend';
import cls from 'classnames';
import {
  TEXT_SEND_REPORT,
  TEXT_REPORT_NAME,
  TEXT_PLACEHOLDER_REPORT,
  TEXT_RECIPIENTS,
} from '../../Constants/texts';
import { isEmailValid } from 'shared/ValidateHelpers';
import {
  PR_EVENT_REPORT_IMAGES_GENERATED,
  RP_EVENT_SEND_REPORT_TITLE_EDITED,
} from 'shared/CSharedEvents';
import { useDispatch, useSelector } from 'react-redux';
import { selectFunnelConfiguration } from 'react-project/redux/funnel-configuration/selectors';
import { selectSessions } from 'react-project/redux/analytics/selectors';
import { sendReportRequestAction } from 'react-project/redux/report/actions';

import { selectUserId } from 'react-project/redux/auth/selectors';

const EMAIL_SEPARATORS = [',', ' '];

const SendReportPopupComponent = ({ setSendReportStatus, title, isNumbersActive }) => {
  const [reportName, setReportName] = useState(title);
  const [textEmail, setTextEmail] = useState('');
  const [emails, setEmails] = useState([]);
  const [filters, setFilters] = useState({});

  const dispatch = useDispatch();
  const funnelConfiguration = useSelector(selectFunnelConfiguration);
  const sessions = useSelector(selectSessions);

  const userId = useSelector(selectUserId);

  useEffect(() => {
    setFilters({
      funnelConfiguration,
      sessions,
    });
  }, [funnelConfiguration, sessions]);

  const sendReportRequest = (e) => {
    const reportData = {
      ...filters,
      name: reportName,
      images: e.detail,
      emails,
      isNumbersActive: isNumbersActive,
      userId,
    };

    dispatch(sendReportRequestAction({ reportData }));
  };

  useEffect(() => {
    commonSendEventFunction(RP_EVENT_SEND_REPORT_TITLE_EDITED, { title: reportName });
  }, [reportName]);

  useEffect(() => {
    document.addEventListener(PR_EVENT_REPORT_IMAGES_GENERATED, sendReportRequest, false);
    return () =>
      document.removeEventListener(PR_EVENT_REPORT_IMAGES_GENERATED, sendReportRequest, false);
  }, [emails, reportName, filters]);

  const onClose = () => {
    setSendReportStatus(false);
  };

  const onChangeReportName = (e) => {
    const { value = '' } = e.target;
    setReportName(value);
  };

  const onChangeEmailText = (e) => {
    const { value = '' } = e.target;
    setTextEmail(value);
  };

  const onDeleteEmail = (index) => {
    const emailArr = cloneData(emails);
    delete emailArr[index];
    setEmails(emailArr.filter((item) => item));
  };

  const onKeyDown = (e) => {
    const { value = '' } = e.target;

    if (isEnterKey(e.key) || EMAIL_SEPARATORS.includes(e.key)) {
      const email = value.trim();
      if (isEmailValid(email)) {
        addEmail(email);
      }

      setTextEmail('');
      e.preventDefault();
    }
  };

  const onClickOutside = (value) => {
    const email = value.trim();
    if (isEmailValid(email)) {
      addEmail(email);
    }

    setTextEmail('');
  };

  const addEmail = (value) => {
    const emailArr = cloneData(emails);
    emailArr.push(value);
    setEmails(emailArr);
  };

  const emailsList = emails.map(function (item, index) {
    return <EmailItem key={item + index} email={item} deleteEmail={() => onDeleteEmail(index)} />;
  });

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Head}>
        <div className={styles.HeadTitle}>
          <div className={styles.Item}>{iconReportTool}</div>
          <div className={styles.Label}>
            <p>{TEXT_SEND_REPORT}</p>
          </div>
        </div>
        <div onClick={onClose} className={cls(styles.Item, styles.Pointer)}>
          {iconPopupClose}
        </div>
      </div>
      <div className={styles.ItemRow}>
        <div className={styles.Label}>{TEXT_REPORT_NAME}</div>
        <input
          onChange={onChangeReportName}
          placeholder={TEXT_PLACEHOLDER_REPORT}
          value={reportName}
        />
      </div>
      <div className={styles.ItemRow}>
        <div className={styles.Label}>{TEXT_RECIPIENTS}</div>
        <div className={styles.Recipients}>
          <div className={styles.EmailContainer}>{emailsList}</div>
          <EmailInput
            emails={emails}
            textEmail={textEmail}
            onKeyDown={onKeyDown}
            onChangeEmailText={onChangeEmailText}
            onClickOutside={onClickOutside}
          />
          <ButtonSend emails={emails} reportName={reportName} />
        </div>
      </div>
    </div>
  );
};

SendReportPopupComponent.propTypes = {
  type: PropTypes.string,
  reportImages: PropTypes.array,
};

export const SendReportPopup = SendReportPopupComponent;
