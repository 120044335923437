import cls from 'classnames';
import produce from 'immer';
import moment from 'moment';
import { CommandStatus } from 'pixi-project/base/command-system/CommandManager';
import React, { Component } from 'react';
import { Banner } from 'react-project/Announcement/Banner';
import { FILTER_NAMES } from 'react-project/Constants/filters';
import { HEAP_EVENTS } from 'react-project/Constants/heapEvents';
import { TEXTS_TOOLTIP } from 'react-project/Constants/texts';
import { AcknowledgementConstants, TUTORIAL_TASKS } from 'react-project/Constants/tutorial';
import styles from 'react-project/Header/Header.module.scss';
import { HeaderActionsDropdown } from 'react-project/Header/HeaderActionsDropdown';
import Modal from 'react-project/Header/Modal';
import PopupWindows from 'react-project/Header/PopupWindows';
import { ZoomDropdown } from 'react-project/Header/ZoomDropdown';
import Expire from 'react-project/Helpers/Expire/Expire';
import { getExplorerFilterProperties } from 'react-project/Helpers/FunnelDataTransform';
import RequestService from 'react-project/Helpers/RequestService';
import { LayerType } from 'react-project/LayersMenu/LayerType';
import { LayersMenu } from 'react-project/LayersMenu/LayersMenu';
import { DateFilterBlock } from 'react-project/LeftSideBar/Filters/filter-components/DateFilterBlock';
import { LeftSideBar } from 'react-project/LeftSideBar/LeftSideBar';
import { LeftSideTab } from 'react-project/LeftSideBar/LeftSideMenu/LeftSideMenu';
import { Share } from 'react-project/Sharing/Share';
import {
  selectorIsPreEnabled,
  selectorIsShareCanvasModalOpen,
} from 'react-project/Sharing/ShareCanvasSelectors';
import { setIsCanvasShareModalOpen, setPreEnabled } from 'react-project/Sharing/shareCanvasSlice';
import {
  resetPeopleTrackingPopups,
  resetPopupZIndex,
} from 'react-project/Toolbar/step-toolbar/PeopleTracking/peopleTrackingSlice';
import ToolsBox from 'react-project/ToolsBox/ToolsBox';
import { isAnalyticsInstalled } from 'react-project/Util/ActionsDetector';
import {
  getUnorganizedFunnelsListUrl,
  getWorkspaceFunnelsListUrl,
} from 'react-project/Util/ExternalUrlHelper';
import { sendHeapTracking } from 'react-project/Util/HEAP.utilities';
import { track as trackCohesive } from 'react-project/Util/CohesiveTracking';
import { When } from 'react-project/Util/When';
import {
  iconAnalyticsError,
  iconAnalyticsWarning,
  iconBackBtnArrow,
  iconCheckmark,
  iconFunnelModeGear,
  iconLogo,
  iconTrafficModeGear,
} from 'react-project/assets/Icons';
import { Modal as BaseModal } from 'react-project/components/modal/Modal';
import ModalStyles from 'react-project/components/modal/SharingModal.module.scss';
import { UpsellPopup } from 'react-project/components/popUp/UpsellPopup/UpsellPopup';
import { WidgetPopup } from 'react-project/components/popUp/WidgetPopup';
import { Tooltip } from 'react-project/components/tooltip/Tooltip';
import { UserProfile } from 'react-project/components/userProfile/UserProfile';
import {
  createJourney,
  createProfile,
  initUserProfile,
} from 'react-project/components/userProfile/helper/dataShape';
import RefreshAnalyticsBtn from 'react-project/components/v2/refreshAnalyticsBtn/RefreshAnalyticsBtn';
import { VersionHistory } from 'react-project/components/versionHistory/VersionHistory';
import {
  loadAnalyticsAsync,
  loadProfileCountriesAsync,
  loadProjectApiKeyAsync,
  loadSessionDetailsAsync,
  loadTrendsAsync,
  refreshSessionsAsync,
  setAnalyticsData,
  setAnalyticsStale,
  setAnalyticsWasCancelled,
  setCancelAnalyticsRequest,
  updateStatus,
} from 'react-project/redux/analytics/actions';
import {
  selectAnalyticsStatus,
  selectAnalyticsWasCancelled,
  selectCancelAnalyticsRequest,
  selectCompareMode,
  selectHasAnalyticsData,
} from 'react-project/redux/analytics/selectors';
import { selectAuth } from 'react-project/redux/auth/selectors';
import { setCanvasChecklist } from 'react-project/redux/checklist/actions';
import { selectCurrentStep } from 'react-project/redux/current-step/selectors';
import { loadCustomIconsAsync } from 'react-project/redux/custom-icons/actions';
import { setExplorerLoadingStatus } from 'react-project/redux/explorer/actions';
import { selectExplorerIsLoading } from 'react-project/redux/explorer/selectors';
import { setFilter, setIsFiltersLoaded } from 'react-project/redux/filters/actions';
import { selectFilters } from 'react-project/redux/filters/selectors';
import { addFocusedStep, removeFocusedStep } from 'react-project/redux/focused-step/actions';
import {
  setActiveDataLayer,
  setDataRange,
  setPanToolActive,
} from 'react-project/redux/funnel-configuration/actions';
import { selectFunnelConfiguration } from 'react-project/redux/funnel-configuration/selectors';
import { loadFunnelRevisionsAsync } from 'react-project/redux/funnel-revisions/actions';
import {
  selectFunnelRevisions,
  selectIsFunnelRevisionsLoading,
} from 'react-project/redux/funnel-revisions/selectors';
import {
  fetchFunnelAsync,
  loadFunnelAsync,
  setIsMigrationC1toC2,
} from 'react-project/redux/funnels/actions';
import {
  selectFunnel,
  selectIsFunnelLoading,
  selectIsMigratedC1toC2,
} from 'react-project/redux/funnels/selectors';
import {
  resetStepModalData,
  setIsStepModalOpened,
  setIsStepModalPinned,
  setStepModalData,
} from 'react-project/redux/modal/actions';
import {
  selectIsStepModalOpened,
  selectIsStepModalPinned,
  selectStepModalData,
} from 'react-project/redux/modal/selectors';
import {
  removeNote,
  resetHasEditsAction,
  setCanvasNote,
  setNote,
  setNoteTitle,
  setNotes,
} from 'react-project/redux/notes/actions';
import { selectHasEdits, selectNotes } from 'react-project/redux/notes/selectors';
import { selectWidgetStatus } from 'react-project/redux/popups/selectors';
import { setSelectedElements } from 'react-project/redux/selected-elements/actions';
import { setUIState } from 'react-project/redux/ui-state/actions';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import { connect } from 'react-redux';
import {
  ANALYTICS_STATUS_CANCELLED,
  ANALYTICS_STATUS_ERROR,
  ANALYTICS_STATUS_LOADING,
  ANALYTICS_STATUS_STALE,
  ANALYTICS_STATUS_SUCCESS,
  FILTER_TYPE_DEFAULT,
  SAVE_TYPE_AUTO,
  SAVE_TYPE_MANUAL,
} from 'shared/CSharedConstants';
import {
  PR_COMMAND_COLLAPSE_LEFT_SIDE_MENU,
  PR_EVENT_ANALYTICS_NEEDS_REFRESH,
  PR_EVENT_CANVAS_CHECKLIST_CHANGED,
  PR_EVENT_COMMAND_REMOVE_EXECUTED,
  PR_EVENT_ELEMENT_POINTER_DOWN,
  PR_EVENT_ELEMENT_POINTER_UP,
  PR_EVENT_FUNNEL_CHANGED,
  PR_EVENT_FUNNEL_LOADED,
  PR_EVENT_OBJECT_SELECTED,
  PR_EVENT_REFRESH_RESPONSE,
  PR_EVENT_SAVE_RESPONSE,
  PR_EVENT_SELECTION_ADDED,
  PR_EVENT_SELECTION_REMOVED,
  PR_EVENT_SESSION_SELECTED,
  PR_EVENT_STEP_FOCUS_CHANGED,
  PR_EVENT_THUMBNAIL_REQUESTED,
  PR_EVENT_TRENDS_NEED_UPDATE,
  PR_EVENT_ZOOM_LEVEL_CHANGED,
  PR_NOTES_ADDED,
  PR_NOTES_LOADED,
  PR_NOTES_REMOVED,
  PR_NOTES_TITLE_UPDATED,
  RP_EVENT_ANALYTICS_STATUS_CHANGED,
  RP_EVENT_ANALYTICS_UPDATED,
  RP_EVENT_DONE_SAVING,
  RP_EVENT_FIT_TO_SCREEN,
  RP_EVENT_FOCUS_FRAME_CLEARED,
  RP_EVENT_LAYER_TOGGLED,
  RP_EVENT_LOAD_REQUEST,
  RP_EVENT_REFRESH_REQUEST,
  RP_EVENT_REPORT_ADDED,
  RP_EVENT_REPORT_REMOVED,
  RP_EVENT_RESTORE_BUTTON_CLICKED,
  RP_EVENT_SAVE_REQUEST,
  RP_EVENT_SESSION_DESELECTED,
  RP_EVENT_TRENDS_LOADED,
  RP_EVENT_UPSELL_POPUP_CLOSED,
  RP_EVENT_ZOOM_IN,
  RP_EVENT_ZOOM_OUT,
  RP_EVENT_ZOOM_RESET,
  RP_EVENT_ZOOM_VALUE_CHANGED,
  RR_CHECKLIST_OPEN,
  RR_EVENT_ON_REFRESH_ANALYTICS,
  RR_EVENT_ON_SHOW_LEFT_CREATE_STEP_MENU,
  RR_NOTES_OPEN,
  RR_SET_ACTIVE_CHECKLIST,
  RR_SET_ACTIVE_NOTE,
  RR_SET_LEFT_SIDE_TAB,
  RR_SHOW_TUTORIAL_POPUP,
  RR_SHOW_UPSELL_POPUP,
} from 'shared/CSharedEvents';
import { cloneData, commonSendEventFunction, isEnterKey } from 'shared/CSharedMethods';
import { debounce } from 'shared/SharedHelpers';
import { HelpLogo } from './HelpLogo';
import { VersionHistoryDropdown } from './VersionHistoryDropdown';
import { asyncGetTutorialCompleted, setNewIndicies } from './tutorialSlice';
import { TrendsStepPicker } from 'react-project/components/trendsStepPicker/TrendsStepPicker';
import SharedElementHelpers from 'shared/SharedElementHelpers';
import { FF_TRENDS } from 'shared/FeatureFlags';
import { ProfitwellScript } from 'react-project/components/profitwell/ProfitwellScript';
import { FilterBtn } from 'react-project/components/toolbarFilterBtn/FilterBtn';
import { AllFiltersBlock } from 'react-project/LeftSideBar/Filters/AllFiltersBlock';
import { AllCompareFiltersBlock } from 'react-project/LeftSideBar/Filters/AllCompareFiltersBlock';
import { ServiceBreakdown } from 'react-project/components/ServiceBreakdown/ServiceBreakdown';
import { getThumbnailImg } from 'react-project/redux/current-step/actions';
import { FunnelDataMode } from 'react-project/components/funnelDataMode/FunnelDataMode';
import { CreateAccountBanner } from 'react-project/components/CreateAccountBanner/CreateAccountBanner';
import { ReactController } from 'react-project/ReactController/ReactController';
import { FloatingPopups } from 'react-project/LeftSideBar/FloatingPopups/FloatingPopups';
import {
  LOCK_NO_ANALYTICS,
  LOCK_NO_PLANNING,
  UILock,
} from 'react-project/components/uilock/UILock';
import PermissionHelper from 'shared/PermissionHelper';

const requestService = new RequestService();

const MAP_ANALYTICS_STATUS_TO_ICON = new Map([
  [ANALYTICS_STATUS_SUCCESS, iconCheckmark],
  [ANALYTICS_STATUS_ERROR, iconAnalyticsError],
  [ANALYTICS_STATUS_STALE, iconAnalyticsWarning],
  [ANALYTICS_STATUS_CANCELLED, iconAnalyticsWarning],
]);

const AUTO_SAVE_LATENCY_TIME = 3 * 1000; // 3 secs
const IS_AUTOSAVE_DISABLED = process.env.REACT_APP_DEBUG === 'true';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stepsModalOpened: false,
      menuPosition: {
        x: 680,
        y: 60,
      },
      createObjectPositions: { x: 0, y: 0 },
      activateSelectTool: false,
      funnelName: '',
      isFunnelSaving: false,
      isAutoSaving: false,
      isErrorSaving: false,
      isManualSaveMode: false,
      isReportToolActive: false,
      isReportToolDisabled: false,
      hasUnsavedChanges: false,
      zoomValue: 100,
      draftedZoom: 100,
      zoomDropdownOpened: false,
      headerActionsDropdownOpened: false,
      versionHistoryOpened: false,
      hasWorkspaceActions: false,
      selectedSessions: [],
      activeStepsModalTab: null,
      showUpsellPopup: false,
      upsellPopupContext: undefined,
      isDebounceCanceled: false,
      canvasSharing: false,
      isProfitwellScriptInstalled: false,
      isFiltersOpened: false,
      isCompareFiltersOpened: false,
      isCreateAccount: false,
    };

    this.props.onSetAnalyticsStale();
    this.funnelModeRef = React.createRef();

    this.refreshAnalytics = this.refreshAnalytics.bind(this);
    this.refreshRevisions = this.refreshRevisions.bind(this);
  }

  onApplyButtonChanged = (isEnabled) => {
    this.props.onFiltersChanged(isEnabled);
  };

  onUpsellClose() {
    this.setState({
      showUpsellPopup: false,
      upsellPopupContext: undefined,
    });
    commonSendEventFunction(RP_EVENT_UPSELL_POPUP_CLOSED);
  }

  onUpsellShow() {
    this.setState({ showUpsellPopup: true });
  }

  onTutorialShow() {
    this.showTutorialModal();
  }

  onThumbnailRequested(e) {
    const currentStep = e.detail.currentStep;
    const data = {
      currentStep,
      projectId: this.props.funnel.projectId,
    };
    this.props.getThumbnailImg(data);
  }

  showTutorialModal() {
    if (this.props.canvasPermissions.isReadonlyAccess) {
      // If there is no analytics data and user has readonly access
      // We will show upsell popup -> which will show that the "feature is not available" message
      if (!this.props.hasAnalyticsData) {
        commonSendEventFunction(RR_SHOW_UPSELL_POPUP);
      }
      return;
    }

    this.setState({
      upsellPopupContext: 'tutorial',
      showUpsellPopup: true,
    });

    // Insert Tutorial task index to Redux to highlight the task
    const index = _.findIndex(
      TUTORIAL_TASKS,
      (el) => el.name === AcknowledgementConstants.TRACKING_SCRIPT,
    );
    this.props.setTutorialTask(index);
  }

  setActive = (type) => {
    this.setState({
      [type]: !this.state[type],
    });
  };

  setZoomDropdownOpened = (opened) => {
    this.setState({ zoomDropdownOpened: opened });
  };

  onSaveClick = () => {
    this.save();
  };

  onWidgetOpened = () => {
    this.setState({
      isReportToolActive: false,
    });
  };

  onWidgetClosed = () => {
    this.setState({
      isReportToolActive: false,
    });
  };

  save = (type = SAVE_TYPE_MANUAL) => {
    if (this.state.isFunnelSaving) {
      return;
    }

    if (this.props.canvasPermissions.isReadonlyAccess) {
      return;
    }

    if (this.props.canvasPermissions.isTutorial) {
      return;
    }

    this.setState(
      {
        hasUnsavedChanges: false,
        isFunnelSaving: true,
        isAutoSaving: type === SAVE_TYPE_AUTO,
      },
      () => {
        commonSendEventFunction(RP_EVENT_SAVE_REQUEST, { value: true });
        if (this.props.hasNotesEdits) {
          sendHeapTracking({
            projectId: this.props.funnel.projectId,
            eventName: HEAP_EVENTS.NOTE_UPDATED,
          });
          trackCohesive(HEAP_EVENTS.NOTE_UPDATED, {
            projectId: this.props.funnel.projectId,
          });
          this.props.resetHasEditsAction();
        }
      },
    );
  };

  autoSave = () => {
    if (
      IS_AUTOSAVE_DISABLED ||
      !this.state.hasUnsavedChanges ||
      this.props.canvasPermissions.isAdminImpersonation
    ) {
      return;
    }

    this.save(SAVE_TYPE_AUTO);
  };

  setSaveSuccess = () => {
    this.setState({
      isManualSaveMode: false,
      isErrorSaving: false,
    });
  };

  setSaveFail = () => {
    this.setState({
      isErrorSaving: true,
    });

    if (this.state.isAutoSaving && !this.state.isManualSaveMode) {
      this.setState(
        {
          isManualSaveMode: true,
        },
        () => {
          this.save(SAVE_TYPE_AUTO);
        },
      );
    }
  };

  autoSaveDebounced = debounce(this.autoSave, AUTO_SAVE_LATENCY_TIME);

  onAnalyticsNeedRefresh = () => {
    this.props.onSetAnalyticsStale();
  };

  onFunnelChanged = () => {
    this.setState({ hasUnsavedChanges: true }, this.autoSaveDebounced);
  };

  onFunnelElementPointerDown = () => {
    // We don't want to save when user is dragging funnel element
    // We are going to cancel the debounce method and resume it
    // when user is done dragging (onFunnelElementPointerUp)
    const isPending = this.autoSaveDebounced.isPending();
    if (isPending) {
      this.autoSaveDebounced.cancel();
      this.setState({ isDebounceCanceled: isPending });
    }
  };

  onFunnelElementPointerUp = () => {
    // if you canceled a debounce, you need to call it again
    if (this.state.isDebounceCanceled) {
      this.autoSaveDebounced(); // revoke the debounce
      this.setState({ isDebounceCanceled: false });
    }
  };

  resetErrorMsg = () => {
    //reset errors
    this.props.setIsMessageErrorShowing(false);
    this.props.setMessageErrorData(null);
  };

  onRefreshAnalyticsClick = () => {
    if (this.props.analyticsStatus === ANALYTICS_STATUS_LOADING) {
      return this.triggerCancelRefreshAnalyticsEvent();
    }

    if (this.props.isFunnelLoading) {
      return;
    }

    if (!this.props.hasAnalyticsData) {
      this.showTutorialModal();
      return;
    }

    // Uncomment this to trigger the fulters to save
    // commonSendEventFunction(RR_EVENT_ON_REFRESH_ANALYTICS);
    // return; // the code below will not be needed

    this.startRefreshingAnalytics();
  };

  startRefreshingAnalytics = () => {
    this.resetErrorMsg();
    this.props.resetPeopleTrackingPopups();
    this.props.resetPopupZIndex();

    this.triggerRefreshAnalyticsEvent();

    // make sure number layers is turned on
    if (!this.props.funnelConfiguration.visibleDataLayers[LayerType.NUMBERS]) {
      const data = { type: LayerType.NUMBERS, isActive: true };
      this.props.onSetActiveDataLayer(data);
      commonSendEventFunction(RP_EVENT_LAYER_TOGGLED, data);
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
      window.app.needsRendering();
    }
  };

  triggerRefreshAnalyticsEvent = () => {
    commonSendEventFunction(RP_EVENT_REFRESH_REQUEST, { isCancel: false });
  };

  triggerCancelRefreshAnalyticsEvent = () => {
    commonSendEventFunction(RP_EVENT_REFRESH_REQUEST, { isCancel: true });
  };

  setHeaderActionsDropdownOpened = (opened) => {
    this.setState({ headerActionsDropdownOpened: opened });
  };

  setVersionHistoryOpened = (opened) => {
    this.setState({ versionHistoryOpened: opened });
  };

  cleanCanvas = () => {
    commonSendEventFunction(RP_EVENT_RESTORE_BUTTON_CLICKED);
    this.refreshAnalytics([], []);
  };

  isCanvasEmpty = (data) => {
    // Needs validation of data to assume it is empty
    return data.objects?.length === 0 && data.joints?.length === 0;
  };

  toggleUpsellModal = (bool) => {
    // Trigger upsellPopup Modal
    // showModal(bool);
  };

  loadRequest = (params = {}, shouldBeSaved = false) => {
    this.props
      .onLoadFunnel({
        params,
        projectId: this.props.funnel.projectId,
        funnelId: this.props.funnelId,
        refreshAnalytics: this.refreshAnalytics,
        refreshRevisions: this.refreshRevisions,
        canvasPermissions: this.props.canvasPermissions,
      })
      .then(() => {
        this.setState({ isCreateAccount: true });
        if (shouldBeSaved) {
          this.setState({ hasUnsavedChanges: true }, this.autoSaveDebounced);
        }
      });
  };

  loadCustomIcons = () => {
    this.props.onLoadCustomIcons();
  };

  sendSaveResponse = (e) => {
    // Prevent saving code block , usefull for testing and not saving the canvas
    // this.setSaveFail();
    // this.setState({ isFunnelSaving: false });
    // return;

    const objForSending = JSON.parse(e.detail.data);
    const previewForSending = JSON.parse(e.detail.preview);

    if (this.props.isMigratedC1toC2) {
      // This is one time event that happens when user opens funnel that was created in C1 - Canvas 1.0 (old canvas)
      requestService.updateCanvasToC2(this.props.funnelId);
    }
    this.props.onSetIsMigrationC1toC2(false);

    const { countries, device, session, step, contributionWindow, focusLogicalOperator } =
      this.props.allFilters.currentFilters.commonFilters;
    const { peopleCompareFilters } = this.props.allFilters.currentFilters;

    objForSending.funnelConfiguration = {
      ...this.props.funnelConfiguration,
      compareFilter: cloneData(peopleCompareFilters),
      filter: { countries, device, session, step, contributionWindow, focusLogicalOperator },
    };

    // Saving the Trend Indicator range
    if (objForSending.funnelConfiguration.compareFilter) {
      objForSending.funnelConfiguration.compareFilter.range =
        this.props.funnelConfiguration.compareFilter.range;
    }

    requestService
      .saveFunnelRequest(this.props.funnelId, objForSending, previewForSending)
      .then((response) => {
        const { success } = response;

        if (success) {
          this.setSaveSuccess();
        } else {
          this.setSaveFail();
        }
      })
      .finally(() => {
        this.setState(
          {
            isFunnelSaving: false,
          },
          () => {
            this.refreshRevisions(this.props.funnelId);
            commonSendEventFunction(RP_EVENT_DONE_SAVING);
            if (this.state.hasUnsavedChanges) {
              this.autoSaveDebounced();
            }
          },
        );
      });
  };

  transformCanvasEntities = ({ canvasEntities, compareCanvasEntities }) => {
    for (let key in canvasEntities) {
      canvasEntities[key].compare_to_data = compareCanvasEntities[key];
    }
    return canvasEntities;
  };

  sendRefreshResponse = (e) => {
    const { data, isCancel } = e.detail;
    let objForSending;
    try {
      objForSending = JSON.parse(data.data);
    } catch (e) {
      objForSending = data;
    }

    const dataObjs = objForSending.objects;
    const dataConnections = objForSending.joints;

    this.refreshAnalytics(dataObjs, dataConnections, isCancel);
  };

  onSessionSelected(e) {
    const isSelected = e.detail.isSelected;
    const sessionID = e.detail.data.id;
    if (isSelected) {
      this.loadSessionDetails(
        sessionID,
        e.detail.data,
        {
          x: e.detail.position.x,
          y: e.detail.position.y,
        },
        e.detail.widgetBounds,
      );
    } else {
      this.removeSelectedSession(sessionID);
    }
  }

  onUpsellEvent(e) {
    this.onUpsellShow();
  }

  loadSessionDetails(sessionID, data, position, widgetBounds) {
    const newSelectedSessions = produce(this.state.selectedSessions, (draft) => {
      draft.push(initUserProfile(data, position, widgetBounds));
    });

    this.setState({
      selectedSessions: newSelectedSessions,
    });

    const projectId = this.props.funnel.projectId;
    const funnelConfiguration = this.props.funnelConfiguration;
    this.props
      .onLoadSessionDetailsAsync(projectId, sessionID, funnelConfiguration, data)
      .then((responseData) => {
        if (responseData) {
          this.onSessionDetailsLoaded(responseData.response, responseData.data);
        }
      });
  }

  onSessionDetailsLoaded(responseData, cellData) {
    const profile = createProfile(responseData);

    const journey = createJourney(responseData);
    const newSelectedSessions = produce(this.state.selectedSessions, (draft) => {
      draft.forEach((item) => {
        if (item.id === cellData.id) {
          item.isLoading = false;
          item.profile = profile;
          item.journey = journey;
        }
      });
    });

    this.setState({
      selectedSessions: newSelectedSessions,
    });
  }
  onUserProfileClose(id) {
    this.removeSelectedSession(id);
    commonSendEventFunction(RP_EVENT_SESSION_DESELECTED, { id });
  }

  removeSelectedSession(id) {
    const newSelectedSessions = produce(this.state.selectedSessions, (draft) => {}).filter(
      (item) => {
        if (item.id !== id) {
          return item;
        }
      },
    );

    this.setState({
      selectedSessions: newSelectedSessions,
    });
  }

  _updateAnalyticsStatus(isCancelRefresh) {
    const analyticsStatus =
      isCancelRefresh || this.props.selectAnalyticsWasCancelled
        ? ANALYTICS_STATUS_CANCELLED
        : ANALYTICS_STATUS_SUCCESS;
    this.props.updateAnalyticsStatus(analyticsStatus);
    commonSendEventFunction(RP_EVENT_ANALYTICS_STATUS_CHANGED, {
      status: analyticsStatus,
    });
  }

  async _setAnalytics(funnelData, dataResponseArr, isCancelRefresh = false) {
    const { projectId, funnelConfiguration, dataObjs, dataConnections } = funnelData;
    try {
      if (this.props.selectAnalyticsWasCancelled) {
        this.props.setAnalyticsWasCancelled(false);
        return;
      } else if (Array.isArray(dataResponseArr) && dataResponseArr.length == 2) {
        const [compareCanvasEntities, canvasEntities] = dataResponseArr;
        const transformedCanvasEntities = this.transformCanvasEntities({
          canvasEntities,
          compareCanvasEntities,
        });

        this.props.setAnalyticsData(transformedCanvasEntities);
        commonSendEventFunction(RP_EVENT_ANALYTICS_UPDATED, transformedCanvasEntities);
        this._updateAnalyticsStatus(isCancelRefresh);
        this.props.onLoadProfileCountries(
          projectId,
          funnelConfiguration,
          dataObjs,
          dataConnections,
          true,
        );
        this.props.onLoadSessions({
          projectId,
          funnelConfiguration,
          dataObjs,
          dataConnections,
          compareMode: true,
        });
        this.props.onLoadProfileCountries(
          projectId,
          funnelConfiguration,
          dataObjs,
          dataConnections,
        );
        this.props.onLoadSessions({ projectId, funnelConfiguration, dataObjs, dataConnections });
      } else if (Array.isArray(dataResponseArr) && dataResponseArr.length === 1) {
        const [canvasEntities] = dataResponseArr;
        if (canvasEntities) {
          commonSendEventFunction(RP_EVENT_ANALYTICS_UPDATED, canvasEntities);
        }
        this._updateAnalyticsStatus(isCancelRefresh);
        this.props.onLoadProfileCountries(
          projectId,
          funnelConfiguration,
          dataObjs,
          dataConnections,
        );
        this.props.onLoadSessions({
          projectId,
          funnelConfiguration,
          dataObjs,
          dataConnections,
        });
      } else {
        throw 'Invalid data type';
      }
    } catch (e) {
      console.log('SETANALYTICS ERROR: ', e);
    }
  }

  onTrendsNeedUpdate = (e) => {
    this.loadTrendsForWidget(e.detail.objects, e.detail.connections, e.detail.widgetId);
  };

  loadTrends(dataObjs, dataConnections) {
    for (let i = 0; i < dataObjs.length; i++) {
      const object = dataObjs[i];
      if (SharedElementHelpers.IsTrendsWidget(object)) {
        this.loadTrendsForWidget(dataObjs, dataConnections, object.ID);
      }
    }
  }

  loadTrendsForWidget(dataObjs, dataConnections, widgetId = null) {
    const projectId = this.props.funnel.projectId;
    const funnelConfiguration = this.props.funnelConfiguration;

    if (this.props.compareModeEnabled) {
      const p1 = this.props.onLoadTrends(
        projectId,
        funnelConfiguration,
        dataObjs,
        dataConnections,
        widgetId,
        false,
      );

      const p2 = this.props.onLoadTrends(
        projectId,
        funnelConfiguration,
        dataObjs,
        dataConnections,
        widgetId,
        true,
      );

      Promise.all([p1, p2])
        .then((result) => {
          const trends = result[0].data;
          const trendsCompare = result[1].data;

          const data = {
            trends,
            trendsCompare,
            ranges: result[0].reqBody.filter['widget-ranges'],
            rangesCompare: result[1].reqBody.filter['widget-ranges'],
            widgetId: widgetId,
            range: result[0].reqBody.filter.range,
            rangeCompare: result[1].reqBody.filter.range,
          };
          commonSendEventFunction(RP_EVENT_TRENDS_LOADED, data);
        })
        .catch((error) => {
          commonSendEventFunction(RP_EVENT_TRENDS_LOADED, { error, widgetId });
        });
    } else {
      this.props
        .onLoadTrends(projectId, funnelConfiguration, dataObjs, dataConnections, widgetId, false)
        .then((result) => {
          commonSendEventFunction(RP_EVENT_TRENDS_LOADED, {
            trends: result.data,
            ranges: result.reqBody.filter['widget-ranges'],
            range: result.reqBody.filter.range,
            widgetId,
          });
        })
        .catch((error) => {
          commonSendEventFunction(RP_EVENT_TRENDS_LOADED, { error, widgetId });
        });
    }
  }

  filterObjects(objects) {
    let objectsToReturn = objects;

    if (!this.props.canvasPermissions.purchaseTrackingAllowed) {
      objectsToReturn = objectsToReturn.filter(
        (obj) => !SharedElementHelpers.IsPurchaseAction(obj),
      );
    }

    if (!this.props.canvasPermissions.calendarTrackingAllowed) {
      objectsToReturn = objectsToReturn.filter((obj) => !SharedElementHelpers.IsMeetingAction(obj));
    }

    if (!this.props.canvasPermissions.formTrackingAllowed) {
      objectsToReturn = objectsToReturn.filter((obj) => !SharedElementHelpers.IsFormAction(obj));
    }

    if (!this.props.canvasPermissions.dealTrackingAllowed) {
      objectsToReturn = objectsToReturn.filter((obj) => !SharedElementHelpers.IsDealAction(obj));
    }

    objectsToReturn = PermissionHelper.filterByDataOrigin(
      objectsToReturn,
      this.props.canvasPermissions,
    );

    return objectsToReturn;
  }

  async refreshAnalytics(dataObjs, dataConnections, isCancelRefresh = false) {
    if (!this.props.canvasPermissions.isAnalyticsAllowed) {
      return;
    }

    if (this.props.funnel.isInUnorganizedFolder) {
      return;
    }
    const projectId = this.props.funnel.projectId;
    const funnelConfiguration = this.props.funnelConfiguration;
    if (isCancelRefresh) {
      this.props.updateAnalyticsStatus(ANALYTICS_STATUS_CANCELLED);
      commonSendEventFunction(RP_EVENT_ANALYTICS_STATUS_CHANGED, {
        status: ANALYTICS_STATUS_CANCELLED,
      });
    } else {
      this.props.updateAnalyticsStatus(ANALYTICS_STATUS_LOADING);
      commonSendEventFunction(RP_EVENT_ANALYTICS_STATUS_CHANGED, {
        status: ANALYTICS_STATUS_LOADING,
      });
    }

    if (FF_TRENDS) {
      // Load Trends
      if (isCancelRefresh) {
        // TODO cancel loading trends here
      } else {
        this.loadTrends(dataObjs, dataConnections);
      }
    }

    try {
      let analyticsRespArr;
      if (isCancelRefresh) {
        analyticsRespArr = [{}];
        this.props.setAnalyticsWasCancelled(true);
        return;
      } else if (this.props.compareModeEnabled) {
        // TODO Don't check if the UI is in compare mode , check the funnel configuration
        // if (this.props.compareModeEnabled) is not a good practice

        // Check if purchase actions are allowed to have analytics data
        const dataObjects = this.filterObjects(dataObjs);

        analyticsRespArr = await Promise.all([
          this.props.onLoadAnalyticsAsync(
            projectId,
            funnelConfiguration,
            dataObjects,
            dataConnections,
            true,
          ),
          this.props.onLoadAnalyticsAsync(
            projectId,
            funnelConfiguration,
            dataObjects,
            dataConnections,
          ),
        ]);
      } else {
        const dataObjects = this.filterObjects(dataObjs);
        const analyticsResp = await this.props.onLoadAnalyticsAsync(
          projectId,
          funnelConfiguration,
          dataObjects,
          dataConnections,
        );
        analyticsRespArr = [analyticsResp];
      }

      if (!!this.props.selectIsCancelAnalyticsRequest) {
        this._updateAnalyticsStatus(true);
      }

      this._setAnalytics(
        { projectId, funnelConfiguration, dataObjs, dataConnections },
        analyticsRespArr,
        isCancelRefresh,
      );
    } catch (e) {
      if (!this.props.compareModeEnabled) {
        commonSendEventFunction(RP_EVENT_ANALYTICS_STATUS_CHANGED, {
          status: ANALYTICS_STATUS_ERROR,
        });
      }

      this.props.updateAnalyticsStatus(ANALYTICS_STATUS_ERROR);
      commonSendEventFunction(RP_EVENT_ANALYTICS_STATUS_CHANGED, {
        status: ANALYTICS_STATUS_ERROR,
      });
    } finally {
      // this.props.setCancelAnalyticsRequest(false);
      // TODO if someone notices that there is a loader spinning
      // even after loading is done , then that request must be synced with this one.
      // this.props.setExplorerLoadingStatus(false);
    }
  }

  cancelAnalytics = () => {
    //COMEBACK TO THIS
    this.props.setAnalyticsWasCancelled(true);
    this.props.setCancelAnalyticsRequest(true);
    this.onRefreshAnalyticsClick();
  };

  humanizeInterval(revision) {
    const { attributes: { updated_at: date } = {} } = revision;

    if (date) {
      const diff = moment().diff(date, 'seconds');
      return `Saved ${moment.duration(diff, 'seconds').humanize()} ago`;
    } else {
      return '';
    }
  }

  refreshRevisions = (funnelId) => {
    this.props.onLoadFunnelRevisions(funnelId);
  };

  dateFormatter = (rawDate) => {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }).format(new Date(rawDate));
  };

  activateSelectTool = () => {
    this.setState(
      {
        activateSelectTool: true,
      },
      () => {
        this.setState({
          activateSelectTool: false,
        });
      },
    );
  };

  openModal = (isOpen, data = {}) => {
    this.props.setStepModalData(data);
    this.props.setIsStepModalOpened(!!isOpen);
  };

  onFitToScreenClicked = () => {
    commonSendEventFunction(RP_EVENT_FIT_TO_SCREEN);
    this.setZoomDropdownOpened(false);
  };

  onZoomInClicked = () => {
    commonSendEventFunction(RP_EVENT_ZOOM_IN);
  };

  onZoomOutClicked = () => {
    commonSendEventFunction(RP_EVENT_ZOOM_OUT);
  };

  onZoomResetClicked = () => {
    commonSendEventFunction(RP_EVENT_ZOOM_RESET);
  };

  onBackBtnIconClick = () => {
    if (this.props.isViewOnlyLink) {
      window.location.href = 'https://funnelytics.io';
    } else if (
      this.props.canvasPermissions.isCollaborator &&
      this.props.canvasPermissions.isReadonlyAccess &&
      this.props.funnel.projectId
    ) {
      window.location.href = getWorkspaceFunnelsListUrl(this.props.funnel.projectId);
    } else if (!this.props.funnel.projectId || this.props.canvasPermissions.isReadonlyAccess) {
      window.location.href = getUnorganizedFunnelsListUrl();
    } else {
      window.location.href = getWorkspaceFunnelsListUrl(this.props.funnel.projectId);
    }
  };

  onSetReportToolStatus = (status) => {
    this.setState({
      isReportToolActive: status,
      activateSelectTool: !status,
    });
  };

  windowResizeCoordsRecalculationEvent = () => {
    const elem = document.querySelector('#ToolsBoxContainer');
    const rect = elem.getBoundingClientRect();
    this.setState({
      menuPosition: {
        ...this.state.menuPosition,
        x: rect.x,
      },
    });
  };

  onWheel = (e) => {
    if (e.ctrlKey) {
      e.preventDefault();
    }
  };

  setCurrentZoom = (e) => {
    const updatedZoomValue = Math.round(e.detail.value * 100);
    this.setState({ zoomValue: updatedZoomValue, draftedZoom: updatedZoomValue });
  };

  handleZoomInputChange = (e) => {
    this.setState({ draftedZoom: e.target.value.replace(/[^\d]/g, '') });
  };

  handleZoomInputAccept = (e) => {
    if (isEnterKey(e.key)) {
      this.setState({ draftedZoom: this.state.zoomValue });
      this.acceptCurrentZoom();
    }
  };

  acceptCurrentZoom = () => {
    commonSendEventFunction(RP_EVENT_ZOOM_VALUE_CHANGED, { value: this.state.draftedZoom });
  };

  onReportAdded = () => {
    this.setState({ isReportToolDisabled: true });
  };

  onReportRemoved = () => {
    this.setState({ isReportToolDisabled: false });
  };

  onStepsModalTabChanged = (tab) => {
    this.setState({
      activeStepsModalTab: tab,
    });
  };

  onFunnelLoaded = (e) => {
    const hasInitialFunnelLoaded = localStorage.getItem('newCanvasVideo');
    // Do not show upsell popup if user has readonly access
    if (
      !hasInitialFunnelLoaded &&
      !this.props.canvasPermissions.isReadonlyAccess &&
      !this.props.canvasPermissions.isTutorial
    ) {
      this.setState({ upsellPopupContext: 'upsellVideo' });
      this.onUpsellShow();
      localStorage.setItem('newCanvasVideo', 'true');
    }

    if (
      e.detail &&
      e.detail.data &&
      e.detail.data.funnelConfiguration &&
      this.props.canvasPermissions.isAnalyticsAllowed
    ) {
      this.preloadTrafficExplorerData(e.detail.data.funnelConfiguration);

      if (
        e.detail.data.funnelConfiguration.visibleDataLayers &&
        e.detail.data.funnelConfiguration.visibleDataLayers[LayerType.FORECASTING]
      ) {
        const eventData = {
          onClick: false,
          onLoad: true,
        };
        sendHeapTracking({
          projectId: this.props.funnel.projectId,
          eventName: HEAP_EVENTS.ENABLE_FORECAST,
          eventData,
        });
        trackCohesive(HEAP_EVENTS.ENABLE_FORECAST, {
          projectId: this.props.funnel.projectId,
          ...eventData,
        });
      }
    }

    if (e.detail && e.detail.data && e.detail.data.canvasChecklistData) {
      this.props.onSetCanvasChecklist(e.detail.data.canvasChecklistData);
    }

    // Send Heap event on canvas open
    const eventData = {
      screen_size: window.innerWidth + 'x' + window.innerHeight,
      sceen_px_count: window.innerWidth * window.innerHeight,
    };
    sendHeapTracking({
      projectId: this.props.funnel.projectId,
      eventName: HEAP_EVENTS.OPEN_CANVAS,
      eventData,
    });
    trackCohesive(HEAP_EVENTS.OPEN_CANVAS, {
      projectId: this.props.funnel.projectId,
      ...eventData,
    });
  };

  onFunnelImportedToCanvas = (e) => {
    this.props.onSetIsFiltersLoaded(true);
  };

  onNotesLoaded = (e) => {
    if (e.detail.canvasNote) {
      this.props.onSetCanvasNote(e.detail.canvasNote);
    }
    this.props.onSetNotes(e.detail.objectNotes);
  };

  onNotesOpen = async (e) => {
    const id = this.props.currentStep.stepId;
    const checklistData = this.props.currentStep.object.checklistData;
    const notes = this.props.currentStep.object.notes;

    commonSendEventFunction(RR_SET_ACTIVE_NOTE, {
      id,
      noteData: notes,
      checklistData,
      object: this.props.currentStep,
      topMenuRect: e.detail.topMenuRect,
    });

    this.setState({ versionHistoryOpened: false });

    commonSendEventFunction(PR_EVENT_OBJECT_SELECTED, {
      show: false,
    });
  };

  onChecklistOpen = async (e) => {
    const id = this.props.currentStep.stepId;
    const checklistData = this.props.currentStep.object.checklistData;
    const notes = this.props.currentStep.object.notes;

    this.setState({ versionHistoryOpened: false });

    commonSendEventFunction(RR_SET_ACTIVE_CHECKLIST, {
      id,
      checklistData,
      noteData: notes,
      object: this.props.currentStep,
      topMenuRect: e.detail.topMenuRect,
    });

    commonSendEventFunction(PR_EVENT_OBJECT_SELECTED, {
      show: false,
    });
  };

  setLeftSidebarMenuTab = (tab) => {
    this.props.onSetUIState({ key: 'lastLeftSideTab', value: tab });
  };

  onNotesAdded = (e) => {
    this.props.onSetNote(e.detail);
  };

  onNotesRemoved = (e) => {
    this.props.onRemoveNote({ id: e.detail });
  };

  onNoteTitleChanged = (e) => {
    const newTitle = e.detail.title;
    const id = e.detail.id;
    this.props.onSetNoteTitle({ id, title: newTitle });
  };

  onSelectionAdded = (e) => {
    const element = e.detail.element;
    const selection = e.detail.selection;

    // if we don't have that not in the list , please add it
    // This happens when we paste an element with a note on the canvas
    if (selection.length === 1 && !!element.notes) {
      // set add button , or show note

      const note = this.props.objectNotes.find((itm) => itm.id === element.id);
      if (!note) {
        this.props.onSetNote({
          id: element.id,
          noteData: element.notes,
        });
      }
    }

    this.props.onSetSelectedElements(selection.slice(), element);
  };

  onSelectionRemoved = (e) => {
    const selection = e.detail.selection;
    const elementRemoved = e.detail.element;
    this.props.onSetSelectedElements(selection.slice(), elementRemoved);
  };

  onCommandDeleteExecuted = (e) => {
    const data = e.detail;

    if (data.status === CommandStatus.ADD) {
      for (let i = 0; i < data.objects.length; i++) {
        const element = data.objects[i];
        if (element.notes) {
          this.props.onSetNote({ id: element.id, noteData: element.notes });
        }
      }
    } else if (data.status === CommandStatus.DELETE) {
      for (let i = data.objects.length - 1; i >= 0; i--) {
        const element = data.objects[i];
        if (element.notes) {
          this.props.onRemoveNote({ id: element.id });
        }
      }
    }
  };

  onApplyFilter = () => {
    if (!this.props.canvasPermissions.isAdminImpersonation) {
      this.resetErrorMsg();
      this.save(SAVE_TYPE_AUTO);
    }
  };

  openVersionHistory = () => {
    this.setVersionHistoryOpened(true);
    this.setHeaderActionsDropdownOpened(false);
  };

  onStepFocusChanged = (e) => {
    const filterType = e.detail.filterType;
    const step = e.detail.step;
    const hasFocus = e.detail.hasFocus;

    if (!hasFocus) {
      this.props.addFocusedStep({
        step: cloneData(step),
        filterTypes: [filterType],
      });
    } else {
      this.props.removeFocusedStep({
        step: cloneData(e.detail.step),
        filterTypes: [e.detail.filterType],
      });
    }
  };

  onFocusFrameCleared = (e) => {
    this.props.removeFocusedStep({
      step: cloneData(e.detail.step),
      filterTypes: [e.detail.filterType],
    });
  };

  onLeftSideCollapseCommand = (e) => {
    this.props.setLeftSideBarCollapsed(e.detail);
  };

  onShowLeftSideCreateStepMenu = (e) => {
    // It can either be MAP of EXPLORER
    this.props.setLeftSideBarCollapsed(false);
    commonSendEventFunction(RR_SET_LEFT_SIDE_TAB, LeftSideTab.MAP_OR_EXPLORER);
    this.setLeftSidebarMenuTab(LeftSideTab.MAP_OR_EXPLORER);
  };

  onCanvasChecklistChanged = (e) => {
    this.props.onSetCanvasChecklist(e.detail);
  };

  componentDidMount() {
    document.addEventListener(PR_EVENT_SAVE_RESPONSE, this.sendSaveResponse, false);
    document.addEventListener(PR_EVENT_REFRESH_RESPONSE, this.sendRefreshResponse, false);
    document.addEventListener(PR_EVENT_FUNNEL_CHANGED, this.onFunnelChanged, false);
    document.addEventListener(PR_EVENT_ANALYTICS_NEEDS_REFRESH, this.onAnalyticsNeedRefresh);
    document.addEventListener(PR_EVENT_ZOOM_LEVEL_CHANGED, this.setCurrentZoom);
    document.addEventListener(PR_EVENT_SESSION_SELECTED, this.onSessionSelected.bind(this));
    document.addEventListener(RR_SHOW_UPSELL_POPUP, this.onUpsellEvent.bind(this));
    window.addEventListener('resize', this.windowResizeCoordsRecalculationEvent);
    window.addEventListener('wheel', this.onWheel, { passive: false });

    document.addEventListener(RP_EVENT_REPORT_ADDED, this.onReportAdded, false);
    document.addEventListener(RP_EVENT_REPORT_REMOVED, this.onReportRemoved, false);
    document.addEventListener(RP_EVENT_LOAD_REQUEST, this.onFunnelLoaded.bind(this), false);
    document.addEventListener(
      PR_EVENT_ELEMENT_POINTER_UP,
      this.onFunnelElementPointerUp.bind(this),
      false,
    );
    document.addEventListener(
      PR_EVENT_ELEMENT_POINTER_DOWN,
      this.onFunnelElementPointerDown.bind(this),
      false,
    );

    document.addEventListener(PR_NOTES_LOADED, this.onNotesLoaded.bind(this), false);
    document.addEventListener(RR_NOTES_OPEN, this.onNotesOpen.bind(this), false);
    document.addEventListener(RR_CHECKLIST_OPEN, this.onChecklistOpen.bind(this), false);
    document.addEventListener(
      PR_EVENT_COMMAND_REMOVE_EXECUTED,
      this.onCommandDeleteExecuted.bind(this),
      false,
    );
    document.addEventListener(PR_NOTES_ADDED, this.onNotesAdded.bind(this), false);
    document.addEventListener(PR_NOTES_REMOVED, this.onNotesRemoved.bind(this), false);
    document.addEventListener(PR_NOTES_TITLE_UPDATED, this.onNoteTitleChanged.bind(this), false);
    document.addEventListener(PR_EVENT_SELECTION_ADDED, this.onSelectionAdded, false);
    document.addEventListener(PR_EVENT_SELECTION_REMOVED, this.onSelectionRemoved, false);
    document.addEventListener(PR_EVENT_STEP_FOCUS_CHANGED, this.onStepFocusChanged, false);
    document.addEventListener(RP_EVENT_FOCUS_FRAME_CLEARED, this.onFocusFrameCleared, false);
    document.addEventListener(
      PR_COMMAND_COLLAPSE_LEFT_SIDE_MENU,
      this.onLeftSideCollapseCommand,
      false,
    );

    document.addEventListener(
      RR_EVENT_ON_SHOW_LEFT_CREATE_STEP_MENU,
      this.onShowLeftSideCreateStepMenu,
      false,
    );

    document.addEventListener(
      PR_EVENT_CANVAS_CHECKLIST_CHANGED,
      this.onCanvasChecklistChanged.bind(this),
      false,
    );

    document.addEventListener(RR_SHOW_TUTORIAL_POPUP, this.onTutorialShow.bind(this), false);

    document.addEventListener(
      PR_EVENT_TRENDS_NEED_UPDATE,
      this.onTrendsNeedUpdate.bind(this),
      false,
    );
    document.addEventListener(
      PR_EVENT_FUNNEL_LOADED,
      this.onFunnelImportedToCanvas.bind(this),
      false,
    );

    document.addEventListener(
      PR_EVENT_THUMBNAIL_REQUESTED,
      this.onThumbnailRequested.bind(this),
      false,
    );

    this.props.onFetchFunnel(this.props.funnelId).then((result) => {
      if (result.success) {
        document.title = this.props.funnel.name;
        isAnalyticsInstalled(this.props.funnel.projectId).then((hasWorkspaceActions) => {
          this.setState(
            {
              funnelName: this.props.funnel.name,
              hasWorkspaceActions,
            },
            () => {
              //TODO: refactor this - just simply use css positioning
              this.windowResizeCoordsRecalculationEvent();
              this.loadRequest();
              this.loadCustomIcons();
            },
          );
          this.props.onLoadProjectApiKey(this.props.funnel.projectId);
        });

        this.setState({ isProfitwellScriptInstalled: true });
      }
    });
    this.props.loadTutorial();
  }

  preloadTrafficExplorerData(funnelConfiguration) {
    const filter = getExplorerFilterProperties({
      funnelConfiguration: funnelConfiguration,
      focusedSteps: funnelConfiguration.focusedSteps,
      search: '',
      pageNumber: 1,
      limit: 20,
    });

    const options = { peopleVsTotal: 'individual-people' };

    const reqBody = {
      filter,
      options,
    };

    requestService.getTrafficExplorerData(this.props.funnel.projectId, reqBody, true);
  }

  sendToStore(dateRangeValue, compare = false) {
    let intermediateValue;
    if (dateRangeValue.min !== dateRangeValue.max) {
      intermediateValue = {
        min: new Date(dateRangeValue.min).toISOString(),
        max: new Date(dateRangeValue.max).toISOString(),
        selectedLabel: dateRangeValue.selectedLabel,
      };
    } else {
      intermediateValue = {
        min: new Date(new Date(dateRangeValue.min).setHours(0, 0, 0, 0)).toISOString(),
        max: new Date(new Date(dateRangeValue.max).setHours(23, 59, 59, 0)).toISOString(),
        selectedLabel: dateRangeValue.selectedLabel,
      };
    }

    this.props.onSetDataRange(intermediateValue, compare);
  }

  onSelectDate(newValue) {
    if (this.props.analyticsStatus === ANALYTICS_STATUS_LOADING) {
      this.triggerCancelRefreshAnalyticsEvent();
      this.props.onSetAnalyticsStale();
      this.props.onSetFilter(newValue, this.props.allFilters);
      return;
    }

    const startEqual =
      newValue.inputStart === this.props.allFilters.previousFilters.commonFilters.inputStart;
    const finishEqual =
      newValue.inputFinish === this.props.allFilters.previousFilters.commonFilters.inputFinish;

    if (!startEqual || !finishEqual) {
      this.props.onSetAnalyticsStale();

      //TODO also update previous Filter
      this.props.onSetPreviousFilter(newValue, this.props.allFilters);
    }

    this.props.onSetFilter(newValue, this.props.allFilters);
  }

  onFilterBtnClicked() {
    this.setState({ isFiltersOpened: !this.state.isFiltersOpened });
  }

  onFilterCompareBtnClicked() {
    this.setState({ isCompareFiltersOpened: !this.state.isCompareFiltersOpened });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResizeCoordsRecalculationEvent);
    window.removeEventListener('wheel', this.onWheel);
    document.removeEventListener(PR_EVENT_ANALYTICS_NEEDS_REFRESH, this.onAnalyticsNeedRefresh);
    document.removeEventListener(RP_EVENT_REPORT_ADDED, this.onReportAdded, false);
    document.removeEventListener(RP_EVENT_REPORT_REMOVED, this.onReportRemoved, false);
    document.removeEventListener(PR_EVENT_SESSION_SELECTED, this.onSessionSelected);
    document.removeEventListener(RR_SHOW_UPSELL_POPUP, this.onUpsellEvent);
    document.removeEventListener(RP_EVENT_LOAD_REQUEST, this.onFunnelLoaded, false);
    document.removeEventListener(PR_EVENT_ELEMENT_POINTER_UP, this.onFunnelElementPointerUp, false);
    document.removeEventListener(
      PR_EVENT_ELEMENT_POINTER_DOWN,
      this.onFunnelElementPointerDown,
      false,
    );
    document.removeEventListener(
      PR_EVENT_COMMAND_REMOVE_EXECUTED,
      this.onCommandDeleteExecuted.bind(this),
      false,
    );
    document.removeEventListener(RR_NOTES_OPEN, this.onNotesOpen, false);
    document.removeEventListener(RR_CHECKLIST_OPEN, this.onChecklistOpen, false);
    document.removeEventListener(PR_NOTES_ADDED, this.onNotesAdded.bind(this), false);
    document.removeEventListener(PR_NOTES_REMOVED, this.onNotesRemoved.bind(this), false);
    document.removeEventListener(PR_NOTES_TITLE_UPDATED, this.onNoteTitleChanged.bind(this), false);
    document.removeEventListener(PR_EVENT_SELECTION_ADDED, this.onSelectionAdded, false);
    document.removeEventListener(PR_EVENT_SELECTION_REMOVED, this.onSelectionRemoved, false);
    document.removeEventListener(
      PR_EVENT_CANVAS_CHECKLIST_CHANGED,
      this.onCanvasChecklistChanged,
      false,
    );
    document.removeEventListener(RR_SHOW_TUTORIAL_POPUP, this.onTutorialShow.bind(this), false);
    document.removeEventListener(
      PR_EVENT_THUMBNAIL_REQUESTED,
      this.onThumbnailRequested.bind(this),
      false,
    );
  }

  render() {
    const { analyticsStatus, funnel } = this.props;

    const isAnalyticsLoading =
      analyticsStatus === ANALYTICS_STATUS_LOADING || this.props.explorerIsLoading;
    const refreshButtonStatusIcon = MAP_ANALYTICS_STATUS_TO_ICON.get(analyticsStatus);

    const { funnelRevisions: { 0: funnelLastRevision = {} } = {} } = this.props;
    const statusSaveText = this.state.isErrorSaving ? (
      <div onClick={this.onSaveClick} className={styles.Error}>
        Save failed. Click here to try again
      </div>
    ) : (
      this.humanizeInterval(funnelLastRevision)
    );
    const textSavedTime = this.state.isFunnelSaving ? 'Saving...' : statusSaveText;
    const isUniversalLoaderActive =
      this.props.isFunnelLoading || analyticsStatus === ANALYTICS_STATUS_LOADING;

    return (
      <>
        <div className={cls(styles.Wrapper)} id="header" ref={this.positionRef}>
          {this.state.showUpsellPopup ? (
            <UpsellPopup
              onClose={this.onUpsellClose.bind(this)}
              context={this.state.upsellPopupContext}
            />
          ) : (
            ''
          )}
          <div className={styles.Header}>
            {isUniversalLoaderActive && (
              <div className={styles.UniversalLoader}>
                <div className={styles.UniversalLoaderBar} />
              </div>
            )}

            <div className={styles.LeftSection}>
              <div className={styles.BackBtnIcon} onClick={this.onBackBtnIconClick}>
                {iconBackBtnArrow}
              </div>
              <div className={styles.Logo}>{iconLogo}</div>
              {this.state.funnelName && (
                <HeaderActionsDropdown
                  dropdownOpened={this.state.headerActionsDropdownOpened}
                  setDropdownToggle={this.setHeaderActionsDropdownOpened}
                  canvasName={this.state.funnelName}
                  dropdownBlock={<VersionHistoryDropdown onClick={this.openVersionHistory} />}
                />
              )}
              <ToolsBox
                funnel={this.props.funnel}
                activateSelectTool={this.state.activateSelectTool}
                onPanningActive={(isActive) => this.props.onPanningActive(isActive)}
                openModal={this.openModal}
                hasWorkspaceActions={this.state.hasWorkspaceActions}
                setReportToolStatus={this.onSetReportToolStatus}
                isReportToolActive={this.state.isReportToolActive}
                activeStepsModalTab={this.state.activeStepsModalTab}
                onStepsModalTabChanged={this.onStepsModalTabChanged}
                onWidgetOpened={this.onWidgetOpened}
                isReportToolDisabled={this.state.isReportToolDisabled || this.props.isFunnelLoading}
              />
              <Modal />
              <UILock lock={LOCK_NO_PLANNING}>
                <DateFilterBlock
                  inputStart={this.props.allFilters.currentFilters.commonFilters.inputStart}
                  inputFinish={this.props.allFilters.currentFilters.commonFilters.inputFinish}
                  type={FILTER_TYPE_DEFAULT}
                  onSelectDate={(newValue) => {
                    this.onSelectDate(newValue);
                  }}
                  sendToStore={(dateRangeValue, compare) => {
                    this.sendToStore(dateRangeValue, compare);
                  }}
                  isApplyButtonEnabled={false}
                  hasFilterChange={false}
                />
              </UILock>
              <FunnelDataMode />

              <div style={{ position: 'relative', height: '100%' }}>
                <FilterBtn
                  id="filter-btn"
                  isCompare={false}
                  onClick={() => {
                    this.onFilterBtnClicked();
                  }}
                  isActive={this.state.isFiltersOpened}
                />
                <When condition={this.props.funnel}>
                  <AllFiltersBlock
                    funnel={this.props.funnel}
                    onApplyFilter={this.onApplyFilter}
                    analyticsIsLoading={isAnalyticsLoading}
                    onClickCancelAnalytics={this.cancelAnalytics}
                    onClose={() => {
                      this.setState({ isFiltersOpened: false });
                    }}
                    isOpen={this.state.isFiltersOpened}
                  />
                </When>
              </div>

              <div style={{ position: 'relative', height: '100%' }}>
                <FilterBtn
                  id="filter-compare-btn"
                  isCompare={true}
                  onClick={() => {
                    this.onFilterCompareBtnClicked();
                  }}
                  isActive={this.state.isCompareFiltersOpened}
                />
                <When condition={this.props.funnel}>
                  <AllCompareFiltersBlock
                    funnel={this.props.funnel}
                    onApplyFilter={this.onApplyFilter}
                    analyticsIsLoading={isAnalyticsLoading}
                    onClickCancelAnalytics={this.cancelAnalytics}
                    onClose={() => {
                      this.setState({ isCompareFiltersOpened: false });
                    }}
                    isOpen={this.state.isCompareFiltersOpened}
                  />
                </When>
              </div>
              <RefreshAnalyticsBtn
                status={analyticsStatus}
                analyticsIsLoading={isAnalyticsLoading}
                onClick={this.onRefreshAnalyticsClick}
                explorerIsLoading={this.props.explorerIsLoading}
                refreshButtonStatusIcon={
                  this.props.canvasPermissions.isAnalyticsAllowed ? refreshButtonStatusIcon : null
                }
                canCancel={true}
                hoveredOnClick={this.cancelAnalytics}
              />
            </div>
            <div className={styles.RightSection}>
              {this.props.canvasPermissions.isAdminImpersonation ? (
                <div onClick={this.save} className={styles.SavedTime}>
                  <span>Admin Save</span>
                </div>
              ) : null}

              <When condition={this.props.canvasPermissions.isReadonlyAccess}>
                <div className={styles.SavedTimeSaving}>View Only</div>
              </When>

              <When condition={this.props.canvasPermissions.isTutorial}>
                <div className={styles.SavedTimeSaving}>Tutorial Mode</div>
              </When>

              <When
                condition={
                  !this.props.canvasPermissions.isReadonlyAccess &&
                  !this.props.canvasPermissions.isTutorial
                }
              >
                {this.state.isFunnelSaving ? (
                  <div className={styles.SavedTimeSaving}>{textSavedTime}</div>
                ) : !this.props.isFunnelRevisionsLoading && textSavedTime ? (
                  <div onClick={this.save} className={styles.SavedTime}>
                    <span>{textSavedTime}</span>
                  </div>
                ) : null}
              </When>

              <ZoomDropdown
                setDropdownOpened={this.setZoomDropdownOpened}
                draftedZoom={this.state.draftedZoom}
                dropdownOpened={this.state.zoomDropdownOpened}
                fitToScreenEvent={this.onFitToScreenClicked}
                handleZoomInputAccept={this.handleZoomInputAccept}
                handleZoomInputChange={this.handleZoomInputChange}
                zoomIn={this.onZoomInClicked}
                zoomOut={this.onZoomOutClicked}
                zoomReset={this.onZoomResetClicked}
                zoomValue={this.state.zoomValue}
                acceptCurrentZoom={this.acceptCurrentZoom}
              />
              <When condition={!this.props.canvasPermissions.isReadonlyAccess}>
                <Tooltip label={TEXTS_TOOLTIP.HELP}>
                  <div
                    className={styles.RightSectionButton}
                    onClick={() => {
                      this.showTutorialModal();
                      sendHeapTracking({
                        projectId: this.props.funnel.projectId,
                        eventName: HEAP_EVENTS.OPEN_CANVAS_TOUR,
                      });
                      trackCohesive(HEAP_EVENTS.OPEN_CANVAS_TOUR, {
                        projectId: this.props.funnel.projectId,
                      });
                    }}
                  >
                    <HelpLogo />
                  </div>
                </Tooltip>
              </When>
            </div>
          </div>
          {/* Used for showing messages on the canvas */}
          {/* <ServiceBreakdown /> */}

          <When
            condition={
              this.state.isCreateAccount &&
              this.props.canvasPermissions.isTutorial &&
              !this.props.auth.isAuthenticated
            }
          >
            <CreateAccountBanner />
          </When>

          {funnel ? (
            <LeftSideBar
              funnel={funnel}
              onApplyFilter={this.onApplyFilter}
              analyticsIsLoading={isAnalyticsLoading}
              onClickCancelAnalytics={this.cancelAnalytics}
              analyticsWasCancelled={this.props.selectAnalyticsWasCancelled}
              isMessageErrorShowing={this.props.isMessageErrorShowing}
              setIsMessageErrorShowing={this.props.setIsMessageErrorShowing}
              isViewOnlyLink={this.props.isViewOnlyLink}
            />
          ) : null}
          {this.state.versionHistoryOpened && funnel ? (
            <VersionHistory
              onClose={() => this.setVersionHistoryOpened(false)}
              isSidebarOpened={true}
              onRestoreFunnel={(params) => {
                this.cleanCanvas();
                this.loadRequest(params, true);
              }}
              funnelId={this.props.funnelId}
            />
          ) : null}
          {this.props.widgetOpened && <WidgetPopup />}
          {this.state.selectedSessions.map((data) => {
            return (
              <UserProfile
                key={data.data.id}
                countryName={data.data.country.name}
                countryCode={data.data.country.code}
                name={data.data.fullName}
                email={data.data.email}
                city={data.data.country.city}
                isMobile={data.data.device === 'mobile'}
                userID={data.data.id}
                createdAt={data.data.createdAt}
                isLoading={data.isLoading}
                journey={data.journey}
                onUserProfileClose={(id) => {
                  this.onUserProfileClose(id);
                }}
                position={data.position}
                widgetBounds={data.widgetBounds}
              />
            );
          })}
          <PopupWindows />
          <BaseModal
            isOpen={this.props.shareModalisOpen}
            onClose={() => {
              if (this.props.librarySharePreEnabled) {
                if (
                  confirm('You have unsaved setting changes. Are you sure you want to leave') ==
                  true
                ) {
                  this.props.setPreEnabled(false);
                  this.props.closeShareModal();
                  return;
                } else {
                  return;
                }
              }
              this.props.closeShareModal();
            }}
            className={ModalStyles}
          >
            <Share />
          </BaseModal>
          <LayersMenu />
          <Banner />
          <TrendsStepPicker />
          <When condition={this.state.isProfitwellScriptInstalled}>
            <ProfitwellScript />
          </When>
          <FloatingPopups />
          <ReactController />
        </div>
      </>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    funnelConfiguration: selectFunnelConfiguration(state),
    funnelRevisions: selectFunnelRevisions(state),
    funnel: selectFunnel(state, props.funnelId),
    analyticsStatus: selectAnalyticsStatus(state),
    compareModeEnabled: selectCompareMode(state),
    explorerIsLoading: selectExplorerIsLoading(state),
    isFunnelLoading: selectIsFunnelLoading(state),
    isFunnelRevisionsLoading: selectIsFunnelRevisionsLoading(state),
    widgetOpened: selectWidgetStatus(state),
    allFilters: selectFilters(state),
    selectIsStepModalOpened: selectIsStepModalOpened(state),
    selectIsStepModalPinned: selectIsStepModalPinned(state),
    selectStepModalData: selectStepModalData(state),
    selectIsCancelAnalyticsRequest: selectCancelAnalyticsRequest(state),
    selectAnalyticsWasCancelled: selectAnalyticsWasCancelled(state),
    currentStep: selectCurrentStep(state),
    isMigratedC1toC2: selectIsMigratedC1toC2(state),
    objectNotes: selectNotes(state),
    hasAnalyticsData: selectHasAnalyticsData(state),
    shareModalisOpen: selectorIsShareCanvasModalOpen(state),
    librarySharePreEnabled: selectorIsPreEnabled(state),
    hasNotesEdits: selectHasEdits(state),
    canvasPermissions: selectCanvasPermissions(state),
    auth: selectAuth(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCancelAnalyticsRequest: (bool) => dispatch(setCancelAnalyticsRequest(bool)),
    onLoadAnalyticsAsync: (
      projectId,
      funnelConfiguration,
      dataObjs,
      dataConnections,
      compareMode,
    ) =>
      dispatch(
        loadAnalyticsAsync(projectId, funnelConfiguration, dataObjs, dataConnections, compareMode),
      ),
    updateAnalyticsStatus: (status) => dispatch(updateStatus(status)),
    onFetchFunnel: (funnelId) => dispatch(fetchFunnelAsync(funnelId)),
    onLoadFunnel: (params) => dispatch(loadFunnelAsync(params)),
    onLoadCustomIcons: () => dispatch(loadCustomIconsAsync()),
    onPanningActive: (newValue) => dispatch(setPanToolActive(newValue)),
    onLoadProfileCountries: (
      projectId,
      funnelConfiguration,
      dataObjs,
      dataConnections,
      compareMode,
    ) =>
      dispatch(
        loadProfileCountriesAsync(
          projectId,
          funnelConfiguration,
          dataObjs,
          dataConnections,
          compareMode,
        ),
      ),
    onLoadTrends: (
      projectId,
      funnelConfiguration,
      dataObjs,
      dataConnections,
      widgetId,
      compareMode,
    ) =>
      dispatch(
        loadTrendsAsync(
          projectId,
          funnelConfiguration,
          dataObjs,
          dataConnections,
          widgetId,
          compareMode,
        ),
      ),

    onLoadSessions: ({ projectId, funnelConfiguration, dataObjs, dataConnections, compareMode }) =>
      dispatch(
        refreshSessionsAsync({
          projectId,
          funnelConfiguration,
          dataObjs,
          dataConnections,
          compareMode,
        }),
      ),
    onLoadFunnelRevisions: (funnelId) => dispatch(loadFunnelRevisionsAsync(funnelId)),
    onSetAnalyticsStale: () => dispatch(setAnalyticsStale()),
    onLoadProjectApiKey: (projectId) => dispatch(loadProjectApiKeyAsync(projectId)),
    onLoadSessionDetailsAsync: (projectId, sessionID, funnelConfiguration, data) =>
      dispatch(loadSessionDetailsAsync(projectId, sessionID, funnelConfiguration, data)),
    setIsStepModalOpened: (bool) => dispatch(setIsStepModalOpened(bool)),
    setIsStepModalPinned: (bool) => dispatch(setIsStepModalPinned(bool)),
    setStepModalData: (data) => dispatch(setStepModalData({ data })),
    resetStepModalData: () => dispatch(resetStepModalData()),
    setExplorerLoadingStatus: () => dispatch(setExplorerLoadingStatus(false)),
    setAnalyticsWasCancelled: (bool) => dispatch(setAnalyticsWasCancelled(bool)),
    setAnalyticsData: (data) => dispatch(setAnalyticsData(data)),
    resetPeopleTrackingPopups: () => dispatch(resetPeopleTrackingPopups()),
    resetPopupZIndex: () => dispatch(resetPopupZIndex()),
    onSetNotes: (data) => dispatch(setNotes(data)),
    onSetCanvasNote: (data) => dispatch(setCanvasNote(data)),
    onSetNote: (data) => dispatch(setNote(data)),
    onSetNoteTitle: (data) => dispatch(setNoteTitle(data)),
    onRemoveNote: (data) => dispatch(removeNote(data)),
    loadTutorial: () => dispatch(asyncGetTutorialCompleted()),
    onSetIsMigrationC1toC2: (bool) => dispatch(setIsMigrationC1toC2(bool)),
    onSetSelectedElements: (selectedElements, elementChanged) =>
      dispatch(setSelectedElements({ selectedElements, elementChanged })),
    toggleShowTutorial: () => dispatch(toggleShowing()),
    setTutorialTask: (index) => dispatch(setNewIndicies(index)),
    closeShareModal: () => dispatch(setIsCanvasShareModalOpen(false)),
    setPreEnabled: (bool) => dispatch(setPreEnabled(bool)),
    resetHasEditsAction: () => dispatch(resetHasEditsAction()),
    addFocusedStep: (data) => dispatch(addFocusedStep(data)),
    removeFocusedStep: (data) => dispatch(removeFocusedStep(data)),
    onSetCanvasChecklist: (data) => dispatch(setCanvasChecklist(data)),
    onSetUIState: (data) => dispatch(setUIState(data)),
    onSetFilter: (newValue, allFilters) => {
      dispatch(
        setFilter({
          type: FILTER_NAMES.CURRENT_FILTERS,
          filterName: FILTER_NAMES.COMMON_FILTERS,
          value: { ...allFilters.currentFilters.commonFilters, ...newValue },
        }),
      );
    },
    onSetPreviousFilter: (newValue, allFilters) => {
      dispatch(
        setFilter({
          type: FILTER_NAMES.PREVIOUS_FILTERS,
          filterName: FILTER_NAMES.COMMON_FILTERS,
          value: { ...allFilters.previousFilters.commonFilters, ...newValue },
        }),
      );
    },
    onSetDataRange: (intermediateValue, compare) =>
      dispatch(setDataRange(intermediateValue, compare)),
    onSetActiveDataLayer: (data) => dispatch(setActiveDataLayer(data)),
    onSetIsFiltersLoaded: (bool) => dispatch(setIsFiltersLoaded(bool)),
    getThumbnailImg: (data) => dispatch(getThumbnailImg(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
