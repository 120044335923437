import React from 'react';
import { iconPopupClose } from 'react-project/assets/Icons';
import { When } from 'react-project/Util/When';
import { Rnd } from 'react-rnd';
import { WidgetBottomResizeComponent } from './WidgetBottomResizeComponent';
import stylesCSS from './WidgetPopup.module.scss';

const UNDER_HEADER_OFFSET = 60;
const POPUP_HEIGHT_VALUE = 393;

export const PopUpWrapper = ({
  children,
  icon,
  title,
  position,
  showCloseIcon,
  menuActions = [],
  onClosePopup,
  isDraggable = true,
  cancel,
  height,
  width,
  onIconClick = () => {},
  minWidth,
  rndRef,
  className,
  onDragStop = () => {},
  onDragStart = () => {},
  styles = {},
}) => {
  const wrapperPosition = position
    ? {
        x: position.x,
        y: position.y + UNDER_HEADER_OFFSET,
      }
    : { x: 0, y: -UNDER_HEADER_OFFSET };

  const optionalCancel = () => {
    if (cancel) {
      return '.' + cancel;
    }
    return '.defaultRndCancel';
  };
  return (
    <Rnd
      ref={rndRef}
      default={{
        width,
        height,
        minWidth,
        ...wrapperPosition,
      }}
      disableDragging={!isDraggable}
      minWidth={minWidth}
      minHeight={height}
      bounds="window"
      resizeHandleComponent={{ bottomRight: <WidgetBottomResizeComponent /> }}
      enableResizing={{
        top: false,
        right: false,
        bottom: true,
        left: false,
        topRight: false,
        bottomRight: true,
        bottomLeft: false,
        topLeft: false,
      }}
      cancel={optionalCancel()}
      className={className}
      onDragStart={onDragStart}
      onDragStop={onDragStop}
      style={{ pointerEvents: 'auto', ...styles }}
    >
      <div className="h-full bg-white rounded shadow flex flex-col overflow-y-hidden">
        <div
          className="p-4 cursor-move cursor-grab border-b-[1px] border-slate-300"
          style={{
            padding: '5px 15px',
            cursor: 'move' /* fallback if grab cursor is unsupported */,
            cursor: 'grab',
            cursor: '-moz-grab',
            cursor: '-webkit-grab',
          }}
        >
          <div className="bg-white flex items-center w-full justify-between">
            <div className="flex items-center cursor-pointer text-gray-500" onClick={onIconClick}>
              {icon}
              <div className="ml-2 flex center text-gray-500 text-xs">{title}</div>
            </div>
            <div className="flex">
              {menuActions.map((component) => {
                return component;
              })}
              <When condition={showCloseIcon}>
                <div className={stylesCSS.SideIcon} onClick={() => onClosePopup()}>
                  {iconPopupClose}
                </div>
              </When>
            </div>
          </div>
        </div>
        {children}
      </div>
    </Rnd>
  );
};

PopUpWrapper.defaultProps = {
  height: POPUP_HEIGHT_VALUE,
};

PopUpWrapper.propTypes = {};
