import React, { useEffect, useState } from 'react';
import styles from 'react-project/components/styleReportPopup/StyleReportPopup.module.scss';
import colorPickerStyle from 'react-project/components/colorPicker/ColorPicker.module.scss';
import toolbarStyles from 'react-project/Toolbar/Toolbar.module.scss';
import { iconReportTool, iconPopupClose } from '../../assets/Icons';
import cls from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  ColorPickerConfig,
  IMAGE_MODE_FILL,
  IMAGE_MODE_FIT_TO_VIEW,
  IMAGE_MODE_ORIGINAL,
  IMAGE_MODE_STRETCH,
} from 'shared/CSharedConstants';
import { ColorPickerItem } from 'react-project/components/colorPicker/ColorPickerItem';
import { selectCustomIcons } from 'react-project/redux/custom-icons/selectors';
import { IconList } from '../iconList/IconList';
import { Switch } from 'react-project/components/switch/Switch';
import produce from 'immer';
import { setCustomIconsAction } from 'react-project/redux/custom-icons/actions';
import { When } from 'react-project/Util/When';
import { FileUploader } from '../fileUploader/FileUploader';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import {
  PR_REPORT_STYLE_APPLICATION_CHANGED,
  PR_REPORT_STYLE_CHANGED,
  PR_REPORT_STYLE_GLOBAL_APPLIED,
} from 'shared/CSharedEvents';
import { PopUpWrapper } from '../popUp/PopUpWrapper';
import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';
import RequestService from 'react-project/Helpers/RequestService';

export const StyleReportPopup = ({ onStyleReportPopupClose, reportSlide }) => {
  const [color, setColor] = useState(reportSlide.style.color);
  const [selectedMode, setSelectedMode] = useState(reportSlide.style.mode);
  const [selectedImage, setSelectedImage] = useState(reportSlide.style.image);
  const [hasImage, setHasImage] = useState(!!reportSlide.style.image);
  const [isGlobalApplied, setIsGlobalApplied] = useState(false);
  const customIcons = useSelector(selectCustomIcons);
  const [isUploaderOpened, setIsUploaderOpened] = useState(null);
  const dispatch = useDispatch();
  const iconType = 'REPORT';

  useEffect(() => {
    document.addEventListener(PR_REPORT_STYLE_GLOBAL_APPLIED, onGlobalStyleApplied);
    return () => {
      document.removeEventListener(PR_REPORT_STYLE_GLOBAL_APPLIED, onGlobalStyleApplied);
    };
  }, []);

  useEffect(() => {
    setColor(reportSlide.style.color);
    setSelectedMode(reportSlide.style.mode);
    setSelectedImage(reportSlide.style.image);
  }, [reportSlide.style]);

  useEffect(() => {
    setHasImage(!!reportSlide.style.image);
  }, [reportSlide.style.image]);

  const onClose = () => {
    onStyleReportPopupClose();
  };

  const onClickOutside = (value) => {
    onStyleReportPopupClose();
  };

  const selectedModeClass = (mod) => {
    return cls(styles.ImageMode, selectedMode === mod ? styles.ImageModeActive : null);
  };

  const getAvailableColors = () => {
    return ColorPickerConfig.filter((itm) => itm.color !== 'transparent');
  };

  const getCustomIcons = (type) => {
    const iconType = type.toLowerCase();
    return customIcons
      .filter((item) => item.type === iconType)
      .sort((a, b) => {
        if (a.created_at === b.created_at) {
          return 0;
        }
        return a.created_at > b.created_at ? 1 : -1;
      });
  };

  const onIconClick = (data) => {
    setSelectedImage(data.src);
    const style = getStyle();
    style.image = data.src;
    commonSendEventFunction(PR_REPORT_STYLE_CHANGED, { style, reportSlide });
  };

  const onAddIconClick = (data) => {
    setIsUploaderOpened(data);
  };

  const onIconCloseClick = async (e) => {
    const requestService = new RequestService();

    const iconId = e.detail.id;
    await requestService.deleteIcon(iconId);

    const newItems = produce(customIcons, (draft) => {
      const index = draft.findIndex((item) => item.id === iconId);
      if (index !== -1) draft.splice(index, 1);
    });

    dispatch(setCustomIconsAction(newItems));
  };

  const setActiveColor = (newColor) => {
    setColor(newColor);
    const style = getStyle();
    style.color = newColor;
    commonSendEventFunction(PR_REPORT_STYLE_CHANGED, { style, reportSlide });
  };

  const onImageModeSelected = (e) => {
    const mode = parseInt(e.currentTarget.dataset.mode);
    setSelectedMode(mode);
    const style = getStyle();
    style.mode = mode;
    commonSendEventFunction(PR_REPORT_STYLE_CHANGED, { style, reportSlide });
  };

  const onApplySwitchToggle = (newState) => {
    const style = getStyle();
    commonSendEventFunction(PR_REPORT_STYLE_APPLICATION_CHANGED, {
      style,
      reportSlide,
      isGlobal: newState,
    });
  };

  const onImageSwitchToggle = (newState) => {
    setHasImage(newState);

    const style = getStyle();
    const oldImage = style.image;
    style.image = newState ? selectedImage : null;
    const newImage = style.image;

    // send event only if there is a change
    if (oldImage !== newImage) {
      commonSendEventFunction(PR_REPORT_STYLE_CHANGED, { style, reportSlide });
    }
  };

  const getStyle = () => {
    return {
      color: color,
      image: hasImage ? selectedImage : null,
      mode: selectedMode,
    };
  };

  const onFileUploaded = (fileID, fileName, imageURL, uploadType) => {
    if (imageURL) {
      const imageData = {
        id: fileID,
        name: fileName,
        path: imageURL,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        type: uploadType.toString().toLowerCase(),
      };

      const newItems = produce(customIcons, (draft) => {
        draft.push(imageData);
      });

      dispatch(setCustomIconsAction(newItems));
    }
    setIsUploaderOpened(null);
  };

  const onFileUploaderClose = (e) => {
    setIsUploaderOpened(null);
  };

  const onGlobalStyleApplied = (e) => {
    setIsGlobalApplied(e.detail.isGlobal);
  };

  return (
    <ClickOutsideCustomComponent
      onClickOutside={() => {
        onClickOutside();
      }}
      ignoreClickOutside={!!isUploaderOpened}
    >
      <PopUpWrapper
        icon={iconReportTool}
        title="Customize Slide"
        showCloseIcon={true}
        onClosePopup={() => onClose()}
        cancel={toolbarStyles.StrippedTableRow}
        minWidth="400px"
        minHeight="450px"
        width="400px"
        height="580px"
        position={{ x: window.innerWidth / 2 - 400 / 2, y: 0 }}
        styles={{ zIndex: 10 }}
      >
        <div className={styles.Wrapper}>
          <div className={styles.ItemRow}>
            Background Color
            <div
              className={cls(colorPickerStyle.Wrapper, styles.ColorWrapper)}
              style={{ gridTemplateColumns: 'repeat(auto-fit, 35px)' }}
            >
              {getAvailableColors().map((item) => (
                <ColorPickerItem
                  key={item.class}
                  setActive={setActiveColor}
                  isActive={color === item.color}
                  value={item}
                />
              ))}
            </div>
          </div>

          <div className={cls(styles.ItemRow, styles.CustomImageWrap)}>
            Custom image
            <Switch isActive={hasImage} onToggle={onImageSwitchToggle} />
          </div>

          <div className={styles.ItemRow} style={{ marginTop: '5px', opacity: hasImage ? 1 : 0.5 }}>
            <div className={styles.ImageModeWrapper}>
              <When condition={!hasImage}>
                <div className={styles.DisableRow}></div>
              </When>
              <div
                onClick={onImageModeSelected}
                data-mode={IMAGE_MODE_FIT_TO_VIEW}
                className={selectedModeClass(IMAGE_MODE_FIT_TO_VIEW)}
              >
                <span>Fit to view</span>
              </div>
              <div className={styles.ImageSeparator}></div>
              <div
                onClick={onImageModeSelected}
                data-mode={IMAGE_MODE_FILL}
                className={selectedModeClass(IMAGE_MODE_FILL)}
              >
                <span>Fill</span>
              </div>
              <div className={styles.ImageSeparator}></div>
              <div
                onClick={onImageModeSelected}
                data-mode={IMAGE_MODE_STRETCH}
                className={selectedModeClass(IMAGE_MODE_STRETCH)}
              >
                <span>Stretch</span>
              </div>
              <div className={styles.ImageSeparator}></div>
              <div
                onClick={onImageModeSelected}
                data-mode={IMAGE_MODE_ORIGINAL}
                className={selectedModeClass(IMAGE_MODE_ORIGINAL)}
              >
                <span>Original</span>
              </div>
            </div>
          </div>

          <div
            className={cls(styles.ItemRow, styles.ImagesList)}
            style={{ opacity: hasImage ? 1 : 0.5 }}
          >
            <When condition={!hasImage}>
              <div className={styles.DisableRow} style={{ top: 0, bottom: 0 }}></div>
            </When>
            <IconList
              useTooltip={false}
              type={iconType}
              parentComponent="StyleReportPopup"
              onIconClick={onIconClick}
              onDragEnd={() => false}
              textSearch={''}
              customUploadedIcons={getCustomIcons(iconType)}
              onAddIconClick={onAddIconClick}
              onCloseClick={onIconCloseClick}
            />
          </div>

          <div className={cls(styles.ItemRow, styles.ApplyWrap)}>
            Apply style to all the slides
            <Switch isActive={isGlobalApplied} onToggle={onApplySwitchToggle} />
          </div>
        </div>
      </PopUpWrapper>

      <When condition={isUploaderOpened}>
        <FileUploader
          uploadType={iconType}
          onFileUploaderClose={onFileUploaderClose}
          onFileUploaded={onFileUploaded}
          position={{ x: window.innerWidth / 2 - 400 / 2 + 50, y: 50 }}
        ></FileUploader>
      </When>
    </ClickOutsideCustomComponent>
  );
};
