import React from 'react';
import cx from 'classnames';

export function SmallText({ children, className, ...props }) {
  return (
    <p className={cx(className, 'text-xs text-slate-700 text-left')} {...props}>
      {children}
    </p>
  );
}

export default SmallText;
