import React from 'react';
import styles from './LeftSideMenu.module.scss';
import { explorerIcon, iconCheck, iconNotesMenu, mapIcon } from '../../assets/Icons';
import { When } from 'react-project/Util/When';
import { FF_PLANNING_NOTES, FF_CHECKLIST } from 'shared/FeatureFlags';
import { TooltipWithHotkey } from 'react-project/components/tooltip/TooltipWithHotkey';
import {
  LOCK_NO_ANALYTICS,
  LOCK_NO_CHECKLIST,
  LOCK_NO_NOTES,
  UILock,
} from 'react-project/components/uilock/UILock';

export const LeftSideTab = {
  NOTES: 'notes',
  TASKS: 'tasks',
  CHECKLIST: 'checklist',
  MAP: 'map',
  EXPLORER: 'explorer',
  MAP_OR_EXPLORER: 'map_or_explorer',
};

const labels = {
  [LeftSideTab.MAP]: 'Map',
  [LeftSideTab.EXPLORER]: 'Explore',
  [LeftSideTab.NOTES]: 'Notes',
  [LeftSideTab.CHECKLIST]: 'Checklists',
};

export const LeftSideMenu = ({ onItemClicked, activeTab, isViewOnlyLink }) => {
  const isMapActive = activeTab === LeftSideTab.MAP;
  const isExplorerActive = activeTab === LeftSideTab.EXPLORER;
  const isNotesActive = activeTab === LeftSideTab.NOTES;
  const isListActive = activeTab === LeftSideTab.CHECKLIST;

  return (
    <div className={`${styles.LeftSideMenu}`}>
      <div className={`${styles.LeftSideMenuWrapper}`}>
        <When condition={!isViewOnlyLink}>
          <TooltipWithHotkey label={labels[LeftSideTab.MAP]} position={'right'}>
            <div
              data-name={LeftSideTab.MAP}
              className={`${styles.LeftSideMenuButton} ${isMapActive ? styles.ActiveButton : ''}`}
              onClick={onItemClicked}
            >
              <div className={styles.LeftSideMenuButtonIcon}>{mapIcon}</div>
            </div>
          </TooltipWithHotkey>
          <TooltipWithHotkey label={labels[LeftSideTab.EXPLORER]} position={'right'}>
            <UILock lock={LOCK_NO_ANALYTICS} style={{ height: 'auto' }}>
              <div
                data-name={LeftSideTab.EXPLORER}
                className={`${styles.LeftSideMenuButton} ${
                  isExplorerActive ? styles.ActiveButton : ''
                }`}
                onClick={onItemClicked}
              >
                <div className={styles.LeftSideMenuButtonIcon}>{explorerIcon}</div>
              </div>
            </UILock>
          </TooltipWithHotkey>
        </When>

        <When condition={FF_PLANNING_NOTES}>
          <TooltipWithHotkey label={labels[LeftSideTab.NOTES]} position={'right'}>
            <UILock lock={LOCK_NO_NOTES} style={{ height: 'auto' }}>
              <div
                data-name={LeftSideTab.NOTES}
                className={`${styles.LeftSideMenuButton} ${
                  isNotesActive ? styles.ActiveButton : ''
                }`}
                onClick={onItemClicked}
              >
                <div className={styles.LeftSideMenuButtonIcon}>{iconNotesMenu()}</div>
              </div>
            </UILock>
          </TooltipWithHotkey>
        </When>

        <When condition={FF_CHECKLIST}>
          <TooltipWithHotkey label={labels[LeftSideTab.CHECKLIST]} position={'right'}>
            <UILock lock={LOCK_NO_CHECKLIST} style={{ height: 'auto' }}>
              <div
                data-name={LeftSideTab.CHECKLIST}
                className={`${styles.LeftSideMenuButton} ${
                  isListActive ? styles.ActiveButton : ''
                }`}
                onClick={onItemClicked}
              >
                <div className={styles.LeftSideMenuButtonIcon}>{iconCheck}</div>
              </div>
            </UILock>
          </TooltipWithHotkey>
        </When>
      </div>
    </div>
  );
};
