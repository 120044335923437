import React from 'react';
import { TutorialContent } from './Content';
import { TaskNavigation } from './TaskNavigation';

export const Body = () => {
  return (
    <div className="flex h-full">
      <TaskNavigation />
      <TutorialContent />
    </div>
  );
};
