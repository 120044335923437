import cls from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import {
  facebookAd,
  iconBackBtnArrow,
  iconFilter,
  iconHorizontalArrows,
  iconLink,
} from 'react-project/assets/Icons';
import { Dropdown, DropdownFlatItems } from 'react-project/components/dropdown/Dropdown';
import { PopUpWrapper } from 'react-project/components/popUp/PopUpWrapper';
import { SearchInput } from 'react-project/components/popUp/search/SearchInput';
import { DropdownTrigger } from 'react-project/components/trackingTab/Attribute';
import { RefreshButton } from 'react-project/components/v2/refreshButton';
import {
  POSITIONING_RIGHT_TO_LEFT,
  ViewportConstrainer,
} from 'react-project/components/viewportConstrainer/ViewportConstrainer';
import {
  fetchAllFacebookAdsCampaigns,
  resetAllAdData,
  setDefaultCollection,
} from 'react-project/features';
import {
  setNewCurrentStepFilterParams,
  setNewCurrentStepIntegrationType,
  setNewCurrentStepTrackingUrl,
} from 'react-project/redux/current-step/actions';
import { selectCurrentStep } from 'react-project/redux/current-step/selectors';
import { selectFunnelConfiguration } from 'react-project/redux/funnel-configuration/selectors';
import { selectCanvasPermissions, selectPermissions } from 'react-project/redux/user/selectors';
import styles from 'react-project/Toolbar/Toolbar.module.scss';
import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';
import {
  FACEBOOK_INTEGRATION,
  isIntegrationInstalled,
} from 'react-project/Util/IntegrationsDetector';
import { When } from 'react-project/Util/When';
import { useDispatch, useSelector } from 'react-redux';
import { IntegrationTypes } from 'shared/CSharedConstants';
import { RP_EVENT_EDIT_OBJECT } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { FacebookIntegration } from '../../FacebookIntegration';
import { AttributeExplorer } from './AttributeExplorer/AttributeExplorer';
import { EditableKeyValueTable } from './EditableKeyValueTable';
import { DEFAULT_OPERATOR, SourceOperators } from './LogicalOperators';
import { SourceTrackingParameters } from './SourceTrackingParameters';

const TITLE = 'Tracking Settings';

const dropDownItemValues = [
  { value: IntegrationTypes.URL_PARAMETERS, label: 'URL Parameters' },
  { value: IntegrationTypes.NONE, label: 'Referring URL' },
  { value: IntegrationTypes.DIRECT_TRAFFIC, label: 'Direct Traffic' },
];

const FACEBOOK_AD_LABEL = 'Facebook Ad';
const FACEBOOK_AD_INTEGRATION = {
  value: IntegrationTypes.FACEBOOK_AD,
  label: 'Facebook Ad integration',
};

export const SourceTracking = (props) => {
  const { projectId, onClose, isNumbersActive } = props;
  const dispatch = useDispatch();
  const canvasPermissions = useSelector(selectCanvasPermissions);
  let hasFbAdIntegrationPermission = false;
  const fbAdIntegrationPermission = useSelector(selectPermissions);
  if (fbAdIntegrationPermission) {
    hasFbAdIntegrationPermission = fbAdIntegrationPermission.some(
      (el) => el.name === 'facebook_integration' && el.permitted,
    );
  }

  /** Funnel Configuration and Permission */
  const isIntegrationConfigured = isIntegrationInstalled(projectId, FACEBOOK_INTEGRATION);
  let integrationAttributes, funnelConfiguration;
  if (isIntegrationConfigured) {
    integrationAttributes = useSelector(
      ({ currentStep }) => currentStep.object.integrationAttributes,
    );
    funnelConfiguration = useSelector(selectFunnelConfiguration);
  }

  // const showDeveloperContent = useSelector(selectShowDeveloperContent);
  const currentStep = useSelector(selectCurrentStep);
  const isCollectionInRedux = useSelector(
    ({ facebookIntegration }) => !!facebookIntegration.stepIds[currentStep.stepId],
  );
  if (!isCollectionInRedux) {
    dispatch(setDefaultCollection({ stepId: currentStep.stepId }));
  }
  const isLoading = useSelector(({ facebookIntegration }) => {
    return facebookIntegration.stepIds[currentStep.stepId].isLoading;
  });
  const { trackingURL, filterData } = currentStep.object;
  const dropdownValue = () => {
    // Disable Facebook Ad Integration
    // TODO maybe this needs to be behind a feature flag
    // if (currentStep.object.label === FACEBOOK_AD_LABEL) {
    //   return FACEBOOK_AD_INTEGRATION;
    // } else {
    return (
      dropDownItemValues.find((el) => el.value === currentStep.object.integrationType) ||
      dropDownItemValues[0]
    );
    // }
  };

  const refTrackingURL = useRef();
  const refPopupWrapper = useRef();
  const refPopupRnd = useRef();

  refTrackingURL.current = trackingURL;

  const [isValueDropdownOpened, setIsValueDropdownOpened] = useState(false);
  const [propertyDropdownValue, setPropertyDropdownValue] = useState(dropdownValue());
  const [pointerDownValue, setPointerDownValue] = useState(-1);
  const [isTrackingParamsOpened, setTrackingParamsOpened] = useState(false);
  const [hasSourceParameters, setHasSourceParameters] = useState(filterData.length > 0);
  const [isBackBtnVisible, setisBackBtnVisible] = useState(false);

  useEffect(() => {
    return () => {
      commonSendEventFunction(RP_EVENT_EDIT_OBJECT, {
        stepId: currentStep.stepId,
        trackingURL: refTrackingURL.current,
      });
    };
  }, []);

  useEffect(() => {
    setHasSourceParameters(filterData.length > 0);
  }, [filterData]);

  useEffect(() => {
    if (propertyDropdownValue === dropDownItemValues[1]) {
      refPopupRnd.current.updateSize({ width: 400, height: 400 });
    }
    if (propertyDropdownValue === FACEBOOK_AD_INTEGRATION) {
      refPopupRnd.current.updateSize({ width: 800, height: 400 });
    }
  }, [propertyDropdownValue]);

  const onContainerPointerDown = (e) => {
    setPointerDownValue(Math.random());
  };

  const onSelectProperty = (property) => {
    setPropertyDropdownValue(property);
    setIsValueDropdownOpened(false);
    dispatch(setNewCurrentStepIntegrationType(property.value));
    commonSendEventFunction(RP_EVENT_EDIT_OBJECT, {
      stepId: currentStep.stepId,
      integrationType: property.value,
    });
  };

  const validateItem = (key, value) => {
    if (key === '') {
      return false;
    }
    return true;
  };

  const updateKeyPairs = (pairs) => {
    const newItems = [];

    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i];
      const key = pair.key;
      const value = pair.value;
      const operator = pair.operator || DEFAULT_OPERATOR;

      if (!validateItem(key, value)) {
        continue;
      }

      // this will remove the last comma and any whitespace after it
      let itemValue = value.replace(/,\s*$/, '').trim();
      const multipleValues = itemValue
        .split(',')
        .filter((itm) => itm !== '')
        .map((itm) => {
          return itm.trim();
        });
      if (multipleValues.length > 1) {
        itemValue = multipleValues;
      }

      const item = {
        key,
        value: itemValue,
        operator,
      };

      newItems.push(item);
    }

    updateStepFilterData(newItems);
  };

  const parseURL = (url) => {
    const splitData = url.toString().split('?');
    const baseUrl = splitData[0];
    const dataToSplit = splitData[1] ? splitData[1] : splitData[0];
    const parameters = splitParameters(dataToSplit);

    return {
      url: baseUrl,
      parameters: parameters,
    };
  };

  const splitParameters = (data) => {
    const parameters = [];
    const pairs = data.toString().split('&');
    for (let i = 0; i < pairs.length; i++) {
      let pair = pairs[i].split('=');
      parameters.push({
        key: decodeURIComponent(pair[0]),
        value: pair[1] === undefined ? '' : decodeURIComponent(pair[1]),
      });
    }
    return parameters;
  };

  const onTrackingParametersChanged = (params) => {
    updateStepFilterData(params);
  };

  const updateStepFilterData = (newFilterData) => {
    dispatch(setNewCurrentStepFilterParams(newFilterData));
    commonSendEventFunction(RP_EVENT_EDIT_OBJECT, {
      stepId: currentStep.stepId,
      label: currentStep.object.label,
      filterData: newFilterData,
    });
  };

  const onInputChange = (value) => {
    const parsedData = parseURL(value);
    if (parsedData.parameters.length) {
      updateKeyPairs(parsedData.parameters);
    }
  };

  const onInputEnter = (value) => {
    if (canvasPermissions.isReadonlyAccess) return;
    onInputChange(value);
  };

  const onInputPaste = (value) => {
    if (canvasPermissions.isReadonlyAccess) return;
    onInputChange(value);
  };

  // Refering url methods

  const onURLInputEnter = (e) => {
    if (canvasPermissions.isReadonlyAccess) return;
    onTrackingUrlChangeEnded();
  };

  const onTrackingParamsOpenedToggle = () => {
    if (trackingURL === '' && !isTrackingParamsOpened) {
      return;
    }
    setTrackingParamsOpened(!isTrackingParamsOpened);
  };

  const onTrackingParamsCloseBtnClicked = () => {
    onTrackingParamsOpenedToggle();
  };

  const onTrackingUrlChangeEnded = () => {
    commonSendEventFunction(RP_EVENT_EDIT_OBJECT, {
      stepId: currentStep.stepId,
      label: currentStep.object.label,
      filterData: currentStep.object.filterData,
      trackingURL: trackingURL,
    });
  };

  const onTrackingUrlBlur = (e) => {
    dispatch(setNewCurrentStepTrackingUrl(e.target.value));
    onTrackingUrlChangeEnded();
  };

  const onURLKeyUp = (e) => {
    if (e.key === 'Enter') {
      onURLInputEnter(e);
    }
  };

  const onURLChange = (e) => {
    if (canvasPermissions.isReadonlyAccess) return;
    e.stopPropagation();
    dispatch(setNewCurrentStepTrackingUrl(e.target.value));
  };

  const handleBackButton = (visible) => {
    setisBackBtnVisible(visible);
  };

  const onAttributesChanged = (newFilters) => {
    updateStepFilterData(newFilters);
  };

  const refreshAll = () => {
    const dispatch = useDispatch();
    const onClick = () => {
      if (isLoading) return;

      dispatch(resetAllAdData(currentStep.stepId));
      dispatch(
        fetchAllFacebookAdsCampaigns({
          stepId: currentStep.stepId,
          projectId,
          timeRange: funnelConfiguration.dateRange,
          integrationAttributes,
        }),
      );
    };
    const refBtn = (hasPermission) => {
      let component;
      if (hasPermission) {
        component = (
          <div className="px-2 flex" key={`srctrk-1`}>
            <RefreshButton isLoading={isLoading} onClick={onClick} />
          </div>
        );
      } else {
        component = null;
      }
      return component;
    };
    return refBtn(isIntegrationConfigured);
  };
  const iconChanger = () => {
    if (propertyDropdownValue === FACEBOOK_AD_INTEGRATION) {
      return <div className="max-w-[20px] max-h-[20px]">{facebookAd()}</div>;
    }
    return isBackBtnVisible ? iconBackBtnArrow : iconHorizontalArrows;
  };

  const setMinWidth = () => {
    return propertyDropdownValue === dropDownItemValues[0] ||
      propertyDropdownValue === FACEBOOK_AD_INTEGRATION
      ? '800px'
      : '400px';
  };
  const searchRef = useRef();

  return (
    <>
      {/* <div ref={refPopupWrapper} onPointerDown={onContainerPointerDown}> */}
      <PopUpWrapper
        icon={iconChanger()}
        onIconClick={() => handleBackButton(false)}
        title={TITLE}
        showCloseIcon={true}
        onClosePopup={() => onClose()}
        cancel={'rndCancel'}
        minWidth={setMinWidth()}
        rndRef={refPopupRnd}
        menuActions={[refreshAll()]}
      >
        <div className={cls(styles.AtrributeExplorerWrapper, 'rndCancel cursor-auto')}>
          <div className={styles.ParameterWindow}>
            <When condition={propertyDropdownValue !== FACEBOOK_AD_INTEGRATION}>
              <div className={styles.Label}>
                <p className={styles.SubLabel}>
                  This step represents people that come from the following
                </p>
                <div className={styles.LabelBlock}>
                  <label className={styles.SideBarLabel}>Source</label>
                  <div className={styles.LabelWrapper}>
                    <ClickOutsideCustomComponent
                      onClickOutside={() => {
                        setIsValueDropdownOpened(false);
                      }}
                    >
                      <Dropdown
                        className={styles.AttributesDropdown}
                        triggerSlot={
                          <DropdownTrigger
                            value={propertyDropdownValue.label}
                            placeholder="Value"
                            isOpen={isValueDropdownOpened}
                          />
                        }
                        isOpen={isValueDropdownOpened}
                        onToggle={(o) => {
                          if (canvasPermissions.isReadonlyAccess) return;
                          setIsValueDropdownOpened(o);
                        }}
                        disabled={canvasPermissions.isReadonlyAccess}
                      >
                        <div>
                          <DropdownFlatItems
                            list={dropDownItemValues}
                            onSelect={(el) => onSelectProperty(el)}
                          />
                        </div>
                      </Dropdown>
                    </ClickOutsideCustomComponent>
                  </div>
                </div>
              </div>
            </When>

            <When condition={propertyDropdownValue === dropDownItemValues[0]}>
              <SearchInput
                inputMaxLength={''}
                icon={iconLink}
                value=""
                onEnterClicked={onInputEnter}
                onPaste={onInputPaste}
                placeholder="Paste a link to add your paramaters automatically"
                cleanOnEnter={true}
              />
              <EditableKeyValueTable
                pairsData={filterData}
                onKeyValueChanged={onTrackingParametersChanged}
                isExternalPointerDown={pointerDownValue}
                availableOperators={SourceOperators}
              />
            </When>

            <When condition={propertyDropdownValue === dropDownItemValues[1]}>
              <div className={styles.Label}>
                <div className={styles.LabelParamBlock}>
                  <div className={styles.LabelEl}>Traffic from refering URLs</div>
                  <div className={styles.TrafficParamBlock}>
                    <div
                      className={cls(styles.StrippedTableRow, styles.TrafficURL, {
                        [styles.notEmpty]: trackingURL !== '',
                      })}
                    >
                      <input
                        autoFocus={true}
                        value={trackingURL}
                        onBlur={onTrackingUrlBlur}
                        placeholder={'Enter URL'}
                        type="text"
                        onKeyUp={onURLKeyUp}
                        onChange={onURLChange}
                        className={`${styles.RsInput}`}
                        disabled={canvasPermissions.isReadonlyAccess}
                      />
                    </div>
                    <div
                      className={cls(
                        styles.TrafficBTN,
                        { [styles.TrafficBTNActive]: hasSourceParameters },
                        { [styles.TrafficBTNShown]: isTrackingParamsOpened },
                        trackingURL === '' ? styles.TrafficBTNDisabled : null,
                      )}
                      onClick={onTrackingParamsOpenedToggle}
                    >
                      URL parameters
                      {iconFilter}
                    </div>
                  </div>
                </div>
                <div className={styles.LabelBlock} />
              </div>
            </When>

            <When condition={propertyDropdownValue === dropDownItemValues[2]}>
              <div className={styles.DirectTrafficWrapper}>
                <div className={styles.LabelEl}>
                  Analyze the traffic from users who directly visit your website. Enter the URL of
                  the page or site you want to examine in the field below. You can use wildcards if
                  needed.
                </div>
                <div className={styles.TrafficParamBlock}>
                  <div
                    className={cls(styles.StrippedTableRow, styles.TrafficURL, {
                      [styles.notEmpty]: trackingURL !== '',
                    })}
                  >
                    <input
                      autoFocus={true}
                      value={trackingURL}
                      onBlur={onTrackingUrlBlur}
                      placeholder={'Enter URL'}
                      type="text"
                      onKeyUp={onURLKeyUp}
                      onChange={onURLChange}
                      className={styles.RsInput}
                      disabled={canvasPermissions.isReadonlyAccess}
                    />
                  </div>
                </div>
              </div>
            </When>

            <When condition={propertyDropdownValue === FACEBOOK_AD_INTEGRATION}>
              {hasFbAdIntegrationPermission ? (
                <FacebookIntegration
                  projectId={projectId}
                  stepId={currentStep.stepId}
                  searchRef={searchRef}
                />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div>Facebook integration is coming soon</div>
                </div>
              )}
            </When>
          </div>
          <When condition={propertyDropdownValue === dropDownItemValues[0]}>
            <div className={styles.AttributeExplorerWindow}>
              <AttributeExplorer
                onAttributesChanged={onAttributesChanged}
                selectedData={filterData}
                projectId={projectId}
              />
            </div>
          </When>
        </div>
      </PopUpWrapper>
      {/* </div> */}

      {/* <div className={styles.ParamtersWindow}></div> */}

      <When condition={isTrackingParamsOpened && propertyDropdownValue === dropDownItemValues[1]}>
        <ViewportConstrainer
          relativeElement={refPopupRnd.current}
          positioning={POSITIONING_RIGHT_TO_LEFT}
        >
          {/* <div onPointerDown={onContainerPointerDown}> */}
          <SourceTrackingParameters
            filterData={filterData}
            onTrackingParamsCloseBtnClicked={onTrackingParamsCloseBtnClicked}
            onTrackingParametersChanged={onTrackingParametersChanged}
            onClose={onTrackingParamsCloseBtnClicked}
            trackingURL={trackingURL}
          >
            <div className={styles.AttributeExplorerWindow}>
              <AttributeExplorer
                onAttributesChanged={onAttributesChanged}
                selectedData={filterData}
                projectId={projectId}
                trackingURL={trackingURL}
              />
            </div>
          </SourceTrackingParameters>
          {/* </div> */}
        </ViewportConstrainer>
      </When>
    </>
  );
};
