import { PR_EVENT_FUNNEL_CHANGED } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import CommandsBatch from '../CommandsBatch';

export default class CommandBatchHighlighted extends CommandsBatch {
  constructor(selectionManager) {
    super();
    this.selectionManager = selectionManager;
  }

  execute() {
    super.execute();
    this.selectionManager.show();
    commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
  }

  revert() {
    super.revert();
    this.selectionManager.show();
    commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
  }
}
