import React from 'react';
import { Button } from 'react-project/components/button/Button';
import styles from 'react-project/components/popUp/UpsellPopup/content/Tutorial.module.scss';

export const Footer = () => {
  const url = 'https://hub.funnelytics.io/c/faq';
  return (
    <>
      <div
        style={{
          fontWeight: 700,
          flexDirection: 'row',
          justifyContent: 'center',
          display: 'flex',
          marginTop: '12px',
        }}
      >
        Need more help?
      </div>
      <div
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <Button
          className={styles.button}
          onClick={(e) => {
            e.preventDefault();
            window.open(url);
          }}
        >
          <a href={url} target="_blank">
            VISIT OUR RESOURCE HUB
          </a>
        </Button>
      </div>
      <div
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <div style={{ width: '65%', textAlign: 'center' }}>
          or reach out to our team at{' '}
          <a
            style={{ textDecoration: 'underline', color: 'blue' }}
            href="mailto:support@funnelytics.io"
          >
            support@funnelytics.io
          </a>
        </div>
      </div>
    </>
  );
};
