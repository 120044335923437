import React, { useState, useRef, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useKey, useClickAway } from 'react-use';
import cls from 'classnames';

import styles from './Dropdown.module.scss';
import { When } from 'react-project/Util/When';

const DropdownComponent = ({
  children,
  triggerSlot,
  isOpen,
  onToggle,
  className,
  contentClassName,
  dropDownRef,
}) => {
  const [closingWithClick, setClosingWithClick] = useState(false);
  const [stylesDropdown, setStylesDropdown] = useState({});

  const ref = useRef(null);
  const updateRef = (ref) => {
    if (ref) {
      const { innerWidth, innerHeight } = window;
      const screen = { width: innerWidth, height: innerHeight };
      const { y, height } = ref.getBoundingClientRect();
      const top = -height - 10;
      if (!Object.keys(stylesDropdown).length && y + height > screen.height) {
        setStylesDropdown({ top });
      }
      // -------------------- This whole component needs refactoring, commenting this code to quickly fix an issue ---------------//
      // if (y < 0) {
      //   const topMin = top - y;

      //   if (topMin !== stylesDropdown.top) {
      //     setStylesDropdown({ top: topMin });
      //   }
      // }
    }
  };

  useClickAway(ref, () => onToggle(false));
  useKey('Escape', () => onToggle(false));

  const onMouseDown = () => {
    if (isOpen) {
      setClosingWithClick(true);
    }
  };

  const onClick = () => {
    if (!isOpen && closingWithClick) {
      setClosingWithClick(false);
    }

    onToggle(!isOpen);
  };

  const cloneAddon = {
    onClick,
    onMouseDown,
  };

  if (triggerSlot.type !== 'div') {
    cloneAddon.isOpen = isOpen;
  }

  return (
    <div ref={dropDownRef} className={cls(styles.Dropdown, className)}>
      {cloneElement(triggerSlot, cloneAddon)}
      <When condition={isOpen}>
        <div
          style={stylesDropdown}
          className={cls(styles.DropdownContent, contentClassName)}
          ref={(ref) => updateRef(ref)}
        >
          {children}
        </div>
      </When>
    </div>
  );
};

DropdownComponent.propTypes = {
  children: PropTypes.node,
  triggerSlot: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export const Dropdown = DropdownComponent;

export const DropdownFlatItems = ({ list, onSelect, className }) => {
  return (
    <div className={cls(styles.MaxWidth, className)}>
      {list.map((el, i) => (
        <div
          key={el.label + i}
          className={styles.CustomPropertyElement}
          onClick={() => onSelect(el)}
        >
          {el.label}
        </div>
      ))}
    </div>
  );
};

DropdownFlatItems.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string })).isRequired,
  onSelect: PropTypes.func.isRequired,
};
