import cls from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { TEXTS_TOOLTIP } from 'react-project/Constants/texts';
import { TOOLTIP_POSITIONS } from 'react-project/Constants/tooltip';
import { LeftSideMenu, LeftSideTab } from 'react-project/LeftSideBar/LeftSideMenu/LeftSideMenu';
import { When } from 'react-project/Util/When';
import { Tooltip } from 'react-project/components/tooltip/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import {
  RP_EVENT_GET_DATA,
  RP_EVENT_TAB_CLICKED,
  RR_SET_LEFT_SIDE_TAB,
  RR_SHOW_TUTORIAL_POPUP,
} from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { FF_CHECKLIST, FF_PLANNING_NOTES } from 'shared/FeatureFlags';
import { iconArrowSimple } from '../assets/Icons';
import { ChecklistBlock } from './Checklist/ChecklistBlock';
import { ExplorerBlock } from './ExplorerBlock/ExplorerBlock';
import styles from './LeftSideBar.module.scss';
import { MappingBlock } from './MappingBlock/MappingBlock';
import { NotesBlock } from './Notes/NotesBlock';
import { setUIState } from 'react-project/redux/ui-state/actions';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import { selectHasAnalyticsData } from 'react-project/redux/analytics/selectors';

const titles = {
  [LeftSideTab.MAP]: 'Map',
  [LeftSideTab.EXPLORER]: 'Explorer',
  [LeftSideTab.NOTES]: 'Canvas Notes',
  [LeftSideTab.CHECKLIST]: 'Canvas Checklist',
};

export const LeftSideBar = ({ funnel, isViewOnlyLink }) => {
  const lastMappingTab = useSelector((state) => state.uiState.lastMappingTab);
  const lastLeftSideTab = useSelector((state) => state.uiState.lastLeftSideTab);
  const activeTabRef = useRef(null);
  const dispatch = useDispatch();
  const canvasPermissions = useSelector(selectCanvasPermissions);
  const hasAnalyticsData = useSelector(selectHasAnalyticsData);
  const [isCollapsed, setIsCollapsed] = useState(true);

  let activeTab = lastLeftSideTab;
  if (lastLeftSideTab === LeftSideTab.MAP_OR_EXPLORER) {
    activeTab = lastMappingTab || LeftSideTab.MAP;
  }
  activeTabRef.current = activeTab;

  useEffect(() => {
    document.addEventListener(RR_SET_LEFT_SIDE_TAB, onTabSetEvent, false);
    commonSendEventFunction(RP_EVENT_GET_DATA, { value: 'FILTERS.IDS' });

    return () => {
      document.removeEventListener(RR_SET_LEFT_SIDE_TAB, onTabSetEvent, false);
    };
  }, []);

  useEffect(() => {
    if (activeTab === LeftSideTab.MAP || activeTab === LeftSideTab.EXPLORER) {
      // set last mapping tab
      dispatch(setUIState({ key: 'lastMappingTab', value: activeTab }));
    }
  }, [activeTab]);

  const onTabSetEvent = (e) => {
    const newActiveTab = e.detail;
    // Listen for this event , it should fire if the leftside bar was already opened

    setIsCollapsed(false);

    if (
      newActiveTab === activeTabRef.current ||
      (newActiveTab === LeftSideTab.MAP_OR_EXPLORER && activeTabRef.current === LeftSideTab.MAP) ||
      (newActiveTab === LeftSideTab.MAP_OR_EXPLORER &&
        activeTabRef.current === LeftSideTab.EXPLORER)
    ) {
      const sidebar = document.getElementById('left-sidebar-wrapper');
      if (sidebar) {
        sidebar.classList.add(styles.FadeIt);
        setTimeout(() => {
          sidebar.classList.remove(styles.FadeIt);
        }, 500);
      }
    }
  };

  const hideFilterButtonClicked = () => {
    collapseLeftSideBar();
  };

  const collapseLeftSideBar = () => {
    setIsCollapsed(true);
    dispatch(setUIState({ key: 'lastLeftSideTab', value: null }));
    commonSendEventFunction(RP_EVENT_TAB_CLICKED, { tab: null });
  };

  const onTabClicked = (e) => {
    const newActiveTab = e.currentTarget.dataset.name;

    if (!isCollapsed && newActiveTab === activeTab) {
      collapseLeftSideBar();
      return;
    }

    // The explorer tab needs to be shown in the case of not having analytics permissions
    // as it contains a demo mockup of the explorer
    // but if the user has analytics permissions and there is no data
    // we show the tutorial popup
    if (
      newActiveTab === LeftSideTab.EXPLORER &&
      canvasPermissions.isAnalyticsAllowed &&
      !hasAnalyticsData
    ) {
      commonSendEventFunction(RR_SHOW_TUTORIAL_POPUP);
      return;
    }

    if (newActiveTab !== activeTab) {
      commonSendEventFunction(RP_EVENT_TAB_CLICKED, { tab: newActiveTab });
    }

    setIsCollapsed(false);

    dispatch(setUIState({ key: 'lastLeftSideTab', value: newActiveTab }));
  };

  const getMenuWidth = (tab) => {
    switch (tab) {
      case LeftSideTab.NOTES:
      case LeftSideTab.CHECKLIST:
        return 700;
      case LeftSideTab.MAP:
      case LeftSideTab.EXPLORER:
        return 560;
      default:
        return 405;
    }
  };

  return (
    <div className={styles.LeftSidebarContainer}>
      <LeftSideMenu
        onItemClicked={onTabClicked}
        activeTab={activeTab}
        width={getMenuWidth(activeTab)}
        isViewOnlyLink={isViewOnlyLink}
      />

      <When condition={!isCollapsed}>
        <div
          className={cls(styles.Wrapper, styles.LeftSidebar)}
          id="left-sidebar"
          style={{ width: getMenuWidth(activeTab) }}
        >
          <div className={styles.HeaderBlock}>{titles[activeTab]}</div>
          <div className={styles.LeftSidebarWrapper} id="left-sidebar-wrapper">
            <Tooltip label={TEXTS_TOOLTIP.HIDE_FILTERS} position={TOOLTIP_POSITIONS.RIGHT}>
              <div className={styles.FilterCloseIcon} onClick={hideFilterButtonClicked}>
                {iconArrowSimple}
              </div>
            </Tooltip>

            <When condition={!isViewOnlyLink && activeTab === LeftSideTab.MAP}>
              <MappingBlock />
            </When>

            <When condition={!isViewOnlyLink && activeTab === LeftSideTab.EXPLORER}>
              <ExplorerBlock funnel={funnel} />
            </When>

            <When condition={FF_PLANNING_NOTES}>
              <When condition={activeTab === LeftSideTab.NOTES}>
                <NotesBlock isCanvas={true} />
              </When>
            </When>

            <When condition={FF_CHECKLIST}>
              <When condition={activeTab === LeftSideTab.CHECKLIST}>
                <ChecklistBlock isCanvas={true} />
              </When>
            </When>
          </div>
        </div>
      </When>
    </div>
  );
};

LeftSideBar.propTypes = {
  funnel: PropTypes.shape({
    projectId: PropTypes.string.isRequired,
  }),
};
