import { produce } from 'immer';
import { handleActions } from 'redux-actions';

import { setCanvasChecklistAction } from './actions';

const initialState = {
  checklist: null,
};

export default handleActions(
  {
    [setCanvasChecklistAction.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.checklist = action.payload;
      }),
  },
  initialState,
);
