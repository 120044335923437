import * as PIXI from 'pixi.js';
import { roundTo } from 'shared/CSharedMethods';
import { numSeparator } from 'shared/NumberHelpers';
import { shortenText } from 'shared/SharedHelpers';
import Styles, { COLOR_WIDGET_TEXT, COLOR_WIDGET_TEXT_SELECTED } from '../Styles';
import TableRow from './TableView/TableRow';

export default class TableRowGoals extends TableRow {
  constructor(headerData, rowSize, columnSpacing = 0) {
    super(headerData, rowSize, columnSpacing);
  }

  initRow() {
    super.initRow();

    this.delegate = null;

    this._id = null;
    this.index = 0;
    this.isSelected = false;

    this.nameLabel = new PIXI.BitmapText('', Styles.WIDGET_LABEL);
    this.nameLabel.tint = COLOR_WIDGET_TEXT;
    this.nameLabel.anchor.y = 0.5;
    this.addChild(this.nameLabel);

    this.hitsLabel = new PIXI.BitmapText('', Styles.WIDGET_LABEL);
    this.hitsLabel.tint = COLOR_WIDGET_TEXT;
    this.hitsLabel.anchor.y = 0.5;
    this.addChild(this.hitsLabel);

    this.goalValueLabel = new PIXI.BitmapText('', Styles.WIDGET_LABEL);
    this.goalValueLabel.tint = COLOR_WIDGET_TEXT;
    this.goalValueLabel.anchor.y = 0.5;
    this.addChild(this.goalValueLabel);

    this.locateIcon = new PIXI.Sprite(PIXI.utils.TextureCache['locate']);
    this.locateIcon.anchor.y = 0.5;
    this.locateIcon.buttonMode = true;
    this.locateIcon.interactive = true;
    this.locateIcon.pointerdown = (e) => {
      e.stopPropagation();
    };
    this.locateIcon.pointerup = (e) => {
      if (this.delegate && this.delegate.delegate) {
        this.delegate.delegate.onLocateClicked(e, this);
      }
    };
    this.addChild(this.locateIcon);
  }

  bindData(data) {
    this.data = data;
    this.visible = true;
    this.nameLabel.text = shortenText(data.name, 30);
    this.hitsLabel.text = data.hits;
    this.goalValueLabel.text = `$${numSeparator(roundTo(data.goal, 2))}`;

    this.updatePositions();
  }

  updatePositions() {
    const rh = this.rowSize.height;

    let cellX = this.columnSpacing;
    this.alignElementsInCell(
      [this.nameLabel],
      cellX,
      this.headerData[0].calculatedWidth,
      rh,
      0,
      this.headerData[0].align,
    );

    cellX += this.headerData[0].calculatedWidth + this.columnSpacing;
    this.alignElementsInCell(
      [this.hitsLabel],
      cellX,
      this.headerData[1].calculatedWidth,
      rh,
      0,
      this.headerData[1].align,
    );

    cellX += this.headerData[1].calculatedWidth + this.columnSpacing;
    this.alignElementsInCell(
      [this.goalValueLabel],
      cellX,
      this.headerData[2].calculatedWidth,
      rh,
      0,
      this.headerData[2].align,
    );

    cellX += this.headerData[2].calculatedWidth + this.columnSpacing;
    this.alignElementsInCell(
      [this.locateIcon],
      cellX,
      this.headerData[3].calculatedWidth,
      rh,
      0,
      this.headerData[3].align,
    );
  }

  onSelectChanged(isSelected) {
    super.onSelectChanged(isSelected);

    this.hitsLabel.tint = isSelected ? COLOR_WIDGET_TEXT_SELECTED : COLOR_WIDGET_TEXT;
    this.goalValueLabel.tint = isSelected ? COLOR_WIDGET_TEXT_SELECTED : COLOR_WIDGET_TEXT;

    window.app.needsRendering();
  }
}
