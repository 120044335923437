import Styles, {
  COLOR_LABEL_DEFAULT,
  TITLE_MARGIN,
  TITLE_PROGRESS_BAR_MARGIN,
} from 'pixi-project/view/Styles';
import ProgressBar from 'pixi-project/view/progressbar/ProgressBar.js';
import * as PIXI from 'pixi.js';
import { CHECKLIST_STATUS_ITEMS } from 'shared/CSharedConstants';

const SPACING = 10;
const BADGE_SIZE = 10;
const BADGE_OFFSET = 4;

export default class TitleDisplay extends PIXI.Container {
  constructor() {
    super();
    this.isChecklistVisible = false;
    this.titleLabel = new PIXI.BitmapText('', Styles.TITLE_LABEL);
    this.titleLabel.tint = COLOR_LABEL_DEFAULT;
    this.titleLabelStyle = new PIXI.TextStyle(Styles.TITLE_LABEL);

    this.titleLabel.interactive = true;
    this.titleLabel.buttonMode = true;
    this.titleLabel.cursor = 'default';

    this.addChild(this.titleLabel);

    // Add progress bar
    this.progressBar = new ProgressBar();
    this.addChild(this.progressBar);

    // Add square color badge
    this.squareColorBadge = new PIXI.Graphics();
    this.squareColorBadge.beginFill(0xffffff);
    this.squareColorBadge.drawRect(0, 0, 10, 10);
    this.squareColorBadge.endFill();
    this.addChild(this.squareColorBadge);
  }

  setProgressbarWidth(width) {
    this.progressBar.setProgressbarWidth(width);
  }

  setText(text) {
    this.titleLabel.text = text;
  }

  getHeight() {
    return this.titleLabel.height;
  }

  showChecklist() {
    this.isChecklistVisible = true;
    this.updatePositions();
  }

  hideChecklist() {
    this.isChecklistVisible = false;
    this.updatePositions();
  }

  updateWithChecklistData(checklistData) {
    window.app.needsRendering();
    if (checklistData) {
      const countCompletedItems = this.countCompletedItems(checklistData.items);
      const progress = countCompletedItems.checked / countCompletedItems.total;

      const hasItems = checklistData.items && checklistData.items.length > 0;
      const isCompleated = progress === 1;
      const hasVisibleItems = !(isCompleated && checklistData.isHideCompleted);
      const isProgressBarVisible = hasItems && hasVisibleItems;
      this.isProgressBarVisible = isProgressBarVisible;

      // TODO temporary disabled , it will be reused in an upcomming feature

      // const statusItem = this.getStatusItem(checklistData.status);
      // const color = PIXI.utils.string2hex(statusItem.color);
      // this.isColorBadgeVisible = !!statusItem.color;

      // if (this.isColorBadgeVisible) {
      //   this.squareColorBadge.tint = color;
      // }

      this.isColorBadgeVisible = false;

      if (isProgressBarVisible) {
        this.progressBar.setProgressColor(0x4ad260);
        this.progressBar.setProgress(progress);
      }

      this.updatePositions();
    } else {
      this.hideChecklist();
    }
  }

  updatePositions() {
    // Update visibility
    this.squareColorBadge.visible = this.isChecklistVisible;
    this.progressBar.visible = this.isChecklistVisible && this.isProgressBarVisible;
    this.squareColorBadge.visible = this.isChecklistVisible && this.isColorBadgeVisible;

    const textMetrics = PIXI.TextMetrics.measureText(this.titleLabel.text, this.titleLabelStyle);
    const text = textMetrics.lines.join('\n');
    this.titleLabel.text = text;

    const badgeSize = this.squareColorBadge.visible ? BADGE_SIZE : 0;
    const spacing = this.squareColorBadge.visible ? SPACING : 0;
    const badgeOffset = this.squareColorBadge.visible ? BADGE_OFFSET : 0;
    const progresBarSpacing = this.progressBar.visible ? 5 : 0;

    const width = this.titleLabel.width + badgeSize + spacing;
    const y = -TITLE_MARGIN - (textMetrics.lines.length - 1) * textMetrics.lineHeight;

    this.titleLabel.x = -width / 2 + badgeSize + spacing;
    this.titleLabel.y = y - progresBarSpacing;

    this.progressBar.x = -this.progressBar.width / 2;
    this.progressBar.y = -TITLE_PROGRESS_BAR_MARGIN - progresBarSpacing;

    this.squareColorBadge.x = -width / 2;
    this.squareColorBadge.y = y + badgeOffset - progresBarSpacing;
  }

  getStatusItem(value) {
    const statusItem = CHECKLIST_STATUS_ITEMS.find((item) => item.value === value);
    return statusItem ? statusItem : CHECKLIST_STATUS_ITEMS[0];
  }

  countCompletedItems(list, data = { checked: 0, total: 0 }) {
    for (let i = 0; i < list.length; i++) {
      data.total++;
      if (list[i].checked) {
        data.checked++;
      }
      if (list[i].items) {
        this.countCompletedItems(list[i].items, data);
      }
    }
    return data;
  }
}
