import { FunnelProperties } from './FunnelProperties';

export const getUpdatedPhotoPath = (urlPath) => {
  if (!urlPath) {
    return urlPath;
  }

  const projectId = FunnelProperties.getProjectId();
  const ownerId = FunnelProperties.getOwnerId();

  if (projectId && ownerId && urlPath.indexOf('/project/') === -1) {
    return urlPath.replace('icons/signed/', `icons/signed/project/${projectId}/owner/${ownerId}/`);
  }

  return urlPath;
};
