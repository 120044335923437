import cls from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import styles from 'react-project/components/progressbar/Progressbar.module.scss';

export const ProgressBar = ({
  percent,
  showPercentage = true,
  customStyles = { height: '20px' },
}) => {
  const fillRef = useRef();
  const widthRef = useRef(0);
  const [requestID, setRequestID] = useState();
  const [currentPercent, setCurrentPercent] = useState(0);

  useEffect(() => {
    return () => {
      cancelAnimationFrame(requestID);
    };
  }, [requestID]);

  useEffect(() => {
    cancelAnimationFrame(requestID);
    setCurrentPercent(0);

    const targetPercent = Math.round(percent) || 0;

    setRequestID(requestAnimationFrame(frame));

    const increment = currentPercent > targetPercent ? -1 : 1;

    function frame() {
      if (widthRef.current === targetPercent) {
        // do nothing
      } else {
        widthRef.current = widthRef.current + increment;
        setCurrentPercent(widthRef.current);
        if (fillRef.current) {
          fillRef.current.style.width = `${widthRef.current}%`;
        }
        setRequestID(requestAnimationFrame(frame));
      }
    }
  }, [percent]);

  return (
    <div className={cls(styles.ProgressContainer)} style={customStyles}>
      <div
        ref={fillRef}
        className={cls(styles.ProgressFill, percent === 100 ? styles.full : null)}
      />
      {showPercentage ? <div className={styles.ProgressText}>{`${currentPercent}%`}</div> : null}
    </div>
  );
};
