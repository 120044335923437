import { EElementTypes } from 'shared/CSharedCategories';
import BaseWidget, { WIDGET_PADDING } from './BaseWidget';
import TableRowTopCountries from './TableRowTopCountries';
import { roundTo } from 'shared/CSharedMethods';
import MainStorage from 'pixi-project/core/MainStorage';

export default class TopCountriesWidget extends BaseWidget {
  constructor(eventHandlers, id, delegate, data) {
    super(eventHandlers, id, delegate, data);

    this.type = EElementTypes.WIDGET_COUNTRIES;

    this.styles = {
      icon: 'icon-top-countries.png',
      title: 'Top Countries',
    };

    this.containerWidth = 395;
  }

  setWidgetData(widgetData) {
    super.setWidgetData(widgetData);

    const tableHeight = this.containerHeight - this.getHeaderHeight();

    this.tableView.rowClass = TableRowTopCountries;
    this.tableView.isPaginated = true;
    this.tableView.pageSize = this.numberOfRows;
    this.tableView.columnSpacing = WIDGET_PADDING;
    this.tableView.tableSize.width = this.containerWidth;
    this.tableView.tableSize.height = tableHeight;
    this.tableView.rowSize.width = this.containerWidth;
    this.tableView.rowSize.height = this.rowHeight;
    this.tableView.headerHeight = this.tableHeaderHeight;
    this.tableView.setHeaderData([
      {
        title: 'Country',
        width: 0.5,
        align: 'left',
      },
      {
        title: 'Total People',
        width: 0.5,
        align: 'left',
      },
      // Uncomment this when you need to implement Total Spent data
      // , {
      //     title: 'Total Spent',
      //     width: 0.3,
      //     align: 'right'
      // }
    ]);
    const prepedData = this.prepData(widgetData);
    this.tableView.setRowsData(prepedData);
    this.tableView.renderTable();
    this.updatePagination();

    this.hideMessage();

    if (!prepedData.length) {
      this.hideContent();
    }
  }

  prepData(widgetData) {
    let sum = 0;
    for (let i = 0; i < widgetData.length; i++) {
      const data = widgetData[i];
      sum += Number(data.hits);
    }

    const results = [];
    for (let i = 0; i < widgetData.length; i++) {
      const data = widgetData[i];
      let result = {
        name: data.name,
        hits: data.hits,
        percent: roundTo((data.hits * 100) / sum, 1),
      };
      results.push(result);
    }
    return results;
  }

  hideContent() {
    this.tableView.visible = false;
  }

  showContent() {
    this.tableView.visible = this.tableView.rowsData.length ? true : false;
  }

  hideMessage() {
    super.hideMessage();
    this.showContent();
  }
}
