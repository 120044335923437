import * as PIXI from 'pixi.js';
import TextInput from 'pixi-text-input';
import BaseContainer from 'pixi-project/base/containers/BaseContainer';
import { EElementCategories, EElementTypes } from 'shared/CSharedCategories';
import ReportSlide from './ReportSlide';
import Styles, { COLOR_REPORT_DASHED_LINE, COLOR_REPORT_TITLE } from '../Styles';
import SharedElementHelpers from 'shared/SharedElementHelpers';
import Button from '../../base/elements/Button';
import { LIMIT_REPORT_SLIDES } from 'shared/CSharedConstants';
import CommandsBatch from 'pixi-project/base/command-system/CommandsBatch';
import { cloneData, commonSendEventFunction, isRightButton } from 'shared/CSharedMethods';
import {
  PR_EVENT_REPORT_WARNING_POPUP_OPENED,
  PR_EVENT_REPORT_WARNING_POPUP_OK_BTN_CLICKED,
  PR_EVENT_SEND_REPORT_CLICKED,
  RP_EVENT_SEND_REPORT_TITLE_EDITED,
  PR_EVENT_REPORT_STYLE,
} from 'shared/CSharedEvents';

import CommandAddReportSlide from 'pixi-project/base/command-system/commands/CommandAddReportSlide';
import CommandDeleteReportSlide from 'pixi-project/base/command-system/commands/CommandDeleteReportSlide';
import CommandLock from 'pixi-project/base/command-system/commands/CommandLock';
import LineDrawHelper from 'pixi-project/utils/LineDrawHelper';
import AppSignals from 'pixi-project/signals/AppSignals';
import ToolTipBox, { TOOLTIP_LOCATION } from '../ToolTipBox';
import SteppedSpinner from '../SteppedSpinner';
import CommandStyleReportSlide from 'pixi-project/base/command-system/commands/CommandStyleReportSlide';
import CommandBatchStyleReport from 'pixi-project/base/command-system/commands/CommandBatchStyleReport';
import MainStorage from 'pixi-project/core/MainStorage';

export const SCALE_THRESHOLD = 0.5;

const SLIDES_MARGIN = 39;
const TEXTURE_NAME_LOCK = 'lock.png';
const TEXTURE_NAME_LOCK_HOVER = 'lock-hover.png';
const TEXTURE_NAME_LOCK_BLUE = 'lock-blue.png';
const TEXTURE_NAME_SEND = 'send.png';
const TEXTURE_NAME_SEND_HOVER = 'send-hover.png';
const TEXTURE_NAME_DOWNLOAD = 'download-report';
const TEXTURE_NAME_DOWNLOAD_HOVER = 'download-report-hover';
const REPORT_TITLE_DEFAULT = 'Report - Double click to rename';

const SEND_BUTTON_OFFSET_Y = -10;
const REPORT_TITLE_OFFSET_Y = -35;

const C_SELECTION_BOUNDARY_GAP = 20;

// Delegate
// - onReportViewPointerDown ( e , reportView )
// - onReportViewMoving
// - onReportViewBeforeContentShift(reportView)
// - onReportViewLockedStateChanged(isLocked, reportView)
// - onReportViewAddSlideClicked(reportView, slide)
// - onReportViewRemoved(reportView)
// - onReportViewRemoveSlideClicked(reportView, slide, commandBatch)
// - onReportViewSlideAdded(reportView , slide)
// - onReportViewSlideRemoved(reportView , slide)
// - onReportViewDownloadPDF(reportView , slide)

export default class ReportView extends BaseContainer {
  constructor(eventHandlers, id, sceneManager, data = null) {
    super(eventHandlers, id);

    this.delegate = sceneManager;
    this.category = EElementCategories.REPORT;
    this.type = EElementTypes.NONE;

    this.isResizable = false;
    this.isShowTool = false;
    this.isSelectable = false;
    this.isLocked = data ? data.isLocked : false;

    this.importData = data;
    this.selectedSlideIndex = -1;
    this.slides = [];
    this.previousDragPoint = new PIXI.Point();

    this.graphics = new PIXI.Graphics(); // used to draw the dashed line
    this.addChild(this.graphics);

    this.content = new PIXI.Container();
    this.addChild(this.content);

    this.isEditMode = false;
    this.isClicked = false;

    this.title = new TextInput(Styles.REPORT_TITLE);
    this.setTitle(data.label || REPORT_TITLE_DEFAULT);
    this.title.setInputStyle('fontSize', Styles.REPORT_TITLE.fontSize + 'px');
    this.title.setInputStyle('color', COLOR_REPORT_TITLE);
    this.title.y = REPORT_TITLE_OFFSET_Y;
    this.title.interactive = true;
    this.title.buttonMode = true;
    this.title.disabled = true;
    this.addChild(this.title);
    this.spinner = null;
    this.previousStyleSlides = [];

    document.addEventListener(
      PR_EVENT_REPORT_WARNING_POPUP_OK_BTN_CLICKED,
      this.onRemoveWarningAnswer.bind(this),
      false,
    );
    document.addEventListener(
      RP_EVENT_SEND_REPORT_TITLE_EDITED,
      this.onEditTitle.bind(this),
      false,
    );

    this.title
      .on('pointerdown', (e) => {
        this.onElementPointerDown(e);
      })
      .on('pointermove', this.onElementPointerMove)
      .on('pointerup', (e) => {
        this.onElementPointerUp(e);
        this.onTitlePointerUp(e);
      })
      .on('pointerupoutside', this.onElementPointerUpOutside)
      .on('pointerover', this.onElementPointerOver)
      .on('pointerout', this.onElementPointerOut);

    if (this.slides.length <= 0) {
      this.initializeEmpty();
    }
    this.createButtons();
    this.rescaleRepositionTitle();

    AppSignals.setOutEditMode.add(this.outEditMode, this);
    AppSignals.viewportClicked.add(this.outEditMode, this);

    this.isSliderDown = false;
    this.isPointerDown = false;

    this.interactive = true;
    this.on('pointerdown', this.onReportAreaPointerDown.bind(this));
    this.on('pointermove', this.onReportAreaPointerMove.bind(this));
    this.on('pointerup', this.onReportAreaPointerUp.bind(this));
  }

  isPointInsideHitArea(e) {
    const bounds = this.content.getLocalBounds();
    const padding = C_SELECTION_BOUNDARY_GAP;

    const bottomY = bounds.y + bounds.height + padding;

    let x = bounds.x - padding;
    let y = -padding;
    let width = bounds.x + bounds.width + padding - x;
    let height = bottomY - y;

    let testBounds = new PIXI.Rectangle(x, y, width, height);
    let testPoint = this.content.toLocal(e.data.global);
    return testBounds.contains(testPoint.x, testPoint.y);
  }

  onSliderPointerDown(e) {
    this.isSliderDown = true;
  }

  onSliderPointerUp(e) {
    this.isSliderDown = false;
  }

  onReportAreaPointerDown(e) {
    if (this.isPointInsideHitArea(e)) {
      if (!this.isSliderDown) {
      } else {
        if (this.isSelected) {
        } else {
          return false;
        }
      }

      if (isRightButton(e)) {
        return true;
      }

      this.isPointerDown = true;
      // lets update the current target so that the system recognizes what element was selected
      e.currentTarget = this.content;
      this.onElementPointerDown(e);
    }
  }

  onReportAreaPointerMove(e) {
    if (this.isPointerDown) {
      this.onElementPointerMove(e);
    }
  }

  onReportAreaPointerUp(e) {
    if (this.isPointerDown) {
      this.isPointerDown = false;
      if (isRightButton(e)) {
        e.currentTarget = this.content;
        return;
      }
      this.onElementPointerUp(e);
    }
  }

  containsPoint(point) {
    // It will be true for any interaction
    // But then it will be filtered inside the event handler see onReportAreaPointerDown
    return true;
  }

  onReportLockedStateChanged() {
    const newStatus = !this.isLocked;

    let commandBatch = new CommandsBatch();
    const commandLockReport = new CommandLock(this, newStatus);
    commandBatch.add(commandLockReport);

    for (let i = 0; i < this.slides.length; i++) {
      const objects = this.slides[i].getSlideObjects();

      for (let j = 0; j < objects.length; j++) {
        const object = objects[j];
        // Prevent text element from being changed if it was in editMode when the lock button is clicked
        if (SharedElementHelpers.IsText(object)) {
          object.outEditMode();
        }
        const commandLock = new CommandLock(object, newStatus);
        commandBatch.add(commandLock);
      }
    }

    AppSignals.commandCreated.dispatch(commandBatch);

    if (this.delegate.onReportViewLockedStateChanged) {
      this.delegate.onReportViewLockedStateChanged(newStatus, this);
    }
  }

  setTitle(value) {
    this.title.text = value;
  }

  refreshTitleWidth() {
    const widthOneSymbol = parseInt(`${this.title.width / this.title.text.length}`);
    this.title.setInputStyle('width', this.title.width + widthOneSymbol);
  }

  onEditTitle(e) {
    this.setTitle(e.detail.title);
    this.refreshTitleWidth();

    window.app.needsRendering();
  }

  onSendButtonClicked() {
    commonSendEventFunction(PR_EVENT_SEND_REPORT_CLICKED, { title: this.title.text });
  }

  onDownloadButtonClicked() {
    this.createSpinButton();
    if (this.delegate.onReportViewDownloadPDF) {
      this.delegate.onReportViewDownloadPDF(this);
    }
  }

  createButtons() {
    this.sendReportButton = new Button(
      TEXTURE_NAME_SEND,
      TEXTURE_NAME_SEND_HOVER,
      this.onSendButtonClicked.bind(this),
    );
    this.addChild(this.sendReportButton);
    // Send button is temporary hidden
    this.sendReportButton.visible = false;

    this.downloadReportButton = new Button(
      TEXTURE_NAME_DOWNLOAD,
      TEXTURE_NAME_DOWNLOAD_HOVER,
      this.onDownloadButtonClicked.bind(this),
    );
    this.addChild(this.downloadReportButton);

    if (this.isLocked) {
      this.lockReportButton = new Button(
        TEXTURE_NAME_LOCK_BLUE,
        TEXTURE_NAME_LOCK_BLUE,
        this.onReportLockedStateChanged.bind(this),
      );
    } else {
      this.lockReportButton = new Button(
        TEXTURE_NAME_LOCK,
        TEXTURE_NAME_LOCK_HOVER,
        this.onReportLockedStateChanged.bind(this),
      );
    }
    this.addChild(this.lockReportButton);

    if (MainStorage.getCanvasPermissions().isReadonlyAccess) {
      this.lockReportButton.visible = false;
    }

    this.rescaleButtons();
    this.positionButtons();

    this.lockButtonTooltip = new ToolTipBox(this.lockReportButton.content, 'Lock', {
      onBeforeShow: (tooltip) => {
        tooltip.setContent(this.isLocked ? 'Unlock Report' : 'Lock Report');
      },
      location: [TOOLTIP_LOCATION.left],
    });

    this.sendReportTooltip = new ToolTipBox(this.sendReportButton.content, 'Send Report', {
      location: [TOOLTIP_LOCATION.left, TOOLTIP_LOCATION.bottom],
    });

    this.downloadReportTooltip = new ToolTipBox(
      this.downloadReportButton.content,
      'Download Report',
      {
        location: [TOOLTIP_LOCATION.left, TOOLTIP_LOCATION.bottom],
      },
    );
  }

  getButtonPositions() {
    const positions = {
      lock: { x: -C_SELECTION_BOUNDARY_GAP, y: -C_SELECTION_BOUNDARY_GAP },
      // Send button is temporary hidden
      // send: { x: -C_SELECTION_BOUNDARY_GAP, y: this.sendReportButton.height + SEND_BUTTON_OFFSET_Y },
      // download: {
      //   x: -C_SELECTION_BOUNDARY_GAP,
      //   y: this.sendReportButton.height + SEND_BUTTON_OFFSET_Y + this.downloadReportButton.height + DOWNLOAD_BUTTON_OFFSET_Y
      // },
      download: {
        x: -C_SELECTION_BOUNDARY_GAP,
        y: this.sendReportButton.height + SEND_BUTTON_OFFSET_Y,
      },
    };

    return positions;
  }

  positionButtons() {
    const positions = this.getButtonPositions();
    this.lockReportButton.position = new PIXI.Point(positions.lock.x, positions.lock.y);
    // Send button is temporary hidden
    //this.sendReportButton.position = new PIXI.Point(positions.send.x, positions.send.y);
    this.downloadReportButton.position = new PIXI.Point(positions.download.x, positions.download.y);
  }

  onPointerDown(e) {
    this.previousDragPoint.copyFrom(this.position);
    if (this.delegate && this.delegate.onReportViewPointerDown) {
      this.delegate.onReportViewPointerDown(e, this);
    }
  }

  move() {
    super.move();

    if (this.delegate && this.delegate.onReportViewMoving) {
      this.delegate.onReportViewMoving(this);
    }

    for (let i = 0; i < this.slides.length; i++) {
      const slide = this.slides[i];
      const objects = slide.objects;
      for (let j = 0; j < objects.length; j++) {
        const object = objects[j];
        object.x += this.x - this.previousDragPoint.x;
        object.y += this.y - this.previousDragPoint.y;
        object.move();
      }
    }

    this.previousDragPoint.copyFrom(this.position);
  }

  isLimitReached() {
    if (LIMIT_REPORT_SLIDES) {
      return this.slides.length === LIMIT_REPORT_SLIDES - 1;
    }
    return false;
  }

  onAddSlideButtonClicked(slide) {
    if (!this.isLimitReached()) {
      const slideNew = new ReportSlide(
        { hasRemoveButton: true, hasAddButton: true, label: this.getLabel() },
        this,
      );
      const indexSlide = this.findIndexSlide(slide);

      const commandBatch = new CommandsBatch();
      const addCommand = new CommandAddReportSlide(this, slideNew, indexSlide + 1);
      commandBatch.add(addCommand);

      AppSignals.commandCreated.dispatch(commandBatch);

      if (this.delegate && this.delegate.onReportViewAddSlideClicked) {
        this.delegate.onReportViewAddSlideClicked(this, slide);
      }

      // inherit the style from the previous slide
      slideNew.setStyle(slide.style);

      window.app.needsRendering();
    }
  }

  refreshAdditionsSlides() {
    for (let i = 0; i < this.slides.length; i++) {
      const slide = this.slides[i];
      slide.setTitle(this.getLabel(i + 1));

      if (LIMIT_REPORT_SLIDES) {
        if (i + 1 === LIMIT_REPORT_SLIDES) {
          slide.hideAddButton();
        } else {
          slide.showAddButton();
        }
      } else {
        slide.showAddButton();
      }

      // This is going to effectevly reorder slides

      if (i > 0) {
        slide.y = i * this.slides[i - 1].height + SLIDES_MARGIN * i + SLIDES_MARGIN;
      } else {
        slide.y = SLIDES_MARGIN;
      }

      slide.positionButtons();
    }

    if (this.slides.length === 1) {
      this.slides[0].hideRemoveButton();
      this.slides[0].positionButtons();
    } else {
      this.slides[0].showRemoveButton();
      this.slides[0].positionButtons();
    }
  }

  findIndexSlide(slide) {
    for (let i = 0; i < this.slides.length; i++) {
      let currentSlide = this.slides[i].getBounds();
      if (currentSlide.y === slide.getBounds().y) {
        return i;
      }
    }

    return false;
  }

  onRemoveWarningAnswer(e) {
    if (e.detail.isSlide !== undefined) {
      if (e.detail.isSlide) {
        if (e.detail.value === true) {
          this.removeSelectedSlide();
        }
      } else {
        if (this.delegate && this.delegate.onReportViewRemoved) {
          this.delegate.onReportViewRemoved(this);
        }
      }
    }
  }

  removeSelectedSlide() {
    const slide = this.slides[this.selectedSlideIndex];

    if (slide) {
      const commandBatch = new CommandsBatch();

      const indexSlide = this.findIndexSlide(slide);
      let deleteCommand = new CommandDeleteReportSlide(this.slides, this, slide, indexSlide);
      commandBatch.add(deleteCommand);

      if (this.delegate && this.delegate.onReportViewRemoveSlideClicked) {
        this.delegate.onReportViewRemoveSlideClicked(this, slide, commandBatch);
      }
    }

    this.selectedSlideIndex = -1;
  }

  onRemoveSlideButtonClicked(slide) {
    this.selectedSlideIndex = this.findIndexSlide(slide);
    if (slide.isEmpty()) {
      this.removeSelectedSlide();
    } else {
      commonSendEventFunction(PR_EVENT_REPORT_WARNING_POPUP_OPENED, { isSlide: true });
    }
  }

  onStyleSlideButtonClicked(slide) {
    commonSendEventFunction(PR_EVENT_REPORT_STYLE, { slide: slide });
  }

  refreshSlides() {
    this.refreshAdditionsSlides();
    this.drawDashedBounds();
    window.app.needsRendering();
  }

  addSlide(slide, index) {
    if (index) {
      // Adding slides by pressing the buttons
      slide.y = index * this.slides[index - 1].height + SLIDES_MARGIN * index;
      this.slides.splice(index, 0, slide);
      this.content.addChild(slide);

      this.refreshSlides();
      // Because a slide was added , other slides associated content need to shift
      this.shiftSlidesContent();
    } else {
      // Adding slides on initialization and loading
      slide.y = this.slides.length * slide.height + SLIDES_MARGIN * this.slides.length;
      if (index === undefined) {
        this.slides.push(slide);
      } else {
        this.slides.splice(index, 0, slide);
      }

      this.content.addChild(slide);
      this.refreshAdditionsSlides();
    }

    slide.setIsLockedButtons(this.isLocked);

    if (this.delegate && this.delegate.onReportViewSlideAdded) {
      this.delegate.onReportViewSlideAdded(this, slide);
    }
  }

  removeSlide(slide, indexSlide) {
    this.slides.splice(indexSlide, 1);
    this.content.removeChild(slide);
    this.slides[0].hasRemoveButton = true;
    this.refreshSlides();
    // Because a slide was removed , other slides associated content need to shift
    this.shiftSlidesContent();
    if (this.delegate && this.delegate.onReportViewSlideRemoved) {
      this.delegate.onReportViewSlideRemoved(this, slide);
    }
  }

  shiftSlidesContent() {
    for (let i = 0; i < this.slides.length; i++) {
      const slide = this.slides[i];
      slide.shiftContent();
    }
  }

  getLabel(value) {
    const numSlide = value ? value : this.slides.length + 1;
    return `( Slide ${numSlide} - 16:9 )`;
  }

  initializeEmpty() {
    const slide = new ReportSlide(
      { hasRemoveButton: true, label: this.getLabel(), isLocked: this.isLocked },
      this,
    );
    this.addSlide(slide);
  }

  changeButtonsState(status) {
    if (status) {
      this.lockReportButton.setTextures(TEXTURE_NAME_LOCK_BLUE, TEXTURE_NAME_LOCK_BLUE);
    } else {
      this.lockReportButton.setTextures(TEXTURE_NAME_LOCK, TEXTURE_NAME_LOCK_HOVER);
    }

    this.setSlideButtonsStatus(status);
    this.lockButtonTooltip.show(this.isLocked ? 'Unlock Report' : 'Lock Report');
    window.app.needsRendering();
  }

  setSlideButtonsStatus(status) {
    this.slides.forEach((slide) => {
      slide.setIsLockedButtons(status);
    });
  }

  init() {
    this.footer.visible = false;
  }

  getState() {
    super.getState();
    this.stateData.data = [];
    this.stateData.isLocked = this.isLocked;
    this.stateData.styles = [];

    for (let i = 0; i < this.slides.length; i++) {
      const slide = this.slides[i];
      let data = slide.getData();
      this.stateData.data.push({ ids: data });
      this.stateData.styles.push(slide.style);
    }

    this.stateData.label = this.title.text;
    return this.stateData;
  }

  onDoubleClick(e) {
    if (MainStorage.getCanvasPermissions().isReadonlyAccess) {
      return;
    }
    this.enterEditMode();
  }

  onTitlePointerUp(e) {
    if (!this.isLocked) {
      if (!this.isClicked) {
        this.isClicked = true;
        this.awaitClick = setTimeout(() => {
          this.isClicked = false;
        }, 600);
      } else {
        this.onDoubleClick(e);
        this.isClicked = false;
      }
    }
  }

  addObjects(objects) {
    // find appropriate slides
    for (let j = 0; j < objects.length; j++) {
      const object = objects[j];
      const objectBounds = object.content.getBounds();
      for (let i = 0; i < this.slides.length; i++) {
        const slide = this.slides[i];
        let bounds = slide.content.getBounds();

        if (bounds.containsRectangle(objectBounds) && !SharedElementHelpers.IsReport(object)) {
          slide.addObject(object);
          break;
        }
      }
    }
  }

  getAllObjects() {
    let objects = [];
    for (let i = 0; i < this.slides.length; i++) {
      const slide = this.slides[i];
      objects = objects.concat(slide.objects);
    }
    return objects;
  }

  removeObjects(objects) {
    for (let j = 0; j < objects.length; j++) {
      const object = objects[j];
      for (let i = 0; i < this.slides.length; i++) {
        const slide = this.slides[i];
        const index = slide.objects.indexOf(object);

        if (index !== -1) {
          slide.removeObject(object);
          break;
        }
      }
    }
  }

  onZoomChanged() {
    this.setZoomLevel(window.app.viewport.scaled);
  }

  setZoomLevel(scale) {
    if (this.delegate && this.delegate.onReportViewBeforeContentShift) {
      this.delegate.onReportViewBeforeContentShift(this);
    }

    this.rescaleButtons(scale);
    this.adjustPositions(scale);

    this.refreshAdditionsSlides();

    this.slides.forEach((slide, index) => {
      slide.onZoomChanged();
    });

    this.drawDashedBounds();
  }

  adjustPositions(scale) {
    scale = scale === undefined ? window.app.viewport.scaled : scale;
    this.rescaleRepositionTitle(scale);
    this.positionButtons();
  }

  rescaleButtons(scale) {
    scale = scale === undefined ? window.app.viewport.scaled : scale;
    const toScale = scale > SCALE_THRESHOLD ? 1 / scale : 1 / SCALE_THRESHOLD;

    this.lockReportButton.scale.set(toScale);
    this.sendReportButton.scale.set(toScale);
    this.downloadReportButton.scale.set(toScale);
  }

  rescaleRepositionTitle(scale) {
    scale = scale === undefined ? window.app.viewport.scaled : scale;

    if (scale > SCALE_THRESHOLD) {
      this.title.scale.set(1 / scale);
      this.title.x = -C_SELECTION_BOUNDARY_GAP;
      this.title.y = -C_SELECTION_BOUNDARY_GAP + REPORT_TITLE_OFFSET_Y / scale - 2 / scale;
    } else {
      this.title.scale.set(1 / SCALE_THRESHOLD);
      this.title.x = -C_SELECTION_BOUNDARY_GAP;
      this.title.y = -this.title.height * SCALE_THRESHOLD + REPORT_TITLE_OFFSET_Y / SCALE_THRESHOLD;
    }
  }

  enterEditMode() {
    this.isEditMode = true;
    this.title.disabled = false;
    this.title.select();

    clearTimeout(this.awaitClick);
    this.isClicked = false;
    window.app.needsRendering();
  }

  outEditMode() {
    if (this.isEditMode) {
      if (!this.title.text.length) {
        this.setTitle(REPORT_TITLE_DEFAULT);
      }
      this.isEditMode = false;
      this.title.disabled = true;
      window.app.needsRendering();
    }
  }

  drawDashedBounds() {
    this.graphics.clear();
    this.graphics.lineStyle(2, COLOR_REPORT_DASHED_LINE);

    const bounds = this.content.getLocalBounds();
    const padding = C_SELECTION_BOUNDARY_GAP;

    const bottomY = bounds.y + bounds.height + padding;

    let pointA = new PIXI.Point(bounds.x - padding, -padding);
    let pointB = new PIXI.Point(bounds.x + bounds.width + padding, -padding);
    let pointC = new PIXI.Point(bounds.x + bounds.width + padding, bottomY);
    let pointD = new PIXI.Point(bounds.x - padding, bottomY);

    LineDrawHelper.drawDashedLine(this.graphics, pointA, pointB);
    LineDrawHelper.drawDashedLine(this.graphics, pointB, pointC);
    LineDrawHelper.drawDashedLine(this.graphics, pointC, pointD);
    LineDrawHelper.drawDashedLine(this.graphics, pointD, pointA);
  }

  getFrameOffset() {
    let scale = window.app.viewport.scaled;
    return { x: -10 * scale, y: -10 * scale };
  }

  getEndPoint() {
    let scale = window.app.viewport.scaled;
    const bounds = this.content.getLocalBounds();
    return {
      width: (this.content.width + 20) * this.scale.x * scale,
      height: (bounds.height + bounds.y + 20) * this.scale.y * scale,
    };
  }

  onSingleSelectionVisibilityChanged(visibitily) {
    this.graphics.visible = !visibitily;
  }

  getSlidesImages(viewport) {
    const canvasPromises = [];
    this.downloadReportTooltip.visible = false;

    for (let i = 0; i < this.slides.length; i++) {
      const slide = this.slides[i];
      const canvasPromise = slide.getCanvasPromise(viewport);
      canvasPromises.push(canvasPromise);
    }

    this.downloadReportTooltip.visible = true;

    return canvasPromises;
  }

  containtsObject(object) {
    for (let i = 0; i < this.slides.length; i++) {
      if (this.slides[i].containtsObject(object)) {
        return true;
      }
    }

    return false;
  }

  getInsideSlideIndex(object) {
    let index = -1;
    for (let i = 0; i < this.slides.length; i++) {
      if (this.slides[i].containtsObject(object)) {
        return i;
      }
    }

    return index;
  }

  isInsideSlide(element) {
    for (let i = 0; i < this.slides.length; i++) {
      const slideBounds = this.slides[i].content.getBounds();
      const elementBounds = element.content.getBounds();
      if (slideBounds.containsRectangle(elementBounds)) {
        return true;
      }
    }

    return false;
  }

  isEmpty() {
    for (let i = 0; i < this.slides.length; i++) {
      if (!this.slides[i].isEmpty()) {
        return false;
      }
    }
    return true;
  }

  prepBreakPointsForMove(position, sceneManager, shouldFreeze = true) {
    let includedObjects = [];

    for (let i = 0; i < this.slides.length; i++) {
      const slide = this.slides[i];
      const objects = slide.objects;
      for (let j = 0; j < objects.length; j++) {
        const object = objects[j];
        includedObjects.push(object);
      }
    }

    if (shouldFreeze) {
      includedObjects.forEach((element) => {
        // do not set freeze data in this case
        // For some reason if we are still dragging an element that is part of the report view
        // and right before the element is being dragged andother mulsiselection dragging have occured
        // if you wait a little , then this method is being fired after a few seconds
        // and it causes to overwrite the forzen data in mid dragging

        if (position && position.global && position.global.x && position.global.y) {
          element.freezeStartMoveData(element.position, position);
        }
      });
    }

    const joints = sceneManager.findInclusiveJoints(includedObjects);
    for (let i = 0; i < joints.length; i++) {
      const joint = joints[i];
      joint.isShifting = true;
      if (shouldFreeze) {
        joint.saveReferentPoint();
      }
    }
  }

  freezeElementsPositions(dummyPositon) {
    for (let i = 0; i < this.slides.length; i++) {
      const slide = this.slides[i];
      const objects = slide.objects;
      for (let j = 0; j < objects.length; j++) {
        const object = objects[j];
        object.freezeStartMoveData(dummyPositon, null);
      }
    }
  }

  onDestroy() {
    super.onDestroy();
    AppSignals.setOutEditMode.remove(this.outEditMode, this);
    AppSignals.viewportClicked.remove(this.outEditMode, this);
  }

  createSpinButton() {
    this.downloadReportButton.setTextures('blank-btn', 'blank-btn');
    this.downloadReportButton.interactive = false;
    this.downloadReportButton.interactiveChildren = false;
    const spinner = new SteppedSpinner();
    spinner.x = -this.downloadReportButton.content.width / 2;
    spinner.y = this.downloadReportButton.content.height / 2;
    this.spinner = spinner;
    spinner.start();
    this.downloadReportButton.addChild(spinner);

    window.app.needsRendering();
  }

  onPDFCreationFinished() {
    this.downloadReportButton.setTextures(TEXTURE_NAME_DOWNLOAD, TEXTURE_NAME_DOWNLOAD_HOVER);
    this.downloadReportButton.interactive = true;
    this.downloadReportButton.interactiveChildren = true;

    if (this.spinner) {
      this.spinner.stop();
      this.spinner.removeFromParent();
      this.spinner.destroy();
      this.spinner = null;
    }
  }

  onCustomIconsLoaded(icons) {
    for (let i = 0; i < this.slides.length; i++) {
      const slide = this.slides[i];
      slide.onCustomIconsLoaded(icons);
    }
  }

  setGlobalStyle(mainSlide, globalStyle, isGlobal) {
    if (isGlobal) {
      this.previousStyleSlides = [];
      const styleBatch = new CommandBatchStyleReport(mainSlide, true, true);
      for (let i = 0; i < this.slides.length; i++) {
        const slide = this.slides[i];
        const style = cloneData(slide.style);
        this.previousStyleSlides.push(style);
        const styleCommand = new CommandStyleReportSlide(slide, globalStyle);
        styleBatch.add(styleCommand);
      }
      AppSignals.commandCreated.dispatch(styleBatch);
    } else {
      const styleBatch = new CommandBatchStyleReport(mainSlide, true, false);
      for (let i = 0; i < this.slides.length; i++) {
        const slide = this.slides[i];
        const style = this.previousStyleSlides[i];
        const styleCommand = new CommandStyleReportSlide(slide, style);
        styleBatch.add(styleCommand);
      }
      AppSignals.commandCreated.dispatch(styleBatch);
    }
    app.needsRendering();
  }
}
