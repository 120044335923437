import { adType, filterForExclusion } from 'react-project/FacebookIntegration/helpers';

export const updateItemStates = (prevState, items, selectedId) => {
  const adsChecked = [];
  const adsUnchecked = [];
  const newState = prevState.map((i) => ({ ...i }));
  // getters
  const getItemState = (id) => newState.find((i) => i.id === id && !filterForExclusion(i))?.state;
  // setters
  const updateParent = (id) => {
    const item = items.find((i) => i.id === id);
    const parent = items.find((i) => i.id === item.parentId);
    if (!parent) return;
    const childIds = items.filter((i) => i.parentId === parent.id).map((i) => i.id);
    // const childStates = childIds.map((childId) => getItemState(childId));
    const childStates = childIds.reduce((result, childId) => {
      const itemState = getItemState(childId);
      if (typeof itemState !== 'number') return result;
      result.push(itemState);
      return result;
    }, []);
    if (childStates.length === childStates.filter((s) => s === 1).length) {
      newState.find((i) => i.id === parent.id).state = 1;
    } else if (childStates.length === childStates.filter((s) => s === 0).length) {
      newState.find((i) => i.id === parent.id).state = 0;
    } else {
      newState.find((i) => i.id === parent.id).state = 2;
    }
    updateParent(parent.id);
  };
  const setUnchecked = (id) => {
    newState.find((i) => {
      if (i.id === id) {
        i.state = 0;
        const { id, type, campaignId, adSetId, totalSpend } = i;
        i.type === adType.AD &&
          adsUnchecked.push({
            id,
            type,
            campaignId,
            adSetId,
            totalSpend: { ...totalSpend, value: Number(totalSpend?.value) },
            adsTotalSpend: {},
          });
      }
    }); // changes state to item being updated of the item in the loop
    items
      .filter((i) => i.parentId === id) // reduces list down to just the children of the parent
      .map((i) => i.id) // transforms array to just ids
      .forEach((childId) => setUnchecked(childId)); // calls setUnchecked for each child
    updateParent(id); // then updates parent
  };
  const setChecked = (id) => {
    newState.find((i) => {
      if (i.id === id) {
        i.state = 1;
        const { id, type, campaignId, adSetId, totalSpend } = i;
        i.type === adType.AD &&
          adsChecked.push({
            id,
            type,
            campaignId,
            adSetId,
            totalSpend: { ...totalSpend, value: Number(totalSpend?.value) },
            adsTotalSpend: {},
          });
      }
    });
    items
      .filter((i) => i.parentId === id && !i.excludeFromCheckbox)
      .map((i) => i.id)
      .forEach((childId) => setChecked(childId));
    updateParent(id);
  };
  // actual logic
  const itemState = getItemState(selectedId);
  if (itemState === 1 || itemState === 2) {
    setUnchecked(selectedId);
  } else {
    setChecked(selectedId);
  }
  // return the newState object and {chcked: [], unchecked: []};

  return { updatedState: newState, adsChecked, adsUnchecked };
};

export default updateItemStates;
