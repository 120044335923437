import Styles from 'pixi-project/view/Styles';
import { roundTo, toPercent } from 'shared/CSharedMethods';

export const COMPARE_TEXTURE_SCALE = 0.75;
export const SEPARATOR_MARGIN = 6;
export const SEPARATOR_THICKNESS = 4;

export default class BaseAnalyticsManager {
  constructor(controller, view, data = null) {
    this.currency = '$';
    this.controller = controller;
    this.view = view;
    this.data = data;
    this.percent = 0;
    this.compareRange = { from: 0, to: 0 };
  }

  setData(data) {
    this.data = data;
    if (!this.data) {
      // clear the view
      this.view.setData([]);
    }
  }

  setNoData() {
    this.data = [{ value: 'n/a' }];
  }

  process() {
    // This is to be considered an abstract method
  }

  verifyData() {
    // Each manager will have its own impelemntation if it decides to verify the data
    return true;
  }

  showPercent() {
    if (this.data && this.view.canHaveValue) {
      this.view.setData([{ value: toPercent(this.percent, 1), ...Styles.FOOTER_PERCENT }]);
    }
  }

  showData() {
    if (this.data && this.view.canHaveValue) {
      this.view.setData([this.data['hits']]);
    }
  }

  getCompareData() {
    if (this.data['compare_to_data']) {
      let currentHits = this.data['hits'];
      let compareHits = this.data['compare_to_data']['hits'];
      let percent = this.parsePercentChange(compareHits, currentHits);

      return { currentHits, compareHits, percent };
    }
    return null;
  }

  parsePercentChange(startValue, endValue) {
    const change = endValue / startValue;

    if (isNaN(change)) {
      return '0';
    } else if (change === Infinity) {
      // If the change is infitly large ,
      // lets just say in a human way that its 100% increase
      return '100';
    }

    if (change === 1) {
      // if the change is 1 , it means there is no change at all.
      return '0';
    } else if (change > 1) {
      // that is a change in the positive scale
      return Math.round((roundTo(change, 2) - 1) * 100).toString();
    } else {
      // below 1 is a negative change
      return (Math.round((1 - roundTo(change, 2)) * 100) * -1).toString();
    }
  }

  getCompareTexture(value) {
    return PIXI.utils.TextureCache[this.isPositive(value) ? 'compare-up' : 'compare-down'];
  }

  getForecastCompareTexture(value) {
    return PIXI.utils.TextureCache[this.isPositive(value) ? 'compare-up' : 'compare-down'];
  }

  isZero(percent) {
    return Number(percent) === 0;
  }

  isPositive(percent) {
    return Number(percent) > 0;
  }

  setCompareRange(range) {
    this.compareRange = range;

    this.updateCompareRange();
  }

  updateCompareRange() {
    this.view.updateView(this.compareRange);
  }
}
