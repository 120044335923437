export const EConnectionCentered = {
  NONE: 'NONE',
  A: 'A',
  B: 'B',
};

export const EConnectionLineType = {
  SOLID: 'SOLID',
  DOTTED: 'DOTTED',
  NODATA: 'NODATA',
};

export const EConnectionDrawLineType = {
  STRAIGHT: 'STRAIGHT',
  BEZIER: 'BEZIER',
};

export const EConnectionType = {
  INCOMING: 'INCOMING',
  OUTGOING: 'OUTGOING',
};
