import React from 'react';
import { Options, Select } from 'react-project/components/input/Select-Options/Select-Options';

export const LibrarySelect = ({
  name,
  optionData,
  formDisabled,
  loading,
  isSaving,
  formValues,
  handleFormChange,
}) => {
  return (
    <>
      <Select
        disabled={formDisabled || loading || isSaving}
        className={'library-form-select'}
        // name={'offerType'}
        name={name}
        value={formValues}
        onChange={handleFormChange}
        required={formValues === 'Select an Option' || formValues === null}
        hasError={(formValues === 'Select an Option' || formValues === null) && !formDisabled}
      >
        {loading ? <option>...loading</option> : <Options optData={optionData} />}
      </Select>
    </>
  );
};
