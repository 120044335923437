import React, { useEffect, useState } from 'react';
import { getLoginUrl, getWorkspaceFunnelsListUrl } from 'react-project/Util/ExternalUrlHelper';
import { fetchFunnelAsync } from 'react-project/redux/funnels/actions';
import { useDispatch } from 'react-redux';
import styles from './Main.module.scss';

export const NoAccess = ({ funnelId }) => {
  const dispatch = useDispatch();
  const [projectId, setProjectId] = useState(null);
  const [countdown, setCountdown] = useState(10); // Initial countdown value in seconds

  useEffect(() => {
    const fetchFunnelData = async () => {
      try {
        const responseData = await dispatch(fetchFunnelAsync(funnelId));
        if (responseData) {
          setProjectId(responseData.data.projectId);
        }
      } catch (error) {
        console.log('Error: ', error);
      }
    };
    fetchFunnelData();
  }, []);

  const getHomeLink = () => {
    if (!projectId) {
      return `${process.env.REACT_APP_CURRENT_APP_URL}`;
    } else {
      const encoded = encodeURIComponent(`${getWorkspaceFunnelsListUrl(projectId)}?f=${funnelId}`);
      return `${getLoginUrl()}?url=${encoded}`;
    }
  };

  useEffect(() => {
    // Update the countdown every second
    if (!projectId) return;
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Cleanup the timer when the component unmounts
    return () => clearInterval(timer);
  }, [projectId]);

  useEffect(() => {
    // Redirect to the Dashboard when the countdown reaches 0
    if (countdown === 0) {
      window.open(getHomeLink(), '_self');
    }
  }, [countdown]);

  return (
    <div className={styles.NoAccess}>
      <h1>You don't have permissions to access this canvas</h1>
      {projectId && countdown > 0 && (
        <div> You'll be redirect to your Dashboard (in {countdown} seconds)</div>
      )}
      <a href={getHomeLink()}>Go to your Dashboard</a>
    </div>
  );
};

export default NoAccess;
