import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { convertFromHTML } from 'draft-convert';
import {
  OPERATOR_EQUAL,
  OPERATOR_NOT_EQUAL,
} from 'react-project/Toolbar/step-toolbar/LogicalOperators';
import { FUNNEL_CONFIGURATION, IconsConfig } from 'shared/CSharedConstants';
import { LayerType } from 'react-project/LayersMenu/LayerType';
import { cloneData } from 'shared/CSharedMethods';

export const CANVAS_TRANSFORM_SCALE_FACTOR = 1.3;

const CATEGORY_STEP = 'STEP';
const CATEGORY_SHAPE = 'SHAPE';
const CATEGORY_PHOTO = 'PHOTO';
const CATEGORY_TEXT = 'TEXT';
const CATEGORY_CONNECTION = 'CONNECTION';

const TYPE_PAGE = 'PAGE';
const TYPE_EVENT = 'EVENT';
const TYPE_SOURCE = 'SOURCE';
const TYPE_MISC = 'MISC';
const TYPE_PHOTO = 'PHOTO';
const TYPE_NONE = 'NONE';

const SHAPE_RECTANGLE = 'RECTANGLE';
const SHAPE_ELLIPSE = 'ELLIPSE';
const SHAPE_TRIANGLE = 'TRIANGLE';

const DRAW_TYPE_STRAIGHT = 'STRAIGHT';
const DRAW_TYPE_BEZIER = 'BEZIER';

const LINE_TYPE_DOTTED = 'DOTTED';
const LINE_TYPE_SOLID = 'SOLID';

export class CanvasTransformator {
  constructor() {
    this.scaleFactor = CANVAS_TRANSFORM_SCALE_FACTOR;

    this.emptyTemplate = {
      objects: [],
      joints: [],
      funnelConfiguration: cloneData(FUNNEL_CONFIGURATION),
      version: 2,
      source: 'canvas 1.0',
      notes: null,
    };

    this.transformMethods = {
      'funnel_steps.Page': (data) => {
        return this.createStep(data, TYPE_PAGE);
      },
      'funnel_steps.Event': (data) => {
        return this.createStep(data, TYPE_EVENT);
      },
      'funnel_steps.Source': (data) => {
        return this.createStep(data, TYPE_SOURCE);
      },
      'funnel_steps.Offline': (data) => {
        return this.createStep(data, TYPE_MISC);
      },
      'standard.Rectangle': (data) => {
        // Sometimes rectangle is used as a text container
        if (data.attrs && data.attrs.label && data.attrs.label.text) {
          return this.createText(data);
        }
        return this.createShape(data, SHAPE_RECTANGLE);
      },
      'standard.Ellipse': (data) => {
        return this.createShape(data, SHAPE_ELLIPSE);
      },
      'standard.Polygon': (data) => {
        return this.createShape(data, SHAPE_TRIANGLE);
      },
      'standard.Image': (data) => {
        return this.createPhoto(data);
      },
      'funnel_misc.TextArea': (data) => {
        return this.createText(data);
      },
      'basic.Text': (data) => {
        return this.createText(data);
      },
      'funnel_misc.Connection': (data) => {
        return this.createConnection(data);
      },
      'funnel_misc.GhostLine': (data) => {
        return this.createGhostLine(data);
      },
    };

    this.stepScaleTargets = {
      'funnel_steps.Page': { width: 105, height: 140 },
      'funnel_steps.Event': { width: 45, height: 45 },
      'funnel_steps.Source': { width: 45, height: 45 },
      'funnel_steps.Offline': { width: 40, height: 40 },
    };

    this.baseUrlsByType = {
      'funnel_steps.Page': 'StepsModal/Pages/',
      'funnel_steps.Event': 'StepsModal/Actions/',
      'funnel_steps.Source': 'StepsModal/Sources/',
      'funnel_steps.Offline': 'StepsModal/Offline/',
    };

    this.mappedActionTypes = {
      standard: 'custom',
      commerce: 'commerce',
      none: 'none',
    };

    this.utmKeys = ['utm_campaign', 'utm_content', 'utm_medium', 'utm_source', 'utm_term'];

    this.createValidIconsList();
  }

  createValidIconsList() {
    // StepsModal/Pages/genericpage.png
    // StepsModal/Sources/source.png
    // StepsModal/Offline/billboard.png
    // StepsModal/Actions/click.png
    this.validPageIcons = this.extractValidIcons([IconsConfig.PAGE]);
    this.validEventIcons = this.extractValidIcons([IconsConfig.EVENT, IconsConfig.SPECIAL_EVENT]);
    this.validMiscIcons = this.extractValidIcons([IconsConfig.MISC]);
    this.validSourceIcons = this.extractValidIcons([IconsConfig.SOURCE]);
    this.validOldIcons = {
      'StepsModal/Actions/application.png': true,
      'StepsModal/Actions/browser.png': true,
      'StepsModal/Actions/cartplus.png': true,
      'StepsModal/Actions/clipboardlist.png': true,
      'StepsModal/Actions/desktop.png': true,
      'StepsModal/Actions/diamond.png': true,
      'StepsModal/Actions/magic.png': true,
      'StepsModal/Actions/shoppingcart.png': true,
      'StepsModal/Actions/video.png': true,
      'StepsModal/Offline/ebook.png': true,
      'StepsModal/Offline/ebook_report.png': true,
      'StepsModal/Offline/guestblogpost.png': true,
      'StepsModal/Offline/onlinemeeting.png': true,
      'StepsModal/Offline/printad.png': true,
      'StepsModal/Offline/textmessage.png': true,
      'StepsModal/Sources/trafficicon.png': true,
      'StepsModal/Sources/facebookad.png': true,
      'StepsModal/Sources/adwords.png': true,
      'StepsModal/Sources/bannerad.png': true,
      'StepsModal/Sources/fbmessenger.png': true,
      'StepsModal/Sources/googleplus.png': true,
      'StepsModal/Sources/googlewords.png': true,
      'StepsModal/Sources/if_facebook_287594.png': true,
      'StepsModal/Sources/if_google_287570.png': true,
      'StepsModal/Sources/if_instagram_1107220.png': true,
      'StepsModal/Sources/if_linkedin_287553.png': true,
      'StepsModal/Sources/if_reddit_1107235.png': true,
      'StepsModal/Sources/if_twitter_2_1107225.png': true,
      'StepsModal/Sources/ebook.png': true,
      'StepsModal/Sources/ebook_report.png': true,
    };
  }

  extractValidIcons(list) {
    const validIcons = {};
    for (let i = 0; i < list.length; i++) {
      const icons = list[i];

      for (let j = 0; j < icons.length; j++) {
        const icon = icons[j];
        const key = (icon.srcPixi + icon.icon + '.png').replace('/asset/', '');
        validIcons[key] = true;
      }
    }
    return validIcons;
  }

  transform(inputData) {
    const outputData = JSON.parse(JSON.stringify(this.emptyTemplate));

    // ADD OBJECTS AND CONNECTIONS
    for (let i = 0; i < inputData.cells.length; i++) {
      const cellData = inputData.cells[i];
      this.addObjectsAndConnections(cellData, outputData);
    }

    // ADD NOTES
    if (inputData.notes) {
      outputData.notes = this.convertNote(inputData.notes);
    }

    return outputData;
  }

  addObjectsAndConnections(cellData, outputData) {
    const transformMethod = this.transformMethods[cellData.type];

    if (!transformMethod) {
      console.warn('unknown object ', cellData);
      return;
    }

    const object = transformMethod(cellData);

    if (!object) {
      console.log('Ignoring ', cellData.type, cellData);
      return;
    }

    // update notes
    if (object.notes) {
      object.notes.category = object.category;
      object.notes.type = object.type;
    }

    const targetCollection = object.category === CATEGORY_CONNECTION ? 'joints' : 'objects';

    outputData[targetCollection].push(object);
  }

  extractTitleText(data) {
    if (data.attrs['.label'].textWrap) {
      return data.attrs['.label'].textWrap.text;
    } else if (data.attrs['.label'].text) {
      return data.attrs['.label'].text;
    }

    return '';
  }

  transferCommonProperties(step, data, type) {
    step.category = CATEGORY_STEP;
    step.type = type;
    this.transformScale(step, this.stepScaleTargets[data.type], data);

    step.filterData = [];
    step.isCustom = false;
    step.label = this.extractTitleText(data);
    step.url = data.attrs.url ? data.attrs.url.text : '';
    step.integrationType = 'none';
    step.integrationAttributes = [];
    step.actionType = 'none';

    if (data.attrs['.previewthumbnail']) {
      step.useThumbnail = true;
      const thumbnailURL = data.attrs['.previewthumbnail']['xlink:href'];
      step.thumbnailURL = this.transformURL(thumbnailURL);
    } else {
      step.useThumbnail = false;
      step.thumbnailURL = '';
    }

    step.texturePath = this.extractImage(data, step);

    step.analyticsFilterData = {
      dataFilter: [],
      displayFilter: {
        avgValuePerCustomer: true,
        totalCustomers: true,
        totalRevenew: true,
      },
    };
  }

  transferEventProperties(step, data) {
    step.actionName = data.attrs.name ? data.attrs.name.text : undefined;
    step.actionType = data.actionType ? this.mappedActionTypes[data.actionType] : 'custom'; // 'none' , 'commerce' , custom

    if (!step.actionName) {
      // if there is no action selected , then its type is none
      step.actionType = 'none';
    }

    // Selected filters
    if (data.attrs.properties) {
      step.filterData = [];

      const entries = Object.entries(data.attrs.properties);
      for (let i = 0; i < entries.length; i++) {
        const [key, value] = entries[i];
        step.filterData.push({
          key: key,
          value: !value ? '*' : value, // null and empty string are to be considered *
          operator: OPERATOR_EQUAL,
        });
      }
    }

    // Checked metrics
    if (data.attrs.commerce && data.attrs.commerce.metrics) {
      const metrics = data.attrs.commerce.metrics;
      // if a certain metric in unchecked (unchecked in the UI) , it will be included as a property
      // in the metrics with a value of false , all the metrics that are checked in the UI will not
      // be stored as properties at all
      // so the metrics we are interested to show will have undefined value
      // While we use a simple bool (true/false) to show if a metric needs to be shown
      step.analyticsFilterData.displayFilter = {
        avgValuePerCustomer: metrics.value_per_customer === false ? false : true,
        totalCustomers: metrics.customers === false ? false : true,
        totalRevenew: metrics.revenue === false ? false : true,
      };
    }

    if (step.texturePath === 'StepsModal/Actions/form.png') {
      step.texturePath = 'StepsModal/Actions/lead.png';
    }

    this.validateGenericIcon(step, this.validEventIcons, 'StepsModal/Actions/click.png');
  }

  transferSourceProperties(step, data) {
    if (step.url) {
      // trackingURL
      step.integrationType = 'none';
      step.trackingURL = step.url;
      step.url = '';
    } else {
      step.integrationType = 'url-parameters';
    }

    for (let i = 0; i < this.utmKeys.length; i++) {
      const k = this.utmKeys[i];
      if (data.attrs[k]) {
        const value = data.attrs[k].text;
        step.filterData.push({
          key: k,
          value: !value ? '*' : value, // null and empty string are to be considered *
          operator: OPERATOR_EQUAL,
        });
      }
    }

    if (data.parameters) {
      for (let i = 0; i < data.parameters.length; i++) {
        const parameter = data.parameters[i];
        const value = !parameter.value ? '*' : parameter.value; // null and empty string are to be considered *
        const key = parameter.key;
        const operator = parameter.type === 'not-contain' ? OPERATOR_NOT_EQUAL : OPERATOR_EQUAL;
        step.filterData.push({ key, value, operator });
      }
    }

    // TODO maybe build a valid list of textures

    if (!data.attrs.image && !step.useThumbnail) {
      step.texturePath = 'StepsModal/Sources/trafficicon.png';
    }

    if (step.texturePath === 'StepsModal/Sources/googleadblockisawful.png') {
      step.texturePath = 'StepsModal/Sources/googlenotblocked.png';
    }

    if (step.texturePath === 'StepsModal/Sources/adnetwork.png') {
      step.texturePath = 'StepsModal/Sources/network.png';
    }

    if (step.texturePath === 'StepsModal/Sources/googlewords.png') {
      step.texturePath = 'StepsModal/Sources/google.png';
    }

    if (step.texturePath === 'StepsModal/Sources/googleads.png') {
      step.texturePath = 'StepsModal/Sources/googlenotblocked.png';
    }

    if (step.texturePath === 'StepsModal/Sources/printad.png') {
      step.texturePath = 'StepsModal/Sources/print.png';
    }

    this.validateGenericIcon(step, this.validSourceIcons, 'StepsModal/Sources/trafficicon.png');
  }

  validateGenericIcon(step, list, defaultIcon) {
    if (!step.isCustom) {
      if (!this.validateIcon(step.texturePath, list)) {
        // Check known icons that are not in the list
        // but are valid and are hosted in the funnelytics server

        if (this.validateIcon(step.texturePath, this.validOldIcons)) {
          return;
        }

        console.warn(
          `${step.texturePath} icon is not found in the funnelytics actions list , setting the default icon ${defaultIcon}`,
          step,
        );

        step.texturePath = defaultIcon;
      }
    }
  }

  validateIcon(iconName, list) {
    return list[iconName] ? true : false;
  }

  transferPageProperties(step, data) {
    if (step.texturePath === 'StepsModal/Pages/salespagewvideo.png') {
      step.texturePath = 'StepsModal/Pages/salespagevideo.png';
    }

    step.x += 10;
    step.y += 20;

    this.validateGenericIcon(step, this.validPageIcons, 'StepsModal/Pages/genericpage.png');
  }

  transferMiscProperties(step, data) {
    if (step.texturePath === 'StepsModal/Offline/printad.png') {
      step.texturePath = 'StepsModal/Offline/print.png';
    }

    this.validateGenericIcon(step, this.validMiscIcons, 'StepsModal/Offline/billboard.png');
  }

  createStep(data, type) {
    const step = this.transformCommon(data);

    this.transferCommonProperties(step, data, type);

    if (type === TYPE_EVENT) {
      this.transferEventProperties(step, data);
    } else if (type === TYPE_SOURCE) {
      this.transferSourceProperties(step, data);
    } else if (type === TYPE_PAGE) {
      this.transferPageProperties(step, data);
    } else if (type === TYPE_MISC) {
      this.transferMiscProperties(step, data);
    }

    // if it has a custom thumbnail
    // legacySize

    if (step.isCustom && data.size) {
      step.legacySize = data.size;
      // scale needs to be overwritten in the case of custom icons
      step.scaleX = 1;
      step.scaleY = 1;
      step.isMigratedFromLegacy = true;
    } else if (data.size) {
      step.legacySize = data.size;
      step.isMigratedFromLegacy = true;
    }

    return step;
  }

  extractImage(data, step) {
    return data.attrs['image'] && data.attrs['image']['xlink:href']
      ? this.transformThumnbial(data.attrs['image']['xlink:href'], data.type, step)
      : 'StepsModal/Pages/genericpage.png';
  }

  transformThumnbial(originalThumbnail, type, step) {
    const baseURL = this.baseUrlsByType[type];
    const imageFileName = originalThumbnail.trim().split('/').pop();

    const [imageName, imageExtension] = imageFileName.split('.');

    if (imageExtension !== 'svg') {
      step.isCustom = true;
      if (originalThumbnail) {
        return this.transformURL(originalThumbnail);
      }

      return '';
    }

    return `${baseURL}${imageName.toLowerCase().replace(/-/g, '').replace(/\s/g, '')}.png`;
  }

  transformURL(url) {
    return url
      .toString()
      .split('?')[0]
      .replace(
        'https://s3-us-west-2.amazonaws.com/funnelytics-thumbnails/',
        'https://funnelytics-thumbnails.s3.us-west-2.amazonaws.com/',
      )
      .replace(
        'https://s3-us-west-2.amazonaws.com/funnelytics-production/',
        'https://funnelytics-production.s3.us-west-2.amazonaws.com/',
      );
  }

  transformScale(object, target, data) {
    object.scaleX = data.size.width / target.width;
    // Scaling must be equal
    object.scaleY = object.scaleX;
  }

  createGhostLine(data) {
    if (data.source && data.source.id && data.target && data.target.id) {
      return this.createConnection(data);
    }
    return null;
  }

  createConnection(data) {
    const isDotted = data.type === 'funnel_misc.GhostLine';
    const connection = {
      ID: data.id,
      category: CATEGORY_CONNECTION,
      type: TYPE_NONE,
      attachmentPointsLocation: { pointA: 'flow', pointB: 'flow' },
      drawLineType: DRAW_TYPE_STRAIGHT,
      goal: null,
      lineType: isDotted ? LINE_TYPE_DOTTED : LINE_TYPE_SOLID,
      iconA_ID: data.source.id,
      iconB_ID: data.target.id,
      headAAngle: 0,
      headAVisible: false,
      headBAngle: 90, // Angle needs to be recaluclated and ignored in here
      headBVisible: true,
      ignoreInBetween: isDotted,
      breakPoints: [],
    };

    if (data.connector && data.connector.name === 'smooth') {
      connection.drawLineType = DRAW_TYPE_BEZIER;
      connection.attachmentPointsLocation = { pointA: 'center', pointB: 'center' };
    }

    // The middle point will be special
    if (data.vertices && data.vertices.length) {
      for (let i = 0; i < data.vertices.length; i++) {
        const v = data.vertices[i];
        connection.breakPoints.push({
          x: v.x * this.scaleFactor,
          y: v.y * this.scaleFactor,
          isSpecial: false,
        });
      }

      const specialV = connection.breakPoints[Math.floor((connection.breakPoints.length - 1) / 2)];
      specialV.isSpecial = true;
    }

    return connection;
  }

  createText(data) {
    const text = this.transformCommon(data);
    text.category = CATEGORY_TEXT;
    text.type = TYPE_NONE;
    text.text = 'Text Label';
    text.hyperlink = null;
    text.wordWrapWidth = 0;
    text.textStyle = {
      fontFamily: 'Roboto',
      color: '#000000',
      fontSize: '24px',
      align: 'left',
      fontStyle: 'normal',
      fontWeight: 'normal',
    };

    // adjust position
    if (data.size) {
      text.x += 30;
      text.y += 10;
    }

    // Extract Text and Style

    if (data.attrs.label && data.attrs.label.text) {
      const extractedText = data.attrs.label.text;
      text.text = extractedText.replace(/[Â]/g, '');
      this.transfertTextStyle(data.attrs.label, text.textStyle);
    }

    if (data.attrs.label && data.attrs.label.annotations && data.attrs.label.annotations.length) {
      const annotation = data.attrs.label.annotations[0].attrs;
      this.transfertTextStyle(annotation, text.textStyle);
    }

    if (data.attrs.text && data.attrs.text) {
      const extractedText = data.attrs.text.text;
      text.text = extractedText.replace(/[Â]/g, '');
      this.transfertTextStyle(data.attrs.text, text.textStyle);
    }

    return text;
  }

  transfertTextStyle(object, textStyle) {
    if (object['fill']) {
      textStyle.color = object['fill'];
    }

    if (object['font-size']) {
      textStyle.fontSize = object['font-size'] + 'px';
    }

    if (object['font-style']) {
      textStyle.fontStyle = object['font-style'];
    }

    if (object['font-weight']) {
      textStyle.fontWeight = object['font-weight'];
    }
  }

  createShape(data, type) {
    const shape = this.transformCommon(data);
    shape.category = CATEGORY_SHAPE;
    shape.type = type;

    shape.shapeData = {
      category: CATEGORY_SHAPE,
      type: type,
      x: 0,
      y: 0,
      width: 200,
      height: 200,
      shapeStyle: {
        borderColor: 1975601,
        fillColor: 16777215,
      },
    };

    if (type === SHAPE_TRIANGLE) {
      shape.shapeData.orientation = 'down';
    }

    if (data.size) {
      shape.shapeData.width = data.size.width * this.scaleFactor;
      shape.shapeData.height = data.size.height * this.scaleFactor;
    }

    if (type === SHAPE_ELLIPSE) {
      shape.shapeData.width = shape.shapeData.width / 2;
      shape.shapeData.height = shape.shapeData.height / 2;
      shape.shapeData.x = shape.shapeData.width;
      shape.shapeData.y = shape.shapeData.height;
    }

    if (data.attrs.body) {
      if (data.attrs.body.stroke) {
        shape.shapeData.shapeStyle.borderColor = this.convertColor(data.attrs.body.stroke);
      }

      if (data.attrs.body.fill) {
        shape.shapeData.shapeStyle.fillColor = this.convertColor(data.attrs.body.fill);
      }
    }

    shape.y -= 10;

    return shape;
  }

  transformCommon(data) {
    const object = {
      ID: data.id,
      x: Math.round(data.position.x * this.scaleFactor),
      y: Math.round(data.position.y * this.scaleFactor),
      isShowTool: true,
      url: '',
      label: '',
      scaleX: 1,
      scaleY: 1,
      notes: data.attrs.planningNotes ? this.convertNote(data.attrs.planningNotes, data) : null,
    };

    return object;
  }

  createPhoto(data) {
    const photo = this.transformCommon(data);
    photo.category = CATEGORY_PHOTO;
    photo.type = TYPE_PHOTO;

    if (data.attrs.image && data.attrs.image['xlink:href']) {
      let imageURL = data.attrs.image['xlink:href'];
      imageURL = this.transformURL(imageURL);

      photo.photoData = {
        imageURL: imageURL,
        fileID: imageURL.split('/').pop(),
        fileName: 'photo.jpg',
        stepId: photo.ID,
        legacySize: data.size,
      };
    }

    return photo;
  }

  convertColor(hex) {
    if (hex === 'none') {
      return 'transparent';
    }

    return Number(hex.toString().replace('#', '0x'));
  }

  convertNote(note, objectData) {
    const operations = note.ops;
    const cfg = {};
    // https://github.com/nozer/quill-delta-to-html
    const converter = new QuillDeltaToHtmlConverter(operations, cfg);
    let html = converter.convert();

    if (html) {
      html = html.replaceAll('href="unsafe:', 'href="');
    }

    const state = convertFromHTML({
      htmlToBlock: (nodeName, node, lastList, inBlock) => {
        if (
          (nodeName === 'figure' && node.firstChild.nodeName === 'IMG') ||
          (nodeName === 'figure' && node.firstChild.nodeName === 'iframe') ||
          (nodeName === 'img' && inBlock !== 'atomic') ||
          (nodeName === 'iframe' && inBlock !== 'atomic')
        ) {
          return 'atomic';
        }
      },
      htmlToEntity: (nodeName, node, createEntity) => {
        if (nodeName === 'a') {
          return createEntity('LINK', 'MUTABLE', { url: node.href });
        } else if (nodeName === 'img') {
          return createEntity('IMAGE', 'IMMUTABLE', { src: node.src });
        } else if (nodeName === 'iframe') {
          return createEntity('draft-js-video-plugin-video', 'IMMUTABLE', { src: node.src });
        }
      },
      entityToHTML: (entity, originalText) => {
        if (entity.type === 'IMAGE') {
          return `<img src="${entity.data.src}" />`;
        } else if (entity.type === 'draft-js-video-plugin-video') {
          return `<iframe src="${entity.data.src}" ></iframe>`;
        }
      },
      blockRendererFn: (block) => {
        if (
          block.getType() === 'atomic' &&
          block.length > 0 &&
          Entity.get(block.getEntityAt(0)).getType() === 'IMAGE'
        ) {
          return {
            component: ({ block }) => {
              const { src } = Entity.get(block.getEntityAt(0)).getData();
              return <img src={src} />;
            },
            editable: false,
          };
        } else if (
          block.getType() === 'atomic' &&
          block.length > 0 &&
          Entity.get(block.getEntityAt(0)).getType() === 'draft-js-video-plugin-video'
        ) {
          return {
            component: ({ block }) => {
              const { src } = Entity.get(block.getEntityAt(0)).getData();
              return <iframe src={src}></iframe>;
            },
            editable: false,
          };
        }
      },
    })(html);

    const editorState = EditorState.createWithContent(state);
    const content = editorState.getCurrentContent();
    const plainText = content.getPlainText();

    const raw = convertToRaw(content);
    const titleText = objectData ? this.extractTitleText(objectData) : 'Canvas Note';
    return {
      data: raw,
      timestamp: new Date().toISOString(),
      title: titleText,
      subTitle: this.extractTitle(plainText),
      category: null,
      type: null,
    };
  }

  extractTitle(plainText) {
    return plainText.split('\n')[0].trim();
  }
}
