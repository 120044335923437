import MainStorage from 'pixi-project/core/MainStorage';
import RequestService from 'react-project/Helpers/RequestService';
import { ActionNames } from 'react-project/redux/actionNamesConstant';
import { createAction } from 'redux-actions';

export const setPermission = createAction(ActionNames.setPermission);
export const setEmail = createAction(ActionNames.setEmail);
export const setShowDeveloperContent = createAction(ActionNames.setShowDeveloperContent);
export const setCanvasPermissions = createAction(ActionNames.setCanvasPermissions);

const requestService = new RequestService();

export const loadAllCanvasPermissionsAsync = (canvasId, isTutorial, isViewOnly) => {
  return async (dispatch) => {
    let canvasPermissions;
    let counter = 3;
    while ((!canvasPermissions || canvasPermissions?.status !== 200) && counter > 0) {
      canvasPermissions = await requestService.getCanvasPermissions(canvasId);
      // keep it for debugging

      // canvasPermissions = {
      //   isActionsAllowed: true,
      //   isAnalyticsAllowed: true,

      //   isForecastAllowed: true,
      //   notesAllowed: true,
      //   checklistAllowed: true,
      //   viewOnlyAllowed: true,
      //   pngExportAllowed: false,
      //   iconsAllowed: false,

      //   peopleCompareFilterAllowed: true,
      //   peopleFilterAllowed: true,
      //   trafficModeAllowed: true,
      //   widgetsAllowed: true,
      //   peopleDataExportAllowed: true,
      //   peopleCsvExportAllowed: true,
      //   pathExploreAllowed: true,

      //   gohighlevelIntegrationAllowed: false,
      //   hubspotIntegrationAllowed: false,
      //   webhookIntegrationAllowed: false,
      //   zapierIntegrationAllowed: false,

      //   dateCompareFilterAllowed: true,
      //   calendarTrackingAllowed: false, // meeting tracking
      //   dealTrackingAllowed: false,
      //   formTrackingAllowed: false,
      //   purchaseTrackingAllowed: false,
      //   isEligibleForFreeTrial: false,
      //   isFunnelytics: false,
      //   isInTrial: false,
      //   unlockCanvasAllowance: {
      //     unlocked_canvases_allowed: -1,
      //     unlocked_canvases: -1,
      //   },
      //   dataRetention: 24,
      //   isCanvasLocked: false,
      //   isReadonlyAccess: false,
      //   isCollaborator: false,
      //   hasStripePayment: false,
      //   isTutorial: false,
      //   isAdminImpersonation: false,
      // };

      if (canvasPermissions) {
        // TODO this is quick trick to make planning allowed if forecasting is allowed
        console.warn('Needs Attention');
        canvasPermissions.isPlanningAllowed = canvasPermissions.isForecastAllowed;
        break;
      } else {
        counter--;
      }
    }

    if (canvasPermissions) {
      canvasPermissions.isTutorial = isTutorial || false;
      canvasPermissions.isReadonlyAccess = isViewOnly || false;
    }

    dispatch(setCanvasPermissions(canvasPermissions));
    MainStorage.setCanvasPermissions(canvasPermissions);
  };
};

export const loadPermissionAsync = ({ scopeId, permission, scope, projectId, level }) => {
  return async (dispatch) => {
    const hasPermission = await requestService.hasUserPermission({
      scopeId,
      permission,
      projectId,
      scope,
      level,
    });
    const fetchMetaPermission = await requestService.hasUserMetaPermission();

    if (hasPermission) {
      dispatch(setPermission({ name: permission, permitted: hasPermission.permitted }));
    }
    if (fetchMetaPermission) {
      dispatch(
        setPermission({
          name: 'facebook_integration',
          permitted: fetchMetaPermission.facebook_integration,
        }),
      );
    }
  };
};

export const loadUserData = ({ userId }) => {
  return async (dispatch) => {
    const user = await requestService.getUserData({ userId });
    if (user) {
      dispatch(setEmail(user.email));
    }
  };
};

export const updateShowDeveloperContent = ({ show }) => {
  return async (dispatch) => {
    dispatch(setShowDeveloperContent(show));
  };
};
