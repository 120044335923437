import React, { useEffect } from 'react';
import styles from 'react-project/LayersMenu/LayersMenu.module.scss';
import {
  flowIcon,
  iconCheck,
  analyticsIcon,
  iconNotesMenu,
  layersMenuIcon,
  iconAnalytics,
  iconForecasting,
} from 'react-project/assets/Icons';
import cls from 'classnames';
import { LayerType } from './LayerType';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import {
  PR_EVENT_FUNNEL_CHANGED,
  RP_EVENT_LAYER_TOGGLED,
  RR_SHOW_TUTORIAL_POPUP,
} from 'shared/CSharedEvents';
import { selectFunnelConfiguration } from 'react-project/redux/funnel-configuration/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveDataLayer, setDataMode } from 'react-project/redux/funnel-configuration/actions';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import { Tooltip } from 'react-project/components/tooltip/Tooltip';
import { When } from 'react-project/Util/When';
import MainStorage from 'pixi-project/core/MainStorage';
import { selectHasAnalyticsData } from 'react-project/redux/analytics/selectors';
import { DATA_MODE_FUNNEL, DATA_MODE_TRAFFIC } from 'shared/CSharedConstants';
import { Warning } from 'react-project/components/popUp/Warning/Warning';
import { FF_FORECASTING } from 'shared/FeatureFlags';
import { selectCurrentFunnelId, selectProjectId } from 'react-project/redux/funnels/selectors';
import { HEAP_EVENTS } from 'react-project/Constants/heapEvents';
import { sendHeapTracking } from 'react-project/Util/HEAP.utilities';
import { track as trackCohesive } from 'react-project/Util/CohesiveTracking';
import { UILock } from 'react-project/components/uilock/UILock';

const buttons = [
  {
    type: LayerType.NUMBERS,
    icon: analyticsIcon,
    title: 'Turn on/off Numbers Layer',
  },
  {
    type: LayerType.FLOW,
    icon: flowIcon,
    title: 'Turn on/off Flow Layer',
  },
];

if (FF_FORECASTING) {
  buttons.push({
    type: LayerType.FORECASTING,
    icon: iconForecasting,
    title: 'Turn on/off Forecast Layer',
  });
}

buttons.push({
  type: LayerType.NOTES,
  icon: iconNotesMenu(),
  title: 'Turn on/off Notes Layer',
});

buttons.push({
  type: LayerType.CHECKLIST,
  icon: iconCheck,
  title: 'Turn on/off Checklist Layer',
});

export const LayersMenu = ({}) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(true);
  const [isWarrningOpen, setIsWarrningOpen] = React.useState(false);
  const funnelConfiguration = useSelector(selectFunnelConfiguration);
  const canvasPermissions = useSelector(selectCanvasPermissions);
  const hasAnalyticsData = useSelector(selectHasAnalyticsData);
  const [lastToggleData, setLastToggleData] = React.useState(null);
  const dispatch = useDispatch();

  const funnelId = useSelector(selectCurrentFunnelId);
  const projectId = useSelector((state) => selectProjectId(state, funnelId));

  useEffect(() => {
    const visibleDataLayers = { ...funnelConfiguration.visibleDataLayers };

    if (!canvasPermissions.isAnalyticsAllowed) {
      visibleDataLayers[LayerType.NUMBERS] = false;
      visibleDataLayers[LayerType.FLOW] = false;
    }

    if (!canvasPermissions.notesAllowed) {
      visibleDataLayers[LayerType.NOTES] = false;
    }

    if (!canvasPermissions.checklistAllowed) {
      visibleDataLayers[LayerType.CHECKLIST] = false;
    }

    MainStorage.setTogglePanelStatus(visibleDataLayers);
  }, [funnelConfiguration.visibleDataLayers]);

  useEffect(() => {
    // The event for toggling the layer need to be sent once the state is updated
    // it is especially important because of MainStorage.setTogglePanelStatus(visibleDataLayers);

    if (lastToggleData) {
      commonSendEventFunction(RP_EVENT_LAYER_TOGGLED, lastToggleData);
    }
  }, [lastToggleData]);

  useEffect(() => {
    if (funnelConfiguration.dataMode === DATA_MODE_TRAFFIC) {
      if (isActive(LayerType.FORECASTING) && isActive(LayerType.NUMBERS)) {
        console.log("//TODO notify users that they can't have both layers active in traffic mode");
        // if numbers and forecasting layers are active in traffic mode , deactivate the forecasting layer
        setMenuItem(LayerType.FORECASTING, false);
      }
    }
  }, [funnelConfiguration.dataMode]);

  const onMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const setMenuItem = (type, active) => {
    dispatch(setActiveDataLayer({ type: type, isActive: active }));

    const data = {
      type: type,
      isActive: active,
    };
    setLastToggleData(data);

    commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
    window.app.needsRendering();
  };

  const onButtonClicked = (type) => {
    // prevent the buttons from clicking while the app is loading
    if (
      !funnelConfiguration.visibleDataLayers ||
      funnelConfiguration.visibleDataLayers[type] === undefined
    ) {
      return false;
    }

    if (canvasPermissions.isAnalyticsAllowed && type === LayerType.NUMBERS && !hasAnalyticsData) {
      commonSendEventFunction(RR_SHOW_TUTORIAL_POPUP);
      return;
    }

    const isButtonActive = isActive(type);

    if (
      ((type === LayerType.FORECASTING && isActive(LayerType.NUMBERS)) ||
        (type === LayerType.NUMBERS && isActive(LayerType.FORECASTING))) &&
      !isButtonActive &&
      funnelConfiguration.dataMode === DATA_MODE_TRAFFIC
    ) {
      setIsWarrningOpen(true);
      return;
    }

    setMenuItem(type, !isButtonActive);

    // track if forecasting it toggled on

    if (type === LayerType.FORECASTING && !isButtonActive) {
      const eventData = {
        onClick: true,
        onLoad: false,
      };
      sendHeapTracking({
        projectId,
        eventName: HEAP_EVENTS.ENABLE_FORECAST,
        eventData,
      });
      trackCohesive(HEAP_EVENTS.ENABLE_FORECAST, {
        projectId,
        ...eventData,
      });
    }
  };

  const hasPermissions = (type) => {
    if (type === LayerType.NOTES) {
      return canvasPermissions.notesAllowed;
    } else if (type === LayerType.CHECKLIST) {
      return canvasPermissions.checklistAllowed;
    } else if (type === LayerType.NUMBERS || type === LayerType.FLOW) {
      return canvasPermissions.isAnalyticsAllowed;
    } else if (type === LayerType.FORECASTING) {
      return canvasPermissions.isForecastAllowed;
    }
    return true;
  };

  const isActive = (type) => {
    if (!funnelConfiguration.visibleDataLayers) {
      return false;
    }

    // if no analytics data , don't turn on the numbers layer
    if (type === LayerType.NUMBERS && !hasAnalyticsData) {
      return false;
    }

    return funnelConfiguration.visibleDataLayers[type];
  };

  const onWarrningClose = () => {
    setIsWarrningOpen(false);
  };

  const onTurnOnFunnelMode = () => {
    if (!isActive(LayerType.FORECASTING)) {
      setMenuItem(LayerType.FORECASTING, true);
    }

    if (!isActive(LayerType.NUMBERS)) {
      setMenuItem(LayerType.NUMBERS, true);
    }

    // set the funnel mode
    dispatch(setDataMode(DATA_MODE_FUNNEL));

    setIsWarrningOpen(false);
  };

  return (
    <div className={styles.LayersMenu}>
      <div className={cls(styles.IconWrapper, styles.MainIconWrapper)}>
        <div onClick={onMenuToggle} className={styles.Icon}>
          {layersMenuIcon}
        </div>
      </div>
      <When condition={isMenuOpen}>
        <div className={styles.Separator}></div>
        <div className={styles.IconWrapper}>
          {buttons.map((button) => (
            <Tooltip label={button.title} key={button.type} position="left">
              <UILock key={button.type} isLocked={!hasPermissions(button.type)}>
                <div
                  key={button.type}
                  onClick={() => onButtonClicked(button.type)}
                  className={cls(
                    styles.Icon,
                    isActive(button.type)
                      ? styles.ActiveIcon
                      : !hasPermissions(button.type)
                        ? styles.DisabledIcon
                        : null,
                  )}
                >
                  {button.icon}
                </div>
              </UILock>
            </Tooltip>
          ))}
        </div>
      </When>
      <When condition={isWarrningOpen}>
        <Warning onClose={onWarrningClose}>
          <div
            style={{
              display: 'Flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px',
              gap: '20px',
            }}
          >
            <div>
              You need to be in Funnel Mode to view the data
              <br />
              with both Numbers and Forecast layers
            </div>
            <div
              style={{
                cursor: 'pointer',
                color: 'white',
                fontWeight: 'normal',
                backgroundColor: '#4A90E2',
                padding: '10px',
                borderRadius: '4px',
              }}
              onClick={onTurnOnFunnelMode}
            >
              Turn on Funnel Mode
            </div>
          </div>
        </Warning>
      </When>
    </div>
  );
};
