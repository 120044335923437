import * as PIXI from 'pixi.js';
import { DropShadowFilter } from 'pixi-filters';

let filter;

export default class DropShadowGenerator {
  static getFilter() {
    if (!filter) {
      const options = { distance: 7, alpha: 0.03, blur: 1, quality: 0.5, resolution: 2 };
      filter = new DropShadowFilter(options);
    }
    return filter;
  }
}
