import React from 'react';

export function EnterButton({ onClick }) {
  const Tail = ({ style, onClick }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={style}
      width="13"
      height="7"
      viewBox="0 0 13 7"
      fill="none"
    >
      <path
        d="M9.87737 6.0572H1.3519C1.12976 6.0572 0.950195 5.87764 0.950195 5.65549C0.950195 5.43335 1.12976 5.25378 1.3519 5.25378H9.87778C10.6217 5.25378 11.2267 4.64881 11.2267 3.90484V0.973975C11.2267 0.752231 11.4063 0.572266 11.6284 0.572266C11.8506 0.572266 12.0301 0.752231 12.0301 0.973975V3.90484C12.0301 5.09189 11.0644 6.0572 9.87737 6.0572Z"
        fill="#636E84"
      />
    </svg>
  );
  const Head = ({ style, onClick }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={style}
      width="6"
      height="6"
      viewBox="0 0 6 6"
      fill="none"
    >
      <path
        d="M5.35159 5.0722C5.29013 5.0722 5.22867 5.05814 5.17202 5.03002L1.21238 3.04999C1.0762 2.9817 0.990234 2.84271 0.990234 2.69046C0.990234 2.53822 1.0762 2.39923 1.21238 2.33094L5.17202 0.350911C5.29655 0.289048 5.44438 0.295073 5.56289 0.368586C5.68139 0.441697 5.7537 0.571047 5.7537 0.71044V4.67049C5.7537 4.80988 5.68139 4.93883 5.56289 5.01234C5.49821 5.05211 5.4247 5.0722 5.35159 5.0722ZM2.29016 2.69046L4.94988 4.02052V1.36041L2.29016 2.69046Z"
        fill="#636E84"
      />
    </svg>
  );
  return (
    <div
      onClick={onClick}
      className="flex cursor-pointer h-7 w-16 rounded-sm bg-slate-200 text-gray-700 text-xs"
    >
      <div className="h-full grow-[2] pt-[7px] pl-[9px]">enter</div>
      <div className="flex grow-[1]">
        <div className="relative top-[14px] left-[-3px]">
          <Head />
        </div>
        <div className="relative top-[11px] left-[-4px]">
          <Tail />
        </div>
      </div>
    </div>
  );
}
