import React, { useState } from 'react';
import { selectCurrentStep } from 'react-project/redux/current-step/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { ActionTypes } from 'shared/CSharedConstants';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { RP_EVENT_EDIT_OBJECT } from 'shared/CSharedEvents';
import { ActionAttributes } from '../ActionAttributes';
import styles from '../TrackingTabAction.module.scss';
import { setNewCurrentStepFilterParams } from 'react-project/redux/current-step/actions';
import { When } from 'react-project/Util/When';
import { EditableKeyValueTable } from 'react-project/Toolbar/step-toolbar/EditableKeyValueTable';
import { listViewIcon, tableViewIcon } from 'react-project/assets/Icons';
import cls from 'classnames';
import LocalStorage from 'react-project/Helpers/LocalStorage';
import { EventOperators } from 'react-project/Toolbar/step-toolbar/LogicalOperators';

export const CustomActionAttributes = ({ projectId }) => {
  const currentStep = useSelector(selectCurrentStep);
  const dispatch = useDispatch();
  const [isTableView, setIsTableView] = useState(LocalStorage.get('isTableView', true));

  if (currentStep.object.actionType === ActionTypes.NONE) {
    return null;
  }

  const handleDeleteAttribute = (newAttributes) => {
    updateStepFilterData(newAttributes);
  };

  const updateStepFilterData = (newFilterData) => {
    dispatch(setNewCurrentStepFilterParams(newFilterData));
    commonSendEventFunction(RP_EVENT_EDIT_OBJECT, {
      stepId: currentStep.stepId,
      label: currentStep.object.label,
      filterData: newFilterData,
    });
  };

  const onParametersChanged = (newFilterData) => {
    updateStepFilterData(newFilterData);
  };

  const activateView = (isTableView) => {
    LocalStorage.set('isTableView', isTableView);
    setIsTableView(isTableView);
  };

  return (
    <div className={styles.CustomActionAttributes}>
      <div className={styles.CustomActionAttributesHeader}>
        <span>switch view</span>
        <div
          onClick={() => activateView(true)}
          className={cls(styles.CustomActionAttributesViewIcon, isTableView ? styles.Active : null)}
        >
          {tableViewIcon}
        </div>
        <div
          onClick={() => activateView(false)}
          className={cls(
            styles.CustomActionAttributesViewIcon,
            !isTableView ? styles.Active : null,
          )}
        >
          {listViewIcon}
        </div>
      </div>

      <When condition={isTableView}>
        <EditableKeyValueTable
          pairsData={currentStep.object.filterData || []}
          onKeyValueChanged={onParametersChanged}
          isExternalPointerDown={false}
          tableMinHeight={'319px'}
          hasAutoFocus={false}
          availableOperators={EventOperators}
        />
      </When>

      <When condition={!isTableView}>
        <ActionAttributes
          attributes={currentStep.object.filterData || []}
          setAttributes={handleDeleteAttribute}
        />
      </When>
    </div>
  );
};
