import * as PIXI from 'pixi.js';
// DELEGATES
// - onHandleDown(event,handle)
// - onHandleMove(event,handle)
// - onHandleUp(event,handle)

export default class ResizeHandle extends PIXI.Graphics {
  constructor(size, color, delegate) {
    super();
    this.delegate = delegate;
    this.index = 0;
    this.drawHandle(size, color);
    this.cursor = 'pointer';
    this.interactive = true;
    this.buttonMode = true;

    this.isMouseDown = false;
    this.pointerdown = this.onPointerDown.bind(this);
    this.pointermove = this.onPointerMove.bind(this);
    this.pointerup = this.onPointerUp.bind(this);
    this.pointerupoutside = this.onPointerUp.bind(this);
  }

  drawHandle(size, color) {
    this.beginFill(color);
    this.drawRect(0, 0, size, size);
    this.endFill();
    this.pivot.set(size / 2, size / 2);
  }

  onPointerDown(e) {
    this.isMouseDown = true;
    if (this.delegate && this.delegate.onHandleDown) {
      this.delegate.onHandleDown(e, this);
    }
  }

  onPointerMove(e) {
    if (this.isMouseDown) {
      if (this.delegate && this.delegate.onHandleMove) {
        this.delegate.onHandleMove(e, this);
      }
    }
  }

  onPointerUp(e) {
    this.isMouseDown = false;
    if (this.delegate && this.delegate.onHandleUp) {
      this.delegate.onHandleUp(e, this);
    }
  }
}
