import cls from 'classnames';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { iconArrow, iconTrash } from 'react-project/assets/Icons';
import { Disclosure } from 'react-project/components/disclosure/Disclosure';
import { Dropdown } from 'react-project/components/dropdown/Dropdown';
import { SearchInput } from 'react-project/components/input/SearchInput/SearchInput';
import { Loader } from 'react-project/components/loader/Loader';
import { TEXT_LOAD_MORE } from 'react-project/Constants/texts';
import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';
import { When } from 'react-project/Util/When';
import { isEnterKey } from 'shared/CSharedMethods';
import styles from './TrackingTabAction.module.scss';

export const DropdownTrigger = ({ isOpen, value, placeholder, ...rest }) => {
  return (
    <div title={value || placeholder} className={styles.ConditionDropdownTrigger} {...rest}>
      <span className={styles.ConditionDropdownTriggerValue}>{value || placeholder}</span>
      <div className={cls(styles.TriggerIcon, { [styles.TriggerIconOpened]: isOpen })}>
        {iconArrow}
      </div>
    </div>
  );
};

DropdownTrigger.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
};
const PAGE_SIZE = 19;
export const Attribute = ({
  properties,
  selectedProperty,
  onSelectProperty: onPropertySelect,
  selectedPropertyValue,
  onSelectPropertyValue,
  onDelete,
  isLoading,
  loadMore,
}) => {
  const [dropdownOpened, setIsPropertyDropdownOpened] = useState(false);
  const [isValueDropdownOpened, setIsValueDropdownOpened] = useState(false);
  const [propertyCustomValue, setPropertyCustomValue] = useState('');
  const [propertySearchQuery, setPropertySearchQuery] = useState('');
  const [propertiesDisclosureOpened, setPropertiesDisclosureOpened] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [selectedPropertyValues, setSelectedPropertyValues] = useState([]);
  if (!properties) {
    return null;
  }

  useEffect(() => {
    const values = properties.find((e) => e.key === selectedProperty.key)?.values;
    setSelectedPropertyValues(values);
  }, [selectedProperty, properties]);

  const filteredProperties = properties.filter(
    (p) =>
      !propertySearchQuery ||
      p.displayName.toLowerCase().includes(propertySearchQuery.toLowerCase()),
  );

  const onSelectProperty = (property) => {
    onPropertySelect(property);
    setIsPropertyDropdownOpened(false);
  };

  const onCustomValueKeyDown = (e) => {
    if (!isEnterKey(e.key)) {
      return;
    }

    onSelectPropertyValue(propertyCustomValue);
    setPropertyCustomValue('');
    setIsValueDropdownOpened(false);
  };

  const onSelectValue = (v) => {
    onSelectPropertyValue(v.value);
    setIsValueDropdownOpened(false);
    setPropertyCustomValue('');
    setIsLoadingMore(false);
  };

  const onLoadMore = () => {
    setIsLoadingMore(true);
    loadMore();
  };

  return (
    <div className={styles.AttributeContainer}>
      <div className={styles.Attribute}>
        <span className={styles.labelTitle}>where</span>
        <ClickOutsideCustomComponent onClickOutside={() => setIsPropertyDropdownOpened(false)}>
          <Dropdown
            className={styles.AttributesDropdown}
            triggerSlot={
              <DropdownTrigger
                value={selectedProperty.displayName}
                placeholder="Property"
                isOpen={dropdownOpened}
              />
            }
            isOpen={dropdownOpened}
            onToggle={(o) => setIsPropertyDropdownOpened(o)}
          >
            <div className={styles.PropertyDropdownContent}>
              <div className={styles.SearchProperty}>
                <SearchInput
                  value={propertySearchQuery}
                  onChange={(value) => setPropertySearchQuery(value)}
                  placeholder="Search"
                />
              </div>
              <div className={styles.DropdownList}>
                {isLoading && <div className={styles.PropertiesLoading}>Is loading...</div>}
                <When condition={!isLoading}>
                  <Disclosure
                    isOpened={propertiesDisclosureOpened}
                    disclosureButtonSlot={
                      <div
                        onClick={() => setPropertiesDisclosureOpened(!propertiesDisclosureOpened)}
                        className={styles.PropertiesListDisclosureButton}
                      >
                        <div>
                          <div
                            className={cls(styles.TriggerIcon, {
                              [styles.TriggerIconOpened]: propertiesDisclosureOpened,
                            })}
                          >
                            {iconArrow}
                          </div>
                          <span className={styles.PropertiesListDisclosureButtonText}>
                            Action Properties
                          </span>
                        </div>
                        <span>{pluralize('type', filteredProperties.length, true)}</span>
                      </div>
                    }
                  >
                    {filteredProperties.map((p) => (
                      <div
                        key={p.key}
                        className={styles.DropdownListItem}
                        onClick={() => onSelectProperty(p)}
                      >
                        <span>{p.displayName}</span>
                        <span>{p.hits}</span>
                      </div>
                    ))}
                  </Disclosure>
                </When>
              </div>
            </div>
          </Dropdown>
        </ClickOutsideCustomComponent>
        <span className={styles.AttributesOperator}>is equal to</span>
        <ClickOutsideCustomComponent onClickOutside={() => setIsValueDropdownOpened(false)}>
          <Dropdown
            className={styles.AttributesDropdown}
            triggerSlot={
              <DropdownTrigger
                value={selectedPropertyValue}
                placeholder="Value"
                isOpen={isValueDropdownOpened}
              />
            }
            isOpen={isValueDropdownOpened}
            onToggle={(o) => setIsValueDropdownOpened(o)}
          >
            <div>
              <div className={styles.CustomPropertyValue}>
                <input
                  autoFocus={true}
                  type="text"
                  value={propertyCustomValue}
                  placeholder="Enter custom value"
                  onChange={(e) => setPropertyCustomValue(e.target.value)}
                  onKeyDown={onCustomValueKeyDown}
                />
              </div>
              <When condition={isLoading || !selectedProperty.key}>
                {!selectedProperty.key ? (
                  <div className={styles.PropertiesLoading}>Must select key</div>
                ) : (
                  <div className={styles.PropertiesLoading}>Is loading...</div>
                )}
              </When>
              {properties.find((e) => e.key === selectedProperty.key) && (
                <When condition={(isLoadingMore || !isLoading) && selectedProperty.key}>
                  <div className={styles.DropdownList}>
                    {properties
                      .find((e) => e.key === selectedProperty.key)
                      .values.map((v) => (
                        <div
                          key={v.value}
                          className={styles.DropdownListItem}
                          onClick={() => onSelectValue(v)}
                        >
                          <span>{v.value}</span>
                          <span>{v.hits}</span>
                        </div>
                      ))}
                  </div>
                  <When condition={selectedPropertyValues?.length > PAGE_SIZE}>
                    <div className={styles.loadMoreButton} onClick={onLoadMore}>
                      {isLoading ? <Loader variant="grey-font-1" /> : TEXT_LOAD_MORE}
                    </div>
                  </When>
                </When>
              )}
            </div>
          </Dropdown>
        </ClickOutsideCustomComponent>
        <button className={styles.RemoveConditionButton} onClick={onDelete}>
          {iconTrash}
        </button>
      </div>
      <div className={styles.Attribute}>
        <span className={styles.andSeperationText}>and</span>
        <div className={styles.andSeperation}></div>
      </div>
    </div>
  );
};
