// RP_ - React to Pixi , PR_ - Pixi to React , RR_ - React to React
export const RP_EVENT_DELETE_PRESSED = 'deletePressed';
export const RP_EVENT_COPY_PRESSED = 'copyPressed';
export const RP_EVENT_DUPLICATE_PRESSED = 'duplicatePressed';
export const RP_EVENT_PASTE_PRESSED = 'pastePressed';
export const RP_EVENT_CURSOR_TYPE = 'cursorType';
export const RP_EVENT_SAVE_REQUEST = 'saveRequest';
export const RP_EVENT_REFRESH_REQUEST = 'refreshRequest';
export const RP_EVENT_GET_DATA = 'getIds';
export const RP_EVENT_LOAD_REQUEST = 'loadRequest';
export const RP_EVENT_CREATE_OBJECT = 'createObject';
export const RP_EVENT_EDIT_OBJECT = 'editObject';
export const RP_EVENT_ANALYTICS_UPDATED = 'updateAnalytics';
export const RP_EVENT_UPDATE_STEP_THUMBNAIL = 'updateStepThumbnail';
export const RP_EVENT_DRAW_LINE_TYPE_CHANGED = 'DRAW_LINE_TYPE_CHANGED';
export const RP_EVENT_CONNECTION_LINE_TYPE_CHANGED = 'CONNECTION_LINE_TYPE_CHANGED';
export const RP_EVENT_CANCEL_NEW_STEP_FROM_CONNECTION = 'cancelNewStepFromConnection';
export const RP_EVENT_HORIZONTAL_ALIGNMENT = 'horizontalElementsAlignment';
export const RP_EVENT_VERTICAL_ALIGNMENT = 'verticalElementsAlignment';
export const RP_EVENT_VERTICAL_SPACING = 'RP_EVENT_VERTICAL_SPACING';
export const RP_EVENT_HORIZONTAL_SPACING = 'RP_EVENT_HORIZONTAL_SPACING';
export const RP_EVENT_ERROR_MESSAGE = 'ErrorMessage';
export const RP_EVENT_FIT_TO_SCREEN = 'fitToScreen';
export const RP_EVENT_ZOOM_IN = 'zoomIn';
export const RP_EVENT_ZOOM_OUT = 'zoomOut';
export const RP_EVENT_ZOOM_RESET = 'zoomReset';
export const RP_EVENT_ZOOM_VALUE_CHANGED = 'zoomValueChanged';
export const RP_EVENT_OBJECT_DRAG_START = 'toolbarObjectDragStarted';
export const RP_EVENT_SAVE_TO_PNG = 'toolbarObjectSaveToPNG';
export const RP_EVENT_ANALYTICS_STATUS_CHANGED = 'analyticsStatusChanged';
export const RP_ANALYTICS_FILTER_DATA_CHANGED = 'analyticsFilterDataChanged';
export const RP_ANALYTICS_PWP_TOOL_ACTIVATED = 'analyticsPeopleWhoPerformedToolActivated';
export const RP_STEP_PICKER_ACTIVATED = 'stepPickerActivated';
export const RP_EVENT_EMPTY_CANVAS_POINTER_UP = 'EMPTY_CANVAS_POINTER_UP';
export const RP_EVENT_STEP_FOCUS_CLEARED = 'STEP_FOCUS_CLEARED';
export const RP_EVENT_FOCUS_FRAME_CLEARED = 'FOCUS_FRAME_CLEARED';
export const RP_EVENT_ESC_PRESSED = 'ESC_PRESSED';
export const RP_EVENT_EMPTY_CANVAS_DOUBLE_CLICK = 'EMPTY_CANVAS_DOUBLE_CLICK';
export const RP_EVENT_ELEMENT_RIGHT_CLICK = 'RP_EVENT_ELEMENT_RIGHT_CLICK';
export const RP_EVENT_UNDO_CLICKED = 'UNDO_CLICKED';
export const RP_EVENT_REDO_CLICKED = 'REDO_CLICKED';
export const RP_EVENT_ICON_CHOSEN = 'ICON_CHOSEN';
export const RP_EVENT_PROPERTY_CHANGED = 'PROPERTY_CHANGED';
export const RP_EVENT_RESTORE_BUTTON_CLICKED = 'RESTORE_BUTTON_CLICKED';
export const RP_EVENT_REPORT_ADDED = 'REPORT_ADDED';
export const RP_EVENT_REPORT_REMOVED = 'REPORT_REMOVED';
export const RP_EVENT_SEND_REPORT_CLICKED = 'RP_EVENT_SEND_REPORT_CLICKED';
export const RP_EVENT_SEND_REPORT_TITLE_EDITED = 'RP_EVENT_SEND_REPORT_TITLE_EDITED';
export const RP_EVENT_WIDGET_DROPPED = 'RP_EVENT_WIDGET_DROPPED';
export const RP_EVENT_TOP_COUNTRIES_LOADED = 'RP_EVENT_TOP_COUNTRIES_LOADED';
export const RP_EVENT_TEXT_PROPERTY_CHANGED = 'RP_EVENT_TEXT_PROPERTY_CHANGED';
export const RP_EVENT_PEOPLE_LOADED = 'RP_EVENT_PEOPLE_LOADED';
export const RP_EVENT_PEOPLE_LOADED_MORE = 'PEOPLE_LOADED_MORE';
export const RP_EVENT_WIDGET_DATA_UPDATED = 'RP_EVENT_WIDGET_DATA_UPDATED';
export const RP_EVENT_TEXT_HYPERLINK_STARTED_EDITING = 'RP_EVENT_TEXT_HYPERLINK_STARTED_EDITING';
export const RP_EVENT_SESSION_DESELECTED = 'RP_EVENT_SESSION_DESELECTED';
export const RP_EVENT_PDF_CREATED = 'RP_EVENT_PDF_CREATED';
export const RP_EVENT_UPSELL_POPUP_CLOSED = 'RP_EVENT_UPSELL_POPUP_CLOSED';
export const RP_EVENT_ELEMENT_SPREAD = 'RP_EVENT_ELEMENT_SPREAD';
export const RP_EVENT_CUSTOM_ICONS_LOADED = 'RP_EVENT_CUSTOM_ICONS_LOADED';
export const RP_EVENT_PHOTO_UPLOADED = 'RP_EVENT_PHOTO_UPLOADED';
export const RP_EVENT_PHOTO_UPLOAD_STARTED = 'RP_EVENT_PHOTO_UPLOAD_STARTED';
export const RP_EVENT_ON_GOAL_CHANGED = 'RP_EVENT_ON_GOAL_CHANGED';
export const RP_EVENT_ON_FORECASTING_CHANGED = 'RP_EVENT_ON_FORECASTING_CHANGED';
export const RP_EVENT_ON_NOTE_EDITED = 'RP_EVENT_ON_NOTE_EDITED';
export const RP_SCROLL_ELEMENT_INTO_VIEW = 'RP_SCROLL_ELEMENT_INTO_VIEW';
export const RP_EVENT_NOTE_EDITING_FINISHED = 'RP_EVENT_NOTE_EDITING_FINISHED';
export const RP_EVENT_DESELECT_ALL = 'RP_EVENT_DESELECT_ALL';
export const RP_EVENT_TAB_CLICKED = 'RP_EVENT_TAB_CLICKED'; // Filters , Notes ...
export const RP_EVENT_TODO_LIST_CHANGED = 'RP_EVENT_TODO_LIST_CHANGED';
export const RP_EVENT_DONE_SAVING = 'RP_EVENT_DONE_SAVING';
export const RP_EVENT_CHECKLIST_CHANGED = 'RP_EVENT_CHECKLIST_CHANGED';
export const RP_EVENT_EXPORT_CSV_OBJECTS = 'RP_EVENT_EXPORT_CSV_OBJECTS';
export const RP_EVENT_EXPORT_CSV_CONNECTIONS = 'RP_EVENT_EXPORT_CSV_CONNECTIONS';
export const RP_EVENT_LAYER_TOGGLED = 'RP_EVENT_LAYER_TOGGLED';
export const RP_EVENT_TRENDS_LOADED = 'RP_EVENT_TRENDS_LOADED';
export const RP_EVENT_TRENDS_STEP_PICKED = 'RP_EVENT_TRENDS_STEP_PICKED';
export const RP_EVENT_TRENDS_PICKER_CLOSED = 'RP_EVENT_TRENDS_PICKER_CLOSED';
export const RP_EVENT_INFO_MESSAGE = 'RP_EVENT_INFO_MESSAGE';
export const RP_EVENT_CALENDAR_DATE_CHANGED = 'RP_EVENT_CALENDAR_DATE_CHANGED';
export const RP_EVENT_BENCHMARK_FROM_ANALYTICS = 'RP_EVENT_BENCHMARK_FROM_ANALYTICS';

export const PR_EVENT_EDIT_MODE = 'editMode';
export const PR_EVENT_TEXT_EDITING_FINISH = 'textEditingFinish';
export const PR_EVENT_OBJECT_SELECTED = 'PR_EVENT_OBJECT_SELECTED';
export const PR_EVENT_CONNECTION_IN_EMPTY_SPACE = 'connectionInEmptySpace';
export const PR_EVENT_REMOVE_POINTER_JOINT = 'PR_EVENT_REMOVE_POINTER_JOINT';
export const PR_EVENT_SAVE_RESPONSE = 'saveResponse';
export const PR_EVENT_REFRESH_RESPONSE = 'refreshResponse';
export const PR_EVENT_FUNNEL_CHANGED = 'funnelChanged';
export const PR_EVENT_ANALYTICS_NEEDS_REFRESH = 'PR_EVENT_ANALYTICS_NEEDS_REFRESH';
export const PR_EVENT_TOOLBOX_STATE_CHANGED = 'toolboxStateChagned';
export const PR_EVENT_QUICK_PANNING_MODE_CHANGED = 'quickPanningModeChanged';
export const PR_EVENT_SHAPE_DRAWING_ENDED = 'SHAPE_DRAWING_ENDED';
export const PR_EVENT_SHAPE_DRAWING_ENDED_FAIL = 'SHAPE_DRAWING_ENDED_FAIL';
export const PR_EVENT_SHAPE_DRAWING_STARTED = 'SHAPE_DRAWING_STARTED';
export const PR_EVENT_SHAPE_STYLE_CHANGED = 'SHAPE_STYLE_CHANGED';
export const PR_EVENT_ZOOM_LEVEL_CHANGED = 'zoomChanged';
export const PR_EVENT_BRING_FORWARD = 'bringForward';
export const PR_EVENT_BRING_TO_FRONT = 'bringToFront';
export const PR_EVENT_SEND_BACKWARD = 'sendBackward';
export const PR_EVENT_SEND_TO_BACK = 'sendToBack';
export const PR_EVENT_UNDO_REDO_ACTIONS_UPDATED = 'undoRedoActionsUpdated';
export const PR_EVENT_ELEMENT_POINTER_DOWN = 'elementPointerDown';
export const PR_EVENT_ELEMENT_POINTER_UP = 'elementPointerUp';
export const PR_EVENT_ELEMENT_SELECTED = 'ELEMENT_SELECTED';
export const PR_EVENT_SELECTION_ADDED = 'PR_EVENT_SELECTION_ADDED';
export const PR_EVENT_SELECTION_REMOVED = 'PR_EVENT_SELECTION_REMOVED';
export const PR_EVENT_SEND_REPORT_CLICKED = 'PR_EVENT_SEND_REPORT_CLICKED';
export const PR_EVENT_DOWNLOAD_REPORT_CLICKED = 'PR_EVENT_DOWNLOAD_REPORT_CLICKED';
export const PR_EVENT_REPORT_IMAGES_GENERATED = 'PR_EVENT_REPORT_IMAGES_GENERATED';
export const PR_EVENT_SHORTCUT_PRESSED = 'PR_EVENT_SHORTCUT_PRESSED';
export const PR_EVENT_REPORT_STYLE = 'PR_EVENT_REPORT_STYLE';
export const PR_EVENT_REPORT_STYLE_UPDATED = 'PR_EVENT_REPORT_STYLE_UPDATED';
export const PR_EVENT_COMMAND_ADD_EXECUTED = 'COMMAND_ADD_EXECUTED';
export const PR_EVENT_COMMAND_MOVE_EXECUTED = 'COMMAND_MOVE_EXECUTED';
export const PR_EVENT_COMMAND_SCALE_EXECUTED = 'COMMAND_SCALE_EXECUTED';
export const PR_EVENT_COMMAND_RESHAPE_EXECUTED = 'COMMAND_RESHAPE_EXECUTED';
export const PR_EVENT_COMMAND_REMOVE_EXECUTED = 'COMMAND_REMOVE_EXECUTED';
export const PR_EVENT_COMMAND_SCALE_MOVE_EXECUTED = 'COMMAND_SCALE_MOVE_EXECUTED';
export const PR_EVENT_REPORT_WARNING_POPUP_OPENED = 'REPORT_WARNING_POPUP_OPENED';
export const PR_EVENT_REPORT_BLOCKED_WARNING_POPUP_OPENED = 'REPORT_BLOCKED_WARNING_POPUP_OPENED';
export const PR_EVENT_REPORT_WARNING_POPUP_OK_BTN_CLICKED = 'REPORT_WARNING_POPUP_OK_BTN_CLICKED';
export const PR_EVENT_REPORT_WARNING_POP_OVERLAPPED = 'PR_EVENT_REPORT_WARNING_POP_OVERLAPPED';
export const PR_MOVING_CURSOR_WITH_ELEMENT = 'PR_MOVING_CURSOR_WITH_ELEMENT';
export const PR_EVENT_PEOPLE_WIDGET_LOAD_MORE_TRIGGERED = 'PEOPLE_WIDGET_LOAD_MORE_TRIGGERED';
export const PR_EVENT_WIDGET_PASTED = 'PR_EVENT_WIDGET_PASTED';
export const PR_EVENT_SESSION_SELECTED = 'PR_EVENT_SESSION_SELECTED';
export const PR_EVENT_COMPARE_RANGE_CHANGED = 'PR_EVENT_COMPARE_RANGE_CHANGED';
export const PR_STEP_TEXTURE_UPDATED = 'PR_STEP_TEXTURE_UPDATED';
export const PR_REPORT_STYLE_CHANGED = 'PR_REPORT_STYLE_CHANGED';
export const PR_REPORT_STYLE_APPLICATION_CHANGED = 'PR_REPORT_STYLE_APPLICATION_CHANGED';
export const PR_REPORT_STYLE_GLOBAL_APPLIED = 'PR_REPORT_STYLE_GLOBAL_APPLIED';
export const PR_EVENT_ON_PHOTO_CHANGED = 'PR_EVENT_ON_PHOTO_CHANGED';
export const PR_EVENT_STEP_FOCUS_CHANGED = 'PR_EVENT_STEP_FOCUS_CHANGED';
export const PR_NOTES_LOADED = 'PR_NOTES_LOADED';
export const PR_NOTES_ADDED = 'PR_NOTES_ADDED';
export const PR_NOTES_REMOVED = 'PR_NOTES_REMOVED';
export const PR_NOTES_TITLE_UPDATED = 'PR_NOTES_TITLE_UPDATED';
export const PR_EVENT_CHECKLIST_MODIFIED = 'PR_EVENT_CHECKLIST_MODIFIED';
export const PR_COMMAND_COLLAPSE_LEFT_SIDE_MENU = 'PR_COMMAND_COLLAPSE_LEFT_SIDE_MENU';
export const PR_EVENT_MIDDLE_MOUSE_DOWN = 'PR_EVENT_MIDDLE_MOUSE_DOWN';
export const PR_EVENT_CANVAS_CHECKLIST_CHANGED = 'PR_EVENT_CANVAS_CHECKLIST_CHANGED';
export const PR_ALL_STEPS = 'PR_ALL_STEPS';
export const PR_EVENT_TRENDS_NEED_UPDATE = 'PR_EVENT_TRENDS_NEED_UPDATE';
export const PR_EVENT_FUNNEL_LOADED = 'PR_EVENT_FUNNEL_LOADED';
export const PR_EVENT_THUMBNAIL_REQUESTED = 'PR_EVENT_THUMBNAIL_REQUESTED';
export const PR_EVENT_LOOP_DETECTION = 'PR_EVENT_LOOP_DETECTION';
export const PR_EVENT_CONNECTION_RELOCATED = 'PR_EVENT_CONNECTION_RELOCATED';
export const PR_EVENT_EXPENSE_OPENED = 'PR_EVENT_EXPENSE_OPENED';
export const PR_EVENT_EXPENSE_ADDED = 'PR_EVENT_EXPENSE_ADDED';
export const PR_EVENT_EXPENSE_REMOVED = 'PR_EVENT_EXPENSE_REMOVED';

export const RR_SHOW_UPSELL_POPUP = 'RR_SHOW_UPSELL_POPUP';
export const RR_EVENT_ON_WIDGET_PRESSED = 'RR_EVENT_ON_WIDGET_PRESSED';
export const RR_EVENT_ON_WIDGET_CLOSE = 'RR_EVENT_ON_WIDGET_CLOSE';
export const RR_EVENT_ON_WIDGET_SELECTED = 'RR_EVENT_ON_WIDGET_SELECTED';
export const RR_NOTES_OPEN = 'RR_NOTES_OPEN';
export const RR_CHECKLIST_OPEN = 'RR_CHECKLIST_OPEN';
export const RR_SET_LEFT_SIDE_TAB = 'RR_SET_LEFT_SIDE_TAB';
export const RR_SET_ACTIVE_NOTE = 'RR_SET_ACTIVE_NOTE';
export const RR_SET_ACTIVE_CHECKLIST = 'RR_SET_ACTIVE_CHECKLIST';
export const RR_EVENT_EDITOR_NEEDS_FOCUS = 'RR_EVENT_EDITOR_NEEDS_FOCUS';
export const RR_EVENT_ON_SHOW_LEFT_CREATE_STEP_MENU = 'RR_EVENT_ON_SHOW_LEFT_CREATE_STEP_MENU';
export const RR_SHOW_TUTORIAL_POPUP = 'RR_SHOW_TUTORIAL_POPUP';
export const RR_EVENT_ON_REFRESH_ANALYTICS = 'RR_EVENT_ON_REFRESH_ANALYTICS';
