import { produce } from 'immer';
import { handleActions } from 'redux-actions';
import {
  setActiveDataLayer,
  setCompareFilterStatus,
  setDataMode,
  setFunnelCompareRangeFrom,
  setFunnelCompareRangeTo,
  setNewFunnelConfiguration,
  setPanToolActive,
  setPreviousFunnelData,
  updateCompareDataRange,
  updateCompareFilterData,
  updateDataRange,
  updateFilterData,
  updateFocusedSteps,
  updateFocusedStepsCompare,
} from './actions';
import { cloneData } from 'shared/CSharedMethods';
import { COMPARE_FILTER_LABELS, FUNNEL_CONFIGURATION } from 'shared/CSharedConstants';

const initialState = cloneData(FUNNEL_CONFIGURATION);

export default handleActions(
  {
    [setPreviousFunnelData.toString()]: (state, action) =>
      produce(state, (draft) => {
        const previousFunnelData = JSON.parse(JSON.stringify(action.payload.data));
        // Remove any nested previousFunnelData to avoid recursive saving
        if (
          previousFunnelData.funnelConfiguration &&
          previousFunnelData.funnelConfiguration.previousFunnelData
        ) {
          delete previousFunnelData.funnelConfiguration.previousFunnelData;
        }
        draft.previousFunnelData = previousFunnelData;
      }),
    [updateCompareDataRange.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.compareDateRange = {
          min: action.payload.min,
          max: action.payload.max,
          selectedLabel: action.payload.selectedLabel,
        };
      }),
    [updateDataRange.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.dateRange = {
          min: action.payload.min,
          max: action.payload.max,
          selectedLabel: action.payload.selectedLabel,
        };
      }),
    [updateFocusedSteps.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.focusedSteps = action.payload;
      }),
    [updateFocusedStepsCompare.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.focusedStepsCompare = action.payload;
      }),
    [updateFilterData.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.filter = {
          device: action.payload.device,
          countries: action.payload.countries,
          contributionWindow: action.payload.contributionWindow,
          focusLogicalOperator: action.payload.focusLogicalOperator,
        };

        if (action.payload.session) {
          draft.filter.session = action.payload.session;
        }
      }),
    [updateCompareFilterData.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.compareFilter.device = action.payload.device;
        draft.compareFilter.countries = action.payload.countries;

        if (action.payload.session) {
          draft.compareFilter.session = action.payload.session;
        }

        if (action.payload.range) {
          draft.compareFilter.range = action.payload.range;
        }

        if (action.payload.inputStart) {
          draft.compareFilter.inputStart = action.payload.inputStart;
        }

        if (action.payload.inputFinish) {
          draft.compareFilter.inputFinish = action.payload.inputFinish;
        }
      }),
    [setCompareFilterStatus.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.selectedCompareFilter = action.payload.status;
      }),
    [setPanToolActive.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.isPanToolActive = action.payload;
      }),
    [setNewFunnelConfiguration.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.dateRange = action.payload.dateRange;
        draft.filter = action.payload.filter;
        draft.compareDateRange = action.payload.compareDateRange;

        draft.compareFilter.device = action.payload.compareFilter.device;
        draft.compareFilter.countries = action.payload.compareFilter.countries;
        if (action.payload.compareFilter.range) {
          draft.compareFilter.range = action.payload.compareFilter.range;
        }

        draft.selectedCompareFilter = action.payload.selectedCompareFilter;

        draft.visibleDataLayers = action.payload.visibleDataLayers;
        draft.dataMode = action.payload.dataMode;

        if (action.payload.focusedSteps) {
          draft.focusedSteps = action.payload.focusedSteps;
        }

        if (action.payload.focusedStepsCompare) {
          draft.focusedStepsCompare = action.payload.focusedStepsCompare;
        }

        if (action.payload.selectedCompareFilter?.label === COMPARE_FILTER_LABELS.PEOPLE) {
          draft.compareDateRange = action.payload.dateRange;
        }

        if (action.payload.selectedCompareFilter?.label === COMPARE_FILTER_LABELS.DATE) {
          draft.compareFilter.device = action.payload.filter.device;
          draft.compareFilter.countries = action.payload.filter.countries;
        }

        if (action.payload.filter.session && action.payload.filter.session.intId) {
          draft.filter.session = action.payload.filter.session.intId;
        }

        if (action.payload.compareFilter.session && action.payload.compareFilter.session.intId) {
          draft.compareFilter.session = action.payload.compareFilter.session.intId;
        }
      }),
    [setActiveDataLayer.toString()]: (state, action) => {
      return produce(state, (draft) => {
        draft.visibleDataLayers[action.payload.type] = action.payload.isActive;
      });
    },
    [setDataMode.toString()]: (state, action) => {
      return produce(state, (draft) => {
        draft.dataMode = action.payload;
      });
    },
    [setFunnelCompareRangeFrom.toString()]: (state, action) => {
      return produce(state, (draft) => {
        draft.compareFilter.range.from = action.payload;
      });
    },
    [setFunnelCompareRangeTo.toString()]: (state, action) => {
      return produce(state, (draft) => {
        draft.compareFilter.range.to = action.payload;
      });
    },
  },
  initialState,
);
