import { SearchIcon, XCircleIcon } from '@heroicons/react/solid';
import React from 'react';
import { EnterButton } from '../enterButton/enterButton';

export function AdFilterBar({
  content,
  handleKeyDown,
  cancelClick,
  searchRef,
  submitClick,
  disabled,
  onChange,
}) {
  return (
    <div className="rndCancel">
      <label htmlFor="searchInput" className="block text-sm font-medium text-gray-700">
        <span className="sr-only">{content.searchField.label}</span>
        <div className="mt-1 flex rounded-md shadow-sm">
          <div className="relative flex flex-grow items-stretch focus-within:z-10">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              ref={searchRef}
              type="text"
              name="searchInput"
              id="searchInput"
              className="block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder={content.searchField.placeholder}
              onKeyDown={handleKeyDown}
              disabled={disabled}
              onChange={onChange}
            />
            <div className="pointer-events-auto absolute inset-y-0 right-0 flex items-center pr-3">
              <XCircleIcon className="h-5 w-5 text-gray-400" onClick={cancelClick} />
              <div className="pl-4">
                <EnterButton onClick={submitClick} />
              </div>
            </div>
          </div>
          {/**Disabled for FA-2336 */}
          {/* <button
            type="button"
            className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
          >
            <span>Active Ads</span>
            <ChevronDownIcon className="h-2 w-2 text-gray-400" aria-hidden="true" />
          </button> */}
        </div>
      </label>
    </div>
  );
}

export default AdFilterBar;
