import {
  PR_EVENT_REPORT_STYLE_UPDATED,
  PR_REPORT_STYLE_GLOBAL_APPLIED,
} from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import CommandsBatch from '../CommandsBatch';

export default class CommandBatchStyleReport extends CommandsBatch {
  constructor(slide, isGlobalApply, isGlobal) {
    super();
    this.slide = slide;
    this.isGlobalApply = isGlobalApply;
    this.isGlobal = isGlobal;
  }

  execute() {
    super.execute();
    commonSendEventFunction(PR_EVENT_REPORT_STYLE_UPDATED, { slide: this.slide });
    if (this.isGlobalApply) {
      commonSendEventFunction(PR_REPORT_STYLE_GLOBAL_APPLIED, { isGlobal: this.isGlobal });
    }
  }

  revert() {
    super.revert();
    commonSendEventFunction(PR_EVENT_REPORT_STYLE_UPDATED, { slide: this.slide });
    if (this.isGlobalApply) {
      commonSendEventFunction(PR_REPORT_STYLE_GLOBAL_APPLIED, { isGlobal: !this.isGlobal });
    }
  }
}
