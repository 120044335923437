import React from 'react';
import { TooltipPopup, useTooltip } from '@reach/tooltip';
import styles from './Tooltip.module.scss';
import { tooltipTriangle } from '../../assets/Icons';
import { Portal } from '@reach/portal';

const BOTTOM_TOOLTIP_TOP_MARGIN = 8;
const MAX_LEFT_TOOLTIP_MARGIN = 8;
const TOP_TOOLTIP_TOP_MARGIN = 50;
const TOP_TRIGGER_TOP_MARGIN = 58;
const BOTTOM_TRIGGER_TOP_MARGIN = 1;
const TRIGGER_LEFT_MARGIN = 50;

const bottomCentered = (triggerRect, tooltipRect) => {
  const triggerCenter = triggerRect.left + triggerRect.width / 2;
  const left = triggerCenter - tooltipRect.width / 2;
  const maxLeft = window.innerWidth - tooltipRect.width - MAX_LEFT_TOOLTIP_MARGIN;
  return {
    left: Math.min(Math.max(MAX_LEFT_TOOLTIP_MARGIN, left), maxLeft) + window.scrollX,
    top: triggerRect.bottom + BOTTOM_TOOLTIP_TOP_MARGIN + window.scrollY,
  };
};

const topCentered = (triggerRect, tooltipRect) => {
  const triggerCenter = triggerRect.left + triggerRect.width / 2;
  const left = triggerCenter - tooltipRect.width / 2;
  const maxLeft = window.innerWidth - tooltipRect.width - MAX_LEFT_TOOLTIP_MARGIN;
  return {
    left: Math.min(Math.max(MAX_LEFT_TOOLTIP_MARGIN, left), maxLeft) + window.scrollX,
    top: triggerRect.top - TOP_TOOLTIP_TOP_MARGIN + window.scrollY,
  };
};

const leftCentered = (triggerRect, tooltipRect) => {
  const triggerVerticalCenter = triggerRect.top + triggerRect.height / 2;
  const left = triggerRect.left - tooltipRect.width;
  return {
    left: left + window.scrollX - 10,
    top: triggerVerticalCenter - tooltipRect.height / 2 + window.scrollY,
  };
};

const rightCentered = (triggerRect, tooltipRect) => {
  const tooltipTopPadding = 5; // 5px
  const triggerVerticalCenter = triggerRect.top + triggerRect.height / 2;
  const right = triggerRect.right - tooltipRect.width;
  return {
    left: tooltipRect.width + right + window.scrollX + 10,
    top: triggerVerticalCenter + window.scrollY - tooltipRect.height / 2 - tooltipTopPadding,
  };
};

const positions = {
  default: bottomCentered,
  top: topCentered,
  bottom: bottomCentered,
  left: leftCentered,
  right: rightCentered,
};

const getTrianglePosition = (triggerRect, position) => {
  switch (position) {
    case 'top':
      return {
        left: triggerRect && triggerRect.left - TRIGGER_LEFT_MARGIN + triggerRect.width / 2,
        top: triggerRect && triggerRect.top + window.scrollY - TOP_TRIGGER_TOP_MARGIN,
        transform: 'rotate(180deg)',
      };
    case 'bottom':
      return {
        left: triggerRect && triggerRect.left - TRIGGER_LEFT_MARGIN + triggerRect.width / 2,
        top: triggerRect && triggerRect.bottom + window.scrollY - BOTTOM_TRIGGER_TOP_MARGIN,
      };
    case 'left':
      return {
        left: triggerRect && triggerRect.left + window.scrollX - 73,
        top: triggerRect && triggerRect.top - triggerRect.height / 2 + 16,
        transform: 'rotate(90deg)',
      };
    case 'right':
      return {
        left: triggerRect && triggerRect.right - TRIGGER_LEFT_MARGIN / 2 - 3,
        top: triggerRect && triggerRect.top - triggerRect.height / 2 + 19,
        transform: 'rotate(270deg)',
      };
    default:
      return {
        left: triggerRect && triggerRect.left - TRIGGER_LEFT_MARGIN + triggerRect.width / 2,
        top: triggerRect && triggerRect.bottom + window.scrollY - BOTTOM_TRIGGER_TOP_MARGIN,
      };
  }
};

export const Tooltip = ({ children, label, 'aria-label': ariaLabel, position }) => {
  const [trigger, tooltip] = useTooltip();
  const { isVisible, triggerRect } = tooltip;
  const trianglePosition = getTrianglePosition(triggerRect, position);

  return (
    <>
      {React.cloneElement(children, trigger)}
      {isVisible && label && (
        <Portal>
          <div style={trianglePosition} className={styles.Triangle}>
            {tooltipTriangle}
          </div>
        </Portal>
      )}
      {label && (
        <TooltipPopup
          {...tooltip}
          label={label}
          aria-label={ariaLabel}
          style={{
            background: '#636e84',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            height: '40px',
            padding: '5px 15px',
            zIndex: 100,
            marginTop: '5px',
            fontSize: '12px',
            fontWeight: 500,
            display: 'flex',
            alignItems: 'center',
          }}
          position={position ? positions[position] : positions.default}
        />
      )}
    </>
  );
};
