import Utils from 'pixi-project/utils/Utils';
import * as PIXI from 'pixi.js';

const RADIUS = 6;

// DELEGATE
// - onBreakPointMoving(event , breakPoint)
// - onBreakPointFinishedMoving(event , breakPoint)
// - onBreakPointDoubleClick(event , breakPoint)

export default class ConnectionBreakPoint extends PIXI.Container {
  constructor(delegate) {
    super();

    this.delegate = delegate;
    this.lastPosition = new PIXI.Point();
    this.startDragPosition = new PIXI.Point();
    this.startPosition = new PIXI.Point();

    this.isPointerDown = false;
    this.didMove = false;
    this.doubleClickTime = 0;
    this.doubleClickPoint = new PIXI.Point();

    this.interactive = true;
    this.buttonMode = true;
    this.cursor = 'move';
    this.pointerdown = this.onPointerDown.bind(this);
    this.pointermove = this.onPointerMove.bind(this);
    this.pointerup = this.onPointerUp.bind(this);
    this.pointerupoutside = this.onPointerUpOutside.bind(this);
    this.interactionBounds = new PIXI.Circle(0, 0, RADIUS * 2);

    this.content = PIXI.Sprite.from(PIXI.utils.TextureCache['break-point']);
    this.content.anchor.set(0.5, 0.5);
    this.addChild(this.content);

    this.connectionDisplay = null;
  }

  savePosition() {
    this.lastPosition.copyFrom(this.position);
    this.startPosition.copyFrom(this.position);
  }

  onPointerDown(e) {
    e.stopPropagation();
    this.isPointerDown = true;
    this.didMove = false;
    let localP = this.parent.toLocal(e.data.global);
    this.startDragPosition.copyFrom(localP);
    this.startPosition.copyFrom(this.position);
    window.app.needsRendering();
  }

  onPointerMove(e) {
    if (this.isPointerDown) {
      let localP = this.parent.toLocal(e.data.global);
      let dx = localP.x - this.startDragPosition.x;
      let dy = localP.y - this.startDragPosition.y;
      this.x = this.startPosition.x + dx;
      this.y = this.startPosition.y + dy;
      this.didMove = true;

      if (this.delegate && this.delegate.onBreakPointMoving) {
        this.delegate.onBreakPointMoving(e, this);
      }
    }
  }

  onPointerUp(e) {
    this.isPointerDown = false;
    // measure time
    let dt = app.ticker.lastTime - this.doubleClickTime;
    this.doubleClickTime = app.ticker.lastTime;

    // measure location
    let distance = Utils.distanceAB(this.doubleClickPoint, e.data.global);
    this.doubleClickPoint.copyFrom(e.data.global);

    if (dt < 300 && distance < 10) {
      this.onDoubleClick(e);
      this.doubleClickTime = 0; // reset the time to prevent consecutive dobule clicking
    }

    if (this.didMove) {
      if (this.delegate && this.delegate.onBreakPointFinishedMoving) {
        this.delegate.onBreakPointFinishedMoving(e, this);
      }
    }
  }

  onPointerUpOutside(e) {
    this.isPointerDown = false;
  }

  onDoubleClick(e) {
    e.stopPropagation();
    if (this.delegate && this.delegate.onBreakPointDoubleClick) {
      this.delegate.onBreakPointDoubleClick(e, this);
    }
  }

  // PIXI overwrite
  containsPoint(point) {
    let local = this.toLocal(point);
    return this.interactionBounds.contains(local.x, local.y);
  }
}
