import { TrackerEventAttributeType, TrackerEventType, TrackerStepType } from './types';

export default (serializer) => {
  serializer.register(TrackerEventType, {
    relationships: {
      step: {
        type: TrackerStepType,
      },
      attrs: {
        type: TrackerEventAttributeType,
      },
    },
  });

  serializer.register(TrackerEventAttributeType);
};
