import React, { useEffect, useState } from 'react';
import { PopUpWrapper } from 'react-project/components/popUp/PopUpWrapper';
import { EditableKeyValueTable } from './EditableKeyValueTable';
import { iconFilter } from 'react-project/assets/Icons';
import styles from 'react-project/Toolbar/step-toolbar/AttributeExplorer/AttributeExplorerList.module.scss';
import { When } from 'react-project/Util/When';
import { SourceOperators } from './LogicalOperators';

export const SourceTrackingParameters = ({
  filterData,
  onTrackingParametersChanged,
  onClose,
  position,
  trackingURL,
  children,
}) => {
  const [pointerDownValue, setPointerDownValue] = useState(-1);
  const [lastURL, setLastURL] = useState(trackingURL);

  useEffect(() => {
    // Monitor for changes to the tracking URL , and if it was emtpy before,
    // it means that the user has deleted the input text and now is typing new one
    // please look at FA-2156
    // We need to disable the auto focus on the right side input list fields kicking in
    // when the user is typing in the left side input list fields
    setLastURL(trackingURL);
  }, [trackingURL]);

  const onPointerDown = (e) => {
    // Random value is generated on each click to force the component to trigger its useEffect that monitors this value
    // This will effectevly notify the component for each click (pointer down) registerd
    setPointerDownValue(Math.random());
  };

  return (
    <PopUpWrapper
      title="URL parameters"
      icon={iconFilter}
      onClose={onClose}
      position={position}
      popupWidth={800}
      popupHeight={300}
      minWidth={800}
      onPointerDown={onPointerDown}
      className="stop-event"
    >
      <div className={styles.ReferingURLWrapper}>
        <When condition={trackingURL === ''}>
          <div className={styles.WrapEmpty}>
            <div>Please enter a referring URL to see the results</div>
          </div>
        </When>
        <When condition={trackingURL !== ''}>
          <EditableKeyValueTable
            pairsData={filterData}
            onKeyValueChanged={onTrackingParametersChanged}
            isExternalPointerDown={pointerDownValue}
            tableMinHeight={'319px'}
            hasAutoFocus={!(lastURL === '')}
            availableOperators={SourceOperators}
          />
          <div className={styles.SourceTrackingSeparator}></div>
          {children}
        </When>
      </div>
    </PopUpWrapper>
  );
};

SourceTrackingParameters.defaultProps = {
  position: { x: 0, y: 0 },
};
