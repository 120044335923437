import { createSlice } from '@reduxjs/toolkit';
const Z_INDEX_DEFAULT = 11;

const initialState = {
  peopleTrackingPopups: [],
  popupZIndex: Z_INDEX_DEFAULT,
  lastDraggedNode: null,
  people: [],
  paginationsLoaded: 0,
  isLoading: false,
};

export const peopleTrackingSlice = createSlice({
  name: 'peopleTracking',
  initialState,
  reducers: {
    setIsLoading: (prevState, action) => ({
      ...prevState,
      isLoading: action.payload,
    }),
    setPeopleTrackingPopup: (prevState, action) => {
      if (
        !prevState.peopleTrackingPopups.some(
          (prev) => prev.session.id === action.payload.session.id,
        )
      ) {
        return {
          ...prevState,
          peopleTrackingPopups: [...prevState.peopleTrackingPopups, action.payload],
        };
      }
    },
    removePeopleTrackingPopup: (prevState, action) => {
      let peopleTrackingPopups = [...prevState.peopleTrackingPopups];
      peopleTrackingPopups = peopleTrackingPopups.filter((p) => p.session.id !== action.payload);
      return {
        ...prevState,
        peopleTrackingPopups,
      };
    },
    resetPeopleTrackingPopups: (prevState) => ({
      ...prevState,
      peopleTrackingPopups: [],
    }),
    incrementPopupZIndex: (prevState) => ({
      ...prevState,
      popupZIndex: prevState.popupZIndex + 1,
    }),
    resetPopupZIndex: (prevState) => ({
      ...prevState,
      popupZIndex: Z_INDEX_DEFAULT,
    }),
  },
});

export const {
  setIsLoading,
  incrementZIndex,
  setPeopleTrackingPopup,
  removePeopleTrackingPopup,
  resetPeopleTrackingPopups,
  incrementPopupZIndex,
  resetPopupZIndex,
  resetLastDraggedNode,
} = peopleTrackingSlice.actions;

export const peopleTrackingReducer = peopleTrackingSlice.reducer;
