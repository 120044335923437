import Facade from 'pixi-project/Facade';
import generateId from 'pixi-project/utils/IDGenerator';
import Utils from 'pixi-project/utils/Utils';
import { cloneData } from 'shared/CSharedMethods';
import SharedElementHelpers from 'shared/SharedElementHelpers';
import { PR_EVENT_REPORT_BLOCKED_WARNING_POPUP_OPENED } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import MainStorage from 'pixi-project/core/MainStorage';

const VALUE_OF_SHIFTING = 30;
const STORAGE_KEY = 'funnel-clipboard';

export default class CopyPasteUtility {
  constructor(sceneManager, selectionManager) {
    this.sceneManager = sceneManager;
    this.selectionManager = selectionManager;

    if (!this.hasValidClipboard()) {
      this.reset();
    }
  }

  duplicateSelection() {
    this.copySelection();
    if (this.clipboard) {
      let center = JSON.parse(this.clipboard).center;
      center.x += VALUE_OF_SHIFTING;
      center.y += VALUE_OF_SHIFTING;
      center = window.app.viewport.toGlobal(center);
      this.pasteClipboard(center);
    }
  }

  copySelection(modifyElementCallback = null) {
    let clipboard = JSON.parse(this.clipboard);
    clipboard.objects = [];
    clipboard.joints = [];

    let selectionBounds = null;
    let selectedObjects = this.selectionManager.getSelectedSteps();

    if (this.canCopy(selectedObjects)) {
      if (selectedObjects.length === 1) {
        let selectedElement = selectedObjects[0];
        let state = this.copyElementState(selectedElement);
        clipboard.objects.push(state);
        selectionBounds = Utils.findGroupBounds([selectedElement]);
      } else if (selectedObjects.length > 1) {
        selectionBounds = Utils.findGroupBounds(selectedObjects);

        for (let i = 0; i < selectedObjects.length; i++) {
          let state = this.copyElementState(selectedObjects[i]);
          clipboard.objects.push(state);
        }

        const joints = this.sceneManager.joints;

        for (let i = 0; i < joints.length; i++) {
          const joint = joints[i];
          const idA = joint.iconA.id;
          const idB = joint.iconB.id;
          // if both ends of the joint are in the clipboard , then lets copy the connection
          let counter = 0;
          for (let j = 0; j < clipboard.objects.length; j++) {
            const object = clipboard.objects[j];
            if (idA === object.ID || idB === object.ID) {
              if (counter++ === 1) {
                let state = this.copyElementState(joint);
                clipboard.joints.push(state);
              }
            }
          }
        }
      }

      if (selectionBounds) {
        // Find the center point of the selection that was copied
        const bouindsLocalPosition = Facade.viewport.toLocal(
          new PIXI.Point(selectionBounds.left, selectionBounds.top),
        );
        clipboard.center.x = bouindsLocalPosition.x + selectionBounds.width / 2;
        clipboard.center.y = bouindsLocalPosition.y + selectionBounds.height / 2;
      }

      if (modifyElementCallback) {
        modifyElementCallback(clipboard);
      }

      // If coping from the canvas , we will not need what is inside the native clipboard
      // as it might contain an image file that will prevent from operating the copy-paste
      // system on the canvas
      if (selectedObjects.length > 0 && !this.isTextSelected()) {
        this.clearNativeClipboard();
      }
    }

    // The reference is stored in JSON format to avoid references
    this.clipboard = JSON.stringify(clipboard);
  }

  isTextSelected() {
    const selection = window.getSelection();
    return selection && selection.type === 'Range';
  }

  clearNativeClipboard() {
    const el = document.createElement('input');
    el.style.position = 'absolute';
    el.style.zIndex = -1;
    el.value = ' ';
    document.body.appendChild(el);
    el.select();
    el.focus();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  copyElementState(element) {
    const state = cloneData(element.getState());
    state.isFocused = element.isFocused;
    state.focusFilterTypes = element.focusFilterTypes;
    return state;
  }

  canPaste() {
    const clipboard = JSON.parse(this.clipboard);

    if (!clipboard || clipboard.objects.length === 0) {
      // no objects to paste
      return false;
    } else {
      return true;
    }
  }

  canCopy(objects) {
    for (let i = 0; i < objects.length; i++) {
      const object = objects[i];
      if (SharedElementHelpers.IsReport(object)) {
        return false;
      }
    }
    return true;
  }

  pasteClipboard(p = null) {
    if (MainStorage.getCanvasPermissions().isReadonlyAccess) {
      return;
    }

    // prepare the clipboard
    p = p || window.app.renderer.plugins.interaction.mouse.global;
    p = window.app.viewport.toLocal(p);
    this.replaceClipboardIds(); // to avoid duplicate IDs
    const clipboard = JSON.parse(this.clipboard);

    if (!this.canPaste()) {
      // no objects to paste
      return;
    }

    // move the position of all the objects in the clipboard so that their group center
    // as at a new position , for the existing clipboard center see copySelection
    this.centerClipboardAt(clipboard, p);

    const importedObjects = this.sceneManager.addObjectsToScene(
      clipboard.objects,
      clipboard.joints,
    );

    // Removes the elements from stage , and then inserts them through the command system.
    this.sceneManager.reAddElements(importedObjects);

    this.selectionManager.clearSelection();
    this.selectionManager.addObjectsToSelection(importedObjects); // select the pasted objects
    this.selectionManager.updateSelection(true, true);

    this.attachObjectsToReport(importedObjects);

    for (let i = 0; i < importedObjects.length; i++) {
      const object = importedObjects[i];
      object.onPaste(this.sceneManager);
    }

    if (importedObjects.length === 1) {
      this.selectionManager.sendElementToReact(importedObjects[0]);
    }

    window.app.needsRendering();
  }

  attachObjectsToReport(objects) {
    const reportView = this.sceneManager.findReport();

    if (reportView) {
      const reportObjects = [];
      objects.forEach((obj) => {
        if (reportView.isInsideSlide(obj)) {
          if (reportView.isLocked) {
            this.sceneManager.commandManager.undo();
            commonSendEventFunction(PR_EVENT_REPORT_BLOCKED_WARNING_POPUP_OPENED);
          } else {
            obj.isInsideReport = true;
            reportObjects.push(obj);
          }
        }
      });
      reportView.removeObjects(reportObjects);
      reportView.addObjects(reportObjects);
    }
  }

  centerClipboardAt(clipboard, p) {
    // calculate the distance between the clipboard group center
    // and the target point
    const shiftX = p.x - clipboard.center.x;
    const shiftY = p.y - clipboard.center.y;

    for (let i = 0; i < clipboard.joints.length; i++) {
      const jointData = clipboard.joints[i];
      for (let j = 0; j < jointData.breakPoints.length; j++) {
        const breakPoint = jointData.breakPoints[j];
        breakPoint.x += shiftX;
        breakPoint.y += shiftY;
      }
    }

    for (let i = 0; i < clipboard.objects.length; i++) {
      const objectData = clipboard.objects[i];
      objectData.x += shiftX;
      objectData.y += shiftY;

      if (objectData.shapeData) {
        objectData.shapeData.parentx = objectData.x;
        objectData.shapeData.parenty = objectData.y;
      }
    }
  }

  replaceClipboardIds() {
    let clipboard = JSON.parse(this.clipboard);

    let ids = {};

    for (let j = 0; j < clipboard.objects.length; j++) {
      const object = clipboard.objects[j];
      let id = generateId();
      ids[object.ID] = id;
      object.ID = id;
    }

    for (let j = 0; j < clipboard.joints.length; j++) {
      const joint = clipboard.joints[j];
      let id = generateId();
      ids[joint.ID] = id;
      joint.ID = id;
      joint.iconA_ID = ids[joint.iconA_ID];
      joint.iconB_ID = ids[joint.iconB_ID];
    }

    this.clipboard = JSON.stringify(clipboard);
  }

  reset() {
    // It is stored in JSON format to avoid references
    this.clipboard = JSON.stringify({ objects: [], joints: [], center: new PIXI.Point() });
  }

  hasValidClipboard() {
    try {
      const clipboard = JSON.parse(this.clipboard);
      return clipboard.objects && clipboard.joints && clipboard.center;
    } catch (e) {
      return false;
    }
  }

  get clipboard() {
    return localStorage.getItem(STORAGE_KEY);
  }

  set clipboard(data) {
    localStorage.setItem(STORAGE_KEY, data);
  }
}
