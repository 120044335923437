import { produce } from 'immer';
import { handleActions } from 'redux-actions';
import { updateFunnel, updateIsFunnelLoading, updateIsMigratedC1toC2 } from './actions';

const initialState = {
  isFunnelLoading: true,
  currentFunnelId: null,
  isMigratedC1toC2: false,
};

export default handleActions(
  {
    [updateFunnel.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft[action.payload.id] = action.payload;
        draft['currentFunnelId'] = action.payload.id;
      }),
    [updateIsFunnelLoading.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.isFunnelLoading = action.payload;
      }),

    [updateIsMigratedC1toC2.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.isMigratedC1toC2 = action.payload;
      }),
  },
  initialState,
);
