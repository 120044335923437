import React from 'react';

import styles from './Main.module.scss';

export const Frobidden = ({ message = 'Frobidden' }) => {
  return (
    <div className={styles.NotFound}>
      <h1>{message}</h1>
    </div>
  );
};

export default Frobidden;
