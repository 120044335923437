import React, { useEffect, useRef, useState } from 'react';
import {
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  convertToRaw,
  convertFromRaw,
  CompositeDecorator,
  AtomicBlockUtils,
} from 'draft-js';

import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
  CodeBlockButton,
  SupButton,
  SubButton,
  createInlineStyleButton,
} from '@draft-js-plugins/buttons';

import './richEditor.css';
import 'draft-js/dist/Draft.css';

import Editor from '@draft-js-plugins/editor';
import createTextAlignmentPlugin from '@draft-js-plugins/text-alignment';
import createStaticToolbarPlugin, { Separator } from '@draft-js-plugins/static-toolbar';
import createImagePlugin from '@draft-js-plugins/image';
import { ImageAdd } from './ImageAdd/ImageAdd';
import createVideoPlugin from '@draft-js-plugins/video';
import { VideoAdd } from './VideoAdd/VideoAdd.jsx';
import createLinkPlugin from '@draft-js-plugins/anchor';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import clearFormatting from 'draft-js-clear-formatting';

import '@draft-js-plugins/anchor/lib/plugin.css';

import buttonStyles from './buttonStyles.module.scss';
import alignmentStyles from './alignmentStyles.module.scss';
import toolbarStyles from './toolbarStyles.module.scss';
import { StyleButton } from './StyleButton/StyleButton';
import {
  iconClearStyle,
  iconHighlighter,
  iconStrikeThrough,
  iconTrashcan,
  spinnerGifBase64,
} from 'react-project/assets/Icons';
import { MyVideoComponent } from './VideoPlugin/MyVideoComponent';
import { findLinkEntities, MyLink } from './MyLink';
import { cloneData, commonSendEventFunction } from 'shared/CSharedMethods';
import { RP_EVENT_INFO_MESSAGE, RR_EVENT_EDITOR_NEEDS_FOCUS } from 'shared/CSharedEvents';
import { uploadPhotoToS3 } from 'react-project/Util/PhotoHelper';
import { useSelector } from 'react-redux';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import { When } from 'react-project/Util/When';
import { isEmpty } from 'lodash';
import { TEXT_TUTORIAL_UPLOAD_NOT_SUPPORTED } from 'react-project/Constants/texts';

const styleMap = {
  CODE: {
    backgroundColor: 'rgba(255, 255, 0, 0.5)',
  },
  STRIKETHROUGH: {
    textDecoration: 'line-through',
  },
  SUPERSCRIPT: {
    verticalAlign: 'super',
  },
  SUBSCRIPT: {
    verticalAlign: 'sub',
  },
};

function getBlockStyle(block) {
  const type = block.getType();
  switch (type) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return null;
  }
}

const HighlihterButton = createInlineStyleButton({
  style: 'CODE',
  children: iconHighlighter,
});

export const RichTextEditor = ({
  onFocus,
  onBlur,
  placeholder,
  onEditorContentChanged,
  rawContent,
  onImageSrcUpdated,
  showDeleteButton,
  onDeleteButtonClicked,
}) => {
  const [
    { plugins, Toolbar, linkDecorator, textAlignmentPlugin, imagePlugin, videoPlugin, linkPlugin },
  ] = useState(() => {
    const staticToolbarPlugin = createStaticToolbarPlugin({
      theme: { buttonStyles, toolbarStyles },
    });

    const textAlignmentPlugin = createTextAlignmentPlugin({
      theme: { alignmentStyles },
    });

    const imagePlugin = createImagePlugin();

    const videoPlugin = createVideoPlugin({ videoComponent: MyVideoComponent });

    const inlineToolbarPlugin = createInlineToolbarPlugin({
      theme: { buttonStyles, toolbarStyles },
    });

    const linkPlugin = createLinkPlugin({
      placeholder: 'http://…',
    });

    const plugins = [
      staticToolbarPlugin,
      textAlignmentPlugin,
      imagePlugin,
      videoPlugin,
      inlineToolbarPlugin,
      linkPlugin,
    ];

    const { Toolbar } = staticToolbarPlugin;

    const linkDecorator = new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: MyLink,
      },
    ]);

    return {
      plugins,
      Toolbar,
      linkDecorator,
      textAlignmentPlugin,
      imagePlugin,
      videoPlugin,
      linkPlugin,
    };
  });

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty(linkDecorator));
  const editorStateRef = useRef();
  const canvasPermissions = useSelector(selectCanvasPermissions);
  const [localRawContent, setLocalRawContent] = useState(rawContent);
  const [firstEdit, setFirstEdit] = useState(true);

  const updateEditorState = (state) => {
    setEditorState(state);
    editorStateRef.current = state;
  };

  const onImageUploaded = (imageURL, entityKey) => {
    updateImage(entityKey, imageURL);
  };

  useEffect(() => {
    document.addEventListener(RR_EVENT_EDITOR_NEEDS_FOCUS, onEditorNeedsFocus, false);

    return () => {
      document.removeEventListener(RR_EVENT_EDITOR_NEEDS_FOCUS, onEditorNeedsFocus, false);
    };
  }, []);

  useEffect(() => {
    if (localRawContent) {
      const state = EditorState.createWithContent(convertFromRaw(localRawContent), linkDecorator);
      updateEditorState(state);
    } else {
      const state = EditorState.createEmpty(linkDecorator);
      updateEditorState(state);
    }
  }, [localRawContent]);

  const editor = useRef(null);

  function focusEditor() {
    editor.current.focus();
  }

  const onEditorNeedsFocus = () => {
    // dirty fix for focusing the eidtor
    // as it seems that we need to wait for something to finish
    // before focusing , and that something is difficult to understand at this point
    setTimeout(() => {
      focusEditor();
    }, 0);
  };

  //////////////// styling

  const _handleKeyCommand = (command, editorState) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return true;
    }
    return false;
  };

  const _mapKeyToEditorCommand = (e) => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */);
      if (newEditorState !== editorState) {
        onChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  };

  const _toggleInlineStyle = (inlineStyle) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }
    onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const onChange = (newState) => {
    // when the editor is opened for the first time, we don't want to trigger the onEditorContentChanged
    if (firstEdit) {
      setFirstEdit(false);
      return;
    }

    const content = newState.getCurrentContent();
    updateEditorState(newState);

    const raw = convertToRaw(content);
    onEditorContentChanged(cloneData(raw), content);
  };

  const onClearStyling = () => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }
    const newEditorState = clearFormatting(editorState);
    //TODO Try to remove STRIKETHROUGH
    updateEditorState(newEditorState);
  };

  // https://draftjs.org/docs/api-reference-editor/#handlepastedfiles
  const handlePastedFiles = (files) => {
    if (canvasPermissions.isReadonlyAccess) {
      return 'handled';
    }
    addFilesToEditor(files);
    return 'handled';
  };

  const handlePastedText = (text, html, editorState) => {
    return 'not-handled';
  };

  const handleDroppedFiles = (selection, files) => {
    if (canvasPermissions.isReadonlyAccess) {
      return 'handled';
    }
    addFilesToEditor(files, selection);
    return 'handled';
  };

  const handleDrop = (selection, dataTransfer, isInternal) => {
    return 'not-handled';
  };

  const checkIfImage = (file) => {
    return file && file['type'].split('/')[0] === 'image';
  };

  const addFilesToEditor = (files, selection) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }

    if (canvasPermissions.isTutorial) {
      // Paste or drop an images in notes
      commonSendEventFunction(RP_EVENT_INFO_MESSAGE, {
        message: TEXT_TUTORIAL_UPLOAD_NOT_SUPPORTED,
      });
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const blob = files[i];
      if (checkIfImage(blob)) {
        const entityKey = insertLocalImage(editorState, spinnerGifBase64, selection);
        uploadPhotoToS3(
          blob,
          (response) => {
            onImageUploaded(response.imageURL, entityKey);
          },
          'icons/issue/misc',
        );
      }
    }
  };

  const onFileUpload = (files) => {
    // find the selection in the editor
    const selection = editorState.getSelection();
    addFilesToEditor(files, selection);
  };

  const insertLocalImage = (editorState, base64, selection = null) => {
    const contentState = editorState.getCurrentContent();
    const randomKey = Math.random();
    const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', {
      src: base64,
      randomKey,
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    let newEditorState = null;
    if (selection) {
      newEditorState = EditorState.set(EditorState.forceSelection(editorState, selection), {
        currentContent: contentStateWithEntity,
      });
    } else {
      newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity,
      });
    }

    const finalEditorState = AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
    updateEditorState(finalEditorState);
    return entityKey;
  };

  const updateImage = (entityKey, url) => {
    const contentState = editorStateRef.current.getCurrentContent();
    contentState.replaceEntityData(entityKey, { src: url });
    // Force re-render draft editor
    setEditorState(
      EditorState.forceSelection(editorStateRef.current, editorStateRef.current.getSelection()),
    );
  };

  const onRemoveNoteClicked = (e) => {
    if (confirm('Are you sure you want to delete this note?')) {
      // Clear the text editor state
      const state = EditorState.createEmpty(linkDecorator);
      updateEditorState(state);

      onDeleteButtonClicked(e);
    }
  };

  let className = 'RichEditor-editor';
  var contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      className += ' RichEditor-hidePlaceholder';
    }
  }

  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-root">
      <div
        onFocus={(e) => {
          onFocus(e);
        }}
        onBlur={(e) => {
          onBlur(e);
        }}
        onClick={focusEditor}
        className={className}
      >
        <Editor
          blockStyleFn={getBlockStyle}
          customStyleMap={styleMap}
          handleKeyCommand={_handleKeyCommand}
          keyBindingFn={_mapKeyToEditorCommand}
          placeholder={placeholder}
          ref={editor}
          spellCheck={true}
          onChange={onChange}
          editorState={editorState}
          plugins={plugins}
          stripPastedStyles={true}
          handlePastedText={handlePastedText}
          handlePastedFiles={handlePastedFiles}
          handleDroppedFiles={handleDroppedFiles}
          handleDrop={handleDrop}
          readOnly={canvasPermissions.isReadonlyAccess}
        />
      </div>

      {/* Toolbar must be rendered after the editor , as otherwise it will introduce a lag in updating the state of the buttons based on the cursor */}
      <Toolbar>
        {
          // may be use React.Fragment instead of div to improve perfomance after React 16
          (externalProps) => (
            <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: '10px' }}>
              <When condition={!canvasPermissions.isReadonlyAccess}>
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <UnderlineButton {...externalProps} />
                <StyleButton
                  key="strike-though"
                  active={currentStyle.has('STRIKETHROUGH')}
                  label={iconStrikeThrough}
                  onToggle={_toggleInlineStyle}
                  style={'STRIKETHROUGH'}
                />
                <HighlihterButton {...externalProps} />
                <Separator {...externalProps} className={buttonStyles.Separator} />
                <HeadlineOneButton {...externalProps} />
                <HeadlineTwoButton {...externalProps} />
                <HeadlineThreeButton {...externalProps} />
                <UnorderedListButton {...externalProps} />
                <OrderedListButton {...externalProps} />
                <BlockquoteButton {...externalProps} />
                <CodeBlockButton {...externalProps} />
                <Separator {...externalProps} className={buttonStyles.Separator} />
                <textAlignmentPlugin.TextAlignment {...externalProps} />
                <linkPlugin.LinkButton {...externalProps} />
                <Separator {...externalProps} className={buttonStyles.Separator} />
                <ImageAdd
                  editorState={editorState}
                  onChange={onChange}
                  modifier={imagePlugin.addImage}
                  onFileUpload={onFileUpload}
                />
                <VideoAdd
                  editorState={editorState}
                  onChange={onChange}
                  modifier={videoPlugin.addVideo}
                />

                <StyleButton
                  key="clearStyle"
                  active={false}
                  label={iconClearStyle}
                  onToggle={onClearStyling}
                  style={null}
                />
                <When condition={showDeleteButton}>
                  <div onClick={onRemoveNoteClicked} className="RichEditor-deleteButton">
                    <div className="RichEditor-deleteButton-wrapper">{iconTrashcan}</div>
                  </div>
                </When>
              </When>
            </div>
          )
        }
      </Toolbar>
    </div>
  );
};

export const isEmptyDraftJs = (rawState) => {
  if (!rawState || isEmpty(rawState)) {
    // filter undefined and {}
    return true;
  }
  const contentState = convertFromRaw(rawState);
  return !(contentState.hasText() && contentState.getPlainText() !== '');
};

// Custom overrides for "code" style.
