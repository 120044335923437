export const EElementCategories = {
  NONE: 'NONE',
  STEP: 'STEP',
  CONNECTION: 'CONNECTION',
  TEXT: 'TEXT',
  PHOTO: 'PHOTO',
  SHAPE: 'SHAPE',
  PANNING: 'PANNING',
  CLICKING: 'CLICKING',
  REPORT: 'REPORT',
  WIDGET: 'WIDGET',
};

export const EElementTypes = {
  NONE: 'NONE',
  PAGE: 'PAGE',
  EVENT: 'EVENT',
  SOURCE: 'SOURCE',
  MISC: 'MISC',
  WIDGET_COUNTRIES: 'WIDGET_COUNTRIES',
  WIDGET_PEOPLE: 'WIDGET_PEOPLE',
  PHOTO: 'PHOTO',
  WIDGET_GOALS: 'WIDGET_GOALS',
  WIDGET_TRENDS: 'WIDGET_TRENDS',
  WIDGET_FORECASTING: 'WIDGET_FORECASTING',
};

export const EStepConnectionPort = {
  IN: 'in',
  OUT: 'out',
  BOTTOM: 'bottom',
};

export const EExplorerConfigTypes = {
  [EElementTypes.PAGE]: EElementTypes.PAGE,
  [EElementTypes.EVENT]: EElementTypes.EVENT,
  [EElementTypes.SOURCE]: EElementTypes.SOURCE,
  PAGE_ALL: 'PAGE_ALL',
  EVENT_ALL: 'EVENT_ALL',
  SOURCE_ALL: 'SOURCE_ALL',
};

export const EIntegrationTypes = {
  FACEBOOK_AD: 'facebook-ad',
};
