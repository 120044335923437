import * as PIXI from 'pixi.js';
import Styles, {
  COLOR_TABLE_LOADER,
  COLOR_WIDGET_SUBTEXT,
  COLOR_WIDGET_TEXT,
  COLOR_WIDGET_TEXT_SELECTED,
} from '../Styles';
import TableRow from './TableView/TableRow';
import { truncate } from 'lodash';
import { roundTo, toPercent } from 'shared/CSharedMethods';
import { AnalyticsHelper } from 'shared/AnalyticsHelper';

export default class TableRowTrendsCompare extends TableRow {
  constructor(headerData, rowSize, columnSpacing = 0) {
    super(headerData, rowSize, columnSpacing);
  }

  initRow() {
    super.initRow();

    this.delegate = null;

    this._id = null;
    this.index = 0;
    this.isSelected = false;
    this.isMouseDown = false;
    this.reverseZebra = true; // starts with the colored row

    this.badge = PIXI.Sprite.from(PIXI.utils.TextureCache['compare_circle']);
    this.badge.anchor.set(0.5, 0.5);
    this.badge.scale.set(0.5);
    this.addChild(this.badge);

    this.titleLabelStyle = new PIXI.TextStyle(Styles.WIDGET_LABEL);

    this.cells = [];

    for (let i = 0; i < this.headerData.length - 1; i++) {
      const cell = new PIXI.Container();

      const hitsLabel = new PIXI.BitmapText('', Styles.WIDGET_LABEL);
      hitsLabel.tint = COLOR_WIDGET_TEXT;
      hitsLabel.anchor.y = 1;
      cell.hitsLabel = hitsLabel;
      cell.addChild(hitsLabel);

      const diffLabel = new PIXI.BitmapText('', Styles.WIDGET_LABEL);
      diffLabel.tint = COLOR_WIDGET_SUBTEXT;
      diffLabel.anchor.set(0, 1);
      cell.diffLabel = diffLabel;
      cell.addChild(diffLabel);

      const compareLabel = new PIXI.BitmapText('', Styles.WIDGET_LABEL);
      compareLabel.tint = COLOR_TABLE_LOADER;
      compareLabel.anchor.y = 0;
      cell.compareLabel = compareLabel;
      cell.addChild(compareLabel);

      const indicator = new PIXI.Sprite();
      indicator.anchor.set(0, 0.5);
      cell.indicator = indicator;
      cell.addChild(indicator);

      this.cells.push(cell);
      this.addChild(cell);
    }

    // the last cell
    const cell = PIXI.Sprite.from(PIXI.utils.TextureCache['icon-trashcan']);
    cell.anchor.set(0.5, 0.5);
    cell.interactive = true;
    cell.buttonMode = true;
    cell.on('pointerup', this.onActionClicked.bind(this));
    this.cells.push(cell);
    this.addChild(cell);
  }

  onActionClicked() {
    if (this.index === 0) {
      // mutate the data
      // set the last cell to have the correct texture
      this.data.isAllTrackedVisible = !this.data.isAllTrackedVisible;
      PIXI.utils.TextureCache[this.data.isAllTrackedVisible ? 'icon-eye' : 'icon-eye-closed'];
      this.delegate.delegate.onToggleAllStepsClicked(this.data);
      return;
    }

    // The row delegates to the table , which delegates to the widget
    this.delegate.delegate.onDeleteStepClicked(this.data);
  }

  bindData(data) {
    this.data = data;
    this.visible = true;

    const hits = data.hits;
    const compareHits = data.compare;

    this.badge.tint = data.color;

    if (this.index === 0) {
      // set the last cell to have a texture of a eye icon
      this.cells[this.cells.length - 1].texture =
        PIXI.utils.TextureCache[data.isAllTrackedVisible ? 'icon-eye' : 'icon-eye-closed'];
    }

    // The first cell
    const textTruncated = truncate(data.label, { length: 45 });
    const textMetrics = PIXI.TextMetrics.measureText(textTruncated, this.titleLabelStyle);
    const text = textMetrics.lines.join('\n');

    this.cells[0].hitsLabel.text = text;
    this.cells[0].diffLabel.text = '';
    const nCells = Math.max(hits.length, compareHits.length);

    // The data cells

    for (let i = 0; i < nCells; i++) {
      const cellData = hits[i];
      const compareData = compareHits[i];
      this.cells[i + 2].hitsLabel.text = cellData === null ? '/' : cellData; // show empty cell as '/'
      this.cells[i + 2].compareLabel.text = compareData;

      const differnece = this.calculateDifference(cellData, compareData);

      AnalyticsHelper.setIndicator(this.cells[i + 2].indicator, differnece);
      this.cells[i + 2].indicator.tint = AnalyticsHelper.getColorForPercent(differnece);
      this.cells[i + 2].indicator.visible = differnece !== null;
      this.cells[i + 2].diffLabel.text = differnece === null ? null : toPercent(differnece, 1);
    }

    // The average cell
    const avgHits = data.averageHits;
    const avgCompare = data.compareAverageHits;
    this.cells[1].hitsLabel.text = isNaN(avgHits) ? null : roundTo(avgHits, 2);
    this.cells[1].compareLabel.text = isNaN(avgCompare) ? null : roundTo(avgCompare, 2);

    const differnece = this.calculateDifference(avgHits, avgCompare);

    AnalyticsHelper.setIndicator(this.cells[1].indicator, differnece);
    this.cells[1].indicator.tint = AnalyticsHelper.getColorForPercent(differnece);
    this.cells[1].indicator.visible = differnece !== null;
    this.cells[1].diffLabel.text = differnece === null ? null : toPercent(differnece, 1);

    this.updatePositions();
  }

  calculateDifference(hits, compare) {
    if (hits === 0 && compare > 0) {
      return -100;
    } else if (hits > 0 && compare === 0) {
      return 100;
    } else if (hits === 0 && compare === 0) {
      return 0;
    } else if (isNaN(hits) || isNaN(compare) || hits === null || compare === null) {
      return null;
    }
    const diff = hits - compare;
    const percentage = (diff / hits) * 100;
    return percentage;
  }

  updatePositions() {
    const rh = this.rowSize.height;

    let cellX = this.columnSpacing;
    const spacing = 0;

    this.badge.x = this.columnSpacing + this.badge.width / 2;
    this.badge.y = rh / 2;

    // The first cell
    this.cells[0].hitsLabel.x = cellX + spacing + this.badge.width + 5;
    this.cells[0].hitsLabel.y = rh / 2;
    this.cells[0].hitsLabel.anchor.y = 0.5;
    cellX += this.headerData[0].calculatedWidth + this.columnSpacing;

    for (let i = 1; i < this.cells.length - 1; i++) {
      const cell = this.cells[i];

      cell.hitsLabel.x = cellX + spacing;
      cell.hitsLabel.y = rh / 2 - spacing;

      cell.indicator.x = cellX + spacing + cell.hitsLabel.width + 8;
      cell.indicator.y = rh / 2 - spacing - 7;

      cell.diffLabel.x = cellX + spacing + cell.hitsLabel.width + 8 + cell.indicator.width + 2;
      cell.diffLabel.y = rh / 2 - spacing;

      cell.compareLabel.x = cellX + spacing;
      cell.compareLabel.y = rh / 2 - spacing;

      cellX += this.headerData[i].calculatedWidth + this.columnSpacing;
    }

    const lastIndex = this.cells.length - 1;
    const lastCell = this.cells[lastIndex];
    lastCell.x = cellX + this.headerData[lastIndex].calculatedWidth / 2;
    lastCell.y = rh / 2;
  }
}
