import { ActionTypes } from 'shared/CSharedConstants';
import { EElementTypes } from 'shared/CSharedCategories';

export const commerceAction = Object.freeze({
  __commerce_action__: 'Purchase',
  __currency__: 'Currency',
  __order__: 'Order ID',
  __sku__: 'Product ID',
  __total_in_cents__: 'Total',
  __label__: 'Description',
});

export const COMMERCE_ACTION_NAME = '__commerce_action__';
// TODO this needs to be verified and coordinated with the backend
export const MEETING_ACTION_NAME = 'Meeting Scheduled';
export const FORM_ACTION_NAME = 'Form Completed';
export const DEAL_ACTION_NAME = '__deal_action__';

export const COMMERCE_ACTIONS = ['purchase', '__commerce_action__'];

export const getActionType = (name, type) => {
  switch (name) {
    case COMMERCE_ACTION_NAME:
      return ActionTypes.COMMERCE;
    default:
      return type === EElementTypes.EVENT ? ActionTypes.CUSTOM : ActionTypes.NONE;
  }
};
