import { produce } from 'immer';
import { handleActions } from 'redux-actions';

import { setSelectedElements } from './actions';

const initialState = {
  selectedElements: [],
  changedElement: null,
};

export default handleActions(
  {
    [setSelectedElements.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.selectedElements = action.payload.selectedElements;
        draft.changedElement = action.payload.elementChanged;
      }),
  },
  initialState,
);
