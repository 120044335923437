import { orderBy } from 'lodash';
import { curry, curryN, filter, find, length, map, pipe, propEq, uniq } from 'ramda';
import { adType, filterForExclusion } from 'react-project/FacebookIntegration/helpers';
import { CheckboxState } from 'react-project/features/facebookIntegration/checkbox/checkbox';
import { updateItemStates } from 'react-project/features/facebookIntegration/checkbox/temp-checkbox';
import { priceMock } from 'react-project/features/facebookIntegration/price-mock';

const BASE_ATTRS = {
  checked: 0,
  expanded: false,
  excludeFromCheckbox: false,
  doNotDisplay: false,
};
const matchAd = (campaignId) => (ad) => ad.campaign.id === campaignId;
const adSetIdsFromAd = (ad) => ad.adset.id;
const mapToStateItem = map((item) => ({
  ...item,
  id: item.id,
  state: item.checked,
  excludeFromCheckbox: filterForExclusion(item),
}));
const mapToCompactItem = map((item) => ({
  id: item.id,
  title: item.title,
  parentId: item.parentId,
  type: item.type,
}));
const getParentId = (tier) => {
  if (!tier || !tier?.type) return null;

  if (tier.type === adType.ADSET && tier.campaignId) {
    return tier.campaignId;
  }

  if (tier.type === adType.AD && tier.adSetId) {
    return tier.adSetId;
  }

  return null;
};
const transformToDesiredState = (prevState) => ({
  state: mapToStateItem(prevState),
  items: mapToCompactItem(prevState),
});

const flattenCollection = (state) => {
  const combinedState = [...state.campaigns, ...state.adSets, ...state.ads];
  const mapCombinedStateToStateFormat = map((item) => ({
    ...item,
    id: item.id,
    title: item.title,
    parentId: getParentId(item),
  }));
  return mapCombinedStateToStateFormat(combinedState);
};

const concatNewStateToOld = (updatedItem, prevItem) => {
  const match = updatedItem.id === prevItem.id;
  const checked = match ? updatedItem.state : prevItem.checked;
  const excludeFromCheckbox = updatedItem.excludeFromCheckbox;
  return { ...prevItem, checked, excludeFromCheckbox };
};

const unfoldCollection = (prevState, state) =>
  map((item) => {
    const isIdMatch = propEq('id', item.id);
    const findMatchBetweenBothStates = find(isIdMatch);
    const updatedItem = findMatchBetweenBothStates(state);

    if (updatedItem) {
      return concatNewStateToOld(updatedItem, item);
    }

    return item;
  }, prevState);

export const getAdSetsByCampaignId = (campaignId, data) => {
  const filterCampaignsById = filter(matchAd(campaignId));
  const getAdSets = map(adSetIdsFromAd);
  return pipe(filterCampaignsById, getAdSets, uniq)(data);
};

export const formatPriceToLocale = (price, locale = 'USD') =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: price.currency || 'USD',
  }).format(price.value);

export const formatUrlParameterData = (urlParameter) => {
  const utmArr = [];
  urlParameter?.split('&').forEach((el) => {
    const [key, value] = el.split('=');
    const urlParamDict = {};
    urlParamDict['key'] = key;
    urlParamDict['value'] = value;
    urlParamDict['contains'] = true;
    utmArr.push(urlParamDict);
  });
  return utmArr;
};

export const determineDepth = (type) => {
  const typeDepthMapping = {
    Campaign: 0,
    AdSet: 1,
    Ad: 2,
  };

  return typeDepthMapping[type] || 0;
};

export const formatToTierFromCategoryOfAd = (data) => ({
  ...data,
  depth: determineDepth(data.type),
  childCount: length(data.childResultsById),
  checked: 0,
  expanded: false,
  parentId: null,
});

export const processRequest = (data) => ({
  ...data,
  depth: determineDepth(data.type),
  childCount: length(data.childResultsById),
  checked: CheckboxState.UNCHECKED,
  expanded: false,
  parentId: null,
});

export const processAdSet = (adSet) => {
  const price = priceMock(adSet.id);
  return {
    ...adSet,
    depth: determineDepth(adSet.type),
    checked: CheckboxState.UNCHECKED,
    excludeFromCheckbox: false,
    expanded: false,
    totalSpend: {
      price,
      formattedPrice: formatPriceToLocale(price),
    },
  };
};

export const getTierStateByLevel = curry(({ campaigns, adSets, ads }, level) => {
  switch (level) {
    case 0:
      return campaigns;
    case 1:
      return adSets;
    case 2:
      return ads;
    default:
      return [];
  }
});

export const updateSpend = (currentItem, newItem, changeType) => {
  if (!currentItem) return;

  if (changeType === 'deduct') {
    try {
      delete currentItem.adsTotalSpend[newItem.id];
    } catch (e) {
      currentItem.totalSpend.value = currentItem.totalSpend.value - newItem.totalSpend.value;
      currentItem.totalSpend.formattedPrice = formatPriceToLocale(currentItem.totalSpend);
      return;
    }
  } else if (changeType === 'add') {
    currentItem.adsTotalSpend[newItem.id] = Number(newItem.totalSpend.value) || 0;
  }
  currentItem.totalSpend.value = Object.values(currentItem.adsTotalSpend).reduce(
    (partialSum, a) => partialSum + a,
    0,
  );
  currentItem.totalSpend.formattedPrice = formatPriceToLocale(currentItem.totalSpend);
  return;
};

export const toggleFieldById = curryN(3, (field, id, tier) =>
  tier.id === id ? { ...tier, [field]: !tier[field] } : tier,
);

export const updateSpendByItem = (item, state, type) => {
  //Update ad
  if (type === 'deduct') {
    delete state[item.id];
  } else if (type === 'add') {
    state[item.id] = { ...item };
  } else {
    throw 'Invalid parameter type';
  }
  //Create Adset spend total if it doesn't exist
  if (type === 'add' && !state[item.adSetId]) {
    state[item.adSetId] = {
      id: item.adSetId,
      type: adType.ADSET,
      campaignId: item.campaignId,
      totalSpend: {
        currency: item.totalSpend.currency,
        value: 0,
        formattedPrice: formatPriceToLocale(0),
      },
      adsTotalSpend: {},
    };
  }
  //Update Adset spend total
  updateSpend(state[item.adSetId], item, type);

  //Create campaign if it doesn't exist
  if (type === 'add' && !state[item.campaignId]) {
    state[item.campaignId] = {
      id: item.campaignId,
      type: adType.CAMPAIGN,
      totalSpend: {
        currency: item.totalSpend.currency,
        value: 0,
        formattedPrice: formatPriceToLocale(0),
      },
      adsTotalSpend: {},
    };
  }
  //Update campaign totalspend
  updateSpend(state[item.campaignId], item, type);
  return state;
};

export const updateCheckboxes = (collection, id) => {
  // predicates
  const typeIs = propEq('type');
  const filterBy = (type) => filter(typeIs(type));
  // transforms
  const flatCollection = flattenCollection(collection);
  const { items, state } = transformToDesiredState(flatCollection);

  // run logic on state change
  const { updatedState, adsChecked, adsUnchecked } = updateItemStates(state, items, id);
  // unfold flat collection
  const unfoldedCollection = unfoldCollection(flatCollection, updatedState);
  // split unfolded data into categories
  const filterByCampaign = filterBy(adType.CAMPAIGN);
  const filterBySets = filterBy(adType.ADSET);
  const filterByAds = filterBy(adType.AD);

  const campaigns = filterByCampaign(unfoldedCollection);
  const adSets = filterBySets(unfoldedCollection);
  const ads = filterByAds(unfoldedCollection);

  return { ads, adSets, campaigns, adsChecked, adsUnchecked };
};

export const selectedAdsToIdCollection = (integrationAttributes) => {
  let userCampaignIds = [];
  let userAdSetIds = [];
  let userAdIds = [];
  integrationAttributes.forEach((item) => {
    const { adSetId, campaignId, id } = item;
    userCampaignIds.push(campaignId);
    userAdSetIds.push(adSetId);
    userAdIds.push(id);
  });
  userCampaignIds = [...new Set(userCampaignIds)];
  userAdSetIds = [...new Set(userAdSetIds)];
  userAdIds = [...new Set(userAdIds)];
  return { userAdIds, userAdSetIds, userCampaignIds };
};

export const createCampaigns = (prevState, payload) => {
  if (!payload.success) return { ...prevState };
  const newResponse = payload.data.map((item) => {
    const price = {
      value: item.price?.value,
      currency: item.price?.currency,
    };
    return {
      ...item,
      ...BASE_ATTRS,
      depth: 0,
      parentId: null,
      totalSpend: {
        ...price,
        formattedPrice: item.price ? formatPriceToLocale(price) : undefined,
      },
    };
  });
  return newResponse;
};

export const applyAttributeFilters = (filterCallbackList, collectionObj) => {
  filterCallbackList.forEach((filterCallback) => {
    filterCallback(collectionObj);
  });
};

export const createAdsAndAdsets = (stepIdPrevState, payload) => {
  if (!payload.success || payload.data.length === 0) return { ...stepIdPrevState };
  const adSets = [...stepIdPrevState.adSets];
  const ads = [...stepIdPrevState.ads];
  const allAdSetIds = [];
  const parentId = payload.data[0]['campaignId'];
  const campaignIndex = stepIdPrevState.campaigns.findIndex((el) => el.id === parentId);
  const isCampaignChecked = stepIdPrevState.campaigns[campaignIndex]['checked'];
  payload.data.forEach((item) => {
    const price = {
      value: item.price?.value,
      currency: item.price?.currency,
    };
    const adSet = {
      ...item,
      ...BASE_ATTRS,
      depth: 1,
      parentId: item.campaignId,
      totalSpend: item.price?.value
        ? {
            ...price,
            formattedPrice: formatPriceToLocale(price),
          }
        : null,
    };
    delete adSet.childResults;
    const filteredAdSetIds = stepIdPrevState.filteredAdSetIds;
    if (filteredAdSetIds > 0) {
      applyAttributeFilters([updateDisplayAttribute(filteredAdSetIds)], adSet);
    }
    if (isCampaignChecked) {
      adSet['checked'] = 1;
    }
    adSets.push(adSet);
    allAdSetIds.push(item.id);

    item.childResults.forEach((childResult) => {
      const price = {
        value: childResult.price?.value,
        currency: childResult.price?.currency,
      };
      const ad = {
        ...BASE_ATTRS,
        ...childResult,
        utm_tags: childResult.utmParameters,
        depth: 2,
        parentId: childResult.adSetId,
        totalSpend: childResult.price?.value
          ? {
              ...price,
              formattedPrice: formatPriceToLocale(price),
            }
          : null,
      };
      if (isCampaignChecked) {
        ad['checked'] = 1;
      }
      const filteredAdIds = stepIdPrevState.filteredAdIds;
      if (filteredAdIds) {
        applyAttributeFilters([updateDisplayAttribute(filteredAdIds)], ad);
      }
      ads.push(ad);
    });
  });

  const campaigns = stepIdPrevState.campaigns.map((item, i) =>
    i === campaignIndex ? { ...item, childResultsById: allAdSetIds } : { ...item },
  );
  orderBy(adSets, [(item) => parseFloat(item.totalSpend?.value || '')], ['desc']);
  orderBy(ads, [(item) => parseFloat(item.totalSpend?.value || '')], ['desc']);
  return { campaigns, adSets, ads };
};

export const updateDisplayAttribute = (idList) => (collectionObj) => {
  let newCollectionObj = { ...collectionObj };
  if (idList.indexOf(collectionObj.id) === -1) {
    newCollectionObj.doNotDisplay = true;
  } else {
    newCollectionObj.doNotDisplay = false;
  }
  return newCollectionObj;
};
