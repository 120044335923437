import styles from './TrackingTabAction.module.scss';
import React from 'react';
import { isArray } from 'lodash';
import { removeItemIcon } from 'react-project/assets/Icons';
import { getLogicalOperator } from 'react-project/Toolbar/step-toolbar/LogicalOperators';

export const ActionAttributes = ({ attributes, setAttributes }) => {
  const onDelete = (attr) => {
    setAttributes(attributes.filter((a) => a !== attr));
  };

  return (
    <div>
      {attributes.map((attr, index) => (
        <div className={styles.ActionAttributeItem} key={attr.key}>
          <span>
            {index === 0 ? 'where' : 'and where'}
            <strong> {attr.key}</strong>
            <span> {getLogicalOperator(attr.operator).label}</span>
            {isArray(attr.value) ? (
              attr.value.map((itm, index) =>
                index !== 0 ? (
                  <span key={index}>
                    {' '}
                    or <strong>{itm}</strong>
                  </span>
                ) : (
                  <strong key={index}> {itm}</strong>
                ),
              )
            ) : (
              <strong> {attr.value === '*' ? 'any' : attr.value}</strong>
            )}
          </span>
          <div className={styles.ActionAttributeButton} onClick={() => onDelete(attr)}>
            {removeItemIcon}
          </div>
        </div>
      ))}
    </div>
  );
};
