import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './TablePopup.module.scss';
import { iconPopupClose } from 'react-project/assets/Icons';
import { StripedTable } from '../../table/StripedTable';
import { Rnd } from 'react-rnd';

const DEFAULT_POPUP_WIDTH = 360;
const DEFAULT_POSITION_X = 120;
const DEFAULT_POSITION_Y = 60;

export const TablePopup = ({
  rows,
  title,
  icon,
  popupWidth,
  position,
  onClose,
  onDeleteBtn,
  onTableClosed,
  cancel,
  onPointerDown,
}) => {
  const valueRef = useRef();
  valueRef.current = onTableClosed;

  useEffect(() => {
    return () => {
      if (valueRef.current) {
        valueRef.current();
      }
    };
  }, []);

  return (
    <Rnd
      default={{
        x: position.x,
        y: position.y,
        width: popupWidth,
      }}
      minWidth={popupWidth}
      bounds="window"
      cancel={'.' + cancel}
      enableResizing={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
    >
      <div onPointerDown={onPointerDown} className={styles.TablePopup}>
        <div className={styles.TablePopupHeader}>
          <div className={styles.TablePopupTitle}>
            {icon && <span className={styles.Icon}>{icon}</span>}
            <span className={styles.TablePopupTitleText}>{title}</span>
          </div>
          {onClose && (
            <div className={styles.TablePopupCloseButton} onClick={onClose}>
              {iconPopupClose}
            </div>
          )}
        </div>

        {rows && <StripedTable rows={rows} onDeleteBtn={onDeleteBtn} />}
      </div>
    </Rnd>
  );
};

TablePopup.defaultProps = {
  position: { x: DEFAULT_POSITION_X, y: DEFAULT_POSITION_Y },
  popupWidth: DEFAULT_POPUP_WIDTH,
  cancel: 'non-existing-class-name',
};

TablePopup.propTypes = {
  rows: PropTypes.shape({
    columnWidths: PropTypes.arrayOf(PropTypes.string),
    columnsNumber: PropTypes.number.isRequired,
    items: PropTypes.array.isRequired,
    rowHeaders: PropTypes.object,
  }),
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  popupWidth: PropTypes.number.isRequired,
  position: PropTypes.shape({ x: PropTypes.number.isRequired, y: PropTypes.number.isRequired })
    .isRequired,
  onClose: PropTypes.func,
};
