import * as PIXI from 'pixi.js';
import { roundTo } from 'shared/CSharedMethods';
import { numSeparator } from 'shared/NumberHelpers';
import { shortenText } from 'shared/SharedHelpers';
import Styles, { COLOR_WIDGET_TEXT, COLOR_WIDGET_TEXT_SELECTED } from '../Styles';
import TableRow from './TableView/TableRow';

export default class TableRowExpenses extends TableRow {
  constructor(headerData, rowSize, columnSpacing = 0) {
    super(headerData, rowSize, columnSpacing);
  }

  initRow() {
    super.initRow();

    this.delegate = null;

    this._id = null;
    this.index = 0;
    this.isSelected = false;

    this.nameLabel = new PIXI.BitmapText('', Styles.WIDGET_LABEL);
    this.nameLabel.tint = COLOR_WIDGET_TEXT;
    this.nameLabel.anchor.y = 0.5;
    this.addChild(this.nameLabel);

    this.hitsLabel = new PIXI.BitmapText('', Styles.WIDGET_LABEL);
    this.hitsLabel.tint = COLOR_WIDGET_TEXT;
    this.hitsLabel.anchor.y = 0.5;
    this.hitsLabel.anchor.x = 1;
    this.addChild(this.hitsLabel);

    this.actionBtn = new PIXI.Sprite(PIXI.utils.TextureCache['locate']);
    this.actionBtn.anchor.y = 0.5;
    this.actionBtn.buttonMode = true;
    this.actionBtn.interactive = true;
    this.actionBtn.pointerdown = (e) => {
      e.stopPropagation();
    };
    this.actionBtn.pointerup = (e) => {
      this.onActionClicked(e);
    };
    this.addChild(this.actionBtn);
  }

  onActionClicked() {
    // if (this.delegate && this.delegate.delegate) {
    //   this.delegate.delegate.onLocateClicked(e, this);
    // }

    if (this.data && this.data.isManual) {
      if (this.delegate && this.delegate.delegate) {
        this.delegate.delegate.onEditClicked(this.data);
      }
    } else {
      if (this.delegate && this.delegate.delegate) {
        this.delegate.delegate.onLocateClicked(this.data);
      }
    }
  }

  bindData(data) {
    this.data = data;
    this.visible = true;
    this.nameLabel.text = shortenText(data.name, 30);
    this.hitsLabel.text = data.expense;

    if (this.data.isManual) {
      this.actionBtn.texture = PIXI.utils.TextureCache['pencil_icon'];
    } else {
      this.actionBtn.texture = PIXI.utils.TextureCache['locate'];
    }

    this.updatePositions();
  }

  updatePositions() {
    const rh = this.rowSize.height;
    let cellX = this.columnSpacing;

    this.nameLabel.x = cellX;
    this.nameLabel.y = rh / 2;

    this.hitsLabel.x = this.rowSize.width - 50;
    this.hitsLabel.y = rh / 2;

    this.actionBtn.x = this.rowSize.width - 40;
    this.actionBtn.y = rh / 2;
  }

  onSelectChanged(isSelected) {
    super.onSelectChanged(isSelected);
    //
    window.app.needsRendering();
  }
}
