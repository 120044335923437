import * as PIXI from 'pixi.js';
import { EElementCategories, EElementTypes, EStepConnectionPort } from 'shared/CSharedCategories';
import BaseContainer from 'pixi-project/base/containers/BaseContainer';
import BaseSignals from 'pixi-project/base/signals/BaseSignals';
import Utils from 'pixi-project/utils/Utils';
import generateId from 'pixi-project/utils/IDGenerator';
import Facade from 'pixi-project/Facade';
import ConnectionHelper from 'pixi-project/view/joint/ConnectionHelper';
import {
  EConnectionCentered,
  EConnectionLineType,
  EConnectionDrawLineType,
  EConnectionType,
} from 'pixi-project/base/joint/CConnectionConstants';
import SharedElementHelpers from 'shared/SharedElementHelpers';
import MainStorage from 'pixi-project/core/MainStorage';
import AnalyticsConnectionManager from 'pixi-project/core/AnalyticsConnectionManager';
import { COLOR_CONNECTION_LINE } from 'pixi-project/view/Styles';
import AppSignals from 'pixi-project/signals/AppSignals';
import ConnectionBreakPoint from './ConnectionBreakPoint';
import { roundTo } from 'shared/CSharedMethods';
import CommandAddBreakPoint from 'pixi-project/base/command-system/commands/CommandAddBreakPoint';
import CommandDeleteBreakPoint from 'pixi-project/base/command-system/commands/CommandDeleteBreakPoint';
import CommandMoveBreakPoint from 'pixi-project/base/command-system/commands/CommandMoveBreakPoint';
import LineDrawHelper from 'pixi-project/utils/LineDrawHelper';
import ConnectionDisplay from '../objects/anaytics-display/ConnectionDisplay';
import BezierSegment from './BezierSegment';
import SelectionPoint from './SelectionPoint';
import GhostBreakPoint from './GhostBreakPoint';
import { SELECTION_TYPE_MULTI, SELECTION_TYPE_SINGLE } from '../selection/SelectionManager';
import { CControlPointTypes } from 'pixi-project/base/containers/CContainerConstants';
import { MIN_ALLOWED_SCALE } from '../selection/SelectionTool';
import HitAreaShapes from 'pixi-project/utils/HitAreaShapes';
import BezierCurve from 'pixi-project/utils/BezierCurve';

export const DEFAULT_CONNECTION_LINE_WIDTH = 2;
export const DEFAULT_HIT_AREA_SHIFT = 20;
const BREAK_POINTS_LIMIT = 5; // max amount of break points allowed
const DEFAULT_HIT_AREA_STEP = 0.1;
const MIN_DISPALY_SCALE = 0.3; // When the target element scales , the analytics display might scale also
const MAX_DISPALY_SCALE = 1;

// Delegates:
// - onConnectionPointerDown(event, connection)
// - onConnectionAttachPointDown(e, connection, attachmentPoint, isEndPoint);

export default class ConnectionContainer extends BaseContainer {
  constructor(elementA, elementB, id = generateId(), loadData = null) {
    super();
    this.category = EElementCategories.CONNECTION;
    this.id = id;
    this.pointA = new PIXI.Point();
    this.pointB = new PIXI.Point();
    this.iconA = elementA;
    this.iconB = elementB;
    this._selectable = true;
    this.type = EElementTypes.NONE;
    this.connectionTypeSource = EStepConnectionPort.IN;
    this.arrowHeadShown = EConnectionCentered.NONE;
    this.delegate = null;
    this.breakPoints = [];
    this.ghostBreakPoints = [];
    this.canShowBreakPoints = true;
    this.isShifting = false;
    this.isResizable = false;
    this.hasSelectionFrame = false;
    this.bezierSegments = [];
    this.attachmentPointsLocation = {
      pointA: CControlPointTypes.CENTER,
      pointB: CControlPointTypes.CENTER,
    };
    this.drawLineType = EConnectionDrawLineType.BEZIER;
    this.detachedData = null;
    this.curve = new BezierCurve(
      new PIXI.Point(),
      new PIXI.Point(),
      new PIXI.Point(),
      new PIXI.Point(),
    );

    this.flowData = {
      points: [],
      density: 0,
      absoluteDensity: 0,
      relativeDensity: 0,
      currentTravelDistance: 0,
      spacing: 0,
      speed: 5,
      lastPointIndex: -1,
      color: COLOR_CONNECTION_LINE,
      cluster: 0,
    };

    this.forecastingData = { percent: 100 }; // default value

    if (loadData) {
      if (loadData.drawLineType) {
        this.drawLineType = loadData.drawLineType;
      }

      if (loadData.attachmentPointsLocation) {
        this.attachmentPointsLocation.pointA = loadData.attachmentPointsLocation.pointA;
        this.attachmentPointsLocation.pointB = loadData.attachmentPointsLocation.pointB;
      }
    }

    if (this.canToogleType()) {
      if (loadData && loadData.lineType) {
        // This is new data format for connections , that stores lineType on serverside.
        this.lineType = loadData.lineType;
      } else if (loadData && loadData.ignoreInBetween) {
        // This is a support for old connections (Stored on serverside before the new format)
        this.lineType = EConnectionLineType.DOTTED;
      } else {
        this.lineType = EConnectionLineType.SOLID;
      }
    } else {
      this.lineType = EConnectionLineType.NODATA;
    }

    const isSourceToAction =
      (SharedElementHelpers.IsSource(elementA) && SharedElementHelpers.IsAction(elementB)) ||
      (SharedElementHelpers.IsAction(elementA) && SharedElementHelpers.IsSource(elementB));
    const isActionToAction =
      SharedElementHelpers.IsAction(elementA) && SharedElementHelpers.IsAction(elementB);
    const isSourceToSource =
      SharedElementHelpers.IsSource(elementA) && SharedElementHelpers.IsSource(elementB);
    const isPageToSource =
      SharedElementHelpers.IsPage(elementA) && SharedElementHelpers.IsSource(elementB);

    if (isSourceToAction || isActionToAction || isSourceToSource || isPageToSource) {
      this.supportedLineTypes = [EConnectionLineType.DOTTED, EConnectionLineType.NODATA];
      if (!loadData) {
        this.lineType = EConnectionLineType.DOTTED;
      }
    } else if (!this.canToogleType()) {
      this.supportedLineTypes = [EConnectionLineType.NODATA];
    } else {
      this.supportedLineTypes = [
        EConnectionLineType.SOLID,
        EConnectionLineType.DOTTED,
        EConnectionLineType.NODATA,
      ];
    }

    this.pointInBody = false;
    this.pointInA = false;
    this.pointInB = false;

    this.content = new PIXI.Graphics();
    this.content.interactive = this._selectable;
    this.content.buttonMode = true;
    this.addChild(this.content);

    this.footer.removeFromParent();
    this.footer = new ConnectionDisplay(this);
    this.footer.delegate = this;
    this.addChild(this.footer);

    this.analyticsManager = new AnalyticsConnectionManager(this, this.footer);

    this.loadBreakPoints(loadData && loadData.breakPoints);

    this.selectionPointA = new SelectionPoint();
    this.attachEventsToHeadPoint(this.selectionPointA);
    this.addChild(this.selectionPointA);

    this.selectionPointB = new SelectionPoint();
    this.attachEventsToHeadPoint(this.selectionPointB);
    this.addChild(this.selectionPointB);

    this.updateFooter();
    this.updateNoDataView();
  }

  attachEventsToHeadPoint(selectionPoint) {
    selectionPoint.pointerdown = this.onConnectionAttachPointDown.bind(this);
  }

  init(connectionType) {
    this.connectionTypeSource = connectionType;

    this.drawHeadA();
    this.drawHeadB();

    this.attach();
    this.draw();

    this.footer.resetData();
    this.adjustAnalyticsDisplayScale(this.getSmallerElement());
  }

  loadBreakPoints(breakPoints = null) {
    if (breakPoints) {
      for (let i = 0; i < breakPoints.length; i++) {
        const breakPointData = breakPoints[i];
        const breakPoint = this.createBreakPoint(breakPointData);
        if (breakPointData.isSpecial) {
          this.footer.breakPoint = breakPoint;
          breakPoint.connectionDisplay = this.footer;
        }
        this.addChild(breakPoint);
        this.breakPoints.push(breakPoint);
      }

      if (!this.isStraight() && this.breakPoints.length) {
        this.recreateBezierSegments();
        this.calculateBezierSegments();
      }
    }

    this.hideBreakPoints();
  }

  draw() {
    this.updatePoints();

    this.calculateControlPoints();
    this.drawAppropriateLine();

    const point = this.getConnectionPoint(0.5);

    this.footer.updateVisibility(
      MainStorage.isNumbersVisible() || MainStorage.isForecastingVisible(),
    );

    if (!this.footer.breakPoint) {
      this.footer.position = point;
    } else {
      this.footer.position = this.footer.breakPoint.position;
    }

    this.headA.x = this.pointA.x;
    this.headA.y = this.pointA.y;
    this.headB.x = this.pointB.x;
    this.headB.y = this.pointB.y;
    this.selectionPointA.x = this.pointA.x;
    this.selectionPointA.y = this.pointA.y;
    this.selectionPointB.x = this.pointB.x;
    this.selectionPointB.y = this.pointB.y;

    if (this.nearestPoints.pointA.type === CControlPointTypes.FLOW) {
      let radians = 0;
      if (this.breakPoints.length) {
        radians = Utils.angleAB(this.breakPoints[0], this.pointA);
      } else {
        radians = Utils.angleAB(this.pointB, this.pointA);
      }
      this.headA.angle = Utils.rad2deg(radians) + 90;
    } else {
      this.headA.angle = ConnectionHelper.GetAngleForHead(this.nearestPoints.pointA) - 90;
    }

    let pointA = null;

    if (this.isStraight()) {
      if (this.breakPoints.length) {
        pointA = this.breakPoints[this.breakPoints.length - 1];
      } else {
        pointA = this.pointA;
      }
    } else {
      pointA = this.controlPointB;
    }

    if (this.nearestPoints.pointB.type === CControlPointTypes.FLOW) {
      let radians = 0;
      if (this.breakPoints.length) {
        radians = Utils.angleAB(this.breakPoints[this.breakPoints.length - 1], this.pointB);
      } else {
        radians = Utils.angleAB(this.pointA, this.pointB);
      }

      this.headB.angle = Utils.rad2deg(radians) + 90;
    } else {
      const radians = Utils.angleAB(pointA, this.pointB);
      this.headB.angle = Utils.rad2deg(radians) + 90;
    }

    this.visible = Utils.distanceAB(this.iconA, this.iconB) >= 100;
  }

  updateHeadsVisibility() {
    this.headA.visible = this.arrowHeadShown === EConnectionCentered.A;
    this.headB.visible = this.arrowHeadShown === EConnectionCentered.B;
  }

  isStraight() {
    return this.drawLineType === EConnectionDrawLineType.STRAIGHT;
  }

  /**
   * Draw the line according to the lineType parameter
   */
  drawAppropriateLine() {
    const { width, color } = this._getLineStyle();
    this.content.clear();
    this.content.lineStyle(width, color);
    const isSolid =
      this.lineType === EConnectionLineType.SOLID || this.lineType === EConnectionLineType.NODATA;
    const isPolyline = this.breakPoints.length ? true : false;

    if (this.isStraight()) {
      this.drawStraightLine(isSolid);
    } else {
      this.drawBezierConnection(isSolid, isPolyline);
    }

    if (this._selectable) {
      this._setHitArea();
    }
  }

  drawStraightLine(isSolid) {
    this.walkAllLines((a, b) => {
      if (isSolid) {
        LineDrawHelper.drawLine(this.content, a, b);
      } else {
        LineDrawHelper.drawDashedLine(this.content, a, b);
      }
    });
  }

  drawBezierConnection(isSolid, isPolyline) {
    if (!isPolyline) {
      if (isSolid) {
        LineDrawHelper.drawBezier(
          this.content,
          this.pointA,
          this.controlPointA,
          this.controlPointB,
          this.pointB,
        );
      } else {
        LineDrawHelper.drawDashedBezier(
          this.content,
          this.pointA,
          this.controlPointA,
          this.controlPointB,
          this.pointB,
        );
      }
    } else {
      this.drawConnectedBezier(isSolid);
    }
  }

  drawConnectedBezier(isSolid) {
    this.calculateBezierSegments();

    let alpha = 1;
    let diameter = 7;
    // DRAW bezier lines
    for (let i = 0; i < this.bezierSegments.length; i++) {
      const b = this.bezierSegments[i];
      if (isSolid) {
        LineDrawHelper.drawBezier(this.content, b.pointA, b.controlA, b.controlB, b.pointB);
      } else {
        LineDrawHelper.drawDashedBezier(this.content, b.pointA, b.controlA, b.controlB, b.pointB);
      }

      // Debug bezier lines
      const debugControlPoints = false;

      if (debugControlPoints) {
        this.content.lineStyle(2, 0x1d6e55, alpha);
        this.content.drawCircle(b.controlA.x, b.controlA.y, diameter);
        this.content.lineStyle(2, 0xff0000, alpha);
        this.content.drawCircle(b.controlB.x, b.controlB.y, diameter);
        alpha -= 0.18;
        diameter -= 1;
        const { width, color } = this._getLineStyle();
        this.content.lineStyle(width, color);
      }
    }
  }

  recreateBezierSegments() {
    this.bezierSegments = [];
    const n = this.breakPoints.length + 1;

    if (n <= 1) {
      // One segment is not really needed
      return;
    }

    for (let i = 0; i < n; i++) {
      const segment = new BezierSegment();
      this.bezierSegments.push(segment);
    }
  }

  calculateBezierSegments() {
    this.calculateControlPoints();

    const angle180 = Utils.deg2rad(180);
    let offsetPoint = new PIXI.Point(0, 0);
    let oppositePoint = new PIXI.Point().copyFrom(this.controlPointA);
    const curveDivision = 3;

    let i = 0;

    // Calculate bezier lines with control points
    this.walk3Point((a, b, c) => {
      // We will iterate over a triad of points ( start point , break points and end point)
      // and determine the virtual line of reflection ( as if the line was hitting a wall and was reflected)
      // Once we find the relection line ( determined by an angle )
      // we will simply place the control points to be on that line

      const directionAngle = Utils.angleAB(b, c);
      const midAngle = Utils.findAngleBetween3Points(a, b, c);
      const isNegative = midAngle < 0 ? true : false;

      const angle = directionAngle + (angle180 - midAngle) / 2;
      const d1 = Utils.distanceAB(a, b);
      const d2 = Utils.distanceAB(b, c);

      // If the mid angle is negative we need to swap
      // the shifting diraction for the control points ,
      // if not done , it causes the beziers to band in the wrong way

      const segment = this.bezierSegments[i];
      segment.controlB.copyFrom(b);
      offsetPoint.set(d1 / curveDivision, 0);
      offsetPoint.setAngle(angle + (isNegative ? 0 : angle180));
      segment.controlB.add(offsetPoint);
      segment.setPoints(a, oppositePoint, segment.controlB, b);

      oppositePoint.copyFrom(b);
      offsetPoint.set(d2 / curveDivision, 0);
      offsetPoint.setAngle(angle + (isNegative ? angle180 : 0));
      oppositePoint.add(offsetPoint);

      i++; // Iterate to the next

      if (!(c instanceof ConnectionBreakPoint)) {
        // Last bezier line , 'i' was already incremented
        // so lets update the final segment
        this.bezierSegments[i].setPoints(b, oppositePoint, this.controlPointB, c);
      }
    });
  }

  createBreakPoint(localPosition) {
    const breakPoint = new ConnectionBreakPoint(this);
    breakPoint.position = localPosition;
    return breakPoint;
  }

  addBreakPoint(breakPoint, connectionDisplay) {
    const location = this.determineBreakPointLocation(breakPoint);
    this.snapBreakPointToLine(breakPoint, location.line[0], location.line[1]);

    const commandAdd = new CommandAddBreakPoint(
      breakPoint,
      this,
      this.breakPoints,
      location.index,
      connectionDisplay,
    );
    AppSignals.commandCreated.dispatch(commandAdd);
  }

  onBreakPointsUpdated() {
    if (this.bezierSegments && this.bezierSegments.length !== this.breakPoints.length + 1) {
      this.recreateBezierSegments();
      this.recreateGhostPoints();
    }
  }

  onBreakPointsPostUpdate() {
    if (this.isSelected) {
      this.updateGhostPointPosition();
    }
  }

  snapBreakPointToLine(breakPoint, a, b) {
    //TODO get neareset from bezier curve
    if (this.isStraight()) {
      let snapPoint = Utils.getNormalFromLine(breakPoint, a, b);
      breakPoint.position = snapPoint;
    } else {
      // let normalPoint = ConnectionHelper.GetBezierNormal(pointA, controlA, controlB, pointB, fraction, DEFAULT_HIT_AREA_SHIFT);
    }
  }

  determineBreakPointLocation(breakPoint) {
    // check to see in which order it needs to enter in the array
    // and returns its position in the array (this.breakPoints)

    let index = 0;
    let smallestDistance = Number.MAX_SAFE_INTEGER;
    let line = null;

    this.walkAllLines(function (startPoint, endPoint, i) {
      const distance = Utils.getDistanceFromLine(breakPoint, startPoint, endPoint);
      if (distance < smallestDistance) {
        smallestDistance = distance;
        index = i;
        line = [startPoint, endPoint];
      }
    });

    return { index, line };
  }

  walkAllLines(callback) {
    if (this.breakPoints.length > 0) {
      const lastIndex = this.breakPoints.length - 1;
      callback(this.pointA, this.breakPoints[0], 0);
      for (let i = 0; i < lastIndex; i++) {
        callback(this.breakPoints[i], this.breakPoints[i + 1], i + 1);
      }
      callback(this.breakPoints[lastIndex], this.pointB, lastIndex + 1);
    } else {
      callback(this.pointA, this.pointB, 0);
    }
  }

  walk3Point(callback) {
    let breakPoints = this.breakPoints;
    if (breakPoints.length > 0) {
      const lastIndex = breakPoints.length - 1;
      for (let i = 0; i <= lastIndex; i++) {
        let firstPoint = i === 0 ? this.pointA : this.breakPoints[i - 1];
        let midPoint = breakPoints[i];
        let lastPoint = i === lastIndex ? this.pointB : breakPoints[i + 1];
        callback(firstPoint, midPoint, lastPoint);
      }
    }
  }

  /**
   * Recalculate and redraw the line
   * @param redrawOnly - defines if we need to recalculate curve control points, nearest points etc
   */
  update(redrawOnly = false) {
    this.content.clear();
    if (redrawOnly) {
      this.drawAppropriateLine();
    } else {
      this.draw();
    }
    this.updateHeadsColor();
  }

  /**
   * Changes drawing points of the connection. For normal case - two closest control points.
   */
  updatePoints() {
    this.calculateNearesetPoints();

    this.pointA = this.nearestPoints.points.A;
    this.pointB = this.nearestPoints.points.B;

    if (this.detachedData) {
      //TODO this needs to go inside calculateNearesetPoints
      if (this.detachedData.isEndPoint) {
        this.pointB = this.detachedData.position;
      } else {
        this.pointA = this.detachedData.position;
      }
    }
  }

  calculateNearesetPoints() {
    if (this.breakPoints.length) {
      let a = null;
      let b = null;

      if (this.attachmentPointsLocation.pointA === CControlPointTypes.FLOW) {
        a = ConnectionHelper.GetIntersectionFromStaticPoint(
          this.breakPoints[0],
          this.iconA,
          Facade.viewport,
        );
      } else if (this.attachmentPointsLocation.pointA === CControlPointTypes.CENTER) {
        a = ConnectionHelper.GetNearestControlPoint(
          this.iconA,
          this.breakPoints[0],
          Facade.viewport,
        );
      } else {
        a = ConnectionHelper.GetControlPointByType(
          this.iconA,
          this.attachmentPointsLocation.pointA,
          Facade.viewport,
        );
      }

      if (this.attachmentPointsLocation.pointB === CControlPointTypes.FLOW) {
        b = ConnectionHelper.GetIntersectionFromStaticPoint(
          this.breakPoints[this.breakPoints.length - 1],
          this.iconB,
          Facade.viewport,
        );
      } else if (this.attachmentPointsLocation.pointB === CControlPointTypes.CENTER) {
        b = ConnectionHelper.GetNearestControlPoint(
          this.iconB,
          this.breakPoints[this.breakPoints.length - 1],
          Facade.viewport,
        );
      } else {
        b = ConnectionHelper.GetControlPointByType(
          this.iconB,
          this.attachmentPointsLocation.pointB,
          Facade.viewport,
        );
      }

      this.nearestPoints = {
        points: {
          A: a.nearestPoint,
          B: b.nearestPoint,
        },
        pointA: a.point,
        pointB: b.point,
      };
    } else {
      this.nearestPoints = ConnectionHelper.DetermineNearestAttachmentPoints(
        this.iconA,
        this.iconB,
        Facade.viewport,
        this.attachmentPointsLocation,
      );
    }
  }

  calculateControlPoints() {
    if (!this.nearestPoints) {
      this.updatePoints();
    }

    if (this.breakPoints.length) {
      this.controlPointA = ConnectionHelper.CalculateControlFirst(
        this.pointA,
        this.breakPoints[0],
        this.nearestPoints.pointA.type,
      );
      this.controlPointB = ConnectionHelper.CalculateControlSecond(
        this.breakPoints[this.breakPoints.length - 1],
        this.pointB,
        this.nearestPoints.pointB.type,
      );
    } else {
      this.controlPointA = ConnectionHelper.CalculateControlFirst(
        this.pointA,
        this.pointB,
        this.nearestPoints.pointA.type,
      );
      this.controlPointB = ConnectionHelper.CalculateControlSecond(
        this.pointA,
        this.pointB,
        this.nearestPoints.pointB.type,
      );

      this.curve.updatePoints(this.pointA, this.controlPointA, this.controlPointB, this.pointB);
    }
  }

  createHead() {
    const head = new PIXI.Graphics();
    this.updateHeadColor(head);
    this.addChild(head);
    head.visible = false;
    return head;
  }

  updateHeadsColor() {
    this.updateHeadColor(this.headA);
    this.updateHeadColor(this.headB);
  }

  updateHeadColor(head) {
    const { width, color } = this._getLineStyle();
    head.clear();
    head.lineStyle(width + 2, color);
    const l = 7;
    head.moveTo(-l, l);
    head.lineTo(0, 0);
    head.lineTo(l, l);
  }

  drawHeadA() {
    this.headA = this.createHead();
  }

  drawHeadB() {
    this.headB = this.createHead();
  }

  hideHeads() {
    this.headA.visible = false;
    this.headB.visible = false;
  }

  showHeadA() {
    this.headA.visible = true;
    this.arrowHeadShown = EConnectionCentered.A;
    this.updateHeadsVisibility();
  }

  showHeadB() {
    this.headB.visible = true;
    this.arrowHeadShown = EConnectionCentered.B;
    this.updateHeadsVisibility();
  }

  activateHeadA() {
    this.arrowHeadShown = EConnectionCentered.A;
    this.registerCorrectHeadDirections(EConnectionType.INCOMING, EConnectionType.OUTGOING);
    this.updateHeadsVisibility();
  }

  activateHeadB() {
    this.arrowHeadShown = EConnectionCentered.B;
    this.registerCorrectHeadDirections(EConnectionType.OUTGOING, EConnectionType.INCOMING);
    this.updateHeadsVisibility();
  }

  /**
   * Register the connection to directions specified
   * @param directionA
   * @param directionB
   * @private
   */
  registerCorrectHeadDirections(directionA, directionB) {
    this.iconA.registerConnection(directionA, this);
    this.iconB.registerConnection(directionB, this);
  }

  updateNoDataView() {
    if (this.isNoDataLine()) {
      this.footer.removeFromParent();
    } else {
      this.addChild(this.footer);
    }
  }

  setLineType(type) {
    this.lineType = type;
    this.updateFooter();
    this.update();
    this.analyticsManager.setData(null);

    this.updateNoDataView();

    window.app.needsRendering();
  }

  setDrawLineType(type) {
    if (this.breakPoints.length && this.drawLineType === EConnectionDrawLineType.STRAIGHT) {
      this.recreateBezierSegments();
    }

    this.drawLineType = type;
    this.updateFooter();
    this.update();

    if (this.isSelected) {
      this.updateGhostPointPosition();
    }

    window.app.needsRendering();
  }

  /**
   * Returns the other side of the element
   * @param element {BaseContainer}
   */
  getOtherSide(element) {
    const isAEnd = element.id === this.iconA.id;
    const isBEnd = element.id === this.iconB.id;
    let result = null;

    if (isAEnd) {
      result = this.iconB;
    }

    if (isBEnd) {
      result = this.iconA;
    }

    return result;
  }

  /**
   * Returns the data that should be send to react when showing a toolbar
   * @returns {{show: *, stepId: *, position: {x: number, y: number}, category: string}}
   * @private
   */
  _getToolbarData(show) {
    let detail = super._getToolbarData(show);
    detail.isTextShape = SharedElementHelpers.IsTextOrShapeElements(this.iconB);
    return detail;
  }

  getPositionPoint() {
    const p = new PIXI.Point();
    const bounds = this.getBounds();
    p.x = bounds.x + bounds.width / 2;
    p.y = bounds.y + bounds.height;
    return p;
  }

  getConnectionPoint(fraction) {
    if (this.isStraight()) {
      if (this.breakPoints.length) {
        return ConnectionHelper.GetLinePoint(this.pointA, this.breakPoints[0], fraction);
      } else {
        return ConnectionHelper.GetLinePoint(this.pointA, this.pointB, fraction);
      }
    } else {
      if (this.breakPoints.length) {
        let bezier = this.bezierSegments[0];
        return ConnectionHelper.GetBezierPoint(
          bezier.pointA,
          bezier.controlA,
          bezier.controlB,
          bezier.pointB,
          fraction,
        );
      } else {
        return ConnectionHelper.GetBezierPoint(
          this.pointA,
          this.controlPointA,
          this.controlPointB,
          this.pointB,
          fraction,
        );
      }
    }
  }

  /**
   * Sets hit area around the connection
   * @private
   */
  _setHitArea() {
    const debug = false; // for debug purpouse to see the boundaries

    if (this.isStraight()) {
      const bezierPolygon = this.createLineHitArea();
      const hitArea = HitAreaShapes.breakPolygon(bezierPolygon);
      this.content.hitArea = hitArea;

      if (debug) {
        this.content.drawPolygon(bezierPolygon);
        this.content.lineStyle(1, 0xff0000);
        for (let i = 0; i < hitArea.shapes.length; i++) {
          const polygon = hitArea.shapes[i];
          this.content.drawPolygon(polygon);
        }
      }
    } else {
      const hitArea = this.createBezierHitArea();
      this.content.hitArea = hitArea;

      if (debug) {
        this.content.lineStyle(1, 0xff0000);
        for (let i = 0; i < hitArea.shapes.length; i++) {
          const polygon = hitArea.shapes[i];
          this.content.drawPolygon(polygon);
        }
      }
    }
  }

  createLineHitArea() {
    let points = [];

    // Info: Math.PI/2 = 90 degrees in radians

    let lastP = null; // paralel point from the starting point

    // We gonna walk all the lines from start to finish
    // First we gonna do a single walk to calculate only the top intersection points of the paralel lines
    // Then we gonna do a second walk to calculate the intersection points of the bottom paralel lines

    let prevLine = null;
    this.walkAllLines((a, b) => {
      if (!prevLine) {
        // We calculate the paralel lines from the first line segment
        // and on one of the lines is the first starting point of the bounds , while the other will be the finishing point

        // calculate one of the paralel points
        let pl2 = this.findParalel(a, b, -Math.PI / 2, DEFAULT_HIT_AREA_SHIFT);
        points.push(pl2[0]);

        // save the other one , as it needs to be insert as last one
        lastP = this.findParalel(a, b, Math.PI / 2, DEFAULT_HIT_AREA_SHIFT);
      } else {
        // Then when we get to the 2nd line segment , we calcualte paralel lines from the previous line and the current line

        let pl1 = this.findParalel(prevLine[0], prevLine[1], -Math.PI / 2, DEFAULT_HIT_AREA_SHIFT);
        let pl2 = this.findParalel(a, b, -Math.PI / 2, DEFAULT_HIT_AREA_SHIFT);

        // Then we get the intersection point
        let intersection = this.checkLineIntersection(
          pl1[0].x,
          pl1[0].y,
          pl1[1].x,
          pl1[1].y,
          pl2[0].x,
          pl2[0].y,
          pl2[1].x,
          pl2[1].y,
        );
        if (intersection) {
          // Then we calculate if maybe we want a line that is closer as the intersection point can happen very far from the actuall line.
          // that is why we call it stop point , as we don't want to go any further away.
          this.addStopPoints(points, a, pl1, pl2, intersection);
        }
      }
      prevLine = [a, b];
    });

    // in this mid step , we actually calculate the paralel lines from the last segment
    // and get their end points
    if (prevLine) {
      let pl1 = this.findParalel(prevLine[0], prevLine[1], -Math.PI / 2, DEFAULT_HIT_AREA_SHIFT);
      points.push(pl1[1]);

      let pl2 = this.findParalel(prevLine[0], prevLine[1], Math.PI / 2, DEFAULT_HIT_AREA_SHIFT);
      points.push(pl2[1]);
    }

    // On the second walk we calculate the bottom intersection points from the segments paralel lines
    prevLine = null;
    let lastMidPoints = [];
    this.walkAllLines((a, b) => {
      if (prevLine) {
        let pl1 = this.findParalel(prevLine[0], prevLine[1], Math.PI / 2, DEFAULT_HIT_AREA_SHIFT);
        let pl2 = this.findParalel(a, b, Math.PI / 2, DEFAULT_HIT_AREA_SHIFT);

        let intersection = this.checkLineIntersection(
          pl1[0].x,
          pl1[0].y,
          pl1[1].x,
          pl1[1].y,
          pl2[0].x,
          pl2[0].y,
          pl2[1].x,
          pl2[1].y,
        );
        if (intersection) {
          this.addStopPoints(lastMidPoints, a, pl1, pl2, intersection);
        }
      }
      prevLine = [a, b];
    });

    // its important to reverse their order , as we are NOT walking the lines from end to start.
    lastMidPoints.reverse();
    points = points.concat(lastMidPoints);

    if (lastP) {
      points.push(lastP[0]);
    }

    return new PIXI.Polygon(points);
  }

  addStopPoints(points, midPoint, pl1, pl2, intersection) {
    const distance = Utils.distanceAB(midPoint, intersection);

    // If the intersection point is further away from the point of intereset
    if (distance > 50) {
      // then add 2 stop points

      // point 1 calculation
      let l = distance - 30;

      let angle = Utils.angleAB(pl1[0], pl1[1]);
      let negativePoint = new PIXI.Point(0, l).setAngle(angle);
      let stopPoint = intersection.clone().sub(negativePoint);

      // The idea here is to prevent the stop point going in the oposite direction from the parelel line start.
      // We check if the length of the newly formed line (using the stop point) is greater then the length of the paralel line
      if (Utils.distanceAB(pl1[0], pl1[1]) < Utils.distanceAB(stopPoint, midPoint)) {
        // In this case we don't want our new line to be that long
        // so we just clone the start point of the paralel line to be our new stop point
        stopPoint = pl1[0].clone();
        stopPoint.x += 0.1; // nudge the point a little , so that it is not in exactly the same position
      }

      points.push(stopPoint);

      // point 2 calculation
      // the same process applies for the points on the other pralel side of the line segment

      let angle2 = Utils.angleAB(pl2[1], pl2[0]);
      let negativePoint2 = new PIXI.Point(0, l).setAngle(angle2);
      let stopPoint2 = intersection.clone().sub(negativePoint2);

      if (Utils.distanceAB(pl2[0], pl2[1]) < Utils.distanceAB(stopPoint2, midPoint)) {
        stopPoint2 = pl2[1].clone();
        stopPoint2.x += 0.1;
      }

      points.push(stopPoint2);
    } else {
      // In most of the cases we are good with the calculated intersection point
      points.push(intersection);
    }
  }

  checkLineIntersection(
    line1StartX,
    line1StartY,
    line1EndX,
    line1EndY,
    line2StartX,
    line2StartY,
    line2EndX,
    line2EndY,
  ) {
    // if the lines intersect, the result contains the x and y of the intersection (treating the lines as infinite) and booleans for whether line segment 1 or line segment 2 contain the point
    let a, b;
    let result = new PIXI.Point();
    const denominator =
      (line2EndY - line2StartY) * (line1EndX - line1StartX) -
      (line2EndX - line2StartX) * (line1EndY - line1StartY);

    if (denominator == 0) {
      if (line1EndX === line2StartX && line1EndY === line2StartY) {
        result.x = line1EndX;
        result.y = line1EndY;
      } else {
        result.x = line1StartX;
        result.y = line1StartY;
      }
      return result;
    }
    a = line1StartY - line2StartY;
    b = line1StartX - line2StartX;
    const numerator1 = (line2EndX - line2StartX) * a - (line2EndY - line2StartY) * b;
    const numerator2 = (line1EndX - line1StartX) * a - (line1EndY - line1StartY) * b;
    a = numerator1 / denominator;
    b = numerator2 / denominator;

    // if we cast these lines infinitely in both directions, they intersect here:
    result.x = line1StartX + a * (line1EndX - line1StartX);
    result.y = line1StartY + a * (line1EndY - line1StartY);

    return result;
  }

  lineIntersect(a, b) {
    a.m = (a[0].y - a[1].y) / (a[0].x - a[1].x); // slope of line 1
    b.m = (b[0].y - b[1].y) / (b[0].x - b[1].x); // slope of line 2
    return a.m - b.m < Number.EPSILON
      ? undefined
      : {
          x: (a.m * a[0].x - b.m * b[0].x + b[0].y - a[0].y) / (a.m - b.m),
          y: (a.m * b.m * (b[0].x - a[0].x) + b.m * a[0].y - a.m * b[0].y) / (b.m - a.m),
        };
  }

  findParalel(a, b, radians, offset) {
    let angle = Utils.angleAB(a, b);
    let offsetPoint = new PIXI.Point(offset, 0).setAngle(angle + radians);
    let c = new PIXI.Point().copyFrom(a).add(offsetPoint);
    let d = new PIXI.Point().copyFrom(b).add(offsetPoint);

    c.x = roundTo(c.x, 2);
    c.y = roundTo(c.y, 2);
    d.x = roundTo(d.x, 2);
    d.y = roundTo(d.y, 2);

    return [c, d];
  }

  createBezierHitArea() {
    let points = [];

    // a normal point from the curve is a point that stands at some distance ( offset )
    // that is normal to the tangent at that point ,
    // https://pomax.github.io/bezierjs/#normal
    // https://en.wikipedia.org/wiki/Tangent

    // if we get the normals from the both sides of the curve at some distance
    // we can create a nice polygon wrapping the curve

    if (this.breakPoints.length) {
      let shapes = [];

      for (let i = 0; i < this.bezierSegments.length; i++) {
        let points = [];
        const segment = this.bezierSegments[i];

        this.setBezierNormals(
          segment.pointA,
          segment.controlA,
          segment.controlB,
          segment.pointB,
          points,
        );

        this.setBezierNormals(
          segment.pointB,
          segment.controlB,
          segment.controlA,
          segment.pointA,
          points,
        );

        const bezierPolygon = new PIXI.Polygon(points);
        const areaShape = HitAreaShapes.breakPolygon(bezierPolygon);
        shapes = shapes.concat(areaShape.shapes);
      }

      return new HitAreaShapes(shapes);
    } else {
      this.setBezierNormals(
        this.pointA,
        this.controlPointA,
        this.controlPointB,
        this.pointB,
        points,
      );
      this.setBezierNormals(
        this.pointB,
        this.controlPointB,
        this.controlPointA,
        this.pointA,
        points,
      );
    }

    const bezierPolygon = new PIXI.Polygon(points);

    return HitAreaShapes.breakPolygon(bezierPolygon);
  }

  setBezierNormals(pointA, controlA, controlB, pointB, points) {
    for (let fraction = 0; fraction <= 1; fraction += DEFAULT_HIT_AREA_STEP) {
      let normalPoint = ConnectionHelper.GetBezierNormal(
        pointA,
        controlA,
        controlB,
        pointB,
        fraction,
        DEFAULT_HIT_AREA_SHIFT,
      );

      // If the normal can't be calculated , then reject that point
      if (Number.isNaN(normalPoint.x) || Number.isNaN(normalPoint.y)) {
        continue;
      }

      points.push(normalPoint);
    }
  }

  canToogleType() {
    if (
      SharedElementHelpers.IsTextOrShapeElements(this.iconA) ||
      SharedElementHelpers.IsTextOrShapeElements(this.iconB) ||
      SharedElementHelpers.IsMisc(this.iconA) ||
      SharedElementHelpers.IsMisc(this.iconB)
    ) {
      return false;
    }
    return true;
  }

  getIsLocked() {
    const reportView = this.delegate.reportView;
    if (reportView) {
      const isInsideReport =
        reportView.containtsObject(this.iconA) || reportView.containtsObject(this.iconB);
      const isLocked = reportView.isLocked && isInsideReport;
      return isLocked;
    }
  }

  updateFooter() {
    this.footer.updateBlocks(
      this.isNoDataLine(),
      MainStorage.isNumbersVisible() || MainStorage.isForecastingVisible(),
    );
  }

  isNoDataLine() {
    return this.lineType === EConnectionLineType.NODATA;
  }

  /**
   * Returns the style of the line depending on the current line type
   * @returns {{color: number, width: number}}
   * @private
   */
  _getLineStyle() {
    const color = this.isFlowMode ? this.flowData.color : COLOR_CONNECTION_LINE;
    return { width: DEFAULT_CONNECTION_LINE_WIDTH, color };
  }

  getState() {
    const data = {};

    data.ID = this.id;
    data.iconA_ID = this.iconA.id;
    data.iconB_ID = this.iconB.id;
    data.category = this.category;

    data.headAVisible = this.headA.visible;
    data.headAAngle = this.headA.angle;
    data.headBVisible = this.headB.visible;
    data.headBAngle = this.headB.angle;
    data.lineType = this.lineType;
    data.drawLineType = this.drawLineType;
    data.breakPoints = [];
    data.attachmentPointsLocation = this.attachmentPointsLocation;
    for (let i = 0; i < this.breakPoints.length; i++) {
      const breakPoint = this.breakPoints[i];
      data.breakPoints.push({
        x: breakPoint.x,
        y: breakPoint.y,
        isSpecial: breakPoint.connectionDisplay ? true : false,
      });
    }

    if (this.lineType === EConnectionLineType.DOTTED) {
      data.ignoreInBetween = true;
    }

    data.goal = this.goal;
    data.forecastingData = this.forecastingData;

    this.stateData = data;

    return data;
  }

  getStateWithAnalyticsData() {
    this.getState();
    let analyticsData = null;

    if (this.analyticsManager) {
      analyticsData = this.analyticsManager.data;
    }

    return {
      ...this.stateData,
      analyticsData,
      iconAState: this.iconA.getStateWithAnalyticsData(),
      iconBState: this.iconB.getStateWithAnalyticsData(),
    };
  }

  updateForecastingData(data) {
    super.updateForecastingData(data);
    if (this.forecastingData === null) {
      this.forecastingData = { percent: 100 };
    }
  }

  /////////////////////////////////////////////////////////////////////
  //////////////////////// EVENT HANDLERS

  onPointerOut(e) {
    if (this.delegate && this.delegate.onConnectionPointerOut) {
      this.delegate.onConnectionPointerOut(e, this);
    }
  }

  onPointerDown(e) {
    this.isPointerDown = true;
    if (this.delegate && this.delegate.onConnectionPointerDown) {
      this.delegate.onConnectionPointerDown(e, this);
    }
  }

  onPointerMove(e) {
    if (this.isPointerDown && this.delegate && this.delegate.onConnectionPointerMove) {
      this.delegate.onConnectionPointerMove(e, this);
    }
  }

  onPointerUp(e) {
    if (this.delegate && this.delegate.onConnectionPointerUp) {
      this.delegate.onConnectionPointerUp(e, this);
    }
    this.isPointerDown = false;
  }

  onPointerUpOutside(e) {
    if (this.isPointerDown) {
      this.onPointerUp(e);
    }
  }

  saveReferentPoint() {
    for (let i = 0; i < this.breakPoints.length; i++) {
      const breakPoint = this.breakPoints[i];
      breakPoint.savePosition();
    }
  }

  onMoveObject(data) {
    if (data === this.iconA || data === this.iconB) {
      if (this.isShifting) {
        this.isShifting = false;
        let frozenData = data.getFrozenData();
        let dx = data.x - frozenData.x || 0;
        let dy = data.y - frozenData.y || 0;

        for (let i = 0; i < this.breakPoints.length; i++) {
          const breakPoint = this.breakPoints[i];
          breakPoint.x = breakPoint.lastPosition.x + dx;
          breakPoint.y = breakPoint.lastPosition.y + dy;
        }
      }
      this.update();
      AppSignals.connectionMoved.dispatch(this);
      if (this.isSelected) {
        this.updateGhostPointPosition();
      }
    }
  }

  onElementScaleChanged(data) {
    const element = this.getSmallerElement();
    this.adjustAnalyticsDisplayScale(element);
  }

  adjustAnalyticsDisplayScale(targetElement) {
    const targetScale = Utils.clamp(targetElement.scale.x, MIN_ALLOWED_SCALE, 1);
    const targetNewScale = Utils.map(
      targetScale,
      MIN_ALLOWED_SCALE,
      1,
      MIN_DISPALY_SCALE,
      MAX_DISPALY_SCALE,
    );
    this.footer.scale.set(targetNewScale);
  }

  getTargetElement() {
    return this.iconB;
  }

  getSmallerElement() {
    return this.iconA.scale.x < this.iconB.scale.x ? this.iconA : this.iconB;
  }

  isTargetElement(element) {
    return element === this.getTargetElement();
  }

  // BreakPoints Delegates

  onBreakPointMoving() {
    this.update();
    this.updateGhostPointPosition();
    this.updateFlowPoints();
    window.app.needsRendering();

    this.sendPositionForToolbar(false);
  }

  onBreakPointFinishedMoving(e, breakPoint) {
    const commandMove = new CommandMoveBreakPoint(breakPoint, this, breakPoint.startPosition);
    AppSignals.commandCreated.dispatch(commandMove);

    this.sendPositionForToolbar(true);
  }

  onBreakPointDoubleClick(e, breakPoint) {
    e.stopPropagation();
    this.deleteBreakPoint(breakPoint);
  }

  // Implements ConnectionDisplay delegate
  onConnectionDisplayStartedMoving(event, connectionDisplay) {
    if (!connectionDisplay.breakPoint) {
      const breakPoint = this.createBreakPoint(connectionDisplay.position);
      this.addBreakPoint(breakPoint, connectionDisplay);
    }

    connectionDisplay.breakPoint.savePosition();
    window.app.needsRendering();
  }

  // Implements ConnectionDisplay delegate
  onConnectionDisplayMoving(event, connectionDisplay) {
    if (connectionDisplay.breakPoint) {
      connectionDisplay.breakPoint.x = connectionDisplay.x;
      connectionDisplay.breakPoint.y = connectionDisplay.y;
      this.update();
      this.updateGhostPointPosition();
      this.updateFlowPoints();
      window.app.needsRendering();
    }
  }

  // Implements ConnectionDisplay delegate
  onConnectionDisplayFinishedMoving(event, connectionDisplay) {
    if (connectionDisplay.breakPoint) {
      const breakPoint = connectionDisplay.breakPoint;
      const commandMove = new CommandMoveBreakPoint(breakPoint, this, breakPoint.startPosition);
      AppSignals.commandCreated.dispatch(commandMove);
      window.app.needsRendering();
    }
  }

  // Implements ConnectionDisplay delegate
  onConnectionDisplayDoubleClicked(event, connectionDisplay) {
    if (connectionDisplay.breakPoint) {
      this.deleteBreakPoint(connectionDisplay.breakPoint);
    }
  }

  // Implements ConnectionDisplay delegate
  onConnectionDisplayPointerDown(event, connectionDisplay) {
    if (this.delegate && this.delegate.onConnectionPointerDown) {
      this.delegate.onConnectionPointerDown(event, this);
    }
    this.saveReferentPoint();
  }

  // Implements ConnectionDisplay delegate
  onConnectionDisplayPointerMove(e, connectionDisplay) {
    // Scenario 1
    // When draggind the display box , it needs to move as a break point
    // when only the connection is selected

    // Scenario 2
    // When draggind the display box , it will move the entire selection
    // if both ends of the display box are selected

    // Scenario 3
    // If one end of the selection box is in the selection
    // then it needs to move the box and the selection at the same time

    if (this.isInMultiSelection) {
      if (this.iconA.isSelected && this.iconB.isSelected) {
        // Scenario 2
        // Don't stop the propagation
        // and the entiere selection will be moved
      } else if (this.iconA.isSelected || this.iconB.isSelected) {
        // Scenario 3
        // also move all the breakpoints with it
        this.isShifting = true;
        if (this.iconA.isSelected) {
          this.onMoveObject(this.iconA);
        } else if (this.iconB.isSelected) {
          this.onMoveObject(this.iconB);
        }

        if (this.delegate && this.delegate.onConnectionDisplayDragged) {
          this.delegate.onConnectionDisplayDragged(e, connectionDisplay);
        }
      }
    } else {
      // This is Scenario 1
      connectionDisplay.moveDisplay(e);
      if (this.delegate && this.delegate.onConnectionDisplayDragged) {
        this.delegate.onConnectionDisplayDragged(e, connectionDisplay);
      }
      e.stopPropagation();
    }
  }

  // Implements ConnectionDisplay delegate
  onConnectionDisplayPointerUp(event, connectionDisplay) {
    if (this.delegate && this.delegate.onConnectionPointerUp) {
      this.delegate.onConnectionPointerUp(event, this);
    }
    this.isPointerDown = false;
  }

  deleteBreakPoint(breakPoint) {
    const commandDelete = new CommandDeleteBreakPoint(breakPoint, this, this.breakPoints);
    AppSignals.commandCreated.dispatch(commandDelete);
    this.recreateGhostPoints();
  }

  onSelected(numberOfObjectsInSelection) {
    super.onSelected(numberOfObjectsInSelection);
    this.selectionPointA.show();
    this.selectionPointB.show();
    this.content.alpha = 0.5;
    this.showGhostBreakPoints();
    this.updateGhostPointPosition();
    this.showBreakPoints();
    this.footer.onConnectionSelected();
    if (numberOfObjectsInSelection > 1) {
      this.isInMultiSelection = true;
    } else {
      this.isInMultiSelection = false;
    }

    if (MainStorage.getCanvasPermissions().isReadonlyAccess) {
      this.selectionPointA.hide();
      this.selectionPointB.hide();
      this.hideGhostBreakPoints();
      this.hideBreakPoints();
    }
  }

  onDeselected(numberOfObjectsInSelection) {
    super.onDeselected(numberOfObjectsInSelection);
    this.selectionPointA.hide();
    this.selectionPointB.hide();
    this.content.alpha = 1;
    this.hideGhostBreakPoints();
    this.hideBreakPoints();
    this.footer.onConnectionDeselected();
    this.isInMultiSelection = false;
  }

  onSelectionTypeChanged(type) {
    if (type === SELECTION_TYPE_SINGLE) {
      this.showAllBreakPoints();
      this.isInMultiSelection = false;
    } else if (type === SELECTION_TYPE_MULTI) {
      this.hideAllBreakPoints();
      this.isInMultiSelection = true;
    }
  }

  onSelectionRedraw() {
    this.footer.updateSelectionBox();
  }

  onDestroy() {
    this.detach();

    this.removeChild(this.headB);
    this.removeChild(this.headA);
    this.iconA = null;
    this.iconB = null;

    this.sendPositionForToolbar(false);
    this.destroy();
  }

  detach() {
    this.content.removeAllListeners();
    BaseSignals.moveObject.remove(this.onMoveObject, this);
    AppSignals.elementScaleChanged.remove(this.onElementScaleChanged, this);

    if (SharedElementHelpers.IsStep(this.iconA)) {
      this.iconA.unregisterConnection(this);
    }

    if (SharedElementHelpers.IsStep(this.iconB)) {
      this.iconB.unregisterConnection(this);
    }
  }

  attach() {
    if (this._selectable) {
      this.content
        .on('pointerdown', this.onPointerDown.bind(this))
        .on('pointermove', this.onPointerMove.bind(this))
        .on('pointerup', this.onPointerUp.bind(this))
        .on('pointerupoutside', this.onPointerUpOutside.bind(this));
    }

    BaseSignals.moveObject.add(this.onMoveObject, this);
    AppSignals.elementScaleChanged.add(this.onElementScaleChanged, this);

    if (this.connectionTypeSource && this.connectionTypeSource === 'in') {
      this.activateHeadA();
    } else {
      this.activateHeadB();
    }
  }

  onConnectionAttachPointDown(e) {
    if (MainStorage.getCanvasPermissions().isReadonlyAccess) {
      return;
    }

    if (this.delegate && this.delegate.onConnectionAttachPointDown) {
      const isEndPoint = this.selectionPointB === e.target;
      this.delegate.onConnectionAttachPointDown(e, this, e.target, isEndPoint);
    }
  }

  moveConnectionHead() {
    this.update();
    window.app.needsRendering();
  }

  // DELEGATE Handler GhostBreakPoint
  onGhostBreakPointDown(e, ghostBreakPoint) {
    if (MainStorage.getCanvasPermissions().isReadonlyAccess) {
      return;
    }

    if (this.hasReachedBreakPointLimit()) {
      return;
    }

    ghostBreakPoint.removeFromParent();
    this.ghostBreakPoints.removeElement(ghostBreakPoint);

    let bp = this.createBreakPoint(ghostBreakPoint.position);

    this.addBreakPoint(bp);
    bp.onPointerDown(e);

    this.recreateGhostPoints();

    app.needsRendering();
  }

  hasReachedBreakPointLimit() {
    return this.breakPoints.length >= BREAK_POINTS_LIMIT;
  }

  recreateGhostPoints() {
    this.hideGhostBreakPoints();
    if (this.isSelected) {
      this.showGhostBreakPoints();
      this.updateGhostPointPosition();
    }
  }

  showGhostBreakPoints() {
    if (!this.canShowBreakPoints) {
      return false;
    }

    if (MainStorage.getCanvasPermissions().isReadonlyAccess) {
      return;
    }

    if (this.hasReachedBreakPointLimit()) {
      return;
    }

    const n = this.breakPoints.length + 1;

    for (let i = 0; i < n; i++) {
      this.addGhostPoint();
    }

    window.app.needsRendering();
  }

  updateGhostPointPosition(
    isNumberVisible = MainStorage.isNumbersVisible() || MainStorage.isForecastingVisible(),
  ) {
    if (this.ghostBreakPoints.length) {
      // Handle the ghost point behind the info display box
      this.ghostBreakPoints[0].visible = true;
      if (isNumberVisible) {
        if (!this.footer.breakPoint) {
          this.ghostBreakPoints[0].visible = false;
        }
      }

      if (this.isNoDataLine()) {
        this.ghostBreakPoints[0].visible = true;
      }

      if (this.isStraight()) {
        let i = 0;
        this.walkAllLines((a, b) => {
          const p = ConnectionHelper.GetLinePoint(a, b, 0.5);
          this.ghostBreakPoints[i++].position = p;
        });
      } else {
        if (this.bezierSegments === null || this.bezierSegments.length <= 1) {
          const p = ConnectionHelper.GetBezierPoint(
            this.pointA,
            this.controlPointA,
            this.controlPointB,
            this.pointB,
            0.5,
          );
          this.ghostBreakPoints[0].position = p;
        } else {
          for (let i = 0; i < this.bezierSegments.length; i++) {
            const s = this.bezierSegments[i];
            const p = ConnectionHelper.GetBezierPoint(
              s.pointA,
              s.controlA,
              s.controlB,
              s.pointB,
              0.5,
            );
            this.ghostBreakPoints[i].position = p;
          }
        }
      }
    }
  }

  addGhostPoint(p) {
    if (MainStorage.getCanvasPermissions().isReadonlyAccess) {
      return;
    }
    const ghostBreakPoint = new GhostBreakPoint(this);
    if (p) {
      ghostBreakPoint.position = p;
    }
    this.addChild(ghostBreakPoint);
    this.ghostBreakPoints.push(ghostBreakPoint);
  }

  hideGhostBreakPoints() {
    for (let i = 0; i < this.ghostBreakPoints.length; i++) {
      const gbp = this.ghostBreakPoints[i];
      gbp.removeFromParent();
    }
    this.ghostBreakPoints = [];
    window.app.needsRendering();
  }

  showBreakPoints() {
    if (!this.canShowBreakPoints) {
      return false;
    }

    if (MainStorage.getCanvasPermissions().isReadonlyAccess) {
      this.hideAllBreakPoints();
      return false;
    }

    for (let i = 0; i < this.breakPoints.length; i++) {
      const breakPoint = this.breakPoints[i];
      breakPoint.visible = true;
      if (
        breakPoint.connectionDisplay &&
        this.isSelected &&
        (MainStorage.isNumbersVisible() || MainStorage.isForecastingVisible()) &&
        !this.isNoDataLine()
      ) {
        breakPoint.visible = false;
      }
    }
  }

  hideBreakPoints() {
    for (let i = 0; i < this.breakPoints.length; i++) {
      const breakPoint = this.breakPoints[i];
      breakPoint.visible = false;
    }
  }

  isConnectionSelected() {
    return this.isSelected;
  }

  showAllBreakPoints() {
    this.canShowBreakPoints = true;
    this.showBreakPoints();
    this.recreateGhostPoints();
  }

  hideAllBreakPoints() {
    this.canShowBreakPoints = false;
    this.hideBreakPoints();
    this.hideGhostBreakPoints();
  }

  calculateLength() {
    if (this.isStraight()) {
      this.length = 0;
      this.walkAllLines((a, b) => {
        this.length += Utils.distanceAB(a, b);
      });
    } else {
      if (this.breakPoints.length === 0) {
        this.length = ConnectionHelper.GetLength(
          this.pointA,
          this.controlPointA,
          this.controlPointB,
          this.pointB,
        );
      } else {
        this.length = 0;
        for (let i = 0; i < this.bezierSegments.length; i++) {
          const segment = this.bezierSegments[i];
          segment.calculateLength();
          this.length += segment.length;
        }
      }
    }
  }

  updateFlowPoints() {
    this.calculateLength();
    for (let i = 0; i < this.flowData.points.length; i++) {
      const flowPoint = this.flowData.points[i];
      flowPoint.setDistanceToTravel(this);
    }
  }

  setFlowMode(isFlowMode) {
    this.isFlowMode = isFlowMode;
    if (!this.isFlowMode) {
      this.flowData.color = COLOR_CONNECTION_LINE;
    }
    this.update();
    window.app.needsRendering();
  }

  getContentBounds() {
    if (this.footer && this.footer.visible) {
      return this.footer.getBounds();
    }

    return null;
  }
}
