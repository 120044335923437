import * as PIXI from 'pixi.js';
import Styles, { COLOR_WIDGET_TEXT, COLOR_WIDGET_TEXT_SELECTED } from '../Styles';
import TableRow from './TableView/TableRow';
import { truncate } from 'lodash';
import { roundTo } from 'shared/CSharedMethods';

export default class TableRowTrends extends TableRow {
  constructor(headerData, rowSize, columnSpacing = 0) {
    super(headerData, rowSize, columnSpacing);
  }

  initRow() {
    super.initRow();

    this.delegate = null;

    this._id = null;
    this.index = 0;
    this.isSelected = false;
    this.isMouseDown = false;
    this.reverseZebra = true; // starts with the colored row

    this.badge = PIXI.Sprite.from(PIXI.utils.TextureCache['compare_circle']);
    this.badge.anchor.set(0.5, 0.5);
    this.badge.scale.set(0.5);
    this.addChild(this.badge);

    this.titleLabelStyle = new PIXI.TextStyle(Styles.WIDGET_LABEL);

    this.cells = [];

    for (let i = 0; i < this.headerData.length - 1; i++) {
      const cell = new PIXI.BitmapText('', Styles.WIDGET_LABEL);
      cell.tint = COLOR_WIDGET_TEXT;
      cell.anchor.y = 0.5;
      this.cells.push(cell);
      this.addChild(cell);
    }

    // the last cell
    const cell = PIXI.Sprite.from(PIXI.utils.TextureCache['icon-trashcan']);
    cell.anchor.set(0.5, 0.5);
    cell.interactive = true;
    cell.buttonMode = true;
    cell.on('pointerup', this.onActionClicked.bind(this));
    this.cells.push(cell);
    this.addChild(cell);
  }

  onActionClicked() {
    if (this.index === 0) {
      // mutate the data
      // set the last cell to have the correct texture
      this.data.isAllTrackedVisible = !this.data.isAllTrackedVisible;
      PIXI.utils.TextureCache[this.data.isAllTrackedVisible ? 'icon-eye' : 'icon-eye-closed'];
      this.delegate.delegate.onToggleAllStepsClicked(this.data);
      return;
    }

    // The row delegates to the table , which delegates to the widget
    this.delegate.delegate.onDeleteStepClicked(this.data);
  }

  bindData(data) {
    this.data = data;
    this.visible = true;

    this.badge.tint = data.color;

    if (this.index === 0) {
      // set the last cell to have a texture of a eye icon
      this.cells[this.cells.length - 1].texture =
        PIXI.utils.TextureCache[data.isAllTrackedVisible ? 'icon-eye' : 'icon-eye-closed'];
    }

    // set the text of the first label , but wrapped
    const textTruncated = truncate(data.label, { length: 45 });
    const textMetrics = PIXI.TextMetrics.measureText(textTruncated, this.titleLabelStyle);
    const text = textMetrics.lines.join('\n');
    this.cells[0].text = text;

    let sum = 0;
    let hasData = true;

    for (let i = 0; i < data.hits.length; i++) {
      const cellData = data.hits[i];

      if (cellData === null) {
        hasData = false;
        this.cells[i + 2].text = '/';
      } else {
        this.cells[i + 2].text = cellData;
      }

      sum += cellData;
    }

    if (hasData) {
      this.cells[1].text = roundTo(data.averageHits, 2);
    } else {
      this.cells[1].text = '/';
    }

    this.updatePositions();
  }

  updatePositions() {
    const rh = this.rowSize.height;
    let cellX = this.columnSpacing;

    this.badge.x = this.columnSpacing + this.badge.width / 2;
    this.badge.y = rh / 2;

    for (let i = 0; i < this.cells.length; i++) {
      const cell = this.cells[i];
      this.alignElementsInCell(
        [cell],
        cellX,
        this.headerData[i].calculatedWidth,
        rh,
        0,
        this.headerData[i].align,
      );
      cellX += this.headerData[i].calculatedWidth + this.columnSpacing;
    }

    this.cells[0].x += this.badge.width + 5; // shift the first cell to the right to align with the badge
  }
}
