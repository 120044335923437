import React from 'react';
import PeopleTrackingPopup from 'react-project/components/popUp/peopleTrackingPopup/PeopleTrackingPopup';
import UtmInfoPopup from 'react-project/components/popUp/utmInfoPopup/UtmInfoPopup';

export default function PopupWindows() {
  return (
    <>
      <UtmInfoPopup />
      <PeopleTrackingPopup />
    </>
  );
}
