import * as PIXI from 'pixi.js';
import { EElementCategories, EElementTypes } from 'shared/CSharedCategories';
import BaseContainer, {
  CONTENT_HIT_AREA_PADDING,
} from 'pixi-project/base/containers/BaseContainer';
import { EConnectionType } from 'pixi-project/base/joint/CConnectionConstants';
import SharedElementHelpers from 'shared/SharedElementHelpers';
import isEqual from 'lodash/isEqual';
import Signals from 'pixi-project/signals/AppSignals';
import AnalyticsStepManager from 'pixi-project/core/AnalyticsStepManager';
import { ActionTypes, IntegrationTypes } from 'shared/CSharedConstants';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { PR_STEP_TEXTURE_UPDATED } from 'shared/CSharedEvents';
import AppSignals from 'pixi-project/signals/AppSignals';
import { getAuthToken } from 'react-project/Util/AuthCookie';
import { getUpdatedPhotoPath } from 'react-project/Util/urlPath';
import MainStorage from 'pixi-project/core/MainStorage';
import { LayerType } from 'react-project/LayersMenu/LayerType';

const THUMBNAIL_POSITION = {
  x: 5,
  y: 55,
};
const BLANK_TEXTURE_NAME = 'StepsModal/Pages/blank.png';
const BLANK_TEXTURE_INTERNAL_SPACE_SIZE = {
  width: 390,
  height: 457,
};

export default class StepContainer extends BaseContainer {
  constructor(type, label, texture, eventHandlers, id, value = '0') {
    super(eventHandlers, id);
    this.titleText = label;
    this.texture = texture;
    this.texturePath = '';
    this.useThumbnail = false;
    this.thumbnailURL = '';
    this.thumbnail = null;
    this.category = EElementCategories.STEP;
    this.type = type;
    this.actionType = ActionTypes.NONE;
    this.integrationType = IntegrationTypes.NONE;
    this.actionName = null;
    this.isFocused = false;
    this.focusFilterTypes = [];
    this.integrationAttributes = [];
    this.isCustom = false;
    this.isCustomIconFetched = false;
    this.notes = null;
    this.checklistData = null;

    this.incomingConnections = [];
    this.outgoingConnections = [];

    this.footer.canHaveValue = this.type !== EElementTypes.MISC;
    this.footer.hasLine = true;
    this.footer.isStepFooter = true;
    this.analyticsManager = new AnalyticsStepManager(this, this.footer);

    // Page filters
    this._filterData = [];

    // URL on tracking page
    this.trackingURL = '';
  }

  init() {
    this.draw(this.texture);
    this.cursor = 'move';

    if (this.isCustom) {
      // on Every load we need to featch the latest image path
      // from the icon list , because url will expire after X amount of time.
      // We do that by using the id that is contained in the image path
      AppSignals.fetchCustomIcons.dispatch(this);
    }

    if (this.footer.canHaveValue) {
      this.footer.interactive = true;
      this.footer.buttonMode = true;
      this.footer.cursor = 'default';
    } else {
      this.removeChild(this.footer);
    }

    this.titleDisplay.pushToBack();
    this.updateFrameSize();
  }

  onAnalyticsProcessingDone() {
    // Make sure the footer is behind the step icon
    this.footer.pushToBack();
    this.titleDisplay.pushToBack();
    window.app.needsRendering();
  }

  onCustomIconsLoaded(customIcons) {
    const iconData = { path: this._getUpdatedTexturePath(this.texturePath) };

    if (iconData) {
      if (!this.isCustomIconFetched) {
        this.texturePath = iconData.path;
        this.isCustomIconFetched = true;

        // load the latest image
        this.loadThumbnail(this.texturePath, (texture) => {
          const isValid = !this.checkIfTextureIsAboveWebGLLimit(texture);

          if (!isValid) {
            texture = this.donwsizeImageToTextureSize(texture);
          }

          this.content.texture = texture;

          if (this.legacySize) {
            const newSize = this.fitInto(
              texture.width,
              texture.height,
              this.legacySize.width,
              this.legacySize.height,
            );

            this.content.width = newSize.width;
            this.content.height = newSize.height;

            // This is a one time calculation
            // that will happen only when we migrate elements from legacy 1.0 to 2.0
            // We are going to offset the elements based on their real size
            if (this.isMigratedFromLegacy) {
              this.x += (this.legacySize.width - newSize.width) / 2;
              this.y += (this.legacySize.height - newSize.height) / 2;
              this.isMigratedFromLegacy = false;
            }
          }

          if (this.analyticsManager) {
            this.analyticsManager.view.renderBox();
          }

          this.updateFrameSize();
          window.app.needsRendering();
          commonSendEventFunction(PR_STEP_TEXTURE_UPDATED, this);
        });
      }
    } else {
      console.warn('Custom icon is not found', this.texturePath);
    }
  }

  fitInto(width, height, maxWidth, maxHeight) {
    const ratio = Math.min(maxWidth / width, maxHeight / height);
    return { width: width * ratio, height: height * ratio };
  }

  /**
   * If an image is not supported by WebGL and it exceeds the texture size
   * The image will be downsized to fit the texture size
   */
  donwsizeImageToTextureSize(texture) {
    const size = window.app.renderer.gl.getParameter(window.app.renderer.gl.MAX_TEXTURE_SIZE);
    const width = texture.width;
    const height = texture.height;

    if (width > size || height > size) {
      const ratio = width / height;
      let newWidth;
      let newHeight;

      if (width > height) {
        newWidth = size;
        newHeight = size / ratio;
      } else {
        newWidth = size * ratio;
        newHeight = size;
      }

      const canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(texture.baseTexture.resource.source, 0, 0, newWidth, newHeight);

      const newTexture = PIXI.Texture.from(canvas);
      return newTexture;
    }
  }

  checkIfTextureIsAboveWebGLLimit(texture) {
    if (window.app.renderer.gl) {
      const size = window.app.renderer.gl.getParameter(window.app.renderer.gl.MAX_TEXTURE_SIZE);
      if (texture.width > size || texture.height > size) {
        return true;
      }
    }

    return false;
  }

  createImage(data) {
    this.content = new PIXI.Sprite(data);
    this.addChild(this.content);
    // Initial scale
    const scale = SharedElementHelpers.IsPage(this) ? 0.3 : 0.15;
    this.content.scale.set(scale);

    if (SharedElementHelpers.IsAction(this)) {
      this.badgePosition.set(-15, -15);
    } else if (SharedElementHelpers.IsSource(this)) {
      this.badgePosition.set(-15, -15);
    }

    super.createImage(data);
    this.updateHitArea();
  }

  updateHitArea() {
    const scale = (1 / this.content.scale.x) * (1 / this.scale.x);
    const bounds = this.content.getLocalBounds();
    const padding = CONTENT_HIT_AREA_PADDING * scale;
    let footerHeight = 0;
    if (this.footer && this.footer.visible) {
      footerHeight = this.footer.getFooterHeight() * scale;
    }
    this.content.hitArea = new PIXI.Rectangle(
      -padding,
      -padding,
      bounds.width + padding * 2,
      bounds.height + padding * 2 + footerHeight,
    );
  }

  getState() {
    super.getState();

    this.stateData.texturePath = this.texturePath;
    this.stateData.filterData = this.filterData;
    this.stateData.useThumbnail = this.useThumbnail;
    this.stateData.thumbnailURL = this.thumbnailURL;
    this.stateData.trackingURL = this.trackingURL;
    this.stateData.actionType = this.actionType;
    this.stateData.integrationType = this.integrationType;
    this.stateData.actionName = this.actionName;
    this.stateData.integrationAttributes = this.integrationAttributes;
    this.stateData.analyticsFilterData = this.analyticsManager.filterData;
    this.stateData.isCustom = this.isCustom;
    if (this.legacySize) {
      this.stateData.legacySize = this.legacySize;
    }
    this.stateData.notes = this.notes;
    this.stateData.checklistData = this.checklistData;

    return this.stateData;
  }

  /**
   * Create a texture of a needed size. Texture must take all available space by width.
   * Bottom part should be trimmed if it takes more space then needed.
   * @param texture
   * @returns {{innerScale: PIXI.Point, adjustedTexture: PIXI.Texture}}
   * @private
   */
  _generateThumbnailTexture(texture) {
    const innerScale = new PIXI.Point(1, 1);
    let adjustedTexture = texture;

    // Calculating scale of the thumbnail by width
    const textureWidthScale = BLANK_TEXTURE_INTERNAL_SPACE_SIZE.width / texture.width;

    // If thumbnail is too small or does not fit into internal space in a blank page
    // we adjust the image by width first
    if (textureWidthScale !== 1) {
      innerScale.x = textureWidthScale;
      // noinspection JSSuspiciousNameCombination
      innerScale.y = textureWidthScale;

      // In case after adjustment by width we still don't fit by height - we trim the image
      const textureAdjustedHeight = texture.height * textureWidthScale;
      const textureAdjustedHeightScale =
        BLANK_TEXTURE_INTERNAL_SPACE_SIZE.height / textureAdjustedHeight;
      if (textureAdjustedHeightScale < 1) {
        // Depending on if we scale the thumbnail down or up -
        // we calculate the lower border of the texture differently
        const height = Math.trunc(
          textureWidthScale < 1
            ? texture.height * textureAdjustedHeightScale
            : (texture.height * BLANK_TEXTURE_INTERNAL_SPACE_SIZE.height) / textureAdjustedHeight,
        );
        adjustedTexture = new PIXI.Texture(
          texture.baseTexture,
          new PIXI.Rectangle(0, 0, texture.width, height),
        );
      }
    }

    // noinspection JSValidateTypes
    return { adjustedTexture, innerScale };
  }

  /**
   * Instead of standard texture apply a black page one with a thumbnail of the content
   * @param thumbnailURL - URL to the content thumbnail
   * @param useThumbnail - set if we use thumbnail
   */
  setThumbnail(thumbnailURL, useThumbnail) {
    if (useThumbnail) {
      this.loadThumbnail(thumbnailURL, (texture) => {
        this.useThumbnail = useThumbnail;
        this.thumbnailURL = thumbnailURL;
        const thumbnail = this.generateThumbnail(texture);
        this.setThumbnailSprite(thumbnail);
      });
    }
  }

  loadThumbnail(thumbnailURL, callback) {
    PIXI.Texture.fromURL(thumbnailURL)
      .then(callback)
      .catch((result) => {
        if (SharedElementHelpers.IsSource(this)) {
          console.warn(`Can't load ${thumbnailURL} using default source icon`);
          this.setTexturePath('StepsModal/Sources/trafficicon.png');
        } else {
          console.log(`[StepContainer.setTexture] Error loading`, thumbnailURL);
        }
      });
  }

  setThumbnailSprite(thumbnail) {
    this.thumbnail = thumbnail;

    this.content.removeChildren();
    this.content.texture = PIXI.Loader.shared.resources[BLANK_TEXTURE_NAME].texture;
    this.content.addChild(this.thumbnail);

    commonSendEventFunction(PR_STEP_TEXTURE_UPDATED, this);
    window.app.needsRendering();
  }

  generateThumbnail(texture) {
    const { adjustedTexture, innerScale } = this._generateThumbnailTexture(texture);

    // Apply attributes to the inner thumbnail
    const thumbnailSprite = new PIXI.Sprite(adjustedTexture);
    thumbnailSprite.scale = innerScale;
    thumbnailSprite.position = new PIXI.Point(
      this.content.position.x + THUMBNAIL_POSITION.x,
      this.content.position.y + THUMBNAIL_POSITION.y,
    );
    return thumbnailSprite;
  }

  /**
   * Saves filter value to the item
   * @param filters
   */
  setFilterData(filters) {
    if (filters) {
      this.filterData = filters;
    }
  }

  setAnalyticsFilterData(filterData) {
    if (filterData) {
      this.analyticsManager.updateFilter(filterData);
    }
  }

  set filterData(value) {
    this._filterData = value;
  }

  get filterData() {
    return this._filterData;
  }

  /**
   * Saves tracking URL value to the item
   * @param trackingURL
   */
  setTrackingURL(trackingURL) {
    this.trackingURL = trackingURL;
  }

  setActionType(actionType) {
    this.actionType = Object.values(ActionTypes).includes(actionType)
      ? actionType
      : ActionTypes.NONE;
  }

  setIntegrationType(integrationType) {
    this.integrationType = integrationType;
  }

  setActionName(actionName) {
    this.actionName = actionName;
  }

  setIntegrationAttributes(attributes) {
    this.integrationAttributes = attributes;
  }

  removeThumbnail() {
    this.useThumbnail = false;
    this.thumbnailURL = '';
    this.content.removeChildren();
  }

  setTexturePath(texturePath) {
    this.removeThumbnail();

    this.texturePath = texturePath;
    this.content.setTexture(texturePath);
    commonSendEventFunction(PR_STEP_TEXTURE_UPDATED, this);
    window.app.needsRendering();
  }
  /**
   * Update values for an element
   * @param data
   */
  updateObject(data) {
    super.updateObject(data);

    if (data.filterData && !isEqual(this.filterData, data.filterData)) {
      this.setFilterData(data.filterData);
      Signals.elementChanged.dispatch();
    }

    if (data.trackingURL !== undefined && this.trackingURL !== data.trackingURL) {
      this.setTrackingURL(data.trackingURL);
      Signals.elementChanged.dispatch();
    }

    if (data.texturePath && !isEqual(this.texturePath, data.texturePath)) {
      this.setTexturePath(data.texturePath);
      commonSendEventFunction(PR_STEP_TEXTURE_UPDATED, this);
    }

    if (data.actionType && !isEqual(this.actionType, data.actionType)) {
      this.setActionType(data.actionType);
    }

    if (data.actionName && !isEqual(this.actionName, data.actionName)) {
      this.setActionName(data.actionName);
    }

    if (
      data.integrationAttributes &&
      !isEqual(this.integrationAttributes, data.integrationAttributes)
    ) {
      this.setIntegrationAttributes(data.integrationAttributes);
    }

    if (data.integrationType && !isEqual(this.integrationType, data.integrationType)) {
      this.setIntegrationType(data.integrationType);
    }
  }

  /**
   * Registers a new connection that is coming to or out of a step
   * @param type {string} - type of connection
   * @param connection {ConnectionContainer} - connection
   */
  registerConnection(type, connection) {
    switch (type) {
      case EConnectionType.INCOMING:
        this.incomingConnections.push(connection);
        break;
      case EConnectionType.OUTGOING:
        this.outgoingConnections.push(connection);
        break;
      default:
        throw Error(`[StepContainer.registerConnection] wrong connection type`);
    }
  }

  /**
   * Removes the connection from the list of incoming and outgoint connections
   * @param connection
   */
  unregisterConnection(connection) {
    this.incomingConnections = this.incomingConnections.filter((element) => {
      return element.id !== connection.id;
    });

    this.outgoingConnections = this.outgoingConnections.filter((element) => {
      return element.id !== connection.id;
    });
  }

  isConnectedTo(step) {
    if (!step.outgoingConnections || !step.incomingConnections) {
      return false;
    }

    for (let i = 0; i < this.incomingConnections.length; i++) {
      const inConnection = this.incomingConnections[i];
      for (let j = 0; j < step.outgoingConnections.length; j++) {
        const outConnection = step.outgoingConnections[j];
        if (inConnection.id === outConnection.id) {
          return true;
        }
      }
    }

    for (let j = 0; j < this.outgoingConnections.length; j++) {
      const outConn = this.outgoingConnections[j];
      for (let i = 0; i < step.incomingConnections.length; i++) {
        const inConn = step.incomingConnections[i];
        if (inConn.id === outConn.id) {
          return true;
        }
      }
    }

    return false;
  }

  hasAnalyticsData() {
    return !!(this.analyticsManager && this.analyticsManager.data);
  }

  addEvents() {
    super.addEvents();
  }

  processAnalyticsData() {
    super.processAnalyticsData();
    if (this.analyticsManager) {
      this.updateFrameSize();
    }
  }

  _getUpdatedTexturePath(imagePath) {
    if (!imagePath) {
      return imagePath;
    }

    const tokenKey = 'token';
    const accessToken = getAuthToken();

    if (!accessToken) {
      return;
    }

    let url = null;
    try {
      url = new URL(imagePath);
    } catch (error) {
      return imagePath;
    }

    url.searchParams.delete(tokenKey);
    url.searchParams.set(tokenKey, accessToken);
    url.pathname = getUpdatedPhotoPath(url.pathname);

    return url.toString();
  }

  onCheckistChanged(checklistData) {
    this.checklistData = checklistData;
    if (MainStorage.isChecklistVisible()) {
      this.titleDisplay.showChecklist();
    }
    this.refreshChecklistData();
  }

  onLoaded(data, sceneManager) {
    super.onLoaded(data, sceneManager);
    MainStorage.isNotesVisible() && this.notes ? this.showNotesBadge() : this.hideNotesBadge();

    // EXTRACT the data you need from the data object
    this.refreshChecklistData();
  }

  refreshChecklistData() {
    this.titleDisplay.updateWithChecklistData(this.checklistData);
  }

  onPaste() {
    if (MainStorage.isChecklistVisible()) {
      this.titleDisplay.showChecklist();
    } else {
      this.titleDisplay.hideChecklist();
    }
  }
}
