import isString from 'lodash/isString';
import isEqual from 'lodash/isEqual';
import replace from 'lodash/replace';
import URI from 'urijs';

const URL_WILDCARD = '*';
const ESCAPE_ASTERISK_VALUE = '%2A';
const ESCAPE_HASH_VALUE = '/%23/';
const URL_SLASH = '/';
const WWW_PREFIX_LENGTH = 4;
const PROTOCOL_SLASHES_LENGTH = 2;

const SPECIAL_CHARACTERS = [URL_SLASH, URL_WILDCARD];

/**
 * Unescapes the Angular hash if it was escaped.
 * @param {string} urlString - The URL string to unescape.
 * @return {string} The URL string with the Angular hash unescaped.
 */
function unescapeAngularHash(urlString) {
  if (urlString.indexOf(ESCAPE_HASH_VALUE) !== -1) {
    urlString = urlString.replace(ESCAPE_HASH_VALUE, '/#/');
  }
  return urlString;
}

/**
 * Normalize the given URI object.
 * @param {URI} parsed - The URI object to normalize.
 * @returns {string} - The normalized URL string.
 */
function normalizeUrl(parsed) {
  parsed.protocol('');
  parsed.search('');
  parsed.hash('');

  let result = parsed.toString().slice(PROTOCOL_SLASHES_LENGTH);
  if (result.slice(result.length - 1) !== '/' && result.slice(result.length - 1) !== URL_WILDCARD) {
    result += '/';
  }

  result = unescapeAngularHash(result);

  return result;
}

/**
 * Check if a given string is a valid URL string.
 * @param {string} string - The string to check.
 * @returns {boolean} - True if the string is a valid URL string, false otherwise.
 */
function isValidUrlString(string) {
  const specialChars = '<>@!#$%^&()_+[]{}?:;|\'"\\,./~`-=';
  for (let i = 0; i < specialChars.length; i++) {
    if (isEqual(string[0], specialChars[i])) {
      return false;
    }
  }

  return true;
}

/**
 * Clean and format the given URL.
 * @param {string} url - The URL string to clean.
 * @returns {string|null} - The cleaned URL string, or null if the input is not valid.
 */
export const cleanUrl = function (url) {
  if (!url || url === 'null' || !isString(url) || !isValidUrlString(url)) {
    return null;
  }
  let clean = url.trim();
  if (clean.search(/^http[s]?:\/\//i) === -1) {
    clean = `http://${url}`;
  }

  return clean;
};

/**
 * Check if the given URI object needs a subdomain.
 * @param {URI} parsed - The URI object to check.
 * @returns {boolean} - True if the URI object needs a subdomain, false otherwise.
 */
const needsSubdomain = (parsed) =>
  !parsed.subdomain() && normalizeUrl(parsed).slice(0, WWW_PREFIX_LENGTH) !== 'www.';

/**
 * Transform escaped characters in the given URL string.
 * @param {string} url - The URL string to transform.
 * @returns {string} - The transformed URL string.
 */
const transformEscaped = (url) => {
  const asteriskRegex = /(\\\*)/g;
  const hashRegex = /(\/\#\/)/g;
  const escapedAsteriskURL = replace(url, asteriskRegex, ESCAPE_ASTERISK_VALUE);
  // escaping angular hash
  return replace(escapedAsteriskURL, hashRegex, ESCAPE_HASH_VALUE);
};

/**
 * Parse the given URL string and return the normalized version.
 * @param {string} inputUrl - The URL string to parse.
 * @returns {string} - The parsed and normalized URL string.
 */
export const parseURL = (inputUrl) => {
  // split inputUrl by comma and parse each URL
  if (!inputUrl) return inputUrl;
  const urls = inputUrl.toString().split(',');
  const parsedUrls = urls.map((url) => parseSingleURL(url));
  return parsedUrls.join(',');
};

export const parseSingleURL = (inputUrl) => {
  if (inputUrl && inputUrl.startsWith(URL_WILDCARD)) {
    const lastUrlChar = inputUrl.slice(inputUrl.length - 1);
    if (!SPECIAL_CHARACTERS.includes(lastUrlChar)) {
      return `${inputUrl}${URL_SLASH}`;
    }
    return inputUrl;
  }
  const url = cleanUrl(inputUrl);

  // Check if cleanup was successfull
  if (!url) {
    return '';
  }

  const transformedUrl = transformEscaped(url);

  const checkSubdomainNeeded = new URI(transformedUrl);
  const parsedResult = new URI(transformedUrl);

  if (needsSubdomain(checkSubdomainNeeded)) {
    parsedResult.subdomain('www');
  }

  return normalizeUrl(parsedResult);
};

export const isURLValid = (urlString) => {
  var urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?', // validate query string
    'i',
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

export const getURLData = (url) => {
  const isValid = isURLValid(url);
  if (isValid) {
    // remove fragments from url and extract parameters
    const parsedData = {};
    // if url contains /#/ then replace it with /%23/
    const urlWithoutHash = url.replace(/\/#\//g, '/%23/');
    // now remove everything after # from url
    const urlWithoutFragment = urlWithoutHash.split('#')[0].replace(/\/%23\//g, '/#/');
    parsedData.url = urlWithoutFragment;
    parsedData.urlWithoutQueryString = urlWithoutFragment.split('?')[0];

    const paramters = [];
    const urlWithoutQuery = urlWithoutFragment.split('?');
    if (urlWithoutQuery.length > 1) {
      const query = urlWithoutQuery[1];
      const queryParameters = query.split('&');
      for (let i = 0; i < queryParameters.length; i++) {
        const parameter = queryParameters[i];
        const keyValue = parameter.split(/=(.*)/s);
        const key = keyValue[0];
        const value = keyValue[1];
        paramters.push({ key, value });
      }
      parsedData.parameters = paramters;
    }

    return parsedData;
  }

  return null;
};
