import React from 'react';
export const ExplorerActionsUpgrade = ({ onBtnClicked }) => {
  return (
    <>
      <div>
        <p>
          Revolutionize your customer journey optimization with Actions, unlocking valuable insights
          data from your users' behaviour.
        </p>
        <p>Experience the full potential of Funnelytics and supercharge your marketing success.</p>
        <p>Don't miss out! Upgrade today and discover the true power of Funnelytics!</p>
        <button onClick={onBtnClicked}>Upgrade Now</button>
      </div>
    </>
  );
};
