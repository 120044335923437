import React, { useEffect, useState } from 'react';
import styles from './copyToClipboardNotification.module.scss';
import cls from 'classnames';

export const CopyToClipboardNotification = ({ count }) => {
  const [textComponents, setTextComponents] = useState([]);

  useEffect(() => {
    if (count === 0) return;
    const newTextComponents = [...textComponents];
    newTextComponents.push(<Copied key={`c-${count}`} />);
    setTextComponents(newTextComponents);
  }, [count]);

  return <>{textComponents}</>;
};

const Copied = () => {
  return (
    <div className={cls(styles.CopySuccess, styles.MessageFadeout_2)}> Copied to Clipboard</div>
  );
};
