export const toOptions = (list, options) => {
  /**
   * @typedef {{
   *    value: string,
   *    autoFocus: boolean,
   *    className: string,
   *    disabled: boolean,
   *
   * }} option
   *
   * @param {Array.<string>} list - An array of strings to be used as the value of the options.
   * @param {Array.<option>} options (optional) - An array of option objects to be used to create the options.
   * @returns {Array.<option>} - An array of options.
   */
  if (!options) {
    return list.map((item) => {
      return {
        value: item,
        autoFocus: false,
        className: '',
        disabled: false,
      };
    });
  }
  const config = options.reduce((acc, option) => {
    if (list.includes(option.value)) {
      const { value, autoFocus, className, disabled } = option;
      acc.push({ value, autoFocus, className, disabled });
    }
    return acc;
  }, []);

  return config;
};

export default { toOptions };
