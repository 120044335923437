import * as PIXI from 'pixi.js';
import Styles, {
  COLOR_COMPARE_PERCENT_NEGATIVE,
  COLOR_COMPARE_PERCENT_NEUTRAL,
  COLOR_COMPARE_PERCENT_POSITIVE,
  COLOR_DISPLAY_SEPARATOR,
} from 'pixi-project/view/Styles';
import TextDisplay from './TextDisplay';
import { COMPARE_TEXTURE_SCALE, SEPARATOR_THICKNESS } from 'pixi-project/core/BaseAnalyticsManager';
import Utils from 'pixi-project/utils/Utils';

export default class CommonCompareMultiDisplaySegment extends PIXI.Container {
  constructor() {
    super();
    this.textDisplays = [];
    this.contentWidth = 0;
    this.compareRange = { from: 0, to: 0 };
    this.displayData = null;
    this.currency = '';
  }

  setData(displayData, goal, width) {
    this.clearDisplays();

    this.displayData = displayData;
    this.contentWidth = width;

    let y = 0;

    for (let i = 0; i < displayData.length; i++) {
      const isLastElement = i === displayData.length - 1;
      const data = displayData[i];
      const textDisplay = new TextDisplay();
      this.addChild(textDisplay);

      const styledData = [];

      styledData.push(
        { value: data[0], ...Styles.FOOTER_TITLE, wordWrap: width - 6 },
        { value: data[1], ...Styles.FOOTER_NUMBER },
        { value: data[2], ...Styles.FOOTER_COMPARE, spacing: 5 },
        {
          children: [
            { texture: data[3][0], scale: COMPARE_TEXTURE_SCALE, visible: data[3][2] !== '0' },
            { value: data[3][1], ...Styles.FOOTER_PERCENT },
          ],
          width,
          background: this.getColor(Number(data[3][2])),
          round: isLastElement ? 4 : 0,
        },
      );

      if (i !== displayData.length - 1) {
        styledData.push({
          isSeparator: true,
          color: COLOR_DISPLAY_SEPARATOR,
          thickness: SEPARATOR_THICKNESS,
          width: width,
          offsetY: -2,
        });
      }

      textDisplay.y = y;
      textDisplay.setData(styledData);
      this.textDisplays.push(textDisplay);

      y += textDisplay.areaSize.height;
    }
  }

  getColor(n) {
    if (Utils.inRange(n, this.compareRange.from, this.compareRange.to)) {
      return COLOR_COMPARE_PERCENT_NEUTRAL;
    }

    return n > this.compareRange.to
      ? COLOR_COMPARE_PERCENT_POSITIVE
      : COLOR_COMPARE_PERCENT_NEGATIVE;
  }

  onRangeUpdated() {
    if (this.displayData) {
      this.setData(this.displayData, null, this.contentWidth);
    }

    window.app.needsRendering();
  }

  clearDisplays() {
    for (let i = 0; i < this.textDisplays.length; i++) {
      const td = this.textDisplays[i];
      td.removeFromParent();
    }
    this.textDisplays = [];
  }

  resetData() {
    this.displayData = null;
  }

  getArea() {
    const size = {
      height: 0,
      width: 0,
    };
    for (let i = 0; i < this.textDisplays.length; i++) {
      const td = this.textDisplays[i];
      size.height += td.areaSize.height;
      size.width = Math.max(td.areaSize.width, size.width);
    }
    return size;
  }
}
