import BaseAnalyticsManager from 'pixi-project/core/BaseAnalyticsManager';
import { roundTo, toPercent } from 'shared/CSharedMethods';
import { numSeparator, numSeparatorRound } from 'shared/NumberHelpers';
import { TEXT_AVG_COST_VALUE, TEXT_PEOPLE, TEXT_REVENUE, TEXT_SPEND } from 'shared/CSharedTexts';
import { isGoalValid } from 'shared/ValidateHelpers';
import { shortenText } from 'shared/SharedHelpers';
import { GOAL_DEFAULT_NAME, NO_DATA_STRING } from 'shared/CSharedConstants';
import MainStorage from './MainStorage';

export default class AnalyticsStepManager extends BaseAnalyticsManager {
  constructor(controller, view, data = null) {
    super(controller, view, data);

    this.filterData = {
      dataFilter: [],
      displayFilter: {
        avgValuePerCustomer: true,
        totalCustomers: true,
        totalRevenew: true,
      },
    };
    this.view.pushToBack();
  }

  process() {
    this.view.setCurrency(this.currency);

    const isDataValid = this.verifyData(this.data);

    if (!isDataValid && MainStorage.isForecastingVisible()) {
      this.showForecasting();
      this.controller.onAnalyticsProcessingDone();
      return;
    }

    if (MainStorage.isNumbersVisible()) {
      if (!isDataValid) {
        this.view.setNoData();
        return;
      }
    }

    if (isDataValid && this.data.is_commerce && !MainStorage.isForecastingVisible()) {
      this.showDetails();
    } else if (isDataValid && this.data.is_integrated_source) {
      this.showIntegrateSourceData();
    } else {
      this.showData();
    }

    if (this.controller.onAnalyticsProcessingDone) {
      this.controller.onAnalyticsProcessingDone();
    }
  }

  verifyData(data) {
    if (!data) {
      return false;
    }

    if (data.hits !== undefined) {
      return true;
    }
    return false;
  }

  updateFilter(filterData) {
    this.filterData = filterData;
    this.process();
  }

  showIntegrateSourceData() {
    if (this.data['compare_to_data']) {
      this.showIntegrateSourceCompare();
    } else {
      const peopleNumber = this.data.hits;
      const costNumber = numSeparator(roundTo(this.data.cost, 2));
      this.view.setCommonMultiData(
        [
          [TEXT_PEOPLE, peopleNumber],
          [TEXT_SPEND, `${this.currency}${costNumber}`],
        ],
        peopleNumber,
      );
    }
  }

  showIntegrateSourceCompare() {
    const peopleNumber = this.data.hits;
    const costNumber = numSeparator(roundTo(this.data.cost, 2));

    const comparePeopleNumber = this.data['compare_to_data'].hits;
    const compareCostNumber = numSeparator(roundTo(this.data['compare_to_data'].cost, 2));

    const peoplePercent = this.parsePercentChange(comparePeopleNumber, peopleNumber);
    const costPercent = this.parsePercentChange(compareCostNumber, costNumber);

    this.view.setCommonCompareMultiData([
      [
        TEXT_PEOPLE,
        peopleNumber,
        comparePeopleNumber,
        [this.getCompareTexture(peoplePercent), toPercent(peoplePercent, 1), peoplePercent],
      ],
      [
        TEXT_SPEND,
        `${this.currency}${costNumber}`,
        `${this.currency}${compareCostNumber}`,
        [this.getCompareTexture(costPercent), `${toPercent(costPercent, 1)}`, costPercent],
      ],
    ]);
  }

  showDetails() {
    if (this.data['compare_to_data']) {
      this.showCompareDetails();
    } else {
      const revenue = this.data.total_revenue_in_cents / 100;
      const customers = this.data.hits;
      const avgValue = revenue / customers || 0;

      let displayData = [];

      if (
        this.filterData &&
        this.filterData.displayFilter &&
        this.filterData.displayFilter.totalCustomers
      ) {
        displayData.push([TEXT_PEOPLE, customers]);
      }

      if (
        this.filterData &&
        this.filterData.displayFilter &&
        this.filterData.displayFilter.totalRevenew
      ) {
        displayData.push([TEXT_REVENUE, `${this.currency}${numSeparator(roundTo(revenue, 2))}`]);
      }

      if (
        this.filterData &&
        this.filterData.displayFilter &&
        this.filterData.displayFilter.avgValuePerCustomer
      ) {
        displayData.push([
          TEXT_AVG_COST_VALUE,
          `${this.currency}${numSeparator(roundTo(avgValue, 2))}`,
        ]);
      }

      this.view.setCommonMultiData(displayData, customers);
    }
    window.app.needsRendering();
  }

  showCompareDetails() {
    const revenue = this.data.total_revenue_in_cents / 100;
    const customers = this.data.hits;
    const avgValue = revenue / customers || 0;

    const compare_revenue = this.data['compare_to_data'].total_revenue_in_cents / 100;
    const compare_customers = this.data['compare_to_data'].hits;
    const compare_avgValue = compare_revenue / compare_customers || 0;

    let revenuePercent = this.parsePercentChange(compare_revenue, revenue);
    let customersPercent = this.parsePercentChange(compare_customers, customers);
    let avgPercent = this.parsePercentChange(compare_avgValue, avgValue);

    this.view.setCommonCompareMultiData([
      [
        TEXT_PEOPLE,
        customers,
        compare_customers,
        [
          this.getCompareTexture(customersPercent),
          toPercent(customersPercent, 1),
          customersPercent,
        ],
      ],
      [
        TEXT_REVENUE,
        `${this.currency}${numSeparator(roundTo(revenue, 2))}`,
        `${this.currency}${numSeparator(roundTo(compare_revenue, 2))}`,
        [this.getCompareTexture(revenuePercent), toPercent(revenuePercent, 1), revenuePercent],
      ],
      [
        TEXT_AVG_COST_VALUE,
        `${this.currency}${numSeparator(roundTo(avgValue, 2))}`,
        toPercent(avgPercent, 1),
        [this.getCompareTexture(avgPercent), toPercent(avgPercent, 1), avgPercent],
      ],
    ]);
  }

  showForecasting() {
    if (this.controller.forecastingCalculatedData) {
      const cost =
        this.controller.forecastingCalculatedData.people !== undefined &&
        this.controller.forecastingCalculatedData.cost !== undefined
          ? `${this.currency}${numSeparator(
              roundTo(this.controller.forecastingCalculatedData.cost, 2),
            )}`
          : undefined;
      const revenue =
        this.controller.forecastingCalculatedData.people !== undefined &&
        this.controller.forecastingCalculatedData.revenue !== undefined
          ? `${this.currency}${numSeparator(
              roundTo(this.controller.forecastingCalculatedData.revenue, 2),
            )}`
          : undefined;

      this.view.setCommonForecastingData(
        TEXT_PEOPLE,
        isNaN(this.controller.forecastingCalculatedData.people)
          ? '-'
          : numSeparatorRound(this.controller.forecastingCalculatedData.people),
        cost,
        revenue,
      );
    } else {
      this.view.setCommonForecastingData(TEXT_PEOPLE, NO_DATA_STRING);
    }
  }

  showData() {
    if (MainStorage.isForecastingVisible() && !MainStorage.isNumbersVisible()) {
      // analytics data is not needed in this case
      this.showForecasting();
    } else if (MainStorage.isForecastingVisible()) {
      if (MainStorage.isNumbersVisible() && MainStorage.isForecastingVisible()) {
        if (this.controller.forecastingCalculatedData) {
          const people = parseFloat(this.data['hits']);
          const fPeople = Math.round(parseFloat(this.controller.forecastingCalculatedData.people));
          const percent = this.parsePercentChange(fPeople, people);

          const viewData = [
            [
              TEXT_PEOPLE,
              numSeparatorRound(people),
              `${isNaN(fPeople) ? '-' : numSeparatorRound(fPeople)}`,
              // forecast compare texture
              [this.getForecastCompareTexture(percent), toPercent(percent, 1), percent],
            ],
          ];

          if (this.controller.forecastingCalculatedData.cost !== undefined) {
            viewData[0][4] = `${this.currency}${numSeparator(
              roundTo(this.controller.forecastingCalculatedData.cost, 2),
            )}`;
          } else {
            viewData[0][4] = undefined;
          }

          if (
            this.controller.forecastingCalculatedData.revenue !== undefined &&
            this.data['total_revenue_in_cents']
          ) {
            viewData[0][5] = `${this.currency}${numSeparator(
              roundTo(this.data['total_revenue_in_cents'] / 100, 2),
            )}`;

            viewData[0][6] = `${this.currency}${numSeparator(
              roundTo(this.controller.forecastingCalculatedData.revenue, 2),
            )}`;
          } else {
            viewData[0][5] = undefined;
            viewData[0][6] = undefined;
          }

          this.view.setCompareForecastingData(viewData);
        } else {
          const people = parseFloat(this.data['hits']);

          const viewData = [
            [
              TEXT_PEOPLE,
              isNaN(people) ? '-' : numSeparatorRound(people),
              NO_DATA_STRING,
              [null, NO_DATA_STRING, NO_DATA_STRING],
            ],
          ];

          this.view.setCompareForecastingData(viewData);
        }
      }
    } else if (this.data && this.view.canHaveValue) {
      if (this.data['compare_to_data']) {
        const data = this.getCompareData();

        const viewData = [
          [
            TEXT_PEOPLE,
            numSeparator(data.currentHits),
            `${numSeparator(data.compareHits)}`,
            [this.getCompareTexture(data.percent), toPercent(data.percent, 1), data.percent],
          ],
        ];

        const goal = this.controller.goal;

        if (isGoalValid(this.controller.goal)) {
          viewData.push([
            shortenText(goal.goalName || GOAL_DEFAULT_NAME, 20),
            `${this.currency}${numSeparator(roundTo(data.currentHits * goal.goalValue, 2))}`,
            `${this.currency}${numSeparator(roundTo(data.compareHits * goal.goalValue, 2))}`,
            [this.getCompareTexture(data.percent), toPercent(data.percent, 1), data.percent],
          ]);
        }

        this.view.setCommonCompareMultiData(viewData);
      } else {
        this.view.setCommonData(TEXT_PEOPLE, numSeparator(this.data['hits']));
      }
    }
  }
}
