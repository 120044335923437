import { createAction } from 'redux-actions';
import { DEVICES_LIST } from 'react-project/LeftSideBar/Filters/filter-components/DevicesFilterBlock';
import { FILTER_NAMES } from 'react-project/Constants/filters';
import { ActionNames } from 'react-project/redux/actionNamesConstant';
import { removeAllCompareFocusedStep, removeAllDefaultFocusedStep } from '../focused-step/actions';
import moment from 'moment';

export const setFilter = createAction(ActionNames.setFilter);
export const setApplyButtonEnabled = createAction(ActionNames.setApplyButtonEnabled);
export const setApplyCompareButtonEnabled = createAction(ActionNames.setApplyCompareButtonEnabled);
export const setCurrentFilter = createAction(ActionNames.setCurrentFilter);
export const setCurrentCommonFilter = createAction(ActionNames.setCurrentCommonFilter);
export const setPreviousCommonFilter = createAction(ActionNames.setPreviousCommonFilter);
export const setIsFiltersLoaded = createAction(ActionNames.setIsFiltersLoaded);

const currentDateString = new Date().toISOString();
const yesterdayDateString = moment().subtract(1, 'day').toISOString();
export const defaultFilters = {
  commonFilters: {
    inputStart: currentDateString,
    inputFinish: currentDateString,
    countries: [],
    device: DEVICES_LIST.ALL_DEVICES,
    focusedSteps: [],
    session: null,
    contributionWindow: null,
    focusLogicalOperator: undefined,
  },
  peopleCompareFilters: {
    focusedSteps: [],
    countries: [],
    device: DEVICES_LIST.ALL_DEVICES,
    session: null,
    range: { from: 2, to: 3 },
  },
  dateCompareFilter: {
    inputStart: yesterdayDateString,
    inputFinish: yesterdayDateString,
  },
};

export const clearFilterState = () => {
  return (dispatch) => {
    const promise = new Promise(function (resolve) {
      dispatch(setCurrentCommonFilter(defaultFilters.commonFilters));
      dispatch(setPreviousCommonFilter(defaultFilters.commonFilters));

      // Remove focused steps from additional places where they are being
      dispatch(removeAllDefaultFocusedStep());

      resolve();
    });

    return promise;
  };
};

export const clearCompareFilterState = ({ lastPeriod }) => {
  return (dispatch) => {
    const promise = new Promise(function (resolve) {
      dispatch(
        setFilter({
          type: FILTER_NAMES.CURRENT_FILTERS,
          filterName: FILTER_NAMES.PEOPLE_COMPARE_FILTERS,
          value: defaultFilters.peopleCompareFilters,
        }),
      );

      dispatch(
        setFilter({
          type: FILTER_NAMES.PREVIOUS_FILTERS,
          filterName: FILTER_NAMES.PEOPLE_COMPARE_FILTERS,
          value: defaultFilters.peopleCompareFilters,
        }),
      );

      // set comapre range to default

      // caclulate last period here

      dispatch(
        setFilter({
          type: FILTER_NAMES.CURRENT_FILTERS,
          filterName: FILTER_NAMES.DATE_COMPARE_FILTER,
          value: {
            inputStart: lastPeriod[0].toISOString(),
            inputFinish: lastPeriod[1].toISOString(),
          },
        }),
      );

      dispatch(
        setFilter({
          type: FILTER_NAMES.PREVIOUS_FILTERS,
          filterName: FILTER_NAMES.DATE_COMPARE_FILTER,
          value: {
            inputStart: lastPeriod[0].toISOString(),
            inputFinish: lastPeriod[1].toISOString(),
          },
        }),
      );

      // Remove focused steps from additional places where they are being
      dispatch(removeAllCompareFocusedStep());

      resolve();
    });

    return promise;
  };
};
