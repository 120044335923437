import React from 'react';
import { getVimeoSrc, getYoutubeSrc, isVimeo, isYoutube } from './VideoUtils';

const YOUTUBE_PREFIX = 'https://www.youtube.com/embed/';
const VIMEO_PREFIX = 'https://player.vimeo.com/video/';

const getSrc = ({ src }) => {
  if (isYoutube(src)) {
    const { srcID } = getYoutubeSrc(src);
    return `${YOUTUBE_PREFIX}${srcID}`;
  }
  if (isVimeo(src)) {
    const { srcID } = getVimeoSrc(src);
    return `${VIMEO_PREFIX}${srcID}`;
  }
  return undefined;
};

export const MyVideoComponent = ({ blockProps, className = '', style, theme, ...otherProps }) => {
  const src = getSrc(blockProps);
  if (src) {
    return (
      <div style={style}>
        <div className={`${theme.iframeContainer} ${className}`}>
          <iframe
            style={{ width: '100%', minHeight: '360px' }}
            className={theme.iframe}
            src={src}
            frameBorder="0"
            allowFullScreen
          />
        </div>
      </div>
    );
  }

  const {
    block, // eslint-disable-line @typescript-eslint/no-unused-vars
    customStyleMap, // eslint-disable-line @typescript-eslint/no-unused-vars
    customStyleFn, // eslint-disable-line @typescript-eslint/no-unused-vars
    decorator, // eslint-disable-line @typescript-eslint/no-unused-vars
    forceSelection, // eslint-disable-line @typescript-eslint/no-unused-vars
    offsetKey, // eslint-disable-line @typescript-eslint/no-unused-vars
    selection, // eslint-disable-line @typescript-eslint/no-unused-vars
    tree, // eslint-disable-line @typescript-eslint/no-unused-vars
    contentState, // eslint-disable-line @typescript-eslint/no-unused-vars
    blockStyleFn, // eslint-disable-line @typescript-eslint/no-unused-vars
    ...elementProps
  } = otherProps;
  return (
    <video
      src={blockProps.src}
      className={theme.video}
      style={{ ...style, width: '100%', minHeight: '360px' }}
      {...elementProps}
      controls
    />
  );
};
