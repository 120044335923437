import React, { useEffect, useState } from 'react';
import { iconRefreshBtn, iconCheckmark, iconAnalyticsWarning } from 'react-project/assets/Icons.js';
import styles from 'react-project/components/trackingTab/ActionAttributeExplorer.module.scss';
import axios from 'axios';
import { getExplorerRequestBody } from 'react-project/Helpers/requestConstruction';
import { selectFocusedSteps } from 'react-project/redux/focused-step/selectors';
import RequestService from 'react-project/Helpers/RequestService';
import { setNewCurrentStepFilterParams } from 'react-project/redux/current-step/actions';
import { selectCurrentStep } from 'react-project/redux/current-step/selectors';
import { selectFunnelConfiguration } from 'react-project/redux/funnel-configuration/selectors';
import { AttributeExplorerList } from 'react-project/Toolbar/step-toolbar/AttributeExplorer/AttributeExplorerList';
import {
  extractSelectedItems,
  transformToFilterData,
} from 'react-project/Toolbar/step-toolbar/AttributeExplorer/AttributeHelper';
import { When } from 'react-project/Util/When';
import { useDispatch, useSelector } from 'react-redux';
import { PAGINATION_LIMIT } from 'shared/CSharedConstants';
import { RP_EVENT_EDIT_OBJECT } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';

let source = axios.CancelToken.source();
const ITEMS_ON_PAGE = PAGINATION_LIMIT;

const ActionAttributeExplorer = ({ projectId }) => {
  const dispatch = useDispatch();

  const currentStep = useSelector(selectCurrentStep);
  const funnelConfiguration = useSelector(selectFunnelConfiguration);
  const focusedSteps = useSelector(selectFocusedSteps);

  const [isRefreshing, setIsRefreshing] = useState(false);
  const [commonParametersExpanded, setCommonParametersExpanded] = useState({});
  const [pageParameters, setPageParameters] = useState({
    attributes: [],
    hits: null,
  });
  const [hasChange, setHasChange] = useState(false);
  const [lastRequest, setLastRequest] = useState(null);
  const [lastSelectedActionType, setLastSelectedActionType] = useState(
    currentStep.object.actionName,
  );
  const [canLoadMoreConfig, setCanLoadMoreConfig] = useState({});
  const [pagination, setPagination] = useState({});
  const [isMoreLoading, setIsMoreLoading] = useState(false);

  const selectedData = currentStep.object.filterData;

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    if (
      lastSelectedActionType !== currentStep.object.actionName &&
      lastSelectedActionType !== currentStep.object.actionType
    ) {
      // Whenever the drop down selection changes the action type, we need to reset the filter data
      updateStepFilterData([]);
    }

    if (currentStep.object.actionType === 'commerce') {
      setLastSelectedActionType(currentStep.object.actionType);
    } else {
      setLastSelectedActionType(currentStep.object.actionName);
    }
  }, [currentStep]);

  useEffect(() => {
    checkForChanges();
  }, [selectedData]);

  useEffect(() => {
    if (lastSelectedActionType) {
      getAttributeExplorerData();
    }
  }, [lastSelectedActionType]);

  const onRefreshIconClicked = () => {
    if (!isRefreshing) {
      setPagination({});
      getAttributeExplorerData();
    }
  };

  const getAttributeExplorerData = async () => {
    setHasChange(false);
    setIsRefreshing(true);
    setPageParameters({
      attributes: [],
      hits: null,
    });

    const reqBody = prepareRequestBody();
    setLastRequest(reqBody);

    source = axios.CancelToken.source();
    const requestService = new RequestService();
    const responseData = await requestService.getAttributeExplorerAttributes(
      projectId,
      reqBody,
      source,
    );

    if (axios.isCancel(responseData)) {
      // The request was canceled, do nothing
    } else if (responseData) {
      // When we get the response, we will collapse all expanded rows
      setCommonParametersExpanded({});
      setPageParameters(responseData);
      createLoadMoreConfig(responseData.attributes);
      setIsRefreshing(false);
      // Set data loaded with success
    } else {
      // TODO set data did not load
      setIsRefreshing(false);
    }
  };

  const loadPaginatedAttributeKeyItems = async (attributeKey, pageNumber) => {
    setIsMoreLoading(true);
    const reqBody = prepareRequestBody(attributeKey, pageNumber);
    setLastRequest(reqBody);

    source = axios.CancelToken.source();
    const requestService = new RequestService();
    const responseData = await requestService.getAttributeExplorerAttributes(
      projectId,
      reqBody,
      source,
    );

    if (axios.isCancel(responseData) || !responseData) {
      setIsMoreLoading(false);
      return;
    }

    pageParameters.attributes.forEach((attribute) => {
      if (attribute.key === attributeKey) {
        const itemWithNewValues = responseData.attributes.find((i) => i.key === attributeKey);

        if (itemWithNewValues.values.length) {
          updateLoadMoreConfig([itemWithNewValues]);
          attribute.values = attribute.values.concat(itemWithNewValues.values);
        }
      }
    });

    setPageParameters(pageParameters);
    setIsMoreLoading(false);
  };

  const prepareRequestBody = (attributeKey = '', pageNumber = 1) => {
    const exploredStep = currentStep ? { ...currentStep.object, ID: currentStep.stepId } : null;
    const reqBody = getExplorerRequestBody({
      funnelConfiguration,
      exploredStep,
      focusedSteps: focusedSteps,
      pageNumber,
      limit: ITEMS_ON_PAGE,
      attributeKey,
    });
    return reqBody;
  };

  const onCommonParametersChanged = (commonParameters) => {
    const joinedParameters = { ...commonParameters }; // , ...commonParametersData
    const newFilterData = transformToFilterData(joinedParameters, selectedData, inPageParameters);
    updateStepFilterData(newFilterData);
  };

  const updateStepFilterData = (newFilterData) => {
    dispatch(setNewCurrentStepFilterParams(newFilterData));
    commonSendEventFunction(RP_EVENT_EDIT_OBJECT, {
      stepId: currentStep.stepId,
      label: currentStep.object.label,
      filterData: newFilterData,
    });
  };

  const checkForChanges = () => {
    const rBody = prepareRequestBody();
    const a = JSON.stringify(rBody);
    const b = JSON.stringify(lastRequest);
    if (a === b || lastRequest === null) {
      setHasChange(false);
    } else {
      setHasChange(true);
    }
  };

  const inPageParameters = (key) => {
    return pageParameters.attributes.find((itm) => itm.key === key);
  };

  const commonParametersData = extractSelectedItems(selectedData, pageParameters.attributes);

  const loadMoreItems = async (key) => {
    if (isMoreLoading) {
      return;
    }

    const { page } = pagination[key] || { page: 1 };
    const nextPage = page + 1;

    await loadPaginatedAttributeKeyItems(key, nextPage);
    setPagination({ ...pagination, [key]: { page: nextPage } });
  };

  const createLoadMoreConfig = (attributes) => {
    const config = attributes.reduce((result, item) => {
      result[item.key] = item.values.length >= ITEMS_ON_PAGE;

      return result;
    }, {});

    setCanLoadMoreConfig(config);
  };

  const updateLoadMoreConfig = (attributes) => {
    attributes.forEach((item) => {
      canLoadMoreConfig[item.key] = item.values.length >= ITEMS_ON_PAGE;
    });

    setCanLoadMoreConfig(canLoadMoreConfig);
  };

  return (
    <div className={styles.ActionAttributeExplorer}>
      <When
        condition={currentStep.object.actionType === 'commerce' || currentStep.object.actionName}
      >
        <div className={styles.ActionAttributeExplorerHeader}>
          <div className={styles.SectionTitle}>Filter action by attributes</div>
          <div className={styles.RefreshBtn} onClick={onRefreshIconClicked}>
            <span className={isRefreshing ? styles.Rotate : null}>{iconRefreshBtn}</span>
            <When condition={!isRefreshing}>
              <div className={styles.StatusIcon}>
                {hasChange ? iconAnalyticsWarning : iconCheckmark}
              </div>
            </When>
          </div>
        </div>

        <When condition={isRefreshing}>
          <div className={styles.Loading}>
            <span>Loading data please wait</span>
          </div>
        </When>
        <When condition={!isRefreshing}>
          {pageParameters.attributes.length === 0 ? (
            <div className={styles.Loading}>
              <span>No data available for the selected parameters</span>
            </div>
          ) : (
            <div className={styles.ActionAttributeExplorerContainer}>
              <AttributeExplorerList
                onSelectionChanged={onCommonParametersChanged}
                title="Action Parameters"
                list={pageParameters.attributes}
                selectedData={commonParametersData}
                expandedRows={commonParametersExpanded}
                setExpandedRows={setCommonParametersExpanded}
                canLoadMoreConfig={canLoadMoreConfig}
                onLoadMore={loadMoreItems}
                isMoreLoading={isMoreLoading}
              />
            </div>
          )}
        </When>
      </When>
      <When
        condition={!currentStep.object.actionName && currentStep.object.actionType !== 'commerce'}
      >
        <div className={styles.CenterContent}>
          Please select an action to explore its parameters
        </div>
      </When>
    </div>
  );
};

export default ActionAttributeExplorer;
