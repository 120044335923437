import { produce } from 'immer';
import { HEAP_EVENTS } from 'react-project/Constants/heapEvents';
import { sendHeapTracking } from 'react-project/Util/HEAP.utilities';
import { track as trackCohesive } from 'react-project/Util/CohesiveTracking';
import { handleActions } from 'redux-actions';

import {
  removeNoteAction,
  resetHasEditsAction,
  setCanvasNoteAction,
  setNoteAction,
  setNotesAction,
  setNoteTitleAction,
} from './actions';

const initialState = {
  canvasNote: {
    data: null,
    timestamp: new Date().toISOString(),
    title: 'Canvas Note',
    subTitle: '',
    category: null,
    type: null,
  },
  objectNotes: [],
  hasEdits: false,
};

export default handleActions(
  {
    [setNotesAction.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.objectNotes = action.payload;
      }),
    [setCanvasNoteAction.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.canvasNote = action.payload;
        draft.hasEdits = true;
      }),
    [setNoteAction.toString()]: (state, action) =>
      produce(state, (draft) => {
        const { note, projectId } = action.payload;
        const index = draft.objectNotes.findIndex((itm) => itm.id === note.id);
        if (index >= 0) {
          draft.objectNotes[index] = note;
          draft.hasEdits = true;
        } else {
          sendHeapTracking({
            projectId,
            eventName: HEAP_EVENTS.NOTE_CREATED,
          });
          trackCohesive(HEAP_EVENTS.NOTE_CREATED, {
            projectId,
          });
          draft.objectNotes.push(note);
        }
      }),
    [setNoteTitleAction.toString()]: (state, action) =>
      produce(state, (draft) => {
        const index = draft.objectNotes.findIndex((itm) => itm.id === action.payload.id);
        if (index >= 0) {
          draft.objectNotes[index].noteData.title = action.payload.title;
        }
      }),
    [removeNoteAction.toString()]: (state, action) =>
      produce(state, (draft) => {
        const index = draft.objectNotes.findIndex((itm) => itm.id === action.payload.id);
        if (index !== -1) draft.objectNotes.splice(index, 1);
      }),
    [resetHasEditsAction.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.hasEdits = false;
      }),
  },
  initialState,
);
