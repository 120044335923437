export const AcknowledgementConstants = Object.freeze({
  MAPPING: 'seen-mapping-video',
  PERFORMANCE: 'seen-performance-video',
  TRACKING_SCRIPT: 'seen-tracking-script',
  JOURNEYS: 'seen-journeys-video',
  GOALS: 'seen-goals-video',
  INSIGHTS: 'seen-insights-video',
  CREATE_REPORTS: 'seen-create-reports-video',
});

export const HubspotReportingConstants = Object.freeze({
  IAO_MAPPING_BASICS: 'iao_mapping_basics',

  IAO_PERFORMANCE_BASICS: 'iao_performance_basics',

  IAO_INSTALL_SCRIPT: 'iao_install_script',

  IAO_JOURNEY_EXPLORER: 'iao_journey_explorer',

  IAO_GOALS: 'iao_goals',

  IAO_FILTERS_SIGNALS: 'iao_filters_and_signals',

  IAO_REPORTS: 'iao_reports',
});

export const TUTORIAL_TASKS = [
  {
    contentType: 'video',
    listTitle: 'Learn the basics',
    title: 'An overview of journey mapping',
    name: AcknowledgementConstants.MAPPING,
    acknowledged: false,
    video:
      'https://player.vimeo.com/video/803143849?h=3998209e48&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479 ',
    bodyInnerHTML: `<div style="text-align: center; width: 80%;">For more tips, tricks, and how-to's on how to use your Funnelytics Mapping account, <a href="https://hub.funnelytics.io/c/funnelytics-start-here/get-success-with-funnelytics-map" target="_blank" style="text-decoration: underline; color: #0099ef">check out this article</a> in our Customer Hub.</div>`,
    hubspotReporting: HubspotReportingConstants.IAO_MAPPING_BASICS,
    requiresAnalytics: false,
    index: 0,
  },
  {
    contentType: 'video',
    listTitle: 'Getting started with analytics',
    title: 'An overview of journey performance',
    name: AcknowledgementConstants.PERFORMANCE,
    acknowledged: false,
    video:
      ' https://player.vimeo.com/video/803205285?h=e227411daf&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479 ',
    bodyInnerHTML: `<div style="text-align: center; width: 90%;">To learn more about your Funnelytics Performance account, including more tips, tricks and how-to's, <a href="https://hub.funnelytics.io/c/funnelytics-start-here/performance-success" target="_blank" style="text-decoration: underline; color: #0099ef">check out this article</a> in our Customer Hub</div>`,
    hubspotReporting: HubspotReportingConstants.IAO_PERFORMANCE_BASICS,
    requiresAnalytics: true,
    index: 1,
  },
  {
    contentType: 'setupScript',
    listTitle: 'Start Collecting data',
    title: 'Install your tracking script',
    name: AcknowledgementConstants.TRACKING_SCRIPT,
    acknowledged: false,
    bodyInnerHTML: `
    <div style="text-align: center; width: 80%;">
      <div style="margin-top: 10px;">
      For more information or assistance installing your tracking script, please refer to <a href="https://hub.funnelytics.io/c/base-tracking/base-script-install" target="_blank" style="text-decoration: underline; color: #0099ef">this support article</a>
      </div>
      <div style="margin-top: 10px;">
        Need help with the installation? We can do it for your for <b>FREE</b>!<br><a href="https://meetings.hubspot.com/uguardascione/funnelytics-script-installation-call?utm_source=app&utm_medium=canvas&utm_campaign=scriptPopup" target="_blank" style="text-decoration: underline; color: #0099ef">Schedule a call here</a>
      </div>
    </div>`,
    hubspotReporting: HubspotReportingConstants.IAO_INSTALL_SCRIPT,
    requiresAnalytics: true,
    index: 2,
  },
  {
    contentType: 'video',
    listTitle: 'Explore your Journeys',
    title: 'Uncover your most profitable paths',
    name: AcknowledgementConstants.JOURNEYS,
    acknowledged: false,
    video:
      ' https://player.vimeo.com/video/803209366?h=5702a76628&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    bodyInnerHTML: `<div style="text-align: center; width: 80%;">Learn how to map from your data in this <a href="https://hub.funnelytics.io/c/mapping/canvas-creation" target="_blank" style="text-decoration: underline; color: #0099ef">support</a> article. Or, to see how you can use Journey Explorer to understand where your users are coming from and where they go next, read <a href="https://hub.funnelytics.io/c/canvas-analysis/step-explorer" target="_blank" style="text-decoration: underline; color: #0099ef">this article</a>.</div>`,
    hubspotReporting: HubspotReportingConstants.IAO_JOURNEY_EXPLORER,
    requiresAnalytics: true,
    index: 3,
  },
  {
    contentType: 'video',
    listTitle: 'Setup Goals',
    title: 'Identify and set goals',
    name: AcknowledgementConstants.GOALS,
    acknowledged: false,
    video:
      'https://player.vimeo.com/video/803212581?h=95bc27587b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479 ',
    bodyInnerHTML: `<div style="text-align: center; width: 80%;">For more information on how to use goals on your canvas, <a href="https://hub.funnelytics.io/c/canvas-analysis/goals" target="_blank" style="text-decoration: underline; color: #0099ef">visit this article</a> in our Customer Support Hub.</div>`,
    hubspotReporting: HubspotReportingConstants.IAO_GOALS,
    requiresAnalytics: true,
    index: 4,
  },
  {
    contentType: 'video',
    listTitle: 'Gather insights and bottleneck signals',
    title: 'Filter and compare data to gain insights and identify bottlenecks',
    name: AcknowledgementConstants.INSIGHTS,
    acknowledged: false,
    video:
      'https://player.vimeo.com/video/803215224?h=3e0f1de232&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479 ',
    bodyInnerHTML: `<div>Our Customer Support Hub has several articles that will help you <a href="https://hub.funnelytics.io/c/canvas-analysis/filter-data" target="_blank" style="text-decoration: underline; color: #0099ef">filter data</a>, <a href="https://hub.funnelytics.io/c/canvas-analysis/compare-people-who-performed-a-step" target="_blank" style="text-decoration: underline; color: #0099ef">compare data sets</a>, <a href="https://hub.funnelytics.io/c/canvas-analysis/people-export" target="_blank" style="text-decoration: underline; color: #0099ef">export data</a>, and <a href="https://hub.funnelytics.io/c/base-tracking/wild-cards" target="_blank" style="text-decoration: underline; color: #0099ef">aggregate your data</a>. For a full list of articles, <a href="https://hub.funnelytics.io/c/canvas-analysis/filter-data" target="_blank" style="text-decoration: underline; color: #0099ef">click here</a>.</div>`,
    hubspotReporting: HubspotReportingConstants.IAO_FILTERS_SIGNALS,
    requiresAnalytics: true,
    index: 5,
  },
  {
    contentType: 'video',
    listTitle: 'Create reports',
    title: 'Create reports that communicate key results',
    name: AcknowledgementConstants.CREATE_REPORTS,
    acknowledged: false,
    video:
      'https://player.vimeo.com/video/803597285?h=2afb0b0d59&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    bodyInnerHTML: `<div>For more information on how to build visual performance reports in your Funnelytics account, visit <a href="https://hub.funnelytics.io/c/canvas-analysis/reports" target="_blank" style="text-decoration: underline; color: #0099ef">this support article</a>. To add the reporting canvas in the video above to your workspace, <a href="https://app.funnelytics.io/share/83567de1-92ff-495b-89d3-5878ab9e6063" target="_blank" style="text-decoration: underline; color: #0099ef">click here</a>.</div>`,
    hubspotReporting: HubspotReportingConstants.IAO_REPORTS,
    requiresAnalytics: true,
    index: 6,
  },
];
