import _, { cloneDeep } from 'lodash';
import MainStorage from 'pixi-project/core/MainStorage';
import { transformCanvasEntities } from 'react-project/Helpers/mappers/analyticsMappers';
import { getDividedRequest } from 'react-project/Helpers/RequestChunker';
import {
  getAnalyticsRequestBody,
  getFilterProperties,
  getTrendsRequestBody,
} from 'react-project/Helpers/requestConstruction';
import RequestService from 'react-project/Helpers/RequestService';
import { ActionNames } from 'react-project/redux/actionNamesConstant';
import { filterIds } from 'react-project/Util/facebookUtils';
import { createAction } from 'redux-actions';
import {
  ANALYTICS_STATUS_STALE,
  COMPARE_FILTER_LABELS,
  IntegrationTypes,
  DATA_MODE_FUNNEL,
} from 'shared/CSharedConstants';
import {
  RP_EVENT_PEOPLE_LOADED,
  RP_EVENT_PEOPLE_LOADED_MORE,
  RP_EVENT_TOP_COUNTRIES_LOADED,
  RP_EVENT_TRENDS_LOADED,
} from 'shared/CSharedEvents';
import { cloneData, commonSendEventFunction } from 'shared/CSharedMethods';
import SharedElementHelpers from 'shared/SharedElementHelpers';

export const updateStatus = createAction(ActionNames.updateStatus);
export const updateProfileCountries = createAction(ActionNames.updateProfileCountries);
export const refreshSessions = createAction(ActionNames.refreshSessions);
export const refreshCompareSessions = createAction(ActionNames.refreshCompareSessions);
export const insertSessions = createAction(ActionNames.insertSessions);
export const insertCompareSessions = createAction(ActionNames.insertCompareSessions);
export const updateSessionsLoadingStatus = createAction(ActionNames.updateSessionsLoadingStatus);
export const setProjectApiKey = createAction(ActionNames.setProjectApiKey);
export const setCompareMode = createAction(ActionNames.setCompareMode);
export const setSelectedSession = createAction(ActionNames.setSelectedSession);
export const updateLastUsedDataObjs = createAction('update_last_used_data_objs');
export const updateLastUsedDataConnections = createAction('update_last_used_data_connections');
export const setCancelAnalyticsRequest = createAction(ActionNames.setCancelAnalyticsRequest);
export const setAnalyticsWasCancelled = createAction(ActionNames.setAnalyticsWasCancelled);
export const setAnalyticsDataAction = createAction(ActionNames.setAnalyticsData);
export const setHasAnalyticsData = createAction(ActionNames.setHasAnalyticsData);

const requestService = new RequestService();

function getFocusedSteps(funnelConfiguration, compareMode) {
  if (compareMode) {
    if (funnelConfiguration.selectedCompareFilter.label === COMPARE_FILTER_LABELS.DATE) {
      return funnelConfiguration.focusedSteps;
    } else {
      return funnelConfiguration.focusedStepsCompare;
    }
  }

  return funnelConfiguration.focusedSteps;
}

export function loadAnalyticsAsync(
  funnelId,
  funnelConfiguration,
  dataObjs,
  dataConnections,
  compareMode,
) {
  return async (dispatch) => {
    const isFunnelMode = funnelConfiguration.dataMode === DATA_MODE_FUNNEL;
    const dividedRequest = getDividedRequest(dataObjs, dataConnections, isFunnelMode);

    const focusedSteps = getFocusedSteps(funnelConfiguration, compareMode);

    const integratedSources = dataObjs.filter(
      (obj) =>
        SharedElementHelpers.IsSource(obj) &&
        obj.integrationType === IntegrationTypes.FACEBOOK_AD &&
        obj.integrationAttributes.length !== 0,
    );

    const integrationData = [];

    integratedSources.forEach((source) => {
      const mappedIntegrations = source.integrationAttributes.map((el) => {
        return el;
      });

      const filteredIntegrations = mappedIntegrations.filter((el) =>
        filterIds(el, mappedIntegrations),
      );

      integrationData.push({ stepId: source.ID, integrations: filteredIntegrations });
    });

    const dateTimeSource = compareMode
      ? funnelConfiguration.compareDateRange
      : funnelConfiguration.dateRange;

    const timeRange = {
      dateStart: new Date(dateTimeSource.min).toISOString().slice(0, 10),
      dateStop: new Date(dateTimeSource.max).toISOString().slice(0, 10),
    };

    const costs = [];

    const fbIntegrationsCostRequests = integrationData.map((integration) => {
      return new Promise((resolve, reject) => {
        const adItemsIds = integration.integrations.map((i) => {
          return i.id;
        });
        requestService
          .getFacebookInsights({
            funnelId,
            timeRange,
            adItemsIds: adItemsIds.join(','),
          })
          .then((cost) => {
            costs.push({ stepId: integration.stepId, cost });
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    });

    const analyticsPromises = dividedRequest.map((req) => {
      return () =>
        new Promise((resolve, reject) => {
          const reqBody = getAnalyticsRequestBody({
            dictionary: req.dictionary,
            compareMode,
            funnelConfiguration,
            focusedSteps,
            connections: req.connections,
          });

          requestService
            .loadAnalyticsRequest(funnelId, reqBody, compareMode)
            .then((data) => {
              resolve(data);
            })
            .catch(() => reject());
        });
    });

    const result = await analyticsPromises.reduce((promiseChain, currPromise) => {
      return promiseChain.then((chainResults) =>
        currPromise().then((currentResult) => [...chainResults, currentResult]),
      );
    }, Promise.resolve([]));

    await Promise.all([...fbIntegrationsCostRequests]);

    let canvasEntities = {};
    result.forEach((el) => {
      canvasEntities = { ...canvasEntities, ...el.data.canvas_entities };
    });
    return transformCanvasEntities({
      dataObjs,
      data: { canvas_entities: canvasEntities },
      integratedSources,
      costs,
    });
  };
}

export function loadTrendsAsync(
  projectId,
  funnelConfiguration,
  dataObjs,
  dataConnections,
  widgetId,
  compareMode,
) {
  return async (dispatch) => {
    const focusedSteps = compareMode
      ? funnelConfiguration.focusedStepsCompare
      : funnelConfiguration.focusedSteps;

    const reqBody = getTrendsRequestBody({
      dictionary: dataObjs,
      compareMode,
      funnelConfiguration,
      focusedSteps,
      connections: dataConnections,
    });

    // clean up the request body
    // to only contain a single widget

    const widgets = reqBody.filter.widgets;

    const widget = cloneDeep(widgets[widgetId]);
    const widgetRequest = cloneDeep(reqBody);
    widgetRequest.filter.widgets = {};
    widgetRequest.filter.widgets[widgetId] = widget;
    widgetRequest.filter['widget-ranges'] = widget.range;
    delete widget.range;

    // remove elements from elements from the dictionary
    // that are not in the widget elements

    const widgetElements = widget.elements;
    const widgetDictionary = widgetRequest.filter.canvas.dictionary;

    const widgetDictionaryIds = Object.keys(widgetDictionary);

    widgetDictionaryIds.forEach((id) => {
      if (!widgetElements.find((wId) => wId === id)) {
        delete widgetDictionary[id];
      }
    });

    return new Promise((resolve, reject) => {
      requestService
        .loadTrendsRequest(projectId, widgetRequest, compareMode)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}

export function loadProfileCountriesAsync(
  projectId,
  funnelConfiguration,
  dataObjs,
  dataConnections,
  compareMode,
) {
  return async (dispatch) => {
    const dividedRequest = getDividedRequest(dataObjs, dataConnections);

    const focusedSteps = compareMode
      ? funnelConfiguration.focusedStepsCompare
      : funnelConfiguration.focusedSteps;

    let analyticsPromises = [];

    if (dividedRequest.length === 0) {
      analyticsPromises = [
        () =>
          new Promise((resolve, reject) => {
            const reqBody = getAnalyticsRequestBody({
              dictionary: {},
              compareMode,
              funnelConfiguration,
              focusedSteps,
              connections: [],
            });

            const bodyWithOmittedValues = _.omit(
              reqBody,
              'filter.profiles.countries',
              'filter.session',
            );

            bodyWithOmittedValues.filter.canvas.isDisabled = 'true';

            requestService
              .getCountriesRequest(projectId, bodyWithOmittedValues)
              .then((data) => {
                resolve(data);
              })
              .catch(() => reject());
          }),
      ];
    } else {
      analyticsPromises = dividedRequest.map((req) => {
        return () =>
          new Promise((resolve, reject) => {
            const reqBody = getAnalyticsRequestBody({
              dictionary: {},
              compareMode,
              funnelConfiguration,
              focusedSteps,
              connections: req.connections,
            });

            const bodyWithOmittedValues = _.omit(
              reqBody,
              'filter.profiles.countries',
              'filter.session',
            );

            bodyWithOmittedValues.filter.canvas.isDisabled = 'true';

            requestService
              .getCountriesRequest(projectId, bodyWithOmittedValues)
              .then((data) => {
                resolve(data);
              })
              .catch(() => reject());
          });
      });
    }

    const result = await analyticsPromises.reduce((promiseChain, currPromise) => {
      return promiseChain.then((chainResults) =>
        currPromise().then((currentResult) => [...chainResults, currentResult]),
      );
    }, Promise.resolve([]));

    const countries = [];
    result.forEach((el) => {
      el.forEach((country) => {
        const sameCountry = countries.find((sc) => sc.name === country.name);
        if (!sameCountry) {
          countries.push(country);
        } else {
          if (sameCountry.hits < country.hits) {
            const index = countries.indexOf(sameCountry);
            countries.splice(index, 1, country);
          }
        }
      });
    });

    dispatch(updateProfileCountries(countries));
    commonSendEventFunction(RP_EVENT_TOP_COUNTRIES_LOADED, countries);
    return countries;
  };
}

export function refreshSessionsAsync({
  projectId,
  funnelConfiguration,
  dataObjs,
  dataConnections,
  compareMode = false,
}) {
  return async (dispatch) => {
    dispatch(updateSessionsLoadingStatus(true));
    dispatch(updateLastUsedDataObjs(cloneData(dataObjs)));
    dispatch(updateLastUsedDataConnections(cloneData(dataConnections)));

    const dividedRequest = getDividedRequest(dataObjs, dataConnections);

    const focusedSteps = compareMode
      ? funnelConfiguration.focusedStepsCompare
      : funnelConfiguration.focusedSteps;

    let analyticsPromises = [];

    if (dividedRequest.length === 0) {
      analyticsPromises = [
        () =>
          new Promise((resolve, reject) => {
            const reqBody = getFilterProperties({
              dictionary: {},
              compareMode,
              funnelConfiguration,
              focusedSteps,
              connections: [],
            });
            reqBody.canvas.isDisabled = 'true';
            requestService
              .getSessionsRequest(projectId, { filter: reqBody })
              .then((data) => {
                resolve(data);
              })
              .catch(() => reject());
          }),
      ];
    } else {
      analyticsPromises = dividedRequest.map((req) => {
        return () =>
          new Promise((resolve, reject) => {
            const reqBody = getFilterProperties({
              dictionary: {},
              compareMode,
              funnelConfiguration,
              focusedSteps,
              connections: [],
            });
            reqBody.canvas.isDisabled = 'true';
            requestService
              .getSessionsRequest(projectId, { filter: reqBody })
              .then((data) => {
                resolve(data);
              })
              .catch(() => reject());
          });
      });
    }

    const result = await analyticsPromises.reduce((promiseChain, currPromise) => {
      return promiseChain.then((chainResults) =>
        currPromise().then((currentResult) => [...chainResults, currentResult]),
      );
    }, Promise.resolve([]));

    const sessionsResult = { meta: { count: 0 }, sessions: [] };
    result.forEach((el) => {
      if (el.data && sessionsResult.meta.count < el.data.meta.count) {
        sessionsResult.meta.count = el.data.meta.count;
      }
      if (el.data) {
        el.data.sessions.forEach((session) => {
          const sameSession = sessionsResult.sessions.find((sc) => sc.userId === session.userId);

          if (!sameSession) {
            sessionsResult.sessions.push(session);
          }
        });

        sessionsResult.sessions.push(...el.data.sessions);
      }
    });

    commonSendEventFunction(RP_EVENT_PEOPLE_LOADED, { data: sessionsResult });

    dispatch(updateSessionsLoadingStatus(false));

    if (result.some((el) => !el.success)) {
      return;
    }

    if (compareMode) {
      dispatch(refreshCompareSessions(sessionsResult));
    } else {
      dispatch(refreshSessions(sessionsResult));
    }
    return sessionsResult;
  };
}

export function loadMoreSessionsAsync({
  projectId,
  funnelConfiguration,
  dataObjs,
  dataConnections,
  compareMode,
  last,
}) {
  return async (dispatch) => {
    dispatch(updateSessionsLoadingStatus(true));

    const dividedRequest = getDividedRequest(dataObjs, dataConnections);

    const focusedSteps = compareMode
      ? funnelConfiguration.focusedStepsCompare
      : funnelConfiguration.focusedSteps;

    let analyticsPromises = [];

    if (dividedRequest.length === 0) {
      analyticsPromises = [
        () =>
          new Promise((resolve, reject) => {
            const reqBody = getFilterProperties({
              dictionary: {},
              compareMode,
              funnelConfiguration,
              focusedSteps,
              connections: [],
            });
            const lastId = last ? { last: last.intId } : {};
            reqBody.canvas.isDisabled = 'true';
            requestService
              .getSessionsRequest(projectId, { filter: reqBody, ...lastId })
              .then((data) => {
                resolve(data);
              })
              .catch(() => reject());
          }),
      ];
    } else {
      analyticsPromises = dividedRequest.map((req) => {
        return () =>
          new Promise((resolve, reject) => {
            const reqBody = getFilterProperties({
              dictionary: {},
              compareMode,
              funnelConfiguration,
              focusedSteps,
              connections: [],
            });
            const lastId = last ? { last: last.intId } : {};
            reqBody.canvas.isDisabled = 'true';
            requestService
              .getSessionsRequest(projectId, { filter: reqBody, ...lastId })
              .then((data) => {
                resolve(data);
              })
              .catch(() => reject());
          });
      });
    }

    const result = await analyticsPromises.reduce((promiseChain, currPromise) => {
      return promiseChain.then((chainResults) =>
        currPromise().then((currentResult) => [...chainResults, currentResult]),
      );
    }, Promise.resolve([]));

    const sessionsResult = { meta: { count: 0 }, sessions: [] };
    result.forEach((el) => {
      if (sessionsResult.meta.count < el.data.meta.count) {
        sessionsResult.meta.count = el.data.meta.count;
      }

      el.data.sessions.forEach((session) => {
        const sameSession = sessionsResult.sessions.find((sc) => sc.userId === session.userId);

        if (!sameSession) {
          sessionsResult.sessions.push(session);
        }
      });

      sessionsResult.sessions.push(...el.data.sessions);
    });

    commonSendEventFunction(RP_EVENT_PEOPLE_LOADED_MORE, { data: sessionsResult });

    dispatch(updateSessionsLoadingStatus(false));

    if (result.some((el) => !el.success)) {
      return;
    }
    if (compareMode) {
      dispatch(insertCompareSessions(sessionsResult));
    } else {
      dispatch(insertSessions(sessionsResult));
    }
  };
}

export function loadProjectApiKeyAsync(projectId) {
  return async (dispatch) => {
    if (projectId) {
      const apiKey = await requestService.getProjectApiKey(projectId);
      dispatch(setProjectApiKey(apiKey));
    }
  };
}

export function setAnalyticsStale() {
  return async (dispatch) => {
    dispatch(updateStatus(ANALYTICS_STATUS_STALE));
  };
}

export function loadSessionDetailsAsync(
  projectId,
  sessionID,
  funnelConfiguration,
  data,
  axiosSource,
) {
  return async (dispatch) => {
    const reqBody = getFilterProperties({
      dictionary: {},
      compareMode: false,
      funnelConfiguration,
      connections: [],
    });
    return await requestService.getSessionDetails({
      projectId,
      sessionID,
      filters: reqBody,
      data,
      axiosSource,
    });
  };
}

export function setAnalyticsData(payload) {
  return async (dispatch, getState) => {
    dispatch(setAnalyticsDataAction(payload));
  };
}

export function hasAnalyticsDataAsync(projectId) {
  return async (dispatch) => {
    const resp = await requestService.checkHasAnalyticsData(projectId);

    if (!resp.success) {
      MainStorage.setHasAnalyticsData(false);
      return dispatch(setHasAnalyticsData(null));
    }

    MainStorage.setHasAnalyticsData(resp.hasTrackingData);
    return dispatch(setHasAnalyticsData(resp.hasTrackingData));
  };
}
