import * as PIXI from 'pixi.js';

const HEIGHT = 8;
const BACKGROUND_COLOR = 0xd9d9d9;
const ROUNDING = 5;

export default class ProgressBar extends PIXI.Container {
  constructor() {
    super();
    this.progress = 0.5;
    this.barWidth = 100;
    this.progressColor = 0xffffff;

    this.background = new PIXI.Graphics();
    this.background.beginFill(BACKGROUND_COLOR);
    this.background.drawRoundedRect(0, 0, this.barWidth, HEIGHT, ROUNDING);
    this.background.endFill();
    this.addChild(this.background);

    this.progressbar = new PIXI.Graphics();
    this.progressbar.beginFill(this.progressColor);
    this.progressbar.drawRoundedRect(0, 0, this.barWidth, HEIGHT, ROUNDING);
    this.progressbar.endFill();
    this.addChild(this.progressbar);

    this.updateProgressbar();
  }

  setProgressbarWidth(width) {
    this.barWidth = width;
    this.background.clear();
    this.background.beginFill(BACKGROUND_COLOR);
    this.background.drawRoundedRect(0, 0, this.barWidth, HEIGHT, ROUNDING);
    this.background.endFill();

    this.updateProgressbar();
  }

  setProgress(progress) {
    this.progress = progress;
    this.updateProgressbar();
  }

  setProgressColor(color) {
    this.progressColor = color;
    this.updateProgressbar();
  }

  updateProgressbar() {
    this.progressbar.clear();
    this.progressbar.beginFill(this.progressColor);
    this.progressbar.drawRoundedRect(0, 0, this.progress * this.barWidth, HEIGHT, ROUNDING);
    this.progressbar.endFill();
  }
}
