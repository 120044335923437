import { COLOR_FRAME_ATTACHMENT } from 'pixi-project/view/Styles';

import BaseSelectionLayer from './BaseSelectionLayer';
import SelectionAttachmentFrame from './SelectionAttachmentFrame';

// DELEGATES
// - onAttachmentPointUp(event , attachmentPoint)

export default class AttachmentSelectionLayer extends BaseSelectionLayer {
  // Overwrite
  getLineColor(selectionFrame) {
    return COLOR_FRAME_ATTACHMENT;
  }

  // Abstract method implementation
  hoverIn(event, element) {
    let frame = this.findFrameByElementId(element.id);

    if (!frame) {
      frame = new SelectionAttachmentFrame(element, this);
      this.addChild(frame);
      this.frames.push(frame);
      element.onFrameShow();
    }

    this.drawAll();
  }

  // Abstract method implementation
  hoverOut(event, element) {
    const point = element.content.toLocal(event.data.global);

    if (this.testPointInsideElement(element, point)) {
      return;
    }

    this.removeFrameByElementId(element.id);
    this.drawAll();
  }

  onAttachmentPointHoverIn(event, attachmentPoint) {}

  onAttachmentPointHoverOut(event, attachmentPoint) {
    const element = attachmentPoint.element;
    const point = element.content.toLocal(event.data.global);

    if (this.testPointInsideElement(element, point)) {
      return;
    }

    this.removeFrameByElementId(element.id);
    this.drawAll();
  }

  onAttachmentPointPointerUp(event, attachmentPoint) {
    const element = attachmentPoint.element;
    if (this.delegate && this.delegate.onAttachmentPointUp) {
      this.delegate.onAttachmentPointUp(event, attachmentPoint);
    }

    this.removeFrameByElementId(element.id);
    this.drawAll();
  }

  testPointInsideElement(element, point) {
    if (element.content.hitArea) {
      if (element.content.hitArea.contains(point.x, point.y)) {
        return true;
      }
    } else {
      const bounds = element.content.getLocalBounds();
      if (bounds.contains(point.x, point.y)) {
        return true;
      }
    }

    return false;
  }
}
