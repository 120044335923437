import cls from 'classnames';
import MainStorage from 'pixi-project/core/MainStorage';
import React, { useEffect, useState } from 'react';
import styles from './Banner.module.scss';

export const Banner = () => {
  const [showing, setShowing] = useState(true);
  useEffect(() => {
    setShowing(MainStorage.canvasPermissions.isInTrial);
  }, []);

  return (
    <div className={cls(styles.announcementBanner, showing && styles.show)}>
      <div className={cls(styles.centeringContainer)}>
        <div style={{ height: '32px', width: '32px', marginRight: '28px' }}>
          <svg
            width="32px"
            height="32px"
            className={styles.icon}
            viewBox="0 0 20 20"
            style={{ width: '100%', height: '100%' }}
          >
            <path
              d="M20.5342 14.1035C20.949 14.1035 21.3598 14.0218 21.7431 13.863C22.1264 13.7042 22.4747 13.4715 22.768 13.1782C23.0614 12.8848 23.2941 12.5366 23.4529 12.1533C23.6116 11.77 23.6933 11.3592 23.6933 10.9443C23.6933 10.5295 23.6116 10.1186 23.4529 9.73536C23.2941 9.35207 23.0614 9.00381 22.768 8.71045C22.4747 8.4171 22.1264 8.1844 21.7431 8.02563C21.3598 7.86687 20.949 7.78516 20.5342 7.78516C19.6963 7.78516 18.8928 8.118 18.3003 8.71045C17.7078 9.30291 17.375 10.1065 17.375 10.9443C17.375 11.7822 17.7078 12.5857 18.3003 13.1782C18.8928 13.7706 19.6963 14.1035 20.5342 14.1035Z"
              fill="#212121"
            />
            <path
              d="M7.64502 6.79476L8.2225 5.94552C9.28405 5.94552 10.3371 5.69924 11.2883 5.24065L16.6045 2.625V19.2871L11.2883 16.6714C10.3341 16.203 9.28544 15.959 8.2225 15.958L7.64502 14.4379V6.79476ZM3.58227 22.0878H5.76821C6.33719 22.0878 6.79578 21.6293 6.79578 21.0603V14.3445H2.55469V21.0603C2.55469 21.6293 3.01328 22.0878 3.58227 22.0878Z"
              fill="#D3D3D3"
            />
            <path
              d="M18.7439 0C18.0524 0 17.3893 0.274682 16.9003 0.76362C16.4114 1.25256 16.1367 1.9157 16.1367 2.60716V19.2947C16.1367 19.9862 16.4114 20.6493 16.9003 21.1382C17.3893 21.6272 18.0524 21.9019 18.7439 21.9019C19.4353 21.9019 20.0985 21.6272 20.5874 21.1382C21.0764 20.6493 21.351 19.9862 21.351 19.2947V2.60716C21.3495 1.91618 21.0743 1.25395 20.5857 0.765354C20.0971 0.276757 19.4349 0.00157016 18.7439 0Z"
              fill="#F8312F"
            />
            <path
              d="M1.46663 5.94531H8.49238V15.9493H1.46663C0.653913 15.9493 0 15.3124 0 14.5311V7.37203C0 6.58224 0.653913 5.94531 1.46663 5.94531Z"
              fill="#CA0B4A"
            />
          </svg>
        </div>
        <div>
          {/* <span style={{ fontWeight: '500' }}>IMPORTANT</span>: Exciting developments and upcoming
          changes with your free Funnelytics Mapping account. */}
          Let us show you how to accelerate your business growth with Funnelytics.
        </div>
        <div>
          <a
            className={cls(styles.flBtn, styles.flBtnBrandGreen)}
            href={
              'https://meetings.hubspot.com/mdia/funnelytics-lite-onboarding?utm_source=app&utm_medium=canvas&utm_campaign=performanceKickOff'
            }
            target="_blank"
            style={{ fontSize: '14px', marginLeft: '28px' }}
          >
            Schedule your Funnelytics growth kickoff call
          </a>
        </div>
      </div>
      <div>
        <div
          className={cls(styles.close)}
          onClick={
            (e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowing(false);
            } /**close Banner */
          }
        >
          ×
        </div>
      </div>
    </div>
  );
};
