import { formatDistanceToNowStrict } from 'date-fns';
import * as PIXI from 'pixi.js';
import { CountriesListArr } from 'shared/CSharedConstants';
import { shortenText } from 'shared/SharedHelpers';
import { isEmailValid } from 'shared/ValidateHelpers';
import Styles, { COLOR_WIDGET_TEXT, COLOR_WIDGET_TEXT_SELECTED } from '../Styles';
import ToolTipBox, { TOOLTIP_LOCATION } from '../ToolTipBox';
import TableRow from './TableView/TableRow';

export default class TableRowPeople extends TableRow {
  constructor(headerData, rowSize, columnSpacing = 0) {
    super(headerData, rowSize, columnSpacing);
  }

  initRow() {
    super.initRow();
    this.setInteractive();

    this.delegate = null;

    this._id = null;
    this.index = 0;
    this.isSelected = false;

    this.countryIcon = new PIXI.Sprite();
    this.countryIcon.anchor.y = 0.5;
    this.addChild(this.countryIcon);

    this.personLabel = new PIXI.BitmapText('', Styles.WIDGET_LABEL);
    this.personLabel.tint = COLOR_WIDGET_TEXT;
    this.personLabel.anchor.y = 0.5;
    this.addChild(this.personLabel);

    this.lastSeenLabel = new PIXI.BitmapText('', Styles.WIDGET_LABEL);
    this.lastSeenLabel.tint = COLOR_WIDGET_TEXT;
    this.lastSeenLabel.anchor.y = 0.5;
    this.addChild(this.lastSeenLabel);

    this.tooltip = new ToolTipBox(this.personLabel, 'Lock', {
      location: [TOOLTIP_LOCATION.left],
    });
  }

  bindData(data) {
    this.data = data;
    this.visible = true;
    const countryAttr = CountriesListArr.find((attrs) => attrs.name === data.country.name);
    const countryCode = countryAttr ? countryAttr.iso2.toLowerCase() : '';
    this.countryIcon.setTexture(`flag/${countryCode}`);
    this.countryIcon.fitTo(25, 25);
    const identifier = this.getPersonIdentifier(data);
    this.personLabel.text = identifier.short;
    this.tooltip.setContent(identifier.full);
    this.lastSeenLabel.text = `${formatDistanceToNowStrict(new Date(data.createdAt))} ago`;
    this.updatePositions();
  }

  getPersonIdentifier(data) {
    const maxLength = 10;
    const result = {
      short: null,
      full: null,
    };
    if (data.email && isEmailValid(data.email)) {
      result.full = data.email;
      result.short = shortenText(data.email, maxLength);
    } else if (data.fullName) {
      result.full = data.fullName;
      result.short = shortenText(data.fullName, maxLength);
    } else {
      result.full = data.id;
      result.short = `Person ${data.id.split('-')[0]}`;
    }
    return result;
  }

  updatePositions() {
    const rh = this.rowSize.height;

    let cellX = this.columnSpacing;
    this.alignElementsInCell(
      [this.countryIcon],
      cellX,
      this.headerData[0].calculatedWidth,
      rh,
      0,
      this.headerData[0].align,
    );

    cellX += this.headerData[0].calculatedWidth + this.columnSpacing;
    this.alignElementsInCell(
      [this.personLabel],
      cellX,
      this.headerData[1].calculatedWidth,
      rh,
      0,
      this.headerData[1].align,
    );

    cellX += this.headerData[1].calculatedWidth + this.columnSpacing;
    this.alignElementsInCell(
      [this.lastSeenLabel],
      cellX,
      this.headerData[2].calculatedWidth,
      rh,
      0,
      this.headerData[2].align,
    );
  }

  onSelectChanged(isSelected) {
    super.onSelectChanged(isSelected);

    this.personLabel.tint = isSelected ? COLOR_WIDGET_TEXT_SELECTED : COLOR_WIDGET_TEXT;
    this.lastSeenLabel.tint = isSelected ? COLOR_WIDGET_TEXT_SELECTED : COLOR_WIDGET_TEXT;

    window.app.needsRendering();
  }
}
