import { PR_EVENT_FUNNEL_CHANGED } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';

export default class CommandDeleteBreakPoint {
  constructor(object, parent, objectsArray) {
    this.object = object;
    this.parent = parent;
    this.objects = objectsArray;
    this.index = objectsArray.indexOf(object);
    this.isExecuted = false;

    this.connectionDisplay = object.connectionDisplay;
  }

  execute() {
    if (!this.isExecuted) {
      this.isExecuted = true;
      window.app.needsRendering();

      this.object.connectionDisplay = null;
      if (this.connectionDisplay) {
        this.connectionDisplay.breakPoint = null;
      }

      this.object.removeFromParent();
      this.objects.removeElement(this.object);
      this.parent.onBreakPointsUpdated();

      if (this.connectionDisplay) {
        // lets transfer the display to the next available breakpoint
      }

      this.parent.update();
      this.parent.updateFlowPoints();
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
    }
  }

  revert() {
    if (this.isExecuted) {
      this.isExecuted = false;
      window.app.needsRendering();

      this.object.connectionDisplay = this.connectionDisplay;
      if (this.connectionDisplay) {
        this.connectionDisplay.breakPoint = this.object;
      }

      this.parent.addChild(this.object);
      this.objects.splice(this.index, 0, this.object);
      this.parent.onBreakPointsUpdated();
      this.parent.update();
      this.parent.updateFlowPoints();
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
    }
  }
}
