import React, { useEffect, useState } from 'react';
import { FiltersBlock } from './FiltersBlock';
import buttonStyles from 'react-project/components/secondaryButton/SecondaryButtons.module.scss';
import { SecondaryButton } from '../../components/secondaryButton/SecondaryButton';
import 'bootstrap-daterangepicker/daterangepicker.css';
import cls from 'classnames';
import styles from './AllFiltersBlock.module.scss';
import {
  ANALYTICS_STATUS_LOADING,
  FILTER_TYPE_DEFAULT,
  FILTER_TYPE_DEFAULT_STEP,
} from 'shared/CSharedConstants';
import { FILTER_NAMES } from 'react-project/Constants/filters';

import { useDispatch, useSelector } from 'react-redux';
import { selectApplyButtonEnabled, selectFilters } from 'react-project/redux/filters/selectors';
import {
  selectAnalyticsStatus,
  selectAnalyticsWasCancelled,
  selectSessions,
} from 'react-project/redux/analytics/selectors';
import { iconFilters } from 'react-project/assets/Icons';
import { selectFocusedSteps } from 'react-project/redux/focused-step/selectors';
import {
  clearFilterState,
  defaultFilters,
  setApplyButtonEnabled,
  setFilter,
} from 'react-project/redux/filters/actions';
import { setSelectedSession } from 'react-project/redux/analytics/actions';
import {
  RP_EVENT_REFRESH_REQUEST,
  RP_EVENT_STEP_FOCUS_CLEARED,
  RR_EVENT_ON_REFRESH_ANALYTICS,
} from 'shared/CSharedEvents';
import { removeFocusedStep } from 'react-project/redux/focused-step/actions';
import { cloneData, commonSendEventFunction } from 'shared/CSharedMethods';
import {
  setDataRange,
  setFilterData,
  setFocusedSteps,
} from 'react-project/redux/funnel-configuration/actions';
import { DEFAULT_DEVICES_STATE } from 'react-project/Constants/step-settings';
import { sendHeapTracking } from 'react-project/Util/HEAP.utilities';
import { track as trackCohesive } from 'react-project/Util/CohesiveTracking';
import { HEAP_EVENTS } from 'react-project/Constants/heapEvents';
import { getDiffInDays } from 'react-project/Util/Dates';
import {
  resetPeopleTrackingPopups,
  resetPopupZIndex,
} from 'react-project/Toolbar/step-toolbar/PeopleTracking/peopleTrackingSlice';
import { selectCurrentStep } from 'react-project/redux/current-step/selectors';
import { selectExplorerItemsConfig } from 'react-project/redux/explorer/selectors';
import { selectFunnelConfiguration } from 'react-project/redux/funnel-configuration/selectors';
import { onSetExplorerItemsConfig } from 'react-project/redux/explorer/actions';
import {
  FILTER_BLOCK_TYPES,
  checkEnabledFilters,
  cleanAndCompare,
  compareCommonFilters,
} from './FilterHelpers';
import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';

export const AllFiltersBlock = ({
  funnel,
  onApplyFilter,
  analyticsIsLoading,
  onClickCancelAnalytics,
  onClose,
  isOpen,
}) => {
  const dispatch = useDispatch();

  const currentStep = useSelector(selectCurrentStep);
  const explorerItemsConfig = useSelector(selectExplorerItemsConfig);
  const funnelConfiguration = useSelector(selectFunnelConfiguration);
  const sessions = useSelector(selectSessions);
  const analyticsStatus = useSelector(selectAnalyticsStatus);
  const allFilters = useSelector(selectFilters);
  const applyButtonForceEnabled = useSelector(selectApplyButtonEnabled);
  const analyticsWasCancelled = useSelector(selectAnalyticsWasCancelled);
  const focusedSteps = useSelector(selectFocusedSteps);
  const [localFilterMonitor, setLocalFilterMonitor] = useState(null);

  const { currentFilters, previousFilters } = allFilters;

  useEffect(() => {
    document.addEventListener(RR_EVENT_ON_REFRESH_ANALYTICS, onRefreshBtnClicked);
    return () => {
      document.removeEventListener(RR_EVENT_ON_REFRESH_ANALYTICS, onRefreshBtnClicked);
    };
  }, [allFilters, analyticsStatus]);

  useEffect(() => {
    dispatch(setFocusedSteps(focusedSteps, false));
    setStepFilterState(focusedSteps);
  }, [focusedSteps]);

  useEffect(() => {
    // This is a hack to make sure the filters are updated before saving them
    // By monitoring this variable we can make sure the filters are updated
    // This variable is only upldated when filters are being cleared.
    if (localFilterMonitor !== null) {
      // previous filters are being save inside clear Filter state
      // they don't need to be saved , as that will overwrite the clearing of the filters
      saveFilters(false);
    }
  }, [localFilterMonitor]);

  const setStepFilterState = (focusedSteps) => {
    dispatch(
      setFilter({
        type: FILTER_NAMES.CURRENT_FILTERS,
        filterName: FILTER_NAMES.COMMON_FILTERS,
        value: { ...allFilters.currentFilters.commonFilters, focusedSteps },
      }),
    );
  };

  const onRemoveFocusedStep = (data) => {
    dispatch(
      removeFocusedStep({
        step: cloneData(data.step),
        filterTypes: data.filterTypes,
      }),
    );
  };

  const clearStepFocusingFilter = (filterTypes) => {
    dispatch(removeFocusedStep({ filterTypes }));
    commonSendEventFunction(RP_EVENT_STEP_FOCUS_CLEARED, { types: filterTypes });
  };

  const clearFilters = () => {
    clearStepFocusingFilter([FILTER_TYPE_DEFAULT_STEP]);
    dispatch(setSelectedSession({ session: null }));

    // // TODO make sure you don't clear compare filters here
    dispatch(clearFilterState()).then(() => {
      // This is a hack , read the comment in the userEffect method monitoring this variable
      setLocalFilterMonitor(Math.random());
    });
  };

  const sendToStore = (dateRangeValue, compare = false) => {
    let intermediateValue;
    if (dateRangeValue.min !== dateRangeValue.max) {
      intermediateValue = {
        min: new Date(dateRangeValue.min).toISOString(),
        max: new Date(dateRangeValue.max).toISOString(),
        selectedLabel: dateRangeValue.selectedLabel,
      };
    } else {
      intermediateValue = {
        min: new Date(new Date(dateRangeValue.min).setHours(0, 0, 0, 0)).toISOString(),
        max: new Date(new Date(dateRangeValue.max).setHours(23, 59, 59, 0)).toISOString(),
        selectedLabel: dateRangeValue.selectedLabel,
      };
    }

    dispatch(setDataRange(intermediateValue, compare));
  };

  const saveFilters = (savePreviousFilters = true) => {
    if (funnel.isInUnorganizedFolder) {
      return;
    }

    if (analyticsStatus === ANALYTICS_STATUS_LOADING) {
      commonSendEventFunction(RP_EVENT_REFRESH_REQUEST, { isCancel: true });
    }

    const { commonFilters } = allFilters.currentFilters;

    const filters = {
      device: commonFilters.device || DEFAULT_DEVICES_STATE,
      countries: commonFilters.countries || [],
      contributionWindow: commonFilters.contributionWindow,
      focusLogicalOperator: commonFilters.focusLogicalOperator,
    };

    if (commonFilters.session) {
      filters.session = commonFilters.session.id;
    }

    dispatch(resetPeopleTrackingPopups());
    dispatch(resetPopupZIndex());
    dispatch(setFilterData(filters)).then(() => {
      const filterDataForHeap = _createFilterDataForHead();
      sendHeapTracking({
        projectId: funnel.projectId,
        eventName: HEAP_EVENTS.FILTER_APPLIED,
        eventData: filterDataForHeap,
      });
      trackCohesive(HEAP_EVENTS.FILTER_APPLIED, {
        funnelId: funnel.id,
        projectId: funnel.projectId,
        ...filterDataForHeap,
      });
      const analyticsFilterBool = analyticsWasCancelled;
      commonSendEventFunction(RP_EVENT_REFRESH_REQUEST, { isCancel: analyticsFilterBool });
      _createAndSetExplorerConfig(filters, commonFilters);
      if (savePreviousFilters) {
        setPreviousFilters({ commonFilters });
      }
    });

    dispatch(setApplyButtonEnabled(false));

    onApplyFilter();
  };

  const onRefreshBtnClicked = () => {
    saveFilters();
  };

  const _createFilterDataForHead = () => {
    const { commonFilters } = allFilters.currentFilters;
    let filterDataForHeap = {};

    filterDataForHeap = {
      days: getDiffInDays(funnelConfiguration.dateRange.min, funnelConfiguration.dateRange.max),
      numOfCountries: commonFilters.countries?.length || 0,
      device: commonFilters.device,
      peopleWhoPerformed: Boolean(commonFilters.focusedSteps && commonFilters.focusedSteps.length),
      people: Boolean(commonFilters.session),
    };
    return filterDataForHeap;
  };

  const _createAndSetExplorerConfig = (filters, commonFilters) => {
    const config = {
      funnelConfiguration: {
        ...funnelConfiguration,
        filter: filters,
      },
      funnel: funnel,
      currentStep: currentStep,
      port: false,
      explorerItemsConfig: explorerItemsConfig,
      mounted: true,
      focusedSteps: focusedSteps,
    };

    if (commonFilters) {
      config.funnelConfiguration.focusedSteps = commonFilters.focusedSteps;
    }

    const {
      currentFilters: { commonFilters: currentCommonFilter },
      previousFilters: { commonFilters: previousCommonFilter },
    } = allFilters;
    const disableCache = !compareCommonFilters(previousCommonFilter, currentCommonFilter);
    dispatch(onSetExplorerItemsConfig(config, disableCache));
  };

  const setPreviousFilters = ({ commonFilters }) => {
    dispatch(
      setFilter({
        type: FILTER_NAMES.PREVIOUS_FILTERS,
        filterName: FILTER_NAMES.COMMON_FILTERS,
        value: commonFilters,
      }),
    );
  };

  const isInsideDatepicker = (e) => {
    let isInsideDateRangePicker = false;
    let element = e.target;
    while (element) {
      if (element.classList.contains('daterangepicker')) {
        isInsideDateRangePicker = true;
        break;
      }
      element = element.parentElement;
    }

    return isInsideDateRangePicker;
  };

  const isToggleBtn = (e) => {
    const element = e.target;

    if (element.id === 'filter-btn' || element.closest('#filter-btn')) {
      return true;
    }

    return false;
  };

  const isDefaultFilter = cleanAndCompare(previousFilters, defaultFilters, false, null);
  const hasAppliedFilters = cleanAndCompare(currentFilters, previousFilters, false, null);

  return (
    <ClickOutsideCustomComponent
      onClickOutside={(e) => {
        if (!isOpen) {
          return;
        } else if (isInsideDatepicker(e)) {
          return;
        } else if (isToggleBtn(e)) {
          return;
        }

        onClose();
      }}
    >
      <div className={cls(styles.FilterWrapper, isOpen ? null : styles.HideFilter)}>
        <div className={styles.FilterDataBlock}>
          <div className={cls(styles.BlockTitle, styles.BlockMainTitle)}>
            <div className={styles.FilterMainIcon}>{iconFilters}</div>
            <span>Filter people by</span>
          </div>
          <FiltersBlock
            filtersSet={[
              FILTER_BLOCK_TYPES.COUNTRIES,
              FILTER_BLOCK_TYPES.DEVICE,
              FILTER_BLOCK_TYPES.STEP,
              FILTER_BLOCK_TYPES.CONTRIBUTION,
              FILTER_BLOCK_TYPES.PEOPLE,
            ]}
            type={FILTER_TYPE_DEFAULT}
            currentFilters={allFilters.currentFilters.commonFilters}
            funnel={funnel}
            sessions={sessions}
            funnelConfiguration={funnelConfiguration}
            setFilters={(newValue) => {
              dispatch(
                setFilter({
                  type: FILTER_NAMES.CURRENT_FILTERS,
                  filterName: FILTER_NAMES.COMMON_FILTERS,
                  value: { ...allFilters.currentFilters.commonFilters, ...newValue },
                }),
              );
            }}
            onClearSteps={() => clearStepFocusingFilter([FILTER_TYPE_DEFAULT_STEP])}
            onRemoveFocusedStep={(data) => onRemoveFocusedStep(data)}
            sendToStore={sendToStore}
            focusedSteps={focusedSteps}
            isApplyButtonEnabled={
              !(
                analyticsStatus === ANALYTICS_STATUS_LOADING ||
                (hasAppliedFilters && !applyButtonForceEnabled)
              )
            }
            enabledFilters={checkEnabledFilters(previousFilters, currentFilters)}
          />
        </div>

        <div className={styles.FilterDataBlock}>
          <div className={styles.FiltersBtns}>
            <SecondaryButton
              title="Reset filters"
              disabled={analyticsStatus === ANALYTICS_STATUS_LOADING || isDefaultFilter}
              onClick={clearFilters}
            />
            <SecondaryButton
              title={analyticsIsLoading ? 'Cancel' : 'Apply filters'}
              disabled={
                !analyticsIsLoading &&
                hasAppliedFilters &&
                !applyButtonForceEnabled &&
                !analyticsWasCancelled
              }
              onClick={analyticsIsLoading ? onClickCancelAnalytics : saveFilters}
              className={buttonStyles.ApplyFiltersButton}
            />
          </div>
        </div>
      </div>
    </ClickOutsideCustomComponent>
  );
};
