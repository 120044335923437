import React from 'react';
import { CountriesFilterBlock } from './filter-components/CountriesFilterBlock';
import { DevicesFilterBlock } from './filter-components/DevicesFilterBlock';
import { StepFilterBlock } from './filter-components/StepFilterBlock';
import { PeopleFilterBlock } from './filter-components/PeopleFilterBlock';
import { DateFilterBlock } from './filter-components/DateFilterBlock';

import { FILTER_TYPE_COMPARE } from 'shared/CSharedConstants';
import { ContributionFilterBlock } from './filter-components/ContributionFilterBlock';
import { FILTER_BLOCK_TYPES } from './FilterHelpers';

export const FiltersBlock = ({
  type,
  filtersSet,
  currentFilters,
  setFilters,
  funnel,
  sessions,
  sendToStore,
  onClearSteps,
  onRemoveFocusedStep,
  isApplyButtonEnabled,
  enabledFilters,
  focusedSteps,
}) => {
  const filtersChanged = type === FILTER_TYPE_COMPARE ? enabledFilters.compare : enabledFilters;

  return (
    <>
      {filtersSet.includes(FILTER_BLOCK_TYPES.DATE) && (
        <DateFilterBlock
          type={type}
          inputStart={currentFilters.inputStart}
          inputFinish={currentFilters.inputFinish}
          onSelectDate={setFilters}
          sendToStore={sendToStore}
          isApplyButtonEnabled={isApplyButtonEnabled}
          hasFilterChange={filtersChanged[FILTER_BLOCK_TYPES.DATE]}
        />
      )}
      {filtersSet.includes(FILTER_BLOCK_TYPES.COUNTRIES) && (
        <CountriesFilterBlock
          type={type}
          funnel={funnel}
          selectedCountries={currentFilters.countries}
          onSelectCountries={setFilters}
          isApplyButtonEnabled={isApplyButtonEnabled}
          hasFilterChange={filtersChanged[FILTER_BLOCK_TYPES.COUNTRIES]}
        />
      )}
      {filtersSet.includes(FILTER_BLOCK_TYPES.DEVICE) && (
        <DevicesFilterBlock
          type={type}
          funnel={funnel}
          selectedDevice={currentFilters.device}
          onSelectDevice={setFilters}
          isApplyButtonEnabled={isApplyButtonEnabled}
          hasFilterChange={filtersChanged[FILTER_BLOCK_TYPES.DEVICE]}
        />
      )}
      {filtersSet.includes(FILTER_BLOCK_TYPES.STEP) && (
        <StepFilterBlock
          type={type}
          funnel={funnel}
          onFilterChanged={setFilters}
          focusedSteps={focusedSteps}
          onClearSteps={onClearSteps}
          focusLogicalOperator={currentFilters.focusLogicalOperator}
          onRemoveFocusedStep={onRemoveFocusedStep}
          isApplyButtonEnabled={isApplyButtonEnabled}
          hasFilterChange={filtersChanged[FILTER_BLOCK_TYPES.STEP]}
        />
      )}
      {filtersSet.includes(FILTER_BLOCK_TYPES.CONTRIBUTION) && (
        <ContributionFilterBlock
          type={type}
          funnel={funnel}
          focusedSteps={focusedSteps}
          isApplyButtonEnabled={isApplyButtonEnabled}
          hasFilterChange={filtersChanged[FILTER_BLOCK_TYPES.CONTRIBUTION]}
          filter={currentFilters.contributionWindow}
          onFilterChanged={setFilters}
        />
      )}
      {filtersSet.includes(FILTER_BLOCK_TYPES.PEOPLE) && (
        <PeopleFilterBlock
          type={type}
          funnel={funnel}
          sessions={sessions}
          selectedSession={currentFilters.session}
          onSelectSession={setFilters}
          isApplyButtonEnabled={isApplyButtonEnabled}
          hasFilterChange={filtersChanged[FILTER_BLOCK_TYPES.PEOPLE]}
        />
      )}
    </>
  );
};
