import axios from 'axios';
import { serializeQuery, setup } from 'axios-cache-adapter';
import get from 'lodash/get';
import QueryString from 'qs';
import { adType } from 'react-project/FacebookIntegration/helpers';
import { parseURL } from 'react-project/Helpers/URLHelpers';
import {
  mapAdSpendData,
  mapFacebookAdsResponse,
  mapFacebookCampaignTreeResponse,
  mapFacebookInsightsResponse,
} from 'react-project/Helpers/mappers/facebookMappers';
import {
  getAuthToken,
  getRefreshToken,
  removeAuthCookieAndReload,
  setAuthCookie,
} from 'react-project/Util/AuthCookie';
import { FunnelProperties } from 'react-project/Util/FunnelProperties';
import { addObjectToFormData } from 'react-project/Util/addObjectToFormData';
import { getUpdatedPhotoPath } from 'react-project/Util/urlPath';
import serializer from 'react-project/json-api/serializer';
import {
  TrackerEventType,
  TrackerProfileType,
  TrackerSessionType,
  TrackerStepType,
  UserType,
} from 'react-project/json-api/types';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import TrafficData from 'shared/TrafficData';
import { ERROR_MESSAGES } from '../../shared/CSharedConstants';
import { RP_EVENT_ERROR_MESSAGE, RP_EVENT_UPDATE_STEP_THUMBNAIL } from '../../shared/CSharedEvents';
import generatePdfFile from '../services/pdf/pdfService';
import { createCustomActionKey } from './cacheKey';
import { isJson } from './utilities';

const axiosInstance = setup({
  baseURL: process.env.REACT_APP_ANALYTICS_API_URL,
  cache: {
    maxAge: 1000 * 60 * 10, // 10 min cache
    exclude: {
      methods: ['put', 'patch', 'delete'],
      filter: (request) => {
        if (
          // White-list routes for caching.
          request.url.indexOf('/facebook-integration/searchAds') !== -1 ||
          request.url.indexOf('/facebook-integration/campaigns') !== -1 ||
          request.url.indexOf('/facebook-integration/adSets') !== -1 ||
          request.url.indexOf('/sessions/single') !== -1 ||
          request.url.indexOf('/people') !== -1
        ) {
          return false;
        } else if (
          // We will only allow traffic explorer requests, but not the `/action_attributes` and `/page_parameters`, to be cached
          request.url.indexOf('traffic-explorer/') === -1 ||
          request.url.indexOf('traffic-explorer/action_attributes') !== -1 ||
          request.url.indexOf('traffic-explorer/page_parameters') !== -1
        ) {
          return true;
        }
        return false;
      },
    },
    key: (req) => {
      const url = `${req.baseURL && !req.url.startsWith('http') ? req.baseURL : ''}${req.url}`;
      const key = url + serializeQuery(req);
      // Create cache key for traffic-explorer API from custom action data.
      let cachingKey = '';

      // Facebook Integration and Session/People caching rules
      if (
        req.data &&
        (url.indexOf('traffic-explorer/') !== -1 ||
          url.indexOf('/facebook-integration/searchAds') !== -1 ||
          url.indexOf('/facebook-integration/campaigns') !== -1 ||
          url.indexOf('/facebook-integration/adSets') !== -1 ||
          url.indexOf('/sessions/single') !== -1 ||
          url.indexOf('/people') !== -1)
      ) {
        let requestData = null;
        // checkout if req data is form data or json and parse accordinly
        if (isJson(req.data)) {
          requestData = JSON.parse(req.data);
        } else if (req.data instanceof FormData) {
          requestData = Object.fromEntries(req.data.entries());
        } else {
          requestData = req.data;
        }

        try {
          const keyAppendix = createCustomActionKey(requestData);
          cachingKey = key + keyAppendix;
        } catch (error) {
          console.error('Key creation Failed:', error);
        }

        if (!requestData) return;
      } else {
        cachingKey = key;
      }

      return cachingKey;
    },
  },
});

class RequestService {
  constructor() {
    this.URL = `${process.env.REACT_APP_API_URL}`;
    this.URLAnalytics = `${process.env.REACT_APP_ANALYTICS_API_URL}`;

    axiosInstance.interceptors.request.use(function (config) {
      const token = getAuthToken();
      config.headers.Authorization = `Bearer ${token}`;

      return config;
    });

    axiosInstance.interceptors.response.use(
      (res) => res,
      async (err) => {
        const originalReq = err.config;
        const refreshTokenUrl = `${this.URL}/refresh-token`;
        const checkViewOnlyPasswordUrl = `${this.URL}/auth/view-only`;
        const isUnathorizedError = err.response && err.response.status === 401;
        const isRefreshTokenUrl = originalReq && originalReq.url === refreshTokenUrl;
        const isViewOnlyPasswordCheck = originalReq && originalReq.url === checkViewOnlyPasswordUrl;

        if (
          isUnathorizedError &&
          !isRefreshTokenUrl &&
          !originalReq._retry &&
          !isViewOnlyPasswordCheck
        ) {
          /**
           * There could be a race condition with token refresh
           * happend at the same time on Ember-Dashboard and New-Canvas.
           * In this case, the fastest refresh call will return valid tokens,
           * while slowest will fail, because refresh token it sends is no longer valid.
           */
          await new Promise((resolve) => setTimeout(resolve, 400));

          originalReq._retry = true;
          const refreshToken = getRefreshToken();

          if (!refreshToken) {
            // Do not try to refresh token if we don't even have it
            removeAuthCookieAndReload();
            return Promise.reject(err);
          }

          try {
            const refreshResponse = await axiosInstance.post(refreshTokenUrl, {
              refresh_token: refreshToken,
            });
            setAuthCookie(refreshResponse.data);
          } catch (e) {
            // Remove cookies completely if refresh request failed
            removeAuthCookieAndReload();
            return Promise.reject(err);
          }

          return axiosInstance(originalReq);
          /**
           * Special case: when refresh-token endpoint fails
           * with any error - delete auth cookies and reload page to show login screen.
           */
        } else if (isRefreshTokenUrl) {
          removeAuthCookieAndReload();
          return Promise.reject(err);
        }

        return Promise.reject(err);
      },
    );
  }

  removeFilterData = (rawData) => {
    const dataWithoutFilters = rawData;
    const objects = [];
    rawData.objects.forEach((item) => {
      if (item.stepFocused) {
        item.stepFocused = false;
      }
      objects.push(item);
    });
    dataWithoutFilters.objects = objects;
    return dataWithoutFilters;
  };

  loadFacebookAdsCampaign = (projectId, timeRange) => {
    return axiosInstance
      .post(
        `${this.URL}/facebook-integration/campaigns`,
        {
          projectId,
          timeRange,
        },
        {
          headers: {
            'X-Project-Id': projectId,
          },
        },
      )
      .then((response) => {
        return {
          success: true,
          data: response.data,
        };
      })
      .catch(() => {
        return {
          success: false,
        };
      });
  };

  loadFacebookAdsAdset = (projectId, campaignId, timeRange) => {
    return axiosInstance
      .post(
        `${this.URL}/facebook-integration/adSets`,
        {
          projectId,
          campaignId,
          timeRange,
        },
        {
          headers: {
            'X-Project-Id': projectId,
          },
        },
      )
      .then((response) => {
        return {
          success: true,
          data: response.data,
        };
      })
      .catch(() => {
        return {
          success: false,
        };
      });
  };

  loadAnalyticsRequest = (projectId, data, compareMode) => {
    return axiosInstance
      .post(`${this.URLAnalytics}/canvas`, data, {
        headers: {
          'X-Project-Id': projectId,
        },
      })
      .then((response) => {
        return {
          success: true,
          data: response.data,
        };
      })
      .catch((error) => {
        console.log(error);
        commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
          errorMSG: ERROR_MESSAGES.ANALYTICS_ERROR,
        });
        return {
          success: false,
        };
      });
  };

  fetchFunnelRequest = async (funnelId) => {
    try {
      const response = await axiosInstance.get(`${this.URL}/funnels/${funnelId}`);
      const { data } = response.data;
      const { relationships } = data;
      const projectId = relationships.project.data?.id || '';
      const ownerId = relationships.user.data?.id || '';
      const customerId = relationships.owner.data?.stripe?.customerId;

      FunnelProperties.setProjectId(projectId);
      FunnelProperties.setOwnerId(ownerId);
      FunnelProperties.setCustomerId(customerId);

      return {
        success: true,
        data: {
          id: data.id,
          ...data.attributes,
          projectId,
        },
      };
    } catch (error) {
      commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
        errorMSG: ERROR_MESSAGES.LOAD_FUNNEL_ERROR,
      });
      let errorStatus;
      error.response && error.response.status
        ? (errorStatus = error.response.status)
        : (errorStatus = error.message);
      return {
        success: false,
        errorStatus: errorStatus,
      };
    }
  };

  loadFunnelRequest = (funnelId, params = {}) => {
    return axiosInstance
      .get(`${this.URL}/funnels/load/${funnelId}`, {
        params,
      })
      .then((response) => {
        const urlForLoad = response.data.url;
        return axios.get(`${urlForLoad}`);
      })
      .catch((error) => {
        if (error.response.status !== 404) {
          commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
            errorMSG: ERROR_MESSAGES.LOAD_FUNNEL_ERROR,
          });
        }
      });
  };

  loadFunnelRevisionsRequest = async (funnelId, offset, limit) => {
    return axiosInstance
      .get(`${this.URL}/funnel-revisions/funnel/${funnelId}`, {
        method: 'GET',
        params: {
          offset,
          limit,
        },
      })
      .then((response) => {
        return {
          success: true,
          data: response.data,
        };
      })
      .catch((error) => {
        return {
          success: false,
        };
      });
  };

  updateCanvasToC2 = async (funnelId) => {
    const url = `${this.URL}/funnels/upgrade-version/${funnelId}`;
    axiosInstance.patch(url);
  };

  saveFunnelRequest = async (funnelId, objForSendingRaw, previewBase64) => {
    const objForSending = this.removeFilterData(objForSendingRaw);
    const previewForSending = await fetch(previewBase64);

    try {
      const revisionIdResponse = await axiosInstance.get(`${this.URL}/funnel-revisions/id`);

      const revisionId = revisionIdResponse.data.id;
      const uploadResponse = await axiosInstance.post(`${this.URL}/funnel-revisions/upload/`, {
        funnel: funnelId,
        revision: revisionId,
        previewContentType: 'image/png',
      });
      const funnelUrl = uploadResponse.data.funnel.url;
      await axios.put(
        `${funnelUrl}`,
        {
          ...objForSending,
        },
        {
          headers: {
            'Content-Type': 'binary/octet-stream',
          },
        },
      );

      const previewUrl = uploadResponse.data.preview.url;
      const blob = await previewForSending.blob();

      await axios.put(`${previewUrl}`, blob, {
        headers: {
          'Content-Type': 'image/png',
        },
      });

      const revisionResponse = await axiosInstance.post(`${this.URL}/funnel-revisions`, {
        data: {
          id: revisionId,
          attributes: {
            created_at: null,
          },
          relationships: {
            funnel: {
              data: {
                type: 'funnels',
                id: funnelId,
              },
            },
          },
          type: 'funnel-revisions',
        },
      });

      return {
        success: true,
      };
    } catch (e) {
      commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, { errorMSG: ERROR_MESSAGES.SAVING_FUNNEL });

      return {
        success: false,
      };
    }
  };

  getSessionsRequest = (projectId, data) => {
    return axiosInstance
      .post(`${this.URLAnalytics}/sessions`, data, {
        headers: {
          'X-Project-Id': projectId,
        },
      })
      .then(async (response) => {
        const sessions = await serializer.deserializeAsync(TrackerSessionType, response.data);

        return {
          data: {
            meta: {
              count: response.data.meta.count,
            },
            sessions: sessions
              .filter((session) => session.profile)
              .map((session) => {
                const countryNameAttr = session.profile.attrs.find((a) => a.key === 'country_name');
                const countryCodeAttr = session.profile.attrs.find((a) => a.key === 'country_code');
                const regionNameAttr = session.profile.attrs.find((a) => a.key === 'region_name');
                const fullNameAttr = session.profile.attrs.find((a) => a.key === 'name');
                const emailAttr = session.profile.attrs.find((a) => a.key === 'email');
                const city = session.profile.attrs.find((a) => a.key === 'city');
                const device = session.device;

                return {
                  id: session.id,
                  intId: session.int_id,
                  createdAt: new Date(session.created_at),
                  userId: session.profile.id,
                  device,
                  fullName: fullNameAttr && fullNameAttr.value,
                  email: emailAttr && emailAttr.value,
                  country: {
                    name: countryNameAttr && countryNameAttr.value,
                    code: countryCodeAttr && countryCodeAttr.value,
                    city: city && city.value,
                    regionName: regionNameAttr && regionNameAttr.value,
                  },
                };
              }),
          },
          success: true,
        };
      })
      .catch((error) => {
        console.log(error);
        commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
          errorMSG: ERROR_MESSAGES.SESSION_PEOPLE_DATA_ERROR,
        });
        return {
          success: false,
        };
      });
  };

  getCountriesRequest = (projectId, data) => {
    return axiosInstance
      .post(`${this.URLAnalytics}/profile-attributes`, data, {
        headers: {
          'X-Project-Id': projectId,
        },
      })
      .then((response) => {
        return response.data.profile_countries.map((c) => ({
          name: c.name,
          hits: Number(c.hits),
        }));
      })
      .catch((error) => {
        console.log(error);
        commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
          errorMSG: ERROR_MESSAGES.TOP_COUNTRIES_ERROR,
        });
        return [];
      });
  };

  loadTrendsRequest = (projectId, data) => {
    // count number of properties in ranges
    if (Object.keys(data.filter['widget-ranges']).length < 2) {
      return new Promise((resolve, reject) => {
        reject(ERROR_MESSAGES.TRENDS_LONGER_RANGE);
      });
    }

    return axiosInstance
      .post(`${this.URLAnalytics}/widgets/trends`, data, {
        headers: {
          'X-Project-Id': projectId,
        },
      })
      .then((response) => {
        return new Promise((resolve, reject) => {
          resolve({ data: response.data, reqBody: data });
        });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
        } else {
          console.error(error);
        }
      });
  };

  getThumbnailImg = (id, url, projectId) => {
    const parsedURL = parseURL(url);

    if (!parsedURL) {
      commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, { errorMSG: ERROR_MESSAGES.THUMBNAIL_ERROR });
      return false;
    }

    return axiosInstance
      .post(`${this.URL}/funnels/thumbnail/`, {
        /**
         * FIXME
         * https is prefered over http as google is starting to force the protocol
         * We need to reconsider prepending a http protocol to the url.                 *
         */
        url: `http://${parsedURL}`,
        projectId,
      })
      .then((response) => {
        const imgUrl = response.data.path;
        const event = new CustomEvent(RP_EVENT_UPDATE_STEP_THUMBNAIL, {
          detail: { id: id, url: imgUrl },
        });
        document.dispatchEvent(event);
      })
      .catch((error) => {
        commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
          errorMSG: ERROR_MESSAGES.THUMBNAIL_ERROR,
        });
      });
  };

  getTrafficExplorerData = async (projectId, data, disableCache = false, source = null) => {
    const cacheConfig = disableCache
      ? {
          invalidate: async (cfg, req) => {
            await cfg.store.removeItem(cfg.uuid);
          },
        }
      : {};

    const options = {
      headers: {
        'X-Project-Id': projectId,
      },
      cache: cacheConfig,
    };

    if (source) {
      options.cancelToken = source.token;
    }

    return axiosInstance
      .post(`${this.URLAnalytics}/traffic-explorer/`, data, options)
      .then((response) => {
        const responseData = {
          has_more_actions: response.data.next_actions.has_more,
          has_more_pages: response.data.next_pages.has_more,
          page_parameters: response.data.page_parameters,
          page_parameters_all: response.data.page_parameters_all,
          // immediate next pages / actions
          next_pages: response.data.next_pages,
          next_pages_all: response.data.next_pages_all,
          next_actions: response.data.next_actions,
          next_actions_all: response.data.next_actions_all,
          // immediate previous pages / actions
          previous_pages: response.data.previous_pages,
          previous_pages_all: response.data.previous_pages_all,
          previous_actions: response.data.previous_actions,
          previous_actions_all: response.data.previous_actions_all,
          direct_traffic_all: response.data.direct_traffic_all,
          direct_traffic: response.data.direct_traffic,
          referrers_all: response.data.referrers_all,
          referrers: response.data.referrers,
        };

        if (!data.filter.explored_step) {
          TrafficData.setData(responseData);
        }

        return new Promise((resolve, reject) => {
          resolve(responseData);
        });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // do nothing , request canceled by user
        } else {
          commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
            errorMSG: ERROR_MESSAGES.TRAFFIC_EXPLORER_ERROR,
          });
        }
      });
  };

  getAttributeExplorerData = (projectId, data, type, source = null) => {
    const options = {
      headers: {
        'X-Project-Id': projectId,
      },
    };

    if (source) {
      options.cancelToken = source.token;
    }

    return axiosInstance
      .post(`${this.URLAnalytics}/traffic-explorer/`, data, options)
      .then((response) => {
        if (type === 'PAGE') {
          const responseData = {
            common_parameters: response.data.page_parameters_all.common_parameters,
            custom_parameters: response.data.page_parameters_all.custom_parameters,
            countValue: response.data.page_parameters_all.hits,
          };
          return new Promise((resolve, reject) => {
            resolve(responseData);
          });
        } else if (type === 'EVENT_PARAMETERS') {
          const responseData = {
            attributes: response.data.action_attributes.key_values,
            hits: response.data.action_attributes.hits,
          };
          return new Promise((resolve, reject) => {
            resolve(responseData);
          });
        } else if (type === 'PAGE_PARAMETERS') {
          const responseData = {
            common_parameters: response.data.page_parameters.common_parameters,
            custom_parameters: response.data.page_parameters.custom_parameters,
            hits: response.data.page_parameters.hits,
          };
          return new Promise((resolve, reject) => {
            resolve(responseData);
          });
        } else {
          const responseData = {
            action_attributes: response.data.action_attributes.key_values,
            countValue: response.data.action_attributes.hits,
            next_actions: response.data.next_actions,
          };
          return new Promise((resolve, reject) => {
            resolve(responseData);
          });
        }
      })
      .catch((error) => {
        console.log(error);
        if (axios.isCancel(error)) {
          return error;
        } else {
          commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
            errorMSG: ERROR_MESSAGES.TRAFFIC_EXPLORER_ERROR,
          });
        }
      });
  };

  getAttributeExplorerAttributes = async (projectId, data, source = null) => {
    const url = `${this.URLAnalytics}/traffic-explorer/action_attributes`;
    const options = {
      headers: {
        'X-Project-Id': projectId,
      },
    };

    if (source) {
      options.cancelToken = source.token;
    }

    try {
      const res = await axiosInstance.post(url, data, options);

      return {
        attributes: res.data.key_values,
        hits: res.data.hits,
      };
    } catch (error) {
      console.log(error);
      if (axios.isCancel(error)) {
        return error;
      } else {
        commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
          errorMSG: ERROR_MESSAGES.TRAFFIC_EXPLORER_ERROR,
        });
      }
    }
  };

  // get page params data
  getAttributeExplorerPageParams = async (projectId, data, source = null) => {
    const url = `${this.URLAnalytics}/traffic-explorer/page_parameters`;
    const options = {
      headers: {
        'X-Project-Id': projectId,
      },
    };

    if (source) {
      options.cancelToken = source.token;
    }

    try {
      const res = await axiosInstance.post(url, data, options);

      return {
        common_parameters: res.data.page_parameters.common_parameters,
        custom_parameters: res.data.page_parameters.custom_parameters,
        hits: res.data.page_parameters.hits,
      };
    } catch (error) {
      if (axios.isCancel(error)) {
        return error;
      } else {
        commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
          errorMSG: ERROR_MESSAGES.TRAFFIC_EXPLORER_ERROR,
        });
      }
    }
  };

  getEventExplorerData = (projectId, data, source) => {
    const options = {
      headers: {
        'X-Project-Id': projectId,
      },
    };
    if (source) {
      options.cancelToken = source.token;
    }
    return axiosInstance
      .post(`${this.URLAnalytics}/traffic-explorer/`, data, options)
      .then((response) => {
        const responseData = {
          action_attributes: response.data.action_attributes.key_values,
          countValue: response.data.action_attributes.hits,
          next_actions: response.data.next_actions,
        };
        return new Promise((resolve, reject) => {
          resolve(responseData);
        });
      })
      .catch((error) => {
        console.log(error);
        commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
          errorMSG: ERROR_MESSAGES.TRAFFIC_EXPLORER_ERROR,
        });
      });
  };

  getProjectApiKey = (projectId) => {
    return axiosInstance
      .get(`${this.URL}/projects/${projectId}/api-key`)
      .then((response) => get(response, ['data', 'api_key'], null))
      .catch(() => null);
  };

  checkActionsExist = (projectId) => {
    return axiosInstance
      .get(`${this.URLAnalytics}/check/exists/actions`, {
        headers: {
          'X-Project-Id': projectId,
        },
      })
      .then((response) => get(response, ['data', 'exists']))
      .catch(() => false);
  };

  checkActionExist = (projectId, name) => {
    return axiosInstance
      .get(`${this.URLAnalytics}/check/exists/actions/${name}`, {
        headers: {
          'X-Project-Id': projectId,
        },
      })
      .then((response) => get(response, ['data', 'exists']))
      .catch(() => false);
  };

  hasUserPermission = ({ scopeId, permission, scope, projectId, level }) => {
    return axiosInstance
      .get(`${this.URL}/user-permissions/has-permission`, {
        headers: {
          'X-Project-Id': projectId,
        },
        params: {
          permission,
          scope,
          scopeId,
          level: level,
        },
      })
      .then((response) => response.data)
      .catch(() => false);
  };

  getCanvasPermissions = async (canvasId) => {
    return this.getAllPermissions('canvasId', canvasId);
  };

  getAllPermissions = async (key, value) => {
    const data = {};
    data[key] = value;
    return axiosInstance
      .post(`${this.URL}/user-permissions/get-all`, data)
      .then((response) => response.data)
      .catch(() => false);
  };

  hasUserMetaPermission = () => {
    return axiosInstance
      .get(`${this.URL}/user-meta/facebook-integration`)
      .then((response) => response.data)
      .catch(() => false);
  };

  hasScriptWarning = (projectId) => {
    return axiosInstance
      .get(`${this.URL}/projects/${projectId}/warning`)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log('fetch error: ', e);
        return false;
      });
  };

  getUserData = ({ userId }) => {
    return axiosInstance
      .get(`${this.URL}/users/${userId}`)
      .then((response) => {
        return serializer.deserialize(UserType, response.data);
      })
      .catch((e) => console.warn('There was an error getting the user data', e));
  };

  getHubspotToken = () => {
    const STUB_TOKEN = '1';
    let STUB_EXPIRY_DATE = new Date();
    STUB_EXPIRY_DATE.setDate(new Date().getDate() + 1);

    return axiosInstance
      .post(`${this.URL}/hubspot/token`, null)
      .then((response) => {
        return response.data;
      })
      .catch(() => ({ token: STUB_TOKEN, expiryDate: STUB_EXPIRY_DATE.toISOString() }));
  };

  sendReport = async (reportMetadata) => {
    // share by download-pdf and email-pdf
    let userInfo = await this.getUserData({ userId: reportMetadata.userId });
    let userFullName = userInfo.first_name + ' ' + userInfo.last_name;
    let pdfBlob = await generatePdfFile(
      {
        ...reportMetadata,
        userFullName,
      },
      false,
    );

    // used for email-pdf
    const formData = new FormData();
    formData.append('emails', reportMetadata.emails);
    formData.append('pdfReport', pdfBlob);

    // post request to send email
    return axiosInstance
      .post(`${this.URL}/reports/generate-pdf`, formData, {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${Math.random().toString().substr(2)}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        console.log('Error for sending report');
      });
  };

  downloadReport = async (reportMetadata) => {
    // share by download-pdf and email-pdf
    const userInfo = await this.getUserData({ userId: reportMetadata.userId });
    const userFullName = userInfo ? userInfo.first_name + ' ' + userInfo.last_name : '';
    const pdfBlob = await generatePdfFile(
      {
        ...reportMetadata,
        userFullName,
      },
      true,
    );
  };

  checkFacebookIntegrationExist = (projectId) => {
    return axiosInstance
      .get(`${this.URL}/facebook-integration/${projectId}/check`, {
        headers: {
          'X-Project-Id': projectId,
        },
      })
      .then((response) => get(response, ['data', 'connected']))
      .catch(() => false);
  };

  getFacebookCampaignTree = async ({ projectId }) => {
    try {
      const response = await axiosInstance.get(
        `${this.URL}/facebook-integration/${projectId}/tree/`,
      );
      return mapFacebookCampaignTreeResponse(response.data);
    } catch (e) {
      console.error('Error for getting facebook campaign data', e);
    }
  };

  getFacebookAds = async ({ projectId, campaignId, dateRange, isAnalyticsActive }) => {
    let adResponse = [];
    let spendData = [];
    try {
      adResponse = await axiosInstance.get(
        `${this.URL}/facebook-integration/${projectId}/tree/${campaignId}`,
      );
    } catch (e) {
      console.error('Error for getting facebook ads data ', e);
    }
    if (isAnalyticsActive) {
      try {
        spendData = await this.getFacebookAdSpend({ projectId, campaignId, dateRange });
      } catch (e) {
        console.error('Error for getting facebook ad spend data ', e);
      }
    }
    try {
      if (spendData.data) {
        const adwithSpendData = mapAdSpendData(adResponse, spendData.data);
        return mapFacebookAdsResponse(adwithSpendData);
      }
      return mapFacebookAdsResponse(adResponse.data.data);
    } catch (e) {
      console.error('Error for mapping facebook ads data ', e);
    }
  };

  getFacebookInsightForIds = async ({ projectId, adItemsIds, timeRange }) => {
    try {
      const response = await axiosInstance.get(
        `${this.URL}/facebook-integration/${projectId}/insights`,
        {
          params: {
            ids: adItemsIds,
            dateStart: timeRange.dateStart,
            dateStop: timeRange.dateStop,
          },
        },
      );
      return response;
    } catch (e) {
      console.error('Error for getting facebook insights data', e);
    }
  };

  getFacebookInsights = async ({ projectId, adItemsIds, timeRange }) => {
    try {
      const response = await this.getFacebookInsightForIds({ projectId, adItemsIds, timeRange });
      return mapFacebookInsightsResponse(response.data);
    } catch (e) {
      console.error('Error for getting facebook insights data', e);
    }
  };

  searchFacebookAds = ({ projectId, searchText = '', timeRange, isAnalyticsActive }) => {
    const searchRequest = {
      projectId,
      searchText,
      timeRange: { from: timeRange.min, to: timeRange.max },
    };
    return axiosInstance
      .post(`${this.URL}/facebook-integration/searchAds`, searchRequest)
      .then(async (response) => {
        if (response.status !== 200 || !response.data.success) {
          throw Error('Fetching Facebook ad search failed');
        }
        return response.data;
      })
      .catch((e) => {
        console.error('Unable to complete Facebook ads search: ', e);
      });
  };

  getSessionDetails = ({ projectId, sessionID, filters, data, axiosSource = null }) => {
    const formData = new FormData();
    addObjectToFormData(filters, 'filters', formData);
    const options = {};
    if (axiosSource) {
      options.cancelToken = axiosSource.token;
    }
    return axiosInstance
      .post(`${this.URLAnalytics}/sessions/single/${sessionID}`, formData, {
        headers: {
          'X-Project-Id': projectId,
        },
        options,
      })
      .then((response) => {
        const parsedEvents = serializer.deserialize(TrackerEventType, response.data.events);
        const parsedSteps = serializer.deserialize(TrackerStepType, response.data.steps);
        const parsedProfile = serializer.deserialize(TrackerProfileType, response.data.profile);

        return {
          response: {
            events: parsedEvents,
            steps: parsedSteps,
            profile: parsedProfile,
          },
          data: data,
        };
      })
      .catch((e) => {
        console.log(e);
      });
  };

  getFacebookCampaignSpend = async ({ projectId, dateRange }) => {
    try {
      const response = await axiosInstance.get(
        `${this.URL}/facebook-integration/${projectId}/adSpend`,
        {
          params: {
            level: adType.CAMPAIGN,
            dateStart: dateRange.dateStart,
            dateStop: dateRange.dateStop,
          },
        },
      );
      return response.data;
    } catch (e) {
      console.log('Error for getting facebook data');
    }
  };

  getFacebookAdSpend = async ({ projectId, campaignId, dateRange }) => {
    try {
      const response = await axiosInstance.get(
        `${this.URL}/facebook-integration/${projectId}/adSpend`,
        {
          params: {
            campaignId,
            level: adType.AD,
            dateStart: dateRange.dateStart,
            dateStop: dateRange.dateStop,
          },
        },
      );
      return response.data;
    } catch (e) {
      console.error('Error getting facebook spend data', e);
    }
  };

  sendTrackDataToHeap = ({ projectId, eventName, eventData, funnelId }) => {
    /**
     * Dashboard API will create the following data for the HEAP tracking with
     * projectId, eventName, and user's access token. Data not listed below 
     * needs to be attached
     * as an object to the eventData.
     *  
     *  identity,
        event,
        properties: {
          accountId,
          email,
          subscriptionPlan
          workspace,
          ownerAccountId,
          ownerSubscriptionPlan,
          ownerEmail,
          ownerTrialStartDate,
          ownerLastPaymentDate,
          lastPaymentDate,
          trailStartDate,
          ...eventData,
        }
    */
    const data = { projectId, event: eventName, eventData, funnelId };
    return axiosInstance
      .post(`${this.URL}/heap-integration/track`, data, {})
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(`Error occurred sending ${eventName} data on Heap: `, error);
      });
  };

  uploadCustomIconImage = async (id, file, uploadPath = 'icons/issue') => {
    const projectId = FunnelProperties.getProjectId();
    const data = { id };

    if (projectId) {
      data.projectId = projectId;
    }

    return axiosInstance
      .post(`${this.URL}/${uploadPath}`, data)
      .then((response) => {
        const requestOptions = {
          method: 'PUT',
          headers: {
            Method: 'PUT',
            'Content-Type': 'binary/octet-stream',
            'Data-Type': 'text',
            'Process-Data': false,
          },
          body: file,
        };

        // response will contain signed S3 url for PUT command
        return fetch(response.data.url, requestOptions);
      })
      .then(() => {
        const ownerId = FunnelProperties.getOwnerId();
        const token = getAuthToken();
        // URL for getting image, served by Dashboard-API
        const miscIconPrefix = uploadPath.indexOf('misc') !== -1 ? 'misc/' : '';
        const projectIdPrefix = projectId ? `project/${projectId}/` : '';
        const ownerIdPrefix = ownerId ? `owner/${ownerId}/` : '';

        const imageURL = `${this.URL}/icons/signed/${projectIdPrefix}${ownerIdPrefix}${miscIconPrefix}${id}?token=${token}`;

        return {
          imageURL,
          fileID: id,
          fileName: file.name,
        };
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
  };

  addIconToList = async (fileID, fileName, imageURL, uploadType) => {
    const data = {
      attributes: {
        name: fileName,
        path: imageURL,
        type: uploadType.toString().toLowerCase(),
      },
      id: fileID,
      type: 'icons',
    };
    return axiosInstance
      .post(`${this.URL}/icons`, { data })
      .then((response) => {
        if (!response || !response.data || !response.data.data || !response.data.data.attributes) {
          return response;
        }

        response.data.data.attributes.path = imageURL;

        return response;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
  };

  getIconsList = async () => {
    return axiosInstance
      .get(`${this.URL}/icons`)
      .then((response) => {
        if (!response || !response.data || !response.data.data) {
          return response;
        }

        const token = getAuthToken();

        response.data.data.forEach((iconObject) => {
          if (iconObject.attributes) {
            const path = getUpdatedPhotoPath(iconObject.attributes.path);

            iconObject.attributes.path = `${this.URL}/${path}?token=${token}`;
          }
        });

        return response;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
  };

  deleteIcon = async (iconID) => {
    return axiosInstance
      .delete(`${this.URL}/icons/${iconID}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
  };

  getPeopleAnalytics = (projectId, data, axiosSource) => {
    const options = {};
    if (axiosSource) {
      options.cancelToken = axiosSource.token;
    }

    return axiosInstance
      .post(`${this.URLAnalytics}/people`, data, {
        headers: {
          'X-Project-Id': projectId,
        },
        options,
      })
      .then(async (response) => {
        const sessions = await serializer.deserializeAsync(TrackerSessionType, response.data);
        return {
          data: {
            meta: {
              count: response.data.meta.count,
            },
            sessions: sessions
              .filter((session) => session.profile)
              .map((session) => {
                const countryNameAttr = session.profile.attrs.find((a) => a.key === 'country_name');
                const countryCodeAttr = session.profile.attrs.find((a) => a.key === 'country_code');
                const regionNameAttr = session.profile.attrs.find((a) => a.key === 'region_name');
                const fullNameAttr = session.profile.attrs.find((a) => a.key === 'name');
                const emailAttr = session.profile.attrs.find((a) => a.key === 'email');
                const city = session.profile.attrs.find((a) => a.key === 'city');
                const device = session.device;

                return {
                  id: session.id,
                  intId: session.int_id,
                  createdAt: new Date(session.created_at),
                  userId: session.profile.id,
                  device,
                  fullName: fullNameAttr && fullNameAttr.value,
                  email: emailAttr && emailAttr.value,
                  country: {
                    name: countryNameAttr && countryNameAttr.value,
                    code: countryCodeAttr && countryCodeAttr.value,
                    city: city && city.value,
                    regionName: regionNameAttr && regionNameAttr.value,
                  },
                };
              }),
          },
          success: true,
        };
      })
      .catch((error) => {
        console.log(error);
        commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
          errorMSG: ERROR_MESSAGES.SESSION_PEOPLE_DATA_ERROR,
        });
        return {
          success: false,
        };
      });
  };

  getPeopleAnalyticsCsv = (projectId, data) => {
    return axiosInstance
      .post(`${this.URLAnalytics}/people/export`, data, {
        headers: {
          'X-Project-Id': projectId,
        },
      })
      .then((res) => {
        return {
          success: true,
          data: res.data,
        };
      })
      .catch((error) => {
        commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
          errorMSG: 'Error downloading People data for CSV',
        });
        return {
          success: false,
        };
      });
  };

  hasFunnelAccess = (funnelId) => {
    const data = { id: funnelId };
    return axiosInstance
      .post(`${this.URL}/funnels/can-access`, data)
      .then((resp) => {
        return {
          success: true,
          data: resp.data.data,
        };
      })
      .catch((err) => ({ success: false, err: err }));
  };

  getIsPrivate = (funnelId) => {
    return axiosInstance
      .get(`${this.URL}/funnels/get-access/${funnelId}`)
      .then((response) => {
        return {
          success: true,
          is_private: response.data.is_private,
          is_tutorial: response.data.is_tutorial,
        };
      })
      .catch((err) => ({ success: false, err, is_private: null }));
  };

  setIsPrivate = (funnelId, data, controller) => {
    return axiosInstance
      .patch(`${this.URL}/funnels/set-access/${funnelId}`, data, { signal: controller.signal })
      .then((resp) => {
        return { success: true, is_private: resp.data.is_private };
      })
      .catch((err) => ({ success: false, err }));
  };

  /** Tutorial */
  getAllAcknowledgements = (taskNames) => {
    return axiosInstance
      .get(`${this.URL}/user-acknowledgements`, {
        params: {
          acknowledgements: taskNames,
        },
        paramsSerializer: (params) => {
          return QueryString.stringify(params);
        },
      })
      .then((resp) => ({ success: true, data: resp.data }))
      .catch((err) => ({ success: false, err }));
  };

  setAcknowledgement = (taskName, email, hubspotProperty) => {
    return axiosInstance.post(`${this.URL}/user-acknowledgements/${taskName}`, {
      has_acknowledged: true,
      email,
      hubspotProperty,
    });
  };

  checkHasAnalyticsData = (projectId) => {
    return axiosInstance
      .get(`${this.URLAnalytics}/check/exists/data/${projectId}`)
      .then((resp) => ({ ...resp.data, success: true }))
      .catch((err) => ({ success: false, err }));
  };

  getSharedAndLibraryCanvas = (funnelId) => {
    return axiosInstance
      .get(`${this.URL}/library/funnel/${funnelId}`)
      .then((response) => {
        return { success: true, data: response.data };
      })
      .catch((err) => ({ success: false, err }));
  };

  setLibraryCanvas = (funnelId, data) => {
    /**
     * @param {string} funnelId
     * @param {object} data
     */
    return axiosInstance
      .patch(`${this.URL}/library/${funnelId}`, data)
      .then((resp) => {
        return { success: true, data: resp.data };
      })
      .catch((err) => ({ success: false, err }));
  };

  setSharedCanvas = (funnelId, data) => {
    /**
     * @param {string} funnelId
     * @param {object} data
     */
    return axiosInstance
      .patch(`${this.URL}/funnels/${funnelId}`, data)
      .then((resp) => {
        return { success: true, data: resp.data.data };
      })
      .catch((err) => ({ success: false, err }));
  };

  newLibraryCanvas = (data) => {
    /**
     * @param {string} funnelId
     * @param {object} data
     * @param {string} data.funnel - funnel properties
     * @param {string} data.canvasLibrary - canvas library properties
     */
    return axiosInstance
      .post(`${this.URL}/library`, data)
      .then((resp) => {
        return { success: true, data: resp.data };
      })
      .catch((err) => ({ success: false, err }));
  };

  createReadOnlyLink = async (funnelId, password = null) => {
    return axiosInstance
      .post(`${this.URL}/funnel-viewonly-links`, {
        funnelId,
        password,
      })
      .then((resp) => {
        return { success: true, data: resp.data };
      })
      .catch((err) => ({ success: false, err }));
  };

  validateReadOnlyLink = async (funnelId) => {
    // GET /funnel-viewonly-links/:funnelId
    return axiosInstance
      .get(`${this.URL}/funnel-viewonly-links/${funnelId}`)
      .then((response) => {
        return { success: true, data: response.data };
      })
      .catch((err) => ({ success: false, err }));
  };

  updateReadOnlyLink = async (funnelId, password) => {
    const data = {};
    if (password !== undefined) {
      data.password = password;
    }
    return axiosInstance
      .patch(`${this.URL}/funnel-viewonly-links/${funnelId}`, data)
      .then((resp) => {
        return { success: true, data: resp.data };
      })
      .catch((err) => ({ success: false, err }));
  };

  deleteReadOnlyLink = async (funnelId) => {
    return axiosInstance
      .delete(`${this.URL}/funnel-viewonly-links/${funnelId}`)
      .then((resp) => {
        return { success: true, data: resp.data };
      })
      .catch((err) => ({ success: false, err }));
  };

  authViewOnly = async (funnelId, password) => {
    return axiosInstance
      .post(`${this.URL}/auth/view-only`, {
        funnelId,
        password,
      })
      .then((resp) => {
        return { success: true, data: resp.data };
      })
      .catch((err) => ({ success: false, err }));
  };

  authTutorial = async (funnelId) => {
    return axiosInstance
      .post(`${this.URL}/auth/tutorial-mode`, {
        funnelId,
      })
      .then((resp) => {
        return { success: true, data: resp.data };
      })
      .catch((err) => ({ success: false, err }));
  };
}

export default RequestService;
