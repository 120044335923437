import AppSignals from 'pixi-project/signals/AppSignals';
import Utils from 'pixi-project/utils/Utils';
import * as PIXI from 'pixi.js';
import Styles, {
  COLOR_WIDGET_HEADER,
  COLOR_WIDGET_OUTLINE,
  COLOR_WIDGET_ZEBRA,
} from '../../Styles';
import TableViewScrollBar from './TableViewScrollBar';
import TableViewSortLabel from './TableViewSortLabel';
import TableViewSubTitled from './TableViewSubTitled';

// DELEGATE
// onTableLoadMore(tableView)

export default class TableViewScroll extends PIXI.Container {
  constructor(rowsClass = null) {
    super();

    this.rowClass = rowsClass;

    this.delegate = null;
    this.loader = null;
    this.loaderAnimation = null;
    this.barStyle = {};
    this.selectedIndices = {};

    this.initProperties();

    this.background = new PIXI.Graphics();
    this.addChild(this.background);

    this.tableRows = new PIXI.Container();
    this.addChild(this.tableRows);

    this.tableRows.mask = new PIXI.Graphics();
    this.tableRows.addChild(this.tableRows.mask);

    this.scrollBar = new TableViewScrollBar(this, this.barStyle);
    this.scrollBar.position.set(0, 0);
    this.addChild(this.scrollBar);

    this.dynamicWidthAvailable = 0;
    this.roundCornerRadius = 16;

    this.attachEventListeners();
  }

  attachEventListeners() {
    this.interactive = true;
    this.on('added', this.onAddedToParent.bind(this));
    this.on('removed', this.onRemoveFromParent.bind(this));
    this.on('pointerdown', this.onPointerDown.bind(this));
    this.on('pointermove', this.onPointerMove.bind(this));
    this.on('pointerup', this.onPointerUp.bind(this));
    this.on('pointerupoutside', this.onPointerUpOutside.bind(this));
    this.on('pointercancel', this.onPointerCancel.bind(this));
    this.on('pointerover', this.onPointerOver.bind(this));
    this.on('pointerout', this.onPointerOut.bind(this));
  }

  initProperties() {
    this.zebraColor = COLOR_WIDGET_ZEBRA;
    this.isHovered = false;
    this.isLoading = false;
    this.hasMoreDataToLoad = true;
    this.pageSize = 0;
    this.columnSpacing = 0;
    this.bottomSpace = 0;
    this.barStyle.width = 5;
    this.barStyle.padding = 5;
    this.barStyle.color = 0x4b4b4b;
    this.barStyle.alpha = 0.5;
    this.barStyle.radius = 5;
    this.barStyle.endPadding = 0;

    this.tableSize = {
      width: 0,
      height: 0,
    };

    this.rowSize = {
      width: 0,
      height: 0,
    };

    this.headerHeight = 0;

    this.headerData = [];
    this.rowsData = [];

    if (this.rows) {
      this.clearRows();
    }

    if (this.titles) {
      this.clearTitles();
    }

    this.titles = [];
    this.rows = [];

    this.isScrollable = true;
    this.isMouseDown = false;
    this.touchTimer = 0;
    this.offsetY = 0;
    this.previousFrameOffsetY = 0;
    this.scrollingSpeed = 0;
    this.friction = 0.05; // 5%
    this.rowSelectedIndex = 0;
    this.tappedRow = null;
    this.previousY = 0;
    this.toleranceDistance = 5;
  }

  resetToDefaults() {
    // just an alias so that the code makes sense when using this method in different scenarios
    this.initProperties();
  }

  addLoader(loader, animation) {
    this.loader = loader;
    this.loaderAnimation = animation;
    this.tableRows.addChild(this.loader);
    this.hideLoader();
  }

  initRows() {
    this.clearRows();

    for (let i = 0; i < this.pageSize + 1; i++) {
      const row = new this.rowClass(this.headerData, this.rowSize, this.columnSpacing);
      row.delegate = this;
      this.tableRows.addChild(row);
      this.rows.push(row);
    }
  }

  clearRows() {
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      row.removeFromParent();
    }
    this.rows = [];
  }

  clearTitles() {
    for (let i = 0; i < this.titles.length; i++) {
      const titleLabel = this.titles[i];
      titleLabel.removeFromParent();
    }
    this.titles = [];
  }

  renderTable() {
    if (!this.rows.length) {
      this.initRows();
    }

    this.isScrollable = this.pageSize < this.rowsData.length;

    this.hitArea = new PIXI.Rectangle(0, 0, this.tableSize.width, this.tableSize.height);
    this.tableRows.y = this.headerHeight;

    // round corners
    this.tableRows.mask.clear();
    this.tableRows.mask.beginFill();

    if (this.roundCornerRadius) {
      this.tableRows.mask.drawRoundedRect(
        0,
        0,
        this.tableSize.width,
        this.rowSize.height * this.pageSize,
        this.roundCornerRadius,
      );

      this.tableRows.mask.drawRect(
        0,
        0,
        this.tableSize.width,
        (this.rowSize.height * this.pageSize) / 2,
      );
    } else {
      this.tableRows.mask.drawRect(0, 0, this.tableSize.width, this.rowSize.height * this.pageSize);
    }

    this.tableRows.mask.endFill();

    this.scrollBar.y = this.tableRows.y;
    this.scrollBar.x = this.rowSize.width - this.barStyle.padding;

    this.renderHeader();

    if (this.loader) {
      this.hideLoader();
    }

    const hasData = this.rowsData.length > 0;

    this.tableRows.visible = hasData;
    this.scrollBar.visible = hasData && this.isScrollable;

    if (!this.isScrollable) {
      for (let i = 0; i < this.rows.length; i++) {
        const row = this.rows[i];
        row.visible = false;
      }
    }
  }

  renderHeader() {
    if (!this.headerData) {
      return;
    }

    let x = this.columnSpacing;

    this.clearTitles();

    for (let i = 0; i < this.headerData.length; i++) {
      const headerData = this.headerData[i];
      const titleLabel = this.headerLabelFactory(headerData);
      this.titles.push(titleLabel);
      this.addChild(titleLabel);
      const headerBounds = titleLabel.getLocalBounds();

      if (headerData.sorting) {
        titleLabel.sortingIndex = i;
      }

      let segmentWidth = headerData.calculatedWidth;

      if (headerData.align === 'left') {
        titleLabel.x = x;
      } else if (headerData.align === 'right') {
        titleLabel.x = x + segmentWidth - headerBounds.width;
      } else {
        // center
        titleLabel.x = x + segmentWidth / 2 - headerBounds.width / 2;
      }

      titleLabel.y = this.headerHeight / 2 - headerBounds.height / 2;
      x += segmentWidth + this.columnSpacing;
    }

    this.background.clear();
    this.background.lineStyle(1, COLOR_WIDGET_OUTLINE, 1);
    this.background.moveTo(0, this.headerHeight);
    this.background.lineTo(this.tableSize.width, this.headerHeight);
  }

  headerLabelFactory(headerData) {
    if (headerData.sorting) {
      const titleLabel = new TableViewSortLabel(
        headerData.title,
        Styles.WIDGET_TITLE_LABEL,
        COLOR_WIDGET_HEADER,
        this,
      );
      return titleLabel;
    } else if (headerData.subTitle) {
      const titleLabel = new TableViewSubTitled(
        headerData.title,
        headerData.subTitle,
        Styles.WIDGET_TITLE_LABEL,
        headerData.subTitleStyle,
        COLOR_WIDGET_HEADER,
        headerData.subTitleColor,
      );
      return titleLabel;
    } else {
      const titleLabel = new PIXI.BitmapText(headerData.title, Styles.WIDGET_TITLE_LABEL);
      titleLabel.tint = COLOR_WIDGET_HEADER;
      return titleLabel;
    }
  }

  onSortLabelClick(label, sorting) {
    // reset all labels sorting status
    for (let i = 0; i < this.titles.length; i++) {
      const titleLabel = this.titles[i];
      if (titleLabel instanceof TableViewSortLabel) {
        titleLabel.reset();
      }
    }

    // restore the sorting state for the clicked label
    label.setSorting(sorting);

    if (this.delegate && this.delegate.onSortingClick) {
      this.delegate.onSortingClick(label, sorting);
    }
  }

  setHeaderData(headerData) {
    this.headerData = headerData;

    if (!this.headerData) {
      return;
    }

    // Calculate the available space
    this.dynamicWidthAvailable = this.tableSize.width;
    for (let i = 0; i < this.headerData.length; i++) {
      const data = this.headerData[i];
      if (data.width > 1) {
        this.dynamicWidthAvailable -= data.width + this.columnSpacing;
      }
    }
    this.dynamicWidthAvailable -= this.columnSpacing; // subtract the end padding

    // Calculate the absolute widts of the columns
    for (let i = 0; i < this.headerData.length; i++) {
      const data = this.headerData[i];
      if (data.width > 1) {
        data.calculatedWidth = data.width;
      } else {
        data.calculatedWidth = data.width * this.dynamicWidthAvailable - this.columnSpacing;
      }
    }
  }

  reIndexData() {
    for (let i = 0; i < this.rowsData.length; i++) {
      const rd = this.rowsData[i];
      rd._id = i;
    }
  }

  setRowsData(rowsData) {
    //set Index
    this.rowsData = rowsData;
    this.reIndexData();
    this.scrollBar.refreshSize();
    this.scrollBar.updatePosition();
  }

  addRowsData(rowsData) {
    this.rowsData = this.rowsData.concat(rowsData);
    this.reIndexData();
    this.isLoading = false;
    this.hideLoader();
    this.scrollBar.refreshSize();
    this.scrollBar.updatePosition();
  }

  onRemoveFromParent() {
    AppSignals.onTickerUpdate.remove(this.onTicker, this);
  }

  onAddedToParent() {
    AppSignals.onTickerUpdate.add(this.onTicker, this);
  }

  onTicker() {
    if (this.visible) {
      this.onUpdate(window.app.ticker.elapsedMS);
    }

    if (this.isLoading && this.loader && this.loader.visible) {
      this.loaderAnimation(this.loader, window.app.ticker.elapsedMS);
    }
  }

  onUpdate(dt) {
    this.touchTimer += dt;

    if (this.touchTimer > 40 && this.isMouseDown) {
      if (this.tappedRow && !this.tappedRow.isMouseDown && !this.tappedRow.hasMoved) {
        this.tappedRow.onPointerDown(null, this);
      }
    }

    if (!this.rowsData.length) {
      return;
    }

    let current_offset = this.offsetY - this.previousFrameOffsetY;

    if (current_offset !== 0) {
      this.scrollingSpeed = (this.scrollingSpeed + current_offset / dt) / 2;
    }

    if (this.scrollingSpeed > 0.02 || this.scrollingSpeed < -0.02) {
      if (!this.isMouseDown) {
        this.scrollY(this.scrollingSpeed * dt);
      }

      this.scrollingSpeed = this.scrollingSpeed - this.scrollingSpeed * this.friction;
    } else if (this.scrollingSpeed < 0.02 && this.scrollingSpeed > -0.02) {
      this.scrollingSpeed = 0.0;
    }

    this.previousFrameOffsetY = this.offsetY;

    this.preProcessBinding();
    this.processBinding();
  }

  rebindRows() {
    // reset the binding
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      row._id = -1;
    }

    this.preProcessBinding();
    this.processBinding();
  }

  getContentSize() {
    return {
      width: this.rowSize.width,
      height: this.rowSize.height * this.rowsData.length + this.bottomSpace,
    };
  }

  scrollToBottom() {
    this.offsetY = this.getBottomY();
    this.scrollY(0);
  }

  getBottomY() {
    let visibleAreaHeight = this.rowSize.height * this.pageSize;
    return this.rowsData.length * this.rowSize.height - visibleAreaHeight + this.bottomSpace;
  }

  scrollY(y) {
    if (!this.isScrollable) {
      this.offsetY = 0;
      return true;
    }

    let bottomY = this.getBottomY();

    if (this.offsetY + y < 0) {
      this.offsetY = 0;
      this.scrollingSpeed = 0;
      this.onScrolledToTop();
    } else if (this.offsetY + y >= bottomY) {
      this.offsetY = bottomY;
      this.scrollingSpeed = 0;
      this.onScrolledToBottom();
    } else {
      this.offsetY += y;
    }

    window.app.needsRendering();
    this.scrollBar.updatePosition();
  }

  onScrolledToTop() {
    // hit the top
  }

  onScrolledToBottom() {
    // hit the bottom
  }

  preProcessBinding() {
    // This will go through the entiere data , and check if any rows needs rebinding
    // if it needs rebiding , it will check if it can reuse some rows , and reorders the rows
    // in such a was to match existing data
    // then at the end , it will fill the gap for the rows where no matching data was found.

    let firstIndex = Math.floor(this.offsetY / this.rowSize.height);
    let firstRow = this.rows[0];

    if (
      firstRow &&
      this.rowsData[firstIndex] &&
      !(firstRow._id !== this.rowsData[firstIndex]._id)
    ) {
      return;
    }

    let pos = 0;
    let orderedRows = [];
    let freeIndices = [];

    for (let i = firstIndex; i < firstIndex + this.pageSize + 1; i++) {
      let foundRow = null;

      for (let j = this.rows.length - 1; j >= 0; j--) {
        let row = this.rows[j];

        if (this.rowsData[i] && this.rowsData[i]._id === row._id) {
          foundRow = row;
          this.rows.splice(j, 1); // remove that one
          break;
        }
      }

      if (foundRow) {
        orderedRows[pos] = foundRow;
      } else {
        freeIndices.push(pos);
      }

      pos++;
    }

    for (let i = 0; i < freeIndices.length; i++) {
      orderedRows[freeIndices[i]] = this.rows[i];
    }

    this.rows = orderedRows;
  }

  processBinding() {
    let firstIndex = Math.floor(this.offsetY / this.rowSize.height);
    let localOffsetY = this.offsetY % this.rowSize.height; // there is a rounding error
    let pos = 0;

    let isBottomRendered = false;

    for (let i = firstIndex; i < firstIndex + this.pageSize + 1; i++) {
      let row = this.rows[pos];
      row.index = i;
      row.position.set(0, pos * this.rowSize.height - localOffsetY);

      if (i === this.rowsData.length) {
        row._id = null;
        row.visible = false;
        this.onBottomRender(row.position);
        isBottomRendered = true;
        break;
      }

      if (this.selectedIndices[row.index] !== undefined) {
        if (this.selectedIndices[row.index] !== row.isSelected) {
          if (row.isSelected) {
            row.deselect();
          } else {
            row.select();
          }
        }
      } else if (row.isSelected) {
        row.deselect();
      }

      if (this.rowsData[i] && this.rowsData[i]._id !== row._id) {
        row._id = this.rowsData[i]._id;
        row.bindData(this.rowsData[i]);
        const isEven = i % 2 == 0;
        row.isEven = isEven;
        row.updateBackgroundColor();
        row.visible = true;
      }

      pos++;
    }

    if (!isBottomRendered) {
      this.onBottomNotRendering();
    }
  }

  onPointerDown(event) {
    this.isMouseDown = true;
    this.previousY = event.data.global.y;
    this.tappedLocation = event.data.global.clone();
    this.tappedRow = null;

    this.touchTimer = 0;

    for (let i = 0; i < this.rows.length; i++) {
      let row = this.rows[i];
      let bounds = row.getBounds();
      if (row.visible && bounds.contains(this.tappedLocation.x, this.tappedLocation.y)) {
        this.tappedRow = row;
      }
    }

    if (this.tappedRow) {
      event.stopPropagation();
    }

    return false;
  }

  onPointerMove(event) {
    if (this.isMouseDown) {
      let distance = Utils.distanceAB(this.tappedLocation, event.data.global);

      let change = this.previousY - event.data.global.y;
      this.previousY = event.data.global.y;

      if (distance > this.toleranceDistance) {
        this.scrollY(change);
      }

      if (this.tappedRow) {
        if (
          !(
            this.touchTimer < 200 &&
            Math.abs(this.scrollingSpeed < 0.05) &&
            Math.abs(this.tappedLocation.y - event.data.global.y) < 10
          )
        ) {
          this.tappedRow.onPointerUp(event, this);
        }
        event.stopPropagation();
      }
    }
  }

  onPointerUp(event) {
    if (this.isMouseDown) {
      this.isMouseDown = false;

      if (this.tappedRow && this.tappedRow.interactive) {
        if (this.scrollingSpeed === 0 && this.tappedRow && !this.tappedRow.hasMoved) {
          this.onRowSelected(this.tappedRow, event);
        } else if (
          this.touchTimer < 200 &&
          Math.abs(this.scrollingSpeed < 0.05) &&
          Math.abs(this.tappedLocation.y - event.data.global.y) < 10
        ) {
          this.onRowSelected(this.tappedRow, event);
        }

        this.tappedRow.onPointerUp(event, this);
        event.stopPropagation();
      }
    }
  }

  onPointerCancel(event) {
    if (this.tappedRow) {
      this.tappedRow.onPointerCancel(event, this);
    }
  }

  onPointerUpOutside(event) {
    this.onPointerUp(event);
  }

  onPointerOver(event) {
    this.isHovered = true;
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      const lp = row.toLocal(event.data.global);
      if (row.hitArea && row.hitArea.contains(lp.x, lp.y)) {
        row.onHoverIn(event);
      }
    }
  }

  onPointerOut(event) {
    this.isHovered = false;
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      row.onHoverOut(event);
    }
  }

  onRowSelected(row) {
    this.selectedIndices[row.index] = !row.isSelected;
    if (this.delegate.onRowSelectionChanged) {
      this.delegate.onRowSelectionChanged(row, !row.isSelected);
    }
  }

  onBottomRender(position) {
    if (this.bottomSpace) {
      if (!this.isLoading) {
        if (this.hasMoreDataToLoad) {
          if (this.delegate && this.delegate.onTableLoadMore) {
            this.delegate.onTableLoadMore(this);
            this.isLoading = true;
          }
        }
      } else if (this.hasMoreDataToLoad) {
        if (this.loader) {
          this.loader.x = this.rowSize.width / 2;
          this.loader.y = position.y + this.rowSize.height / 2;
        }
      }
      this.showLoader();
    }
  }

  onBottomNotRendering() {
    if (this.bottomSpace) {
      this.hideLoader();
    }
  }

  showLoader() {
    if (this.loader) {
      this.loader.visible = true;
    }
  }

  hideLoader() {
    if (this.loader && this.loader.visible) {
      this.loader.visible = false;
      window.app.needsRendering();
    }
  }

  onAllDataLoaded() {
    this.isLoading = false;
    this.scrollingSpeed = 0.1; // to force rerendering without the bottomSpacing
    this.hasMoreDataToLoad = false;
    this.bottomSpace = 0;
    this.scrollBar.refreshSize();
    this.hideLoader();
  }

  onWheel(event) {
    let speed = 0.2;
    if (event.deltaY < 0) {
      this.scrollingSpeed += -speed;
    } else {
      this.scrollingSpeed += speed;
    }
  }

  deselect(index) {
    this.selectedIndices[index] = false;
  }

  get bottomSpace() {
    return this.isScrollable ? this._bottomSpace : 0;
  }

  set bottomSpace(value) {
    this._bottomSpace = value;
  }
}
