import React from 'react';

export const TermsConditions = () => {
  return (
    <div style={{ paddingTop: '28px' }}>
      <div style={{ fontSize: '9px', fontWeight: '500' }}> Terms and Conditions </div>
      <div style={{ fontSize: '8px', paddingTop: '4px' }}>
        By submitting this canvas to the Canvas Library this box you confirm that you have read and
        agree to our Terms and Conditions and that the canvas you are sharing in the Canvas Library
        does not contain any harmful or inappropriate content.
      </div>
      <div style={{ fontSize: '8px', paddingTop: '8px' }}>
        Failure to meet the above requirement will result in the removal of the canvas from the
        Canvas Library.
      </div>
    </div>
  );
};
