import { CControlPointTypes } from 'pixi-project/base/containers/CContainerConstants';
import ControlPoint from 'pixi-project/base/containers/ControlPoint';
import Utils from 'pixi-project/utils/Utils';

export const DEFAULT_PENETRATION_HEIGHT = 23;
const DEFAULT_PENETRATION_WIDTH = 12;
const NON_SIMETRY_ANOMALY_PADDING = 3;
const SHIFT_DEVISION_SEGMENT = 3;
const MIN_SHIFT_LIMIT = 30;

export default class ConnectionHelper {
  static GetAngleForHead(gr) {
    switch (gr.type) {
      case CControlPointTypes.TOP:
        return -90;
      case CControlPointTypes.BOTTOM:
        return 90;
      case CControlPointTypes.RIGHT:
        return 0;
      case CControlPointTypes.LEFT:
        return -180;
      default:
        throw Error(`[ConnectionHelper.GetAngleForHead] Incorrect parameters ${gr.type}`);
    }
  }

  static ShiftCoordinates(point, controlPoint) {
    switch (controlPoint.type) {
      case CControlPointTypes.TOP:
        point.y = point.y + DEFAULT_PENETRATION_HEIGHT;
        break;
      case CControlPointTypes.BOTTOM:
        point.y = point.y - DEFAULT_PENETRATION_HEIGHT;
        break;
      case CControlPointTypes.RIGHT:
        point.x = point.x - DEFAULT_PENETRATION_WIDTH;
        break;
      case CControlPointTypes.LEFT:
        point.x = point.x + DEFAULT_PENETRATION_WIDTH + NON_SIMETRY_ANOMALY_PADDING;
        break;
      default:
        throw Error(
          `[ConnectionHelper.ShiftCoordinates] Incorrect parameters ${controlPoint.type}`,
        );
    }

    return point;
  }

  static CalculateShift(type, pointA, pointB) {
    let shiftX = 0,
      shiftY = 0;
    if (type === CControlPointTypes.LEFT || type === CControlPointTypes.RIGHT) {
      shiftX = (pointB.x - pointA.x) / SHIFT_DEVISION_SEGMENT;
      shiftX = ConnectionHelper.ClampMaxValue(shiftX, MIN_SHIFT_LIMIT);
    }
    if (type === CControlPointTypes.TOP || type === CControlPointTypes.BOTTOM) {
      shiftY = (pointB.y - pointA.y) / SHIFT_DEVISION_SEGMENT;
      shiftY = ConnectionHelper.ClampMaxValue(shiftY, MIN_SHIFT_LIMIT);
    }
    return { shiftX, shiftY };
  }

  static ClampMaxValue(value, limit) {
    if (value < 0) {
      return Math.min(value, -limit);
    }
    return Math.max(value, limit);
  }

  /**
   *
   * @param p0 Starting point (x or y)
   * @param p1 Control point 1 (x or y)
   * @param p2 Control point 2 (x or y)
   * @param p3 End point (x or y)
   * @param distance Fraction (from 0 to 1) of the full length of the curve
   * @returns {number} Coordinate (x or y) of the point on specified distance from the start
   */
  static CalculateBezierPoint(p0, p1, p2, p3, distance) {
    const t = distance;
    return (1 - t) ** 3 * p0 + 3 * (1 - t) ** 2 * t * p1 + 3 * (1 - t) * t * t * p2 + t ** 3 * p3;
  }

  // Use this
  /**
   * Returns the point on a curve for a given fraction [0, 1] on it
   * @param pointA
   * @param controlPointA
   * @param controlPointB
   * @param pointB
   * @param fraction [0, 1]
   * @returns {{x: number, y: number}}
   * @constructor
   */
  static GetBezierPoint(pointA, controlPointA, controlPointB, pointB, fraction) {
    return {
      x: ConnectionHelper.CalculateBezierPoint(
        pointA.x,
        controlPointA.x,
        controlPointB.x,
        pointB.x,
        fraction,
      ),
      y: ConnectionHelper.CalculateBezierPoint(
        pointA.y,
        controlPointA.y,
        controlPointB.y,
        pointB.y,
        fraction,
      ),
    };
  }

  static GetPrescisionBezierPoint(pointA, controlPointA, controlPointB, pointB, t) {
    // shortcuts
    if (t === 0) {
      return pointA;
    }
    if (t === 1) {
      return pointB;
    }

    const mt = 1 - t;

    let mt2 = mt * mt,
      t2 = t * t,
      a,
      b,
      c,
      d = 0;

    a = mt2 * mt;
    b = mt2 * t * 3;
    c = mt * t2 * 3;
    d = t * t2;

    const ret = {
      x: a * pointA.x + b * controlPointA.x + c * controlPointB.x + d * pointB.x,
      y: a * pointA.y + b * controlPointA.y + c * controlPointB.y + d * pointB.y,
    };

    return ret;
  }

  // And use this
  // noinspection JSUnusedGlobalSymbols
  /**
   * Returns the point on a straight line for a given fraction [0, 1] on it
   * @param pointA
   * @param pointB
   * @param fraction
   * @returns {{x: *, y: *}}
   * @constructor
   */
  static GetLinePoint(pointA, pointB, fraction) {
    let x, y;
    x = pointA.x + fraction * (pointB.x - pointA.x);
    y = pointA.y + fraction * (pointB.y - pointA.y);
    return { x, y };
  }

  // TODO use this
  /**
   * Calculates the length of a Bezier Curve
   * @param {Point} pointA
   * @param {Point} controlPointA
   * @param {Point} controlPointB
   * @param {Point} pointB
   * @returns Integer
   */
  static GetLength(pointA, controlPointA, controlPointB, pointB) {
    let startPoint, endPoint;
    const step = 0.01;
    let length = 0;
    for (let i = 0; i < 1; i += step) {
      startPoint = ConnectionHelper.GetBezierPoint(pointA, controlPointA, controlPointB, pointB, i);

      endPoint = ConnectionHelper.GetBezierPoint(
        pointA,
        controlPointA,
        controlPointB,
        pointB,
        i + step,
      );

      length += Utils.distanceAB(startPoint, endPoint);
    }

    return length;
  }

  static DetermineNearestAttachmentPoints(iconA, iconB, localLayer, locations) {
    // Center means that we will auto snap to the nearest point
    // Flow means that we will calculate the intersection with the frame by pointing to the center

    if (
      locations.pointA === CControlPointTypes.CENTER &&
      locations.pointB === CControlPointTypes.CENTER
    ) {
      // A is center and B is center
      return ConnectionHelper.GetNearestPointsBetween(iconA, iconB, localLayer);
    } else if (
      locations.pointA === CControlPointTypes.CENTER &&
      locations.pointB === CControlPointTypes.FLOW
    ) {
      // A is center and B is flow
      return ConnectionHelper.GetFromPointToIntersection(iconA, iconB, localLayer);
    } else if (
      locations.pointA === CControlPointTypes.FLOW &&
      locations.pointB === CControlPointTypes.CENTER
    ) {
      // A is flow and B is center
      const np = ConnectionHelper.GetFromPointToIntersection(iconB, iconA, localLayer);
      return ConnectionHelper.ReverseNearesetPointsResult(np);
    } else if (
      locations.pointA === CControlPointTypes.FLOW &&
      locations.pointB === CControlPointTypes.FLOW
    ) {
      // A is flow and B is flow
      return ConnectionHelper.GetIntersectionToIntersection(iconA, iconB, localLayer);
    } else if (locations.pointA === CControlPointTypes.CENTER) {
      // A is center and B is fixed
      return ConnectionHelper.GetNearPointA(iconA, iconB, localLayer, locations.pointB);
    } else if (locations.pointA === CControlPointTypes.FLOW) {
      // A is flow and B is fixed
      const np = ConnectionHelper.GetFromPointToIntersection(
        iconB,
        iconA,
        localLayer,
        locations.pointB,
      );
      return ConnectionHelper.ReverseNearesetPointsResult(np);
    } else if (locations.pointB === CControlPointTypes.CENTER) {
      // A is fixed and B is center
      return ConnectionHelper.GetNearPointB(iconA, iconB, localLayer, locations.pointA);
    } else if (locations.pointB === CControlPointTypes.FLOW) {
      // A is fixed and B is flow
      return ConnectionHelper.GetFromPointToIntersection(
        iconA,
        iconB,
        localLayer,
        locations.pointA,
      );
    } else {
      // A is fixed and B is fixed
      return ConnectionHelper.GetFixedPointAB(iconA, iconB, localLayer, locations);
    }
  }

  static GetNearPointA(iconA, iconB, localLayer, pointType) {
    let dist = Number.MAX_SAFE_INTEGER;
    const points = { A: null, B: null }; // local positions of the same points

    const result = {
      points: points,
      pointA: null,
      pointB: null,
    };

    let r = ConnectionHelper.GetControlPointByType(iconB, pointType, localLayer);
    points.B = r.nearestPoint;
    result.pointB = r.point;

    for (let i = 0; i < iconA.points.length; i++) {
      let pointA = localLayer.toLocal(iconA.points[i].position, iconA);

      let tDist = Utils.distanceAB(pointA, points.B);
      if (tDist < dist) {
        dist = tDist;
        result.pointA = iconA.points[i];
        points.A = pointA;
      }
    }

    return result;
  }

  static ReverseNearesetPointsResult(data) {
    return {
      points: {
        A: data.points.B,
        B: data.points.A,
      },
      pointA: data.pointB,
      pointB: data.pointA,
    };
  }

  static GetIntersectionFromStaticPoint(staticPoint, iconB, localLayer) {
    // calculations are done in the global coordinate system
    const boundsB = ConnectionHelper.GetBoundsFromElement(iconB);
    const centerB = new PIXI.Point(boundsB.x + boundsB.width / 2, boundsB.y + boundsB.height / 2);

    let nearestGlobal = staticPoint.getGlobalPosition();

    const intersection = Utils.lineSegmentRectangleIntersection(centerB, nearestGlobal, boundsB);

    const cp = new ControlPoint(CControlPointTypes.FLOW);
    cp.position = iconB.toLocal(intersection);

    return {
      nearestPoint: localLayer.toLocal(intersection),
      point: cp,
    };
  }

  static GetFromPointToIntersection(iconA, iconB, localLayer, type = null) {
    let dist = Number.MAX_SAFE_INTEGER;
    const points = { A: null, B: null };

    const result = {
      points: points,
      pointA: null,
      pointB: null,
    };

    // calculations are done in the global coordinate system
    const boundsB = ConnectionHelper.GetBoundsFromElement(iconB);
    const centerB = new PIXI.Point(boundsB.x + boundsB.width / 2, boundsB.y + boundsB.height / 2);

    // lets find nearest point
    let nearestPoint = null;
    let nearestGlobal = null;

    if (type) {
      // Find from fixed point
      let r = ConnectionHelper.GetControlPointByType(iconA, type, localLayer);
      nearestPoint = r.point;
      nearestGlobal = r.point.getGlobalPosition();
    } else {
      // Search for the nearest point
      for (let i = 0; i < iconA.points.length; i++) {
        nearestGlobal = iconA.points[i].getGlobalPosition();
        const tDist = Utils.distanceAB(nearestGlobal, centerB);
        if (tDist < dist) {
          dist = tDist;
          nearestPoint = iconA.points[i];
        }
      }
    }

    // find the intersection with the frame
    const intersection = Utils.lineSegmentRectangleIntersection(centerB, nearestGlobal, boundsB);

    const pointA = localLayer.toLocal(nearestPoint.position, iconA);
    points.A = pointA;
    result.pointA = nearestPoint;

    const cp = new ControlPoint(CControlPointTypes.FLOW);
    cp.position = iconB.toLocal(intersection);
    points.B = localLayer.toLocal(intersection);
    result.pointB = cp;

    return result;
  }

  static GetIntersectionToIntersection(iconA, iconB, localLayer) {
    const points = { A: null, B: null };

    const result = {
      points: points,
      pointA: null,
      pointB: null,
    };

    // calculations are done in the global coordinate system

    const boundsA = ConnectionHelper.GetBoundsFromElement(iconA);
    const centerA = new PIXI.Point(boundsA.x + boundsA.width / 2, boundsA.y + boundsA.height / 2);

    const boundsB = ConnectionHelper.GetBoundsFromElement(iconB);
    const centerB = new PIXI.Point(boundsB.x + boundsB.width / 2, boundsB.y + boundsB.height / 2);

    const intersectionA = Utils.lineSegmentRectangleIntersection(centerA, centerB, boundsA);
    const intersectionB = Utils.lineSegmentRectangleIntersection(centerA, centerB, boundsB);

    const cpA = new ControlPoint(CControlPointTypes.FLOW);
    cpA.position = iconA.toLocal(intersectionA);
    points.A = localLayer.toLocal(intersectionA);
    result.pointA = cpA;

    const cpB = new ControlPoint(CControlPointTypes.FLOW);
    cpB.position = iconB.toLocal(intersectionB);
    points.B = localLayer.toLocal(intersectionB);
    result.pointB = cpB;

    return result;
  }

  static GetBoundsFromElement(element) {
    return element.getAttachmentFrame();
  }

  static GetNearPointB(iconA, iconB, localLayer, pointType) {
    let dist = Number.MAX_SAFE_INTEGER;
    const points = { A: null, B: null }; // local positions of the same points

    const result = {
      points: points,
      pointA: null,
      pointB: null,
    };

    let r = ConnectionHelper.GetControlPointByType(iconA, pointType, localLayer);
    points.A = r.nearestPoint;
    result.pointA = r.point;

    for (let i = 0; i < iconB.points.length; i++) {
      let pointA = localLayer.toLocal(iconB.points[i].position, iconB);

      let tDist = Utils.distanceAB(pointA, points.A);
      if (tDist < dist) {
        dist = tDist;
        result.pointB = iconB.points[i];
        points.B = pointA;
      }
    }

    return result;
  }

  static GetFixedPointAB(iconA, iconB, localLayer, location) {
    const result = {
      points: { A: null, B: null },
      pointA: null,
      pointB: null,
    };

    let ra = ConnectionHelper.GetControlPointByType(iconA, location.pointA, localLayer);
    result.points.A = ra.nearestPoint;
    result.pointA = ra.point;

    let rb = ConnectionHelper.GetControlPointByType(iconB, location.pointB, localLayer);
    result.points.B = rb.nearestPoint;
    result.pointB = rb.point;

    return result;
  }

  static GetNearestPointsBetween(iconA, iconB, localLayer) {
    const iconAPoints = iconA.points;
    const iconBPoints = iconB.points;
    let grA, grB;
    let dist = Number.MAX_SAFE_INTEGER;
    const points = { A: null, B: null };

    for (let i = 0; i < iconAPoints.length; i++) {
      let pointA = localLayer.toLocal(iconAPoints[i].position, iconA);
      for (let j = 0; j < iconBPoints.length; j++) {
        let pointB = localLayer.toLocal(iconBPoints[j].position, iconB);
        let tDist = Utils.distanceAB(pointA, pointB);
        if (tDist < dist) {
          dist = tDist;
          grA = iconAPoints[i];
          grB = iconBPoints[j];
          points.A = pointA;
          points.B = pointB;
        }
      }
    }

    return { points, pointA: grA, pointB: grB };
  }

  static GetNearestControlPoint(iconA, point, localLayer) {
    const iconAPoints = iconA.points;
    let grA;
    let dist = 999999999;
    let nearestPoint = null;

    for (let i = 0; i < iconAPoints.length; i++) {
      let pointA = localLayer.toLocal(iconAPoints[i].position, iconA);
      let pointB = localLayer.toLocal(point.getGlobalPosition());

      let tDist = Utils.distanceAB(pointA, pointB);
      if (tDist < dist) {
        dist = tDist;
        grA = iconAPoints[i];
        nearestPoint = pointA;
      }
    }

    return { nearestPoint, point: grA };
  }

  static GetControlPointByType(iconA, type, localLayer) {
    const point = iconA.getAttachPoint(type);
    const nearestPoint = localLayer.toLocal(point.position, iconA);
    return { nearestPoint, point: point };
  }

  static CalculateControlFirst(pointA, pointB, type) {
    const { shiftX, shiftY } = ConnectionHelper.CalculateShift(type, pointA, pointB);
    return new PIXI.Point(pointA.x + shiftX, pointA.y + shiftY);
  }

  static CalculateControlSecond(pointA, pointB, type) {
    const { shiftX, shiftY } = ConnectionHelper.CalculateShift(type, pointA, pointB);
    return new PIXI.Point(pointB.x - shiftX, pointB.y - shiftY);
  }

  // # https://pomax.github.io/bezierinfo/#pointvectors
  static GetCubicDerivative(t, points) {
    let mt = 1 - t,
      a = mt * mt,
      b = 2 * mt * t,
      c = t * t,
      d = [
        {
          x: 3 * (points[1].x - points[0].x),
          y: 3 * (points[1].y - points[0].y),
        },
        {
          x: 3 * (points[2].x - points[1].x),
          y: 3 * (points[2].y - points[1].y),
        },
        {
          x: 3 * (points[3].x - points[2].x),
          y: 3 * (points[3].y - points[2].y),
        },
      ];

    return {
      x: a * d[0].x + b * d[1].x + c * d[2].x,
      y: a * d[0].y + b * d[1].y + c * d[2].y,
    };
  }

  static GetNormal(d) {
    const q = Math.sqrt(d.x * d.x + d.y * d.y);
    return { x: -d.y / q, y: d.x / q };
  }

  static GetBezierNormal(a, ca, cb, b, fraction, distance) {
    const p = ConnectionHelper.GetBezierPoint(a, ca, cb, b, fraction);
    let d = ConnectionHelper.GetCubicDerivative(fraction, [a, ca, cb, b]);

    const m = Math.sqrt(d.x * d.x + d.y * d.y);
    d = { x: d.x / m, y: d.y / m };
    let n = ConnectionHelper.GetNormal(d);

    return new PIXI.Point(p.x + n.x * distance, p.y + n.y * distance);
  }
}
