import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import Header from 'react-project/Header/Header';
import Main from 'react-project/Main/Main';
import NoAccess from 'react-project/Main/NoAccess';
import {
  asyncFetchCanvasHasViewOnly,
  asyncFetchIsLinkPrivate,
} from 'react-project/Sharing/shareCanvasSlice';
import { When } from 'react-project/Util/When';
import { hasAnalyticsDataAsync } from 'react-project/redux/analytics/actions';
import { selectHasAnalyticsData } from 'react-project/redux/analytics/selectors';
import { asyncHasAccessToFunnel, setAccessToFunnel } from 'react-project/redux/auth/actions';
import { selectUserId } from 'react-project/redux/auth/selectors';
import { selectCustomIcons } from 'react-project/redux/custom-icons/selectors';
import {
  loadAllCanvasPermissionsAsync,
  loadPermissionAsync,
  loadUserData,
} from 'react-project/redux/user/actions';
import { selectCanvasPermissions, selectEmail } from 'react-project/redux/user/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { RP_EVENT_CUSTOM_ICONS_LOADED } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { identify as identifyCohesive } from '../Util/CohesiveTracking';
import { getUserId } from 'react-project/Util/AuthCookie';
import * as Visibility from 'visibilityjs';
import { logout as logoutAction } from 'react-project/redux/auth/actions';

export const Funnel = (props) => {
  const match = props.match;
  const [isLeftSideBarCollapsed, setLeftSideBarCollapsed] = useState(false);
  const [isMessageErrorShowing, setIsMessageErrorShowing] = useState(false);
  const [messageErrorData, setMessageErrorData] = useState({});
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);
  const customIcons = useSelector(selectCustomIcons);
  const projectId = useSelector((state) => state.funnels[match.params.funnelId]?.projectId);
  const hasAccessToFunnel = useSelector((state) => state.auth.hasAccessToFunnel);
  const accessToken = props.accessToken;
  const canvasPermissions = useSelector(selectCanvasPermissions);
  const hasAnalyticsData = useSelector(selectHasAnalyticsData);
  const [isUsetifullTagsExecuted, setIsUsetifullTagsExecuted] = useState(false);
  const userEmail = useSelector(selectEmail);

  const cookieUserId = getUserId();
  const isLoggedIn = !!cookieUserId;

  useEffect(() => {
    if (
      !isEmpty(canvasPermissions) &&
      !canvasPermissions.isTutorial &&
      userId &&
      hasAnalyticsData !== null &&
      !isUsetifullTagsExecuted &&
      userEmail
    ) {
      setUsetifulTags();
    }

    if (!isEmpty(canvasPermissions) && canvasPermissions.isTutorial === true) {
      if (!isLoggedIn) {
        setUsetifulTags();
      } else if (userId) {
        // email is not available in this case
        setUsetifulTags();
      }
    }
  }, [canvasPermissions, userId, hasAnalyticsData, userEmail]);

  const onVisibilityChange = (e, state) => {
    if (!Visibility.hidden()) {
      const cookieUserId = getUserId();
      const isLoggedIn = !!cookieUserId;
      // stay for view only link or tutorial
      if (!isLoggedIn && !props.isTutorial && !props.isViewOnlyLink) {
        dispatch(logoutAction());
      }
    }
  };

  useEffect(() => {
    const listener = Visibility.change(onVisibilityChange);
    return () => {
      Visibility.unbind(listener);
    };
  }, [props]);

  useEffect(() => {
    // Load chat support permission
    // TODO see if this is needed at all

    // If we try to open some menu that will be opened outside the viewport,
    // it will cause the application to be misplaced , due to scrolling
    // So we need to make sure that the application viewport does not scroll
    const element = document.getElementById('application-container');
    element.addEventListener('scroll', (e) => {
      if (e.target.scrollTop != 0 || e.target.scrollLeft != 0) {
        e.target.scrollTop = 0;
        e.target.scrollLeft = 0;
      }
    });

    if (accessToken) {
      // View only Mode / Tutorial Mode
      dispatch(setAccessToFunnel(true));
      dispatch(
        loadAllCanvasPermissionsAsync(
          match.params.funnelId,
          props.isTutorial,
          props.isViewOnlyLink,
        ),
      );
      dispatch(asyncFetchIsLinkPrivate({ funnelId: match.params.funnelId })); // For ShareLink. Request when canvas is loading.
    } else {
      dispatch(
        loadPermissionAsync({
          scopeId: userId,
          permission: 'feature.chat-support',
          scope: 'user',
          funnelId: match.params.funnelId,
          level: 'read',
        }),
      );
      dispatch(asyncHasAccessToFunnel(match.params.funnelId));
      dispatch(
        loadAllCanvasPermissionsAsync(
          match.params.funnelId,
          props.isTutorial,
          props.isViewOnlyLink,
        ),
      );
      dispatch(loadUserData({ userId }));
      dispatch(asyncFetchIsLinkPrivate({ funnelId: match.params.funnelId })); // For ShareLink. Request when canvas is loading.
      dispatch(asyncFetchCanvasHasViewOnly({ funnelId: match.params.funnelId })); // For ViewOnly. Request when canvas is loading.
    }
  }, []);

  useEffect(() => {
    if (canvasPermissions === false) {
      localStorage.setItem(`view-only-auth-${props.match.params.funnelId}`, undefined);
    }
  }, [canvasPermissions]);

  useEffect(() => {
    if (!projectId) return;
    dispatch(hasAnalyticsDataAsync(projectId));
  }, [projectId]);

  useEffect(() => {
    commonSendEventFunction(RP_EVENT_CUSTOM_ICONS_LOADED, customIcons);
  }, [customIcons]);

  useEffect(() => {
    if (userId) {
      identifyCohesive(userId);
    }
  }, [userId]);

  const setUsetifulTags = () => {
    if (isUsetifullTagsExecuted) {
      return;
    }

    setIsUsetifullTagsExecuted(true);

    window.usetifulTags = {
      userId: userId,
      firstName: null,
      isAnalyticsAllowed: canvasPermissions.isAnalyticsAllowed,
      isActionsAllowed: canvasPermissions.isActionsAllowed,
      isAdminImpersonation: canvasPermissions.isAdminImpersonation,
      isReadonlyAccess: canvasPermissions.isReadonlyAccess,
      isCollaborator: canvasPermissions.isCollaborator,
      isEligibleForFreeTrial: canvasPermissions.isEligibleForFreeTrial,
      isTutorial: canvasPermissions.isTutorial,
      hasData: hasAnalyticsData,
      userEmail: userEmail,
    };

    (function (w, d, s) {
      var a = d.getElementsByTagName('head')[0];
      var r = d.createElement('script');
      r.async = 1;
      r.src = s;
      r.setAttribute('id', 'usetifulScript');
      r.dataset.token = '2fe90350eb0f8e8b3af9af7acc1d2b56';
      a.appendChild(r);
    })(window, document, 'https://www.usetiful.com/dist/usetiful.js');
  };

  return (
    <div id="application-container" style={{ overflow: 'hidden', width: '100%', height: '100%' }}>
      <When condition={hasAccessToFunnel === true}>
        <>
          <Header
            isViewOnlyLink={props.isViewOnlyLink}
            funnelId={match.params.funnelId}
            isLeftSideBarCollapsed={isLeftSideBarCollapsed}
            setLeftSideBarCollapsed={setLeftSideBarCollapsed}
            isMessageErrorShowing={isMessageErrorShowing}
            setIsMessageErrorShowing={setIsMessageErrorShowing}
            messageErrorData={messageErrorData}
            setMessageErrorData={setMessageErrorData}
          />
          <Main
            funnelId={match.params.funnelId}
            userId={userId}
            isMessageErrorShowing={isMessageErrorShowing}
            setIsMessageErrorShowing={setIsMessageErrorShowing}
            messageErrorData={messageErrorData}
            setMessageErrorData={setMessageErrorData}
          />
        </>
      </When>
      <When condition={hasAccessToFunnel === false}>
        <NoAccess funnelId={match.params.funnelId} />
      </When>
    </div>
  );
};
