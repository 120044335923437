import { handleActions } from 'redux-actions';
import { produce } from 'immer';
import { ActionTypes, IntegrationTypes } from 'shared/CSharedConstants';
import {
  setAttributeExplorerData,
  setThumbnailIsLoading,
  setNewCurrentStepLabel,
  setNewCurrentStepUrl,
  setNewCurrentStep,
  setNewCurrentStepFilterParams,
  setNewCurrentStepTrackingUrl,
  setNewCurrentStepActionType,
  setNewCurrentStepActionName,
  setTextStyle,
  setFacebookTree,
  setFacebookAds,
  setIntegrationAttributes,
  setTextHyperLink,
  setNewCurrentStepIntegrationType,
  setSelectedFacebookTree,
  setNewCurrentStepGoal,
  setNewCurrentStepForecastingData,
} from 'react-project/redux/current-step/actions';

const initialState = {
  stepId: 0,
  position: { x: 0, y: 0 },
  thumbnailIsLoading: false,
  setFacebookTree: [],
  setSelectedFacebookTree: [],
  object: {
    type: '',
    category: '',
    isTextShape: false,
    supportedLineTypes: undefined,
    src: '/asset/StepsModal/Pages/webinarLive.svg',
    label: '',
    url: '',
    thumbnail: '',
    filterData: [],
    trackingURL: '',
    hyperlink: '',
    actionType: ActionTypes.NONE,
    integrationType: IntegrationTypes.NONE,
    actionName: null,
    analyticsFilterData: {},
    attributesExplorerData: {},
    selectedAttributes: [],
    textStyle: {},
    integrationAttributes: [],
    goal: null,
    forecastingData: null,
  },
};

export default handleActions(
  {
    [setNewCurrentStepGoal.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.object.goal = action.payload;
      }),
    [setNewCurrentStepLabel.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.object.label = action.payload;
      }),
    [setNewCurrentStepUrl.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.object.url = action.payload;
      }),
    [setNewCurrentStepActionType.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.object.actionType = action.payload;
      }),
    [setNewCurrentStepIntegrationType.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.object.integrationType = action.payload;
      }),
    [setNewCurrentStepActionName.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.object.actionName = action.payload;
      }),
    [setFacebookTree.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.setFacebookTree = action.payload;
      }),
    [setSelectedFacebookTree.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.setSelectedFacebookTree = action.payload;
      }),
    [setFacebookAds.toString()]: (state, action) => {
      const campaignId = action.payload[0].campaign.id;
      const updatedIndex = state.setFacebookTree.findIndex((el) => el.id === campaignId);
      let updated = state.setFacebookTree[updatedIndex];
      updated = { ...updated, children: action.payload };
      const updatedFacebookTree = [
        ...state.setFacebookTree.slice(0, updatedIndex),
        updated,
        ...state.setFacebookTree.slice(updatedIndex + 1),
      ];
      return { ...state, setFacebookTree: updatedFacebookTree };
    },
    [setNewCurrentStep.toString()]: (state, action) =>
      produce(state, (draft) => {
        const filters =
          Array.isArray(action.payload.filterData) > 0 ? action.payload.filterData : [];
        const trackingURL = action.payload.trackingURL ? action.payload.trackingURL : '';

        draft.position = {
          x: action.payload.x,
          y: action.payload.y,
        };
        draft.stepId = action.payload.stepId;
        draft.object = { ...action.payload };
        draft.object.label = action.payload.label;
        draft.object.url = action.payload.url;
        draft.object.filterData = filters;
        draft.object.trackingURL = trackingURL;

        if (action.payload.shapeData) {
          const { shapeStyle: { borderColor, fillColor } = {} } = action.payload.shapeData;
          draft.object.shapeStyle = { borderColor, fillColor };
        }
      }),
    [setNewCurrentStepFilterParams.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.object.filterData = action.payload;
      }),
    [setAttributeExplorerData.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.object.attributesExplorerData = action.payload;
      }),
    [setNewCurrentStepTrackingUrl.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.object.trackingURL = action.payload;
      }),
    [setThumbnailIsLoading.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.object.thumbnailIsLoading = action.payload;
      }),
    [setTextStyle.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.object.textStyle = { ...draft.object.textStyle, ...action.payload };
      }),
    [setIntegrationAttributes.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.object.integrationAttributes = action.payload;
      }),
    [setTextHyperLink.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.object.hyperlink = action.payload;
      }),
    [setNewCurrentStepForecastingData.toString()]: (state, action) =>
      produce(state, (draft) => {
        if (draft.object.forecastingData === null) {
          draft.object.forecastingData = {
            ...action.payload,
          };
        } else {
          draft.object.forecastingData = {
            ...draft.object.forecastingData,
            ...action.payload,
          };
        }
      }),
  },
  initialState,
);
