import 'pixi-assetslist-loader';
import * as PIXI from 'pixi.js';
import Signals from 'pixi-project/signals/AppSignals';

export default class ApplicationLoader {
  loadAssets() {
    PIXI.Loader.shared._afterMiddleware[1] = this.use;
    PIXI.Loader.shared.add(`${process.env.PUBLIC_URL}/asset/AssetsConfig.json`);
    PIXI.Loader.shared.onComplete.add(() => {
      this.onComplete();
    });
    PIXI.Loader.shared.load();
  }

  onComplete = () => {
    Signals.assetsLoadingComplete.dispatch();
  };

  use(resource, next) {
    // create a new texture if the data is an Image object
    if (
      resource.data &&
      (resource.type === PIXI.LoaderResource.TYPE.IMAGE || resource.extension === 'svg')
    ) {
      const { data, url, name, metadata } = resource;
      let loaderMeta = null;
      // "assetsLastChild" will break the images if passed.
      if (metadata && metadata.type !== 'assetsListChild') {
        loaderMeta = metadata;
      }

      PIXI.Texture.fromLoader(data, url, name, loaderMeta)
        .then((texture) => {
          resource.texture = texture;
          next();
        })
        .catch(next);
    } else {
      next();
    }
  }
}
