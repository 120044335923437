export function setUniqueSessions(displayedSessions, newSessions, pushTarget = displayedSessions) {
  newSessions.forEach((sec) => {
    const hasObj = displayedSessions.findIndex((first) => {
      return first.id === sec.id;
    });
    if (hasObj === -1) {
      pushTarget.push(sec);
    }
  });
}
