export default class BezierCurve {
  constructor(pointA, controlPointA, controlPointB, pointB, precision = 100) {
    this.a = pointA;
    this.b = controlPointA;
    this.c = controlPointB;
    this.d = pointB;

    this.totalLength = 0;
    this.precision = precision;
    this.arcLengths = new Array(this.precision + 1);

    this.calculateArcs();
  }

  updatePoints(pointA, controlPointA, controlPointB, pointB) {
    this.a.copyFrom(pointA);
    this.b.copyFrom(controlPointA);
    this.c.copyFrom(controlPointB);
    this.d.copyFrom(pointB);

    this.calculateArcs();
  }

  get(t) {
    return {
      x: this.mx(t),
      y: this.my(t),
    };
  }

  mx(u) {
    return this.x(this.map(u));
  }

  my(u) {
    return this.y(this.map(u));
  }

  x(t) {
    return (
      (1 - t) * (1 - t) * (1 - t) * this.a.x +
      3 * ((1 - t) * (1 - t)) * t * this.b.x +
      3 * (1 - t) * (t * t) * this.c.x +
      t * t * t * this.d.x
    );
  }

  y(t) {
    return (
      (1 - t) * (1 - t) * (1 - t) * this.a.y +
      3 * ((1 - t) * (1 - t)) * t * this.b.y +
      3 * (1 - t) * (t * t) * this.c.y +
      t * t * t * this.d.y
    );
  }

  map(u) {
    const targetLength = u * this.arcLengths[this.precision];
    let low = 0;
    let high = this.precision;
    let index = 0;

    while (low < high) {
      index = low + (((high - low) / 2) | 0);
      if (this.arcLengths[index] < targetLength) {
        low = index + 1;
      } else {
        high = index;
      }
    }

    if (this.arcLengths[index] > targetLength) {
      index--;
    }

    const lengthBefore = this.arcLengths[index];
    if (lengthBefore === targetLength) {
      return index / this.precision;
    } else {
      return (
        (index + (targetLength - lengthBefore) / (this.arcLengths[index + 1] - lengthBefore)) /
        this.precision
      );
    }
  }

  calculateArcs() {
    this.arcLengths[0] = 0;

    let ox = this.x(0);
    let oy = this.y(0);
    let clen = 0;

    for (let i = 1; i <= this.precision; i += 1) {
      const x = this.x(i * 0.01);
      const y = this.y(i * 0.01);
      const dx = ox - x;
      const dy = oy - y;
      clen += Math.sqrt(dx * dx + dy * dy);
      this.arcLengths[i] = clen;
      (ox = x), (oy = y);
    }
    this.totalLength = clen;
  }
}
