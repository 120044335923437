import React, { useState, useEffect } from 'react';
import MainStorage from 'pixi-project/core/MainStorage';
import { ItemsWithLabelCustomization } from 'react-project/Constants/step-settings';
import {
  TEXTS_SHAPES,
  TEXTS_TOOLTIP,
  TEXT_ADD_STEP,
  TEXT_PASTE_OBJECT,
  TEXT_REDO_ACTION,
  TEXT_UNDO_ACTION,
} from 'react-project/Constants/texts';
import { setIsCanvasShareModalOpen } from 'react-project/Sharing/shareCanvasSlice';
import { ContextMenu } from 'react-project/Toolbar/ContextMenu';
import { ExportComponent } from 'react-project/Toolbar/step-toolbar/exportComponent/ExportComponent';
import { notifyIfValueChanged, onCloseMenu } from 'react-project/Util/InputHelpers';
import { When } from 'react-project/Util/When';
import dropdownStyles from 'react-project/components/dropdown/Dropdown.module.scss';
import { DropdownBlock } from 'react-project/components/dropdown/DropdownBlock';
import { DropdownItem } from 'react-project/components/dropdown/DropdownItem';
import { ViewportConstrainer } from 'react-project/components/viewportConstrainer/ViewportConstrainer';
import { resetAdPopups } from 'react-project/features';
import { setTextHyperLink } from 'react-project/redux/current-step/actions';
import { selectCurrentStep } from 'react-project/redux/current-step/selectors';
import { clearInputsState } from 'react-project/redux/inputs/actions';
import { selectInputValues } from 'react-project/redux/inputs/selectors';
import { setIsStepModalOpened, setStepModalData } from 'react-project/redux/modal/actions';
import {
  selectIsStepModalOpened,
  selectIsStepModalPinned,
  selectStepModalData,
} from 'react-project/redux/modal/selectors';
import {
  setPanGuideStatus,
  setPerfectShapeGuideStatus,
} from 'react-project/redux/notification-alerts/actions';
import { setWidgetStatus } from 'react-project/redux/popups/actions';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { EElementCategories } from 'shared/CSharedCategories';
import {
  ACTIVE_STATE_DEFAULT,
  ACTIVE_STATE_DRAW,
  ACTIVE_STATE_PAN,
  ACTIVE_STATE_PHOTO,
  ACTIVE_STATE_REPORT,
  ACTIVE_STATE_SELECT,
  ACTIVE_STATE_TEXT,
  ACTIVE_STATE_TWP,
  ACTIVE_STATE_WIDGET,
  ANALYTICS_STATUS_LOADING,
  ANALYTICS_STATUS_STALE,
  ANALYTICS_STATUS_SUCCESS,
  DEFAULT_TEXT_VALUE,
  EShapeTypes,
  MODAL_SOURCE_EVENT,
} from 'shared/CSharedConstants';
import {
  PR_EVENT_CONNECTION_IN_EMPTY_SPACE,
  PR_EVENT_EDIT_MODE,
  PR_EVENT_QUICK_PANNING_MODE_CHANGED,
  PR_EVENT_REPORT_WARNING_POP_OVERLAPPED,
  PR_EVENT_SHAPE_DRAWING_ENDED,
  PR_EVENT_SHORTCUT_PRESSED,
  PR_EVENT_TEXT_EDITING_FINISH,
  PR_EVENT_TOOLBOX_STATE_CHANGED,
  PR_EVENT_UNDO_REDO_ACTIONS_UPDATED,
  PR_MOVING_CURSOR_WITH_ELEMENT,
  RP_EVENT_CANCEL_NEW_STEP_FROM_CONNECTION,
  RP_EVENT_CREATE_OBJECT,
  RP_EVENT_CURSOR_TYPE,
  RP_EVENT_ELEMENT_RIGHT_CLICK,
  RP_EVENT_EMPTY_CANVAS_DOUBLE_CLICK,
  RP_EVENT_EMPTY_CANVAS_POINTER_UP,
  RP_EVENT_ESC_PRESSED,
  RP_EVENT_EXPORT_CSV_CONNECTIONS,
  RP_EVENT_EXPORT_CSV_OBJECTS,
  RP_EVENT_OBJECT_DRAG_START,
  RP_EVENT_PASTE_PRESSED,
  RP_EVENT_REDO_CLICKED,
  RP_EVENT_REFRESH_REQUEST,
  RP_EVENT_SAVE_TO_PNG,
  RP_EVENT_UNDO_CLICKED,
  RP_STEP_PICKER_ACTIVATED,
  RR_EVENT_ON_SHOW_LEFT_CREATE_STEP_MENU,
  RR_EVENT_ON_WIDGET_CLOSE,
  RR_EVENT_ON_WIDGET_PRESSED,
  RR_EVENT_ON_WIDGET_SELECTED,
} from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { CANVAS_ACTION_NAMES } from 'shared/canvasActionNames';
import {
  blankDownload,
  iconCircleTiny,
  iconImg,
  iconPanTool,
  iconPaste,
  iconPlus,
  iconRedo,
  iconSelect,
  iconShare,
  iconSquare,
  iconText,
  iconTriangle,
  iconUndo,
} from '../assets/Icons';
import styles from './ToolsBox.module.scss';
import ToolsItem from './ToolsItem';
import { WidgetTool } from 'react-project/components/tools/WidgetTool';
import { ReportTool } from 'react-project/components/tools/ReportTool';
import { selectAnalyticsStatus } from 'react-project/redux/analytics/selectors';
import { selectFunnelConfiguration } from 'react-project/redux/funnel-configuration/selectors';

const categoryText = EElementCategories.TEXT;
const categoryPhoto = EElementCategories.PHOTO;
const categoryClicking = EElementCategories.CLICKING;
const categoryPanning = EElementCategories.PANNING;
const categoryNone = EElementCategories.NONE;

const drawExtended = [
  {
    type: EShapeTypes.RECTANGLE,
    icon: iconSquare,
    label: TEXTS_SHAPES[EShapeTypes.RECTANGLE],
    actionName: CANVAS_ACTION_NAMES.DRAW_RECTANGLE,
    tooltipLabel: TEXTS_TOOLTIP.DRAW_RECTANGLE,
  },
  {
    type: EShapeTypes.ELLIPSE,
    icon: iconCircleTiny,
    label: TEXTS_SHAPES[EShapeTypes.ELLIPSE],
    actionName: CANVAS_ACTION_NAMES.DRAW_CIRCLE,
    tooltipLabel: TEXTS_TOOLTIP.DRAW_CIRCLE,
  },
  {
    type: EShapeTypes.TRIANGLE,
    icon: iconTriangle,
    label: TEXTS_SHAPES[EShapeTypes.TRIANGLE],
    actionName: CANVAS_ACTION_NAMES.DRAW_TRIANGLE,
    tooltipLabel: TEXTS_TOOLTIP.DRAW_TRIANGLE,
  },
];

const selectExtended = [
  {
    type: ACTIVE_STATE_SELECT,
    icon: iconSelect,
    label: CANVAS_ACTION_NAMES.SELECT,
    actionName: CANVAS_ACTION_NAMES.SELECT,
    tooltipLabel: TEXTS_TOOLTIP.SELECT,
  },
  {
    type: ACTIVE_STATE_PAN,
    icon: iconPanTool,
    label: CANVAS_ACTION_NAMES.PAN,
    actionName: CANVAS_ACTION_NAMES.PAN,
    tooltipLabel: TEXTS_TOOLTIP.PAN,
  },
];

// Function component declaration (example)
const ToolsBox = ({
  funnel,
  activateSelectTool, // Don't delete just yet , it might be put in use
  onPanningActive,
  openModal,
  hasWorkspaceActions,
  setReportToolStatus,
  isReportToolActive,
  activeStepsModalTab,
  onStepsModalTabChanged,
  onWidgetOpened,
  isReportToolDisabled,
}) => {
  const pngExportRef = React.createRef();

  // State
  const [contextMenuOpened, setContextMenuOpened] = useState(false);
  const [contextElementMenuOpened, setContextElementMenuOpenedState] = useState(false);
  const [createObjectPositions, setCreateObjectPositions] = useState({ x: 0, y: 0 });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [open, setOpen] = useState({ draw: false });
  const [prevActiveState, setPrevActiveState] = useState('');
  const [activeState, setActiveStateState] = useState(ACTIVE_STATE_SELECT);
  const [selectedDrawItem, setSelectedDrawItem] = useState({
    icon: iconCircleTiny,
    actionName: CANVAS_ACTION_NAMES.DRAW_CIRCLE,
    tooltipLabel: TEXTS_TOOLTIP.DRAW_CIRCLE,
    label: TEXTS_SHAPES[EShapeTypes.ELLIPSE],
    type: EShapeTypes.ELLIPSE,
  });
  const [selectedToolItem, setSelectedToolItem] = useState({
    icon: iconSelect,
    actionName: CANVAS_ACTION_NAMES.SELECT,
    tooltipLabel: TEXTS_TOOLTIP.SELECT,
    label: CANVAS_ACTION_NAMES.SELECT,
    type: ACTIVE_STATE_SELECT,
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [port, setPort] = useState('');
  const [canPaste, setCanPaste] = useState(false);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [canExplore, setCanExplore] = useState(true);
  const [isExportModalOpened, setIsExportModalOpened] = useState(false);
  const [csvDownloadEventName, setCSVDownloadEventName] = useState(null);

  // Selectors
  const currentStep = useSelector(selectCurrentStep);
  const inputs = useSelector(selectInputValues);
  const IsStepModalOpened = useSelector(selectIsStepModalOpened);
  const IsStepModalPinned = useSelector(selectIsStepModalPinned);
  const canvasPermissions = useSelector(selectCanvasPermissions);
  const analyticsStatus = useSelector(selectAnalyticsStatus);
  const funnelConfiguration = useSelector(selectFunnelConfiguration);
  const modalData = useSelector(selectStepModalData);

  const dispatch = useDispatch();

  const setShareModalOpen = () => {
    dispatch(setIsCanvasShareModalOpen(true));
  };

  useEffect(() => {
    const eventConnectionHandler = (e) => {
      e.preventDefault();

      setCreateObjectPositions({
        x: e.detail.position.x,
        y: e.detail.position.y,
      });

      setPosition({
        x: e.detail.position.x,
        y: e.detail.position.y,
      });

      setPort(e.detail.port);
      setCanExplore(e.detail.canExplore);

      const stepModalData = {
        hasWorkspaceActions: hasWorkspaceActions,
        funnel: funnel,
        port: e.detail.port,
        coords: {
          x: e.detail.position.x,
          y: e.detail.position.y,
        },
        createObjectPositions: {
          x: e.detail.position.x,
          y: e.detail.position.y,
        },
        onCloseModal: onCloseShowSteps,
        canExplore: e.detail.canExplore,
        activeStepsModalTab: activeStepsModalTab,
        onStepsModalTabChanged: onStepsModalTabChanged,
        color: 'green',
        modalSource: null,
      };
      stepModalData.modalSource = MODAL_SOURCE_EVENT;

      dispatch(setStepModalData({ data: stepModalData }));
      if (!IsStepModalPinned) {
        showSteps(!IsStepModalOpened, e.detail.canExplore);
      }
    };

    const onCloseShowSteps = () => {
      if (!IsStepModalPinned) {
        dispatch(setIsStepModalOpened(false));
      }

      if (port) {
        commonSendEventFunction(RP_EVENT_CANCEL_NEW_STEP_FROM_CONNECTION, {});
        portCleaning();
      }
    };

    const canvasElement = document.getElementById('canvas');
    canvasElement.addEventListener('pointerdown', onCanvasClick);

    document.addEventListener(PR_EVENT_CONNECTION_IN_EMPTY_SPACE, eventConnectionHandler, false);
    document.addEventListener(PR_EVENT_QUICK_PANNING_MODE_CHANGED, forcePanningMode, false);
    document.addEventListener(RP_EVENT_ELEMENT_RIGHT_CLICK, onElementRightClick, false);

    document.addEventListener('contextmenu', (e) => e.preventDefault());

    document.addEventListener(PR_EVENT_EDIT_MODE, editModeHandler, false);
    document.addEventListener(RP_EVENT_ESC_PRESSED, onESCPressed, false);
    document.addEventListener(RP_STEP_PICKER_ACTIVATED, onTrendsPickerActivated, false);
    document.addEventListener(PR_EVENT_TEXT_EDITING_FINISH, onTextEditingFinish, false);
    document.addEventListener(PR_EVENT_SHAPE_DRAWING_ENDED, onDrawShapeEnded, false);
    document.addEventListener(RP_EVENT_EMPTY_CANVAS_DOUBLE_CLICK, onEmptyCanvasDoubleClick, false);
    document.addEventListener(PR_EVENT_UNDO_REDO_ACTIONS_UPDATED, onUndoRedoActionUpdated, false);
    document.addEventListener(
      PR_EVENT_REPORT_WARNING_POP_OVERLAPPED,
      isMouseOverLockedReport,
      false,
    );
    document.addEventListener(PR_EVENT_SHORTCUT_PRESSED, onShortcutPressed, false);
    document.addEventListener(RR_EVENT_ON_WIDGET_PRESSED, onWidgetPressed, false);
    document.addEventListener(RP_EVENT_EMPTY_CANVAS_POINTER_UP, onEmptyCanvasPointerUp, false);
    document.addEventListener(RR_EVENT_ON_WIDGET_CLOSE, onWidgetClose, false);
    document.addEventListener(RR_EVENT_ON_WIDGET_SELECTED, onWidgetSelected, false);

    return () => {
      const canvasElement = document.getElementById('canvas');
      canvasElement.removeEventListener('pointerdown', onCanvasClick);

      document.removeEventListener(PR_EVENT_CONNECTION_IN_EMPTY_SPACE, eventConnectionHandler);
      document.removeEventListener(PR_EVENT_QUICK_PANNING_MODE_CHANGED, forcePanningMode);
      document.removeEventListener(RP_EVENT_ELEMENT_RIGHT_CLICK, onElementRightClick);

      document.removeEventListener(PR_EVENT_EDIT_MODE, editModeHandler);
      document.removeEventListener(RP_EVENT_ESC_PRESSED, onESCPressed);
      document.removeEventListener(RP_STEP_PICKER_ACTIVATED, onTrendsPickerActivated);
      document.removeEventListener(PR_EVENT_TEXT_EDITING_FINISH, onTextEditingFinish);
      document.removeEventListener(PR_EVENT_SHAPE_DRAWING_ENDED, onDrawShapeEnded);
      document.removeEventListener(RP_EVENT_EMPTY_CANVAS_DOUBLE_CLICK, onEmptyCanvasDoubleClick);
      document.removeEventListener(PR_EVENT_UNDO_REDO_ACTIONS_UPDATED, onUndoRedoActionUpdated);
      document.removeEventListener(PR_EVENT_REPORT_WARNING_POP_OVERLAPPED, isMouseOverLockedReport);
      document.removeEventListener(PR_EVENT_SHORTCUT_PRESSED, onShortcutPressed);
      document.removeEventListener(RR_EVENT_ON_WIDGET_PRESSED, onWidgetPressed);
      document.removeEventListener(RP_EVENT_EMPTY_CANVAS_POINTER_UP, onEmptyCanvasPointerUp);
      document.removeEventListener(RR_EVENT_ON_WIDGET_CLOSE, onWidgetClose, false);
      document.removeEventListener(RR_EVENT_ON_WIDGET_SELECTED, onWidgetSelected, false);
    };
  }, [
    activeState,
    modalData,
    funnel,
    currentStep,
    selectedDrawItem,
    IsStepModalPinned,
    port,
    isExportModalOpened,
    activeStepsModalTab,
    hasWorkspaceActions,
  ]);

  useEffect(() => {
    if (isReportToolActive) {
      setActiveState(ACTIVE_STATE_REPORT);
    }

    if (activateSelectTool) {
      setActiveState(ACTIVE_STATE_SELECT);
    }
  }, [isReportToolActive]);

  useEffect(() => {
    // if ther is saved event name when analytics finished loading
    // download the csv
    if (csvDownloadEventName && analyticsStatus === ANALYTICS_STATUS_SUCCESS) {
      // reset the event name
      setCSVDownloadEventName(null);

      // set a small delay to make sure the analytics data was binded with the canvas elements
      setTimeout(() => {
        commonSendEventFunction(csvDownloadEventName, funnelConfiguration);
      }, 200);
    }
  }, [analyticsStatus]);

  const openExtended = (state) => {
    setActiveState(state);
    forceCloseExtended();
    setOpen({
      ...open,
      [state]: !open[state],
    });
  };

  const openTools = (state) => {
    // do nothing
  };

  const portCleaning = () => {
    setPort('');
  };

  const forceCloseExtended = () => {
    setOpen({
      select: false,
      draw: false,
    });

    if (port) {
      commonSendEventFunction(RP_EVENT_CANCEL_NEW_STEP_FROM_CONNECTION, {});
      portCleaning();
    }
    if (
      activeState !== ACTIVE_STATE_DRAW &&
      activeState !== ACTIVE_STATE_TEXT &&
      activeState !== ACTIVE_STATE_PHOTO &&
      activeState !== ACTIVE_STATE_PAN &&
      activeState !== ACTIVE_STATE_REPORT &&
      activeState !== ACTIVE_STATE_SELECT // also check for select
    ) {
      setActiveState(ACTIVE_STATE_SELECT);
    }
  };

  const onTextEditingFinish = () => {
    setActiveState(ACTIVE_STATE_SELECT);
  };

  const onDrawShapeEnded = () => {
    setActiveState(ACTIVE_STATE_SELECT);
  };

  const onWidgetPressed = () => {
    setActiveState(ACTIVE_STATE_WIDGET);
  };

  const onESCPressed = (e) => {
    setActiveState(ACTIVE_STATE_SELECT);
  };

  const onShortcutPressed = (e) => {
    if (e.detail && e.detail.action) {
      if (e.detail.action === CANVAS_ACTION_NAMES.SELECT) {
        setActiveState(ACTIVE_STATE_SELECT);
      } else if (e.detail.action === CANVAS_ACTION_NAMES.ADD_TEXT) {
        setActiveState(ACTIVE_STATE_TEXT);
      } else if (e.detail.action === CANVAS_ACTION_NAMES.DRAW_RECTANGLE) {
        setSelectedDrawItem(drawExtended[0]);
        setActiveState(ACTIVE_STATE_DRAW);
      } else if (e.detail.action === CANVAS_ACTION_NAMES.DRAW_CIRCLE) {
        setSelectedDrawItem(drawExtended[1]);
        setActiveState(ACTIVE_STATE_DRAW);
      } else if (e.detail.action === CANVAS_ACTION_NAMES.DRAW_TRIANGLE) {
        setSelectedDrawItem(drawExtended[2]);
        setActiveState(ACTIVE_STATE_DRAW);
      }
    } else {
      throw 'Please define the action for the shortcut';
    }
  };

  const isMouseOverLockedReport = (e) => {
    if (e.detail.isOverlapped) {
      document.body.style.cursor = 'no-drop';
    } else if (e.detail.type == categoryText) {
      document.body.style.cursor = 'text';
    } else if (e.detail.type == EElementCategories.SHAPE) {
      document.body.style.cursor = 'crosshair';
    } else if (e.detail.type == EElementCategories.PHOTO) {
      document.body.style.cursor = 'crosshair';
    }
  };

  const removeMouseMoveListeners = () => {
    document.removeEventListener('mousemove', onTextCursorMove);
    document.removeEventListener('mousemove', onShapeCursorMove);
  };

  const onTextCursorMove = (e) => {
    commonSendEventFunction(PR_MOVING_CURSOR_WITH_ELEMENT, {
      coords: { x: e.clientX, y: e.clientY },
      type: categoryText,
    });
  };

  const onShapeCursorMove = (e) => {
    commonSendEventFunction(PR_MOVING_CURSOR_WITH_ELEMENT, {
      coords: { x: e.clientX, y: e.clientY },
      type: EElementCategories.SHAPE,
    });
  };

  const toggleTextTool = () => {
    const newActiveState = activeState !== categoryText ? categoryText : ACTIVE_STATE_DEFAULT;

    setActiveState(newActiveState);
    document.addEventListener('mousemove', onTextCursorMove);
  };

  const togglePhotoTool = () => {
    const newActiveState = activeState !== categoryPhoto ? categoryPhoto : ACTIVE_STATE_DEFAULT;
    setActiveState(newActiveState);
  };

  const closeNotificationAlarm = (activeState) => {
    if (activeState !== ACTIVE_STATE_PAN) {
      dispatch(setPanGuideStatus(false));
    }
  };

  const setActiveState = (newState) => {
    // START OF BLOCK
    // it needs performance optimization , as this block takes about 50ms to execute
    // which causes a delay in the UI
    if (newState !== ACTIVE_STATE_REPORT) {
      setReportToolStatus(false);
    }

    if (newState === ACTIVE_STATE_DRAW && activeState === ACTIVE_STATE_DRAW) {
      return;
    }

    closeNotificationAlarm(newState);
    dispatch(setPerfectShapeGuideStatus(newState === ACTIVE_STATE_DRAW));

    setPrevActiveState(activeState);
    if (!IsStepModalPinned) {
      openModal(false);
    }
    //END OF BLOCK

    if (newState === ACTIVE_STATE_SELECT || newState === ACTIVE_STATE_DRAW) {
      panning(categoryClicking);
    }

    setActiveStateState(newState);

    if (newState !== ACTIVE_STATE_DRAW || newState !== ACTIVE_STATE_TEXT) {
      removeMouseMoveListeners();
    }

    commonSendEventFunction(PR_EVENT_TOOLBOX_STATE_CHANGED, { state: newState });

    if (newState === ACTIVE_STATE_SELECT) {
      setSelectedToolItem(selectExtended[0]);
    } else if (newState === ACTIVE_STATE_PAN) {
      setSelectedToolItem(selectExtended[1]); // pan Item
    }
  };

  const editModeHandler = (e) => {
    setIsEditMode(e.detail.value);
  };

  const onSelectDrawItem = (item) => {
    setSelectedDrawItem(item);
    if (activeState !== ACTIVE_STATE_DRAW) {
      setActiveState(ACTIVE_STATE_DRAW);
    }
  };

  const onDrawToolClicked = () => {
    if (activeState === ACTIVE_STATE_DRAW) {
      setActiveState(ACTIVE_STATE_SELECT);
      setSelectedDrawItem({ ...selectedDrawItem, label: null });
      removeMouseMoveListeners();
      return;
    } else {
      // repair the selected item , label
      setSelectedDrawItem({ ...selectedDrawItem, label: TEXTS_SHAPES[selectedDrawItem.type] });
    }

    document.addEventListener('mousemove', onShapeCursorMove);
  };

  const onSelectToolItem = (item) => {
    setSelectedToolItem(item);

    setActiveState(item.type);

    const isPanning = item.type === ACTIVE_STATE_PAN;

    if (isPanning) {
      dispatch(setPanGuideStatus(true));
      panning(categoryPanning);
    } else {
      dispatch(setPanGuideStatus(false));
    }
  };

  const createObject = (category, e) => {
    if (isEditMode) {
      return;
    }

    commonSendEventFunction(RP_EVENT_CREATE_OBJECT, {
      position: {
        x: e.clientX,
        y: e.clientY,
      },
      object: {
        category: category,
        text: DEFAULT_TEXT_VALUE,
      },
      isStepModalPinned: IsStepModalPinned,
    });

    if (activeState === categoryText) {
      toggleTextTool();
    } else if (activeState === categoryPhoto) {
      togglePhotoTool();
    }

    dispatch(setTextHyperLink(''));
  };

  const createReportObject = (e) => {
    commonSendEventFunction(RP_EVENT_CREATE_OBJECT, {
      position: {
        x: e.clientX,
        y: e.clientY,
      },
      object: {
        category: EElementCategories.REPORT,
      },
      isStepModalPinned: IsStepModalPinned,
    });

    setActiveState(ACTIVE_STATE_SELECT);
  };

  const notifyDragStarted = (category) => {
    commonSendEventFunction(RP_EVENT_OBJECT_DRAG_START, {
      object: { category: category, text: DEFAULT_TEXT_VALUE },
    });

    //TODO this is a hack , a proper state needs to be set once we drop the text item on the canvas.
    // but that will be done after refactoring some code
    setActiveState(ACTIVE_STATE_DEFAULT);
  };

  const forcePanningMode = (e) => {
    let isPanning = e.detail['isPanning'];
    onPanningActive(isPanning);
    if (isPanning) {
      setActiveState(ACTIVE_STATE_PAN);
    } else {
      setActiveState(prevActiveState);
    }
  };

  const onTrendsPickerActivated = (e) => {
    if (e.detail.active) {
      setActiveState(ACTIVE_STATE_TWP);
    }
  };

  const panning = (category) => {
    category === categoryPanning ? onPanningActive(true) : onPanningActive(false);

    commonSendEventFunction(RP_EVENT_CURSOR_TYPE, { category: category });
  };

  const updateContextMenuActionsAvailability = (detail) => {
    setCanPaste(!!detail.canPaste);
    setCanRedo(!!detail.canRedo);
    setCanUndo(!!detail.canUndo);
  };

  const onUndoRedoActionUpdated = (e) => {
    setCanUndo(e.detail.canUndo);
    setCanRedo(e.detail.canRedo);
  };

  const onEmptyCanvasPointerUp = (e) => {
    if (e.detail.isRight && !MainStorage.getCanvasPermissions().isReadonlyAccess) {
      const data = e.detail;
      e.detail.originalEvent.stopPropagation();
      openContextMenu(data);
    }
  };

  const onCanvasClick = (e) => {
    closeContextElementMenu();
    setStateOfContextmenu(false);

    dispatch(setWidgetStatus(false));
    forceCloseExtended();

    dispatch(resetAdPopups());

    switch (activeState) {
      case categoryPanning:
        break;
      case ACTIVE_STATE_DRAW:
        commonSendEventFunction(RP_EVENT_CREATE_OBJECT, {
          position: { x: e.clientX, y: e.clientY },
          object: { category: EElementCategories.SHAPE, type: selectedDrawItem.type },
          isStepModalPinned: IsStepModalPinned,
        });
        break;
      case ACTIVE_STATE_REPORT:
        createReportObject(e);
        break;
      case categoryText:
        createObject(categoryText, e);
        break;
      case categoryPhoto:
        createObject(categoryPhoto, e);
        break;
      default:
    }
  };

  const closeContextElementMenu = () => {
    if (inputs) {
      onCloseMenu({
        inputs,
        clearInputsState: () => {
          dispatch(clearInputsState());
        },
      });
    }
    setContextElementMenuOpened(false);
  };

  const setStateOfContextmenu = (value) => {
    setContextMenuOpened(value);
  };

  const setContextElementMenuOpened = (value) => {
    setContextElementMenuOpenedState(value);
  };

  const showSteps = (value, canExplore = true) => {
    setCanExplore(canExplore);
    dispatch(setIsStepModalOpened(value));
  };

  const toggleExportModal = () => {
    setIsExportModalOpened(!isExportModalOpened);
  };

  const onExportClose = () => {
    setIsExportModalOpened(false);
  };

  const saveToPNG = (resolution) => {
    commonSendEventFunction(RP_EVENT_SAVE_TO_PNG, { resolution });
  };

  const onExportElements = () => {
    onCSVExport(RP_EVENT_EXPORT_CSV_OBJECTS);
  };

  const onExportConnections = () => {
    onCSVExport(RP_EVENT_EXPORT_CSV_CONNECTIONS);
  };

  const onCSVExport = (eventName) => {
    if (analyticsStatus === ANALYTICS_STATUS_LOADING) {
      return;
    }

    if (analyticsStatus === ANALYTICS_STATUS_STALE) {
      // set the event name to be triggered after analytics is refreshed
      setCSVDownloadEventName(eventName);
      // trigger a refresh
      commonSendEventFunction(RP_EVENT_REFRESH_REQUEST, { isCancel: false });
    } else if (analyticsStatus === ANALYTICS_STATUS_SUCCESS) {
      commonSendEventFunction(eventName, funnelConfiguration);
    }
  };

  const openShareCanvas = () => {
    setShareModalOpen();
  };

  const openContextMenu = (data) => {
    setPosition({
      x: data.originalEvent.x,
      y: data.originalEvent.y,
    });
    setContextMenuOpened(true);

    onContextMenuOpened(data);
  };

  const onContextMenuOpened = (data) => {
    updateContextMenuActionsAvailability(data);
  };

  const onEmptyCanvasDoubleClick = (e) => {
    commonSendEventFunction(RR_EVENT_ON_SHOW_LEFT_CREATE_STEP_MENU);
  };

  const onUndoAction = () => {
    commonSendEventFunction(RP_EVENT_UNDO_CLICKED);
  };

  const onRedoAction = () => {
    commonSendEventFunction(RP_EVENT_REDO_CLICKED);
  };

  const onElementRightClick = (e) => {
    if (canvasPermissions.isReadonlyAccess) {
      // hide menus in readonly mode
      return;
    }

    setContextElementMenuOpened(true);
  };

  const onAddingStep = () => {
    // close context menu
    setStateOfContextmenu(false);

    // open left side bar
    commonSendEventFunction(RR_EVENT_ON_SHOW_LEFT_CREATE_STEP_MENU);
  };

  const onPaste = () => {
    setStateOfContextmenu(false);
    const p = { x: position.x, y: position.y + 50 };
    commonSendEventFunction(RP_EVENT_PASTE_PRESSED, p);
  };

  const onWidgetClose = () => {
    setActiveState(ACTIVE_STATE_SELECT);
    dispatch(setWidgetStatus(false));
  };

  const onWidgetSelected = () => {
    setActiveState(ACTIVE_STATE_SELECT);
  };

  const renderContextMenu = () => {
    if (contextMenuOpened) {
      return (
        <ViewportConstrainer>
          <div className={dropdownStyles.DropdownContent}>
            <DropdownBlock isBorder key="block-1">
              <DropdownItem
                icon={iconPlus}
                key={TEXT_ADD_STEP}
                label={TEXT_ADD_STEP}
                actionName={CANVAS_ACTION_NAMES.ADD_STEP}
                onClick={onAddingStep}
              />
              <DropdownItem
                key={TEXT_PASTE_OBJECT}
                label={TEXT_PASTE_OBJECT}
                disabled={!canPaste}
                icon={iconPaste}
                actionName={CANVAS_ACTION_NAMES.PASTE}
                onClick={onPaste}
              />
            </DropdownBlock>
            <DropdownBlock key="block-2">
              <DropdownItem
                key={TEXT_UNDO_ACTION}
                label={TEXT_UNDO_ACTION}
                icon={iconUndo}
                disabled={!canUndo}
                actionName={CANVAS_ACTION_NAMES.UNDO}
                onClick={onUndoAction}
              />
              <DropdownItem
                key={TEXT_REDO_ACTION}
                label={TEXT_REDO_ACTION}
                icon={iconRedo}
                disabled={!canRedo}
                actionName={CANVAS_ACTION_NAMES.REDO}
                onClick={onRedoAction}
              />
            </DropdownBlock>
          </div>
        </ViewportConstrainer>
      );
    }
  };

  return (
    <div className={styles.Wrapper} id="ToolsBoxContainer">
      {renderContextMenu()}
      <ul className={styles.Items}>
        <When condition={!canvasPermissions.isReadonlyAccess}>
          <ToolsItem
            icon={iconText}
            type={categoryText}
            event={toggleTextTool}
            createObject={createObject}
            notifyDragStarted={notifyDragStarted}
            active={activeState === categoryText}
            tooltipLabel={TEXTS_TOOLTIP.ADD_TEXT}
            actionName={CANVAS_ACTION_NAMES.ADD_TEXT}
          />

          <ToolsItem
            icon={iconImg}
            type={categoryPhoto}
            event={togglePhotoTool}
            createObject={createObject}
            notifyDragStarted={notifyDragStarted}
            active={activeState === categoryPhoto}
            tooltipLabel={TEXTS_TOOLTIP.ADD_PHOTO}
            actionName={CANVAS_ACTION_NAMES.ADD_PHOTO}
            isLocked={!canvasPermissions.iconsAllowed}
          />

          <ToolsItem
            icon={selectedDrawItem.icon}
            onSelectItem={(item) => onSelectDrawItem(item)}
            extended={drawExtended}
            open={open.draw && activeState === 'draw'}
            openExtended={openExtended}
            type="draw"
            event={onDrawToolClicked}
            active={activeState === 'draw'}
            selectedItem={selectedDrawItem}
            tooltipLabel={selectedDrawItem.tooltipLabel}
          />
        </When>

        <ToolsItem
          icon={activeState === ACTIVE_STATE_PAN ? iconPanTool : iconSelect}
          onSelectItem={(item) => onSelectToolItem(item)}
          extended={selectExtended}
          open={
            open.tool && (activeState === ACTIVE_STATE_SELECT || activeState === ACTIVE_STATE_PAN)
          }
          openExtended={openTools}
          type="tool"
          event={() => {
            onSelectToolItem(selectedToolItem);
          }}
          active={activeState === ACTIVE_STATE_SELECT || activeState === ACTIVE_STATE_PAN}
          selectedItem={selectedToolItem}
          tooltipLabel={selectedToolItem.tooltipLabel}
          isFillActive={true}
        />

        <When condition={!canvasPermissions.isReadonlyAccess}>
          <WidgetTool onOpen={onWidgetOpened} isActive={activeState === ACTIVE_STATE_WIDGET} />

          <ReportTool
            setReportToolStatus={setReportToolStatus}
            isActive={isReportToolActive}
            isDisabled={isReportToolDisabled}
          />
        </When>

        <ToolsItem
          id="export-canvas-btn"
          ref={pngExportRef}
          icon={blankDownload}
          type={categoryNone}
          event={toggleExportModal}
          tooltipLabel={TEXTS_TOOLTIP.EXPORT_CANVAS}
          actionName={CANVAS_ACTION_NAMES.EXPORT_CANVAS}
          active={isExportModalOpened}
        />

        <When condition={!canvasPermissions.isReadonlyAccess}>
          <ToolsItem
            icon={iconShare}
            type={categoryNone}
            event={openShareCanvas}
            tooltipLabel={TEXTS_TOOLTIP.SHARE_CANVAS}
            active={true}
          />
        </When>
      </ul>
      {contextElementMenuOpened && (
        <ViewportConstrainer>
          <div className={dropdownStyles.DropdownContent}>
            <ContextMenu
              dropdownTrigger={false}
              showLabel={ItemsWithLabelCustomization.includes(currentStep.object.type)}
              onItemSelect={() => setContextElementMenuOpened(false)}
              notifyIfValueChanged={notifyIfValueChanged}
              projectId={funnel.projectId}
            />
          </div>
        </ViewportConstrainer>
      )}

      {isExportModalOpened && (
        <ExportComponent
          btnRef={pngExportRef}
          onExportClose={onExportClose}
          onSaveToPNG={saveToPNG}
          onExportElements={onExportElements}
          onExportConnections={onExportConnections}
          analyticsStatus={analyticsStatus}
        />
      )}

      {/* {isFunnelModelOpened && (
        <FunnelModeComponent
          btnRef={funnelModeRef}
          onClose={onFunnelModeClose}
          onModeChanged={onFunnelModeChanged}
          onLoopDetected={onLoopDetected}
          activeMode={funnelConfiguration.dataMode}
        />
      )} */}
      {/* {isLoopWarningOpened && <LoopWarningComponent onClose={onLoopWarrningClose} />} */}
    </div>
  );
};

export default ToolsBox;
