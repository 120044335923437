import React, { useEffect, useState } from 'react';
import ExpireHidden from './ExpireHidden';
import ExpireVisible from './ExpireVisible';

const ExpireView = (props) => {
  const [visible, setVisible] = useState(true);
  let timeout = null;
  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    timeout = setTimeout(() => {
      setVisible(false);
    }, props.delay);
  }, [props.delay]);

  const showVisibleView = () => {
    return props.children.find((child) => child.type === ExpireVisible);
  };

  const showHiddenView = () => {
    return props.children.find((child) => child.type === ExpireHidden);
  };

  return visible ? showVisibleView() : showHiddenView();
};

export default ExpireView;
