import React from 'react';
import stepStyles from 'react-project/Toolbar/Toolbar.module.scss';
import styles from './TrackingTabAction.module.scss';
import { PopUpWrapper } from 'react-project/components/popUp/PopUpWrapper';
import { iconHorizontalArrows } from 'react-project/assets/Icons';
import ActionAttributeExplorer from './ActionAttributeExplorer';
import cx from 'classnames';

export const TrackingSettings = ({
  children,
  title,
  description,
  onClose,
  projectId,
  isVisible,
}) => {
  return (
    <PopUpWrapper
      icon={iconHorizontalArrows}
      title={title}
      showCloseIcon={true}
      onClosePopup={() => onClose()}
      cancel={'rndCancel'}
      minWidth={800}
      popupWidth={800}
      className={isVisible ? null : styles.Hidden}
    >
      <div className={cx('rndCancel cursor-auto', stepStyles.ActionAttributesWrapper)}>
        <div className={stepStyles.TrackingTab} style={{ width: '400px', minWidth: '400px' }}>
          <p className={styles.SettingsDescription}>{description}</p>
          <div className={styles.SettingsContent}>{children}</div>
        </div>
        <ActionAttributeExplorer projectId={projectId} />
      </div>
    </PopUpWrapper>
  );
};
