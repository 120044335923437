import * as PIXI from 'pixi.js';
import Styles, {
  COLOR_WIDGET_ZEBRA,
  COLOR_WIDGET_SELECTED_ROW,
  COLOR_WIDGET_HOVER_ROW,
} from '../../Styles';

export default class TableRow extends PIXI.Container {
  constructor(headerData, rowSize, columnSpacing = 0) {
    super();
    this.data = null;
    this.headerData = headerData;
    this.rowSize = rowSize;
    this.columnSpacing = columnSpacing;
    this.background = null;
    this.isSelected = false;
    this.isEven = false;
    this.isHover = false;
    this.isMouseDown = false;
    this.hasMoved = false;
    this.reverseZebra = false;
    this.initRow();
  }

  setInteractive() {
    this.pointerover = this.onHoverIn.bind(this);
    this.pointerout = this.onHoverOut.bind(this);
    this.interactive = true;
    this.cursor = 'pointer';
    this.hitArea = new PIXI.Rectangle(1, 0, this.rowSize.width - 2, this.rowSize.height);
  }

  initRow() {
    this.background = new PIXI.Graphics();
    this.addChild(this.background);
  }

  setBackgroundColor(color) {
    this.background.clear();
    this.background.beginFill(color);
    this.background.lineStyle(0);
    this.background.drawRect(1, 0, this.rowSize.width - 2, this.rowSize.height);
  }

  setData(data) {
    this.data = data;
  }

  displayData(isEven = false) {
    this.isEven = isEven;
    this.updateBackgroundColor();
    this.bindData();
    this.updatePositions();
  }

  bindData() {
    // abstract method
  }

  updatePositions() {
    // abstract method
  }

  alignElementsInCell(elements, cellX, cellWidth, cellHeight, contentPadding, align) {
    if (align === 'left') {
      let startX = cellX;
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        let elementOffsetX = element.anchor.x * element.width;
        element.x = startX + elementOffsetX;
        element.y = cellHeight / 2;
        startX += contentPadding + element.width;
      }
    } else if (align === 'right') {
      let startX = cellX + cellWidth;
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        let elementOffsetX = element.anchor.x * element.width;
        element.x = startX + elementOffsetX - element.width;
        element.y = cellHeight / 2;
        startX -= contentPadding + element.width;
      }
    } else {
      console.warn('//TODO Center alignmenet not implemented yet');
    }
  }

  onSelectChanged(isSelected) {
    this.updateBackgroundColor();
  }

  select() {
    this.isSelected = true;
    this.onSelectChanged(this.isSelected);
  }

  deselect() {
    this.isSelected = false;
    this.onSelectChanged(this.isSelected);
  }

  onHoverIn(e) {
    this.isHover = true;
    this.updateBackgroundColor();
  }

  onHoverOut(e) {
    this.isHover = false;
    this.updateBackgroundColor();
  }

  updateBackgroundColor() {
    this.background.visible = true;

    if (this.isSelected) {
      this.setBackgroundColor(COLOR_WIDGET_SELECTED_ROW);
    } else if (this.isHover) {
      this.setBackgroundColor(COLOR_WIDGET_HOVER_ROW);
    } else if (!this.isEven && !this.reverseZebra) {
      this.setBackgroundColor(COLOR_WIDGET_ZEBRA);
    } else if (this.isEven && this.reverseZebra) {
      this.setBackgroundColor(COLOR_WIDGET_ZEBRA);
    } else {
      this.background.visible = false;
    }

    window.app.needsRendering();
  }

  onPointerDown(event) {
    this.isMouseDown = true;
    this.hasMoved = false;
  }

  onPointerMove(event) {
    if (!this.isMouseDown) {
      return;
    }
    this.hasMoved = true;
    return;
  }

  onPointerUp(event) {
    this.isMouseDown = false;
    this.hasMoved = false;
  }

  onPointerCancel() {
    this.isMouseDown = false;
    this.hasMoved = false;
  }
}
