import { isNumber } from 'lodash';

export function numSeparator(value = '', separator = ',') {
  return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, separator);
}

export function numSeparatorRound(value = '', separator = ',') {
  return Math.round(value)
    .toString()
    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, separator);
}

export const isValidNumber = (value) => {
  const number = parseFloat(value);
  if (isNumber(number) && number.toString() === value) {
    return true;
  } else if (value.endsWith('.') || value.startsWith('.') || value === '.') {
    return true;
  }
  return false;
};

export const validateInput = (input) => {
  let isValid = false;
  if (isValidNumber(input.value)) {
    input.setCustomValidity('');
    isValid = true;
  } else {
    input.setCustomValidity('Please enter a number');
  }

  input.reportValidity();
  return isValid;
};
