import cls from 'classnames';
import { isNumber } from 'lodash';
import MainStorage from 'pixi-project/core/MainStorage';
import React from 'react';
import { iconForecasting } from 'react-project/assets/Icons';
import { setStepForecastingData } from 'react-project/redux/current-step/actions';
import { selectCurrentStep } from 'react-project/redux/current-step/selectors';
import ui from 'react-project/ui.module.scss';
import { When } from 'react-project/Util/When';
import { useDispatch, useSelector } from 'react-redux';
import { ActionTypes } from 'shared/CSharedConstants';
import SharedElementHelpers from 'shared/SharedElementHelpers';
import { FloatingWindow } from '../floatingWindow/FloatingWindow';
import { selectSelectedElements } from 'react-project/redux/selected-elements/selectors';
import { DropDown } from '../dropdowncomponent/dropdown';
import { ForecastingConnectionView } from './ForecastingConnectionView';

const TITLE = <div className={cls(ui.ContainerRows, ui.Gap5)}>{iconForecasting}Forecast</div>;

export const periodOptions = [
  { value: 0, label: 'Per Day' },
  { value: 1, label: 'Per Week' },
  { value: 2, label: 'Per Month (30-day avg)' },
  { value: 3, label: 'Fixed' },
];

const methodOptions = [
  { value: 0, label: 'Spend & Cost per Person' },
  { value: 1, label: 'People & Cost per Person' },
  { value: 2, label: 'People & Spend' },
];

export const ForecastingComponent = ({ onClose }) => {
  const currentStep = useSelector(selectCurrentStep);
  const selectedElements = useSelector(selectSelectedElements);
  const dispatch = useDispatch();

  const isConnection = SharedElementHelpers.IsConnection(currentStep.object);
  const element = selectedElements[0];
  const avoidRect = element ? element.getBounds() : null;

  let isRootElement = false;
  if (!isConnection) {
    if (
      window.app.planeContainer.sceneManager.graphData &&
      window.app.planeContainer.sceneManager.graphData.roots
    ) {
      const roots = window.app.planeContainer.sceneManager.graphData.roots;
      if (roots.includes(currentStep.stepId)) {
        isRootElement = true;
      }
    }
  }

  const isValidNumber = (value) => {
    const number = parseFloat(value);
    if (isNumber(number) && number.toString() === value) {
      return true;
    }
    return false;
  };

  const validateInput = (input) => {
    let isValid = false;
    if (isValidNumber(input.value)) {
      input.setCustomValidity('');
      isValid = true;
    } else {
      input.setCustomValidity('Please enter a number');
    }

    input.reportValidity();
    return isValid;
  };

  const onPeopleChanged = (e) => {
    const isValid = validateInput(e.target);
    if (isValid) {
      const numberOfPeople = e.target.value;
      dispatch(setStepForecastingData(currentStep.stepId, { numberOfPeople }));
    } else {
      dispatch(setStepForecastingData(currentStep.stepId, { numberOfPeople: null }));
    }
  };

  const onCostChanged = (e) => {
    const isValid = validateInput(e.target);
    if (isValid) {
      const cost = e.target.value;
      dispatch(setStepForecastingData(currentStep.stepId, { cost }));
    } else {
      dispatch(setStepForecastingData(currentStep.stepId, { cost: null }));
    }
  };

  const onCostPerPersonChanged = (e) => {
    const isValid = validateInput(e.target);
    if (isValid) {
      const costPerPerson = e.target.value;
      dispatch(setStepForecastingData(currentStep.stepId, { costPerPerson }));
    } else {
      dispatch(setStepForecastingData(currentStep.stepId, { costPerPerson: null }));
    }
  };

  const onRevenuePerPersonChanged = (e) => {
    const isValid = validateInput(e.target);
    if (isValid) {
      const avgRevenue = parseFloat(e.target.value);
      dispatch(setStepForecastingData(currentStep.stepId, { avgRevenue }));
    } else {
      dispatch(setStepForecastingData(currentStep.stepId, { avgRevenue: null }));
    }
  };

  const getValue = (currentStep, prop) => {
    if (currentStep.object.forecastingData) {
      if (
        currentStep.object.forecastingData[prop] !== null &&
        currentStep.object.forecastingData[prop] !== undefined
      ) {
        return currentStep.object.forecastingData[prop];
      }
    }

    return '';
  };

  const getCalculatedValue = (currentStep, prop) => {
    if (currentStep.object.forecastingCalculatedData) {
      if (currentStep.object.forecastingCalculatedData[prop] !== undefined) {
        return currentStep.object.forecastingCalculatedData[prop];
      }
    }

    return '';
  };

  const findSelectionItem = (currentStep, prop) => {
    const value = getValue(currentStep, prop);
    const option = periodOptions.find((option) => option.value === value);

    return option ? option : periodOptions[0];
  };

  const findMethodItem = (currentStep, prop) => {
    const value = getValue(currentStep, prop);
    const option = methodOptions.find((option) => option.value === value);

    return option ? option : methodOptions[0];
  };

  const onPeriodChange = (item) => {
    const period = parseInt(item.value);
    dispatch(setStepForecastingData(currentStep.stepId, { period }));
  };

  const onInputMethodChange = (item) => {
    const calculationMethod = parseInt(item.value);
    dispatch(setStepForecastingData(currentStep.stepId, { calculationMethod }));
  };

  const dropDownWidth = { width: '300px' };
  const inputStyle = { width: '220px', minWidth: '220px' };
  const selectedPeriod = findSelectionItem(currentStep, 'period');
  const calculationMethod = findMethodItem(currentStep, 'calculationMethod');

  let calculatedCost = 0;
  let calculatedCostPerPerson = 0;

  if (currentStep.object.forecastingData) {
    // realtime calculation
    if (calculationMethod.value === 2) {
      // calculate cost per person
      if (currentStep.object.forecastingData.cost !== undefined) {
        calculatedCostPerPerson =
          currentStep.object.forecastingData.cost /
          currentStep.object.forecastingData.numberOfPeople;
      }
    } else if (calculationMethod.value === 1) {
      // calculate cost , its the reverse of the above
      if (currentStep.object.forecastingData.costPerPerson !== undefined) {
        calculatedCost =
          currentStep.object.forecastingData.numberOfPeople *
          currentStep.object.forecastingData.costPerPerson;
      }
    }
  }

  // safeguard for NaN
  if (isNaN(calculatedCost)) {
    calculatedCost = 0;
  }

  if (isNaN(calculatedCostPerPerson)) {
    calculatedCostPerPerson = 0;
  }

  const isCommerece = currentStep.object.actionType === ActionTypes.COMMERCE;
  const isSource = SharedElementHelpers.IsSource(currentStep.object);

  // The poeple input field is disabled when the element is not a root element or
  // if isSource and  the calculation method is not 0
  let isPeopleDisabled = !isRootElement || (isSource && calculationMethod.value === 0);

  let peopleValue = '';

  if (isRootElement) {
    if (
      isSource &&
      currentStep.object.forecastingData &&
      currentStep.object.forecastingData.cost !== undefined &&
      currentStep.object.forecastingData.costPerPerson !== undefined &&
      !calculationMethod.value
    ) {
      peopleValue =
        currentStep.object.forecastingData.cost / currentStep.object.forecastingData.costPerPerson;
    } else {
      peopleValue = getValue(currentStep, 'numberOfPeople');
    }
  } else {
    peopleValue = getCalculatedValue(currentStep, 'people');
  }

  return (
    <>
      <FloatingWindow
        title={TITLE}
        onWindowClose={onClose}
        avoidRect={avoidRect}
        contentStyle={{ minHeight: 'auto', overflow: 'visible' }}
      >
        <When condition={!isConnection}>
          <div className={cls(ui.ContainerColumns, ui.AlignNormal, ui.Pad5_10, ui.Gap10)}>
            <div className={cls(ui.FlexStart, ui.Text)}>Set the forecast for the element</div>

            <div className={cls(ui.ContainerRows, ui.Gap10, ui.PadLeftRight10)}>
              <label className={ui.Label}>Period</label>
              <DropDown
                onSelectionChanged={onPeriodChange}
                itemList={periodOptions}
                classStyling={ui}
                style={dropDownWidth}
                selectedItem={isRootElement ? selectedPeriod : null}
                disabled={!isRootElement}
              />
            </div>

            <When condition={isSource}>
              <div className={cls(ui.ContainerRows, ui.Gap10, ui.PadLeftRight10)}>
                <label className={ui.Label}>Inputs</label>
                <DropDown
                  onSelectionChanged={onInputMethodChange}
                  itemList={methodOptions}
                  classStyling={ui}
                  style={dropDownWidth}
                  selectedItem={calculationMethod}
                />
              </div>
            </When>
          </div>

          <div className={ui.Separator} />
        </When>

        <div className={cls(ui.ContainerColumns, ui.AlignNormal, ui.PadBottom10)}>
          {/* STEP View */}
          <When condition={!isConnection}>
            <div className={cls(ui.ContainerRows, ui.Pad10_20)}>
              <label className={ui.Label}>
                People
                <When condition={isRootElement}>
                  <span>{selectedPeriod.label}</span>
                </When>
              </label>
              <input
                className={ui.Input}
                style={inputStyle}
                onKeyDown={(e) => e.stopPropagation()}
                onChange={onPeopleChanged}
                type="number"
                placeholder="0"
                step="1"
                value={peopleValue}
                disabled={isPeopleDisabled}
                autoFocus
              />
            </div>

            <When condition={isSource}>
              <div className={cls(ui.ContainerRows, ui.Pad10_20, ui.Gap5)}>
                <label className={ui.Label}>
                  Spend
                  <When condition={isRootElement}>
                    <span>{selectedPeriod.label}</span>
                  </When>
                </label>

                <input
                  className={ui.Input}
                  style={inputStyle}
                  onKeyDown={(e) => e.stopPropagation()}
                  onChange={onCostChanged}
                  placeholder="0"
                  type="number"
                  step="any"
                  value={
                    calculationMethod.value === 1 ? calculatedCost : getValue(currentStep, 'cost')
                  }
                  disabled={calculationMethod.value === 1}
                />
              </div>

              <div className={cls(ui.ContainerRows, ui.Pad10_20, ui.Gap5)}>
                <label className={ui.Label}>Cost per Person</label>

                <input
                  className={ui.Input}
                  style={inputStyle}
                  onKeyDown={(e) => e.stopPropagation()}
                  onChange={onCostPerPersonChanged}
                  placeholder="0"
                  type="number"
                  step="any"
                  value={
                    calculationMethod.value === 2
                      ? calculatedCostPerPerson
                      : getValue(currentStep, 'costPerPerson')
                  }
                  disabled={calculationMethod.value === 2}
                />
              </div>
            </When>

            <When condition={isCommerece}>
              <div className={cls(ui.ContainerRows, ui.Pad10_20)}>
                <label className={ui.Label}>Revenue per Person</label>

                <input
                  className={ui.Input}
                  style={inputStyle}
                  onKeyDown={(e) => e.stopPropagation()}
                  onChange={onRevenuePerPersonChanged}
                  placeholder="0"
                  type="number"
                  step="any"
                  defaultValue={getValue(currentStep, 'avgRevenue')}
                />
              </div>
            </When>
          </When>

          {/* CONNECTION view */}

          <When condition={isConnection}>
            <ForecastingConnectionView />
          </When>
        </div>
      </FloatingWindow>
    </>
  );
};
