import React from 'react';
import { CanvasLibrary } from './CanvasLibrary';
import { ShareLink } from './ShareLink';
import Styles from './ShareLink.module.scss';
import { ShareTabs } from './constants';

export const Body = ({ currentTab, switchTab }) => {
  // Include alert message layer to prevent switching tabs when there are errors in the form
  return (
    <div className={Styles.body}>
      {currentTab === ShareTabs.SHARING_LINK && <ShareLink switchTab={switchTab} />}
      {currentTab === ShareTabs.CANVAS_LIBRARY && <CanvasLibrary />}
      {currentTab === ShareTabs.COLLABORATORS && <div> Collaborators</div>}
    </div>
  );
};
