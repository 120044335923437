import cls from 'classnames';
import React, { Fragment } from 'react';
import styles from './Tutorial.module.scss';
import { Body } from './Tutorial/Body';

export const Tutorial = ({ onClose }) => {
  const onBtnClose = () => {
    onClose();
  };

  const onBackToCanvas = () => {
    onClose();
  };

  return (
    <Fragment>
      <div className={cls(styles.Overlay, styles.OverlayActive)} />
      <div className={styles.upsellModal}>
        <div className={styles.upsellClose} onClick={onBtnClose}>
          &times;
        </div>
        <div className={styles.upsellBody}>
          <Body />
        </div>
      </div>
    </Fragment>
  );
};

Tutorial.propTypes = {};
