import { PR_EVENT_FUNNEL_CHANGED } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';

export default class CommandDeleteReportSlide {
  constructor(objects, parent, object, index) {
    this.objects = objects;
    this.parent = parent;
    this.object = object;
    this.index = index;
    this.isExecuted = false;
  }

  execute() {
    if (!this.isExecuted) {
      this.isExecuted = true;
      window.app.needsRendering();

      this.parent.removeSlide(this.object, this.index);
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
    }
  }

  revert() {
    if (this.isExecuted) {
      this.isExecuted = false;
      window.app.needsRendering();

      this.parent.addSlide(this.object, this.index);
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
    }
  }
}
