import React from 'react';
export const StyleButton = ({ onToggle, style, active, label }) => {
  const onMouseDown = (e) => {
    e.preventDefault();
    onToggle(style);
  };

  let className = 'RichEditor-styleButton';
  if (active) {
    className += ' RichEditor-activeButton';
  }

  return (
    <span className={className} onMouseDown={onMouseDown}>
      {label}
    </span>
  );
};
