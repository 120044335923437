export const isMouseWheelButton = (event) => {
  return event.data?.originalEvent?.which === 2 || event.which === 2;
};

export const isRightButton = (event) => {
  return event.data?.originalEvent?.which === 3 || event.which === 3;
};

export const isShiftKey = (event) => {
  return event.shiftKey;
};

export const isCtrlKey = (event) => {
  let kc = event.keyCode;
  if (kc === 17 || kc === 224 || kc === 91 || kc === 92 || kc === 93) {
    return true;
  }
  return false;
};

export const isEscapeKey = (event) => {
  return event.key === 'Escape' || event.code === 'Escape';
};

export const isSpaceKey = (event) => {
  return event.keyCode === 32 || event.code === 'Space';
};

export const isKeyC = (event) => {
  return event.keyCode === 67 || event.code === 'KeyC';
};

export const isKeyD = (event) => {
  return event.keyCode === 68 || event.code === 'KeyD';
};

export const isKeyV = (event) => {
  return event.keyCode === 86 || event.code === 'KeyV';
};

export const isKeyZ = (event) => {
  return event.keyCode === 90 || event.code === 'KeyZ';
};

export const isKeyY = (event) => {
  return event.keyCode === 89 || event.code === 'KeyY';
};

export const isKeyPlus = (event) => {
  // Because the + key is behind the = key in windows
  return (
    event.key === '+' || event.key === '=' || event.code === 'Equal' || event.code === 'NumpadAdd'
  );
};

export const isKeyMinus = (event) => {
  return event.key === '-' || event.code === 'Minus' || event.code === 'NumpadSubtract';
};

export const isKeyZero = (event) => {
  return event.key === '0' || event.code === 'Numpad0' || event.code === 'Digit0';
};

export const isKey1 = (event) => {
  return event.key === '1' || event.code === 'Numpad1' || event.code === 'Digit1';
};

export const isDelete = (event) => {
  const key = event.key; // const {key} = event; ES6+
  if (key === 'Backspace' || key === 'Delete') {
    return true;
  }
};

export const isKeyLeftSBracket = (event) => {
  return event.keyCode === 219 || event.code === 'BracketLeft';
};

export const isKeyRightSBracket = (event) => {
  return event.keyCode === 221 || event.code === 'BracketRight';
};

export const isKeyS = (event) => {
  return event.keyCode === 83 || event.code === 'KeyS';
};

export const isKeyT = (event) => {
  return event.keyCode === 84 || event.code === 'KeyT';
};

export const isKeyO = (event) => {
  return event.keyCode === 79 || event.code === 'KeyO';
};

export const isKeyR = (event) => {
  return event.keyCode === 82 || event.code === 'KeyR';
};

export const isKeyP = (event) => {
  return event.keyCode === 80 || event.code === 'KeyP';
};

// 84 'KeyT'
// 79 'KeyO'
// 82 'KeyR'
// 80 'KeyP'

export const commonSendEventFunction = (eventType, detailObj) => {
  const event = new CustomEvent(eventType, { detail: detailObj });
  document.dispatchEvent(event);
};

/**
 * Source: https://stackoverflow.com/questions/15762768/javascript-math-round-to-two-decimal-places
 * @param {Number} number
 * @param {int} digits Default value is 0
 * @returns String representation of the rounded number
 */
export const roundTo = (number, digits = 0) => {
  let negative = false;
  if (number < 0) {
    negative = true;
    number = number * -1;
  }
  let multiplicator = Math.pow(10, digits);
  number = parseFloat((number * multiplicator).toFixed(11));
  number = (Math.round(number) / multiplicator).toFixed(digits);
  if (negative) {
    number = (number * -1).toFixed(digits);
  }
  return Number(number);
};

/**
 * Takes a number and concatenates a % string at the end
 * @param {Number} number any number
 * @param {Integer} digits it defaults to 2 decimal places
 * @returns String with a % sign at the end
 */
export const toPercent = (number, digits = 2, showPlusSign = false) => {
  if (number > 0) {
    const prepend = showPlusSign ? '+' : '';
    return `${prepend}${roundTo(number, digits)}%`;
  } else {
    return `${roundTo(number, digits)}%`;
  }
};

export const toDollar = (number) => {
  if (typeof number !== 'number') return number;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(Number(number));
};

export const nFormatter = (num, digits = 2) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};

export const isEnterKey = (key) => {
  return key === 'Enter';
};

export const goToURL = (link) => {
  return window.open(link, '_blank');
};

export const getMousePosition = () => {
  return window.app.renderer.plugins.interaction.mouse.global || { x: 0, y: 0 };
};

/**
 * Deep clone an object data without cloning the methods
 * @param {Object} object
 * @returns cloned data object
 */
export const cloneData = (object) => {
  return JSON.parse(JSON.stringify(object));
};
