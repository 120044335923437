import * as PIXI from 'pixi.js';
import { COLOR_DISPLAY_BORDER } from 'pixi-project/view/Styles';
import DropShadowGenerator from 'pixi-project/utils/DropShadowGenerator';
import CommonDisplaySegment from './CommonDisplaySegment';
import CommonMultiDisplaySegment from './CommonMultiDisplaySegment';
import CommonCompareMultiDisplaySegment from './CommonCompareMultiDisplaySegment';
import CompareForecastingSegment from './CompareForecastingSegment';
import CommonForecastingSegment from './CommonForecastingSegment';

export const LABEL_OFFSET_Y = 6;
export const LABEL_PADDING = 5;
export const BOX_PADDING = 10; //TODO this must be the same as the selection padding

export const LABEL_ALIGN_LEFT = 'left';
export const LABEL_ALIGN_RIGHT = 'right';
export const LABEL_ALIGN_CENTER = 'center';

const STEP_SPACING = 10;
const BOTTOM_PADDING = 4;

export default class StepDisplay extends PIXI.Container {
  constructor(step) {
    super();

    this.data = [];
    this.step = step;
    this.canHaveValue = false;
    this.backgroundBox = null;
    this.isStepFooter = true;
    this.isCompare = false;
    this.boxStyle = {
      borderThickness: 1,
      borderColor: COLOR_DISPLAY_BORDER,
      borderRadius: 4,
      backgroundColor: 0xffffff,
      padding: 7,
    };

    this.backgroundBox = new PIXI.Graphics();
    this.addChild(this.backgroundBox);
    this.backgroundBox.pushToBack();

    this.activeDisplay = null;

    this.commonDisplay = new CommonDisplaySegment();
    this.addChild(this.commonDisplay);

    this.coommonMultiDisplay = new CommonMultiDisplaySegment();
    this.addChild(this.coommonMultiDisplay);

    this.commonCompareMultiDisplay = new CommonCompareMultiDisplaySegment();
    this.addChild(this.commonCompareMultiDisplay);

    this.compareForecastingDisplay = new CompareForecastingSegment();
    this.addChild(this.compareForecastingDisplay);

    this.commonForecastingDisplay = new CommonForecastingSegment();
    this.addChild(this.commonForecastingDisplay);

    this.compareRange = { from: 0, to: 0 };
    this.shouldCache = true;
  }

  setCurrency(currency) {
    this.commonDisplay.currency = currency;
    this.coommonMultiDisplay.currency = currency;
    this.commonCompareMultiDisplay.currency = currency;
    this.compareForecastingDisplay.currency = currency;
    this.commonForecastingDisplay.currency = currency;
  }

  hideAllDisplays() {
    this.isCompare = false;
    // Keep adding displays here
    this.commonDisplay.visible = false;
    this.coommonMultiDisplay.visible = false;
    this.commonCompareMultiDisplay.visible = false;
    this.compareForecastingDisplay.visible = false;
    this.commonForecastingDisplay.visible = false;
  }

  activateDisplay(display) {
    this.hideAllDisplays();
    this.activeDisplay = display;
    this.activeDisplay.compareRange = this.compareRange;
    display.visible = true;
    this.setDropShadow(true);
  }

  setCommonData(title, value) {
    this.activateDisplay(this.commonDisplay);
    const width = this.step.content.width;
    this.commonDisplay.setData(title, value, this.step.goal, width + BOX_PADDING * 2);
    this.renderBox();
  }

  setCommonMultiData(data, value) {
    this.activateDisplay(this.coommonMultiDisplay);
    const width = this.step.content.width;
    this.coommonMultiDisplay.setData(data, this.step.goal, value, width + BOX_PADDING * 2);
    this.renderBox();
  }

  setCommonCompareMultiData(data) {
    this.activateDisplay(this.commonCompareMultiDisplay);
    this.isCompare = true;
    const width = this.step.content.width;
    this.commonCompareMultiDisplay.setData(data, this.step.goal, width + BOX_PADDING * 2);
    this.renderBox();
  }

  setCommonForecastingData(title, value, cost, revenue) {
    this.activateDisplay(this.commonForecastingDisplay);
    const width = this.step.content ? this.step.content.width : 100;
    this.commonForecastingDisplay.setData({
      title,
      value,
      cost,
      revenue,
      width: width + BOX_PADDING * 2,
    });
    this.renderBox();
  }

  setCompareForecastingData(data) {
    this.activateDisplay(this.compareForecastingDisplay);
    this.isCompare = true; //if we are showing the compare box , this is needed
    const width = this.step.content.width;
    this.compareForecastingDisplay.setData(data, this.step.goal, width + BOX_PADDING * 2);
    this.renderBox();
  }

  setNoData() {
    this.activateDisplay(this.commonDisplay);
    this.commonDisplay.setNoData();
    this.renderBox();
  }

  resetData() {
    this.activateDisplay(this.commonDisplay);
    this.commonDisplay.resetData();
    this.renderBox();
  }

  setData(dataArray) {}

  getFooterHeight() {
    return this.activeDisplay
      ? this.activeDisplay.getArea().height + (this.isCompare ? -1 : BOTTOM_PADDING)
      : 0;
  }

  renderBox() {
    if (!this.step.content) {
      return;
    }

    this.backgroundBox.cacheAsBitmap = false;

    const contentHeight = this.activeDisplay.getArea().height;

    const width = this.step.content.width;
    const footerStartY = this.step.content.height + STEP_SPACING;

    this.activeDisplay.y = footerStartY;

    const x = -width / 2 - BOX_PADDING;
    const y = -BOX_PADDING;

    this.backgroundBox.clear();

    if (this.step.goal && this.step.goal.hasGoal) {
      this.backgroundBox.lineStyle(2, 0x0ed073, 1, 1);
    } else {
      this.backgroundBox.lineStyle(0);
    }

    this.backgroundBox.beginFill(this.boxStyle.backgroundColor, 1);
    this.backgroundBox.drawRoundedRect(
      x,
      y,
      width + BOX_PADDING * 2,
      footerStartY + BOX_PADDING + contentHeight + (this.isCompare ? -1 : BOTTOM_PADDING),
      this.boxStyle.borderRadius,
    );
    this.backgroundBox.endFill();

    if (this.shouldCache) {
      this.backgroundBox.cacheAsBitmapResolution = 2;
      this.backgroundBox.cacheAsBitmap = true;
    }
  }

  setCaching(shouldCache) {
    this.shouldCache = shouldCache;

    if (this.shouldCache) {
      this.backgroundBox.cacheAsBitmapResolution = 2;
      this.backgroundBox.cacheAsBitmap = true;
    } else {
      this.backgroundBox.cacheAsBitmap = false;
    }
  }

  updateVisibility(isVisible) {
    if (this.canHaveValue) {
      this.visible = isVisible;
    }
  }

  setDropShadow(visible) {
    this.backgroundBox.cacheAsBitmap = false;
    if (visible) {
      this.backgroundBox.filters = [DropShadowGenerator.getFilter()];
    } else {
      this.backgroundBox.filters = [];
    }

    if (this.shouldCache) {
      this.backgroundBox.cacheAsBitmap = true;
    }
  }

  updateView(range) {
    this.compareRange = range;
    if (this.activeDisplay) {
      if (this.activeDisplay.onRangeUpdated) {
        this.activeDisplay.compareRange = this.compareRange;
        this.activeDisplay.onRangeUpdated();
      }
    }
  }
}
