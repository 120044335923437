import Worker from 'worker-loader!pixi-project/workers/workerCreateCanvas.js';

export default class ImageSnapshot {
  constructor(renderer, width, height, resolution = 2) {
    this.width = width;
    this.height = height;
    this.resolution = resolution;
    this.renderer = renderer;
  }

  setSize(width, height, resolution) {
    this.width = width;
    this.height = height;
    this.resolution = resolution || this.resolution;
  }

  createSnapshot(content, name, resolution, quality, beforeRender, afterRender) {
    const width = this.width;
    const height = this.height;

    if (
      this.checkIfTextureIsAboveWebGLLimit({
        width: width * resolution,
        height: height * resolution,
      })
    ) {
      throw new Error(
        'Your Graphics Card does not support this resolution. Please try a lower resolution.',
      );
      return;
    }

    const renderTexture = new PIXI.RenderTexture.create({
      width,
      height,
      resolution: resolution || this.resolution,
    });

    if (beforeRender) {
      beforeRender(content, renderTexture);
    }

    // Render the scene into a new texture
    this.renderer.render(content, { renderTexture });

    if (afterRender) {
      afterRender(content);
    }

    // Handle the image
    const img = this.renderer.plugins.extract.image(renderTexture, 'image/png', quality);
    img.name = name + '@' + resolution + 'x';
    renderTexture.destroy();

    return img;
  }

  checkIfTextureIsAboveWebGLLimit(texture) {
    if (window.app.renderer.gl) {
      const size = window.app.renderer.gl.getParameter(window.app.renderer.gl.MAX_TEXTURE_SIZE);
      if (texture.width > size || texture.height > size) {
        return true;
      }
    }

    return false;
  }

  getCanvas(content, beforeRender, afterRender) {
    const width = this.width;
    const height = this.height;
    const resolution = this.resolution;

    const renderTexture = new PIXI.RenderTexture.create({ width, height, resolution });

    if (beforeRender) {
      beforeRender(content);
    }

    // Render the scene into a new texture
    this.renderer.render(content, { renderTexture });

    if (afterRender) {
      afterRender(content);
    }

    return this.exportToCanvas(renderTexture);
  }

  exportToCanvas(renderTexture) {
    const renderer = this.renderer;
    const gl = renderer.gl;
    const resolution = renderTexture.baseTexture.resolution;
    const frame = renderTexture.frame;
    const BYTES_PER_PIXEL = 4;
    const width = Math.floor(frame.width * resolution + 1e-4);
    const height = Math.floor(frame.height * resolution + 1e-4);
    const webglPixels = new Uint8Array(BYTES_PER_PIXEL * width * height);

    // upload the texture to the renderer
    renderer.renderTexture.bind(renderTexture);

    // Read the pixels from the renderer context
    gl.readPixels(
      frame.x * resolution,
      frame.y * resolution,
      width,
      height,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      webglPixels,
    );

    // Map the pixel data to canvas element
    return new Promise(function (resolve, reject) {
      let worker = new Worker();
      worker.addEventListener('message', (evt) => {
        let canvasData = evt.data.canvasData;
        let canvasBuffer = new PIXI.utils.CanvasRenderTarget(width, height, 1);
        canvasBuffer.context.putImageData(canvasData, 0, 0);
        resolve(canvasBuffer.canvas);
      });
      worker.postMessage({ width, height, webglPixels });
    });
  }

  forceDownloadImage(img) {
    let a = document.createElement('a');
    a.href = img.src;
    a.download = `${img.name || 'image'}.png`;
    a.click();
  }

  copyImageToClipboard(img) {
    //If Clipboard is supported
    if (window['ClipboardItem'] && navigator.clipboard) {
      fetch(img.src)
        .then(function (response) {
          return response.blob();
        })
        .then(function (blob) {
          const item = new window.ClipboardItem({ 'image/png': blob });
          navigator.clipboard.write([item]);
        });
    }
  }
}
