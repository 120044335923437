import React, { useEffect, useState } from 'react';
import NotFound from 'react-project/Main/NotFound';
import { Funnel } from './Funnel';
import RequestService from 'react-project/Helpers/RequestService';
import {
  getAuthToken,
  getRefreshToken,
  getTutorialAuth,
  getUserId,
} from 'react-project/Util/AuthCookie';
import NoAccess from 'react-project/Main/NoAccess';
import Frobidden from 'react-project/Main/Forbidden';
import { TutorialPopup } from 'react-project/components/popUp/Tutorial/TutorialPopup';
import * as ReactDOM from 'react-dom';

const requestService = new RequestService();

export const TutorialView = (props) => {
  const [isChecking, setIsChecking] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isShared, setIsShared] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [isLinkValid, setIsLinkValid] = useState(true);

  useEffect(() => {
    if (!props.match.params.funnelId) {
      return;
    }

    initializeApp();
  }, []);

  const initializeApp = () => {
    const userId = getUserId();

    if (userId) {
      const accessToken = getAuthToken();
      // but check if this canvas can be accessed

      requestService.authTutorial(props.match.params.funnelId).then((result) => {
        // make sure that the canvas is a tutorial canvas
        if (result.success) {
          setIsShared(true);
          setAccessToken(accessToken);
          setIsAuthorized(true);
          setIsChecking(false);
        } else {
          setIsChecking(false);
          setAccessToken(false);
          setIsAuthorized(false);
        }
      });

      return;
    } else {
      requestService.validateReadOnlyLink(props.match.params.funnelId).then(onCheckFinished);
    }
  };

  const onCanvasSelection = (canvasId) => {
    // redirect
    window.location.href = `${process.env.PUBLIC_URL}/tutorial/${canvasId}`;
  };

  const onCheckFinished = (result) => {
    setIsChecking(false);

    if (result.success) {
      setIsShared(true);

      // check if its already authorized
      const tutorialAuth = getTutorialAuth();

      if (tutorialAuth && tutorialAuth.access_token) {
        setAccessToken(tutorialAuth.access_token);
        setIsAuthorized(true);
        return;
      } else {
        setIsChecking(true);
        checkPassword();
      }
    } else {
      console.log('failed to validate link', result);
      // result.err.response.data.errors[0].error.message
      // 'Viewonly link was not found'
      if (
        result &&
        result.err &&
        result.err.response &&
        result.err.response.data &&
        result.err.response.data.errors &&
        result.err.response.data.errors.length &&
        result.err.response.data.errors[0].error.message === 'Viewonly link was not found'
      ) {
        setIsLinkValid(false);
        return;
      }

      // If the link validation failed
      // but there is a token in the local storage
      // probably that token is expired
      // so lets try to refresh it
      const viewOnlyAuth = getTutorialAuth();
      if (viewOnlyAuth && viewOnlyAuth.access_token) {
        // clear a pre existing token
        localStorage.setItem(`tutorial-auth-${props.match.params.funnelId}`, undefined);
        // then check again
        requestService.validateReadOnlyLink(props.match.params.funnelId).then(onCheckFinished);
      } else {
        setAccessToken(false);
      }
    }
  };

  const checkPassword = () => {
    requestService
      .authTutorial(props.match.params.funnelId)
      .then((result) => {
        setIsChecking(false);
        if (result.success) {
          if (result.data && result.data.access_token) {
            setAccessToken(result.data.access_token);

            const refresh_token = getRefreshToken();
            const cookieData = { ...result.data, refresh_token };

            const canvasId = props.match.params.funnelId;

            localStorage.setItem(`tutorial-auth-${canvasId}`, JSON.stringify(cookieData));

            setIsAuthorized(true);
          }
        } else {
          setIsAuthorized(false);
        }
      })
      .catch((error) => {
        setIsAuthorized(false);
      });
  };

  if (!isLinkValid) {
    return <NotFound message="Invalid link" />;
  } else if (!props.match.params.funnelId) {
    return ReactDOM.createPortal(
      <TutorialPopup onCanvasSelection={onCanvasSelection} />,
      window.document.body,
    );
  } else if (isChecking) {
    return null;
  } else if (accessToken === false) {
    return <NoAccess />;
  } else if (isShared) {
    if (isAuthorized) {
      return <Funnel {...props} accessToken={accessToken} isTutorial={true} />;
    } else {
      return <Frobidden message="Can't access this canvas" />;
    }
  } else {
    return <NotFound />;
  }
};
