import Utils from 'pixi-project/utils/Utils';
import * as PIXI from 'pixi.js';

export default class TableViewScrollBar extends PIXI.Container {
  constructor(scrollView, style) {
    super();

    this.scrollView = scrollView;
    this.barStyle = style;

    this.content = new PIXI.Graphics();
    this.addChild(this.content);

    this.aspect = 0;

    this.refreshSize();
    this.updatePosition();
  }

  refreshSize() {
    this.viewWidth = this.scrollView.tableSize.width;
    this.viewHeight = this.scrollView.rowSize.height * this.scrollView.pageSize;

    const contentSize = this.scrollView.getContentSize();

    this.contentWidth = contentSize.width;
    this.contentHeight = contentSize.height;

    this.aspect = (this.viewHeight / this.contentHeight) * this.scrollView.scale.y;
    let height = this.viewHeight * this.aspect - this.barStyle.endPadding * 2;
    height = Utils.clamp(height, 10, this.viewHeight - this.barStyle.endPadding * 2);

    this.barHeight = height;

    this.content.clear();
    this.content.lineStyle(0);
    this.content.beginFill(0x000000, this.barStyle.alpha);
    this.content.drawRoundedRect(0, 0, this.barStyle.width, this.barHeight, this.barStyle.radius);
    this.content.endFill();

    this.diff = this.viewWidth - this.contentWidth;
  }

  updatePosition() {
    let y = Utils.map(
      this.scrollView.offsetY,
      0,
      this.contentHeight * this.scrollView.scale.y - this.viewHeight,
      this.barStyle.endPadding,
      this.viewHeight - this.barHeight - this.barStyle.endPadding,
    );

    this.content.x = -this.barStyle.width;
    this.content.y = y;
  }
}
