import * as PIXI from 'pixi.js';

export default class Button extends PIXI.Container {
  constructor(imageName, hoverImageName, onClick) {
    super();
    this.hoverImageName = hoverImageName;
    this.imageName = imageName;
    this.isMouseDown = false;
    this.disabled = false;
    this.onClick = onClick;

    this.content = this.createButtonContent();
    this.addChild(this.content);
    this.attach();
  }

  setDisabled(status) {
    this.disabled = status;
  }

  setTextures(imageName, hoverImageName) {
    this.imageName = imageName;
    this.hoverImageName = hoverImageName;

    this.content.setTexture(this.imageName);
  }

  createButtonContent() {
    const texture = PIXI.utils.TextureCache[this.imageName];
    const button = new PIXI.Sprite(texture);
    button.anchor.set(1, 0);
    button.scale.set(0.5);
    button.interactive = true;
    button.buttonMode = true;
    button.visible = true;
    return button;
  }

  onPointerOver() {
    this.content.setTexture(this.hoverImageName);
    window.app.needsRendering();
  }

  onPointerOut() {
    this.content.setTexture(this.imageName);
    window.app.needsRendering();
  }

  onPointerDown(e) {
    e.stopPropagation();
    if (!this.disabled) {
      this.isMouseDown = true;
    }
  }

  onButtonClicked() {
    if (this.onClick) {
      this.onClick();
    }
  }

  onPointerUp(e) {
    e.stopPropagation();
    if (!this.disabled) {
      this.isMouseDown = false;
      this.onButtonClicked();
    }
  }

  onPointerUpOutside() {
    this.isMouseDown = false;
  }

  attach() {
    if (this.hoverImageName) {
      this.content.on('pointerover', this.onPointerOver.bind(this));
    }
    this.content.on('pointerout', this.onPointerOut.bind(this));
    this.content.on('pointerdown', this.onPointerDown.bind(this));
    this.content.on('pointerup', this.onPointerUp.bind(this));
    this.content.on('pointerupoutside', this.onPointerUpOutside.bind(this));
  }
}
