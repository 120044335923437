import { FILTER_TYPE_COMPARE } from 'shared/CSharedConstants';

export const selectStepOpened = (state, type) => {
  if (type === FILTER_TYPE_COMPARE) {
    return state.focusedStep.isFocusCompareStepOpened;
  } else {
    return state.focusedStep.isFocusStepOpened;
  }
};

export const selectFocusedSteps = (state) => state.focusedStep.focusedSteps;
export const selectFocusedCompareSteps = (state) => state.focusedStep.focusedCompareSteps;
