import LineDrawHelper from 'pixi-project/utils/LineDrawHelper';
import * as PIXI from 'pixi.js';
import Styles from '../Styles';
import Utils from 'pixi-project/utils/Utils';
import { nFormatter } from 'shared/CSharedMethods';
import { ALL_TRACKED_DATA_COLOR, DIVISION_MODE_DAILY } from './TrendsWidget';

const LEFT_PADDING = 60;
const RIGHT_PADDING = 10;
const BOTTOM_LABELS_SPACING = 30;
const LEFT_LABELS_SPACING = 40;
const TOP_PADDING = 20;
const GRID_LINES_COLOR = 0xd9d9d9;
const MIN_LINES = 3; // min number of horizontal lines on the grid
const MAX_LINES = 7; // max number of horizontal lines on the grid

const DATA_LINES_ALPHA = 0.9;
const DATA_LINES_THICKNESS = 1;

const MAX_DAILY_LABELS = 20; // show 20 labels in daily mode
const MAX_DATE_LABELS = 9; // show 9 labels in other modes

export default class TrendsChart extends PIXI.Graphics {
  constructor(size) {
    super();

    this.data = null;
    this.compareData = null;
    this.allTracked = null;
    this.allTrackedCompare = null;

    this.labels = [];
    this.size = size;

    this.viewMode = null;

    this.tempPointA = new PIXI.Point();
    this.tempPointB = new PIXI.Point();
  }

  setMode(mode) {
    this.viewMode = mode;
  }

  setChartData(data, compareData, isAllTrackedVisible) {
    this.data = data;
    this.compareData = compareData;
    this.isAllTrackedVisible = isAllTrackedVisible;
  }

  cleanUp() {
    this.data = null;
    this.compareData = null;
    this.isAllTrackedVisible = false;

    // clear previous drawings
    this.clear();
    this.clearLabels();
  }

  drawChart() {
    const { min, max } = this.findMinMaxData();

    const wholeNumbers = this.findPreferedNumbersBetween(min, max, MIN_LINES, MAX_LINES);

    const xPositions = this.drawVerticalLines();

    // Add labels
    this.drawVerticalLineLabels(this.data.dates, xPositions);

    if (this.compareData) {
      this.drawVerticalLineLabels(this.compareData.dates, xPositions, 0x4f48d4, 15, 10);
    }

    this.drawHorizontalLines(wholeNumbers);

    this.drawDataLines(this.data.objectsArray, wholeNumbers, xPositions);

    if (this.compareData) {
      this.drawDataLines(this.compareData.objectsArray, wholeNumbers, xPositions, true);
    }

    return;
  }

  drawVerticalLineLabels(dates, xPositions, color = 0x636e84, yOffset = 3, fontSize = 13) {
    // limit the number of labels shown
    const step =
      this.viewMode === DIVISION_MODE_DAILY
        ? Math.floor(dates.length / (MAX_DAILY_LABELS + 1)) + 1 // show 15 labels in daily mode
        : Math.floor(dates.length / (MAX_DATE_LABELS + 1)) + 1;

    for (let i = 0; i < dates.length; i += step) {
      const key = dates[i];
      const label = new PIXI.BitmapText(key, Styles.WIDGET_LABEL);
      label.fontSize = fontSize;
      label.tint = color;
      label.anchor.set(0.5, 0);
      label.position.x = xPositions[i];
      label.position.y = this.size.height - BOTTOM_LABELS_SPACING;
      label.y += yOffset;
      this.labels.push(label);
      this.addChild(label);
    }
  }

  drawVerticalLines() {
    this.lineStyle(1, GRID_LINES_COLOR, 1);
    const xPositions = [];

    const count = Math.max(
      this.data.dates.length,
      this.compareData ? this.compareData.dates.length : 0,
    );

    const spacing = (this.size.width - LEFT_LABELS_SPACING - LEFT_PADDING - RIGHT_PADDING) / count;

    const startX = LEFT_LABELS_SPACING + LEFT_PADDING;

    for (let i = 0; i < count; i++) {
      this.tempPointA.set(startX + i * spacing, 0);
      this.tempPointB.set(startX + i * spacing, this.size.height - BOTTOM_LABELS_SPACING);
      LineDrawHelper.drawDashedLine(this, this.tempPointA, this.tempPointB, 2, 2);
      xPositions.push(this.tempPointA.x);
    }

    return xPositions;
  }

  drawHorizontalLines(wholeNumbers) {
    const spacing =
      (this.size.height - TOP_PADDING - BOTTOM_LABELS_SPACING) / (wholeNumbers.length - 1);

    this.lineStyle(1, GRID_LINES_COLOR, 1);

    for (let i = 0; i < wholeNumbers.length; i++) {
      const value = wholeNumbers[i];
      const y = this.size.height - BOTTOM_LABELS_SPACING - spacing * i;
      this.tempPointA.set(LEFT_LABELS_SPACING, y);
      this.tempPointB.set(this.size.width - RIGHT_PADDING, y);
      LineDrawHelper.drawLine(this, this.tempPointA, this.tempPointB);

      const label = new PIXI.BitmapText(nFormatter(value), Styles.WIDGET_LABEL);
      label.tint = 0x636e84;
      label.anchor.set(1, 0.5);
      label.position.copyFrom(this.tempPointA);
      label.x -= 3;
      this.labels.push(label);
      this.addChild(label);
    }
  }

  drawDataLines(objects, wholeNumbers, xPositions, isDashed = false) {
    for (let i = 0; i < objects.length; i++) {
      const object = objects[i];
      if (object.id === 'allSessions' && !this.isAllTrackedVisible) {
        continue;
      }

      this.lineStyle(DATA_LINES_THICKNESS, object.color, DATA_LINES_ALPHA);

      for (let j = 0; j < object.hits.length - 1; j++) {
        const hits1 = object.hits[j];
        const hits2 = object.hits[j + 1];
        this.drawSingleDataLine(hits1, hits2, wholeNumbers, xPositions, j, isDashed);
      }
    }
  }

  drawAllTrackedData(allTrackedData, wholeNumbers, xPositions, isDashed = false) {
    const values = Object.values(allTrackedData);
    for (let i = 0; i < values.length - 1; i++) {
      const value1 = values[i];
      const value2 = values[i + 1];
      this.lineStyle(DATA_LINES_THICKNESS, ALL_TRACKED_DATA_COLOR, DATA_LINES_ALPHA);
      this.drawSingleDataLine(value1, value2, wholeNumbers, xPositions, i, isDashed);
    }
  }

  drawSingleDataLine(hits1, hits2, wholeNumbers, xPositions, index, isDashed = false) {
    const plotMaxY = this.size.height - BOTTOM_LABELS_SPACING;
    const minWhole = wholeNumbers[0];
    const maxWhole = wholeNumbers[wholeNumbers.length - 1];
    const y1 = Utils.map(hits1, maxWhole, minWhole, TOP_PADDING, plotMaxY);
    const y2 = Utils.map(hits2, maxWhole, minWhole, TOP_PADDING, plotMaxY);
    const x1 = xPositions[index];
    const x2 = xPositions[index + 1];

    if (isDashed) {
      LineDrawHelper.drawDashedLine(this, new PIXI.Point(x1, y1), new PIXI.Point(x2, y2));
    } else {
      this.moveTo(x1, y1);
      this.lineTo(x2, y2);
    }
  }

  /**
   * find N whole numbers between integers min and max
   * there need to be between minN and maxN numbers inbetween min and max
   */
  findPreferedNumbersBetween(min, max, minN, maxN) {
    if (min === 0 && max === 0) {
      return [0, 100, 200, 300]; // retrun a default value in edge cases
    }

    const prefered = [];
    let spacing = 0;

    let a = 0;
    const digitsA = this.countDigits(min);
    if (digitsA > 2) {
      a = 1 * Math.pow(10, digitsA - 1);
    }
    const b = this.findNextPreferedNumber(max);

    const domain = b - a;

    const candidates = [];

    for (let i = maxN; i >= minN; i--) {
      let spacing = domain / i;
      let small = this.findWholeNumber(spacing);
      let big = this.findNextPreferedNumber(spacing);
      const smallDiff = Math.abs(small - spacing);
      const bigDiff = Math.abs(big - spacing);
      candidates.push({
        spacing,
        small,
        big,
        i,
        smallDiff,
        bigDiff,
        diff: Math.min(smallDiff, bigDiff),
      });
    }

    let best = candidates[0];

    for (let i = 0; i < candidates.length; i++) {
      const candidate = candidates[i];
      if (candidate.diff < best.diff) {
        best = candidate;
      }
    }

    spacing = best.bigDiff < best.smallDiff ? best.big : best.small;

    for (let sum = a; sum < max; sum += spacing) {
      prefered.push(sum);
    }

    // also add one more to make sure its not cut off
    prefered.push(prefered[prefered.length - 1] + spacing);

    return prefered;
  }

  findNextPreferedNumber(x) {
    if (this.isPreferedNumber(x)) {
      return x;
    }

    let digits = this.countDigits(x);
    const firstDigit = Math.floor(x / Math.pow(10, digits - 1));
    let preferedFirstDigit = firstDigit;

    const wholeNumber = firstDigit * Math.pow(10, digits - 1);
    const fifty = 5 * Math.pow(10, digits - 2);

    if (x > wholeNumber) {
      preferedFirstDigit++;
    }

    let preferedNumber = preferedFirstDigit * Math.pow(10, digits - 1);

    if (wholeNumber + fifty >= x) {
      preferedNumber = wholeNumber + fifty;
    }

    return preferedNumber;
  }

  isPreferedNumber(x) {
    if (x === 0) {
      return true;
    }
    let digits = this.countDigits(x);
    const firstDigit = Math.floor(x / Math.pow(10, digits - 1));
    // Do math for 0.5
    const fifty = 5 * Math.pow(10, digits - 2);
    const wholeNumber = firstDigit * Math.pow(10, digits - 1);
    const isWhole = x === wholeNumber || x === wholeNumber + fifty;
    return isWhole;
  }

  findWholeNumber(x) {
    if (x === 0) {
      return 0;
    }
    let digits = this.countDigits(x);
    const firstDigit = Math.floor(x / Math.pow(10, digits - 1));
    const wholeNumber = firstDigit * Math.pow(10, digits - 1);
    return wholeNumber;
  }

  countDigits(x) {
    if (x === 0) {
      return 1;
    }
    return Math.floor(Math.log10(x) + 1);
  }

  findMinMaxData() {
    const { min, max } = this.findMinMaxInData(this.data.objectsArray);

    if (this.compareData) {
      const { min: minCompare, max: maxCompare } = this.findMinMaxInData(
        this.compareData.objectsArray,
      );
      return { min: Math.min(min, minCompare), max: Math.max(max, maxCompare) };
    }

    if (min > max || (min <= 0 && max <= 0)) {
      // in the case of no data
      return { min: 0, max: 1000 };
    }

    return { min, max };
  }

  findMinMaxInData(objectsArray) {
    let min = Number.MAX_SAFE_INTEGER;
    let max = 0;

    objectsArray.forEach((object) => {
      if (object.id === 'allSessions' && !this.isAllTrackedVisible) {
        return;
      }

      for (let i = 0; i < object.hits.length; i++) {
        const hits = object.hits[i];
        if (hits < min) {
          min = hits;
        }
        if (hits > max) {
          max = hits;
        }
      }
    });

    return { min, max };
  }

  clearLabels() {
    for (let i = 0; i < this.labels.length; i++) {
      const label = this.labels[i];
      label.removeFromParent();
    }
    this.labels = [];
  }
}
