export default class CommandAddStepToTrends {
  constructor(widget, data) {
    this.widget = widget;
    this.data = data;

    // formatting fix
    this.data.id = data.ID;

    this.isExecuted = false;
  }

  execute() {
    if (!this.isExecuted) {
      this.isExecuted = true;

      if (this.widget.addStep(this.data)) {
        this.widget.onStepsChanged();
        this.widget.hasNewSteps = true;
      }

      window.app.needsRendering();
    }
  }

  revert() {
    if (this.isExecuted) {
      window.app.needsRendering();

      this.widget.removeStep(this.data);
      this.widget.onStepsChanged();
    }
  }
}
