import RequestService from 'react-project/Helpers/RequestService';
import { PR_EVENT_ON_PHOTO_CHANGED, RP_EVENT_ERROR_MESSAGE } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { v4 as uuidv4 } from 'uuid';
import { FunnelProperties } from './FunnelProperties';

const requestService = new RequestService();

export const verifyPhoto = (file, onFileUploadFailed) => {
  if (file.size > 1000 * 1000 * 5) {
    const error = 'File is too large, maximum size allowed is 5MB';
    commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
      errorMSG: error,
    });
    onFileUploadFailed(error);
    return false;
  }

  if (file && file.type.split('/')[0] !== 'image') {
    const error = 'File is not an image';
    commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
      errorMSG: error,
    });
    onFileUploadFailed(error);
    return false;
  }

  return true;
};

export const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const uploadPhotoToS3 = (file, callback, path) => {
  const id = uuidv4();
  requestService.uploadCustomIconImage(id, file, path).then(callback);
};

export const addPhotoToLibrary = (fileName, imageURL, fileID, uploadType, onFileUploaded) => {
  requestService.addIconToList(fileID, fileName, imageURL, uploadType).then((result) => {
    if (onFileUploaded) {
      onFileUploaded(fileID, fileName, result.data.data.attributes.path, uploadType);
    }
  });
};

export const onPhotoUploadClicked = (currentStep) => {
  const inputField = document.createElement('input');
  inputField.type = 'file';
  inputField.onchange = (e) => {
    const file = e.target.files[0];
    commonSendEventFunction(PR_EVENT_ON_PHOTO_CHANGED, {
      file,
      currentStep,
    });
  };
  inputField.click();
};
