import cx from 'classnames';
import React, { useRef } from 'react';
import { getDepthClassNames } from 'react-project/FacebookIntegration/helpers';
import { Checkbox } from '../checkbox';
import { ChevronToggle } from '../chevronToggle';
import { InfoIcon } from '../infoIcon';
import { SmallText } from '../smallText';
import { StatusIndicator } from '../statusIndicator';

function List({ children, onClick }) {
  return (
    <div onClick={onClick} className="mx-1 flex items-start">
      {children}
    </div>
  );
}
function ListItem({ children, hide, className }) {
  return (
    <div className={cx(className, hide ? 'hidden' : '', 'flex items-start justify-left px-1')}>
      {children}
    </div>
  );
}
ListItem.defaultProps = {
  hide: false,
};

function Warning() {
  return (
    <div className="mt-1 mr-1 h-4 w-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
      >
        <path
          d="M0 12H14L7 0L0 12ZM7.63636 10.1053H6.36364V8.8421H7.63636V10.1053ZM7.63636 7.57895H6.36364V5.05263H7.63636V7.57895Z"
          fill="#FFD53E"
        />
      </svg>
    </div>
  );
}

function UncheckOnly({ onClick }) {
  return (
    <button type="button" onClick={onClick} className="mt-1 mr-1 h-4 w-4">
      <svg
        className="h-[13px] w-[13px]"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 13 13"
        fill="none"
      >
        <path
          d="M6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12C2.6862 12 0 9.3138 0 6C0 2.6862 2.6862 0 6 0ZM3.6 6.6H8.4V5.4H3.6V6.6Z"
          fill="#6D778B"
        />
      </svg>
    </button>
  );
}

function TierCheckboxControls({ data, options, className, handleCheck }) {
  const checkboxOption = options?.selectedView ? (
    <div className={cx('opacity-0 pt-[2px]', options?.isHover ? 'opacity-100' : '')}>
      <UncheckOnly onClick={handleCheck} />
    </div>
  ) : (
    <Checkbox selection={data.checked} onChange={handleCheck} disabled={options?.preventUse} />
  );
  return <div className={className}> {options?.isWarning ? <Warning /> : checkboxOption} </div>;
}

export function TierTreeRow({ data, children, handleToggle, handleCheck, options }) {
  const expandDisabled = data.childCount <= 0;
  const baseRef = options?.ref ? options.ref : useRef(null);
  const formattedPrice =
    options?.selectedView && options?.altPrice ? options.altPrice : data.totalSpend?.formattedPrice;
  return (
    <div className={cx('py-1', options?.doNotDisplay ? 'hidden' : '')}>
      <div
        className={cx(
          'flex items-start',
          options?.selectedView && options?.isHover ? 'bg-sky-100' : '',
        )}
        ref={baseRef}
      >
        <div className="w-2/3 pr-4">
          <button
            type="button"
            className={cx(
              'block w-full',
              getDepthClassNames(['', 'pl-[1.5rem]', 'pl-[4rem]'])(data.depth),
            )}
            onClick={handleToggle}
            disabled={expandDisabled}
          >
            <List>
              {data.expanded !== null ? (
                <ListItem hide={options?.isAd} className="pt-2">
                  <ChevronToggle open={data.expanded} disabled={expandDisabled} />
                </ListItem>
              ) : null}
              <ListItem className="pt-2">
                <StatusIndicator status={data.status} />
              </ListItem>
              <ListItem>
                <SmallText className={'text-slate-500 pt-1 break-words break-all'}>
                  {data.title}
                </SmallText>
              </ListItem>
            </List>
          </button>
        </div>
        <div className="flex w-1/3 items-start justify-end ">
          <div className="w-full px-2 truncate min-w-[82px]">
            {data.totalSpend?.formattedPrice ? (
              <SmallText className={'text-slate-500 text-xs pt-1'}>{formattedPrice}</SmallText>
            ) : (
              <div className="min-w-[40px] text-xs text-slate-500 pt-1"> Not Available </div>
            )}
          </div>
          <div className="pl-4 mt-[2px] min-w-[34px]">
            {options?.showInfo ? (
              <InfoIcon
                visible={true}
                onClick={() => {
                  options.onInfoClick(data);
                }}
                title="UTM Parameters"
              />
            ) : (
              <div className="min-w-9"> </div>
            )}
          </div>
          <div className={cx('pr-2', options?.preventUse ? 'cursor-not-allowed' : '')}>
            <TierCheckboxControls options={options} data={data} handleCheck={handleCheck} />
          </div>
        </div>
      </div>
      {children ? <div data-test-id="childrenRender">{children}</div> : null}
    </div>
  );
}
TierTreeRow.defaultProps = {
  children: null,
  handleToggle: undefined,
  options: undefined,
};

export default TierTreeRow;
