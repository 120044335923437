import { iconMiddleMouse } from 'react-project/assets/Icons';
import { CANVAS_ACTION_NAMES } from '../../shared/canvasActionNames';

export const DefaultHotkeys = {
  [CANVAS_ACTION_NAMES.DUPLICATE]: { keys: ['ctrl', 'd'], separator: '+' },
  [CANVAS_ACTION_NAMES.COPY]: { keys: ['ctrl', 'c'], separator: '+' },
  [CANVAS_ACTION_NAMES.PASTE]: { keys: ['ctrl', 'v'], separator: '+' },
  [CANVAS_ACTION_NAMES.RESET_ZOOM]: { keys: ['ctrl', '0'], separator: '+' },
  [CANVAS_ACTION_NAMES.ZOOM_IN]: { keys: ['ctrl', '+'], separator: '+' },
  [CANVAS_ACTION_NAMES.ZOOM_OUT]: { keys: ['ctrl', '-'], separator: '+' },
  [CANVAS_ACTION_NAMES.FIT_TO_SCREEN]: { keys: ['ctrl', '1'], separator: '+' },
  [CANVAS_ACTION_NAMES.DELETE]: { keys: ['del', 'backspace'], separator: 'or' },
  [CANVAS_ACTION_NAMES.UNDO]: { keys: ['ctrl', 'z'], separator: '+' },
  [CANVAS_ACTION_NAMES.REDO]: { keys: ['ctrl', 'y'], separator: '+' },
  [CANVAS_ACTION_NAMES.PAN]: {
    keys: [{ value: iconMiddleMouse, background: 'KeyBackgroundTransparent' }, 'space'],
    separator: 'or',
  },
  [CANVAS_ACTION_NAMES.BRING_FORWARD]: { keys: ['ctrl', ']'], separator: '+' },
  [CANVAS_ACTION_NAMES.BRING_TO_FRONT]: { keys: ['ctrl', 'shift', ']'], separator: '+' },
  [CANVAS_ACTION_NAMES.SEND_BACKWARD]: { keys: ['ctrl', '['], separator: '+' },
  [CANVAS_ACTION_NAMES.SEND_TO_BACK]: { keys: ['ctrl', 'shift', '['], separator: '+' },
  [CANVAS_ACTION_NAMES.SELECT]: { keys: ['s'] },
  [CANVAS_ACTION_NAMES.ADD_TEXT]: { keys: ['t'] },
  [CANVAS_ACTION_NAMES.DRAW_RECTANGLE]: { keys: ['r'] },
  [CANVAS_ACTION_NAMES.DRAW_CIRCLE]: { keys: ['o'] },
  [CANVAS_ACTION_NAMES.DRAW_TRIANGLE]: { keys: ['p'] },
  [CANVAS_ACTION_NAMES.CHECKLIST_ADD_SIBLING]: { keys: ['Enter'] },
  [CANVAS_ACTION_NAMES.CHECKLIST_ADD_CHILD]: { keys: ['shift', 'Enter'], separator: '+' },
  [CANVAS_ACTION_NAMES.CHECKLIST_INDENT]: { keys: ['Tab'] },
  [CANVAS_ACTION_NAMES.CHECKLIST_OUTDENT]: { keys: ['shift', 'Tab'], separator: '+' },
  [CANVAS_ACTION_NAMES.CHECKLIST_MOVE_UP]: { keys: ['shift', 'PgUp'], separator: '+' },
  [CANVAS_ACTION_NAMES.CHECKLIST_MOVE_DOWN]: { keys: ['shift', 'PgDn'], separator: '+' },
  [CANVAS_ACTION_NAMES.CHECKLIST_DELETE]: { keys: ['shift', 'del'], separator: '+' },
};

export const MacHotkeys = {
  [CANVAS_ACTION_NAMES.DUPLICATE]: { keys: ['⌘', 'd'], separator: '+' },
  [CANVAS_ACTION_NAMES.COPY]: { keys: ['⌘', 'c'], separator: '+' },
  [CANVAS_ACTION_NAMES.PASTE]: { keys: ['⌘', 'v'], separator: '+' },
  [CANVAS_ACTION_NAMES.RESET_ZOOM]: { keys: ['⌘', '0'], separator: '+' },
  [CANVAS_ACTION_NAMES.ZOOM_IN]: { keys: ['⌘', '+'], separator: '+' },
  [CANVAS_ACTION_NAMES.ZOOM_OUT]: { keys: ['⌘', '-'], separator: '+' },
  [CANVAS_ACTION_NAMES.FIT_TO_SCREEN]: { keys: ['ctrl', '1'], separator: '+' },
  [CANVAS_ACTION_NAMES.DELETE]: { keys: ['del', 'backspace'], separator: 'or' },
  [CANVAS_ACTION_NAMES.UNDO]: { keys: ['⌘', 'z'], separator: '+' },
  [CANVAS_ACTION_NAMES.REDO]: { keys: ['⌘', 'shift', 'z'], separator: '+' },
  [CANVAS_ACTION_NAMES.PAN]: {
    keys: [{ value: iconMiddleMouse, background: 'KeyBackgroundTransparent' }, 'space'],
    separator: 'or',
  },
  [CANVAS_ACTION_NAMES.BRING_FORWARD]: { keys: ['⌘', ']'], separator: '+' },
  [CANVAS_ACTION_NAMES.BRING_TO_FRONT]: { keys: ['⌘', 'shift', ']'], separator: '+' },
  [CANVAS_ACTION_NAMES.SEND_BACKWARD]: { keys: ['⌘', '['], separator: '+' },
  [CANVAS_ACTION_NAMES.SEND_TO_BACK]: { keys: ['⌘', 'shift', '['], separator: '+' },
  [CANVAS_ACTION_NAMES.SELECT]: { keys: ['s'] },
  [CANVAS_ACTION_NAMES.ADD_TEXT]: { keys: ['t'] },
  [CANVAS_ACTION_NAMES.DRAW_RECTANGLE]: { keys: ['r'] },
  [CANVAS_ACTION_NAMES.DRAW_CIRCLE]: { keys: ['o'] },
  [CANVAS_ACTION_NAMES.DRAW_TRIANGLE]: { keys: ['p'] },
  [CANVAS_ACTION_NAMES.CHECKLIST_ADD_SIBLING]: { keys: ['Enter'] },
  [CANVAS_ACTION_NAMES.CHECKLIST_ADD_CHILD]: { keys: ['shift', 'Enter'], separator: '+' },
  [CANVAS_ACTION_NAMES.CHECKLIST_INDENT]: { keys: ['Tab'] },
  [CANVAS_ACTION_NAMES.CHECKLIST_OUTDENT]: { keys: ['shift', 'Tab'], separator: '+' },
  [CANVAS_ACTION_NAMES.CHECKLIST_MOVE_UP]: { keys: ['shift', 'PgUp'], separator: '+' },
  [CANVAS_ACTION_NAMES.CHECKLIST_MOVE_DOWN]: { keys: ['shift', 'PgDn'], separator: '+' },
  [CANVAS_ACTION_NAMES.CHECKLIST_DELETE]: { keys: ['shift', 'del'], separator: '+' },
};
