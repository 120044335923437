import RequestService from 'react-project/Helpers/RequestService';
import MainStorage from 'pixi-project/core/MainStorage';

const requestService = new RequestService();

export const sendHeapTracking = (heapData) => {
  const { projectId, eventName, eventData, funnelId } = heapData;

  return new Promise((res, rej) => {
    if (
      MainStorage.getCanvasPermissions().isAdminImpersonation ||
      MainStorage.getCanvasPermissions().isReadonlyAccess ||
      MainStorage.getCanvasPermissions().isTutorial
    ) {
      return res();
    }
    requestService
      .sendTrackDataToHeap({
        projectId,
        eventName,
        eventData,
        funnelId,
      })
      .then((response) => res(response))
      .catch((error) => rej(error));
  });
};
