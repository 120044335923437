import React, { useState } from 'react';
import { iconEnter, iconSearchPopup } from 'react-project/assets/Icons';
import styles from './SearchInput.module.scss';
import cls from 'classnames';

const DEFAULT_PLACEHOLDER = 'Search';

export const SearchInput = ({
  onChange,
  className,
  onEnterClicked,
  onPaste,
  icon,
  inputMaxLength,
  placeholder,
  value,
  cleanOnEnter,
}) => {
  const [searchValue, setSearchValue] = useState(value || '');
  const [isPasted, setIsPasted] = useState(false);

  const onHandleSearch = (e) => {
    setSearchValue(e.target.value);

    if (onChange) {
      onChange(e.target.value);
    }

    if (isPasted) {
      const pastedSearchValue = e.target.value;
      setTimeout(() => {
        onPaste(pastedSearchValue);
        if (cleanOnEnter) {
          setSearchValue('');
        }
      }, 100);
    }

    setIsPasted(false);
  };

  const onHandleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onEnterClicked(searchValue);
      if (cleanOnEnter) {
        setSearchValue('');
      }
    }
  };

  const onEnterBtnClicked = () => {
    onEnterClicked(searchValue);
    if (cleanOnEnter) {
      setSearchValue('');
    }
  };

  const onInputPaste = (e) => {
    setIsPasted(true);
  };

  return (
    <div className={cls(styles.SearchWrapper, className)}>
      <div className={styles.Icon}>{icon}</div>
      <input
        autoFocus={true}
        onKeyDown={onHandleKeyDown}
        placeholder={placeholder || DEFAULT_PLACEHOLDER}
        maxLength={inputMaxLength}
        value={searchValue}
        type="text"
        onChange={onHandleSearch}
        onPaste={onInputPaste}
        onPointerDown={(e) => {
          e.stopPropagation();
        }}
      />
      <button className={styles.EnterButton} onClick={onEnterBtnClicked}>
        enter <div className={styles.EnterButtonIcon}>{iconEnter}</div>
      </button>
    </div>
  );
};

SearchInput.defaultProps = {
  onEnterClicked: () => {},
  onPaste: () => {},
  icon: iconSearchPopup,
  inputMaxLength: 2000,
  placeholder: 'Search',
  cleanOnEnter: false,
};
