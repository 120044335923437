export default class CommandRemoveFromReportView {
  constructor(objects, reportView) {
    this.objects = objects.slice();
    this.reportView = reportView;

    this.isExecuted = false;
  }

  execute() {
    if (!this.isExecuted) {
      this.reportView.removeObjects(this.objects);
      this.isExecuted = true;
    }
  }

  revert() {
    if (this.isExecuted) {
      this.reportView.removeObjects(this.objects);
      this.reportView.addObjects(this.objects);
      this.isExecuted = false;
    }
  }
}
