import * as PIXI from 'pixi.js';
import Styles, {
  BOX_SEPARATOR_THICKHNESS,
  BOX_SEPARATOR_TRIANGLE,
  COLOR_COMPARE_PERCENT_NEUTRAL,
  COLOR_DISPLAY_SEPARATOR,
  COLOR_FORECASTING_NEGATIVE,
  COLOR_FORECASTING_NEUTRAL,
  COLOR_FORECASTING_POSITIVE,
  CONNECTION_BOX_PADDING,
  CONNECTION_BOX_PADDING_HORIZONTAL,
  SELECTION_ROUND_CORNER,
} from 'pixi-project/view/Styles';
import TextDisplay from './TextDisplay';
import { ANALYTICS_DATA_DEFAULT_PLACEHOLDER } from 'shared/CSharedConstants';
import { COMPARE_TEXTURE_SCALE } from 'pixi-project/core/BaseAnalyticsManager';
import Utils from 'pixi-project/utils/Utils';

const TRIANGLE_OFFSET_Y = -8;

export default class ConnectionForecastingCompareSegment extends PIXI.Container {
  constructor() {
    super();
    this.graphics = new PIXI.Graphics();
    this.addChild(this.graphics);

    this.rateDisplay = new TextDisplay();
    this.addChild(this.rateDisplay);

    this.peopleDisplay = new TextDisplay();
    this.addChild(this.peopleDisplay);

    this.displayData = null;
    this.compareRange = { from: 0, to: 0 };
  }

  setData(data) {
    this.displayData = data;

    this.bindDisplayData(this.rateDisplay, data[0], 'right');
    this.bindDisplayData(this.peopleDisplay, data[1], 'left');
    this.layoutSegments();
  }

  onRangeUpdated() {
    if (this.displayData) {
      this.setData(this.displayData);
    }

    window.app.needsRendering();
  }

  bindDisplayData(display, rateData, sharpen) {
    display.setData([
      { value: rateData[0], ...Styles.FOOTER_TITLE },
      { value: rateData[1], ...Styles.FOOTER_NUMBER },
      { value: rateData[2], ...Styles.FORECASTING_NUMBER, spacing: 5 },
      {
        children: [
          {
            texture: rateData[3][0],
            scale: COMPARE_TEXTURE_SCALE,
            visible: rateData[3][2] !== '0',
          },
          { value: rateData[3][1], ...Styles.FOOTER_PERCENT },
        ],
        width: 0,
        backgroundHorizontalPadding: CONNECTION_BOX_PADDING_HORIZONTAL,
        background: this.getColor(Number(rateData[3][2])),
        round: SELECTION_ROUND_CORNER,
        sharpen,
      },
    ]);
  }

  getColor(n) {
    if (!n) {
      return COLOR_FORECASTING_NEUTRAL;
    }

    if (Utils.inRange(n, this.compareRange.from, this.compareRange.to)) {
      return COLOR_COMPARE_PERCENT_NEUTRAL;
    }

    return n < this.compareRange.to ? COLOR_FORECASTING_NEGATIVE : COLOR_FORECASTING_POSITIVE;
  }

  layoutSegments() {
    const rateBounds = this.rateDisplay.areaSize;
    const hitBounds = this.peopleDisplay.areaSize;

    const midY = 0;
    const totalWidth =
      rateBounds.width +
      hitBounds.width +
      CONNECTION_BOX_PADDING_HORIZONTAL * 2 +
      BOX_SEPARATOR_THICKHNESS;
    const totalHeight = Math.max(
      this.rateDisplay.areaSize.height,
      this.peopleDisplay.areaSize.height,
    );
    const rateMidX = -totalWidth / 2 + rateBounds.width / 2;
    const peopleMidX =
      rateMidX +
      rateBounds.width / 2 +
      CONNECTION_BOX_PADDING_HORIZONTAL * 2 +
      BOX_SEPARATOR_THICKHNESS +
      hitBounds.width / 2;
    const separatorX =
      -totalWidth / 2 +
      rateBounds.width +
      CONNECTION_BOX_PADDING_HORIZONTAL +
      BOX_SEPARATOR_THICKHNESS / 2;

    this.rateDisplay.x = rateMidX;
    this.rateDisplay.y = -rateBounds.height / 2;

    this.peopleDisplay.x = peopleMidX;
    this.peopleDisplay.y = -hitBounds.height / 2;

    this.graphics.clear();
    this.graphics.lineStyle(BOX_SEPARATOR_THICKHNESS, COLOR_DISPLAY_SEPARATOR, 1);
    this.graphics.moveTo(separatorX, -totalHeight / 2 - CONNECTION_BOX_PADDING);
    this.graphics.lineTo(separatorX, totalHeight / 2 - 1);
    this.graphics.closePath();

    this.drawTriangle(
      separatorX,
      midY + TRIANGLE_OFFSET_Y,
      COLOR_DISPLAY_SEPARATOR,
      BOX_SEPARATOR_TRIANGLE,
    );
  }

  drawTriangle(x, midY, color, triangleSize) {
    this.graphics.lineStyle(0);
    this.graphics.beginFill(color, 1);
    this.graphics.moveTo(x, midY - triangleSize);
    this.graphics.lineTo(x + triangleSize, midY);
    this.graphics.lineTo(x, midY + triangleSize);
    this.graphics.closePath();
    this.graphics.endFill();
  }

  resetData() {
    this.displayData = null;
    this.rateDisplay.setData([ANALYTICS_DATA_DEFAULT_PLACEHOLDER]);
  }

  getArea() {
    return {
      width:
        this.rateDisplay.areaSize.width +
        this.peopleDisplay.areaSize.width +
        BOX_SEPARATOR_THICKHNESS +
        CONNECTION_BOX_PADDING_HORIZONTAL * 2,
      height: Math.max(this.rateDisplay.areaSize.height, this.peopleDisplay.areaSize.height),
    };
  }
}
