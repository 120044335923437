import { createSlice } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { map } from 'ramda';
import {
  createAdsAndAdsets,
  createCampaigns,
  selectedAdsToIdCollection,
  toggleFieldById,
  updateCheckboxes,
  updateDisplayAttribute,
  updateSpendByItem,
} from 'react-project/features/facebookIntegration/helpers';
import RequestService from 'react-project/Helpers/RequestService';
import { FB_AD_MODAL_MODE_DEFAULT } from 'shared/CSharedConstants';

const requestService = new RequestService();
const initialState = {
  stepIds: {},
  canSetIntegrationAttributes: false,
  adsPopup: [],
  popUpBounds: null,
  popUpOffsetCount: 0,
  currentDateRange: {},
};
const initialCollectionByStepId = {
  stepId: undefined,
  campaigns: [],
  adSets: [],
  ads: [],
  selectedAdPrices: {},
  isLoading: false,
  isBlockingInteraction: false,
  collectionDisplayMode: FB_AD_MODAL_MODE_DEFAULT,
  filteredCampaignIds: [],
  filteredAdSetIds: [],
  filteredAdIds: [],
};

export const facebookIntegrationSlice = createSlice({
  name: 'facebookIntegration',
  initialState,
  reducers: {
    /** Base Facebook Ad actions*/
    setCheckboxes: (prevState, action) => {
      const { id, stepId } = action.payload;
      // Update Checkboxes
      const { ads, adSets, campaigns, adsChecked, adsUnchecked } = updateCheckboxes(
        prevState.stepIds[stepId],
        id,
      );
      // Update selection side Total Spend
      let mappedPrevState = JSON.parse(JSON.stringify(prevState.stepIds[stepId].selectedAdPrices));
      adsUnchecked.forEach((item) => updateSpendByItem(item, mappedPrevState, 'deduct'));
      adsChecked.forEach((item) => updateSpendByItem(item, mappedPrevState, 'add'));
      let newState = {
        ...prevState,
        stepIds: {
          ...prevState.stepIds,
        },
      };
      newState.stepIds[stepId] = {
        ...prevState.stepIds[stepId],
        campaigns,
        adSets,
        ads,
        selectedAdPrices: mappedPrevState,
      };
      return newState;
    },
    toggleCampaignById: (prevState, action) => {
      const { id, stepId } = action.payload;
      let newState = {
        ...prevState,
        stepIds: {
          ...prevState.stepIds,
        },
      };
      newState.stepIds[stepId] = {
        ...prevState.stepIds[stepId],
        campaigns: map(toggleFieldById('expanded', id), prevState.stepIds[stepId].campaigns),
      };
      return newState;
    },
    toggleAdSetById: (prevState, action) => {
      const { id, stepId } = action.payload;
      let newState = {
        ...prevState,
        stepIds: {
          ...prevState.stepIds,
        },
      };
      newState.stepIds[stepId] = {
        ...prevState.stepIds[stepId],
        adSets: map(toggleFieldById('expanded', id), prevState.stepIds[stepId].adSets),
      };
      return newState;
    },
    updatePopupContainerBounds: (prevState, action) => {
      const { dimensions } = action.payload;
      let newState = {
        ...prevState,
        popUpBounds: dimensions,
      };
      return newState;
    },
    incrementPopContainerOffset: (prevState) => ({
      ...prevState,
      popUpOffsetCount: prevState.popUpOffsetCount + 1,
    }),
    resetPopContainerOffset: (prevState) => ({
      ...prevState,
      popUpOffsetCount: 0,
    }),
    removeAdPopup: (prevState, action) => {
      let newAdPopup = [...prevState.adsPopup];
      newAdPopup.splice(
        newAdPopup.findIndex((item) => item.id === action.payload),
        1,
      );
      const newState = {
        ...prevState,
        adsPopup: newAdPopup,
      };
      return newState;
    },
    resetAdPopups: (prevState) => {
      return {
        ...prevState,
        adsPopup: [],
        popUpBounds: null,
        popUpOffsetCount: 0,
      };
    },
    insertAdPopup: (prevState, action) => {
      const { stepId } = action.payload;
      const newAdsPopup = [...prevState.adsPopup];
      if (action.payload.id) {
        action.payload.offset = prevState.popUpOffsetCount;
        newAdsPopup.some(({ id }) => id === action.payload.id)
          ? newAdsPopup
          : newAdsPopup.push(action.payload);
        let newState = {
          ...prevState,
          adsPopup: newAdsPopup,
          popUpOffsetCount: prevState.popUpOffsetCount + 1,
          stepIds: {
            ...prevState.stepIds,
          },
        };
        newState.stepIds[stepId] = {
          ...prevState.stepIds[stepId],
        };
        return newState;
      }
    },
    setAdsetsAndAds: (prevState, action) => {
      const { stepId } = action.payload;
      const { campaigns, adSets, ads } = createAdsAndAdsets(
        prevState.stepIds[stepId],
        action.payload.data,
      );
      let newState = {
        ...prevState,
        stepIds: {
          ...prevState.stepIds,
        },
      };
      newState.stepIds[stepId] = {
        ...prevState.stepIds[stepId],
        campaigns,
        adSets,
        ads,
      };

      return newState;
    },
    setPopupModalPosition: (prevState, action) => {
      const {
        position: { x, y },
        id,
        stepId,
      } = action.payload;

      const newAdsPopup = prevState.adsPopup.map((item) => {
        if (item.id === id) {
          return { ...item, draggablePosition: { x, y } };
        }
        return item;
      });
      let newState = {
        ...prevState,
        stepIds: {
          ...prevState.stepIds,
        },
      };
      newState.adsPopup = [...newAdsPopup];
      return newState;
    },
    setAll: (prevState, action) => {
      const { campaigns, adSets, ads, selectedAdPrices, stepId, currentDateRange } = action.payload;
      let newState = {
        ...prevState,
        canSetIntegrationAttributes: true,
        stepIds: {
          ...prevState.stepIds,
        },
        currentDateRange,
      };
      newState.stepIds[stepId] = {
        ...prevState.stepIds[stepId],
        campaigns: [...campaigns],
        adSets: [...adSets],
        ads: [...ads],
        selectedAdPrices: { ...selectedAdPrices },
        isLoading: false,
      };
      return newState;
    },
    resetAllAdData: (prevState, action) => {
      const stepId = action.payload;
      let newState = {
        ...prevState,
        adsPopup: [],
        popUpBounds: null,
        popUpOffsetCount: 0,
        stepIds: {
          ...prevState.stepIds,
        },
      };
      newState.stepIds[stepId] = {
        ...prevState.stepIds[stepId],
        campaigns: [],
        adSets: [],
        ads: [],
        selectedAdPrices: {},
        isLoading: false,
      };

      return newState;
    },
    setIsLoading: (prevState, action) => {
      const { stepId, isLoading } = action.payload;
      let newState = {
        ...prevState,
        stepIds: {
          ...prevState.stepIds,
        },
      };
      newState.stepIds[stepId] = {
        ...prevState.stepIds[stepId],
        isLoading,
      };
      return newState;
    },
    setDefaultCollection: (prevState, action) => {
      const { stepId } = action.payload;
      let newState = {
        ...prevState,
        stepIds: {
          ...prevState.stepIds,
        },
      };
      newState.stepIds[stepId] = {
        ...initialCollectionByStepId,
        stepId,
      };
      return newState;
    },
    setCurrentDateRange: (prevState, action) => ({
      ...prevState,
      currentDateRange: action.payload,
    }),
    setCollection: (prevState, action) => {
      const {
        stepId,
        campaigns,
        adSets,
        ads,
        filteredCampaignIds,
        filteredAdSetIds,
        filteredAdIds,
      } = action.payload;
      let newState = {
        ...prevState,
        stepIds: {
          ...prevState.stepIds,
        },
      };
      newState.stepIds[stepId] = {
        ...prevState.stepIds[stepId],
        campaigns,
        adSets,
        ads,
        filteredCampaignIds,
        filteredAdSetIds,
        filteredAdIds,
      };
      return newState;
    },
    resetFilteredCollection: (prevState, action) => {
      const { stepId } = action.payload;
      let newState = {
        ...prevState,
        stepIds: {
          ...prevState.stepIds,
        },
      };
      newState.stepIds[stepId] = {
        ...prevState.stepIds[stepId],
        filteredCampaignsIds: [],
        filteredAdSetsIds: [],
        filteredAdsIds: [],
        collectionDisplayMode: FB_AD_MODAL_MODE_DEFAULT,
      };
      return newState;
    },
    setBlockInteraction: (prevState, action) => {
      const { stepId, isBlockingInteraction } = action.payload;
      let newState = {
        ...prevState,
        stepIds: {
          ...prevState.stepIds,
        },
      };
      newState.stepIds[stepId] = {
        ...prevState.stepIds[stepId],
        isBlockingInteraction,
      };
      return newState;
    },
    setCollectionDisplayMode: (prevState, action) => {
      const { stepId, collectionDisplayMode } = action.payload;
      let newState = {
        ...prevState,
        stepIds: {
          ...prevState.stepIds,
        },
      };
      newState.stepIds[stepId] = {
        ...prevState.stepIds[stepId],
        collectionDisplayMode,
      };
      return newState;
    },
  },
});

export const {
  setCheckboxes,
  toggleCampaignById,
  toggleAdSetById,
  updatePopupContainerBounds,
  incrementPopContainerOffset,
  resetPopContainerOffset,
  removeAdPopup,
  insertAdPopup,
  resetAdPopups,
  setAdsetsAndAds,
  emptyStepAttributes,
  setPopupModalPosition,
  setAll,
  setCollection,
  resetAllAdData,
  setIsLoading,
  setDefaultCollection,
  setCurrentDateRange,
  resetFilteredCollection,
  setBlockInteraction,
  setCollectionDisplayMode,
} = facebookIntegrationSlice.actions;

export const facebookIntegrationReducer = facebookIntegrationSlice.reducer;

/*
  Redux Thunk actions below
*/

export const searchFacebookAds = (reqObj) => async (dispatch, getState) => {
  const { stepId, projectId, searchText } = reqObj;
  try {
    dispatch(setBlockInteraction({ stepId, isBlockingInteraction: true }));
    dispatch(setIsLoading({ stepId, isLoading: true }));
    const timeRange = getState().facebookIntegration.currentDateRange;
    const response = await requestService.searchFacebookAds({ projectId, searchText, timeRange });
    if (!response.success) throw error('Unable to fetch Facebook Ads result');
    let filteredCampaignIds = new Set();
    let filteredAdSetIds = new Set();
    let filteredAdIds = new Set();
    response.data.forEach((el) => {
      filteredCampaignIds.add(el.campaign.id);
      filteredAdSetIds.add(el.adset.id);
      filteredAdIds.add(el.id);
    });
    filteredCampaignIds = [...filteredCampaignIds];
    filteredAdSetIds = [...filteredAdSetIds];
    filteredAdIds = [...filteredAdIds];
    const prevCampaigns = getState().facebookIntegration.stepIds[stepId].campaigns;
    const prevAdsets = getState().facebookIntegration.stepIds[stepId].adSets;
    const prevAds = getState().facebookIntegration.stepIds[stepId].ads;
    const campaigns = prevCampaigns.map(updateDisplayAttribute(filteredCampaignIds));
    const adSets = prevAdsets.map(updateDisplayAttribute(filteredAdSetIds));
    const ads = prevAds.map(updateDisplayAttribute(filteredAdIds));
    dispatch(
      setCollection({
        stepId,
        campaigns,
        adSets,
        ads,
        filteredCampaignIds,
        filteredAdSetIds,
        filteredAdIds,
      }),
    );
  } catch (e) {
    console.log('searchFacebookAds Error: ', e);
  } finally {
    dispatch(setIsLoading({ stepId, isLoading: false }));
    dispatch(setBlockInteraction({ stepId, isBlockingInteraction: false }));
  }
};

export const fetchFacebookAdsAdsets = (reqObj) => async (dispatch, getState) => {
  const { stepId, projectId, campaignId } = reqObj;
  const timeRange = getState().facebookIntegration.currentDateRange;
  const response = await requestService.loadFacebookAdsAdset(projectId, campaignId, timeRange);
  if (!response.success) return;
  dispatch(setAdsetsAndAds({ ...response, stepId }));
};

export const fetchAllFacebookAdsCampaigns = (reqObj) => async (dispatch, getState) => {
  const { stepId } = reqObj;
  const collection = getState().facebookIntegration.stepIds[stepId];
  if (!collection) {
    dispatch(setIsLoading({ stepId, isLoading: false }));
    return;
  }
  const campaignsCount = collection?.campaigns.length > 0;
  if (campaignsCount) {
    dispatch(setIsLoading({ stepId, isLoading: true }));
    return;
  }

  const { projectId, timeRange, integrationAttributes } = reqObj;
  let mockCollection = {
    campaigns: [],
    adSets: [],
    ads: [],
    selectedAdPrices: {},
    canSetIntegrationAttributes: false,
    isLoading: true,
    currentDateRange: {},
  };

  const campaignResponse = await requestService.loadFacebookAdsCampaign(projectId, timeRange);
  //TODO: Case to handle failed request
  mockCollection.campaigns = createCampaigns(mockCollection, campaignResponse.data);
  const { userAdIds, userCampaignIds } = selectedAdsToIdCollection(integrationAttributes);
  // Force a fetch of adsets/ads for campaigns
  const adsetResponses = await Promise.all(
    userCampaignIds.map(async (userCampaignId) => {
      return await requestService.loadFacebookAdsAdset(projectId, userCampaignId, timeRange);
    }),
  );
  adsetResponses.forEach((adsetResp) => {
    const { campaigns, adSets, ads } = createAdsAndAdsets(mockCollection, adsetResp.data);
    mockCollection = {
      ...mockCollection,
      campaigns,
      adSets,
      ads,
    };
  });

  // All ads needs to be checked
  userAdIds.forEach((userAd) => {
    // TODO: Needs to check if AD ID exists. Needs escape case if it doesn't
    if (!mockCollection.ads.some((ad) => ad.id === userAd)) return;
    // Update Selection Spend
    const { ads, adSets, campaigns, adsChecked, adsUnchecked } = updateCheckboxes(
      mockCollection,
      userAd,
    );
    mockCollection.campaigns = campaigns;
    mockCollection.adSets = adSets;
    mockCollection.ads = ads;
    adsUnchecked.forEach((item) =>
      updateSpendByItem(item, mockCollection.selectedAdPrices, 'deduct'),
    );
    adsChecked.forEach((item) => updateSpendByItem(item, mockCollection.selectedAdPrices, 'add'));
  });
  mockCollection.currentDateRange = timeRange;
  mockCollection.campaigns = orderBy(
    mockCollection.campaigns,
    [(item) => parseFloat(item.totalSpend?.value) || ''],
    ['desc'],
  );
  mockCollection.adSets = orderBy(
    mockCollection.adSets,
    [(item) => parseFloat(item.totalSpend?.value) || ''],
    ['desc'],
  );
  mockCollection.ads = orderBy(
    mockCollection.ads,
    [(item) => parseFloat(item.totalSpend?.value) || ''],
    ['desc'],
  );

  dispatch(setAll({ ...mockCollection, stepId }));
};
