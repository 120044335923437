import React, { useEffect, useRef, useState } from 'react';
import styles from 'react-project/LeftSideBar/LeftSideBar.module.scss';
import { iconContribution, iconWarrning } from 'react-project/assets/Icons';
import { When } from 'react-project/Util/When';
import cls from 'classnames';
import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';
import { TEXTS_TOOLTIP } from 'react-project/Constants/texts';
import { getDirtyStyle, getFilterAddClass } from 'react-project/Util/FilterStyleHelper';
import {
  FILTER_ITEM_PADDING_WIDTH,
  HEADER_HEIGHT,
  LEFT_SIDEBAR_WIDTH,
} from 'shared/CSharedConstants';
import { hasScrollBar } from 'react-project/Util/hasScrollBar';
import { getRefPosition } from 'react-project/Util/getPosition';
import { TOOLTIP_POSITIONS } from 'react-project/Constants/tooltip';
import { Tooltip } from 'react-project/components/tooltip/Tooltip';
import { SecondaryButton } from 'react-project/components/secondaryButton/SecondaryButton';
import buttonStyles from 'react-project/components/secondaryButton/SecondaryButtons.module.scss';

export const DEFAULT_STEP_LABEL = 'Select a step';
export const CONTRIBUTION_DIRECTION_NONE = 'none';
export const CONTRIBUTION_DIRECTION_FORWARD = 'forward';
export const CONTRIBUTION_DIRECTION_BACKWARD = 'backward';

const defaultFilter = Object.freeze({
  direction: CONTRIBUTION_DIRECTION_NONE,
  duration: 0,
});

export const ContributionFilterBlock = ({
  type,
  hasFilterChange,
  focusedSteps,
  filter,
  onFilterChanged,
  isApplyButtonEnabled,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [localFilter, setLocalFilter] = useState(defaultFilter);

  let isFilterApplied = false;
  let nothingToClear = false;
  let isFilterSet = true;

  if (!filter) {
    if (JSON.stringify(localFilter) === JSON.stringify(defaultFilter)) {
      isFilterApplied = true;
      nothingToClear = true;
      isFilterSet = false;
    } else {
      isFilterApplied = false;
    }

    if (localFilter.direction === CONTRIBUTION_DIRECTION_NONE) {
      isFilterSet = false;
    }
  } else {
    if (JSON.stringify(localFilter) === JSON.stringify(filter)) {
      isFilterApplied = true;
    } else {
      isFilterApplied = false;
    }

    if (JSON.stringify(filter) === JSON.stringify(defaultFilter)) {
      nothingToClear = true;
      isFilterSet = false;
    }

    if (localFilter.direction === CONTRIBUTION_DIRECTION_NONE) {
      isFilterSet = false;
    }
  }

  useEffect(() => {
    if (filter) {
      setLocalFilter(filter);
    } else if (filter === null) {
      setLocalFilter(defaultFilter);
    }
  }, [filter]);

  const toggleOpenedFilter = (e, value) => {
    const setOpenValue = value !== undefined ? value : !isOpened;
    setIsOpened(setOpenValue && focusedSteps.length > 0);
  };

  const onClearButtonClicked = (e) => {
    onFilterChanged({
      contributionWindow: undefined,
    });
    setLocalFilter(defaultFilter);
  };

  const onApplyButtonClicked = (e) => {
    toggleOpenedFilter(e, false);
    const newFilter = { ...localFilter };

    if (newFilter.direction === CONTRIBUTION_DIRECTION_NONE) {
      onFilterChanged({
        contributionWindow: undefined,
      });
      setLocalFilter(defaultFilter);
    } else {
      onFilterChanged({
        contributionWindow: newFilter,
      });
    }
  };

  const onDirectionChanaged = (e) => {
    const newFilter = { ...localFilter, direction: e.target.value };
    setLocalFilter(newFilter);
  };

  const onDurationChanged = (e) => {
    const duration = parseInt(e.target.value);
    if (!isNaN(duration)) {
      const newFilter = { ...localFilter, duration };
      setLocalFilter(newFilter);
    } else if (e.target.value === '') {
      const newFilter = { ...localFilter, duration: '' };
      setLocalFilter(newFilter);
    }
  };

  const ref = useRef();
  const position = getRefPosition(ref);
  const sidebar = document.getElementById('left-sidebar-wrapper');

  return (
    <ClickOutsideCustomComponent
      ignoreClickOutside={!isOpened}
      onClickOutside={(e) => {
        toggleOpenedFilter(e, false);
      }}
    >
      <div
        className={cls(styles.FiltersItem, getFilterAddClass(type), {
          [styles.ActiveFilter]: isOpened && focusedSteps.length > 0,
          [getDirtyStyle(type)]: false,
          [styles.DisabledFilter]: focusedSteps.length <= 0,
        })}
        ref={ref}
      >
        <When condition={focusedSteps.length > 0}>
          <div className={styles.ItemsForSelection} onClick={toggleOpenedFilter}>
            <span className={cls(styles.FilterTitleBlock, styles.PWPBlock)}>
              Contribution Window:
              <span className={styles.CountriesTitle}>
                {isFilterSet
                  ? `${localFilter.direction} ${localFilter.duration} ${
                      localFilter.duration !== 1 ? 'days' : 'day'
                    }`
                  : 'Not Set'}
              </span>
            </span>
            <div className={styles.filterIconArrangement} style={{ marginRight: '-3px' }}>
              <When condition={hasFilterChange && isApplyButtonEnabled}>
                <Tooltip
                  label={TEXTS_TOOLTIP.UNAPPLIED_CHANGES}
                  position={TOOLTIP_POSITIONS.BOTTOM}
                >
                  <div className={styles.FilterWarning}>{iconWarrning}</div>
                </Tooltip>
              </When>
              {iconContribution}
            </div>
          </div>
        </When>

        <When condition={focusedSteps.length <= 0}>
          <div className={styles.ItemsForSelection} onClick={toggleOpenedFilter}>
            <span className={cls(styles.FilterTitleBlock, styles.PWPBlock)}>
              Contribution Window:
              <span className={styles.CountriesTitle}>Filter by step first</span>
            </span>
            <div className={styles.filterIconArrangement} style={{ marginRight: '-3px' }}>
              {iconContribution}
            </div>
          </div>
        </When>

        <When condition={isOpened}>
          <div
            className={styles.StepFilterWrapper}
            style={{
              position: 'absolute',
              top: 0, // position.y - HEADER_HEIGHT,
              //left: LEFT_SIDEBAR_WIDTH + (hasScrollBar(sidebar) ? 0 : FILTER_ITEM_PADDING_WIDTH),
              zIndex: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div className={styles.PWPFocusedControls}>
              <div className={styles.PWPTitleSection}> Set your Contribution Window parameters</div>
            </div>

            <div className={styles.Separator}></div>

            <div className={styles.ContributionBlock}>
              <div className={styles.ContributionControl}>
                <span>Direction</span>
                <select value={localFilter.direction || 'none'} onChange={onDirectionChanaged}>
                  <option value={CONTRIBUTION_DIRECTION_NONE}>None</option>
                  <option value={CONTRIBUTION_DIRECTION_FORWARD}>Forward</option>
                  <option value={CONTRIBUTION_DIRECTION_BACKWARD}>Backward</option>
                </select>
              </div>
              <div className={styles.ContributionDescription}>
                Should the Contribution Window search the Customer Journeys before or after the
                steps selected in the Step Filter?
              </div>
            </div>

            <div className={styles.Separator}></div>

            <div className={styles.ContributionBlock}>
              <div className={styles.ContributionControl}>
                <span> Duration (days)</span>
                <input onChange={onDurationChanged} type="text" value={localFilter.duration || 0} />
              </div>
              <div className={styles.ContributionDescription}>
                How many days should the Contribution Window use to look for conversions in the
                Customer Journeys?
              </div>
            </div>

            <div className={styles.PWPFooterSection}>
              <SecondaryButton
                title="Clear All"
                onClick={onClearButtonClicked}
                className={
                  isFilterApplied && !nothingToClear
                    ? cls(buttonStyles.ApplyFiltersButton, styles.PWPApplyButton)
                    : null
                }
              />
              <SecondaryButton
                title="Apply"
                onClick={onApplyButtonClicked}
                className={cls(
                  buttonStyles.ApplyFiltersButton,
                  styles.PWPApplyButton,
                  isFilterApplied ? styles.ButtonDisabled : null,
                )}
              />
            </div>
          </div>
        </When>
      </div>
    </ClickOutsideCustomComponent>
  );
};
