import { produce } from 'immer';
import {
  setExplorerItemsConfig,
  setExplorerLoadingCount,
  setExplorerLoadingStatus,
  setExplorerPageNumber,
  setExplorerPageParameters,
  setLoadMoreSourceItemsConfig,
} from 'react-project/redux/explorer/actions';
import { handleActions } from 'redux-actions';
import { DEFAULT_ITEMS_CONFIG } from 'shared/CSharedConstants';

const initialState = DEFAULT_ITEMS_CONFIG;

export default handleActions(
  {
    [setExplorerItemsConfig.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.explorerItemsConfig = action.payload;
      }),
    [setExplorerPageNumber.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.explorerPageNumber = action.payload;
      }),
    [setExplorerLoadingStatus.toString()]: (state, action) =>
      produce(state, (draft) => {
        // Introduce a counter to prevent the loading status from being set to false
        // if there are multiple requests in flight
        if (action.payload === false && draft.explorerLoadingCount === 0) {
          draft.explorerIsLoading = action.payload;
        } else if (action.payload === true) {
          draft.explorerIsLoading = true;
        }
      }),
    [setExplorerLoadingCount.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.explorerLoadingCount += action.payload;
      }),
    [setExplorerPageParameters.toString()]: (state, action) =>
      produce(state, (draft) => {
        if (action.payload) {
          draft.explorerItemsConfig.SOURCE.commonParameters =
            action.payload.page_parameters.common_parameters;
          draft.explorerItemsConfig.SOURCE.customParameters =
            action.payload.page_parameters.custom_parameters;
        }
      }),
    [setLoadMoreSourceItemsConfig.toString()]: (state, action) => {
      //Check for duplicates from the query in data
      //Set check to determine if there are no more new data that we remove the "load more" button
      //Update to explorer source.
      const payload = action.payload;
      let source = {
        commonParameters: [...state.explorerItemsConfig.SOURCE.commonParameters],
        customParameters: [...state.explorerItemsConfig.SOURCE.customParameters],
      };
      //find index of matching param, if index === -1 push to array else update existing in place

      const setParams = (sourceParams) => (attr) => {
        //attr is UTM_TERM
        // Find matching common param key
        const index = sourceParams.findIndex((el) => el.key === attr.key);
        if (index === -1) return sourceParams.push(attr);

        // With index, update the values of the Common/Custom Param
        // Push value array if it doesn't already exist
        // ex. attr has multiple values in an array which we
        let paramAttrValues = [...sourceParams[index].values];
        attr.values.forEach((attrVal) => {
          const valExists = sourceParams[index].values.some((dict) => {
            return dict.value === attrVal.value;
          });
          if (valExists) return;
          paramAttrValues.push(attrVal);
        });
        const replacementAttr = {
          key: attr.key,
          hits: attr.hits,
          values: paramAttrValues,
          has_more: attr.has_more,
        };
        sourceParams[index] = replacementAttr;
      };
      payload.common_parameters.forEach(setParams(source.commonParameters));
      payload.custom_parameters.forEach(setParams(source.customParameters));
      return produce(state, (draft) => {
        draft.explorerItemsConfig.SOURCE.commonParameters = source.commonParameters;
        draft.explorerItemsConfig.SOURCE.customParameters = source.customParameters;
      });
    },
  },
  initialState,
);
