import cls from 'classnames';
import React from 'react';
import ShareStyles from 'react-project/Sharing/ShareLink.module.scss';
import { LibrarySelect } from './LibrarySelect';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import { useSelector } from 'react-redux';

export const LibraryForm = ({
  options,
  loading,
  setFormValues,
  formValues,
  isSaving,
  formDisabled,
}) => {
  const canvasPermissions = useSelector(selectCanvasPermissions);

  /**
   * Manage form values and send them to the parent component
   *
   * @param {React.ChangeEvent<HTMLTextAreaElement>}
   * @returns {void}
   */
  const handleFormChange = (e) => {
    if (canvasPermissions.isTutorial) {
      return;
    }
    const { name, value } = e.target;
    if (name === 'industry' || name === 'offerType' || name === 'purpose') {
      setFormValues((prev) => ({ ...prev, [name]: value }));
    }
  };

  return (
    <div className={cls(ShareStyles.fontbase, 'text-xs pb-[24px]')}>
      <div className="pb-[24px]">
        <div>
          <div className="p-[8px]"></div>
          <div className="flex">
            <div className="flex flex-column w-full">
              <div className="w-6/12 pr-[12px]">
                <div className="flex justify-between pb-[12px]">
                  <div className="font-light" for={'purpose'}>
                    Category
                  </div>
                  <div className="italic font-light">Required*</div>
                </div>
                <LibrarySelect
                  name={'purpose'}
                  optionData={options.purposeOption}
                  formDisabled={formDisabled}
                  loading={loading}
                  isSaving={isSaving}
                  formValues={formValues.purpose}
                  handleFormChange={handleFormChange}
                />
              </div>
              <div className="w-6/12 pr-[12px]">
                <div className="flex justify-between pb-[12px]">
                  <div className="font-light" for="industry">
                    Industry
                  </div>
                  <div className="italic font-light">Required*</div>
                </div>
                <LibrarySelect
                  name={'industry'}
                  optionData={options.industryOption}
                  formDisabled={formDisabled}
                  loading={loading}
                  isSaving={isSaving}
                  formValues={formValues.industry}
                  handleFormChange={handleFormChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="flex">
          <div className="flex flex-column w-full">
            <div className="w-6/12 pr-[12px]">
              <div className="flex justify-between pb-[12px]">
                <div className="font-light" for="offer">
                  Goal
                </div>
                <div className="italic font-light">Required*</div>
              </div>
              <LibrarySelect
                name={'offerType'}
                optionData={options.offerTypeOption}
                formDisabled={formDisabled}
                loading={loading}
                isSaving={isSaving}
                formValues={formValues.offerType}
                handleFormChange={handleFormChange}
              />
            </div>
            <div className="w-6/12 pr-[12px]"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
