import BaseAnalyticsManager from 'pixi-project/core/BaseAnalyticsManager';
import { toPercent, toDollar, roundTo } from 'shared/CSharedMethods';
import { TEXT_PEOPLE, TEXT_RATE, TEXT_CPP } from 'shared/CSharedTexts';
import { numSeparator, numSeparatorRound } from 'shared/NumberHelpers';
import { EIntegrationTypes } from 'shared/CSharedCategories';
import { isGoalValid } from 'shared/ValidateHelpers';
import { shortenText } from 'shared/SharedHelpers';
import { GOAL_DEFAULT_NAME, NO_DATA_STRING } from 'shared/CSharedConstants';
import MainStorage from './MainStorage';

export default class AnalyticsConnectionManager extends BaseAnalyticsManager {
  constructor(controller, view, data = null) {
    super(controller, view, data);
  }

  setData(data) {
    this.data = data;

    if (!this.data) {
      // clear the view
      this.view.resetData();
    }
  }

  process() {
    this.view.setCurrency(this.currency);
    const isDataValid = this.verifyData(this.data);

    if (!isDataValid && MainStorage.isForecastingVisible()) {
      this.showForecasting();
      this.controller.draw();
      return;
    }

    if (MainStorage.isNumbersVisible()) {
      if (!isDataValid) {
        this.percent = null;
        this.view.setNoData();
        this.controller.draw();
        return;
      }
    }

    if (isDataValid) {
      this.percent = this.getConversionRate(this.data['hits']);
      this.createIntegrationViewData();
    }

    this.showData();
    this.controller.draw();
  }

  createIntegrationViewData() {
    this.integrationViewData = {
      ...this.controller.iconA.analyticsManager.data,
      cpp: this.getCostPerPerson(this.data['hits']),
    };
  }

  verifyData(data) {
    if (!data) {
      return false;
    }
    if (data.hits !== undefined) {
      return true;
    }
    return false;
  }

  getConversionRate(value, isCompare = false) {
    const sourceData = this.controller.iconA.analyticsManager.data;
    if (sourceData) {
      const incomingValue = isCompare ? sourceData['compare_to_data']['hits'] : sourceData['hits'];

      if (incomingValue > 0) {
        return (value / incomingValue) * 100;
      }
    }
    return 0;
  }

  getCostPerPerson(value) {
    const sourceData = this.controller.iconA.analyticsManager.data;
    if (!sourceData || !sourceData.hits || !value) return 'N/A';
    // return CPP value based on connection hits number
    if (sourceData && value > 0) {
      return sourceData.cost / value;
      // fallback to source elemenbt CPP value
    } else if (sourceData) {
      return sourceData.cost / sourceData.hits;
    }
  }

  showForecasting() {
    if (this.controller.forecastingCalculatedData) {
      const people = Math.round(this.controller.forecastingCalculatedData.people);
      const percent = this.controller.forecastingCalculatedData.percent;
      this.view.setCommonForecastingData(
        toPercent(percent, 2),
        numSeparator(people),
        TEXT_RATE,
        TEXT_PEOPLE,
        null,
      );
    } else {
      // there is no forecasting data to show
      this.view.setCommonForecastingData(
        NO_DATA_STRING,
        NO_DATA_STRING,
        TEXT_RATE,
        TEXT_PEOPLE,
        null,
      );
    }
  }

  showData() {
    if (MainStorage.isForecastingVisible() && !MainStorage.isNumbersVisible()) {
      // analytics data is not needed in this case
      this.showForecasting();
    } else if (MainStorage.isForecastingVisible()) {
      if (MainStorage.isNumbersVisible() && MainStorage.isForecastingVisible()) {
        if (this.controller.forecastingCalculatedData) {
          const fPercent = this.controller.forecastingCalculatedData.percent;
          const fPeople = this.controller.forecastingCalculatedData.people;

          const people = this.data['hits'];

          const rateChangePercent = this.parsePercentChange(fPercent, this.percent);
          // calculate the rate once you round the forecasted people number
          const ratePeopleChange = this.parsePercentChange(Math.round(fPeople), people);

          const compareData = [
            [
              TEXT_RATE,
              toPercent(this.percent, 2),
              toPercent(fPercent, 2),
              [
                this.getForecastCompareTexture(rateChangePercent),
                toPercent(rateChangePercent, 1),
                rateChangePercent,
              ],
            ],
            [
              TEXT_PEOPLE,
              numSeparatorRound(people),
              numSeparatorRound(fPeople),
              [
                this.getForecastCompareTexture(ratePeopleChange),
                toPercent(ratePeopleChange, 1),
                ratePeopleChange,
              ],
            ],
          ];

          this.view.setCompareForecastingData(compareData, null);
        } else {
          const people = this.data['hits'];

          const compareData = [
            [
              TEXT_RATE,
              toPercent(this.percent, 2),
              NO_DATA_STRING,
              [null, NO_DATA_STRING, NO_DATA_STRING],
            ],
            [
              TEXT_PEOPLE,
              numSeparatorRound(people),
              NO_DATA_STRING,
              [null, NO_DATA_STRING, NO_DATA_STRING],
            ],
          ];

          this.view.setCompareForecastingData(compareData, null);
        }
      }
    } else if (this.data && this.view.canHaveValue) {
      if (this.data['compare_to_data']) {
        const data = this.getCompareData();
        const comparePercent = this.getConversionRate(data.compareHits, true);
        const rateChangePercent = this.parsePercentChange(comparePercent, this.percent);

        const compareData = [
          [
            TEXT_RATE,
            toPercent(this.percent, 2),
            toPercent(comparePercent, 2),
            [
              this.getCompareTexture(rateChangePercent),
              toPercent(rateChangePercent, 1),
              rateChangePercent,
            ],
          ],
          [
            TEXT_PEOPLE,
            numSeparator(data.currentHits),
            numSeparator(data.compareHits),
            [this.getCompareTexture(data.percent), toPercent(data.percent, 1), data.percent],
          ],
        ];

        if (isGoalValid(this.controller.goal)) {
          const goal = this.controller.goal;
          compareData.push([
            shortenText(goal.goalName || GOAL_DEFAULT_NAME, 20),
            `${this.currency}${numSeparator(roundTo(data.currentHits * goal.goalValue, 2))}`,
            `${this.currency}${numSeparator(roundTo(data.compareHits * goal.goalValue, 2))}`,
            [this.getCompareTexture(data.percent), toPercent(data.percent, 1), data.percent],
          ]);
        }

        this.view.setCompareData(compareData, this.controller.goal);
      } else {
        if (this.controller.iconA.integrationType === EIntegrationTypes.FACEBOOK_AD) {
          this.view.setCommonData(
            toDollar(this.integrationViewData.cpp),
            // connection should display it's own hits number
            numSeparator(this.data.hits),
            TEXT_CPP,
            TEXT_PEOPLE,
            this.controller.goal,
          );
        } else {
          this.view.setCommonData(
            toPercent(this.percent, 2),
            numSeparator(this.data['hits']),
            TEXT_RATE,
            TEXT_PEOPLE,
            this.controller.goal,
          );
        }
      }
    }
  }
}
