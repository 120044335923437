import React, { Component } from 'react';
import '../../pixi-project/index';
import styles from './Main.module.scss';
import { NotificationAlert } from '../components/notificationAlert/NotificationAlert';
import { SendReportPopup } from '../components/sendReportPopup/SendReportPopup';
import { StyleReportPopup } from '../components/styleReportPopup/StyleReportPopup';
import { connect } from 'react-redux';
import {
  PR_EVENT_ON_PHOTO_CHANGED,
  RP_EVENT_PHOTO_UPLOADED,
  RP_EVENT_PHOTO_UPLOAD_STARTED,
  PR_EVENT_CONNECTION_IN_EMPTY_SPACE,
  PR_EVENT_DOWNLOAD_REPORT_CLICKED,
  PR_EVENT_ELEMENT_POINTER_UP,
  PR_EVENT_ELEMENT_SELECTED,
  PR_EVENT_PEOPLE_WIDGET_LOAD_MORE_TRIGGERED,
  PR_EVENT_REPORT_BLOCKED_WARNING_POPUP_OPENED,
  PR_EVENT_REPORT_STYLE,
  PR_EVENT_REPORT_STYLE_UPDATED,
  PR_EVENT_REPORT_WARNING_POPUP_OK_BTN_CLICKED,
  PR_EVENT_REPORT_WARNING_POPUP_OPENED,
  PR_EVENT_SEND_REPORT_CLICKED,
  PR_EVENT_OBJECT_SELECTED,
  PR_EVENT_WIDGET_PASTED,
  RP_EVENT_CANCEL_NEW_STEP_FROM_CONNECTION,
  RP_EVENT_CONNECTION_LINE_TYPE_CHANGED,
  RP_EVENT_DRAW_LINE_TYPE_CHANGED,
  RP_EVENT_ELEMENT_RIGHT_CLICK,
  RP_EVENT_ERROR_MESSAGE,
  RP_EVENT_WIDGET_DATA_UPDATED,
  RP_EVENT_INFO_MESSAGE,
} from 'shared/CSharedEvents';
import { ErrorMessageContainer } from 'react-project/Main/ErrorMessageContainer';
import { loadMoreSessionsAsync } from 'react-project/redux/analytics/actions';
import {
  selectDataConnections,
  selectDataObjs,
  selectProfileCountries,
  selectSessions,
} from 'react-project/redux/analytics/selectors';
import { setNewCurrentStep, setTextStyle } from 'react-project/redux/current-step/actions';
import { selectFunnelConfiguration } from 'react-project/redux/funnel-configuration/selectors';
import { selectFunnel } from 'react-project/redux/funnels/selectors';
import { downloadReportRequestAction } from 'react-project/redux/report/actions';
import { selectEmail } from 'react-project/redux/user/selectors';

import { EElementCategories, EElementTypes } from 'shared/CSharedCategories';
import { suppressedErrorMessages } from 'shared/CSharedConstants';
import { HEAP_EVENTS } from 'react-project/Constants/heapEvents';
import { uploadPhotoToS3 } from 'react-project/Util/PhotoHelper';

import { cloneData, commonSendEventFunction } from 'shared/CSharedMethods';

import { ClosePopup } from '../components/popUp/ClosePopup';
import { ConfirmationPopup } from '../components/popUp/ConfirmationPopup';
import { LoadHubspotChat } from '../Helpers/HubspotChat';
import { ClickOutsideCustomComponent } from '../Util/ClickOutsideCustom';
import { When } from '../Util/When';
import { Menus } from 'react-project/Menus/Menus';
import { sendHeapTracking } from 'react-project/Util/HEAP.utilities';
import { track as trackCohesive } from 'react-project/Util/CohesiveTracking';
import { LayerType } from 'react-project/LayersMenu/LayerType';
import { InfoPopup } from 'react-project/components/popUp/infoPopup/InfoPopup';

const POPUP_MESSAGE =
  'Deleting the report will delete everything within it. \n Do you still wish to continue?';
const POPUP_BLOCKED_MESSAGE = 'This report is locked. Please unlock to make any changes';

class Main extends Component {
  state = {
    toolbarOpened: false,
    category: EElementCategories.CONNECTION,
    toolbarData: {},
    toolbarMultiSelect: false,
    isShowStep: false,
    reportWarningModalOpened: false,
    reportBlockedWarningModalOpened: false,
    isShowSendReportPopup: false,
    titleSendReportPopup: '',
    isRightMouse: false,
    isShowStyleReportPopup: false,
    reportStyleSlide: null,
    infoMessage: null,
  };

  setSendReportStatus = (status) => {
    this.setState({
      isShowSendReportPopup: status,
    });
  };

  onInfoClose = () => {
    this.setState({ infoMessage: null });
  };

  errorMessageSetter = (isShow) => {
    this.props.setIsMessageErrorShowing(isShow);
  };

  errorMessageHandler = (e) => {
    // If the analytics is hidden, then don't show the error message
    if (
      e &&
      e.type === RP_EVENT_ERROR_MESSAGE &&
      suppressedErrorMessages.includes(e.detail.errorMSG)
    ) {
      // this will suppress the message from showing
      return;
    }

    if (this.props.isMessageErrorShowing) {
      // do not show more then a single message at a time
      // until we have a better way to show multiple messages
      return;
    }

    this.props.setMessageErrorData(e.detail.errorMSG);
    this.props.setIsMessageErrorShowing(true);

    if (e.detail.autoClose) {
      setTimeout(() => {
        this.props.setMessageErrorData(null);
        this.props.setIsMessageErrorShowing(false);
      }, 5000);
    }
  };

  setToolbarMultiselect = (state) => {
    this.setState({
      toolbarMultiSelect: state,
    });
  };

  onObjectSelected = (e) => {
    if (!e.detail.show) {
      this.setState({
        toolbarOpened: false,
        toolbarData: {},
        toolbarMultiSelect: false,
        isRightMouse: e.detail.isRightMouse || false,
      });
      return;
    }

    if (e.detail.stepId && e.detail.hyperlink !== undefined) {
      this.updateCurrentStep(e.detail);
    }

    if (e.detail.textStyle) {
      this.props.onSetTextStyle(e.detail.textStyle);
    }

    const detailData = {
      coords: e.detail.position || { x: 0, y: 0 },
      sectionStyle: e.detail.sectionStyle,
      avoidRectangles: e.detail.avoidRectangles,
      stepId: e.detail.stepId,
    };

    if (e.detail.multiSelect) {
      this.setToolbarMultiselect(e.detail.multiSelect);
      Object.keys(e.detail).forEach((prop) => (detailData[prop] = e.detail[prop]));
    } else {
      detailData.commonLineType = e.detail.lineType;
      detailData.supportedLineTypes = e.detail.supportedLineTypes;
      detailData.commonDrawLineType = e.detail.commonDrawLineType;
    }

    this.setState({
      toolbarOpened: e.detail.show,
      category: e.detail.category,
      toolbarData: detailData,
      type: e.detail.type,
      isRightMouse: e.detail.isRightMouse || false,
    });
  };

  onConnectionInEmptySpace = () => {
    this.setState({ isShowStep: true });
  };

  onCancelNewStepFromConnection = () => {
    this.setState({ isShowStep: false });
  };

  onElementPointerUp = (e) => {
    this.updateCurrentStep(e.detail);
  };

  onElementRightClicked = (e) => {
    this.updateCurrentStep(e.detail);
  };

  onElementSelected = (e) => {
    this.updateCurrentStep(e.detail);
  };

  updateCurrentStep = (data) => {
    const step = cloneData(data);
    this.props.onSetNewCurrentStep(step);
  };

  onConnectionLineTypeChanged = (lineType) => {
    let newToolbarData = { ...this.state.toolbarData };
    newToolbarData.commonLineType = lineType;

    this.setState({
      toolbarData: newToolbarData,
    });

    commonSendEventFunction(RP_EVENT_CONNECTION_LINE_TYPE_CHANGED, {
      lineType,
    });
  };

  onConnectionDrawLineTypeChanged = (drawLineType) => {
    let newToolbarData = { ...this.state.toolbarData };
    newToolbarData.commonDrawLineType = drawLineType;

    this.setState({
      toolbarData: newToolbarData,
    });

    commonSendEventFunction(RP_EVENT_DRAW_LINE_TYPE_CHANGED, {
      drawLineType,
    });
  };

  onReportWarningPopup = (e) => {
    const { isSlide } = e.detail;
    this.seStatusReportWarningPopup(true, isSlide);
  };

  seStatusReportWarningPopup = (isShow, isSlide = false) => {
    this.setState({
      reportWarningModalOpened: isShow,
      reportWarningModalSlide: isSlide,
    });
  };

  onReportBlockedWarningPopup = (e) => {
    e.preventDefault();
    this.setStatusReportBlockedWarningPopup(true);
  };

  setStatusReportBlockedWarningPopup = (isShow) => {
    this.setState({
      reportBlockedWarningModalOpened: isShow,
    });
  };

  onSendReportClicked = (e) => {
    const { title } = e.detail;
    this.setState({ isShowSendReportPopup: true, titleSendReportPopup: title });
  };

  onStyleReportClicked = (e) => {
    const stateUpdate = { isShowStyleReportPopup: true, reportStyleSlide: e.detail.slide };
    this.setState(stateUpdate);
  };

  onStyleReportUpdated = (e) => {
    if (this.state.isShowStyleReportPopup) {
      const stateUpdate = { reportStyleSlide: e.detail.slide };
      this.setState(stateUpdate);
    }
  };

  onPhotoObjectChanged = (e) => {
    const file = e.detail.file;
    const currentStep = e.detail.currentStep;

    // Start loading
    commonSendEventFunction(RP_EVENT_PHOTO_UPLOAD_STARTED, {
      stepId: currentStep.stepId,
    });

    uploadPhotoToS3(
      file,
      (response) => {
        commonSendEventFunction(RP_EVENT_PHOTO_UPLOADED, {
          ...response,
          stepId: currentStep.stepId,
        });
      },
      'icons/issue/misc',
    );
  };

  onInfoMessageReceived = (e) => {
    this.setState({ infoMessage: e.detail.message });
  };

  onStyleReportPopupClose = (e) => {
    this.setState({
      isShowStyleReportPopup: false,
    });
  };

  onDownloadReportClicked = (e) => {
    const { title, images } = e.detail;
    const funnelConfiguration = this.props.funnelConfiguration;
    const sessions = this.props.sessions;
    const { projectId } = this.props.funnel;

    Promise.all(images).then((values) => {
      const reportData = {
        name: title,
        userId: this.props.userId,
        funnelConfiguration,
        sessions,
        images: values,
        isNumbersActive: this.props.funnelConfiguration.visibleDataLayers[LayerType.NUMBERS],
      };
      this.props.onDownloadButtonClicked(reportData);
    });
    // Send Heap event on downloading report
    sendHeapTracking({
      projectId,
      eventName: HEAP_EVENTS.REPORT_DOWNLOADED,
    });

    trackCohesive(HEAP_EVENTS.REPORT_DOWNLOADED, {
      projectId,
    });
  };

  onLoadMoreSessions = (e) => {
    const { projectId = false } = this.props.funnel;
    const sessions = this.props.sessions.sessions;
    const last = sessions && sessions[sessions.length - 1];

    if (!projectId || !last) {
      return;
    }

    this.props.onLoadMoreSessions({
      projectId,
      funnelConfiguration: this.props.funnelConfiguration,
      dataObjs: this.props.dataObjs,
      dataConnections: this.props.dataConnections,
      compareMode: false,
      last,
    });
  };

  onWidgetPasted = (e) => {
    const widgetType = e.detail.type;
    let data = null;

    if (widgetType === EElementTypes.WIDGET_PEOPLE) {
      data = this.props.sessions;
    } else if (widgetType === EElementTypes.WIDGET_COUNTRIES) {
      data = this.props.countries;
    }

    commonSendEventFunction(RP_EVENT_WIDGET_DATA_UPDATED, { ...e.detail, data });
  };

  componentDidMount() {
    document.addEventListener(PR_EVENT_OBJECT_SELECTED, this.onObjectSelected, false);
    document.addEventListener(RP_EVENT_ERROR_MESSAGE, this.errorMessageHandler, false);
    document.addEventListener(
      PR_EVENT_CONNECTION_IN_EMPTY_SPACE,
      this.onConnectionInEmptySpace,
      false,
    );
    document.addEventListener(
      RP_EVENT_CANCEL_NEW_STEP_FROM_CONNECTION,
      this.onCancelNewStepFromConnection,
      false,
    );
    document.addEventListener(PR_EVENT_ELEMENT_POINTER_UP, this.onElementPointerUp, false);
    document.addEventListener(RP_EVENT_ELEMENT_RIGHT_CLICK, this.onElementRightClicked, false);
    document.addEventListener(PR_EVENT_ELEMENT_SELECTED, this.onElementSelected);
    document.addEventListener(PR_EVENT_REPORT_WARNING_POPUP_OPENED, this.onReportWarningPopup);
    document.addEventListener(
      PR_EVENT_REPORT_BLOCKED_WARNING_POPUP_OPENED,
      this.onReportBlockedWarningPopup,
    );
    document.addEventListener(PR_EVENT_SEND_REPORT_CLICKED, this.onSendReportClicked);
    document.addEventListener(PR_EVENT_DOWNLOAD_REPORT_CLICKED, this.onDownloadReportClicked);
    document.addEventListener(PR_EVENT_PEOPLE_WIDGET_LOAD_MORE_TRIGGERED, this.onLoadMoreSessions);
    document.addEventListener(PR_EVENT_WIDGET_PASTED, this.onWidgetPasted);
    document.addEventListener(PR_EVENT_REPORT_STYLE, this.onStyleReportClicked);
    document.addEventListener(PR_EVENT_REPORT_STYLE_UPDATED, this.onStyleReportUpdated);
    document.addEventListener(PR_EVENT_ON_PHOTO_CHANGED, this.onPhotoObjectChanged);
    document.addEventListener(RP_EVENT_INFO_MESSAGE, this.onInfoMessageReceived);
  }

  componentWillUnmount() {
    document.removeEventListener(PR_EVENT_OBJECT_SELECTED, this.onObjectSelected, false);
    document.removeEventListener(RP_EVENT_ERROR_MESSAGE, this.errorMessageHandler, false);
    document.removeEventListener(
      PR_EVENT_CONNECTION_IN_EMPTY_SPACE,
      this.onConnectionInEmptySpace,
      false,
    );
    document.removeEventListener(
      RP_EVENT_CANCEL_NEW_STEP_FROM_CONNECTION,
      this.onCancelNewStepFromConnection,
      false,
    );
    document.removeEventListener(PR_EVENT_ELEMENT_POINTER_UP, this.onElementPointerUp, false);
    document.removeEventListener(RP_EVENT_ELEMENT_RIGHT_CLICK, this.onElementRightClicked, false);
    document.removeEventListener(PR_EVENT_ELEMENT_SELECTED, this.onElementSelected);
    document.removeEventListener(PR_EVENT_REPORT_WARNING_POPUP_OPENED, this.onReportWarningPopup);
    document.removeEventListener(
      PR_EVENT_REPORT_BLOCKED_WARNING_POPUP_OPENED,
      this.onReportBlockedWarningPopup,
    );
    document.removeEventListener(PR_EVENT_DOWNLOAD_REPORT_CLICKED, this.onDownloadReportClicked);
    document.removeEventListener(
      PR_EVENT_PEOPLE_WIDGET_LOAD_MORE_TRIGGERED,
      this.onLoadMoreSessions,
    );
    document.removeEventListener(PR_EVENT_WIDGET_PASTED, this.onWidgetPasted);
    document.removeEventListener(PR_EVENT_REPORT_STYLE, this.onStyleReportClicked);
    document.removeEventListener(PR_EVENT_REPORT_STYLE_UPDATED, this.onStyleReportUpdated);
    document.removeEventListener(PR_EVENT_ON_PHOTO_CHANGED, this.onPhotoObjectChanged);
  }

  render() {
    const { funnel, userEmail, messageErrorData, isMessageErrorShowing } = this.props;
    const {
      toolbarData,
      type,
      toolbarMultiSelect,
      toolbarOpened,
      isShowStep,
      reportBlockedWarningModalOpened,
      reportWarningModalOpened,
      titleSendReportPopup,
      isShowSendReportPopup,
      isRightMouse,
      isShowStyleReportPopup,
      reportStyleSlide,
    } = this.state;
    const isShowToolbar = toolbarOpened && isShowStep === false;
    const renderToolbar = () => {
      if (isShowToolbar) {
        return (
          <>
            <Menus
              elementData={this.state}
              funnel={funnel}
              position={toolbarData.coords}
              sectionStyle={toolbarData.sectionStyle}
              stepId={toolbarData.stepId}
              iconType={type}
              isNumbersActive={this.props.funnelConfiguration.visibleDataLayers[LayerType.NUMBERS]}
              toolbarData={toolbarData}
              onConnectionDrawLineTypeChanged={this.onConnectionDrawLineTypeChanged}
              onConnectionLineTypeChanged={this.onConnectionLineTypeChanged}
              isRightMouse={isRightMouse}
              isMultiSelect={toolbarMultiSelect}
            />
          </>
        );
      }

      return null;
    };

    return (
      <div className={styles.Wrapper}>
        <When condition={reportBlockedWarningModalOpened}>
          <ClickOutsideCustomComponent
            onClickOutside={() => {
              this.setStatusReportBlockedWarningPopup(false);
            }}
          >
            <ClosePopup
              onCancel={() => {
                this.setStatusReportBlockedWarningPopup(false);
                commonSendEventFunction(PR_EVENT_REPORT_WARNING_POPUP_OK_BTN_CLICKED, {
                  value: false,
                });
              }}
            >
              {POPUP_BLOCKED_MESSAGE}
            </ClosePopup>
          </ClickOutsideCustomComponent>
        </When>
        <When condition={reportWarningModalOpened}>
          <ClickOutsideCustomComponent
            onClickOutside={() => {
              this.seStatusReportWarningPopup(false);
            }}
          >
            <ConfirmationPopup
              onCancel={() => {
                this.seStatusReportWarningPopup(false);
                commonSendEventFunction(PR_EVENT_REPORT_WARNING_POPUP_OK_BTN_CLICKED, {
                  value: false,
                });
              }}
              onAccept={() => {
                this.seStatusReportWarningPopup(false);
                const { reportWarningModalSlide: isSlide } = this.state;
                commonSendEventFunction(PR_EVENT_REPORT_WARNING_POPUP_OK_BTN_CLICKED, {
                  value: true,
                  isSlide,
                });
              }}
            >
              {POPUP_MESSAGE}
            </ConfirmationPopup>
          </ClickOutsideCustomComponent>
        </When>
        {userEmail && <LoadHubspotChat email={userEmail} />}
        {renderToolbar()}
        {isMessageErrorShowing ? (
          <ErrorMessageContainer
            msgData={messageErrorData}
            onClose={() => this.errorMessageSetter(false)}
          />
        ) : null}
        <NotificationAlert />
        {isShowSendReportPopup && (
          <SendReportPopup
            title={titleSendReportPopup}
            setSendReportStatus={this.setSendReportStatus}
            isNumbersActive={this.props.funnelConfiguration.visibleDataLayers[LayerType.NUMBERS]}
          />
        )}
        {isShowStyleReportPopup && (
          <StyleReportPopup
            onStyleReportPopupClose={this.onStyleReportPopupClose}
            reportSlide={reportStyleSlide}
          />
        )}
        <When condition={this.state.infoMessage}>
          <InfoPopup onClose={this.onInfoClose} message={this.state.infoMessage} />
        </When>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    funnel: selectFunnel(state, props.funnelId),
    userEmail: selectEmail(state),
    sessions: selectSessions(state),
    countries: selectProfileCountries(state),
    funnelConfiguration: selectFunnelConfiguration(state),
    dataObjs: selectDataObjs(state),
    dataConnections: selectDataConnections(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSetNewCurrentStep: (newValue) => dispatch(setNewCurrentStep(newValue)),
    onSetTextStyle: (newValue) => dispatch(setTextStyle(newValue)),
    onDownloadButtonClicked: (reportData) => dispatch(downloadReportRequestAction({ reportData })),
    onLoadMoreSessions: ({
      projectId,
      funnelConfiguration,
      dataObjs,
      dataConnections,
      compareMode,
      last,
    }) =>
      dispatch(
        loadMoreSessionsAsync({
          projectId,
          funnelConfiguration,
          dataObjs,
          dataConnections,
          compareMode,
          last,
        }),
      ),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Main);
