import * as PIXI from 'pixi.js';
import Utils from 'pixi-project/utils/Utils';
import { PR_EVENT_OBJECT_SELECTED } from 'shared/CSharedEvents';
import { COLOR_SELECTION, COLOR_MULTIPLE_SELECTION_RECTANGLE } from 'pixi-project/view/Styles';
import { SELECTION_ROUND_CORNER } from '../Styles';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { EStepConnectionPort } from 'shared/CSharedCategories';
import { CControlPointTypes } from 'pixi-project/base/containers/CContainerConstants';
import ResizeHandle from './ResizeHandle';
import MainStorage from 'pixi-project/core/MainStorage';
export const MULTIPLE_SELECTION_BOUNDS_WIDTH = 2;
export const MULTIPLE_SELECTION_WIDTH = 2;
export const MULTIPLE_SELECTION_RECTANGLE_OPACITY = 0.5;

export const EMSDrawTypes = {
  SELECTION: 'SELECTION',
  BOUNDS: 'BOUNDS',
};

const HANDLE_SIZE = 10;
const NUMBER_OF_HANDLES = 4;

// DELEGATES
// - onMultiSelectionFrameDraw(frame)
// - onMultiResizeStarted(startFrame)
// - onMultiResizeChanged(startFrame,endFrame)
// - onMultiResizeEnded(startFrame,endFrame)

export default class MultipleSelection extends PIXI.Container {
  constructor(onHeadsDown, delegate) {
    super();
    this.sourcePoint = null;
    this.targetPoint = null;

    this.boundsStart = { x: 0, y: 0 };
    this.boundsEnd = { x: 0, y: 0 };

    this.delegate = delegate;
    this.drawFrame = false;
    this.drawSelection = false;
    this.selectedObjects = [];
    this.sectionStyle = { x: 0, y: 0, width: 0, height: 0 };
    this.isRightMouse = false;

    this.onHeadsDown = onHeadsDown;
    this.headIn = null;
    this.headOut = null;
    this.canResize = true;
    this.resizeHandles = [];
    this.handlesConf = [
      { x: 0, y: 0, cursor: 'nwse-resize', xMul: 1, yMul: 1, widthMul: -1, heightMul: -1 }, // top left
      { x: 1, y: 0, cursor: 'nesw-resize', xMul: 0, yMul: 1, widthMul: 1, heightMul: -1 }, // top right
      { x: 1, y: 1, cursor: 'nwse-resize', xMul: 0, yMul: 0, widthMul: 1, heightMul: 1 }, // bottom right
      { x: 0, y: 1, cursor: 'nesw-resize', xMul: 1, yMul: 0, widthMul: -1, heightMul: 1 }, // bottom left
    ];
    this.currentSelectionFrame = new PIXI.Rectangle();
    this.startResizeSelectionFrame = new PIXI.Rectangle();
    this.startResizePos = new PIXI.Point();

    this.init();
  }

  init() {
    this.frame = new PIXI.Graphics();
    this.addChild(this.frame);
    this.isDraggingStarted = false;
    this.isSelecting = false;
    this.drawArrows();
    if (this.canResize) {
      this.createResizeHandles();
    }

    this.visible = false;
  }

  draw() {
    this.frame.clear();
    this.showResizeHandles(false);

    this.setVisibilityArrows(false);

    if (this.drawSelection && this.targetPoint) {
      this.frame.lineStyle(
        MULTIPLE_SELECTION_WIDTH,
        COLOR_MULTIPLE_SELECTION_RECTANGLE,
        MULTIPLE_SELECTION_RECTANGLE_OPACITY,
      );
      this.frame.beginFill(
        COLOR_MULTIPLE_SELECTION_RECTANGLE,
        MULTIPLE_SELECTION_RECTANGLE_OPACITY,
      );
      this.frame.drawRect(0, 0, this.targetPoint.x - this.x, this.targetPoint.y - this.y);
    }

    if (this.drawFrame && this.boundsStart && this.boundsEnd) {
      this.currentSelectionFrame.set(
        this.boundsStart.x - this.x,
        this.boundsStart.y - this.y,
        this.boundsEnd.x - this.boundsStart.x,
        this.boundsEnd.y - this.boundsStart.y,
      );

      this.frame.lineStyle(MULTIPLE_SELECTION_BOUNDS_WIDTH, COLOR_SELECTION);
      this.frame.beginFill(COLOR_MULTIPLE_SELECTION_RECTANGLE, 0);
      this.frame.drawRoundedRect(
        this.currentSelectionFrame.x,
        this.currentSelectionFrame.y,
        this.currentSelectionFrame.width,
        this.currentSelectionFrame.height,
        SELECTION_ROUND_CORNER,
      );

      if (this.canResize && !MainStorage.getCanvasPermissions().isReadonlyAccess) {
        //TODO check selection content if we should really show
        this.showResizeHandles(true);
        this.positionHandles();
      }

      if (this.delegate && this.delegate.onMultiSelectionFrameDraw) {
        this.delegate.onMultiSelectionFrameDraw(this.frame);
      }
    }

    if (!this.visible) {
      this.visible = true;
    }
  }

  createResizeHandles() {
    this.resizeHandles = [];
    for (let i = 0; i < NUMBER_OF_HANDLES; i++) {
      const handle = this.createHandle(i);
      this.frame.addChild(handle);
      this.resizeHandles.push(handle);
    }
  }

  createHandle(index) {
    const handle = new ResizeHandle(HANDLE_SIZE, COLOR_SELECTION, this);
    handle.cursor = this.handlesConf[index].cursor;
    handle.index = index;
    return handle;
  }

  showResizeHandles(visible) {
    for (let i = 0; i < this.resizeHandles.length; i++) {
      const handle = this.resizeHandles[i];
      handle.visible = visible;
    }
  }

  positionHandles() {
    for (let i = 0; i < this.resizeHandles.length; i++) {
      const handle = this.resizeHandles[i];
      const conf = this.handlesConf[handle.index];
      // { x: 0, y: 0, cursor: 'nwse-resize', xMul: 1, yMul: 1, widthMul: -1, heightMul: -1 },  // top left
      handle.x = this.currentSelectionFrame.x + this.currentSelectionFrame.width * conf.x;
      handle.y = this.currentSelectionFrame.y + this.currentSelectionFrame.height * conf.y;
    }
  }

  onHandleDown(event, handle) {
    this.startResizeSelectionFrame.copyFrom(this.currentSelectionFrame);
    this.startResizeSelectionFrame.x += this.x;
    this.startResizeSelectionFrame.y += this.y;
    this.startResizePos.copyFrom(event.data.global);

    if (this.delegate && this.delegate.onMultiResizeStarted) {
      this.delegate.onMultiResizeStarted(this.startResizeSelectionFrame);
    }
  }

  onHandleMove(event, handle) {
    let x = event.data.global.x - this.startResizePos.x;
    let y = event.data.global.y - this.startResizePos.y;
    const conf = this.handlesConf[handle.index];

    // Apply scaling limit and keep aspect ratio
    if (this.delegate && this.delegate.getScaleLimit) {
      const scaleLimit = this.delegate.getScaleLimit();

      let newScaleX =
        (this.startResizeSelectionFrame.width + x * conf.widthMul) /
        this.startResizeSelectionFrame.width;
      let newScaleY =
        (this.startResizeSelectionFrame.height + y * conf.heightMul) /
        this.startResizeSelectionFrame.height;

      // Keep aspect ratio
      if (newScaleX > newScaleY) {
        newScaleY = newScaleX;
      } else {
        newScaleX = newScaleY;
      }

      if (newScaleX < scaleLimit.minScale) {
        // Apply min scale
        x =
          (scaleLimit.minScale * this.startResizeSelectionFrame.width -
            this.startResizeSelectionFrame.width) /
          conf.widthMul;
      } else if (newScaleX > scaleLimit.maxScale) {
        // Apply max scale
        x =
          (scaleLimit.maxScale * this.startResizeSelectionFrame.width -
            this.startResizeSelectionFrame.width) /
          conf.widthMul;
      } else {
        // Apply aspect ratio
        x =
          (newScaleX * this.startResizeSelectionFrame.width -
            this.startResizeSelectionFrame.width) /
          conf.widthMul;
      }

      if (newScaleY < scaleLimit.minScale) {
        // Apply min scale
        y =
          (scaleLimit.minScale * this.startResizeSelectionFrame.height -
            this.startResizeSelectionFrame.height) /
          conf.heightMul;
      } else if (newScaleY > scaleLimit.maxScale) {
        // Apply max scale
        y =
          (scaleLimit.maxScale * this.startResizeSelectionFrame.height -
            this.startResizeSelectionFrame.height) /
          conf.heightMul;
      } else {
        // Apply aspect ratio
        y =
          (newScaleY * this.startResizeSelectionFrame.height -
            this.startResizeSelectionFrame.height) /
          conf.heightMul;
      }
    }

    const _x = this.startResizeSelectionFrame.x + x * conf.xMul;
    const _y = this.startResizeSelectionFrame.y + y * conf.yMul;
    const _width = this.startResizeSelectionFrame.width + x * conf.widthMul;
    const _height = this.startResizeSelectionFrame.height + y * conf.heightMul;

    this.currentSelectionFrame.set(_x, _y, _width, _height);

    if (this.delegate && this.delegate.onMultiResizeChanged) {
      this.delegate.onMultiResizeChanged(
        this.startResizeSelectionFrame,
        this.currentSelectionFrame,
      );
    }
  }

  onHandleUp(event, handle) {
    // Resize Ended
    if (this.delegate && this.delegate.onMultiResizeEnded) {
      this.delegate.onMultiResizeEnded(this.startResizeSelectionFrame, this.currentSelectionFrame);
    }
  }

  drawArrows() {
    // todo Why are we using SVGs here. Are we using them at all
    const leftArrowHead = PIXI.Texture.from(`${process.env.PUBLIC_URL}/asset/leftArrowHead.svg`);
    const rightArrowHead = PIXI.Texture.from(`${process.env.PUBLIC_URL}/asset/rightArrowHead.svg`);

    this.headsContainer = new PIXI.Container();
    this.addChild(this.headsContainer);

    this.headIn = new PIXI.Sprite(leftArrowHead);
    this.headIn.name = EStepConnectionPort.IN;
    this.headIn.type = CControlPointTypes.LEFT;
    this.headIn.anchor.set(0.5);
    this.headIn.interactive = true;
    this.headIn.buttonMode = true;
    this.headIn.cursor = 'grab';
    this.headsContainer.addChild(this.headIn);

    this.headOut = new PIXI.Sprite(rightArrowHead);
    this.headOut.name = EStepConnectionPort.OUT;
    this.headOut.type = CControlPointTypes.RIGHT;
    this.headOut.anchor.set(0.5);
    this.headOut.interactive = true;
    this.headOut.buttonMode = true;
    this.headOut.cursor = 'grab';
    this.headsContainer.addChild(this.headOut);

    this.headsContainer.interactive = true;
    this.headsContainer.visible = false;

    this.headIn.on('pointerdown', this.onHeadsDown);

    this.headOut.on('pointerdown', this.onHeadsDown);
  }

  onElementPointerOut(e) {
    this.onElementPointerUp(e);
  }

  getSelectionBounds() {
    let result = null;
    if (this.sourcePoint && this.targetPoint) {
      const rect = Utils.getBoundingRect([
        this.sourcePoint.x,
        this.sourcePoint.y,
        this.targetPoint.x,
        this.targetPoint.y,
      ]);

      result = new PIXI.Rectangle(rect.left, rect.top, rect.width, rect.height);
    }

    return result;
  }

  _setSourcePoint(x, y) {
    this.sourcePoint = {
      x: x,
      y: y,
    };
    this.x = this.sourcePoint.x;
    this.y = this.sourcePoint.y;
  }

  _setTargetPoint(x, y) {
    this.targetPoint = {
      x: x,
      y: y,
    };
  }

  hideToolbar() {
    this.notifyObjectSelected(false);
  }

  showToolbar() {
    this.notifyObjectSelected(true);
  }

  setSelectionBounds(bounds) {
    this.boundsStart = { x: bounds.left, y: bounds.top };
    this.boundsEnd = { x: bounds.left + bounds.width, y: bounds.top + bounds.height };
  }

  resetSelection() {
    this.sourcePoint = null;
    this.targetPoint = null;
    this.boundsStart = null;
    this.boundsEnd = null;
    this.frame.clear();
  }

  hideBounds() {
    if (this.visible) {
      this.visible = false;
    }
  }

  setVisibilityArrows(status) {
    this.headsContainer.visible = status;
  }

  show(showToolbar = true) {
    this.positionArrow();

    if (showToolbar) {
      this.setVisibilityArrows(true);
    }
  }

  updateArrowHeads(visible) {
    this.headIn.visible = visible;
    this.headOut.visible = visible;
  }

  positionArrow() {
    this.headIn.x = this.boundsStart.x - this.x;
    this.headIn.y = this.boundsStart.y - this.y + (this.boundsEnd.y - this.boundsStart.y) / 2;

    this.headOut.x = this.boundsEnd.x - this.x;
    this.headOut.y = this.boundsEnd.y - this.y - (this.boundsEnd.y - this.boundsStart.y) / 2;

    this.points = [this.headIn, this.headOut];
  }

  updateFrame(showToolbar = true) {
    this.show(showToolbar);
  }

  /**
   * Sets a point from which we position the toolbar for an element
   */
  setToolbarPositionPoint() {
    if (this.boundsStart && this.boundsEnd) {
      const rect = Utils.getBoundingRect([
        this.boundsStart.x,
        this.boundsStart.y,
        this.boundsEnd.x,
        this.boundsEnd.y,
      ]);
      this.positionPoint = new PIXI.Point(rect.left + rect.width / 2, rect.top + rect.height);
      this.sectionStyle.x = rect.left;
      this.sectionStyle.y = rect.top;
      this.sectionStyle.width = rect.width;
      this.sectionStyle.height = rect.height;
    }
  }

  notifyObjectSelected(show, homogeneous = null) {
    if (!this.positionPoint) {
      return;
    }

    const position = this.positionPoint;
    position.x *= window.app.scaleManager.aspectRatio;
    position.y *= window.app.scaleManager.aspectRatio;
    const data = {};
    data.position = { x: position.x, y: position.y };
    data.sectionStyle = {
      x: this.sectionStyle.x,
      y: this.sectionStyle.y,
      width: this.sectionStyle.width * window.app.scaleManager.aspectRatio,
      height: this.sectionStyle.height * window.app.scaleManager.aspectRatio,
    };
    data.show = show;
    data.multiSelect = true;

    if (homogeneous) {
      let keys = Object.keys(homogeneous);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        data[key] = homogeneous[key];
      }
    }

    commonSendEventFunction(PR_EVENT_OBJECT_SELECTED, data);
  }

  onViewportDown(e) {
    let point = e.data.global;
    this._setSourcePoint(point.x, point.y);
    this._setTargetPoint(point.x, point.y);
  }

  onViewportMove(e) {
    let point = e.data.global;
    if (this.isSelecting) {
      this._setTargetPoint(point.x, point.y);
    }
  }

  onViewportUp(e) {
    if (this.isSelecting) {
      let point = e.data.global;
      this._setTargetPoint(point.x, point.y);
      this.clearSelectionFrame();
    }
    this.isDraggingStarted = false;
  }

  clearSelectionFrame() {
    this.isSelecting = false;
    this.frame.clear();
    this.isDraggingStarted = false;
  }

  onViewportOut() {}
}
