import { EElementCategories, EElementTypes } from 'shared/CSharedCategories';

export default class SharedElementHelpers {
  static IsStep(element) {
    return element.category === EElementCategories.STEP;
  }

  static IsAction(element) {
    return element.category === EElementCategories.STEP && element.type === EElementTypes.EVENT;
  }

  static IsPage(element) {
    return element.category === EElementCategories.STEP && element.type === EElementTypes.PAGE;
  }

  static IsSource(element) {
    return element.category === EElementCategories.STEP && element.type === EElementTypes.SOURCE;
  }

  static IsMisc(element) {
    return element.category === EElementCategories.STEP && element.type === EElementTypes.MISC;
  }

  static IsConnection(element) {
    return element.category === EElementCategories.CONNECTION;
  }

  static IsText(element) {
    return element.category === EElementCategories.TEXT;
  }

  static IsPhoto(element) {
    return element.category === EElementCategories.PHOTO;
  }

  static IsShape(element) {
    return element.category === EElementCategories.SHAPE;
  }

  static IsReport(element) {
    return element.category === EElementCategories.REPORT;
  }

  static IsTextOrShapeElements(element) {
    return (
      element && (SharedElementHelpers.IsShape(element) || SharedElementHelpers.IsText(element))
    );
  }

  static IsWidget(element) {
    return element.category === EElementCategories.WIDGET;
  }

  static IsTrendsWidget(element) {
    return (
      element.category === EElementCategories.WIDGET && element.type === EElementTypes.WIDGET_TRENDS
    );
  }

  static IsPurchaseAction(element) {
    return SharedElementHelpers.IsAction(element) && element.actionType === 'commerce';
  }

  static IsMeetingAction(element) {
    //TODO later on implement how the determine if an action if of this type , this is just temporary
    return SharedElementHelpers.IsAction(element) && element.actionName === 'Meeting Scheduled';
  }

  static IsDealAction(element) {
    //TODO later on implement how the determine if an action if of this type , this is just temporary
    return SharedElementHelpers.IsAction(element) && element.actionType === 'deal';
  }

  static IsFormAction(element) {
    return SharedElementHelpers.IsAction(element) && element.actionName === 'Form Completed';
  }

  /**
   * Tries to insert a report following the rules:
   * Start from the bootom displayed objects and place it behind the first non-report object
   * @param {ShapeContainerDrawable} shape
   * @param {Array} objects
   * @param {PIXI.DisplayObject} parent
   */
  static InsertReport(shape, objects, parent) {
    for (let i = 0; i < parent.children.length; i++) {
      const sibling = parent.children[i];

      if (!SharedElementHelpers.IsReport(sibling)) {
        parent.addChildAt(shape, i);

        for (let j = 0; j < objects.length; j++) {
          const siblingObject = objects[j];
          if (!SharedElementHelpers.IsReport(siblingObject)) {
            objects.splice(j, 0, shape);
            break;
          }
        }

        return;
      }
    }
    // If there was no case to be inserted
    // then just place the object
    parent.addChild(shape);
    objects.push(shape);
  }

  /**
   * Tries to insert a shape following the rules:
   * Start from the bootom displayed objects and place it behind the first non-shape object
   * Also ignore reports
   * @param {ShapeContainerDrawable} shape
   * @param {Array} objects
   * @param {PIXI.DisplayObject} parent
   */
  static InsertShape(shape, objects, parent) {
    for (let i = 0; i < parent.children.length; i++) {
      const sibling = parent.children[i];

      if (!SharedElementHelpers.IsShape(sibling) && !SharedElementHelpers.IsReport(sibling)) {
        parent.addChildAt(shape, i);

        for (let j = 0; j < objects.length; j++) {
          const siblingObject = objects[j];
          if (
            !SharedElementHelpers.IsShape(siblingObject) &&
            !SharedElementHelpers.IsReport(sibling)
          ) {
            objects.splice(j, 0, shape);
            break;
          }
        }

        return;
      }
    }
    // If there was no case to be inserted
    // then just place the object
    parent.addChild(shape);
    objects.push(shape);
  }

  /**
   * Tries to insert a text following the rules:
   * Start from the bootom displayed objects and place it behind the first non-shape && non-text object && non-report
   * @param {TextLabel} textObject
   * @param {Array} objects
   * @param {PIXI.DisplayObject} parent
   */
  static InsertText(textObject, objects, parent) {
    for (let i = 0; i < parent.children.length; i++) {
      const sibling = parent.children[i];

      if (
        !SharedElementHelpers.IsTextOrShapeElements(sibling) &&
        !SharedElementHelpers.IsReport(sibling)
      ) {
        parent.addChildAt(textObject, i);

        for (let j = 0; j < objects.length; j++) {
          const siblingObject = objects[j];
          if (
            !SharedElementHelpers.IsTextOrShapeElements(siblingObject) &&
            !SharedElementHelpers.IsReport(sibling)
          ) {
            objects.splice(j, 0, textObject);
            break;
          }
        }

        return;
      }
    }
    // If there was no case to be inserted
    // then just place the object
    parent.addChild(textObject);
    objects.push(textObject);
  }
}
