import React, { useRef, useState } from 'react';
import * as ReactDOM from 'react-dom';
import styles from 'react-project/Toolbar/Toolbar.module.scss';
import uploaderStyles from 'react-project/components/fileUploader/FileUploader.module.scss';
import { v4 as uuidv4 } from 'uuid';

import { When } from 'react-project/Util/When';
import { PopUpWrapper } from 'react-project/components/popUp/PopUpWrapper';
import { iconHorizontalArrows, iconRefreshBtn } from 'react-project/assets/Icons';
import { EElementTypes } from 'shared/CSharedCategories';
import RequestService from 'react-project/Helpers/RequestService';
import { addPhotoToLibrary, uploadPhotoToS3, verifyPhoto } from 'react-project/Util/PhotoHelper';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import { useSelector } from 'react-redux';

export const FileUploader = (props) => {
  const {
    onFileUploaderClose,
    uploadType,
    onFileUploaded = () => {},
    onFileUploadFailed = () => {},
    position,
  } = props;

  const [isUploading, setIsUploading] = useState(false);
  const inputFileHTMLRef = useRef();
  const canvasPermissions = useSelector(selectCanvasPermissions);

  const onClose = () => {
    if (!isUploading) {
      onFileUploaderClose();
    }
  };

  const setFile = (file) => {
    if (verifyPhoto(file, onFileUploadFailed)) {
      setIsUploading(true);
      uploadPhotoToS3(file, (result) => {
        setIsUploading(false);
        addPhotoToLibrary(
          result.fileName,
          result.imageURL,
          result.fileID,
          uploadType,
          onFileUploaded,
        );
      });
    }
  };

  const determineUploadDetails = (type) => {
    // EElementTypes
    if (type === EElementTypes.PAGE) {
    }
  };

  const onUploadClick = () => {
    if (canvasPermissions.isTutorial) {
      return;
    }

    inputFileHTMLRef.current.click();
  };

  determineUploadDetails(uploadType);

  return ReactDOM.createPortal(
    <PopUpWrapper
      icon={iconHorizontalArrows}
      title="File Uploader"
      showCloseIcon={true}
      onClosePopup={() => onClose()}
      cancel={styles.StrippedTableRow}
      minWidth="400px"
      height="260px"
      position={position || { x: window.innerWidth / 2 - 400 / 2, y: 0 }}
      className="stop-event"
    >
      <div className={styles.TrackingTab}>
        <div className={uploaderStyles.container}>
          <When condition={isUploading}>
            <div className={uploaderStyles.loader}>{iconRefreshBtn}</div>
          </When>
          <When condition={canvasPermissions.isTutorial}>
            <p style={{ maxWidth: '300px', textAlign: 'center' }}>
              Uploading images is not allowed in tutorial workspace, but you can do that in your own
              workspace
            </p>
          </When>
          <When condition={!isUploading && !canvasPermissions.isTutorial}>
            <p>Please select a JPEG or PNG file under 5MB in size</p>
            <div onClick={onUploadClick} className={uploaderStyles.UploadButton}>
              Click here to choose an image
            </div>
            <input
              ref={inputFileHTMLRef}
              style={{ display: 'none' }}
              type="file"
              name="file"
              onChange={(e) => setFile(e.target.files[0])}
            />
          </When>
        </div>
      </div>
    </PopUpWrapper>,
    window.document.body,
  );
};
