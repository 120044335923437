import { combineReducers } from '@reduxjs/toolkit';
import { tutorialReducer } from 'react-project/Header/tutorialSlice';
import { canvasSharing } from 'react-project/Sharing/shareCanvasSlice';
import { peopleTrackingReducer } from 'react-project/Toolbar/step-toolbar/PeopleTracking/peopleTrackingSlice';
import { facebookIntegrationReducer } from '../features/facebookIntegration';
import analytics from './analytics/reducer';
import auth from './auth/reducer';
import currentStep from './current-step/reducer';
import customIcons from './custom-icons/reducer';
import explorer from './explorer/reducer';
import filters from './filters/reducer';
import focusedStep from './focused-step/reducer';
import funnelConfiguration from './funnel-configuration/reducer';
import funnelRevisions from './funnel-revisions/reducer';
import funnels from './funnels/reducer';
import inputs from './inputs/reducer';
import modal from './modal/reducer';
import notes from './notes/reducer';
import notificationAlerts from './notification-alerts/reducer';
import popups from './popups/reducer';
import report from './report/reducer';
import user from './user/reducer';
import selectedElements from './selected-elements/reducer';
import checklist from './checklist/reducer';
import uiState from './ui-state/reducer';

export default combineReducers({
  facebookIntegration: facebookIntegrationReducer,
  peopleTracking: peopleTrackingReducer,
  tutorial: tutorialReducer,
  funnelConfiguration, //NOTE we keep track of filter state also in the the funnel configuration
  notificationAlerts,
  funnelRevisions,
  currentStep,
  focusedStep,
  analytics,
  explorer,
  funnels,
  filters,
  report,
  inputs,
  modal,
  popups,
  user,
  auth,
  notificationAlerts,
  customIcons,
  notes,
  canvasSharing,
  selectedElements,
  checklist,
  uiState,
});
