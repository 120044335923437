import React from 'react';
import { DropdownExplorer } from 'react-project/components/dropdown/DropdownExplorer';
import { EExplorerConfigTypes, EStepConnectionPort } from 'shared/CSharedCategories';
import { EXPLORER_ITEMS, EXPLORER_TABS } from 'shared/CSharedConstants';

export const HeaderTitle = ({
  param,
  onSelectDropdownExplorerItem,
  explorerDropdownActiveItem,
  port,
  sourceDropdown,
  onSelectDropdownTrafficItem,
}) => {
  switch (param) {
    case EExplorerConfigTypes.PAGE_ALL:
    case EXPLORER_TABS.PAGE:
      return (
        <div>
          <DropdownExplorer
            items={
              port === EStepConnectionPort.IN
                ? EXPLORER_ITEMS.PREVIOUS_PAGES_ITEMS
                : EXPLORER_ITEMS.NEXT_PAGES_ITEMS
            }
            onSelectItem={onSelectDropdownExplorerItem}
            activeItem={explorerDropdownActiveItem}
          />
        </div>
      );
    case EExplorerConfigTypes.EVENT_ALL:
    case EXPLORER_TABS.EVENT:
      return (
        <div>
          <DropdownExplorer
            items={
              port === EStepConnectionPort.IN
                ? EXPLORER_ITEMS.PREVIOUS_ACTIONS_ITEMS
                : EXPLORER_ITEMS.NEXT_ACTIONS_ITEMS
            }
            onSelectItem={onSelectDropdownExplorerItem}
            activeItem={explorerDropdownActiveItem}
          />
        </div>
      );
    case EExplorerConfigTypes.SOURCE_ALL:
    case EXPLORER_TABS.SOURCE:
      return (
        <div>
          <DropdownExplorer
            items={EXPLORER_ITEMS.TRAFFIC_ITEMS}
            onSelectItem={onSelectDropdownTrafficItem}
            activeItem={sourceDropdown}
          />
        </div>
      );
  }
};

export default HeaderTitle;
