import React, { useState } from 'react';
import {
  POSITIONING_BOTTOM,
  VERTICAL_ALIGN_LEFT,
  ViewportConstrainer,
} from 'react-project/components/viewportConstrainer/ViewportConstrainer';
import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';
import styles from './ExportComponent.module.scss';
import cls from 'classnames';
import { Dropdown, DropdownFlatItems } from 'react-project/components/dropdown/Dropdown';
import { DropdownTrigger } from 'react-project/components/trackingTab/Attribute';
import { csvDownload, iconWarrning, pngDownload } from 'react-project/assets/Icons';
import { ANALYTICS_STATUS_LOADING } from 'shared/CSharedConstants';
import { When } from 'react-project/Util/When';
import {
  LOCK_NO_ACTIONS,
  LOCK_NO_ANALYTICS,
  LOCK_NO_CSV_EXPORT,
  UILock,
} from 'react-project/components/uilock/UILock';
import { useSelector } from 'react-redux';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';

const MAX_FREE_RESOLUTION = 2;

export const ExportComponent = ({
  onExportClose,
  onSaveToPNG,
  onExportElements,
  onExportConnections,
  btnRef,
  analyticsStatus,
}) => {
  const [isValueDropdownOpened, setIsValueDropdownOpened] = useState(false);
  const [propertyDropdownValue, setPropertyDropdownValue] = useState({
    label: 'x2',
    value: 2,
  });
  const canvasPermissions = useSelector(selectCanvasPermissions);

  const onSelectProperty = (property) => {
    setPropertyDropdownValue(property);
    setIsValueDropdownOpened(false);
  };

  const onExportClicked = (resolution) => {
    onExportClose();
    setTimeout(() => {
      onSaveToPNG(resolution);
    }, 100);
  };

  const onExportElementsClicked = () => {
    onExportClose();
    setTimeout(() => {
      onExportElements();
    }, 100);
  };

  const onExportConnectionsClicked = () => {
    onExportClose();
    setTimeout(() => {
      onExportConnections();
    }, 100);
  };

  const dropDownItemValues = [
    { label: 'x2', value: 2 },
    { label: 'x3', value: 3 },
    { label: 'x4', value: 4 },
    { label: 'x6', value: 6 },
    { label: 'x8', value: 8 },
  ];

  const isPNGExportLocked =
    propertyDropdownValue.value > MAX_FREE_RESOLUTION && !canvasPermissions.pngExportAllowed;

  return (
    <ViewportConstrainer
      positioning={POSITIONING_BOTTOM}
      relativeElement={btnRef.current}
      verticalAlign={VERTICAL_ALIGN_LEFT}
    >
      <ClickOutsideCustomComponent
        onClickOutside={(e) => {
          const el = e.target;
          if (el.id === 'export-canvas-btn' || el.closest('#export-canvas-btn')) return;

          onExportClose();
        }}
      >
        <div className={styles.Export}>
          <div className={styles.ExportBox}>
            <div className={cls(styles.Container)}>
              <div className={styles.Title}>PNG Export</div>
              <div className={styles.Option}>
                <div> Resolution </div>
                <div>
                  <ClickOutsideCustomComponent
                    onClickOutside={() => {
                      setIsValueDropdownOpened(false);
                    }}
                  >
                    <Dropdown
                      className={styles.AttributesDropdown}
                      triggerSlot={
                        <DropdownTrigger
                          value={propertyDropdownValue.label}
                          placeholder="Value"
                          isOpen={isValueDropdownOpened}
                        />
                      }
                      isOpen={isValueDropdownOpened}
                      onToggle={(o) => setIsValueDropdownOpened(o)}
                    >
                      <div>
                        <DropdownFlatItems
                          className={styles.AttributesDropdownItems}
                          list={dropDownItemValues}
                          onSelect={(el) => onSelectProperty(el)}
                        />
                      </div>
                    </Dropdown>
                  </ClickOutsideCustomComponent>
                </div>
                <UILock isLocked={isPNGExportLocked}>
                  <div
                    onClick={() => onExportClicked(propertyDropdownValue.value)}
                    className={styles.ExportButton}
                  >
                    Download
                  </div>
                </UILock>
              </div>
            </div>
            <div className={styles.Separator}></div>

            <div className={cls(styles.Container)}>
              <div className={styles.Title}>CSV Export</div>
              <div className={styles.Option}>
                <div> Canvas Steps (Elements) </div>
                <UILock lock={LOCK_NO_CSV_EXPORT}>
                  <div onClick={() => onExportElementsClicked()} className={styles.ExportButton}>
                    Download
                  </div>
                </UILock>
              </div>

              <div className={styles.Option}>
                <div> Canvas Conversions (Lines) </div>
                <UILock lock={LOCK_NO_CSV_EXPORT}>
                  <div onClick={() => onExportConnectionsClicked()} className={styles.ExportButton}>
                    Download
                  </div>
                </UILock>
              </div>
            </div>

            <When condition={analyticsStatus === ANALYTICS_STATUS_LOADING}>
              <div className={cls(styles.Note, styles.Container)}>
                {iconWarrning}
                <span> Please wait for Analytics to finish loading </span>
              </div>
            </When>
          </div>
        </div>
      </ClickOutsideCustomComponent>
    </ViewportConstrainer>
  );
};
