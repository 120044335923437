import React from 'react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';
import colors from 'tailwindcss/colors';

export function ChevronToggle({ open, style, onClick }) {
  const ChevronRight = ({ style, onClick }) => (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="9"
      viewBox="0 0 7 9"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.62875 8.53613L0.734375 7.54327L4.07267 4.53613L0.734375 1.52899L1.62875 0.536133L6.06924 4.53613L1.62875 8.53613Z"
        fill="#636E84"
        style={style}
        onClick={onClick}
      />
    </svg>
  );

  const ChevronDown = ({ style, onClick }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      viewBox="0 0 9 6"
      height="6"
      fill="none"
      style={style}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.711426 0.909996L1.70429 0.015625L4.71143 3.35392L7.71856 0.015625L8.71143 0.909996L4.71143 5.35049L0.711426 0.909996Z"
        fill="#636E84"
        style={style}
        onClick={onClick}
      />
    </svg>
  );

  const iconStyles = {
    stroke: colors.slate['500'],
    pointerEvents: 'none',
    // width: 'none',
    // height: 'none',
  };

  return (
    <div onClick={onClick} className="TEST1234">
      {open ? (
        <ChevronDown style={iconStyles} onClick={onClick} />
      ) : (
        <ChevronRight style={iconStyles} onClick={onClick} />
      )}
    </div>
  );
}

export default ChevronToggle;
