import RequestService from 'react-project/Helpers/RequestService';

const requestService = new RequestService();

const generateKeyForActionsExistsParam = (projectId) =>
  `are-workspace-actions-detected:${projectId}`;

const generateKeyForActionExistsParam = (projectId, name) =>
  `are-workspace-actions-detected:${projectId}:${name}`;

const checkCacheAnalyticsIsInstalled = (projectId) => {
  const actionsKey = generateKeyForActionsExistsParam(projectId);
  return Boolean(localStorage.getItem(actionsKey));
};

const checkCacheAnalyticsIsInstalledForAction = (projectId, name) => {
  const actionsKey = generateKeyForActionExistsParam(projectId, name);
  return Boolean(localStorage.getItem(actionsKey));
};

const setCacheAnalyticsInstalled = (projectId, hasCode, name) => {
  const actionsKey = name
    ? generateKeyForActionExistsParam(projectId, name)
    : generateKeyForActionsExistsParam(projectId);

  if (hasCode) {
    //TODO: consider to introduce the separate helper for localStorage
    localStorage.setItem(actionsKey, 'true');
  }
};

export const isAnalyticsInstalled = async (projectId, name) => {
  if (projectId) {
    const isCacheInstalled = name
      ? checkCacheAnalyticsIsInstalledForAction(projectId, name)
      : checkCacheAnalyticsIsInstalled(projectId);

    if (isCacheInstalled) {
      return isCacheInstalled;
    } else {
      const hasCode = name
        ? await requestService.checkActionExist(projectId, name)
        : await requestService.checkActionsExist(projectId);
      setCacheAnalyticsInstalled(projectId, hasCode, name);
      return hasCode;
    }
  }
};
