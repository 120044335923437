import React, { useState, useEffect, useRef } from 'react';
import { StepURLParamsTableRow } from './StepURLParamsTableRow';
import styles from 'react-project/Toolbar/Toolbar.module.scss';
import produce from 'immer';
import { DropDown } from 'react-project/components/dropdowncomponent/dropdown';
import { PlaneTable } from 'react-project/components/table/PlaneTable';
import {
  backwardCompatibleOperator,
  isMathOperator,
  transformToSupportedNumber,
} from './LogicalOperators';
import { cloneData } from 'shared/CSharedMethods';
import { useSelector } from 'react-redux';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';

export const EditableKeyValueTable = (props) => {
  const {
    pairsData,
    onKeyValueChanged,
    isExternalPointerDown,
    tableMinHeight,
    hasAutoFocus,
    availableOperators = [],
    plainTableStyles,
  } = props;

  const refInputKey = useRef();
  const refInputValue = useRef();
  const refDropDown = useRef();
  const refTableClosedMethod = useRef();
  const refPointerDownOutside = useRef();
  const canvasPermissions = useSelector(selectCanvasPermissions);
  // When the table is closed externaly ,
  // we will make an attempt to save any input data
  useEffect(() => {
    return () => {
      if (refTableClosedMethod.current) {
        refTableClosedMethod.current();
      }
    };
  }, []);

  // Update the contents
  useEffect(() => {
    const tc = createTableContent(pairsData);
    setTableContent(tc);
  }, [pairsData]);

  // isExternalPointerDown needs to be assigned a random value outside this component ,
  // it should be used to register mouse clicks outside this compnent
  useEffect(() => {
    if (refPointerDownOutside.current) {
      refPointerDownOutside.current();
    }
  }, [isExternalPointerDown]);

  const onEnterValue = () => {
    const key = refInputKey.current.value.trim();
    const value = refInputValue.current.value;
    const dropDownAPI = refDropDown.current;
    dropDownAPI.selectedItem;
    addKeyPair(key, value, dropDownAPI.selectedItem.value);
  };

  const validateKeyValue = (pairsData) => {
    const newPairsData = produce(pairsData, (draft) => {
      draft.forEach((d) => {
        if (isMathOperator(d.operator)) {
          d.value = transformToSupportedNumber(d.value);
        }
      });
    });
    onKeyValueChanged(newPairsData);
  };

  const addKeyPair = (key, value, operator) => {
    if (!validateItem(key, value)) {
      return false;
    }

    // this will remove the last comma and any whitespace after it
    let itemValue = value.replace(/,\s*$/, '').trim();
    const multipleValues = itemValue
      .split(',')
      .filter((itm) => itm !== '')
      .map((itm) => {
        return itm.trim();
      });

    if (multipleValues.length > 1) {
      itemValue = multipleValues;
    }

    const item = {
      key,
      value: itemValue,
      operator,
    };

    const newPairsData = produce(pairsData, (draft) => {
      draft.push(item);
    });

    validateKeyValue(newPairsData);
  };

  const validateItem = (key, value) => {
    if (key === '') {
      return false;
    }
    return true;
  };

  const onTableClosed = () => {
    onEnterValue();
  };

  const onRowChanged = (index, itemKey, itemValue, operator) => {
    const newPairsData = produce(pairsData, (draft) => {
      const draftItem = draft[index];
      draftItem.operator = operator;
      draftItem.key = itemKey;
      draftItem.value = itemValue;
      delete draftItem.contains;
    });

    validateKeyValue(newPairsData);
  };

  const onDeleteBtn = (index) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }
    const newPairsData = produce(pairsData, (draft) => {
      draft.splice(index, 1);
    });

    validateKeyValue(newPairsData);
  };

  const propertyDropdownValue = availableOperators.length ? cloneData(availableOperators[0]) : null;

  const createTableContent = (pairsData) => {
    let isValueFocused = false;
    let isKeyFocused = false;
    let isDropDownPressed = false;

    const onPointerDownOutside = (e) => {
      if (!isValueFocused && !isKeyFocused && !isDropDownPressed) {
        onEnterValue();
      }
    };

    refPointerDownOutside.current = onPointerDownOutside;

    const onKeyboardDown = (e) => {
      if (e.key === 'Enter') {
        onEnterValue();
      } else if (e.key === 'Tab') {
        isValueFocused = true;
      }
    };

    const onValueKeyboardDown = (e) => {
      if (e.key === 'Enter') {
        isValueFocused = false;
        onEnterValue();
      } else if (e.key === 'Tab') {
        e.stopPropagation();
        e.preventDefault();
        onEnterValue();
      }
    };

    const onValuePointerDown = (e) => {
      isValueFocused = true;
      e.stopPropagation();
    };

    const onKeyPointerDown = (e) => {
      isKeyFocused = true;
      e.stopPropagation();
    };

    const onKeyBlur = (e) => {
      isKeyFocused = false;

      if (!isValueFocused && !isDropDownPressed) {
        onEnterValue();
      }
    };

    const onValueBlur = (e) => {
      isValueFocused = false;

      if (isDropDownPressed || isKeyFocused) {
        // Take no action if we are pressing on the checkbox
      } else {
        onEnterValue();
      }
    };

    const onDropDownChanged = (item) => {
      propertyDropdownValue.label = item.label;
      propertyDropdownValue.value = item.value;
    };

    const itemsData = pairsData.map((item, index) => {
      const operator = backwardCompatibleOperator(item);
      return createItem(index, item.key, item.value === undefined ? '' : item.value, operator);
    });

    const onDropDownPointerDown = (e) => {
      e.stopPropagation();
      isDropDownPressed = true;
    };

    const addKeyItem = {
      key1: (
        <input
          autoFocus={hasAutoFocus}
          ref={refInputKey}
          onKeyDown={onKeyboardDown}
          onPointerDown={onKeyPointerDown}
          onBlur={onKeyBlur}
          type="text"
          placeholder="+add Key"
          className={styles.addItemKey}
          disabled={canvasPermissions.isReadonlyAccess}
        />
      ),
      key2: (
        <DropDown
          disabled={canvasPermissions.isReadonlyAccess}
          selectedItem={propertyDropdownValue}
          itemList={availableOperators}
          onSelectionChanged={onDropDownChanged}
          onPointerDown={onDropDownPointerDown}
          innerRef={refDropDown}
        ></DropDown>
      ),
      key3: (
        <input
          ref={refInputValue}
          onKeyDown={onValueKeyboardDown}
          onPointerDown={onValuePointerDown}
          onBlur={onValueBlur}
          type="text"
          placeholder="+add Values"
          className={styles.addItemValue}
          disabled={canvasPermissions.isReadonlyAccess}
        />
      ),
    };

    itemsData.push(addKeyItem);

    return {
      columnWidths: ['28%', '28%', '28%', '10%'],
      columnsNumber: 4,
      rowHeaders: {
        keys: [
          <div>Key</div>,
          <div style={{ textAlign: 'right' }}>&nbsp;</div>,
          <div style={{ textAlign: 'right' }}> Value</div>,
        ],
      },
      items: itemsData,
    };
  };

  const createItem = (index, itemKey, itemValue, operator) => {
    const item = {
      rowHtml: (
        <StepURLParamsTableRow
          index={index}
          operator={operator}
          onChange={onRowChanged}
          itemKey={itemKey}
          itemValue={itemValue}
          itemList={availableOperators}
        />
      ),
      hasDelete: true,
    };
    return item;
  };

  const initialTableContentState = createTableContent(pairsData);
  const [tableContent, setTableContent] = useState(initialTableContentState);

  refTableClosedMethod.current = onTableClosed;

  return (
    <PlaneTable
      rows={tableContent}
      onDeleteBtn={onDeleteBtn}
      bodyStyle={{ overflow: 'visible', minHeight: tableMinHeight }}
      styles={plainTableStyles}
    />
  );
};

EditableKeyValueTable.defaultProps = {
  position: { x: 0, y: 0 },
  hasAutoFocus: true,
};
