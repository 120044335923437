import cls from 'classnames';
import React from 'react';
import styles from './TextArea.module.scss';

export const TextArea = ({ className, disabled, ...props }) => {
  /**
   * @param {React.ChangeEvent<HTMLTextAreaElement>} e
   * @returns {void}
   */
  return (
    <textarea className={cls(styles.TextArea, className, disabled && styles.disabled)} {...props} />
  );
};
