export function isEmailValid(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function isNumeric(str) {
  if (typeof str != 'string') return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export function isGoalValid(goal) {
  return goal && goal.hasGoal && goal.goalValue && isNumeric(goal.goalValue);
}
