import React, { useEffect, useRef, useState } from 'react';
import styles from './FloatingPopup.module.scss';
import cls from 'classnames';
import { iconCheck, iconNotesMenu, iconPopupClose, locatorIcon } from 'react-project/assets/Icons';
import { RP_SCROLL_ELEMENT_INTO_VIEW } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { ChecklistBlock } from '../Checklist/ChecklistBlock';
import { When } from 'react-project/Util/When';
import { NotesBlock } from '../Notes/NotesBlock';
import {
  VERTICAL_ALIGN_CENTER,
  ViewportConstrainer,
} from 'react-project/components/viewportConstrainer/ViewportConstrainer';
import { useSelector } from 'react-redux';
import { selectSelectedElements } from 'react-project/redux/selected-elements/selectors';

export const FLOAT_POPUP_TAB_NOTE = 'note';
export const FLOAT_POPUP_TAB_CHECKLIST = 'checklist';

export const FloatingPopup = ({
  isOpen,
  onRemovePopup,
  popInfo,
  onNoteRemoved,
  onPopupDrag,
  checklistData,
  elementID,
  onTabSwitch,
  step,
  onNoteEdited,
  onChecklistEdited,
}) => {
  const popRef = useRef(null);
  const popHeaderRef = useRef(null);
  const popTitleRef = useRef(null);
  const activeTab = popInfo.activeTab;

  // get selected element
  const selectedElements = useSelector(selectSelectedElements);
  let rectsToAvoidInitialState = [];
  if (selectedElements.length === 1) {
    const rect = selectedElements[0].getBounds();
    rectsToAvoidInitialState.push(rect);
  }
  const [avoidRects] = useState(rectsToAvoidInitialState);

  useEffect(() => {
    // Make the DIV element draggable:

    let pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;

    const popElement = popRef.current;
    const headerElement = popHeaderRef.current;
    const titleElement = popTitleRef.current;

    headerElement.addEventListener('pointerdown', dragMouseDown, false);
    titleElement.addEventListener('pointerdown', (e) => e.stopPropagation(), false);

    function dragMouseDown(e) {
      e = e || window.event;

      // CancelDragging
      if (e.target.classList.contains('CancelDragging')) {
        e.stopPropagation();
        return;
      }

      e.preventDefault();

      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.addEventListener('pointerup', closeDragElement);
      document.addEventListener('pointermove', elementDrag);

      onPopupDrag(elementID);
    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;

      const topX = popElement.offsetTop - pos2;
      const leftX = popElement.offsetLeft - pos1;

      popElement.style.top = topX + 'px';
      popElement.style.left = leftX + 'px';

      // get its position and recalculate the restictions
      const popRect = popElement.getBoundingClientRect();

      if (popRect.top < 55) {
        const diff = 55 - popRect.top;
        popElement.style.top = topX + diff + 'px';
      }

      if (popRect.left < 52) {
        const diff = 52 - popRect.left;
        popElement.style.left = leftX + diff + 'px';
      }

      // prevent right side overflow
      if (popRect.right > window.innerWidth) {
        const diff = popRect.right - window.innerWidth;
        popElement.style.left = leftX - diff + 'px';
      }

      // prevent bottom overflow
      if (popRect.bottom > window.innerHeight) {
        const diff = popRect.bottom - window.innerHeight;
        popElement.style.top = topX - diff + 'px';
      }
    }

    function closeDragElement() {
      // stop moving when mouse button is released:
      document.removeEventListener('pointerup', closeDragElement);
      document.removeEventListener('pointermove', elementDrag);
    }

    return () => {
      headerElement.removeEventListener('pointerdown', dragMouseDown);
    };
  }, []);

  const onLocatorClicked = () => {
    commonSendEventFunction(RP_SCROLL_ELEMENT_INTO_VIEW, { id: elementID });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  return (
    <ViewportConstrainer
      verticalAlign={VERTICAL_ALIGN_CENTER}
      relativeRectangle={popInfo.topMenuRect}
      avoidRectangles={avoidRects}
    >
      <div ref={popRef} className={cls(styles.Window, isOpen ? null : styles.Hidden)}>
        <div ref={popHeaderRef} className={styles.Header}>
          <div className={styles.HeaderTop}>
            <div className={styles.Tabs}>
              <div
                className={cls(styles.Tab, {
                  [styles.ActiveTab]: activeTab === FLOAT_POPUP_TAB_NOTE || activeTab === null,
                })}
                onClick={() => {
                  onTabSwitch(FLOAT_POPUP_TAB_NOTE, elementID);
                }}
              >
                {iconNotesMenu()} Notes
              </div>
              <div
                className={cls(styles.Tab, {
                  [styles.ActiveTab]: activeTab === FLOAT_POPUP_TAB_CHECKLIST,
                })}
                onClick={() => {
                  onTabSwitch(FLOAT_POPUP_TAB_CHECKLIST, elementID);
                }}
              >
                {iconCheck} Checklist
              </div>
            </div>

            <div
              className={styles.Close}
              onClick={() => {
                onRemovePopup(elementID);
              }}
            >
              {iconPopupClose}
            </div>
          </div>

          <div className={cls(styles.Title)}>
            <span ref={popTitleRef}>{step.object.label}</span>
            <div onClick={onLocatorClicked}>{locatorIcon}</div>
            <div className={styles.Type}>
              <span className="CancelDragging">{capitalizeFirstLetter(step.object.type)}</span>
            </div>
          </div>
        </div>
        <div className={cls(styles.Content)}>
          <When condition={activeTab === FLOAT_POPUP_TAB_NOTE || activeTab === null}>
            <NotesBlock
              elementID={elementID}
              onNoteRemoved={onNoteRemoved}
              step={step}
              onNoteEdited={onNoteEdited}
            />
          </When>
          <When condition={activeTab === FLOAT_POPUP_TAB_CHECKLIST}>
            <ChecklistBlock
              isCanvas={false}
              elementChecklistData={checklistData}
              elementID={elementID}
              step={step}
              onChecklistEdited={onChecklistEdited}
            />
          </When>
        </div>
      </div>
    </ViewportConstrainer>
  );
};
