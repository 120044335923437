import * as PIXI from 'pixi.js';

export default class Color {
  constructor(hex_code) {
    this.hex_code = hex_code;

    if (hex_code > 16777215) {
      this.alpha = hex_code >>> 24;
      this.a = hex_code >>> 24;
    } else {
      this.alpha = 255;
      this.a = hex_code >>> 255;
    }

    this.red = (hex_code >> 16) & 0xff;
    this.green = (hex_code >> 8) & 0xff;
    this.blue = hex_code & 0xff;

    this.r = (hex_code >> 16) & 0xff;
    this.g = (hex_code >> 8) & 0xff;
    this.b = hex_code & 0xff;
  }

  static hex_to_color_32(hex_code) {
    var a = 0;

    if (hex_code > 16777215) {
      a = hex_code >>> 24;
    } else {
      a = hex_code >>> 255;
    }

    var r = (hex_code >> 16) & 0xff;
    var g = (hex_code >> 8) & 0xff;
    var b = hex_code & 0xff;

    return (a << 24) | (r << 16) | (g << 8) | b;
  }

  static get_color_32(a, r, g, b) {
    return (a << 24) | (r << 16) | (g << 8) | b;
  }

  static hex_to_number(color) {
    var color = color.replace('#', '0x');
    color = PIXI.utils.hex2rgb(color);
    color = Color.get_color_32(1, color[0] * 255, color[1] * 255, color[2] * 255);

    return color;
  }

  static hslToHex(h, s, l) {
    l /= 100;
    const a = (s * Math.min(l, 1 - l)) / 100;
    const f = (n) => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, '0');
    };
    return parseInt(`0x${f(0)}${f(8)}${f(4)}`);
  }

  static getGradientColor(start_color, end_color, percent) {
    // strip the leading # if it's there
    start_color = start_color.replace(/^\s*#|\s*$/g, '');
    end_color = end_color.replace(/^\s*#|\s*$/g, '');

    // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
    if (start_color.length == 3) {
      start_color = start_color.replace(/(.)/g, '$1$1');
    }

    if (end_color.length == 3) {
      end_color = end_color.replace(/(.)/g, '$1$1');
    }

    // get colors
    var start_red = parseInt(start_color.substr(0, 2), 16),
      start_green = parseInt(start_color.substr(2, 2), 16),
      start_blue = parseInt(start_color.substr(4, 2), 16);

    var end_red = parseInt(end_color.substr(0, 2), 16),
      end_green = parseInt(end_color.substr(2, 2), 16),
      end_blue = parseInt(end_color.substr(4, 2), 16);

    // calculate new color
    var diff_red = end_red - start_red;
    var diff_green = end_green - start_green;
    var diff_blue = end_blue - start_blue;

    diff_red = (diff_red * percent + start_red).toString(16).split('.')[0];
    diff_green = (diff_green * percent + start_green).toString(16).split('.')[0];
    diff_blue = (diff_blue * percent + start_blue).toString(16).split('.')[0];

    // ensure 2 digits by color
    if (diff_red.length == 1) diff_red = '0' + diff_red;
    if (diff_green.length == 1) diff_green = '0' + diff_green;
    if (diff_blue.length == 1) diff_blue = '0' + diff_blue;

    return parseInt(`0x${diff_red}${diff_green}${diff_blue}`);
  }
}
