const SOURCE_CANVAS = 0;
const SOURCE_REQUEST = 1;

export default class LoopDetector {
  constructor() {
    this.graph = {};
    this.cycles = [];
    this.visited = {};
    this.objects = [];
    this.connections = [];
    this.loadSource = SOURCE_CANVAS;
  }

  reset() {
    this.cycles = [];
    this.visited = {};
  }

  findRootNodes() {
    const incomingNodes = {};

    // Initialize incoming nodes for all vertices
    Object.keys(this.graph).forEach((node) => {
      incomingNodes[node] = 0;
    });

    // Count incoming connections for each node
    Object.values(this.graph).forEach((neighbors) => {
      neighbors.forEach((neighbor) => {
        incomingNodes[neighbor]++;
      });
    });

    // Find nodes with zero incoming connections (root nodes)
    const rootNodes = Object.keys(incomingNodes).filter((node) => incomingNodes[node] === 0);

    return rootNodes;
  }

  loadDataFromCanvas(canvasDictionary, connections) {
    this.loadSource = SOURCE_CANVAS;
    this.objects = canvasDictionary;
    this.connections = [...connections].filter((connection) => !connection.isNoDataLine());
    this.graph = {};

    // Initialize graph with empty adjacency lists
    canvasDictionary.forEach((vertex) => {
      this.graph[vertex.id] = [];
    });

    // Build graph edges from connections
    this.connections.forEach((connection) => {
      const from = this.getFrom(connection);
      const to = this.getTo(connection);
      this.graph[from].push(to);
    });
  }

  loadDataFromRequest(canvasDictionary, connections) {
    this.loadSource = SOURCE_REQUEST;

    // Convert canvasDictionary to an array of objects
    const objects = Object.keys(canvasDictionary).map((key) => {
      return canvasDictionary[key];
    });

    this.objects = objects;
    this.connections = [...connections].filter((connection) => !connection.is_no_data_line);

    this.graph = {};

    // Initialize graph with empty adjacency lists
    objects.forEach((vertex) => {
      this.graph[vertex.id] = [];
    });

    // Build graph edges from connections
    this.connections.forEach((connection) => {
      const from = this.getFrom(connection);
      const to = this.getTo(connection);
      this.graph[from].push(to);
    });
  }

  getFrom(connection) {
    if (this.loadSource === SOURCE_CANVAS) {
      return connection.iconA ? connection.iconA.id : null;
    } else {
      return connection.source;
    }
  }

  getTo(connection) {
    if (this.loadSource === SOURCE_CANVAS) {
      return connection.iconB ? connection.iconB.id : null;
    } else {
      return connection.target;
    }
  }

  isConnectionEqual(connection, from, to) {
    return this.getFrom(connection) === from && this.getTo(connection) === to;
  }

  dfs(node, visited, currentPath) {
    visited[node] = true;
    currentPath.push(node);

    for (const neighbor of this.graph[node]) {
      if (currentPath.includes(neighbor)) {
        // Found a cycle
        const cycleStartIndex = currentPath.indexOf(neighbor);
        const cycle = currentPath.slice(cycleStartIndex);
        this.cycles.push(this.getCycleInfo(cycle, this.connections));
      } else if (!visited[neighbor]) {
        this.dfs(neighbor, visited, currentPath);
      }
    }

    currentPath.pop();
    visited[node] = false;
  }

  findCycles() {
    this.cycles = [];
    this.visited = {};

    Object.keys(this.graph).forEach((node) => {
      if (!this.visited[node]) {
        this.dfs(node, this.visited, []);
      }
    });

    // Remove duplicate cycles
    const uniqueCycles = [];
    const cycleSet = new Set();

    for (const cycle of this.cycles) {
      const sortedCycle = cycle.vertices.slice().sort();
      const cycleKey = sortedCycle.join(',');
      if (!cycleSet.has(cycleKey)) {
        uniqueCycles.push(cycle);
        cycleSet.add(cycleKey);
      }
    }

    return uniqueCycles;
  }

  getCycleInfo(cycle, connections) {
    const cycleInfo = {
      vertices: cycle,
      connections: [],
    };

    for (let i = 0; i < cycle.length; i++) {
      const from = cycle[i];
      const to = cycle[(i + 1) % cycle.length];
      const connection = connections.find((conn) => this.isConnectionEqual(conn, from, to));
      cycleInfo.connections.push(connection.id);
    }
    return cycleInfo;
  }

  traverseFromRootNode(root, calculator) {
    const visited = {};

    const dfs = (node, parent = null) => {
      // Perform calculations on the current node
      if (visited[node]) {
        return;
      }

      if (!calculator(node, parent)) {
        // if the node returns false, stop the traversal
        return;
      }

      visited[node] = true;

      // Recursive DFS traversal for each neighbor
      this.graph[node].forEach((neighbor) => {
        dfs(neighbor, node);
      });
    };

    dfs(root);
  }

  traverseFromRootNodeAvoidLoops(root, connections, calculator) {
    const isAtLoop = (node, parent) => {
      return connections.some((connection) => {
        return connection.iconA.id === parent && connection.iconB.id === node;
      });
    };

    const dfs = (node, parent = null) => {
      // Perform calculations on the current node
      if (isAtLoop(node, parent)) {
        return;
      }

      if (!calculator(node, parent)) {
        // if the node returns false, stop the traversal
        return;
      }

      // Recursive DFS traversal for each neighbor
      this.graph[node].forEach((neighbor) => {
        dfs(neighbor, node);
      });
    };

    dfs(root);
  }

  // New method to find root nodes containing a specific node
  findRootsContainingNode(targetNodeId) {
    const rootNodes = this.findRootNodes();
    const rootsContainingNode = [];

    const dfs = (node, visited) => {
      if (visited[node]) return false;
      if (node === targetNodeId) return true;
      visited[node] = true;

      for (const neighbor of this.graph[node]) {
        if (dfs(neighbor, visited)) return true;
      }

      return false;
    };

    for (const root of rootNodes) {
      const visited = {};
      if (dfs(root, visited)) {
        rootsContainingNode.push(root);
      }
    }

    return rootsContainingNode;
  }

  findRootsContainingConnection(connectionId) {
    const rootNodes = this.findRootNodes();
    const rootsContainingConnection = [];

    // Find the source and target nodes of the given connection
    const connection = this.connections.find((conn) => conn.id === connectionId);
    if (!connection) {
      return rootsContainingConnection; // Return empty list if connection not found
    }

    const fromNode = this.getFrom(connection);
    const toNode = this.getTo(connection);

    const dfs = (node, visited) => {
      if (visited[node]) return false;
      visited[node] = true;

      for (const neighbor of this.graph[node]) {
        if (node === fromNode && neighbor === toNode) {
          return true; // Connection found in the path
        }
        if (dfs(neighbor, visited)) {
          return true;
        }
      }

      return false;
    };

    for (const root of rootNodes) {
      const visited = {};
      if (dfs(root, visited)) {
        rootsContainingConnection.push(root);
      }
    }

    return rootsContainingConnection;
  }
}
