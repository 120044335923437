import cls from 'classnames';
import concat from 'lodash/concat';
import flattenDeep from 'lodash/flattenDeep';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-project/components/tooltip/Tooltip';
import { getActionType } from 'react-project/Constants/specialActions';
import { getIntegrationType } from 'react-project/Constants/specialSources';
import { EElementCategories, EElementTypes } from 'shared/CSharedCategories';
import { IconsConfig } from 'shared/CSharedConstants';
import {
  PR_EVENT_REPORT_WARNING_POP_OVERLAPPED,
  PR_MOVING_CURSOR_WITH_ELEMENT,
} from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import styles from './IconList.module.scss';
import { iconClose } from 'react-project/assets/Icons';
import { TOOLTIP_POSITIONS } from 'react-project/Constants/tooltip';
import { LOCK_NO_ICONS, UILock } from '../uilock/UILock';
import { When } from 'react-project/Util/When';

const SPECIAL_ITEMS_KEY = 'SPECIAL';
const ACTIONABLE_ITEMS_KEY = 'ACTIONABLE';

const IconListComponent = ({
  type,
  parentComponent,
  colls = 6,
  textSearch = '',
  onIconClick,
  onDragEnd,
  isDisabledMapTab,
  withSpecialItems = false,
  otherIconsTitle,
  onAddIconClick,
  onCloseClick = () => {},
  customUploadedIcons = [],
  useTooltip = true,
  isCommerceAllowed = true,
}) => {
  const classGrid = cls(
    styles.IconsSet,
    { [styles.FiveColls]: colls === 5 },
    { [styles.SixColls]: colls === 6 },
  );

  const isAllIcons = textSearch.length > 0 && isDisabledMapTab;
  const allIcons = Object.values(IconsConfig).reduce((res, curr) => res.concat(curr));
  const keys = Object.keys(IconsConfig).filter((el) => el.includes(type));
  const otherIcons = [];
  const specialIcons = [];
  const actionableIcons = [];

  const [dragAndDropActive, setDragAndDropActive] = useState();

  keys.forEach((key) => {
    if (key.includes(SPECIAL_ITEMS_KEY)) {
      specialIcons.push(...IconsConfig[key]);
    } else if (key.includes(ACTIONABLE_ITEMS_KEY)) {
      actionableIcons.push(...IconsConfig[key]);
    } else {
      otherIcons.push(...IconsConfig[key]);
    }
  });

  const icons = withSpecialItems
    ? flattenDeep(concat(specialIcons, otherIcons))
    : IconsConfig[type];
  const iconsList = isAllIcons ? allIcons : icons;

  const textSearchLower = textSearch.toLowerCase();

  let itemsFilteredData = [];
  if (iconsList && iconsList.length > 0) {
    itemsFilteredData = iconsList.filter((icon) => {
      const iconNameLower = icon.name.toLowerCase();
      return iconNameLower.indexOf(textSearchLower) > -1;
    });
  }

  let customIconsList = customUploadedIcons;

  if (textSearch !== '') {
    customIconsList = customUploadedIcons.filter((icon) => {
      const iconNameLower = icon.name.toLowerCase();
      return iconNameLower.indexOf(textSearchLower) > -1;
    });
  }

  const isMouseOverLockedReport = (e) => {
    if (e.detail.isOverlapped) {
      onMouseOverLockedReport();
    } else {
      onMouseLeaveLockedReport();
    }
  };

  const onMouseOverLockedReport = () => {
    document.body.ondragenter = (e) => {
      e.preventDefault();
      e.dataTransfer.dropEffect = 'none';
    };

    document.body.ondragover = (e) => {
      e.preventDefault();
      e.dataTransfer.dropEffect = 'none';
    };
  };

  const onMouseLeaveLockedReport = () => {
    document.body.ondragenter = (e) => {
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    };

    document.body.ondragover = (e) => {
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    };
  };

  const onActionClick = (data) => {
    onAddIconClick(data);
  };

  useEffect(() => {
    if (dragAndDropActive) {
      document.body.ondragenter = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
      };

      document.body.ondragover = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
      };
    } else {
      document.body.ondragenter = (e) => {};
      document.body.ondragover = (e) => {};
    }
  }, [dragAndDropActive]);

  useEffect(() => {
    document.addEventListener(PR_EVENT_REPORT_WARNING_POP_OVERLAPPED, isMouseOverLockedReport);
    return () => {
      document.removeEventListener(PR_EVENT_REPORT_WARNING_POP_OVERLAPPED, isMouseOverLockedReport);
    };
  }, []);

  const transformName = (name) => {
    switch (name) {
      case 'googleads':
        return 'googlenotblocked';
      case 'adnetwork':
        return 'network';
      case 'printad':
        return 'print';
      default:
        return name;
    }
  };

  const wrapTooltip = (children, name, key) => {
    return (
      <Tooltip key={key} label={name} position={TOOLTIP_POSITIONS.TOP}>
        {children}
      </Tooltip>
    );
  };

  const transformIcon = (icon, i) => {
    const nameWithoutSpaces = icon.name.split(' ').join('').toLowerCase();
    const mappedName = transformName(nameWithoutSpaces);
    const src = `${process.env.PUBLIC_URL}${icon.src}${mappedName}.svg`;
    const srcPixi = `${process.env.PUBLIC_URL}${icon.srcPixi}${mappedName}.png`;

    return (
      <div
        key={icon.name + i}
        className={cls(styles.ListItem)}
        onClick={() =>
          onIconClick({
            src: srcPixi.substring(7, srcPixi.length),
            title: icon.name,
            type: icon.type,
            actionType: getActionType(icon.id),
            integrationType: getIntegrationType(icon.id),
          })
        }
        onDragEnd={(e) => {
          onDragEnd(e.clientX, e.clientY, {
            src: srcPixi.substring(7, srcPixi.length),
            title: icon.name,
            type: icon.type,
            actionType: getActionType(icon.id),
            integrationType: getIntegrationType(icon.id),
          });
          setDragAndDropActive(false);
        }}
        onDragStart={(e) => {
          e.currentTarget.style.cursor = 'grab';
          document.body.style.cursor = 'grab';
          setDragAndDropActive(true);
        }}
        onDrag={(e) => {
          commonSendEventFunction(PR_MOVING_CURSOR_WITH_ELEMENT, {
            coords: { x: e.clientX, y: e.clientY },
            type: EElementCategories.SHAPE,
          });
        }}
        draggable={true}
      >
        <div className={styles.IconContainer}>
          <img name={icon.name} src={src} alt="" draggable={false} />
        </div>
        <div className={styles.IconTitle}>{icon.name}</div>
      </div>
    );
  };

  const transformCustomIcon = (icon, i) => {
    const src = icon.path;
    const type = icon.type.toString().toUpperCase();
    return (
      <div
        key={icon.name + i}
        className={cls(styles.ListItem, styles.CustomIconContainer)}
        onClick={() =>
          onIconClick({
            src: src,
            title: icon.name,
            type: type,
            actionType: getActionType(icon.id),
            integrationType: getIntegrationType(icon.id),
            isCustom: true,
          })
        }
        onDragEnd={(e) => {
          onDragEnd(e.clientX, e.clientY, {
            src: src,
            title: icon.name,
            type: type,
            actionType: getActionType(icon.id),
            integrationType: getIntegrationType(icon.id),
            isCustom: true,
          });
          setDragAndDropActive(false);
        }}
        onDragStart={(e) => {
          e.currentTarget.style.cursor = 'grab';
          document.body.style.cursor = 'grab';
          setDragAndDropActive(true);
        }}
        onDrag={(e) => {
          commonSendEventFunction(PR_MOVING_CURSOR_WITH_ELEMENT, {
            coords: { x: e.clientX, y: e.clientY },
            type: EElementCategories.SHAPE,
          });
        }}
        draggable={true}
      >
        <div className={styles.IconContainer}>
          <img crossOrigin="" name={icon.name} src={src} alt="" draggable={false} />
          <div
            onClick={(e) => {
              e.stopPropagation();
              if (confirm('Are you sure you want to delete this icon ?')) {
                e.detail = icon;
                onCloseClick(e);
              }
            }}
            className={styles.CloseIcon}
          >
            {iconClose}
          </div>
        </div>
        <div className={styles.IconTitle}>{icon.name}</div>
      </div>
    );
  };

  const transformActionableIcon = (icon, i) => {
    const src = `${process.env.PUBLIC_URL}${icon.src}`;
    return (
      <UILock key={i} lock={LOCK_NO_ICONS} style={{ height: 'auto' }}>
        <div
          key={i}
          className={cls(styles.ListItem)}
          onClick={() =>
            onActionClick({
              title: icon.name,
              type: icon.type,
              actionType: getActionType(icon.id),
              integrationType: getIntegrationType(icon.id),
            })
          }
        >
          <div className={styles.IconContainer}>
            <img name={icon.name} src={src} alt="" draggable={false} />
          </div>
          <div className={styles.IconTitle}>{icon.name}</div>
        </div>
      </UILock>
    );
  };

  const heightAdjustment = (type, parentComponent) => {
    const heights = {
      SOURCE: { StepSettings: '177', StepsModal: '119' },
      PAGE: { StepSettings: '243', StepsModal: '119' },
      MISC: { StepSettings: '175', StepsModal: '119' },
      EVENT: { StepSettings: '177', StepsModal: '119' },
      REPORT: { StyleReportPopup: '0' },
    };
    return heights[type] ? heights[type][parentComponent] : '0';
  };

  return (
    <div
      className={cls(styles.IconsSection)}
      style={{ height: `calc(100% - ${heightAdjustment(type, parentComponent)}px)` }}
    >
      {textSearch !== '' || specialIcons.length === 0 ? (
        <div className={classGrid}>
          {itemsFilteredData.map((icon, i) =>
            useTooltip
              ? wrapTooltip(transformIcon(icon, i), icon.name, icon.name + i)
              : transformIcon(icon, i),
          )}
          {customIconsList.map((icon, i) =>
            useTooltip
              ? wrapTooltip(transformCustomIcon(icon, `custom_${i}`), icon.name, icon.name + i)
              : transformCustomIcon(icon, `custom_${i}`),
          )}
          {!isDisabledMapTab &&
            actionableIcons.map((icon, i) => transformActionableIcon(icon, `actionable_${i}`))}
        </div>
      ) : (
        <>
          <When condition={!(type === EElementTypes.EVENT && !isCommerceAllowed)}>
            <div className={classGrid}>{specialIcons.map((icon, i) => transformIcon(icon, i))}</div>
          </When>

          <div>
            {otherIconsTitle && <div className={styles.OtherIconsTitle}>{otherIconsTitle}</div>}
            <div className={classGrid}>
              {otherIcons.map((icon, i) =>
                useTooltip
                  ? wrapTooltip(transformIcon(icon, i), icon.name, icon.name + i)
                  : transformIcon(icon, i),
              )}
              {customIconsList.map((icon, i) =>
                useTooltip
                  ? wrapTooltip(transformCustomIcon(icon, `custom_${i}`), icon.name, icon.name + i)
                  : transformCustomIcon(icon, `custom_${i}`),
              )}
              {actionableIcons.map((icon, i) => transformActionableIcon(icon, `actionable_${i}`))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

IconListComponent.propTypes = {
  type: PropTypes.string.isRequired,
  colls: PropTypes.number,
  textSearch: PropTypes.string,
  onIconClick: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  isDisabledMapTab: PropTypes.bool,
  otherIconsTitle: PropTypes.string,
};

export const IconList = IconListComponent;
