import { produce } from 'immer';
import { handleActions } from 'redux-actions';

import {
  addDefaultFocusedStep,
  addCompareFocusedStep,
  removeDefaultFocusedStep,
  removeCompareFocusedStep,
  openCompareFocusedStep,
  openDefaultFocusedStep,
  removeAllCompareFocusedStep,
  removeAllDefaultFocusedStep,
  setDefaultFocusedSteps,
  setCompareFocusedSteps,
} from './actions';

const initialState = {
  focusedSteps: [],
  focusedCompareSteps: [],
  isFocusStepOpened: false,
  isFocusCompareStepOpened: false,
};

export default handleActions(
  {
    [setDefaultFocusedSteps.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.focusedSteps = action.payload;
      }),
    [setCompareFocusedSteps.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.focusedCompareSteps = action.payload;
      }),
    [addDefaultFocusedStep.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.focusedSteps.push(action.payload);
      }),
    [addCompareFocusedStep.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.focusedCompareSteps.push(action.payload);
      }),
    [removeDefaultFocusedStep.toString()]: (state, action) =>
      produce(state, (draft) => {
        const index = draft.focusedSteps.findIndex((itm) => itm.stepId === action.payload.stepId);
        if (index !== -1) draft.focusedSteps.splice(index, 1);
      }),
    [removeCompareFocusedStep.toString()]: (state, action) =>
      produce(state, (draft) => {
        const index = draft.focusedCompareSteps.findIndex(
          (itm) => itm.stepId === action.payload.stepId,
        );
        if (index !== -1) draft.focusedCompareSteps.splice(index, 1);
      }),
    [removeAllDefaultFocusedStep.toString()]: (state) =>
      produce(state, (draft) => {
        draft.focusedSteps = [];
      }),
    [removeAllCompareFocusedStep.toString()]: (state) =>
      produce(state, (draft) => {
        draft.focusedCompareSteps = [];
      }),
    [openDefaultFocusedStep.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.isFocusStepOpened = action.payload;
      }),
    [openCompareFocusedStep.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.isFocusCompareStepOpened = action.payload;
      }),
  },
  initialState,
);
