import cls from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ShareStyles from 'react-project/Sharing/ShareLink.module.scss';
import { Input } from 'react-project/components/input/Input/Input';
import { TextArea } from 'react-project/components/input/TextArea/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import { asyncUpdateSharedCanvasLibrary } from './shareCanvasSlice';

export const SharedForm = ({ disabled = false }) => {
  /**
   * This component takes the changes from the input fields, validate there are
   * differences between the default values and the current values and then sends
   * it on change along with a debounce of 3 seconds. During this time, a spinner
   * will show there are changes that are actively being saved.
   */

  function getVimeoVideoId(link) {
    /**
     * Support for vimeo links in the form of:
      https://vimeo.com/396809825;
      https://player.vimeo.com/396809825;
      https://player.vimeo.com/video/396809825;
      https://player.vimeo.com/external/396809825.mp4?s=abcdefg1234567&profile_id=108;
     */
    const regex =
      /(?:player\.)?vimeo\.com\/((?:video|external)\/.*?(\d+))|(?:player\.)?vimeo\.com\/.*?(\d+)/i;
    const match = link.match(regex);
    if (!match) return null;
    const found = match[3] || match[2] || match[1];
    if (match && found) {
      return found;
    }
    // Return an empty string or handle the case where the link is invalid
    return null;
  }

  const dispatch = useDispatch();

  const dispatchAsyncUpdateSharedCanvasLibrary = useCallback((formValue) => {
    dispatch(
      asyncUpdateSharedCanvasLibrary({ props: { shared: formValue }, actionType: 'update' }),
    );
  }, []);

  const debouncedSendRequest = useMemo(() => {
    return _.debounce(dispatchAsyncUpdateSharedCanvasLibrary, 3000);
  }, [dispatchAsyncUpdateSharedCanvasLibrary]);

  const validVimeoLink = (link) => {
    const hasVimeoId = getVimeoVideoId(link);
    return !!hasVimeoId || !link;
  };

  const didMount = useRef(false);
  const loading = useSelector((state) => state.canvasSharing.formDataLoading);
  const defaultDescription = useSelector(
    (state) => state.canvasSharing.sharedDescriptionDefault.description,
  );
  const defaultVideo = useSelector(
    (state) => state.canvasSharing.sharedDescriptionDefault.videoLink,
  );
  const [count, setCount] = useState(0);
  const [formValues, setFormValues] = useState({
    description: '',
    videoLink: '',
  });
  const [vimeoError, setVimeoError] = useState(false);

  useEffect(() => {
    setFormValues({
      description: defaultDescription || '',
      videoLink: defaultVideo || '',
    });
  }, [loading]);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    const cloneformValues = { ...formValues };
    if (!loading) {
      // Prevents the initial load from sending a request
      if (!validVimeoLink(formValues.videoLink)) {
        delete cloneformValues.videoLink;
      }
      debouncedSendRequest(cloneformValues);
    }
  }, [formValues]);

  const handleChange = (e) => {
    // Prevents React from resetting its properties:
    e.persist();
    const attr = e.target.name;
    if (attr === 'description') {
      setCount(e.target.value.length);
    }
    if (attr === 'videoLink') {
      setVimeoError(!validVimeoLink(e.target.value));
    }
    setFormValues((prevState) => {
      return {
        ...prevState,
        [attr]: e.target.value,
      };
    });
  };

  return (
    <div className={ShareStyles.fontbase}>
      <div className="font-bold">Canvas Description (Optional)</div>
      <label className={ShareStyles.ShareCanvasCopyWarning} htmlFor="description">
        Whats in this canvas?
      </label>
      <div style={{ transform: 'translate(0, -12px)' }}>
        <div>
          <div className={ShareStyles.textCounter}>{count}/500</div>
        </div>
        <div style={{ height: '110px' }}>
          <TextArea
            maxLength={500}
            onChange={handleChange}
            disabled={disabled || loading}
            name="description"
            value={formValues.description}
          />
        </div>
      </div>
      <div className="font-bold">Video (Optional)</div>
      <div className="flex">
        <label
          className={cls(ShareStyles.ShareCanvasCopyWarning, 'flex-auto')}
          htmlFor="videoLink"
          style={{ transform: 'translateY(33%)' }}
        >
          {'(Vimeo Only - paste link here)'}
        </label>
        <div className={'flex-auto'}>
          <div>
            <Input
              disabled={disabled || loading}
              onChange={handleChange}
              name="videoLink"
              value={formValues.videoLink}
              hasError={!validVimeoLink(formValues.videoLink)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
