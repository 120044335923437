import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styles from 'react-project/BottomBar/BottomBar.module.scss';
import { SessionListItem } from 'react-project/BottomBar/SessionListItem';
import { Button } from 'react-project/components/button/Button';
import { List } from 'react-project/components/list/List';
import { TEXT_LOAD_MORE_USERS } from 'react-project/Constants/texts';
import { loadMoreSessionsAsync } from 'react-project/redux/analytics/actions';
import {
  selectCompareMode,
  selectDataConnections,
  selectDataObjs,
  selectSessions,
} from 'react-project/redux/analytics/selectors';
import { selectFunnelConfiguration } from 'react-project/redux/funnel-configuration/selectors';
import { selectFunnel } from 'react-project/redux/funnels/selectors';
import { When } from 'react-project/Util/When';
import { connect } from 'react-redux';
import { FILTER_TYPE_COMPARE } from '../../shared/CSharedConstants';
import { SearchInput } from '../components/input/SearchInput/SearchInput';
import { Loader } from '../components/loader/Loader';
import { NoDataBlock } from '../components/noDataBlock/NoDataBlock';

const PeopleComponent = (props) => {
  const [propertySearchQuery, setPropertySearchQuery] = useState('');
  const sessions = props.sessions;
  const isCompare = props.type === FILTER_TYPE_COMPARE;
  const currentSessions = isCompare ? sessions.compareSessions : sessions.sessions;
  const currentSessionsMeta = isCompare ? sessions.compareMeta : sessions.meta;
  const filteredItems = currentSessions.filter((p) => {
    return (
      !propertySearchQuery ||
      p.id.toLowerCase().includes(propertySearchQuery.toLowerCase()) ||
      p.email?.toLowerCase().includes(propertySearchQuery.toLowerCase()) ||
      p.fullName?.toLowerCase().includes(propertySearchQuery.toLowerCase())
    );
  });

  const onLoadMoreSessions = () => {
    const { projectId = false } = props.funnel;
    const last = currentSessions && currentSessions[currentSessions.length - 1];

    if (!projectId || !last) {
      return;
    }

    props.onLoadMoreSessions({
      projectId,
      last,
      funnelConfiguration: props.funnelConfiguration,
      dataObjs: props.dataObjs,
      dataConnections: props.dataConnections,
      compareMode: props.type === FILTER_TYPE_COMPARE,
    });
  };

  return (
    <div className={!props.selectable ? styles.SectionWrapper : null}>
      <div>
        {!props.isSearchShowing ? (
          <b className={styles.SectionHeader}>People</b>
        ) : (
          <div className={styles.SectionSearch}>
            <SearchInput
              value={propertySearchQuery}
              onChange={(value) => {
                setPropertySearchQuery(value);
              }}
              placeholder="Search"
            />
          </div>
        )}
      </div>
      {props.compareModeEnabled && !props.selectable ? (
        <div className={styles.SessionsListWrapper}>
          <NoDataBlock text="No compare data available" />
        </div>
      ) : (
        <div className={styles.SessionsListWrapper}>
          <List
            items={filteredItems}
            renderHeader={() => (
              <div className={styles.ListHeader}>
                <div>
                  <span className={props.selectable ? styles.ColumnPadding : undefined}>
                    Country
                  </span>
                  <span className={styles.ColumnPadding}>Person</span>
                </div>
                <span>First Touch</span>
              </div>
            )}
            renderItem={(item, index) => (
              <SessionListItem
                {...item}
                key={index}
                selectable={props.selectable}
                isSelected={props.selectedSessionId === item.intId}
                toggleSelected={() => props.onSessionSelected(item)}
              />
            )}
          />
          <div className={styles.LoadMoreSessions}>
            <When condition={currentSessions.length >= currentSessionsMeta.count}>
              No more users to show
            </When>
            <When condition={currentSessions.length < currentSessionsMeta.count}>
              <Button
                className={styles.MediumButton}
                onClick={onLoadMoreSessions}
                disabled={props.sessions.isLoading}
              >
                {props.sessions.isLoading ? <Loader /> : TEXT_LOAD_MORE_USERS}
              </Button>
            </When>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  sessions: selectSessions(state),
  funnel: selectFunnel(state, ownProps.funnelId),
  compareModeEnabled: selectCompareMode(state),
  funnelConfiguration: selectFunnelConfiguration(state),
  dataObjs: selectDataObjs(state),
  dataConnections: selectDataConnections(state),
});

const mapDispatchToProps = (dispatch) => ({
  onLoadMoreSessions: ({
    projectId,
    funnelConfiguration,
    dataObjs,
    dataConnections,
    compareMode,
    last,
  }) =>
    dispatch(
      loadMoreSessionsAsync({
        projectId,
        funnelConfiguration,
        dataObjs,
        dataConnections,
        compareMode,
        last,
      }),
    ),
});

PeopleComponent.propTypes = {
  funnelId: PropTypes.string.isRequired,
  funnel: PropTypes.shape({
    projectId: PropTypes.string.isRequired,
  }),
  sessions: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    sessions: PropTypes.arrayOf(
      PropTypes.shape({
        intId: PropTypes.number.isRequired,
      }),
    ).isRequired,
    meta: PropTypes.shape({
      count: PropTypes.number.isRequired,
    }).isRequired,
    compareSessions: PropTypes.arrayOf(
      PropTypes.shape({
        intId: PropTypes.number.isRequired,
      }),
    ).isRequired,
    compareMeta: PropTypes.shape({
      count: PropTypes.number.isRequired,
    }).isRequired,
  }),
  onLoadMoreSessions: PropTypes.func.isRequired,
  selectable: PropTypes.bool,
  isSearchShowing: PropTypes.bool,
  onSessionSelected: PropTypes.func,
  selectedSessionId: PropTypes.number,
};

export const People = connect(mapStateToProps, mapDispatchToProps)(PeopleComponent);
