import { EXPLORER_TABS } from 'shared/CSharedConstants';
import { getExplorerRequestBody } from 'react-project/Helpers/requestConstruction';
import RequestService from 'react-project/Helpers/RequestService';
import axios from 'axios';
import {
  getExplorerFilterProperties,
  getRangeProperty,
} from 'react-project/Helpers/FunnelDataTransform';
import { SUB_TAB } from '../ExplorerBlock/ExplorerBlockConfig';

export const ITEMS_ON_PAGE = 20;

let source = axios.CancelToken.source();
const requestService = new RequestService();

export const mockExplorerData = {
  next_pages_all: {
    list: [
      {
        url: 'www.funnelytics.io',
        hits: '15420',
        is_external: false,
      },
      {
        url: 'app.funnelytics.io',
        hits: '9981',
        is_external: false,
      },
      {
        url: 'www.funnelytics.io/start-here',
        hits: '3465',
        is_external: false,
      },
      {
        url: 'app.funnelytics.io/register',
        hits: '2201',
        is_external: false,
      },
      {
        url: 'www.funnelytics.io/blog',
        hits: '1897',
        is_external: false,
      },
      {
        url: 'www.funnelytics.io/mapping/',
        hits: '1705',
        is_external: false,
      },
      {
        url: 'app.funnelytics.io/library/',
        hits: '1701',
        is_external: false,
      },
      {
        url: 'app.funnelytics.io/dashboard/',
        hits: '1477',
        is_external: false,
      },
      {
        url: 'www.funnelytics.io/mapping-services/',
        hits: '1386',
        is_external: false,
      },
      {
        url: 'hub.funnelytics.io/c/hub-tour/',
        hits: '1352',
        is_external: false,
      },
      {
        url: 'hub.funnelytics.io/',
        hits: '1145',
        is_external: false,
      },
      {
        url: 'help.funnelytics.io/en/knowledge/',
        hits: '1086',
        is_external: false,
      },
      {
        url: 'www.funnelytics.io/map-plus/',
        hits: '978',
        is_external: false,
      },
      {
        url: 'app.funnelytics.io/dashboard/settings/my-subscriptions/',
        hits: '921',
        is_external: false,
      },
      {
        url: 'app.funnelytics.io/dashboard/settings/account/',
        hits: '731',
        is_external: false,
      },
    ],
  },
};

export const mockExplorerActionsData = [
  {
    name: 'Link Click',
    hits: '15420',
  },
  {
    name: 'Button Click',
    hits: '9981',
  },
  {
    name: 'Form Submit',
    hits: '3465',
  },
  {
    name: 'Scroll',
    hits: '2201',
  },
  {
    name: 'Video View',
    hits: '1897',
  },
  {
    name: 'Purchase',
    hits: '1705',
  },
  {
    name: 'FAQ Click',
    hits: '1386',
  },
  {
    name: 'Chat opened',
    hits: '1352',
  },
  {
    name: 'Export Click',
    hits: '978',
  },
  {
    name: 'Menu Expanded',
    hits: '921',
  },
];

export default class ExplorerDataHelper {
  constructor() {
    this.activeTab = EXPLORER_TABS.PAGE;
    this.selectedData = [];
    this.selectedItem = {};
    this.focusedSteps = [];
    this.funnelConfiguration = {};
    this.pageNumber = 1;
    this.funnel = {};
    this.activeSubTab = null;
  }

  cancelRequest = () => {
    source.cancel('Operation canceled by the user.');
  };

  update = (
    funnelConfiguration,
    focusedSteps,
    activeTab,
    selectedData,
    selectedItem,
    funnel,
    activeSubTab,
  ) => {
    this.funnelConfiguration = funnelConfiguration;
    this.focusedSteps = focusedSteps;
    this.activeTab = activeTab;
    this.selectedData = selectedData;
    this.selectedItem = selectedItem;
    this.funnel = funnel;
    this.activeSubTab = activeSubTab;
  };

  prepareRequestBody = (preSelectedData = null, attributeKey = '', pageNumber = 1) => {
    const exploredStep = this.createExploredStepTemplate(preSelectedData);

    const reqBody = getExplorerRequestBody({
      funnelConfiguration: this.funnelConfiguration,
      exploredStep,
      focusedSteps: this.focusedSteps,
      pageNumber,
      limit: ITEMS_ON_PAGE,
      attributeKey,
    });
    return reqBody;
  };

  createExploredStepTemplate = (preSelectedData) => {
    if (this.activeTab === EXPLORER_TABS.PAGE) {
      return {
        ID: 'xyz',
        url: this.selectedItem.url,
        category: 'STEP',
        type: 'PAGE',
        filterData: preSelectedData ? preSelectedData : this.selectedData,
      };
    } else if (this.activeTab === EXPLORER_TABS.EVENT) {
      const result = {
        ID: 'xyz',
        url: '',
        category: 'STEP',
        type: 'EVENT',
        actionType: 'custom', // this might change
        integrationType: 'none',
        actionName: this.selectedItem.name,
        filterData: preSelectedData ? preSelectedData : this.selectedData,
      };

      if (this.selectedItem.name === 'Purchase') {
        result.actionType = 'commerce';
      }

      return result;
    } else if (this.activeSubTab.id === SUB_TAB.ALL_REFERING_URLS) {
      return {
        ID: 'xyz',
        category: 'STEP',
        type: 'SOURCE',
        trackingURL: this.selectedItem.url,
        is_commerce: 'false',
        filterData: preSelectedData ? [] : this.selectedData,
        integrationType: 'none',
      };
    } else if (this.activeTab === EXPLORER_TABS.SOURCE) {
      const result = {
        ID: 'xyz',
        category: 'STEP',
        type: 'SOURCE',
        url: '',
        is_commerce: 'false',
        filterData: preSelectedData ? preSelectedData : this.selectedData,
        integrationType: 'url-parameters',
      };

      if (result.filterData.length === 0) {
        return null;
      }

      return result;
    }
  };

  createElementTemplate = (item, filterData, activeSubTab) => {
    if (this.activeTab === EXPLORER_TABS.PAGE) {
      const name = this.nameFromFilterData(filterData);
      return {
        url: item.url,
        category: 'STEP',
        type: 'PAGE',
        filterData: filterData,
        label: item.url + (name ? ` (${name})` : ''),
        src: '/asset/StepsModal/Pages/genericpage.png',
        value: item.hits,
      };
    } else if (this.activeTab === EXPLORER_TABS.EVENT) {
      const name = this.nameFromFilterData(filterData);
      const result = {
        url: '',
        category: 'STEP',
        type: 'EVENT',
        actionType: 'custom', // this might change
        integrationType: 'none',
        actionName: item.name,
        filterData: filterData,
        label: item.name + (name ? ` (${name})` : ''),
        src: '/asset/StepsModal/Actions/click.png',
        value: item.hits,
      };

      if (item.name === 'Purchase') {
        result.is_commerce = 'true';
        result.src = 'StepsModal/Actions/purchase.png';
        result.actionType = 'commerce';
        delete result.value;
      }

      return result;
    } else if (this.activeSubTab.id === SUB_TAB.ALL_REFERING_URLS) {
      const name = this.nameFromFilterData(filterData);

      const result = {
        category: 'STEP',
        type: 'SOURCE',
        trackingURL: item.url,
        is_commerce: 'false',
        filterData: filterData,
        integrationType: 'none',
        label: item.url + (name ? ` (${name})` : ''),
        src: 'StepsModal/Sources/trafficicon.png',
        value: item.hits,
      };

      // The case of Direct Traffic
      if (item.integrationType) {
        result.integrationType = item.integrationType;
        result.label = item.name;
        // can't set filters to Direct Traffic
        result.filterData = [];
      }

      return result;
    } else if (this.activeTab === EXPLORER_TABS.SOURCE) {
      const _filterData = filterData.length
        ? filterData
        : [{ key: item.key, value: '*', operator: '=' }];
      const label = this.nameFromFilterData(_filterData);

      return {
        category: 'STEP',
        type: 'SOURCE',
        url: '',
        is_commerce: 'false',
        filterData: _filterData,
        integrationType: 'url-parameters',
        label: label,
        src: 'StepsModal/Sources/trafficicon.png',
        value: item.hits,
      };
    }
  };

  nameFromFilterData = (filterData) => {
    return `${filterData.map((o) => o.key + '=' + o.value).join('& ')}`.substring(0, 100);
  };

  getAttributeExplorerData = async ({
    preSelectedData = null,
    callback,
    attributeKey = '',
    pageNumber = 1,
  }) => {
    if (source) {
      source.cancel('Operation not allowed to finish');
    }

    const reqBody = this.prepareRequestBody(preSelectedData, attributeKey, pageNumber);

    source = axios.CancelToken.source();

    let responseData = null;

    if (this.activeTab === EXPLORER_TABS.PAGE || this.activeTab === EXPLORER_TABS.SOURCE) {
      responseData = await requestService.getAttributeExplorerPageParams(
        this.funnel.projectId,
        reqBody,
        source,
      );
    } else if (this.activeTab === EXPLORER_TABS.EVENT) {
      responseData = await requestService.getAttributeExplorerAttributes(
        this.funnel.projectId,
        reqBody,
        source,
      );
    }

    if (axios.isCancel(responseData)) {
      // The request was canceled, do nothing
      callback(null, reqBody); // cancel request
    } else if (responseData) {
      callback(responseData, reqBody); // response data
    } else {
      callback({ error: true }, reqBody); // error
    }
  };

  loadExplorerData = (fetchFreshData = false, pagination = null, search) => {
    const filter = getExplorerFilterProperties({
      funnelConfiguration: this.funnelConfiguration,
      focusedSteps: this.focusedSteps,
      search,
      pageNumber: pagination ? pagination.page : 1,
      limit: pagination ? pagination.limit : ITEMS_ON_PAGE,
    });

    const options = { peopleVsTotal: 'individual-people' };

    const reqBody = {
      filter,
      options,
    };
    return requestService.getTrafficExplorerData(this.funnel.projectId, reqBody, fetchFreshData);
  };
}
