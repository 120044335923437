import React, { useEffect, useRef, useState } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import styles from 'react-project/LeftSideBar/LeftSideBar.module.scss';
import { iconCalendar, iconWarrning } from 'react-project/assets/Icons';
import cls from 'classnames';
import PropTypes from 'prop-types';
import { getDirtyStyle, getFilterAddClass } from 'react-project/Util/FilterStyleHelper';
import moment from 'moment';
import { When } from 'react-project/Util/When';
import { TEXTS_TOOLTIP } from 'react-project/Constants/texts';
import { TOOLTIP_POSITIONS } from 'react-project/Constants/tooltip';
import { Tooltip } from 'react-project/components/tooltip/Tooltip';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import { selectHasAnalyticsData } from 'react-project/redux/analytics/selectors';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { RP_EVENT_CALENDAR_DATE_CHANGED, RR_SHOW_TUTORIAL_POPUP } from 'shared/CSharedEvents';
import { useSelector } from 'react-redux';
import { FILTER_TYPE_COMPARE } from 'shared/CSharedConstants';
import { selectFunnelConfiguration } from 'react-project/redux/funnel-configuration/selectors';
import { dateFormatter, dateFormatterToLocale } from 'react-project/Util/DateFormat';
import { selectIsFiltersLoaded } from 'react-project/redux/filters/selectors';
import { calculateLastPeriod } from '../FilterHelpers';

export const tutorialMinDate = moment('2023-07-31T22:00:00.000Z');
export const tutorialMaxDate = moment('2023-08-31T21:59:59.000Z');

export const DateFilterBlock = ({
  inputStart,
  inputFinish,
  onSelectDate,
  sendToStore,
  type,
  isApplyButtonEnabled,
  hasFilterChange,
}) => {
  const ref = useRef();
  const pickerRef = useRef();
  const [dateOpened, setDateOpened] = useState(false);
  const canvasPermissions = useSelector(selectCanvasPermissions);
  const hasAnalyticsData = useSelector(selectHasAnalyticsData);
  const funnelConfiguration = useSelector(selectFunnelConfiguration);
  const [renderKey, setRenderKey] = useState(Date.now());
  const isFiltersLoaded = useSelector(selectIsFiltersLoaded);
  const [dateRange, setDateRange] = useState();

  useEffect(() => {
    setDateRange(getPresetRangesByType(type));
  }, [canvasPermissions]);

  useEffect(() => {
    pickerRef.current.setStartDate(dateFormatterToLocale(inputStart));
    pickerRef.current.setEndDate(dateFormatterToLocale(inputFinish));

    const days = moment(inputFinish).diff(moment(inputStart), 'days') + 1;
    const data = {
      days: days,
      startDate: moment(inputStart).toISOString(),
      endDate: moment(inputFinish).toISOString(),
    };
    commonSendEventFunction(RP_EVENT_CALENDAR_DATE_CHANGED, data);
  }, [inputStart, inputFinish]);

  useEffect(() => {
    setRenderKey(Date.now());
  }, [dateRange]);

  useEffect(() => {
    if (type === FILTER_TYPE_COMPARE) {
      if (isLastPeriod()) {
        const lastPeriod = calculateLastPeriod(funnelConfiguration);

        // set the picker to the last period
        pickerRef.current.setStartDate(moment(lastPeriod[0].toISOString()));
        pickerRef.current.setEndDate(moment(lastPeriod[1].toISOString()));

        // update filters
        const intermediateValue = {
          min: lastPeriod[0].toISOString(),
          max: lastPeriod[1].toISOString(),
          selectedLabel: createLastPeriodLabel(),
        };
        setDateRangeValue(intermediateValue);
      }

      // update last period in date ranges
      setDateRange(getPresetRangesByType(type));
    }
  }, [funnelConfiguration.dateRange.min, funnelConfiguration.dateRange.max]);

  const onShow = (event, picker) => {
    setDateOpened(true);
  };

  const onHide = (event, picker) => {
    const dateRangeValue = {
      min: picker.startDate,
      max: picker.endDate,
      selectedLabel: picker.chosenLabel,
    };
    setDateRangeValue(dateRangeValue);
    return;
  };

  const setDateRangeValue = (dateRangeValue) => {
    onSelectDate({
      inputStart: dateRangeValue.min,
      inputFinish: dateRangeValue.max,
    });
    setDateOpened(false);
    sendToStore(dateRangeValue);
  };

  const onCalendarInputClicked = () => {
    if (isCalendarDisabled()) {
      if (!hasAnalyticsData) {
        commonSendEventFunction(RR_SHOW_TUTORIAL_POPUP);
      }
    }
  };

  const isCalendarDisabled = () => {
    if (!isFiltersLoaded) {
      return true;
    }

    return false;
  };

  const getPresetRangesByType = (type) => {
    const today = [moment(), moment()];
    const yesterday = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
    const last7Days = [moment().subtract(6, 'days'), moment()];
    const last30Days = [moment().subtract(29, 'days'), moment()];
    const thisMonth = [moment().startOf('month'), moment().endOf('month')];
    const lastMonth = [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ];

    let ranges = null;
    if (canvasPermissions.isTutorial) {
      ranges = {
        Today: [tutorialMaxDate.clone(), tutorialMaxDate.clone()],
        Yesterday: [
          tutorialMaxDate.clone().subtract(1, 'days'),
          tutorialMaxDate.clone().subtract(1, 'days'),
        ],
        'Last 7 Days': [tutorialMaxDate.clone().subtract(6, 'days'), tutorialMaxDate.clone()],
        'Last 30 Days': [tutorialMaxDate.clone().subtract(29, 'days'), tutorialMaxDate.clone()],
        'This Month': [
          tutorialMaxDate.clone().startOf('month'),
          tutorialMaxDate.clone().endOf('month'),
        ],
      };
    } else if (type === FILTER_TYPE_COMPARE) {
      const lastPeriod = calculateLastPeriod(funnelConfiguration);
      ranges = {
        [createLastPeriodLabel()]: lastPeriod,
        'Last 7 Days': last7Days,
        'Last 30 Days': last30Days,
        'This Month': thisMonth,
        'Last Month': lastMonth,
      };
    } else {
      ranges = {
        Today: today,
        Yesterday: yesterday,
        'Last 7 Days': last7Days,
        'Last 30 Days': last30Days,
        'This Month': thisMonth,
        'Last Month': lastMonth,
      };
    }
    return ranges;
  };

  const isLastPeriod = () => {
    return (
      funnelConfiguration.compareDateRange.selectedLabel &&
      funnelConfiguration.compareDateRange.selectedLabel.startsWith('Last Period')
    );
  };

  const createLastPeriodLabel = () => {
    return `Last Period`;
  };

  const maxDate = moment();
  const minDate = moment().subtract(canvasPermissions.dataRetention || 0, 'months');

  const datePickerSettings = {
    opens: 'right',
    startDate: dateFormatterToLocale(inputStart),
    endDate: dateFormatterToLocale(inputFinish),
    locale: {
      applyLabel: 'Ok',
    },
    alwaysShowCalendars: true,
    ranges: dateRange,
    maxDate,
    minDate,
  };

  return (
    <div
      className={cls(styles.FiltersItem, getDirtyStyle(type), getFilterAddClass(type), {
        [styles.ActiveFilter]: dateOpened,
      })}
      style={{ position: 'relative', cursor: 'pointer', margin: '10px 15px' }}
      ref={ref}
      onClick={onCalendarInputClicked}
    >
      <DateRangePicker
        key={renderKey}
        ref={pickerRef}
        autoApply={true}
        autoUpdateInput={false}
        initialSettings={datePickerSettings}
        onHide={onHide}
        onShow={onShow}
      >
        <div
          className={cls(
            styles.ItemsForSelection,
            { [styles.DatePickerFilter]: dateOpened },
            isCalendarDisabled() ? styles.CalendarDisabled : null,
          )}
        >
          <span className={styles.DateBlock}>
            {dateFormatter(inputStart)} - {dateFormatter(inputFinish)}
          </span>

          <div className={styles.filterIconArrangement} style={{ marginLeft: '10px' }}>
            <When condition={hasFilterChange && isApplyButtonEnabled}>
              <Tooltip label={TEXTS_TOOLTIP.UNAPPLIED_CHANGES} position={TOOLTIP_POSITIONS.BOTTOM}>
                <div className={styles.FilterWarning}>{iconWarrning}</div>
              </Tooltip>
            </When>
            {iconCalendar}
          </div>
        </div>
      </DateRangePicker>
    </div>
  );
};

DateFilterBlock.propType = {
  inputStart: PropTypes.string.isRequired,
  inputFinish: PropTypes.string.isRequired,
  onSelectDate: PropTypes.func.isRequired,
  sendToStore: PropTypes.func.isRequired,
};
