export const ActionNames = {
  updateStatus: '@analytics/UPDATE_STATUS',
  updateProfileCountries: '@analytics/UPDATE_PROFILE_COUNTRIES',
  refreshSessions: '@analytics/UPDATE_SESSIONS',
  refreshCompareSessions: '@analytics/UPDATE_COMPARE_SESSIONS',
  insertSessions: '@analytics/INSERT_SESSIONS',
  insertCompareSessions: '@analytics/INSERT_COMPARE_SESSIONS',
  updateSessionsLoadingStatus: '@analytics/UPDATE_SESSIONS_LOADING_STATUS',
  setProjectApiKey: '@analytics/SET_PROJECT_API_KEY',
  setCompareMode: '@analytics/SET_COMPARE_MODE',
  setSelectedSession: '@analytics/SET_SELECTED_SESSION',
  // setAnalyticsCompareRangeFrom: '@analytics/SET_ANALYTICS_COMPARE_RANGE_FROM',
  // setAnalyticsCompareRangeTo: '@analytics/SET_ANALYTICS_COMPARE_RANGE_TO',
  setCancelAnalyticsRequest: '@analytics/SET_CANCEL_ANALYTICS_REQUEST',
  setAnalyticsWasCancelled: '@analytics/SET_ANALYTICS_WAS_CANCELLED',
  setAnalyticsData: '@analytics/SET_ANALYTICS_DATA',
  setHasAnalyticsData: '@analytics/SET_ANALYTICS_HAS_DATA',

  clean: '@auth/clean',
  setAccessToFunnel: '@auth/setAccessToFunnel',

  setThumbnailIsLoading: '@current-step/SET_THUMBNAIL_IS_LOADING',
  setAttributeExplorerData: '@current-step/SET_ATTRIBUTE_EXPLORER_DATA',
  setNewCurrentStepLabel: '@current-step/SET_NEW_CURRENT_STEP_LABEL',
  setNewCurrentStepUrl: '@current-step/SET_NEW_CURRENT_STEP_URL',
  setNewCurrentStep: '@current-step/SET_NEW_CURRENT_STEP',
  setNewCurrentStepFilterParams: '@current-step/SET_NEW_CURRENT_STEP_FILTER_PARAMS',
  setNewCurrentStepTrackingUrl: '@current-step/SET_NEW_CURRENT_STEP_TRACKING_URL',
  setNewCurrentStepActionType: '@current-step/SET_NEW_CURRENT_STEP_ACTION_TYPE',
  setNewCurrentStepIntegrationType: '@current-step/SET_NEW_CURRENT_STEP_INTEGRATION_TYPE',
  setNewCurrentStepActionName: '@current-step/SET_NEW_CURRENT_STEP_ACTION_NAME',
  setTextStyle: '@current-step/SET_TEXT_STYLE',
  setFacebookTree: '@current-step/SET_NEW_CURRENT_STEP_FACEBOOK_FREE',
  setFacebookSelectedTree: '@current-step/SET_SELECTED_CURRENT_STEP_FACEBOOK_FREE',
  setFacebookAds: '@current-step/SET_NEW_CURRENT_STEP_FACEBOOK_ADS',
  setIntegrationAttributes: '@current-step/SET_INTEGRATION_ATTRIBUTES',
  setTextHyperLink: '@current-step/SET_TEXT_HYPERLINK',
  setNewCurrentStepGoal: '@current-step/SET_NEW_CURRENT_STEP_GOAL',
  setNewCurrentStepForecastingData: '@current-step/SET_NEW_CURRENT_STEP_FORECASTING_DATA',

  setExplorerPageParameters: '@explorer/SET_EXPLORER_PAGE_PARAMETERS',
  setExplorerItemsConfig: '@explorer/SET_EXPLORER_ITEMS_CONFIG',
  setExplorerPageNumber: '@explorer/SET_EXPLORER_PAGE_NUMBER',
  setExplorerLoadingStatus: '@explorer/SET_EXPLORER_LOADING_STATUS',
  setLoadMoreSourceItemsConfig: '@explorer/SET_LOAD_MORE_SOURCE_ITEMS_CONFIG',
  setExplorerLoadingCount: '@explorer/SET_EXPLORER_LOADING_COUNT',

  setFilter: '@filters/SET_FILTER',
  setApplyButtonEnabled: '@filters/SET_APPLY_BUTTON_STATUS',
  setApplyCompareButtonEnabled: '@filters/SET_APPLY_COMPARE_BUTTON_STATUS',
  setCurrentFilter: '@filters/SET_CURRENT_FILTER',
  setCurrentCommonFilter: '@filters/SET_CURRENT_COMMON_FILTER',
  setPreviousCommonFilter: '@filters/SET_PREVIOUS_COMMON_FILTER',
  setIsFiltersLoaded: '@filters/SET_IS_FILTERS_LOADED',

  addDefaultFocusedStep: '@focused-step/ADD_DEFAULT_STEP',
  addCompareFocusedStep: '@focused-step/ADD_COMPARE_STEP',
  removeDefaultFocusedStep: '@focused-step/REMOVE_DEFAULT_STEP',
  removeCompareFocusedStep: '@focused-step/REMOVE_COMPARE_STEP',
  removeAllDefaultFocusedStep: '@focused-step/REMOVE_ALL_DEFAULT_STEP',
  removeAllCompareFocusedStep: '@focused-step/REMOVE_ALL_COMPARE_STEP',
  openDefaultFocusedStep: '@focused-step/OPEN_DEFAULT_STEP',
  openCompareFocusedStep: '@focused-step/OPEN_COMPARE_STEP',
  setDefaultFocusedSteps: '@focused-step/SET_DEFAULT_FOCUSED_STEPS',
  setCompareFocusedSteps: '@focused-step/SET_COMPARE_FOCUSED_STEPS',

  updateFocusedSteps: '@funnel-configuration/UPDATE_FOCUSED_STEPS',
  updateFocusedStepsCompare: '@funnel-configuration/UPDATE_FOCUSED_STEPS_COMPARE',

  setSelectedElements: '@funnel-configuration/SET_SELECTED_ELEMENTS',

  updateDataRange: '@funnel-configuration/SET_NEW_DATE_RANGE',
  updateCompareDataRange: '@funnel-configuration/SET_COMPARE_DATE_RANGE',
  updateFilterData: '@funnel-configuration/SET_FILTER_DATA',
  updateCompareFilterData: '@funnel-configuration/SET_COMPARE_FILTER_DATA',
  setCompareFilterStatus: '@funnel-configuration/SET_COMPARE_FILTER_STATUS',
  setPanToolActive: '@funnel-configuration/SET_PAN_TOOL_ACTIVE',
  setNewFunnelConfiguration: '@funnel-configuration/SET_NEW_FUNNEL_CONFIGURATION',
  setPreviousFunnelData: '@funnel-configuration/SET_PREVIOUS_FUNNEL_DATA',
  setActiveDataLayer: '@funnel-configuration/SET_ACTIVE_DATA_LAYER',
  setDataMode: '@funnel-configuration/SET_DATA_MODE',

  setFunnelCompareRangeFrom: '@funnel-configuration/SET_COMPARE_RANGE_FROM',
  setFunnelCompareRangeTo: '@funnel-configuration/SET_COMPARE_RANGE_TO',

  updateFunnelRevisions: '@funnel-revisions/UPDATE_REVISIONS',
  updateFunnelRevisionsMeta: '@funnel-revisions/UPDATE_REVISIONS_META',
  updateFunnelMoreRevisions: '@funnel-revisions/UPDATE_MORE_REVISIONS',
  updateFunnelRevisionsIsLoading: '@funnel-revisions/UPDATE_REVISIONS_IS_LOADING',

  updateFunnel: '@funnel/UPDATE',
  updateIsFunnelLoading: '@funnel/UPDATE_IS_FUNNEL_LOADING',
  updateIsMigratedC1toC2: '@funnel/UPDATE_IS_MIGRATED_C1_TO_C2',

  setInputValue: '@inputs/SET_INPUT_VALUE',
  addLastInputValue: '@inputs/ADD_LAST_INPUT_VALUE',
  clearInputsState: '@inputs/CLEAR_INPUTS_STATE',

  setPermission: '@user/SET_PERMISSION',
  setEmail: '@user/SET_EMAIL',
  setShowDeveloperContent: '@user/SET_SHOW_DEVELOPER_CONTENT',
  setCanvasPermissions: '@user/SET_CANVAS_PERMISSIONS',

  setPanGuideStatus: '@notification-alerts/SET_PAN_GUIDE_STATUS',
  setPerfectShapeGuideStatus: '@notification-alerts/SET_PERFECT_SHAPE_GUIDE_STATUS',
  setUpdateCanvasDataStatus: '@notification-alerts/SET_UPDATE_CANVAS_DATA_STATUS',

  setIsModalOpened: '@modal/SET_IS_MODAL_OPENED',
  setIsStepModalOpened: '@modal/SET_IS_STEP_MODAL_OPENED',
  setIsStepModalPinned: '@modal/SET_IS_STEP_MODAL_PINNED',
  setStepModalData: '@modal/SET_STEP_MODAL_DATA',
  resetStepModalData: '@modal/RESET_STEP_MODAL_DATA',

  setWidgetStatus: '@popups/SET_WIDGET_STATUS',
  setWidgetPosition: '@popups/SET_WIDGET_POSITION',

  setCustomIcons: '@custom-icons/SET_CUSTOM_ICONS',

  setCanvasNoteAction: '@notes/SET_CANVAS_NOTE',
  setNoteAction: '@notes/SET_NOTE',
  setNotesAction: '@notes/SET_NOTES',
  removeNoteAction: '@notes/REMOVE_NOTE',
  setNoteTitleAction: '@notes/SET_NOTE_TITLE',
  resetHasEditsAction: '@notes/RESET_HAS_EDITS',

  setCanvasChecklistAction: '@checklist/SET_CANVAS_CHECKLIST',
  setUIStateAction: '@ui-state/SET_UI_STATE',
};
