import { PR_EVENT_FUNNEL_CHANGED, PR_EVENT_CHECKLIST_MODIFIED } from 'shared/CSharedEvents';
import { cloneData, commonSendEventFunction } from 'shared/CSharedMethods';

export default class CommandChecklistChanged {
  constructor(object, checklistData) {
    this.object = object;
    this.oldChecklistData = object.checklistData ? cloneData(object.checklistData) : undefined;
    this.newChecklistData = checklistData ? cloneData(checklistData) : undefined;
    this.isInitial = true;
    this.isExecuted = false;
  }

  execute() {
    if (!this.isExecuted) {
      this.object.onCheckistChanged(this.newChecklistData);

      if (!this.isInitial) {
        commonSendEventFunction(PR_EVENT_CHECKLIST_MODIFIED, {
          data: this.newChecklistData,
          object: this.object,
        });
      }

      this.isExecuted = true;
      this.isInitial = false;
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
      window.app.needsRendering();
    }
  }

  revert() {
    if (this.isExecuted) {
      this.object.onCheckistChanged(this.oldChecklistData);

      this.isExecuted = false;
      commonSendEventFunction(PR_EVENT_CHECKLIST_MODIFIED, {
        data: this.oldChecklistData,
        object: this.object,
      });
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
      window.app.needsRendering();
    }
  }
}
