import { UserType, MembershipType, RoleType, UserMetaPropertyType } from './types';

export default (serializer) => {
  serializer.register(UserType, {
    relationships: {
      membership: {
        type: MembershipType,
      },
      role: {
        type: RoleType,
      },
      meta_properties: {
        type: UserMetaPropertyType,
      },
    },
  });

  serializer.register(UserMetaPropertyType);
  serializer.register(RoleType);
  serializer.register(MembershipType);
};
