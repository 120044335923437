import { EConnectionLineType } from 'pixi-project/base/joint/CConnectionConstants';
import { roundTo } from 'shared/CSharedMethods';
import SharedElementHelpers from 'shared/SharedElementHelpers';

export default class CSVExporter {
  canvasDataToCSVFormat(data) {
    const CSVData = [];

    const titleRow = ['Report Page', 'Step ID', 'Step Type', 'Step Label', 'Step URL', 'People'];
    CSVData.push(titleRow);

    for (let i = 0; i < data.length; i++) {
      const object = data[i];
      if (SharedElementHelpers.IsStep(object)) {
        const row = [
          object.slideIndex !== undefined ? object.slideIndex + 1 : '', // report page
          object.ID, // id
          object.type, // type
          object.label, // title
          object.url || 'N/A', // url
          object.analyticsData ? object.analyticsData.hits : 'N/A', // people
        ];
        CSVData.push(row);
      }
    }
    return CSVData;
  }

  trendsDataToCSVFormat(data, compareData) {
    const CSVData = [];

    const titleRow = ['Step Name', 'Step ID', 'Avg Hits'];

    if (compareData) {
      titleRow.push('Avg Hits Compare');
    }

    for (let i = 0; i < data.dates.length; i++) {
      titleRow.push(data.dates[i]);

      if (compareData && compareData.dates.length > i) {
        titleRow.push('Compare to ' + compareData.dates[i]);
      }
    }

    CSVData.push(titleRow);

    for (let i = 0; i < data.objectsArray.length; i++) {
      const row = [];
      const objectData = data.objectsArray[i];
      row.push(objectData.label);
      row.push(objectData.id);
      row.push(objectData.averageHits);
      if (compareData) {
        row.push(objectData.compareAverageHits);
      }

      // add hits
      for (let j = 0; j < objectData.hits.length; j++) {
        row.push(objectData.hits[j]);

        if (objectData.compareHits && objectData.compareHits.length > j) {
          row.push(objectData.compareHits[j]);
        }
      }

      CSVData.push(row);
    }

    return CSVData;
  }

  canvasConnDataToCSVFormat(data) {
    const CSVData = [];

    const titleRow = [
      'Report Page',
      'Step conversion ID',

      'Step A Type',
      'Step A Label',
      'Step A URL',
      'Step A People',

      'Step B Type',
      'Step B Label',
      'Step B URL',
      'Step B People',

      'Conversion Type',
      'People Converted',
      'Conversion Rate',
    ];
    CSVData.push(titleRow);

    for (let i = 0; i < data.length; i++) {
      const object = data[i];
      if (SharedElementHelpers.IsConnection(object)) {
        const row = [
          object.slideIndexA !== undefined && object.slideIndexA === object.slideIndexB
            ? object.slideIndexA + 1
            : null, // report page
          object.ID,

          object.iconAState.type,
          object.iconAState.label,
          object.iconAState.url ? object.iconAState.url : 'N/A',
          object.iconAState.analyticsData ? object.iconAState.analyticsData.hits : 'N/A',

          object.iconBState.type,
          object.iconBState.label,
          object.iconBState.url ? object.iconBState.url : 'N/A',
          object.iconBState.analyticsData ? object.iconBState.analyticsData.hits : 'N/A',

          this.convertConnectionLineType(object.lineType),
          object.analyticsData ? object.analyticsData.hits : 'N/A',
          roundTo(object.conversionRate || 0, 2), // conversion rate
        ];
        CSVData.push(row);
      }
    }
    return CSVData;
  }

  convertConnectionLineType(lineType) {
    switch (lineType) {
      case EConnectionLineType.SOLID:
        return 'Direct';
      case EConnectionLineType.DOTTED:
        return 'Indirect';
      case EConnectionLineType.NODATA:
        return 'No Data';
      default:
        return 'Undefined';
    }
  }

  downloadCSV(CSVData, name = 'csv_export', dateRange) {
    let csvContent = '';
    for (let i = 0; i < CSVData.length; i++) {
      const row = CSVData[i];
      const rowData = row.join(',');
      csvContent += rowData + '\n';
    }

    // set date range

    const minDate = new Date(dateRange.min);
    const minStr = `${minDate.getFullYear()}_${minDate.getMonth() + 1}_${minDate.getDate()}`;
    const maxDate = new Date(dateRange.max);
    const maxStr = `${maxDate.getFullYear()}_${maxDate.getMonth() + 1}_${maxDate.getDate()}`;

    const dateRangeStr = minStr === maxStr ? minStr : ` ${minStr} to ${maxStr}`;

    this.saveFile(`${document.title}_${name} - ${dateRangeStr}.csv`, csvContent);
  }

  saveFile(filename, data) {
    const blob = new Blob([data], { type: 'text/csv' });
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      const elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  }
}
