import cls from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createPortal } from 'react-dom';
import { iconModalClose } from 'react-project/assets/Icons';
import { ClickOutsideCustomComponent } from '../../Util/ClickOutsideCustom';
import styles from './Modal.module.scss';

export const Modal = ({ title, onClose, isOpen, children, className }) => {
  const root = document.getElementsByTagName('body')[0];

  const modalContent = (
    <div className={cls(styles.ModalWrapper, className?.ModalWrapper)}>
      <div className={cls(styles.ModalBackground, className?.ModalBackground)} />
      <ClickOutsideCustomComponent onClickOutside={onClose}>
        <div className={cls(styles.Modal, className?.Modal)}>
          {title && <h2 className={cls(styles.ModalTitle, className?.ModalTitle)}>{title}</h2>}
          <button
            onClick={onClose}
            className={cls(styles.ModalCloseButton, className?.ModalCloseButton)}
          >
            {iconModalClose}
          </button>
          <div
            className={cls(styles.ModalContent, className?.ModalContent)}
            style={title ? null : { marginTop: 0 }}
          >
            {children}
          </div>
        </div>
      </ClickOutsideCustomComponent>
    </div>
  );

  return isOpen && createPortal(modalContent, root);
};

Modal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
