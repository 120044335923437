import RequestService from 'react-project/Helpers/RequestService';

const requestService = new RequestService();
export const FACEBOOK_INTEGRATION = 'facebook';

const generateKeyForIntegrationExistsParam = (projectId, name) =>
  `are-project-integration-detected:${projectId}:${name}`;

const setCacheIntegrationInstalled = (projectId, isInstalled, name) => {
  const actionsKey = generateKeyForIntegrationExistsParam(projectId, name);

  if (isInstalled) {
    localStorage.setItem(actionsKey, 'true');
  }
};

const checkCacheIntegrationIsInstalled = (projectId, name) => {
  const actionsKey = generateKeyForIntegrationExistsParam(projectId, name);
  return Boolean(localStorage.getItem(actionsKey));
};

export const isIntegrationInstalled = async (projectId, name) => {
  if (projectId) {
    const isCacheInstalled = checkCacheIntegrationIsInstalled(projectId, name);

    if (isCacheInstalled) {
      return isCacheInstalled;
    } else {
      let isInstalled;
      switch (name) {
        case FACEBOOK_INTEGRATION:
          isInstalled = await requestService.checkFacebookIntegrationExist(projectId);
          break;
        default:
          isInstalled = false;
          break;
      }

      setCacheIntegrationInstalled(projectId, isInstalled, name);
      return isInstalled;
    }
  }
};
