import { ActionNames } from 'react-project/redux/actionNamesConstant';
import { createAction } from 'redux-actions';

export const setNoteAction = createAction(ActionNames.setNoteAction);
export const removeNoteAction = createAction(ActionNames.removeNoteAction);
export const setCanvasNoteAction = createAction(ActionNames.setCanvasNoteAction);
export const setNotesAction = createAction(ActionNames.setNotesAction);
export const setNoteTitleAction = createAction(ActionNames.setNoteTitleAction);
export const resetHasEditsAction = createAction(ActionNames.resetHasEditsAction);

export const setCanvasNote = (note) => {
  return (dispatch) => dispatch(setCanvasNoteAction(note));
};

export const setNote = (note) => {
  return (dispatch, getState) => {
    const currentFunnel = getState().funnels.currentFunnelId;
    const projectId = getState().funnels[currentFunnel].projectId;
    dispatch(setNoteAction({ note, projectId }));
  };
};

export const setNotes = (notes) => {
  return (dispatch) => dispatch(setNotesAction(notes));
};

export const removeNote = (note) => {
  return (dispatch) => dispatch(removeNoteAction(note));
};

export const setNoteTitle = (note) => {
  return (dispatch) => dispatch(setNoteTitleAction(note));
};
