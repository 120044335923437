import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import AuthProvider from './routing/AuthProvider';
import { AuthRoute } from './routing/AuthRoute';

import { Funnel } from './Pages/Funnel';
import '@reach/tooltip/styles.css';
import { NotFound } from './Main/NotFound';
import { ViewOnly } from './Pages/ViewOnly';
import { TutorialView } from './Pages/TutorialView';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <AuthRoute path="/funnels/:funnelId" exact render={(props) => <Funnel {...props} />} />
          <AuthRoute path="/view/:funnelId" exact render={(props) => <ViewOnly {...props} />} />
          <AuthRoute
            path="/tutorial/:funnelId"
            exact
            render={(props) => <TutorialView {...props} />}
          />
          <AuthRoute path="/tutorial" exact render={(props) => <TutorialView {...props} />} />
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
