import React from 'react';
import styles from './PopUp.module.scss';
import { Button } from 'react-project/components/button/Button';
import PropTypes from 'prop-types';

export const ConfirmationPopup = (props) => {
  const { children, onAccept, onCancel } = props;

  return (
    <div className={styles.Modal}>
      <div className={styles.Wrapper}>
        <div className={styles.Content}>{children}</div>
        <div className={styles.BtnsBlock}>
          <Button className={styles.Btn} onClick={() => onAccept()}>
            Delete
          </Button>
          <Button className={styles.Btn} onClick={() => onCancel()}>
            Сancel
          </Button>
        </div>
      </div>
    </div>
  );
};

ConfirmationPopup.propTypes = {
  children: PropTypes.node.isRequired,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
};
