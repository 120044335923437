import { produce } from 'immer';
import { parseAuthCookie } from 'react-project/Util/AuthCookie';
import { handleActions } from 'redux-actions';
import { clean, setAccessToFunnel } from './actions';

const getInitialState = () => {
  const parsedCookie = parseAuthCookie();

  if (!parsedCookie) {
    return {
      isAuthenticated: false,
      userId: undefined,
    };
  }

  return createAuthObject(parsedCookie);
};

const createAuthObject = (auth) => {
  const isAuthenticated =
    Boolean(auth.id) && (Boolean(auth.accessToken) || Boolean(auth.access_token));

  return {
    isAuthenticated,
    userId: isAuthenticated ? auth.id : undefined,
    hasAccessToFunnel: null,
  };
};

const initialState = getInitialState();

export default handleActions(
  {
    [clean.toString()]: (state) =>
      produce(state, (draft) => {
        draft.isAuthenticated = false;
        draft.userId = undefined;
      }),
    [setAccessToFunnel.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.hasAccessToFunnel = action.payload;
      }),
  },
  initialState,
);
