import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Body } from './Body';
import { Head } from './Head';
import styles from './Share.module.scss';
import { asyncFetchSharedCanvasLibrary, setCurrentTab, setPreEnabled } from './shareCanvasSlice';

export const Share = () => {
  const dispatch = useDispatch();
  const librarySharePreEnabled = useSelector((state) => state.canvasSharing.preEnabled);
  useEffect(() => {
    dispatch(asyncFetchSharedCanvasLibrary({ actionType: 'setDefault' }));
  }, []);
  const currentTab = useSelector((state) => state.canvasSharing.currentTab);
  const onTabClick = (tab) => {
    if (librarySharePreEnabled) {
      if (confirm('You have unsaved setting changes. Are you sure you want to leave') == true) {
        dispatch(setCurrentTab(tab));
        dispatch(setPreEnabled(false));
        return;
      } else {
        return;
      }
    }
    dispatch(setCurrentTab(tab));
  };
  return (
    <div className={styles.ShareContainer}>
      <Head currentTab={currentTab} onClick={onTabClick} />
      <Body currentTab={currentTab} switchTab={onTabClick} />
    </div>
  );
};
