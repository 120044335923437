import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from './Dropdown';
import styles from './Dropdown.module.scss';
import cls from 'classnames';
import { ClickOutsideCustomComponent } from '../../Util/ClickOutsideCustom';
import {
  POSITIONING_BOTTOM_TO_TOP_RIGHT_TO_LEFT,
  VERTICAL_ALIGN_LEFT,
  ViewportConstrainer,
} from '../viewportConstrainer/ViewportConstrainer';

export const CommonDropdown = ({
  dropdownTrigger,
  className,
  contentClassName,
  items,
  forceCloseOnSelect = false,
  onDropdownOppened,
  verticalAlign = VERTICAL_ALIGN_LEFT,
  horizontalAlign = POSITIONING_BOTTOM_TO_TOP_RIGHT_TO_LEFT,
  padding,
}) => {
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const dropDownRef = useRef();

  useEffect(() => {
    if (onDropdownOppened) {
      onDropdownOppened(dropdownOpened);
    }
  }, [dropdownOpened]);

  const checkForParentDropDown = (element) => {
    if (!element) {
      return false;
    }

    if (dropDownRef.current) {
      if (element === dropDownRef.current) {
        return true;
      }
      return checkForParentDropDown(element.parentNode);
    }
  };

  return (
    <Dropdown
      dropDownRef={dropDownRef}
      className={className}
      triggerSlot={dropdownTrigger}
      isOpen={dropdownOpened}
      onToggle={(o) => setDropdownOpened(o)}
    >
      <ClickOutsideCustomComponent
        ignoreClickOutside={!dropdownOpened}
        onClickOutside={(e) => {
          if (checkForParentDropDown(e.target)) {
            return false;
          }
          setDropdownOpened(false);
        }}
      >
        <ViewportConstrainer
          relativeElement={dropDownRef.current}
          positioning={horizontalAlign}
          verticalAlign={verticalAlign}
          padding={padding}
        >
          <div
            className={cls(styles.CommonDropdownItems, contentClassName, styles.DropDownSolid)}
            onClick={forceCloseOnSelect ? () => setDropdownOpened(false) : () => {}}
          >
            {items.map((item) => (
              <div key={item.key}>{item}</div>
            ))}
          </div>
        </ViewportConstrainer>
      </ClickOutsideCustomComponent>
    </Dropdown>
  );
};

CommonDropdown.propTypes = {
  dropdownTrigger: PropTypes.node,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  items: PropTypes.array,
  position: PropTypes.string, //TODO: change to enum with available options
};
