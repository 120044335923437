import React, { useState } from 'react';
import { iconWidget } from 'react-project/assets/Icons';
import { IconWidgetList } from '../iconList/IconWidgetList';
import { PopUpWrapper } from 'react-project/components/popUp/PopUpWrapper';
import { useSelector, useDispatch } from 'react-redux';
import { selectWidgetPosition } from 'react-project/redux/popups/selectors';
import { setWidgetStatus } from 'react-project/redux/popups/actions';
import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { RR_EVENT_ON_WIDGET_CLOSE } from 'shared/CSharedEvents';

const TITLE = 'Widgets';

export const WidgetPopup = () => {
  const [searchValue, setSearchValue] = useState();
  const dispatch = useDispatch();

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const onWidgetCreated = (widget) => {
    dispatch(setWidgetStatus(false));
  };

  const onNotAllowed = () => {
    commonSendEventFunction(RR_EVENT_ON_WIDGET_CLOSE);
  };

  const position = useSelector(selectWidgetPosition);

  return (
    <ClickOutsideCustomComponent
      onClickOutside={(e) => {
        const el = e.target;
        if (el.id === 'widget-tool-btn' || el.closest('#widget-tool-btn')) return;

        commonSendEventFunction(RR_EVENT_ON_WIDGET_CLOSE);
      }}
    >
      <PopUpWrapper
        icon={iconWidget}
        title={TITLE}
        position={position}
        minWidth="400px"
        height="auto"
      >
        {/* Widgets Search is not supportred at the moment , it will be implemented in the future */}
        {/* <SearchInput handleSearch={handleSearch} /> */}
        <div>
          <IconWidgetList
            onWidgetCreated={onWidgetCreated}
            searchValue={searchValue}
            currentTab="TABLE"
            onNotAllowed={onNotAllowed}
          />
        </div>
      </PopUpWrapper>
    </ClickOutsideCustomComponent>
  );
};

WidgetPopup.propTypes = {};
