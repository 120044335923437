import { produce } from 'immer';
import unionBy from 'lodash/unionBy';
import { handleActions } from 'redux-actions';
import { ANALYTICS_STATUS_SUCCESS } from 'shared/CSharedConstants';
import {
  insertCompareSessions,
  insertSessions,
  refreshCompareSessions,
  refreshSessions,
  setAnalyticsDataAction,
  setAnalyticsWasCancelled,
  setCancelAnalyticsRequest,
  setCompareMode,
  setHasAnalyticsData,
  setProjectApiKey,
  setSelectedSession,
  updateLastUsedDataConnections,
  updateLastUsedDataObjs,
  updateProfileCountries,
  updateSessionsLoadingStatus,
  updateStatus,
} from './actions';

const initialState = {
  status: ANALYTICS_STATUS_SUCCESS,
  data: null,
  attributeExplorerStatus: ANALYTICS_STATUS_SUCCESS,
  projectApiKey: null,
  profileAttributes: {
    countries: [],
  },
  sessions: {
    sessions: [],
    meta: {
      count: 0,
    },
    compareSessions: [],
    compareMeta: {
      count: 0,
    },
    isLoading: false,
  },
  selectedSession: null,
  compareModeEnabled: false,
  lastUsedDataObjs: [],
  lastUsedDataConnections: [],
  cancelAnalyticsRequest: false,
  analyticsWasCancelled: false,
  hasAnalyticsData: null,
};

export default handleActions(
  {
    [setAnalyticsWasCancelled.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.analyticsWasCancelled = action.payload;
      }),
    [setCancelAnalyticsRequest.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.cancelAnalyticsRequest = action.payload;
      }),
    [updateStatus.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.status = action.payload;
      }),
    [updateProfileCountries.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.profileAttributes.countries = action.payload;
      }),
    [updateSessionsLoadingStatus.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.sessions.isLoading = action.payload;
      }),
    [refreshSessions.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.sessions.sessions = _.uniqBy(action.payload.sessions, 'intId');
        draft.sessions.meta = action.payload.meta;
      }),
    [refreshCompareSessions.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.sessions.compareSessions = action.payload.sessions;
        draft.sessions.compareMeta = action.payload.meta;
      }),
    [insertSessions.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.sessions.sessions = unionBy(
          draft.sessions.sessions,
          action.payload.sessions,
          'intId',
        );
        draft.sessions.meta = action.payload.meta;
      }),
    [insertCompareSessions.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.sessions.compareSessions = unionBy(
          draft.sessions.compareSessions,
          action.payload.sessions,
          'intId',
        );
        draft.sessions.compareMeta = action.payload.meta;
      }),
    [setProjectApiKey.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.projectApiKey = action.payload;
      }),
    [setCompareMode.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.compareModeEnabled = action.payload;
      }),
    [setSelectedSession.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.selectedSession = action.payload.session;
      }),
    [updateLastUsedDataObjs.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.lastUsedDataObjs = action.payload;
      }),
    [updateLastUsedDataConnections.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.lastUsedDataConnections = action.payload;
      }),
    [setAnalyticsDataAction.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.data = action.payload;
      }),
    [setHasAnalyticsData.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.hasAnalyticsData = action.payload;
      }),
  },
  initialState,
);
