import React from 'react';
import styles from './Spinner.module.scss';

export const Spinner = (props) => {
  const override = {
    height: '24px',
    width: '24px',
    ...props,
  };
  return (
    <div style={override}>
      <div className={styles.ldsRing}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
