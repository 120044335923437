import ConnectionHelper from 'pixi-project/view/joint/ConnectionHelper';
import Utils from './Utils';

const DEFAULT_DOTTED_DOT_FRACTION = 0.02;
const DEFAULT_DOTTED_EMPTY_FRACTION = 0.03;
const DEFAULT_DOTTED_LENGTH = 300;

export default class LineDrawHelper {
  static drawDashedLine(
    graphics,
    pointA,
    pointB,
    solidLength = 10,
    gapLength = 8,
    forceClose = true,
    forceEdges = true,
  ) {
    const angle = Utils.angleAB(pointA, pointB);
    const lineLength = Utils.distanceAB(pointA, pointB);

    const lineV = new PIXI.Point(solidLength).setAngle(angle);
    const gapV = new PIXI.Point(gapLength).setAngle(angle);

    const startPoint = new PIXI.Point().copyFrom(pointA);
    const nextPoint = new PIXI.Point().copyFrom(startPoint);

    // The algorithm will keep drawing until it reaches the end point
    let i = 0;
    while (true) {
      if (i++ % 2 === 0) {
        // draw fragment line
        nextPoint.add(lineV);
        // If the framgent line that is going to be drawn exceeds the line
        if (Utils.distanceAB(pointA, nextPoint) > lineLength) {
          // If the line does not end with a gap
          if (Utils.distanceAB(pointA, startPoint) < lineLength) {
            if (forceEdges && Utils.distanceAB(startPoint, pointB) < solidLength / 3) {
              // If the frament is too short , better join it with its previous fragment
              // So we will draw a bit longer fragment
              startPoint.sub(gapV);
              LineDrawHelper.drawLine(graphics, startPoint, pointB);
            } else {
              LineDrawHelper.drawLine(graphics, startPoint, pointB);
            }
          } else if (forceClose) {
            // if the line ends with a gap , but we want to close the gap anyway
            startPoint.sub(gapV);
            LineDrawHelper.drawLine(graphics, startPoint, pointB);
          }
          break;
        }

        LineDrawHelper.drawLine(graphics, startPoint, nextPoint);
      } else {
        // make gap
        nextPoint.add(gapV);
        startPoint.copyFrom(nextPoint);
      }
    }
  }

  static drawLine(graphics, pointA, pointB) {
    graphics.moveTo(pointA.x, pointA.y);
    graphics.lineTo(pointB.x, pointB.y);
  }

  static drawDashedBezier(graphics, pointA, controlPointA, controlPointB, pointB) {
    let fraction = 0;

    // We calculate the length of the bezier curve and set
    // the length of each dash approximately the same
    const length = ConnectionHelper.GetLength(pointA, controlPointA, controlPointB, pointB);
    const dottedFraction = DEFAULT_DOTTED_DOT_FRACTION * (DEFAULT_DOTTED_LENGTH / length);
    const emptyFraction = DEFAULT_DOTTED_EMPTY_FRACTION * (DEFAULT_DOTTED_LENGTH / length);

    while (fraction < 1) {
      // Calculate the start of the dash
      const dottedStartPoint = ConnectionHelper.GetBezierPoint(
        pointA,
        controlPointA,
        controlPointB,
        pointB,
        fraction,
      );

      // Set the end fraction of the dash
      fraction += dottedFraction;
      // Fix for the case when we go above '1' fraction. We use '1' instead
      if (fraction > 1) {
        fraction = 1;
      }
      // Calculate the end of the dash
      const dottedFinishPoint = ConnectionHelper.GetBezierPoint(
        pointA,
        controlPointA,
        controlPointB,
        pointB,
        fraction,
      );

      LineDrawHelper.drawLine(graphics, dottedStartPoint, dottedFinishPoint);

      fraction += emptyFraction;
    }
  }

  static drawBezier(graphics, pointA, controlPointA, controlPointB, pointB) {
    graphics.moveTo(pointA.x, pointA.y);
    graphics.bezierCurveTo(
      controlPointA.x,
      controlPointA.y,
      controlPointB.x,
      controlPointB.y,
      pointB.x,
      pointB.y,
    );
  }
}
