import cls from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IndustryList, OfferTypeList, PurposeList } from 'react-project/Constants/canvasSharing';
import style from 'react-project/Sharing/CanvasLibrary.module.scss';
import ShareStyles from 'react-project/Sharing/ShareLink.module.scss';
import { toOptions } from 'react-project/components/input/Select-Options/utility';
import { Spinner } from 'react-project/components/spinner/spinner';
import { Switch } from 'react-project/components/switch/Switch';
import { useDispatch, useSelector } from 'react-redux';
import { LibraryForm } from './LibraryForm';
import Notifications from './Notifications';
import { SharedForm } from './SharedForm';
import { TermsConditions } from './TermsConditions';
import {
  asyncSetIsLinkPrivate,
  asyncUpdateSharedCanvasLibrary,
  setAlertMessage,
  setPreEnabled,
  setShowAlert,
} from './shareCanvasSlice';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import { When } from 'react-project/Util/When';

const FORM_MESSAGE = {
  HAS_ERROR: 'Please fix the errors in the form',
};

const SAVE_MAPPING = {
  SUCCESS: 'Your changes have been saved',
  SUCCESS_INIT: 'Your canvas is now shared',
  ERROR_GENERAL: 'Something went wrong, please try again',
};

const createMessage = (message, type = 'success') => {
  /**
   * @description Creates a message object to be used in the alert component
   * @param {string} message
   * @param {string} type - success or error
   * @returns {object}
   */

  return { message, type };
};

export const CanvasLibrary = () => {
  const dispatch = useDispatch();

  /** Library shared is enabled */
  const formDisabled = useRef();
  const funnelId = useSelector((state) => state.funnels.currentFunnelId);
  const preEnabled = useSelector((state) => state.canvasSharing.preEnabled);
  const isCanvasLibraryPublic = useSelector((state) => state.canvasSharing.isLibrarySharePublic);
  const canvasPermissions = useSelector(selectCanvasPermissions);
  const [isErrorShown, setIsErrorShown] = useState(false);

  /** Creating select values with data from Redux - start */
  const defaultIndustry = useSelector(
    (state) => state.canvasSharing.libraryDescriptionDefault.industry,
  );
  const defaultOfferType = useSelector(
    (state) => state.canvasSharing.libraryDescriptionDefault.offerType,
  );
  const defaultPurpose = useSelector(
    (state) => state.canvasSharing.libraryDescriptionDefault.purpose,
  );
  const [isNewToLibrary, setIsNewToLibrary] = useState(
    !defaultIndustry || !defaultOfferType || !defaultPurpose,
  );
  const industryEnum = isNewToLibrary ? ['Select an Option', ...IndustryList] : [...IndustryList];
  const offerTypeEnum = isNewToLibrary
    ? ['Select an Option', ...OfferTypeList]
    : [...OfferTypeList];
  const purposeEnum = isNewToLibrary ? ['Select an Option', ...PurposeList] : [...PurposeList];
  const [isSaving, setIsSaving] = useState(false);
  const [hasFormError, setHasFormError] = useState(false);
  const [hasDefaultValues, setHasDefaultValues] = useState(false);
  const loading = useSelector((state) => state.canvasSharing.formDataLoading);

  const [displayMsg, setDisplayMsg] = useState({});

  useEffect(() => {
    if (defaultIndustry && defaultOfferType && defaultPurpose) {
      dispatch(setPreEnabled(false));
      setIsNewToLibrary(false);
    }
  }, [defaultIndustry, defaultOfferType, defaultPurpose]);

  useEffect(() => {
    if (isCanvasLibraryPublic === null || isCanvasLibraryPublic === false) {
      formDisabled.current = true;
    } else {
      formDisabled.current = false;
    }
  }, [isCanvasLibraryPublic]);

  const dispatchAsyncUpdateSharedCanvasLibrary = useCallback((newFormValues) => {
    return dispatch(
      asyncUpdateSharedCanvasLibrary({
        props: { library: { ...newFormValues, enabled: true }, funnel: { id: funnelId } },
        actionType: 'update',
        isNewToLibrary,
      }),
    )
      .then((resp) => {
        setDisplayMsg(null);
        if (resp.error) {
          throw new Error(`There was an error in the response`);
        }
        setFormValues({ ...newFormValues });
        const msgObj = createMessage(SAVE_MAPPING.SUCCESS, 'success');
        setDisplayMsg(msgObj);
        setIsSaving(false);
      })
      .catch((e) => {
        setDisplayMsg(null);
        const msgObj = createMessage(SAVE_MAPPING.ERROR_GENERAL, 'error');
        setDisplayMsg(msgObj);
        setFormValues({ ...formValues });
      })
      .finally(() => {
        setIsSaving(false);
      });
  }, []);

  const debouncedSendRequest = useMemo(() => {
    return _.debounce(dispatchAsyncUpdateSharedCanvasLibrary, 3000);
  }, [dispatchAsyncUpdateSharedCanvasLibrary]);

  /** end */

  /** transform enum to valid options */
  const industryOption = toOptions(industryEnum);
  const offerTypeOption = toOptions(offerTypeEnum);
  const purposeOption = toOptions(purposeEnum);
  const [formValues, setFormValues] = useState({
    industry: defaultIndustry,
    offerType: defaultOfferType,
    purpose: defaultPurpose,
  });

  useEffect(() => {
    const { industry, offerType, purpose } = formValues;
    const hasError =
      !industry ||
      !offerType ||
      !purpose ||
      industry === 'Select an Option' ||
      offerType === 'Select an Option' ||
      purpose === 'Select an Option';
    const isDefaultVals =
      industry === defaultIndustry && offerType === defaultOfferType && purpose === defaultPurpose;
    setHasFormError(hasError);
    setHasDefaultValues(isDefaultVals);
    let resp;
    if (!hasError && !isDefaultVals) {
      setIsSaving(true);
      debouncedSendRequest(formValues);
    }
  }, [formValues.industry, formValues.offerType, formValues.purpose]);

  const triggerChange = (status) => {
    if (canvasPermissions.isTutorial) {
      setIsErrorShown(true);
      return;
    }

    if (isNewToLibrary) {
      dispatch(setPreEnabled(!!status));
      return;
    }
    dispatch(asyncUpdateSharedCanvasLibrary({ props: { library: { enabled: !!status } } }));
  };

  let isFormDisabled = preEnabled ? false : formDisabled.current;
  isFormDisabled = canvasPermissions.isTutorial ? true : isFormDisabled; // disable it in tutorial mode

  return (
    <div
      className={cls(ShareStyles.ShareCanvasContainer)}
      onBlur={() => {
        if (hasFormError) {
          dispatch(setAlertMessage(FORM_MESSAGE.HAS_ERROR));
          return dispatch(setShowAlert(true));
        }
      }}
    >
      <div className="flex justify-between pb-[12px]">
        <div className="flex">
          <div>
            <Switch
              onToggle={triggerChange}
              isActive={isCanvasLibraryPublic || preEnabled}
              disabled={isCanvasLibraryPublic === null}
              warn={preEnabled}
            />
          </div>
          <div>
            <div style={{ display: 'flex' }}>
              <div className={cls(style.toggleText)} style={{ color: '#636E84' }}>
                Share this Canvas in the Template Library
              </div>
              <div style={{ paddingLeft: '12px', transform: 'translate(0, -2px)' }}>
                {isSaving && <Spinner />}
              </div>
            </div>
            <Notifications messageObj={displayMsg} />
          </div>
        </div>
      </div>
      <div style={{ paddingLeft: '36px' }}>
        <div className={cls(ShareStyles.ShareCanvasCopyWarning, 'pb-[16px]')}>
          Enabling this toggle will allow other users to access this canvas from the Funnelytics™
          Template Library.
        </div>
        <div style={{ display: isCanvasLibraryPublic || preEnabled ? 'block' : 'none' }}>
          <LibraryForm
            options={{ industryOption, offerTypeOption, purposeOption }}
            formValues={formValues}
            loading={loading}
            isSaving={isSaving}
            setFormValues={setFormValues}
            hasFormError={hasFormError}
            formDisabled={isFormDisabled}
          />
          {/* <Tags /> */}
          <SharedForm disabled={canvasPermissions.isTutorial} />
          <TermsConditions />
        </div>
      </div>

      <When condition={isErrorShown}>
        <div
          className="flex flex-column"
          style={{
            color: 'red',
            marginTop: '20px',
          }}
        >
          You can't edit sharing settings in tutorial mode
        </div>
      </When>
    </div>
  );
};
