import React, { useEffect, useState } from 'react';
import { FiltersBlock } from './FiltersBlock';
import { DropdownBlock } from 'react-project/components/dropdown/DropdownBlock';
import { DropdownItem } from 'react-project/components/dropdown/DropdownItem';
import { RangePicker } from 'react-project/components/rangePicker/RangePicker';
import buttonStyles from 'react-project/components/secondaryButton/SecondaryButtons.module.scss';
import { CommonDropdown } from '../../components/dropdown/CommonDropdown';
import { SecondaryButton } from '../../components/secondaryButton/SecondaryButton';
import 'bootstrap-daterangepicker/daterangepicker.css';
import styles from './AllFiltersBlock.module.scss';
import cls from 'classnames';
import {
  ANALYTICS_STATUS_LOADING,
  COMPARE_FILTER_LABELS,
  DATA_MODE_FUNNEL,
  DATA_MODE_TRAFFIC,
  FILTER_TYPE_COMPARE,
  FILTER_TYPE_COMPARE_STEP,
} from 'shared/CSharedConstants';
import { FILTER_NAMES } from 'react-project/Constants/filters';

import { useDispatch, useSelector } from 'react-redux';
import {
  selectApplyCompareButtonEnabled,
  selectFilters,
} from 'react-project/redux/filters/selectors';
import {
  selectAnalyticsStatus,
  selectAnalyticsWasCancelled,
  selectCompareMode,
  selectSessions,
} from 'react-project/redux/analytics/selectors';
import {
  iconAddFilter,
  iconArrow,
  iconCalendar,
  iconForecasting,
  iconPeople,
  iconRemove,
} from 'react-project/assets/Icons';
import {
  selectFocusedCompareSteps,
  selectFocusedSteps,
} from 'react-project/redux/focused-step/selectors';
import {
  clearCompareFilterState,
  defaultFilters,
  setApplyCompareButtonEnabled,
  setFilter,
} from 'react-project/redux/filters/actions';
import { setCompareMode } from 'react-project/redux/analytics/actions';
import {
  RP_EVENT_BENCHMARK_FROM_ANALYTICS,
  RP_EVENT_LAYER_TOGGLED,
  RP_EVENT_REFRESH_REQUEST,
  RP_EVENT_STEP_FOCUS_CLEARED,
} from 'shared/CSharedEvents';
import { removeFocusedStep } from 'react-project/redux/focused-step/actions';
import { cloneData, commonSendEventFunction } from 'shared/CSharedMethods';
import {
  setActiveDataLayer,
  setCompareFilter,
  setCompareFilterData,
  setDataMode,
  setDataRange,
  setFocusedSteps,
} from 'react-project/redux/funnel-configuration/actions';
import { DEFAULT_DEVICES_STATE } from 'react-project/Constants/step-settings';
import { sendHeapTracking } from 'react-project/Util/HEAP.utilities';
import { HEAP_EVENTS } from 'react-project/Constants/heapEvents';
import { getDiffInDays } from 'react-project/Util/Dates';
import {
  resetPeopleTrackingPopups,
  resetPopupZIndex,
} from 'react-project/Toolbar/step-toolbar/PeopleTracking/peopleTrackingSlice';
import { selectCurrentStep } from 'react-project/redux/current-step/selectors';
import { selectExplorerItemsConfig } from 'react-project/redux/explorer/selectors';
import { selectFunnelConfiguration } from 'react-project/redux/funnel-configuration/selectors';
import { onSetExplorerItemsConfig } from 'react-project/redux/explorer/actions';
import { dateFormatter } from 'react-project/Util/DateFormat';
import {
  FILTER_BLOCK_TYPES,
  calculateLastPeriod,
  checkEnabledFilters,
  cleanAndCompare,
  compareCommonFilters,
} from './FilterHelpers';
import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';
import { track as trackCohesive } from 'react-project/Util/CohesiveTracking';
import { When } from 'react-project/Util/When';
import { LayerType } from 'react-project/LayersMenu/LayerType';
import MainStorage from 'pixi-project/core/MainStorage';
import { Warning } from 'react-project/components/popUp/Warning/Warning';
import { FF_FORECASTING } from 'shared/FeatureFlags';
import {
  LOCK_NO_ANALYTICS,
  LOCK_NO_COMPARE_DATE,
  LOCK_NO_COMPARE_PEOPLE,
  LOCK_NO_FORECASTING,
  UILock,
} from 'react-project/components/uilock/UILock';

export const AllCompareFiltersBlock = ({
  funnel,
  onApplyFilter,
  analyticsIsLoading,
  onClickCancelAnalytics,
  onClose,
  isOpen,
}) => {
  const dispatch = useDispatch();

  const currentStep = useSelector(selectCurrentStep);
  const explorerItemsConfig = useSelector(selectExplorerItemsConfig);
  const funnelConfiguration = useSelector(selectFunnelConfiguration);
  const sessions = useSelector(selectSessions);
  const analyticsStatus = useSelector(selectAnalyticsStatus);
  const compareModeEnabled = useSelector(selectCompareMode);
  const allFilters = useSelector(selectFilters);
  const applyButtonForceEnabled = useSelector(selectApplyCompareButtonEnabled);
  const analyticsWasCancelled = useSelector(selectAnalyticsWasCancelled);
  const focusedSteps = useSelector(selectFocusedSteps);
  const focusedCompareSteps = useSelector(selectFocusedCompareSteps);
  const [localFilterMonitor, setLocalFilterMonitor] = useState(null);
  const [lastToggleData, setLastToggleData] = React.useState(null);
  const [isWarrningOpen, setIsWarrningOpen] = React.useState(false);

  const { currentFilters, previousFilters } = allFilters;
  const selectedCompareFilter = funnelConfiguration.selectedCompareFilter;

  const isForecasting = funnelConfiguration.visibleDataLayers[LayerType.FORECASTING];

  useEffect(() => {
    dispatch(setFocusedSteps(focusedSteps, false));
    setStepFilterState(focusedSteps, false);
  }, [focusedSteps]);

  useEffect(() => {
    dispatch(setFocusedSteps(focusedCompareSteps, true));
    setStepFilterState(focusedCompareSteps, true);
  }, [focusedCompareSteps]);

  useEffect(() => {
    // This is a hack to make sure the filters are updated before saving them
    // By monitoring this variable we can make sure the filters are updated
    // This variable is only upldated when filters are being cleared.
    if (localFilterMonitor !== null) {
      // previous filters are being save inside clear Filter state
      // they don't need to be saved , as that will overwrite the clearing of the filters
      saveFilters(false);
    }
  }, [localFilterMonitor]);

  // If the forecasting layer is active , then the compare filter should be deleted
  useEffect(() => {
    document.addEventListener(RP_EVENT_LAYER_TOGGLED, onLayerToggled);

    return () => {
      document.removeEventListener(RP_EVENT_LAYER_TOGGLED, onLayerToggled);
    };
  }, [analyticsStatus, allFilters, compareModeEnabled]);

  // This code is related only to the forecasting data layer
  useEffect(() => {
    // The event for toggling the layer need to be sent once the state is updated
    // it is especially important because of MainStorage.setTogglePanelStatus(visibleDataLayers);

    if (lastToggleData) {
      setTimeout(() => {
        commonSendEventFunction(RP_EVENT_LAYER_TOGGLED, lastToggleData);
      }, 0);
    }
  }, [lastToggleData]);

  const onLayerToggled = (e) => {
    if (e.detail.type === LayerType.FORECASTING && e.detail.isActive) {
      // only if a compare filter is active
      if (compareModeEnabled) {
        onDeleteCompareFilter();
      }
    }
  };

  const setStepFilterState = (focusedSteps, isCompare) => {
    if (isCompare) {
      dispatch(
        setFilter({
          type: FILTER_NAMES.CURRENT_FILTERS,
          filterName: FILTER_NAMES.PEOPLE_COMPARE_FILTERS,
          value: { ...allFilters.currentFilters.peopleCompareFilters, focusedSteps },
        }),
      );
    } else {
      dispatch(
        setFilter({
          type: FILTER_NAMES.CURRENT_FILTERS,
          filterName: FILTER_NAMES.COMMON_FILTERS,
          value: { ...allFilters.currentFilters.commonFilters, focusedSteps },
        }),
      );
    }
  };

  const onForecastingClicked = (e) => {
    // check if numbers layer is active
    if (
      funnelConfiguration.dataMode === DATA_MODE_TRAFFIC &&
      funnelConfiguration.visibleDataLayers[LayerType.NUMBERS]
    ) {
      setIsWarrningOpen(true);
    } else {
      activateForecastingLayer(true);
    }
  };

  const activateForecastingLayer = (isActive) => {
    dispatch(setActiveDataLayer({ type: LayerType.FORECASTING, isActive }));
    const data = {
      type: LayerType.FORECASTING,
      isActive,
    };
    setLastToggleData(data);
  };

  const onWarrningClose = () => {
    setIsWarrningOpen(false);
  };

  const onTurnOnFunnelMode = () => {
    activateForecastingLayer(true);
    setIsWarrningOpen(false);
    dispatch(setDataMode(DATA_MODE_FUNNEL));
    ``;
  };

  const onRemoveFocusedStep = (data) => {
    dispatch(
      removeFocusedStep({
        step: cloneData(data.step),
        filterTypes: data.filterTypes,
      }),
    );
  };

  const clearStepFocusingFilter = (filterTypes) => {
    dispatch(removeFocusedStep({ filterTypes }));
    commonSendEventFunction(RP_EVENT_STEP_FOCUS_CLEARED, { types: filterTypes });
  };

  const clearFilters = () => {
    const lastPeriod = calculateLastPeriod(funnelConfiguration);
    clearStepFocusingFilter([FILTER_TYPE_COMPARE_STEP]);
    dispatch(clearCompareFilterState({ lastPeriod })).then(() => {
      // This is a hack , read the comment in the userEffect method monitoring this variable
      setLocalFilterMonitor(Math.random());
    });
  };

  const onSelectCompareFilter = (item) => {
    if (item.label === 'Forecast') {
      const currentDateString = new Date().toISOString();

      dispatch(setCompareMode(false));
      dispatch(setCompareFilterData({}));

      dispatch(setCompareFilter({ status: false })).then(() => {
        sendToStore(
          {
            min: dateFormatter(currentDateString),
            max: dateFormatter(currentDateString),
          },
          false,
        );
      });
      clearStepFocusingFilter([FILTER_TYPE_COMPARE_STEP]);

      onForecastingClicked();
      return;
    }

    const isForecastingLayerActive = funnelConfiguration.visibleDataLayers[LayerType.FORECASTING];
    // if forecasting was active , then deactivate it
    if (isForecastingLayerActive) {
      activateForecastingLayer(false);
    }

    // Send Heap event on selecting compare filter
    sendHeapTracking({
      projectId: funnel.projectId,
      eventName: HEAP_EVENTS.ADDED_COMPARE_DATA,
    });
    trackCohesive(HEAP_EVENTS.ADDED_COMPARE_DATA, {
      funnelId: funnel.id,
      projectId: funnel.projectId,
    });

    dispatch(setCompareFilter({ status: item || false })).then(() =>
      sendToStore(
        {
          min: dateFormatter(allFilters.currentFilters.commonFilters.inputStart),
          max: dateFormatter(allFilters.currentFilters.commonFilters.inputFinish),
          selectedLabel: item.label,
        },
        true,
      ),
    );

    dispatch(setCompareMode(true));
    dispatch(setApplyCompareButtonEnabled(true));
  };

  const onFilterRemoveClicked = () => {
    if (isForecasting) {
      activateForecastingLayer(false);
      return;
    }
    onDeleteCompareFilter();
  };

  const onDeleteCompareFilter = () => {
    const currentDateString = new Date().toISOString();

    dispatch(setCompareMode(false));
    dispatch(setCompareFilterData({}));
    sendToStore(
      {
        min: dateFormatter(currentDateString),
        max: dateFormatter(currentDateString),
      },
      true,
    );
    dispatch(setCompareFilter({ status: false }));
    clearStepFocusingFilter([FILTER_TYPE_COMPARE_STEP]);
    saveFilters();
  };

  const sendToStore = (dateRangeValue, compare = false) => {
    let intermediateValue;
    if (dateRangeValue.min !== dateRangeValue.max) {
      intermediateValue = {
        min: new Date(dateRangeValue.min).toISOString(),
        max: new Date(dateRangeValue.max).toISOString(),
        selectedLabel: dateRangeValue.selectedLabel,
      };
    } else {
      intermediateValue = {
        min: new Date(new Date(dateRangeValue.min).setHours(0, 0, 0, 0)).toISOString(),
        max: new Date(new Date(dateRangeValue.max).setHours(23, 59, 59, 0)).toISOString(),
        selectedLabel: dateRangeValue.selectedLabel,
      };
    }

    dispatch(setDataRange(intermediateValue, compare));

    if (
      compareModeEnabled &&
      !compare &&
      selectedCompareFilter?.label === COMPARE_FILTER_LABELS.PEOPLE
    ) {
      // Also set the date range for the compare filter
      dispatch(setDataRange(intermediateValue, true));
    }
  };

  const onBenchmarkFromAnalytics = () => {
    commonSendEventFunction(RP_EVENT_BENCHMARK_FROM_ANALYTICS);
    onClose();
  };

  const saveFilters = (savePreviousFilters = true) => {
    if (funnel.isInUnorganizedFolder) {
      return;
    }

    if (analyticsStatus === ANALYTICS_STATUS_LOADING) {
      commonSendEventFunction(RP_EVENT_REFRESH_REQUEST, { isCancel: true });
    }

    const { commonFilters, peopleCompareFilters, dateCompareFilter } = allFilters.currentFilters;

    const filters = {
      device: commonFilters.device || DEFAULT_DEVICES_STATE,
      countries: commonFilters.countries || [],
      contributionWindow: commonFilters.contributionWindow,
      focusLogicalOperator: commonFilters.focusLogicalOperator,
    };

    if (commonFilters.session) {
      filters.session = commonFilters.session.id;
    }

    dispatch(resetPeopleTrackingPopups());
    dispatch(resetPopupZIndex());

    const compareFilters = _createCompareFilters(filters);
    const filterDataForHeap = _createFilterDataForHead();
    sendHeapTracking({
      projectId: funnel.projectId,
      eventName: HEAP_EVENTS.COMPARE_MODE_FILTER,
      eventData: filterDataForHeap,
    });
    trackCohesive(HEAP_EVENTS.COMPARE_MODE_FILTER, {
      funnelId: funnel.id,
      projectId: funnel.projectId,
      ...filterDataForHeap,
    });

    const analyticsFilterBool = analyticsWasCancelled;

    dispatch(setCompareFilterData(compareFilters)).then(() => {
      commonSendEventFunction(RP_EVENT_REFRESH_REQUEST, { isCancel: analyticsFilterBool });
      _createAndSetExplorerConfig(filters);
      if (savePreviousFilters) {
        setPreviousFilters({ peopleCompareFilters, dateCompareFilter });
      }
    });

    dispatch(setApplyCompareButtonEnabled(false));

    onApplyFilter();
  };

  const _createFilterDataForHead = () => {
    const { commonFilters, peopleCompareFilters } = allFilters.currentFilters;
    const { selectedCompareFilter } = funnelConfiguration ?? {};
    let filterDataForHeap = {};
    if (compareModeEnabled) {
      if (selectedCompareFilter?.label === COMPARE_FILTER_LABELS.PEOPLE) {
        filterDataForHeap = {
          compareType: selectedCompareFilter?.label,
          numOfCountries: peopleCompareFilters.countries?.length || 0,
          device: peopleCompareFilters.device,
          peopleWhoPerformed: Boolean(
            peopleCompareFilters.focusedSteps && peopleCompareFilters.focusedSteps.length,
          ),
          people: Boolean(peopleCompareFilters.session),
        };
      } else {
        filterDataForHeap = {
          compareType: selectedCompareFilter?.label,
          days: getDiffInDays(
            funnelConfiguration.compareDateRange.min,
            funnelConfiguration.compareDateRange.max,
          ),
        };
      }
    } else {
      filterDataForHeap = {
        days: getDiffInDays(funnelConfiguration.dateRange.min, funnelConfiguration.dateRange.max),
        numOfCountries: commonFilters.countries?.length || 0,
        device: commonFilters.device,
        peopleWhoPerformed: Boolean(
          commonFilters.focusedSteps && commonFilters.focusedSteps.length,
        ),
        people: Boolean(commonFilters.session),
      };
    }
    return filterDataForHeap;
  };

  const _createCompareFilters = (filters) => {
    const { commonFilters, peopleCompareFilters } = allFilters.currentFilters;
    const { selectedCompareFilter } = funnelConfiguration ?? {};
    const isPeopleCompareFilterSelected =
      selectedCompareFilter?.label === COMPARE_FILTER_LABELS.PEOPLE;
    const compareFilters = isPeopleCompareFilterSelected
      ? {
          device: peopleCompareFilters.device || DEFAULT_DEVICES_STATE,
          countries: peopleCompareFilters.countries || [],
        }
      : { ...filters };

    if (isPeopleCompareFilterSelected && peopleCompareFilters.session) {
      compareFilters.session = peopleCompareFilters.session.id;
    } else if (!isPeopleCompareFilterSelected && commonFilters.session) {
      compareFilters.session = commonFilters.session.id;
    }

    if (commonFilters.inputStart) {
      compareFilters.inputStart = commonFilters.inputStart;
    }

    if (commonFilters.inputFinish) {
      compareFilters.inputFinish = commonFilters.inputFinish;
    }

    return compareFilters;
  };

  const _createAndSetExplorerConfig = (filters, commonFilters) => {
    const config = {
      funnelConfiguration: {
        ...funnelConfiguration,
        filter: filters,
      },
      funnel: funnel,
      currentStep: currentStep,
      port: false,
      explorerItemsConfig: explorerItemsConfig,
      mounted: true,
      focusedSteps: focusedSteps,
    };

    if (commonFilters) {
      config.funnelConfiguration.focusedSteps = commonFilters.focusedSteps;
    }

    const {
      currentFilters: { commonFilters: currentCommonFilter },
      previousFilters: { commonFilters: previousCommonFilter },
    } = allFilters;
    const disableCache = !compareCommonFilters(previousCommonFilter, currentCommonFilter);
    dispatch(onSetExplorerItemsConfig(config, disableCache));
  };

  const setPreviousFilters = ({ peopleCompareFilters, dateCompareFilter }) => {
    dispatch(
      setFilter({
        type: FILTER_NAMES.PREVIOUS_FILTERS,
        filterName: FILTER_NAMES.PEOPLE_COMPARE_FILTERS,
        value: peopleCompareFilters,
      }),
    );

    dispatch(
      setFilter({
        type: FILTER_NAMES.PREVIOUS_FILTERS,
        filterName: FILTER_NAMES.DATE_COMPARE_FILTER,
        value: dateCompareFilter,
      }),
    );
  };

  const isInsideDatepicker = (e) => {
    let isInsideDateRangePicker = false;
    let element = e.target;
    while (element) {
      if (element.classList.contains('daterangepicker')) {
        isInsideDateRangePicker = true;
        break;
      }
      element = element.parentElement;
    }

    return isInsideDateRangePicker;
  };

  const isToggleBtn = (e) => {
    const element = e.target;

    if (element.id === 'filter-compare-btn' || element.closest('#filter-compare-btn')) {
      return true;
    }

    return false;
  };

  const isDefaultFilter = cleanAndCompare(
    previousFilters,
    defaultFilters,
    true,
    selectedCompareFilter,
  );
  const hasAppliedFilters = cleanAndCompare(
    currentFilters,
    previousFilters,
    true,
    selectedCompareFilter,
  );

  return (
    <ClickOutsideCustomComponent
      onClickOutside={(e) => {
        if (!isOpen) {
          return;
        } else if (isInsideDatepicker(e)) {
          return;
        } else if (isToggleBtn(e)) {
          return;
        }

        onClose();
      }}
    >
      <div className={cls(styles.FilterWrapper, isOpen ? null : styles.HideFilter)}>
        {/* Compare Filters  */}

        <div className={styles.FilterDataBlock}>
          <When condition={!compareModeEnabled && !isForecasting}>
            <div className={styles.ChooseCompareFilter}>
              <div className={styles.CompareTitle}>Compare data by</div>
              <div className={styles.FilterOptions}>
                <UILock lock={LOCK_NO_COMPARE_DATE} style={{ width: '100%' }}>
                  <div
                    onClick={() => onSelectCompareFilter({ label: COMPARE_FILTER_LABELS.DATE })}
                    className={styles.FilterOption}
                  >
                    {iconCalendar} Date
                  </div>
                </UILock>

                <UILock lock={LOCK_NO_COMPARE_PEOPLE} style={{ width: '100%' }}>
                  <div
                    onClick={() => onSelectCompareFilter({ label: COMPARE_FILTER_LABELS.PEOPLE })}
                    className={styles.FilterOption}
                  >
                    {iconPeople()} People
                  </div>
                </UILock>
                <When condition={FF_FORECASTING}>
                  <UILock lock={LOCK_NO_FORECASTING} style={{ width: '100%' }}>
                    <div onClick={onForecastingClicked} className={styles.FilterOption}>
                      {iconForecasting} Forecast
                    </div>
                  </UILock>
                </When>
              </div>
            </div>
          </When>

          <When condition={compareModeEnabled}>
            <CompareHeader
              onSelectCompareFilter={onSelectCompareFilter}
              selectedCompareFilter={selectedCompareFilter}
              onFilterRemoveClicked={onFilterRemoveClicked}
            />
          </When>

          <When condition={!compareModeEnabled && isForecasting}>
            <CompareHeader
              onSelectCompareFilter={onSelectCompareFilter}
              selectedCompareFilter={{ label: 'Forecast' }}
              onFilterRemoveClicked={onFilterRemoveClicked}
              isForecasting={isForecasting}
            />
          </When>

          {selectedCompareFilter &&
            selectedCompareFilter.label === COMPARE_FILTER_LABELS.PEOPLE && (
              <FiltersBlock
                type={FILTER_TYPE_COMPARE}
                filtersSet={[
                  FILTER_BLOCK_TYPES.COUNTRIES,
                  FILTER_BLOCK_TYPES.DEVICE,
                  FILTER_BLOCK_TYPES.STEP,
                  FILTER_BLOCK_TYPES.PEOPLE,
                ]}
                currentFilters={allFilters.currentFilters.peopleCompareFilters}
                funnel={funnel}
                sessions={{ session: sessions.compareSessions, meta: sessions.compareMeta }}
                funnelConfiguration={funnelConfiguration}
                onClearSteps={() => clearStepFocusingFilter([FILTER_TYPE_COMPARE_STEP])}
                onRemoveFocusedStep={(data) => onRemoveFocusedStep(data)}
                setFilters={(newValue) =>
                  dispatch(
                    setFilter({
                      type: FILTER_NAMES.CURRENT_FILTERS,
                      filterName: FILTER_NAMES.PEOPLE_COMPARE_FILTERS,
                      value: {
                        ...allFilters.currentFilters.peopleCompareFilters,
                        ...newValue,
                      },
                    }),
                  )
                }
                sendToStore={(date) => sendToStore(date, true)}
                focusedSteps={focusedCompareSteps}
                isApplyButtonEnabled={
                  !(
                    analyticsStatus === ANALYTICS_STATUS_LOADING ||
                    (hasAppliedFilters && !applyButtonForceEnabled)
                  )
                }
                enabledFilters={checkEnabledFilters(previousFilters, currentFilters)}
              />
            )}
          {selectedCompareFilter && selectedCompareFilter.label === COMPARE_FILTER_LABELS.DATE && (
            <FiltersBlock
              type={FILTER_TYPE_COMPARE}
              filtersSet={[FILTER_BLOCK_TYPES.DATE]}
              currentFilters={allFilters.currentFilters.dateCompareFilter}
              funnel={funnel}
              sessions={{ session: sessions.compareSessions, meta: sessions.compareMeta }}
              funnelConfiguration={funnelConfiguration}
              setFilters={(newValue) =>
                dispatch(
                  setFilter({
                    type: FILTER_NAMES.CURRENT_FILTERS,
                    filterName: FILTER_NAMES.DATE_COMPARE_FILTER,
                    value: {
                      ...allFilters.currentFilters.dateCompareFilter,
                      ...newValue,
                    },
                  }),
                )
              }
              sendToStore={(date) => sendToStore(date, true)}
              isApplyButtonEnabled={
                !(
                  analyticsStatus === ANALYTICS_STATUS_LOADING ||
                  (hasAppliedFilters && !applyButtonForceEnabled)
                )
              }
              enabledFilters={checkEnabledFilters(previousFilters, currentFilters)}
            />
          )}
          {/* Range Picker */}
          {(selectedCompareFilter.label === COMPARE_FILTER_LABELS.PEOPLE ||
            selectedCompareFilter.label === COMPARE_FILTER_LABELS.DATE) && <RangePicker />}

          <When condition={!compareModeEnabled && isForecasting}>
            <RangePicker />
            <div className={styles.ForecastingDataBlock}>
              <span>
                Copy all analytics data into forecasting data , then modify it to see expected
                results
              </span>

              <UILock lock={LOCK_NO_ANALYTICS}>
                <SecondaryButton
                  title="Benchmark from Analytics"
                  disabled={false}
                  onClick={onBenchmarkFromAnalytics}
                  className={buttonStyles.ApplyFiltersButton}
                />
              </UILock>
            </div>
          </When>
        </div>

        <When condition={compareModeEnabled}>
          <div className={styles.FilterDataBlock}>
            <div className={styles.FiltersBtns}>
              <SecondaryButton
                title="Reset filters"
                disabled={analyticsStatus === ANALYTICS_STATUS_LOADING || isDefaultFilter}
                onClick={clearFilters}
              />
              <SecondaryButton
                title={analyticsIsLoading ? 'Cancel' : 'Apply filters'}
                disabled={
                  !analyticsIsLoading &&
                  hasAppliedFilters &&
                  !applyButtonForceEnabled &&
                  !analyticsWasCancelled
                }
                onClick={analyticsIsLoading ? onClickCancelAnalytics : saveFilters}
                className={buttonStyles.ApplyFiltersButton}
              />
            </div>
          </div>
        </When>
      </div>

      <When condition={isWarrningOpen}>
        <Warning onClose={onWarrningClose}>
          <div
            style={{
              display: 'Flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px',
              gap: '20px',
            }}
          >
            <div>
              You need to be in Funnel Mode to view the data
              <br />
              with both Numbers and Forecast layers
            </div>
            <div
              style={{
                cursor: 'pointer',
                color: 'white',
                fontWeight: 'normal',
                backgroundColor: '#4A90E2',
                padding: '10px',
                borderRadius: '4px',
              }}
              onClick={onTurnOnFunnelMode}
            >
              Turn on Funnel Mode
            </div>
          </div>
        </Warning>
      </When>
    </ClickOutsideCustomComponent>
  );
};

const DropdownTrigger = ({ ...rest }) => {
  const parameters = { ...rest };
  // isOpen is an invalid parameter for Dom Elements , so we need to remove it
  // before passing it to the Dom Element
  delete parameters.isOpen;
  const getIcon = (parameters) => {
    if (parameters.selectedcomparefilter && parameters.selectedcomparefilter.label) {
      return (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '5px',
          }}
        >
          {parameters.selectedcomparefilter.label}
          {iconArrow}
        </span>
      );
    } else {
      return iconAddFilter;
    }
  };
  return (
    <div className={styles.CompareDropdownTrigger} {...parameters}>
      {getIcon(parameters)}
    </div>
  );
};

const CompareHeader = ({
  onSelectCompareFilter,
  selectedCompareFilter,
  onFilterRemoveClicked,
  isForecasting,
}) => {
  return (
    <div className={styles.BlockTitle}>
      <span>Compare data by</span>
      <CommonDropdown
        forceCloseOnSelect={true}
        items={[
          <DropdownBlock isBorder key="block-1">
            <UILock key={COMPARE_FILTER_LABELS.DATE} lock={LOCK_NO_COMPARE_DATE}>
              <DropdownItem
                onClick={() => onSelectCompareFilter({ label: COMPARE_FILTER_LABELS.DATE })}
                label={COMPARE_FILTER_LABELS.DATE}
                icon={iconCalendar}
                data-ignore-click-outside={true}
              />
            </UILock>
            <UILock key={COMPARE_FILTER_LABELS.PEOPLE} lock={LOCK_NO_COMPARE_PEOPLE}>
              <DropdownItem
                onClick={() => onSelectCompareFilter({ label: COMPARE_FILTER_LABELS.PEOPLE })}
                label={COMPARE_FILTER_LABELS.PEOPLE}
                icon={iconPeople()}
                data-ignore-click-outside={true}
              />
            </UILock>

            <UILock key={'Forecasting'} lock={LOCK_NO_FORECASTING}>
              <DropdownItem
                onClick={() => onSelectCompareFilter({ label: 'Forecast' })}
                label={'Forecast'}
                key={'Forecasting'}
                icon={iconForecasting}
                data-ignore-click-outside={true}
              />
            </UILock>
          </DropdownBlock>,
        ]}
        dropdownTrigger={<DropdownTrigger selectedcomparefilter={selectedCompareFilter} />}
        contentClassName={styles.CompareDropdownContent}
      />
      <div onClick={onFilterRemoveClicked} className={styles.RemoveFilter}>
        {iconRemove}
      </div>
    </div>
  );
};
