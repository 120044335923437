import cls from 'classnames';
import { isNumber } from 'lodash';
import MainStorage from 'pixi-project/core/MainStorage';
import React, { useEffect, useState } from 'react';
import { iconForecasting } from 'react-project/assets/Icons';
import { setStepForecastingData } from 'react-project/redux/current-step/actions';
import { selectCurrentStep } from 'react-project/redux/current-step/selectors';
import ui from 'react-project/ui.module.scss';
import { When } from 'react-project/Util/When';
import { useDispatch, useSelector } from 'react-redux';
import { ActionTypes } from 'shared/CSharedConstants';
import SharedElementHelpers from 'shared/SharedElementHelpers';
import { FloatingWindow } from '../floatingWindow/FloatingWindow';
import { selectSelectedElements } from 'react-project/redux/selected-elements/selectors';
import { DropDown } from '../dropdowncomponent/dropdown';
import { Switch } from '../switch/Switch';
import { cloneData } from 'shared/CSharedMethods';

export const ForecastingConnectionView = ({ onClose }) => {
  const currentStep = useSelector(selectCurrentStep);
  const selectedElements = useSelector(selectSelectedElements);
  const dispatch = useDispatch();
  const [availableRootElements, setAvailableRootElements] = useState([]);

  const days = MainStorage.calendarData.days;

  useEffect(() => {
    const loopDetector = window.app.planeContainer.sceneManager.forecastingController.loopDetector;
    // get roots that lead to that step
    const rootNodeIds = loopDetector.findRootsContainingConnection(currentStep.stepId);

    // find the elements on the canvas
    const objects = window.app.planeContainer.sceneManager.objects;
    const roots = objects.filter((object) => rootNodeIds.includes(object.id));

    const rootElements = roots.map((root) => {
      return {
        id: root.id,
        titleText: root.titleText,
      };
    });

    setAvailableRootElements(rootElements);
  }, []);

  const isValidNumber = (value) => {
    const number = parseFloat(value);
    if (isNumber(number) && number.toString() === value) {
      return true;
    }
    return false;
  };

  const validateInput = (input) => {
    let isValid = false;
    if (isValidNumber(input.value)) {
      input.setCustomValidity('');
      isValid = true;
    } else {
      input.setCustomValidity('Please enter a number');
    }

    input.reportValidity();
    return isValid;
  };

  const onPercentChanged = (e) => {
    const isValid = validateInput(e.target);
    if (isValid) {
      const percent = parseFloat(e.target.value);
      dispatch(setStepForecastingData(currentStep.stepId, { percent }));
    } else {
      dispatch(setStepForecastingData(currentStep.stepId, { percent: null }));
    }
  };

  const onSpecificPercentChanged = (e, rootId) => {
    let streams = null;
    if (!currentStep.object.forecastingData.streams) {
      streams = {};
    } else {
      streams = cloneData(currentStep.object.forecastingData.streams);
    }

    streams[rootId] = parseFloat(e.target.value);

    dispatch(setStepForecastingData(currentStep.stepId, { streams }));
  };

  const getValue = (currentStep, prop) => {
    if (currentStep.object.forecastingData) {
      if (currentStep.object.forecastingData[prop] !== null) {
        return currentStep.object.forecastingData[prop];
      }
    }

    return '';
  };

  const getSpecificPercent = (currentStep, rootId) => {
    if (currentStep.object.forecastingData && currentStep.object.forecastingData.streams) {
      const streams = currentStep.object.forecastingData.streams;
      if (streams[rootId] !== undefined) {
        return streams[rootId];
      }
    }

    return 100;
  };

  const onSwitchToggle = (isActive) => {
    dispatch(setStepForecastingData(currentStep.stepId, { isAdvanced: isActive }));
  };

  const dropDownWidth = { width: '300px' };
  const inputStyle = { width: '220px', minWidth: '220px' };

  return (
    <div className={cls(ui.ContainerColumns, ui.Pad10_20, ui.AlignNormal)}>
      <div className={cls(ui.FlexJustify, ui.Text)}>
        Set the forecast for the element
        <div className={cls(ui.FlexEnd, ui.Gap5)}>
          Advanced:
          <Switch isActive={getValue(currentStep, 'isAdvanced')} onToggle={onSwitchToggle} />
        </div>
      </div>

      <When condition={!getValue(currentStep, 'isAdvanced')}>
        <div className={cls(ui.ContainerRows, ui.Pad10)}>
          <label className={ui.Label}>
            Conversion Rate <span>Average</span>
          </label>

          <input
            className={ui.Input}
            style={inputStyle}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={onPercentChanged}
            placeholder="0"
            type="text"
            defaultValue={getValue(currentStep, 'percent')}
            autoFocus
          />
        </div>
      </When>

      <When condition={getValue(currentStep, 'isAdvanced')}>
        {availableRootElements.map((rootElement) => {
          return (
            <div key={rootElement.id} className={cls(ui.ContainerRows, ui.Pad10)}>
              <label className={ui.Label}>
                {rootElement.titleText}
                <span>Conversion Rate</span>
              </label>

              <input
                className={ui.Input}
                style={inputStyle}
                onKeyDown={(e) => e.stopPropagation()}
                onChange={(е) => onSpecificPercentChanged(е, rootElement.id)}
                placeholder="0"
                type="text"
                defaultValue={getSpecificPercent(currentStep, rootElement.id)}
              />
            </div>
          );
        })}
      </When>
    </div>
  );
};
