import * as PIXI from 'pixi.js';
import { COLOR_ELEMENT_HIGHLIGHT_FRAME, COLOR_STEP_FOCUSED } from 'pixi-project/view/Styles';
import SelectionFocusFrame from './SelectionFocusFrame';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { RP_EVENT_FOCUS_FRAME_CLEARED } from 'shared/CSharedEvents';
import BaseSelectionLayer from './BaseSelectionLayer';

export default class FocusSelectionLayer extends BaseSelectionLayer {
  // Overwrite
  getLineColor(selectionFrame) {
    const isFocused = selectionFrame.element.isFocused && !selectionFrame.element.isHovered;
    return isFocused ? COLOR_STEP_FOCUSED : COLOR_ELEMENT_HIGHLIGHT_FRAME;
  }

  // Abstract method implementation
  hoverIn(event, element) {
    if (!element.isFocused) {
      let frame = new SelectionFocusFrame(element, this);
      this.addChild(frame);
      this.frames.push(frame);
      element.onFrameShow();
    }
    this.drawAll();
  }

  // Abstract method implementation
  hoverOut(event, element) {
    if (!element.isFocused) {
      this.removeFrameByElementId(element.id);
    }
    this.drawAll();
  }

  hide() {
    this.visible = false;
  }

  focus(step, filterType) {
    this.focusSingleElement(step, filterType);
    this.drawAll();
  }

  blur(step, filterType) {
    this.blurSingleElement(step, filterType);
    this.drawAll();
  }

  focusSingleElement(element, filterType) {
    element.isFocused = true;
    element.focusFilterTypes.push(filterType);
    let frame = this.findFrameByElementId(element.id);

    if (!frame) {
      frame = new SelectionFocusFrame(element, this);
      this.addChild(frame);
      this.frames.push(frame);
      element.onFrameShow();
    }

    frame.setFilterType(filterType);
  }

  blurSingleElement(step, filterType) {
    let frame = this.findFrameByElementId(step.id);
    if (frame) {
      this.removeFrameFilter(frame, filterType);
    }
  }

  removeFrameFilter(frame, filterType) {
    frame.removeFilterType(filterType);
    frame.element.focusFilterTypes.removeElement(filterType);
    if (!frame.isValid()) {
      this.frames.removeElement(frame);
      frame.removeFromParent();
      frame.element.isFocused = false;
      frame.element.onFrameHide();
    }
  }

  removeByFilterType(filterTypes) {
    for (let i = 0; i < filterTypes.length; i++) {
      const filterType = filterTypes[i];
      for (let j = this.frames.length - 1; j >= 0; j--) {
        const frame = this.frames[j];
        if (frame) {
          this.removeFrameFilter(frame, filterType);
        }
      }
    }

    this.drawAll();
  }

  removeByStep(filterTypes, step) {
    for (let i = 0; i < filterTypes.length; i++) {
      const filterType = filterTypes[i];
      for (let j = this.frames.length - 1; j >= 0; j--) {
        const frame = this.frames[j];
        if (frame && frame.element.id === step.ID) {
          this.removeFrameFilter(frame, filterType);
        }
      }
    }

    this.drawAll();
  }

  // Delegate handler
  onFrameRemoved(frame, filterType) {
    this.removeFrameFilter(frame, filterType);
    this.drawAll();
    window.app.needsRendering();

    const data = frame.element.getState();
    data.stepId = data.ID;

    commonSendEventFunction(RP_EVENT_FOCUS_FRAME_CLEARED, {
      step: data,
      filterType: filterType,
      hasFocus: true,
    });
  }
}
