import * as PIXI from 'pixi.js';
import { isCtrlKey } from 'shared/CSharedMethods';

// Delegates
// - onTEsc
// - onCaretChange
// - onTextChange

export default class TextEditable {
  constructor(delegate) {
    this.id = PIXI.utils.uid();
    this.delegate = delegate;

    this.element = null;

    this.preventKeyCombinations = ['b', 'B', 'i', 'I', 'u', 'U'];

    this.createEditable();
    this.bindControls();

    this.isCtrlDown = false;
    this.linesCount = 0;
  }

  createEditable() {
    this.createStyle();
    this.createElement();
    this.configureElement();
  }

  createStyle() {
    let resolution = window.app.renderer.resolution;
    let css = `
            #element${this.id}:active{ outline:none; }
            #element${this.id}:focus{ outline:none; }
            #element${this.id}{ 
                position: absolute ;
                margin: 0 ;            
                white-space: pre-wrap;   
                word-break: break-word;
                color: #000000;
                line-height: initial;
                caret-color: #000000;
                display:none;
                border: ${resolution}px solid #D64E82; 
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
            }
            
            #element${this.id}::-moz-selection{ background-color:#3297FD; }
            #element${this.id}::selection{ background-color:#3297FD; }
        `;

    let style = document.createElement('style');
    style.innerHTML = css;
    style.type = 'text/css';
    document.head.appendChild(style);
  }

  createElement() {
    let editable = document.createElement('div');
    editable.id = 'element' + this.id;
    editable.contentEditable = true;
    document.body.appendChild(editable);
    this.element = editable;
  }

  configureElement() {
    this.exec('insertBrOnReturn', false, true);
    this.exec('styleWithCSS', false, false);
    this.exec('defaultParagraphSeparator', false, 'br');
  }

  bindControls() {
    this.element.addEventListener('focus', this.onFocus.bind(this), false);
    this.element.addEventListener('keyup', this.onKeyUp.bind(this), false);
    this.element.addEventListener('keydown', this.onKeyDown.bind(this), false);
    this.element.addEventListener('mouseup', this.onMouseUp.bind(this), false);
    this.element.addEventListener('paste', this.onDocumentPaste.bind(this), false);

    this.element.addEventListener(
      'mousedown',
      function (event) {
        event.stopPropagation();
        return false;
      },
      false,
    );

    this.element.addEventListener(
      'selectstart',
      function (event) {
        event.stopPropagation();
        return false;
      },
      false,
    );

    this.element.addEventListener(
      'blur',
      function (event) {
        event.stopPropagation();
        return false;
      },
      false,
    );

    this.element.addEventListener('keypress', function (event) {
      const key = event.which || event.keyCode;
      if (key === 13) {
        // 13 is enter
        // code for enter
        event.stopPropagation();
        return false;
      }
    });
  }

  exec(a, b, c) {
    document.execCommand(a, b, c);
  }

  onFocus(e) {
    this.onDocumentUpdated(e, false);
  }

  onMouseUp(e) {
    this.onDocumentUpdated(e);
  }

  onKeyDown(e) {
    if (isCtrlKey(e)) {
      this.isCtrlDown = true;
    }
    this.onDocumentUpdated(e);
  }

  onKeyUp(e) {
    if (isCtrlKey(e)) {
      this.isCtrlDown = false;
    }
    this.onDocumentUpdated(e);
  }

  onDocumentUpdated(event, isUpdate = true) {
    this.cleanUpArtefacts();

    if (event) {
      event.stopPropagation();
      const key = event.which || event.keyCode;

      if (key === 27) {
        // on press ESC
        if (this.delegate && this.delegate.onTEsc) {
          this.delegate.onTEsc(this);
        }
        return true;
      }

      // Prevent key combinations such as Ctrl+B and Ctrl+I and Ctrl+U
      if (this.isCtrlDown && this.preventKeyCombinations.indexOf(event.key) > -1) {
        event.preventDefault();
        return;
      }
    }

    const label = this.getLabel();
    const ap = label.getGlobalPosition();
    const ax = label.anchor.x;
    const ay = label.anchor.y;

    this.setElementPosition(this.element, ap.x, ap.y, ax, ay);

    if (this.delegate && this.delegate.onCaretChange) {
      this.delegate.onCaretChange(event);
    }

    const linesCount = this.getCountOfLines();

    if (this.delegate && this.delegate.onTextChange && isUpdate) {
      this.delegate.onTextChange(linesCount !== this.linesCount);
    }

    this.linesCount = linesCount;

    return false;
  }

  onDocumentPaste(e) {
    e.preventDefault();

    // Get the copied text from the clipboard
    const text = e.clipboardData
      ? (e.originalEvent || e).clipboardData.getData('text/plain')
      : // For IE
        window.clipboardData
        ? window.clipboardData.getData('Text')
        : '';

    if (document.queryCommandSupported && document.queryCommandSupported('insertText')) {
      document.execCommand('insertText', false, text);
    } else {
      // Insert text at the current position of caret
      const range = document.getSelection().getRangeAt(0);
      range.deleteContents();

      const textNode = document.createTextNode(text);
      range.insertNode(textNode);
      range.selectNodeContents(textNode);
      range.collapse(false);

      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }

    this.onDocumentUpdated(e);
  }

  cleanUpArtefacts() {
    let innerText = this.element.innerText;
    innerText = innerText.replace(/\s/g, '');

    if (innerText === '') {
      this.element.innerHTML = '';
    }
  }

  selection() {
    return document.getSelection();
  }

  set(object) {
    this.selectedObject = object;
    const label = this.getLabel();
    const size = parseInt(this.getLabelStyle('fontSize'));

    this.element.style.lineHeight = label.maxLineHeight + 'px';
    this.element.style.textAlign = this.getLabelStyle('align');
    this.element.style.fontFamily = this.getLabelStyle('fontFamily');
    this.element.style.fontSize = size + 'px';

    if (this.getLabelStyle('wordWrap')) {
      this.element.style.width = this.getLabelStyle('wordWrapWidth') + 20 + 'px';
    } else {
      this.element.style.width = 'max-content';
    }
  }

  getLabel() {
    return this.selectedObject.textLabel;
  }

  getLabelStyle(key) {
    return this.selectedObject.textLabel.textStyles.default[key];
  }

  setStyle(key, value) {
    this.element.style[key] = value;
  }

  getStyle(key) {
    return this.element.style[key];
  }

  show(object) {
    this.set(object);
    object.visible = false;

    const label = this.getLabel();
    const ap = label.getGlobalPosition();

    this.setText(label.text);
    this.element.style.display = 'inline-block';
    this.element.style.zIndex = 1000;

    this.setElementPosition(this.element, ap.x, ap.y, label.anchor.x, label.anchor.y);

    this.moveCursorToEnd();

    this.isCtrlDown = false;
  }

  getCountOfLines() {
    const lineHeight = parseInt(
      window.getComputedStyle(this.element, null).getPropertyValue('line-height'),
    );
    return Math.floor(this.element.offsetHeight / lineHeight);
  }

  hide() {
    if (this.selectedObject) {
      this.selectedObject.visible = true;
      this.selectedObject = null;
    }
    this.element.style.display = 'none';
    this.element.style.zIndex = -1000;
    this.linesCount = 0;
  }

  toHtmlText(text) {
    let t = text.replace(/\r\n/g, '<br>');
    t = t.replace(/\n/g, '<br>');
    t = t.replace(/&/g, '&amp;');
    return t;
  }

  toPixiText(text) {
    let t = text + '';

    t = t.replace(/<br>/g, '\n');
    t = t.replace(/&amp;/g, '&');
    t = t.replace(/&gt;/g, '>');
    t = t.replace(/&lt;/g, '<');

    t = t.replace(/^\s+|\s+$/g, ''); // remove trailing new line

    return t;
  }

  toPixiTextWithNewLine(text) {
    let t = text + '';

    t = t.replace(/<br>/g, '\n');
    t = t.replace(/&amp;/g, '&');
    t = t.replace(/&gt;/g, '>');
    t = t.replace(/&lt;/g, '<');

    if (t.endsWith('\n')) {
      return t.replaceAll('\n\n', '\n');
    }

    return t;
  }

  getText = function () {
    return this.element.innerHTML
      .replace(/<div>/gi, '<br>')
      .replace(/<\/div>/gi, '')
      .replace(/&nbsp;/gi, ' ');
  };

  setText(text) {
    this.element.innerHTML = this.toHtmlText(text);
  }

  moveCursorToEnd() {
    // [optional] make sure focus is on the element
    this.element.focus();
    // // select all the content in the element
    document.execCommand('selectAll', false, null);
    // // collapse selection to the end
    document.getSelection().collapseToEnd();
  }

  setElementPosition(element, ix, iy, ax = 0.5, ay = 0.5) {
    const scale = window.app.viewport.scaled;
    const resolution = window.app.renderer.resolution;

    const basePadding = 8.5;

    const padding = basePadding * scale;

    const size = element.getBoundingClientRect();

    const canvas = window.app.renderer.view;

    const mtop = parseInt(canvas.style.marginTop) || 0;
    const mLeft = parseInt(canvas.style.marginLeft) || 0;

    const cwidth = parseInt(canvas.style.width);
    const cheight = parseInt(canvas.style.height);

    const fay = cheight / window.app.scaleManager.canvasHeight;
    const fax = cwidth / window.app.scaleManager.canvasWidth;

    const w = size.width - padding * 2 - resolution * 2;
    const h = size.height - padding * 2 - resolution * 2;

    const x = Math.round(ix * fax - w * ax + mLeft - padding) + 0 * scale;
    const y = Math.round(iy * fay - h * ay + mtop - padding) + 0.7 * scale;

    element.style.left = x + 'px';
    element.style.top = y + 'px';
    element.style.padding = basePadding + 'px';

    const transform = 'scale(' + fax * scale + ', ' + fay * scale + ')';
    element.style.transform = transform;
    element.style.transformOrigin = '0 0';
  }

  getAnchor() {
    return this.element.querySelector('a');
  }

  setAnchor(url) {
    let existingAnchor = this.getAnchor();
    if (!existingAnchor) {
      this.element.innerHTML = `<a href="${url}">${this.element.innerHTML}</a>`;
    } else {
      existingAnchor.href = url;
    }
    window.app.needsRendering();
  }

  removeAnchor() {
    let existingAnchor = this.getAnchor();
    if (existingAnchor) {
      existingAnchor.replaceWith(...existingAnchor.childNodes);
    }
    window.app.needsRendering();
  }
}
