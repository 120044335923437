import axios from 'axios';
import cls from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { SetupDescription } from 'react-project/components/trackingTab/SetupDescription';
import styles from 'react-project/components/trackingTab/TrackingTabAction.module.scss';
import {
  TEXT_CUSTOM_TRACKING_DESCRIPTION,
  TEXT_CUSTOM_TRACKING_SETUP_DESCRIPTION,
  TEXT_CUSTOM_TRACKING_SETUP_LINK_DESCRIPTION,
  TEXT_CUSTOM_TRACKING_SETUP_TITLE,
  TEXT_CUSTOM_TRACKING_TITLE,
} from 'react-project/Constants/texts';
import { getExplorerRequestBody } from 'react-project/Helpers/requestConstruction';
import { selectFocusedSteps } from 'react-project/redux/focused-step/selectors';
import RequestService from 'react-project/Helpers/RequestService';
import { selectCurrentStep } from 'react-project/redux/current-step/selectors';
import { selectFunnelConfiguration } from 'react-project/redux/funnel-configuration/selectors';
import { setIsModalOpened } from 'react-project/redux/modal/actions';
import { isAnalyticsInstalled } from 'react-project/Util/ActionsDetector';
import { useDispatch, useSelector } from 'react-redux';
import { ActionTypes } from 'shared/CSharedConstants';
import { Modal } from '../../components/modal/Modal';
import { CustomActionAttributes } from '../../components/trackingTab/custom-action/CustomActionAttributes';
import { CustomActionDropdown } from '../../components/trackingTab/custom-action/CustomActionDropdown';
import { TrackingContent } from '../../components/trackingTab/TrackingContent';

const requestService = new RequestService();
let source = axios.CancelToken.source();
const INSTRUCTIONS_LINK = 'https://hub.funnelytics.io/c/tracking-setup/base-script-install#:~:text=TRACKING%20CUSTOM%20ACTIONS';

const useCustomActionTypes = () => {
  const [actionTypes, setActionTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, []);

  const loadActionTypes = useCallback(
    async (projectId, reqBody) => {
      setIsLoading(true);

      source = axios.CancelToken.source();
      const response = await requestService.getEventExplorerData(projectId, reqBody, source);

      if (axios.isCancel(response)) {
        // Do Nothing
      } else if (response) {
        setActionTypes(
          response.next_actions.list.map((actionType) => ({
            ...actionType,
            hits: Number(actionType.hits),
          })),
        );
        setIsLoading(false);
      }
    },
    [setIsLoading, setActionTypes],
  );

  return {
    actionTypes,
    loadActionTypes,
    isLoading,
  };
};

export const CustomActionTracking = ({ projectId, onClose, onContentChanged }) => {
  const { actionTypes, loadActionTypes, isLoading } = useCustomActionTypes();
  const currentStep = useSelector(selectCurrentStep);
  const funnelConfiguration = useSelector(selectFunnelConfiguration);
  const focusedSteps = useSelector(selectFocusedSteps);
  const currentActionType = currentStep.object.actionName
    ? { name: currentStep.object.actionName }
    : {};

  const [modalOpened, setModalOpened] = useState(false);
  const [hasWorkspaceActions, setHasWorkspaceActions] = useState(false);
  const [hasWorkspaceActionsLoading, setHasWorkspaceActionsLoading] = useState(true);
  const [selectedActionType, setSelectedActionType] = useState(currentActionType);
  const [actionTypeDropdownOpened, setActionTypeDropdownOpened] = useState(false);

  const dispatch = useDispatch();

  const onOpenModal = () => {
    setModalOpened(true);
    dispatch(setIsModalOpened(true));
  };

  const onCloseModal = () => {
    setModalOpened(false);
    dispatch(setIsModalOpened(false));
  };

  useEffect(() => {
    isAnalyticsInstalled(projectId, ActionTypes.CUSTOM).then((exists) => {
      setHasWorkspaceActionsLoading(false);
      setHasWorkspaceActions(exists);
    });
  }, [projectId]);

  useEffect(() => {
    if (onContentChanged) {
      onContentChanged();
    }
  }, [hasWorkspaceActions, hasWorkspaceActionsLoading]);

  useEffect(() => {
    if (actionTypeDropdownOpened) {
      // Need to pass nameRequired to false so that custom action list is not  associate with selected action.
      // But in the case of using the PWP tool , the name is required
      console.warn('see how focused steps are used here');
      const nameRequired = focusedSteps && focusedSteps.selectedValue ? true : false;
      const reqBody = getExplorerRequestBody({
        funnelConfiguration,
        exploredStep: null,
        focusedSteps: focusedSteps,
        nameRequired,
      });

      loadActionTypes(projectId, _.omit(reqBody, 'filter.explored_step'));
    }
  }, [projectId, currentStep, funnelConfiguration, actionTypeDropdownOpened, focusedSteps]);

  return (
    <>
      <TrackingContent
        projectId={projectId}
        trackingTabTitle={TEXT_CUSTOM_TRACKING_TITLE}
        setupTitle={TEXT_CUSTOM_TRACKING_SETUP_TITLE}
        description={TEXT_CUSTOM_TRACKING_DESCRIPTION}
        setupInstructionsLink={INSTRUCTIONS_LINK}
        setupLinkDescription={TEXT_CUSTOM_TRACKING_SETUP_LINK_DESCRIPTION}
        setupDescription={TEXT_CUSTOM_TRACKING_SETUP_DESCRIPTION}
        hasWorkspaceActions={hasWorkspaceActions}
        hasWorkspaceActionsLoading={hasWorkspaceActionsLoading}
        onClose={onClose}
      >
        <div className={styles.DropwdownContainer}>
          <span className={styles.labelTitle}>action</span>

          <CustomActionDropdown
            actionTypes={actionTypes}
            selectedActionType={selectedActionType}
            setSelectedActionType={setSelectedActionType}
            openModal={onOpenModal}
            modalOpened={modalOpened}
            isLoading={isLoading}
            dropdownOpened={actionTypeDropdownOpened}
            setDropdownOpened={setActionTypeDropdownOpened}
          />
        </div>

        {currentStep.object.actionType !== ActionTypes.NONE && (
          <CustomActionAttributes projectId={projectId} />
        )}
      </TrackingContent>
      <Modal isOpen={modalOpened} onClose={onCloseModal} title={TEXT_CUSTOM_TRACKING_SETUP_TITLE}>
        <div className={cls(styles.SetupContent, styles.SetupModalContent)}>
          <SetupDescription
            setupDescription={TEXT_CUSTOM_TRACKING_SETUP_DESCRIPTION}
            setupInstructionsLink={INSTRUCTIONS_LINK}
            setupLinkDescription={TEXT_CUSTOM_TRACKING_SETUP_LINK_DESCRIPTION}
          />
        </div>
      </Modal>
    </>
  );
};

CustomActionTracking.propTypes = {
  projectId: PropTypes.string.isRequired,
};
