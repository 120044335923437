import React from 'react';
import {
  addItemIcon,
  arrowCollapsedIcon,
  arrowExpandedIcon,
  removeItemIcon,
  spreadItemIcon,
} from 'react-project/assets/Icons';
import styles from 'react-project/Toolbar/step-toolbar/AttributeExplorer/AttributeExplorerList.module.scss';
import cls from 'classnames';
import produce from 'immer';
import { When } from 'react-project/Util/When';
import { Tooltip } from 'react-project/components/tooltip/Tooltip';
import { Loader } from 'react-project/components/loader/Loader';
import { TEXTS_TOOLTIP, TEXT_LOAD_MORE } from 'react-project/Constants/texts';
import { TOOLTIP_POSITIONS } from 'react-project/Constants/tooltip';
import { useSelector } from 'react-redux';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';

export const AttributeExplorerList = ({
  title,
  list,
  onSelectionChanged,
  selectedData,
  expandedRows,
  setExpandedRows,
  canSpread = false,
  onSpread,
  canLoadMoreConfig,
  onLoadMore,
  isMoreLoading = false,
}) => {
  const canvasPermissions = useSelector(selectCanvasPermissions);

  const isItemPartialySelected = (key, selectedData) => {
    return selectedData[key] && Object.keys(selectedData[key]).length > 0;
  };

  const isItemCompletlySelected = (key, selectedData) => {
    if (selectedData[key] && selectedData[key]['*'] === true) {
      return true;
    }
    return false;
  };

  const toggleTopItemSelection = (key, index) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }

    if (isItemCompletlySelected(key, selectedData)) {
      // Deselect the item if it is selected
      const newData = produce(selectedData, (draft) => {
        delete draft[key];
        removeEmptyKeys(draft); // In case there was a key manualy entered with no value
      });
      onSelectionChanged(newData);
    } else {
      // Select all data
      const prepSelectedRows = { '*': true };
      const newData = produce(selectedData, (draft) => {
        draft[key] = prepSelectedRows;
        removeEmptyKeys(draft); // In case there was a key manualy entered with no value
      });
      onSelectionChanged(newData);
    }
  };

  const toggleSubItemSelection = (key, value) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }

    const newSelectedRows = produce(selectedData, (draft) => {
      if (!draft[key]) {
        draft[key] = {};
      }

      if (draft[key]['*'] === true) {
        // Toggling from "all selected" to "include all except this one"
        delete draft[key]['*'];
        const allKeys = getAllValues(key, list);
        draft[key] = allKeys;
        delete draft[key][value];
      } else if (draft[key][value] !== undefined) {
        // remove existing key
        delete draft[key][value];
      } else {
        // add new key
        draft[key][value] = true;
      }

      removeEmptyKeys(draft); // In case there was a key manualy entered with no value
    });

    onSelectionChanged(newSelectedRows);
  };

  const getAllValues = (key, list) => {
    const result = {};
    for (let i = 0; i < list.length; i++) {
      if (list[i].key === key) {
        for (let j = 0; j < list[i].values.length; j++) {
          const valueData = list[i].values[j];
          result[valueData.value] = true;
        }
      }
    }

    return result;
  };

  const removeEmptyKeys = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    });
  };

  const isSubItemSelected = (key, value) => {
    return selectedData[key] && selectedData[key][value] !== undefined;
  };

  const toggleItem = (index) => {
    const newExpandedRows = produce(expandedRows, (draft) => {
      draft[index] = !draft[index];
    });
    setExpandedRows(newExpandedRows);
  };

  const onSpreadIconClicked = (key, index) => {
    onSpread(list[index]);
  };

  return (
    <div className={styles.Wrap}>
      <div className={styles.Header}>
        <span className={styles.Title}>{title}</span>
        <span className={cls(styles.Subtitle, canSpread ? styles.SpreadCount : null)}>People</span>
      </div>
      <div className={styles.ListContainer}>
        {list.map((item, index) => (
          <div className={styles.Item} key={index}>
            {/* Top Item */}
            <div
              className={cls(
                styles.ItemHeader,
                isItemCompletlySelected(item.key, selectedData) ? styles.ItemIncluded : null,
                isItemPartialySelected(item.key, selectedData) ? styles.ItemPartialyIncluded : null,
              )}
            >
              <div className={cls(styles.SubWrap, styles.LeftWrap)}>
                <span
                  onClick={(e) => {
                    toggleItem(index);
                  }}
                >
                  {expandedRows[index] === true ? arrowExpandedIcon : arrowCollapsedIcon}
                </span>
                <span
                  className={styles.ItemName}
                  onClick={(e) => {
                    toggleItem(index);
                  }}
                >
                  {item.key}
                </span>
              </div>
              <div className={cls(styles.SubWrap, styles.RightWrap)}>
                <span>{item.hits}</span>
                <When condition={canSpread}>
                  <Tooltip label={TEXTS_TOOLTIP.SPREAD_ITEM} position={TOOLTIP_POSITIONS.TOP}>
                    <span
                      onClick={(e) => {
                        onSpreadIconClicked(item.key, index);
                      }}
                      className={styles.SpreadIcon}
                    >
                      {spreadItemIcon}
                    </span>
                  </Tooltip>
                </When>
                <When condition={!canvasPermissions.isReadonlyAccess}>
                  <span
                    onClick={(e) => {
                      toggleTopItemSelection(item.key, index);
                    }}
                  >
                    {isItemCompletlySelected(item.key, selectedData) ? removeItemIcon : addItemIcon}
                  </span>
                </When>
              </div>
            </div>

            {/* Sub Items */}
            <div
              className={cls(
                styles.ItemSubList,
                expandedRows[index] === true ? styles.ItemExpanded : styles.ItemCollapsed,
              )}
            >
              {item.values.map((value, valueIndex) => (
                <div
                  className={cls(
                    styles.SubItem,
                    isSubItemSelected(item.key, value.value) ||
                      isItemCompletlySelected(item.key, selectedData)
                      ? styles.ItemIncluded
                      : null,
                  )}
                  key={valueIndex}
                >
                  <span>{value.value}</span>
                  <div className={cls(styles.SubWrap, styles.RightWrap)}>
                    <span>{value.hits}</span>
                    <When condition={!canvasPermissions.isReadonlyAccess}>
                      <span
                        onClick={() => {
                          toggleSubItemSelection(item.key, value.value);
                        }}
                      >
                        {isSubItemSelected(item.key, value.value) ||
                        isItemCompletlySelected(item.key, selectedData)
                          ? removeItemIcon
                          : addItemIcon}
                      </span>
                    </When>
                  </div>
                </div>
              ))}
              {canLoadMoreConfig && canLoadMoreConfig[item.key] ? (
                <div className={cls(styles.LoadMore)} onClick={() => onLoadMore(item.key)}>
                  {isMoreLoading ? <Loader variant="grey-font-1" /> : TEXT_LOAD_MORE}
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
