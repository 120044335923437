import CommandMove from 'pixi-project/base/command-system/commands/CommandMove';

export const ALIGN_TOP = 'ALIGN_TOP';
export const ALIGN_CENTER_Y = 'ALIGN_CENTER_Y';
export const ALIGN_BOTTOM = 'ALIGN_BOTTOM';
export const ALIGN_LEFT = 'ALIGN_LEFT';
export const ALIGN_CENTER_X = 'ALIGN_CENTER_X';
export const ALIGN_RIGHT = 'ALIGN_RIGHT';
export const SPACING_HORIZONTAL = 'SPACING_HORIZONTAL';
export const SPACING_VERTICAL = 'SPACING_VERTICAL';
const SPACING_X = 30;
const SPACING_Y = 20;

const SPACING_CONN_X = 180;
const SPACING_CONN_Y = 90;

export default class AlignUtility {
  spaceObjects(objects, type) {
    const commands = [];
    const objectsToAlign = this.cloneSorted(objects, type);
    const object = objectsToAlign[0];

    let xLine = object.x;
    let yLine = object.y;

    for (let i = 0; i < objectsToAlign.length; i++) {
      const object = objectsToAlign[i];
      const nextObject = objectsToAlign[i + 1];
      const bounds = object.getLocalBounds();
      const isConnected = nextObject && object.isConnectedTo(nextObject);

      // Some objects have a title that is longer then the element body
      // This contributes to the total width of the measurment

      // This is the local width that excludes the title width
      const w = object.content.getLocalBounds().width * object.content.scale.x;
      const height = bounds.height;
      let width = 0;
      let titleSideSpacing = 0; // This is length of the title going outside the element body

      if (bounds.width > w) {
        // If there is an excess title on the sides
        let sideExcess = (bounds.width - w) / 2;
        width = w + sideExcess;
        // The first element will be ignored and not moved
        titleSideSpacing = i > 0 ? sideExcess : 0;
      } else {
        width = bounds.width;
      }

      let dx = 0;
      let dy = 0;

      if (type === SPACING_HORIZONTAL) {
        dx = titleSideSpacing + xLine;
        dy = object.y;
      } else if (type === SPACING_VERTICAL) {
        dx = object.x;
        dy = yLine;
      }

      const spacingX = isConnected ? SPACING_CONN_X : SPACING_X;
      const spacingY = isConnected ? SPACING_CONN_Y : SPACING_Y;

      xLine += titleSideSpacing + width * object.scale.x + spacingX;
      yLine += height * object.scale.y + spacingY;

      const from = new PIXI.Point(object.x, object.y);
      const to = new PIXI.Point(dx, dy);

      const command = new CommandMove(object, from, to);
      commands.push(command);
    }

    return commands;
  }

  cloneSorted(objects, type) {
    if (type === SPACING_HORIZONTAL) {
      return this.sortObjects(objects.slice(), 'x');
    } else if (type === SPACING_VERTICAL) {
      return this.sortObjects(objects.slice(), 'y');
    }
  }

  sortObjects(objects, property) {
    objects.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1;
      } else if (a[property] > b[property]) {
        return 1;
      }
      return 0;
    });

    return objects;
  }

  alignObjects(objects, type) {
    const edges = this.findAlignEdges(objects, this.getBounds(type));
    const commands = [];

    for (let i = 0; i < objects.length; i++) {
      const object = objects[i];
      const bounds = this.getBoundsByType(object, type);

      let dx = 0;
      let dy = 0;

      if (type === ALIGN_TOP) {
        dy = edges.minY - bounds.top;
      } else if (type === ALIGN_RIGHT) {
        dx = edges.maxX - bounds.right;
      } else if (type === ALIGN_BOTTOM) {
        dy = edges.maxY - bounds.bottom;
      } else if (type === ALIGN_LEFT) {
        dx = edges.minX - bounds.left;
      } else if (type === ALIGN_CENTER_X) {
        let cy = edges.minY + (edges.maxY - edges.minY) / 2;
        dy = cy - bounds.top - (bounds.bottom - bounds.top) / 2;
      } else if (type === ALIGN_CENTER_Y) {
        let cx = edges.minX + (edges.maxX - edges.minX) / 2;
        dx = cx - bounds.left - (bounds.right - bounds.left) / 2;
      }

      const scale = window.app.viewport.scaled;
      const from = new PIXI.Point(object.x, object.y);
      const to = new PIXI.Point(object.x + dx / scale, object.y + dy / scale);

      const command = new CommandMove(object, from, to);
      commands.push(command);
    }

    return commands;
  }

  findAlignEdges(objects, getBoundsMethod) {
    const b = getBoundsMethod(objects[0]);
    let minX = b.left;
    let maxX = b.right;
    let minY = b.top;
    let maxY = b.bottom;

    for (let i = 0; i < objects.length; i++) {
      const bounds = getBoundsMethod(objects[i]);

      if (minX > bounds.left) {
        minX = bounds.left;
      }

      if (maxX < bounds.right) {
        maxX = bounds.right;
      }

      if (minY > bounds.top) {
        minY = bounds.top;
      }

      if (maxY < bounds.bottom) {
        maxY = bounds.bottom;
      }
    }

    return { minX, maxX, minY, maxY };
  }

  getBounds(type) {
    return function (object) {
      return this.getBoundsByType(object, type);
    }.bind(this);
  }

  getBoundsByType(object, type) {
    if (type === ALIGN_CENTER_X) {
      return object.content.getBounds();
    } else {
      return object.getBounds();
    }
  }
}
