export const selectAnalyticsStatus = (state) => state.analytics.status;

export const selectProfileCountries = (state) => state.analytics.profileAttributes.countries;

export const selectSessions = (state) => state.analytics.sessions;

export const selectSession = (state) => state.analytics.selectedSession;

export const selectApiKey = (state) => state.analytics.projectApiKey;

export const selectCompareMode = (state) => state.analytics.compareModeEnabled;

export const selectDataObjs = (state) => state.analytics.lastUsedDataObjs;
export const selectDataConnections = (state) => state.analytics.lastUsedDataConnections;

export const selectCancelAnalyticsRequest = (state) => state.analytics.cancelAnalyticsRequest;
export const selectPreviousAnalytics = (state) => state.analytics.previousAnalytics;
export const selectAnalyticsWasCancelled = (state) => state.analytics.analyticsWasCancelled;
export const selectAnalyticsData = (state) => state.analytics.data;
export const selectHasAnalyticsData = (state) => state.analytics.hasAnalyticsData;
