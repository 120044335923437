import * as PIXI from 'pixi.js';
import Styles, { COLOR_DISPLAY_SEPARATOR } from 'pixi-project/view/Styles';
import TextDisplay from './TextDisplay';
import { ANALYTICS_DATA_DEFAULT_PLACEHOLDER, GOAL_DEFAULT_NAME } from 'shared/CSharedConstants';
import { SEPARATOR_THICKNESS } from 'pixi-project/core/BaseAnalyticsManager';
import { isGoalValid } from 'shared/ValidateHelpers';
import { shortenText } from 'shared/SharedHelpers';
import { numSeparator } from 'shared/NumberHelpers';
import { roundTo } from 'shared/CSharedMethods';

export default class CommonMultiDisplaySegment extends PIXI.Container {
  constructor() {
    super();
    this.textDisplay = new TextDisplay();
    this.addChild(this.textDisplay);
    this.contentWidth = 0;
    this.currency = '';
  }

  setData(displayData, goal, value, width) {
    const styledData = [];

    for (let i = 0; i < displayData.length; i++) {
      const pair = displayData[i];
      styledData.push(
        { value: pair[0], ...Styles.FOOTER_TITLE },
        { value: pair[1], ...Styles.FOOTER_NUMBER },
      );

      if (i !== displayData.length - 1) {
        styledData.push({
          isSeparator: true,
          color: COLOR_DISPLAY_SEPARATOR,
          thickness: SEPARATOR_THICKNESS,
          width: width,
        });
      }
    }

    if (isGoalValid(goal)) {
      const goalVal = parseFloat(value.toString().replace(/,/g, '')) * goal.goalValue;
      styledData.push(
        {
          isSeparator: true,
          color: COLOR_DISPLAY_SEPARATOR,
          thickness: SEPARATOR_THICKNESS,
          width: width,
        },
        { value: shortenText(goal.goalName, 20) || GOAL_DEFAULT_NAME, ...Styles.FOOTER_TITLE },
        {
          value: `${this.currency}${numSeparator(roundTo(goalVal, 2))}`,
          ...Styles.FOOTER_NUMBER,
        },
      );
    }

    this.textDisplay.setData(styledData);
  }

  resetData() {
    this.textDisplay.setData([ANALYTICS_DATA_DEFAULT_PLACEHOLDER]);
  }

  getArea() {
    return this.textDisplay.areaSize;
  }
}
