// Offer types
const OfferType = Object.freeze({
  GENERATE_APPLICATIONS: 'Generate Applications',
  GENERATE_LEADS: 'Generate Leads',
  GENERATE_RECURRING_REVENUE: 'Generate Recurring Revenue',
  GENERATE_SALES: 'Generate Sales',
  LIQUIDATE_AD_COSTS: 'Liquidate Ad Costs',
  SCHEDULE_APPOINTMENTS: 'Schedule Appointments',
  STOP_CANCELLATION: 'Stop Cancellation',
  STRATEGY_PLANNING: 'Strategy & Planning',
});

const Industry = Object.freeze({
  MARKETING: 'Marketing',
  NUTRITION_FITNESS: 'Nutrition/Fitness',
  SELF_HELP: 'Self Help',
  ECOMMERCE: 'eCommerce',
  LOCAL_BUSINESS: 'Local Business',
  REAL_ESTATE: 'Real Estate',
  TRAVEL: 'Travel',
  BUSINESS: 'Business',
  SALES: 'Sales',
  EVENT: 'Event',
  LEADERSHIP: 'Leadership',
  CONSULTING: 'Consulting',
  FRANCHISE: 'Franchise',
  AFFILIATE_MARKETING: 'Affiliate Marketing',
  SAAS: 'SaaS',
  OTHER: 'Other',
});

const Purpose = Object.freeze({
  FRAMEWORK: 'Framework',
  FUNNEL_BREAKDOWN: 'Funnel Breakdown',
  REPORTS: 'Reports',
});

export const IndustryList = Object.freeze([...Object.values(Industry)]);
export const PurposeList = Object.freeze([...Object.values(Purpose)]);
export const OfferTypeList = Object.freeze([...Object.values(OfferType)]);
