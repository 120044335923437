import React, { Children } from 'react';
import styles from './WidgetPopup.module.scss';
import { Rnd } from 'react-rnd';
import { WidgetBottomResizeComponent } from './WidgetBottomResizeComponent';
import PropTypes from 'prop-types';

const UNDER_HEADER_OFFSET = 60;
const POPUP_HEIGHT_VALUE = 393;

export const ModalWrapper = (props) => {
  const {
    children,
    position,
    isDraggable = true,
    cancel,
    height,
    width,
    minWidth,
    minHeight = undefined,
    rndRef,
    dragHandleClassName,
  } = props;

  const wrapperPosition = position
    ? {
        x: position.x,
        y: position.y + UNDER_HEADER_OFFSET,
      }
    : { x: 0, y: -UNDER_HEADER_OFFSET };

  return (
    <Rnd
      ref={rndRef}
      default={{
        width: width,
        height: height,
        minWidth: minWidth,
        ...wrapperPosition,
      }}
      disableDragging={!isDraggable}
      minWidth={minWidth}
      minHeight={minHeight || POPUP_HEIGHT_VALUE}
      bounds="window"
      dragHandleClassName={dragHandleClassName}
      resizeHandleComponent={{ bottomRight: <WidgetBottomResizeComponent /> }}
      enableResizing={{
        top: false,
        right: false,
        bottom: true,
        left: false,
        topRight: false,
        bottomRight: true,
        bottomLeft: false,
        topLeft: false,
      }}
      cancel={'.' + cancel}
    >
      <div className={styles.Container}>{children}</div>
    </Rnd>
  );
};

ModalWrapper.defaultProps = {
  height: POPUP_HEIGHT_VALUE,
};

ModalWrapper.propTypes = {
  dragHandleClassName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  position: PropTypes.object,
  isDraggable: PropTypes.bool,
  cancel: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  minHeight: PropTypes.number,
  minWidth: PropTypes.number,
  rndRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};
