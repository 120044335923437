import * as PIXI from 'pixi.js';

export default class MultiStyleText extends PIXI.Text {
  constructor(text, styles) {
    super(text);
    this.hitboxes = [];

    this.styles = styles;
    return this;
  }

  static DEFAULT_TAG_STYLE = {
    align: 'left',
    breakWords: false,
    dropShadow: false,
    dropShadowAngle: Math.PI / 6,
    dropShadowBlur: 0,
    dropShadowColor: '#000000',
    dropShadowDistance: 5,
    fill: 'black',
    fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
    fontFamily: 'Arial',
    fontSize: 26,
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: 'normal',
    letterSpacing: 0,
    lineHeight: 0,
    lineJoin: 'miter',
    miterLimit: 10,
    padding: 0,
    stroke: 'black',
    strokeThickness: 0,
    textBaseline: 'alphabetic',
    valign: 'baseline',
    wordWrap: false,
    wordWrapWidth: 100,
  };

  static debugOptions = {
    spans: {
      enabled: false,
      baseline: '#44BB44',
      top: '#BB4444',
      bottom: '#4444BB',
      bounding: 'rgba(255, 255, 255, 0.1)',
      text: true,
    },
    objects: {
      enabled: false,
      bounding: 'rgba(255, 255, 255, 0.05)',
      text: true,
    },
  };

  onTagPointerOver(e, tag) {
    // Needs Overloading
  }

  onTagPointerOut(e, tag) {
    // Needs Overloading
  }

  setTagStyle(tag, style) {
    if (tag in this.textStyles) {
      this.assign(this.textStyles[tag], style);
    } else {
      this.textStyles[tag] = this.assign({}, style);
    }
    this._style = new PIXI.TextStyle(this.textStyles['default']);
    this.dirty = true;
  }

  deleteTagStyle(tag) {
    if (tag === 'default') {
      this.textStyles['default'] = this.assign({}, MultiStyleText.DEFAULT_TAG_STYLE);
    } else {
      delete this.textStyles[tag];
    }
    this._style = new PIXI.TextStyle(this.textStyles['default']);
    this.dirty = true;
  }

  getTagRegex(captureName, captureMatch) {
    let tagAlternation = Object.keys(this.textStyles).join('|');
    if (captureName) {
      tagAlternation = '(' + tagAlternation + ')';
    } else {
      tagAlternation = '(?:' + tagAlternation + ')';
    }
    let reStr =
      '<' +
      tagAlternation +
      '(?:\\s+[A-Za-z0-9_\\-]+=(?:"(?:[^"]+|\\\\")*"|\'(?:[^\']+|\\\\\')*\'))*\\s*>|</' +
      tagAlternation +
      '\\s*>';
    if (captureMatch) {
      reStr = '(' + reStr + ')';
    }
    return new RegExp(reStr, 'g');
  }

  getPropertyRegex() {
    return new RegExp('([A-Za-z0-9_\\-]+)=(?:"((?:[^"]+|\\\\")*)"|\'((?:[^\']+|\\\\\')*)\')', 'g');
  }

  getTextDataPerLine(lines) {
    let outputTextData = [];
    let re = this.getTagRegex(true, false);

    let styleStack = [this.assign({}, this.textStyles['default'])];
    let tagStack = [{ name: 'default', properties: {} }];

    for (let i = 0; i < lines.length; i++) {
      let lineTextData = [];
      let matches = [];
      let matchArray = void 0;

      while ((matchArray = re.exec(lines[i]))) {
        matches.push(matchArray);
      }

      if (matches.length === 0) {
        lineTextData.push(
          this.createTextData(
            lines[i],
            styleStack[styleStack.length - 1],
            tagStack[tagStack.length - 1],
          ),
        );
      } else {
        let currentSearchIdx = 0;
        let _length = lineTextData.length;

        for (let j = 0; j < matches.length; j++) {
          if (matches[j].index > currentSearchIdx) {
            lineTextData.push(
              this.createTextData(
                lines[i].substring(currentSearchIdx, matches[j].index),
                styleStack[styleStack.length - 1],
                tagStack[tagStack.length - 1],
              ),
            );
          }

          if (matches[j][0][1] === '/') {
            if (styleStack.length > 1) {
              styleStack.pop();
              tagStack.pop();
            }
          } else {
            let properties = {};
            let propertyRegex = this.getPropertyRegex();
            let propertyMatch = void 0;
            while ((propertyMatch = propertyRegex.exec(matches[j][0]))) {
              properties[propertyMatch[1]] = propertyMatch[2] || propertyMatch[3];
            }
            let _tag = { name: matches[j][1], properties: properties };
            tagStack.push(_tag);

            let ss = this.assign(
              {},
              styleStack[styleStack.length - 1],
              this.textStyles[matches[j][1]],
            );
            this.updateStyle(ss, _tag);
            styleStack.push(ss);
          }
          currentSearchIdx = matches[j].index + matches[j][0].length;
        }

        if (currentSearchIdx < lines[i].length) {
          lineTextData.push(
            this.createTextData(
              lines[i].substring(currentSearchIdx),
              styleStack[styleStack.length - 1],
              tagStack[tagStack.length - 1],
            ),
          );
        }

        //  its an empty line
        if (_length === lineTextData.length) {
          lineTextData.push(
            this.createTextData(
              lines[i].substring(currentSearchIdx),
              styleStack[styleStack.length - 1],
              tagStack[tagStack.length - 1],
            ),
          );
        }
      }

      outputTextData.push(lineTextData);
    }

    return outputTextData;
  }

  updateStyle(style, tag) {
    // This method is used to update the style of individual tags
    const newStyles = {};
    for (let prop in tag.properties) {
      if (Object.prototype.hasOwnProperty.call(tag.properties, prop)) {
        const value = tag.properties[prop];

        if (prop === 'style' && value) {
          const _vals = value.split(';');

          for (let _i = 0; _i < _vals.length; _i++) {
            const _v = _vals[_i];
            const _keyValues = _v.split(':');

            if (_keyValues.length === 2) {
              let _styleKey = _keyValues[0].trim();
              const _styleValue = _keyValues[1].trim();
              if (_styleKey === 'font-size') {
                _styleKey = 'fontSize';
              } else if (_styleKey === 'text-align') {
                _styleKey = 'align';
              } else if (_styleKey === 'font-style') {
                _styleKey = 'fontStyle';
              }
              newStyles[_styleKey] = _styleValue;
            }
          }
        } else if (prop === 'face') {
          newStyles['fontFamily'] = value;
        } else if (prop === 'color') {
          newStyles['fill'] = value;
        }
      }
    }

    for (let prop in newStyles) {
      if (Object.prototype.hasOwnProperty.call(newStyles, prop)) {
        style[prop] = newStyles[prop];
      }
    }
  }

  getFontString(style) {
    return new PIXI.TextStyle(style).toFontString();
  }

  createTextData(text, style, tagName) {
    return {
      text: text,
      style: style,
      width: 0,
      height: 0,
      fontProperties: undefined,
      tag: tagName,
    };
  }

  getDropShadowPadding() {
    let _this = this;
    let maxDistance = 0;
    let maxBlur = 0;
    Object.keys(this.textStyles).forEach(function (styleKey) {
      let _a = _this.textStyles[styleKey],
        dropShadowDistance = _a.dropShadowDistance,
        dropShadowBlur = _a.dropShadowBlur;
      maxDistance = Math.max(maxDistance, dropShadowDistance || 0);
      maxBlur = Math.max(maxBlur, dropShadowBlur || 0);
    });
    return maxDistance + maxBlur;
  }

  clearHitboxes() {
    for (let i = this.hitboxes.length - 1; i >= 0; i--) {
      const hitboxesData = this.hitboxes[i];
      hitboxesData.element.removeFromParent();
    }
    this.hitboxes = [];
  }

  createHitbox(x, y, width, height, tag) {
    let element = new PIXI.Container();
    element.position.set(x, y);
    element.hitArea = new PIXI.Rectangle(0, 0, width, height);
    this.addChild(element);
    this.hitboxes.push({
      element: element,
      tag: tag,
    });

    element.interactive = true;
    element.on('pointerover', (e) => {
      return this.onTagPointerOver(e, tag);
    });
    element.on('pointerout', (e) => {
      return this.onTagPointerOut(e, tag);
    });
  }

  updateText() {
    let _this = this;
    if (!this.dirty) {
      return;
    }

    this.clearHitboxes();

    this.texture.baseTexture.resolution = this.resolution;
    let textStyles = this.textStyles;
    let outputText = this.text;
    if (this._style.wordWrap) {
      outputText = this.wordWrap(this.text);
    }
    let lines = outputText.split(/(?:\r\n|\r|\n)/);
    let outputTextData = this.getTextDataPerLine(lines);

    let lineWidths = [];
    let lineYMins = [];
    let lineYMaxs = [];
    let maxLineWidth = 0;
    let maxLineHeight = 0;
    this.linesData = outputTextData;

    for (let i = 0; i < lines.length; i++) {
      let lineWidth = 0;
      let lineYMin = 0;
      let lineYMax = 0;

      for (let j = 0; j < outputTextData[i].length; j++) {
        let sty = outputTextData[i][j].style;

        this.context.font = this.getFontString(sty);

        outputTextData[i][j].width = this.context.measureText(outputTextData[i][j].text).width;
        if (outputTextData[i][j].text.length === 0) {
          outputTextData[i][j].width += (outputTextData[i][j].text.length - 1) * sty.letterSpacing;
          if (j > 0) {
            lineWidth += sty.letterSpacing / 2;
          }
          if (j < outputTextData[i].length - 1) {
            lineWidth += sty.letterSpacing / 2;
          }
        }
        lineWidth += outputTextData[i][j].width;
        outputTextData[i][j].fontProperties = PIXI.TextMetrics.measureFont(this.context.font);
        outputTextData[i][j].height =
          outputTextData[i][j].fontProperties.fontSize + outputTextData[i][j].style.strokeThickness;
        if (typeof sty.valign === 'number') {
          lineYMin = Math.min(lineYMin, sty.valign - outputTextData[i][j].fontProperties.descent);
          lineYMax = Math.max(lineYMax, sty.valign + outputTextData[i][j].fontProperties.ascent);
        } else {
          lineYMin = Math.min(lineYMin, -outputTextData[i][j].fontProperties.descent);
          lineYMax = Math.max(lineYMax, outputTextData[i][j].fontProperties.ascent);
        }

        maxLineHeight = Math.max(maxLineHeight, outputTextData[i][j].height);
      }

      lineWidths[i] = lineWidth;
      lineYMins[i] = lineYMin;
      lineYMaxs[i] = lineYMax;
      maxLineWidth = Math.max(maxLineWidth, lineWidth);
    }

    this.maxLineHeight = maxLineHeight;

    let stylesArray = Object.keys(textStyles).map(function (key) {
      return textStyles[key];
    });
    let maxStrokeThickness = stylesArray.reduce(function (prev, cur) {
      return Math.max(prev, cur.strokeThickness || 0);
    }, 0);
    let dropShadowPadding = this.getDropShadowPadding();
    let totalHeight =
      lineYMaxs.reduce(function (prev, cur) {
        return prev + cur;
      }, 0) -
      lineYMins.reduce(function (prev, cur) {
        return prev + cur;
      }, 0);
    let width = maxLineWidth + maxStrokeThickness + 2 * dropShadowPadding;
    let height = totalHeight + 2 * dropShadowPadding;
    this.canvas.width = (width + this.context.lineWidth) * this.resolution;
    this.canvas.height = height * this.resolution;
    this.context.scale(this.resolution, this.resolution);
    this.context.textBaseline = 'alphabetic';
    this.context.lineJoin = 'round';
    let basePositionY = dropShadowPadding;
    let drawingData = [];
    for (let i = 0; i < outputTextData.length; i++) {
      let line = outputTextData[i];
      let linePositionX = void 0;
      switch (this._style.align) {
        case 'left':
          linePositionX = dropShadowPadding;
          break;
        case 'center':
          linePositionX = dropShadowPadding + (maxLineWidth - lineWidths[i]) / 2;
          break;
        case 'right':
          linePositionX = dropShadowPadding + maxLineWidth - lineWidths[i];
          break;
      }

      for (let j = 0; j < line.length; j++) {
        let _a = line[j],
          style = _a.style,
          text = _a.text,
          fontProperties = _a.fontProperties,
          width_1 = _a.width,
          height_1 = _a.height,
          tag = _a.tag;
        linePositionX += maxStrokeThickness / 2;
        let linePositionY = maxStrokeThickness / 2 + basePositionY + fontProperties.ascent;
        switch (style.valign) {
          case 'top':
            break;
          case 'baseline':
            linePositionY += lineYMaxs[i] - fontProperties.ascent;
            break;
          case 'middle':
            linePositionY +=
              (lineYMaxs[i] - lineYMins[i] - fontProperties.ascent - fontProperties.descent) / 2;
            break;
          case 'bottom':
            linePositionY +=
              lineYMaxs[i] - lineYMins[i] - fontProperties.ascent - fontProperties.descent;
            break;
          default:
            linePositionY += lineYMaxs[i] - fontProperties.ascent - style.valign;
            break;
        }

        if (style.letterSpacing === 0) {
          drawingData.push({
            text: text,
            style: style,
            x: linePositionX,
            y: linePositionY,
            width: width_1,
            ascent: fontProperties.ascent,
            descent: fontProperties.descent,
            tag: tag,
          });
          linePositionX += line[j].width;
        } else {
          this.context.font = this.getFontString(line[j].style);
          for (let k = 0; k < text.length; k++) {
            if (k > 0 || j > 0) {
              linePositionX += style.letterSpacing / 2;
            }
            drawingData.push({
              text: text.charAt(k),
              style: style,
              x: linePositionX,
              y: linePositionY,
              width: width_1,
              ascent: fontProperties.ascent,
              descent: fontProperties.descent,
              tag: tag,
            });
            linePositionX += this.context.measureText(text.charAt(k)).width;
            if (k < text.length - 1 || j < line.length - 1) {
              linePositionX += style.letterSpacing / 2;
            }
          }
        }
        linePositionX -= maxStrokeThickness / 2;
      }
      basePositionY += lineYMaxs[i] - lineYMins[i];
    }

    this.context.save();

    drawingData.forEach(function (_a) {
      let style = _a.style,
        text = _a.text,
        x = _a.x,
        y = _a.y;
      if (!style.dropShadow) {
        return;
      }
      _this.context.font = _this.getFontString(style);
      let dropFillStyle = style.dropShadowColor;
      if (typeof dropFillStyle === 'number') {
        dropFillStyle = PIXI.utils.hex2string(dropFillStyle);
      }
      _this.context.shadowColor = dropFillStyle;
      _this.context.shadowBlur = style.dropShadowBlur;
      _this.context.shadowOffsetX =
        Math.cos(style.dropShadowAngle) * style.dropShadowDistance * _this.resolution;
      _this.context.shadowOffsetY =
        Math.sin(style.dropShadowAngle) * style.dropShadowDistance * _this.resolution;
      _this.context.fillText(text, x, y);
    });

    this.context.restore();

    drawingData.forEach(function (_a) {
      let style = _a.style,
        text = _a.text,
        x = _a.x,
        y = _a.y,
        width = _a.width,
        ascent = _a.ascent,
        descent = _a.descent,
        tag = _a.tag;
      _this.context.font = _this.getFontString(style);
      let strokeStyle = style.stroke;
      if (typeof strokeStyle === 'number') {
        strokeStyle = PIXI.utils.hex2string(strokeStyle);
      }
      _this.context.strokeStyle = strokeStyle;
      _this.context.lineWidth = style.strokeThickness;
      let fillStyle = style.fill;

      if (typeof fillStyle === 'number') {
        fillStyle = PIXI.utils.hex2string(fillStyle);
      } else if (Array.isArray(fillStyle)) {
        for (let i = 0; i < fillStyle.length; i++) {
          let fill = fillStyle[i];
          if (typeof fill === 'number') {
            fillStyle[i] = PIXI.utils.hex2string(fill);
          }
        }
      }

      _this.context.fillStyle = _this._generateFillStyle(new PIXI.TextStyle(style), [text]);
      if (style.stroke && style.strokeThickness) {
        _this.context.strokeText(text, x, y);
      }
      if (style.fill) {
        _this.context.fillText(text, x, y);
      }
      let offset = -_this._style.padding - _this.getDropShadowPadding();
      _this.createHitbox(x + offset, y - ascent + offset, width, ascent + descent, tag);

      if (style.textDecoration && style.textDecoration === 'underline') {
        let fontSize = parseInt(style.fontSize);
        let yOffset = fontSize * 0.1;
        _this.context.lineWidth = fontSize * 0.085;
        _this.context.strokeStyle = style.fill;
        _this.context.beginPath();
        _this.context.moveTo(x, y + yOffset);
        _this.context.lineTo(x + width, y + yOffset);
        _this.context.closePath();
        _this.context.stroke();
      }
    });

    if (MultiStyleText.debugOptions.objects.enabled) {
      if (MultiStyleText.debugOptions.objects.bounding) {
        this.context.fillStyle = MultiStyleText.debugOptions.objects.bounding;
        this.context.beginPath();
        this.context.rect(0, 0, width, height);
        this.context.fill();
      }
      if (MultiStyleText.debugOptions.objects.text) {
        this.context.fillStyle = '#ffffff';
        this.context.strokeStyle = '#000000';
        this.context.lineWidth = 2;
        this.context.font = '8px monospace';
        this.context.strokeText(width.toFixed(2) + 'x' + height.toFixed(2), 0, 8, width);
        this.context.fillText(width.toFixed(2) + 'x' + height.toFixed(2), 0, 8, width);
      }
    }
    this.updateTexture();
  }

  wordWrap(text) {
    let result = '';
    let tags = Object.keys(this.textStyles).join('|');
    let re = new RegExp('(</?(' + tags + ')>)', 'g');
    let lines = text.split('\n');
    let wordWrapWidth = this._style.wordWrapWidth;
    let styleStack = [this.assign({}, this.textStyles['default'])];
    this.context.font = this.getFontString(this.textStyles['default']);
    for (let i = 0; i < lines.length; i++) {
      let spaceLeft = wordWrapWidth;
      let words = lines[i].split(' ');
      for (let j = 0; j < words.length; j++) {
        // Empty strings need to be removed
        let parts = words[j].split(re).filter((w) => w !== '');

        for (let k = 0; k < parts.length; k++) {
          if (re.test(parts[k])) {
            result += parts[k];
            if (parts[k][1] === '/') {
              k++;
              styleStack.pop();
            } else {
              k++;
              styleStack.push(
                this.assign({}, styleStack[styleStack.length - 1], this.textStyles[parts[k]]),
              );
            }
            this.context.font = this.getFontString(styleStack[styleStack.length - 1]);
            continue;
          }
          let partWidth = this.context.measureText(parts[k]).width;

          if (this._style.breakWords && partWidth > spaceLeft && partWidth < wordWrapWidth) {
            // If the word can't fit but can be fitten into the next line
            result += '\n';
            result += parts[k];
            spaceLeft = wordWrapWidth - partWidth;
          } else if (this._style.breakWords && partWidth > spaceLeft) {
            // If the word can't be fitted in the remaning space
            let characters = parts[k].split('');

            if (k === 0 && j !== 0) {
              // if its not the very first word , then bring it into a new line
              // then start breaking it
              spaceLeft = wordWrapWidth;
              result += '\n';
            }

            for (let c = 0; c < characters.length; c++) {
              let characterWidth = this.context.measureText(characters[c]).width;
              if (characterWidth > spaceLeft) {
                result += '\n' + characters[c];
                spaceLeft = wordWrapWidth - characterWidth;
              } else {
                result += characters[c];
                spaceLeft -= characterWidth;
              }
            }
          } else {
            let paddedPartWidth = partWidth + (k === 0 ? this.context.measureText(' ').width : 0);
            if (j === 0 || paddedPartWidth > spaceLeft) {
              if (j > 0) {
                result += '\n';
              }
              result += parts[k];
              spaceLeft = wordWrapWidth - partWidth;
            } else {
              spaceLeft -= paddedPartWidth;
              if (k === 0) {
                result += ' ';
              }
              result += parts[k];
            }
          }
        }
      }
      if (i < lines.length - 1) {
        result += '\n';
      }
    }
    return result;
  }

  updateTexture() {
    let texture = this._texture;
    let dropShadowPadding = this.getDropShadowPadding();
    texture.baseTexture.resolution = this.resolution;
    // setRealSize was altered to respect resolution
    // it will generate accurate texutre size in this way.
    // It is not known to me why its resoultion was always set to 1.
    texture.baseTexture.setRealSize(this.canvas.width, this.canvas.height, this.resolution);
    texture.baseTexture.width = this.canvas.width / this.resolution;
    texture.baseTexture.height = this.canvas.height / this.resolution;
    texture.trim.width = texture.frame.width = this.canvas.width / this.resolution;
    texture.trim.height = texture.frame.height = this.canvas.height / this.resolution;
    texture.trim.x = -this._style.padding - dropShadowPadding;
    texture.trim.y = -this._style.padding - dropShadowPadding;
    texture.orig.width = texture.frame.width - (this._style.padding + dropShadowPadding) * 2;
    texture.orig.height = texture.frame.height - (this._style.padding + dropShadowPadding) * 2;
    this._onTextureUpdate();
    texture.baseTexture.emit('update', texture.baseTexture);
    this.dirty = false;
  }

  assign = function (destination) {
    let sources = [];
    for (let _i = 1; _i < arguments.length; _i++) {
      sources[_i - 1] = arguments[_i];
    }
    for (let _a = 0, sources_1 = sources; _a < sources_1.length; _a++) {
      let source = sources_1[_a];
      for (let key in source) {
        destination[key] = source[key];
      }
    }
    return destination;
  };

  set styles(styles) {
    this.textStyles = {};
    this.textStyles['default'] = this.assign({}, MultiStyleText.DEFAULT_TAG_STYLE);
    for (let style in styles) {
      if (style === 'default') {
        this.assign(this.textStyles['default'], styles[style]);
      } else {
        this.textStyles[style] = this.assign({}, styles[style]);
      }
    }
    this._style = new PIXI.TextStyle(this.textStyles['default']);
    this.dirty = true;
  }
}
