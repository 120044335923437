import React from 'react';
import styles from './IconWidgetList.module.scss';
import { IconsWidgetConfig } from 'shared/CSharedConstants';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { RP_EVENT_WIDGET_DROPPED, RR_EVENT_ON_WIDGET_SELECTED } from 'shared/CSharedEvents';
import { EElementTypes } from '../../../shared/CSharedCategories';
import { useSelector } from 'react-redux';
import {
  selectAnalyticsData,
  selectProfileCountries,
  selectSessions,
} from 'react-project/redux/analytics/selectors';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import { UILock } from '../uilock/UILock';

export const IconWidgetList = ({
  searchValue = '',
  currentTab = 'TABLE',
  onWidgetCreated,
  onNotAllowed,
}) => {
  const icons = IconsWidgetConfig[currentTab];
  const countries = useSelector(selectProfileCountries);
  const sessions = useSelector(selectSessions);
  const anaytics = useSelector(selectAnalyticsData);
  const canvasPermissions = useSelector(selectCanvasPermissions);

  const getWidgetData = (id) => {
    switch (id) {
      case EElementTypes.WIDGET_COUNTRIES:
        return countries;
      case EElementTypes.WIDGET_PEOPLE:
        return sessions;
      case EElementTypes.WIDGET_GOALS:
        return anaytics;
      default:
        return null;
    }
  };

  const itemsFilterData = icons.filter((icon) => {
    const iconNameLower = icon.name.toLowerCase();
    const textSearchLower = searchValue.toLowerCase();

    return iconNameLower.indexOf(textSearchLower) > -1;
  });

  const onCreateWidget = (item, e) => {
    const data = getWidgetData(item.id);
    commonSendEventFunction(RP_EVENT_WIDGET_DROPPED, {
      position: {
        x: e.clientX,
        y: e.clientY,
      },
      object: { type: item.id, data },
    });

    onWidgetCreated(item);
  };

  const isAllowedCheck = (item) => {
    if (item.id !== EElementTypes.WIDGET_FORECASTING) {
      return canvasPermissions.widgetsAllowed;
    }

    if (canvasPermissions.isForecastAllowed && !canvasPermissions.isAnalyticsAllowed) {
      switch (item.id) {
        case EElementTypes.WIDGET_FORECASTING:
          return true;
        default:
          return false;
      }
    }

    return true;
  };

  return (
    <div className={styles.ListWrapper}>
      {itemsFilterData.map((item) => {
        const src = `${process.env.PUBLIC_URL}${item.src}${item.icon}.svg`;
        const isAllowed = isAllowedCheck(item);

        return (
          <UILock
            onClick={() => {
              onNotAllowed();
            }}
            key={item.id}
            isLocked={!isAllowed}
          >
            <div
              className={styles.ListItem}
              draggable={true}
              onDragEnd={(e) => {
                isAllowed && onCreateWidget(item, e);
              }}
              onClick={(e) => {
                onCreateWidget(item, e);
              }}
              onMouseDown={(e) => {
                commonSendEventFunction(RR_EVENT_ON_WIDGET_SELECTED);
                e.stopPropagation();
              }}
            >
              <div className={styles.ItemIcon}>
                <img name={item.name} src={src} alt="" draggable={false} />
              </div>
              <div className={styles.ItemLabel}>{item.name}</div>
            </div>
          </UILock>
        );
      })}
    </div>
  );
};
