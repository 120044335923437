import React, { useEffect, useState } from 'react';
import styles from './TutorialPopup.module.scss';
import { tutorialIcon } from 'react-project/assets/Icons';

const canvases = [
  {
    id: 'abb0b4ee-c0cb-43f2-8699-c40ebe3d1b23',
    title: 'Try Funnelytics Map ',
    description: (
      <>
        Use Funnelytics to <strong>map and present</strong> funnels and strategies
      </>
    ),
    icon: 'canvas-1.svg',
  },
  {
    id: 'ccf1d27c-babb-4b37-9df5-22e218b3768e',
    title: 'Try Funnelytics Performance',
    description: (
      <>
        Use Funnelytics to <strong>measure and optimize</strong> results
      </>
    ),
    icon: 'canvas-3.svg',
  },
  {
    id: '19a316a7-137a-44d2-b9ee-64d35e16b42d',
    title: 'Play with a blank canvas',
    description: 'Do you want to play on your own?',
    icon: 'blank-canvas.svg',
  },
];

export const TutorialPopup = ({ onCanvasSelection }) => {
  const onSegmentClicked = (canvas) => {
    onCanvasSelection(canvas.id);
  };

  return (
    <div className={styles.Background}>
      <div className={styles.Popup}>
        <div className={styles.Header}>
          <div className={styles.Icon}>{tutorialIcon}</div>
          <div className={styles.Title}> Funnelytics Demo Canvas</div>
        </div>
        <div className={styles.Content}>
          {canvases.map((canvas) => {
            return (
              <div
                onClick={() => {
                  onSegmentClicked(canvas);
                }}
                key={canvas.id}
                className={styles.Segment}
              >
                <div className={styles.SegmentIcon}>
                  <img src={`${process.env.PUBLIC_URL}/asset/tutorial/${canvas.icon}`} />
                </div>
                <div className={styles.SegmentContent}>
                  <div className={styles.SegmentDescription}>{canvas.description}</div>
                  <div className={styles.SegmentTitle}>{canvas.title}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
