import { isEqual } from 'lodash';
import { COMPARE_FILTER_LABELS } from 'shared/CSharedConstants';
import { cloneData } from 'shared/CSharedMethods';
import moment from 'moment';

export const FILTER_BLOCK_TYPES = {
  DATE: 'DATE',
  PEOPLE: 'PEOPLE',
  STEP: 'STEP',
  CONTRIBUTION: 'CONTRIBUTION',
  DEVICE: 'DEVICE',
  COUNTRIES: 'COUNTRIES',
};

export const isFilterEqual = (filterA, filterB) => {
  const a = compareCommonFilters(filterA.commonFilters, filterB.commonFilters);
  const b = isEqual(filterA.dateCompareFilter, filterB.dateCompareFilter);
  const c = isEqual(filterA.peopleCompareFilters, filterB.peopleCompareFilters);
  return a && b && c;
};

export const compareCommonFilters = (commonFilterA, commonFilterB) => {
  // It is important not to compare the inputStart & inputFinish
  const a = isEqual(commonFilterA.contributionWindow, commonFilterB.contributionWindow);
  const b = isEqual(commonFilterA.countries, commonFilterB.countries);
  const c = isEqual(commonFilterA.device, commonFilterB.device);
  const d = isEqual(commonFilterA.focusLogicalOperator, commonFilterB.focusLogicalOperator);
  const e = isEqual(commonFilterA.focusedSteps, commonFilterB.focusedSteps);
  const f = isEqual(commonFilterA.session, commonFilterB.session);
  return a && b && c && d && e && f;
};

export const calculateLastPeriod = (funnelConfiguration) => {
  const inputMin = funnelConfiguration.dateRange.min;
  const inputMax = funnelConfiguration.dateRange.max;
  const min = moment(inputMin);
  const max = moment(inputMax);

  const diff = max.diff(min, 'days');

  // detect a whole month
  if (diff === max.daysInMonth() - 1) {
    return [
      moment(inputMin).subtract(15, 'days').startOf('month'),
      moment(inputMin).subtract(15, 'days').endOf('month'),
    ];
  }

  return [moment(inputMin).subtract(diff + 1, 'days'), moment(inputMax).subtract(diff + 1, 'days')];
};

export const checkEnabledFilters = (previousFilter, currentFilter) => {
  // lets check them one by one
  const c1 = currentFilter.commonFilters;
  const c2 = previousFilter.commonFilters;

  const compare1 = currentFilter.peopleCompareFilters;
  const compare2 = previousFilter.peopleCompareFilters;

  return {
    [FILTER_BLOCK_TYPES.DATE]: c1.inputStart !== c2.inputStart || c1.inputFinish !== c2.inputFinish,
    [FILTER_BLOCK_TYPES.DEVICE]: c1.device !== c2.device,
    [FILTER_BLOCK_TYPES.PEOPLE]: c1.session !== c2.session,
    [FILTER_BLOCK_TYPES.COUNTRIES]: !isEqual(c1.countries, c2.countries),
    [FILTER_BLOCK_TYPES.STEP]:
      !isEqual(c1.focusedSteps, c2.focusedSteps) ||
      c1.focusLogicalOperator !== c2.focusLogicalOperator,
    [FILTER_BLOCK_TYPES.CONTRIBUTION]: !isEqual(c1.contributionWindow, c2.contributionWindow),
    compare: {
      [FILTER_BLOCK_TYPES.DATE]: !isEqual(
        currentFilter.dateCompareFilter,
        previousFilter.dateCompareFilter,
      ),
      [FILTER_BLOCK_TYPES.DEVICE]: compare1.device !== compare2.device,
      [FILTER_BLOCK_TYPES.PEOPLE]: compare1.session !== compare2.session,
      [FILTER_BLOCK_TYPES.COUNTRIES]: !isEqual(compare1.countries, compare2.countries),
      [FILTER_BLOCK_TYPES.STEP]: !isEqual(compare1.focusedSteps, compare2.focusedSteps),
    },
  };
};

export const cleanAndCompare = (filter1, filter2, isCompareMode, selectedCompareFilter) => {
  const filter1WithoutDate = cleanupFilter(filter1, isCompareMode, selectedCompareFilter);
  const filter2WithoutDate = cleanupFilter(filter2, isCompareMode, selectedCompareFilter);
  return isEqual(filter1WithoutDate, filter2WithoutDate);
};

const cleanupFilter = (filter, isCompareMode, selectedCompareFilter) => {
  const filterCleaned = cloneData(filter);

  // remove data that does not need to be compared
  if (isCompareMode) {
    if (filterCleaned.commonFilters) {
      delete filterCleaned.commonFilters;
    }

    if (selectedCompareFilter && selectedCompareFilter.label === COMPARE_FILTER_LABELS.DATE) {
      // comparing by date
      delete filterCleaned.peopleCompareFilters;
    } else if (
      selectedCompareFilter &&
      selectedCompareFilter.label === COMPARE_FILTER_LABELS.PEOPLE
    ) {
      // compare by people
      delete filterCleaned.dateCompareFilter;
      // remove range as it is not part of the filter
      delete filterCleaned.peopleCompareFilters.range;
    }
  } else {
    if (filterCleaned.commonFilters) {
      delete filterCleaned.commonFilters.inputStart;
      delete filterCleaned.commonFilters.inputFinish;
    }

    delete filterCleaned.dateCompareFilter;
    delete filterCleaned.peopleCompareFilters;
  }
  return filterCleaned;
};
