import cls from 'classnames';
import React, { Fragment } from 'react';
import { UPSELL_TEXTS, UPSELL_URLS } from 'react-project/Constants/upsells';
import styles from './VideoUpsell.module.scss';

export const VideoUpsell = ({ onClose, isEligibleForFreeTrial }) => {
  const onBtnClose = () => {
    onClose();
  };

  const onBackToCanvas = () => {
    onClose();
  };

  const btnHref = isEligibleForFreeTrial ? UPSELL_URLS.start_free_trial : UPSELL_URLS.upgrade;
  const btnText = isEligibleForFreeTrial ? UPSELL_TEXTS.start_free_trial : UPSELL_TEXTS.upgrade;

  return (
    <Fragment>
      <div className={cls(styles.Overlay, styles.OverlayActive)} />
      <div className={styles.upsellModal}>
        <div className={styles.upsellClose} onClick={onBtnClose}>
          &times;
        </div>
        <div className={styles.upsellContent}>
          <iframe
            className={styles.upsellVideo}
            src="https://player.vimeo.com/video/709139431?color&autopause=0&loop=0&muted=0&title=1&portrait=1&byline=1#t="
            allowFullScreen
          ></iframe>

          <div className={styles.upsellFooter}>
            <a target="_blank" href={btnHref} className={styles.upsellButton}>
              {btnText}
            </a>
            <button className={styles.upsellBack} onClick={onBackToCanvas}>
              back to canvas
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

VideoUpsell.propTypes = {};
