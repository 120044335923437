import { Resizable } from 're-resizable';
import React, { useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import { Rnd } from 'react-rnd';
import { v4 as uuidv4 } from 'uuid';

export const POSITIONING_BOTTOM = 'POSITIONING_BOTTOM'; // show only at bottom
export const POSITIONING_BOTTOM_TO_TOP = 'POSITIONING_BOTTOM_TO_TOP'; // try bottom first
export const POSITIONING_RIGHT_TO_LEFT = 'POSITIONING_RIGHT_TO_LEFT'; // try right first
export const POSITIONING_BOTTOM_TO_TOP_RIGHT_TO_LEFT = 'POSITIONING_BOTTOM_TO_TOP_RIGHT_TO_LEFT'; // try bottom first

export const VERTICAL_ALIGN_LEFT = 'VERTICAL_ALIGN_LEFT';
export const VERTICAL_ALIGN_CENTER = 'VERTICAL_ALIGN_CENTER';
export const VERTICAL_ALIGN_RIGHT = 'VERTICAL_ALIGN_RIGHT';

const VIEWPORT_PADDING = 10;
const RECTANGLE_PADDING = 10; // padding between rectangles when fitting

export const ViewportConstrainer = ({
  children,
  relativeRectangle = null, // It positions around the values of the rectangle
  relativeElement = null, // It positions around an HTML Element
  relativeMouseBox = null, // It creates a box around the current mouse position
  positioning = POSITIONING_BOTTOM_TO_TOP_RIGHT_TO_LEFT, // Prefered positioning
  verticalAlign = VERTICAL_ALIGN_LEFT, // Align edges with the relative element (used with POSITIONING_BOTTOM_TO_TOP)
  apiRef = null, // exposing the methods
  padding = RECTANGLE_PADDING,
  avoidRectangles = [],
  stickToOuterEdges = true, // used only when there is not enough space to fit the content
}) => {
  const { innerWidth, innerHeight } = window;
  const viewport = { width: innerWidth, height: innerHeight };
  const id = uuidv4();
  const testRectangle = { x: 0, y: 0, width: 0, height: 0 };

  useLayoutEffect(() => {
    updateContentPosition();
  }, [relativeRectangle, relativeElement]);

  const updateContentPosition = () => {
    // Find the dom representation of the ViewportConstrainer component
    const constrainerElement = document.getElementById(id);
    // Find the first Dom Element we are wrapping
    // and then adjust its position
    const contentElement = constrainerElement.children[0];
    requestAnimationFrame(() => {
      if (contentElement) {
        adjustContentPosition(contentElement, positioning);
      }
    });
  };

  // The API can be used externaly to rerender/reposition the content
  if (apiRef) {
    apiRef.current = { updateContentPosition };
  }

  const adjustContentPosition = (contentElement, positioning) => {
    // If there are relative elements to position the content around them
    // then take them into account when calculating the new position
    contentElement.style.left = 0;
    contentElement.style.top = 0;
    contentElement.style.right = '';
    contentElement.style.bottom = '';

    if (relativeElement) {
      const relRect =
        relativeElement instanceof Rnd
          ? relativeElement.resizableElement.current.getBoundingClientRect()
          : relativeElement.getBoundingClientRect();

      fitAroundRelativeRectangle(contentElement, relRect, positioning);
    } else if (relativeRectangle) {
      // fit around the first relative element
      fitAroundRelativeRectangle(contentElement, relativeRectangle, positioning);
    } else if (relativeMouseBox) {
      // Make a box around the mouse position
      const mousePosition = app.renderer.plugins.interaction.mouse.global;
      const r = {
        x: mousePosition.x - relativeMouseBox.width / 2,
        y: mousePosition.y - relativeMouseBox.height / 2,
        width: relativeMouseBox.width,
        height: relativeMouseBox.height,
      };
      fitAroundRelativeRectangle(contentElement, r, positioning);
    } else {
      // fit at mouse position
      fitAtMousePosition(contentElement);
    }
  };

  const fitAroundRelativeRectangle = (contentElement, relativeRectangle, positioning) => {
    const outerRect = getViewportRect();

    if (positioning === POSITIONING_BOTTOM) {
      setToBottom(contentElement, relativeRectangle, outerRect);
    } else if (positioning === POSITIONING_BOTTOM_TO_TOP) {
      fitBottomToTop(contentElement, relativeRectangle, outerRect);
    } else if (positioning === POSITIONING_RIGHT_TO_LEFT) {
      fitRightToLeft(contentElement, relativeRectangle, outerRect);
    } else if (positioning === POSITIONING_BOTTOM_TO_TOP_RIGHT_TO_LEFT) {
      fitBottomToTopToRightToLeft(contentElement, relativeRectangle, outerRect);
    }
  };

  const fitRightToLeft = (contentElement, relativeRectangle, outerRect) => {
    if (fitAtRight(contentElement, relativeRectangle, outerRect)) {
      // The content fits at the bottom
    } else if (fitAtLeft(contentElement, relativeRectangle, outerRect)) {
      // The content fits at the top
    } else {
      fitLeftRight(contentElement, relativeRectangle, outerRect);
    }
  };

  const fitBottomToTop = (contentElement, relativeRectangle, outerRect) => {
    if (fitAtBottom(contentElement, relativeRectangle, outerRect)) {
      // The content fits at the bottom
    } else if (fitAtTop(contentElement, relativeRectangle, outerRect)) {
      // The content fits at the top
    } else {
      // place the content at the top
      fitAtRemaining(contentElement, relativeRectangle, outerRect);
    }
  };

  const fitBottomToTopToRightToLeft = (contentElement, relativeRectangle, outerRect) => {
    if (fitAtBottom(contentElement, relativeRectangle, outerRect)) {
      // The content fits at the bottom
    } else if (fitAtTop(contentElement, relativeRectangle, outerRect)) {
      // The content fits at the top
    } else {
      fitRightToLeft(contentElement, relativeRectangle, outerRect);
    }
  };

  const setToBottom = (contentElement, relativeRectangle, outerRect) => {
    const contentRect = contentElement.getBoundingClientRect();
    const y = relativeRectangle.y + relativeRectangle.height + padding;

    const x = getVerticalAlignEdge(verticalAlign, relativeRectangle, contentRect);

    updateTestRectangle(x, y, contentRect.width, contentRect.height);
    const translate = translateToFit(testRectangle, outerRect);

    updateElementPosition(contentElement, x + translate.x, y);

    return true;
  };

  const fitAtBottom = (contentElement, relativeRectangle, outerRect) => {
    const contentRect = contentElement.getBoundingClientRect();
    const y = relativeRectangle.y + relativeRectangle.height + padding;

    if (y + contentRect.height < outerRect.y + outerRect.height && y > outerRect.y) {
      // it fits on the Y axis

      const x = getVerticalAlignEdge(verticalAlign, relativeRectangle, contentRect);

      if (
        intersectsWithRectangles(new PIXI.Rectangle(x, y, contentRect.width, contentRect.height))
      ) {
        return false;
      }

      updateTestRectangle(x, y, contentRect.width, contentRect.height);
      const translate = translateToFit(testRectangle, outerRect);

      updateElementPosition(contentElement, x + translate.x, y);

      return true;
    }

    return false;
  };

  const fitAtTop = (contentElement, relativeRectangle, outerRect) => {
    const contentRect = contentElement.getBoundingClientRect();
    let y = relativeRectangle.y - contentRect.height - padding;

    if (y + contentRect.height > outerRect.y + outerRect.height) {
      y = outerRect.y + outerRect.height - contentRect.height - padding;
    }

    if (y > outerRect.y) {
      const x = getVerticalAlignEdge(verticalAlign, relativeRectangle, contentRect);

      if (
        intersectsWithRectangles(new PIXI.Rectangle(x, y, contentRect.width, contentRect.height))
      ) {
        return false;
      }

      updateTestRectangle(x, y, contentRect.width, contentRect.height);
      const translate = translateToFit(testRectangle, outerRect);

      updateElementPosition(contentElement, x + translate.x, y);

      return true;
    }

    return false;
  };

  const fitAtRemaining = (contentElement, relativeRectangle, outerRect) => {
    const contentRect = contentElement.getBoundingClientRect();
    const y = outerRect.y + padding;

    const x = relativeRectangle.x + relativeRectangle.width / 2 - contentRect.width / 2;

    updateTestRectangle(x, y, contentRect.width, contentRect.height);
    const translate = translateToFit(testRectangle, outerRect);

    updateElementPosition(contentElement, x + translate.x, y);
  };

  const getVerticalAlignEdge = (verticalAlign, relativeRectangle, contentRect) => {
    if (verticalAlign === VERTICAL_ALIGN_CENTER) {
      return relativeRectangle.x + relativeRectangle.width / 2 - contentRect.width / 2;
    } else if (verticalAlign === VERTICAL_ALIGN_LEFT) {
      return relativeRectangle.x;
    } else if (verticalAlign === VERTICAL_ALIGN_RIGHT) {
      return relativeRectangle.x + relativeRectangle.width;
    }
  };

  const updateTestRectangle = (x, y, width, height) => {
    testRectangle.x = x;
    testRectangle.y = y;
    testRectangle.width = width;
    testRectangle.height = height;
  };

  const fitAtRight = (contentElement, relativeRectangle, outerRect) => {
    const contentRect = contentElement.getBoundingClientRect();
    const x = relativeRectangle.x + relativeRectangle.width + padding;

    if (x + contentRect.width < outerRect.x + outerRect.width && x > outerRect.x) {
      // it fits on the X axis
      const y = relativeRectangle.y;

      if (
        intersectsWithRectangles(new PIXI.Rectangle(x, y, contentRect.width, contentRect.height))
      ) {
        return false;
      }

      updateTestRectangle(x, y, contentRect.width, contentRect.height);
      const translate = translateToFit(testRectangle, outerRect);

      updateElementPosition(contentElement, x, y + translate.y);

      return true;
    }

    return false;
  };

  const fitAtLeft = (contentElement, relativeRectangle, outerRect) => {
    const contentRect = contentElement.getBoundingClientRect();
    const x = relativeRectangle.x - contentRect.width - padding;

    if (x + contentRect.width <= relativeRectangle.x - padding && x > outerRect.x) {
      // it fits on the X axis
      const y = relativeRectangle.y;

      if (
        intersectsWithRectangles(new PIXI.Rectangle(x, y, contentRect.width, contentRect.height))
      ) {
        return false;
      }

      updateTestRectangle(x, y, contentRect.width, contentRect.height);
      const translate = translateToFit(testRectangle, outerRect);

      updateElementPosition(contentElement, x, y + translate.y);

      return true;
    }

    return false;
  };

  const fitLeftRight = (contentElement, relativeRectangle, outerRect) => {
    const contentRect = contentElement.getBoundingClientRect();

    //If the space available on the left side is bigger then the space available
    // on the right side of the relative element
    const isLeftSpaceBigger =
      relativeRectangle.x > outerRect.width - relativeRectangle.x - relativeRectangle.width;

    let x = 0;
    if (stickToOuterEdges) {
      x = isLeftSpaceBigger ? 0 : outerRect.x + outerRect.width;
    } else {
      // stick to the relative rectangle
      x = isLeftSpaceBigger
        ? relativeRectangle.x - contentRect.width - padding
        : relativeRectangle.x + relativeRectangle.width + padding;
    }

    const y = relativeRectangle.y;
    updateTestRectangle(x, y, contentRect.width, contentRect.height);
    const translate = translateToFit(testRectangle, outerRect);

    updateElementPosition(contentElement, x + translate.x, y + translate.y);

    return true;
  };

  const fitAtMousePosition = (contentElement) => {
    const contentRect = contentElement.getBoundingClientRect();
    const mousePosition = app.renderer.plugins.interaction.mouse.global;

    // TODO check if centered

    // Place the inner rectangle at the mouse position
    const innerRect = {
      x: mousePosition.x,
      y: mousePosition.y,
      width: contentRect.width,
      height: contentRect.height,
    };

    if (verticalAlign === VERTICAL_ALIGN_CENTER) {
      innerRect.x -= contentRect.width / 2;
    } else if (verticalAlign === VERTICAL_ALIGN_RIGHT) {
      innerRect.x += contentRect.width;
    }

    const outerRect = getViewportRect();

    if (canFit(innerRect, outerRect)) {
      updateElementPosition(contentElement, innerRect.x, innerRect.y);
    } else {
      // shift the inner rectangle position to try and fit inside the outer rectangle
      const translation = translateToFit(innerRect, outerRect);
      updateElementPosition(
        contentElement,
        innerRect.x + translation.x,
        innerRect.y + translation.y,
      );
    }
  };

  const intersectsWithRectangles = (rect) => {
    for (let i = 0; i < avoidRectangles.length; i++) {
      if (rect.overlaps(avoidRectangles[i])) {
        return true;
      }
    }

    return false;
  };

  const getViewportRect = () => {
    // take the window size and them clip the header area
    // and finally add some padding so that the popups don't stick to the edges
    const headerElement = document.getElementById('header');
    const headerRect = headerElement.getBoundingClientRect();
    return {
      x: VIEWPORT_PADDING, // add some padding so that the popups don't stick to the edges
      y: headerRect.height + VIEWPORT_PADDING,
      width: viewport.width - VIEWPORT_PADDING * 2,
      height: viewport.height - headerRect.height - VIEWPORT_PADDING * 2,
    };
  };

  const updateElementPosition = (contentElement, x, y) => {
    // You need to first check if there is an RND component nested

    const rndComponent = findRndComponent(contentElement);
    // If we are wrapping an RND component
    // then its best to update its position
    // otherwise it will glich when trying to drag it
    // ( cos it remembers its original position , it can't be altered through CSS only)
    if (rndComponent) {
      rndComponent.updatePosition({ x, y });
    } else {
      // in all other cases directly trasnlate the position
      // of the dom element
      const transform = buildTranslateProp(x, y);
      contentElement.style.transform = transform;
    }
  };

  const buildTranslateProp = (x, y) => {
    return `translate(${Math.round(x)}px , ${Math.round(y)}px)`;
  };

  // if a rectangle can fit in
  const canFit = (innerRect, outerRect) => {
    if (innerRect.x < outerRect.x) {
      return false;
    } else if (innerRect.y < outerRect.y) {
      return false;
    } else if (innerRect.x + innerRect.width > outerRect.x + outerRect.width) {
      return false;
    } else if (innerRect.y + innerRect.height > outerRect.y + outerRect.height) {
      return false;
    }

    return true;
  };

  // Find by how much the inner rectangle needs to be translated
  // in order to fit into the outer rectangle
  const translateToFit = (innerRect, outerRect) => {
    // Just find by how much the inner rectangle goes outside of the
    // outer rectangle on the x and y axis
    const translation = {
      x: 0,
      y: 0,
    };

    // horizontaly
    if (innerRect.x < outerRect.x) {
      translation.x = outerRect.x - innerRect.x;
    } else if (innerRect.x + innerRect.width > outerRect.x + outerRect.width) {
      translation.x = outerRect.x + outerRect.width - (innerRect.x + innerRect.width);
    }

    // verticaly
    if (innerRect.y < outerRect.y) {
      translation.y = outerRect.y - innerRect.y;
    } else if (innerRect.y + innerRect.height > outerRect.y + outerRect.height) {
      translation.y = outerRect.y + outerRect.height - (innerRect.y + innerRect.height);
    }

    return translation;
  };

  const findRndComponent = (contentElement) => {
    const resizeComponent = findReactComponent([contentElement]);
    if (resizeComponent) {
      // ResizeComponent
      return traverseReturnNode(resizeComponent, Rnd);
    }
    return null;
  };

  // This is a Hack to try and traverse all the children to find if
  // a specific node exists
  // The traverse is done on the props.children property
  const findReactComponent = (children = []) => {
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      const reactInstance = fetchReactInstance(child);
      if (
        reactInstance &&
        reactInstance.return &&
        reactInstance.return.elementType &&
        reactInstance.return.elementType === Resizable
      ) {
        return reactInstance;
      }

      if (child.childNodes && child.childNodes.length) {
        return findReactComponent(child.childNodes);
      }
    }
  };

  const fetchReactInstance = (element) => {
    const key = Object.keys(element).find((key) => key.startsWith('__reactInternalInstance$'));
    return element[key];
  };

  const traverseReturnNode = (element, componentType) => {
    if (element.elementType === componentType) {
      return element.stateNode;
    }

    if (element.child) {
      return traverseReturnNode(element.return, componentType);
    }
  };

  // The component will always be rendered at a root level in the dom
  // Because the component can be used by nesting it
  // and we don't want the parent component to interfere
  // with the children component and alter its position
  return ReactDOM.createPortal(
    <div id={id} style={{ position: 'absolute', left: 0, top: 0, width: 0, height: 0, zIndex: 10 }}>
      {children}
    </div>,
    window.document.body,
  );
};
