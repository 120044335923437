import React from 'react';
import { csvDownload } from 'react-project/assets/Icons';
import { Spinner } from 'react-project/components/spinner/spinner';
import { HEAP_EVENTS } from 'react-project/Constants/heapEvents';
import { getPeopleCSVRequestBody } from 'react-project/Helpers/requestConstruction';
import RequestService from 'react-project/Helpers/RequestService';
import { sendHeapTracking } from 'react-project/Util/HEAP.utilities';
import { track as trackCohesive } from 'react-project/Util/CohesiveTracking';
import { CsvDownload as CsvDownloadComponent } from './CsvDownload';
import { LOCK_NO_PEOPLE_DATA_EXPORT, UILock } from 'react-project/components/uilock/UILock';

const requestService = new RequestService();
const makeCSV = async (csvIsLoading, setCsvIsLoading, requestObj, projectId, metaCount) => {
  if (csvIsLoading) return;
  setCsvIsLoading(true);
  //Fetch data, async
  const data = getPeopleCSVRequestBody(requestObj);

  const csv = await requestService.getPeopleAnalyticsCsv(projectId, data);
  if (!csv.success) {
    setCsvIsLoading(false);
    return;
  }
  const a = document.createElement('a');
  a.style = 'display: none';
  document.getElementById('csv').appendChild(a);
  const CSVFile = new Blob([csv.data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(CSVFile);
  a.href = url;
  a.download = `People Tracking - ${metaCount} people`;
  a.click();
  window.URL.revokeObjectURL(url);
  setCsvIsLoading(false);

  sendHeapTracking({
    projectId,
    eventName: HEAP_EVENTS.PEOPLE_EXPORT,
  });

  trackCohesive(HEAP_EVENTS.PEOPLE_EXPORT, {
    projectId,
  });
};

export const StyledCsvDownload = ({
  isLoading,
  isEnabled,
  sessions,
  setCsvIsLoading,
  csvIsLoading,
  requestObj,
  projectId,
  metaCount,
}) => {
  const enabled = (
    <>
      <CsvDownloadComponent
        data={sessions}
        h="20px"
        w="20px"
        onClick={() =>
          !isLoading
            ? makeCSV(csvIsLoading, setCsvIsLoading, requestObj, projectId, metaCount)
            : null
        }
      />

      {isLoading ? <Spinner height="24px" width="24px" /> : null}
    </>
  );

  const disabled = (
    <div className="opacity-50 cursor-not-allowed">{csvDownload('20px', '20px')}</div>
  );

  return (
    <UILock lock={LOCK_NO_PEOPLE_DATA_EXPORT} style={{ height: 'auto' }}>
      <div
        className="cursor-pointer"
        id="csv"
        style={{ display: 'flex', height: '100%', alignItems: 'center', padding: '0 10px' }}
      >
        {isEnabled ? enabled : disabled}
      </div>
    </UILock>
  );
};

export default StyledCsvDownload;
