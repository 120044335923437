import React, { useEffect } from 'react';
import {
  VERTICAL_ALIGN_CENTER,
  ViewportConstrainer,
} from 'react-project/components/viewportConstrainer/ViewportConstrainer';
import {
  STEP_GOAL,
  STEP_PEOPLE_TAB_NAME,
  STEP_SETTINGS_TAB_NAME,
  STEP_TRACKING_TAB_NAME,
} from 'react-project/Constants/step-settings';
import { resetAdPopups } from 'react-project/features';
import { selectIsModalOpened } from 'react-project/redux/modal/selectors';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import styles from 'react-project/Toolbar/Toolbar.module.scss';
import { notifyIfValueChanged } from 'react-project/Util/InputHelpers';
import { When } from 'react-project/Util/When';
import { useDispatch, useSelector } from 'react-redux';
import { EElementTypes } from 'shared/CSharedCategories';
import { ActionTypes } from 'shared/CSharedConstants';
import { ClickOutsideCustomComponent } from '../Util/ClickOutsideCustom';
import { CustomActionTracking } from './step-toolbar/CustomActionTracking';
import { PageTracking } from './step-toolbar/PageTracking';
import { PeopleTracking } from './step-toolbar/PeopleTracking';
import { RevenueActionTracking } from './step-toolbar/RevenueActionTracking';
import { SourceTracking } from './step-toolbar/SourceTracking';
import { StepGoals } from './step-toolbar/StepGoals';
import { StepSettings } from './step-toolbar/StepSettings';

export const StepToolbar = ({
  iconType,
  isShowUrlParams,
  handleInputChangeUrlParams,
  onAddCustomParameter,
  urlParamsRows,
  handleFilterPageByUrlChange,
  currentStep,
  projectApiKey,
  projectId,
  funnelConfiguration,
  isNumbersActive,
  onFilterParametersPasted,
  selectedTab,
  setSelectedTab,
  activeMenuRef,
}) => {
  const isModalOpened = useSelector(selectIsModalOpened);
  const canvasPermissions = useSelector(selectCanvasPermissions);

  const dispatch = useDispatch();

  const stepToolbarRef = React.useRef();
  const constrainerAPIRef = React.useRef();
  const revenueConstrainerAPIRef = React.useRef();

  useEffect(() => {
    if (
      (!canvasPermissions.isAnalyticsAllowed || !canvasPermissions.isActionsAllowed) &&
      iconType === EElementTypes.EVENT && // show only for event
      (selectedTab === STEP_TRACKING_TAB_NAME || // if the tracking tab is clicked
        selectedTab === STEP_PEOPLE_TAB_NAME) // if the people tab is clicked
    ) {
      setSelectedTab('');
    }
  }, [iconType, selectedTab]);

  const closeAllTabs = () => {
    setSelectedTab('');
  };

  const onCustomActionContentChanged = () => {
    if (constrainerAPIRef.current) {
      constrainerAPIRef.current.updateContentPosition();
    }
  };

  const onRevenueActionContentChanged = () => {
    if (revenueConstrainerAPIRef.current) {
      revenueConstrainerAPIRef.current.updateContentPosition();
    }
  };

  const resetSourceTab = () => {
    dispatch(resetAdPopups());
    closeAllTabs();
  };

  const targetHasStopClass = (element) => {
    if (element) {
      if (element.classList.contains('stop-event')) {
        return true;
      }
      return targetHasStopClass(element.parentElement);
    } else {
      return false;
    }
  };

  return (
    <div ref={stepToolbarRef} className={styles.StepToolbar}>
      <ClickOutsideCustomComponent
        onClickOutside={(e) => {
          if (targetHasStopClass(e.target)) {
            return;
          }

          const targetEl = e.target.innerText
            ? e.target.innerText.toLowerCase()
            : e.target.dataset.value || '';

          // In order to toggle the menu buttton
          if (selectedTab !== targetEl) {
            setSelectedTab('');
          }

          //Tab-based event
          if (iconType === EElementTypes.SOURCE) {
            dispatch(resetAdPopups());
          }
        }}
        ignoreClickOutside={isModalOpened}
      >
        <When condition={selectedTab === STEP_PEOPLE_TAB_NAME}>
          <ViewportConstrainer
            verticalAlign={VERTICAL_ALIGN_CENTER}
            relativeElement={activeMenuRef.current}
          >
            <PeopleTracking onClose={closeAllTabs} />
          </ViewportConstrainer>
        </When>
        <When condition={selectedTab === STEP_SETTINGS_TAB_NAME}>
          <ViewportConstrainer
            verticalAlign={VERTICAL_ALIGN_CENTER}
            relativeElement={activeMenuRef.current}
          >
            <StepSettings
              iconType={iconType}
              stepId={currentStep.stepId}
              showUrlBlock={iconType === EElementTypes.PAGE}
              projectId={projectId}
              notifyIfValueChanged={notifyIfValueChanged}
              onClose={closeAllTabs}
            />
          </ViewportConstrainer>
        </When>
        <When condition={selectedTab === STEP_GOAL}>
          <ViewportConstrainer
            verticalAlign={VERTICAL_ALIGN_CENTER}
            relativeElement={activeMenuRef.current}
          >
            <StepGoals onClose={closeAllTabs} />
          </ViewportConstrainer>
        </When>
        <When condition={selectedTab === STEP_TRACKING_TAB_NAME}>
          <When condition={iconType === EElementTypes.PAGE}>
            <ViewportConstrainer
              verticalAlign={VERTICAL_ALIGN_CENTER}
              relativeElement={activeMenuRef.current}
            >
              <PageTracking
                isShowUrlParams={isShowUrlParams}
                handleInputChangeUrlParams={handleInputChangeUrlParams}
                handleFilterPageByUrlChange={handleFilterPageByUrlChange}
                onAddCustomParameter={onAddCustomParameter}
                urlParamsRows={urlParamsRows}
                projectId={projectId}
                onClose={closeAllTabs}
                onFilterParametersPasted={onFilterParametersPasted}
              />
            </ViewportConstrainer>
          </When>
          <When condition={iconType === EElementTypes.SOURCE}>
            <ViewportConstrainer
              verticalAlign={VERTICAL_ALIGN_CENTER}
              relativeElement={activeMenuRef.current}
            >
              <SourceTracking
                onAddCustomParameter={onAddCustomParameter}
                urlParamsRows={urlParamsRows}
                onClose={resetSourceTab}
                projectId={projectId}
                isNumbersActive={isNumbersActive}
              />
            </ViewportConstrainer>
          </When>
          <When
            condition={
              iconType === EElementTypes.EVENT &&
              currentStep.object.actionType !== ActionTypes.COMMERCE
            }
          >
            <ViewportConstrainer apiRef={constrainerAPIRef} relativeElement={activeMenuRef.current}>
              <CustomActionTracking
                onContentChanged={onCustomActionContentChanged}
                projectId={projectId}
                onClose={closeAllTabs}
              />
            </ViewportConstrainer>
          </When>
          <When
            condition={
              iconType === EElementTypes.EVENT &&
              currentStep.object.actionType === ActionTypes.COMMERCE
            }
          >
            <ViewportConstrainer
              apiRef={revenueConstrainerAPIRef}
              relativeElement={activeMenuRef.current}
            >
              <RevenueActionTracking
                projectApiKey={projectApiKey}
                projectId={projectId}
                currentStep={currentStep}
                funnelConfiguration={funnelConfiguration}
                onClose={closeAllTabs}
                onContentChanged={onRevenueActionContentChanged}
              />
            </ViewportConstrainer>
          </When>
        </When>
      </ClickOutsideCustomComponent>
    </div>
  );
};
