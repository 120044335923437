import React from 'react';
import styles from './MenuImageButton.module.scss';
import cls from 'classnames';

export const MenuImageButton = ({ id, image, title, isActive, onClick }) => {
  const reference = React.useRef(null);
  return (
    <div
      ref={reference}
      onClick={(e) => {
        e.stopPropagation();
        onClick(e, reference, id);
      }}
      onPointerDown={(e) => e.stopPropagation()}
      onPointerUp={(e) => e.stopPropagation()}
      className={cls(styles.Item, isActive ? styles.Active : null)}
    >
      {image ? <div>{image}</div> : null}
      {title ? <div>{title}</div> : null}
    </div>
  );
};
