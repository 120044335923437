import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  createJourney,
  createProfile,
} from 'react-project/components/userProfile/helper/dataShape';
import { UserProfile } from 'react-project/components/userProfile/UserProfile';
import { loadSessionDetailsAsync } from 'react-project/redux/analytics/actions';
import { selectFunnelConfiguration } from 'react-project/redux/funnel-configuration/selectors';
import { selectFunnel } from 'react-project/redux/funnels/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { removePeopleTrackingPopup } from './peopleTrackingSlice';

export const PeopleJourney = ({ session, eventTarget }) => {
  const source = axios.CancelToken.source();
  const dispatch = useDispatch();
  const projectId = useSelector((state) => selectFunnel(state)['projectId']);
  const funnelConfiguration = useSelector((state) => selectFunnelConfiguration(state));
  const [journey, setJourney] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [boundingRect, setBoundingRect] = useState();
  useEffect(function () {
    setBoundingRect(eventTarget.parentNode.getBoundingClientRect());
    const fetchJourneyAsync = async () => {
      const responseData = await dispatch(
        loadSessionDetailsAsync(projectId, session.id, funnelConfiguration, session, source),
      );
      if (responseData) {
        const profile = createProfile(responseData.response);
        const journey = createJourney(responseData.response);
        setJourney(journey);
        setIsLoading(false);
      }
    };
    fetchJourneyAsync();
  }, []);
  return (
    <>
      {isLoading ? (
        <div> Loading...</div>
      ) : (
        <UserProfile
          key={`pjourney-${session.id}`}
          countryName={session.country.name}
          countryCode={session.country.code}
          name={session.fullName}
          email={session.email}
          city={session.country.city}
          isMobile={session.device === 'mobile'}
          userID={session.id}
          createdAt={session.createdAt}
          isLoading={isLoading}
          journey={journey}
          onUserProfileClose={(id) => {
            dispatch(removePeopleTrackingPopup(session.id));
          }}
          widgetBounds={boundingRect}
        />
      )}
    </>
  );
};
