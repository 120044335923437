import React from 'react';
import { iconRefreshBtn } from 'react-project/assets/Icons';
import cls from 'classnames';

export const RefreshButton = ({ isLoading, onClick }) => {
  return (
    <button className={cls(isLoading ? 'animate-spin cursor-wait' : '')} onClick={onClick}>
      {iconRefreshBtn}
    </button>
  );
};
