export const UPSELL_URLS = {
  discover: 'https://funnelytics.io/discover',
  start_free_trial: `${process.env.REACT_APP_CURRENT_APP_URL}/login?next=performance-business-trial&utm_source=app&utm_medium=canvas`,
  upgrade: `${process.env.REACT_APP_CURRENT_APP_URL}/dashboard/settings/my-subscriptions?v=2&h=performance-business`,
};

export const UPSELL_TEXTS = {
  book_a_demo: 'Book a demo',
  start_free_trial: 'Start Your Free Trial',
  upgrade: 'Upgrade',
};
