import React, { forwardRef } from 'react';
import {
  horizontalSpacingIcon,
  icon3Dots,
  iconHorizontalAlignment,
  iconVerticalAlignment,
  veritcalSpacingIcon,
} from 'react-project/assets/Icons';
import {
  VERTICAL_ALIGN_CENTER,
  ViewportConstrainer,
} from 'react-project/components/viewportConstrainer/ViewportConstrainer';
import {
  RP_EVENT_HORIZONTAL_ALIGNMENT,
  RP_EVENT_HORIZONTAL_SPACING,
  RP_EVENT_VERTICAL_ALIGNMENT,
  RP_EVENT_VERTICAL_SPACING,
} from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { MenuHorizontalLabel } from '../Components/MenuItems/MenuHorizontalLabel';
import { MenuImageButton } from '../Components/MenuItems/MenuImageButton';
import { MenuVerticalSeparator } from '../Components/MenuItems/MenuVerticalSeparator';
import {
  MENU_BTN_ALIGN_HORIZONTAL,
  MENU_BTN_ALIGN_VERTICAL,
  MENU_BTN_ARRANGE_HORIZONTAL,
  MENU_BTN_ARRANGE_VERTICAL,
  MENU_BTN_STEP_MORE,
} from '../MenusConfiguration';

import styles from './AlignArrangeMenu.module.scss';

export const AlignArrangeMenu = forwardRef(({ sectionStyle, onButtonClicked }, ref) => {
  const onClick = (e, reference, id) => {
    onButtonClicked(e, reference, id);
  };

  const horizontalAlignment = () => {
    commonSendEventFunction(RP_EVENT_HORIZONTAL_ALIGNMENT);
  };

  const verticalAlignment = () => {
    commonSendEventFunction(RP_EVENT_VERTICAL_ALIGNMENT);
  };

  const verticalSpacing = () => {
    commonSendEventFunction(RP_EVENT_VERTICAL_SPACING);
  };

  const horizontalSpacing = () => {
    commonSendEventFunction(RP_EVENT_HORIZONTAL_SPACING);
  };

  return (
    <ViewportConstrainer verticalAlign={VERTICAL_ALIGN_CENTER} relativeRectangle={sectionStyle}>
      <div ref={ref} className={styles.Menu}>
        <div className={styles.MenuWrapper}>
          <MenuHorizontalLabel title="Arrange" />
          <MenuImageButton
            id={MENU_BTN_ARRANGE_HORIZONTAL}
            image={horizontalSpacingIcon}
            onClick={horizontalSpacing}
          />
          <MenuImageButton
            id={MENU_BTN_ARRANGE_VERTICAL}
            image={veritcalSpacingIcon}
            onClick={verticalSpacing}
          />

          <MenuVerticalSeparator />

          <MenuHorizontalLabel title="Align" />
          <MenuImageButton
            id={MENU_BTN_ALIGN_VERTICAL}
            image={iconVerticalAlignment}
            onClick={verticalAlignment}
          />
          <MenuImageButton
            id={MENU_BTN_ALIGN_HORIZONTAL}
            image={iconHorizontalAlignment}
            onClick={horizontalAlignment}
          />

          <MenuImageButton id={MENU_BTN_STEP_MORE} image={icon3Dots} onClick={onClick} />
        </div>
      </div>
    </ViewportConstrainer>
  );
});
