import cls from 'classnames';
import React from 'react';
import styles from './Input.module.scss';

export const Input = ({ className, disabled, hasError, ...props }) => {
  /**
   * @param {React.ChangeEvent<HTMLTextAreaElement>}
   * @returns {void}
   */
  return (
    <>
      <input
        className={cls(
          'focus:border-4 focus:border-solid focus:border-l-transparent',
          styles.Input,
          className,
          disabled && styles.disabled,
          hasError && styles.error,
        )}
        disabled={disabled}
        {...props}
      />
    </>
  );
};
