import { HEAP_EVENTS } from 'react-project/Constants/heapEvents';
import RequestService from 'react-project/Helpers/RequestService';
import { ActionNames } from 'react-project/redux/actionNamesConstant';
import { loadAnalyticsAsync, updateStatus } from 'react-project/redux/analytics/actions';
import { sendHeapTracking } from 'react-project/Util/HEAP.utilities';
import { createAction } from 'redux-actions';
import {
  ANALYTICS_STATUS_ERROR,
  ANALYTICS_STATUS_LOADING,
  ANALYTICS_STATUS_SUCCESS,
} from 'shared/CSharedConstants';
import {
  RP_EVENT_EDIT_OBJECT,
  RP_EVENT_ON_FORECASTING_CHANGED,
  RP_EVENT_ON_GOAL_CHANGED,
} from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';

const requestService = new RequestService();

export const setThumbnailIsLoading = createAction(ActionNames.setThumbnailIsLoading);
export const setAttributeExplorerData = createAction(ActionNames.setAttributeExplorerData);
export const setNewCurrentStepLabel = createAction(ActionNames.setNewCurrentStepLabel);
export const setNewCurrentStepUrl = createAction(ActionNames.setNewCurrentStepUrl);
export const setNewCurrentStep = createAction(ActionNames.setNewCurrentStep);
export const setNewCurrentStepFilterParams = createAction(
  ActionNames.setNewCurrentStepFilterParams,
);
export const setNewCurrentStepTrackingUrl = createAction(ActionNames.setNewCurrentStepTrackingUrl);
export const setNewCurrentStepActionType = createAction(ActionNames.setNewCurrentStepActionType);
export const setNewCurrentStepActionName = createAction(ActionNames.setNewCurrentStepActionName);
export const setTextStyle = createAction(ActionNames.setTextStyle);
export const setIntegrationAttributes = createAction(ActionNames.setIntegrationAttributes);
export const setTextHyperLink = createAction(ActionNames.setTextHyperLink);
export const setNewCurrentStepIntegrationType = createAction(
  ActionNames.setNewCurrentStepIntegrationType,
);

export const setFacebookTree = createAction(ActionNames.setFacebookTree);
export const setSelectedFacebookTree = createAction(ActionNames.setFacebookSelectedTree);
export const setFacebookAds = createAction(ActionNames.setFacebookAds);
export const setNewCurrentStepGoal = createAction(ActionNames.setNewCurrentStepGoal);
export const setNewCurrentStepForecastingData = createAction(
  ActionNames.setNewCurrentStepForecastingData,
);

export function setStepGoal(stepId, goal) {
  return async (dispatch) => {
    dispatch(setNewCurrentStepGoal(goal));
    commonSendEventFunction(RP_EVENT_ON_GOAL_CHANGED, {
      stepId,
      goal,
    });
  };
}

export function setStepForecastingData(stepId, data) {
  return async (dispatch) => {
    dispatch(setNewCurrentStepForecastingData(data));
    commonSendEventFunction(RP_EVENT_ON_FORECASTING_CHANGED, {
      stepId,
      data,
    });
  };
}

export function setNewLabel({ label, currentStep }) {
  return async (dispatch) => {
    dispatch(setNewCurrentStepLabel(label));

    commonSendEventFunction(RP_EVENT_EDIT_OBJECT, {
      stepId: currentStep.stepId,
      label,
      filterData: currentStep.object.filterData,
    });
  };
}

export function getThumbnailImg({ currentStep, projectId }) {
  return async (dispatch) => {
    if (currentStep?.object.url) {
      dispatch(setThumbnailIsLoading(true));
      requestService
        .getThumbnailImg(currentStep.stepId, currentStep.object.url, projectId)
        .finally(() => {
          dispatch(setThumbnailIsLoading(false));
        });
    }
  };
}

export function setNewUrl({ url, currentStep }) {
  return async (dispatch) => {
    dispatch(setNewCurrentStepUrl(url));

    commonSendEventFunction(RP_EVENT_EDIT_OBJECT, {
      stepId: currentStep.stepId,
      url,
    });
  };
}

export function updateAnalyticsWhenUrlChanged({
  url,
  currentStep,
  projectId,
  funnelConfiguration,
}) {
  return async (dispatch) => {
    dispatch(setNewCurrentStepUrl(url));

    const objForSending = [
      {
        ID: currentStep.stepId,
        url,
        filterData: currentStep.object.filterData,
      },
    ];

    dispatch(updateStatus(ANALYTICS_STATUS_LOADING));

    dispatch(loadAnalyticsAsync(projectId, funnelConfiguration, objForSending))
      .then(() => {
        dispatch(updateStatus(ANALYTICS_STATUS_SUCCESS));
      })
      .catch(() => {
        dispatch(updateStatus(ANALYTICS_STATUS_ERROR));
      });

    commonSendEventFunction(RP_EVENT_EDIT_OBJECT, {
      stepId: currentStep.stepId,
      url,
    });
  };
}

export function getAttributeExplorerDataAsync(projectId, startData, funnelConfiguration) {
  return async (dispatch) => {
    dispatch(updateStatus(ANALYTICS_STATUS_LOADING));

    const responseData = await requestService.getAttributeExplorerData(
      projectId,
      startData,
      funnelConfiguration,
    );

    dispatch(setAttributeExplorerData(responseData));
    dispatch(updateStatus(responseData ? ANALYTICS_STATUS_SUCCESS : ANALYTICS_STATUS_ERROR));
  };
}

export const getFacebookTreeRequest = (projectId, timeRange, isAnalyticsActive) => {
  return async (dispatch) => {
    const dateRange = {
      dateStart: new Date(timeRange.min).toISOString().slice(0, 10),
      dateStop: new Date(timeRange.max).toISOString().slice(0, 10),
    };
    const treeResponse = await requestService.getFacebookCampaignTree({ projectId });
    let spendData = [];
    if (isAnalyticsActive) {
      spendData = await requestService.getFacebookCampaignSpend({ projectId, dateRange });
    }
    const response = treeResponse.map((d) => {
      const adSpend = spendData?.data?.data.find((s) => s.campaign_id === d.value);
      if (adSpend) {
        return { ...d, spend: adSpend.spend };
      }
      return d;
    });

    if (response) {
      dispatch(setFacebookTree(response));
    }
  };
};

export const getFacebookAdRequest = (projectId, campaignId, dateRange, isAnalyticsActive) => {
  return async () => {
    return await requestService.getFacebookAds({
      projectId,
      campaignId,
      dateRange,
      isAnalyticsActive,
    });
  };
};

export const searchFacebookAdRequest = async (
  projectId,
  searchTerm,
  dateRange,
  isAnalyticsActive,
) => {
  const response = await requestService.searchFacebookAds({
    projectId,
    searchTerm,
    dateRange,
    isAnalyticsActive,
  });
  if (response) {
    // dispatch(setSelectedFacebookTree(response));
    return response;
  }
};

export const getFacebookCampaignSpend = (projectId, timeRange) => {
  const dateRange = getDateRange(timeRange);
  return async () => {
    return await requestService.getFacebookCampaignSpend({ projectId, dateRange });
  };
};

export const getFacebookAdSpend = (projectId, campaignId, dateRange) => {
  return async () => {
    return await requestService.getFacebookAdSpend({ projectId, campaignId, dateRange });
  };
};
