import React from 'react';
import styles from './CanvasPassword.module.scss';

export const CanvasPassword = ({ checkPassword, authMessage, isChecking }) => {
  const inputRef = React.useRef(null);

  const onKeyUp = (e) => {
    e.stopPropagation();
    if (e.keyCode === 13) {
      checkPassword(e.target.value);
    }
    return false;
  };

  return (
    <div className={styles.Wrapper}>
      <h1 className={styles.Title}>This canvas is password protected</h1>
      <p className={styles.Description}>
        {isChecking ? 'Validating ...' : 'Please provide your password '}
      </p>
      <div className={styles.PasswordWrapper}>
        <input ref={inputRef} type="password" className={styles.PasswordInput} onKeyUp={onKeyUp} />
        <div className={styles.Button} onClick={(e) => checkPassword(inputRef.current.value)}>
          Submit
        </div>
      </div>

      <div className={styles.ErrorMessage}>{authMessage}</div>
    </div>
  );
};
