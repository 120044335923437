import Styles from 'pixi-project/view/Styles';
import * as PIXI from 'pixi.js';

export default class DropDown extends PIXI.Container {
  /**
   * items is an array of objects with the following structure:
   * { label: 'item name', value: 'item value' }
   * defaultMode is the default selected item value
   * onChange is a callback function that will be called when the selected item is changed
   * style is the style of the dropdown with the following structure:
   * { background: 0x000000, label: { color: 0xffffff, fontSize: 12, fontFamily: 'Arial' } , border: { color: 0xffffff, width: 1 } , padding: 5 }
   */

  constructor(items, defaultMode, onChange, style = null, width = 0, height = 0) {
    super();
    this.items = items;
    this.defaultMode = defaultMode;
    this.onChange = onChange;
    this.style = style;
    this._width = width;
    this._height = height;

    this.itemsContainer = null;
    this.background = null;
    this.label = null;
    this.arrow = null;
    this.border = null;
    this.itemsCollection = []; // it contains the items of the dropdown

    this.createUI();
  }

  createUI() {
    // create the items of the dropdown
    this.itemsContainer = new PIXI.Graphics();
    this.itemsContainer.visible = false;

    this.addChild(this.itemsContainer);

    this.createItems();
    const size = this.determineMaxSizeOfItems();

    this.arrow = new PIXI.Sprite(PIXI.Texture.from('compare-down'));
    this.arrow.anchor.set(0.5, 0.5);
    this.arrow.tint = this.style.label.color;

    this._width = size.width + this.style.padding * 2 + 5 + this.arrow.width;
    this._height = size.height + this.style.padding * 2;
    this.updateBoundsForItems(this._width, this._height);

    this.itemsContainer.x = 0;
    this.itemsContainer.y = this._height + 2;

    // draw the background of the dropdown
    this.itemsContainer.beginFill(this.style.background);
    this.itemsContainer.lineStyle(this.style.border.width, this.style.border.color);
    this.itemsContainer.drawRoundedRect(0, 0, this._width, this._height * this.items.length, 5);

    this.positionAllItems();

    // create the UI of the dropdown

    this.label = new PIXI.BitmapText('Default', Styles.DROPDOWN_SELECTED_LABEL);
    this.label.anchor.set(0, 0.5);
    this.label.tint = 0x555555;
    this.label.x = this.style.padding;
    this.label.y = this._height / 2;

    this.arrow.x = this._width - this.arrow.width / 2 - this.style.padding;
    this.arrow.y = this._height / 2;

    this.background = new PIXI.Graphics();
    this.background.lineStyle(this.style.border.width, this.style.border.color);
    this.background.beginFill(this.style.background);
    this.background.drawRoundedRect(0, 0, this._width, this._height, 5);

    this.background.endFill();

    this.addChild(this.background);
    this.addChild(this.label);
    this.addChild(this.arrow);

    // set the default item
    this.label.text = this.getItemByValue(this.defaultMode).label;

    // add the event listeners
    this.background.interactive = true;
    this.background.buttonMode = true;
    this.background.on('pointerdown', (e) => {
      e.stopPropagation();
    });

    this.background.on('pointerup', (e) => {
      e.stopPropagation();
      setTimeout(() => {
        if (!this.itemsContainer.visible) {
          this.open();
        }
      }, 0);
    });

    this.itemsContainer.on('pointerupoutside', () => {
      this.itemsContainer.visible = false;
    });
  }

  getItemByValue(value) {
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      if (item.value === value) {
        return item;
      }
    }
    return null;
  }

  createItems() {
    // create all items and add them in the collection
    for (let i = 0; i < this.items.length; i++) {
      const item = this.createItem(this.items[i]);
      this.itemsContainer.addChild(item);
      this.itemsCollection.push(item);
    }
  }

  createItem(itemData) {
    const itemLabel = new PIXI.BitmapText(itemData.label, Styles.DROPDOWN_ITEM_LABEL); // this.style.label
    itemLabel.tint = itemData.color;
    itemLabel.on('pointerdown', (e) => {
      this.itemsContainer.visible = false;
      e.stopPropagation();
      this.label.text = itemData.label;
      this.onChange(itemData.value);
      this.onDropdownClose();
    });

    return itemLabel;
  }

  determineMaxSizeOfItems() {
    let maxWidth = 0;
    let maxHeight = 0;

    for (let i = 0; i < this.itemsCollection.length; i++) {
      const item = this.itemsCollection[i];
      maxWidth = Math.max(maxWidth, item.width);
      maxHeight = Math.max(maxHeight, item.height);
    }

    return { width: maxWidth, height: maxHeight };
  }

  positionAllItems() {
    const padding = this.style.padding;
    let y = padding;

    for (let i = 0; i < this.itemsCollection.length; i++) {
      const item = this.itemsCollection[i];
      item.x = padding;
      item.y = y;
      y += item.height + padding * 2;
    }

    // draw lines between items
    this.itemsContainer.lineStyle(this.style.border.width, this.style.border.color);
    for (let i = 0; i < this.itemsCollection.length - 1; i++) {
      const item = this.itemsCollection[i];
      this.itemsContainer.moveTo(0, item.y + item.height + padding);
      this.itemsContainer.lineTo(this._width, item.y + item.height + padding);
    }
  }

  setInteractiveBoundsForItem(item, x, y, width, height) {
    item.interactive = true;
    item.buttonMode = true;
    item.cursor = 'pointer';
    item.hitArea = new PIXI.Rectangle(x, y, width, height);
  }

  updateBoundsForItems(width, height) {
    const padding = this.style.padding;
    let y = padding;

    for (let i = 0; i < this.itemsCollection.length; i++) {
      const item = this.itemsCollection[i];
      this.setInteractiveBoundsForItem(item, 0, 0, width, height);
      y += item.height + padding;
    }
  }

  onDropdownOpen() {
    this.arrow.rotation = Math.PI;
  }

  onDropdownClose() {
    this.arrow.rotation = 0;
  }

  open() {
    this.itemsContainer.visible = true;
    this.onDropdownOpen();
    window.app.needsRendering();
  }

  close() {
    this.itemsContainer.visible = false;
    this.onDropdownClose();
    window.app.needsRendering();
  }

  isOpened() {
    return this.itemsContainer.visible;
  }
}
