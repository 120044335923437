import { cloneData } from 'shared/CSharedMethods';

export default class TreeData {
  constructor() {
    // A Tree node must have
    // id: unique id
    // items: array of children
    // hierarchyIndex: index of the node in the tree

    this.data = [];
  }

  readData(data) {
    this.data = cloneData(data);
  }

  add(item, list = this.data) {
    list.push(item);
  }

  moveItemToChildren(item, list = this.data) {
    for (let i = 0; i < list.length; i++) {
      const listItem = list[i];
      if (listItem.id === item.id) {
        const previousItem = list[i - 1];
        if (previousItem) {
          this.removeItem(listItem, list);
          if (!previousItem.items) {
            previousItem.items = [];
          }
          previousItem.items.push(listItem);
          return true;
        }
      } else if (listItem.items) {
        if (this.moveItemToChildren(item, listItem.items)) {
          return true;
        }
      }
    }
    return false;
  }

  moveItemToParent(item, list = this.data) {
    for (let i = 0; i < list.length; i++) {
      const listItem = list[i];
      if (listItem.items) {
        for (let j = 0; j < listItem.items.length; j++) {
          const childItem = listItem.items[j];
          if (childItem.id === item.id) {
            this.removeItem(childItem, listItem.items);
            list.splice(i + 1, 0, childItem);
            return true;
          } else if (childItem.items) {
            if (this.moveItemToParent(item, listItem.items)) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  // It check if it is descendant of the parentItem
  // It will check all the generations of the parentItem
  isDescendant(parentItem, childItem) {
    if (parentItem.items) {
      for (let i = 0; i < parentItem.items.length; i++) {
        if (parentItem.items[i].id === childItem.id) {
          return true;
        }
        if (this.isDescendant(parentItem.items[i], childItem)) {
          return true;
        }
      }
    }
    return false;
  }

  // It will update the hierarchyIndex of all items in the tree
  // the hierarcyIndex is a number that increases from top to bottom
  // the depth is the level of depth of the item in the tree
  updateHierarchyIndex(list = this.data, startIndex = 0, depth = 0) {
    let ind = startIndex;
    for (let i = 0; i < list.length; i++) {
      ind += 1;
      list[i].hierarchyIndex = ind;
      list[i].depth = depth;
      if (list[i].items) {
        ind = this.updateHierarchyIndex(list[i].items, ind, depth + 1);
      }
    }
    return ind;
  }

  // Update item in tree by replaceing it
  updateElementInList(item, list = this.data) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === item.id) {
        list[i] = cloneData(item);
      } else if (list[i].items) {
        this.updateElementInList(item, list[i].items);
      }
    }
  }

  // Remove item from tree
  removeItem(item, list = this.data) {
    for (let i = list.length - 1; i >= 0; i--) {
      if (list[i].id === item.id) {
        list.splice(i, 1);
      } else if (list[i].items) {
        this.removeItem(item, list[i].items);
      }
    }
  }

  // Insert item as sibling before or after target item
  insertItemInList(itemToAdd, targetItem, list = this.data) {
    for (let i = list.length - 1; i >= 0; i--) {
      if (list[i].id === targetItem.id) {
        if (itemToAdd.hierarchyIndex < targetItem.hierarchyIndex) {
          list.splice(i + 1, 0, itemToAdd);
        } else {
          list.splice(i, 0, itemToAdd);
        }
      } else if (list[i].items) {
        this.insertItemInList(itemToAdd, targetItem, list[i].items);
      }
    }
  }

  // Insert item as child of target item
  insertItemAsChild(itemToAdd, targetItem, list = this.data) {
    for (let i = list.length - 1; i >= 0; i--) {
      if (list[i].id === targetItem.id) {
        if (!list[i].items) {
          list[i].items = [];
        }
        list[i].items.push(itemToAdd);
        return;
      } else if (list[i].items) {
        this.insertItemAsChild(itemToAdd, targetItem, list[i].items);
      }
    }
  }

  // Remove all items that are empty
  // - isEmpty is a function that returns true if the item is empty
  removeEmptyItems(isEmpty, list = this.data) {
    for (let i = list.length - 1; i >= 0; i--) {
      if (isEmpty(list[i])) {
        list.splice(i, 1);
      } else if (list[i].items) {
        this.removeEmptyItems(isEmpty, list[i].items);
      }
    }
  }

  findItemList(targetItem, list = this.data) {
    for (let i = list.length - 1; i >= 0; i--) {
      if (list[i].id === targetItem.id) {
        return list;
      } else if (list[i].items) {
        const subList = this.findItemList(targetItem, list[i].items);
        if (subList) {
          return subList;
        }
      }
    }

    return null;
  }

  isFirstInList(item, list = this.data) {
    const targetList = this.findItemList(list, item);
    return targetList[0].id === item.id;
  }

  isLastInList(item, list = this.data) {
    const targetList = this.findItemList(list, item);
    return targetList[targetList.length - 1].id === item.id;
  }

  moveItemUpInList(item) {
    const targetList = this.findItemList(item);
    const index = targetList.findIndex((itm) => itm.id === item.id);
    // if its not the first item in the list
    if (index > 0) {
      const temp = targetList[index - 1];
      targetList[index - 1] = item;
      targetList[index] = temp;
      return true;
    }

    return false;
  }

  moveItemDownInList(item) {
    const targetList = this.findItemList(item);
    const index = targetList.findIndex((itm) => itm.id === item.id);
    // if its not the last item in the list
    if (index < targetList.length - 1) {
      const temp = targetList[index + 1];
      targetList[index + 1] = item;
      targetList[index] = temp;
      return true;
    }

    return false;
  }

  // It appends the items as siblings to the target item
  appendItemsToItem(item, items) {
    let targetList = this.findItemList(item);

    let index = 0;
    if (!targetList) {
      targetList = this.data;
    } else {
      index = targetList.findIndex((itm) => itm.id === item.id);
      const firstItem = items.shift();
      targetList[index].text = firstItem.text;
      targetList[index].items = firstItem.items.concat(targetList[index].items);
    }

    targetList.splice(index + 1, 0, ...items);
  }

  addItemsAsChildren(item, items) {
    const targetList = this.findItemList(item);
    const index = targetList.findIndex((itm) => itm.id === item.id);
    targetList[index].items.splice(index + 1, 0, ...items);
  }

  hasHeritage(hasHeritage, list = this.data) {
    for (let i = list.length - 1; i >= 0; i--) {
      if (hasHeritage(list[i])) {
        return true;
      } else if (list[i].items) {
        if (this.hasHeritage(hasHeritage, list[i].items)) {
          return true;
        }
      }
    }
    return false;
  }

  updateAllElements(update, list = this.data) {
    for (let i = list.length - 1; i >= 0; i--) {
      update(list[i]);
      if (list[i].items) {
        this.updateAllElements(update, list[i].items);
      }
    }
  }

  findItem(method, list = this.data) {
    for (let i = list.length - 1; i >= 0; i--) {
      if (method(list[i])) {
        return list[i];
      } else if (list[i].items) {
        const item = this.findItem(method, list[i].items);
        if (item) {
          return item;
        }
      }
    }
    return null;
  }

  countCompletedItems(list = this.data, data = { checked: 0, total: 0 }) {
    for (let i = 0; i < list.length; i++) {
      data.total++;
      if (list[i].checked) {
        data.checked++;
      }
      if (list[i].items) {
        this.countCompletedItems(list[i].items, data);
      }
    }
    return data;
  }

  didCountChanged(oldCount) {
    const newCount = this.countCompletedItems();
    return oldCount.total !== newCount.total;
  }
}
