import * as PIXI from 'pixi.js';
import Styles, { COLOR_DISPLAY_SEPARATOR } from 'pixi-project/view/Styles';
import TextDisplay from './TextDisplay';
import { ANALYTICS_DATA_DEFAULT_PLACEHOLDER, GOAL_DEFAULT_NAME } from 'shared/CSharedConstants';
import { SEPARATOR_THICKNESS } from 'pixi-project/core/BaseAnalyticsManager';
import { numSeparator } from 'shared/NumberHelpers';
import { isGoalValid } from 'shared/ValidateHelpers';
import { roundTo } from 'shared/CSharedMethods';
import { shortenText } from 'shared/SharedHelpers';

export default class CommonDisplaySegment extends PIXI.Container {
  constructor() {
    super();
    this.currency = '';
    this.textDisplay = new TextDisplay();
    this.addChild(this.textDisplay);
  }

  setData(title, value, goal, width) {
    const displayData = [
      { value: title, ...Styles.FOOTER_TITLE },
      { value: value, ...Styles.FOOTER_NUMBER },
    ];

    if (isGoalValid(goal)) {
      const goalVal = parseFloat(value.replace(/,/g, '')) * goal.goalValue;
      displayData.push(
        {
          isSeparator: true,
          color: COLOR_DISPLAY_SEPARATOR,
          thickness: SEPARATOR_THICKNESS,
          width: width,
        },
        { value: shortenText(goal.goalName, 20) || GOAL_DEFAULT_NAME, ...Styles.FOOTER_TITLE },
        {
          value: `${this.currency}${numSeparator(roundTo(goalVal, 2))}`,
          ...Styles.FOOTER_NUMBER,
        },
      );
    }
    this.textDisplay.setData(displayData);
  }

  setNoData() {
    this.textDisplay.setData(['n/a']);
  }

  resetData() {
    this.textDisplay.setData([ANALYTICS_DATA_DEFAULT_PLACEHOLDER]);
  }

  getArea() {
    return this.textDisplay.areaSize;
  }
}
