import React from 'react';
import PropTypes from 'prop-types';
import { pinned } from 'react-project/assets/Icons';

const COLOR = {
  active: '#009BF3',
  inactive: '#636E84',
};

export const Pinned = ({ isPinned, onClick }) => {
  const pinnedColor = isPinned ? COLOR.active : COLOR.inactive;
  return (
    <div
      style={{ fill: pinnedColor }}
      onClick={() => {
        onClick(!isPinned);
      }}
    >
      {' '}
      {pinned(pinnedColor)}{' '}
    </div>
  );
};

Pinned.propTypes = {
  isPinned: PropTypes.bool,
  onClick: PropTypes.func,
};
