import React, { useEffect, useRef, useState } from 'react';
import { When } from 'react-project/Util/When';
import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';
import { iconExternalLink, iconLink } from 'react-project/assets/Icons';
import styles from './MyLink.module.scss';

export const MyLink = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  const [isOpened, setIsOpened] = useState(false);
  const anchorRef = useRef();
  const popupRef = useRef();

  const onPointerOut = (e) => {
    setIsOpened(false);
  };

  const onPointerDown = (e) => {
    setIsOpened(true);
    e.stopPropagation();
  };

  const fitIn = () => {
    if (anchorRef.current && popupRef.current) {
      const relativeRectangle = anchorRef.current.getBoundingClientRect();
      const contentRect = popupRef.current.getBoundingClientRect();
      const outerRect = anchorRef.current.closest('.RichEditor-editor').getBoundingClientRect();

      const padding = 10;
      // This is the absolute position of the right side of the link on the screen
      let x = relativeRectangle.x + contentRect.width + padding;

      // if that gets out of the
      if (x > outerRect.x + outerRect.width) {
        // fit at right side
        const dx = x - (outerRect.x + outerRect.width);
        popupRef.current.style.left = `${relativeRectangle.x - dx - outerRect.x}px`;
      } else {
        // position at element position
        popupRef.current.style.left = `${relativeRectangle.x - outerRect.x}px`;
      }
    }
  };

  useEffect(() => {
    fitIn();
  }, [anchorRef.current, popupRef.current, isOpened]);

  return (
    <ClickOutsideCustomComponent ignoreClickOutside={false} onClickOutside={(e) => onPointerOut(e)}>
      <a
        ref={anchorRef}
        onPointerDown={onPointerDown}
        style={{ textDecoration: 'underline', color: 'blue' }}
        href={url}
      >
        {props.children}

        <div
          ref={popupRef}
          onPointerDown={(e) => {
            e.stopPropagation();
            e.preventDefault();
            window.open(url, '_blank');
          }}
          className={styles.LinkPopup}
          style={{
            display: isOpened ? 'flex' : 'none',
          }}
        >
          {url}
          {iconExternalLink}
        </div>
      </a>
    </ClickOutsideCustomComponent>
  );
};

export const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
};
