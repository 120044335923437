import { PR_EVENT_FUNNEL_CHANGED, PR_NOTES_TITLE_UPDATED } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';

export default class CommandElementTitle {
  constructor(object, previousTitle, nextTitle) {
    this.object = object;
    this.previousTitle = previousTitle;
    this.nextTitle = nextTitle;

    this.isExecuted = false;
  }

  execute() {
    if (!this.isExecuted) {
      this.object.drawTitleLabel(this.nextTitle);
      // update the title of the note , if there is one
      if (this.object.notes) {
        this.object.notes = {
          ...this.object.notes,
          title: this.nextTitle,
        };
      }

      window.app.needsRendering();
      this.isExecuted = true;
      // send that title was updated
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
      commonSendEventFunction(PR_NOTES_TITLE_UPDATED, {
        id: this.object.id,
        title: this.nextTitle,
      });
    }
  }

  revert() {
    if (this.isExecuted) {
      this.object.drawTitleLabel(this.previousTitle);
      // update the title of the note , if there is one
      if (this.object.notes) {
        this.object.notes = {
          ...this.object.notes,
          title: this.previousTitle,
        };
      }

      window.app.needsRendering();
      this.isExecuted = false;
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
      commonSendEventFunction(PR_NOTES_TITLE_UPDATED, {
        id: this.object.id,
        title: this.previousTitle,
      });
    }
  }
}
