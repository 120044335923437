import cls from 'classnames';
import { isNumber } from 'lodash';
import React, { useEffect, useState } from 'react';
import styles from 'react-project/Toolbar/Toolbar.module.scss';
import uiStyles from 'react-project/ui.module.scss';
import { FloatingWindow } from '../floatingWindow/FloatingWindow';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import {
  PR_EVENT_EXPENSE_ADDED,
  PR_EVENT_EXPENSE_REMOVED,
  PR_EVENT_FUNNEL_CHANGED,
} from 'shared/CSharedEvents';
import { When } from 'react-project/Util/When';
import { periodOptions } from './ForecastingComponent';
import { DropDown } from '../dropdowncomponent/dropdown';

const TITLE = 'Manual Expense';

export const ForecastingExpenses = ({ onClose, expenseData }) => {
  const nameRef = React.createRef();
  const amountRef = React.createRef();
  const [selectedPeriod, setPeriod] = useState(expenseData.period);

  const isEditing = !!expenseData.id;

  const isValidNumber = (value) => {
    const number = parseFloat(value);
    if (isNumber(number) && number.toString() === value) {
      return true;
    }
    return false;
  };

  const validateInput = (input) => {
    let isValid = false;
    if (isValidNumber(input.value)) {
      input.setCustomValidity('');
      isValid = true;
    } else {
      input.setCustomValidity('Please enter a number');
    }

    input.reportValidity();
    return isValid;
  };

  const validateNotEmpty = (input) => {
    let isValid = false;
    if (input.value.trim().length > 0) {
      input.setCustomValidity('');
      isValid = true;
    } else {
      input.setCustomValidity('Please enter a value');
    }

    input.reportValidity();
    return isValid;
  };

  const onNameChange = (e) => {
    validateNotEmpty(e.target);
  };

  const onAmountChange = (e) => {
    const isValid = validateNotEmpty(e.target);
    if (!isValid) {
      return;
    }

    validateInput(e.target);
  };

  const onAddExpense = () => {
    const name = nameRef.current.value;
    const amount = amountRef.current.value;

    const isNameValid = validateNotEmpty(nameRef.current);
    if (!isNameValid) {
      return;
    }

    const isAmountEmpty = validateNotEmpty(amountRef.current);
    if (!isAmountEmpty) {
      return;
    }

    const isAmountValid = validateInput(amountRef.current);
    if (!isAmountValid) {
      return;
    }

    const data = {
      id: expenseData.id,
      name,
      amount,
      period: selectedPeriod,
    };

    commonSendEventFunction(PR_EVENT_EXPENSE_ADDED, data);
    onClose();

    // trigger saving of canvas state , save canvas , canvas save
    commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
  };
  const onDeleteExpense = () => {
    commonSendEventFunction(PR_EVENT_EXPENSE_REMOVED, { id: expenseData.id });
    onClose();
  };

  const onInputKey = (e) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      onAddExpense();
    }
  };

  const onPeriodChange = (item) => {
    const period = parseInt(item.value);
    setPeriod(period);
  };

  const dropDownWidth = { width: '180px' };

  const selectedPeriodOption =
    periodOptions.find((item) => {
      return item.value === selectedPeriod;
    }) || periodOptions[0];

  return (
    <>
      <FloatingWindow
        title={TITLE}
        onWindowClose={() => {
          onClose();
        }}
      >
        <div className={cls(styles.Label, styles.LabelGoals)}>
          <div className={styles.LabelLeft}>
            <label className={styles.SideBarLabel}>Name</label>
          </div>
          <div className={styles.LabelWrapper}>
            <input
              ref={nameRef}
              onKeyDown={(e) => e.stopPropagation()}
              onChange={onNameChange}
              placeholder="Enter name"
              className={styles.RsInput}
              type="text"
              defaultValue={expenseData.name}
              autoFocus
            />
          </div>
        </div>

        <div className={cls(styles.Label, styles.LabelGoals)}>
          <div className={styles.LabelLeft}>
            <label className={styles.SideBarLabel}>Period</label>
          </div>
          <div className={styles.LabelWrapper}>
            <DropDown
              onSelectionChanged={onPeriodChange}
              itemList={periodOptions}
              classStyling={uiStyles}
              style={dropDownWidth}
              selectedItem={selectedPeriodOption}
            />
          </div>
        </div>

        <div className={cls(styles.Label, styles.LabelGoals)}>
          <div className={styles.LabelLeft}>
            <label className={styles.SideBarLabel}>Amount</label>
          </div>
          <div className={styles.LabelWrapper}>
            <input
              ref={amountRef}
              onKeyDown={onInputKey}
              onChange={onAmountChange}
              placeholder="0"
              className={styles.RsInput}
              type="text"
              defaultValue={expenseData.amount}
            />
          </div>
        </div>

        <div className={cls(styles.Label, styles.LabelGoals)}>
          <div className={cls(uiStyles.ContainerRows, uiStyles.Bold)}>
            <When condition={isEditing}>
              <div onClick={onDeleteExpense} className={cls(uiStyles.Link, uiStyles.Red)}>
                Delete
              </div>
            </When>
            <div></div>
            <div onClick={onAddExpense} className={cls(uiStyles.Button)}>
              {isEditing ? 'Save' : 'Add'} Expense
            </div>
          </div>
        </div>
      </FloatingWindow>
    </>
  );
};
