import cls from 'classnames';
import React from 'react';
import styles from 'react-project/components/switch/Switch.module.scss';

export const Switch = ({ onToggle, isActive, warn, disabled = false }) => {
  const onClick = (e) => {
    onToggle(!isActive);
  };

  return (
    <div className={isActive ? styles.Active : null}>
      <div className={styles.SwitchWrapper}>
        <label className={`${styles.Switch}`}>
          <input onClick={onClick} type="checkbox" disabled={disabled} />
          <span className={cls(styles.SliderRound, warn && styles.Warn)}></span>
        </label>
      </div>
    </div>
  );
};
