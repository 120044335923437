import { PR_EVENT_FUNNEL_CHANGED } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';

export default class CommandMoveBreakPoint {
  constructor(object, parent, startPosition, endPosition = null) {
    this.object = object;
    this.parent = parent;
    this.startPosition = startPosition.clone();
    this.endPosition = endPosition || object.position.clone();

    this.isExecuted = false;
  }

  execute() {
    if (!this.isExecuted) {
      this.object.x = this.endPosition.x;
      this.object.y = this.endPosition.y;
      this.isExecuted = true;
      this.parent.update();
      this.parent.onBreakPointsUpdated();
      this.parent.onBreakPointsPostUpdate();
      this.parent.updateFlowPoints();
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
    }
  }

  revert() {
    if (this.isExecuted) {
      this.object.x = this.startPosition.x;
      this.object.y = this.startPosition.y;
      this.isExecuted = false;
      this.parent.update();
      this.parent.onBreakPointsUpdated();
      this.parent.onBreakPointsPostUpdate();
      this.parent.updateFlowPoints();
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
    }
  }
}
