/**
 * This class will contain funnel properties inside of its static fields.
 * They should be static in order to share same data across multiple files.
 */
export class FunnelProperties {
  static _projectId;
  static _ownerId;
  static _customerId;

  static setProjectId(projectId) {
    FunnelProperties._projectId = projectId;
  }

  static getProjectId() {
    return FunnelProperties._projectId;
  }

  static setOwnerId(ownerId) {
    FunnelProperties._ownerId = ownerId;
  }

  static setCustomerId(customerId) {
    FunnelProperties._customerId = customerId;
  }

  static getOwnerId() {
    return FunnelProperties._ownerId;
  }

  static getCustomerId() {
    return FunnelProperties._customerId;
  }
}
