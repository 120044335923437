import { ActionNames } from 'react-project/redux/actionNamesConstant';
import { createAction } from 'redux-actions';

export const setUIStateAction = createAction(ActionNames.setUIStateAction);

export const setUIState = (newState) => {
  return (dispatch) => {
    dispatch(setUIStateAction(newState));
  };
};
