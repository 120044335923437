import CommandAddNote from 'pixi-project/base/command-system/commands/CommandAddNote';
import CommandBatchHighlighted from 'pixi-project/base/command-system/commands/CommandBatchHighlighted';
import CommandBatchMove from 'pixi-project/base/command-system/commands/CommandBatchMove';
import CommandBatchStyleReport from 'pixi-project/base/command-system/commands/CommandBatchStyleReport';
import CommandContentIcon from 'pixi-project/base/command-system/commands/CommandContentIcon';
import CommandDeleteNote from 'pixi-project/base/command-system/commands/CommandDeleteNote';
import CommandDrawLineType from 'pixi-project/base/command-system/commands/CommandDrawLineType';
import CommandEditNote from 'pixi-project/base/command-system/commands/CommandEditNote';
import CommandElementTitle from 'pixi-project/base/command-system/commands/CommandElementTitle';
import CommandElementURL from 'pixi-project/base/command-system/commands/CommandElementURL';
import CommandLineType from 'pixi-project/base/command-system/commands/CommandLineType';
import CommandShapeStyle from 'pixi-project/base/command-system/commands/CommandShapeStyle';
import CommandStyleReportSlide from 'pixi-project/base/command-system/commands/CommandStyleReportSlide';
import CommandTextContent from 'pixi-project/base/command-system/commands/CommandTextContent';
import CommandTextStyle from 'pixi-project/base/command-system/commands/CommandTextStyle';
import CommandThumbnail from 'pixi-project/base/command-system/commands/CommandThumbnail';
import CommandsBatch from 'pixi-project/base/command-system/CommandsBatch';
import MainStorage from 'pixi-project/core/MainStorage';
import AppSignals from 'pixi-project/signals/AppSignals';
import { CANVAS_NOTE_ID } from 'react-project/LeftSideBar/Notes/NotesBlock';
import { EElementTypes } from 'shared/CSharedCategories';
import {
  ANALYTICS_STATUS_LOADING,
  PropertyType,
  SELECTION_UPDATE_SOURCE_GOAL_CHANGED,
} from 'shared/CSharedConstants';
import {
  PR_EVENT_ANALYTICS_NEEDS_REFRESH,
  PR_EVENT_COMPARE_RANGE_CHANGED,
  PR_EVENT_FUNNEL_CHANGED,
  PR_EVENT_SAVE_RESPONSE,
  PR_EVENT_SHAPE_STYLE_CHANGED,
  PR_REPORT_STYLE_APPLICATION_CHANGED,
  PR_REPORT_STYLE_CHANGED,
  PR_STEP_TEXTURE_UPDATED,
  RP_ANALYTICS_FILTER_DATA_CHANGED,
  RP_EVENT_ANALYTICS_STATUS_CHANGED,
  RP_EVENT_CANCEL_NEW_STEP_FROM_CONNECTION,
  RP_EVENT_CONNECTION_LINE_TYPE_CHANGED,
  RP_EVENT_COPY_PRESSED,
  RP_EVENT_CUSTOM_ICONS_LOADED,
  RP_EVENT_DELETE_PRESSED,
  RP_EVENT_DESELECT_ALL,
  RP_EVENT_DONE_SAVING,
  RP_EVENT_DRAW_LINE_TYPE_CHANGED,
  RP_EVENT_DUPLICATE_PRESSED,
  RP_EVENT_ELEMENT_SPREAD,
  RP_EVENT_HORIZONTAL_ALIGNMENT,
  RP_EVENT_HORIZONTAL_SPACING,
  RP_EVENT_ICON_CHOSEN,
  RP_EVENT_NOTE_EDITING_FINISHED,
  RP_EVENT_ON_GOAL_CHANGED,
  RP_EVENT_ON_NOTE_EDITED,
  RP_EVENT_PASTE_PRESSED,
  RP_EVENT_PDF_CREATED,
  RP_EVENT_PEOPLE_LOADED,
  RP_EVENT_PEOPLE_LOADED_MORE,
  RP_EVENT_PHOTO_UPLOADED,
  RP_EVENT_PHOTO_UPLOAD_STARTED,
  RP_EVENT_PROPERTY_CHANGED,
  RP_EVENT_REDO_CLICKED,
  RP_EVENT_REFRESH_REQUEST,
  RP_EVENT_RESTORE_BUTTON_CLICKED,
  RP_EVENT_SAVE_REQUEST,
  RP_EVENT_SAVE_TO_PNG,
  RP_EVENT_SESSION_DESELECTED,
  RP_EVENT_STEP_FOCUS_CLEARED,
  RP_EVENT_TAB_CLICKED,
  RP_EVENT_TEXT_HYPERLINK_STARTED_EDITING,
  RP_EVENT_TEXT_PROPERTY_CHANGED,
  RP_EVENT_CHECKLIST_CHANGED,
  RP_EVENT_TOP_COUNTRIES_LOADED,
  RP_EVENT_UNDO_CLICKED,
  RP_EVENT_UPDATE_STEP_THUMBNAIL,
  RP_EVENT_UPSELL_POPUP_CLOSED,
  RP_EVENT_VERTICAL_ALIGNMENT,
  RP_EVENT_VERTICAL_SPACING,
  RP_EVENT_WIDGET_DATA_UPDATED,
  RP_EVENT_WIDGET_DROPPED,
  RP_SCROLL_ELEMENT_INTO_VIEW,
  RP_EVENT_EXPORT_CSV_OBJECTS,
  RP_EVENT_EXPORT_CSV_CONNECTIONS,
  RP_EVENT_LAYER_TOGGLED,
  RP_EVENT_TRENDS_LOADED,
  RP_EVENT_TRENDS_STEP_PICKED,
  RP_EVENT_TRENDS_PICKER_CLOSED,
  RP_EVENT_ON_FORECASTING_CHANGED,
  RP_EVENT_CALENDAR_DATE_CHANGED,
  RP_EVENT_BENCHMARK_FROM_ANALYTICS,
  PR_EVENT_EXPENSE_ADDED,
  PR_EVENT_EXPENSE_REMOVED,
} from 'shared/CSharedEvents';
import { cloneData, commonSendEventFunction } from 'shared/CSharedMethods';
import SharedElementHelpers from 'shared/SharedElementHelpers';
import AlignUtility, {
  ALIGN_CENTER_X,
  ALIGN_CENTER_Y,
  SPACING_HORIZONTAL,
  SPACING_VERTICAL,
} from './AlignUtility';
import CommandChecklistChanged from 'pixi-project/base/command-system/commands/CommandChecklistChanged';
import { LayerType } from 'react-project/LayersMenu/LayerType';

export default class AppEventController {
  constructor(sceneManager, selectionManager, commandManager, copyPasteUtility, mainController) {
    this.sceneManager = sceneManager;
    this.selectionManager = selectionManager;
    this.commandManager = commandManager;
    this.copyPasteUtility = copyPasteUtility;
    this.mainController = mainController;

    this.alignUtility = new AlignUtility();

    this.analyticsLoadingStatus = null;
    this.isFunnelLoaded = false;

    this.eventQueue = []; // for events that are fired before the funnel is loaded

    this.addEvents();
  }

  onFunnelLoaded(e) {
    this.isFunnelLoaded = true;
    this.executeEventsInQueue();
  }

  executeEventsInQueue() {
    for (let i = 0; i < this.eventQueue.length; i++) {
      const event = this.eventQueue[i];
      event.method(event.data);
    }
    this.eventQueue = [];
  }

  addMethodToQueue(method, data) {
    this.eventQueue.push({ method, data });
  }

  addEvents() {
    AppSignals.commandCreated.add(this.onCommandCreated, this);
    AppSignals.stageBeforeRendered.add(this.onBeforeStageRendered, this);
    AppSignals.fetchCustomIcons.add(this.onFetchCustomIcons, this);

    document.addEventListener(
      RP_EVENT_WIDGET_DATA_UPDATED,
      this.onWidgetDataUpdated.bind(this),
      false,
    );
    document.addEventListener(RP_EVENT_WIDGET_DROPPED, this.onWidgetDropped.bind(this), false);
    document.addEventListener(
      RP_EVENT_TOP_COUNTRIES_LOADED,
      this.onAnalyticsTopCountriesLoaded.bind(this),
      false,
    );
    document.addEventListener(
      RP_EVENT_PEOPLE_LOADED,
      this.onAnalyticsPeopleLoaded.bind(this),
      false,
    );
    document.addEventListener(
      RP_EVENT_PEOPLE_LOADED_MORE,
      this.onAnalyticsPeopleLoadedMore.bind(this),
      false,
    );
    document.addEventListener(
      RP_ANALYTICS_FILTER_DATA_CHANGED,
      this.onAnalyticsFilterChanged.bind(this),
      false,
    );
    document.addEventListener(
      RP_EVENT_ANALYTICS_STATUS_CHANGED,
      this.onAnalyticsStatusChanged.bind(this),
      false,
    );
    document.addEventListener(RP_EVENT_REFRESH_REQUEST, this.onAnalyticsRefresh.bind(this), false);
    document.addEventListener(RP_EVENT_UNDO_CLICKED, this.onUndoClicked.bind(this), false);
    document.addEventListener(RP_EVENT_REDO_CLICKED, this.onRedoClicked.bind(this), false);
    document.addEventListener(
      RP_EVENT_STEP_FOCUS_CLEARED,
      this.onStepFocusCleared.bind(this),
      false,
    );
    document.addEventListener(
      PR_EVENT_SHAPE_STYLE_CHANGED,
      this.onShapeStyleChanged.bind(this),
      false,
    );
    document.addEventListener(RP_EVENT_VERTICAL_ALIGNMENT, this.onVerticalAlign.bind(this), false);
    document.addEventListener(
      RP_EVENT_HORIZONTAL_ALIGNMENT,
      this.onHorizontalAlign.bind(this),
      false,
    );

    document.addEventListener(RP_EVENT_VERTICAL_SPACING, this.onVerticalSpacing.bind(this), false);
    document.addEventListener(
      RP_EVENT_HORIZONTAL_SPACING,
      this.onHorizontalSpacing.bind(this),
      false,
    );

    document.addEventListener(RP_EVENT_SAVE_TO_PNG, this.onSaveToPng.bind(this), false);
    document.addEventListener(
      RP_EVENT_CONNECTION_LINE_TYPE_CHANGED,
      this.onConnectionLineTypeChanged.bind(this),
      false,
    );
    document.addEventListener(
      RP_EVENT_DRAW_LINE_TYPE_CHANGED,
      this.onDrawLineTypeChanged.bind(this),
      false,
    );
    document.addEventListener(RP_EVENT_ICON_CHOSEN, this.onIconChosen.bind(this), false);
    document.addEventListener(
      RP_EVENT_PROPERTY_CHANGED,
      this.onElementPropertyChanged.bind(this),
      false,
    );
    document.addEventListener(
      RP_EVENT_RESTORE_BUTTON_CLICKED,
      this.onRestoreClicked.bind(this),
      false,
    );
    document.addEventListener(
      RP_EVENT_CANCEL_NEW_STEP_FROM_CONNECTION,
      this.onCancelNewStepFromConnection.bind(this),
      false,
    );
    document.addEventListener(
      RP_EVENT_UPDATE_STEP_THUMBNAIL,
      this.onUpdateStepThumbnail.bind(this),
      false,
    );
    document.addEventListener(RP_EVENT_DELETE_PRESSED, this.onDeleteObjects.bind(this), false);
    document.addEventListener(RP_EVENT_COPY_PRESSED, this.onCopyObjects.bind(this), false);
    document.addEventListener(
      RP_EVENT_DUPLICATE_PRESSED,
      this.onDuplicateObjects.bind(this),
      false,
    );
    document.addEventListener(RP_EVENT_PASTE_PRESSED, this.onPasteObjects.bind(this), false);
    document.addEventListener(
      RP_EVENT_TEXT_PROPERTY_CHANGED,
      this.onTextPropertyChanged.bind(this),
      false,
    );
    document.addEventListener(
      RP_EVENT_TEXT_HYPERLINK_STARTED_EDITING,
      this.onTextHyperlinkStartedEditing.bind(this),
      false,
    );
    document.addEventListener(RP_EVENT_SESSION_DESELECTED, this.onSessionDeselected.bind(this));
    document.addEventListener(RP_EVENT_PDF_CREATED, this.onPDFCreated.bind(this));
    document.addEventListener(
      PR_EVENT_COMPARE_RANGE_CHANGED,
      this.onCompareRangeChanged.bind(this),
    );
    document.addEventListener(RP_EVENT_UPSELL_POPUP_CLOSED, this.onUpsellPopupClosed.bind(this));
    document.addEventListener(RP_EVENT_ELEMENT_SPREAD, this.onElementSpread.bind(this));
    document.addEventListener(PR_STEP_TEXTURE_UPDATED, this.onElementTextureUpdated.bind(this));
    document.addEventListener(RP_EVENT_CUSTOM_ICONS_LOADED, this.onCustomIconsLoaded.bind(this));
    document.addEventListener(PR_REPORT_STYLE_CHANGED, this.onReportViewStyleChanged.bind(this));
    document.addEventListener(
      PR_REPORT_STYLE_APPLICATION_CHANGED,
      this.onReportViewStyleApplicationChanged.bind(this),
    );
    document.addEventListener(RP_EVENT_PHOTO_UPLOADED, this.onPhotoObjectUploaded.bind(this));
    document.addEventListener(
      RP_EVENT_PHOTO_UPLOAD_STARTED,
      this.onPhotoObjectUploadStarted.bind(this),
    );
    document.addEventListener(RP_EVENT_ON_GOAL_CHANGED, this.onGoalChanged.bind(this));
    document.addEventListener(RP_EVENT_ON_NOTE_EDITED, this.onNoteEdited.bind(this));
    document.addEventListener(
      RP_EVENT_NOTE_EDITING_FINISHED,
      this.onNoteEditingFinished.bind(this),
    );
    document.addEventListener(RP_SCROLL_ELEMENT_INTO_VIEW, this.onElementScrollToView.bind(this));
    document.addEventListener(RP_EVENT_DESELECT_ALL, this.onDeselectAll.bind(this));
    document.addEventListener(RP_EVENT_TAB_CLICKED, this.onLeftPanelTabClicked.bind(this));
    document.addEventListener(RP_EVENT_CHECKLIST_CHANGED, this.onChecklistChanged.bind(this));
    document.addEventListener(RP_EVENT_SAVE_REQUEST, this.onSaveRequest.bind(this), false);
    document.addEventListener(RP_EVENT_DONE_SAVING, this.onDoneSaving.bind(this), false);
    document.addEventListener(
      RP_EVENT_EXPORT_CSV_OBJECTS,
      this.onCSVExportObjects.bind(this),
      false,
    );
    document.addEventListener(
      RP_EVENT_EXPORT_CSV_CONNECTIONS,
      this.onCSVExportConnections.bind(this),
      false,
    );

    document.addEventListener(RP_EVENT_LAYER_TOGGLED, this.onLayerToggled.bind(this), false);
    document.addEventListener(RP_EVENT_TRENDS_LOADED, this.onTrendsDataRecieved.bind(this), false);
    document.addEventListener(
      RP_EVENT_TRENDS_STEP_PICKED,
      this.onTrendsStepPicked.bind(this),
      false,
    );
    document.addEventListener(
      RP_EVENT_TRENDS_PICKER_CLOSED,
      this.onTrendsPickerClosed.bind(this),
      false,
    );
    document.addEventListener(
      RP_EVENT_ON_FORECASTING_CHANGED,
      this.onFrecastingChanged.bind(this),
      false,
    );

    document.addEventListener(
      RP_EVENT_CALENDAR_DATE_CHANGED,
      this.onCalendarDateChanged.bind(this),
    );

    document.addEventListener(
      RP_EVENT_BENCHMARK_FROM_ANALYTICS,
      this.onBenchmarkFromAnalytics.bind(this),
    );

    document.addEventListener(PR_EVENT_EXPENSE_ADDED, this.onExpenseAdded.bind(this));
    document.addEventListener(PR_EVENT_EXPENSE_REMOVED, this.onExpenseRemoved.bind(this));
  }

  onTrendsDataRecieved(e) {
    // wait for the app to finish loading then execute the event
    if (!this.isFunnelLoaded) {
      this.addMethodToQueue(this.onTrendsDataRecieved.bind(this), e);
      return;
    }

    const trends = e.detail.trends;
    const trendsCompare = e.detail.trendsCompare;
    const ranges = e.detail.ranges;
    const rangesCompare = e.detail.rangesCompare;
    const error = e.detail.error;
    const widgetId = e.detail.widgetId;
    const range = e.detail.range;
    const rangeCompare = e.detail.rangeCompare;

    for (let i = 0; i < this.sceneManager.objects.length; i++) {
      const object = this.sceneManager.objects[i];
      if (SharedElementHelpers.IsTrendsWidget(object) && object.id === widgetId) {
        if (error) {
          object.onDataError(error);
        } else {
          if (trends[object.id]) {
            object.setWidgetData(
              trends ? trends[object.id] : null,
              trendsCompare ? trendsCompare[object.id] : null,
              ranges,
              rangesCompare,
              range,
              rangeCompare,
            );
            object.onDataFinishedLoading(
              trends ? trends[object.id] : null,
              trendsCompare ? trendsCompare[object.id] : null,
              ranges,
              rangesCompare,
              range,
              rangeCompare,
            );
          } else {
            console.warn(`Trends data received but there is no widget with this id ${widgetId}`);
          }
        }
      }
    }
  }

  onTrendsStepPicked(e) {
    const trendsWidget = this.sceneManager.getElementById(e.detail.widgetId);
    trendsWidget.onStepPicked(e.detail.step);
  }

  onTrendsPickerClosed(e) {
    const trendsWidget = this.sceneManager.getElementById(e.detail.widgetId);
    trendsWidget.onPickerClosed(this.sceneManager);
  }

  onWidgetDataUpdated(event) {
    const eventData = event.detail;
    for (let i = 0; i < this.sceneManager.objects.length; i++) {
      const object = this.sceneManager.objects[i];
      if (SharedElementHelpers.IsWidget(object) && object.category === eventData.category) {
        if (eventData.data && object.id === eventData.id) {
          object.setWidgetData(eventData.data);
        }
      }
    }
  }

  onWidgetDropped(event) {
    event.detail.position.x /= window.app.scaleManager.aspectRatio;
    event.detail.position.y /= window.app.scaleManager.aspectRatio;
    this.sceneManager.createWidget(event);
  }

  onAnalyticsTopCountriesLoaded(event) {
    for (let i = 0; i < this.sceneManager.objects.length; i++) {
      const object = this.sceneManager.objects[i];
      if (SharedElementHelpers.IsWidget(object) && object.type === EElementTypes.WIDGET_COUNTRIES) {
        object.setWidgetData(event.detail);
      }
    }
  }

  onAnalyticsPeopleLoaded(event) {
    for (let i = 0; i < this.sceneManager.objects.length; i++) {
      const object = this.sceneManager.objects[i];
      if (SharedElementHelpers.IsWidget(object) && object.type === EElementTypes.WIDGET_PEOPLE) {
        object.setWidgetData(event.detail.data);
      }
    }
  }

  onAnalyticsPeopleLoadedMore(event) {
    for (let i = 0; i < this.sceneManager.objects.length; i++) {
      const object = this.sceneManager.objects[i];
      if (SharedElementHelpers.IsWidget(object) && object.type === EElementTypes.WIDGET_PEOPLE) {
        object.addWidgetData(event.detail.data);
      }
    }
  }

  onAnalyticsFilterChanged(e) {
    const keys = Object.keys(e.detail.entities);

    for (let i = 0; i < keys.length; i++) {
      const stepId = keys[i];
      const step = this.sceneManager.getElementById(stepId);
      step.analyticsManager.updateFilter(e.detail.entities[stepId]);
    }
    window.app.needsRendering();
  }

  onAnalyticsStatusChanged(e) {
    // Once the analytics data has loaded, check if an element was edited while loading
    // Note: edge case for 'Refresh analytics' status. For most of the cases refer to onElementChanged
    if (
      this.analyticsLoadingStatus === ANALYTICS_STATUS_LOADING &&
      this.mainController.hasElementChanged
    ) {
      commonSendEventFunction(PR_EVENT_ANALYTICS_NEEDS_REFRESH);
      this.mainController.hasElementChanged = false;
    }
    this.analyticsLoadingStatus = e.detail.status;
    window.app.needsRendering();
  }

  onAnalyticsRefresh(e) {
    const isCancel = (e && e.detail && e.detail.isCancel) || false;
    this.sceneManager.analyticsRefreshed(isCancel);
    this.selectionManager.updateFocused();
  }

  onUndoClicked() {
    this.commandManager.undo();
  }

  onRedoClicked() {
    this.commandManager.redo();
  }

  onStepFocusCleared(e) {
    if (e.detail.step) {
      this.selectionManager.focusSelection.removeByStep(e.detail.types, e.detail.step);
    } else {
      this.selectionManager.focusSelection.removeByFilterType(e.detail.types);
    }
  }

  onShapeStyleChanged(e) {
    const shape = this.selectionManager.selectedElement;
    const oldShapeData = cloneData(shape.shapeData);

    const style = e.detail;
    shape.setStyle(style);

    const command = new CommandShapeStyle(shape, oldShapeData);
    this.commandManager.execute(command);
  }

  /**
   * Align a set of selected elements to the top
   */
  onVerticalAlign() {
    this.alignObjects(ALIGN_CENTER_X);
  }

  /**
   * Align a set of selected elements to the left
   */
  onHorizontalAlign() {
    this.alignObjects(ALIGN_CENTER_Y);
  }

  onVerticalSpacing() {
    this.spaceObjects(SPACING_VERTICAL);
    this.alignObjects(ALIGN_CENTER_Y);
  }

  onHorizontalSpacing() {
    this.spaceObjects(SPACING_HORIZONTAL);
    this.alignObjects(ALIGN_CENTER_X);
  }

  onSaveToPng(e) {
    const resolution = e.detail.resolution;
    this.sceneManager.saveToPng(resolution);
  }

  /**
   * Change inBetween status of a connection
   * Temporarily updates status of all connected outgoing connections
   * @param e
   * @private
   */
  onConnectionLineTypeChanged(e) {
    const batchCommands = new CommandsBatch();
    for (let i = 0; i < this.selectionManager.selectedObjects.length; i++) {
      const object = this.selectionManager.selectedObjects[i];
      const command = new CommandLineType(object, e.detail.lineType);
      batchCommands.add(command);
    }
    this.commandManager.execute(batchCommands);
  }

  onDrawLineTypeChanged(e) {
    const batchCommands = new CommandsBatch();
    for (let i = 0; i < this.selectionManager.selectedObjects.length; i++) {
      const object = this.selectionManager.selectedObjects[i];
      const command = new CommandDrawLineType(object, e.detail.drawLineType);
      batchCommands.add(command);
    }
    this.commandManager.execute(batchCommands);
  }

  onIconChosen(data) {
    const step = this.sceneManager.getElementById(data.detail.stepId);
    const texturePath = data.detail.texturePath;
    const isCustom = data.detail.isCustom;
    const command = new CommandContentIcon(step, texturePath, isCustom);
    this.commandManager.execute(command);
  }

  onElementPropertyChanged(event) {
    const type = event.detail.type;
    const stepId = event.detail.stepId;
    const element = this.sceneManager.getElementById(stepId);

    if (type === PropertyType.LABEL) {
      const command = new CommandElementTitle(
        element,
        event.detail.previousValue,
        event.detail.currentValue,
      );
      this.commandManager.execute(command);
    } else if (type === PropertyType.URL) {
      const command = new CommandElementURL(
        element,
        event.detail.previousValue,
        event.detail.currentValue,
      );
      this.commandManager.execute(command);
    }
  }

  onRestoreClicked(e) {
    this.mainController.clearStage();
  }

  /**
   * Cancel the joint to the coordinates if after opening a "new step" dialog
   * we cancel the action somehow
   * @private
   */
  onCancelNewStepFromConnection() {
    this.sceneManager.removeCoordinatesJoint();
  }

  /**
   * Change texture to new thumbnail for the element
   * @param e
   * @private
   */
  onUpdateStepThumbnail(e) {
    const data = e.detail;
    if (!data || !data.url || typeof data.id === 'undefined') {
      throw Error(`[_onUpdateStepThumbnail] Wrong data format ${data}`);
    }

    const step = this.sceneManager.getElementById(data.id);
    // Only for 'step' type for now
    if (SharedElementHelpers.IsStep(step)) {
      step.loadThumbnail(data.url, (texture) => {
        const thumbnail = step.generateThumbnail(texture);
        const command = new CommandThumbnail(step, data.url, true, thumbnail);
        this.commandManager.execute(command);
      });
    }
  }

  onDeleteObjects(e) {
    this.sceneManager.deleteSelection();
  }

  onCopyObjects(e) {
    this.copyPasteUtility.copySelection();
    this.selectionManager.hideToolbar();
  }

  onDuplicateObjects(e) {
    this.copyPasteUtility.duplicateSelection();
    this.selectionManager.hideToolbar();
  }

  onPasteObjects(e) {
    if (MainStorage.getCanvasPermissions().isReadonlyAccess) {
      return;
    }

    const position = e.detail;
    // Map the point to the canvas resolution
    position.x *= 1 / window.app.scaleManager.aspectRatio;
    position.y *= 1 / window.app.scaleManager.aspectRatio;
    this.copyPasteUtility.pasteClipboard(position);
  }

  onTextHyperlinkStartedEditing(e) {
    const textLabel = this.selectionManager.selectedElement;
    if (textLabel && SharedElementHelpers.IsText(textLabel)) {
      textLabel.previousValue = textLabel.getText();
    }
  }

  onTextPropertyChanged(e) {
    if (this.selectionManager.selectedElement) {
      if (e.detail.isFinal !== undefined) {
        const textLabel = this.selectionManager.selectedElement;
        const oldText = textLabel.previousValue;
        const newText = textLabel.getText();
        if (oldText !== newText) {
          const command = new CommandTextContent(
            textLabel,
            oldText,
            newText,
            this.selectionManager,
          );
          this.commandManager.execute(command);
        }
      } else if (e.detail.url !== undefined) {
        const textLabel = this.selectionManager.selectedElement;
        textLabel.addHyperlink(e.detail.url);
      } else {
        const textObject = this.selectionManager.selectedElement;
        const command = new CommandTextStyle(textObject, e.detail, this.selectionManager);
        this.commandManager.execute(command);
      }
    }
  }

  onCommandCreated(command, isBatching = false, isMoveBatch = false) {
    if (isBatching) {
      let batch = null;
      if (isMoveBatch) {
        batch = new CommandBatchMove(this.selectionManager);
        batch.addCommands(command);
      } else {
        batch = new CommandBatchHighlighted(this.selectionManager);
        batch.add(command);
      }

      this.commandManager.execute(batch);
    } else {
      this.commandManager.execute(command);
    }
  }

  onBeforeStageRendered(e) {
    this.sceneManager.culling.prepObjectsForRendering();
  }

  onManualSave(e) {
    const data = this.sceneManager.getSceneData(true);
    commonSendEventFunction(PR_EVENT_SAVE_RESPONSE, data);
  }

  onSessionDeselected(e) {
    const widgets = this.sceneManager.getAllWidgets();
    for (let i = 0; i < widgets.length; i++) {
      const widget = widgets[i];
      if (widget.type === EElementTypes.WIDGET_PEOPLE) {
        widget.deselectRow(e.detail.id);
      }
    }
  }

  onPDFCreated(e) {
    if (this.sceneManager.reportView) {
      this.sceneManager.reportView.onPDFCreationFinished();
    }
  }

  onCompareRangeChanged(e) {
    this.sceneManager.setCompareRange(e.detail);
    this.sceneManager.onCompareRangeUpdated();
  }

  onUpsellPopupClosed(e) {
    if (this.sceneManager.pointerJoint) {
      this.sceneManager.removeCoordinatesJoint();
      this.selectionManager.hideToolbar();
    }
  }

  onElementSpread(e) {
    const shift = 20;
    const key = e.detail.key;
    const values = e.detail.values;
    const elementData = this.selectionManager.selectedElement.getState();
    const elementAttribute = elementData.filterData.find((itm) => itm.key === key);

    for (let i = 0; i < values.length; i++) {
      const val = values[i];
      // Copy the selected object
      // because we are in a loop it will be the last object that was pasted in this case

      this.copyPasteUtility.copySelection((clipboard) => {
        // Modify the object that is copied
        const o = clipboard.objects[0];
        const attribute = o.filterData.find((itm) => itm.key === key);

        // Set key value
        if (elementAttribute && elementAttribute.value !== val.value) {
          // Modify existing attribute value
          attribute.value = val.value;
        } else if (!elementAttribute) {
          if (attribute) {
            // because we are in a loop
            // modify the newly created attribute
            attribute.value = val.value;
          } else {
            // create a new attribute if it does not exist
            o.filterData.push({
              contains: 'true',
              key: key,
              value: val.value,
            });
          }
        }

        // clean empty values;
        for (let j = o.filterData.length - 1; j >= 0; j--) {
          const fd = o.filterData[j];
          if (fd.value === '') {
            o.filterData.splice(j, 1);
          }
        }

        // set title
        o.label = `${elementData.label} [${key}=${val.value}]`;
      });

      if (this.copyPasteUtility.clipboard) {
        const clipboard = JSON.parse(this.copyPasteUtility.clipboard);
        const elementCopy = clipboard.objects[0];
        const copyFilterAttribute = elementCopy.filterData.find((itm) => itm.key === key);

        // Paste element if its different from element we are trying to copy
        if (
          (elementAttribute && elementAttribute.value !== copyFilterAttribute.value) ||
          !elementAttribute
        ) {
          let center = clipboard.center;
          center.x += shift;
          center.y += shift;
          center = window.app.viewport.toGlobal(center);
          this.copyPasteUtility.pasteClipboard(center);
        }
      }
    }

    // make sure the clipboard is cleared after this operation
    this.copyPasteUtility.reset();
  }

  onElementTextureUpdated(e) {
    // When custom images load , the actual size of the element changes
    this.selectionManager.updateSelection(true, true);
  }

  onCustomIconsLoaded(e) {
    this.sceneManager.customIcons = e.detail;
    for (let i = 0; i < this.sceneManager.objects.length; i++) {
      const object = this.sceneManager.objects[i];
      if (object.isCustom) {
        object.onCustomIconsLoaded(this.sceneManager.customIcons);
      } else if (SharedElementHelpers.IsReport(object)) {
        object.onCustomIconsLoaded(this.sceneManager.customIcons);
      }
    }
  }

  onFetchCustomIcons(object) {
    object.onCustomIconsLoaded(this.sceneManager.customIcons);
  }

  onReportViewStyleChanged(e) {
    const style = e.detail.style;
    const slide = e.detail.reportSlide;

    const styleBatch = new CommandBatchStyleReport(slide);
    const styleCommand = new CommandStyleReportSlide(slide, style);
    styleBatch.add(styleCommand);
    this.commandManager.execute(styleBatch);
  }

  onReportViewStyleApplicationChanged(e) {
    const data = e.detail;
    const reportView = data.reportSlide.reportView;
    reportView.setGlobalStyle(data.reportSlide, data.style, data.isGlobal);
  }

  onPhotoObjectUploaded(e) {
    const stepId = e.detail.stepId; // find step and update its photo
    const step = this.sceneManager.getStepById(stepId);
    if (step) {
      step.onPhotoDataChanged(e.detail);
    }
    commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
  }

  onPhotoObjectUploadStarted(e) {
    const stepId = e.detail.stepId; // find step and update its photo
    const step = this.sceneManager.getStepById(stepId);
    if (step) {
      step.onPhotoLoading();
    }
  }

  onGoalChanged(e) {
    const step = this.sceneManager.getElementById(e.detail.stepId);
    if (step) {
      step.updateGoal(e.detail.goal);
      if (!SharedElementHelpers.IsConnection(step)) {
        step.updateFrameSize();
      }
      this.selectionManager.updateSelection(
        true,
        true,
        true,
        true,
        SELECTION_UPDATE_SOURCE_GOAL_CHANGED,
      );
      window.app.needsRendering();
    }

    for (let i = 0; i < this.sceneManager.objects.length; i++) {
      const object = this.sceneManager.objects[i];
      if (SharedElementHelpers.IsWidget(object) && object.type === EElementTypes.WIDGET_GOALS) {
        object.refreshDisplay();
      }
    }

    commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
  }

  onFrecastingChanged(e) {
    const step = this.sceneManager.getElementById(e.detail.stepId);
    // either step or connetion
    if (step) {
      step.updateForecastingData(e.detail.data);
      if (MainStorage.isForecastingVisible()) {
        this.sceneManager.calculateForecastingData();
        this.mainController.processAnalyticsData();
      }
    }

    commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
  }

  onCalendarDateChanged(e) {
    const data = e.detail;
    MainStorage.setCalendarData(data);

    if (MainStorage.isForecastingVisible()) {
      this.sceneManager.calculateForecastingData();
      this.mainController.processAnalyticsData();
    }
  }

  onBenchmarkFromAnalytics(e) {
    this.sceneManager.onBenchmarkFromAnalytics();
  }

  onExpenseAdded(e) {
    // find all forecasting Widgets
    const objects = this.sceneManager.objects;
    for (let i = 0; i < objects.length; i++) {
      const object = objects[i];
      if (
        SharedElementHelpers.IsWidget(object) &&
        object.type === EElementTypes.WIDGET_FORECASTING
      ) {
        object.onExpenseAdded(e.detail);
      }
    }
  }

  onExpenseRemoved(e) {
    const objects = this.sceneManager.objects;
    for (let i = 0; i < objects.length; i++) {
      const object = objects[i];
      if (
        SharedElementHelpers.IsWidget(object) &&
        object.type === EElementTypes.WIDGET_FORECASTING
      ) {
        object.onExpenseRemoved(e.detail);
      }
    }
  }

  onNoteEdited(e) {
    if (e.detail.id === CANVAS_NOTE_ID) {
      // prevent from editing when the data is the same
      if (
        this.sceneManager.canvasDataNotes &&
        this.sceneManager.canvasDataNotes.data &&
        e.detail.noteData &&
        e.detail.noteData.data
      ) {
        if (
          JSON.stringify(this.sceneManager.canvasDataNotes.data) ===
          JSON.stringify(e.detail.noteData.data)
        ) {
          return;
        }
      }

      this.sceneManager.canvasDataNotes = e.detail.noteData;
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
    } else {
      const step = this.sceneManager.getElementById(e.detail.id);

      if (step.notes && e.detail.isEmpty) {
        // deleting a note
        const command = new CommandDeleteNote(step);
        this.commandManager.execute(command);
      } else if (!step.notes && e.detail.noteData && !e.detail.isEmpty) {
        // adding a new note
        const command = new CommandAddNote(step, e.detail.noteData);
        this.commandManager.execute(command);
      } else if (e.detail.noteData && !e.detail.isEmpty) {
        // Editing an existing note
        step.notes = e.detail.noteData;
        step.onNoteChanged();
        commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
      }
    }
  }

  onNoteEditingFinished(e) {
    if (e.detail.oldData.id === CANVAS_NOTE_ID) {
      // TODO add canvas note editing in the undo queue
    } else {
      const step = this.sceneManager.getElementById(e.detail.oldData.id);
      if (step) {
        if (!step.notes) {
          // nothing to edit here
          return;
        }

        const newNote = cloneData(step.notes);
        const command = new CommandEditNote(step, e.detail.oldData.noteData, newNote);
        this.commandManager.execute(command);
      }
    }
  }

  onDeselectAll(e) {
    this.selectionManager.clearReset();

    this.selectionManager.updateFocused();
    this.selectionManager.focusSelection.visible = true;
  }

  onLeftPanelTabClicked(e) {}

  onLayerToggled(e) {
    const data = e.detail;

    switch (data.type) {
      case LayerType.NOTES:
        this.sceneManager.onNotesToggle(data.isActive);
        break;
      case LayerType.CHECKLIST:
        this.sceneManager.onChecklistToggle(data.isActive);
        break;
      case LayerType.NUMBERS:
        this.sceneManager.toggleAnalytics(data.isActive);
        this.selectionManager.onToggleAnalytics(data.isActive);
        break;
      case LayerType.FORECASTING:
        this.sceneManager.toggleForecasting(data.isActive);
        this.selectionManager.onToggleForecasting(data.isActive);

        break;
      case LayerType.FLOW:
        this.sceneManager.onFlowToggle(data.isActive);
        break;
      default:
        break;
    }

    window.app.needsRendering();
  }

  onChecklistChanged(e) {
    const checklistData = e.detail.checklistData;
    const id = e.detail.id;

    if (id) {
      // Checklist data for a step
      const element = this.sceneManager.getElementById(id);

      const command = new CommandChecklistChanged(element, checklistData);
      this.commandManager.execute(command);
    } else {
      // Checklist data for the canvas
      const command = new CommandChecklistChanged(this.sceneManager.canvasChecklist, checklistData);
      this.commandManager.execute(command);
    }
  }

  onSaveRequest(e) {
    const data = this.sceneManager.getSceneData(true);
    commonSendEventFunction(PR_EVENT_SAVE_RESPONSE, data);
  }

  onDoneSaving(e) {
    this.sceneManager.sendNumberOfElementsToHeap();
  }

  onCSVExportObjects(e) {
    this.sceneManager.exportObjectsToCSV(e.detail);
  }
  onCSVExportConnections(e) {
    this.sceneManager.exportConnectionsToCSV(e.detail);
  }

  onElementScrollToView(e) {
    var stepId = e.detail.id;

    let element = this.sceneManager.getStepById(stepId);
    let position = null;

    if (element) {
      position = new PIXI.Point().copyFrom(element.position);
    } else {
      // Its a connection
      element = this.sceneManager.getConnectionById(stepId);
      if (element) {
        // Bacause connections are drawin in an overlay
        // we need to determine the relative position to the viewport
        const gp = element.footer.getGlobalPosition();
        position = window.app.viewport.toLocal(gp);
      }
    }

    // move position to global ,
    // subtruct the width of the lefside menu
    const size = MainStorage.getLeftPanelSize();
    const width = size.width;
    const globalPosition = window.app.viewport.toGlobal(position);
    globalPosition.x -= -(window.innerWidth / 2 - width) + (window.innerWidth - width) / 2;
    position = window.app.viewport.toLocal(globalPosition);

    if (element) {
      const selectionManager = this.selectionManager;
      selectionManager.clearReset();
      selectionManager.hide();

      window.app.viewport.animate({
        removeOnInterrupt: false,
        position,
        time: 300,
        callbackOnComplete: () => {
          selectionManager.addToSelection(element);
          selectionManager.updateSelection();
          selectionManager.multi.clearSelectionFrame();
          window.app.needsRendering();
        },
      });
    }
  }

  /// Action methods

  alignObjects(type) {
    if (MainStorage.getCanvasPermissions().isReadonlyAccess) {
      return;
    }

    const objects = this.selectionManager.getSelectedSteps();
    if (objects.length) {
      const commandsBatch = new CommandBatchMove(this.selectionManager);
      const commands = this.alignUtility.alignObjects(objects, type);
      commandsBatch.addCommands(commands);
      this.commandManager.execute(commandsBatch);
      this.selectionManager.updateSelection(true, true);
      window.app.needsRendering();
    }
  }

  spaceObjects(type) {
    if (MainStorage.getCanvasPermissions().isReadonlyAccess) {
      return;
    }

    const objects = this.selectionManager.getSelectedSteps();
    if (objects.length) {
      const commandsBatch = new CommandBatchMove(this.selectionManager);
      const commands = this.alignUtility.spaceObjects(objects, type);
      commandsBatch.addCommands(commands);
      this.commandManager.execute(commandsBatch);
      this.selectionManager.updateSelection(true, true);
      window.app.needsRendering();
    }
  }
}
