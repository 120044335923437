import React, { useEffect, useRef } from 'react';
import styles from './FloatingWindow.module.scss';
import cls from 'classnames';
import { iconPopupClose } from 'react-project/assets/Icons';
import {
  VERTICAL_ALIGN_CENTER,
  ViewportConstrainer,
} from 'react-project/components/viewportConstrainer/ViewportConstrainer';

export const FloatingWindow = ({
  title,
  avoidRect,
  windowID,
  onWindowDrag,
  onWindowClose,
  children,
  contentStyle,
}) => {
  const popRef = useRef(null);
  const popHeaderRef = useRef(null);

  useEffect(() => {
    // Make the DIV element draggable:

    let pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;

    const popElement = popRef.current;
    const headerElement = popHeaderRef.current;

    headerElement.addEventListener('pointerdown', dragMouseDown, false);

    function dragMouseDown(e) {
      e = e || window.event;

      // CancelDragging
      if (e.target.classList.contains('CancelDragging')) {
        e.stopPropagation();
        return;
      }

      e.preventDefault();

      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.addEventListener('pointerup', closeDragElement);
      document.addEventListener('pointermove', elementDrag);

      onWindowDrag && onWindowDrag(windowID);
    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;

      const topX = popElement.offsetTop - pos2;
      const leftX = popElement.offsetLeft - pos1;

      popElement.style.top = topX + 'px';
      popElement.style.left = leftX + 'px';

      // get its position and recalculate the restictions
      const popRect = popElement.getBoundingClientRect();

      if (popRect.top < 55) {
        const diff = 55 - popRect.top;
        popElement.style.top = topX + diff + 'px';
      }

      if (popRect.left < 52) {
        const diff = 52 - popRect.left;
        popElement.style.left = leftX + diff + 'px';
      }

      // prevent right side overflow
      if (popRect.right > window.innerWidth) {
        const diff = popRect.right - window.innerWidth;
        popElement.style.left = leftX - diff + 'px';
      }

      // prevent bottom overflow
      if (popRect.bottom > window.innerHeight) {
        const diff = popRect.bottom - window.innerHeight;
        popElement.style.top = topX - diff + 'px';
      }
    }

    function closeDragElement() {
      // stop moving when mouse button is released:
      document.removeEventListener('pointerup', closeDragElement);
      document.removeEventListener('pointermove', elementDrag);
    }

    return () => {
      headerElement.removeEventListener('pointerdown', dragMouseDown);
    };
  }, []);

  return (
    <ViewportConstrainer verticalAlign={VERTICAL_ALIGN_CENTER} relativeRectangle={avoidRect}>
      <div ref={popRef} className={cls(styles.Window)}>
        <div ref={popHeaderRef} className={styles.Header}>
          <div className={styles.HeaderTop}>
            <div className={styles.Title}>{title}</div>
            <div
              className={styles.Close}
              onClick={() => {
                onWindowClose && onWindowClose(windowID);
              }}
            >
              {iconPopupClose}
            </div>
          </div>
        </div>
        <div className={cls(styles.Content)} style={contentStyle}>
          {children}
        </div>
      </div>
    </ViewportConstrainer>
  );
};
