import { createAction } from 'redux-actions';
import { ActionNames } from 'react-project/redux/actionNamesConstant';

export const setIsModalOpened = createAction(ActionNames.setIsModalOpened);
/**
 *  Step Modal related selects below
 */
export const setIsStepModalOpened = createAction(ActionNames.setIsStepModalOpened);
export const setIsStepModalPinned = createAction(ActionNames.setIsStepModalPinned);
export const setStepModalData = createAction(ActionNames.setStepModalData);
export const resetStepModalData = createAction(ActionNames.resetStepModalData);
