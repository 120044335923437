import MainStorage from 'pixi-project/core/MainStorage';
import { EElementTypes } from 'shared/CSharedCategories';
import Styles, {
  COLOR_LABEL_DEFAULT,
  COLOR_LABEL_DEFAULT_LIGHT,
  COLOR_WIDGET_TEXT,
} from '../Styles';
import BaseWidget from './BaseWidget';
import { commonSendEventFunction, roundTo } from 'shared/CSharedMethods';
import {
  PR_EVENT_EXPENSE_OPENED,
  PR_EVENT_FUNNEL_CHANGED,
  RP_SCROLL_ELEMENT_INTO_VIEW,
} from 'shared/CSharedEvents';
import TableViewScroll from './TableView/TableViewScroll';
import SharedElementHelpers from 'shared/SharedElementHelpers';
import { shortenText } from 'shared/SharedHelpers';
import { numSeparator } from 'shared/NumberHelpers';
import TableRowExpenses from './TableRowExpenses';
import generateId from 'pixi-project/utils/IDGenerator';

const iconFitTo = {
  width: 24,
  height: 24,
};

export default class ForecastingWidget extends BaseWidget {
  constructor(eventHandlers, id, delegate, data) {
    super(eventHandlers, id, delegate, data);

    this.type = EElementTypes.WIDGET_FORECASTING;
    this.styles = {
      icon: 'icon-forecasting',
      title: 'Forecast',
    };

    this.containerWidth = 1200;
    this.containerHeight = 0; // to be calculated

    this.maxColumns = 0;
    this.legendWidth = 200;

    this.tableWidth = 320;

    this.cards = [];

    this.manualExpenses = [];
    this.calculatedValues = null;
    this.sceneManager = null;
  }

  createTable() {
    this.tableView = new TableViewScroll();
    this.tableView.roundCornerRadius = 0; // no corner radius
    this.tableView.delegate = this;

    this.createExpensesContainer();
    this.expensesContainer.addChild(this.tableView);
  }

  createExpensesContainer() {
    // create a background on the right side of the widget
    const background = new PIXI.Graphics();
    background.beginFill(0xf0f2f7);
    background.lineStyle(0, 0xffffff, 0);
    background.drawRoundedRect(830, 60, 360, 390, 10);
    background.endFill();
    this.content.addChild(background);

    // create a white container in it
    const container = new PIXI.Graphics();
    container.beginFill(0xffffff);
    container.lineStyle(2, 0xd9d9d9);
    container.drawRoundedRect(0, 0, 340, 370, 10);
    container.endFill();
    container.position.set(840, 70);
    this.content.addChild(container);

    // draw header and footer lines on inside the container
    container.lineStyle(2, 0xd9d9d9, 1);
    container.moveTo(0, 50);
    container.lineTo(340, 50);
    container.moveTo(0, 320);
    container.lineTo(340, 320);

    //// HEADER

    // add title that says Expenses on top left
    const title = new PIXI.BitmapText('Expenses', Styles.TEXT_MEDIUM);
    title.fontSize = 26;
    title.tint = COLOR_LABEL_DEFAULT;
    title.position.set(10, 12);
    container.addChild(title);

    const btnWidth = 30;
    const btnHeight = 30;
    // add blue button with a white cross on the right side of the header
    const button = new PIXI.Graphics();
    button.beginFill(0x009bf3);
    button.lineStyle(2, 0x009bf3, 0);
    button.drawRoundedRect(0, 0, btnWidth, btnHeight, 5);
    button.endFill();
    button.position.set(300, 10);
    container.addChild(button);

    const crossPadding = 5;
    button.lineStyle(4, 0xffffff);
    button.moveTo(btnWidth / 2, crossPadding);
    button.lineTo(btnWidth / 2, btnHeight - crossPadding);
    button.moveTo(crossPadding, btnHeight / 2);
    button.lineTo(btnWidth - crossPadding, btnHeight / 2);

    button.interactive = true;
    button.buttonMode = true;
    button.on('pointerdown', (event) => {
      event.stopPropagation();
    });

    button.on('pointerup', (event) => {
      const getGlobalPosition = button.getGlobalPosition();
      commonSendEventFunction(PR_EVENT_EXPENSE_OPENED, {
        position: { x: getGlobalPosition.x, y: getGlobalPosition.y - 100 },
      });
    });

    // FOOTER

    // add Total to the footer
    const total = new PIXI.BitmapText('Total', Styles.TEXT_MEDIUM);
    total.fontSize = 26;
    total.tint = COLOR_LABEL_DEFAULT;
    total.position.set(10, 330);
    container.addChild(total);

    // add total sum on the right side of the footer
    const totalSum = new PIXI.BitmapText('-', Styles.TEXT_MEDIUM);
    totalSum.fontSize = 26;
    totalSum.anchor.x = 1;
    totalSum.tint = COLOR_LABEL_DEFAULT;
    totalSum.position.set(330, 330);
    container.addChild(totalSum);

    this.labelExpensesTotal = totalSum;

    this.expensesContainer = new PIXI.Container();
    this.expensesContainer.position.set(10, 60);
    container.addChild(this.expensesContainer);
  }

  createCards() {
    const padding = 20;

    const dataPeople = {
      title: 'People',
      icon: 'icon-people.png',
      data: {
        current: '-',
        perDay: '-',
        perWeek: '-',
        perMonth: '-',
      },
    };

    const dataForms = {
      title: 'Forms Completed',
      icon: 'StepsModal/Actions/lead.png',
      data: {
        current: '-',
        perDay: '-',
        perWeek: '-',
        perMonth: '-',
      },
    };

    const dataPurchases = {
      title: 'Purchases',
      icon: 'StepsModal/Actions/purchase.png',
      data: {
        current: '-',
        perDay: '-',
        perWeek: '-',
        perMonth: '-',
      },
    };

    const dataRevenue = {
      title: 'Revenue',
      icon: 'StepsModal/Actions/purchase.png',
      data: {
        current: '-',
        perDay: '-',
        perWeek: '-',
        perMonth: '-',
      },
    };

    const dataExpenses = {
      title: 'Expenses',
      icon: 'expenses-icon',
      data: {
        current: '-',
        perDay: '-',
        perWeek: '-',
        perMonth: '-',
      },
    };

    const dataProfit = {
      title: 'Profit',
      icon: 'StepsModal/Actions/purchase.png',
      data: {
        current: '-',
        perDay: '-',
        perWeek: '-',
        perMonth: '-',
      },
    };

    const headerHeight = 50;
    const cardWidth = 250;
    const cardHeight = 120;
    const smallCardHeight = 90;

    //create background with gray color that wraps the cards with padding of 10px
    const background = new PIXI.Graphics();
    background.beginFill(0xf0f2f7);
    background.lineStyle(0, 0xffffff, 0);
    background.drawRoundedRect(
      padding / 2,
      headerHeight + padding / 2,
      cardWidth * 3 + padding * 3,
      cardHeight * 2 + padding * 3 + smallCardHeight,
      10,
    );
    background.endFill();
    this.content.addChild(background);

    const card1 = this.createCard(dataPeople);
    card1.position.set(padding * 1, cardWidth * 0 + headerHeight + padding);
    this.content.addChild(card1);
    this.cards.push(card1);

    const card2 = this.createCard(dataForms);
    card2.position.set(padding * 2 + cardWidth * 1, headerHeight + padding);
    this.content.addChild(card2);
    this.cards.push(card2);

    const card3 = this.createCard(dataPurchases);
    card3.position.set(padding * 3 + cardWidth * 2, headerHeight + padding);
    this.content.addChild(card3);
    this.cards.push(card3);

    const card4 = this.createCard(dataRevenue);
    card4.position.set(padding * 1 + cardWidth * 0, headerHeight + cardHeight + padding * 2);
    this.content.addChild(card4);
    this.cards.push(card4);

    const card5 = this.createCard(dataExpenses);
    card5.position.set(padding * 2 + cardWidth * 1, headerHeight + cardHeight + padding * 2);
    this.content.addChild(card5);
    this.cards.push(card5);

    const card6 = this.createCard(dataProfit);
    card6.position.set(padding * 3 + cardWidth * 2, headerHeight + cardHeight + padding * 2);
    this.content.addChild(card6);
    this.cards.push(card6);

    // add small cards

    const returnData = {
      title: 'Return On Investment',
      icon: 'StepsModal/Actions/purchase.png',
      data: {
        current: '-',
      },
    };

    const card7 = this.createSmallCard(returnData, 790, smallCardHeight);
    card7.position.set(padding, cardHeight * 2 + headerHeight + padding * 3);
    this.cards.push(card7);
    this.content.addChild(card7);
  }

  createCard(data, width = 250, height = 120, padding = 10) {
    const card = new PIXI.Container();

    // add background to the card , with border color #D9D9D9 , and round corners , and white background
    const background = new PIXI.Graphics();
    background.beginFill(0xffffff);
    background.lineStyle(2, 0xd9d9d9);
    background.drawRoundedRect(0, 0, width, height, 10);
    background.endFill();
    card.addChild(background);

    // put title on the top left corner
    const title = new PIXI.BitmapText(data.title, Styles.TEXT_MEDIUM);
    title.fontSize = 20;
    title.tint = COLOR_LABEL_DEFAULT;
    title.position.set(padding, padding);
    card.addChild(title);

    const text = new PIXI.BitmapText(data.data.current, Styles.TEXT_STRONG);
    text.fontSize = 30;
    text.tint = COLOR_LABEL_DEFAULT;
    text.anchor.set(0.5, 0.5);
    text.position.set(width / 2, 60);
    card.addChild(text);

    // add icon on the top right

    if (data.icon) {
      const icon = new PIXI.Sprite(PIXI.utils.TextureCache[data.icon]);
      icon.fitTo(iconFitTo.width, iconFitTo.height);
      icon.position.set(width - padding - icon.width, padding);
      card.addChild(icon);
      card.icon = icon;
    }

    const footerY = height - padding - 20;

    this.createTitleSubTitle(data.data.perDay, 'per day', 'left', padding, footerY, card);
    this.createTitleSubTitle(data.data.perWeek, 'Per 7-days', 'center', width / 2, footerY, card);
    this.createTitleSubTitle(
      data.data.perMonth,
      'Per 30-days',
      'right',
      width - padding,
      footerY,
      card,
    );

    card.textContent = text;

    return card;
  }

  createSmallCard(data, width = 250, height = 60, padding = 10) {
    const card = new PIXI.Container();

    // add background to the card , with border color #D9D9D9 , and round corners , and white background
    const background = new PIXI.Graphics();
    background.beginFill(0xffffff);
    background.lineStyle(2, 0xd9d9d9);
    background.drawRoundedRect(0, 0, width, height, 10);
    background.endFill();
    card.addChild(background);

    // put title on the top left corner
    const title = new PIXI.BitmapText(data.title, Styles.TEXT_MEDIUM);
    title.fontSize = 20;
    title.tint = COLOR_LABEL_DEFAULT;
    title.position.set(padding, padding);
    card.addChild(title);

    const text = new PIXI.BitmapText(data.data.current, Styles.TEXT_STRONG);
    text.fontSize = 30;
    text.tint = COLOR_LABEL_DEFAULT;
    text.anchor.set(0.5, 0.5);
    text.position.set(width / 2, 60);
    card.addChild(text);

    card.textContent = text;

    // add icon on the top right
    if (data.icon) {
      const icon = new PIXI.Sprite(PIXI.utils.TextureCache[data.icon]);
      icon.fitTo(iconFitTo.width, iconFitTo.height);
      icon.position.set(width - padding - icon.width, padding);
      card.addChild(icon);
      card.icon = icon;
    }

    return card;
  }

  createTitleSubTitle = (title, subTitle, align, startX, startY, container) => {
    const titleText = new PIXI.BitmapText(title, Styles.TEXT_MEDIUM);
    titleText.tint = COLOR_LABEL_DEFAULT;
    titleText.anchor.set(0.5, 0.5);
    titleText.position.set(startX, startY);
    container.addChild(titleText);

    const subTitleText = new PIXI.BitmapText(subTitle, Styles.TEXT_DEFAULT);
    subTitleText.tint = COLOR_LABEL_DEFAULT_LIGHT;
    subTitleText.anchor.set(0.5, 0.5);
    subTitleText.position.set(startX, startY + 15);
    container.addChild(subTitleText);

    if (align === 'left') {
      titleText.anchor.set(0, 0.5);
      titleText.x = startX;
      subTitleText.anchor.set(0, 0.5);
      subTitleText.x = startX;
    } else if (align === 'right') {
      titleText.anchor.set(1, 0.5);
      titleText.x = startX;
      subTitleText.anchor.set(1, 0.5);
      subTitleText.x = startX;
    }

    // join the subTitle every letter except the first one
    const key = subTitle
      .replace('-', '')
      .split(' ')
      .map((word, index) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('');
    container['text' + key] = titleText;
  };

  // Widget Methods Overrides and implementations

  createHeader() {
    super.createHeader();
    this.headerIcon.scale.set(0.7);
  }

  createSubHeader() {}

  createContent() {
    this.message = new PIXI.BitmapText('', Styles.WIDGET_MESSAGE);
    this.message.tint = COLOR_WIDGET_TEXT;

    this.message.anchor.set(0.5, 0.5);
    this.content.addChild(this.message);
  }

  calculateContainerHeight() {
    this.containerHeight = 460;
  }

  positionHeader() {
    super.positionHeader();
  }

  positionSubHeader() {}

  positionTable() {
    super.positionTable();
  }

  positionPagination() {}

  updatePagination() {}

  onPaginationLeft(e) {
    if (this.hasPreviousPage()) {
      this.currentPage--;
      this.rebindData();
      this.updatePagination();
    }
  }

  onPaginationRight(e) {
    if (this.hasNextPage()) {
      this.currentPage++;
      this.rebindData();
      this.updatePagination();
    }
  }

  hasNextPage() {
    return this.perPage * this.currentPage + this.perPage < this.maxColumns;
  }

  hasPreviousPage() {
    return this.currentPage > 0;
  }

  getSubHeaderHeight() {
    return 250;
  }

  onWheel(event) {
    this.tableView.onWheel(event);
  }

  positionTable() {
    this.tableView.position.set(0, 0);
  }

  acceptsWheelEvents() {
    return true; // This table will not scroll
  }

  hideMessage() {
    super.hideMessage();
    this.showContent();
  }

  showMessage() {
    super.showMessage();
  }

  hideContent() {
    this.noDataMessage.visible = false;
  }

  showContent() {
    this.noDataMessage.visible = false;
    this.tableView.visible = true;
  }

  setWidgetData(
    widgetDataReceived,
    compareDataRecieved,
    ranges,
    rangesCompare,
    range,
    rangeCompare,
  ) {
    super.setWidgetData();
    this.showContent();
  }

  // Utility

  onPaste(sceneManager) {
    super.onPaste();
  }

  onStepsChanged() {
    // triggers when a step is being added or deleted
    commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
  }

  onDataError(error) {}

  onWindowPointerDown(e) {}

  // Base Container Overrides
  getState() {
    super.getState();
    this.stateData.data = {
      manualExpenses: this.manualExpenses,
    };
    return this.stateData;
  }

  onWidgetDropped() {
    this.dataMessage.removeFromParent();
    this.tableView.visible = true;
  }

  onWidgetLoaded() {
    this.dataMessage.removeFromParent();
  }

  onInitFinished() {}

  onCreationFinished() {
    this.createCards();
    this.headerLabel.fontSize = 20;

    this.loadingMessage.removeFromParent();
  }

  processAnalyticsData(sceneManager) {
    // only if forecasting is enabled
    const days = MainStorage.calendarData.days;
    this.sceneManager = sceneManager; // keep the reference

    if (!MainStorage.isForecastingVisible()) {
      // precalculate the forecasting values here
      sceneManager.forecastingController.calculateForecasting();
    }

    // sceneManager.forecastingController.loopDetector
    const forecastingController = sceneManager.forecastingController;
    const loopDetector = forecastingController.loopDetector;
    const rootNodes = loopDetector.findRootNodes();

    let totalNumberOfPeople = 0;

    // go through all rootNodes
    for (let i = 0; i < rootNodes.length; i++) {
      const rootNodeId = rootNodes[i];
      if (rootNodeId !== this.id) {
        const object = sceneManager.getElementById(rootNodeId);
        // if its not a widget and it has analyticsManager
        if (
          object.forecastingCalculatedData &&
          !SharedElementHelpers.IsWidget(object) &&
          object.forecastingCalculatedData.people
        ) {
          totalNumberOfPeople += object.forecastingCalculatedData.people;
        }
      }
    }

    this.setCardWithData(this.cards[0], totalNumberOfPeople / days, days);

    this.calculatedValues = this.calculateAllValues(sceneManager, days);
    this.setCardWithData(this.cards[1], this.calculatedValues.formsCompleted, days);
    this.setCardWithData(this.cards[2], this.calculatedValues.purchases, days);
    this.setCardWithData(this.cards[3], this.calculatedValues.revenue, days, '$', '', 2);
    this.setCardWithData(this.cards[4], this.calculatedValues.expenses, days, '$', '', 2);
    this.setCardWithData(
      this.cards[5],
      this.calculatedValues.profit,
      days,
      '$',
      '',
      2,
      this.calculatedValues.profit >= 0 ? 'StepsModal/Actions/purchase.png' : 'expenses-icon',
    );
    const roi = this.calculatedValues.roi;
    this.cards[6].textContent.text = roundTo(this.calculatedValues.roi, 2) + 'x';
    this.cards[6].icon.texture =
      PIXI.utils.TextureCache[
        this.calculatedValues.roi >= 1 ? 'StepsModal/Actions/purchase.png' : 'expenses-icon'
      ];
    this.cards[6].icon.fitTo(iconFitTo.width, iconFitTo.height);

    this.labelExpensesTotal.text =
      '$' + numSeparator(roundTo(this.calculatedValues.expenses * days, 2));
    this.bindTableData();

    if (!MainStorage.isForecastingVisible()) {
      // erase forecasting data
      sceneManager.forecastingController.clearForecastingCalculations();
    }
  }

  bindTableData() {
    const days = MainStorage.calendarData.days;
    const tableData = [];

    for (let i = 0; i < this.calculatedValues.expenseObjects.length; i++) {
      const expenseObject = this.calculatedValues.expenseObjects[i];
      const name = shortenText(expenseObject.object.titleText, 25);
      const expense = '$' + roundTo(expenseObject.costPerDay * days, 2);
      const rowData = {
        name,
        expense,
        id: expenseObject.object.id,
      };

      tableData.push(rowData);
    }

    // add manual expenses
    for (let i = 0; i < this.manualExpenses.length; i++) {
      const name = shortenText(this.manualExpenses[i].name, 25);
      const expense =
        '$' +
        roundTo(this.manualExpenses[i].amount, 2) +
        ' ' +
        this.getPeriodLabel(this.manualExpenses[i].period);
      const rowData = {
        name,
        expense,
        amount: this.manualExpenses[i].amount,
        isManual: true,
        id: this.manualExpenses[i].id,
        period: this.manualExpenses[i].period,
      };

      tableData.push(rowData);
    }

    this.setTableData(tableData);
  }

  getPeriodLabel(period) {
    if (!period) {
      return 'per day';
    } else if (period === 1) {
      return 'per week';
    } else if (period === 2) {
      return 'per 30 days';
    } else if (period === 3) {
      return 'fixed';
    }
  }

  setTableData(tableData) {
    const numberOfRows = 8; // display 8 rows
    const rowHeight = 31;
    const tableHeight = rowHeight * numberOfRows;

    this.tableView.resetToDefaults();

    this.tableView.bottomSpace = 0;
    this.tableView.rowClass = TableRowExpenses;
    this.tableView.pageSize = numberOfRows;
    this.tableView.columnSpacing = 10; // WIDGET_PADDING;
    this.tableView.tableSize.width = this.tableWidth;
    this.tableView.tableSize.height = tableHeight;
    this.tableView.rowSize.width = this.tableWidth; // this.containerWidth;
    this.tableView.rowSize.height = rowHeight;
    this.tableView.headerHeight = 0; //this.tableHeaderHeight;
    this.tableView.interactive = true;

    this.tableView.setHeaderData(null); // no header
    this.tableView.setRowsData(tableData);

    this.tableView.renderTable();
  }

  calculateAllValues(sceneManager, days) {
    const values = {
      formsCompleted: 0,
      purchases: 0,
      revenue: 0,
      expenses: 0,
      profit: 0,
      roi: 0,
      expenseObjects: [],
    };

    const noData =
      !MainStorage.canvasPermissions.isAnalyticsAllowed || !MainStorage.getHasAnalyticsData();

    // iterate through all objects
    sceneManager.objects.forEach((object) => {
      // forecastingCalculatedData
      if (object.analyticsManager && object.forecastingCalculatedData) {
        const forecastingCalculatedData = object.forecastingCalculatedData;

        if (forecastingCalculatedData) {
          // calculate forms completed
          if (SharedElementHelpers.IsAction(object)) {
            if (object.actionType === 'commerce') {
              values.purchases += forecastingCalculatedData.people / days;
              if (forecastingCalculatedData.revenue !== undefined) {
                values.revenue += forecastingCalculatedData.revenue / days;
              }
            }

            if (
              object.actionName === 'Form Completed' ||
              object.texturePath === 'StepsModal/Actions/lead.png'
            ) {
              values.formsCompleted += forecastingCalculatedData.people / days;
            }
          } else if (SharedElementHelpers.IsSource(object)) {
            if (forecastingCalculatedData && forecastingCalculatedData.cost !== undefined) {
              // extract per day value

              const costPerDay = forecastingCalculatedData.cost / days;
              values.expenses += costPerDay;

              values.expenseObjects.push({
                object,
                costPerDay,
              });
            }
          }
        }

        // divide all values by day
      }
    });

    // go through all manual expenses
    this.manualExpenses.forEach((expense) => {
      // calculate expenses based on selected period
      const amount = parseFloat(expense.amount);
      if (!expense.period) {
        // per day
        values.expenses += amount / 1;
      } else if (expense.period === 1) {
        // per Week
        values.expenses += amount / 7;
      } else if (expense.period === 2) {
        // per Month ( 30 day)
        values.expenses += amount / 30;
      } else if (expense.period === 3) {
        // fixed
        values.expenses += amount / days;
      }
    });

    // calculate profit
    values.profit = values.revenue - values.expenses;

    if (values.profit && !values.expenses) {
      values.roi = 1;
    } else if (!values.expenses) {
      values.roi = 0;
    } else {
      values.roi = 1 + values.profit / values.expenses;
    }

    return values;
  }

  setCardWithData(
    card,
    valuePerDay,
    days,
    prepend = '',
    append = '',
    precision = 0,
    iconName = null,
  ) {
    const currentValue = precision
      ? roundTo(valuePerDay * days, precision)
      : Math.ceil(valuePerDay * days);
    const currentValuePerWeek = precision
      ? roundTo(valuePerDay * 7, precision)
      : Math.round(valuePerDay * 7);
    const currentValuePerMonth = precision
      ? roundTo(valuePerDay * 30, precision)
      : Math.round(valuePerDay * 30);
    const perDayCalculated = precision ? roundTo(valuePerDay, precision) : Math.ceil(valuePerDay);

    card.textContent.text = `${prepend}${numSeparator(currentValue)}${append}`;
    card.textPerDay.text = `${prepend}${numSeparator(perDayCalculated)}${append}`;
    card.textPer7days.text = `${prepend}${numSeparator(currentValuePerWeek)}${append}`;
    card.textPer30days.text = `${prepend}${numSeparator(currentValuePerMonth)}${append}`;

    if (iconName) {
      card.icon.texture = PIXI.utils.TextureCache[iconName];
      card.icon.fitTo(24, 24);
    }
  }

  onExpenseAdded(data) {
    let isNew = false;
    if (data.id) {
      // edit and existing expense
      const index = this.manualExpenses.findIndex((expense) => expense.id === data.id);
      if (index !== -1) {
        this.manualExpenses[index] = data;
      }
    } else {
      // add a new expense
      data.id = generateId();
      this.manualExpenses.push(data);
      isNew = true;
    }

    if (this.sceneManager) {
      this.processAnalyticsData(this.sceneManager);
      if (isNew) {
        this.tableView.scrollToBottom();
      }
    }
  }

  onExpenseRemoved(data) {
    const index = this.manualExpenses.findIndex((expense) => expense.id === data.id);
    if (index !== -1) {
      this.manualExpenses.splice(index, 1);
    }

    if (this.sceneManager) {
      this.processAnalyticsData(this.sceneManager);
    }
  }

  onEditClicked(data) {
    commonSendEventFunction(PR_EVENT_EXPENSE_OPENED, data);
  }

  onLocateClicked(data) {
    // move canvas to object
    commonSendEventFunction(RP_SCROLL_ELEMENT_INTO_VIEW, { id: data.id });
  }

  onLoaded(objectData) {
    // loaded when canvas  is created
    if (objectData.data) {
      this.manualExpenses = objectData.data.manualExpenses || [];
    }
  }
}
