import React, { forwardRef, useEffect, useState } from 'react';
import { icon3Dots, iconImg } from 'react-project/assets/Icons';
import {
  VERTICAL_ALIGN_CENTER,
  ViewportConstrainer,
} from 'react-project/components/viewportConstrainer/ViewportConstrainer';
import { MenuColorButton } from '../Components/MenuItems/MenuColorButton';
import { MenuImageButton } from '../Components/MenuItems/MenuImageButton';
import {
  MENU_BTN_SHAPE_FILL,
  MENU_BTN_SHAPE_STROKE,
  MENU_BTN_STEP_MORE,
} from '../MenusConfiguration';
import styles from './ShapeMenu.module.scss';
import { getColorClass } from 'react-project/Util/ColorPicker';
import { When } from 'react-project/Util/When';
import { ColorPicker } from 'react-project/components/colorPicker/ColorPicker';
import SharedElementHelpers from 'shared/SharedElementHelpers';
import { PR_EVENT_SHAPE_STYLE_CHANGED } from 'shared/CSharedEvents';

const KEY_BORDER_COLOR = 'borderColor';
const KEY_FILL_COLOR = 'fillColor';

export const ShapeMenu = forwardRef(
  ({ currentStep, sectionStyle, onButtonClicked, shapeStyle }, ref) => {
    const [activeItem, setActiveItem] = React.useState(null);
    const [shapeStyleBorderColor, setShapeStyleBorderColor] = useState(0x000000);
    const [shapeStyleFillColor, setShapeStyleFillColor] = useState(0xffffff);

    const onShapeStyleChanged = (e) => {
      const { detail = {} } = e;

      Object.keys(detail).forEach((key) => {
        if (key === KEY_BORDER_COLOR) {
          setShapeStyleBorderColor(detail[key]);
        } else if (key === KEY_FILL_COLOR) {
          setShapeStyleFillColor(detail[key]);
        }
      });
    };

    useEffect(() => {
      if (SharedElementHelpers.IsShape(currentStep.object)) {
        const _shapeStyle = currentStep.object.shapeStyle;

        setShapeStyleBorderColor(_shapeStyle[KEY_BORDER_COLOR]);
        setShapeStyleFillColor(_shapeStyle[KEY_FILL_COLOR]);
      }

      document.addEventListener(PR_EVENT_SHAPE_STYLE_CHANGED, onShapeStyleChanged, false);

      return () => {
        document.removeEventListener(PR_EVENT_SHAPE_STYLE_CHANGED, onShapeStyleChanged);
      };
    }, [currentStep]);

    const onClick = (e, reference, id) => {
      setActiveItem(id);
      onButtonClicked(e, reference, id);
    };

    const strokeClassName = shapeStyle
      ? styles[`Border${getColorClass(shapeStyleBorderColor)}`]
      : null;
    const fillClassName = shapeStyle ? styles[`Fill${getColorClass(shapeStyleFillColor)}`] : null;

    return (
      <>
        <ViewportConstrainer verticalAlign={VERTICAL_ALIGN_CENTER} relativeRectangle={sectionStyle}>
          <div ref={ref} className={styles.Menu}>
            <div className={styles.MenuWrapper}>
              <MenuColorButton
                id={MENU_BTN_SHAPE_STROKE}
                colorClass={strokeClassName}
                isActive={activeItem === MENU_BTN_SHAPE_STROKE}
                onClick={onClick}
              />
              <MenuColorButton
                id={MENU_BTN_SHAPE_FILL}
                colorClass={fillClassName}
                isActive={activeItem === MENU_BTN_SHAPE_FILL}
                onClick={onClick}
              />
              <MenuImageButton
                id={MENU_BTN_STEP_MORE}
                image={icon3Dots}
                title={null}
                isActive={activeItem === MENU_BTN_STEP_MORE}
                onClick={onClick}
              />
            </div>
          </div>
        </ViewportConstrainer>
        <When condition={activeItem === MENU_BTN_SHAPE_STROKE}>
          <ViewportConstrainer relativeElement={ref.current}>
            <ColorPicker
              color={shapeStyle ? shapeStyleBorderColor : null}
              type={KEY_BORDER_COLOR}
            />
          </ViewportConstrainer>
        </When>
        <When condition={activeItem === MENU_BTN_SHAPE_FILL}>
          <ViewportConstrainer relativeElement={ref.current}>
            <ColorPicker color={shapeStyle ? shapeStyleFillColor : null} type={KEY_FILL_COLOR} />
          </ViewportConstrainer>
        </When>
      </>
    );
  },
);
