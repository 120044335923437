import MainStorage from 'pixi-project/core/MainStorage';
import { EElementTypes } from 'shared/CSharedCategories';
import Styles, { COLOR_WIDGET_SUB_TITLE, COLOR_WIDGET_TEXT } from '../Styles';
import BaseWidget, { HEADER_SPACING, WIDGET_PADDING } from './BaseWidget';
import TableViewScroll from './TableView/TableViewScroll';
import TableRowTrends from './TableRowTrends';
import TrendsChart from './TrendsChart';
import TableRowTrendsCompare from './TableRowTrendsCompare';
import TrendsLegend from './TrendsLegend';
import SharedElementHelpers from 'shared/SharedElementHelpers';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import {
  PR_ALL_STEPS,
  PR_EVENT_FUNNEL_CHANGED,
  PR_EVENT_TRENDS_NEED_UPDATE,
  RP_EVENT_ERROR_MESSAGE,
  RP_STEP_PICKER_ACTIVATED,
} from 'shared/CSharedEvents';
import { ERROR_MESSAGES } from 'shared/CSharedConstants';
import DropDown from 'pixi-project/base/ui/Dropdown';
import Utils from 'pixi-project/utils/Utils';
import CSVExporter from 'pixi-project/utils/CSVExporter';
import ImageSnapshot from 'pixi-project/utils/ImageSnapshot';
import CommandAddStepToTrends from 'pixi-project/base/command-system/commands/CommandAddStepToTrends';
import AppSignals from 'pixi-project/signals/AppSignals';
import CommandRemoveStepFromTrends from 'pixi-project/base/command-system/commands/CommandRemoveStepFromTrends';

const TREND_LINE_COLORS = [0x006ba6, 0x0496ff, 0xffbc42, 0xd81159, 0x8f2d56, 0xbaa5ff];

export const DIVISION_MODE_DAILY = 'daily';
export const DIVISION_MODE_3DAY = '3day';
export const DIVISION_MODE_WEEKLY = 'weekly';
export const DIVISION_MODE_MONTHLY = 'monthly';

const modes = [
  { label: 'Daily', value: DIVISION_MODE_DAILY, color: 0x636e84 },
  { label: '3 Day', value: DIVISION_MODE_3DAY, color: 0x636e84 },
  { label: 'Weekly', value: DIVISION_MODE_WEEKLY, color: 0x636e84 },
  { label: 'Monthly', value: DIVISION_MODE_MONTHLY, color: 0x636e84 },
];

export const ALL_TRACKED_DATA_COLOR = 0x999999;

const PAGINATION_PER_PAGE = 7;
const MAX_ROWS = 5;

export default class TrendsWidget extends BaseWidget {
  constructor(eventHandlers, id, delegate, data) {
    super(eventHandlers, id, delegate, data);

    this.type = EElementTypes.WIDGET_TRENDS;
    this.styles = {
      icon: 'icon-trends',
      title: 'Trends',
    };

    this.hasNewSteps = false; // used to determine if the widget needs to be refreshed after the picker is closed

    this.steps = data.configuration
      ? data.configuration.steps
      : [{ ID: 0, label: 'All People Tracked' }]; // array of objects containing ID and label
    this.isAllTrackedVisible = data.configuration ? data.configuration.isAllTrackedVisible : false;
    this.viewDataMode =
      data.configuration && data.configuration.viewDataMode
        ? data.configuration.viewDataMode
        : DIVISION_MODE_WEEKLY;

    this.currentPage = 0; // current page of the table
    this.perPage = PAGINATION_PER_PAGE; // number of columns per page
    this.numberOfRows = MAX_ROWS; // number of rows in the table , all sessions + steps

    this.containerWidth = 1200;
    this.containerHeight = 400;

    this.maxColumns = 0;
    this.legendWidth = 200;
  }

  // Widget Methods Overrides and implementations

  createHeader() {
    super.createHeader();
    this.headerIcon.scale.set(0.7);

    this.addBtnContainer = new PIXI.Container();
    this.addBtnContainer.visible = false;
    this.addChild(this.addBtnContainer);

    const addBtn = new PIXI.Sprite.from(PIXI.utils.TextureCache['add.png']);
    addBtn.anchor.y = 0.5;
    addBtn.scale.set(0.25);
    this.addBtnContainer.addChild(addBtn);

    const label = new PIXI.BitmapText('Add Step', Styles.WIDGET_LABEL);
    label.tint = COLOR_WIDGET_TEXT;
    label.anchor.set(0, 0.5);
    label.x = addBtn.width + 10;
    this.addBtnContainer.addChild(label);

    this.addBtnContainer.interactive = true;
    this.addBtnContainer.buttonMode = true;
    this.addBtnContainer.hitArea = new PIXI.Rectangle(
      -10,
      -10,
      addBtn.width + label.width + 20,
      addBtn.height + 10,
    );
    this.addBtnContainer.on('pointerdown', (e) => {
      e.stopPropagation();
    });
    this.addBtnContainer.on('pointerover', (e) => {
      e.stopPropagation();
      addBtn.alpha = 0.9;
      window.app.needsRendering();
    });
    this.addBtnContainer.on('pointerout', (e) => {
      e.stopPropagation();
      addBtn.alpha = 1;
      window.app.needsRendering();
    });
    this.addBtnContainer.on('pointerup', (e) => {
      e.stopPropagation();
      this.onAddBtnClicked(e);
    });

    // Create Mode selector
    this.modeSelectorContainer = new PIXI.Container();
    this.modeSelectorContainer.x = this.containerWidth - this.legendWidth - 200;
    // this.modeSelector.visible = false;
    this.addChild(this.modeSelectorContainer);

    const modeLabel = new PIXI.BitmapText('View data:', Styles.WIDGET_LABEL);
    modeLabel.tint = COLOR_WIDGET_TEXT;
    modeLabel.anchor.set(0, 0.5);
    this.modeSelectorContainer.addChild(modeLabel);

    const modeDropdown = new DropDown(modes, this.viewDataMode, this.onModeChanged.bind(this), {
      background: 0xffffff,
      label: { color: 0x636e84, fontSize: 12, fontFamily: 'Roboto' },
      border: { color: 0xc6cbd5, width: 1 },
      padding: 5,
    });
    modeDropdown.x = modeLabel.width + 10;
    modeDropdown.y = -modeDropdown.height / 2;
    this.modeSelectorContainer.addChild(modeDropdown);
    this.modeDropdown = modeDropdown;

    // Add download buttons

    // download table as CSV
    this.downloadCSVBtn = PIXI.Sprite.from(PIXI.utils.TextureCache['download-csv']);
    this.downloadCSVBtn.anchor.set(0.5, 0.5);
    this.downloadCSVBtn.scale.set(0.5);
    this.downloadCSVBtn.interactive = true;
    this.downloadCSVBtn.buttonMode = true;
    this.downloadCSVBtn.on('pointerdown', (e) => {
      e.stopPropagation();
    });
    this.downloadCSVBtn.on('pointerover', (e) => {
      e.stopPropagation();
      this.downloadCSVBtn.alpha = 0.9;
      window.app.needsRendering();
    });
    this.downloadCSVBtn.on('pointerout', (e) => {
      e.stopPropagation();
      this.downloadCSVBtn.alpha = 1;
      window.app.needsRendering();
    });
    this.downloadCSVBtn.on('pointerup', (e) => {
      e.stopPropagation();
      this.onDownloadCSVClicked(this);
    });
    this.content.addChild(this.downloadCSVBtn);

    // download chart as PNG
    this.downloadChartPNG = PIXI.Sprite.from(PIXI.utils.TextureCache['download-png']);
    this.downloadChartPNG.anchor.set(0.5, 0.5);
    this.downloadChartPNG.scale.set(0.5);
    this.downloadChartPNG.interactive = true;
    this.downloadChartPNG.buttonMode = true;
    this.downloadChartPNG.on('pointerdown', (e) => {
      e.stopPropagation();
    });
    this.downloadChartPNG.on('pointerover', (e) => {
      e.stopPropagation();
      this.downloadChartPNG.alpha = 0.9;
      window.app.needsRendering();
    });
    this.downloadChartPNG.on('pointerout', (e) => {
      e.stopPropagation();
      this.downloadChartPNG.alpha = 1;
      window.app.needsRendering();
    });
    this.downloadChartPNG.on('pointerup', (e) => {
      e.stopPropagation();
      this.onDownloadChartPNG(this);
    });
    this.content.addChild(this.downloadChartPNG);

    // add reload btn

    this.reloadBtn = PIXI.Sprite.from(PIXI.utils.TextureCache['loader.png']);
    this.reloadBtn.anchor.set(0.5, 0.5);
    this.reloadBtn.scale.set(0.65);
    this.reloadBtn.tint = 0x636e84;
    this.reloadBtn.interactive = true;
    this.reloadBtn.buttonMode = true;
    this.reloadBtn.on('pointerdown', (e) => {
      e.stopPropagation();
    });
    this.reloadBtn.on('pointerover', (e) => {
      e.stopPropagation();
      this.reloadBtn.alpha = 0.9;
      window.app.needsRendering();
    });

    this.reloadBtn.on('pointerout', (e) => {
      e.stopPropagation();
      this.reloadBtn.alpha = 1;
      window.app.needsRendering();
    });

    this.reloadBtn.on('pointerup', (e) => {
      e.stopPropagation();
      this.onReloadBtnClicked(this);
    });

    this.content.addChild(this.reloadBtn);
  }

  createSubHeader() {
    this.chart = new TrendsChart({
      height: this.getSubHeaderHeight(),
      width: this.containerWidth - this.legendWidth,
    });
    this.chart.setMode(this.viewDataMode);
    this.content.addChild(this.chart);

    this.legend = new TrendsLegend({
      height: this.getSubHeaderHeight(),
      width: this.legendWidth,
    });
    this.content.addChild(this.legend);
  }

  createTable() {
    this.tableView = new TableViewScroll();
    this.tableView.delegate = this;
    this.content.addChild(this.tableView);
  }

  createContent() {
    this.message = new PIXI.BitmapText('', Styles.WIDGET_MESSAGE);
    this.message.tint = COLOR_WIDGET_TEXT;

    this.message.anchor.set(0.5, 0.5);
    this.content.addChild(this.message);
  }

  positionHeader() {
    super.positionHeader();
    const y = this.getHeaderY();
    this.addBtnContainer.position.set(this.containerWidth - this.addBtnContainer.width - 20, y);
    this.modeSelectorContainer.position.set(
      this.addBtnContainer.x - this.modeSelectorContainer.width - 20,
      y,
    );

    const spacing = 20;

    this.downloadCSVBtn.x = this.modeSelectorContainer.x - this.downloadCSVBtn.width / 2 - spacing;
    this.downloadCSVBtn.y = y;

    this.downloadChartPNG.x = this.downloadCSVBtn.x - this.downloadChartPNG.width / 2 - spacing;
    this.downloadChartPNG.y = y;

    this.reloadBtn.x = this.downloadChartPNG.x - this.reloadBtn.width / 2 - spacing;
    this.reloadBtn.y = y;
  }

  positionSubHeader() {
    this.chart.position.set(0, this.getHeaderHeight());
    this.legend.position.set(this.containerWidth - this.legendWidth, this.getHeaderHeight());
  }

  positionTable() {
    super.positionTable();
    this.message.position.set(this.containerWidth / 2, this.tableView.position.y + 125);
  }

  positionPagination() {
    const y = this.getHeaderY() + this.getSubHeaderHeight();

    const rightX = this.containerWidth - WIDGET_PADDING - this.paginationBtnRight.width / 2;

    this.paginationBtnRight.x = rightX;

    this.paginationBtnRight.y = y - this.paginationBtnRight.height / 2;

    this.paginationBtnLeft.x =
      this.paginationBtnRight.x -
      this.paginationBtnLeft.width / 2 -
      this.paginationBtnRight.width / 2 -
      HEADER_SPACING;
    this.paginationBtnLeft.y = y - this.paginationBtnLeft.height / 2;

    this.paginationLabel.x =
      this.paginationBtnLeft.x - this.paginationBtnLeft.width / 2 - HEADER_SPACING * 2;
    this.paginationLabel.y = y;
  }

  updatePagination() {
    const len = this.maxColumns;
    const startItem = this.currentPage * this.perPage + 1;
    const endItem = Utils.clamp(startItem + this.perPage - 1, 0, len);

    this.paginationLabel.text = `${startItem}-${endItem} of ${len} items`;

    this.paginationBtnLeft.alpha = this.hasPreviousPage() ? 1 : 0.5;
    this.paginationBtnRight.alpha = this.hasNextPage() ? 1 : 0.5;

    window.app.needsRendering();
  }

  onPaginationLeft(e) {
    if (this.hasPreviousPage()) {
      this.currentPage--;
      this.rebindData();
      this.updatePagination();
    }
  }

  onPaginationRight(e) {
    if (this.hasNextPage()) {
      this.currentPage++;
      this.rebindData();
      this.updatePagination();
    }
  }

  hasNextPage() {
    return this.perPage * this.currentPage + this.perPage < this.maxColumns;
  }

  hasPreviousPage() {
    return this.currentPage > 0;
  }

  getSubHeaderHeight() {
    return 250;
  }

  onWheel(event) {
    // this.tableView.onWheel(event);
  }

  acceptsWheelEvents() {
    return false; // This table will not scroll
  }

  hideMessage() {
    super.hideMessage();
    this.headerLabel.text = 'Trends';
    this.showContent();
  }

  showMessage() {
    super.showMessage();
    this.headerLabel.text = 'Trends';
  }

  hideContent() {
    this.tableView.visible = false;
    this.chart.visible = false;
    this.legend.visible = false;
    this.noDataMessage.visible = false;
    this.message.visible = false;
    this.paginationBtnLeft.visible = false;
    this.paginationBtnRight.visible = false;
    this.paginationLabel.visible = false;
  }

  showContent() {
    this.tableView.visible = this.tableView.rowsData.length ? true : false;
    this.noDataMessage.visible = this.tableView.rowsData.length ? false : true;
    this.chart.visible = this.tableView.rowsData.length ? true : false;
    this.legend.visible = this.tableView.rowsData.length ? true : false;

    this.paginationBtnLeft.visible = this.tableView.rowsData.length ? true : false;
    this.paginationBtnRight.visible = this.tableView.rowsData.length ? true : false;
    this.paginationLabel.visible = this.tableView.rowsData.length ? true : false;
  }

  preTransformData(data, ranges) {
    if (!data) {
      return null;
    }

    const dates = [];
    const objects = {};
    const objectsArray = [];

    let colorIndex = 0;

    for (let rangeKey in data) {
      // transform the keys into date labels
      const rangeData = ranges.find((r) => {
        return r.key == rangeKey;
      });
      dates.push(rangeData.label);

      const range = data[rangeKey];

      for (let objectId in range) {
        const objectData = range[objectId];

        if (!objects[objectId]) {
          objects[objectId] = {
            hits: [],
            leadTime: [],
            averageHits: 0,
            label: this.resolveName(objectId),
            color: TREND_LINE_COLORS[colorIndex++],
          };
        }

        const object = objects[objectId];

        if (objectId === 'allSessions') {
          object.hits.push(objectData);
        } else {
          object.hits.push(objectData.hits);
          object.leadTime.push(objectData.lead_time_in_seconds);
        }
      }
    }

    // calculate average hits for objects

    for (let objectId in objects) {
      const object = objects[objectId];
      let sum = 0;
      for (let i = 0; i < object.hits.length; i++) {
        const hit = object.hits[i];
        sum += hit;
      }
      object.averageHits = sum / object.hits.length;
    }

    for (let objectId in objects) {
      const object = objects[objectId];
      object.id = objectId;
      object.dates = dates.slice();
      objectsArray.push(object);
    }

    // create ordered list of objects
    const orderedObjectsArray = [];
    orderedObjectsArray.push(objectsArray[objectsArray.length - 1]);

    for (let i = 0; i < this.steps.length; i++) {
      const step = this.steps[i];
      for (let j = 0; j < objectsArray.length; j++) {
        const object = objectsArray[j];
        if (object.id === step.ID) {
          orderedObjectsArray.push(object);
          break;
        }
      }
    }

    return { objects, objectsArray: orderedObjectsArray, dates };
  }

  setIsTrackingVisibleToData(widgetData) {
    if (!widgetData) {
      return;
    }

    for (let i = 0; i < widgetData.objectsArray.length; i++) {
      const object = widgetData.objectsArray[i];
      if (object.id === 'allSessions') {
        object.isAllTrackedVisible = this.isAllTrackedVisible;
        break;
      }
    }

    if (widgetData.objects.allSessions) {
      widgetData.objects.allSessions.isAllTrackedVisible = this.isAllTrackedVisible;
    }
  }

  setWidgetData(
    widgetDataReceived,
    compareDataRecieved,
    ranges,
    rangesCompare,
    range,
    rangeCompare,
  ) {
    this.stopSpinning();
    if (!widgetDataReceived) {
      this.message.visible = false;
      return; // no data message will be displayed instead
    }

    const trasformedWidgetData = this.preTransformData(widgetDataReceived, ranges);
    const trasformedCompareData = this.preTransformData(compareDataRecieved, rangesCompare);

    this.addBtnContainer.visible = true; // show add btn

    // Use data

    super.setWidgetData(trasformedWidgetData);
    this.compareData = trasformedCompareData;

    this.range = range;
    this.rangeCompare = rangeCompare;

    this.setIsTrackingVisibleToData(this.widgetData);
    this.setIsTrackingVisibleToData(this.compareData);

    this.maxColumns = trasformedWidgetData.dates.length;
    this.numberOfRows = trasformedWidgetData.objectsArray.length;

    this.numberOfColumns = this.perPage < this.maxColumns ? this.perPage : this.maxColumns;

    this.setTableData(this.widgetData, this.compareData);

    // Chart
    this.chart.cleanUp();
    this.chart.setChartData(this.widgetData, this.compareData, this.isAllTrackedVisible);
    this.chart.drawChart();

    this.legend.cleanUp();
    this.legend.setData(this.widgetData, this.compareData, this.isAllTrackedVisible);
    this.legend.drawLegend();

    if (MainStorage.isNumbersVisible()) {
      this.hideMessage();
    } else {
      this.showMessage();
    }

    this.updatePagination();

    this.onChange();
  }

  // Methods

  addStep(stepData) {
    if (this.isStepDuplicate(stepData)) {
      commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
        errorMSG: 'Step is already in this widget',
        autoClose: true,
      });
      return false;
    }

    if (this.steps.length >= 5) {
      // max allowed steps
      commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
        errorMSG: 'There is a limit of 5 steps per widget',
        autoClose: true,
      });
      return false;
    }

    this.steps.push({
      ID: stepData.ID,
      label: stepData.label,
    });

    // insert blank data for the object
    this.insertBlankData(stepData, this.widgetData);
    if (this.compareData) {
      this.insertBlankData(stepData, this.compareData);
    }

    this.numberOfRows++;

    this.rebindData();
    this.onChange();

    return true;
  }

  removeStep(stepData) {
    // remove the step from the steps array
    const index = this.steps.findIndex((step) => step.ID === stepData.id);
    if (index > -1) {
      this.steps.splice(index, 1);
      this.numberOfRows--;
    } else {
      return; // not found
    }

    // remove the step from widget data
    this.removeFromWidgetData(stepData, this.widgetData);
    this.removeFromWidgetData(stepData, this.compareData);

    this.rebindData();

    this.onChange();
  }

  // Data Methods

  rebindData() {
    this.setIsTrackingVisibleToData(this.widgetData);
    this.setIsTrackingVisibleToData(this.compareData);

    this.tableView.visible = true;
    this.setTableData(this.widgetData, this.compareData);

    // Chart
    this.chart.cleanUp();
    this.chart.setChartData(this.widgetData, this.compareData, this.isAllTrackedVisible);
    this.chart.drawChart();

    this.legend.cleanUp();
    this.legend.setData(this.widgetData, this.compareData, this.isAllTrackedVisible);
    this.legend.drawLegend();

    window.app.needsRendering();
  }

  createTableData(widgetData, compareData) {
    const tableData = [];
    for (let i = 0; i < widgetData.objectsArray.length; i++) {
      const object = widgetData.objectsArray[i];
      tableData.push({
        ...object,
        hits: object.hits
          ? object.hits.slice(
              this.currentPage * this.perPage,
              this.currentPage * this.perPage + this.perPage,
            )
          : [],
      });
    }

    if (!compareData) {
      return tableData;
    } else {
      // paginate compare hits
      const compareTemp = [];
      for (let i = 0; i < compareData.objectsArray.length; i++) {
        const object = compareData.objectsArray[i];
        compareTemp.push({
          ...object,
          hits: object.hits
            ? object.hits.slice(
                this.currentPage * this.perPage,
                this.currentPage * this.perPage + this.perPage,
              )
            : [],
        });
      }

      for (let i = 0; i < tableData.length; i++) {
        const data = tableData[i];
        const compare = compareTemp[i];
        data.compare = compare.hits;
        data.compareAverageHits = compare.averageHits;
      }

      return tableData;
    }
  }

  extractHeaderData(widgetData, compareData) {
    const maxColumns = this.numberOfColumns;
    const columnWidth = 1 / (maxColumns + 1);

    const headerData = [
      {
        title: 'Step Name',
        width: 160,
        align: 'left',
      },
    ];

    headerData.push({
      title: 'Average',
      width: columnWidth,
      align: 'left',
    });

    if (compareData) {
      const cols = this.extractDataTitle(widgetData, columnWidth);
      const colsCompare = this.extractDataTitle(compareData, columnWidth);

      const maxL = Math.max(cols.length, colsCompare.length);

      for (let i = 0; i < maxL; i++) {
        headerData.push({
          title: cols[i] ? cols[i].title : null,
          subTitle: colsCompare[i] ? colsCompare[i].title : null,
          subTitleStyle: Styles.WIDGET_SUB_TITLE_LABEL,
          subTitleColor: COLOR_WIDGET_SUB_TITLE,
          width: columnWidth,
          align: 'left',
        });
      }
    } else {
      const cols = this.extractDataTitle(widgetData, columnWidth);
      headerData.push(...cols);
    }

    // buttons column
    headerData.push({
      title: '',
      width: 30,
      align: 'left',
    });

    return headerData;
  }

  extractDataTitle(widgetData, columnWidth) {
    const temp = [];

    for (let i = 0; i < widgetData.dates.length; i++) {
      temp.push({
        title: widgetData.dates[i],
        width: columnWidth,
        align: 'left',
      });
    }

    return temp.slice(
      this.perPage * this.currentPage,
      this.perPage * this.currentPage + this.perPage,
    );
  }

  setTableData(widgetData, compareData) {
    const tableHeight = this.containerHeight - this.getHeaderHeight() - this.getSubHeaderHeight();

    this.tableView.resetToDefaults();

    this.tableView.bottomSpace = this.rowHeight;
    this.tableView.rowClass = compareData ? TableRowTrendsCompare : TableRowTrends;
    this.tableView.pageSize = this.numberOfRows;
    this.tableView.columnSpacing = WIDGET_PADDING;
    this.tableView.tableSize.width = this.containerWidth;
    this.tableView.tableSize.height = tableHeight;
    this.tableView.rowSize.width = this.containerWidth;
    this.tableView.rowSize.height = this.rowHeight;
    this.tableView.headerHeight = this.tableHeaderHeight;
    this.tableView.interactive = false;

    const headerData = this.extractHeaderData(widgetData, compareData);
    this.tableView.setHeaderData(headerData);

    const tableData = this.createTableData(widgetData, compareData);
    this.tableView.setRowsData(tableData);

    this.tableView.renderTable();

    return tableData;
  }

  createBlankData() {
    const result = {};

    const n = 5;
    const dates = [];
    const hits = [];

    for (let i = 0; i < n; i++) {
      dates.push(`Day ${i + 1}`);
      hits.push(null);
    }

    const objects = {};

    objects.allSessions = {
      id: 'allSessions',
      hits: hits,
      label: 'All People Tracked',
      averageHits: 0,
      color: 0xaaaaaa,
      dates: dates.slice(),
      leadTime: [],
    };

    for (let i = 1; i < this.steps.length; i++) {
      const step = this.steps[i];
      objects[step.ID] = {
        id: step.ID,
        hits: hits,
        label: step.label,
        averageHits: 0,
        color: TREND_LINE_COLORS[i - 1],
        dates: dates.slice(),
        leadTime: [],
      };
    }

    const objectsArray = Array.from(Object.values(objects));

    result.dates = dates;
    result.objects = objects;
    result.objectsArray = objectsArray;

    return result;
  }

  insertBlankData(stepData, widgetData) {
    const data = {
      id: stepData.ID,
      hits: [],
      label: stepData.label,
      leadTime: [],
      color: TREND_LINE_COLORS[this.steps.length - 2],
    };
    widgetData.objects[stepData.ID] = data;
    widgetData.objectsArray.push(data);
  }

  removeFromWidgetData(stepData, widgetData) {
    if (!widgetData) {
      return;
    }

    widgetData.objects[stepData.id] = null;
    delete widgetData.objects[stepData.id];

    for (let i = 0; i < widgetData.objectsArray.length; i++) {
      const object = widgetData.objectsArray[i];
      if (object.id === stepData.id) {
        widgetData.objectsArray.splice(i, 1);
        break;
      }
    }
  }

  setBlankData() {
    this.dataMessage.visible = false;

    this.message.text = 'Add steps to see trends';
    this.message.visible = true;
    this.message.position.set(this.containerWidth / 2, this.containerHeight / 2 + 20);

    const widgetData = this.createBlankData();
    this.widgetData = widgetData;
    this.compareData = null;
  }

  // Utility

  startSpinning() {
    this.stopSpinning();

    this.spinningInterval = setInterval(() => {
      this.reloadBtn.rotation += 0.3;
      window.app.needsRendering();
    }, 200);
  }

  stopSpinning() {
    clearInterval(this.spinningInterval);
    this.reloadBtn.rotation = 0;
    window.app.needsRendering();
  }

  fetchNewData(sceneManager) {
    commonSendEventFunction(PR_EVENT_TRENDS_NEED_UPDATE, {
      widgetId: this.id,
      objects: sceneManager.getObjectsData(),
      connections: sceneManager.getConnectionsStateData(),
    });

    // show loading state
    this.onAnalyticsStartedRefresh();
  }

  isStepDuplicate(stepData) {
    for (let i = 0; i < this.steps.length; i++) {
      const step = this.steps[i];
      if (step.ID === stepData.ID) {
        return true;
      }
    }
    return false;
  }

  hasStepId(id) {
    for (let i = 0; i < this.steps.length; i++) {
      const step = this.steps[i];
      if (step.ID === id) {
        return true;
      }
    }
    return false;
  }

  resolveName(id) {
    for (let i = 0; i < this.steps.length; i++) {
      const step = this.steps[i];
      if (step.ID === id) {
        return step.label;
      }
    }

    if (id === 'allSessions') {
      return 'All People Tracked';
    }
    return '';
  }

  // Events

  onDeleteCommandExecuted(data) {
    const isLoading = this.loadingMessage.visible;

    if (data.status === 'delete') {
      // check if any of our steps are deleted
      for (let i = this.steps.length - 1; i >= 0; i--) {
        const step = this.steps[i];
        const object = data.objects.find((o) => o.id === step.ID);
        if (object) {
          this.removeStep(object);
          object.widgetIds.push(this.id);
        }
      }
    } else if (data.status === 'add') {
      // check the widgetIds and see if any of them is this widget
      for (let i = 0; i < data.objects.length; i++) {
        const object = data.objects[i];
        if (object.widgetIds.includes(this.id)) {
          this.addStep(object.getState());
          // remove the id from the widgetIds array
          const index = object.widgetIds.indexOf(this.id);
          if (index > -1) {
            object.widgetIds.splice(index, 1);
          }
        }
      }
    }

    if (isLoading) {
      this.tableView.visible = false;
    }

    this.onStepsChanged();
  }

  onAddBtnClicked(e) {
    const objectsData = this.delegate.getObjectsData();
    const data = [];
    for (let i = 0; i < objectsData.length; i++) {
      const objectData = objectsData[i];
      if (SharedElementHelpers.IsStep(objectData)) {
        data.push(objectData);
      }
    }

    commonSendEventFunction(PR_ALL_STEPS, {
      objects: data,
      widgetId: this.id,
    });

    commonSendEventFunction(RP_STEP_PICKER_ACTIVATED, {
      active: true,
      widgetId: this.id,
    });
  }

  onStepPicked(stepData) {
    const command = new CommandAddStepToTrends(this, stepData);
    AppSignals.commandCreated.dispatch(command);
  }

  onPickerClosed(sceneManager) {
    if (this.hasNewSteps) {
      this.hasNewSteps = false;
      this.fetchNewData(sceneManager);
    }

    commonSendEventFunction(RP_STEP_PICKER_ACTIVATED, {
      active: false,
      widgetId: this.id,
    });
  }

  onDeleteStepClicked(stepData) {
    const command = new CommandRemoveStepFromTrends(this, stepData);
    AppSignals.commandCreated.dispatch(command);
  }

  onToggleAllStepsClicked(stepData) {
    this.isAllTrackedVisible = !this.isAllTrackedVisible;
    this.rebindData();
    this.onChange();
  }

  onWidgetDropped() {
    this.setBlankData();
    this.addBtnContainer.visible = true;
  }

  onWidgetLoaded() {
    this.startSpinning();
  }

  onModeChanged(mode) {
    this.viewDataMode = mode;
    this.chart.setMode(mode);
    const sceneManager = this.delegate;
    this.fetchNewData(sceneManager);
  }

  onPaste(sceneManager) {
    super.onPaste();

    const widgetData = this.createBlankData();
    this.widgetData = widgetData;
    this.compareData = null;

    this.rebindData();

    this.loadingMessage.visible = false;
    this.chart.visible = true;
    this.legend.visible = true;
  }

  onDataFinishedLoading(data) {
    // No data yet , the widget was just created while loading
    if (!data) {
      this.rebindData();
    }
    this.stopSpinning();
  }

  onChange() {
    if (this.steps.length <= 1) {
      this.message.text = 'Add steps to see trends';
      this.message.visible = true;
      this.message.position.set(this.containerWidth / 2, this.tableView.position.y + 125);
    } else {
      this.message.visible = false;
    }

    window.app.needsRendering();
  }

  onStepsChanged() {
    // triggers when a step is being added or deleted
    commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
  }

  onAnalyticsStartedRefresh() {
    super.onAnalyticsStartedRefresh();
    this.addBtnContainer.visible = false;
    app.needsRendering();

    this.startSpinning();
  }

  onDataError(error) {
    this.stopSpinning();

    this.loadingMessage.visible = false;
    this.tableView.visible = true;
    this.chart.visible = true;
    this.legend.visible = true;
    this.content.visible = true;
    this.addBtnContainer.visible = true;

    this.paginationBtnLeft.visible = true;
    this.paginationBtnRight.visible = true;
    this.paginationLabel.visible = true;

    if (this.widgetData && error !== ERROR_MESSAGES.TRENDS_LONGER_RANGE) {
      this.rebindData();
    } else if (error === ERROR_MESSAGES.TRENDS_LONGER_RANGE) {
      this.hideContent();

      this.message.text = error;
      this.message.visible = true;
      this.message.position.set(this.containerWidth / 2, this.containerHeight / 2);
    }
    window.app.needsRendering();
  }

  onDownloadCSVClicked() {
    if (this.compareData) {
      for (let i = 0; i < this.widgetData.objectsArray.length; i++) {
        const objectData = this.widgetData.objectsArray[i];
        const compareData = this.compareData.objects[objectData.id];
        if (compareData) {
          objectData.compareHits = compareData.hits;
          objectData.compareAverageHits = compareData.averageHits;
          objectData.compareDates = compareData.dates;
        }
      }
    }

    if (this.widgetData) {
      const csvExporter = new CSVExporter();
      const csvData = csvExporter.trendsDataToCSVFormat(this.widgetData, this.compareData);
      csvExporter.downloadCSV(csvData, 'trends', this.range);
    }
  }

  onDownloadChartPNG() {
    if (!this.widgetData) {
      return;
    }

    const snapShot = new ImageSnapshot(
      window.app.renderer,
      this.chart.size.width + this.legend.size.width + 30, // to have some extra padding on the right
      this.chart.size.height + 20, // to have some extra padding on the bottom
    );

    const whiteBackground = new PIXI.Graphics();
    whiteBackground.beginFill(0xffffff);
    whiteBackground.drawRect(0, 0, snapShot.width, snapShot.height);
    whiteBackground.endFill();

    const x = this.chart.position.x;
    const y = this.chart.position.y;
    const parent = this.chart.parent;

    this.chart.position.set(0, 10);
    whiteBackground.addChild(this.chart);

    const legendX = this.legend.position.x;
    const legendY = this.legend.position.y;
    const legendParent = this.legend.parent;

    this.legend.position.set(this.chart.size.width + 10, 10);
    whiteBackground.addChild(this.legend);

    const img = snapShot.createSnapshot(whiteBackground, 'Chart', 2);

    snapShot.forceDownloadImage(img);

    // restore chart
    this.chart.position.set(x, y);
    parent.addChild(this.chart);

    // restore legend
    this.legend.position.set(legendX, legendY);
    legendParent.addChild(this.legend);
  }

  // onRefresh
  onReloadBtnClicked() {
    const isLoading = this.loadingMessage.visible;

    if (isLoading) {
      return;
    }

    const sceneManager = this.delegate;
    this.fetchNewData(sceneManager);
  }

  onWindowPointerDown(e) {
    if (this.modeDropdown.isOpened()) {
      this.modeDropdown.close();
    }
  }

  // Base Container Overrides
  getState() {
    super.getState();
    this.stateData.configuration = {
      steps: [],
      isAllTrackedVisible: this.isAllTrackedVisible,
      viewDataMode: this.viewDataMode,
    };

    for (let i = 0; i < this.steps.length; i++) {
      const step = this.steps[i];
      this.stateData.configuration.steps.push({
        ID: step.ID,
        label: step.label,
      });
    }

    return this.stateData;
  }
}
