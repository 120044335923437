import cls from 'classnames';
import MainStorage from 'pixi-project/core/MainStorage';
import React, { useEffect, useState } from 'react';
import { When } from 'react-project/Util/When';
import { ForecastingExpenses } from 'react-project/components/forecasting/ForecastingExpenses';
import { useDispatch, useSelector } from 'react-redux';
import { PR_EVENT_EXPENSE_OPENED } from 'shared/CSharedEvents';

// The main role of this controller is to manage other general components that need to be shown
// like menus, banners, popups, etc.
export const ReactController = () => {
  const [expenseData, setExpenseData] = useState(null);

  useEffect(() => {
    document.addEventListener(PR_EVENT_EXPENSE_OPENED, onExpenseOpened);
  }, []);

  const onExpenseOpened = (event) => {
    setExpenseData(event.detail);
  };

  return (
    <>
      <When condition={expenseData}>
        <ForecastingExpenses onClose={() => setExpenseData(null)} expenseData={expenseData} />
      </When>
    </>
  );
};
