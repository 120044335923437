import React from 'react';
import { iconPlusSmall, iconSmallLogo } from 'react-project/assets/Icons';
import { AttributesList } from 'react-project/AttributeExplorer/AttributesList';
import { Loader } from 'react-project/components/loader/Loader';
import { NoDataBlock } from 'react-project/components/noDataBlock/NoDataBlock';
import { UILock } from 'react-project/components/uilock/UILock';
import {
  commerceAction,
  COMMERCE_ACTION_NAME,
  getActionType,
  MEETING_ACTION_NAME,
  FORM_ACTION_NAME,
  DEAL_ACTION_NAME,
} from 'react-project/Constants/specialActions';
import { TEXT_LOAD_MORE } from 'react-project/Constants/texts';
import { selectExplorerIsLoading } from 'react-project/redux/explorer/selectors';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import styles from 'react-project/StepsModal/StepsModal.module.scss';
import { When } from 'react-project/Util/When';
import { useSelector } from 'react-redux';
import { EElementTypes, EExplorerConfigTypes, EStepConnectionPort } from 'shared/CSharedCategories';
import {
  ActionTypes,
  ANALYTICS_STATUS_LOADING,
  EXPLORER_TABS,
  IconsConfig,
} from 'shared/CSharedConstants';
import { numSeparator } from 'shared/NumberHelpers';
import PermissionHelper from 'shared/PermissionHelper';

export const ExplorerTab = ({
  type,
  activeExplorerTab,
  port,
  sourceDropdown,
  isLoadingPageParameters,
  explorerDropdownActiveItem,
  position,
  explorerItemsConfig,
  analyticsStatus,
  trafficAttributeClick,
  directTrafficClicked,
  onDragend,
  onLoadMore,
  onIconClick,
  stepsModalSetState,
  isInUnorganizedFolder,
}) => {
  const explorerIsLoading = useSelector(selectExplorerIsLoading);
  const canvasPermissions = useSelector(selectCanvasPermissions);

  if (activeExplorerTab === EXPLORER_TABS.SOURCE) {
    const sourceType =
      port === EStepConnectionPort.IN ? sourceDropdown.type : EExplorerConfigTypes.SOURCE;
    const sourceItemsConfig = explorerItemsConfig[sourceType];
    const commonAttributes = (sourceItemsConfig && sourceItemsConfig.commonParameters) || [];
    const customAttributes = (sourceItemsConfig && sourceItemsConfig.customParameters) || [];
    const isDirectTraffic = sourceType === EExplorerConfigTypes.SOURCE_ALL;
    const directTrafficHits = isDirectTraffic
      ? explorerItemsConfig.direct_traffic_all
      : explorerItemsConfig.direct_traffic;
    return (
      <div className={styles.StepsContainer}>
        <div className={styles.ExplorerSectionHeader}>
          <span className={styles.StepsTitle}>
            {isDirectTraffic ? 'Direct Traffic All' : 'Direct Traffic'}
            <When condition={!(analyticsStatus === ANALYTICS_STATUS_LOADING || explorerIsLoading)}>
              {' '}
              ({directTrafficHits})
            </When>
          </span>
          <When condition={!(analyticsStatus === ANALYTICS_STATUS_LOADING || explorerIsLoading)}>
            <div className={styles.PlusBtn} onClick={() => directTrafficClicked(directTrafficHits)}>
              {iconPlusSmall}
            </div>
          </When>
        </div>
        <div className={styles.ExplorerSectionHeader}>
          <span className={styles.StepsTitle}>Common Parameters:</span>
        </div>
        <When condition={commonAttributes.length > 0}>
          <AttributesList
            attributes={commonAttributes}
            onSelect={trafficAttributeClick}
            isLoadingPageParameters={isLoadingPageParameters}
            stepsModalSetState={stepsModalSetState}
            isInUnorganizedFolder={isInUnorganizedFolder}
          />
        </When>
        <When
          condition={
            !commonAttributes.length &&
            !(analyticsStatus === ANALYTICS_STATUS_LOADING || explorerIsLoading)
          }
        >
          <div className={styles.NoAttributesMessage}>No common attributes to explorer</div>
        </When>
        <div className={styles.ExplorerSectionHeader}>
          <span className={styles.StepsTitle}>Custom Parameters:</span>
        </div>
        <When condition={customAttributes.length > 0}>
          <AttributesList
            attributes={customAttributes}
            onSelect={trafficAttributeClick}
            isLoadingPageParameters={isLoadingPageParameters}
            stepsModalSetState={stepsModalSetState}
            isInUnorganizedFolder={isInUnorganizedFolder}
          />
        </When>
        <When
          condition={
            !customAttributes.length &&
            !(analyticsStatus === ANALYTICS_STATUS_LOADING || explorerIsLoading)
          }
        >
          <div className={styles.NoAttributesMessage}>No custom attributes to explorer</div>
        </When>
      </div>
    );
  }

  const configType =
    port &&
    (type === EXPLORER_TABS.PAGE || type === EXPLORER_TABS.EVENT || type === EXPLORER_TABS.SOURCE)
      ? explorerDropdownActiveItem.type
      : type;

  const getIconPath = (name, type) => {
    switch (type) {
      case EExplorerConfigTypes.PAGE_ALL:
      case EElementTypes.PAGE:
        const pageIcon = IconsConfig[EElementTypes.PAGE].find(
          (icon) => icon.name === 'Generic Page',
        );
        const pageIconSrc = `${pageIcon.srcPixi}${pageIcon.name
          .split(' ')
          .join('')
          .toLowerCase()}.png`;
        return pageIconSrc;

      case EExplorerConfigTypes.EVENT_ALL:
      case EElementTypes.EVENT:
        if (name === COMMERCE_ACTION_NAME) {
          const actionIcon = IconsConfig.SPECIAL_EVENT.find(
            (icon) => icon.name === commerceAction[name],
          );
          const actionIconSrc = `${actionIcon.srcPixi}${actionIcon.name.toLowerCase()}.png`;

          return actionIconSrc;
        }
        const actionIcon = IconsConfig[EElementTypes.EVENT].find((icon) => icon.name === 'Click');
        const actionIconSrc = `${actionIcon.srcPixi}${actionIcon.name.toLowerCase()}.png`;

        return actionIconSrc;
    }
  };

  const exploreItemsConfigType = explorerIsLoading ? [] : explorerItemsConfig[configType];
  const configTypeToHasMore = {
    [EXPLORER_TABS.EVENT]: explorerItemsConfig.has_more_actions,
    [EExplorerConfigTypes.EVENT_ALL]: explorerItemsConfig.has_more_actions_all,
    [EXPLORER_TABS.PAGE]: explorerItemsConfig.has_more_pages,
    [EExplorerConfigTypes.PAGE_ALL]: explorerItemsConfig.has_more_pages_all,
  };

  const isHasMore = configTypeToHasMore[configType];
  const elementBottom = explorerIsLoading ? <Loader variant="grey-font-1" /> : TEXT_LOAD_MORE;

  //NOTE! The same lock needs to also be impmented in the ExplorerBlock.jsx
  // see also filterObjects in Header.jsx ,
  // and possibly Menus.jsx if you wanna block the menu items
  const isElementLocked = (item) => {
    if (!canvasPermissions.purchaseTrackingAllowed && item.name === COMMERCE_ACTION_NAME) {
      return true;
    } else if (!canvasPermissions.calendarTrackingAllowed && item.name === MEETING_ACTION_NAME) {
      return true;
    } else if (!canvasPermissions.formTrackingAllowed && item.name === FORM_ACTION_NAME) {
      return true;
    } else if (!canvasPermissions.dealTrackingAllowed && item.name === DEAL_ACTION_NAME) {
      return true;
    }

    if (PermissionHelper.isDataOriginBlocked(item.data_origin, canvasPermissions)) {
      return true;
    }

    return false;
  };

  return (
    <div className={explorerIsLoading ? null : styles.StepsContainer}>
      <When condition={exploreItemsConfigType?.length}>
        {exploreItemsConfigType?.map((item, i) => {
          return (
            <UILock key={i} isLocked={isElementLocked(item)} style={{ height: 'auto' }}>
              <div
                className={styles.StepsItem}
                onClick={() => {
                  const actionType = getActionType(item.name, type);
                  const actionName = actionType === ActionTypes.CUSTOM ? item.name : null;
                  // TODO: Check if there is need integration type (from explorer)

                  if (position.x === 60) {
                    onDragend(100, 250, {
                      src: getIconPath(item.name, type),
                      title: commerceAction[item.name] || item.name || item.url,
                      type,
                      url: item.url,
                      actionType,
                      actionName,
                    });
                  } else {
                    onIconClick({
                      src: getIconPath(item.name, type),
                      title: commerceAction[item.name] || item.name || item.url,
                      type,
                      url: item.url,
                      hits: item.hits,
                      actionType,
                      actionName,
                    });
                  }
                }}
              >
                <div className={styles.StepsUrl}>
                  {commerceAction[item.name] && iconSmallLogo}
                  {commerceAction[item.name] || item.name || item.url}
                </div>
                <div>{item.hasOwnProperty('hits') ? numSeparator(item.hits) : null}</div>
              </div>
            </UILock>
          );
        })}

        <div className={styles.LoadMore} onClick={() => onLoadMore(type)}>
          {isHasMore ? elementBottom : null}
        </div>
      </When>
      <When
        condition={
          !explorerIsLoading && (!exploreItemsConfigType || exploreItemsConfigType.length === 0)
        }
      >
        <NoDataBlock />
      </When>
    </div>
  );
};

export default ExplorerTab;
