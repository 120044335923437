import LineDrawHelper from 'pixi-project/utils/LineDrawHelper';
import * as PIXI from 'pixi.js';
import Styles, { COLOR_WIDGET_TEXT } from '../Styles';
import { truncate } from 'lodash';

const VERTICAL_SPACING = 20; // vertical spacing between the lines
const LINE_WIDTH = 20; // width of the line
const LINE_SPACING = 10; // spacing between the line and the label

export default class TrendsLegend extends PIXI.Graphics {
  constructor(size) {
    super();
    this.size = size;
    this.labels = [];
  }

  cleanUp() {
    this.clear(); // clear the graphics
    this.removeChildren();
    this.labels = [];
  }

  setData(widgetData, compareData, isAllTrackedVisible) {
    this.data = widgetData;
    this.compareData = compareData;
    this.isAllTrackedVisible = isAllTrackedVisible;

    let x = 150;
    let y = 30;
    const textLength = 30;

    for (let i = 0; i < widgetData.objectsArray.length; i++) {
      const rowData = widgetData.objectsArray[i];

      if (rowData.id === 'allSessions' && !isAllTrackedVisible) {
        continue;
      }

      const textTruncated = truncate(`Step: ${rowData.label}`, { length: textLength });
      const label = new PIXI.BitmapText(textTruncated, Styles.WIDGET_LABEL);
      label.tint = COLOR_WIDGET_TEXT;
      label.anchor.set(1, 0.5);
      this.labels.push(label);
      this.addChild(label);

      label.position.set(x, y);

      // draw line
      this.lineStyle(2, rowData.color, 1);
      this.moveTo(x + LINE_SPACING, y);
      this.lineTo(x + LINE_SPACING + LINE_WIDTH, y);

      y += VERTICAL_SPACING;

      if (compareData) {
        const textTruncated = truncate(`Compare: ${rowData.label}`, { length: textLength });
        const label = new PIXI.BitmapText(textTruncated, Styles.WIDGET_LABEL);
        label.tint = COLOR_WIDGET_TEXT;
        label.anchor.set(1, 0.5);
        this.labels.push(label);
        this.addChild(label);

        label.position.set(x, y);

        // draw line
        LineDrawHelper.drawDashedLine(
          this,
          new PIXI.Point(x + LINE_SPACING, y),
          new PIXI.Point(x + LINE_SPACING + LINE_WIDTH, y),
          4,
          2,
        );

        y += VERTICAL_SPACING;
      }
    }
  }

  drawLegend() {}
}
