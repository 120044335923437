import cls from 'classnames';
import React from 'react';
import styles from './PerformanceUpgrade.module.scss';

export const PerformanceLiteUpgrade = ({ onClose }) => {
  const onBtnClose = () => {
    onClose();
  };

  const onBookADemo = () => {
    window.open(
      `${process.env.REACT_APP_CURRENT_APP_URL}/dashboard/settings/my-subscriptions?h=performance-business`,
      '_blank',
    );
  };

  return (
    <div id="popup1" className={cls(styles.Overlay, styles.OverlayActive)}>
      <div
        className={cls(
          'w-[calc(100vw/3) min-w-[550px] max-w-[700px] top-[calc(30vh/2)]',
          styles.popup,
        )}
        style={{ padding: '50px 60px 50px 60px' }}
      >
        <h2>
          Upgrade to Funnelytics™ <b>Performance Business</b> to access this feature!
        </h2>
        <div className={styles.close} onClick={onBtnClose}>
          &times;
        </div>
        <div className="pt-[24px]">
          Funnelytics is more than a journey mapping tool - it's a full suite of tools to help you{' '}
          <span style={{ fontWeight: '800' }}>plan</span>,{' '}
          <span style={{ fontWeight: '800' }}>measure</span> and{' '}
          <span style={{ fontWeight: '800' }}>optimize</span> your customer journey performance
        </div>
        <div style={{ paddingTop: '24px' }}>
          By upgrading to Funnelytics™ <b>Performance Business</b>, you'll unlock:
        </div>
        <div style={{ paddingTop: '12px' }}>
          <ul>
            <li style={{ paddingBottom: '12px', listStyleType: 'circle', marginLeft: '24px' }}>
              Cutting-edge visual analytics tool to understand performance and gain valuable growth
              insights.
            </li>

            <li style={{ paddingBottom: '12px', listStyleType: 'circle', marginLeft: '24px' }}>
              Powerful planning features like notes, checklists, collaboration, view-only share
              links, and more.
            </li>

            <li style={{ paddingBottom: '12px', listStyleType: 'circle', marginLeft: '24px' }}>
              Access to 100+ proven funnel & strategy templates from some of the world’s top
              marketers.
            </li>

            <li style={{ paddingBottom: '12px', listStyleType: 'circle', marginLeft: '24px' }}>
              Track videos views, button click, forms, purchases, calendar bookings and custom
              events across multiple domain
            </li>

            <li style={{ paddingBottom: '12px', listStyleType: 'circle', marginLeft: '24px' }}>
              Integrate with 1,000+ apps via Zapier and Webhooks
            </li>
          </ul>
        </div>
        <div className={cls(styles.ActionsWrapper, 'pt-[24px]')}>
          <div onClick={onBookADemo} className={cls(styles.BookADemo, 'mt-2')}>
            <div className={styles.Text}>Upgrade</div>
          </div>
          <div
            onClick={onBtnClose}
            className={cls(styles.BackToCanvas)}
            style={{
              position: 'inherit',
              bottom: '22px',
              left: '16px',
            }}
          >
            Back to canvas
          </div>
        </div>
      </div>
    </div>
  );
};
