import _ from 'lodash';
import { adType } from 'react-project/FacebookIntegration/helpers';

export const mapFacebookTreeResponse = (data) => {
  if (data.length === 0) {
    return [];
  }
  const result = [];
  const groupedByParents = _.groupBy(data, 'campaign.id');
  _.each(groupedByParents, (el) => {
    const groupedAdsets = _.groupBy(el, 'adset.id');
    const adsets = [];
    _.each(groupedAdsets, (ads) => {
      const mappedAds = ads.map((ad) => ({ type: adType.AD, value: ad.id, ...ad }));
      adsets.push({
        ...ads[0].adset,
        type: adType.ADSET,
        campaign: { ...ads[0].campaign },
        children: mappedAds,
        spend: mappedAds.reduce((a, b) => {
          return a ? a.spend : 0 + b ? b.spend : 0;
        }, 0),
      });
    });
    result.push({
      ...el[0].campaign,
      value: el[0].campaign.id,
      type: adType.CAMPAIGN,
      children: adsets,
      spend: adsets.reduce((a, b) => {
        return (a ? a.spend : 0) + (b ? b.spend : 0);
      }, 0),
    });
  });

  return result;
};

export const mapFacebookAdsResponse = (data) => {
  if (data.length === 0) {
    return [];
  }
  let adsets = [];
  const groupedByParents = _.groupBy(data, 'campaign.id');
  _.each(groupedByParents, (el) => {
    const groupedAdsets = _.groupBy(el, 'adset.id');
    adsets = [];
    _.each(groupedAdsets, (ads) => {
      const mappedAds = ads.map((ad) => ({ type: adType.AD, value: ad.id, ...ad }));
      adsets.push({
        ...ads[0].adset,
        type: adType.ADSET,
        campaign: { ...ads[0].campaign },
        children: mappedAds,
        spend: mappedAds.reduce((a, b) => {
          return (a ? a.spend : 0) + (b ? b.spend : 0);
        }, 0),
      });
    });
    return adsets;
  });
  return adsets;
};

export const mapFacebookCampaignTreeResponse = (response) => {
  const result = [];
  response.data.map((el) => {
    const adsets = [];
    result.push({ ...el, value: el.id, type: adType.CAMPAIGN, children: adsets });
  });
  return result;
};

export const mapFacebookInsightsResponse = (response) => {
  const cost = response.data.reduce((acc, curr) => {
    if (curr.data[0] && curr.data[0].spend) {
      return acc + Number(curr.data[0].spend);
    }

    return acc;
  }, 0);

  return cost;
};

export const mapAdSpendData = (adData, spendData) => {
  const adList = adData.data.data.map((d) => {
    const adSpend = spendData?.data?.find((s) => s.ad_id === d.id);
    if (adSpend) {
      return { ...d, spend: adSpend.spend };
    }
    return d;
  });
  return adList;
};

export const mapAdListSpendData = (adData, spendData) => {
  const adList = adData?.data?.data.map((d) => {
    let spendAmount = 0;
    spendData?.data?.forEach((s) => {
      s.data.forEach((y) => {
        if (y.ad_id === d.id) {
          spendAmount = y.spend;
        }
      });
    });
    if (spendAmount) {
      return { ...d, spend: spendAmount };
    }
    return d;
  });
  return adList;
};
