const YOUTUBEMATCH_URL =
  /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
const VIMEOMATCH_URL =
  /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/; // eslint-disable-line no-useless-escape

export function isYoutube(url) {
  return YOUTUBEMATCH_URL.test(url);
}
export function isVimeo(url) {
  return VIMEOMATCH_URL.test(url);
}

export function getYoutubeSrc(url) {
  const parts = url && url.match(YOUTUBEMATCH_URL);
  const id = parts && parts[1] ? parts[1] : undefined;
  return {
    srcID: id,
    srcType: 'youtube',
    url,
  };
}
export function getVimeoSrc(url) {
  const parts = url && url.match(VIMEOMATCH_URL);
  const id = parts && parts[3] ? parts[3] : undefined;
  return {
    srcID: id,
    srcType: 'vimeo',
    url,
  };
}
