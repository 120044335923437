import React, { useEffect } from 'react';
import { FunnelProperties } from 'react-project/Util/FunnelProperties';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import { useSelector } from 'react-redux';

export const ProfitwellScript = () => {
  const customerId = FunnelProperties.getCustomerId();
  const canvasPermissions = useSelector(selectCanvasPermissions);

  useEffect(() => {
    if (!customerId || canvasPermissions.isReadonlyAccess || canvasPermissions.isTutorial) {
      return;
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.id = 'profitwell-js';
    script.innerHTML = `(function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
    a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
    s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
    })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');
    profitwell('start', { 'user_id': '${customerId}' });`;
    script.dataset.pwAuth = '80070e16bc2d449d57c0f9429a7cb10f';
    window.document.body.appendChild(script);
  }, []);

  return <></>;
};
