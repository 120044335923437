import * as PIXI from 'pixi.js';
import ConnectionHelper from './ConnectionHelper';
import Utils from 'pixi-project/utils/Utils';

export default class FlowPoint extends PIXI.Container {
  constructor() {
    super();

    this.content = PIXI.Sprite.from('compare_circle');
    this.content.anchor.set(0.5, 0.5);
    this.content.scale.set(0.75);
    this.addChild(this.content);

    this.speed = 3;
    this.currentDistance = 0;
    this.distanceToTravel = 0;
    this.needsRecycle = false;
  }

  setDistanceToTravel(connection) {
    if (connection.isStraight()) {
      this.distanceToTravel = connection.length;
    } else if (connection.bezierSegments.length > 0) {
      const segments = connection.bezierSegments;
      let distance = 0;
      for (let i = 0; i < segments.length; i++) {
        const segment = segments[i];
        distance += segment.length;
      }
      this.distanceToTravel = distance;
    } else {
      this.distanceToTravel = connection.length;
    }
  }

  update(delta = 0) {
    this.currentDistance += this.speed * delta;
  }

  reset() {
    this.currentDistance = 0;
    this.distanceToTravel = 0;
    this.needsRecycle = false;
    this.position = { x: 0, y: 0 };
  }

  setColor(color) {
    this.content.tint = color;
  }

  draw(connection) {
    if (this.currentDistance >= this.distanceToTravel) {
      this.currentDistance = this.currentDistance;
      this.needsRecycle = true;
      return;
    }

    if (connection.isStraight()) {
      this.drawStraight(connection);
    } else if (connection.breakPoints.length === 0) {
      this.drawBezier(connection);
    } else {
      this.drawBezierSegments(connection.bezierSegments);
    }
  }

  drawStraight(connection) {
    let distance = 0;
    let stopFlag = false;

    connection.walkAllLines((a, b) => {
      if (stopFlag) {
        return;
      }
      const localLength = Utils.distanceAB(a, b);
      distance += localLength;
      if (this.currentDistance <= distance) {
        const fraction = (localLength - (distance - this.currentDistance)) / localLength;
        const point = ConnectionHelper.GetLinePoint(a, b, fraction);
        this.position = point;
        stopFlag = true;
      }
    });
  }

  drawBezier(connection) {
    const fraction = (connection.length - this.currentDistance) / connection.length;
    const point = connection.curve.get(1 - fraction); // For some reason the curve is reversed
    this.position = point;
  }

  drawBezierSegments(segments) {
    let distance = 0;
    for (let i = 0; i < segments.length; i++) {
      const segment = segments[i];
      distance += segment.length;
      if (this.currentDistance <= distance) {
        // calculate local fraction
        const fraction = (segment.length - (distance - this.currentDistance)) / segment.length;
        const point = segment.curve.get(fraction);
        this.position = point;
        break;
      }
    }
  }
}
