import cls from 'classnames';
import React, { useEffect, useState } from 'react';
import messageStyles from 'react-project/Sharing/Notifications.module.scss';
import clipStyles from 'react-project/components/copyToClipboardNotification/copyToClipboardNotification.module.scss';

const Notifications = ({ messageObj }) => {
  const [textComponents, setTextComponents] = useState([]);

  useEffect(() => {
    console.log('NEW MESSAGE', messageObj);
    setTextComponents(null);
    if (!messageObj || Object.keys(messageObj).length === 0) return;
    const { message, type } = messageObj;
    console.log('NEW MESSAGE');
    setTextComponents(<Message message={message} type={type} />);

    return () => {
      setTextComponents(null);
    };
  }, [messageObj]);

  return <>{textComponents}</>;
};

const Message = ({ message, type }) => {
  const cssStyle = type === 'success' ? messageStyles.Success : messageStyles.Error;
  console.log();
  return (
    <div className={cls(messageStyles.Message, cssStyle, clipStyles.MessageFadeout_8)}>
      {message}
    </div>
  );
};

export default Notifications;
