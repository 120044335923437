import cls from 'classnames';
import React from 'react';
import {
  iconAnalyticsError,
  iconAnalyticsWarning,
  iconCheckmark,
  iconRefreshBtn,
} from 'react-project/assets/Icons';
import ExpireHidden from 'react-project/Helpers/Expire/ExpireHidden';
import ExpireView from 'react-project/Helpers/Expire/ExpireView';
import ExpireVisible from 'react-project/Helpers/Expire/ExpireVisible';
import {
  ANALYTICS_STATUS_CANCELLED,
  ANALYTICS_STATUS_ERROR,
  ANALYTICS_STATUS_STALE,
  ANALYTICS_STATUS_SUCCESS,
  THUMBNAIL_TYPE,
} from 'shared/CSharedConstants';
import styles from './RefreshButton.module.scss';

const MAP_ANALYTICS_STATUS_TO_ICON = new Map([
  [ANALYTICS_STATUS_SUCCESS, iconCheckmark],
  [ANALYTICS_STATUS_ERROR, iconAnalyticsError],
  [ANALYTICS_STATUS_STALE, iconAnalyticsWarning],
  [ANALYTICS_STATUS_CANCELLED, iconAnalyticsWarning],
]);

export const RefreshButton = ({
  loading,
  analyticsStatus,
  onClick,
  type,
  loaderClassName,
  expire,
}) => {
  const refreshButtonStatusIcon =
    analyticsStatus && MAP_ANALYTICS_STATUS_TO_ICON.get(analyticsStatus);

  if (loading) {
    return (
      <div
        className={cls(styles.AnalyticsReloading, loaderClassName, {
          [styles.ThumbnailLoad]: type === THUMBNAIL_TYPE,
        })}
      >
        {iconRefreshBtn}
      </div>
    );
  } else {
    return (
      <div className={styles.ReloadBtn} onClick={onClick}>
        {iconRefreshBtn}
        {refreshButtonStatusIcon && (
          <span className={styles.RefreshBtnStatus}>{refreshButtonStatusIcon}</span>
        )}
        {expire && (
          <ExpireView delay={expire}>
            <ExpireVisible>
              <span className={styles.RefreshBtnStatus}>{iconCheckmark}</span>
            </ExpireVisible>
            <ExpireHidden>
              <span className={styles.RefreshBtnStatus}>{iconAnalyticsWarning}</span>
            </ExpireHidden>
          </ExpireView>
        )}
      </div>
    );
  }
};
