import MainStorage from 'pixi-project/core/MainStorage';
import { EElementTypes } from 'shared/CSharedCategories';
import {
  PR_EVENT_PEOPLE_WIDGET_LOAD_MORE_TRIGGERED,
  PR_EVENT_SESSION_SELECTED,
} from 'shared/CSharedEvents';
import { cloneData, commonSendEventFunction } from 'shared/CSharedMethods';
import Styles, { COLOR_TABLE_LOADER, COLOR_WIDGET_HEADER } from '../Styles';
import BaseWidget, { WIDGET_PADDING } from './BaseWidget';
import TableRowPeople from './TableRowPeople';
import TableViewScroll from './TableView/TableViewScroll';

export default class PeopleWidget extends BaseWidget {
  constructor(eventHandlers, id, delegate, data) {
    super(eventHandlers, id, delegate, data);

    this.type = EElementTypes.WIDGET_PEOPLE;
    this.styles = {
      icon: 'icon-people.png',
      title: 'People',
    };

    this.totalRows = 0; // number of rows loaded
    this.dataKeys = {}; // keep track of the data loaded to avoid duplicates

    this.containerWidth = 395;
  }

  positionPagination() {
    const y = this.getHeaderY();
    this.paginationLabel.x = this.containerWidth - WIDGET_PADDING;
    this.paginationLabel.y = y;
  }

  createPagination() {
    this.paginationLabel = new PIXI.BitmapText('no data', Styles.WIDGET_PAGINATION_LABEL);
    this.paginationLabel.tint = COLOR_WIDGET_HEADER;
    this.paginationLabel.anchor.set(1, 0.5);
    this.content.addChild(this.paginationLabel);
  }

  updatePagination() {
    const len = this.tableView.rowsData.length;
    this.paginationLabel.text = `${len} of ${this.totalRows}`;
    if (this.isAnalyticsVisible) {
      this.headerLabel.text = `${this.totalRows} people`;
    }
    window.app.needsRendering();
  }

  createTable() {
    this.tableView = new TableViewScroll();
    this.tableView.delegate = this;
    this.content.addChild(this.tableView);

    // lets create a loader
    let loader = new PIXI.Sprite();
    loader.setTexture('loader.png');
    loader.tint = COLOR_TABLE_LOADER;
    loader.anchor.set(0.5, 0.5);
    loader.scale.set(0.5, 0.5);

    this.tableView.addLoader(loader, this.loaderAnimation);
  }

  loaderAnimation(loader, dt) {
    loader.rotation += dt * 0.003;
    window.app.needsRendering();
  }

  addWidgetData(widgetData) {
    // This is the load more data case
    this.totalRows = widgetData.meta.count;
    const data = this.prepData(widgetData);
    if (data.length) {
      this.tableView.addRowsData(data);
    } else {
      this.tableView.onAllDataLoaded();
    }
    this.updatePagination();
    window.app.needsRendering();
  }

  setWidgetData(widgetData) {
    super.setWidgetData(widgetData);

    this.dataKeys = {}; // reset the dataKeys

    this.totalRows = widgetData.meta.count;

    const tableHeight = this.containerHeight - this.getHeaderHeight();

    this.tableView.resetToDefaults();

    this.tableView.bottomSpace = this.rowHeight;
    this.tableView.rowClass = TableRowPeople;
    this.tableView.pageSize = this.numberOfRows;
    this.tableView.columnSpacing = WIDGET_PADDING;
    this.tableView.tableSize.width = this.containerWidth;
    this.tableView.tableSize.height = tableHeight;
    this.tableView.rowSize.width = this.containerWidth;
    this.tableView.rowSize.height = this.rowHeight;
    this.tableView.barStyle.alpha = 0.5;
    this.tableView.barStyle.padding = 5;
    this.tableView.barStyle.width = 5;
    this.tableView.barStyle.radius = 5;
    this.tableView.barStyle.endPadding = 5; // the padding from top and bottom
    this.tableView.headerHeight = this.tableHeaderHeight;

    this.tableView.setHeaderData([
      {
        title: 'Country',
        width: 65,
        align: 'left',
      },
      {
        title: 'Person',
        width: 0.5,
        align: 'left',
      },
      {
        title: 'First Touch',
        width: 0.5,
        align: 'left',
      },
    ]);
    const prepedData = this.prepData(widgetData);

    this.tableView.setRowsData(prepedData);
    this.tableView.renderTable();

    this.hideMessage();

    this.updatePagination();
    window.app.needsRendering();
  }

  prepData(widgetData) {
    let dataSet = [];
    for (let i = 0; i < widgetData.sessions.length; i++) {
      const data = cloneData(widgetData.sessions[i]);
      data._id = i;
      if (!this.isDuplicate(data)) {
        dataSet.push(data);
      }
    }
    return dataSet;
  }

  isDuplicate(data) {
    const key = data.id;
    if (this.dataKeys[key]) {
      return true;
    }
    this.dataKeys[key] = true;
    return false;
  }

  onTableLoadMore(tableView) {
    commonSendEventFunction(PR_EVENT_PEOPLE_WIDGET_LOAD_MORE_TRIGGERED);
  }

  onWheel(event) {
    this.tableView.onWheel(event);
  }

  acceptsWheelEvents() {
    return true;
  }

  onRowSelectionChanged(cell, isSelected) {
    const p = cell.getGlobalPosition();
    commonSendEventFunction(PR_EVENT_SESSION_SELECTED, {
      data: cell.data,
      isSelected,
      position: { x: p.x, y: p.y },
      widgetBounds: this.getBounds(),
    });
  }

  deselectRow(id) {
    for (let i = 0; i < this.tableView.rowsData.length; i++) {
      const rowData = this.tableView.rowsData[i];
      if (rowData.id === id) {
        this.tableView.deselect(i);
        break;
      }
    }
  }

  hideMessage() {
    super.hideMessage();
    this.headerLabel.text = `${this.totalRows} people`;
    this.showContent();
  }

  showMessage() {
    super.showMessage();
    this.headerLabel.text = 'People';
  }

  hideContent() {
    this.tableView.visible = false;
    this.noDataMessage.visible = false;
  }

  showContent() {
    this.tableView.visible = this.tableView.rowsData.length ? true : false;
    this.noDataMessage.visible = this.tableView.rowsData.length ? false : true;
  }
}
