import React, { useEffect, useRef, useState } from 'react';
import styles from './ChecklistBlock.module.scss';
import { useSelector } from 'react-redux';
import { When } from 'react-project/Util/When';
import {
  selectChangedElement,
  selectSelectedElements,
} from 'react-project/redux/selected-elements/selectors';
import { icon3Dots, iconCopyAction, iconDragItem } from 'react-project/assets/Icons';
import { cloneData, commonSendEventFunction } from 'shared/CSharedMethods';
import { v4 as uuidv4 } from 'uuid';
import SharedElementHelpers from 'shared/SharedElementHelpers';
import { Switch } from 'react-project/components/switch/Switch';
import { ProgressBar } from 'react-project/components/progressbar/progressbar';
import { MoreActions } from './MoreActions';
import {
  PR_EVENT_FUNNEL_CHANGED,
  PR_EVENT_CHECKLIST_MODIFIED,
  RP_EVENT_CHECKLIST_CHANGED,
} from 'shared/CSharedEvents';
import cls from 'classnames';
import TreeData from 'react-project/Util/TreeData';
import HTMLExtractor from './HTMLExtractor';
import { selectCurrentFunnelId, selectFunnel } from 'react-project/redux/funnels/selectors';
import { HEAP_EVENTS } from 'react-project/Constants/heapEvents';
import { sendHeapTracking } from 'react-project/Util/HEAP.utilities';
import { track as trackCohesive } from 'react-project/Util/CohesiveTracking';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import { selectChecklist } from 'react-project/redux/checklist/selectors';

const treeData = new TreeData();

const createEmptyItem = (id) => {
  return {
    id: id || uuidv4(), // mandatory for the tree structure to work properly
    hierarchyIndex: 0, // mandatory
    deapth: 0, // mandatory
    items: [], // mandatory
    text: '',
    checked: false,
    isEditing: true,
  };
};

const BLANK_ID = 'blankitem';
const htmlExtractor = new HTMLExtractor(createEmptyItem);

const CHECKLIST_ACTIONS = {
  ADD: 'add',
  ADD_SIBLING: 'add-sibling',
  ADD_CHILD: 'add-child',
  REMOVE: 'remove',
  PASTE: 'paste',
  MOVE_UP: 'move-up',
  MOVE_DOWN: 'move-down',
  TEXT_EDIT: 'text-edit',
  FINISHED_EDITING: 'finished-editing',
  CHECK: 'checked',
  UNCHECK: 'unchecked',
  SHOW_COMPLETED: 'show-completed',
  HIDE_COMPLETED: 'hide-completed',
  REORDER: 'reorder',
  INDENT: 'indent',
  OUTDENT: 'outdent',
};

export const ChecklistBlock = ({
  isCanvas,
  elementChecklistData,
  elementID,
  onChecklistEdited = () => {},
}) => {
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [itemListData, setItemListData] = useState([]);
  const [isHideCompleted, setIsHideCompleted] = useState(false);
  const [moreActionsData, setMoreActionsData] = useState(null);
  const projectId = useSelector((state) => selectFunnel(state)['projectId']);
  const canvasPermissions = useSelector(selectCanvasPermissions);
  const canvasChecklist = useSelector(selectChecklist);
  const funnelId = useSelector(selectCurrentFunnelId);

  const itemsCountData = treeData.countCompletedItems(itemListData);
  const [countData, setCountData] = useState(itemsCountData);

  useEffect(() => {
    let checklistData = isCanvas ? canvasChecklist : elementChecklistData;

    const list = checklistData ? checklistData.items || [] : [];
    const _isHideCompleted = checklistData ? checklistData.isHideCompleted || false : false;
    setIsHideCompleted(_isHideCompleted);

    treeData.readData(list);
    treeData.updateHierarchyIndex();
    treeData.removeEmptyItems((item) => item.text === '');
    setItemListData(treeData.data);
    const count = treeData.countCompletedItems();
    setCountData(count);

    // Listening for undo/redo events
    document.addEventListener(PR_EVENT_CHECKLIST_MODIFIED, onChecklistModified);
    return () => {
      document.removeEventListener(PR_EVENT_CHECKLIST_MODIFIED, onChecklistModified);
    };
  }, []);

  const onChecklistModified = (e) => {
    if (e.detail.object && e.detail.object.id === elementID) {
      treeData.readData(e.detail.data.items || []);
      treeData.updateHierarchyIndex();
      setItemListData(treeData.data);
      setIsHideCompleted(e.detail.data.isHideCompleted || false);
      onChecklistEdited(elementID, treeData.data);
    }
  };

  const dragStart = (e, item) => {
    if (canvasPermissions.isReadonlyAccess) {
      e.stopPropagation();
      e.preventDefault();
      return;
    }
    e.stopPropagation();
    dragItem.current = item;
  };

  const dragEnter = (e, item) => {
    if (canvasPermissions.isReadonlyAccess) {
      e.stopPropagation();
      e.preventDefault();
      return;
    }
    e.stopPropagation();
    dragOverItem.current = item;
    const toMove = dragItem.current;
    treeData.readData(itemListData);
    if (toMove.id !== item.id && !treeData.isDescendant(toMove, item)) {
      treeData.removeItem(toMove);
      treeData.insertItemInList(toMove, item);
      treeData.updateHierarchyIndex();
      setItemListData(treeData.data);
    }
  };

  const onDragEnd = (e) => {
    e.stopPropagation();
    notifyChange(null, CHECKLIST_ACTIONS.REORDER);
  };

  const addSiblingToItem = (item, shouldNotify = true) => {
    treeData.readData(itemListData);
    const newItem = createEmptyItem();
    newItem.hierarchyIndex = Number.MIN_SAFE_INTEGER; // this will force the item to be added below the target item
    treeData.insertItemInList(newItem, item);
    treeData.updateHierarchyIndex();
    setItemListData(treeData.data);
    if (shouldNotify) {
      notifyChange({ items: treeData.data }, CHECKLIST_ACTIONS.ADD_SIBLING);
    }
  };

  const addChildToItem = (item) => {
    treeData.readData(itemListData);
    const newItem = createEmptyItem();
    treeData.insertItemAsChild(newItem, item);
    treeData.updateHierarchyIndex();
    setItemListData(treeData.data);
    notifyChange({ items: treeData.data }, CHECKLIST_ACTIONS.ADD_CHILD);
  };

  const deleteItem = (item) => {
    treeData.readData(itemListData);
    treeData.removeItem(item);
    treeData.updateHierarchyIndex();
    setItemListData(treeData.data);
    notifyChange({ items: treeData.data }, CHECKLIST_ACTIONS.REMOVE);
  };

  const copyList = (item) => {
    const items = item ? [item] : itemListData;
    const html = createHTMLList(items);
    copyFormatted(html);
  };

  const createHTMLList = (items) => {
    if (!items || items.length === 0) return '';

    let html = '<ul>';
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      html += `<li>${item.text}</li>`;
      const children = item.items;
      if (children) {
        html += createHTMLList(children);
      }
    }
    html += `</ul>`;
    return html;
  };

  const copyFormatted = (html) => {
    const container = document.createElement('div');
    container.innerHTML = html;
    container.style.position = 'fixed';
    container.style.pointerEvents = 'none';
    container.style.opacity = 0;
    const activeSheets = Array.prototype.slice.call(document.styleSheets).filter(function (sheet) {
      return !sheet.disabled;
    });
    document.body.appendChild(container);
    window.getSelection().removeAllRanges();
    const range = document.createRange();
    range.selectNode(container);
    window.getSelection().addRange(range);
    document.execCommand('copy');
    for (let i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = true;
    document.execCommand('copy');
    for (let i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = false;
    document.body.removeChild(container);
  };

  const indent = (item) => {
    treeData.readData(itemListData);
    if (treeData.moveItemToChildren(item)) {
      treeData.updateHierarchyIndex();
      setItemListData(treeData.data);
      notifyChange({ items: treeData.data }, CHECKLIST_ACTIONS.INDENT);
    }
  };

  const outdent = (item) => {
    treeData.readData(itemListData);
    if (treeData.moveItemToParent(item)) {
      treeData.updateHierarchyIndex();
      setItemListData(treeData.data);
      notifyChange({ items: treeData.data }, CHECKLIST_ACTIONS.OUTDENT);
    }
  };

  const moveItemUp = (item) => {
    treeData.readData(itemListData);
    if (treeData.moveItemUpInList(item)) {
      setItemListData(treeData.data);
      notifyChange({ items: treeData.data }, CHECKLIST_ACTIONS.MOVE_UP);
    }
  };

  const moveItemDown = (item) => {
    treeData.readData(itemListData);
    if (treeData.moveItemDownInList(item)) {
      setItemListData(treeData.data);
      notifyChange({ items: treeData.data }, CHECKLIST_ACTIONS.MOVE_DOWN);
    }
  };

  const onCheckboxCheck = (e, item) => {
    if (canvasPermissions.isReadonlyAccess) {
      e.stopPropagation();
      return;
    }
    e.stopPropagation();
    item.checked = !item.checked;

    treeData.readData(itemListData);
    treeData.updateElementInList(item);
    setItemListData(treeData.data);
    notifyChange(
      { items: treeData.data },
      item.checked ? CHECKLIST_ACTIONS.CHECK : CHECKLIST_ACTIONS.UNCHECK,
    );
  };

  const onTextClicked = (e, item) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }

    e.stopPropagation();
    if (item.isEditing) {
      return;
    }

    item.isEditing = true;

    treeData.readData(itemListData);
    treeData.updateAllElements((item) => (item.isEditing = false));
    treeData.updateElementInList(item);
    setItemListData(treeData.data);

    const count = treeData.countCompletedItems();
    setCountData(count);
  };

  const onTextChanged = (e, item) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }

    e.stopPropagation();
    item.text = e.target.value;

    treeData.readData(itemListData);

    // Add the first item if it is empty
    if (treeData.data.length === 0) {
      treeData.data.push(item);
    }

    treeData.updateElementInList(item);
    setItemListData(treeData.data);
  };

  const onTextEdited = (e, item) => {
    e.stopPropagation();
    item.isEditing = false;

    treeData.readData(itemListData);
    treeData.updateElementInList(item);
    if (item.text === '') {
      // if the text is empty, remove the item
      treeData.removeEmptyItems((item) => item.text === '');

      if (treeData.didCountChanged(countData)) {
        trackEvent(CHECKLIST_ACTIONS.REMOVE);
        const count = treeData.countCompletedItems();
        setCountData(count);
      }
    } else {
      if (treeData.didCountChanged(countData)) {
        trackEvent(CHECKLIST_ACTIONS.ADD);
        const count = treeData.countCompletedItems();
        setCountData(count);
      }
    }

    treeData.updateHierarchyIndex();
    setItemListData(treeData.data);
    notifyChange({ items: treeData.data }, CHECKLIST_ACTIONS.TEXT_EDIT);
  };

  const onInputFocus = (e, item) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }
    e.stopPropagation();
  };

  const onInputBlur = (e, item) => {
    if (!itemListData || !itemListData.length) {
      // Bacause the blur event is being emitted
      // at the moment of creating an empty list
      return;
    }
    onTextEdited(e, item);
  };

  // Handle Shortcuts
  const onTextKeyDown = (e, item) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }
    // check modifiers
    const isShift = e.shiftKey;
    const isCtrl = e.ctrlKey || e.metaKey;
    e.stopPropagation();

    if (isShift && e.key === 'Enter') {
      addChildToItem(item);
    } else if (isShift && e.key === 'Tab') {
      outdent(item);
      e.preventDefault();
    } else if (isShift && (e.key === 'ArrowUp' || e.key === 'PageUp')) {
      moveItemUp(item);
      e.preventDefault();
    } else if (isShift && (e.key === 'ArrowDown' || e.key === 'PageDown')) {
      moveItemDown(item);
      e.preventDefault();
    } else if (isShift && e.key === 'Delete') {
      deleteItem(item);
    } else if (isCtrl && (e.key === 'b' || e.key === 'B')) {
      // bold the text
      onItemBold(item);
      e.preventDefault();
    } else if (isCtrl && (e.key === 'i' || e.key === 'I')) {
      // italicize the text
      onItemItalic(item);
      e.preventDefault();
    } else if (isCtrl && (e.key === 'u' || e.key === 'U')) {
      // underline the text
      onItemUnderline(item);
      e.preventDefault();
    } else if (e.key === 'Enter') {
      onEnter(e, item);
    } else if (e.key === 'Escape') {
      onTextEdited(e, item);
    } else if (e.key === 'Tab') {
      indent(item);
      e.preventDefault();
    }
  };

  const onItemBold = (item) => {
    setItemStyle(item, { bold: true });
  };

  const onItemItalic = (item) => {
    setItemStyle(item, { italic: true });
  };

  const onItemUnderline = (item) => {
    setItemStyle(item, { underline: true });
  };

  const setItemStyle = (item, style) => {
    if (item.style) {
      // iterate over the style object and toggle the style
      Object.keys(style).forEach((key) => {
        item.style[key] = !item.style[key];
      });
    } else {
      item.style = style;
    }
    treeData.readData(itemListData);
    treeData.updateElementInList(item);
    setItemListData(treeData.data);
  };

  const onEnter = (e, item) => {
    onTextEdited(e, item);
    if (item.text !== '') {
      const count = treeData.countCompletedItems();
      setCountData(count);
      addSiblingToItem(item, false);
    }
  };

  const onAddChecklistItem = (e) => {
    if (canvasPermissions.isReadonlyAccess) {
      e.stopPropagation();
      return;
    }
    e.stopPropagation();
    const newItem = createEmptyItem();
    treeData.readData(itemListData);
    treeData.add(newItem);
    treeData.updateHierarchyIndex();
    setItemListData(treeData.data);
  };

  const onMoreActionsClicked = (e, item) => {
    if (canvasPermissions.isReadonlyAccess) {
      e.stopPropagation();
      return;
    }
    e.stopPropagation();
    const relativeElement = e.currentTarget;
    setMoreActionsData({ item, relativeElement });
  };

  const closeMoreActions = () => {
    if (moreActionsData) {
      setMoreActionsData(null);
    }
  };

  const onHideCompletedToggle = () => {
    setIsHideCompleted(!isHideCompleted);
    notifyChange(
      { isHideCompleted: !isHideCompleted },
      isHideCompleted ? CHECKLIST_ACTIONS.SHOW_COMPLETED : CHECKLIST_ACTIONS.HIDE_COMPLETED,
    );
  };

  const onMoreActionClickOutside = (e) => {
    closeMoreActions();
  };

  const onAddSiblingClicked = (e) => {
    addSiblingToItem(moreActionsData.item);
    e.stopPropagation();
    closeMoreActions();
  };

  const onAddChildClicked = (e) => {
    addChildToItem(moreActionsData.item);
    e.stopPropagation();
    closeMoreActions();
  };

  const onDeleteClicked = (e) => {
    e.stopPropagation();
    deleteItem(moreActionsData.item);
    closeMoreActions();
  };

  const onCopyClicked = (e) => {
    e.stopPropagation();
    copyList(moreActionsData.item);
    closeMoreActions();
  };

  const onIndentClicked = (e) => {
    e.stopPropagation();
    indent(moreActionsData.item);
    closeMoreActions();
  };

  const onOutdentClicked = (e) => {
    e.stopPropagation();
    outdent(moreActionsData.item);
    closeMoreActions();
  };

  const onMoveUpClicked = (e) => {
    e.stopPropagation();
    moveItemUp(moreActionsData.item);
    closeMoreActions();
  };

  const onMoveDownClicked = (e) => {
    e.stopPropagation();
    moveItemDown(moreActionsData.item);
    closeMoreActions();
  };

  const onCopyChecklist = (e) => {
    e.stopPropagation();
    copyList();
    closeMoreActions();
  };

  const notifyChange = (data, action = null, id = null) => {
    const checklistData = {
      items: cloneData(itemListData),
      isHideCompleted: isHideCompleted,
    };

    if (data) {
      Object.assign(checklistData, data);
    }

    if (action === CHECKLIST_ACTIONS.REMOVE) {
      trackEvent(CHECKLIST_ACTIONS.REMOVE);
    } else if (action === CHECKLIST_ACTIONS.CHECK) {
      trackEvent(CHECKLIST_ACTIONS.CHECK);
    } else if (action === CHECKLIST_ACTIONS.UNCHECK) {
      trackEvent(CHECKLIST_ACTIONS.UNCHECK);
    } else if (action === CHECKLIST_ACTIONS.PASTE) {
      trackEvent(CHECKLIST_ACTIONS.PASTE);
    }

    commonSendEventFunction(RP_EVENT_CHECKLIST_CHANGED, { checklistData, id: elementID });
    commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);

    onChecklistEdited(elementID, checklistData);
  };

  const onInputPaste = (e, item) => {
    if (canvasPermissions.isReadonlyAccess) {
      e.stopPropagation();
      return;
    }
    e.stopPropagation();
    const extractor = htmlExtractor.getChecklistExtractor(e.clipboardData);

    if (extractor) {
      const items = cloneData(extractor(e.clipboardData.getData('text/html')));
      if (items && items.length > 0) {
        e.preventDefault();
        treeData.readData(itemListData);
        if (item.text === '') {
          treeData.appendItemsToItem(item, items);
        } else {
          treeData.addItemsAsChildren(item, items);
        }
        treeData.updateHierarchyIndex();
        setItemListData(treeData.data);
        notifyChange({ items: treeData.data }, CHECKLIST_ACTIONS.PASTE);
      }
    }
  };

  const createChecklistItems = (itemsData = []) => {
    const items = [];
    for (let i = 0; i < itemsData.length; i++) {
      const itemData = itemsData[i];
      const childNode = createItemHTML(itemData);
      items.push(childNode);
    }

    return items;
  };

  const checkIfItemIsUnchecked = (item) => {
    return !item.checked;
  };

  const createItemStyle = (item) => {
    return {
      fontWeight: item.style && item.style.bold ? 'bold' : 'normal',
      fontStyle: item.style && item.style.italic ? 'italic' : 'normal',
      textDecoration: item.style && item.style.underline ? 'underline' : 'none',
    };
  };

  const trackEvent = (action) => {
    let type = isCanvas ? 'canvas' : '';
    const count = treeData.countCompletedItems();
    setCountData(count);

    const trackingData = {
      projectId: projectId,
      eventName: HEAP_EVENTS.CHECKLIST_CHANGED,
      eventData: {
        number_of_items: count.total,
        checked_items: count.checked,
        progress: count.checked / count.total,
        element_type: type,
        action: action,
      },
      funnelId: funnelId,
    };
    sendHeapTracking(trackingData);
    trackCohesive(HEAP_EVENTS.CHECKLIST_CHANGED, {
      ...trackingData.eventData,
      projectId: projectId,
      funnelId: funnelId,
    });
  };

  const createItemHTML = (item) => {
    const shouldHide =
      isHideCompleted &&
      item.checked &&
      // if it has unchecked children, we should not hide it
      !treeData.hasHeritage(checkIfItemIsUnchecked, item.items || []);

    return shouldHide ? null : (
      <div
        key={item.id}
        className={styles.Item}
        draggable
        onDragStart={(e) => dragStart(e, item)}
        onDragEnter={(e) => dragEnter(e, item)}
        onDragEnd={onDragEnd}
      >
        <div className={styles.ItemContent}>
          <div className={styles.Handle}>{iconDragItem}</div>
          <div className={styles.Checkbox}>
            <input
              onChange={(e) => {
                onCheckboxCheck(e, item);
                e.stopPropagation();
              }}
              type="checkbox"
              checked={item.checked}
            />
          </div>
          <div
            draggable
            onDragStart={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onClick={(e) => onTextClicked(e, item)}
            className={cls(styles.ItemText, item.checked ? styles.Checked : null)}
            style={createItemStyle(item)}
          >
            {item.isEditing ? (
              <input
                key={item.id}
                autoFocus={itemsCountData.total > 0}
                value={item.text}
                onChange={(e) => onTextChanged(e, item)}
                onFocus={(e) => onInputFocus(e, item)}
                onBlur={(e) => onInputBlur(e, item)}
                onKeyDown={(e) => onTextKeyDown(e, item)}
                placeholder={'Add a new item...'}
                onPaste={(e) => onInputPaste(e, item)}
                onPointerDown={(e) => {
                  e.stopPropagation();
                  e.currentTarget.focus();
                }}
              />
            ) : (
              item.text
            )}
          </div>
          <div onClick={(e) => onMoreActionsClicked(e, item)} className={styles.AddSubItem}>
            {icon3Dots}
          </div>
        </div>
        {item.items && item.items.length ? (
          <div className={styles.Children}>{createChecklistItems(item.items)}</div>
        ) : null}
      </div>
    );
  };

  return (
    <>
      <div className={styles.Wrapper}>
        <div className={styles.Block}>
          <When condition={isCanvas}>
            <div className={cls(styles.ContentBlock, styles.ProgressBarBlock)}>
              <ProgressBar
                showPercentage={false}
                percent={(itemsCountData.checked / itemsCountData.total) * 100}
              />
              <span className={styles.ProgressCounter}>
                {itemsCountData.checked} / {itemsCountData.total}
              </span>
            </div>

            <div className={styles.TitleBlock} style={{ marginTop: '10px' }}>
              <div className={styles.Title}>
                Copy Checklist
                <div onClick={onCopyChecklist} className={styles.CopyChecklist}>
                  {iconCopyAction}
                </div>
              </div>
              <div className={styles.TitleRightContent}>
                <Switch onToggle={onHideCompletedToggle} isActive={isHideCompleted} />
                Hide Completed
              </div>
            </div>

            <div
              className={styles.ContentBlock}
              style={{ flexGrow: 2, overflow: 'auto', marginBottom: 0 }}
            >
              <div className={styles.ChecklistBlock}>
                <When condition={itemListData && itemListData.length}>
                  <div className={styles.List}>{createChecklistItems(itemListData)}</div>
                </When>

                <When condition={!itemListData || !itemListData.length}>
                  <div className={styles.List}>
                    {createChecklistItems([createEmptyItem(BLANK_ID)])}
                  </div>
                </When>

                <When condition={!canvasPermissions.isReadonlyAccess}>
                  <div onClick={onAddChecklistItem} className={styles.AddChecklist}>
                    + Add Checklist Item
                  </div>
                </When>
              </div>
            </div>
          </When>

          <When condition={!isCanvas}>
            <When condition={itemsCountData.total > 0 || !canvasPermissions.isReadonlyAccess}>
              <div className={cls(styles.ContentBlock, styles.ProgressBarBlock)}>
                <ProgressBar
                  showPercentage={false}
                  percent={(itemsCountData.checked / itemsCountData.total) * 100}
                />
                <span className={styles.ProgressCounter}>
                  {itemsCountData.checked} / {itemsCountData.total}
                </span>
              </div>

              <div className={styles.TitleBlock} style={{ marginTop: '10px' }}>
                <div className={styles.Title}>
                  Copy Checklist
                  <div onClick={onCopyChecklist} className={styles.CopyChecklist}>
                    {iconCopyAction}
                  </div>
                </div>
                <div className={styles.TitleRightContent}>
                  <Switch onToggle={onHideCompletedToggle} isActive={isHideCompleted} />
                  Hide Completed
                </div>
              </div>

              <div
                className={styles.ContentBlock}
                style={{ flexGrow: 2, overflow: 'auto', marginBottom: 0 }}
              >
                <div className={styles.ChecklistBlock}>
                  <When condition={itemListData && itemListData.length}>
                    <div className={styles.List}>{createChecklistItems(itemListData)}</div>
                  </When>

                  <When condition={!itemListData || !itemListData.length}>
                    <div className={styles.List}>
                      {createChecklistItems([createEmptyItem(BLANK_ID)])}
                    </div>
                  </When>

                  <When condition={!canvasPermissions.isReadonlyAccess}>
                    <div onClick={onAddChecklistItem} className={styles.AddChecklist}>
                      + Add Checklist Item
                    </div>
                  </When>
                </div>
              </div>
            </When>
          </When>
        </div>
      </div>
      <When condition={moreActionsData !== null}>
        <MoreActions
          itemData={moreActionsData ? moreActionsData.item : null}
          relativeElement={moreActionsData ? moreActionsData.relativeElement : null}
          onAddSibling={onAddSiblingClicked}
          onAddChild={onAddChildClicked}
          onIndent={onIndentClicked}
          onOutdent={onOutdentClicked}
          onMoveUp={onMoveUpClicked}
          onMoveDown={onMoveDownClicked}
          onClickOutside={onMoreActionClickOutside}
          onDelete={onDeleteClicked}
          onCopy={onCopyClicked}
        />
      </When>
    </>
  );
};
