import React, { useEffect, useState } from 'react';

const Expire = (props) => {
  const [visible, setVisible] = useState(true);
  let timeout = null;
  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    timeout = setTimeout(() => {
      setVisible(false);
    }, props.delay);
  }, [props.delay]);

  return visible ? props.children : null;
};

export default Expire;
