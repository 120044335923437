import { PR_EVENT_CANVAS_CHECKLIST_CHANGED } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';

export default class CanvasChecklist {
  constructor() {
    this.checklistData = null;
  }

  onCheckistChanged(checklistData) {
    this.checklistData = checklistData;
    commonSendEventFunction(PR_EVENT_CANVAS_CHECKLIST_CHANGED, checklistData);
  }

  getData() {
    return this.checklistData;
  }
}
