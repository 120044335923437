import { DropdownBlock } from 'react-project/components/dropdown/DropdownBlock';
import styles from './TextToolbar.module.scss';
import commonDropdownStyles from 'react-project/components/dropdown/Dropdown.module.scss';
import { DropdownItem } from 'react-project/components/dropdown/DropdownItem';
import React, { forwardRef, useState } from 'react';
import cls from 'classnames';
import { Dropdown } from 'react-project/components/dropdown/Dropdown';
import { ClickOutsideCustomComponent } from '../../Util/ClickOutsideCustom';
import { iconArrow } from 'react-project/assets/Icons';

const TextDropdownTrigger = forwardRef(({ isOpen, classNames, title, ...rest }, ref) => {
  const tabClassName = cls(styles.TextToolbarTab, styles.WithBorder, classNames);
  return (
    <div className={tabClassName} {...rest}>
      <div className={cls(styles.TriggerWrapper, { [styles.ActiveStyle]: isOpen })}>
        {title} <span className={styles.IconArrow}>{iconArrow}</span>
      </div>
    </div>
  );
});

export const TextDropdown = ({
  label,
  items,
  dropdownItemsStyle,
  onSelect,
  dropdownTriggerStyles,
}) => {
  const [dropdownOpened, setDropdownOpened] = useState(false);

  const renderTextTypes = () => {
    return items.map((item) => {
      return (
        <DropdownItem
          className={cls(
            commonDropdownStyles.DropdownItem,
            styles.TextDropdownItem,
            item.className,
          )}
          label={item.label}
          key={item.label}
          onClick={() => {
            setDropdownOpened(false);
            onSelect(item);
          }}
        />
      );
    });
  };

  return (
    <ClickOutsideCustomComponent
      onClickOutside={() => {
        setDropdownOpened(false);
      }}
    >
      <Dropdown
        contentClassName={styles.TextDropdown}
        triggerSlot={<TextDropdownTrigger title={label} classNames={dropdownTriggerStyles} />}
        onToggle={(opened) => setDropdownOpened(opened)}
        isOpen={dropdownOpened}
      >
        <div className={cls(commonDropdownStyles.CommonDropdownItems, dropdownItemsStyle)}>
          <DropdownBlock>{renderTextTypes()}</DropdownBlock>
        </div>
      </Dropdown>
    </ClickOutsideCustomComponent>
  );
};
