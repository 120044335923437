import React from 'react';

import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';
import styles from './Warning.module.scss';
import cls from 'classnames';
import { iconClose, iconWarrning } from 'react-project/assets/Icons';

export const Warning = (props) => {
  const { onClose } = props;

  return (
    <ClickOutsideCustomComponent
      onClickOutside={(e) => {
        onClose();
      }}
    >
      <div className={styles.Wrapper}>
        <div className={styles.WrapperBox}>
          <div className={cls(styles.Container, styles.Title)}>
            <div className={styles.WarningTitle}>{iconWarrning} Warrning</div>
            <div className={styles.CloseBtn} onClick={() => onClose()}>
              {iconClose}
            </div>
          </div>
          <div className={styles.Separator}></div>

          <div className={cls(styles.Warrning)}>{props.children}</div>
        </div>
      </div>
    </ClickOutsideCustomComponent>
  );
};
