import styles from './tools.module.scss';
import { iconReportTool } from 'react-project/assets/Icons';
import { Tooltip } from 'react-project/components/tooltip/Tooltip';
import React from 'react';
import cls from 'classnames';
import { TEXTS_TOOLTIP } from '../../Constants/texts';
import MainStorage from 'pixi-project/core/MainStorage';
import { LOCK_NO_PLANNING, UILock } from '../uilock/UILock';

export const ReportTool = ({ setReportToolStatus, isActive, isDisabled }) => {
  const onClick = () => {
    const isPlanningAllowed = MainStorage.getCanvasPermissions().isPlanningAllowed;
    if (isPlanningAllowed === null) {
      console.warn('Waiting to load data from server');
      return;
    }

    if (!isDisabled) {
      setReportToolStatus(!isActive);
    }
  };

  const tabsClassName = cls(styles.ToolButton, {
    [styles.Active]: isActive,
    [styles.Disabled]: isDisabled,
  });

  return (
    <Tooltip label={TEXTS_TOOLTIP.REPORT_TOOL}>
      <UILock lock={LOCK_NO_PLANNING}>
        <div className={tabsClassName} onClick={onClick}>
          {iconReportTool}
        </div>
      </UILock>
    </Tooltip>
  );
};
