import React, { useEffect, useRef, useState } from 'react';
import {
  addBulkIcon,
  arrowCollapsedIcon,
  arrowExpandedIcon,
  circlePlusIcon,
  iconRefreshBtn,
  iconSearch,
  iconSmallLogo,
  iconWarrning,
  moreActionsIcon,
} from 'react-project/assets/Icons';
import { ANALYTICS_STATUS_LOADING, EXPLORER_TABS, IntegrationTypes } from 'shared/CSharedConstants';
import styles from './ExplorerBlock.module.scss';
import cls from 'classnames';
import { SUB_TAB, explorerSubTabs, explorerTabs, subTabsTitles } from './ExplorerBlockConfig';
import { useSelector } from 'react-redux';
import { selectFunnelConfiguration } from 'react-project/redux/funnel-configuration/selectors';
import { When } from 'react-project/Util/When';
import { ParametersBlock } from '../ParametersBlock/ParametersBlock';
import { PR_EVENT_MIDDLE_MOUSE_DOWN, RP_EVENT_CREATE_OBJECT } from 'shared/CSharedEvents';
import { Tooltip } from 'react-project/components/tooltip/Tooltip';
import { TOOLTIP_POSITIONS } from 'react-project/Constants/tooltip';
import MainStorage from 'pixi-project/core/MainStorage';
import { cloneData, commonSendEventFunction } from 'shared/CSharedMethods';
import ExplorerDataHelper, {
  mockExplorerActionsData,
  mockExplorerData,
} from '../Helpers/ExplorerDataHelper';
import { selectFocusedSteps } from 'react-project/redux/focused-step/selectors';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import { ExplorerFreeTrial } from './upsell/ExplorerFreeTrial';
import { ExplorerUpgrade } from './upsell/ExplorerUpgrade';
import { ExplorerActionsUpgrade } from './upsell/ExplorerActionsUpgrade';
import { selectAnalyticsStatus } from 'react-project/redux/analytics/selectors';
import { UILock } from 'react-project/components/uilock/UILock';
import {
  COMMERCE_ACTION_NAME,
  DEAL_ACTION_NAME,
  FORM_ACTION_NAME,
  MEETING_ACTION_NAME,
} from 'react-project/Constants/specialActions';
import PermissionHelper from 'shared/PermissionHelper';

const explorerDataHelper = new ExplorerDataHelper();

const PAGINATION_LIMIT = 20;

const STATUS_LOADING = 'loading';
const STATUS_ERROR = 'error';
const STATUS_SUCCESS = 'success';

const DEFAULT_PAGINATION_STATE = {
  [SUB_TAB.ALL_REFERING_URLS]: 1,
  [SUB_TAB.ALL_PAGES]: 1,
  [SUB_TAB.CUSTOM_PARAMS]: 1,
  [SUB_TAB.CUSTOM_ACTIONS]: 1,
  [SUB_TAB.CONVERSION_ACTIONS]: 1,
};

export const ExplorerBlock = ({ funnel }) => {
  const textSearchInputRef = useRef(null);
  const scrollContentRef = useRef(null);
  const refreshBtnRef = useRef(null);
  const elementCreationShiftRef = useRef(0);

  const [isDisabledMapTab, setIsDisabledMapTab] = useState(false);
  const [activeTab, setActiveTab] = useState(EXPLORER_TABS.PAGE);
  const [textSearch, setTextSearch] = useState('');
  const [subTabs, setSubTabs] = useState(explorerSubTabs[activeTab]);
  const [activeSubTab, setActiveSubTab] = useState(subTabs[0]);
  const [titleRow, setTitleRow] = useState(subTabsTitles[activeSubTab.id]);
  const [status, setStatus] = useState(STATUS_SUCCESS);
  const [data, setData] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isParametersOpened, setIsParametersOpened] = useState(false);
  const [expandedItems, setExpandedItems] = useState([]);
  const [loadMoreStatus, setLoadMoreStatus] = useState(STATUS_SUCCESS);
  const [loadMorePagination, setLoadMorePagination] = useState(DEFAULT_PAGINATION_STATE);
  const [loadMoreKeyStatus, setLoadMoreKeyStatus] = useState({});
  const [paginationByKey, setPaginationByKey] = useState({});
  const [isInitialized, setIsInitialized] = useState(false);

  const focusedSteps = useSelector(selectFocusedSteps);
  const canvasPermissions = useSelector(selectCanvasPermissions);
  const funnelConfiguration = useSelector(selectFunnelConfiguration);
  const analyticsStatus = useSelector(selectAnalyticsStatus);

  // keep the helper updated
  useEffect(() => {
    explorerDataHelper.update(
      funnelConfiguration,
      focusedSteps,
      activeTab,
      [], // empty selected data
      selectedItem,
      funnel,
      activeSubTab,
    );
  }, [funnelConfiguration, focusedSteps, activeTab, selectedItem, funnel, activeSubTab]);

  useEffect(() => {
    if (analyticsStatus === ANALYTICS_STATUS_LOADING && isInitialized) {
      fetchData();
    }
  }, [analyticsStatus]);

  useEffect(() => {
    setSubTabs(explorerSubTabs[activeTab]);
  }, [activeTab]);

  useEffect(() => {
    onSubTabChanged(activeSubTab);
  }, [activeSubTab]);

  useEffect(() => {
    if (refreshBtnRef.current) {
      if (status === STATUS_LOADING) {
        refreshBtnRef.current.classList.add(styles.SpinAnimation);
      } else {
        refreshBtnRef.current.classList.remove(styles.SpinAnimation);
      }
    }
  }, [status]);

  // Keep the initialization logic at the bottom of the other useEffects
  // The order is important here
  useEffect(() => {
    document.addEventListener(PR_EVENT_MIDDLE_MOUSE_DOWN, onMiddleMouseDown, false);

    if (!canvasPermissions.isAnalyticsAllowed) {
      setData(mockExplorerData);
      return;
    }

    fetchData();

    setIsInitialized(true);

    return () => {
      document.removeEventListener(PR_EVENT_MIDDLE_MOUSE_DOWN, onMiddleMouseDown, false);
    };
  }, []);

  const fetchData = () => {
    setStatus(STATUS_LOADING);
    loadData().then((data) => {
      setData(data);
      setStatus(STATUS_SUCCESS);
    });
  };

  const onMiddleMouseDown = (e) => {
    if (textSearchInputRef.current) {
      textSearchInputRef.current.blur();
    }
  };

  const onRefreshDataClicked = () => {
    if (!isActionAllowed()) {
      return;
    }

    setStatus(STATUS_LOADING);
    setLoadMorePagination(DEFAULT_PAGINATION_STATE);
    setLoadMoreStatus(STATUS_SUCCESS);
    loadData(true).then((data) => {
      setData(data);
      setStatus(STATUS_SUCCESS);
    });
  };

  const getLoadMoreByKeyStatus = (key) => {
    return loadMoreKeyStatus[key] || STATUS_SUCCESS;
  };

  const getPaginationByKey = (key) => {
    return paginationByKey[key] || 1;
  };

  const isItemExpanded = (item) => {
    return expandedItems.includes(item.key);
  };

  const toggleSubItems = (item) => {
    const isExpanded = isItemExpanded(item);
    if (isExpanded) {
      setExpandedItems(expandedItems.filter((key) => key !== item.key));
    } else {
      setExpandedItems(expandedItems.concat(item.key));
    }
  };

  const onMoreActionClicked = (item) => {
    if (!isActionAllowed()) {
      return;
    }
    // selectedItem
    setIsParametersOpened(true);
    setSelectedItem(item);
  };

  const onMapItemClicked = (item, data) => {
    if (!isActionAllowed()) {
      return;
    }

    createItem(item, data);
  };

  const onConfirmAndMapClicked = (item, data, parametersData) => {
    const newValue = extractHitsWithFilter(item, data, parametersData);
    const modifiedItemData = cloneData(item);
    modifiedItemData.hits = newValue;
    createItem(modifiedItemData, data, parametersData);
  };

  const extractHitsWithFilter = (item, filterData, parametersData) => {
    if (filterData.length === 0) {
      // no filter
      return item.hits;
    } else if (filterData.length === 1) {
      const filterItem = filterData[0];
      // if filterItem.value is array
      if (Array.isArray(filterItem.value)) {
        return undefined; // the value can't be determined
      } else if (filterItem.value === '*') {
        // lets find the specific key
        const keyData = parametersData.attributes.find((itm) => itm.key === filterItem.key);
        return keyData ? keyData.hits : undefined;
      } else {
        // find sub key
        const keyData = parametersData.attributes.find((itm) => itm.key === filterItem.key);
        if (keyData && keyData.values && Array.isArray(keyData.values)) {
          const subKeyData = keyData.values.find((itm) => itm.value === filterItem.value);
          if (subKeyData) {
            return subKeyData.hits;
          }
        }
      }
    }
  };

  const onBulkMapClicked = () => {
    if (!isActionAllowed()) {
      return;
    }

    elementCreationShiftRef.current = 0;

    for (let i = 0; i < 10 && i < transformedData.length; i++) {
      const item = transformedData[i];
      createItem(item);
    }
  };

  const isActionAllowed = () => {
    if (!canvasPermissions.isAnalyticsAllowed) {
      return false;
    }

    if (!canvasPermissions.isActionsAllowed && activeTab === EXPLORER_TABS.EVENT) {
      return false;
    }

    return true;
  };

  const createItem = (selectedItem, data = []) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }

    const panelSize = MainStorage.getLeftPanelSize();
    const spacing = 10;
    const shiftIndex = elementCreationShiftRef.current;

    const x = panelSize.width + (window.innerWidth - panelSize.width) / 2 + shiftIndex * spacing;
    const y = panelSize.height / 2 + shiftIndex * spacing;

    const elementData = explorerDataHelper.createElementTemplate(selectedItem, data, activeSubTab);

    if (selectedItem.data_origin) {
      elementData.filterData = [
        { key: '__dataOrigin__', value: selectedItem.data_origin, operator: '=' },
      ];
    }

    commonSendEventFunction(RP_EVENT_CREATE_OBJECT, {
      position: { x, y },
      object: elementData,
      explorerType: elementData.type,
      isStepModalPinned: false,
    });

    elementCreationShiftRef.current += 1;

    if (elementCreationShiftRef.current > 20) {
      elementCreationShiftRef.current = 0;
    }
  };

  const onMoreSubActionClicked = (item, key) => {
    // selectedItem
    setIsParametersOpened(true);
    setSelectedItem({
      key,
      ...item,
    });
  };

  const onCloseParametersClicked = () => {
    setIsParametersOpened(false);
  };

  const onSubTabChanged = (subTab) => {
    if (!canvasPermissions.isAnalyticsAllowed) {
      return;
    }
    const titleRow = subTabsTitles[subTab.id];
    setTitleRow(titleRow);

    // close paramters window
    setIsParametersOpened(false);

    // scroll to top
    if (scrollContentRef.current) {
      scrollContentRef.current.scrollTop = 0;
    }
  };

  // Switching tabs
  const activateTab = (type) => {
    if (!canvasPermissions.isAnalyticsAllowed) {
      return;
    }
    // MAP subtab changed
    setActiveTab(type);
    setIsDisabledMapTab(false);
    focusTextSearch();

    const subTabs = explorerSubTabs[type];
    setSubTabs(subTabs);
    setActiveSubTab(subTabs[0]);
  };

  // Searching
  const focusTextSearch = () => {
    textSearchInputRef.current.focus();
  };

  const onSearchChange = (e) => {
    setTextSearch(e.target.value);
  };

  const onSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      getSearchResults();
    }
  };

  const onEnterBtnClicked = () => {
    getSearchResults();
  };

  const getSearchResults = () => {
    if (!isActionAllowed()) {
      return;
    }

    const pagination = { page: 1, limit: PAGINATION_LIMIT };

    setData(null);
    setStatus(STATUS_LOADING);
    setLoadMorePagination(DEFAULT_PAGINATION_STATE);
    setLoadMoreStatus(STATUS_SUCCESS);
    loadData(true, pagination, textSearch).then((data) => {
      setData(data);
      setStatus(STATUS_SUCCESS);
    });
  };

  const onSubTabClick = (id) => {
    const tab = subTabs.find((tab) => tab.id === id);
    setActiveSubTab(tab);
  };

  const transformItemsData = (data) => {
    if (!data) {
      return [];
    }

    let result = [];

    switch (activeSubTab.id) {
      case SUB_TAB.CUSTOM_PARAMS: // sources
        result = getCustomParams(data);
        break;
      case SUB_TAB.ALL_PAGES: // pages
        result = getAllPages(data);
        break;
      case SUB_TAB.CUSTOM_ACTIONS: // events
        result = getAllActions(data);
        break;
      case SUB_TAB.ALL_REFERING_URLS:
        result = getAllReferingUrls(data);
        break;
      default:
        break;
    }

    return result;
  };

  const getCustomParams = (data) => {
    return [].concat(
      data.page_parameters.common_parameters,
      data.page_parameters.custom_parameters,
    );
  };

  const getAllPages = (data) => {
    return data.next_pages_all.list.slice().filter((item) => item.is_external === false);
  };

  const getAllReferingUrls = (data) => {
    return data.referrers_all.list.slice();
  };

  const getAllActions = (data) => {
    if (!canvasPermissions.isActionsAllowed) {
      return mockExplorerActionsData;
    }

    const list = data.next_actions_all.list.slice();

    // iterate over the list and check if they are purchase, meeting, deal or form
    list.forEach((item) => {
      if (item.name === COMMERCE_ACTION_NAME) {
        item.isPurchase = true;
        item.name = 'Purchase';
        item.icon = iconSmallLogo;
      } else if (item.name === MEETING_ACTION_NAME) {
        item.isMeeting = true;
      } else if (item.name === DEAL_ACTION_NAME) {
        item.isDeal = true;
      } else if (item.name === FORM_ACTION_NAME) {
        item.isForm = true;
      }
    });

    return list;
  };

  const loadData = (fetchFreshData = false, pagination = null, searchWord) => {
    return explorerDataHelper.loadExplorerData(fetchFreshData, pagination, searchWord);
  };

  const onLoadMoreClicked = () => {
    // activeSubTab
    const currentPage = loadMorePagination[activeSubTab.id];
    const pagination = { page: currentPage + 1, limit: PAGINATION_LIMIT };
    requestData(undefined, pagination, data);
  };

  const requestData = (searchWord, pagination, oldData = null) => {
    if (loadMoreStatus === STATUS_LOADING) {
      return;
    }
    setLoadMoreStatus(STATUS_LOADING);

    loadData(true, pagination, searchWord).then((resultData) => {
      setLoadedData(resultData, pagination, oldData);
    });
  };

  const setLoadedData = (resultData, pagination, oldData) => {
    const newData = !oldData
      ? resultData
      : {
          ...oldData,
          next_pages_all: {
            list: oldData.next_pages_all.list.concat(resultData.next_pages_all.list),
            has_more: resultData.next_pages_all.has_more,
          },
          next_actions_all: {
            list: oldData.next_actions_all.list.concat(resultData.next_actions_all.list),
            has_more: resultData.next_actions_all.has_more,
          },
          referrers_all: {
            list: oldData.referrers_all.list.concat(resultData.referrers_all.list),
            has_more: resultData.referrers_all.has_more,
          },
        };

    const newPagination = {
      ...loadMorePagination,
      [activeSubTab.id]: pagination.page,
    };
    setLoadMorePagination(newPagination);

    setData(newData);
    setLoadMoreStatus(STATUS_SUCCESS);
  };

  const onUpdateClicked = () => {
    let url = '';
    if (canvasPermissions.isEligibleForFreeTrial) {
      // start a free trial
      url = `${process.env.REACT_APP_CURRENT_APP_URL}/login?next=performance-business-trial`;
    } else {
      url = `${process.env.REACT_APP_CURRENT_APP_URL}/dashboard/settings/my-subscriptions?h=performance-business`;
    }
    window.open(url);
  };

  const onActionsUpdateClicked = () => {
    const url = `${process.env.REACT_APP_CURRENT_APP_URL}/dashboard/settings/my-subscriptions?h=performance-business`;
    window.open(url);
  };

  const onItemHasMoreClicked = (item) => {
    setLoadMoreKeyStatus({ ...loadMoreKeyStatus, [item.key]: STATUS_LOADING });

    const preSelectedData = [{ key: item.key, value: '*', operator: '=' }];

    const pageNumber = getPaginationByKey(item.key) + 1;

    explorerDataHelper.getAttributeExplorerData({
      preSelectedData,
      attributeKey: item.key,
      callback: (result) => {
        const resultData = result.common_parameters[0] || result.custom_parameters[0];

        const page_parameters = cloneData(data.page_parameters);
        let parameter = page_parameters.common_parameters.find((o) => o.key === item.key);

        // try to find in custom parameters
        if (!parameter) {
          parameter = page_parameters.custom_parameters.find((o) => o.key === item.key);
        }

        if (parameter) {
          if (resultData) {
            parameter.values = parameter.values.concat(resultData.values);
            parameter.has_more = resultData.has_more;

            if (resultData.values.length < PAGINATION_LIMIT) {
              parameter.has_more = false;
            }
          } else {
            parameter.has_more = false;
          }
        }

        const newData = {
          ...data,
          page_parameters,
        };

        setData(newData);

        setLoadMoreKeyStatus({ ...loadMoreKeyStatus, [item.key]: STATUS_SUCCESS });
        setPaginationByKey({ ...paginationByKey, [item.key]: pageNumber });
      },
      pageNumber,
    });
  };

  const checkHasMore = () => {
    // Note: page parameters , common/custom are not paginated
    if (activeTab === EXPLORER_TABS.PAGE) {
      return data && data.next_pages_all && data.next_pages_all.has_more;
    } else if (activeTab === EXPLORER_TABS.EVENT) {
      return data && data.next_actions_all && data.next_actions_all.has_more;
    } else if (
      activeTab === EXPLORER_TABS.SOURCE &&
      activeSubTab.id === SUB_TAB.ALL_REFERING_URLS
    ) {
      return data && data.referrers_all && data.referrers_all.has_more;
    }

    return false;
  };

  const transformedData = transformItemsData(data);
  const hasLoadMore = checkHasMore();

  return (
    <>
      <div className={styles.SearchSection}>
        {iconSearch}
        <input
          placeholder="Search"
          autoFocus={true}
          ref={textSearchInputRef}
          maxLength="512"
          className={styles.Search}
          value={textSearch}
          onChange={onSearchChange}
          onKeyUp={onSearchKeyUp}
          type="text"
        />

        <div onClick={onEnterBtnClicked} className={styles.EnterBtn}>
          Enter
        </div>
        <div className={styles.Separator}></div>

        <div ref={refreshBtnRef} onClick={onRefreshDataClicked} className={styles.Refresh}>
          <Tooltip label="Refresh" position={TOOLTIP_POSITIONS.TOP}>
            {iconRefreshBtn}
          </Tooltip>
        </div>
      </div>

      {/* Tabs Section */}
      <div className={styles.TopSectionIcon}>
        {explorerTabs.map((tab) => (
          <MappingTabButton
            key={tab.type}
            icon={tab.icon}
            title={tab.title}
            type={tab.type}
            isActive={true}
            onClick={activateTab}
            activeMapTab={activeTab}
          />
        ))}
      </div>

      <div className={styles.TopSectionIcon}>
        {subTabs.map((tab) => (
          <SubTabButton
            key={tab.id}
            title={tab.title}
            id={tab.id}
            onClick={onSubTabClick}
            activeTab={activeSubTab}
          />
        ))}
      </div>

      <div className={styles.ItemsBlock}>
        <div className={cls(styles.Item, styles.ItemTitles)}>
          <div className={styles.ItemTitleUrl}>{titleRow[0]}</div>
          <div className={styles.ItemTitleHits}>{titleRow[1]}</div>
          <div className={styles.ItemTitleActions}>
            <Tooltip label="Bulk add the top 10 elements">
              <div className={styles.BulkAddBtn} onClick={onBulkMapClicked}>
                {addBulkIcon}
              </div>
            </Tooltip>
          </div>
        </div>
        <When condition={status === STATUS_SUCCESS}>
          <div ref={scrollContentRef} className={styles.ItemsWrapper}>
            <When
              condition={
                activeTab === EXPLORER_TABS.SOURCE &&
                activeSubTab.id === SUB_TAB.ALL_REFERING_URLS &&
                transformedData.length !== 0
              }
            >
              <ItemRow
                item={{
                  hits: data ? data.direct_traffic_all : 0,
                  name: 'Direct Traffic',
                  integrationType: IntegrationTypes.DIRECT_TRAFFIC,
                }}
                onMapItemClicked={onMapItemClicked}
                hideMore={true}
                canvasPermissions={canvasPermissions}
              />
            </When>
            {transformedData.map((item, index) => (
              <React.Fragment key={index}>
                <ItemRow
                  item={item}
                  isItemExpanded={isItemExpanded}
                  toggleSubItems={toggleSubItems}
                  onMapItemClicked={onMapItemClicked}
                  onMoreActionClicked={onMoreActionClicked}
                  canvasPermissions={canvasPermissions}
                />
                {item.values && isItemExpanded(item) && (
                  <div className={styles.ItemSubWrap}>
                    {item.values.map((itm, index) => (
                      <div key={index} className={styles.Item}>
                        <div className={styles.ItemMain}>
                          <div className={styles.ItemUrl}>{itm.value}</div>
                          <div className={styles.ItemHits}>{itm.hits}</div>
                          <div className={styles.ItemActions}>
                            <div
                              onClick={(e) => {
                                const filterData = [
                                  { key: item.key, value: itm.value, operator: '=' },
                                ];
                                onMapItemClicked(itm, filterData);
                              }}
                              className={styles.ItemActionPlus}
                            >
                              {circlePlusIcon}
                            </div>
                            <div
                              onClick={(e) => onMoreSubActionClicked(itm, item.key)}
                              className={styles.ItemActionMore}
                            >
                              {moreActionsIcon}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <When condition={item.has_more}>
                      <div className={styles.MessageSection}>
                        <div
                          onClick={() => onItemHasMoreClicked(item)}
                          className={styles.LoadMoreButton}
                        >
                          <When condition={getLoadMoreByKeyStatus(item.key) === STATUS_LOADING}>
                            Loading ...
                          </When>
                          <When condition={getLoadMoreByKeyStatus(item.key) === STATUS_ERROR}>
                            {iconWarrning} Try Again
                          </When>
                          <When condition={getLoadMoreByKeyStatus(item.key) === STATUS_SUCCESS}>
                            Load More
                          </When>
                        </div>
                      </div>
                    </When>
                  </div>
                )}
              </React.Fragment>
            ))}

            <When condition={hasLoadMore}>
              <div className={styles.MessageSection}>
                <div onClick={onLoadMoreClicked} className={styles.LoadMoreButton}>
                  <When condition={loadMoreStatus === STATUS_LOADING}>Loading ...</When>
                  <When condition={loadMoreStatus === STATUS_ERROR}>{iconWarrning} Try Again</When>
                  <When condition={loadMoreStatus === STATUS_SUCCESS}>Load More</When>
                </div>
              </div>
            </When>
          </div>
        </When>
        <When condition={status === STATUS_LOADING}>
          <div className={styles.Loading}>Loading...</div>
        </When>
        <When condition={status === STATUS_ERROR}>
          <div className={styles.Error}>Error</div>
        </When>
        <When condition={transformedData.length === 0 && status === STATUS_SUCCESS}>
          <div className={styles.NoData}>No Data</div>
        </When>
        <When condition={!canvasPermissions.isAnalyticsAllowed}>
          <div className={styles.UpsellBlock}>
            <div className={styles.EmptyBlock}></div>
            <div className={styles.UpsellMessage}>
              <When condition={canvasPermissions.isEligibleForFreeTrial}>
                <ExplorerFreeTrial onBtnClicked={onUpdateClicked} />
              </When>

              <When condition={!canvasPermissions.isEligibleForFreeTrial}>
                <ExplorerUpgrade onBtnClicked={onUpdateClicked} />
              </When>
            </div>
          </div>
        </When>

        <When condition={!canvasPermissions.isActionsAllowed && activeTab === EXPLORER_TABS.EVENT}>
          <div className={styles.UpsellBlock}>
            <div className={styles.EmptyBlock}></div>
            <div className={styles.UpsellMessage}>
              <ExplorerActionsUpgrade onBtnClicked={onActionsUpdateClicked} />
            </div>
          </div>
        </When>
      </div>

      <When condition={isParametersOpened}>
        <ParametersBlock
          funnel={funnel}
          activeTab={activeTab}
          activeSubTab={activeSubTab}
          selectedItem={selectedItem}
          onCloseClicked={onCloseParametersClicked}
          onConfirmAndMapClicked={onConfirmAndMapClicked}
        />
      </When>
    </>
  );
};

const MappingTabButton = ({ type, title, icon, isActive, onClick, activeMapTab }) => {
  return (
    <button
      className={cls(styles.Chip, {
        [styles.Active]: isActive && activeMapTab === type,
      })}
      onClick={() => onClick(type)}
    >
      <span className={styles.ChipIcon}>{icon}</span>
      <span className={styles.ChipText}>{title}</span>
    </button>
  );
};

const SubTabButton = ({ id, title, onClick, activeTab }) => {
  return (
    <button
      className={cls(styles.SubTab, {
        [styles.SubTabActive]: activeTab.id === id,
      })}
      onClick={() => onClick(id)}
    >
      <span className={styles.SubTabText}>{title}</span>
    </button>
  );
};

//NOTE! The same lock needs to also be impmented in the ExplorerTab.jsx
// see also filterObjects in Header.jsx ,
// and possibly Menus.jsx if you wanna block the menu items
const checkLockedItems = (item, canvasPermissions) => {
  if (!canvasPermissions.purchaseTrackingAllowed && !!item.isPurchase) {
    return true;
  } else if (!canvasPermissions.calendarTrackingAllowed && !!item.isMeeting) {
    return true;
  } else if (!canvasPermissions.dealTrackingAllowed && !!item.isDeal) {
    return true;
  } else if (!canvasPermissions.formTrackingAllowed && !!item.isForm) {
    return true;
  }

  if (PermissionHelper.isDataOriginBlocked(item.data_origin, canvasPermissions)) {
    return true;
  }

  return false;
};

const ItemRow = ({
  item,
  isItemExpanded,
  toggleSubItems,
  onMapItemClicked,
  onMoreActionClicked,
  hideMore = false,
  canvasPermissions,
}) => {
  return (
    <UILock isLocked={checkLockedItems(item, canvasPermissions)}>
      <div className={styles.Item}>
        <div className={styles.ItemMain}>
          {item.values && (
            <div onClick={() => toggleSubItems(item)} className={styles.ItemArrow}>
              {item.values && isItemExpanded(item) ? arrowExpandedIcon : arrowCollapsedIcon}
            </div>
          )}
          <div className={styles.ItemUrl}>
            <When condition={item.icon}>
              <span className={styles.ItemIcon}>{item.icon}</span>
            </When>
            <span
              onClick={() => {
                if (item.values && window.getSelection().toString().length === 0) {
                  toggleSubItems(item);
                }
              }}
            >
              {item.key || item.url || item.name}
            </span>
          </div>
          <div className={styles.ItemHits}>{item.hits}</div>
          <div className={styles.ItemActions}>
            <div onClick={(e) => onMapItemClicked(item)} className={styles.ItemActionPlus}>
              {circlePlusIcon}
            </div>
            <When condition={!hideMore}>
              <div onClick={(e) => onMoreActionClicked(item)} className={styles.ItemActionMore}>
                {moreActionsIcon}
              </div>
            </When>
          </div>
        </div>
      </div>
    </UILock>
  );
};
