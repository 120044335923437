import { createSlice } from '@reduxjs/toolkit';
import RequestService from 'react-project/Helpers/RequestService';
import { RP_EVENT_ERROR_MESSAGE } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { ShareTabs } from './constants';
import _ from 'lodash';

const requestService = new RequestService();
const initialState = {
  isCanvasSharePublic: null,
  isCanvasViewOnly: null,
  isPasswordProtected: null,
  isLibrarySharePublic: null,
  isModalOpen: false,
  currentTab: ShareTabs.SHARING_LINK,
  hasFormError: false,

  preEnabled: false,
  showAlert: false,
  alertMessage: '',

  sharedDescriptionDefault: {
    description: null,
    videoLink: null,
  },
  sharedDescription: {
    description: null,
    videoLink: null,
  },
  libraryDescriptionDefault: {
    industry: null,
    offerType: null,
    purpose: null,
  },
  hasFormValidationError: false,
  formDataLoading: true,
  hasUnsavedChanges: false,
};

export const shareCanvasSlice = createSlice({
  name: 'shareCanvas',
  initialState,
  reducers: {
    setSharedOrLibraryDescription: (prevState, action) => {
      /**
       * @param {Object} libraryProps - Object containing the library properties to be updated
       * @param {Object} sharedProps - Object containing the shared properties to be updated
       * @param {string} actionType - actionType of description to be updated ('default' or 'update')
       */
      let base = {
        ...prevState,
        formDataLoading: false,
      };
      const { libraryProps = {}, sharedProps, actionType = 'update' } = action.payload;
      const { enabled, ...rest } = libraryProps;
      let libraryName = 'libraryDescription';
      let sharedName = 'sharedDescription';
      if (actionType === 'setDefault') {
        libraryName = 'libraryDescriptionDefault';
        sharedName = 'sharedDescriptionDefault';
      }
      if (sharedProps) {
        base[sharedName] = {
          ...prevState[sharedName],
          ...sharedProps,
        };
      }
      if (Object.keys(libraryProps).length > 0) {
        base['isLibrarySharePublic'] = enabled;
        base[libraryName] = {
          ...prevState[libraryName],
          ...rest,
        };
      }
      return base;
    },
    setIsCanvasSharePublic: (prevState, action) => ({
      ...prevState,
      isCanvasSharePublic: action.payload,
    }),
    setIsCanvasViewOnly: (prevState, action) => ({
      ...prevState,
      isCanvasViewOnly: action.payload,
    }),
    setIsPasswordProtected: (prevState, action) => ({
      ...prevState,
      isPasswordProtected: action.payload,
    }),
    setIsLibrarySharePublic: (prevState, action) => ({
      ...prevState,
      isLibrarySharePublic: action.payload,
    }),
    setIsCanvasShareModalOpen: (prevState, action) => ({
      ...prevState,
      isModalOpen: action.payload,
    }),
    setCurrentTab: (prevState, action) => ({
      ...prevState,
      currentTab: action.payload,
    }),
    setHasFormError: (prevState, action) => ({
      ...prevState,
      hasFormError: action.payload,
    }),
    setShowAlert: (prevState, action) => ({
      ...prevState,
      showAlert: action.payload,
    }),
    setAlertMessage: (prevState, action) => ({
      ...prevState,
      alertMessage: action.payload,
    }),
    setPreEnabled: (prevState, action) => ({
      ...prevState,
      preEnabled: action.payload,
    }),
  },
});

export const {
  setIsCanvasSharePublic,
  setIsCanvasViewOnly,
  setIsPasswordProtected,
  setIsLibrarySharePublic,
  setIsCanvasShareModalOpen,
  setCurrentTab,
  setHasFormError,
  setShowAlert,
  setAlertMessage,
  setPreEnabled,
  setSharedOrLibraryDescription,
} = shareCanvasSlice.actions;

export const canvasSharing = shareCanvasSlice.reducer;

/*
  Redux Thunk actions below
*/

export const asyncFetchSharedCanvasLibrary = (reqObj) => async (dispatch, getState) => {
  /**
   * @param {Object} reqObj - Object containing the request parameters
   * reqObj.actionType - actionType of description to be updated ('setDefault' or 'update')
   */
  try {
    const { actionType = 'update' } = reqObj;

    const resp = await requestService.getSharedAndLibraryCanvas(getState().funnels.currentFunnelId);
    let altFetch;
    let arg;
    const { success } = resp;
    if (!success && resp.err?.response?.status === 404) {
      altFetch = await requestService.fetchFunnelRequest(getState().funnels.currentFunnelId);
      if (altFetch.success) {
        const { data } = altFetch;
        arg = {
          sharedProps: {
            description: data.description,
            videoLink: data.video_link,
          },
          libraryProps: {
            enabled: false,
          },
          actionType,
        };
      }
    } else if (success) {
      const { data } = resp;
      arg = {
        sharedProps: {
          description: data.funnel.description,
          videoLink: data.funnel.video_link,
        },
        libraryProps: {
          enabled: data.enabled,
          industry: data.industry,
          offerType: data.offer_type,
          purpose: data.purpose,
        },
        actionType,
      };
    }

    if (arg) {
      dispatch(setSharedOrLibraryDescription(arg));
    }
  } catch (err) {
    commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
      errorMSG: 'Something went wrong. Please refresh your browser.',
    });
  }
};

export const asyncUpdateSharedCanvasLibrary = (reqObj) => async (dispatch, getState) => {
  /**
   * @param {Object} reqObj - Object containing the request parameters
   * reqObj.props - Object containing the properties to be updated
   * reqObj.props.library - library properties to be updated
   * reqObj.props.shared - shared properties to be updated
   * reqObj.props.isNewToLibrary - boolean to indicate if the funnel is new to the library and requires a POST request
   * reqObj.actionType - actionType of description to be updated ('setDefault' or 'update')
   */
  try {
    const { actionType = 'update', props = {}, isNewToLibrary } = reqObj;
    // Logic to check props for containing library and/or shared properties
    const promises = [];
    if (Object.keys(props).length === 0) {
      return;
    }

    const apiDataScaffold = (data = {}) => ({
      data: {
        attributes: {
          ...data,
        },
      },
    });

    const convertToSnakeCase = (obj) => {
      const newObj = {};
      Object.keys(obj).forEach((key) => {
        newObj[_.snakeCase(key)] = obj[key];
      });
      return newObj;
    };

    if (props.library) {
      if (isNewToLibrary) {
        const { library, funnel } = props;
        const formattedLibrary = { ...convertToSnakeCase(library), funnel };
        promises.push(requestService.newLibraryCanvas(formattedLibrary));
      } else {
        const { library } = props;
        const formattedLibrary = convertToSnakeCase(library);
        promises.push(
          requestService.setLibraryCanvas(
            getState().funnels.currentFunnelId,
            apiDataScaffold(formattedLibrary),
          ),
        );
      }
    }
    if (props.shared) {
      const { shared } = props;
      const formattedShared = convertToSnakeCase(shared);
      promises.push(
        requestService.setSharedCanvas(
          getState().funnels.currentFunnelId,
          apiDataScaffold(formattedShared),
        ),
      );
    }

    const allPromises = await Promise.all(promises);
    let updatedDefaults = {};
    allPromises.forEach((resp) => {
      if (resp.err || resp.error) {
        throw 'Error updating shared or library description';
      }
      if (resp.success === false) {
        return;
      }
      if (resp.data?.type === 'funnel') {
        updatedDefaults['sharedProps'] = {
          description: resp.data.attributes.description,
          videoLink: resp.data.attributes.video_link,
        };
      } else {
        const { enabled, industry, offer_type, purpose } = resp.data;
        updatedDefaults['libraryProps'] = {
          enabled,
          industry,
          offerType: offer_type,
          purpose,
        };
      }
    });
    if (Object.keys(updatedDefaults).length === 0) {
      return;
    }
    updatedDefaults['actionType'] = 'setDefault';
    dispatch(setSharedOrLibraryDescription(updatedDefaults));
    return { success: true };
  } catch (err) {
    console.log('DISPATCH ERROR: ', err);
    commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
      errorMSG: 'Something went wrong. Please refresh your browser.',
    });
    return { error: err };
  }
};

export const asyncFetchIsLinkPrivate = (reqObj) => async (dispatch, getState) => {
  const { funnelId } = reqObj;
  try {
    const resp = await requestService.getIsPrivate(funnelId);
    if (!resp.success) {
      dispatch(setIsCanvasSharePublic(null));
    }
    let isCanvasSharePublic;
    if (resp.is_private === null) {
      isCanvasSharePublic = null;
    } else {
      isCanvasSharePublic = !resp.is_private;
    }
    dispatch(setIsCanvasSharePublic(isCanvasSharePublic));
  } catch (err) {
    commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
      errorMSG: 'Something went wrong. Please refresh your browser.',
    });
  }
};

export const asyncSetIsLinkPrivate = (reqObj) => async (dispatch, getState) => {
  const { abortController, isCanvasSharePublic } = reqObj;
  try {
    const funnelId = getState().funnels.currentFunnelId;
    const resp = await requestService.setIsPrivate(
      funnelId,
      { is_private: !isCanvasSharePublic },
      abortController,
    );
    dispatch(setIsCanvasSharePublic(!resp.is_private));
  } catch (err) {
    commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
      errorMSG: 'Something went wrong. Please refresh your browser.',
    });
  }
};

export const asyncFetchCanvasHasViewOnly =
  ({ funnelId }) =>
  async (dispatch, getState) => {
    try {
      const resp = await requestService.validateReadOnlyLink(funnelId);
      if (resp.success) {
        dispatch(setIsCanvasViewOnly(true));
        dispatch(setIsPasswordProtected(!!resp.data.password));
      }
    } catch (err) {
      commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
        errorMSG: 'Something went wrong. Please refresh your browser.',
      });
    }
  };

export const asyncSetIsCanvasViewOnly =
  ({ isCanvasViewOnly }) =>
  async (dispatch, getState) => {
    try {
      const funnelId = getState().funnels.currentFunnelId;

      if (isCanvasViewOnly) {
        requestService.createReadOnlyLink(funnelId, null);
        dispatch(setIsCanvasViewOnly(true));
      } else {
        requestService.deleteReadOnlyLink(funnelId);
        dispatch(setIsCanvasViewOnly(false));
      }
    } catch (err) {
      commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
        errorMSG: 'Something went wrong. Please refresh your browser.',
      });
    }
  };

export const asyncUpdateCanvasViewOnly =
  ({ password }) =>
  async (dispatch, getState) => {
    try {
      const funnelId = getState().funnels.currentFunnelId;
      return requestService.updateReadOnlyLink(funnelId, password);
    } catch (err) {
      commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
        errorMSG: 'Something went wrong. Please refresh your browser.',
      });
    }
  };
