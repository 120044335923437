import isEmpty from 'lodash/isEmpty';
import SharedElementHelpers from 'shared/SharedElementHelpers';

const hasTackingData = (el) => {
  if (el) {
    if (SharedElementHelpers.IsSource(el)) {
      if (isEmpty(el.filterData) && isEmpty(el.trackingURL)) {
        return false;
      }
    }

    if (SharedElementHelpers.IsPage(el)) {
      if (isEmpty(el.url)) {
        return false;
      }
    }

    return true;
  }

  return true;
};

export const transformCanvasEntities = ({ dataObjs, data, integratedSources, costs }) => {
  if (data) {
    const { canvas_entities } = data;

    for (let key in canvas_entities) {
      const isIntegrated = integratedSources.some((el) => el.ID === key);
      if (isIntegrated) {
        canvas_entities[key].is_integrated_source = true;
        const cost = costs.find((el) => el.stepId === key);
        if (cost !== undefined) {
          canvas_entities[key].cost = cost.cost;
        } else {
          canvas_entities[key].cost = undefined;
        }
      }
    }

    return canvas_entities;
  }
};
