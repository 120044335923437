import React, { useEffect, useRef, useState } from 'react';
import { iconVideo } from 'react-project/assets/Icons';
import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';
import { StyleButton } from '../StyleButton/StyleButton';
import styles from './VideoAdd.module.scss';
import { useSelector } from 'react-redux';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';

export const VideoAdd = ({ editorState, onChange, modifier }) => {
  const [url, setURL] = useState('');
  const [open, setOpen] = useState(false);
  const inputRef = useRef();
  const canvasPermissions = useSelector(selectCanvasPermissions);

  useEffect(() => {
    if (open) {
      setURL('');
      inputRef.current.focus();
    }
  }, [open]);

  const openPopover = () => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }
    setOpen(true);
  };

  const addVideo = () => {
    onChange(modifier(editorState, { src: url }));
    setOpen(false);
  };

  const changeUrl = (e) => {
    setURL(e.target.value);
  };

  const onPointerOut = (e) => {
    setOpen(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setURL(inputRef.current.value);
      addVideo();
    }
  };

  const popoverClassName = open ? styles.addVideoPopover : styles.addVideoClosedPopover;
  return (
    <ClickOutsideCustomComponent ignoreClickOutside={false} onClickOutside={(e) => onPointerOut(e)}>
      <div className={styles.addVideo}>
        <StyleButton
          key="add-video-button"
          active={false}
          label={iconVideo}
          onToggle={openPopover}
        />
        <div className={popoverClassName}>
          <input
            ref={inputRef}
            type="text"
            placeholder="Paste the video url …"
            className={styles.addVideoInput}
            onChange={changeUrl}
            value={url}
            onKeyDown={handleKeyDown}
          />
          <button className={styles.addVideoConfirmButton} type="button" onClick={addVideo}>
            Add
          </button>
        </div>
      </div>
    </ClickOutsideCustomComponent>
  );
};
