import cls from 'classnames';
import React, { Component } from 'react';
import {
  VERTICAL_ALIGN_CENTER,
  ViewportConstrainer,
} from 'react-project/components/viewportConstrainer/ViewportConstrainer';
import { setNewCurrentStepLabel, setTextStyle } from 'react-project/redux/current-step/actions';
import { selectCurrentStep } from 'react-project/redux/current-step/selectors';
import textToolbarStyles from 'react-project/Toolbar/text-toolbar/TextToolbar.module.scss';
import styles from 'react-project/Toolbar/Toolbar.module.scss';
import { connect } from 'react-redux';
import {
  FONT_STYLE_TYPE,
  TextAlignConfig,
  TextColorPickerConfig,
  TextFontFamilyConfig,
  TextFontSizeConfig,
  TextFontStylesConfig,
} from 'shared/CSharedConstants';
import { RP_EVENT_TEXT_PROPERTY_CHANGED } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { ContextMenu } from './ContextMenu';
import { TextColorDropdown } from './text-toolbar/TextColorDropdown';
import { TextDropdown } from './text-toolbar/TextDropdown';
import { TextElement } from './text-toolbar/TextElements';
import { TextLinkDropdown } from './text-toolbar/TextLinkDropdown';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';

class CommonToolbar extends Component {
  constructor(props) {
    super(props);

    this.lineTypesMenuRef = React.createRef();

    this.state = {
      urlValue: this.props.currentStep.object.url,
      hyperlink: this.props.currentStep.object.hyperlink,
      isTextShape: this.props.currentStep.object.isTextShape,
      toolbarData: this.props.toolbarData,
      isGoalsOpenned: false,
    };
  }

  render() {
    const textChangeFontFamily = (value) => {
      this.props.updateTextStyle({ fontFamily: value.label });
      commonSendEventFunction(RP_EVENT_TEXT_PROPERTY_CHANGED, { fontFamily: value.label });
    };

    const textChangeFontSize = (value) => {
      this.props.updateTextStyle({ fontSize: value.label });
      commonSendEventFunction(RP_EVENT_TEXT_PROPERTY_CHANGED, { fontSize: value.label });
    };

    const textChangeAlign = (item) => {
      this.props.updateTextStyle({ align: item.value });
      commonSendEventFunction(RP_EVENT_TEXT_PROPERTY_CHANGED, { align: item.value });
    };

    const textChangeColor = (value) => {
      this.props.updateTextStyle({ color: value });
      commonSendEventFunction(RP_EVENT_TEXT_PROPERTY_CHANGED, { color: value });
    };

    const textChangeLink = (value) => {
      this.props.updateTextStyle({ url: value });
      commonSendEventFunction(RP_EVENT_TEXT_PROPERTY_CHANGED, { url: value });
    };

    const onHyperLinkChanged = (oldValue, newValue) => {
      commonSendEventFunction(RP_EVENT_TEXT_PROPERTY_CHANGED, {
        url: newValue,
        oldUrl: oldValue,
        isFinal: true,
      });
    };

    const textChangeProperty = (data) => {
      const updateData = {};
      const previousValue = this.props.currentStep.object.textStyle[data.type];
      updateData[data.type] = previousValue === data.style ? 'normal' : data.style;
      normalizeTextDecorationValue(updateData, data);
      this.props.updateTextStyle(updateData);
      commonSendEventFunction(RP_EVENT_TEXT_PROPERTY_CHANGED, updateData);
    };

    const normalizeTextDecorationValue = (updateData, data) => {
      if (data.type === FONT_STYLE_TYPE.DECORATION && updateData[data.type] === 'normal') {
        updateData[data.type] = 'none';
      }
    };

    const selectingTypeOfToolbar = () => {
      if (this.props.toolbarData && this.props.toolbarData.supportedLineTypes) {
        // Common toolbar for connection lines , it is shown in single and multi select.
        if (this.props.isRightMouse && !this.props.canvasPermissions.isReadonlyAccess) {
          return (
            <ViewportConstrainer relativeMouseBox={{ width: 10, height: 10 }}>
              <div style={{ position: 'absolute' }}>
                <ContextMenu
                  dropdownTrigger={false}
                  showLayerHierarchy={false}
                  showCopy={false}
                  showDuplicate={false}
                />
              </div>
            </ViewportConstrainer>
          );
        } else {
          return null;
        }
      } else if (this.props.multiSelect) {
        return null;
      } else if (
        this.props.currentStep.object.category === 'TEXT' &&
        !this.props.canvasPermissions.isReadonlyAccess
      ) {
        const { align, color, fontFamily, fontSize, fontStyle, fontWeight } =
          this.props.currentStep.object.textStyle;

        const hyperlink = this.props.currentStep.object.hyperlink;

        const currentFontFamilyConfig = TextFontFamilyConfig.find((el) => el.family === fontFamily);
        const currentTextAlign = TextAlignConfig.find((el) => el.label === align);

        return (
          <div className={styles[this.props.currentStep.object.type]}>
            <ViewportConstrainer
              verticalAlign={VERTICAL_ALIGN_CENTER}
              relativeRectangle={this.props.sectionStyle}
            >
              <div className={`${styles.Toolbar} ${styles.ToolbarMultiple}`}>
                <TextDropdown
                  label={fontFamily}
                  items={TextFontFamilyConfig.map((item) => ({
                    label: item.family,
                    className: textToolbarStyles[item.style],
                    value: item,
                  }))}
                  dropdownTriggerStyles={cls(textToolbarStyles[currentFontFamilyConfig.style])}
                  dropdownItemsStyle={textToolbarStyles.FontFamilyDropdown}
                  onSelect={textChangeFontFamily}
                />
                <TextDropdown
                  label={fontSize}
                  items={TextFontSizeConfig.map((item) => ({ label: item, value: item }))}
                  dropdownItemsStyle={textToolbarStyles.FontSizeDropdown}
                  onSelect={textChangeFontSize}
                />
                <TextColorDropdown
                  selectedValue={color}
                  onSelect={textChangeColor}
                  items={TextColorPickerConfig}
                />
                {TextFontStylesConfig.map((item, index) => (
                  <TextElement
                    key={item.style}
                    label={item.symbol}
                    onClick={() => {
                      textChangeProperty(item);
                    }}
                    isActive={item.style === fontStyle || item.style === fontWeight}
                    classNames={cls(textToolbarStyles[item.style], {
                      [textToolbarStyles['PaddingLeft-5']]:
                        index === TextFontStylesConfig.length - 1,
                      [textToolbarStyles['PaddingRight-5']]: index === 0,
                      [textToolbarStyles.ClearPadding]:
                        index !== 0 && index !== TextFontStylesConfig.length - 1,
                    })}
                    withBorder={index === TextFontStylesConfig.length - 1}
                  />
                ))}
                <TextDropdown
                  label={<span>{currentTextAlign.icon}</span>}
                  items={TextAlignConfig.map((item) => ({
                    label: (
                      <span
                        className={cls({
                          [textToolbarStyles.ActiveDropdownItem]: item.value === align,
                        })}
                      >
                        {item.icon}
                      </span>
                    ),
                    value: item.value,
                  }))}
                  dropdownItemsStyle={textToolbarStyles.TextAlignDropdown}
                  onSelect={textChangeAlign}
                />
                <TextLinkDropdown
                  selectedValue={hyperlink || ''}
                  onSelect={textChangeLink}
                  onHyperLinkChanged={onHyperLinkChanged}
                />
                <ContextMenu />
              </div>
            </ViewportConstrainer>
          </div>
        );
      }

      return null;
    };

    return <div>{selectingTypeOfToolbar()}</div>;
  }
}

function mapStateToProps(state) {
  return {
    currentStep: selectCurrentStep(state),
    canvasPermissions: selectCanvasPermissions(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSetNewCurrentStepLabel: (newValue) => dispatch(setNewCurrentStepLabel(newValue)),
    updateTextStyle: (value) => dispatch(setTextStyle(value)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommonToolbar);
