import { CommandStatus } from 'pixi-project/base/command-system/CommandManager';
import CommandAdd from 'pixi-project/base/command-system/commands/CommandAdd';
import CommandAddConnection from 'pixi-project/base/command-system/commands/CommandAddConnection';
import CommandDeleteConnection from 'pixi-project/base/command-system/commands/CommandDeleteConnection';
import CommandDeleteStep from 'pixi-project/base/command-system/commands/CommandDeleteStep';
import CommandRelocateConnection from 'pixi-project/base/command-system/commands/CommandRelocateConnection';
import CommandsBatch from 'pixi-project/base/command-system/CommandsBatch';
import { CControlPointTypes } from 'pixi-project/base/containers/CContainerConstants';
import ViewportCulling from 'pixi-project/base/culling/ViewportCulling';
import { EConnectionLineType } from 'pixi-project/base/joint/CConnectionConstants';
import MainStorage from 'pixi-project/core/MainStorage';
import Facade from 'pixi-project/Facade';
import { default as AppSignals, default as Signals } from 'pixi-project/signals/AppSignals';
import CSVExporter from 'pixi-project/utils/CSVExporter';
import ImageSnapshot from 'pixi-project/utils/ImageSnapshot';
import ConnectionContainer from 'pixi-project/view/joint/ConnectionContainer';
import ConnectionToCoordinates from 'pixi-project/view/joint/ConnectionToCoordinates';
import PhotoContainer from 'pixi-project/view/objects/PhotoContainer';
import ShapeEllipse from 'pixi-project/view/objects/ShapeEllipse';
import ShapeRectangle from 'pixi-project/view/objects/ShapeRectangle';
import ShapeTriangle, { ORIENTATION_DOWN } from 'pixi-project/view/objects/ShapeTriangle';
import StepContainer from 'pixi-project/view/objects/StepContainer';
import TextLabel from 'pixi-project/view/objects/Text/TextLabel';
import ReportSlide from 'pixi-project/view/reports/ReportSlide';
import ReportView from 'pixi-project/view/reports/ReportView';
import GoalsWidget from 'pixi-project/view/widgets/GoalsWidget';
import PeopleWidget from 'pixi-project/view/widgets/PeopleWidget';
import TopCountriesWidget from 'pixi-project/view/widgets/TopCountriesWidget';
import * as PIXI from 'pixi.js';
import { HEAP_EVENTS } from 'react-project/Constants/heapEvents';
import { DEFAULT_OPERATOR } from 'react-project/Toolbar/step-toolbar/LogicalOperators';
import { sendHeapTracking } from 'react-project/Util/HEAP.utilities';
import { track as trackCohesive } from 'react-project/Util/CohesiveTracking';
import {
  EElementCategories,
  EElementTypes,
  EExplorerConfigTypes,
  EStepConnectionPort,
} from 'shared/CSharedCategories';
import {
  ACTIVE_STATE_DRAW,
  EShapeTypes,
  EXPORTED_IMAGE_NAME,
  FILTER_TYPE_COMPARE_STEP,
  FILTER_TYPE_DEFAULT_STEP,
  IntegrationTypes,
  SELECTION_UPDATE_SOURCE_ANALYTICS,
} from 'shared/CSharedConstants';
import {
  PR_EVENT_COMMAND_ADD_EXECUTED,
  PR_EVENT_COMMAND_MOVE_EXECUTED,
  PR_EVENT_COMMAND_REMOVE_EXECUTED,
  PR_EVENT_COMMAND_RESHAPE_EXECUTED,
  PR_EVENT_COMMAND_SCALE_EXECUTED,
  PR_EVENT_COMMAND_SCALE_MOVE_EXECUTED,
  PR_EVENT_CONNECTION_IN_EMPTY_SPACE,
  PR_EVENT_CONNECTION_RELOCATED,
  PR_EVENT_DOWNLOAD_REPORT_CLICKED,
  PR_EVENT_LOOP_DETECTION,
  PR_EVENT_ON_PHOTO_CHANGED,
  PR_EVENT_REFRESH_RESPONSE,
  PR_EVENT_REMOVE_POINTER_JOINT,
  PR_EVENT_REPORT_BLOCKED_WARNING_POPUP_OPENED,
  PR_EVENT_REPORT_IMAGES_GENERATED,
  PR_EVENT_REPORT_WARNING_POPUP_OPENED,
  RP_EVENT_CREATE_OBJECT,
  RP_EVENT_ERROR_MESSAGE,
  RP_EVENT_INFO_MESSAGE,
  RP_EVENT_REPORT_ADDED,
  RP_EVENT_REPORT_REMOVED,
  RP_EVENT_SEND_REPORT_CLICKED,
} from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import SharedElementHelpers from 'shared/SharedElementHelpers';
import TrafficData from 'shared/TrafficData';
import CanvasChecklist from './CanvasChecklist';
import { LayerType } from 'react-project/LayersMenu/LayerType';
import BaseSignals from 'pixi-project/base/signals/BaseSignals';
import FlowController from 'pixi-project/view/joint/FlowController';
import TrendsWidget from 'pixi-project/view/widgets/TrendsWidget';
import { FF_TRENDS } from 'shared/FeatureFlags';
import { TEXT_TUTORIAL_UPLOAD_NOT_SUPPORTED } from 'react-project/Constants/texts';
import LoopDetector from 'pixi-project/utils/LoopDetector';
import { debounce } from 'lodash';
import ForecastingController from './ForecastingController';
import ForecastingWidget from 'pixi-project/view/widgets/ForecastingWidget';

export default class SceneManager {
  constructor(controller) {
    this.controller = controller;
    this.selectionManager = null;
    this.commandManager = null;
    this.canvasChecklist = new CanvasChecklist();

    this.iconsContainer = controller.iconsContainer;
    this.jointsContainer = controller.jointsContainer;
    this.mesh = controller.mesh;

    this.canvasDataProjectId = null;
    this.canvasDataFunnelId = null;
    this.canvasDataSource = null;
    this.canvasDataVersion = null;
    this.canvasDataNotes = null;

    this.objects = [];
    this.joints = [];
    this.objectCreated = null;
    this.iconA = null;
    this.iconB = null;
    this.pointerJoint = null;
    this._addStepOnConnectionEnd = false;
    this.stateData = { objects: [], joints: [] };
    this.reportView = null; // just a reference to optimize work with reports
    this.isSceneLocked = false;
    this.customIcons = [];
    this.compareRange = { from: 0, to: 0 };

    this.flowController = new FlowController(this.joints);

    this.heapRecordedNumberOfElements = {
      numberOfSteps: 0,
      numberOfConnections: 0,
    };

    this.importer = null;
    this.culling = new ViewportCulling(window.app.viewport);

    this.isAnalyticsVisible = false;
    this.isForecastingVisible = false;

    const width = window.app.viewport.screenWidth;
    const height = window.app.viewport.screenHeight;
    this.snapShot = new ImageSnapshot(window.app.renderer, width, height);
    this.graphData = null;
    this.loopDetectionDebounce = debounce(this.onLoopDetection.bind(this), 0);
    this.forecastingController = new ForecastingController(this.objects, this.joints);

    document.addEventListener(RP_EVENT_CREATE_OBJECT, this.onCreateObject.bind(this), false);
    document.addEventListener(
      RP_EVENT_SEND_REPORT_CLICKED,
      this.onSendReportClicked.bind(this),
      false,
    );

    // handle culling events
    AppSignals.connectionMoved.add(this.onConnectionMoved, this);
    document.addEventListener(PR_EVENT_COMMAND_ADD_EXECUTED, this.onCommandAddExecuted.bind(this));
    document.addEventListener(
      PR_EVENT_COMMAND_REMOVE_EXECUTED,
      this.onCommandDeleteExecuted.bind(this),
    );
    document.addEventListener(
      PR_EVENT_COMMAND_MOVE_EXECUTED,
      this.onCommandMoveExecuted.bind(this),
    );
    document.addEventListener(
      PR_EVENT_COMMAND_RESHAPE_EXECUTED,
      this.onCommandReshapeExecuted.bind(this),
    );
    document.addEventListener(
      PR_EVENT_COMMAND_SCALE_EXECUTED,
      this.onCommandScaleExecuted.bind(this),
    );
    document.addEventListener(
      PR_EVENT_COMMAND_SCALE_MOVE_EXECUTED,
      this.onCommandScaleMoveExecuted.bind(this),
    );
    document.addEventListener(
      PR_EVENT_CONNECTION_RELOCATED,
      this.onCommandRelocateConnectionExecuted.bind(this),
    );
  }

  onMetaDataLoaded(projectId, funnelId, source, version, notes, canvasChecklistData) {
    this.canvasDataProjectId = projectId;
    this.canvasDataFunnelId = funnelId;
    this.canvasDataSource = source;
    this.canvasDataVersion = version;
    this.canvasDataNotes = notes;
    this.canvasChecklist.checklistData = canvasChecklistData;
  }

  onFunnelLoaded(objects, connections, funnelConfiguration) {
    this.addObjectsToScene(objects, connections);
    this.bindObjectsToReport();
    this.culling.addObjects(this.objects);
    this.culling.addObjects(this.joints);
    this.focusElements(funnelConfiguration);
    this.sendNumberOfElementsToHeap();

    // turn on data layers on load

    if (MainStorage.getCanvasPermissions().notesAllowed) {
      this.onNotesToggle(funnelConfiguration.visibleDataLayers[LayerType.NOTES]);
    }

    if (MainStorage.getCanvasPermissions().checklistAllowed) {
      this.onChecklistToggle(funnelConfiguration.visibleDataLayers[LayerType.CHECKLIST]);
    }

    // TODO what about mapping plan when we have forecasting enabled
    if (MainStorage.getCanvasPermissions().isAnalyticsAllowed) {
      this.isAnalyticsVisible = funnelConfiguration.visibleDataLayers[LayerType.NUMBERS];
      this.isForecastingVisible = funnelConfiguration.visibleDataLayers[LayerType.FORECASTING];
      this.toggleAnalyticsForecasting();
    }

    this.onFlowToggle(funnelConfiguration.visibleDataLayers[LayerType.FLOW]);

    this.controller.zoomUtility.fitToScreen();

    // Detect loops
    this.loopDetectionDebounce();
  }

  onNotesToggle(isVisible) {
    this.setBadgeVisibility(isVisible);
    window.app.needsRendering();
  }

  onChecklistToggle(isVisible) {
    this.setChecklistVisibility(isVisible);
    window.app.needsRendering();
  }

  onSendReportClicked() {
    const canvasPromises = this.getReportImages();
    Promise.all(canvasPromises).then((images) => {
      commonSendEventFunction(PR_EVENT_REPORT_IMAGES_GENERATED, images);
    });
  }

  onConnectionMoved(connection) {
    this.culling.updateObject(connection);
    connection.updateFlowPoints();
  }

  onCommandAddExecuted(e) {
    this.handleCullingByStatus(e.detail);
    this.handleReport(e.detail);

    for (let i = 0; i < this.objects.length; i++) {
      const element = this.objects[i];
      if (SharedElementHelpers.IsWidget(element) && element.type === EElementTypes.WIDGET_GOALS) {
        element.setWidgetData(this.analyticsData);

        for (let j = 0; j < e.detail.objects.length; j++) {
          const object = e.detail.objects[j];
          const stepId = object.id;
          if (
            element.widgetData &&
            !element.widgetData[stepId] &&
            object.analyticsManager &&
            object.analyticsManager.data
          ) {
            element.widgetData[stepId] = { hits: parseInt(object.analyticsManager.data.hits) };
          }
        }
        element.refreshDisplay();
      }
    }

    // Detect loops
    this.loopDetectionDebounce();
  }

  onCommandDeleteExecuted(e) {
    this.handleCullingByStatus(e.detail);
    this.handleReport(e.detail);

    for (let i = 0; i < this.objects.length; i++) {
      const element = this.objects[i];
      if (SharedElementHelpers.IsWidget(element) && element.type === EElementTypes.WIDGET_GOALS) {
        element.refreshDisplay();
      }

      if (SharedElementHelpers.IsWidget(element) && element.type === EElementTypes.WIDGET_TRENDS) {
        element.onDeleteCommandExecuted(e.detail);
      }
    }

    // Detect looops
    this.loopDetectionDebounce();
  }

  onCommandRelocateConnectionExecuted(e) {
    // also recalculate loop detection and update forecasting
    this.loopDetectionDebounce();
  }

  onLoopDetection() {
    const graphData = this.getGraphData();
    this.graphData = graphData;
    commonSendEventFunction(PR_EVENT_LOOP_DETECTION, graphData.loops);

    // also calculate forecasting data if visible
    if (MainStorage.isForecastingVisible()) {
      this.calculateForecastingData();
      this.controller.processAnalyticsData();
    }
  }

  onCommandMoveExecuted(e) {
    this.handleCullingByStatus(e.detail);
  }

  onCommandReshapeExecuted(e) {
    this.handleCullingByStatus(e.detail);
  }

  onCommandScaleExecuted(e) {
    this.handleCullingByStatus(e.detail);
  }

  onCommandScaleMoveExecuted(e) {
    this.handleCullingByStatus(e.detail);
  }

  handleCullingByStatus(data) {
    if (data.status === CommandStatus.ADD) {
      this.culling.addObjects(data.objects);
    } else if (data.status === CommandStatus.DELETE) {
      this.culling.removeObjects(data.objects);
    } else if (data.status === CommandStatus.UPDATE) {
      this.culling.updateObjects(data.objects);
    }
  }

  handleReport(data) {
    // check if there is a report
    const reportView = this.objectsContainReport(data.objects);
    if (reportView) {
      if (data.status === CommandStatus.ADD) {
        this.reportView = reportView;
        commonSendEventFunction(RP_EVENT_REPORT_ADDED);
      } else if (data.status === CommandStatus.DELETE) {
        this.reportView = null;
        commonSendEventFunction(RP_EVENT_REPORT_REMOVED);
      }
    }
  }

  objectsContainReport(objects) {
    for (let i = 0; i < objects.length; i++) {
      if (SharedElementHelpers.IsReport(objects[i])) {
        return objects[i];
      }
    }
    return false;
  }

  /**
   * Returns an index of an element from the array
   * @param id
   * @param source Array of the elements to look into
   * @returns {number}
   */
  getElementIndexById(id, source) {
    for (let i = 0; i < source.length; i++) {
      if (source[i].id === id) {
        return i;
      }
    }

    return -1;
  }

  /**
   * Parse and use all data that we received from the server to load the funnel
   * @param e - server data
   */
  addObjectsToScene(objects, connections) {
    const importedObjects = [];

    // Parse data into internal format
    for (let i = 0; i < objects.length; i++) {
      const data = {
        detail: {
          object: {
            ...objects[i],
            id: objects[i].ID,
            src: objects[i].texturePath,
          },
        },
      };

      // Old shapes format support added on 26 04 2021
      // TODO It should be remove after some time
      // when we are sure that no more old shapes are in the system
      this.transformOldShapeFormat(objects[i]);
      this.transformOldShapeFormat(data.detail.object);

      // todo Refactor to allow an element to add his data depending on the element type
      if (SharedElementHelpers.IsStep(objects[i])) {
        if (typeof objects[i].filterData !== 'undefined') {
          data.detail.object.filterData = objects[i].filterData;
        }
        if (typeof objects[i].trackingURL !== 'undefined') {
          data.detail.object.trackingURL = objects[i].trackingURL;
        }
        // utm to filter transformation in order to support the old format
        this.utmToFilterData(objects[i], data.detail.object);
        if (typeof objects[i].useThumbnail !== 'undefined') {
          data.detail.object.useThumbnail = objects[i].useThumbnail;
        }
        if (typeof objects[i].thumbnailURL !== 'undefined') {
          data.detail.object.thumbnailURL = objects[i].thumbnailURL;
        }
      }

      let loadData = null;
      if (objects[i].category === EElementCategories.SHAPE) {
        loadData = objects[i].shapeData;
      }

      // Create elements
      this.onCreateObject(data, true, loadData);

      if (this.objectCreated) {
        if (objects[i].category === EElementCategories.TEXT) {
          this.objectCreated.wordWrapWidth = objects[i].wordWrapWidth || 0;

          if (objects[i].resolution) {
            this.objectCreated.textLabel.resolution = objects[i].resolution;
          }

          this.objectCreated.setText(objects[i].text);

          if (objects[i].textStyle) {
            this.objectCreated.setStyles(objects[i].textStyle);
          }
        }

        this.objectCreated.scale.set(objects[i].scaleX, objects[i].scaleY);
        importedObjects.push(this.objectCreated);

        if (objects[i].isFocused && objects[i].focusFilterTypes) {
          for (let j = 0; j < objects[i].focusFilterTypes.length; j++) {
            const focusFilterType = objects[i].focusFilterTypes[j];
            this.selectionManager.focusSelection.focusSingleElement(
              this.objectCreated,
              focusFilterType,
            );
          }
        }

        this.objectCreated.goal = data.detail.object.goal;
        this.objectCreated.notes = data.detail.object.notes;
        this.objectCreated.checklistData = data.detail.object.checklistData;
        this.objectCreated.forecastingData = data.detail.object.forecastingData || null;
        this.objectCreated.onNoteChanged();
        this.objectCreated.onLoaded(objects[i], this);
      }
    }

    this.objectCreated = null;

    // Create joins between objects
    for (let i = 0; i < connections.length; i++) {
      const connectionData = connections[i];

      // support old format for connection attachment points
      this.transformAttachmentPoints(connectionData.attachmentPointsLocation);
      const iconA = this.objects[this.getStepIndexById(connectionData.iconA_ID)];
      const iconB = this.objects[this.getStepIndexById(connectionData.iconB_ID)];

      if (!iconA || !iconB) {
        continue;
      }

      // check if objects are valid
      if (!SharedElementHelpers.IsStep(iconA) || !SharedElementHelpers.IsStep(iconB)) {
        continue;
      }

      const connectionType = connectionData.headAVisible ? 'in' : 'out';

      const connection = new ConnectionContainer(iconA, iconB, connectionData.ID, connectionData);
      connection.delegate = this;
      connection.goal = connectionData.goal;
      connection.init(connectionType);
      this.joints.push(connection);
      this.jointsContainer.addChild(connection);
      connection.forecastingData = connectionData.forecastingData || { percent: 100 }; // default value is 100% for all connections
      connection.analyticsManager.setCompareRange(this.compareRange);
      connection.onLoaded(connectionData);
    }

    this.selectionManager.focusSelection.drawAll();
    window.app.needsRendering();

    return importedObjects;
  }

  // This method is used to transform old shapes format to the new one
  // for backward compatibility
  transformAttachmentPoints(attachmentPoints) {
    if (attachmentPoints) {
      // 'auto' will now be 'flow'
      attachmentPoints.pointA =
        attachmentPoints.pointA === 'auto' ? CControlPointTypes.FLOW : attachmentPoints.pointA;
      attachmentPoints.pointB =
        attachmentPoints.pointB === 'auto' ? CControlPointTypes.FLOW : attachmentPoints.pointB;

      // 'null' will not be 'center'
      attachmentPoints.pointA = attachmentPoints.pointA || CControlPointTypes.CENTER;
      attachmentPoints.pointB = attachmentPoints.pointB || CControlPointTypes.CENTER;
    }
  }

  utmToFilterData(utmObject, filterObject) {
    //This method is here to transform any old format object to the new format
    if (typeof utmObject.utmData !== 'undefined') {
      if (!filterObject.filterData) {
        filterObject.filterData = [];
      }
      Object.keys(utmObject.utmData).forEach((key) => {
        filterObject.filterData.push({
          key: key,
          value: utmObject.utmData[key],
          operator: DEFAULT_OPERATOR,
        });
      });
    }
  }

  bindObjectsToReport() {
    const reportView = this.findReport();

    if (reportView) {
      let data = reportView.importData.data;

      // iterate for each slide
      for (let j = 0; j < data.length; j++) {
        const importData = data[j];
        let slide = null;

        if (reportView.slides.length <= j) {
          const isLimitReached = reportView.isLimitReached();
          slide = new ReportSlide(
            { hasRemoveButton: true, hasAddButton: !isLimitReached, label: reportView.getLabel() },
            reportView,
          );
          reportView.addSlide(slide);
        } else {
          slide = reportView.slides[j];
        }

        if (reportView.importData.styles) {
          slide.setStyle(reportView.importData.styles[j]);
        }

        const objects = [];

        let ids = importData.ids;
        for (let k = 0; k < ids.length; k++) {
          const id = ids[k];
          let step = this.getStepById(id);
          if (step) {
            objects.push(step);
          }
        }

        slide.addObjects(objects);
      }
    }
  }

  shouldDraw(isLoaded) {
    return (
      !isLoaded &&
      this.controller.inputEventController.toolbarActiveState === ACTIVE_STATE_DRAW &&
      !this.selectionManager.hasSelectedElements()
    );
  }

  onCreateObject(e, isLoaded = false, loadData = null) {
    if (!isLoaded) {
      e.detail.position.x *= 1 / window.app.scaleManager.aspectRatio;
      e.detail.position.y *= 1 / window.app.scaleManager.aspectRatio;

      if (this.reportView && this.reportView.isLocked) {
        const bounds = this.reportView.getBounds();
        if (bounds.contains(e.detail.position.x, e.detail.position.y)) {
          commonSendEventFunction(PR_EVENT_REPORT_BLOCKED_WARNING_POPUP_OPENED);
          return;
        }
      }
    }

    if (this.shouldDraw(isLoaded)) {
      // create an object when in drawing mode
      this.controller.inputEventController.onDrawObjectStart(e);
      return;
    }

    if (!isLoaded) {
      AppSignals.setOutEditMode.dispatch();
      if (!SharedElementHelpers.IsTextOrShapeElements(e.detail.object)) {
        Signals.elementChanged.dispatch();
      }
      window.app.needsRendering();
    }

    switch (e.detail.object.category) {
      case EElementCategories.STEP:
        this.objectCreated = this.createStep(e, isLoaded);
        let connections = [];
        // In case of creating an element after drawing connection to an empty space
        if (this.selectionManager.selectedObjects.length > 1 && e.detail.port) {
          connections = this.createAdditionalMultiConnection(this.objectCreated);
        } else if (e.detail.sourceId && e.detail.port) {
          let lineType = null;
          if (
            e.detail.explorerType === EExplorerConfigTypes.PAGE_ALL ||
            e.detail.explorerType === EExplorerConfigTypes.EVENT_ALL ||
            e.detail.object.lineType === EExplorerConfigTypes.SOURCE_ALL
          ) {
            lineType = EConnectionLineType.DOTTED;
          } else {
            lineType = EConnectionLineType.SOLID;
          }

          const connection = this.createAdditionalConnection(
            e.detail.port,
            e.detail.sourceId,
            lineType,
            this.objectCreated,
          );
          connections.push(connection);
        }

        if (!isLoaded) {
          // Immediately show the analytics data

          if (e.detail.object.value !== undefined) {
            let stepAnalyticsData = null;
            if (connections.length > 0) {
              const hitsData = TrafficData.findBy(e.detail);

              stepAnalyticsData = {
                hits: hitsData === null ? '-' : hitsData,
              };
            } else {
              stepAnalyticsData = {
                hits: e.detail.object.value,
              };
            }

            this.objectCreated.analyticsManager.setData(stepAnalyticsData);
            this.objectCreated.analyticsManager.process();
            this.objectCreated.updateFrameSize();

            // Set connection data
            if (
              connections.length > 0 &&
              this.objectCreated.integrationType !== IntegrationTypes.DIRECT_TRAFFIC // do not set connection data for direct traffic
            ) {
              for (let i = 0; i < connections.length; i++) {
                let connection = connections[i];
                const connectionAnalyticsData = {
                  hits: e.detail.object.value,
                };
                connection.analyticsManager.setData(connectionAnalyticsData);
                connection.analyticsManager.process();
              }
            }
          }
        }

        this.addObjectToStage(this.objectCreated, isLoaded);

        if (e.type === RP_EVENT_CREATE_OBJECT && e.detail.isPinned === false) {
          this.selectionManager.selectElement(this.objectCreated);
        }

        break;
      case EElementCategories.TEXT:
        this.objectCreated = this.createText(e, isLoaded);
        break;
      case EElementCategories.SHAPE:
        this.objectCreated = this.createShape(e, isLoaded, loadData);
        break;
      case EElementCategories.REPORT:
        this.objectCreated = this.createReport(e, isLoaded, loadData);
        break;
      case EElementCategories.WIDGET:
        this.objectCreated = this.createWidget(e, isLoaded, loadData);
        break;
      case EElementCategories.PHOTO:
        this.objectCreated = this.createPhoto(e, isLoaded, loadData);
        break;
      default:
        console.warn(`[onCreateObject] Wrong object type ${e.detail.object.category}`);
        return null;
    }

    if (!isLoaded && e.detail.object.category !== EElementCategories.REPORT) {
      // attach object if it is above the report
      if (this.reportView) {
        const isSlideOverlaps = this.reportView.isInsideSlide(this.objectCreated);
        if (isSlideOverlaps) {
          this.reportView.addObjects([this.objectCreated]);
        }
      }
    }
  }

  initializeObject(updElement, data, isLoaded = false) {
    let p;
    if (isLoaded) {
      p = { x: data.detail.object.x, y: data.detail.object.y };
    } else {
      p = Facade.viewport.toLocal(data.detail.position);
      if (SharedElementHelpers.IsSource(updElement) && !updElement.trackingURL) {
        if (updElement.integrationType != IntegrationTypes.DIRECT_TRAFFIC) {
          updElement.integrationType = IntegrationTypes.URL_PARAMETERS;
        }
      }
    }

    updElement.x = p.x;
    updElement.y = p.y;
    updElement.isCustom = data.detail.object.isCustom || false;
    updElement.legacySize = data.detail.object.legacySize;
    updElement.isMigratedFromLegacy = data.detail.object.isMigratedFromLegacy;
    updElement.goal = data.detail.object.goal;
    updElement.init();
    updElement.originWidth = updElement.width;
    updElement.originHeight = updElement.height;

    return updElement;
  }

  addObjectToStage(updElement, isLoaded) {
    if (!isLoaded) {
      // Find related connections , ( creating a step by dragging a connection )
      const relatedJoints = this.findAllRelatedConnections(updElement);

      let commandAdd = new CommandAdd(
        updElement,
        this.iconsContainer,
        this.objects,
        relatedJoints,
        this.jointsContainer,
        this.joints,
        this.selectionManager,
      );
      this.commandManager.execute(commandAdd);
    } else {
      // Shapes & Texts have special rules on how to be placed on the canvas
      if (SharedElementHelpers.IsShape(updElement)) {
        SharedElementHelpers.InsertShape(updElement, this.objects, this.iconsContainer);
      } else if (SharedElementHelpers.IsText(updElement)) {
        SharedElementHelpers.InsertText(updElement, this.objects, this.iconsContainer);
      } else if (SharedElementHelpers.IsReport(updElement)) {
        SharedElementHelpers.InsertReport(updElement, this.objects, this.iconsContainer);
      } else {
        this.iconsContainer.addChild(updElement);
        this.objects.push(updElement);
      }
    }
  }

  findAllRelatedConnections(element) {
    const relatedJointsIDs = this.getAllJointsIdsRelatedToStep(element);
    const relatedJoints = [];
    for (let i = 0; i < relatedJointsIDs.length; i++) {
      const id = relatedJointsIDs[i];
      const joint = this.getConnectionById(id);
      relatedJoints.push(joint);
    }
    return relatedJoints;
  }

  getPlaceHolderIcons(object) {
    if (SharedElementHelpers.IsAction(object)) {
      return PIXI.utils.TextureCache['default-action'];
    } else if (SharedElementHelpers.IsPage(object)) {
      return PIXI.utils.TextureCache['default-page'];
    } else if (SharedElementHelpers.IsSource(object)) {
      return PIXI.utils.TextureCache['default-source'];
    } else if (SharedElementHelpers.IsMisc(object)) {
      return PIXI.utils.TextureCache['default-misc'];
    } else if (SharedElementHelpers.IsPhoto(object)) {
      return PIXI.utils.TextureCache['photo-upload'];
    }

    console.warn('No default texture was found for', object);
    return null;
  }

  createStep(data, isLoaded = false) {
    const { object } = data.detail;
    const { label } = object;

    const texturePath = object.src;
    const texture = object.isCustom
      ? this.getPlaceHolderIcons(object)
      : PIXI.Texture.from(texturePath);

    // todo need to refactor the parameter set process
    let step = new StepContainer(
      object.type,
      label,
      texture,
      this._getEventHandlers(),
      object.id,
      object.value,
    );

    step.setFilterData(object.filterData);
    if (object.integrationAttributes) {
      step.setIntegrationAttributes(object.integrationAttributes);
    }
    step.texturePath = texturePath;
    step.setURL(object.url);
    step.setTrackingURL(object.trackingURL);
    step.setThumbnail(object.thumbnailURL, object.useThumbnail);
    step.setActionType(object.actionType);
    step.setIntegrationType(object.integrationType);
    step.setActionName(object.actionName);
    step.setAnalyticsFilterData(object.analyticsFilterData);
    step.analyticsManager.setCompareRange(this.compareRange);
    step.setLockedStatus(object.isLocked);
    step = this.initializeObject(step, data, isLoaded);

    return step;
  }

  createStepFromURL(urlData) {
    const position = window.app.renderer.plugins.interaction.mouse.global;
    const url = urlData.urlWithoutQueryString;
    const label = url.length > 50 ? url.substring(0, 50) + '...' : url;

    const data = {
      detail: {
        object: {
          type: 'PAGE',
          src: 'StepsModal/Pages/genericpage.png',
          label,
          url: url,
          category: EElementCategories.STEP,
          actionType: 'none',
          integrationType: 'none',
          isCustom: false,
        },
        position,
      },
    };

    // add paremters
    if (urlData.parameters && urlData.parameters.length > 0) {
      const filterData = [];
      for (let i = 0; i < urlData.parameters.length; i++) {
        const parameter = urlData.parameters[i];
        filterData.push({
          key: parameter.key,
          value: parameter.value,
          contains: 'true',
          isFocusKey: false,
          operator: DEFAULT_OPERATOR,
        });
      }
      data.detail.object.filterData = filterData;
    }

    const step = this.createStep(data, false);
    this.addObjectToStage(step, false);

    return step;
  }

  createText(data, isLoaded = false) {
    const { object } = data.detail;
    let textObject = new TextLabel(object.text, this._getEventHandlers(), object.id);
    textObject.delegate = this;
    textObject.setLockedStatus(object.isLocked);

    this.initializeObject(textObject, data, isLoaded);
    this.addObjectToStage(textObject, isLoaded);

    if (!isLoaded) {
      this.selectionManager.clearSelection();
      this.selectionManager.addToSelection(textObject);
      this.selectionManager.updateSelection();
      this.selectionManager.hide();

      // setTimeout is used to send the method to execute in the event loop
      setTimeout(function () {
        textObject.enterEditMode();
      }, 0);

      this.selectionManager.single.setToolbarPositionPoint();
      this.selectionManager.single.notifyObjectSelected(true);
    }

    return textObject;
  }

  createPhoto(data, isLoaded = false) {
    const { object } = data.detail;
    const texture = this.getPlaceHolderIcons(object);

    const photoObject = new PhotoContainer(
      object.photoData,
      texture,
      this._getEventHandlers(),
      object.id,
    );
    photoObject.delegate = this;
    photoObject.setLockedStatus(object.isLocked);

    this.initializeObject(photoObject, data, isLoaded);
    this.addObjectToStage(photoObject, isLoaded);

    if (!isLoaded) {
      this.selectionManager.clearSelection();
      this.selectionManager.hide();

      if (MainStorage.getCanvasPermissions().isTutorial) {
        // wrap in timeout to avoid showing the message after the click events are fired
        setTimeout(() => {
          // Initial trigger of the upload popup when user puts an image/photo object on the canvas
          commonSendEventFunction(RP_EVENT_INFO_MESSAGE, {
            message: TEXT_TUTORIAL_UPLOAD_NOT_SUPPORTED,
          });
        }, 0);

        return;
      }

      // check if we are in view-only or tutorial mode
      // we should not allow uploading photots but instead to inform the user that they
      // can't do that

      if (MainStorage.getCanvasPermissions().iconsAllowed) {
        // Trigger the upload popup
        const inputField = document.createElement('input');
        inputField.type = 'file';

        inputField.onchange = (e) => {
          const file = e.target.files[0];
          const state = photoObject.getState();
          state.stepId = state.ID;
          commonSendEventFunction(PR_EVENT_ON_PHOTO_CHANGED, {
            file,
            currentStep: state,
          });
        };
        inputField.click();
      }
    }

    return photoObject;
  }

  createPhotoFromFile(file) {
    const position = window.app.renderer.plugins.interaction.mouse.global;

    if (MainStorage.getCanvasPermissions().isTutorial) {
      // Paste image directly to the canvas
      commonSendEventFunction(RP_EVENT_INFO_MESSAGE, {
        message: RP_EVENT_INFO_MESSAGE,
      });
      return;
    }

    const data = {
      detail: {
        object: {
          category: 'PHOTO',
          position,
        },
        position,
      },
    };

    const texture = PIXI.utils.TextureCache['photo-upload'];
    const photoObject = new PhotoContainer(undefined, texture, this._getEventHandlers(), undefined);
    photoObject.delegate = this;
    photoObject.setLockedStatus(false);

    this.initializeObject(photoObject, data, false);
    this.addObjectToStage(photoObject, false);

    this.selectionManager.clearSelection();
    this.selectionManager.hide();

    const state = photoObject.getState();
    state.stepId = state.ID;
    commonSendEventFunction(PR_EVENT_ON_PHOTO_CHANGED, {
      file,
      currentStep: state,
    });
  }

  createShape(data, isLoaded = false, loadData = null) {
    const objectData = data.detail.object;

    let shape = null;

    if (isLoaded && loadData) {
      if (loadData.width === 0 || loadData.height === 0) {
        // There are cases where invalid shapes remain in memory , the reason is not known
        // The best way to fix this is to remove the shape from the canvas
        console.warn('Shape width or height is 0 , and it will be ignored');
        return null;
      }
    }

    if (objectData.type === EShapeTypes.CIRCLE || objectData.type === EShapeTypes.ELLIPSE) {
      shape = new ShapeEllipse(this._getEventHandlers(), objectData.id, loadData);
    } else if (
      objectData.type === EShapeTypes.SQUARE ||
      objectData.type === EShapeTypes.RECTANGLE
    ) {
      shape = new ShapeRectangle(this._getEventHandlers(), objectData.id, loadData);
    } else if (objectData.type === EShapeTypes.TRIANGLE) {
      shape = new ShapeTriangle(this._getEventHandlers(), objectData.id, loadData);
    }
    shape.delegate = this;
    shape = this.initializeObject(shape, data, isLoaded);
    shape.setLockedStatus(objectData.isLocked);

    this.addObjectToStage(shape, true);

    return shape;
  }

  createWidget(event, isLoaded = false) {
    const { object } = event.detail;

    let widget = null;
    if (object.type === EElementTypes.WIDGET_PEOPLE) {
      widget = new PeopleWidget(this._getEventHandlers(), object.id, this, object);
    } else if (object.type === EElementTypes.WIDGET_COUNTRIES) {
      widget = new TopCountriesWidget(this._getEventHandlers(), object.id, this, object);
    } else if (object.type === EElementTypes.WIDGET_GOALS) {
      widget = new GoalsWidget(this._getEventHandlers(), object.id, this, object);
    } else if (object.type === EElementTypes.WIDGET_TRENDS && FF_TRENDS) {
      widget = new TrendsWidget(this._getEventHandlers(), object.id, this, object);
    } else if (object.type === EElementTypes.WIDGET_FORECASTING) {
      widget = new ForecastingWidget(this._getEventHandlers(), object.id, this, object);
    }

    if (!widget) {
      return null;
    }

    if (isLoaded) {
      // on canvas load , also the analytics is loading
      widget.isAnalyticsLoading = true;
    }

    widget.setCompareRange(this.compareRange);
    this.initializeObject(widget, event, isLoaded);
    this.addObjectToStage(widget, isLoaded);
    const toogleData = MainStorage.getTogglePanelStatus();
    if (!isLoaded) {
      widget.onAnalyticsSwitched(toogleData.numbers);
    } else {
      widget.isAnalyticsVisible = toogleData.numbers;
    }

    if (object.data) {
      widget.setWidgetData(object.data);
    }

    if (!isLoaded) {
      widget.onWidgetDropped();
    } else {
      widget.onWidgetLoaded();
    }

    return widget;
  }

  createReport(data, isLoaded = false) {
    if (this.findReport() && !isLoaded) {
      return;
    }
    const { object } = data.detail;
    this.reportView = new ReportView(this._getEventHandlers(), object.id, this, object);

    this.initializeObject(this.reportView, data, isLoaded);
    this.addObjectToStage(this.reportView, isLoaded);

    if (isLoaded) {
      commonSendEventFunction(RP_EVENT_REPORT_ADDED);
    }

    return this.reportView;
  }

  getReport() {
    if (this.reportView && this.reportView.parent) {
      return this.reportView;
    }
    return null;
  }

  findReport() {
    for (let i = 0; i < this.objects.length; i++) {
      const object = this.objects[i];
      if (SharedElementHelpers.IsReport(object)) {
        return object;
      }
    }
    return null;
  }

  transformOldShapeFormat(objectData) {
    if (objectData.category === EShapeTypes.CIRCLE) {
      objectData.category = EElementCategories.SHAPE;
      objectData.type = EShapeTypes.ELLIPSE;
      if (objectData.shapeData) {
        objectData.shapeData.width = objectData.shapeData.radius;
        objectData.shapeData.height = objectData.shapeData.radius;
      }
    } else if (objectData.category === EShapeTypes.SQUARE) {
      objectData.category = EElementCategories.SHAPE;
      objectData.type = EShapeTypes.RECTANGLE;
    } else if (objectData.category === EShapeTypes.TRIANGLE) {
      objectData.category = EElementCategories.SHAPE;
      objectData.type = EShapeTypes.TRIANGLE;
      if (objectData.shapeData) {
        let sd = objectData.shapeData;
        sd.width = Math.abs(sd.x0 - sd.x2);
        sd.height = Math.abs(sd.y0 - sd.y1);
        sd.x = 0;
        sd.y = 0;
        sd.orientation = ORIENTATION_DOWN;
      }
    }

    if (objectData.shapeData) {
      objectData.shapeData.category = EElementCategories.SHAPE;
    }
  }

  removeStepWithId(id) {
    const index = this.getStepIndexById(id);
    let step = this.objects[index];
    const joints = this.getAllJointsIdsRelatedToStep(step);
    for (let i = 0; i < joints.length; i++) {
      this.removeConnection(joints[i]);
    }

    step.onDestroy();
    this.iconsContainer.removeChild(step);
    step = null;
    this.objects.splice(index, 1);
  }

  /**
   * Removes a connection object. Removes the element from total connection list
   * @param id
   */
  removeConnection(id) {
    for (let i = this.joints.length - 1; i >= 0; i--) {
      if (this.joints[i].id === id) {
        this.joints[i].onDestroy();
        this.joints[i] = null;
        this.joints.splice(i, 1);
        Signals.elementChanged.dispatch();
        break;
      }
    }
    window.app.needsRendering();
  }

  /**
   * Create a connection between two steps
   * @param iconA
   * @param iconB
   */
  createConnection(iconA, iconB, lineType, saveForUndo = true, attachmentPointType) {
    let joint = null;
    this.iconB = iconB;

    // By default connection lines will be connected to the center
    const aType = attachmentPointType || CControlPointTypes.CENTER;

    if (this.isIncommingCon()) {
      this.iconB = iconA;
      this.iconA = iconB;
      this.fromIconHeadName = EStepConnectionPort.OUT;
      joint = new ConnectionContainer(iconB, iconA);
      joint.attachmentPointsLocation.pointA = aType;
    } else {
      joint = new ConnectionContainer(iconA, iconB);
      joint.attachmentPointsLocation.pointB = aType;
    }

    joint.delegate = this;
    joint.analyticsManager.setCompareRange(this.compareRange);
    joint.init(this.fromIconHeadName);

    if (lineType) {
      joint.setLineType(lineType);
    }

    // TODO the connection should automatically detect where to draw the head
    // this needs to be refactored , as this is redundant data and it leads to bugs
    // FA-1953 was caused by this
    if (this.isIncommingCon()) {
      joint.showHeadA();
    } else {
      joint.showHeadB();
    }

    if (saveForUndo) {
      let commandAddConnection = new CommandAddConnection(joint, this.jointsContainer, this.joints);
      this.commandManager.execute(commandAddConnection);
      this.removeHighlight(iconA, iconB);
    }

    Signals.elementChanged.dispatch();

    this.removeCoordinatesJoint();

    if (this.controller.cursorMode === EElementCategories.PANNING) {
      Facade.viewport.plugins.resume('drag');
    }

    window.app.needsRendering();

    return joint;
  }

  isIncommingCon() {
    return this.fromIconHeadName === EStepConnectionPort.IN;
  }

  isOutgoingCon() {
    return this.fromIconHeadName === EStepConnectionPort.OUT;
  }

  removeHighlight(iconA, iconB) {
    iconA.isHovered = false;
    iconB.isHovered = false;

    if (!this.selectionManager.isSelected(iconA)) {
      this.selectionManager.focusSelection.hoverOut(null, iconA);
    } else if (!this.selectionManager.isSelected(iconB)) {
      this.selectionManager.focusSelection.hoverOut(null, iconB);
    } else {
      this.selectionManager.focusSelection.drawAll();
    }
  }

  addConnection(connection) {
    if (connection) {
      this.joints.push(connection);
      this.jointsContainer.addChild(connection);
    }
  }

  /**
   * Create a connection right after creating an element and
   * which is connected to step with iconAId Id
   * @param headName
   * @param iconAId
   * @param iconBId
   */
  createAdditionalConnection(headName, iconAId, lineType, iconB) {
    const iconA = this.getStepById(iconAId);
    if (iconA) {
      this.fromIconHeadName = headName;
      this.iconA = iconA;
      let connection = this.createConnection(this.iconA, iconB, lineType, false);
      this.addConnection(connection);
      return connection;
    } else {
      throw Error(`[PlaneContainer: createAdditionalConnection] no step with id ${iconAId}`);
    }
  }

  createAdditionalMultiConnection(iconB) {
    return this.createConnectionsToElement(iconB, { type: CControlPointTypes.CENTER });
  }

  /**
   * Removes the floating connection
   */
  removeCoordinatesJoint() {
    if (this.pointerJoint) {
      commonSendEventFunction(PR_EVENT_REMOVE_POINTER_JOINT);
    }

    this._addStepOnConnectionEnd = false;
    this.jointsContainer.removeChild(this.pointerJoint);
    this.pointerJoint = null;
    this.iconA = null;
    this.iconB = null;
    window.app.needsRendering();
  }

  markReportObjects() {
    this.objects.forEach((object) => (object.isInsideReport = false));
    const newReportObjects = this.getObjectsInsideReport();
    newReportObjects.forEach((object) => {
      object.isInsideReport = true;
    });
    return newReportObjects;
  }

  getObjectsInsideReport() {
    const reportView = this.getReport();

    const newReportObjects = [];
    if (reportView) {
      for (let j = 0; j < this.objects.length; j++) {
        const element = this.objects[j];
        const isSlideOverlaps = reportView.isInsideSlide(element);
        if (isSlideOverlaps && !SharedElementHelpers.IsReport(element)) {
          newReportObjects.push(element);
          element.isInsideReport = true;
        }
      }
    }

    return newReportObjects;
  }

  getAllWidgets() {
    const widgets = [];
    for (let i = 0; i < this.objects.length; i++) {
      const element = this.objects[i];
      if (SharedElementHelpers.IsWidget(element)) {
        widgets.push(element);
      }
    }
    return widgets;
  }

  /**
   * Gets a step element by ID
   * @param id
   * @returns {BaseContainer} Element. If not found - null.
   */
  getStepById(id) {
    let result = null;
    const elementId = this.getStepIndexById(id);
    if (elementId >= 0) {
      result = this.objects[elementId];
    }
    return result;
  }

  /**
   * Gets a connection element by ID
   * @param id
   * @returns {ConnectionContainer} Element. If not found - null.
   */
  getConnectionById(id) {
    let result = null;
    const elementId = this.getConnectionIndexById(id);
    if (elementId >= 0) {
      result = this.joints[elementId];
    }
    return result;
  }

  /**
   * Returns container object by ID
   * @param id Id of the element
   * @returns {BaseContainer} Container object
   */
  getElementById(id) {
    let result = this.getStepById(id);
    if (!result) {
      result = this.getConnectionById(id);
      if (!result) {
        throw Error(`[PlaneContainer.getElementById] No element with id ${id} found`);
      }
    }

    return result;
  }

  getAllJointsIdsRelatedToStep(step) {
    const joints = [];
    for (let i = 0; i < this.joints.length; i++) {
      if (this.joints[i].iconA.id === step.id || this.joints[i].iconB.id === step.id) {
        joints.push(this.joints[i].id);
      }
    }
    return joints;
  }

  /**
   * Prepare data about all components to save it to server
   * @param includeConnections - why do you need the data: for make a save or get ids of all elements
   */
  getSceneData(includeConnections = true) {
    const zoom = this.controller.zoomUtility.getZoomLevel();

    // We need to save the data at zoom level 1.0
    // because in the ReportView we might adjust some elements position based on the zoom level
    // and if the elements are not saved at the correct location they will start driffting when saving the canvas data
    //
    // And because we are autosaving the data , this will be triggered by a timer (search for debounce)
    // it means it can happen while interacting with the canvas
    // Usually its not a problem unless the user is dragging elements on the canvas
    // But we now have a solution to pause the autosave timer when the user is interacting with the canvas
    if (this.reportView) {
      // Set zoom level is a method with side effects , it will cause the elements to "freeze start moving data"
      // inside the canvas so the we can zoom in/out correctly
      this.reportView.setZoomLevel(1.0);
    }

    this.stateData.objects = this.getObjectsData();
    this.stateData.joints = includeConnections
      ? this.getConnectionsStateData()
      : this.getDataConnectionsStateData();

    if (this.reportView) {
      this.reportView.setZoomLevel(zoom);
    }

    this.stateData.source = this.canvasDataSource;
    this.stateData.notes = this.canvasDataNotes;
    this.stateData.version = this.canvasDataVersion;
    this.stateData.canvasChecklistData = this.canvasChecklist.getData();

    const data = {
      data: JSON.stringify(this.stateData),
      preview: JSON.stringify(this.snapShot.createSnapshot(Facade.viewport).src),
    };

    return data;
  }

  getObjectsData() {
    const data = [];

    for (let i = 0; i < this.objects.length; i++) {
      const obj = this.objects[i];
      const objectState = obj.getState();
      data.push(objectState);
    }

    return data;
  }

  getObjectsWithAnalyticsData() {
    const data = [];

    const reportView = this.getReport();

    for (let i = 0; i < this.objects.length; i++) {
      const obj = this.objects[i];
      const objectState = obj.getStateWithAnalyticsData();
      // check if object is inside report view

      if (reportView) {
        const slideIndex = reportView.getInsideSlideIndex(obj);
        if (slideIndex !== -1) {
          objectState.slideIndex = slideIndex;
        }
      }

      data.push(objectState);
    }

    return data;
  }

  /**
   * Get the state data of data connections.
   * @returns stateData
   */
  getDataConnectionsStateData() {
    const stateData = [];

    for (let i = 0; i < this.joints.length; i++) {
      const joint = this.joints[i];
      const jointState = joint.getState();
      // Ignore connections that do not have data
      if (this.isDataConnection(joint)) {
        stateData.push(jointState);
      }
    }

    return stateData;
  }

  getDataConnectionsStateWithAnalyticsData() {
    const stateData = [];

    const reportView = this.getReport();

    for (let i = 0; i < this.joints.length; i++) {
      const joint = this.joints[i];
      const jointState = joint.getStateWithAnalyticsData();

      jointState.conversionRate = joint.analyticsManager.percent;

      // Ignore connections that do not have data
      if (this.isDataConnection(joint)) {
        if (reportView) {
          const slideIndexA = reportView.getInsideSlideIndex(joint.iconA);
          if (slideIndexA !== -1) {
            jointState.slideIndexA = slideIndexA;
          }

          const slideIndexB = reportView.getInsideSlideIndex(joint.iconB);
          if (slideIndexB !== -1) {
            jointState.slideIndexB = slideIndexB;
          }
        }

        stateData.push(jointState);
      }
    }

    return stateData;
  }

  /**
   * Get the state data of connections.
   * @returns stateData
   */
  getConnectionsStateData() {
    const stateData = [];

    for (let i = 0; i < this.joints.length; i++) {
      const joint = this.joints[i];
      const jointState = joint.getState();
      stateData.push(jointState);
    }

    return stateData;
  }

  /**
   * If it is a connection between , page / event / source.
   * Offline elements , shapes or texts do not hold data
   * @param {ConnectionContainer} connection
   * @returns
   */
  isDataConnection(connection) {
    return (
      SharedElementHelpers.IsStep(connection.iconA) &&
      !SharedElementHelpers.IsMisc(connection.iconA) &&
      SharedElementHelpers.IsStep(connection.iconB) &&
      !SharedElementHelpers.IsMisc(connection.iconB)
    );
  }

  getStepIndexById(id) {
    return this.getElementIndexById(id, this.objects);
  }

  getConnectionIndexById(id) {
    return this.getElementIndexById(id, this.joints);
  }

  /**
   * Returns an object that stores all event handlers
   * @returns {{onElementPointerDown: PlaneContainer.onElementPointerDown, onElementPointerUp: PlaneContainer.onElementPointerUp}}
   * @private
   */
  _getEventHandlers() {
    return {
      onElementPointerDown: this.controller.onElementPointerDown.bind(this.controller),
      onElementPointerMove: this.controller.onElementPointerMove.bind(this.controller),
      onElementPointerUp: this.controller.onElementPointerUp.bind(this.controller),
      onElementPointerUpOutside: this.controller.onElementPointerUpOutside.bind(this.controller),
      onElementPointerOver: this.controller.onElementPointerOver.bind(this.controller),
      onElementPointerOut: this.controller.onElementPointerOut.bind(this.controller),
    };
  }

  /**
   * It handles the objects interation state ,
   * used to disable other objects for interaction
   */
  setObjectsInteraction(interactive) {
    for (let i = 0; i < this.objects.length; i++) {
      let object = this.objects[i];
      object.setInteractiveChildren(interactive);
    }

    for (let i = 0; i < this.joints.length; i++) {
      let joint = this.joints[i];
      joint.setInteractiveChildren(interactive);
    }
  }

  lockAllObjectsForInteraction() {
    for (let i = 0; i < this.objects.length; i++) {
      let object = this.objects[i];
      object.setInteractiveChildren(false);
    }

    for (let i = 0; i < this.joints.length; i++) {
      let joint = this.joints[i];
      joint.setInteractiveChildren(false);
    }
    this.isSceneLocked = true;
  }

  /**
   * It handles the objects interation state ,
   * used to disable other objects for interaction
   */
  lockInteractionsForNonStepOjects() {
    for (let i = 0; i < this.objects.length; i++) {
      let object = this.objects[i];
      if (!SharedElementHelpers.IsStep(object)) {
        object.setInteractiveChildren(false);
      }
    }

    for (let i = 0; i < this.joints.length; i++) {
      let joint = this.joints[i];
      joint.setInteractiveChildren(false);
    }
    this.isSceneLocked = true;
  }

  /**
   * It reverts the objects interation state to a previous known state
   */
  revertObjectsInteraction() {
    for (let i = 0; i < this.objects.length; i++) {
      let object = this.objects[i];
      object.revertInteractiveChildren();
    }

    for (let i = 0; i < this.joints.length; i++) {
      let joint = this.joints[i];
      joint.revertInteractiveChildren();
    }
    this.isSceneLocked = false;
  }

  deleteElementById(id) {
    const element = this.getElementById(id);
    if (!element) {
      throw Error(`[PlaneContainer.deleteElementById] Wrong element with id: ${id}`);
    }

    switch (element.category) {
      case EElementCategories.STEP:
      case EElementCategories.TEXT:
      case EElementCategories.SHAPE:
        this.removeStepWithId(id);
        break;
      case EElementCategories.CONNECTION:
        this.removeConnection(id);
        break;
      default:
        throw Error(
          `[PlaneContainer.deleteElementById] Wrong element category to delete ${element.category} with id: ${id}`,
        );
    }

    if (!SharedElementHelpers.IsTextOrShapeElements(element)) {
      Signals.elementChanged.dispatch();
    }
    window.app.needsRendering();
  }

  isObjectsContainLockedReport(objects) {
    if (this.objectsContainReport(objects)) {
      const reportView = this.getReport();
      if (reportView.isLocked) {
        return true;
      }
    }

    return false;
  }

  onDeleteSelection() {
    if (this.objectsContainReport(this.selectionManager.selectedObjects)) {
      const reportView = this.getReport();
      if (!reportView.isLocked) {
        if (reportView.isEmpty()) {
          this.deleteSelection();
          this.culling.removeObject(reportView);
        } else {
          commonSendEventFunction(PR_EVENT_REPORT_WARNING_POPUP_OPENED, { isSlide: false });
        }
      }

      return;
    }

    this.deleteSelection();
  }

  deleteSelection() {
    let commandBatch = new CommandsBatch();

    let commands = this.getDeleteCommands(this.selectionManager.selectedObjects);
    for (let i = 0; i < commands.length; i++) {
      const command = commands[i];
      commandBatch.add(command);
    }
    this.commandManager.execute(commandBatch);

    this.selectionManager.clearSelection();
    this.selectionManager.hide();
    window.app.needsRendering();
  }

  findInclusiveJoints(steps) {
    let relatedConnections = [];

    for (let i = 0; i < steps.length; i++) {
      const object = steps[i];
      if (SharedElementHelpers.IsConnection(object)) {
        continue;
      }
      relatedConnections = relatedConnections.concat(this.findAllRelatedConnections(object));
    }

    // Get all unique connections
    relatedConnections = relatedConnections.filter(function (item, pos, self) {
      return self.indexOf(item) !== pos;
    });

    return relatedConnections;
  }

  getDeleteCommands(steps) {
    let connectionsToDelete = [];
    let commands = [];

    for (let i = 0; i < steps.length; i++) {
      let object = steps[i];
      if (SharedElementHelpers.IsConnection(object)) {
        // selected objects may also be connections , and in the case of selecting
        // connection lines exclusively , there will be no related connections
        // so we must always set the selected connections to be deleted
        if (SharedElementHelpers.IsConnection(object)) {
          connectionsToDelete.push(object);
        }
      } else {
        let deleteCommand = new CommandDeleteStep(
          object,
          this.iconsContainer,
          this.objects,
          this.selectionManager.focusSelection,
        );
        commands.push(deleteCommand);
      }

      // find related connections
      let relatedConnections = this.findAllRelatedConnections(object);
      connectionsToDelete = connectionsToDelete.concat(relatedConnections);
    }

    // Get all unique connections
    connectionsToDelete = connectionsToDelete.filter(function (item, pos, self) {
      return self.indexOf(item) == pos;
    });

    // Create connections delete commands
    for (let i = 0; i < connectionsToDelete.length; i++) {
      let connection = connectionsToDelete[i];
      let deleteCommand = new CommandDeleteConnection(
        connection,
        this.jointsContainer,
        this.joints,
      );
      commands.push(deleteCommand);
    }

    if (this.objectsContainReport(steps)) {
      const reportView = this.getReport();

      for (let i = 0; i < reportView.slides.length; i++) {
        const objects = reportView.slides[i].getSlideObjects();
        if (objects.length) {
          const insideCommands = this.getDeleteCommands(objects);
          commands = commands.concat(insideCommands);
        }
      }
    }

    return commands;
  }

  /**
   * Removes the elements from stage , and then inserts them through the command system.
   * @param {Steps} steps
   */
  reAddElements(steps) {
    let deleteCommands = this.getDeleteCommands(steps);
    let addCommandsBatch = new CommandsBatch();

    for (let i = 0; i < deleteCommands.length; i++) {
      const deleteCommand = deleteCommands[i];
      // The command is being executed , but its not added into the commands queue
      deleteCommand.execute();

      let object = deleteCommand.object;
      let parent = deleteCommand.parent;
      let objects = deleteCommand.objects;

      let addCommand = null;
      // Convert the Delete commands into Add commands.
      if (deleteCommand instanceof CommandDeleteStep) {
        let object = deleteCommand.object;
        // It is important that related joints is empty since we are already adding the joints
        addCommand = new CommandAdd(
          object,
          parent,
          objects,
          [],
          this.jointsContainer,
          this.joints,
          this.selectionManager,
        );
      } else if (deleteCommand instanceof CommandDeleteConnection) {
        addCommand = new CommandAddConnection(object, parent, objects);
      }

      // because we used a delete command to remove elements from the stage , that completly removes all event listeners.
      // To add them back we need to set the flag in the newly created command "wasReverted" to be true
      // That will add the event listeners back at its first execution.
      addCommand.wasReverted = true;
      addCommandsBatch.add(addCommand);
    }
    this.commandManager.execute(addCommandsBatch);
  }

  setCachingForElements(elements, status) {
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      if (element.footer) {
        element.footer.setCaching(status);
      }
    }
  }

  setDropShadows(elements, status) {
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      if (element.footer) {
        element.footer.setDropShadow(status);
      }
    }
  }

  saveToPng(resolution) {
    try {
      const img = this.snapShot.createSnapshot(Facade.viewport, EXPORTED_IMAGE_NAME, resolution);
      this.snapShot.forceDownloadImage(img);
    } catch (error) {
      commonSendEventFunction(RP_EVENT_ERROR_MESSAGE, {
        errorMSG: error.message,
      });
    }
  }

  getReportImages() {
    const reportView = this.getReport();
    if (reportView) {
      const bounds = reportView.getCullingBounds();
      this.culling.showSegments(bounds);

      return reportView.getSlidesImages(Facade.viewport);
    }
    return null;
  }

  resetStepsAnalyticsData() {
    for (let i = 0; i < this.objects.length; i++) {
      const object = this.objects[i];

      if (object.footer) {
        if (object.analyticsManager) {
          object.analyticsManager.setData(null);
        }

        if (object.footer.parent) {
          object.footer.pushToBack();
        }
        object.footer.resetData();
        object.updateFrameSize();
      }
    }
  }

  resetConnectionsAnalyticsData() {
    for (let i = 0; i < this.joints.length; i++) {
      const connection = this.joints[i];
      connection.analyticsManager.setData(null);
      connection.footer.resetData();
      connection.draw();
    }
  }

  onViewportMove(e) {
    if (this.iconA) {
      // update joint position when trying to connect to another object
      const p = Facade.viewport.toLocal(e.data.global);
      if (this.pointerJoint && !this._addStepOnConnectionEnd) {
        this.pointerJoint.update(p.x, p.y);
        window.app.needsRendering();
      }
    }

    if (this.movingConnection) {
      this.onConnectionAttachPointMove(e, this.movingConnection);
    }
  }

  onViewportUp(e) {
    if (this.movingConnection) {
      this.onConnectionHeadRelocateToEmptyCanvas(e, this.movingConnection);
    }

    // if we need to create an element
    if (this.pointerJoint && this.iconA) {
      this._addStepOnConnectionEnd = true;
      const position = e.data.global;
      position.x *= window.app.scaleManager.aspectRatio;
      position.y *= window.app.scaleManager.aspectRatio;

      // This is to avoid some weird behaviour ,
      // for some reason the components that are supposed to listen for PR_EVENT_CONNECTION_IN_EMPTY_SPACE
      // are being removed for a very brief moment , and then added back
      setTimeout(() => {
        commonSendEventFunction(PR_EVENT_CONNECTION_IN_EMPTY_SPACE, {
          position: { x: position.x, y: position.y },
          sourceId: this.iconA.id,
          port: this.pointerJoint.port,
          canExplore: this.selectionManager.selectedObjects.length < 2,
        });
      }, 0);
    } else if (this.pointerJoint) {
      this.removeCoordinatesJoint();
      this.selectionManager.hideToolbar();
    }
  }

  onSelectionHeadsDown(e) {
    if (!this.iconA) {
      e.stopPropagation();
      const port = e.currentTarget.name;
      const p = e.data.global;
      p.x *= window.app.scaleManager.aspectRatio;
      p.y *= window.app.scaleManager.aspectRatio;

      if (port === EStepConnectionPort.BOTTOM) {
        console.warn('This was the former impmentation for attribute explorer');
      } else {
        const iconA =
          this.selectionManager.selectedObjects.length > 1
            ? this.selectionManager.multi
            : this.selectionManager.selectedObjects[0];
        const toPoint = Facade.viewport.toLocal(e.target.getGlobalPosition());
        this.createPointerJoint(iconA, toPoint, port);
        Facade.viewport.plugins.pause('drag');
        this.selectionManager.updateSelection(false, true, false, false);
      }
    }
    window.app.needsRendering();
  }

  createConnectionsToElement(targetElement, attachmentPoint = { type: CControlPointTypes.FLOW }) {
    const attachType = attachmentPoint.type;
    const connections = [];

    if (
      this.selectionManager.selectedObjects.length > 1 &&
      !this.selectionManager.isSelected(targetElement)
    ) {
      // Create multiple connections to an element
      const batchCommands = new CommandsBatch();
      const isIncommingCon = this.isIncommingCon();

      this.selectionManager.getSelectedSteps().forEach((element) => {
        // Set this before creating the connection , as it will be reset to null after creation
        this.fromIconHeadName = isIncommingCon ? EStepConnectionPort.IN : EStepConnectionPort.OUT;

        // determine if its incoming or outgoing connection
        const connection = this.createConnection(
          element,
          targetElement,
          undefined,
          false,
          attachType,
        );
        connections.push(connection);
        const commandAddConnection = new CommandAddConnection(
          connection,
          this.jointsContainer,
          this.joints,
        );
        batchCommands.add(commandAddConnection);
      });

      if (batchCommands.commands.length) {
        this.commandManager.execute(batchCommands);
      } else {
        this.removeCoordinatesJoint();
      }

      targetElement.isHovered = false;
      if (!this.selectionManager.isSelected(targetElement)) {
        this.selectionManager.focusSelection.hoverOut(null, targetElement);
      } else {
        this.selectionManager.focusSelection.drawAll();
      }

      this.selectionManager.selectElement(targetElement);
    } else if (
      this.selectionManager.selectedObjects.length > 1 &&
      this.selectionManager.isSelected(targetElement)
    ) {
      // If there is multiple connection and it is trying to connect to element in this selection
      this.removeCoordinatesJoint();
      this.selectionManager.show();
    } else if (this.iconA && this.iconA !== targetElement) {
      // Create a single connection to an element
      const connection = this.createConnection(
        this.iconA,
        targetElement,
        undefined,
        true,
        attachType,
      );
      connections.push(connection);
      this.selectionManager.selectElement(targetElement);
    } else {
      // If it is trying to connect to itself
      this.removeCoordinatesJoint();
      this.selectionManager.show();
    }

    this.fromIconHeadName = null;
    window.app.needsRendering();

    return connections;
  }

  analyticsRefreshed(isCancel) {
    this.resetStepsAnalyticsData();
    this.resetConnectionsAnalyticsData();

    for (let i = 0; i < this.objects.length; i++) {
      const element = this.objects[i];
      if (SharedElementHelpers.IsWidget(element)) {
        element.onAnalyticsStartedRefresh();
      }
    }

    const data = this.getSceneData(false);
    commonSendEventFunction(PR_EVENT_REFRESH_RESPONSE, { data, isCancel });

    if (this.selectionManager.hasSelectedElements()) {
      this.selectionManager.updateSelection(
        true,
        false,
        true,
        true,
        SELECTION_UPDATE_SOURCE_ANALYTICS,
      );
    }

    this.flowController.onAnalyticsCleared();
  }

  // Delegate handler connection handler
  onConnectionPointerDown(e, connection) {
    e.stopPropagation();
    const isMultiSelect =
      this.controller.inputEventController.isShiftDown ||
      this.controller.inputEventController.isCtrlDown;
    this.selectionManager.onElementPointerDown(e, isMultiSelect);
  }

  // Delegate handler connection handler
  onConnectionPointerMove(e, connection) {
    this.selectionManager.onElementPointerMove(e);
  }

  // Delegate handler connection handler
  onConnectionPointerUp(e, connection) {
    // Finish the selection
    this.selectionManager.onViewportUp(e);
    if (this.selectionManager.isMovingElements()) {
      this.selectionManager.onElementPointerUp(e);

      this.controller.viewportAutoPan.isActive = false;
    }

    if (this.pointerJoint) {
      this.removeCoordinatesJoint();
      this.selectionManager.hideToolbar();
    }
    e.stopPropagation();

    // clear guide lines
    this.controller.alignmentGuides.clear();
  }

  onConnectionDisplayDragged(e, connectionDisplay) {
    this.controller.onConnectionDisplayDragged(e, connectionDisplay);
  }

  // Delegate handler connection handler
  onConnectionAttachPointDown(event, connection, attachPoint, isEndPoint) {
    event.stopPropagation();
    // deselect connection
    this.selectionManager.clearSelection();
    this.selectionManager.hide();
    // save connection
    this.movingConnection = connection;
    connection.detachedData = {
      isEndPoint: isEndPoint,
      position: attachPoint.position.clone(),
    };
    connection.interactive = false;
    connection.interactiveChildren = false;
  }

  onConnectionAttachPointMove(event, connection) {
    // move pointer joint selected head
    if (connection.detachedData) {
      event.stopPropagation();
      const p = Facade.viewport.toLocal(event.data.global);
      connection.detachedData.position.copyFrom(p);
      connection.moveConnectionHead();
    }
  }

  onConnectionHeadRelocateToEmptyCanvas(e, connection) {
    const isEnd = connection.detachedData.isEndPoint;
    const element = isEnd ? connection.iconA : connection.iconB;
    const port = isEnd ? 'out' : 'in';
    const toPoint = Facade.viewport.toLocal(e.data.global);
    this.clearMovingConnection();

    this.joints.removeElement(connection);
    connection.removeFromParent();

    this.selectionManager.selectElement(element);

    this.createPointerJoint(element, toPoint, port);
  }

  createPointerJoint(iconA, toPoint, port) {
    this.fromIconHeadName = port;
    this.iconA = iconA;
    this.pointerJoint = new ConnectionToCoordinates(this.iconA, toPoint.x, toPoint.y, port);
    this.jointsContainer.addChild(this.pointerJoint);
  }

  onConnectionHeadDroppedToElement(event, connection) {
    const element = this.selectionManager.getClickedElement(event);
    this.onConnectionHeadRelocatedTo(element, { type: CControlPointTypes.FLOW });
    this.selectionManager.attachmentSelection.clear();
  }

  onConnectionHeadRelocatedTo(element, attachmentPoint) {
    if (this.movingConnection) {
      const conneciton = this.movingConnection;
      const isEndPoint = conneciton.detachedData.isEndPoint;
      const aType = attachmentPoint.type;

      if (SharedElementHelpers.IsShape(element)) {
        this.clearMovingConnection();
        return;
      }

      const relocateConnectionCommand = new CommandRelocateConnection(
        this.movingConnection,
        element,
        isEndPoint,
        aType,
      );
      this.commandManager.execute(relocateConnectionCommand);

      conneciton.interactive = true;
      conneciton.interactiveChildren = true;
    }

    this.movingConnection = null;
  }

  clearMovingConnection() {
    if (this.movingConnection) {
      this.movingConnection.detachedData = null;
      this.movingConnection.interactive = true;
      this.movingConnection.interactiveChildren = true;
      this.movingConnection.update();
      this.movingConnection = null;
    }
    this.selectionManager.attachmentSelection.clear();
  }

  // Delegate handler shape Handler
  onShapeInvalidDraw(shape) {
    this.deleteElementById(shape.id);
  }

  // Delegate Handler report view
  onReportViewMoving(reportView) {
    reportView.prepBreakPointsForMove(null, this, false);
  }

  onReportViewPointerDown(e, reportView) {
    reportView.prepBreakPointsForMove(e.data, this);
  }

  onReportViewBeforeContentShift(reportView) {
    const dummyPositon = new PIXI.InteractionData(); // becauase it is not really needed in this case
    reportView.prepBreakPointsForMove(dummyPositon, this);
    reportView.freezeElementsPositions(dummyPositon);
  }

  onReportViewLockedStateChanged(isLocked, reportView) {
    // To avoid the case when we click the lock button with already selected report
    if (isLocked) {
      this.selectionManager.clearSelection();
    }
  }

  onReportViewRemoved(reportView) {
    this.deleteSelection();
    this.culling.removeObject(reportView);
  }

  onReportViewAddSlideClicked(reportView, slide) {
    // Attach objects which are above the slides
    const newReportObjects = this.markReportObjects();
    reportView.removeObjects(newReportObjects);
    reportView.addObjects(newReportObjects);

    this.selectionManager.updateSelection();
    this.culling.updateObject(reportView);
  }

  onReportViewRemoveSlideClicked(reportView, slide, commandBatch) {
    const commands = this.getDeleteCommands(slide.objects);
    for (let i = 0; i < commands.length; i++) {
      const command = commands[i];
      commandBatch.add(command);
    }

    this.commandManager.execute(commandBatch);
  }

  onReportViewSlideAdded(reportView, slide) {
    this.culling.updateObject(reportView);
  }

  onReportViewSlideRemoved(reportView, slide) {
    this.culling.updateObject(reportView);
  }

  onReportViewDownloadPDF(reportView) {
    const blobPromises = this.getReportImages();
    commonSendEventFunction(PR_EVENT_DOWNLOAD_REPORT_CLICKED, {
      title: reportView.title.text,
      images: blobPromises,
    });
  }

  onTextLineHeightChanged(textLabel) {
    // The pixi label needs to contain the latest text that is being typed
    // so that we can update the label in the correct position

    textLabel.updateContentRectangle();
    textLabel.updateBounds();

    // Update the label in the correct position
    // and update the position of the React menu
    this.selectionManager.single.setToolbarPositionPoint();
    this.selectionManager.updateSelection(true, true, true, true);
    this.selectionManager.single.notifyObjectSelected(true);
  }

  onBeforeTextLabelFinishedEditing(textLabel, oldText, newText) {
    if (oldText !== newText) {
      this.updateTextResolution(textLabel);
    }
  }

  onTextLabelFinishedEditing(textLabel, oldText, newText) {}

  updateTextResolution(textLabel) {
    const rect = textLabel.editable.element.getBoundingClientRect();
    const zoom = this.controller.zoomUtility.getZoomLevel();
    const width = rect.width / zoom;
    const height = rect.height / zoom;
    textLabel.updateUsableResolution(width, height);
  }

  onTextLabelEdited(textLabel, isLineCountChanged) {
    // Its important not to be updating the text all the time
    // because of performance issues (fast typing becomes slow)

    if (isLineCountChanged) {
      // We need to update the resolution , because
      // transfer text will cause the texture to be updated
      this.updateTextResolution(textLabel);

      // Its important to update the text
      // so that we calculate the menu position to be
      // right underneath the text
      textLabel.transferText(true);
    }

    return;
  }

  focusElements(funnelConfiguration) {
    if (funnelConfiguration.focusedSteps) {
      this.focusElementsByType(funnelConfiguration.focusedSteps, FILTER_TYPE_DEFAULT_STEP);
    }

    if (funnelConfiguration.focusedStepsCompare) {
      this.focusElementsByType(funnelConfiguration.focusedStepsCompare, FILTER_TYPE_COMPARE_STEP);
    }
  }

  focusElementsByType(steps, type) {
    for (let i = 0; i < steps.length; i++) {
      const stepData = steps[i];
      const element = this.objects.find((itm) => itm.id === stepData.ID);
      if (element) {
        this.selectionManager.focusSelection.focus(element, type);
      }
    }
  }

  setCompareRange(compareRange) {
    this.compareRange = compareRange;
  }

  onCompareRangeUpdated() {
    const objects = this.objects;
    const joints = this.joints;
    const allElements = objects.concat(joints);

    for (let i = 0; i < allElements.length; i++) {
      const element = allElements[i];
      if (element.analyticsManager) {
        element.analyticsManager.setCompareRange(this.compareRange);
      } else if (
        SharedElementHelpers.IsWidget(element) &&
        element.type === EElementTypes.WIDGET_GOALS
      ) {
        element.setCompareRange(this.compareRange);
      }
    }
  }

  setBadgeVisibility(isVisible) {
    for (let i = 0; i < this.objects.length; i++) {
      const object = this.objects[i];
      isVisible && object.notes ? object.showNotesBadge() : object.hideNotesBadge();
    }
  }

  setChecklistVisibility(isVisible) {
    for (let i = 0; i < this.objects.length; i++) {
      const object = this.objects[i];
      isVisible && object.checklistData
        ? object.titleDisplay.showChecklist()
        : object.titleDisplay.hideChecklist();
    }
  }

  destroyAllObjects() {
    for (let i = this.joints.length - 1; i >= 0; i--) {
      this.joints[i].onDestroy();
    }

    for (let i = this.objects.length - 1; i >= 0; i--) {
      this.objects[i].onDestroy();
    }
  }

  saveNumberOfElements() {
    this.heapRecordedNumberOfElements = {
      numberOfSteps: this.objects.length,
      numberOfConnections: this.joints.length,
    };
  }

  hasNumberOfElementsChanged() {
    return (
      this.heapRecordedNumberOfElements.numberOfSteps !== this.objects.length ||
      this.heapRecordedNumberOfElements.numberOfConnections !== this.joints.length
    );
  }

  getNumberOfElements() {
    return {
      numberOfSteps: this.objects.length,
      numberOfConnections: this.joints.length,
    };
  }

  sendNumberOfElementsToHeap() {
    // Send data to heap only if the number of elements has changed
    if (this.hasNumberOfElementsChanged()) {
      const eventData = this.getNumberOfElements();
      sendHeapTracking({
        projectId: this.canvasDataProjectId, // Project id is not needed for this event
        funnelId: this.canvasDataFunnelId,
        eventName: HEAP_EVENTS.NUMBER_OF_ELEMENTS_CHANGED,
        eventData,
      });
      trackCohesive(HEAP_EVENTS.NUMBER_OF_ELEMENTS_CHANGED, {
        ...eventData,
        projectId: this.canvasDataProjectId,
        funnelId: this.canvasDataFunnelId,
      });
      this.saveNumberOfElements(); // save the number of elements in the scene for tracking
    }
  }

  exportObjectsToCSV(funnelConfiguration) {
    const stateData = this.getObjectsWithAnalyticsData();
    const csvExporter = new CSVExporter();
    const csvData = csvExporter.canvasDataToCSVFormat(stateData);
    csvExporter.downloadCSV(csvData, 'export_steps', funnelConfiguration.dateRange);
  }

  exportConnectionsToCSV(funnelConfiguration) {
    const stateData = this.getDataConnectionsStateWithAnalyticsData();
    const csvExporter = new CSVExporter();
    const csvData = csvExporter.canvasConnDataToCSVFormat(stateData);
    csvExporter.downloadCSV(csvData, 'export_conversions', funnelConfiguration.dateRange);
  }

  toggleAnalytics(isVisible) {
    this.isAnalyticsVisible = isVisible;

    this.toggleAnalyticsForecasting();

    // trigger analytics to repaint the data on the canvas
    this.controller.processAnalyticsData();
  }

  toggleForecasting(isVisible) {
    this.isForecastingVisible = isVisible;

    if (isVisible) {
      this.calculateForecastingData();
    } else {
      this.removeForecastingData();
    }

    this.toggleAnalyticsForecasting();

    // trigger analytics to repaint the data on the canvas
    this.controller.processAnalyticsData();
  }

  removeForecastingData() {
    for (let i = 0; i < this.objects.length; i++) {
      if (SharedElementHelpers.IsStep(this.objects[i])) {
        this.objects[i].forecastingCalculatedData = null;
      }
    }

    for (let i = 0; i < this.joints.length; i++) {
      const connection = this.joints[i];
      connection.forecastingCalculatedData = null;
    }
  }

  calculateForecastingData() {
    this.forecastingController.calculateForecasting();
  }

  toggleAnalyticsForecasting() {
    this.toggleDataPanels(this.isAnalyticsVisible || this.isForecastingVisible);
  }

  toggleDataPanels(isVisible) {
    const objects = this.objects;
    for (let i = 0; i < objects.length; i++) {
      if (SharedElementHelpers.IsStep(objects[i])) {
        objects[i].footer.updateVisibility(isVisible);
        objects[i].updateAttachPoints();
        objects[i].updateHitArea();
        BaseSignals.moveObject.dispatch(objects[i]);
      }

      if (SharedElementHelpers.IsWidget(objects[i])) {
        objects[i].onAnalyticsSwitched(isVisible);
      }
    }

    const joints = this.joints;
    for (let i = 0; i < joints.length; i++) {
      joints[i].footer.updateVisibility(isVisible);
      joints[i].updateGhostPointPosition(isVisible);
    }
  }

  onFlowToggle(isVisible) {
    this.flowController.onFlowToggle(isVisible);
  }

  onAnalyticsDataReceived(data) {
    this.analyticsData = data;
    this.flowController.onAnalyticsDataReceived(data);
    if (MainStorage.isForecastingVisible()) {
      this.calculateForecastingData();
    }
  }

  onBenchmarkFromAnalytics() {
    // set analytics data
    if (this.analyticsData) {
      this.forecastingController.mapFromAnalytics();
      this.calculateForecastingData();
      this.controller.processAnalyticsData();
    }
  }

  onWindowPointerDown(e) {
    for (let i = 0; i < this.objects.length; i++) {
      const element = this.objects[i];
      element.onWindowPointerDown(e);
    }
  }

  onWindowPointerUp(e) {
    // notify elements that the pointer is up , its for detecting clicks outside

    for (let i = 0; i < this.objects.length; i++) {
      const element = this.objects[i];
      element.onWindowPointerUp(e);
    }
  }

  getGraphData() {
    const loopDetector = new LoopDetector();
    loopDetector.loadDataFromCanvas(this.objects, this.joints);

    const loops = loopDetector.findCycles();
    const roots = loopDetector.findRootNodes();

    return {
      loops,
      roots,
    };
  }
}
