import { getExplorerRequestBody } from 'react-project/Helpers/requestConstruction';
import RequestService from 'react-project/Helpers/RequestService';
import { ActionNames } from 'react-project/redux/actionNamesConstant';
import { createAction } from 'redux-actions';
import { EStepConnectionPort } from 'shared/CSharedCategories';
import { PAGINATION_LIMIT } from 'shared/CSharedConstants';

export const setExplorerPageParameters = createAction(ActionNames.setExplorerPageParameters);
export const setExplorerItemsConfig = createAction(ActionNames.setExplorerItemsConfig);
export const setExplorerPageNumber = createAction(ActionNames.setExplorerPageNumber);
export const setExplorerLoadingStatus = createAction(ActionNames.setExplorerLoadingStatus);
export const setLoadMoreSourceItemsConfig = createAction(ActionNames.setLoadMoreSourceItemsConfig);
export const setExplorerLoadingCount = createAction(ActionNames.setExplorerLoadingCount);

const requestService = new RequestService();

const isConnectionPortIn = (port) => port === EStepConnectionPort.IN;
const isFirstPage = (page) => page === 1;

export const onSetExplorerItemsConfig = (props, disableCache = false) => {
  return async (dispatch) => {
    const {
      port,
      funnel,
      pageNumber = 1,
      funnelConfiguration,
      focusedSteps,
      currentStep,
      explorerItemsConfig,
      mounted,
      search,
      axiosSource,
    } = props;
    // set counter
    dispatch(setExplorerLoadingCount(1));
    dispatch(setExplorerLoadingStatus(true));

    let newExplorerItemsConfig;
    const exploredStep =
      currentStep && port ? { ...currentStep.object, ID: currentStep.stepId } : null;

    const reqBody = getExplorerRequestBody({
      funnelConfiguration,
      search,
      focusedSteps,
      pageNumber,
      exploredStep,
      limit: PAGINATION_LIMIT,
    });

    const dataTransformationPromise = requestService.getTrafficExplorerData(
      funnel.projectId,
      reqBody,
      disableCache,
      axiosSource,
    );

    dataTransformationPromise
      .then((responseData) => {
        if (!mounted) {
          return;
        }

        const cachedConfig = isFirstPage(pageNumber)
          ? { PAGE: [], EVENT: [], PAGE_ALL: [], EVENT_ALL: [] }
          : explorerItemsConfig;

        // New data below needs to be appended to an array instead of replacing
        if (responseData) {
          newExplorerItemsConfig = {
            ...explorerItemsConfig,
            PAGE: isConnectionPortIn(port)
              ? [...cachedConfig['PAGE'], ...responseData.previous_pages.list]
              : [...cachedConfig['PAGE'], ...responseData.next_pages.list],
            PAGE_ALL: isConnectionPortIn(port)
              ? [...cachedConfig['PAGE_ALL'], ...responseData.previous_pages_all.list]
              : [...cachedConfig['PAGE_ALL'], ...responseData.next_pages_all.list],
            EVENT: isConnectionPortIn(port)
              ? [...cachedConfig['EVENT'], ...responseData.previous_actions.list]
              : [...cachedConfig['EVENT'], ...responseData.next_actions.list],
            EVENT_ALL: isConnectionPortIn(port)
              ? [...cachedConfig['EVENT_ALL'], ...responseData.previous_actions_all.list]
              : [...cachedConfig['EVENT_ALL'], ...responseData.next_actions_all.list],
            SOURCE: {
              commonParameters: responseData.page_parameters.common_parameters,
              customParameters: responseData.page_parameters.custom_parameters,
            },
            SOURCE_ALL: {
              commonParameters: responseData.page_parameters_all.common_parameters,
              customParameters: responseData.page_parameters_all.custom_parameters,
            },
            direct_traffic_all: responseData.direct_traffic_all,
            direct_traffic: responseData.direct_traffic,
            has_more_actions: isConnectionPortIn(port)
              ? responseData.previous_actions_all.has_more
              : responseData.next_actions.has_more,
            has_more_pages: isConnectionPortIn(port)
              ? responseData.previous_pages.has_more
              : responseData.next_pages.has_more,
            has_more_actions_all: isConnectionPortIn(port)
              ? responseData.previous_actions_all.has_more
              : responseData.next_actions_all.has_more,
            has_more_pages_all: isConnectionPortIn(port)
              ? responseData.previous_pages_all.has_more
              : responseData.next_pages_all.has_more,
          };
        } else {
          newExplorerItemsConfig = {
            ...explorerItemsConfig,
          };
        }
        dispatch(setExplorerLoadingCount(-1));
        dispatch(setExplorerItemsConfig(newExplorerItemsConfig));
        dispatch(setExplorerLoadingStatus(false));
      })
      .catch(() => {
        dispatch(setExplorerLoadingCount(-1));
        dispatch(setExplorerLoadingStatus(false));
      });
  };
};

export const onSetExplorerPageNumber = (newValue) => {
  return async (dispatch) => {
    dispatch(setExplorerPageNumber(newValue));
  };
};

export const onSetExplorerItemsConfigLoadMore = (newValue) => {
  return async (dispatch) => {
    dispatch(setExplorerItemsConfig(newValue));
    dispatch(setExplorerLoadingStatus(false));
  };
};
