import React from 'react';
import PropTypes from 'prop-types';
import styles from 'react-project/components/dropdown/Dropdown.module.scss';
import { HotKey } from 'react-project/components/dropdown/HotKey';
import cls from 'classnames';
import { getHotkeyForAction } from 'react-project/Util/HotkeysUtil';

const DropdownItemComponent = ({
  label,
  actionName,
  icon,
  disabled = false,
  active = false,
  isFillActive,
  ...other
}) => {
  const hotkey = getHotkeyForAction(actionName);

  const getActiveClass = (active, isFillActive) => {
    if (active) {
      return isFillActive ? styles.ActiveDropdownItemFill : styles.ActiveDropdownItem;
    }
    return null;
  };

  const activeClass = getActiveClass(active, isFillActive);

  return (
    <div
      className={cls(styles.DropdownItem, { [styles.DropdownItemDisabled]: disabled })}
      {...other}
    >
      <div
        style={{ pointerEvents: 'none' }}
        className={cls(styles.DropdownItemLabelWrapper, activeClass)}
      >
        {icon && <div className={styles.DropdownItemIcon}>{icon}</div>}
        <div className={styles.DropdownItemLabel}>{label}</div>
      </div>
      {hotkey && <HotKey {...hotkey} />}
    </div>
  );
};

DropdownItemComponent.propTypes = {
  label: PropTypes.any,
  hotkey: PropTypes.shape({
    keys: PropTypes.array,
    separator: PropTypes.string,
  }),
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
};

export const DropdownItem = DropdownItemComponent;
