import React from 'react';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import plainTableStyles from './PlaneTable.module.scss';
import cls from 'classnames';
import { When } from 'react-project/Util/When';
import { iconClose } from 'react-project/assets/Icons';

export const PlaneTable = ({ rows, onDeleteBtn, bodyStyle, styles = plainTableStyles }) => {
  const gridTemplateColumns = rows.columnWidths
    ? `${rows.columnWidths.join(' ')}`
    : `repeat(${rows.columnsNumber}, auto)`;

  return (
    <div className={styles.StripedTable}>
      {rows.rowHeaders && (
        <div className={styles.TableHead} style={{ gridTemplateColumns }}>
          {rows.rowHeaders.keys.map((key, index) => {
            return <div key={`${key}-${index}`}>{key}</div>;
          })}
        </div>
      )}
      <div className={styles.TableBody} style={bodyStyle}>
        {rows.items.map((item, index) => {
          if (item.rowHtml) {
            return (
              <div
                className={cls(styles.Row)}
                style={{ gridTemplateColumns, ...rows.style }}
                key={`row-${index}`}
              >
                {item.rowHtml}
                <When condition={item.hasDelete}>
                  <div
                    onClick={() => {
                      onDeleteBtn(index);
                    }}
                    className={styles.RowDeleteBtn}
                  >
                    {' '}
                    {iconClose}{' '}
                  </div>
                </When>
              </div>
            );
          } else {
            const row = values(item);
            return (
              <div
                className={styles.Row}
                style={{ gridTemplateColumns, ...rows.style }}
                key={`row-${index}`}
              >
                {row.map((cell, index) => {
                  return <div key={`cell-${index}`}>{cell}</div>;
                })}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

PlaneTable.defaultProps = {
  bodyStyle: {},
};

PlaneTable.propTypes = {
  rows: PropTypes.shape({
    columnWidths: PropTypes.arrayOf(PropTypes.string),
    columnsNumber: PropTypes.number.isRequired,
    items: PropTypes.array.isRequired,
    rowHeaders: PropTypes.object,
  }).isRequired,
};
