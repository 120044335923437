import produce from 'immer';
import React, { useEffect, useRef, useState } from 'react';
import { iconSearch, iconSettings, iconThumbnail } from 'react-project/assets/Icons';
import { FileUploader } from 'react-project/components/fileUploader/FileUploader';
import { IconList } from 'react-project/components/iconList/IconList';
import { PopUpWrapper } from 'react-project/components/popUp/PopUpWrapper';
import { RefreshButton } from 'react-project/components/refreshButton/RefreshButton';
import RequestService from 'react-project/Helpers/RequestService';
import {
  setNewLabel,
  setNewUrl,
  updateAnalyticsWhenUrlChanged,
} from 'react-project/redux/current-step/actions';
import {
  selectCurrentStep,
  selectThumbnailIsLoading,
} from 'react-project/redux/current-step/selectors';
import { setCustomIconsAction } from 'react-project/redux/custom-icons/actions';
import { selectCustomIcons } from 'react-project/redux/custom-icons/selectors';
import { selectFunnelConfiguration } from 'react-project/redux/funnel-configuration/selectors';
import { addLastInputValue, setInputValue } from 'react-project/redux/inputs/actions';
import { selectInputValues } from 'react-project/redux/inputs/selectors';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import stepModalStyles from 'react-project/StepsModal/StepsModal.module.scss';
import styles from 'react-project/Toolbar/Toolbar.module.scss';
import { When } from 'react-project/Util/When';
import { useDispatch, useSelector } from 'react-redux';
import { ActionTypes, PropertyType, THUMBNAIL_TYPE } from 'shared/CSharedConstants';
import { PR_EVENT_THUMBNAIL_REQUESTED, RP_EVENT_ICON_CHOSEN } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';

const TITLE = 'Style';

export const StepSettings = (props) => {
  const { showUrlBlock, iconType, stepId, projectId, notifyIfValueChanged, onClose } = props;
  const [searchText, setSearchText] = useState('');
  const [isUploaderOpened, setIsUploaderOpened] = useState(null);

  const dispatch = useDispatch();
  const currentStep = useSelector(selectCurrentStep);
  const funnelConfiguration = useSelector(selectFunnelConfiguration);
  const customIcons = useSelector(selectCustomIcons);
  const canvasPermissions = useSelector(selectCanvasPermissions);

  const inputs = useSelector(selectInputValues);

  const labelRef = useRef();
  const urlRef = useRef();
  const searchRef = useRef();

  useEffect(() => {
    if (labelRef && labelRef.current) {
      dispatch(
        setInputValue({
          type: PropertyType.LABEL,
          value: labelRef.current.value,
          stepId: currentStep.stepId,
        }),
      );
    }
    if (urlRef && urlRef.current) {
      dispatch(
        setInputValue({
          type: PropertyType.URL,
          value: urlRef.current.value,
          stepId: currentStep.stepId,
        }),
      );
    }
  }, [labelRef, urlRef]);

  const onLabelChange = (e) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }
    dispatch(setNewLabel({ label: e.target.value, currentStep }));
    dispatch(addLastInputValue({ type: PropertyType.LABEL, value: labelRef.current.value }));
  };

  const onGetThumbnail = (e) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }
    commonSendEventFunction(PR_EVENT_THUMBNAIL_REQUESTED, { currentStep });
  };

  const onLabelFocus = (e) => {
    dispatch(
      setInputValue({
        type: PropertyType.LABEL,
        value: e.target.value,
        stepId: currentStep.stepId,
      }),
    );
  };

  const onLabelBlur = (e) => {
    const currentValue = e.target.value;
    const pageLabel = inputs.find((el) => el.type === PropertyType.LABEL);
    const data = {
      type: PropertyType.LABEL,
      previousValue: pageLabel.previousValue,
      currentValue: currentValue,
      value: currentValue,
      stepId: currentStep.stepId,
    };
    if (notifyIfValueChanged(data)) {
      dispatch(setInputValue(data));
    }
  };

  const onUrlFocus = (e) => {
    dispatch(
      setInputValue({ type: PropertyType.URL, value: e.target.value, stepId: currentStep.stepId }),
    );
  };

  const onUrlFocusOut = (e) => {
    const currentValue = e.target.value;
    const pageURL = inputs.find((el) => el.type === PropertyType.URL);
    const data = {
      type: PropertyType.URL,
      previousValue: pageURL.previousValue,
      currentValue: currentValue,
      value: currentValue,
      stepId: currentStep.stepId,
    };
    if (notifyIfValueChanged(data)) {
      dispatch(setInputValue(data));
    }

    dispatch(
      updateAnalyticsWhenUrlChanged({
        url: currentValue,
        currentStep,
        funnelConfiguration,
        projectId,
      }),
    );
  };

  const onUrlChanged = (e) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }
    dispatch(setNewUrl({ url: e.target.value, currentStep }));
    dispatch(addLastInputValue({ type: PropertyType.URL, value: urlRef.current.value }));
  };

  const thumbnailIsLoading = useSelector(selectThumbnailIsLoading);

  const urlValue = currentStep.object.url;
  const label = currentStep.object.label;

  const onIconClick = ({ src, title, isCustom }) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }
    commonSendEventFunction(RP_EVENT_ICON_CHOSEN, {
      stepId: stepId,
      texturePath: src,
      title: title,
      isCustom,
    });
  };

  const getCustomIcons = (type) => {
    const iconType = type.toLowerCase();
    return customIcons
      .filter((item) => item.type === iconType)
      .sort((a, b) => {
        if (a.created_at === b.created_at) {
          return 0;
        }
        return a.created_at > b.created_at ? 1 : -1;
      });
  };

  const onAddIconClick = (data) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }
    setIsUploaderOpened(data);
  };

  const onFileUploaderClose = (e) => {
    setIsUploaderOpened(null);
  };

  const onFileUploaded = (fileID, fileName, imageURL, uploadType) => {
    if (imageURL) {
      const imageData = {
        id: fileID,
        name: fileName,
        path: imageURL,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        type: uploadType.toString().toLowerCase(),
      };

      const newItems = produce(customIcons, (draft) => {
        draft.push(imageData);
      });

      dispatch(setCustomIconsAction(newItems));
    }
    setIsUploaderOpened(null);
  };

  const onIconCloseClick = async (e) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }

    const requestService = new RequestService();

    const iconId = e.detail.id;
    await requestService.deleteIcon(iconId);

    const newItems = produce(customIcons, (draft) => {
      const index = draft.findIndex((item) => item.id === iconId);
      if (index !== -1) draft.splice(index, 1);
    });

    dispatch(setCustomIconsAction(newItems));
  };

  return (
    <>
      <PopUpWrapper
        icon={iconSettings}
        title={TITLE}
        showCloseIcon={true}
        onClosePopup={() => onClose()}
        cancel={'rndCancel'}
        minWidth="400px"
      >
        <div className="rndCancel cursor-auto h-full">
          <div className={styles.Label}>
            <div>
              <label className={styles.SideBarLabel}>Label</label>
              <div className={styles.LabelWrapper}>
                <input
                  autoFocus={true}
                  ref={labelRef}
                  className={styles.RsInput}
                  type="text"
                  value={label}
                  onChange={onLabelChange}
                  onFocus={onLabelFocus}
                  onBlur={onLabelBlur}
                  disabled={canvasPermissions.isReadonlyAccess}
                />
              </div>
            </div>
            <When condition={showUrlBlock}>
              <div style={{ marginTop: '15px' }}>
                <label className={styles.SideBarLabel}>Create a thumbnail from a URL</label>
                <div className={styles.LabelWrapper}>
                  <input
                    ref={urlRef}
                    value={urlValue}
                    onChange={onUrlChanged}
                    type="text"
                    onFocus={onUrlFocus}
                    onBlur={onUrlFocusOut}
                    className={`${styles.RsInput} ${styles.UrlInput}`}
                    disabled={canvasPermissions.isReadonlyAccess}
                  />

                  <div className={styles.ThumbnailIcon} onClick={onGetThumbnail}>
                    {thumbnailIsLoading ? (
                      <RefreshButton
                        type={THUMBNAIL_TYPE}
                        analyticsStatus="success"
                        loading={true}
                      />
                    ) : (
                      iconThumbnail
                    )}
                  </div>
                </div>
              </div>
            </When>
          </div>
          <When condition={currentStep.object.actionType !== ActionTypes.COMMERCE}>
            <div className={stepModalStyles.SearchSection}>
              <div className={styles.IconsListBlock}>
                {iconSearch}
                <input
                  placeholder="Search Icons"
                  ref={searchRef}
                  className={styles.Search}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  type="text"
                />
              </div>
            </div>
            <IconList
              type={iconType}
              parentComponent="StepSettings"
              onIconClick={onIconClick}
              onDragEnd={() => false}
              textSearch={searchText}
              customUploadedIcons={getCustomIcons(iconType)}
              onAddIconClick={onAddIconClick}
              onCloseClick={onIconCloseClick}
              isCommerceAllowed={false}
            />
          </When>
        </div>
      </PopUpWrapper>

      <When condition={isUploaderOpened}>
        <FileUploader
          uploadType={isUploaderOpened?.type}
          onFileUploaderClose={onFileUploaderClose}
          onFileUploaded={onFileUploaded}
        ></FileUploader>
      </When>
    </>
  );
};
