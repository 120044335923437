export const HEAP_EVENTS = {
  ANALYTICS_ENABLED: 'analytics_enabled',
  ADDED_COMPARE_DATA: 'added_compare_data',
  REPORT_DOWNLOADED: 'report_downloaded',
  FILTER_APPLIED: 'filter_applied',
  COMPARE_MODE_FILTER: 'compare_mode_filter',
  EXPLORE_STEP: 'explore_step',
  MAP_STEP: 'map_step',
  OPEN_CANVAS: 'open_canvas',
  PLANNING_ENABLED: 'planning_enabled',
  NOTE_CREATED: 'note_created',
  NOTE_UPDATED: 'note_updated',
  PEOPLE_EXPORT: 'people_export',
  OPEN_CANVAS_TOUR: 'open_canvas_tour',
  CANVAS_TOUR_COMPLETED: 'canvas_tour_completed',
  GOALS_ENABLED: 'goals_enabled',
  NUMBER_OF_ELEMENTS_CHANGED: 'number_of_elements_changed',
  CHECKLIST_CHANGED: 'checklist_changed',
  ENABLE_FORECAST: 'enable_forecast',
};
