import { PR_EVENT_FUNNEL_CHANGED } from 'shared/CSharedEvents';
import { cloneData, commonSendEventFunction } from 'shared/CSharedMethods';

export default class CommandTextContent {
  constructor(textObject, oldText, newText, selectionManager) {
    this.textObject = textObject;
    this.selectionManager = selectionManager;
    this.oldText = cloneData(oldText);
    this.newText = cloneData(newText);

    this.isExecuted = false;
  }

  execute() {
    if (!this.isExecuted) {
      this.textObject.setText(this.newText);
      window.app.needsRendering();
      this.isExecuted = true;
      this.selectionManager.single.updateSelectedElementFrame();
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
    }
  }

  revert() {
    if (this.isExecuted) {
      this.textObject.setText(this.oldText);
      window.app.needsRendering();
      this.isExecuted = false;
      this.selectionManager.single.updateSelectedElementFrame();
      commonSendEventFunction(PR_EVENT_FUNNEL_CHANGED);
    }
  }
}
