import React from 'react';
import { ProgressBar } from 'react-project/components/progressbar/progressbar';

export const Header = ({ completedPercent }) => {
  return (
    <>
      <div style={{ fontFamily: 'Roboto', textAlign: 'left', weight: '700', fontSize: '19px' }}>
        Get Started With Funnelytics
      </div>
      <div style={{ display: 'flex', justifyContent: 'left', padding: '20px 0 0 0' }}>
        <ProgressBar
          percent={completedPercent}
          customStyles={{ height: '26px', width: '82%' }}
          showPercentage={false}
        />
        <div style={{ paddingLeft: '6px' }}>{`${completedPercent}%`}</div>
      </div>
    </>
  );
};
