import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import cls from 'classnames';
import styles from './DropDown.module.scss';
import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';
import { iconArrow } from 'react-project/assets/Icons';

export const DropDown = ({
  selectedItem,
  itemList = [],
  placeholder = 'Please Select',
  onSelectionChanged,
  onPointerDown,
  innerRef,
  disabled = false,
  classStyling = {},
  style,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isOpenRef = useRef();
  const dropDownRef = useRef();
  const listRef = useRef();
  const dropDownStyle = { ...styles, ...classStyling };

  const handleScroll = (e) => {
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll, true);
    return () => {
      document.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  useEffect(() => {
    isOpenRef.current = isOpen;
  }, [isOpen]);

  useLayoutEffect(() => {
    // Calculate where to position the drop down list
    // it needs to always show below the drop down
    // and it needs to fit inside the window.

    const dropDown = dropDownRef.current;
    const list = listRef.current;
    const dropDownRect = dropDown.getBoundingClientRect();
    const listRect = list.getBoundingClientRect();
    let x = dropDownRect.x - listRect.x;
    let y = dropDownRect.y - listRect.y + dropDownRect.height;

    // Check if the list goes ouside the viewport
    let overflowX = listRect.x + x + listRect.width - window.innerWidth;
    let overflowY = listRect.y + y + listRect.height - window.innerHeight;

    // if it does , then readjust it to fit in
    if (overflowX > 0) {
      x -= overflowX;
    }

    if (overflowY > 0) {
      y -= overflowY;
    }

    const transform = `translate(${x}px,${y}px)`;

    // Only apply this on a rendering when the component is opened
    // otherwise it will introduce a drift ( shft + shift + shift ...) in its position
    if (isOpen) {
      list.style.transform = transform;
    } else {
      list.style.transform = '';
    }
  });

  const onItemSelected = (item) => {
    onSelectionChanged(item);
    setIsOpen(false);
  };

  const onClickOutside = () => {
    setIsOpen(false);
  };

  const toggle = () => {
    if (disabled) {
      return;
    }
    setIsOpen(!isOpen);
  };

  // Expose API
  if (innerRef) {
    innerRef.current = {
      open: () => {
        setIsOpen(true);
      },
      close: () => {
        setIsOpen(false);
      },
      get isOpen() {
        return isOpenRef.current;
      },
      get selectedItem() {
        return selectedItem;
      },
    };
  }

  const onElementPointerDown = (e) => {
    if (onPointerDown) {
      onPointerDown(e);
    }
  };

  return (
    <ClickOutsideCustomComponent onClickOutside={() => onClickOutside()}>
      <div
        ref={dropDownRef}
        onPointerDown={onElementPointerDown}
        className={cls(dropDownStyle.DropDown, disabled ? dropDownStyle.DropDownDisabled : null)}
        style={style}
      >
        <div className={dropDownStyle.DropDownHeader} onClick={(e) => toggle()}>
          <span className={dropDownStyle.HeaderText}>
            {selectedItem ? selectedItem.label : placeholder}
          </span>
          <span
            className={cls(
              isOpen ? dropDownStyle.DropDownOpenedHeader : dropDownStyle.DropDownClosedHeader,
            )}
          >
            {iconArrow}
          </span>
        </div>
        <div
          ref={listRef}
          className={cls(dropDownStyle.DropDownList, isOpen ? dropDownStyle.DropDownActive : null)}
        >
          {itemList.map((item, index) => {
            return (
              <div
                key={index}
                className={dropDownStyle.DropDownItem}
                onClick={(e) => onItemSelected(item)}
              >
                {item.label}
              </div>
            );
          })}
        </div>
      </div>
    </ClickOutsideCustomComponent>
  );
};
