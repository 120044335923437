import { CControlPointTypes } from 'pixi-project/base/containers/CContainerConstants';
import * as PIXI from 'pixi.js';
import { SELECTION_BOUNDARY_GAP } from '../Styles';
import AttachmentPoint from './AttachmentPoint';

const MARKS_SCALE = 1;
const SCALE_THRESHOLD = 0.7;

const frameTypes = [
  CControlPointTypes.LEFT,
  CControlPointTypes.RIGHT,
  CControlPointTypes.TOP,
  CControlPointTypes.BOTTOM,
  CControlPointTypes.CENTER,
];

export default class SelectionAttachmentFrame extends PIXI.Container {
  constructor(element, delegate = null) {
    super();

    this.graphics = new PIXI.Graphics();
    this.addChild(this.graphics);

    this.delegate = delegate;

    this.element = element;
    this.visibleFrame = true;
    this.frame = { x: 0, y: 0, width: 0, height: 0, lineColor: 0 };

    this.attachmentPoints = [];
    this.attachmentPointsPositions = {
      [CControlPointTypes.LEFT]: [0, 0.5],
      [CControlPointTypes.RIGHT]: [1, 0.5],
      [CControlPointTypes.TOP]: [0.5, 0],
      [CControlPointTypes.BOTTOM]: [0.5, 1],
      [CControlPointTypes.CENTER]: [0.5, 0.5],
    };

    this.init();
  }

  init() {
    for (let i = 0; i < frameTypes.length; i++) {
      const type = frameTypes[i];
      var attachmentPoint = new AttachmentPoint(type, this.delegate, this.element);
      this.addChild(attachmentPoint);
      this.attachmentPoints.push(attachmentPoint);
    }
  }

  getCenterAttachmentPoint() {
    for (let i = 0; i < this.attachmentPoints.length; i++) {
      const attachmentPoint = this.attachmentPoints[i];
      if (attachmentPoint.type === CControlPointTypes.CENTER) {
        return attachmentPoint;
      }
    }

    return null;
  }

  draw(lineColor, lineWidth) {
    const endPoint = this.element.getEndPoint();
    const viewportScale = window.app.viewport.scaled;
    const padding = SELECTION_BOUNDARY_GAP * viewportScale;

    if (this.visibleFrame) {
      const width = endPoint.width + 2 * padding;
      const height = endPoint.height + 2 * padding;
      this.drawFrame(-padding, -padding, width, height, lineWidth, lineColor);
    }

    this.graphics.visible = this.visibleFrame;

    // Update the PWP marks
    this.positionAttachmentPoints();
    this.scaleAttachmentPoints(viewportScale);
  }

  positionAttachmentPoints() {
    for (let i = 0; i < this.attachmentPoints.length; i++) {
      const attachmentPoint = this.attachmentPoints[i];
      attachmentPoint.x =
        this.frame.x + this.frame.width * this.attachmentPointsPositions[attachmentPoint.type][0];
      attachmentPoint.y =
        this.frame.y + this.frame.height * this.attachmentPointsPositions[attachmentPoint.type][1];
    }
  }

  scaleAttachmentPoints(viewportScale) {
    // If the viewport scale (zoom) is bellow a certain threshold
    // Then start scaling the buttons down with it
    // const markerScale = this.calculateTargetScale(viewportScale);
    // for (let i = 0; i < this.attachmentPoints.length; i++) {
    //     const attachmentPoint = this.attachmentPoints[i];
    //     attachmentPoint.scale.set(markerScale, markerScale);
    // }
  }

  calculateTargetScale(viewportScale) {
    const belowThreshold = viewportScale < SCALE_THRESHOLD;
    const scaledValue = viewportScale * MARKS_SCALE;
    return belowThreshold ? scaledValue : MARKS_SCALE;
  }

  drawFrame(x, y, width, height, lineWidth, lineColor) {
    if (this.isFrameEqual(x, y, width, height, lineColor)) {
      // No need to redraw the frame
      return;
    } else {
      this.setFrame(x, y, width, height, lineColor);
    }

    this.graphics.clear();
    this.graphics.lineStyle(lineWidth, lineColor, 1);
    this.graphics.drawRoundedRect(x, y, width, height, 6);
  }

  isFrameEqual(x, y, width, height, lineColor) {
    return (
      this.frame.x === x &&
      this.frame.y === y &&
      this.frame.width === width &&
      this.frame.height === height &&
      this.frame.lineColor === lineColor
    );
  }

  setFrame(x, y, width, height, lineColor) {
    this.frame.x = x;
    this.frame.y = y;
    this.frame.width = width;
    this.frame.height = height;
    this.frame.lineColor = lineColor;
  }
}
