import { handleActions } from 'redux-actions';
import { produce } from 'immer';
import {
  setApplyButtonEnabled,
  setFilter,
  setCurrentFilter,
  defaultFilters,
  setCurrentCommonFilter,
  setPreviousCommonFilter,
  setIsFiltersLoaded,
  setApplyCompareButtonEnabled,
} from 'react-project/redux/filters/actions';

import { cloneData } from 'shared/CSharedMethods';

const initialState = {
  currentFilters: {
    ...cloneData(defaultFilters),
  },
  previousFilters: {
    ...cloneData(defaultFilters),
  },
  applyButtonEnabled: false,
  applyCompareButtonEnabled: false,
  isLoaded: false,
};

export default handleActions(
  {
    [setFilter.toString()]: (state, action) => {
      return produce(state, (draft) => {
        draft[action.payload.type][action.payload.filterName] = action.payload.value;
      });
    },
    [setCurrentCommonFilter.toString()]: (state, action) => {
      return produce(state, (draft) => {
        const commonFilters = {
          commonFilters: {
            ...action.payload,
            inputStart: state.currentFilters.commonFilters.inputStart,
            inputFinish: state.currentFilters.commonFilters.inputFinish,
          },
        };
        draft.currentFilters = {
          ...state.currentFilters,
          ...commonFilters,
        };
      });
    },
    [setPreviousCommonFilter.toString()]: (state, action) => {
      return produce(state, (draft) => {
        const previousFilters = {
          ...state.previousFilters,
          commonFilters: {
            ...action.payload,
            inputStart: state.currentFilters.commonFilters.inputStart,
            inputFinish: state.currentFilters.commonFilters.inputFinish,
          },
        };
        draft.previousFilters = previousFilters;
      });
    },
    [setApplyButtonEnabled.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.applyButtonEnabled = action.payload;
      }),
    [setApplyCompareButtonEnabled.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.applyCompareButtonEnabled = action.payload;
      }),
    [setIsFiltersLoaded.toString()]: (state, action) =>
      produce(state, (draft) => {
        draft.isLoaded = action.payload;
      }),
    [setCurrentFilter.toString()]: (state, action) => {
      return produce(state, (draft) => {
        draft.currentFilters = {
          commonFilters: {
            ...action.payload.filter,
            inputStart: action.payload.dateRange.min,
            inputFinish: action.payload.dateRange.max,
            session: action.payload.filter.session
              ? { id: action.payload.filter.session, intId: action.payload.filter.session }
              : null,
            focusedSteps: action.payload.focusedSteps,
            contributionWindow: action.payload.filter.contributionWindow,
            focusLogicalOperator: action.payload.filter.focusLogicalOperator,
          },
          peopleCompareFilters: {
            ...action.payload.compareFilter,
            session: action.payload.compareFilter.session
              ? {
                  id: action.payload.compareFilter.session,
                  intId: action.payload.compareFilter.session,
                }
              : null,
            focusedSteps: action.payload.focusedStepsCompare,
          },
          dateCompareFilter: {
            inputStart: action.payload.compareDateRange.min,
            inputFinish: action.payload.compareDateRange.max,
          },
        };
        draft.previousFilters = {
          commonFilters: {
            ...action.payload.filter,
            inputStart: action.payload.dateRange.min,
            inputFinish: action.payload.dateRange.max,
            session: action.payload.filter.session
              ? { id: action.payload.filter.session, intId: action.payload.filter.session }
              : null,
            focusedSteps: action.payload.focusedSteps,
            contributionWindow: action.payload.filter.contributionWindow,
            focusLogicalOperator: action.payload.filter.focusLogicalOperator,
          },
          peopleCompareFilters: {
            ...action.payload.compareFilter,
            session: action.payload.compareFilter.session
              ? {
                  id: action.payload.compareFilter.session,
                  intId: action.payload.compareFilter.session,
                }
              : null,
            focusedSteps: action.payload.focusedStepsCompare,
          },
          dateCompareFilter: {
            inputStart: action.payload.compareDateRange.min,
            inputFinish: action.payload.compareDateRange.max,
          },
        };
      });
    },
  },
  initialState,
);
