import cls from 'classnames';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { iconArrow } from 'react-project/assets/Icons';
import { Disclosure } from 'react-project/components/disclosure/Disclosure';
import { Dropdown } from 'react-project/components/dropdown/Dropdown';
import { SearchInput } from 'react-project/components/input/SearchInput/SearchInput';
import {
  setNewCurrentStepActionName,
  setNewCurrentStepActionType,
  setNewCurrentStepFilterParams,
} from 'react-project/redux/current-step/actions';
import { selectCurrentStep } from 'react-project/redux/current-step/selectors';
import { When } from 'react-project/Util/When';
import { useDispatch, useSelector } from 'react-redux';
import { ActionTypes } from 'shared/CSharedConstants';
import { RP_ANALYTICS_FILTER_DATA_CHANGED, RP_EVENT_EDIT_OBJECT } from 'shared/CSharedEvents';
import { commonSendEventFunction } from 'shared/CSharedMethods';
import { ClickOutsideCustomComponent } from '../../../Util/ClickOutsideCustom';
import styles from '../TrackingTabAction.module.scss';
import { selectCanvasPermissions } from 'react-project/redux/user/selectors';
import { UILock } from 'react-project/components/uilock/UILock';
import {
  COMMERCE_ACTION_NAME,
  DEAL_ACTION_NAME,
  FORM_ACTION_NAME,
  MEETING_ACTION_NAME,
} from 'react-project/Constants/specialActions';

const DropdownTrigger = ({ isOpen, value, placeholder, ...rest }) => {
  return (
    <div className={styles.CustomActionDropdownTrigger} {...rest}>
      <span
        className={cls(
          styles.ConditionDropdownTriggerValue,
          styles.CustomConditionDropdownTriggerValue,
        )}
      >
        {value || placeholder}
      </span>
      <div
        className={cls(styles.TriggerIcon, styles.CustomActionDropdownTriggerIcon, {
          [styles.TriggerIconOpened]: isOpen,
        })}
      >
        {iconArrow}
      </div>
    </div>
  );
};

export const CustomActionDropdown = ({
  openModal,
  modalOpened,
  actionTypes,
  selectedActionType,
  setSelectedActionType,
  isLoading,
  dropdownOpened,
  setDropdownOpened,
}) => {
  const [actionTypeSearchQuery, setActionTypeSearchQuery] = useState('');
  const [actionsDisclosureOpened, setActionsDisclosureOpened] = useState(false);
  const currentStep = useSelector(selectCurrentStep);
  const canvasPermissions = useSelector(selectCanvasPermissions);
  const dispatch = useDispatch();

  const filteredActionTypes = actionTypes.filter(
    (p) =>
      !actionTypeSearchQuery || p.name.toLowerCase().includes(actionTypeSearchQuery.toLowerCase()),
  );

  const onSelectActionType = (actionType) => {
    if (canvasPermissions.isReadonlyAccess) {
      return;
    }

    setSelectedActionType(actionType);
    setDropdownOpened(false);
    dispatch(setNewCurrentStepActionType(ActionTypes.CUSTOM));
    dispatch(setNewCurrentStepActionName(actionType.name));

    if (currentStep.object.actionName !== actionType.name) {
      commonSendEventFunction(RP_EVENT_EDIT_OBJECT, {
        stepId: currentStep.stepId,
        actionType: ActionTypes.CUSTOM,
        actionName: actionType.name,
        filterData: [],
      });

      commonSendEventFunction(RP_ANALYTICS_FILTER_DATA_CHANGED, {
        entities: {
          [currentStep.stepId]: {
            dataFilter: [],
          },
        },
      });

      dispatch(setNewCurrentStepFilterParams([]));
    }
  };

  const isItemLocked = (item) => {
    if (item.name === MEETING_ACTION_NAME && !canvasPermissions.calendarTrackingAllowed) {
      return true;
    } else if (item.name === FORM_ACTION_NAME && !canvasPermissions.formTrackingAllowed) {
      return true;
    } else if (item.name === COMMERCE_ACTION_NAME && !canvasPermissions.purchaseTrackingAllowed) {
      return true;
    } else if (item.name === DEAL_ACTION_NAME && !canvasPermissions.dealTrackingAllowed) {
      return true;
    }

    return false;
  };

  return (
    <ClickOutsideCustomComponent
      onClickOutside={() => setDropdownOpened(false)}
      ignoreClickOutside={modalOpened}
    >
      <Dropdown
        className={styles.CustomActionDropdown}
        triggerSlot={
          <DropdownTrigger
            value={selectedActionType.name}
            placeholder="Select an action"
            isOpen={dropdownOpened}
          />
        }
        isOpen={dropdownOpened}
        onToggle={(o) => {
          if (canvasPermissions.isReadonlyAccess) {
            return;
          }
          setDropdownOpened(o);
        }}
      >
        <div className={styles.PropertyDropdownContent}>
          <div className={styles.SearchProperty}>
            <SearchInput
              value={actionTypeSearchQuery}
              onChange={(value) => setActionTypeSearchQuery(value)}
              placeholder="Search"
            />
          </div>
          <div className={styles.DropdownList}>
            {isLoading && <div className={styles.PropertiesLoading}>Is loading...</div>}
            <When condition={!isLoading}>
              <Disclosure
                isOpened={actionsDisclosureOpened}
                disclosureButtonSlot={
                  <div
                    onClick={() => setActionsDisclosureOpened(!actionsDisclosureOpened)}
                    className={styles.PropertiesListDisclosureButton}
                  >
                    <div>
                      <div
                        className={cls(styles.TriggerIcon, {
                          [styles.TriggerIconOpened]: actionsDisclosureOpened,
                        })}
                      >
                        {iconArrow}
                      </div>
                      <span className={styles.PropertiesListDisclosureButtonText}>
                        Custom Actions
                      </span>
                    </div>
                    <span>{pluralize('type', filteredActionTypes.length, true)}</span>
                  </div>
                }
              >
                {filteredActionTypes.map((p) => (
                  <UILock isLocked={isItemLocked(p)}>
                    <div
                      key={p.name}
                      className={styles.DropdownListItem}
                      onClick={() => onSelectActionType(p)}
                    >
                      <span>{p.name}</span>
                      <span>{p.hits}</span>
                    </div>
                  </UILock>
                ))}
              </Disclosure>
            </When>
          </div>
          <div className={styles.CustomActionDropdownSetup}>
            <button onClick={openModal} className={styles.CustomActionDropdownSetupButton}>
              + Setup New Custom Action
            </button>
          </div>
        </div>
      </Dropdown>
    </ClickOutsideCustomComponent>
  );
};
