import React, { useEffect, useRef, useState } from 'react';
import styles from 'react-project/LeftSideBar/LeftSideBar.module.scss';
import { iconCounty, iconWarrning } from 'react-project/assets/Icons';
import { When } from 'react-project/Util/When';
import cls from 'classnames';
import PropTypes from 'prop-types';
import CountriesFilter from 'react-project/FilterData/CountriesFilter';
import { ClickOutsideCustomComponent } from 'react-project/Util/ClickOutsideCustom';
import { getDirtyStyle, getFilterAddClass } from 'react-project/Util/FilterStyleHelper';
import { TEXTS_TOOLTIP } from 'react-project/Constants/texts';
import { TOOLTIP_POSITIONS } from 'react-project/Constants/tooltip';
import { Tooltip } from 'react-project/components/tooltip/Tooltip';
import { cloneData } from 'shared/CSharedMethods';

export const CountriesFilterBlock = ({
  selectedCountries,
  funnel,
  onSelectCountries,
  type,
  isApplyButtonEnabled,
  hasFilterChange,
}) => {
  const [countriesOpened, setCountriesOpened] = useState(false);

  const onSelect = (countries, allCountries) => {
    const sc = cloneData(countries); // selected countries
    const ac = cloneData(allCountries); // all countries list
    if (JSON.stringify(sc.sort()) === JSON.stringify(ac.sort())) {
      // If all the available countries are selected , its the same as empty array.
      // By default empty array means all countries are selected
      // And its important to set it to empty so that when the system compares
      // the default empty array with a list of all countries , it should be the same.
      // And the system compares that in order to determine if there was a change in the filter.
      onSelectCountries({ countries: [] });
    } else {
      onSelectCountries({ countries: countries });
    }
  };

  const ref = useRef();

  return (
    <ClickOutsideCustomComponent
      ignoreClickOutside={!countriesOpened}
      onClickOutside={() => setCountriesOpened(false)}
    >
      <div
        className={cls(styles.FiltersItem, getFilterAddClass(type), {
          [styles.ActiveFilter]: countriesOpened,
          [getDirtyStyle(type)]: selectedCountries.length !== 0,
        })}
        ref={ref}
      >
        <div
          className={styles.ItemsForSelection}
          onClick={() => setCountriesOpened(!countriesOpened)}
        >
          <span className={cls(styles.FilterTitleBlock, styles.CountryBlock)}>
            Country:
            <span className={`${styles.CapitalizeTitle} ${styles.CountriesTitle}`}>
              {selectedCountries.join(', ') || 'all countries'}
            </span>
          </span>
          <div className={styles.filterIconArrangement}>
            <When condition={hasFilterChange && isApplyButtonEnabled}>
              <Tooltip label={TEXTS_TOOLTIP.UNAPPLIED_CHANGES} position={TOOLTIP_POSITIONS.BOTTOM}>
                <div className={styles.FilterWarning}>{iconWarrning}</div>
              </Tooltip>
            </When>
            {iconCounty}
          </div>
        </div>
        <When condition={countriesOpened && funnel}>
          <CountriesFilter
            funnel={funnel}
            selectedCountries={selectedCountries}
            setSelectedCountries={onSelect}
            innerRef={ref}
          />
        </When>
      </div>
    </ClickOutsideCustomComponent>
  );
};

CountriesFilterBlock.propType = {
  selectedCountries: PropTypes.array.isRequired,
  funnel: PropTypes.shape({
    projectId: PropTypes.string.isRequired,
  }),
  onSelectCountries: PropTypes.func.isRequired,
};
